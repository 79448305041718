import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Highchart from './highchart';
import SalesOutput from './salesoutput.jsx';
import CostResource from './costresource.jsx';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../ImprovementPlan/comment';
import { getAccessByMenuKey } from "../../../utils/userManagement";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

export default function ImprovementAnalysis(props) {
    const [breakdownByArr, setBreakdownByArr] = React.useState(['Monthly', 'Quarterly'])
    const [filterByArr, setFilterByArr] = React.useState(['Line of Business', 'Functions', 'Projects'])
    const [stagesArr, setStagesArr] = React.useState(['EBITDA', 'Inventory', 'Recievables', 'Revenue', 'Cost'])
    const [breakdownByValue, setBreakdownByValue] = React.useState(null)
    const [filterByValue, setFilterByValue] = React.useState(null)
    const [stagesValue, setStagesValue] = React.useState(null)
    const [radioValue, setRadioValue] = React.useState('1');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBreakdownByChange = (event) => {
        if (event.target) {
            setBreakdownByValue(event.target.value)
        }
    };

    const handleFilterByChange = (event) => {
        if (event.target) {
            setFilterByValue(event.target.value)
        }
    };

    const handleStagesChange = (event) => {
        if (event.target) {
            setStagesValue(event.target.value)
        }
    };

    const handleChangeRadio = (event) => {
        setRadioValue(event.target.value);
    }

    return (
        <div className="ImprovementAnalysis mt-20">
            <div className="filterSection">
            <h4 className="text-center mb-20">FCF Target Breakdown by Cadence Stages</h4>
            <Grid container style={{maxWidth:'600px'}} spacing={1} >
                <FormControl  component="fieldset">
                    <RadioGroup aria-label="gender" name="radio" value={radioValue} onChange={handleChangeRadio} className='radioGroupHorizontal'>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Cadence Analysis" />
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Improve Cost and Resources" />
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label="Improve Sales and Outputs" />
                    </RadioGroup>
               </FormControl>
            </Grid>
            <Grid container spacing={3} className="mt-10 filter-options">
                <Grid item xs={12} sm={2} className='maxw-200 breakdown-by-dropdownlist'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Time Period</InputLabel>
                        <Select
                            value={breakdownByValue}
                            label="Month"
                            onChange={handleBreakdownByChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {breakdownByArr && breakdownByArr.length && breakdownByArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className='maxw-200 filter-by-custom-ddl'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Dimensions</InputLabel>
                        <Select
                            value={filterByValue}
                            label="Quarter"
                            onChange={handleFilterByChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {filterByArr && filterByArr.length && filterByArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className='maxw-200 stages-custom-ddl'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>KPI's</InputLabel>
                        <Select
                            value={stagesValue}
                            label="EBITDA"
                            onChange={handleStagesChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {stagesArr && stagesArr.length && stagesArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className="collaborate-button text-right">
				  {getAccessByMenuKey(props.menuData, "view_initiatives", "chat") &&
                    <Button variant="contained" className='newButton btnBlue btnCollaborate uppercase' size="small" color="primary" onClick={handleOpen}>Collaborate</Button> 
                        }
                </Grid>
            </Grid>
            </div>
            
                            
            <Modal
             className="collaborate-modal"
             open={open}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
            >
                <div style={style}>
                 <CommentBox />
                </div>
            </Modal>                
            
            {
                radioValue == 1 ? 
                <>
                    <Highchart/>
                    <div className="bottomBanner mt-20">**FCF Cadence Chart of EBITDA for the year 2019 - Line of Business</div>
                </> : radioValue == 2 ? <CostResource/> : <SalesOutput/>
            }
        </div>
    )
}
