
import React from "react";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../utils/dateFormat'
import { numberWithCommas, handleTableTransform } from '../../../../utils/numberUtils'

import { getAccessByMenuKey , getActionOwneruser} from "../../../../utils/userManagement";
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ActionOwnerPopper = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

const headerColorObj = {
    "current": '#64DD17',
    "1_30_days": '#AEEA00',
    "31_60_days": '#FFD600',
    "61_90_days": '#FFAB00',
    "91_180_days": '#FF6D00',
    "180_plus_days": '#d00909'
}

const agingCategoryArr = [
    '1_30_days',
    '31_60_days',
    '61_90_days',
    '91_180_days',
    '180_plus_days',
    'grand_total',
    'overdue_amount',
    'current'
]

const handleDynamicWidth = (item) => {
    let widthSize = 65;
    switch (item.key) {
        case 'percentage':
            widthSize = 90;
            break;
        case 'grand_total':
            widthSize = 80;
            break;
        case 'customer_name':
            widthSize = 130;
            break;
        case 'project':
            widthSize = 80;
            break;
        case 'document_number':
            widthSize = 80;
            break;
        case 'assignment':
            widthSize = 80;
            break;
        case 'overdue_amount':
            widthSize = 120;
            break;
        case 'improvement_plan':
            widthSize = 120;
            break;
        case 'collection_forecast':
            widthSize = 120;
            break;
        case 'collection_actual':
            widthSize = 120;
            break;
        case 'action_owner':
            widthSize = 150;
            break;
        case 'text':
            widthSize = 90;
            break;

        default: break;
    }
    return widthSize
}

const modifyColumnData = (columnData) => {
    let modifiedData;
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData
    return modifiedData
}

const handleSummaryColumn = (dataItem, buRowData) => {
    let returnString = '';
    returnString = dataItem.label;
    if (buRowData[dataItem.key]) {
        returnString = returnString + ' ' + modifyColumnData(buRowData[dataItem.key])
    }
    return returnString;
}

const handleFixedColumn = (item) => {
    let fixed = ''
    switch (item.key) {
        case 'customer_name':
            fixed = 'left'
            break;
        case 'customer':
            fixed = 'left'
            break;
        case 'company_code':
            fixed = 'left'
            break;

        default: break;
    }
    return fixed
}

export const changeKeysOfHeader = (
    data,
    summaryState,
    authState,
    externalState,
    buValue,
    handleChatChangeFn,
    onImageInputHandler,
    handleSendChatFunction,
    handleImageOptions,
    uploadImageObj,
    getPipID,
    showCommentModal,
    handleChatClick,
    handleOpenEditModal,
    handleOpenHistoryModal,
    menuData,
) => {

   
    const convertAmountData = (data) => {
            var modifiedData = data 
            if(modifiedData < 0 && modifiedData!= -1){
                var positiveTransformedValue = Math.abs(modifiedData)
            }else {
                var positiveTransformedValue = modifiedData
            }
        modifiedData = numberWithCommas(positiveTransformedValue)
        return modifiedData
      }
      const convertAmountDataWithoutRound = (data) => {
        var modifiedData = data
    
        if (modifiedData < 0 && modifiedData != -1) {
          var positiveTransformedValue = Math.abs(modifiedData)
        } else {
          var positiveTransformedValue = modifiedData
        }
        modifiedData = numberWithCommas(positiveTransformedValue)
        return modifiedData
      }
    const menus = authState.menuData
    let buUser = getActionOwneruser(externalState.allUser, buValue);
    // externalState.allUser.filter(item => {
    //     if (item && item.bu && buValue) {
    //         return item.bu.toLowerCase() == buValue.toLowerCase()
    //     }
    // });

    // if (buUser.length == 0) {
    //     buUser = externalState.allUser.filter(x => {
    //         let res = false;
    //         let roles = ["admin", "corporate", "functional"];
    //         roles.forEach(y => {
    //             if (!res)
    //                 res = x.role.toLowerCase().indexOf(y.toLowerCase()) > -1
    //         })
    //         return res
    //     })
    // }

    // buUser = buUser.sort(
    //     function (a, b) {
    //         if (a.role === b.role) {
    //             // order by role and name
    //             return a.full_name > b.full_name ? 1 : -1;
    //         }
    //         return a.role > b.role ? 1 : -1;
    //     });

    if (data && data.length) {
        return data.map((item) => {
            if (item.key == 'comments') {
                return {
                    Header: 'Comments / Edit / History',
                    id: item.key,
                    width: 150,
                    accessor: item.key,
                    Cell: (thisProps) => {
                        const data = thisProps.original
                        return (
                            <div className="flex ai-center jc-space-evenly">
                                    {getAccessByMenuKey(menuData, "cadence_board", "edit") &&
                                <Tooltip title="Edit">
                                    <Icon className="fs-20 primary" color={"inherit"} onClick={(e) => {e.preventDefault(); handleOpenEditModal(data)}}>edit</Icon>
                                </Tooltip>
                    }
                                    {getAccessByMenuKey(menuData, "cadence_board", "chat") &&
                                <Tooltip title="Add Comment">
                                                
                                    <Icon className="fs-20 primary" color={"inherit"} onClick={(e) => {e.preventDefault(); handleChatClick(thisProps); showCommentModal(data);  }}>add_comment</Icon>
                                </Tooltip>
                    }
                                <Tooltip title="Show Histories">
                                    <Icon className="fs-20 primary" color={"inherit"} onClick={(e) => {e.preventDefault(); handleOpenHistoryModal(data)}}>history</Icon>
                                </Tooltip>
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }

            }

            return {
                Header: item.label,
                id: item.key,
                width: 110,
                accessor: item.key,
                Cell: (props) => {
                    return (
                        <div className='text-center'>
                            {

                                <span>{handleTableTransform(props.original, item)}</span>
                            }
                        </div>
                    )
                },
                sortMethod: (a, b) => {
                    if(typeof a != "string"){
                        // console.log(a, "ini A")
                        // console.log(b, "ini B")
                    return Number(a)-Number(b)
                    }else{
                        if(item.key == "collection_actual"){
                            let val = a.split(",").join("")
                            let valb = b.split(",").join("")
                            let valInt = parseInt(val)
                            let valIntB = parseInt(valb)
                            valInt = isNaN(valInt) ? 0 : valInt
                            valIntB = isNaN(valIntB) ? 0 : valIntB

                            return valInt - valIntB
                        }else{
                            if (a > b) {
                                return -1;
                              } else if (a < b) {
                                return 1;
                              }
                              return 0;
                        }
                        
                   }
                },
                style: {
                    fontSize: '12px',
                    cursor: item.key == 'grand_total' ? '' : 'pointer'
                },
                headerStyle: {
                    whiteSpace: 'break-spaces',
                    overflow: 'visible',
                    fontSize: '13px',
                    fontWeight: 'bold',
                }
            }

            
        });
    }
    return [];
};