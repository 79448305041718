import { put, takeLatest, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import {
    fetchDPlanningSalVolDataService,
    fetchDPlanningNetSalDataService,
    fetchDPlanningDistCostDataService,
    fetchDPlanningVarCostClinkerDataService,
    fetchDPlanningVarCostCementDataService,
    fetchDPlanningFixedCostDataService,
    fetchDPlanningEbitdaAin000sDataService,
    fetchDPlanningEbitdaPertDataService,
    saveKpiPlanningModelsService,
    fetchKpiPlanningModelsService,
    fetchKPIPlanningService,
    saveKpiBudgetPlanningModelsService,
    fetchKPIComparePlanningService,
    saveKpiBudgetPlanningInlineModelsService,
    updateStatusKpiPlanningService,
    fetchKpiPlanningReviewService
} from "./service";


function* fetchDPlanningSalVolData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_SALES_VOLUME_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningSalVolDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_SALES_VOLUME_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_SALES_VOLUME_LOADER", loading: false });
}

function* fetchDPlanningNetSalData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_NET_SALES_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningNetSalDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_NET_SALES_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_NET_SALES_LOADER", loading: false });
}

function* fetchDPlanningDistCostData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_DIST_COST_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningDistCostDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_DIST_COST_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_DIST_COST_LOADER", loading: false });
}

function* fetchDPlanningVarCostClinkerData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CLINKER_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningVarCostClinkerDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CLINKER_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CLINKER_LOADER", loading: false });
}

function* fetchDPlanningVarCostCementData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CEMENT_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningVarCostCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CEMENT_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_VAR_COST_CEMENT_LOADER", loading: false });
}

function* fetchDPlanningFixedCostData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_FIXED_COST_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningFixedCostDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_FIXED_COST_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_FIXED_COST_LOADER", loading: false });
}

function* fetchDPlanningEbitdaAin000sData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_EBITDA_AIN000S_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningEbitdaAin000sDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_EBITDA_AIN000S_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DETAIL_PLANNING_EBITDA_AIN000S_LOADER", loading: false });
}

function* fetchDPlanningEbitdaPertData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_EBITDA_PERT_LOADER", loading: true });
    }
    const response = yield call(fetchDPlanningEbitdaPertDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DETAIL_PLANNING_EBITDA_PERT_SUCCESS", data: response.data });
    } else yield put({ type: "FFETCH_DETAIL_PLANNING_EBITDA_PERT_LOADER", loading: false });
}

function* saveKpiPlanningModels(data){
    yield put({ type: "FETCH_KPI_PLANNING_DATA_LOADER", loading: true });
    const response = yield call(saveKpiPlanningModelsService, data.payload);
    if (response && response.status == 200) {
        toastr.success('KPI Planning Saved')
        yield put({ type: "SAVE_KPI_PLANNING_SUCCESS", data: response.data });
        yield put({ type: "FETCH_KPI_PLANNING_DATA_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* fetchKpiPlanningModelsData(data) {
	yield put({ type: 'FETCH_KPI_PLANNING_MODELS_DATA_LOADER', loading: true });
	const response = yield call(fetchKpiPlanningModelsService, data.payload);
	if (response && response.status == 200 && !data.payload.export) {
		yield put({
			type: 'FETCH_KPI_PLANNING_MODELS_DATA_SUCCESS',
			data: response.data,
		});
	}

	yield put({ type: 'FETCH_KPI_PLANNING_MODELS_DATA_LOADER', loading: false });

}

function* fetchKPIPlanningData(data) {
    yield put({ type: "FETCH_KPI_PLANNING_DATA_LOADER", loading: true });
    const response = yield call(fetchKPIPlanningService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_KPI_PLANNING_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_KPI_PLANNING_DATA_LOADER", loading: false });
}

function* saveKpiBudgetPlanningModels(data){
    yield put({ type: "EDIT_KPI_PLANNING_DATA_LOADER", loading: true });
    const response = yield call(saveKpiBudgetPlanningModelsService, data.payload);
    if (response && response.status == 200) {
        toastr.success('KPI Budget Planning Saved')
        yield put({ type: "EDIT_KPI_PLANNING_DATA_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* fetchKPIComparePlanningData(data) {
    yield put({ type: "FETCH_KPI_COMPARE_PLANNING_DATA_LOADER", loading: true });
    const response = yield call(fetchKPIComparePlanningService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_KPI_COMPARE_PLANNING_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_KPI_COMPARE_PLANNING_DATA_LOADER", loading: false });
}

function* saveKpiBudgetPlanningInlineModels(data){
    yield put({ type: "EDIT_KPI_PLANNING_DATA_LOADER", loading: true });
    const response = yield call(saveKpiBudgetPlanningInlineModelsService, data.payload);
    if (response && response.status == 200) {
        toastr.success('KPI Budget Planning Saved')
        yield put({ type: "EDIT_KPI_PLANNING_DATA_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* updateStatusKpiPlanning(data){
    let dstatus = data.payload[0].status;
    let dloader = "FETCH_KPI_PLANNING_REVIEW_DATA_LOADER";
    if (dstatus == 'submit') {
        dloader = "EDIT_KPI_PLANNING_DATA_LOADER"
    }
    yield put({ type: dloader, loading: true });
    const response = yield call(updateStatusKpiPlanningService, data.payload);
    if (response && response.status == 200) {
        toastr.success(`${dstatus.toUpperCase()} KPI Planning Success`)
        // yield put({ type: "UPDATE_STATUS_KPI_PLANNING_SUCCESS", data: response.data });
        yield put({ type: dloader, loading: false });
        yield put({ type: "FETCH_KPI_PLANNING_REVIEW", payload : {}});  
    }else{
         toastr.error(response.error)
    }
}

function* fetchKpiPlanningReviewData(data) {
	yield put({ type: 'FETCH_KPI_PLANNING_REVIEW_DATA_LOADER', loading: true });
	const response = yield call(fetchKpiPlanningReviewService, data.payload);
	if (response && response.status == 200 && !data.payload.export) {
		yield put({
			type: 'FETCH_KPI_PLANNING_REVIEW_DATA_SUCCESS',
			data: response.data,
		});
    }
yield put({ type: 'FETCH_KPI_PLANNING_REVIEW_DATA_LOADER', loading: false });
}   

export default function* actionWatcher() {
    yield takeLatest("FETCH_DETAIL_PLANNING_SALES_VOLUME", fetchDPlanningSalVolData)    
    yield takeLatest("FETCH_DETAIL_PLANNING_NET_SALES", fetchDPlanningNetSalData)
    yield takeLatest("FETCH_DETAIL_PLANNING_DIST_COST", fetchDPlanningDistCostData)
    yield takeLatest("FETCH_DETAIL_PLANNING_VAR_COST_CLINKER", fetchDPlanningVarCostClinkerData)
    yield takeLatest("FETCH_DETAIL_PLANNING_VAR_COST_CEMENT", fetchDPlanningVarCostCementData)

    yield takeLatest("FETCH_DETAIL_PLANNING_FIXED_COST", fetchDPlanningFixedCostData)
    yield takeLatest("FETCH_DETAIL_PLANNING_EBITDA_AIN000S", fetchDPlanningEbitdaAin000sData)
    yield takeLatest("FETCH_DETAIL_PLANNING_EBITDA_PERT", fetchDPlanningEbitdaPertData)

    yield takeLatest('SAVE_KPI_PLANNING',saveKpiPlanningModels)
    yield takeLatest("FETCH_KPI_PLANNING_MODELS", fetchKpiPlanningModelsData)
    yield takeLatest('FETCH_KPI_PLANNING', fetchKPIPlanningData)
    yield takeLatest('SAVE_KPI_BUDGET_PLANNING',saveKpiBudgetPlanningModels)

    yield takeLatest('FETCH_KPI_COMPARE_PLANNING', fetchKPIComparePlanningData)
    yield takeLatest('SAVE_KPI_BUDGET_PLANNING_INLINE',saveKpiBudgetPlanningInlineModels)

    yield takeLatest('UPDATE_STATUS_KPI_PLANNING',updateStatusKpiPlanning)
    yield takeLatest("FETCH_KPI_PLANNING_REVIEW", fetchKpiPlanningReviewData)
    
}

