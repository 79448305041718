import React from 'react';
import Kanban from "./Kanban/index.jsx"

function KanbanBoard() {
    return (
         <Kanban/>
        
    )
}

export default KanbanBoard;