import React from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import NewTableComponent from './newTable'
import { Grid } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NegativeBarGraph from './GraphComponent'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

export class DashboardComponent extends React.Component{
    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.state = {
            newRadioSelectorValue: '0',
            topRadioSelectorValue: '1',
            tabValue: 0,
            openChat: false,
            monthValue : '',
            monthArr : [],
            loadingChart : false,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            },
            timeSelection:{
                month:{
                    value: 'Apr-2023',
                    options: ['Apr-2023', 'May 2023', 'June 2023', 'July 2023']
                },
                quarter: {
                    value: '',
                    options:[]
                },
                annual:{
                    value: '',
                    options:[]
                }
            }
        }
    }
    componentDidMount() {
        console.log( this.props, "props")
        this.props.fetchCementData({})
        this.props.fetchActualFilterData()
    }

    componentDidUpdate(prevProps) {
        let now = this.props.actualForecastCementState

        let nowChart = this.props.dashboardChartState
        let prevChart = prevProps.dashboardChartState
        if (now && now.filterArr && this.state.monthArr.length == 0) {
            this.setState({monthArr : now.filterArr, monthValue : '30-Apr-2023'})
            this.props.fetchChartData({month : '30-Apr-2023'})
        }
        // console.log(nowChart, prevChart, "--- yw ")
        if(prevChart.loading != nowChart.loading){
            this.setState({ loadingChart : nowChart.loading })
        }
    }

    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }
   
    
    handleMonthChange = (event) => {
        this.setState({monthValue : event.target.value})
        this.props.fetchChartData({month : event.target.value})
    }

    handleTabChange = (event, value) => {
        this.setState({
            tabValue: value
        })
    }

    handleNumberTransform = (number) => {
        let modifiedData = number > 10 ? ">10%" : number < -10 ?  "<-10%" : parseInt(number) + "%"
        return modifiedData
    }

    render () {
        const {timeSelection, tabValue, monthValue, monthArr, loadingChart, newRadioSelectorValue}= this.state;
        const {dashboardstate, dashboardChartState, handShowCompare} = this.props
        // const {value: monthValue, options: monthOptions} = timeSelection.month;
        const {value: quarterValue, options: quarterOptions} = timeSelection.quarter;
        const {value: annualValue, options: annualOptions}=timeSelection.annual;

        const dashboardData = dashboardstate ? dashboardstate : {
            loading: undefined,
            rowHeader:[
                {"key": "data_field","label": "","operation": 0},
                {"key": "actual_10","label": "10-Actual","operation": 0},
                {"key": "frcst_20","label": "20-Forecast","operation": 0},
                {"key": "total_10_20","label": "Total","operation": 0},
                {"key": "actual_20","label": "20-Actual","operation": 0},
                {"key": "frcst_10","label": "10-Forecast","operation": 0},
                {"key": "total_20_10","label": "Total","operation": 0},  
                {"key": "actual_30","label": "30-Actual","operation": 0},
                {"key": "budget","label": "Budget","operation": 0},
            ],
            rowData: [
                {data_field: 'Gap to Sales Volume', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'Gap to Sales EBITDA', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'Production Mix Impact', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'WIP Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'Sales Mix Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'FG Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                {data_field: 'Improvement initiatives', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
            ]
        }

        


        return (
            <React.Fragment>
                <Grid container className="pb-15 time-filter-section">
                <Grid item xs={3} sm={3} className='maxw-200 mr-15'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={monthValue}
                            label="Month"
                            defaultValue={'30-Apr-2023'}
                            onChange={this.handleMonthChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {monthArr && monthArr.length && monthArr.map(item => {
                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} className='maxw-200 mr-15'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Quarter</InputLabel>
                        <Select
                            label="Quarter"
                            disabled
                        >
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>FY</InputLabel>
                        <Select
                            label="FY"
                            disabled
                        >
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="pt-0">
                        <Grid container xs={12} sm={12} className="pr-20 pt-10 download-section" >
                            <Grid item xs={10} sm={10} className="pr-20 pt-10 buttons-section" > 
                                <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className='mb-10'
                                    onChange={this.handleTabChange}
                                >
                                    <Tab label="Table" className="list-nested" id='nav-tab-0' aria-controls='nav-tabpanel-0' />
                                    <Tab label="Chart" className="list-nested" id='nav-tab-1' aria-controls='nav-tabpanel-1' />

                                </Tabs>
                            </Grid>                   
                            <Grid item xs={2} sm={2} className="pr-20 pt-10 buttons-section" >
                                <Button variant='contained' className='run-button mr-10 newButton btnBlue btnCompare' onClick={handShowCompare}>Search and Compare</Button>
                            </Grid> 
                        </Grid>

                        <TabPanel value={tabValue} index={0}>
                        <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                    <div className="group">
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="All" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Plant" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Sales Region" />
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="Product" />
                                        <FormControlLabel value="5" control={<Radio color="primary" />} label="Dealer" />
                                        <FormControlLabel value="6" control={<Radio color="primary" />} label="Channel" />
                                        <FormControlLabel value="7" control={<Radio color="primary" />} label="Cost Center" />
                                        <FormControlLabel value="8" control={<Radio color="primary" />} label="Customer" />
                                    
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <NewTableComponent className='newNewCustomTable fixedFirstColumTable expandableRows custom-borders' tableData={dashboardData}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                        <Grid item xs={8} sm={8} className="pr-20 pt-10 download-section" >
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                    <div className="group">
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="All" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Plant" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Sales Region" />
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="Product" />
                                        <FormControlLabel value="5" control={<Radio color="primary" />} label="Dealer" />
                                        <FormControlLabel value="6" control={<Radio color="primary" />} label="Channel" />
                                        <FormControlLabel value="7" control={<Radio color="primary" />} label="Cost Center" />
                                        <FormControlLabel value="8" control={<Radio color="primary" />} label="Customer" />

                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Grid> 
                        {loadingChart ? <div  className='text-center mt-10'><CircularProgress /></div> : 

                                <div>
                                    {dashboardChartState && dashboardChartState.rowData && dashboardChartState.rowData.length > 0 ? <h3 className='primary text-center mb-10'><b>Variance vs Budget</b></h3> : ''}
                                    {dashboardChartState && dashboardChartState.rowData && dashboardChartState.rowData.length > 0 ?
                                        <NegativeBarGraph graphData={dashboardChartState.rowData } transformData={(number) => this.handleNumberTransform(number)}  doExport={false} /> :
                                        <h3 className='text-center'>There is no data for the selected date</h3>
                                    }
                                </div>
    }
                        </TabPanel>
                </Grid>
            </React.Fragment>
        )


    }

}

export default connect(
    state => {
        console.log ('master state', state.shreeCementState)
        return {
            summaryState: state.dashboardState.receivableData.ageingData.summary,
            authReducer: state.authReducer,
            dashboardstate: state.shreeCementState.dashBoardData,
            dashboardChartState: state.shreeCementState.dashBoardChartData,
            actualForecastCementState: state.shreeCementState.actualForecastCementData,
            
        }
    },
    dispatch => {
        return {
            fetchCementData(data) {
                dispatch({
                    type: "FETCH_DASHBOARD_DATA",
                    payload: data
                });
            },
            fetchChartData(data) {
                dispatch({
                    type: "FETCH_DASHBOARD_CHART_DATA",
                    payload: data
                });
            },
            fetchActualFilterData() {
                dispatch({
                    type: "FETCH_ACTUAL_FORECAST_FILTER_DATA"
                });
            }
        };
    }
)(DashboardComponent);;

//row headers
//row data - column 1