import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LogoImage from "../../../images/KOC/koc_logo_color.png"
import { Link as Linkroute } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import HomeIcon from '@material-ui/icons/Home';


export default function Appbar(props) {
  const panelMenuArray = [
    {
      id: 1,
      text: 'Dashboard',
      link: '/new-dashboard/analysis',
      defaultBreadcrumbs : {text : "Overview", link:"#"}
    },
    {
      id: 2,
      text: 'Operational Parameters',
      link: '/new-dashboard/operational',
      defaultBreadcrumbs : {text : "Table View", link:"#"}
    },
    {
      id: 3,
      text: 'RCA and Remedial Action',
      link: '/new-dashboard/rca',
      defaultBreadcrumbs : {text : "Kanban Board", link:"#"}
    },
  ]
  


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (link) =>{
        window.location = link;
  }

  const setBreadcrumbsList = (data)=>{
        let breadcrumbs = []
        breadcrumbs.push({text:data.text, link : data.link})
        breadcrumbs.push(data.defaultBreadcrumbs)
        props.setBreadcrumbs(breadcrumbs);
    }


  return (
    <div className="new-app-bar">
      <AppBar position="fixed">
        <Toolbar className="top-navbar">
          {/* <img src={LogoImage} className="img-logo"/> */}
          <div>
          <Tooltip title={"Home"}>
            <Linkroute to="/new-dashboard/panel">
              <IconButton>
                <HomeIcon
                  aria-label="menu"
                  color="inherit"
                >
                </HomeIcon>
              </IconButton>
            </Linkroute>
          </Tooltip>
          </div>
          <div className="menu-navigation">
            {
                (props.showMenu || window.location.pathname !== '/new-dashboard/panel') && (
                    panelMenuArray && panelMenuArray.map((item, index) => (
                        <Linkroute to={item.link}  className="top-menu" onClick={()=>{props.setActiveMenu(item.text);setBreadcrumbsList(item)}}>
                          <span className={(props.activeMenu == item.text || window.location.pathname === item.link)? 'active menu-item' : 'menu-item'}>{item.text}</span>
                        </Linkroute>
                    ))
               )
            }
            
            <div className="user-icon">
              <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu>
              </div>
          </div>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}
