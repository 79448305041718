import {transformFilterData, transformDefaultHeaderValues} from './transformer'

const initialState = {
    emailTemplateData: {
        Ebitda: {
            loading: false,
            data: {}
        },
    },
    cementData: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    drilldownData: {
        loading: false,
        rowData: [],
        rowHeader: [],
        rowDataSecond : [],
        rowHeaderSecond : []
    },
    simulationData: {
        loading: false,
        rowData: [],
        rowHeader: [],
        filterHbp: {
            loading: false,
            dataArr: [],
            tradeArr: [],
            customerArr: [],
            dealerArr: [],
            regionArr: [],
            costCenterArr: [],
        }
    },
    fixedCostCementData: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    salesLogisticCementData: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    cementCostData: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    clinkerCementData: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    actualForecastCementData: {
        loading: false,
        filterArr: [],
        rowData: [],
        rowHeader: []
    },
    dashBoardData:{
        loading: false,
        rowData: [],
        rowHeader: [],
    },
    dashBoardChartData:{
        loading: false,
        rowData: [],
        rowHeader: [],
    },
    compareDataPNLEbitda: {
        loading: false,
        rowHeader: [],
        rowData: [],
        defaultRowHeader: [],
        defaultRowData: [],
        filter: {
           periodArr: [],
           dataTypeArr: [],
           plantArr: [],
           countryArr: [],
        //    buArr: []
           salesRegionArr: [],
           productArr: [],
           dealerArr: [],
           channelArr: [],
           costCenterArr: [],
           customerArr: [],
        }
     },
     planningData: {
        loading: false,
        rowHeader: [],
        rowData: [],
        filter: {
            loading: false,
            planTypeArr: [],
            planArr: [],
            periodArr: [],
            viewByArr: [],
            regionArr: [],
            tradeArr: [],
        }
     }
};

export default (state = initialState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'FETCH_CEMENT_DATA_LOADER':
            newState.cementData.loading = action.loading;
            return newState;
        case 'FETCH_CEMENT_DATA_SUCCESS':
            newState.cementData.loading = action.loading;
            newState.cementData.rowData = action.data.row_data;
            newState.cementData.rowHeader = action.data.row_header;

            newState.compareDataPNLEbitda.rowData = action.data.row_data;
            newState.compareDataPNLEbitda.rowHeader = action.data?.row_header ? transformDefaultHeaderValues(action.data?.row_header) : [] ;
            newState.compareDataPNLEbitda.defaultRowHeader = newState.compareDataPNLEbitda.rowHeader;
            newState.compareDataPNLEbitda.defaultRowData = action.data.row_data;
            return newState;
        case 'FETCH_FIXED_COST_CEMENT_DATA_LOADER':
            newState.fixedCostCementData.loading = action.loading;
            return newState;
        case 'FETCH_FIXED_COST_CEMENT_DATA_SUCCESS':
            newState.fixedCostCementData.loading = action.loading;
            newState.fixedCostCementData.rowData = action.data.row_data;
            newState.fixedCostCementData.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_SALES_LOGISTIC_CEMENT_DATA_LOADER':
            newState.salesLogisticCementData.loading = action.loading;
            return newState;
        case 'FETCH_SALES_LOGISTIC_CEMENT_DATA_SUCCESS':
            newState.salesLogisticCementData.loading = action.loading;
            newState.salesLogisticCementData.rowData = action.data.row_data;
            newState.salesLogisticCementData.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_CEMENT_COST_DATA_LOADER':
            newState.cementCostData.loading = action.loading;
            return newState;
        case 'FETCH_CEMENT_COST_DATA_SUCCESS':
            newState.cementCostData.loading = action.loading;
            newState.cementCostData.rowData = action.data.row_data;
            newState.cementCostData.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_CLINKER_CEMENT_DATA_LOADER':
            newState.clinkerCementData.loading = action.loading;
            return newState;
        case 'FETCH_CLINKER_CEMENT_DATA_SUCCESS':
            newState.clinkerCementData.loading = action.loading;
            newState.clinkerCementData.rowData = action.data.row_data;
            newState.clinkerCementData.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_ACTUAL_FORECAST_FILTER_DATA_SUCCESS':
            const {
                monthArr
             } = transformFilterData(action.data)
            newState.actualForecastCementData.filterArr = monthArr;
            return newState;
        case 'FETCH_ACTUAL_FORECAST_CEMENT_DATA_LOADER':
            newState.actualForecastCementData.loading = action.loading;
            return newState;
        case 'FETCH_ACTUAL_FORECAST_CEMENT_DATA_SUCCESS':
            newState.actualForecastCementData.loading = action.loading;
            newState.actualForecastCementData.rowData = action.data.row_data;
            newState.actualForecastCementData.rowHeader = action.data.row_header;
        case 'FETCH_DASHBOARD_DATA_LOADER':
            newState.dashBoardData.loading = action.loading;
            return newState;
        case 'FETCH_DASHBOARD_DATA_SUCCESS':
            newState.dashBoardData.loading = action.loading;
            newState.dashBoardData.rowData = action.data.row_data;
            newState.dashBoardData.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DASHBOARD_CHART_DATA_LOADER':
            newState.dashBoardChartData.loading = action.loading;
            return newState;
        case 'FETCH_DASHBOARD_CHART_DATA_SUCCESS':
            newState.dashBoardChartData.rowData = action.data.row_data;
            return newState;
        case 'FETCH_SIMULATION_DATA_LOADER':
            newState.simulationData.loading = action.loading;
            return newState;
        case 'FETCH_SIMULATION_DATA_SUCCESS':
            newState.simulationData.rowData = action.data.row_data;
            newState.simulationData.rowHeader = action.data.row_header;
            return newState;
        case "RESET_COMPARE_DATA_PNL_EBITDA":
            newState.compareDataPNLEbitda.loading = action.loading;
            newState.compareDataPNLEbitda.rowData = newState.compareDataPNLEbitda.defaultRowData;
            newState.compareDataPNLEbitda.rowHeader = newState.compareDataPNLEbitda.defaultRowHeader;
            return newState;
        case "FETCH_COMPARE_DATA_PNL_EBITDA_SUCCESS":
            newState.compareDataPNLEbitda.loading = action.loading;
            newState.compareDataPNLEbitda.rowData = action.data.row_data;
            newState.compareDataPNLEbitda.rowHeader = action.data.row_header;
            return newState;
        case "FETCH_COMPARE_DATA_PNL_EBITDA_LOADER":
            newState.compareDataPNLEbitda.loading = action.loading;
            return newState;

        case "FETCH_COMPARE_FILTER_PNL_EBITDA_LOADER":
            newState.compareDataPNLEbitda.loading = action.loading;
            return newState;
        case "FETCH_COMPARE_FILTER_PNL_EBITDA_SUCCESS":
            var { 
                plantArr, 
                dataTypeArr,
                countryArr, 
                periodArr, 
                salesRegionArr, 
                productArr, 
                dealerArr, 
                channelArr, 
                costCenterArr, 
                customerArr } = transformFilterData(action.data);
            newState.compareDataPNLEbitda.filter.periodArr = periodArr;
            newState.compareDataPNLEbitda.filter.dataTypeArr = dataTypeArr;
            newState.compareDataPNLEbitda.filter.countryArr = countryArr;
            newState.compareDataPNLEbitda.filter.plantArr = plantArr;

            newState.compareDataPNLEbitda.filter.salesRegionArr = salesRegionArr;
            newState.compareDataPNLEbitda.filter.productArr = productArr;
            newState.compareDataPNLEbitda.filter.dealerArr = dealerArr;
            newState.compareDataPNLEbitda.filter.channelArr = channelArr;
            newState.compareDataPNLEbitda.filter.costCenterArr = costCenterArr;
            newState.compareDataPNLEbitda.filter.customerArr = customerArr;
            return newState;
        case 'FETCH_DRILLDOWN_DATA_LOADER':
            newState.drilldownData.loading = action.loading;
            return newState;
        case 'FETCH_DRILLDOWN_DATA_SUCCESS':
            newState.drilldownData.rowData = action.data.row_data;
            newState.drilldownData.rowHeader = action.data.row_header;
            newState.drilldownData.rowDataSecond = action.data.row_data_second;
            newState.drilldownData.rowHeaderSecond = action.data.row_header_second;
            return newState;
        case 'FETCH_EBITDA_VIEW_EMAIL_LOADER':
            newState.emailTemplateData.Ebitda.loading = action.loading;
            return newState;
        case 'FETCH_EBITDA_EMAIL_TEMPLATE_DATA_SUCCESS':
            newState.emailTemplateData.Ebitda.loading = false;
            newState.emailTemplateData.Ebitda.data = action.data;
            return newState;
        case "FETCH_DROPDOWN_MASTER_EBITDA_LOADER":
            newState.simulationData.filterHbp.loading = action.loading;
            return newState;
        case "FETCH_DROPDOWN_MASTER_EBITDA_SUCCESS":    
                newState.simulationData.filterHbp.dataArr = action.data.data;
                newState.simulationData.filterHbp.tradeArr = action.data.trade;
                newState.simulationData.filterHbp.customerArr = action.data.customer;
                newState.simulationData.filterHbp.dealerArr = action.data.dealer;
                newState.simulationData.filterHbp.regionArr = action.data.region;
                newState.simulationData.filterHbp.costCenterArr = action.data.cost_center;
                return newState;
        case "FETCH_DROPDOWN_PLANNING_LOADER":
            newState.planningData.filter.loading = action.loading;
            return newState;
        case "FETCH_DROPDOWN_PLANNING_SUCCESS":
            newState.planningData.filter.planTypeArr = action.data.plan_type;
            newState.planningData.filter.planArr = action.data.plan_list;
            newState.planningData.filter.periodArr = action.data.period;
            newState.planningData.filter.viewByArr = action.data.view_by;
            newState.planningData.filter.regionArr = action.data.region;
            newState.planningData.filter.tradeArr = action.data.trade;
            return newState;
        default:
            return newState;
    }
}
