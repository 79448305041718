import React from 'react';
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router";
import MainComponent from './MainComponent'
import {formatDate} from '../../../../utils/dateFormat'
import { downloadExcelV1, mapExcelHeader } from '../../../../utils/exportExcel';
import { format, parseJSON } from "date-fns";

export class ImprovementPlan extends React.Component {
    constructor(props) {
      super(props);

      this.reverseParentGroupObj = {
        "external": 0,
        "slb": 1,
        "unbilled": 2
     }

     this.state = {
      externalfilterArr: [],
      hasFilterData: false,
      defaultTab:  0,
      externalFilterObject: {
          action_owner: [],
          aging_category: [],
          category: "",
          customer_name:  [],
          customer:  [],
          skip: 0,
          bu:  'BU 1',
          parent_group: 'external',
          receivableTabSelectorValue: this.props.tabSelectorValue
      },
      selectedBu : 'BU 1',
  }

    }

    componentDidMount() {

      let payload = {
          action_owner: [],
          aging_category: [],
          category: "",
          customer_name: [],
          skip: 0,
          bu: 'BU 1',
          parent_group: 'external'
      };
      const {state} = this.props.location;
      let parentGroup = 'external';

      if (state) {
          payload.customer_name = [state.customer_name]
          payload.bu = state.bu.toLowerCase()
          parentGroup = state.parent_group.toLowerCase()
          payload.parent_group = parentGroup
      }

      this.setState({
          defaultTab: state ? this.reverseParentGroupObj[parentGroup] : 0,
          externalFilterObject: payload
      })
      // this.props.getFilterData(payload)
      this.props.getPipMasterData({ bu: payload.bu })
      this.props.getAllUser({});

  }

  componentDidUpdate() {
    // const { hasFilterData } = this.state
    // const { externalData } = this.props;
    // const { external } = this.props.ageingData
    // if (!hasFilterData && external.fetched) {
    //     const filterArr = externalData && externalData.filterObj && externalData.filterObj.data
    //     this.setState({ externalfilterArr: filterArr, hasFilterData: true })
    // }
}

handleDateChangeFn = (payload) => {
  this.props.updateSelectedDate(payload.selectedDate)
  this.currentDate = payload.selectedDate;
  if (payload.mainTab == 'summary') {
      this.props.getSummaryDetail();
  } else {
      const { externalFilterObject } = this.state;
      // let modExternalFilterObject = JSON.parse(JSON.stringify(externalFilterObject))
      externalFilterObject['bu'] = payload.secondaryTab
      externalFilterObject['parent_group'] = payload.mainTab
      externalFilterObject['customer_name'] = []
      externalFilterObject['receivableTabSelectorValue'] = this.props.tabSelectorValue
      this.setState({externalFilterObject})
      // this.props.setSelectedFilter(externalFilterObject)
      // this.props.getSummaryBuData(externalFilterObject)
      this.props.getMSMDetail(externalFilterObject);
      // this.props.getFilterData(externalFilterObject);
  }
}

mapExcelDataSummary = (data) => {
  data = data.map(x => {
      delete x.overdue_amount
      switch (x.parent_group) {
          case "external":
              x.parent_group = "3rd Party"
              break;
          case "slb":
              x.parent_group = "SLB"
              break;
          case "unbilled":
              x.parent_group = "Unbilled"
              break;
          case "recievables":
              x.parent_group = "Total by BU"
              break;
      }
      return x
  })
  return data
}

mapUser = (data) => {
  let userData = this.props.externalData.allUser
  data = data.map(x=> {
      let label = userData.find(y => {
          return y.login_id == x.action_owner
      })

      if(label != undefined)
      x.action_owner = label.full_name

      label = userData.find(y => {
          return y.login_id == x.collection_forecast_confirmed_by
      })

      if(label != undefined)
      x.collection_forecast_confirmed_by = label.full_name

      return x
  })
  return data
}

handleBuChange = (bu) => {
  const {externalFilterObject} = this.state;

  let newExternalFilter = {...{}, ...externalFilterObject}
  newExternalFilter.customer_name = []
  
  this.setState({externalFilterObject: newExternalFilter,selectedBu : bu == 'BU 1' ? 'BU 1' : bu.toUpperCase()})
  this.props.setSelectedBusinessUnit(bu)
}

handleChangeSelectedParentGroup = (parentGroup) => {

  const {externalFilterObject} = this.state;

  let newExternalFilter = {...{}, ...externalFilterObject}
  newExternalFilter.customer_name = []
  newExternalFilter.parent_group = parentGroup

  this.setState({
      externalFilterObject: newExternalFilter
  })

}

 mapExcelData = (data, header, dataType, field) => {
    field = field == undefined ? "key" : field
    let newRes = []
    let mergeCells = []
    let fontColors = []

    let colorCode = { red : "FFFF0000", green : 'FF00AC00', orange : "FFFF8A36" }

    data = data.forEach((x, idxMain) => {
            x["pip_details"].forEach((a,idx) => {
            let newX = [];
            header.forEach((z, idxHeader) => {
                let finalVal = ""
                let curValMain = x[z[field]]
                let curValPip = z[field] == "bu"? undefined : x["pip_details"][idx][z[field]] // get pip details data, and ignore bu field
                if(idx == 0){ // first data, fill all the field
                    finalVal = curValMain == undefined ? curValPip == undefined ? "" : curValPip : curValMain

                    if(curValPip == undefined){ // merge cells for Main Data
                        let pipLen = x["pip_details"].length - 1
                        mergeCells.push({ rowStart : idxMain, colStart : idxHeader, rowFinish : idxMain + pipLen, colFinish : idxHeader })
                    }

                }else{ // next data, only fill the pip data
                    finalVal = curValPip == undefined ? "" : curValPip 
                }

                if(z[field] == 'stage')
                fontColors.push({ row : idxMain + idx, column : idxHeader, font : { color : {argb : colorCode[x["pip_details"][idx]["status"]] } }} )


                let dt = dataType[idxHeader]

                if (dt == 'date') {//convert to date

                try {
                    finalVal = format(parseJSON(finalVal), "dd MMM yyyy")
                    } catch (e) {
                    console.log(e)
                    }
                }

                newX.push(finalVal)
            })
            newRes.push(newX)

      })
    })
    return {data : newRes, merge : mergeCells, font : fontColors }
  }


handleDownloadExcel = () =>{
    let newArr = [];
    let ops = {};
    ops.dataType = {6 : 'number', 11 :  'number', 10 : 'date'}
    ops.fileName = ` Improvement Plan Summary `

    let data = this.props.externalData.data
    let header = mapExcelHeader(data.row_header, "label")
    data.row_data = data.row_data.sort(function(a, b){return a.pip_oldest - b.pip_oldest}).reverse() 
    let mapped = this.mapExcelData(data.row_data, data.row_header, ops.dataType)

    ops.columns = header
    ops.data = mapped.data;
    ops.merge = mapped.merge;
    ops.font = mapped.font;
    ops.alignment = { 12 : { horizontal: 'left' }  }

    downloadExcelV1(ops)
}

render() {
  const { externalfilterArr,externalFilterObject, defaultTab } = this.state;
  const { ageingData, externalData, dashboardState } = this.props;
  const filterArr = externalData && externalData.filterObj && externalData.filterObj.data
  return (
      <React.Fragment>
          {dashboardState.reportDateArr && dashboardState.reportDateArr.length ?
              <MainComponent
                  externalfilterArr={filterArr}
                  handleApplyFilter={this.handleApplyFilter}
                  handleSendComment={this.handleSendComment}
                  handleChangeSelectedBU={this.handleBuChange}
                  handleChangeSelectedParentGroup={this.handleChangeSelectedParentGroup}
                  defaultTab={defaultTab}
                  reportDateArr={dashboardState.reportDateArr}
                  handleDateChangeFn={this.handleDateChangeFn}
                  handleDownloadExcel={this.handleDownloadExcel}
                  printDocument={this.printDocument}
                  externalFilterObject={externalFilterObject}
                  receivableTabSelectorValue={this.props.tabSelectorValue}
              /> :
              null
          }
      </React.Fragment>
  );
}
}

export default withRouter(
  connect(
    state => {
        return {
            ageingData: state.dashboardState.receivableData.ageingData,
            externalData: state.dashboardState.receivableData.ageingData.external,
            dashboardState: state.dashboardState
        }
    },
    dispatch => {
        return {
            getMSMDetail(data) {
                dispatch({
                    type: "FETCH_RECEIVABLE_AGEING_IMPROVEMENT_PLAN_EXTERNAL_DATA",
                    payload: data,
                });
            },
            getSummaryDetail() {
                dispatch({
                    type: 'FETCH_RECEIVABLE_DATA'
                });
            },
            getPipMasterData(data) {
                dispatch({
                    type: "FETCH_MASTER_PIP_DATA",
                    payload: data,
                });
            },
            getFilterData(data) {
                dispatch({
                    type: "FETCH_EXTERNAL_FILTER_DATA",
                    payload: data,
                });
            },
            getSummaryBuData(data) {
                dispatch({
                    type: "FETCH_SUMMARY_BU_DATA",
                    payload: data,
                });
            },
            setSelectedFilter(data) {
                dispatch({
                    type: "SET_SELECTED_FILTER",
                    payload: data,
                });
            },
            setSelectedBusinessUnit(data) {
                dispatch({
                    type: "SET_SELECTED_BUSINESS_UNIT",
                    payload: data,
                });
            },
            updateSelectedDate(data) {
                dispatch({
                    type: "UPDATE_SELECTED_DATE",
                    payload: data,
                });
            },
            getAllUser(data) {
                dispatch({
                    type: "FETCH_ALL_USER",
                    payload: data,
                });
            },
        };
    }
      )(ImprovementPlan)
    );