import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../../constant";

function HighChart(props) {
    const {performanceAnalysis: { monthlyChange: { negativeValueColor, positiveValueColor } }} = CHART_COLORS;
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'bar'
                        },
                        title: {
                            text: 'Total FCF change vs Last Month'
                        },
                        credits: {
                            enabled: false
                          },
                        xAxis: {
                            categories: ['Project4', 'LoB1', 'Project1', 'Project3', 'Location3', 'Location1', 'Project2', 'Location2', 'LoB2', 'LoB3']
                        },
                        yAxis: {
                            title: {
                                text: ''
                            }
                        },
                        legend: {
                            reversed: true
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            }
                        },
                        series: [{
                            name: 'USD MILLIONS',
                            data: [ 
                                { y: 3.1, color: positiveValueColor}, 
                                {y: 2.1, color: positiveValueColor}, 
                                {y: 1.7, color: positiveValueColor}, 
                                {y: 1.5, color: positiveValueColor}, 
                                {y: 0.7, color:positiveValueColor}, 
                                {y: -0.7, color:negativeValueColor}, 
                                {y: -1.4, color: negativeValueColor}, 
                                {y: -2, color: negativeValueColor}, 
                                {y:-3, color:negativeValueColor}, 
                                {y: -3.9, color:negativeValueColor} 
                            ],
                        }]
                    }
                }
            />
        </div>
    )
}
export default HighChart;