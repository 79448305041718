import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { APP_ICONS } from '../../../constant';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


function createData(name, t360, t90, t30, t1, tPlus1, tPlus30, detail) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30, detail};
}

function createDetail(name, t360, t90, t30, t1, tPlus1, tPlus30) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30};
}

const rows = [
  createData(
    'Revenue', "", "", "", "", "", "",
    [
     createDetail("Passenger","", "", "", "", "", ""),
     createDetail("Cargo","", "", "", "", "", ""),
     createDetail("Food & Beverage","", "", "", "", "", ""),
     createDetail("Other","", "", "", "", "", ""),
    ]
  ),
  createData(
    'Cost',"", "", "", "", "", "",
    [
     createDetail("Fuel Cost","", "", "", "", "", ""),
     createDetail("Crew cost","", "", "", "", "", ""),
     createDetail("Operational Cost","", "", "", "", "", ""),
     createDetail("Overhead Cost","", "", "", "", "", ""),
     createDetail("Other","", "", "", "", "", ""),
    ]
  ),
  createData(
    'Route Profit/ Loss',"", "", "", "", "", "",
    []
  )
];

const rowsDummy = [
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
]

const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        expandComponent.map(detail => (
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>{detail.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
         </TableRow>
        ))
      )}
    </>
  );
};


export default function Analytics() {

  return (
    <Grid container className="analytics-content">
      <Grid container className="mb-20">
          <Grid item xs={12} className="flex jc-space-between ai-center">
              <Button variant="contained" color="secondary" className="mr-20 btn-dark-blue" size="small">Add</Button>
              <div>
                <Tooltip title={'Download to Excel'}>
                  <img
                    src={downloadExcelIcon}
                    height='35'
                    className='download-icon mr-10'
                  />
                </Tooltip>
                <Tooltip title={'Download to Pdf'}>
                  <img
                    src={downloadPdfIcon}
                    height='35'
                    className='download-icon'
                  />
                </Tooltip>
              </div>
          </Grid>
      </Grid>
      <Grid item xs={12} className="mt-20 mb-30">
          <Table className="custom-table" aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last Update</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="center" className="action flex ai-center jc-center">
                  <EditIcon fontSize="small"/>
                  <Button variant="contained" color="secondary" className="ml-20 btn-dark-blue" size="small"><DeleteIcon fontSize="small"/></Button>
                </TableCell>
              </TableRow>
               <TableRow>
                <TableCell className="px-15"></TableCell>
                <TableCell className="px-15"></TableCell>
                <TableCell className="px-15"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
      </Grid>
      <Grid item xs={12}>
          <Table className="custom-table" aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell></TableCell>
                <TableCell>T-360</TableCell>
                <TableCell>T-90</TableCell>
                <TableCell>T-30</TableCell>
                <TableCell>T-1</TableCell>
                <TableCell>T+1</TableCell>
                <TableCell>T+30</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <ExpandableTableRow
                  key={row.name}
                  expandComponent={row.detail}
                >
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </ExpandableTableRow>
              ))}
            </TableBody>
          </Table>
      </Grid>
    </Grid>
  );
}
