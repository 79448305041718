
import React from 'react';
import { connect } from "react-redux";
import CadenceComponent from './cadenceBoard'

export class CadenceBoardMaster extends React.Component {
    constructor(props) {
        super(props);
        this.dateStatus = ["None", "Blank", "Late", "Completed", "Ahead"]
        this.impPlanTypeArr = []
        this.totalPlanArr = []
        this.state = {
            seriesData: [],
            loading: true,
            tabValue: 0,
            month: '',
            costValue: '',
            totalPlanValue: '',
            planTypeValue: '',
            blankDateEnabled: true,
            lateEnabled: true,
            completedEnabled: true,
            aheadEnabled: true,
            dateStatusSelected: this.dateStatus
        }
    }

    componentDidMount() {
        // this.props.getCadenceBoardData();
        // this.props.fetchCadenceBoardData();
        this.props.fetchFCFFilter();
    }

    componentDidUpdate() {
        this.fillFilter();
    }

    fillFilter = () => {
        const { ecfmState } = this.props;
        const allFilter = ecfmState.filter;

        if (allFilter.length > 0) {
            let kpiObj = {};
            allFilter.map(item => {
                if (item.key === "kpi") kpiObj = item
            })
            const kpiValueArray = kpiObj['value']
            let kpiValueArr = []
            kpiValueArray.map((item, idx) => {
                kpiValueArr[idx] = item['kpi']
            })

            this.impPlanTypeArr = [...new Set(kpiValueArr)];
        }
    }



    saveDate = (payload) => {
        this.props.saveCadenceBoardData(payload);
    }




    render() {
        const { authReducer } = this.props
        const { dateStatusSelected, planTypeValue } = this.state
        const menuData = authReducer.menuData
        return (
            <div className="cadenceBoard mt-20">
                <div className='custom-tab' style={{ "width": "70rem" }}>
                    <CadenceComponent dateStatusSelected={dateStatusSelected} menuData={menuData} fetchCadenceBoardData={this.props.fetchCadenceData} saveDate={this.saveDate} planTypeValue={planTypeValue} />
                </div>

            </div>

        )
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state.dashboardState.receivableData.ageingData,
                authReducer: state.authReducer,
                ecfmState: state.ecfmState
            }
        },
        dispatch => {
            return {
                fetchFCFFilter(data) {
                    dispatch({
                        type: 'FETCH_FCF_FILTER',
                        payload: data,
                    });
                },
                fetchFCFImpactAnalysis() {
                    dispatch({
                        type: 'FETCH_FCF_IMPACT_ANALYSIS',
                        // payload: data,
                    });
                },
                getFormPlanId(data) {
                    dispatch({
                        type: "FETCH_CREATE_PLAN_ID_DATA",
                        payload: data,
                    });
                },

                saveCreatePlanData(data) {
                    dispatch({
                        type: "SAVE_PLAN_FORM_DROPDOWN_DATA",
                        payload: data,
                    });
                },
                saveCadenceBoardData(data) {
                    dispatch({
                        type: 'UPDATE_STATUS_KPI_PLANNING',
                        payload: data,
                    });
                },
                fetchCadenceBoardData(data) {
                    dispatch({
                        type: 'FETCH_CADENCE_BOARD_DATA',
                        payload: data,
                    });
                },
            };
        }
    )(CadenceBoardMaster);