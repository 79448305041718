import React, { useEffect, useState } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';



const CTCStringFilterLayout = ({ columnData, defaultFilterValue, onFilterChange, onClose, options, optionLabel }) => {
    const [data, setData] = useState({
        type: 'string',
        condition: [],
        caseSensitive: true,
        value: [],
    });
    const [optionSelected, setOptionSelected] = React.useState([]);



    const applyFilter = () => {
        onFilterChange(optionSelected);
        onClose && onClose();
    }

    const clearFilter = () => {
        setOptionSelected([])
        onFilterChange([]);
        onClose && onClose();
    }

    const handleConditionChange = (event) => {
        setOptionSelected(event.target.value);
    }
    
    useEffect(()=> {
        if(defaultFilterValue){
            setOptionSelected(defaultFilterValue)
        }
    },[defaultFilterValue])


    return (
        <>
            <div className="custom-table-filter-content-section">
                <InputLabel className="demo-mutiple-name-label">{optionLabel}</InputLabel>
                <Select
                    value={optionSelected}
                    placeholder={optionLabel}
                    multiple="true"
                    onChange={handleConditionChange}
                    size="small"
                >
                    {options.map((name) => (
                        <MenuItem key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
               
            </div>
            <div className='custom-table-filter-actions-section'>
                <Button size="small" variant="contained" className="newButton btnBlue uppercase btnApply" onClick={applyFilter}>Apply</Button>
                {/* <Button size="small" variant="outlined" className="clear-btn" onClick={clearFilter}>Clear</Button> */}
            </div>
        </>
    )
};

export default CTCStringFilterLayout;