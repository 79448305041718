
import React from 'react';
import "react-table-6/react-table.css";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Grid, Box, InputLabel, MenuItem,  Select, Button } from '@material-ui/core';

export default function SimpleModal(props) {

    const handleClose = () => {
        props.modalCallBack(false);
    };

   
    const [toEmail, setToEmail] = useState([])
    const [ccEmail, setCCEmail] = useState([])
    
    const [toEmailValue, setToEmailValue] = useState([])
    const [ccEmailValue, setCcEmailValue] = useState([])

  
const handleToEmailChange = (event) => {
    setToEmailValue(event.target.value) 
}

const handleCcEmailChange = (event) => {
    setCcEmailValue(event.target.value) 
}

React.useEffect(() => {
    setToEmailValue([])
    setCcEmailValue([])

    setToEmail(props.users)
    setCCEmail(props.users)

}, [props.users]);

const handleSendEmail = (event) => {
    props.sendEmail({ to : toEmailValue, cc : ccEmailValue }) 
    props.modalCallBack(false);
}

const body = (
    <div className='customModal sendEmail-PopUp'>
        <div className='header'>
        <h2 className='text-center'>{props.header}</h2>
        <Icon onClick={() => { handleClose() }}>close</Icon>
        </div>
        <div className='modal-content pt-5'>
      
    <Grid container spacing={3}>
            <Grid item xs={12} sm={12} className='mb-10'>
          
                     <FormControl fullWidth>
                          <InputLabel>To</InputLabel>
                          <Select
                              value={toEmail.length > 0 ? toEmailValue : []}
                              label="To"
                              onChange={handleToEmailChange}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {toEmail && toEmail.length && toEmail.map(item => {
                                  return <MenuItem value={item.email} key={item.email} >{item.full_name}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>
                         
            </Grid>
            <Grid item xs={12} sm={12} className='mb-10'>
          
                        <FormControl fullWidth>
                          <InputLabel>CC</InputLabel>
                          <Select
                              value={ccEmail.length > 0 ? ccEmailValue : []}
                              label="CC"
                              onChange={handleCcEmailChange}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {ccEmail && ccEmail.length && ccEmail.map(item => {
                                  return <MenuItem value={item.email} key={item.email} >{item.full_name}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>
                         
            </Grid>
    </Grid>
    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
        <Button variant='contained' className='run-button mt-10' onClick={() => handleSendEmail() }>Submit</Button>
    </div>
    </div>
    </div>
)

return (
    <div>
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
        </div>
    );
}