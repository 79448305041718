import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from "@material-ui/core/InputLabel";
import { DropzoneArea } from 'material-ui-dropzone'
import DownloadExcel from "../../../../images/download_excel.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function UploadModal(props) {
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [reportDate, setReportDate] = React.useState(new Date());
    const [files, setFiles] = useState(true)
    const [uploadType, setUploadType] = useState(['Raw Data'])
    const [uploadTypeValue, setUploadTypeValue] = useState('')
    
    const handleChangeFile = (file) => {
        setFiles(file);
    }

    const handlePreviewIcon = () => {
        return <img src={DownloadExcel} className='pointer' height='30' />
    }

    const handleSaverole = () => {
        const formData = new FormData();
        formData.append("yearmonth", format(reportDate, "yyyy/MM"));
        formData.append("files", files[0]);
        formData.append("upload_type", 'New_pab_and_New_currency');
        props.saveCallback(formData);
    }

    const handleResetPopUp = () => {
        var currentTime = new Date() 
        setReportDate(new Date(currentTime.getFullYear(), currentTime.getMonth()-1));
        setBtnDisabled(true)
        setUploadTypeValue('')
    }

    React.useEffect(() => {
        if (files.length > 0 && reportDate != null && reportDate != 'Invalid Date')
            setBtnDisabled(false)
        else
            setBtnDisabled(true)
    }, [files, reportDate, uploadTypeValue])

    React.useEffect(() => {
        handleResetPopUp();
    }, [props.showModal]);

    const body = (
        <div className='customModal automationModal'>
            <div className='header'>
                <h2>Upload Impact Analysis File</h2>
                {props.saveLoading ?  <Icon onClick={props.modalCallBack}>close</Icon>  : ""}
            </div>
            <div className='modal-content' style={{ pointerEvents:  props.saveLoading ? 'none' : ''}}>
                {props.saveLoading ? <div className='text-center'><CircularProgress /></div> :
                    <React.Fragment>
                        <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    openTo="month"
                                    size="small"
                                    format="MMM-yyyy"
                                    views={["year", "month"]}
                                    className='maxw-200'
                                    label="Report Date"
                                    value={reportDate}
                                    // minDate={reportDate}
                                    maxDate={new Date()}
                                    onChange={setReportDate}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <FormControl className='mt-40 mb-40 w-100 maxw-200'>
                            {/* <InputLabel id="demo-simple-select-label">Please select Type of Upload</InputLabel>
                            <Select
                                value={uploadTypeValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {uploadType && uploadType.length && uploadType.map((item, i) => {
                                    return <MenuItem value={i} key={i} onClick={() => setUploadTypeValue(i)}>{item}</MenuItem>
                                })}
                            </Select> */}
                        </FormControl>
                        <DropzoneArea
                            filesLimit={1}
                            getPreviewIcon={handlePreviewIcon}
                            onChange={handleChangeFile.bind(this)}
                            acceptedFiles={['.xls', '.xlsx', '.xlsm']}
                            showFileNames={true}
                            showAlerts={false}
                            maxFileSize={500000000000}
                            dropzoneClass={'payment-dropzone'}
                        />
                        {!props.saveLoading &&
                            <>
                                <Grid container justify="flex-end" className='mt-30 mb-10'>
                                    <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                                    <Button variant="contained" onClick={props.modalCallBack}>Cancel</Button>
                                </Grid>
                                <Grid container justify="flex-end"><span><i>Note: Impact Analysis Data will be uploaded only for selected Report Date.</i></span></Grid>
                            </>
                        }
                    </React.Fragment>
                }
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={props.modalCallBack}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}