import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
    //   colors: ['#058DC7', '#DDDF00', '#50B432', '#ED561B', '#8E44AD', '#FF9655', '#FFF263', '#6AF9C4']
})
function BarChart(props) {
    return (
        <div id="column_chart_overdue" className="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'column',
                            height: 400,
                            spacingBottom: 0,
                            options3d: {
                                enabled: true,
                                alpha: 0,
                                beta: 0,
                                depth: 20,
                                viewDistance: 25
                            }
                        },
                        title: {
                            text: props.chartTitle,
                            useHTML: true,
                            align: "left",
                            margin: 25,
                            style: {
                                fontSize: "16px",   
                            }
                        },
                        subtitle: { 
                            text: props.chartSubtitle,
                            useHTML: true,
                        },
                        navigation: {
                            buttonOptions: {
                                verticalAlign: 'top',
                                useHTML: true,
                                y: -10
                            }
                        },
                        xAxis: {
                            type: 'category',
                        },
                        yAxis: {
                            title: { text: '' }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            pointFormat: '<span style="color:{point.color}" >{point.name}</span>: <b>{point.y:,.0f}</b><br/>',
                        },
                        plotOptions: {

                            series: {
                                opacity: 0.9,
                                borderWidth: 0,
                                pointPadding:0.2,
                            },
                        },
                        legend: {
                            squareSymbol: false,
                            symbolHeight: 6,
                            symbolWidth: 12,
                            symbolRadius: 0
                        },
                        series: props.chartData,
                        // [
                        //     {
                        //         // name: props.chartData.name,
                        //         // colorByPoint: true,
                        //         data: props.chartData
                        //     }
                        // ],
                        // responsive: {
                        //     rules: [{
                        //         condition: { maxWidth: 450 },
                        //     }]
                        // },
                        credits: {
                            enabled: false
                        },
                    }
                }
            />
        </div>
    )
}
export default BarChart;