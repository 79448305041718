import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import DropdownComponent from './Dropdown';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from '../../../../images/download_excel.svg';
import ReactTableComponent from './ReactTableComponent';
import {downloadExcelV1, mapExcelDataV1} from '../../../../utils/exportExcel';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Time } from 'highcharts';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
      >
        {value === index && (
            <React.Fragment>{children}</React.Fragment>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export class DayReportComponent extends React.Component {

  constructor(props) {
    super(props);
    this.buMap = {
      0: 'BU 1',
      1: 'BU 2',
      2: 'BU 3',
      3: 'BU 4',
    };
    this.state = {
      externalTabValue: 0,
      month: props.dashboardState.selectedDate,
     
      filterPayload: {
        action_owner: [],
        aging_category: [],
        yearmonth: [],
        bu: [],
        document_number: [],
        category: '',
        customer_name: [],
        skip: 0,
        limit: 10,
        export: false,
        reqTime: '',
        // tabValue:0,
        openInProgressModal: false,
        company_code: [],
        data_type : "Billed",
      },
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    const datelen = this.props.dashboardState.reportDateArr &&
        this.props.dashboardState.reportDateArr.length;
    const updatedMonth = this.props.dashboardState.reportDateArr[datelen > 0 ?
        datelen - 1 :
        0];
    this.setState({month: updatedMonth});
    this.fetchReportDataFn();
    this.props.getAllUser({});
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.invoiceListReportState, prevProps.invoiceListReportState, "cekProps")
    if (prevProps.invoiceListReportState.export && !this.props.invoiceListReportState.export) {
      let newFilterPayload = {
        action_owner: [],
        aging_category: [],
        yearmonth: [],
        bu: [],
        document_number: [],
        category: '',
        customer_name: [],
        skip: 0,
        limit: 10,
        export: false,
        reqTime: '',
        openInProgressModal: false,
        company_code: [],
        data_type :  this.state.filterPayload.data_type
      };
      this.setState({openInProgressModal: false, filterPayload : newFilterPayload});
    }
    // const {hasFilterData} = this.state;
    // const {externalData} = this.props;
    // const {external} = this.props.ageingData;
    // if (!hasFilterData && external.fetched) {
    //   const filterArr = externalData && externalData.filterObj &&
    //       externalData.filterObj.data;
    //   this.setState({externalfilterArr: filterArr, hasFilterData: true});
    // }
  }

  fetchReportDataFn = () => {
    const {filterPayload, month, externalTabValue} = this.state;
    const payload = {
      ...filterPayload,
      report_date: month,
      receivableTabSelectorValue: this.props.tabSelectorValue
    };
    this.props.fetchInvoiceListReportData(payload);
    // if(payload.export != true){
      this.props.fetchInvoiceListFilterData(payload);
    // }
  };

  // handleTabValueChange = (event, newValue) =>{
  //   this.setState({tabValue: newValue});
  // }

  handlePageChange = data => {
    if(this.state.filterPayload.export == false){
      this.setState(prevState => ({
        ...prevState,
        filterPayload: {
          ...prevState.filterPayload,
          skip: data,
        },
      }), () => this.fetchReportDataFn());
    }
    
  };

  // handleExternalTabChange = (event, newValue) => {

  //   let newFilterPayload = {
  //     action_owner: [],
  //     aging_category: [],
  //     yearmonth: [],
  //     category: '',
  //     customer_name: [],
  //     skip: 0,
  //     limit: 10
  //   };

  //   this.setState(
  //       {externalTabValue: newValue, filterPayload: newFilterPayload},
  //       () => {
  //         this.fetchReportDataFn();
  //       });
  // };

  onMonthChange = () => {
    this.child.current.handleResetFilter();
  };
   
  handleMonthChange = (event) => {
    if (event.target && this.state.month != event.target.value) {
      console.log()
      let newFilterPayload = {
        action_owner: [],
        aging_category: [],
        yearmonth: [],
        company_code: [],
        bu: [],
        document_number: [],
        category: '',
        customer_name: [],
        skip: 0,
        limit: 0,
        data_type : this.state.filterPayload.data_type
      };

      this.onMonthChange();
      this.setState({month: event.target.value});

      this.handleApplyFilter(newFilterPayload);
      this.setState(() => {
        this.props.updateSelectedDate(event.target.value);
        this.fetchReportDataFn();
      });
    }
  };

  handleApplyFilter = (payload) => {
    this.setState({
      filterPayload: payload,
    }, () => {
      this.fetchReportDataFn();
    });
  };

  mapUser = (data) => {
    let userData = this.props.externalData.allUser;
    data = data.map(x => {
      let label = userData.find(y => {
        return y.login_id == x.action_owner;
      });

      if (label != undefined)
        x.action_owner = label.full_name;

      label = userData.find(y => {
        return y.login_id == x.collection_forecast_confirmed_by;
      });

      if (label != undefined)
        x.collection_forecast_confirmed_by = label.full_name;

      return x;
    });
    return data;
  };

  handleDownloadExcel = () => {
    // const {
    //   invoiceListReportState,
    // } = this.props;
    // let header = JSON.parse(
    //     JSON.stringify(invoiceListReportState.tableRowHeader));
    // header = header.filter((x) => {
    //   let exclude = ['collection_forecast', 'improvement_plan'];
    //   return exclude.indexOf(x.key) == -1;
    // });
    // let data = JSON.parse(JSON.stringify(invoiceListReportState.tableRowData));
    // let ops = {};
    // ops.dataType = {5: 'number', 8: 'number', 9: 'number'};
    // const {externalTabValue} = this.state;
    // let bu = this.buMap[externalTabValue];
    // let datax = mapExcelDataV1(this.mapUser(data), header, undefined,
    //     ops.dataType);
    // header = header.map(x => {
    //   return x.label;
    // });
    // var filename = ' Receivables - Invoice Reports';
    // ops.fileName = filename;
    // ops.columns = header;
    // ops.data = datax;
    // ops.alignment = {13: {wrapText: true}};
    // downloadExcelV1(ops);
    // this.setState({openInProgressModal: false});
    let newDate = new Date()

    const {filterPayload, month, externalTabValue} = this.state;
    // const newFilterPayload = {
    //   ...filterPayload,
    //   report_date: month,
    // };
    let newFilterPayload = {
        action_owner: filterPayload.action_owner,
        aging_category: filterPayload.aging_category,
        yearmonth: filterPayload.yearmonth,
        company_code: filterPayload.company_code,
        bu: filterPayload.bu,
        document_number: filterPayload.document_number,
        category: filterPayload.category,
        customer_name: filterPayload.customer_name,
        sold_to_country: filterPayload.sold_to_country,
        revised_classification_new: filterPayload.revised_classification_new,
        skip: 0,
        limit: 0,
        export : true,
        data_type :  filterPayload.data_type
      };
      this.setState({
        openInProgressModal: true,
      }, () => {
        this.handleApplyFilter(newFilterPayload);
      });
     
      // this.setState(() => {
      //   this.setState({openInProgressModal: true});
      //   this.fetchReportDataFn();
      // });
       

  };
  handleRadioChange = (event) => {
    let newFilterPayload = {
      action_owner: [],
      aging_category: [],
      yearmonth: [],
      company_code: [],
      bu: [],
      document_number: [],
      category: '',
      customer_name: [],
      skip: 0,
      limit: 10,
      data_type : event.target.value
    };
    this.onMonthChange();
    this.handleApplyFilter(newFilterPayload);
     

};
  render() {
    const {
      dashboardState,
      invoiceListReportState,
    } = this.props;
    const {month, externalTabValue, openInProgressModal} = this.state;
    const arrayDate = dashboardState.reportDateArr;
    const newArray = [];
    const idx = arrayDate.length;
    let Newidx = 0;
    for (var keys in arrayDate) {
      Newidx++;

      newArray.push(arrayDate[(idx - Newidx)]);
    }
    const datelen = dashboardState.reportDateArr &&
        dashboardState.reportDateArr.length;
    // console.log("tabvaluee - ", this.state.tabValue);
    // const updatedMonth = dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]

    return (
        <React.Fragment>
          <div className="flex ai-center jc-space-between mb-10">
            <FormControl className="w-200">
              <Select
                  // value={month || updatedMonth}
                  value={month}
                  onChange={this.handleMonthChange}
                  MenuProps={{onExited: () => document.activeElement.blur()}}
              >
                {newArray && newArray.length &&
                newArray.map((item, index) => {
                  return <MenuItem value={item} key={index}>{item}</MenuItem>;
                })
                }
              </Select>
            </FormControl>

            {/* {this.props.tabSelectorValue == 1 ? <Tabs value={tabValue} onChange={this.handleTabValueChange} indicatorColor="primary" textColor="primary" className='blockTabs'>
                <LinkTab value={2} label="Default" {...a11yProps(2)} /> 
                <LinkTab value={1} label="Contract Liability" {...a11yProps(1)} />   
            </Tabs> : ''} */}

            <Tooltip title={'Download to Excel'}>
              <img
                  src={DownloadExcel}
                  className="pointer"
                  height="30"
                  alt="download excel"
                  onClick={(event) => {
                    event.preventDefault();
                    // 
                    // setTimeout(() => {
                      this.handleDownloadExcel();
                    // }, 10);
                  }}/>
            </Tooltip>
          </div>
          <div>
          <React.Fragment>
                                <FormControl component="fieldset">
                                    <RadioGroup name="radio" value={this.state.filterPayload.data_type} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
                                    <FormControlLabel value="Billed" control={<Radio color="primary" />} label="Billed" />
                                    <FormControlLabel value="Unbilled" control={<Radio color="primary" />} label="Unbilled" />
                                    {this.props.tabSelectorValue == 1 ? <FormControlLabel value="Liability" control={<Radio color="primary" />} label="Contract Liability" /> : ""}
                                       
                                    </RadioGroup>
                                </FormControl>
                                </React.Fragment>
            </div>
          {/* <Tabs
                    value={externalTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    className='mb-10'
                    onChange={this.handleExternalTabChange}
                >
                    <Tab label="MSMT" className="list-nested" id='nav-tab-0' aria-controls='nav-tabpanel-0' />
                    <Tab label="LCS" className="list-nested" id='nav-tab-1' aria-controls='nav-tabpanel-1' />
                    <Tab label="DGS" className="list-nested" id='nav-tab-2' aria-controls='nav-tabpanel-2' />
                    <Tab label="PAB" className="list-nested" id='nav-tab-3' aria-controls='nav-tabpanel-3' />
                </Tabs>
                <TabPanel value={externalTabValue} index={0}>
                </TabPanel>
                <TabPanel value={externalTabValue} index={1}>
                </TabPanel>
                <TabPanel value={externalTabValue} index={2}>
                </TabPanel>
                <TabPanel value={externalTabValue} index={3}>
                </TabPanel> */}
          <DropdownComponent
              externalfilterArr={invoiceListReportState.dayFilterArr}
              handleApplyFilter={this.handleApplyFilter}
              parentGroup={''}
              buValue={this.buMap[externalTabValue]}
              dataType ={this.state.filterPayload.data_type}
              ref={this.child}
          />

          {this.state.filterPayload.data_type =="Contract Liability" ? <></>: 
          <ReactTableComponent
              buValue={this.buMap[externalTabValue]}
              handleRefresh={this.fetchReportDataFn}
              onPageChange={this.handlePageChange}
          />  }
          {this.state.filterPayload.data_type =="Contract Liability" ? <></>: <Modal
              aria-labelledby="download-in-progress"
              aria-describedby="download-in-progress"
              open={openInProgressModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
          >
            <Fade in={openInProgressModal}>
              <div className="customModal" style={{width: '300px'}}>
                <div className="header">Download in Progress</div>
                <div className="modal-content">
                  <p className="text-center">Please wait while the file is being
                    downloaded.</p>
                </div>
              </div>
            </Fade>
          </Modal>}
        </React.Fragment>
    );
  }
}

export default connect(
    state => {
      return {
        summaryState: state.dashboardState.receivableData.ageingData.summary,
        dashboardState: state.dashboardState,
        invoiceListReportState: state.invoiceListReportState,
        externalData: state.dashboardState.receivableData.ageingData.external,
      };
    },
    dispatch => {
      return {
        updateSelectedDate(data) {
          dispatch({
            type: 'UPDATE_SELECTED_DATE',
            payload: data,
          });
        },
        fetchInvoiceListReportData(data) {
          dispatch({
            type: 'FETCH_INVOICE_LIST_REPORT_DATA',
            payload: data,
          });
        },
        fetchInvoiceListFilterData(data) {
          dispatch({
            type: 'FETCH_INVOICE_LIST_FILTER_DATA',
            payload: data,
          });
        },
        getAllUser(data) {
          dispatch({
            type: 'FETCH_ALL_USER',
            payload: data,
          });
        },
      };
    },
)(DayReportComponent);
