import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function Table() {
  const columns = [
    {
      Header: "",
      accessor: "name"
    },
    {
      Header: "Trailing KPIs",
      columns: [
        {
          Header: "UOM",
          accessor: "uom"
        },
        {
          Header: "Q3",
          accessor: "q3KPI"
        },

        {
          Header: "Q4",
          accessor: "q4KPI"
        },
        {
          Header: "Q1",
          accessor: "q1KPI"
        },
      ]
    },
    
    {
      Header: "Forward View",
      columns: [
        {
          Header: "Q2",
          accessor: "q2View"
        }
      ]
    },
    {
      Header: "Current QTR",
      columns: [
       {
          Header: "Q3",
          accessor: "q3QTR"
        }
      ]
    },
    {
      Header: "",
      accessor: "change",
      width: 170,
      className: "dropdown-column",
      Cell: (props) => {
      return (
          <FormControl variant="outlined" className="selectOutlineSmall"
            fullWidth size="small">
            <InputLabel>Select Change</InputLabel>
            <Select
              label="Select Change"
            >

              <MenuItem value="">
                <em>Select Change</em>
              </MenuItem>
            </Select>
          </FormControl>
      )
  }
    }
  ];

  var dummyData = [
    {name: "Revenue", uom: "$M", q3KPI: 120000  , q4KPI: 90, q1KPI : 100, q2View: 110, q3QTR : 120, change: null},
    {name: "EBITDA", uom: "% Of Rev", q3KPI: 24000  , q4KPI: "15%", q1KPI : "20%", q2View: "21%", q3QTR : "20%", change: null},
    {name: "AR", uom: "DSO", q3KPI: 12000  , q4KPI: 90, q1KPI : 85, q2View: 100, q3QTR : 100, change: null},
    {name: "Inventory", uom: "% Of Rev", q3KPI: 12000  , q4KPI: "15%", q1KPI : "20%", q2View: "21%", q3QTR : "20%", change: null},
    {name: "AP", uom: "Days to Pay", q3KPI: 18000  , q4KPI: 30, q1KPI : 30, q2View: 35, q3QTR : 30, change: null},
    {name: "FCF", uom: "$M", q3KPI: 9000  , q4KPI: 16, q1KPI : 20, q2View: 15, q3QTR : 15, change: null},
  ];

  return (
      <ReactTable
          columns={columns}
          data={dummyData}
          minRows={0}
          showPagination={false}
          className="-striped -highlight simpleCustomTable"
      />
  )
}

export default Table;
