import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import AnalysisTnEComponent from './AnalysisTnEComponent';
import AdhocChart from './AdhocChart';
import RevenueByRegion from './RevenueByRegion';
import { useHistory, Link as Linkroute } from "react-router-dom";
import { getAccessByMenuKey } from "../../../utils/userManagement";

export function DashboardComponents(props) {
    const history = useHistory();
    const costAnalysisArray = ["K01a - Overtime Salaries", "K02 - Benefits", 'K06 - Supplies, Equipment & Telecom', 'K08 - Outside Services & Professional Fees', 'K10 - Travel & Entertainment', 'K12 - Occupancy & Facilities'];
    const adHocChartsArray = ['New BU 1 Service Revenue by Region', 'New BU 1 Service Revenue and Std Margin - NA'];
    const { isCost } = props
    const [openTnE, setOpenTnE] = React.useState(false);
    const [openAdhoc, setOpenAdhoc] = React.useState(false);
    const [openAdhocRegion, setOpenAdhocRegion] = React.useState(false);
    const [reportType, setReportType] = React.useState('');
    const handleTnEPage = (value) => {
        let report_type = value;
        // report_type = report_type.includes('&') ? report_type.replace('&', '%26') : report_type;

        setOpenTnE(!openTnE);
        setReportType(report_type);
    }

    const handleAdhocPage = (item) => {
        switch (item) {
            case 'New BU 1 Service Revenue and Std Margin - NA':
                setOpenAdhoc(!openAdhoc);
                break;
            case 'New BU 1 Service Revenue by Region':
                setOpenAdhocRegion(!openAdhocRegion);
                break;
            default:
                break;
        }
    }

    const handleBack = () => {
        setOpenAdhoc(false)
        setOpenTnE(false)
        setOpenAdhocRegion(false)
    }

    return (
        <div>
            {openTnE ?
                (
                    <AnalysisTnEComponent handleBack={handleBack} reportType={reportType} />
                )
                :
                openAdhoc ?
                    (< AdhocChart handleBack={handleBack} />)
                    :
                    (openAdhocRegion ?
                        (
                            <RevenueByRegion handleBack={handleBack} />
                        ) :
                        (
                            <div className='blockDesignSidebar'>
                                <div>
                                    <Grid container spacing={2} >
                                        {isCost ? <div>
                                            {getAccessByMenuKey(props.menuData, "cost_analysis", "view") &&
                                                <Grid item sm={12} xs={12}>
                                                    <Accordion expanded>
                                                        <AccordionSummary className="accordion-head">
                                                            <Typography>COST ANALYSIS</Typography>
                                                        </AccordionSummary>
                                                        {costAnalysisArray && costAnalysisArray.length && costAnalysisArray.map(item => {
                                                            return <AccordionDetails className='accordion-item pointer' onClick={() => { handleTnEPage(item) }}>{item}</AccordionDetails>
                                                        })}
                                                    </Accordion>
                                                </Grid>
                                            }</div> :
                                            <div>
                                                {getAccessByMenuKey(props.menuData, "adhoc_chart", "view") &&
                                                    <Grid item sm={12} xs={12}>
                                                        <Accordion expanded>
                                                            <AccordionSummary className="accordion-head">
                                                                <Typography>AD HOC CHARTS </Typography>
                                                            </AccordionSummary>
                                                            {adHocChartsArray && adHocChartsArray.length && adHocChartsArray.map(item => {
                                                                return <AccordionDetails className='accordion-item pointer' onClick={() => { handleAdhocPage(item) }} >{item}</AccordionDetails>
                                                            })}
                                                        </Accordion>
                                                    </Grid>
                                                }
                                            </div>
                                        }


                                    </Grid>
                                </div>
                            </div>
                        )
                    )

            }


        </div>
    )

}