import React from 'react';
import { Button, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { getAccessByMenuKey } from '../../../../utils/userManagement';
import './index.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function SavedDataTable(props) {
	const history = useHistory();
	const [tableHeader, setTableHeader] = React.useState([]);
	const [showTable, setShowTable] = React.useState(true);
	const freeCashFlowState = useSelector((state) => state.freeCashFlowState);
	const filterArray = freeCashFlowState.analyticStudio.filter;
	const analyticStudioData = freeCashFlowState.analyticStudio;
	const thisTableData = [];
	const thisTableHeader = [];
	const savedDataReport = analyticStudioData.savedDataReport;
	const metaDataArray = freeCashFlowState.metaData;

	const savedData = savedDataReport.row_data ? savedDataReport.row_data : [];
	const headerData = savedDataReport.row_header
		? savedDataReport.row_header
		: [];

	const deleteSavedData = (data) => {
		const obj = { _id: data };
		toastr.confirm('Delete Saved Data?', {
			onOk: () => props.deleteSavedData(obj),
		});
	};

	const handleNewButton = () => {
		setShowTable(false);
		history.push('/dashboard/analytics-studio/data-report');
	};

	const redirectToEditPage = (reportId) => {
		history.push(`/dashboard/analytics-studio/data-report?id=${reportId}`);
	};

	const obj = {
		Header: 'Actions',
		id: 'buttons',
		accessor: 'buttons',
		Cell: (thisProps) => (
			<div>
				{getAccessByMenuKey(props.menuData, 'analytics_studio', 'edit') && (
					<Tooltip title={'Edit'}>
						<EditIcon
							className='icon-table'
							onClick={() => {
								redirectToEditPage(thisProps.original._id);
								// handleOpenTab(thisProps.original);
							}}
						/>
					</Tooltip>
				)}
				{getAccessByMenuKey(props.menuData, 'analytics_studio', 'delete') && (
					<Tooltip title={'Delete'}>
						<DeleteIcon
							className='icon-table'
							onClick={() => {
								deleteSavedData(thisProps.original._id);
							}}
						/>
					</Tooltip>
				)}
			</div>
		),
	};

	thisTableHeader.push(obj);

	const changeKeysOfHeader = (data) => {
		if (data.length) {
			return data.map((item, index) => {
				return {
					Header: item.label,
					id: item.key,
					accessor: item.key,
				};
			});
		}

		return [];
	};

	let tableHeaderData = changeKeysOfHeader(headerData);
	tableHeaderData.push(obj);
	let tableRowData = savedData;

	const handleBackFCFImpact  = () => {
        window.location.assign('/dashboard/free-cashflow-analytics/free-cashflow')
      }
	  let backFCF = localStorage.getItem('show-back-fcf')
	return (
		<Grid container justifyContent='center' spacing={2}>
			<Grid item xs={12} sm={8}>
				<div className='top-header'>
				{ backFCF == 'true' ?
              			<div >
							<Tooltip title="Back to Improve KPIs and Free Cashflow">
								<Button variant='contained' color="primary" className='newButton btnBlue' onClick={ (event) => {
									event.preventDefault();
									handleBackFCFImpact()
								}}><ChevronLeftIcon /></Button>
							</Tooltip>
						</div> : ''
          		}

					<div>
						<b>Analytics Studio Report</b>
					</div>
					{getAccessByMenuKey(props.menuData, 'analytics_studio', 'add') && (
						<Button
							onClick={handleNewButton}
							variant='contained'
							className='newButton btnBlue btnCreate uppercase'
							color='primary'
						>
							Create New Report
						</Button>
					)}
				</div>
			</Grid>
			<Grid item xs={8} className='analyticStudioTable-container'>
				<ReactTable
					columns={tableHeaderData}
					data={tableRowData}
					// loading={loading}
					defaultPageSize={10}
					// pageSize={tableRowData.length > 5 ? tableRowData.length : 5}
					showPagination={true}
					className='-striped -highlight xpna-react-table'
				/>
			</Grid>
		</Grid>
	);
}
