import React from 'react';
import DashboardIcon from "../../../images/KuwaitAirways/icon_dashboard.png";
import OperationalIcon from "../../../images/KuwaitAirways/icon_operational.png";
import AnalyticsIcon from "../../../images/KuwaitAirways/icon_analytics.png";
import AIIcon from "../../../images/KuwaitAirways/icon_ai.png";
import KeyIcon from "../../../images/KuwaitAirways/icon_key.png";
import DatasourcesIcon from "../../../images/KuwaitAirways/icon_datasources.png";

import ProfitIcon from "../../../images/KuwaitAirways/profit_icon.png";
import { Link as Linkroute } from "react-router-dom";
// import "./index.scss";

const panelMenuArray = [
  {
    id: 1,
    text: 'Dashboard',
    subText: '',
    link: '/route-profitability/dashboard',
    icon: DashboardIcon,
    show: true,
    key: "dashboard"
  },
  {
    id: 2,
    text: 'Operational Parameters',
    subText: '',
    link: '/route-profitability/operational',
    icon: OperationalIcon,
    show: true,
    key: "operational"
  },
  {
    id: 3,
    text: 'Analytics',
    link: '/route-profitability/analytics',
    icon: AnalyticsIcon,
    show: true,
    key: "analytics"
  },
  {
    id: 4,
    text: 'Load Factor AI',
    subText: '',
    link: '/route-profitability/ai',
    icon: AIIcon,
    show: true,
    key: "ai"
  },
  {
    id: 5,
    text: 'Key Initiatives',
    link: '/route-profitability/key-initiatives',
    icon: KeyIcon,
    show: true,
    key: "key-initiatives"
  },
  {
    id: 6,
    text: 'Datasources',
    link: '/route-profitability/datasources',
    icon: DatasourcesIcon,
    show: true,
    key: "datasources"
  }
]

function Panel(props) {

    const setBreadcrumbsList = (data)=>{
        let breadcrumbs = []
        breadcrumbs.push({text:data.text, link : data.link})
        breadcrumbs.push(data.defaultBreadcrumbs)
        props.setBreadcrumbs(breadcrumbs);
    }

    return (
        <div className="panel-page">
            <div className="header-panel">
                Maximize  Route Profitability using single source of truth data, AI, initiatives <br/>
                and collaboration between revenue management, fleet operations and finance teams
            </div>
            <div className="box-list">
                 {
                    panelMenuArray && panelMenuArray.map((item, index) => (
                        <Linkroute to={item.link} className="box" onClick={()=>{props.setShowMenu(true); props.setActiveMenu(item.text);}}>
                            <div className="box-icon"><img src={item.icon} className="icon-panel"/></div>
                            <div className="box-title">{item.text}<br/>{item.subText && item.subText}</div>
                        </Linkroute>
                    ))  
                  }
            </div>
        </div>
    )
}

export default Panel;