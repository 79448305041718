import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas } from '../../../../../utils/numberUtils'

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
})

Highcharts.wrap(Highcharts.Axis.prototype, 'translate', function (proceed) {
    // Normal Translation
    var result = proceed.apply(this, [].slice.call(arguments, 1));

    // Apply curving
    var line = 500000,
        scaleDown =10  //  0.5=2 times tighter, 
                         // 0.25=4 times, etc.
    if (this.options.curvature) {
        var val = ((arguments[1] - this.min) / (this.max - this.min));

        if (arguments[1] < line) { //low
            val = val * scaleDown;
        } else { //high
            var plus = (line - this.min) * (1 - scaleDown);
            var scaleUp = ((arguments[1] - line) / (this.max - line));
            val = val - (((1 - scaleUp) * plus) / (this.max - this.min));
        }
        result = (this.len * val);   
        if (arguments[2] == 0) {
            result = this.len - result;
        }
    }
    return result;
});

function BarChart(props) {

    return (
        <div id="column_chart_overdue" className="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'scatter',
                            height: 400,
                            width: props.width,
                            options3d: {
                                enabled: true,
                                alpha: 0,
                                beta: 0,
                                depth: 20,
                                viewDistance: 25
                            }
                        },
                        title: {
                            text: props.chartTitle,
                            useHTML: true,
                            align: "left",
                            margin: 25,
                            style: {
                                fontSize: "16px",   
                            }
                        },
                        navigator: {
                            enabled: true
                        },
                        subtitle: { 
                            text: props.subTitle,
                            style: {
                                fontWeight: "bold",
                                fontSize: "14px"   
                            }
                        },
                        xAxis: {
                            min: 90,
                            title: {text : "Overdue Days",margin: 20},
                            tickInterval: 90,
                        },
                         yAxis: {
                            title: {text : "$ Values"},
                            min: -124368,
                            max: 8000000,
                            tickInterval: 2000000,
                            curvature: true,
                            tickPositions: [-124368, 20000,50000,100000,300000,500000, 2000000, 4000000, 6000000,8000000]
                        },
                        tooltip: {
                            useHTML: true,
                            backgroundColor: "#fff",
                            padding:1,
                            style: {
                              zIndex: 9999,
                            },
                            formatter: function () {
                               let index = this.point.index
                               let dataTooltips = this.series.data[index].options.tooltip
                                let buLabel = "BU"
                                let regionLabel = "Region"
                                if(props.tabSelectorValue == 1){
                                    buLabel = "New BU"
                                 regionLabel = "Sold To Country"
                                }
                               return '<div class="highcharts-tooltip>"<span>' + buLabel+ ' </span>: <b>' + dataTooltips.bu + ' </b><span style="margin-left:50px;">' + regionLabel+ ' </span>: <b>' + dataTooltips.region + '</b><br/> '+
                                      '<span>Company Code</span>: <b>' + dataTooltips.company_code + '</b><br/> '+
                                      '<span>Customer Id</span>: <b>' + dataTooltips.customer + '</b><br/> '+
                                      '<span>Customer Name</span>: <b>' + dataTooltips.customer_name + '</b><br/> '+
                                      '<span>Current Month Overdue Amount</span>: $ <b>' + numberWithCommas(Number.parseFloat(this.series.data[index].options.y).toFixed(0)) + '</b><br/> '+
                                      '<span>Last Month Overdue Amount</span>: $ <b>' + numberWithCommas(Number.parseFloat(this.series.data[index].options.prev_amt).toFixed(0)) + '</b><br/></div>'

                            }
                        },
                        plotOptions: {
                            series: {
                                opacity: 1,
                                borderWidth: 1
                            },
                        },
                        series:[
                          {
                            color: props.color,
                            name: "Data",
                            type: "scatter",
                            data: props.chartData,
                            showInLegend: false,
                            accessibility: {
                              exposeAsGroupOnly: true
                            },
                            marker: {
                              radius: 3.5
                            }
                          }
                        ],
                        credits: {
                            enabled: false
                        },
                        navigator: {
                            enabled: true
                        }
                    }
                }
            />
        </div>
    )
}
export default BarChart;