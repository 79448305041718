import React from 'react';
import Highcharts from "../../../../highcharts";
import PieChart from "highcharts-react-official";
import {  numberWithCommas } from '../../../../utils/numberUtils'

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});
//Margin by Region
// const options = {
//   chart: {
//     type: "pie"
//   },
//   title: {
//     text: 'Revenue by Region'
//   },
//   plotOptions: {
//     pie: {
//       allowPointSelect: true,
//       cursor: 'pointer',
//       dataLabels: {
//         enabled: true,
//         format: '<b>{point.name}</b>: {point.percentage:.1f} %'
//       }
//     }
//   },
//   series: [
//     {
//       data: [
//         {
//           name: 'QQ',

//           y: 100
//         },
//         {
//           name: 'QQ',
//           y: 50
//         }
//       ]
//     }
//   ],
//   credits: {
//       enabled: false
//   },
// };
class ReactTableComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            options : {
              }

        }
    }


    modifyColumnData = (columnData) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData
        return modifiedData
    }

    numberFormat = (num, digits) =>{
        var value = num < 0 ? Math.abs(num) : num
        
        var si = [
          { value: 1, symbol: "" },
          { value: 1E3, symbol: "k" },
          { value: 1E6, symbol: "M" },
          { value: 1E9, symbol: "G" },
          { value: 1E12, symbol: "T" },
          { value: 1E15, symbol: "P" },
          { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
          if (value >= si[i].value) {
            break;
          }
        }

        var correctNumber = (value / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
        return num < 0 ? "(-" + correctNumber +" )" : correctNumber
    }

    getSeries = (data)=>{
      // var colors = ["#64b3f5","#f3690b","#1679cc","#fbd851"];
      // // data.sort((a, b) => (a.name > b.name) ? 1 : -1)
      // var i = 0;
      // data.forEach(element => {
      //   element.color = colors[i];
      //   if (element.name === 'East') {
      //     element.color = "#64b3f5"
      //   }
      //   if (element.name === 'West') {
      //     element.color = "#f3690b"
      //   }
      //   i++;
      // })
      return data;
    }

    handlePieData = () => {

        let _this = this;
        const {NewPieData} = this.props;

        let seriesData = NewPieData && NewPieData.row_data && NewPieData.row_data.length ? NewPieData.row_data.reverse() : []
        let options = {
            chart: {
              type: "pie",
            },
            title: {
                text: NewPieData.title,
                align: "left",
                margin: 25,
                style: {
                    fontSize: "16px",   
                }
            },
            legend: {
                itemMarginTop: 10,
                enabled: true,
                // width:300,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                useHTML: true,
                labelFormatter: function() {
                    return '<div style="text-align: left; width:70px;float:left;">' + this.name + '</div><div style="width:70px; float:left;text-align:left;"> $ ' + _this.numberFormat(this.y,1) + '</div>';
                }     
            },
            plotOptions: {
              pie: {
                // center: ["50%", "50%"],
                // size: '100%',
                allowPointSelect: true,
                showInLegend: true,
                cursor: 'pointer',
                tooltip: {
                  pointFormatter: function (){
                           return `<b>${_this.props.seriesName}</b>: $${_this.modifyColumnData(this.y)}`
                     }
                },
                dataLabels: {
                  enabled: true,
                //   distance: -45,
                //   formatter: function (){
                //       return `<b>${this.point.name}</b>: ${_this.modifyColumnData(this.point.y)}`
                //   }
                  format: `<b>{point.name}</b>: {point.percentage:.1f} %`
                }
              }
            },
            series: [
              {
                data: _this.getSeries(seriesData)
              }
            ],
            credits: {
                enabled: false
            },
          }

          this.setState({
              options
          })
    }

    componentDidUpdate(prevProps) {

        if(prevProps.NewLoader && !this.props.NewLoader){
            this.handlePieData()
        }
    }

  render() {

    const {options} = this.state

    return (
      <PieChart highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }}/>
    )

  }
}

export default ReactTableComponent;