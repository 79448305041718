import React from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import CommentModal from './CommentModal';
import ForecastModal from './CollectionForecastModal';
import AddPipModal from './PerformanceImprovementComponent';
import ListPipModal from './PerformanceImprovementComponent/piplist';
import InvoiceDetailModal from '../Shared/InvoiceDetail';
import CircularProgress from '@material-ui/core/CircularProgress';
import {numberWithCommas} from '../../../../utils/numberUtils';
import {formatDate} from '../../../../utils/dateFormat';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import {isSocketConnectionOpen, startWS} from '../../../../utils/web-socket';
import {getAllCommentHistoryData} from '../../../../utils/commentDataUtil';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import {checkToOpenInvoiceDetail} from '../utils';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import {CONFIG} from '../../../../config';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  getAccessByMenuKey,
  getActionOwneruser,
} from '../../../../utils/userManagement';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Typography} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import NewPagination from "../../../common/components/CustomPagination";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ActionOwnerPopper = function(props) {
  return <Popper {...props} style={{width: 'fit-content'}}
                 placement="bottom-start"/>;
};

export class ExternalReceivableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.dataColumns = [];
    this.sortList = {};
    this.allUserList = [];
    this.taggedUser = [];
    this.myInterval = {};
    this.socketCollection = {};
    this.chatDataCollection = {};
    this.headerColorObj = {
      'current': '#64DD17',
      '1_30_days': '#AEEA00',
      '31_60_days': '#FFD600',
      '61_90_days': '#FFAB00',
      '91_180_days': '#ff944d',
      '180_plus_days': '#ff5c33',
    };

    this.agingCategoryArr = [
      // 'customer_name',
      '1_30_days',
      '31_60_days',
      '61_90_days',
      '91_180_days',
      '180_plus_days',
      'grand_total',
      'overdue_amount',
      'current',
    ];

    this.state = {
      showModal: false,
      showActionLoading: false,
      showLoader: false,
      invoicePayload: null,
      showModalPip: false,
      showModalListPip: false,
      showForecastModal: false,
      showInvoiceDetailModal: false,
      isCustomerNamePopup: false,
      commentRow: {},
      customerName: '',
      age: '',
      updateFlag: false,
      tableRowData: [],
      rowHeaderData: [],
      chatDataCollection: {},
      catData: this.props.externalState.pipMaster.category,
      subCatData: [],
      cleanUpPipData: false,
      stageData: this.props.externalState.pipMaster.stage,
      editModePip: false,
      viewModePip: false,
      editPipData: {},
      agingCategoryValue: '',
      imagePreview: null,
      imageName: '',
      uploadImageObj: {},
      refMenuAnchorEl: null,
    };
  }

  componentDidMount() {
    const {externalFilterObject} = this.props;
    let defaultCustomerState = externalFilterObject.customer;
    let payload = {
      action_owner: [],
      aging_category: [],
      category: '',
      customer: defaultCustomerState,
      customer_name: externalFilterObject.customer_name && externalFilterObject.customer_name.length ? externalFilterObject.customer_name : undefined,
      skip: 0,
      limit: 60,
      parent_group: this.props.parentGroup,
      receivableTabSelectorValue: this.props.receivableTabSelectorValue,
      bu: this.props.buValue,
    };
    console.log(payload,"cekPayloadLiability")
    if (defaultCustomerState && defaultCustomerState.length == 0) {
      console.log('defaultCustomerStatedefaultCustomerState', externalFilterObject)
      payload.customer_name = externalFilterObject.customer_name
    }
    this.props.getMSMDetail(payload);
    this.props.getSummaryBuData(payload);
    this.props.getFilterData(payload);
    this.myInterval = setInterval(() => {
      this.callGetAllCommentHistoryFunc(this.props.externalState.activePage,
          this.props.externalState.data.row_data,
          this.props.dashboardState.selectedDate);
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  modifyColumnData = (columnData) => {
    let modifiedData;
    modifiedData = Math.round(columnData);
    var isNegative = modifiedData < 0;
    var positiveTransformedValue = isNegative ?
        Math.abs(modifiedData) :
        modifiedData;
    modifiedData = numberWithCommas(positiveTransformedValue);
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
    modifiedData = modifiedData == '0' ? '' : modifiedData;
    return modifiedData;
  };

  handlePipSave = (data) => {
    this.props.savePipData(data);
  };

  handlePipUpdate = (data) => {
    this.props.updatePipData(data);
  };

  handleEditModePip = (data) => {
    this.setState({
      editModePip: true,
      showModalPip: true,
      editPipData: data,
      cleanUpPipData: true,
    });
  };

  handleSummaryColumn = (dataItem) => {
    const {buRowData} = this.props.summaryState;
   
    let returnString = '';
    returnString = dataItem.label;
    
    if (buRowData[dataItem.key]) {
     
      returnString = returnString + ' ' +
          this.modifyColumnData(buRowData[dataItem.key]);
    }

    if (dataItem.key == "grand_total"){
      console.log(buRowData, buRowData[dataItem.key], returnString, "checkBuRowData")
  }
    return returnString;
  };

  handleDynamicWidth = (item) => {
    let widthSize = 65;
    switch (item.key) {
      case 'percentage':
        widthSize = 90;
        break;
      case 'company_code':
        widthSize = 90;
        break;
      case 'customer':
        widthSize = 90;
        break;
      case 'grand_total':
        widthSize = 110;
        break;
      case 'liabillity':
        widthSize = 110;
        break;
      case 'customer_name':
        widthSize = 175;
        break;
      case 'overdue_amount':
        widthSize = 100;
        break;
      case 'improvement_plan':
        widthSize = 100;
        break;
      case 'collection_forecast':
        widthSize = 130;
        break;
      case 'collection_actual':
        widthSize = 110;
        break;
      case 'action_owner':
        widthSize = 150;
        break;
      default:
        break;
    }
    return widthSize;
  };

  handleFixedColumn = (item) => {
    let fixed = '';
    switch (item.key) {
      case 'project_id':
        fixed = 'left';
        break;
      case 'customer_name':
        fixed = 'left';
        break;
      case 'customer':
        fixed = 'left';
        break;
      case 'company_code':
        fixed = 'left';
        break;
      default:
        break;
    }
    return fixed;
  };

  handleChatUpdation = (gData) => {
    const {tableRowData, updateFlag} = this.state;
    const {externalState} = this.props;
    const {parentGroup, buValue, authState} = this.props;
    if (externalState.data && externalState.data.row_data &&
        externalState.data.row_data.length) {
      const rowDataSet = externalState.data.row_data;
      let indexData = -1;
      let updatedRowDataSet = rowDataSet.map((item, index) => {
        if (!item['obj'][gData.chatData[0].id]) {
          if (parentGroup == 'unbilled' && buValue == 'pab') {
            if (item.customer == gData.customerId && item.company_code ==
                gData.company_code && item.project_id == gData.project_id) {
              item['isChatConnection'] = true;
              indexData = index;
              item.commentArr.unshift(gData.chatData[0]);
              item['obj'][gData.chatData[0].id] = true;
            }
          } else {
            if (item.customer == gData.customerId && item.company_code ==
                gData.company_code) {
              item['isChatConnection'] = true;
              indexData = index;
              item.commentArr.unshift(gData.chatData[0]);
              item['obj'][gData.chatData[0].id] = true;
            }
          }

        }
        return item;
      });
      if (indexData > -1) {
        if (updatedRowDataSet[indexData].commentArr &&
            updatedRowDataSet[indexData].commentArr.length) {
          let obj = {};
          let commentModifiedArr = [];
          updatedRowDataSet[indexData].commentArr.forEach(item => {
            if (!obj[item.id]) {
            	let usr = this.props.externalState.allUser.find(x=>{ return x.login_id == item.login_id });
              if(usr){
                item.name = usr.full_name;
              }
              commentModifiedArr.push(item);
              obj[item.id] = true;
            }
          });
          updatedRowDataSet[indexData].commentArr = commentModifiedArr;
        }
      }
      this.props.updateExternalData(updatedRowDataSet);
    }
  };

  handleConnectionClose = (channelId) => {
    console.log(channelId, 'connection closed');
    if (this.socketCollection[channelId]) {
      delete this.socketCollection[channelId];
    }
  };

  getPipID = (rowData) => {
    console.log(rowData,'getPipIDgetPipID' )

    this.props.getPipIDData({bu: rowData.bu})
  };

  handleSocketConnectionError = (e) => {
    this.props.socketConnectionError(true);
  };

  establishSocketConnection = (data) => {
    if (!this.socketCollection[data.channel_customer]) {
      const socketInfo = startWS(
          data.channel_customer,
          data.customer,
          data.company_code,
          data.project_id,
          data.commentArr,
          this.handleChatUpdation,
          this.handleConnectionClose,
          '',
          this.handleSocketConnectionError,
      );
      this.socketCollection[data.channel_customer] = socketInfo;
    }
  };

  handleSendChatAttachmentFunction = (attachmentData) => {
    const sendChatObject = {
      'body': attachmentData.id,
      'type': attachmentData.type,
      'file_name': attachmentData.original_name,
    };
    this.props.uploadChatFileToggle();
    if (isSocketConnectionOpen(this.socketCollection[attachmentData.channel])) {
      this.socketCollection[attachmentData.channel].send(
          JSON.stringify(sendChatObject));
    }
  };

  handleSendChatFunction = (rowData) => {
    console.log(rowData, 'checkData2');
    let sendChatObject = {};
    if (document.getElementById(
        `outlined-basic-${rowData.channel_customer}`).value) {
      sendChatObject = {
        'body': document.getElementById(
            `outlined-basic-${rowData.channel_customer}`).value,
        'type': 'text',
        'tagged_user': this.taggedUser,
      };
      if (!isSocketConnectionOpen(
          this.socketCollection[rowData.channel_customer])) {
        delete this.socketCollection[rowData.channel_customer];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(
          this.socketCollection[rowData.channel_customer])) {
        this.socketCollection[rowData.channel_customer].send(
            JSON.stringify(sendChatObject));
        this.chatDataCollection[rowData.channel_customer] = '';
        document.getElementById(
            `outlined-basic-${rowData.channel_customer}`).value = '';
        this.taggedUser = [];
      }
    }
  };

  handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
    const {chatDataCollection} = this.state;
    const sendChatObject = {
      'body': inputValue,
      'type': 'text',
      'tagged_user': taggedUser,
    };
    if (inputValue) {
      if (!isSocketConnectionOpen(
          this.socketCollection[rowData.channel_customer])) {
        delete this.socketCollection[rowData.channel_customer];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(
          this.socketCollection[rowData.channel_customer])) {
        this.socketCollection[rowData.channel_customer].send(
            JSON.stringify(sendChatObject));
        chatDataCollection[rowData.channel_customer] = '';
        this.setState({'chatDataCollection': chatDataCollection});
      }
    }
  };

  handleChatChangeFn = (event, rowData) => {
    if (event.target && event.target.value) {
      this.chatDataCollection[rowData.channel_customer] = event.target.value;
      const valueLen = event.target.value.length;
      const targetValue = event.target.value;
      if (valueLen && targetValue[valueLen - 1] == '@') {
        this.setState({refMenuAnchorEl: event.currentTarget});
      }
    }
  };

  onImageInputHandler = (event, props) => {
    let imagePreview;
    if (event.target.files[0].type == 'application/pdf' ||
        event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      imagePreview = '';
    } else {
      imagePreview = URL.createObjectURL(event.target.files[0]);
    }
    let imageName = event.target.files[0].name;
    const {uploadImageObj} = this.state;
    uploadImageObj[props.channel_customer] = {
      imagePreview,
      imageName,
      file: event.target.files[0],
    };
    this.setState({'uploadImageObj': uploadImageObj});
  };

  handleImageOptions = (event, props) => {
    const {uploadImageObj} = this.state;
    if (event === 'approve') {
      const imageObj = uploadImageObj[props.channel_customer];
      if (imageObj) {
        const payloadData = {
          file: imageObj.file,
          id: props.channel_customer,
        };
        this.props.uploadChatFile(payloadData);
      }
    }
    if (event === 'cancel') {
      this.imagePreview = null;
    }
    if (document.getElementById('upload-icon')) {
      document.getElementById(`upload-icon`).value = '';
    }
    document.getElementById(`upload-icon${props.channel_customer}`).value = '';
    delete uploadImageObj[props.channel_customer];
    this.setState({'uploadImageObj': uploadImageObj});
  };

  handleActionOwnerChange = (data, props) => {
    this.setState({showActionLoading: true});
    data = data == null ? {login_id: ''} : data;
    const payload = {
      company_code: props.company_code,
      action_owner: data.login_id,
      // customer: Number.parseInt(props.customer),
      project_id: props.project_id,
      customer: props.customer,
      bu: props.bu,
    };
    props.action_owner = data.login_id;
    this.props.updateActionOwnerFn(payload);
  };

  handleRefMenuClose = (item) => {
    this.taggedUser.push(item.login_id);
    if (this.state.refMenuAnchorEl && this.state.refMenuAnchorEl.value) {
      this.state.refMenuAnchorEl.value += item.name || '';
    }
    this.setState({refMenuAnchorEl: null});
  };

  convertAmountData = (data) => {
    var modifiedData = Math.round(data);
    var isNegative = modifiedData < 0;
    var positiveTransformedValue = isNegative ?
        Math.abs(modifiedData) :
        modifiedData;
    modifiedData = numberWithCommas(positiveTransformedValue);

    return modifiedData;
  };

  convertAmountDataWithoutRound = (data) => {
    var modifiedData = data;

    if (modifiedData < 0 && modifiedData != -1) {
      var positiveTransformedValue = Math.abs(modifiedData);
    } else {
      var positiveTransformedValue = modifiedData;
    }
    modifiedData = numberWithCommas(positiveTransformedValue);
    return modifiedData;
  };

  handleShowCursor = (item) => {
    let cursorPointer = '';
    if (this.agingCategoryArr.indexOf(item.key) > -1) {
      cursorPointer = 'pointer';
    }

    const {parentGroup, buValue} = this.props;
    // if (parentGroup == "unbilled"){
    //   if (buValue == 'lcs' || buValue == 'pab' || buValue == 'dgs'){
    //     cursorPointer = ''
    //   }
    // }

    return cursorPointer;
  };

  changeKeysOfHeader = (data) => {
    const {authState} = this.props;
    const menus = authState.menuData;
    let bu = this.props.buValue;
    let parentGroup = this.props.parentGroup;
    let allUser = this.props.externalState.allUser;
    //default list by user BU
    let buUser = getActionOwneruser(allUser, bu);
    if (data && data.length) {
      return data.map((item) => {
        if (item.key == 'comments') {
          return {
            Header: item.label,
            id: item.key,
            headerStyle: {
              minWidth: '400px',
            },
            style: {
              width: '55px',
              whiteSpace: 'break-spaces',
              overflow: 'visible',
              minWidth: '400px',
            },
            accessor: item.key,
            Cell: (props) => (
                <div className="kcommentContainerTable">
                  <div
                      className="kcommentInputSection flex ai-center jc-space-between">
                    <TextField
                        id={`outlined-basic-${props.original.channel_customer}`}
                        label="Type your Comment"
                        name="comment"
                        placeholder="Comments"
                        multiline
                        variant="outlined"
                        size="small"
                        onChange={(event) => {
                          this.handleChatChangeFn(event, props.original);
                        }}
                    />
                    <input style={{display: 'none'}}
                           id={`upload-icon${props.original.channel_customer}`}
                           type="file"
                           onChange={(event) => this.onImageInputHandler(event,
                               props.original)}/>
                    {getAccessByMenuKey(menus, 'aging_reports', 'upload') &&
                    <Tooltip title="Attach File">
                      <IconButton color="primary" aria-label="upload picture"
                                  className="px-0 mx-0">
                        <label
                            htmlFor={`upload-icon${props.original.channel_customer}`}>
                          <Icon fontSize="small">attach_file</Icon>
                        </label>
                      </IconButton>
                    </Tooltip>
                    }
                    {getAccessByMenuKey(menus, 'aging_reports', 'add') &&
                    <Tooltip title="Send">
                      <IconButton color="primary" aria-label="upload picture"
                                  className="px-0 mx-0" onClick={() => {
                        this.handleSendChatFunction(props.original);
                      }}>
                        <Icon>send</Icon>
                      </IconButton>
                    </Tooltip>
                    }
                  </div>
                  {this.state.uploadImageObj[props.original.channel_customer] &&
                  <div className="uploadedFilePreview">
                    <img
                        src={this.state.uploadImageObj[props.original.channel_customer].imagePreview}
                        height="50" width="50"/>
                    <span>{this.state.uploadImageObj[props.original.channel_customer].imageName}</span>
                    <IconButton aria-label="approve upload"
                                onClick={() => this.handleImageOptions(
                                    'approve', props.original)}>
                      <Icon fontSize="small">check</Icon>
                    </IconButton>
                    <IconButton aria-label="cancel upload"
                                onClick={() => this.handleImageOptions('cancel',
                                    props.original)}>
                      <Icon fontSize="small">clear</Icon>
                    </IconButton>
                  </div>}
                  {props.original.commentArr &&
                  props.original.commentArr.length ?
                      <div className="kcommentArea">
                        {props.original &&
                        props.original.commentArr &&
                        props.original.commentArr.length
                            ? props.original.commentArr.map((item, index) => {
                              return (
                                  <div key={index}>
                                    <div
                                        className="date">{item.name}, {formatDate(
                                        item.timestamp)}</div>
                                    {item.type == 'image' ?
                                        <img width="100"
                                             src={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel_customer}/${item.body}`}/> :
                                        item.type == 'doc' ?
                                            <div><a
                                                href={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel_customer}/${item.body}`}>
                                              {item.file_name}
                                            </a></div> :
                                            <div dangerouslySetInnerHTML={{
                                              __html: item.body.replace(
                                                  /@\S+/gi,
                                                  '<strong class="primary-dark">$&</strong>'),
                                            }}></div>
                                    }
                                  </div>
                              );
                            })
                            : ''}
                      </div> :
                      ''}
                  <div className="commentModalIcon text-center">
                    <Tooltip title="More">
                      <Icon color="primary"
                            onClick={() => {
                              const commentPayload = {
                                company_code: props.original.company_code,
                                customer: props.original.customer,
                                bu: this.props.buValue,
                              };
                              this.setState({
                                showModal: true,
                                commentRow: props.original,
                              });
                            }}>
                        more_horiz
                      </Icon>
                    </Tooltip>
                  </div>
                </div>
            ),
          };
        } else if (item.key == 'collection_forecast') {
          return {
            Header: this.handleSummaryColumn(item),
            id: item.key,
            width: this.handleDynamicWidth(item),
            headerStyle: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
            },
            style: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
              fontSize: '11px',
              textAlign: 'center',
              cursor: 'pointer',
            },
            accessor: item.key,
            Cell: (props) => {
              const UserName = buUser.filter(x => x.login_id ==
                  props.original.collection_forecast_confirmed_by);
              return (
                  <React.Fragment>
                    <Tooltip title="Edit Collections Forecast">
                      <div className="mt-10"
                           onClick={() => {
                             this.setState({
                               showForecastModal: true,
                               commentRow: props.original,
                             });
                             this.getPipID(props.original);
                           }}>
                        <div>{formatDate(
                            props.original.collection_forecast_date,
                            'dd/MMM/yyyy')}</div>
                        <div>{UserName && UserName.length ?
                            UserName[0].full_name :
                            ''}</div>
                        <div>{this.convertAmountDataWithoutRound(
                            props.original.collection_forecast) != -1 ?
                            this.convertAmountDataWithoutRound(
                                props.original.collection_forecast) :
                            'Not Available'
                        }</div>
                      </div>
                    </Tooltip>
                  </React.Fragment>
              );
            },
          };
        } else if (item.key == 'improvement_plan') {
          return {
            Header: item.label,
            id: item.key,
            width: this.handleDynamicWidth(item),
            headerStyle: {
              whiteSpace: 'break-spaces',
              width: '70px !important',
              overflow: 'visible',
            },
            style: {
              whiteSpace: 'break-spaces',
              width: '70px !important',
              overflow: 'visible',
              fontSize: '11px',
              textAlign: 'center',
            },
            accessor: item.key,
            Cell: (props) => (
                <div className="mt-10 flex ai-center jc-space-evenly">
                  <Tooltip title="View Improvement Plan">
                    <Badge anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }} badgeContent={props.original.pip_count} color="primary">
                      <Icon
                          color={
                            props.original['pip_count'] > 0 ? 'inherit' :
                                'disabled'}
                          onClick={() => {
                            if (props.original['pip_count'] > 0) {
                              this.setState({
                                showModalListPip: true,
                                commentRow: props.original,
                                clickedBUValue: props.original.bu
                                // showLoader: true
                              });
                            }
                          }}
                      >remove_red_eye
                      </Icon>
                    </Badge>
                  </Tooltip>
                  {
                    getAccessByMenuKey(menus, 'aging_reports', 'add') &&
                    <Tooltip title={
                      props.original['61_90_days'] > 0 ||
                      props.original['91_180_days'] > 0 ||
                      props.original['180_plus_days'] > 0 ||
                      (bu == 'pab' && parentGroup == 'unbilled') ?
                          'Add Improvement Plan' :
                          'Improvement Plan enabled for Aging>60 days'
                    }>
                      <Icon color={
                        props.original['61_90_days'] > 0 ||
                        props.original['91_180_days'] > 0 ||
                        props.original['180_plus_days'] > 0 ||
                        (bu == 'pab' && parentGroup == 'unbilled') ? 'inherit' :
                            'disabled'}
                            disabled
                            onClick={() => {
                              if (
                                  props.original['61_90_days'] > 0 ||
                                  props.original['91_180_days'] > 0 ||
                                  props.original['180_plus_days'] > 0 ||
                                  (bu == 'pab' && parentGroup == 'unbilled')) {
                                this.setState({
                                  showModalPip: true,
                                  commentRow: props.original,
                                  cleanUpPipData: true,
                                  editModePip: false,
                                  clickedBUValue: props.original.bu
                                  // showLoader: true
                                });
                                this.getPipID(props.original);
                              }
                            }}
                      >add_circle_outline</Icon>
                    </Tooltip>
                  }
                </div>
            ),
          };
        } else if (item.key == 'action_owner') {
          return {
            Header: item.label,
            id: item.key,
            width: this.handleDynamicWidth(item),
            headerStyle: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
            },
            style: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
              fontSize: '11px',
              textAlign: 'center',
            },
            accessor: item.key,
            Cell: (props) => {
              let labelObj = this.allUserList.find(x => {
                return x.login_id == props.original.action_owner || x.name ==
                    props.original.action_owner || x.full_name ==
                    props.original.action_owner;
              });
              let label = labelObj == undefined || labelObj == null ?
                  '' :
                  labelObj.full_name;
              return (
                  <Autocomplete
                      // getOptionSelected={(option, value) => option.login_id == props.original.action_owner}
                      className="actionOwnerTabelSelect"
                      options={buUser}
                      size="small"
                      PopperComponent={ActionOwnerPopper}
                      getOptionLabel={(option) => option.full_name}
                      value={labelObj ? labelObj : null}
                      onChange={(event, data) => {
                        this.handleActionOwnerChange(data, props.original);
                      }}
                      renderInput={(params) => (
                          <Tooltip title={label}>
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                          </Tooltip>
                      )}
                      renderOption={(option) => <Typography
                          style={{fontSize: '12px', width: '250px'}}
                          className="flex jc-space-between minw-200 ai-center"
                          noWrap>
                        <span className="pr-10">{option.full_name}</span>
                        <span style={{
                          opacity: '0.5',
                          float: 'right',
                        }}>{option.role}</span>
                      </Typography>}
                  />
              );

            },
          };
        } else if (item.key == 'customer_name') {
          return {
            Header: this.handleSummaryColumn(item),
            id: item.key,
            width: this.handleDynamicWidth(item),
            fixed: this.handleFixedColumn(item),
            headerStyle: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
              fontSize: '11px',
              fontWeight: 'bold',
              backgroundColor: this.headerColorObj[item.key] ?
                  this.headerColorObj[item.key] :
                  '#DEE3EC',
            },
            style: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
              fontSize: '11px',
              textAlign: item.key != 'customer_name' ? 'center' : '',

              cursor: this.agingCategoryArr.indexOf(item.key) > -1 ?
                  (this.props.parentGroup == 'unbilled' && this.props.buValue ==
                  'pab' ? '' : this.handleShowCursor(item)) :
                  '',

              // cursor: this.handleShowCursor(item)

            },
            Cell: (props) => (
                <>
                  <p className="mt-0">{props.original.customer_name}</p>
                  <div className="usernameCreditBlock pointer" onClick={() => {
                    const payload = {
                      customer_name: [props.original.customer_name],
                      // company_code: props.original.company_code,
                      parent_group: this.props.parentGroup,
                      bu: this.props.receivableTabSelectorValue ==1? props.original.new_bu : props.original.bu,  
                      receivableTabSelectorValue: this.props.receivableTabSelectorValue
                    };
                    if (props.original.credit_limit != null) {
                      this.props.getCreditLimitDetail(payload);
                      this.setState({
                        invoicePayload: payload,
                        showInvoiceDetailModal: true,
                        isCustomerNamePopup: true,
                        customerName: props.original.customer_name,
                      });
                    }

                  }}>
                    {props.original.credit_limit_note != null ?
                        <div>Credit
                          Limit: {props.original.credit_limit_note}</div> :
                        props.original.credit_limit == null ?
                            <div>Credit Limit not available</div>
                            :
                            <>
                              <div>Credit Limit: ${this.convertAmountData(
                                  props.original.credit_limit)}</div>
                              <div>{props.original.credit_billed_unbilled.label}:
                                ${this.convertAmountData(
                                    props.original.credit_billed_unbilled.value)}</div>
                              <div className={props.original.credit_status < 0 ?
                                  'negative' :
                                  'positive'}>Credit Status:
                                ${this.convertAmountData(
                                    props.original.credit_status)}</div>
                            </>
                    }
                  </div>
                </>
            ),
          };
        }

        return {
          Header: this.handleSummaryColumn(item),
          id: item.key,
          width: this.handleDynamicWidth(item),
          fixed: this.handleFixedColumn(item),
          accessor: (_data) => {
            if (item.operation) {
              let modifiedData;
              let columnData = _data[item.key];

              switch (item.operation) {
                case 0:
                  modifiedData = item.key;
                  break;
                case 1:
                  modifiedData = Math.round(columnData);
                  break;
                case 2:
                  modifiedData = numberWithCommas(columnData);
                  break;
                case 4:
                  modifiedData = modifiedData < 0 ?
                      '(' + Math.abs(columnData) + ')' :
                      columnData;
                  modifiedData = modifiedData == '0' ? '' : modifiedData;
                  break;
                case 3:
                  modifiedData = Math.round(columnData);
                  modifiedData = numberWithCommas(modifiedData);
                  break;
                case 5:
                  modifiedData = Math.round(columnData);
                  modifiedData = modifiedData < 0 ?
                      '(' + Math.abs(modifiedData) + ')' :
                      modifiedData;
                  modifiedData = modifiedData == '0' ? '' : modifiedData;
                  break;
                case 6:
                  var isNegative = columnData < 0;
                  var positiveTransformedValue = isNegative ?
                      Math.abs(columnData) :
                      columnData;
                  modifiedData = numberWithCommas(positiveTransformedValue);
                  modifiedData = isNegative ?
                      '(' + modifiedData + ')' :
                      modifiedData;
                  modifiedData = modifiedData == '0' ? '' : modifiedData;
                  break;
                case 7:
                  modifiedData = Math.round(columnData);
                  var isNegative = modifiedData < 0;
                  var positiveTransformedValue = isNegative ?
                      Math.abs(modifiedData) :
                      modifiedData;
                  modifiedData = numberWithCommas(positiveTransformedValue);
                  modifiedData = isNegative ?
                      '(' + modifiedData + ')' :
                      modifiedData;
                  modifiedData = modifiedData == '0' ? '' : modifiedData;
                  break;
                default:
                  break;
              }

              return modifiedData;

            } else {
              return _data[item.key];
            }
          },
          sortMethod: (a, b) => {
            if (item.operation) {
              let valNeg = a.indexOf('(') > -1 ? -1 : 1;
              let valNegB = b.indexOf('(') > -1 ? -1 : 1;
              let val = a.split(',').join('').replace('(', '').replace(')', '');
              let valB = b.split(',').
                  join('').
                  replace('(', '').
                  replace(')', '');
              let valInt = parseInt(val);
              let valIntB = parseInt(valB);
              valInt = isNaN(valInt) ? 0 : valInt * valNeg;
              valIntB = isNaN(valIntB) ? 0 : valIntB * valNegB;
              return valInt - valIntB;
            } else {
              if (a > b) {
                return -1;
              } else if (a < b) {
                return 1;
              }
              return 0;
            }
          },
          headerStyle: {
            whiteSpace: 'break-spaces',
            width: '50px !important',
            overflow: 'visible',
            fontSize: '11px',
            fontWeight: 'bold',
            backgroundColor: this.headerColorObj[item.key] ?
                this.headerColorObj[item.key] :
                '#DEE3EC',
          },
          style: {
            whiteSpace: 'break-spaces',
            width: '50px !important',
            overflow: 'visible',
            fontSize: '11px',
            textAlign: item.key != 'customer_name' ? 'center' : '',
            cursor: this.handleShowCursor(item),
          },
        };
      });
    }
    return [];
  };

  changeValuesOfData = (data) => {
    if (data && data.length) {
      let transformedData = data.map((item) => {
        if (item.commentArr == undefined) {
          item['commentArr'] = [];
          item['isChatConnection'] = false;
          item['obj'] = {};
        }
        return item;
      });

      return transformedData;
    }
    return [];
  };

  modalCallBack = () => {
    this.setState({showModal: false});
  };

  forecastmodalCallBack = () => {
    this.setState({showForecastModal: false});
  };

  modalCallBackPip = () => {
    this.setState({showModalPip: false});
  };

  modalCallBackListPip = () => {
    this.setState({showModalListPip: false});
  };

  modalCallBackcleanUpPipData = () => {
    this.setState({cleanUpPipData: false});
  };
  invoiceModalCallBack = () => {
    this.setState({showInvoiceDetailModal: false, isCustomerNamePopup: false});
  };

  changeSubCatData = (cat) => {
    this.setState({
      subCatData: this.props.externalState.pipMaster.subcategory.filter(val =>
          val.parent_value == cat,
      ),
    });
  };

  fetchCommentData = (payload) => {
    this.props.getCommentData(payload);
  };

  filterRowHeaderData = (payload) => {
    if (this.props.parentGroup == 'unbilled')
      return payload.filter(x => {
        return x.id.toLowerCase() != 'collection_actual';
      });

    return payload;
  };

  componentDidUpdate(prevProps) {
    const {externalState, dashboardState, chatStoreState} = this.props;
    console.log(dashboardState, 'check masuk sini');
    if (!(this.state.rowHeaderData && this.state.rowHeaderData.length) &&
        externalState.data && externalState.data.row_header &&
        externalState.data.row_header.length
    ) {
      let dataColumns = this.changeKeysOfHeader(
          externalState && externalState.data && externalState.data.row_header,
      );
      dataColumns = this.filterRowHeaderData(dataColumns);
      this.setState({rowHeaderData: dataColumns});
    }
    if ((prevProps.summaryState.buRowData.business_unit !=
        this.props.summaryState.buRowData.business_unit) ||
        (prevProps.externalState.data.row_data &&
            this.props.externalState.data.row_data &&
            prevProps.externalState.data.row_data.length !=
            this.props.externalState.data.row_data.length)
    ) {
      let dataColumns = this.changeKeysOfHeader(
          externalState && externalState.data && externalState.data.row_header,
      );
      dataColumns = this.filterRowHeaderData(dataColumns);
      this.setState({rowHeaderData: dataColumns});
    }
    if (prevProps.dashboardState.loading != this.props.dashboardState.loading) {
      let dataColumns = this.changeKeysOfHeader(
          externalState && externalState.data && externalState.data.row_header,
      );
      dataColumns = this.filterRowHeaderData(dataColumns);
      this.setState({rowHeaderData: dataColumns});
    }
    if ((prevProps.summaryState.buRowData.overdue_amount !=
        this.props.summaryState.buRowData.overdue_amount)
    ) {
      let dataColumns = this.changeKeysOfHeader(
          externalState && externalState.data && externalState.data.row_header,
      );
      dataColumns = this.filterRowHeaderData(dataColumns);
      this.setState({rowHeaderData: dataColumns});
    }
    if (externalState.activePage !=
        prevProps.dashboardState.receivableData.ageingData.external.activePage) {
      this.callGetAllCommentHistoryFunc(externalState.activePage,
          externalState.data.row_data);
    }
    if (externalState.selectedPipData.BsonID !=
        prevProps.externalState.selectedPipData.BsonID) {
      this.modalCallBackPip();

      if (externalState.selectedPipData.BsonID != undefined) {
        let payload = {
          action_owner: [],
          aging_category: [],
          category: '',
          customer_name: [],
          skip: 0,
          parent_group: this.props.parentGroup,
          bu: this.props.buValue,
          receivableTabSelectorValue: this.props.receivableTabSelectorValue
          
        };
        this.props.getMSMDetail(payload);
      }
    }
    if (externalState.createChannel) {
      this.establishSocketConnection(externalState.newRowItem);
      this.props.updateChannelFlag(false);
    }
    if (chatStoreState.uploadFile) {
      this.handleSendChatAttachmentFunction(chatStoreState.data);
    }
    if (this.props.externalState.allUser.length) {
      this.allUserList = this.props.externalState.allUser;
    }

    if (prevProps.dashboardState.actionOwnerLoader &&
        !dashboardState.actionOwnerLoader) {
      this.setState({
        showActionLoading: false,
      });
    }

    if (prevProps.dashboardState.actionOwnerLoader && !dashboardState.actionOwnerLoader){
        this.setState({
          showActionLoading: false
        })
    }
  }

  handleRowClick = (rowData) => {

    if (rowData.original.channel_customer) {
      this.establishSocketConnection(rowData.original);
      const commentPayload = {
        channelId: rowData.original.channel_customer,
      };
      if (!this.socketCollection[rowData.original.channel_customer]) {
        this.props.fetchOneCommentDataHistory(commentPayload);
      }
    } else {

      const createChannelPayload = {
        company_code: rowData.original.company_code,
        customer: rowData.original.customer,
        customer_name: rowData.original.customer_name,
        business_unit: this.props.buValue,
        type: this.props.parentGroup,
        customer_chat_flag: true,
        project_id: rowData.original.project_id,

      };
      this.props.createNewChannel(createChannelPayload);
    }
  };

  isOpenInvoiceCondition = () => {

    const {parentGroup, buValue} = this.props;

    // if (parentGroup == "unbilled"){
    //   if (buValue == 'lcs' || buValue == 'pab' || buValue == 'dgs'){
    //     return false
    //   }
    // }

    return true;
  };

  handleColumnClick = (state, colInfo, columnDetail, etc) => {
    const {
      bu,
      customer,
      company_code,
      parent_group,
      customer_name,
    } = colInfo.original;
    const payload = {
      aging_category: columnDetail.id != 'customer_name' ? columnDetail.id : '',
      customer,
      company_code,
      parent_group: this.props.parentGroup,
      bu,
      receivableTabSelectorValue: this.props.receivableTabSelectorValue,
      skip: 0,
      limit: 5
    };
    if (checkToOpenInvoiceDetail(columnDetail.id) && this.isOpenInvoiceCondition()) {
      this.props.getInvoiceDetail(payload);
      this.setState({
        invoicePayload: payload,
        showInvoiceDetailModal: true,
        customerName: customer_name,
        agingCategoryValue: columnDetail.id != 'customer_name' ? ` - USD ${colInfo.row[columnDetail.id]}` : '',
      });

    }
  };

  columnClickFunction = (state, colInfo, abc, etc) => {
    if (colInfo) {
      return {
        onClick: (e) => {
          this.handleColumnClick(state, colInfo, abc, etc);
        },
      };
    } else {
      return {};
    }
  };

  rowClickFunction = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {

      return {
        onClick: (e) => {
          this.handleRowClick(rowInfo);
        },
      };
    } else {
      return {};
    }
  };

  callGetAllCommentHistoryFunc = (activePage, rowData, reportDate) => {
    const getCommentPayload = getAllCommentHistoryData(activePage, rowData,
        reportDate);
    this.props.fetchCommentDataHistory(getCommentPayload);
  };

  onPageChange = (data) => {
    // const { externalState } = this.props
    // this.props.updateActivePage(data);
    const {updateActivePage, externalFilterObject, parentGroup,receivableTabSelectorValue, buValue} = this.props;
    let tempExternalFilterObject = {...externalFilterObject};
    updateActivePage(data);
    tempExternalFilterObject.skip = data;
    tempExternalFilterObject.parent_group = parentGroup;
    tempExternalFilterObject.bu = buValue;
    tempExternalFilterObject.receivableTabSelectorValue = receivableTabSelectorValue;

    // let defaultCustomerState = externalFilterObject.customer;
    // let payload = {
    //   action_owner: [],
    //   aging_category: [],
    //   category: '',
    //   customer: defaultCustomerState,
    //   skip: data,
    //   limit: 60,
    //   parent_group: this.props.parentGroup,
    //   bu: this.props.buValue,
    // };
    this.props.getMSMDetail(tempExternalFilterObject);
  };

  setCommentModalData = () => {
    const {commentRow} = this.state;
    const {externalState} = this.props;
    let commentArrData = [];
    if (externalState.data.row_data && externalState.data.row_data.length) {
      commentArrData = externalState.data.row_data.filter(item => {
        if (item.channel_customer == commentRow.channel_customer) {
          return item;
        }
      });
    }
    return commentArrData && commentArrData[0];
  };

  handleForeCastSubmit = (payload) => {
    this.props.sendCollectionForecast(payload);
    this.forecastmodalCallBack();
  };

  handleInvoiceSorting = (columnId) => {
    const {invoicePayload} = this.state;

    this.sortList[columnId] = this.sortList[columnId] != undefined ?
        !this.sortList[columnId] :
        false;

    let payload = {
      ...invoicePayload,
      receivableTabSelectorValue: this.props.receivableTabSelectorValue,

    };
    payload['sort'] = {
      key: columnId,
      desc: this.sortList[columnId],
      
    };
    this.props.getInvoiceDetail(payload);
  };

  handleTablePageChange = pageNumber => {
    console.log(pageNumber);
    if (pageNumber != NaN){
      let tempPayload = this.state.invoicePayload;
      tempPayload.skip = pageNumber;
      tempPayload.receivableTabSelectorValue = this.props.receivableTabSelectorValue;

      this.setState({
        invoicePayload: tempPayload,
      }, () => this.props.getInvoiceDetail(tempPayload));
    }
  }

  handleDownloadFromBECallback = () => {
    const {invoicePayload} = this.state;

    let payload = {
      ...invoicePayload,
      export: true,
    };

    this.props.getCreditLimitDetail(payload);
  };

  render() {
    const {
      externalState,
      dashboardState,
      authState,
      invoiceDetailState,
    } = this.props;
    const {
      cleanUpPipData,
      catData,
      subCatData,
      showModal,
      showModalListPip,
      showModalPip,
      showInvoiceDetailModal,
      invoicePayload,
      isCustomerNamePopup,
      commentRow,
      age,
      tableRowData,
      rowHeaderData,
      customerName,
      stageData,
      editModePip,
      viewModePip,
      editPipData,
      agingCategoryValue,
      showActionLoading,
      showForecastModal,
      uploadImageObj,
    } = this.state;
    const commentModalData = this.setCommentModalData();
    const menus = authState.menuData;
    const externalDataLen = externalState && externalState.data &&
    externalState.data.row_data ? externalState.data.row_data.length : 0;

    let bu = this.props.buValue;
    //default list by user BU
    let buUser;
    if (this.props.externalState && this.props.externalState.allUser) {
      let allUser = this.props.externalState.allUser;
      buUser = getActionOwneruser(allUser, bu);
    }


    return (
        <div className="dayReportPage">
          {this.state.showActionLoading &&
          <div className="progressfullpage"><CircularProgress/></div>}
          {externalState.loading || dashboardState.loading ?
              <div className="progressfullpage"><CircularProgress/></div> :
              <React.Fragment>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    id="customized-menu"
                    anchorEl={this.state.refMenuAnchorEl}
                    keepMounted
                    open={Boolean(this.state.refMenuAnchorEl)}
                    onClose={this.handleRefMenuClose}
                >
                  {
                    buUser && buUser.length &&
                    buUser.map((item, index) => {
                      return <MenuItem
                          value={item.full_name}
                          key={index}
                          onClick={() => this.handleRefMenuClose(item)}
                      >
                        <div className="flex jc-space-between w-100 ai-center">
                          <span className="pr-10">{item.full_name}</span>
                          <span style={{
                            opacity: '0.5',
                            float: 'right',
                          }}>{item.role}</span>
                        </div>
                      </MenuItem>;
                    })
                  }
                </Menu>
                <ReactTableFixedColumns
                    data={externalState.data.row_data}
                    columns={rowHeaderData}
                    defaultPageSize={60}
                    // pageSize={externalDataLen >= 100 ? 100 : externalDataLen}
                    loading={dashboardState.loading}
                    PaginationComponent={NewPagination}
                    className="xpna-aging-table -striped -highlight"
                    onPageChange={this.onPageChange}
                    getTrProps={this.rowClickFunction}
                    getTdProps={this.columnClickFunction}
                    pages={externalState?.data?.total_count ? Math.ceil(externalState.data.total_count / 60) : 0}
                    pageSizeOptions={[10, 20, 30]}
                    style={{ height: '465px', }}
                />
                {
                  <CommentModal
                      showModal={showModal}
                      commentRowData={commentRow}
                      modalCallBack={this.modalCallBack}
                      commentsData={commentModalData}
                      userDataState={authState.userData}
                      sendCommmentCallback={this.handleSendChatModalFunction}
                      buValue={this.props.buValue}
                      onImageInputHandler={this.onImageInputHandler}
                      handleImageOptions={this.handleImageOptions}
                      uploadImageObj={uploadImageObj}
                      allUsers={this.props.externalState.allUser}
                      menuData={menus}
                  />
                }
                {
                  showForecastModal && <ForecastModal
                      showModal={showForecastModal}
                      commentRowData={commentRow}
                      modalCallBack={this.forecastmodalCallBack}
                      handleForeCastSubmit={this.handleForeCastSubmit}
                  />
                }
                {
                  showInvoiceDetailModal && <InvoiceDetailModal
                      showModal={showInvoiceDetailModal}
                      modalCallBack={this.invoiceModalCallBack}
                      invoiceDetailState={invoiceDetailState}
                      customerName={customerName}
                      agingCategoryValue={agingCategoryValue}
                      handleInvoiceSorting={this.handleInvoiceSorting}
                      parentGroup={this.props.parentGroup}
                      buValue={this.props.buValue}
                      isCustomerNamePopup={isCustomerNamePopup}
                      activePage={invoicePayload ? invoicePayload.skip ?? 0 : 0}
                      handleTablePageChange={this.handleTablePageChange}
                      handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                  />
                }
                {
                  <AddPipModal
                      showModal={showModalPip}
                      modalCallBackPip={this.modalCallBackPip}
                      userDataState={authState.userData}
                      catData={catData}
                      subCatData={subCatData}
                      changeSubCatData={this.changeSubCatData}
                      buValue={this.props.buValue}
                      customerData={commentRow}
                      allUser={this.props.externalState.allUser}
                      saveCallback={this.handlePipSave}
                      updateCallback={this.handlePipUpdate}
                      pipIDData={externalState.pipData.pipIDData}
                      cleanUpPipData={cleanUpPipData}
                      modalCallBackcleanUpPipData={this.modalCallBackcleanUpPipData}
                      editMode={editModePip}
                      viewMode={viewModePip}
                      editData={editPipData}
                      stageData={stageData}
                      loading={externalState.pipData.loading}
                  />
                }
                {
                  <ListPipModal
                      editMode={editModePip}
                      showModalPip={showModalPip}
                      showModal={showModalListPip}
                      modalCallBackPip={this.modalCallBackListPip}
                      customerData={commentRow}
                      buValue={this.state.clickedBUValue}
                      editAccess={getAccessByMenuKey(menus, 'aging_reports',
                          'edit')}
                      editCallBack={this.handleEditModePip}
                  />
                }
              </React.Fragment>
          }
        </div>
    );
  }
}

export default connect(
    (state) => {
      return {
        externalState: state.dashboardState.receivableData.ageingData.external,
        summaryState: state.dashboardState.receivableData.ageingData.summary,
        dashboardState: state.dashboardState,
        authState: state.authReducer,
        invoiceDetailState: state.invoiceDetailState,
        chatStoreState: state.chatStore,
      };
    },
    (dispatch) => {
      return {
        savePipData(data) {
          dispatch({
            type: 'SAVE_PIP_DATA',
            payload: data,
          });
        },
        updatePipData(data) {
          dispatch({
            type: 'UPDATE_PIP_DATA',
            payload: data,
          });
        },
        getPipIDData(data) {
          dispatch({
            type: 'FETCH_PIP_ID',
            payload: data,
          });
        },
        getMSMDetail(data) {
          dispatch({
            type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA',
            payload: data,
          });
        },
        getFilterData(data) {
          dispatch({
            type: 'FETCH_EXTERNAL_FILTER_DATA',
            payload: data,
          });
        },
        getCommentData(data) {
          dispatch({
            type: 'FETCH_EXTERNAL_COMMENT_DATA',
            payload: data,
          });
        },
        getSummaryBuData(data) {
          dispatch({
            type: 'FETCH_SUMMARY_BU_DATA',
            payload: data,
          });
        },
        updateExternalData(data) {
          dispatch({
            type: 'UPDATE_EXTERNAL_DATA_SET',
            payload: data,
          });
        },
        updateActivePage(data) {
          dispatch({
            type: 'UPDATE_ACTIVE_PAGE_EXTERNAL',
            payload: data,
          });
        },
        fetchCommentDataHistory(data) {
          dispatch({
            type: 'FETCH_COMMENT_CHANNEL_DATA_HISTORY',
            payload: data,
          });
        },
        fetchOneCommentDataHistory(data) {
          dispatch({
            type: 'FETCH_COMMENT_ONE_CHANNEL_DATA_HISTORY',
            payload: data,
          });
        },
        getInvoiceDetail(data) {
          dispatch({
            type: 'FETCH_INVOICE_DETAIL',
            payload: data,
          });
        },
        getCreditLimitDetail(data) {
          dispatch({
            type: 'FETCH_CREDIT_LIMIT_DETAIL',
            payload: data,
          });
        },
        createNewChannel(data) {
          dispatch({
            type: 'CREATE_CHANNEL_ID',
            payload: data,
          });
        },
        updateChannelFlag(data) {
          dispatch({
            type: 'CHANGE_CREATE_CHANNEL_FLAG',
            payload: data,
          });
        },
        sendCollectionForecast(data) {
          dispatch({
            type: 'SEND_COLLECTION_FORECAST',
            payload: data,
          });
        },
        uploadChatFile(data) {
          dispatch({
            type: 'CHAT_UPLOAD_FILE',
            payload: data,
          });
        },
        uploadChatFileToggle() {
          dispatch({
            type: 'CHAT_UPLOAD_FILE_TOGGLE',
          });
        },
        downloadChatFile(data) {
          dispatch({
            type: 'CHAT_DOWNLOAD_FILE',
            payload: data,
          });
        },
        updateActionOwnerFn(data) {
          dispatch({
            type: 'UPDATE_ACTION_OWNER',
            payload: data,
          });
        },
        socketConnectionError(data) {
          dispatch({
            type: 'SHOW_ERROR_PAGE',
            payload: data,
          });
        },
      };
    },
)(ExternalReceivableComponent);

