import React from "react";
import { useState } from 'react';
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";
import DownloadExcel from "../../../images/download_excel.svg";
// import Tooltip from '@material-ui/core/Tooltip';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { getAccessByMenuKey } from "../../../utils/userManagement";
// import './index.scss';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TableComponent from '../TableComponent';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { format } from "date-fns";

export default function (props) {
    const {kpiPlanningDatas, kpiPlanningDataView, fetchKpiPlanningReview, updateStatusKpiPlanning} = props;
    // const {loading, rowData, rowHeader} = kpiPlanningDatas;

    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const [isViewClick, setIsViewClick] = useState(false);
    const [plantDetailsDataView, setPlantDetailsDataView] = useState({});
    const [tableHeaderDataView, setTableHeaderDataView] = useState([]);
    const [tableRowDataView, setTableRowDataView] = useState([]);
    const [loadingView, setLoadingView] = useState(false);

    const [loading, setLoading] = useState(false);
    const [rowHeader, setRowHeader] = useState([]);
    const [rowData, setRowData] = useState([]);

    const handleUpdateStatusKpiPlanning = (original, status) => {
        const toastrConfirmOptions = {
            onOk: () => {
                let payload = {
                    "_id": original.id,
                    "comment": original.comment,
                    "status": status
                }
                updateStatusKpiPlanning([payload])
                setIsUpdateStatus(true)
                setIsViewClick(false)
            },
            onCancel: () => console.log('CANCEL: clicked')
        }; 

        toastr.confirm(`Are you sure you want to ${status}?`, toastrConfirmOptions)
    }

    const handleViewDetails = (original) => {
        let payload = {
            "_id": original.id,
            "first_load": true
        }
        props.fetchKPIPlanning(payload)

        setIsViewClick(true)
    }

    const handleCommentChange = (_value, props, item) => {
        // console.log("_value : ",_value, " :props: ",props, " :item: ", item);
        props.comment = _value
    }

    const changeKeysOfHeader = (data) => {
        if (data.length) {
            return data.map((item, index) => {
                if(item.key == "action"){                    
                    const obj = {
                        Header : item.label,
                        id: item.key,
                        accessor: item.key,
                        Cell: (thisProps) => (
                            <React.Fragment>
                                {getAccessByMenuKey(props.menuData, "pns_func_pnl", "review") && thisProps.original.status=="submit" &&
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                    <Tooltip title={"Approve"}>
                                        {/* <ThumbUpIcon className='primary-dark marginrightLeft05Vw' fontSize='small' onClick={()=>{handleUpdateStatusKpiPlanning(thisProps.original,'approve')}}/> */}
                                        <Icon
                                            className="fs-20 icon-in-border ok-icon"
                                            color={"inherit"}
                                            disabled
                                            onClick={()=>{handleUpdateStatusKpiPlanning(thisProps.original,'approve')}}
                                        >
                                            done
                                        </Icon>
                                    </Tooltip>
                                    <Tooltip title={"Reject"}>
                                        <Icon
                                            className="fs-20 icon-in-border nok-icon"
                                            color={"inherit"}
                                            disabled
                                            onClick={()=>{handleUpdateStatusKpiPlanning(thisProps.original,'reject')}}
                                        >
                                            close
                                        </Icon>
                                    </Tooltip>
                                    </div>
                                }
                                <Tooltip title={"View"}>
                                    <VisibilityIcon className='primary-dark marginrightLeft05Vw mt-10' fontSize='small' onClick={()=>{handleViewDetails(thisProps.original)}}/>
                                </Tooltip>
                                
                            </React.Fragment>
                        )
                    }

                    return obj
                }

                if(item.key == "approve_date"){
                    const obj = {
                        Header : item.label,
                        id: item.key,
                        accessor: item.key,
                        Cell: (thisProps) => {
                            let dt = format(new Date(thisProps.original[item.key]), "dd-MMM-yyyy H:mma")
                            return (
                            <span>
                                { dt.indexOf("0001") > -1 ? "" : dt  }
                                </span>
                            )
                           }
                    }

                    return obj
                }

                if (item.key == "comment") {
                    const obj = {
                        Header : item.label,
                        id: item.key,
                        accessor: item.key,
                        Cell: (thisProps) => (
                            <React.Fragment>
                                {getAccessByMenuKey(props.menuData, "pns_func_pnl", "review") && thisProps.original.status=='submit' ?
                                    <div className="KpiPlanInput maxw-150">
										<FormControl sx={{ m: 1, width: '10ch' }} variant="outlined">
											<OutlinedInput
												id="outlined-adornment-weight"
												// type="number"
												// defaultValue={thisProps.original.sub_column[parentData.key][item.key]}
												defaultValue={thisProps.original.comment}
												onChange={(event) => { handleCommentChange(event.target.value, thisProps.original, item) }}
												// endAdornment={showPerComponent}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
													'aria-label': 'weight',
												}}
											/>
										</FormControl>
									</div> : <div className="KpiPlanInput maxw-150">{thisProps.original.comment}</div>
                                }
                                
                            </React.Fragment>
                        )
                    }

                    return obj
                }

                return {
                    Header: item.label,
                    id: item.key,
                    accessor: item.key,
                };
            });
        }

        return [];
    };
    let tableHeaderData = changeKeysOfHeader(rowHeader);
    let tableRowData = rowData;

    React.useEffect(() => {
        if (isUpdateStatus) {
            setIsUpdateStatus(false)
            fetchKpiPlanningReview({})
        }

        setLoading(kpiPlanningDatas.loading)
        if (!kpiPlanningDatas.loading && kpiPlanningDatas.rowData.length > 0) {
            setRowHeader(kpiPlanningDatas.rowHeader)
            setRowData(kpiPlanningDatas.rowData)
        }

        if (kpiPlanningDataView) {
            setPlantDetailsDataView(kpiPlanningDataView.planDetails)
            setTableHeaderDataView(kpiPlanningDataView.rowHeader)
            setTableRowDataView(kpiPlanningDataView.rowData)
            setLoadingView(kpiPlanningDataView.loading)
        }
    }, [kpiPlanningDatas, kpiPlanningDataView])

    const handleColumnDataClick = (_data, parentData, itemData) => {
        // console.log(_data, parentData, itemData);
    }

    return (
        <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12}>
                <div className='top-header'>
                    <div>
                        <b>Review / Approve</b>
                    </div>
                    
                    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        {/* <Tooltip title={"Collaborate"}>
                            <Icon
                                className="fs-20 primary mr-10"
                                color={"inherit"}
                                disabled
                                onClick={() => {
                                    // handleOpen();
                                }
                                }
                            >
                                chat_bubble_filled
                            </Icon>
                        </Tooltip> */}
                        <Tooltip title={'Download to Excel'}>
                            <img
                                src={DownloadExcel}
                                className='pointer mr-10'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    fetchKpiPlanningReview({export : true});
                                }} />
                        </Tooltip>
                        <Tooltip title={'Download to PDF'}>
                            <PictureAsPdfIcon fontSize="large"
                                height='30'
                                className="pointer mr-10"
                                onClick={(event) => {
                                    // event.preventDefault();
                                    //   this.printDocument();
                                }} />
                        </Tooltip>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} className='planningModelsTable-container'>
                <ReactTable
                    columns={tableHeaderData}
                    data={tableRowData}
                    loading={loading}
                    defaultPageSize={10}
                    pageSize={tableRowData.length > 5 ? tableRowData.length : 5}
                    showPagination={true}
                    className='-striped -highlight xpna-react-table'
                />
            </Grid>

            {isViewClick && 
                <Grid item xs={12} sm={12}>                    
                    <Grid item xs={12} className='planningModelsTable-container mt-10'>
                        {/* <span>ID: {plantDetailsDataView._id} </span>   */}
                        <Grid container className="dropdown-filter">
                            <Grid xs={12} className="top-header mb-10" >
                                <div>
                                    <b>{plantDetailsDataView.name +' - '+ plantDetailsDataView.version}</b>
                                </div>
                            </Grid>
                            <Grid container xs={6} className="dropdown-filter" >
                                <Grid item xs={4}>
                                    <span>Historical Data Period: {plantDetailsDataView.period_type.split(' ')
                                         .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
                                         .join(' ')} </span>
                                </Grid>
                                <Grid item xs={5}>
                                    <span>No Of Month: {plantDetailsDataView.period.map(x => x).join(', ')} </span>
                                </Grid>
                            </Grid>
                            <Grid container xs={6} className="dropdown-filter" style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                <Grid item xs={4}>
                                    <span>Budget Data Period: {plantDetailsDataView.period_type.split(' ')
                                         .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
                                         .join(' ')} </span>  
                                </Grid>
                                <Grid item xs={5}>
                                    <span>Budget No Of Month: {plantDetailsDataView.budget_period.map(x => x).join(', ')} </span>
                                </Grid>
                            </Grid>
                        </Grid>         
                    </Grid>
                    <Grid item xs={12} className='planningModelsTable-container mt-10'>
                        <TableComponent
                            rowHeader={tableHeaderDataView}
                            rowData={tableRowDataView}
                            loading={loadingView}
                            handleColumnDataClick={handleColumnDataClick}
                        />
                    </Grid>
                </Grid>
            }

        </Grid>

    );
}