import React, {useState, useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ActualBUComponent from './ActualBU'
import SummaryByKPI from './SummaryByKPI'
import { useDispatch } from "react-redux"

const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
			style={{ padding: '10px 10px 5px' }}
		>
			{value === index && children}
		</div>
	);
}
  

export default function(){
    const [tabSelectorValue, setTabSelectorValue] = useState(0)
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabSelectorValue(newValue)
    }

    useEffect(()=>{
        dispatch({
            type: "FETCH_ACTUAL_BU_FILTER"
        })
    },[])

    return (
        <div>
            <Tabs
          value={tabSelectorValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className='mt-16 mb-16'
        >
          <Tab label="BU Actual Data"  {...a11yProps(0)} >
          </Tab>
          <Tab label="Summary By KPI"  {...a11yProps(1)} ></Tab>
        </Tabs>
        < div className="ex1" >
          <TabPanel value={tabSelectorValue} index={0}>
            <ActualBUComponent/>
        </TabPanel>
        <TabPanel value={tabSelectorValue} index={1}>
            <SummaryByKPI/>
        </TabPanel>
        </div>
            
        </div>
    )
}