import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from 'react';
import { parseNumber, parsePrice } from '../../../utils/numberUtils';
import Legend from './Legend';
import { regionNames, hemisphereLngLtd, regionLngLtd } from './map-data';
import './map-style.css';
import './mapbox-gl.css';

const getDSOIconType = (DSO) => {
	let iconType = '';

	if (DSO < 5) {
		iconType = 'dso-green';
	}

	if (-5 <= DSO && DSO <= 5) {
		iconType = 'dso-yellow';
	}

	if (DSO > 5) {
		iconType = 'dso-red';
	}

	return iconType;
};

const getMarginIconType = (margin) => {
	let iconType = '';

	if (margin < 2) {
		iconType = 'margin-red';
	}

	if (-2 <= margin && margin <= 2) {
		iconType = 'margin-yellow';
	}

	if (margin > 2) {
		iconType = 'margin-green';
	}

	return iconType;
};

mapboxgl.accessToken =
	'pk.eyJ1Ijoic2Vuc2lhLWVhY2lpdCIsImEiOiJja3RqbWIzNDExZGIwMnJqeWpldzh2Z3hxIn0.SZjYVrM2Ng5UTj-Y1zQ-aA';

const MapBoxContainer = (props) => {
	const options = [
		{
			name: 'Revenue',
			description: '',
			property: 'pop_est',
			legendRevenueLabel: [
				['>=20%', '#7130ae'],
				['5-20%', '#9b74c5'],
				['<5%', '#d7c8e9'],
			],
			legendDSOLabel: [
				['<5 Days', '#008000'],
				['+/-5 Days', '#f3b700'],
				['>5 Days', '#d2222d'],
			],
			legendMarginLabel: [
				['>2%', '#008000'],
				['+/-2%', '#f3b700'],
				['<2%', '#d2222d'],
			],
			stops: [
				[-1, '#e7e7e7'],
				[0, '#7130ae'],
				[1000000, '#9b74c5'],
				[5000000, '#d7c8e9'],
			],
		},
	];
	const mapContainerRef = useRef(null);
	const [active, setActive] = useState(options[0]);
	const [map, setMap] = useState(null);

	const { type, mapData } = props;
	const data = mapData?.data ?? [];
	const summary_dso = mapData?.summary_dso ?? 0;
	const summary_margin = mapData?.summary_margin ?? 0;

	// Initialize map when component mounts
	useEffect(() => {
		const totalRevenue = data.reduce(
			(prevVal, currVal) => prevVal + currVal?.stats?.revenue ?? 0,
			0
		);
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: 'mapbox://styles/sensia-eaciit/cktogi18x18im17n26hpvs4gz',
			center: [5, 34],
			zoom: 1.5,
			minZoom: 1.5,
		});

		map.on('load', () => {
			map.addSource('countries', {
				type: 'vector',
				url: 'mapbox://mapbox.country-boundaries-v1',
			});

			map.setPaintProperty('water', 'fill-color', '#ffffff');

			// Build a GL match expression that defines the color for every vector tile feature
			// Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
			const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
			const hemisphereUniArr = [],
				regionUniArr = [];

			data.forEach((cData) => {
				const {
					area,
					stats: { dso, margin, revenue = 0 },
					countries = [],
				} = cData;
				const parsedDso = dso - summary_dso;
				const parsedDsoString = parseNumber(dso, false);
				const parsedMargin = margin - summary_margin;
				const parsedMarginString = parseNumber(margin);
				const calculatedRevenue = ((revenue / totalRevenue) * 100).toFixed(2);
				const parsedRevenue = parsePrice(revenue);

				if (type == '0') {
					const { iso_code, latitude, longitude } = cData;

					if (latitude && latitude) {
						// Convert the range of data values to a suitable color
						let color = null;

						if (dso) {
							const iconType = getDSOIconType(parsedDso);

							if (iconType !== '') {
								// create a HTML element for each feature
								const el = document.createElement('div');
								el.className = 'circle marker-' + iconType;

								// make a marker for each feature and add it to the map
								new mapboxgl.Marker({ element: el, offset: [0, -10] })
									.setLngLat([longitude, latitude])
									.setPopup(
										new mapboxgl.Popup() // add popups
											.setHTML(
												`<span>Country - <strong>${area}</strong></span><br/>` +
													`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
													`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
													`<span>Margin  - <strong>${parsedMarginString} %</strong></span><br/>`
											)
									)
									.addTo(map);
							}
						}

						if (margin) {
							let iconType = getMarginIconType(parsedMargin);

							if (iconType !== '') {
								// create a HTML element for each feature
								const el = document.createElement('div');
								el.className = 'triangle-up marker-' + iconType;

								// make a marker for each feature and add it to the map
								new mapboxgl.Marker({ element: el, offset: [0, 10] })
									.setLngLat([longitude, latitude])
									.setPopup(
										new mapboxgl.Popup() // add popups
											.setHTML(
												`<span>Country - <strong>${area}</strong></span><br/>` +
													`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
													`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
													`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
											)
									)
									.addTo(map);
							}
						}

						if (calculatedRevenue) {
							if (calculatedRevenue >= 20) {
								//color = 'rgb(113, 48, 174)';
								color = 'rgb(1, 90, 187)';
							}

							if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
								//color = 'rgb(155, 116, 197)';
								color = '#70B5FA'
							}

							if (calculatedRevenue < 5) {
								//color = 'rgb(215, 200, 233)';
								color = '#B6D3F3';
							}

							matchExpression.push(iso_code, color);
						}
					}
				} else if (type == '1') {
					if (dso) {
						const iconType = getDSOIconType(parsedDso);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'circle marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, -10] })
								.setLngLat(hemisphereLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Hemisphere - <strong>${area}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					if (margin) {
						let iconType = getMarginIconType(parsedMargin);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'triangle-up marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, 10] })
								.setLngLat(hemisphereLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Hemisphere - <strong>${area}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					countries.forEach(({ iso_code }) => {
						if (!hemisphereUniArr.includes(iso_code)) {
							hemisphereUniArr.push(iso_code);
							if (calculatedRevenue) {
								let color = null;

								if (calculatedRevenue >= 20) {
									color = 'rgb(1, 90, 187)';
								}

								if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
									color = '#70B5FA';
								}

								if (calculatedRevenue < 5) {
									color = '#B6D3F3';
								}

								matchExpression.push(iso_code, color);
							}
						}
					});
				} else if (type == '2') {
					if (dso) {
						const iconType = getDSOIconType(parsedDso);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'circle marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, -10] })
								.setLngLat(regionLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Region - <strong>${regionNames[area]}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					if (margin) {
						let iconType = getMarginIconType(parsedMargin);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'triangle-up marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, 10] })
								.setLngLat(regionLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Region - <strong>${regionNames[area]}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					countries.forEach(({ iso_code }) => {
						if (!regionUniArr.includes(iso_code)) {
							regionUniArr.push(iso_code);

							if (calculatedRevenue) {
								let color = null;

								if (calculatedRevenue >= 20) {
									color = 'rgb(1, 90, 187)';
								}

								if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
									color = '#70B5FA';
								}

								if (calculatedRevenue < 5) {
									color = '#B6D3F3';
								}

								matchExpression.push(iso_code, color);
							}
						}
					});
				}
			});

			// Last value is the default, used where there is no data
			matchExpression.push('rgba(0, 0, 0, 0)');

			// Add layer from the vector tile source to create the choropleth
			// Insert it below the 'admin-1-boundary-bg' layer in the style
			map.addLayer(
				{
					id: 'countries-join',
					type: 'fill',
					source: 'countries',
					'source-layer': 'country_boundaries',
					paint: {
						'fill-color': matchExpression,
					},
				},
				'admin-1-boundary-bg'
			);

			setMap(map);
			map.resize();
		});
		

		// Clean up on unmount
		return () => map.remove();
	}, [JSON.stringify(data)]);

	useEffect(() => {
		paint();
	}, [active]);

	const paint = () => {
		if (map) {
			map.setPaintProperty('countries', 'fill-color', {
				property: active.property,
				stops: active.stops,
			});
		}
	};

	const changeState = (i) => {
		setActive(options[i]);
		map.setPaintProperty('countries', 'fill-color', {
			property: active.property,
			stops: active.stops,
		});
	};

	return (
		<div>
			<div
				ref={mapContainerRef}
				className='map-container top right left bottom'
			/>
			{/* <Legend
				// active={active}
				stops={active.legendRevenueLabel}
				dso={active.legendDSOLabel}
				margins={active.legendMarginLabel}
			/> */}
		</div>
	);
};

export default MapBoxContainer;
