import {
   convertChartData,
   convertDSOChartData,
   getDSOTableData,
   mapToChartDataDSOBuWise,
   mapToChartDataDSOBuWiseDSO,
   mapCommentsName,
   reOrderTableViewData,
   getYearlyChart,
   convertARChartView,
   sortDataAnalysis,
   getSortingSubchart,
   transformFilterData
} from './transformer'

const reducer = (state = {
   loading: false,
   showErrorPage: false,
   actionOwnerLoader: false,
   dashboardData: [],
   reportDateArr: [],
   customerNameArr: [],
   selectedDate: '',
   generalTableState:{
      loading: false, 
      rowData: [],
      rowHeaderData: []
   },
   exitWithoutSaveFlag: false,
   showExitWithoutSaveModal: {
      showModal: false,
      nextLocation: ''
   },
   dashboardAnalytic: {
      OverdueAnalysis: {
         data: {},
         loading: true,
      },
      ArOverdueChartView:{
         data: {},
         selectedDate: '',
         reportDateArr: [],
         loading: true
      },
      YearlyChartView:{
         data : {},
         subChartData : {},
         loading: true,
         filterBu : [],
         filterRegion : [],
         filterSegment: [],
         filterCountry: []
      },
      ScatterChart: {
         buTable: {},
         regionTable: {},
         slbTable: {},
         tpTable: {},
         pemexTable: {},
         loading: true,
      },
      AROverdueActionAnalysis: {
         loading: false,
         emailTemplate : {
            loading : true,
            data : {}
         },
         data: {}
      },
      DSOAnalysis: {
         data: { summary: [], summaryTable: [], buWise: [], buWiseDSO: [], dso_improvement: [] },
         header: [],
         loading: true,
         buWiseLoading: true,
         saveLoading: true,
         buWiseDSOLoading: true,
      },
      Collections: {
         data: {},
         loading: true
      }
   },
   receivableData: {
      payloadData: {},
      compareData: {
         loading: false,
         rowHeader: [],
         rowData: [],
         filter: {
            periodArr: [],
            parentGroupArr: [],
            regionArr: [],
            buArr: []
         }

      },
      ageingData: {
         summary: {
            fetched: false,
            loading: true,
            externalData: {},
            buRowData: {},
            internalData: {},
            unbilledData: {},
            totalData: {}
         },
         summaryMonthly: {
            fetched: false,
            loading: true,
            externalData: {},
            buRowData: {},
            internalData: {},
            unbilledData: {},
            totalData: {}
         },
         external: {
            fetched: false,
            loading: true,
            createChannel: false,
            newRowItem: {},
            filterObj: {
               data: {}
            },
            selectedFilter: {
               action_owner: [],
               aging_category: [],
               category: "",
               bu: 'BU 1',
               limit: 50,
               customer_name: [],
               skip: 0

            },
            comments: {
               loading: false,
               data: []
            },
            activePage: 0,
            data: {},
            pipMaster: {},
            allUser: [],
            selectedPipData: {},
            pipData: {
               listPipData: [],
               pipComments : [],
               pipIDData: "",
               loading: false
            }
         },
         internal: {}
      }
   },
   error: false
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {
      case 'SAVE_PIP_COMMENTS_DATA_LOADER' :
         newState.receivableData.ageingData.external.pipData.loading = true;
         return newState

      case 'SAVE_PIP_COMMENTS_DATA_SUCCESS' :
         newState.receivableData.ageingData.external.pipData.loading = false;
         return newState

      case 'FETCH_PIP_DATA_LOADER':
         newState.receivableData.ageingData.external.pipData.pipComments = [];
         newState.receivableData.ageingData.external.pipData.loading = true;
         return newState

      case 'CLEAR_POP_UP':
         newState.receivableData.ageingData.external.pipData.pipComments = [];
         newState.receivableData.ageingData.external.pipData.listPipData = [];
         return newState

      case 'FETCH_DASHBOARD_DATA_LOADER':
         return { ...state, loading: action.loading };

      case 'RECEIVABLE_AGEING_SUMMARY_DATA_SUCCESS':
         newState.loading = action.loading;
         newState.receivableData.ageingData.summary.fetched = true;
         newState.receivableData.ageingData.summary.loading = false;
         // if(action.payload.receivableTabSelectorValue ==1 ){

         //    let commonHeader =  action.data.external.row_header;
         //    let NewHead = commonHeader.slice(0,7);
         //    NewHead.push({key: 'contract_liability', label: 'Contract Liability', operation: 7});
         //    NewHead.push(commonHeader[7]);
         //    action.data.external.row_header = NewHead;
            
         // }

         newState.receivableData.ageingData.summary.externalData = action.data.external;
         newState.receivableData.ageingData.summary.internalData = action.data.internal;
         //TODO: Remove the dummy functions
         newState.receivableData.ageingData.summary.unbilledData =  /*dummyConvert(action.data.unbilled, action.reportDate);*/action.data.unbilled;
         newState.receivableData.ageingData.summary.totalData =  /*dummyTotalConvert(action.data.total, action.reportDate);*/action.data.total;
         return newState;
         case 'RECEIVABLE_CONTRACT_LIABILITY_DATA_SUCCESS':
            newState.loading = action.loading;
            newState.receivableData.ageingData.summaryMonthly.fetched = true;
            newState.loading = false;
            newState.receivableData.ageingData.summaryMonthly.loading = false;
            newState.receivableData.ageingData.summaryMonthly.externalData = action.data.hfm;
            return newState;
      case 'RECEIVABLE_New_HEMISPHERE_SUCCESS':
         newState.loading = action.loading;
         newState.receivableData.ageingData.summaryMonthly.fetched = true;
         newState.loading = false;
         newState.receivableData.ageingData.summaryMonthly.loading = false;
         newState.receivableData.ageingData.summaryMonthly.externalData = action.data;
         return newState;
   
      case 'RECEIVABLE_MONTHLY_ROLLING_DATA_SUCCESS':
         newState.loading = action.loading;
         newState.receivableData.ageingData.summaryMonthly.fetched = true;
         newState.loading = false;
         newState.receivableData.ageingData.summaryMonthly.loading = false;
         newState.receivableData.ageingData.summaryMonthly.externalData = action.data.external;
         newState.receivableData.ageingData.summaryMonthly.internalData = action.data.internal;
         newState.receivableData.ageingData.summaryMonthly.totalData = action.data.total;
         //TODO: Remove the dummy functions
         newState.receivableData.ageingData.summaryMonthly.unbilledData =  /*dummyConvert(action.data.unbilled, action.reportDate);*/action.data.unbilled;
         return newState;

      case 'RECEIVABLE_AGEING_EXTERNAL_DATA_SUCCESS':
         newState.loading = action.loading;
         newState.receivableData.ageingData.external.fetched = true;
         newState.receivableData.ageingData.external.loading = false;
         newState.receivableData.ageingData.external.data = action.data;
         newState.receivableData.payloadData = action.payload;
         return newState

      case 'SEND_COMMENTS_ACTION_LOADER':
         newState.receivableData.ageingData.external.comments.loading = action.loading;
         return newState

      case 'CHANGE_CREATE_CHANNEL_FLAG':
         newState.receivableData.ageingData.external.createChannel = action.payload;
         if (!action.payload) {
            newState.receivableData.ageingData.external.newRowItem = {}
         }
         return newState

      case 'CREATE_CHANNEL_SUCCESS':
         newState.receivableData.ageingData.external.createChannel = action.payload;
         newState.receivableData.ageingData.external.data.row_data = action.data;
         newState.receivableData.ageingData.external.newRowItem = action.updatedItem;
         return newState

      case 'SEND_COMMENTS_ACTION_SUCCESS':
         newState.receivableData.ageingData.external.comments.loading = action.loading;
         return newState

      case 'FETCH_EXTERNAL_FILTER_DATA_SUCCESS':
         newState.receivableData.ageingData.external.filterObj.data = action.data;
         return newState

      case 'FETCH_MASTER_PIP_DATA_SUCCESS':
         newState.receivableData.ageingData.external.pipMaster = action.data;
         return newState

      case 'FETCH_ALL_USER_SUCCESS':
         newState.receivableData.ageingData.external.allUser = action.data;
         return newState

      case 'SAVE_PIP_DATA_SUCCESS':
         newState.receivableData.ageingData.external.pipData.loading = false;
         newState.receivableData.ageingData.external.selectedPipData = action.data;
         return newState

      case 'FETCH_PIP_DATA_SUCCESS':
         newState.receivableData.ageingData.external.selectedPipData = {};
         newState.receivableData.ageingData.external.pipData.loading = false;
         newState.receivableData.ageingData.external.pipData.listPipData = action.data;
            let arrayComments = []
            if(action.data.length > 0){
            let curData = action.data[0]
            
            if(curData.reasonfordelays != "")
            arrayComments.push({message : curData.reasonfordelays, date : curData.updatedon, login_id : curData.responsibleparty, main : true})

            if(curData.additionalcomments != null && curData.additionalcomments.length > 0){
               arrayComments = arrayComments.concat(curData.additionalcomments)
            }

         }
            newState.receivableData.ageingData.external.pipData.pipComments = arrayComments
            return newState

      case 'UPDATE_PIP_DATA_SUCCESS':
         newState.receivableData.ageingData.external.pipData.loading = false;
         newState.receivableData.ageingData.external.selectedPipData = action.data;
         return newState

      case 'FETCH_PIP_ID_SUCCESS':
         newState.receivableData.ageingData.external.pipData.loading = false;
         newState.receivableData.ageingData.external.pipData.pipIDData = action.data;
         return newState

      case 'FETCH_EXTERNAL_COMMENT_DATA_LOADER':
         newState.receivableData.ageingData.external.comments.loading = action.loading;
         return newState

      case 'SUMMARY_BU_DATA_SUCCESS':
         newState.receivableData.ageingData.summary.buRowData = action.data;
         return newState

      case 'FETCH_EXTERNAL_COMMENT_DATA_SUCCESS':
         newState.receivableData.ageingData.external.comments.data = action.data;
         newState.receivableData.ageingData.external.comments.loading = action.loading;
         return newState

      case 'UPDATE_EXTERNAL_DATA_SET':
         newState.receivableData.ageingData.external.data.row_data = action.payload;
         return newState

      case 'SET_SELECTED_FILTER':
         newState.receivableData.ageingData.external.selectedFilter = action.payload;
         return newState

      case 'UPDATE_ACTIVE_PAGE_EXTERNAL':
         newState.receivableData.ageingData.external.activePage = action.payload;
         return newState

      case 'SET_SELECTED_BUSINESS_UNIT':
         newState.receivableData.ageingData.external.selectedFilter.bu = action.payload;
         return newState

      case 'FETCH_COMMENT_HISTORY_ALL_SUCCESS':
         newState.receivableData.ageingData.external.data.row_data = action.data;
         return newState

      case 'FETCH_AGING_REPORT_DATE_SUCCESS':
         newState.reportDateArr = action.data.dates;
         const dateLen = action.data.dates.length;
         newState.selectedDate = dateLen > 0 ? action.data.dates[dateLen - 1] : '';
         return newState

      case 'FETCH_AGING_CUSTOMER_NAME_SUCCESS':
          if (action.data && action.data.length) {
            newState.customerNameArr = action.data.map((item) => {
               return item.customer_name
            })


          }
         return newState

       
      case 'UPDATE_SELECTED_DATE':
         newState.selectedDate = action.payload;
         return newState

      case 'FETCH_OVERDUE_DATA_LOADER':
         newState.dashboardAnalytic.OverdueAnalysis.loading = action.loading;
         return newState

      case 'FETCH_AR_OVERDUE_TABLE_DATA_LOADER':
         newState.dashboardAnalytic.ScatterChart.loading = action.loading;
         return newState

      case 'FETCH_DSO_DATA_LOADER':
         newState.dashboardAnalytic.DSOAnalysis.loading = action.loading;
         return newState

      case 'ADD_DSO_DATA_LOADER':
         newState.dashboardAnalytic.DSOAnalysis.saveLoading = action.loading;
         return newState

      case 'FETCH_DSO_BU_WISE_DATA_LOADER':
         newState.dashboardAnalytic.DSOAnalysis.buWiseLoading = action.loading;
         return newState

      case 'FETCH_DSO_BU_WISE_DATA_DSO_LOADER':
         newState.dashboardAnalytic.DSOAnalysis.buWiseDSOLoading = action.loading;
         return newState

      case 'FETCH_OVERDUE_DATA_SUCCESS':
         newState.dashboardAnalytic.OverdueAnalysis.loading = false;
         newState.dashboardAnalytic.OverdueAnalysis.data = convertChartData(action.data);
         return newState


      case 'FETCH_AR_OVERDUE_CHART_VIEW_DATA_LOADER':
         newState.dashboardAnalytic.ArOverdueChartView.loading = action.loading;
         return newState

      case 'FETCH_AR_OVERDUE_CHART_VIEW_DATA_SUCCESS':
         newState.dashboardAnalytic.ArOverdueChartView.loading = false;
         newState.dashboardAnalytic.ArOverdueChartView.data = convertARChartView(action.data);
         return newState

      case 'FETCH_AR_OVERDUE_YEARLY_LOADER':
         newState.dashboardAnalytic.YearlyChartView.loading = action.loading;
         return newState

      case 'FETCH_AR_OVERDUE_YEARLY_DATA_SUCCESS':
         newState.dashboardAnalytic.YearlyChartView.loading = false;
         newState.dashboardAnalytic.YearlyChartView.data = getYearlyChart(action.data.PlotData);
         newState.dashboardAnalytic.YearlyChartView.subChartData = getSortingSubchart(action.data.SubChartData,action.data.SubChartOrder)
         return newState

      case 'FETCH_AR_OVERDUE_CHART_VIEW_FILTER_DATA_SUCCESS':
         const buFilter = ["ALL"]
         const regionFilter = ["ALL"]
         const segmentFilter = ["ALL"]
         const countryFilter = ["ALL"]

         newState.dashboardAnalytic.ArOverdueChartView.reportDateArr = action.data.ReportDates;
         newState.dashboardAnalytic.ArOverdueChartView.selectedDate = action.data.LatestDate;
         if(action.chartType == "new"){
            newState.dashboardAnalytic.YearlyChartView.filterBu = buFilter.concat(action.data.new_bu);
         }else{

            newState.dashboardAnalytic.YearlyChartView.filterBu = buFilter.concat(action.data.bu);
         }
         newState.dashboardAnalytic.YearlyChartView.filterRegion = regionFilter.concat(action.data.region);
         newState.dashboardAnalytic.YearlyChartView.filterSegment = segmentFilter.concat(action.data.segment);
         newState.dashboardAnalytic.YearlyChartView.filterCountry = countryFilter.concat(action.data.countries);

         return newState

      case 'FETCH_AR_OVERDUE_TABLE_DATA_SUCCESS':
         newState.dashboardAnalytic.ScatterChart.loading = false;
         newState.dashboardAnalytic.ScatterChart.buTable = reOrderTableViewData(action.data.bu_table, "bu");
         newState.dashboardAnalytic.ScatterChart.regionTable = reOrderTableViewData(action.data.region_table , "region");
         newState.dashboardAnalytic.ScatterChart.slbTable = reOrderTableViewData(action.data.slb_table, "slb");
         newState.dashboardAnalytic.ScatterChart.tpTable = reOrderTableViewData(action.data.tp_table , "tp");
         newState.dashboardAnalytic.ScatterChart.pemexTable = reOrderTableViewData(action.data.pemex, "pemex");

         return newState

      case 'FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER':
         newState.dashboardAnalytic.AROverdueActionAnalysis.loading = action.loading;
         return newState

      case 'FETCH_AR_OVERDUE_ANALYSIS_ACTION_DATA_SUCCESS':
         newState.dashboardAnalytic.AROverdueActionAnalysis.loading = false;
         newState.dashboardAnalytic.AROverdueActionAnalysis.data = sortDataAnalysis(action.data.analysis_action);
         return newState

      case 'FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER':
         newState.dashboardAnalytic.AROverdueActionAnalysis.emailTemplate.loading = action.loading;
         return newState


      case 'FETCH_AR_OVERDUE_EMAIL_TEMPLATE_DATA_SUCCESS':
         newState.dashboardAnalytic.AROverdueActionAnalysis.emailTemplate.loading = false;
         newState.dashboardAnalytic.AROverdueActionAnalysis.emailTemplate.data = action.data;
         return newState


      case 'FETCH_DSO_DATA_EDITED_SUCCESS':
         newState.dashboardAnalytic.DSOAnalysis.saveLoading = false;
         return newState

      case 'FETCH_DSO_DATA_SUCCESS':
         newState.dashboardAnalytic.DSOAnalysis.loading = false;
         newState.dashboardAnalytic.DSOAnalysis.data.summary = convertDSOChartData(action.data.data, action.payload);
         newState.dashboardAnalytic.DSOAnalysis.data.dso_improvement = action.data.dso_improvement;
         newState.dashboardAnalytic.DSOAnalysis.data.summaryTable = getDSOTableData(action.data.data, action.payload);
         newState.dashboardAnalytic.DSOAnalysis.header = action.data.header;
         newState.dashboardAnalytic.DSOAnalysis.filter = action.data.filter;
         newState.dashboardAnalytic.DSOAnalysis.monthFilter = action.data.monthFilter;
         newState.dashboardAnalytic.DSOAnalysis.HeaderSummary = action.data.summry;
         return newState

      case 'FETCH_DSO_BU_WISE_DATA_SUCCESS':
         newState.dashboardAnalytic.DSOAnalysis.buWiseLoading = false;
         newState.dashboardAnalytic.DSOAnalysis.data.buWise = mapToChartDataDSOBuWise(action.data.totalunbilleddso.row_data_default, action.payload)
         return newState

      case 'FETCH_DSO_BU_WISE_DATA_DSO_SUCCESS':
         newState.dashboardAnalytic.DSOAnalysis.buWiseDSOLoading = false;
         newState.dashboardAnalytic.DSOAnalysis.data.buWiseDSO = mapToChartDataDSOBuWiseDSO(action.data, action.payload);
         return newState

      case 'UPDATE_EXIT_WITHOUT_SAVE_FLAG':
         newState.exitWithoutSaveFlag = action.data;
         return newState

      case 'UPDATE_EXIT_WITHOUT_SAVE_MODAL':
         newState.showExitWithoutSaveModal.showModal = action.data.showModal
         newState.showExitWithoutSaveModal.nextLocation = action.data.nextLocation
         return newState

      case 'FETCH_COLLECTIONS_DATA_LOADER':
         newState.dashboardAnalytic.Collections.loading = true
         return newState
         case 'FETCH_COLLECTIONS_DATA_LOADER_FALSE':
         newState.dashboardAnalytic.Collections.loading = false
         return newState

      case 'FETCH_UNALLOCATED_EXIST' : 
            newState.dashboardAnalytic.Collections.unallocatedExist = action.unallocatedExist;

      case 'FETCH_GENERAL_TABLE_STATE_LOADER':
            newState.generalTableState.loading = true
            return newState;

      case 'FETCH_GENERAL_TABLE_STATE_SUCCESS':
            newState.generalTableState.loading = false;
            newState.generalTableState.rowHeaderData = action.data.row_header;
            let dt = {"row_data":[{"process_id":"06ab03a0-5959-4282-821a-e7fbdee07b93","ingestion_type":"trans","err_msg":"Invalid Document Date Format | parsing time \"21-10\": month out of range","debug_msg":"Document Date|Posting Date format not correct","row_no":5,"process_type":"DataIn","field_name":"Document Date|Posting Date","field_value":"21-10|28/02/2021","created_at":"2021-03-11T10:10:46.38Z","err_type":"data_error"},{"process_id":"06ab03a0-5959-4282-821a-e7fbdee07b93","ingestion_type":"trans","err_msg":"Invalid Document Date Format | parsing time \"21-12\": month out of range","debug_msg":"Document Date|Posting Date format not correct","row_no":6,"process_type":"DataIn","field_name":"Document Date|Posting Date","field_value":"21-12|28/02/2021","created_at":"2021-03-11T10:10:46.381Z","err_type":"data_error"}],"row_header":[{"key":"err_type","label":"Error Type","operation":0},{"key":"row_no","label":"Row No.","operation":0},{"key":"ingestion_type","label":"Ingestion Type","operation":0},{"key":"upload_type","label":"Upload Type","operation":0},{"key":"err_msg","label":"Error Msg","operation":0},{"key":"debug_msg","label":"Debug Msg","operation":0},{"key":"created_at","label":"Created At","operation":0}]}
            newState.generalTableState.rowData = dt['row_data'];
            // newState.generalTableState.rowData = action.data.row_data;
            return newState;

      case 'FETCH_COLLECTIONS_DATA_SUCCESS':
         newState.dashboardAnalytic.Collections.data = action.data
         newState.dashboardAnalytic.Collections.loading = false
         return newState

         case 'SHOW_ERROR_PAGE_REDUCER':
            newState.showErrorPage = action.data
            return newState

         case 'UPDATE_ACTION_OWNER_LOADER':
            newState.actionOwnerLoader = true;
            return newState

         case 'UPDATE_ACTION_OWNER_SUCCESS':
            newState.actionOwnerLoader = false;
            return newState

         case "FETCH_COMPARE_FILTER_RECEIVABLE_LOADER":
         newState.receivableData.compareData.loading = action.loading;
         return newState;
         case "FETCH_COMPARE_FILTER_RECEIVABLE_SUCCESS":
         var { 
            regionArr, 
            parentGroupArr, 
            periodArr, 
            buArr } = transformFilterData(action.data.filter);
         newState.receivableData.compareData.filter.buArr = buArr;
         newState.receivableData.compareData.filter.periodArr = periodArr;
         newState.receivableData.compareData.filter.parentGroupArr = parentGroupArr;
         newState.receivableData.compareData.filter.regionArr = regionArr;
         return newState;

      case "FETCH_COMPARE_DATA_RECEIVABLE_SUCCESS":
         // newState.drilldownDetailState = action.data;
         newState.receivableData.compareData.loading = false;
         newState.receivableData.compareData.rowData = action.data.row_data;
         newState.receivableData.compareData.rowHeader = action.data.row_header;
         return newState;
      case "FETCH_COMPARE_DATA_RECEIVABLE_LOADER":
         // newState.drilldownDetailState = action.data;
         newState.receivableData.compareData.loading = action.loading;
         return newState;


      default:
         return newState;
   }
};
export default reducer;



