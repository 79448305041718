import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { numberWithCommas } from '../../../../utils/numberUtils'
import "react-table-6/react-table.css";
import { Grid, Tooltip } from "@material-ui/core";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Button from '@material-ui/core/Button';
import NewPagination from "../../../common/components/CustomPagination";

import ModalPayment from "./PaymentPopUp"
import ModalComment from "./CommentPopUp"
import 'react-table-hoc-fixed-columns/lib/styles.css'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAccessByMenuKey } from "../../../../utils/userManagement";
import ErrorPage from "../../../common/components/ErrorPage";
import parseJSON from 'date-fns/parseJSON'
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import InProgressComponent from '../../../common/components/InProgress'
import { format } from 'date-fns/esm';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import {
  downloadExcel,
  pdfFileName,
  mapExcelDataPayment,
  mapExcelHeader,
  mapExcelHeaderComparativeAutomated,
  mapExcelDataComparativeAutomatedValidation,
  mapDataPDF,
  mapPDFDataPLComparative
} from '../../../../utils/exportExcel'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class AutomationPayment extends React.Component {

  constructor(props) {
    super(props);
    this.invoiceHeaderInfo = ''
    this.state = {
      open: false,
      rowData: this.props.autoPaymentState.data,
      rowDataCustomer: [],
      rowHeaderCustomer: [],
      rowHeaderData: [],
      showModal: false,
      showCommentModal: false,
      editData: {},
      editMode: false,
      showInvoiceModal: false,
      typeInvoiceModalForecast: false,
      tabSelectorValue: 0,
      openPopup: false,
      id: '',
      anchorEl: null,
      showErrorModal: false,
      fileName: '',
      showCommentError: false,
      commentErrors: []
    };
  }

  modalCallBack = () => {
    this.setState({ showModal: false, showCommentModal: false });
  };

  componentDidMount() {
    this.props.getPaymentData({})
    this.props.fetchReportDate();
  }

  handleEditClick = (data) => {
    this.setState({
      editData: data,
      editMode: true,
      showModal: true
    });
  }

  handleCommentEditClick = (data) => {
    this.setState({
      editData: data,
      editMode: true,
      showCommentModal: true
    });
  }

  handleAddClick = (data) => {
    this.setState({
      editMode: false,
      showModal: true
    });
  }

  handleCommentAddClick = (data) => {
    this.setState({
      editMode: false,
      showCommentModal: true
    });
  }

  refreshList = () => {
    if (this.state.tabSelectorValue == 0) {
      this.props.getPaymentData({})
    } else {
      this.props.getCustomerCommentsData({})
    }
  }

  handlePaymentSave = (data) => {
    document.body.style.cursor = 'wait';
    this.props.savePaymentData(data)
  }

  handleCommentSave = (data) => {
    this.props.saveCommentData(data)
  }

  updateFileName = (name) => {
    this.setState({
      fileName: name + " " + format(new Date, "MM-dd-yyyy HH-mm-ss") + ".xlsx",
    })
  }

  componentDidUpdate(prevProps) {
    const { autoPaymentState, autoCustomerCommentsState } = this.props;
    if (this.state.tabSelectorValue == 0) {
      if (prevProps.autoPaymentState.data.length != autoPaymentState.data.length) {
        let newHeader = this.changeKeysOfHeader(autoPaymentState.header)

        this.setState({ rowData: autoPaymentState.data, rowHeaderData: newHeader })
      }

    } else {
      if (prevProps.autoCustomerCommentsState.data.length != autoCustomerCommentsState.data.length) {
        let newHeader = this.changeKeysOfHeader(autoCustomerCommentsState.header)
        this.setState({ rowHeaderCustomer: newHeader, rowDataCustomer: autoCustomerCommentsState.data, })
      }

    }
    if ((prevProps.autoPaymentState.saveLoading && !autoPaymentState.saveLoading) && autoPaymentState.saveSuccess) {
      this.refreshList();
      this.modalCallBack();
    }
    if (prevProps.autoPaymentState.showError !== autoPaymentState.showError && autoPaymentState.showError) {
      this.setState({ showErrorModal: true })
    }
    document.body.style.cursor = 'default';
  }

  handleDeletePayment = () => {
    if (this.state.tabSelectorValue == 0) {
      this.props.deletePaymentData(this.state.editData);
    } else {
      this.props.deleteCustomerCommentsData({ id: this.state.editData.id, upload_type: this.state.editData.upload_type });
    }
    this.handleCloseDialog();
  }

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleOpenDialog = (data) => {
    this.setState({
      editData: data,
      open: true
    });
  };

  handleDynamicWidth = (item) => {
    let widthSize;
    switch (item.key) {
      case 'automation.file_name':
        widthSize = 280;
        break;
      case 'automation.bu':
        // widthSize = 75;
        // break;
      // case 'automation.created_by':
      //   widthSize = 175;
      //   break;
      case "report_date":
      // case "automation.created_date":
      //   widthSize = 100;
      //   break;
      case "action":
        // widthSize = 75;
        // break;
      case "total_aging":
           widthSize = 150;
           break;
      case "total_forecast":
      default: break;
    }
    return widthSize
  }

  handleDownload = (ori) => {
    const {tabSelectorValue} = this.state;
    this.props.downloadPaymentData(
      {
        path: ori.automation.file_path,
        file_name: ori.automation.file_name,
        id: ori.id, 
        // upload_type,
        type: tabSelectorValue == 0 ? 'payment' : 'comment'
      })
  }

  maskFileName = (props) => {
    let ori = props.original;
    let coldata = ori["automation"]['file_name'].split(")")
    coldata.splice(0, 1)
    return coldata
  }

  handleInvoiceModalClose = () => {
    this.setState({ showInvoiceModal: false })
  }

  handleCommentModalClose =() => {
    this.setState({showCommentError: false, commentErrors: ""})
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabSelectorValue: newValue }, () => {
      if (newValue == 0) {
        this.props.getPaymentData({})
      } else {
        this.props.getCustomerCommentsData({})
      }
    })
  };

  changeFormatData = (_data, item) => {
    let modifiedData;
    let columnData = _data[item.key]
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData
    return modifiedData;
  }

  formatData = (data) => {
    let modifiedData;
    let columnData = data
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData
    return modifiedData;
  }

  handleInvoiceModalClick = (data, item) => {
    if (data.original) {
      var newDate = new Date(data.original["report_date"])
      let newMonth = newDate.getMonth() + 1 + '';
      if (newMonth && newMonth.length < 2) {
        newMonth = '0' + newMonth;
      }
      let payload = {
        id_payment: data.original["id"],
        // bu: data.original.automation.bu,
        // report_date: data.original["report_date"]
      }
      this.invoiceHeaderInfo = this.formatData(data.original.total_aging) + ' - ' + format(parseJSON(data.original["report_date"]), 'dd MMM yyyy')
      this.props.fetchInvoiceDetail(payload);
      this.setState({ showInvoiceModal: true, typeInvoiceModalForecast: false })
    }
  }


  handleInvoiceModalForecastClick = (data, item) => {
    if (data.original) {
      var newDate = new Date(data.original["report_date"])
      let newMonth = newDate.getMonth() + 1 + '';
      if (newMonth && newMonth.length < 2) {
        newMonth = '0' + newMonth;
      }
      let payload = {
        bu: data.original.automation.bu,
        report_date: data.original["report_date"]
      }
      this.invoiceHeaderInfo = this.formatData(data.original.total_aging_forecast) + ' - ' + format(parseJSON(data.original["report_date"]), 'dd MMM yyyy')
      this.props.fetchInvoiceDetail(payload);
      this.setState({ showInvoiceModal: true, typeInvoiceModalForecast: true })
    }
  }

  changeKeysOfHeader2 = (data) => {
    if (data && data.length) {
      return data.map((item, i) => {
        return {
          Header: item.label,
          id: item.key,
          Cell: (props) => {
            return (
              <div className='text-center'>
                {
                  item.key == 'bu' && props.original[item.key] == 'Grand Total' ?
                    <span><b>{props.original[item.key]}</b></span> :
                    <span>{item.key == 'bu' ? props.original[item.key] : this.formatData(props.original[item.key])}</span>
                }
              </div>
            )
          },
          style: {
            fontSize: '12px',
          },
          headerStyle: {
            whiteSpace: 'break-spaces',
            overflow: 'visible',
            fontSize: '13px',
            // fontWeight: 'bold',
          }
        }
      })
    }
    return []
  }

  changeKeysOfHeader = (data) => {
    const { authReducer } = this.props
    const menus = authReducer.menuData
    if (data && data.length) {
      // data.push({ label: "Action", key: "action" })
      // data.splice(5, 0, { label: 'Aging Report', key: 'aging_report' })
      return data.map((item) => {
        let obj = {
          Header: item.label,
          id: item.key,
          width: this.handleDynamicWidth(item),
          accessor: item.key,
          headerStyle: {
            fontSize: '13px',
            // fontWeight: 'bold',
            height: '35px',
            // lineHeight: '30px'
          },
          style: {
            fontSize: '12px',
            textAlign: 'center',
            height: '33px',
            // lineHeight: '40px',
            paddingLeft: '4px',
          },
        }
        // if (item.key == "action") {
        //   obj.Cell = (props) => (
        //     <React.Fragment>
        //       { getAccessByMenuKey(menus, "auto_payment", "delete") &&
        //         <Tooltip title={this.state.tabSelectorValue == 1 ? "Delete Comments Data" : "Delete Collection Data"}>
        //           <DeleteIcon fontSize='small' className='primary-dark' onClick={() => this.handleOpenDialog(props.original)} />
        //         </Tooltip>
        //       }
        //     </React.Fragment>
        //   )
        // }
        if (item.key == "automation.created_date") {
          obj.accessor = (_data) => {
            let coldata = format(parseJSON(_data["automation"]['created_date']), 'dd MMM yyyy')
            return coldata
          }
        }
        if (item.key =="automation.error_details"){
          obj.Cell = (props)=> {

                let errors = props.original.automation.error_details;

                return errors ? 
                <Tooltip title= {"Open Error Details"}>
                  <span onClick={() => this.setState({ showCommentError: true, commentErrors: errors})} className='primary-dark pointer'>
                    <u> View </u>
                  </span>
                </Tooltip>:''
          }
        }
        if (item.key == "report_date") {
          obj.accessor = (_data) => {
            let coldata = format(parseJSON(_data["report_date"]), 'dd MMM yyyy')
            return coldata
          }
        }
        if (item.key == 'automation.file_name') {
          obj.Cell = (props) => (
            getAccessByMenuKey(menus, "auto_payment", "download") ?
              <Tooltip title={"Download Collection Data"}>
                <span onClick={() => {
                  this.handleDownload(props.original)
                }} className='primary-dark pointer'><u>{
                  this.maskFileName(props)
                }</u></span>
              </Tooltip>
              : this.maskFileName(props)
          )
        }
        if (item.key == "total_actual" || item.key == "total_forecast" || item.key == 'total' || item.key == 'total_actual_forcast') {
          obj.accessor = (_data) => {
            let modifiedData;
            let columnData = _data[item.key]
            modifiedData = Math.round(columnData)
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            modifiedData = modifiedData == '0' ? '' : modifiedData
            modifiedData = modifiedData == 'NaN' ? '' : modifiedData
            return modifiedData;
          }
        }
        
        if (item.key == 'total_aging') {
          obj.Cell = (props) => (
            <React.Fragment>
              <span className='pointer' onClick={() => { this.handleInvoiceModalClick(props, item) }}>
                {this.changeFormatData(props.original, item)}
              </span>
            </React.Fragment>
          )
        }

        if(item.key == 'total_forecast' ){
          obj.sortable = false;
        }

        if (item.key == 'total_aging_forecast') {
          obj.sortable = false;
          obj.Cell = (props) => (
            <React.Fragment>
              <span 
              // className='pointer' 
              // onClick={() => { this.handleInvoiceModalForecastClick(props, item) }}
              >
                {this.changeFormatData(props.original, item)}
              </span>
            </React.Fragment>
          )
        }
        return obj
      })
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState(prevState => ({
      openPopup: true,
      id: prevState.openPopup ? 'simple-popover' : undefined
    }));
  };

  handleClose = () => {
    this.setState(prevState => ({
      anchorEl: null,
      openPopup: false,
      id: prevState.openPopup ? 'simple-popover' : undefined
    }));
  };

  transformErrorData = (data) => {
    return data.map((res, i) => {
      return <p key={i}>{res.message}</p>
    })
  }

  modifyMessage = (message) => {
    return message.split('\n')
      .map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            {item}
            <br />
          </React.Fragment>
        )
      });
  }

  handleCommentErrorModalClose =() => {
    this.setState({ showErrorModal: false }, () =>{
      this.modalCallBack();
      this.props.getCustomerCommentsData({})
    } )

   }
  handleExcelDownload = () => {
    let data = []
    let header = {};
    let merge = [];
    let headers = []
      let rowData = []
      let nameFile = ""
    if(this.state.tabSelectorValue == 0){
       headers = this.state.rowHeaderData
       rowData = this.state.rowData
       nameFile ="Collection"
    }else{
      headers = this.state.rowHeaderCustomer
      rowData = this.state.rowDataCustomer
      nameFile ="Comment"
    }
   

    console.log(this.tabSelectorValue, "testnewData")
    console.log(headers, "testnewHeader")
    data = mapExcelDataPayment(rowData, headers, "id")
    header = mapExcelHeader(headers, "Header")
          console.log(data, "testnewData")
          console.log(header, "testnewHeader")
   
  
    let fileName = "AR Upload - " + nameFile + "_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
  
       let sheetName = 'Data';
    
    downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
}

  handleBackFCFImpact  = () => {
    window.location.assign('/dashboard/free-cashflow-analytics/free-cashflow')
  }

  render() {
    const { autoPaymentState, autoCustomerCommentsState, authReducer } = this.props;
    const { open, editData, editMode, showModal, rowData, rowDataCustomer, rowHeaderCustomer, showCommentModal, rowHeaderData, showInvoiceModal, tabSelectorValue, id, openPopup, anchorEl, typeInvoiceModalForecast, showErrorModal, showCommentError, commentErrors, fileName } = this.state;
    const menus = authReducer.menuData
    let invoiceHeaderData = this.changeKeysOfHeader2(autoPaymentState.invoiceHeader)
    let newErrors=[];
    if(commentErrors){
      newErrors = commentErrors.map((x)=> {
        let msg = x.message;
        return <li>{msg}</li>
      })
    }
    let backFCF = localStorage.getItem('show-back-fcf')

    const body = (
      <React.Fragment>
           <div container className={ backFCF == 'true'? 'flex jc-space-between' : 'flex jc-flex-end' }>
            { backFCF == 'true' ?
              <Grid item xs={12} sm={1} className={'mt-8'} >
                                <Tooltip title="Back to Improve KPIs and Free Cashflow">
                                    <Button variant='contained' color="primary" className='back-button' onClick={ (event) => {
                                      event.preventDefault();
                                      this.handleBackFCFImpact()
                                    }}><ChevronLeftIcon /></Button>
                                </Tooltip>
                            </Grid> : ''
          }
        <Tooltip title={'Download to Excel'} className='pointer'>
            <img
                src={DownloadExcel}
                className='pointer'
                height='30'
                onClick={(event) => {
                    event.preventDefault();
                    this.handleExcelDownload();
                }} />
        </Tooltip>
       </div>
        
        <Tabs
          value={tabSelectorValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="COLLECTION" />
          <Tab label="COMMENTS" />
        </Tabs>
        <Grid container justify="flex-end" className='mt-20 mb-20'>
          {tabSelectorValue == 1 && getAccessByMenuKey(menus, "auto_payment", "add") &&
            <div>
              <Button variant="contained" className="newButton btnBlue btnUpload uppercase" onClick={() => this.handleCommentEditClick()} color="primary">Upload Comments</Button>
              <Button variant="contained" className="ml-10 newButton btnBlue btnDownload uppercase" onClick={this.handleClick}> Download Template </Button>
              <Popover
                id={id}
                open={openPopup}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Button style={{ marginRight: '5px', marginLeft: '5px' }} color="primary" href="/assets/Customer_Level_Template.xlsx" target="_blank"
                  onClick={() => { this.updateFileName("Customer_Level_Template") }} download={fileName}> Customer Level </Button>
                <Divider />
                <Button style={{ marginRight: '5px', marginLeft: '15px' }} color="primary" href="/assets/Invoice_Level_Template.xlsx" target="_blank"
                  onClick={() => { this.updateFileName("Invoice_Level_Template") }} download={fileName}> Invoice Level </Button>
              </Popover>
            </div>
          }
          {tabSelectorValue == 0 && getAccessByMenuKey(menus, "auto_payment", "add") &&
            <div>
              <Button variant="contained" className="newButton btnBlue btnUpload uppercase" onClick={() => this.handleAddClick()} color="primary">Upload Collection</Button>

              <Button variant="contained" className="ml-10 newButton btnBlue btnDownload uppercase" onClick={this.handleClick}> Download Template </Button>
              <Popover
                id={id}
                open={openPopup}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Button style={{ marginRight: '5px', marginLeft: '20px' }} color="primary" href="/assets/MSMT_LCS_Template.xlsx" target="_blank"
                  onClick={() => { this.updateFileName("BU_Template") }} download={fileName} > BU 1, BU 2 </Button>

                {/* <Divider /> */}

                {/* <Button style={{ marginRight: '5px', marginLeft: '15px' }} color="primary" href="/assets/LCS_DGS_Template.xlsx" target="_blank"
                 onClick = {()=>{this.updateFileName("LCS_DGS_Template") } }  download = {fileName} > LCS, DGS </Button> */}

                <Divider />

                <Button style={{ marginRight: '5px', marginLeft: '10px' }} color="primary" href="/assets/DGS_PAB_Template.xlsx" target="_blank"
                  onClick={() => { this.updateFileName("LCS_DGS_PAB_Template") }} download={fileName}> BU 2, BU 3, BU 4</Button>

                <Divider />

                { <Button style={{ marginRight: '5px', marginLeft: '15px' }} color="primary" href="/assets/PAB_Template.xlsx" target="_blank"
                onClick = {()=>{this.updateFileName("PAB_Template") } }  download = {fileName}  > BU 4 </Button> }

              </Popover>
            </div>
          }
        </Grid>
        <div>
          {autoPaymentState.loading ? <div className='text-center'><CircularProgress /></div> :
            tabSelectorValue == 0 ?
              <ReactTable
                data={rowData}
                columns={rowHeaderData}
                defaultPageSize={10}
                className="xpna-react-table single-line-header -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              /> : <ReactTable
                data={rowDataCustomer}
                columns={rowHeaderCustomer}
                defaultPageSize={5}
                className="xpna-react-table single-line-header -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              />
          }
        </div>
        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-title">{"Delete Data"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting the file will remove the uploaded collection data from the application. Do you want to Proceed?
                </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">Cancel</Button>
            <Button onClick={this.handleDeletePayment} color="secondary">Yes</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        {body}
        {autoPaymentState.saveLoading &&
          <InProgressComponent
            headerText='Upload in Progress'
            bodyText={tabSelectorValue == 0 ?'Collection data Upload is in progress, please wait..' : 'Comment data Upload is in progress, please wait..'}
            showModal="autoPaymentState.saveLoading"
          />
        }
        {
          <ModalPayment
            saveLoading={autoPaymentState.saveLoading}
            showModal={showModal}
            modalCallBack={this.modalCallBack}
            saveCallback={this.handlePaymentSave}
            editData={editData}
            reportDateArr={autoPaymentState.reportDateArrMonthly == undefined ? this.props.dashboardState.reportDateArr : autoPaymentState.reportDateArrMonthly}
            selectedDate={autoPaymentState.selectedDateMonthly == undefined ? this.props.dashboardState.selectedDate : autoPaymentState.selectedDateMonthly}
          />
        }
        {
          <ModalComment
            saveLoading={autoPaymentState.saveLoading}
            showModal={showCommentModal}
            modalCallBack={this.modalCallBack}
            saveCallback={this.handleCommentSave}
            editData={editData}
            reportDateArr={this.props.dashboardState.reportDateArr}
            selectedDate={this.props.dashboardState.selectedDate}
          />
        }
        { showCommentError && 
          <Modal
            open={showCommentError}
            onClose={this.handleCommentModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showCommentError}>
              <div className='customModal' style={{ maxWidth: 500 }}>
                <div className='header'>
                  {<h2>Error Details</h2>}
                  <Icon onClick={() => { this.handleCommentModalClose() }}>close</Icon>
                </div>
                <div className='modal-content'>
                  <div className="listItem">
                    <ul>{newErrors}</ul>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        }
        {
          <Modal
            open={showInvoiceModal}
            onClose={this.handleInvoiceModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showInvoiceModal}>
              <div className='customModal'>
                <div className='header'>
                  {<h2>{`Aging Report - USD ${this.invoiceHeaderInfo}`}</h2>}
                  <Icon onClick={() => { this.handleInvoiceModalClose() }}>close</Icon>
                </div>
                <div className='modal-content'>
                  {autoPaymentState.invoiceLoader ? '' :
                    <ReactTable
                      columns={invoiceHeaderData}
                      data={typeInvoiceModalForecast == false ? autoPaymentState.invoiceData : autoPaymentState.invoiceDataForecast}
                      defaultPageSize={3}
                      pageSize={typeInvoiceModalForecast == false ? autoPaymentState.invoiceData.length : autoPaymentState.invoiceDataForecast.length}
                      className="xpna-react-table single-line-header -striped -highlight"
                      PaginationComponent={NewPagination}
                      sortable={false}
                    />
                  }
                </div>
              </div>
            </Fade>
          </Modal>
        }
        {showErrorModal &&
          <Modal
            open={showErrorModal}
            onClose={() => this.setState({ showErrorModal: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showErrorModal}>
              <div className='customModal columnFilterModal' style={{ maxWidth: 500 }}>
                <div className='header'>
                  <h2>{autoPaymentState.errorWhileSaving.result && autoPaymentState.errorWhileSaving.result.map((file, fileIndex) => {
                    if (file.warnings.length && !file.errors.length) {
                      return 'Data Saved Successfully but there are warnings'
                    }
                    return 'Error'
                  })}</h2>
                  <Icon onClick={() => { this.handleCommentErrorModalClose() } }>close</Icon>
                </div>
                <div className='modal-content'>
                  {autoPaymentState.errorWhileSaving.result && autoPaymentState.errorWhileSaving.result.map((file, fileIndex) => {
                    return <>
                      <h3>Filename: {file.file_name}</h3>
                      {file.errors.length ? <>
                        <h3>Errors</h3>
                        {file.errors.map((err, errIndex) => <p key={errIndex}>{this.modifyMessage(err.message)}</p>)}
                      </> : ''}
                      {file.warnings.length ? <>
                        <h3>Warnings</h3>
                        {file.warnings.map((warn, warnIndex) => <p key={warnIndex}>{this.modifyMessage(warn.message)}</p>)}
                      </> : ''}
                    </>
                  })}
                  <div className='text-center'>
                  {autoPaymentState.errorWhileSaving && autoPaymentState.errorWhileSaving.result && autoPaymentState.errorWhileSaving.result.length ?
                  
                  autoPaymentState.errorWhileSaving.result[0].error_record &&
                  <h3>
                       Please correct the above errors and re-upload. Rest of the comments have been uploaded.
                    </h3> : null}
                    <Button variant="contained" color="primary" onClick={() => { this.handleCommentErrorModalClose()}} className='mt-30'>Ok</Button>
                    </div>
                </div>
              </div>
            </Fade>
          </Modal>
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      autoPaymentState: state.userManagementState.autoPaymentState,
      autoCustomerCommentsState: state.userManagementState.autoCustomerCommentsState,
      authReducer: state.authReducer,
      dashboardState: state.dashboardState
    };
  },
  (dispatch) => {
    return {
      getPaymentData(data) {
        dispatch({
          type: "FETCH_AUTO_PAYMENT",
          payload: data,
        });
      },
      deletePaymentData(data) {
        dispatch({
          type: "DELETE_AUTO_PAYMENT",
          payload: data,
        });
      },
      getCustomerCommentsData(data) {
        dispatch({
          type: "FETCH_AUTO_CUSTOMER_COMMENTS",
          payload: data,
        });
      },
      deleteCustomerCommentsData(data) {
        dispatch({
          type: "DELETE_AUTO_CUSTOMER_COMMENTS",
          payload: data,
        });
      },
      savePaymentData(data) {
        dispatch({
          type: "SAVE_AUTO_PAYMENT",
          payload: data,
        });
      },
      saveCommentData(data) {
        dispatch({
          type: "SAVE_AUTO_COMMENT",
          payload: data,
        });
      },
      downloadPaymentData(data) {
        dispatch({
          type: "DOWNLOAD_FILE_AUTO",
          payload: data,
        });
      },
      fetchInvoiceDetail(data) {
        dispatch({
          type: "FETCH_PAYMENT_INVOICE_DETAIL",
          payload: data,
        });
      },

      fetchReportDate() {
        dispatch({
          type: 'FETCH_AGING_REPORT_DATE_MONTHLY',
        });
      },
    };
  }
)(AutomationPayment);
