import React from 'react';
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import NewTableComponent from './newTableSimulation'
import DownloadExcel from "../../../../images/download_excel.svg";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import SimpleModal from '../Drilldown';
import HBPSimulationModal from './HBP/modal.jsx';

export class SimulationEbitdaComponent extends React.Component {

    constructor(props) {
        super(props);
        this.savePayload = {}
        this.drilldownPayload = {}
        this.headerDrilldown = ''
        this.state = {
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            },
            loading : false,
            showDrilldown : false,
            monthValue : '',
            monthArr:[],
            showHbpSimulation: false
        }
    }


    componentDidMount() {
        // console.log( this.props, "props")
        this.props.fetchActualFilterData()
    }

    componentDidUpdate(prevProps) {
        let now = this.props.actualForecastCementState
        // console.log(this.props, "-- update props")
        let nowChart = this.props.simulationState
        let prevChart = prevProps.simulationState
        if (now && now.filterArr && this.state.monthArr.length == 0) {
            this.setState({monthArr : now.filterArr, monthValue : '30-Apr-2023'})
            this.props.fetchSimulationData({month : '30-Apr-2023'})
        }

        if(prevChart.loading != nowChart.loading){
            this.setState({ loading : nowChart.loading })
            this.savePayload = {}
        }
    }


    handleMonthChange = (event) => {
        this.setState({ monthValue :  event.target.value})
        this.props.fetchSimulationData({month : event.target.value})
    }

    handleSaveChange = (input, data, header, parent ) => {
        const { monthValue} = this.state
        const uniqKey = data.data_field + '_' + data.unit 
        if (this.savePayload[uniqKey]) {
            this.savePayload[uniqKey] = {
              ...this.savePayload[uniqKey],
              [header.key]: Number(input),
            }
          }else{
            this.savePayload[uniqKey] = {
                data_field : data.data_field,
                unit : data.unit,
                "manual_norm":  header.key == "manual_norm" ? Number(input) : data["sub_column"][parent.key]["manual_norm"],
                "target_setting": header.key == "target_setting" ? Number(input) : data["sub_column"][parent.key]["target_setting"],
                yearmonth : monthValue,
            }
          }

          console.log(uniqKey, this.savePayload, "-- handle save")
    }

    createSavePayload = () => {
        const payload = []
        Object.keys(this.savePayload).forEach(item => {
          payload.push(this.savePayload[item])
        })
    
        return payload
      }

    handleSaveSubmit = () => {
        const payload = this.createSavePayload()
        console.log(payload, "-- handle submit")

        if(payload.length > 0){
            this.props.submitChange(payload)
        }else{
            toastr.warning("No data change")
        }
    }

    handleDrilldownClose = () => {
        this.setState({showDrilldown : false})
        this.drilldownPayload = {}
    }

    handleGenerateDrilldownHeader = () =>{
        return 'Header'
    }

    fetchDrilldownData = (ori, exportExcel) =>{
        if(ori && exportExcel != true){
            this.drilldownPayload = {
                data_field : ori.data_field,
                data_field_parent : ori.data_field_parent,
                month_filter : this.state.monthValue,
                export : false
            }

            this.headerDrilldown = (ori.data_field_parent != "" ? ori.data_field_parent + " - ": "") + ori.data_field + " Drilldown"
        }else{
            this.drilldownPayload.export = true
        }

        this.props.fetchDrilldownData(this.drilldownPayload)
        this.setState({showDrilldown : true})
    }

    handlecloseHbpSimulation = () => {
        this.setState({showHbpSimulation : false})
    }

    handleshowHbpSimulation = () => {
        this.props.fetchHBPDropdownMaster()
        this.setState({showHbpSimulation : true})
    }


    render() {

        const { monthValue, monthArr, loading , showDrilldown, showHbpSimulation} = this.state
        const { simulationState, handShowCompare, simulationDrilldownState} = this.props
        // console.log(simulationState, "-- yw simulation")
        return (
            <React.Fragment>
                <Grid container xs={12} sm={12} className="pb-15">
            <Grid item xs={12} sm={12} className="pt-0 flex ai-center jc-space-between mt-5 mb-5">
                <Grid item xs={7} sm={7} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={monthValue}
                            label="Month"
                            defaultValue={'30-Apr-2023'}
                            onChange={this.handleMonthChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {monthArr && monthArr.length && monthArr.map(item => {
                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5} sm={5} className="pt-10 download-section" >

                    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <Button variant='contained' className='run-button mr-10 newButton btnBlue btnCompare' onClick={handShowCompare}>Search and Compare</Button>
                        <Button variant='contained' className='run-button mr-10 newButton btnBlue btnSave' onClick={() => this.handleSaveSubmit()}>Save</Button>

                        <Tooltip title={"Collaborate"}>
                            <Icon
                                className="fs-20 primary mr-10"
                                color={"inherit"}
                                disabled
                                onClick={() => {
                                    this.props.chatClick();
                                }
                                }
                            >
                                chat_bubble_filled
                            </Icon>
                        </Tooltip>
                        <Tooltip title={'Download to Excel'}>
                            <img
                                src={DownloadExcel}
                                className='pointer mr-10'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.fetchSimulationData({export : true, month : this.state.monthValue});
                                }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="pt-0">
                { loading ? <div className='progressfullpage'><CircularProgress /></div> :
                        simulationState && simulationState.rowData && simulationState.rowData.length > 0 ?
                        <div style={{maxHeight:'700px', overflow:'scroll'}}>
                        <NewTableComponent handleDrilldown={this.fetchDrilldownData} tableData={simulationState}  handleSaveChange={this.handleSaveChange} showHbpSimulation={this.handleshowHbpSimulation}/> 
                        </div>
                        :
                        <h3 className='text-center'>There is no data for the selected date</h3>
                }
            </Grid>
        </Grid>
            <SimpleModal
                data={simulationDrilldownState}
                showModal={showDrilldown}
                modalCallBack={this.handleDrilldownClose}
                header={ this.headerDrilldown }
                handleExcelDownload={ () => { this.fetchDrilldownData(null, true)  } }
            />
           
            <HBPSimulationModal
                hbpSimulation={showHbpSimulation}
                modalCallBack={this.handlecloseHbpSimulation}
                headerModal={ "Select HBP Simulation" }
                yearMonth={this.state.monthValue}
                filterHbp={simulationState.filterHbp}
            />
            </React.Fragment>
        );
    }
}

export default
connect(
    state => {
        console.log ('--yw master state', state.shreeCementState)

        return {
            authReducer: state.authReducer,
            simulationState: state.shreeCementState.simulationData,
            simulationDrilldownState: state.shreeCementState.drilldownData,
            actualForecastCementState: state.shreeCementState.actualForecastCementData,
        }
    },
    dispatch => {
        return {
            fetchSimulationData(data) {
                dispatch({
                    type: "FETCH_SIMULATION_DATA",
                    payload: data
                });
            },
            fetchDrilldownData(data) {
                dispatch({
                    type: "FETCH_DRILLDOWN_DATA",
                    payload: data
                });
            },
            fetchActualFilterData() {
                dispatch({
                    type: "FETCH_ACTUAL_FORECAST_FILTER_DATA"
                });
            },
            submitChange(data){
                dispatch({
                type: "SAVE_SIMULATION_DATA",
                payload: data
                })
            },
            fetchHBPDropdownMaster() {
                dispatch({
                    type: "FETCH_DROPDOWN_MASTER_EBITDA"
                })
            }
        };
    }
)(SimulationEbitdaComponent);

