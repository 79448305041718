import React from 'react';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChartContainer from './Charts/chartContainer';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Tabs from '@material-ui/core/Tabs';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import NewSummaryTableComponent from './TableComponent'
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AnalsisImg from '../../../images/sus1.png'
import OcosAllocation from '../../../images/ocosAllocation.png'
import Prof1Img from '../../../images/marg1.png'
import Prof2Img from '../../../images/eco1.png'
import CCAImg from '../../../images/cca.png'
import Dash1Img from '../../../images/dash1.png'
import { fetchMonthlyQuarterBUPLService } from '../store/service';
import { formatMs } from '@material-ui/core';
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import { isThisISOWeek } from 'date-fns';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
//import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import AOPForecastComponent from './AOPForecast';
import { Autocomplete, TextField } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class NewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.tabMap = {
            0: 'summary',
            1: 'BU 1',
            2: 'BU 2',
            3: 'BU 3',
            4: 'BU 4',
            5: 'OH'
        }

        this.radioSelectorReconMap = {
            'pab-validation': '6'
        }

        this.imgMap = {
            0: AnalsisImg,
            1: Prof1Img,
            2: Prof2Img,
            3: CCAImg,
            4: Dash1Img
        }
        this.imgOcos = {
            0: OcosAllocation,
        }

        this.radioMap = {
            0: 'summary',
            1: 'plant',
            2: 'le',
            3: 'region',
            4: 'country',
            5: 'hemisphere'
        }

        let defaultYearValue = this.props.incomeStatementState.defaultYear &&
        this.props.incomeStatementState.yearFilterArr.length ? this.props.incomeStatementState.defaultYear : '';
        let defaultMonthValue = ''

        let linkToValidatePAB = localStorage.getItem('linkToValidatePAB')

        if (linkToValidatePAB){
            defaultMonthValue =  this.props.incomeStatementState.defaultMonth;
            defaultYearValue = '';
        }

        this.state = {
            showLoader: true,
            isDetailedView: false,
            regionClicked: {},
            aopTabState: {},
            hemisphereClicked: {},
            monthSelectorValue: defaultMonthValue,
            qtrSelectorValue: '',
            additionalData:[],
            chartDataSO: [],
            TableDataSO: [],
            invoiceObject: null,
            yearSelectorValue: defaultYearValue,
            radioSelectorValue: '0',
            radioSelectorReconValue: '0',
            comparativeSelectorValue: '1',
            tabSelectorValue: 0,
            radioSelectorValueLE: "summaryLE",
            radioSelectorValuePlant : "summary",
            radioSelectorValueDGSAutomation : "lawson",
            radioSelectorDynanicDGS : "Total",
            newRadioSelectorValue: '4',
            NewTabList: [],
            radioSelectorValueSO : "summary",
            radioType : "",
            GraphView : true,
            radioArrSelected : "Default",
            radioArrCountrySelected : "Default",
            commentModal : false,
            DGAAutomationRadioList : [
                { text: 'Dynamic Radio 1', value: 'Dynamic Radio 1' },
                { text: 'Dynamic Radio 2', value: 'Dynamic Radio 2' },
                { text: 'Dynamic Radio 3', value: 'Dynamic Radio 3' },
               
            ],
            invoiceDetailStateNew : '',
            invoiceDetailFilter : '',
            showInvoiceDetailModal: false,
            showInvoiceDetailModalChart: false,
            selectedDrilldown : null,
            commentValue :'',
            radioData : [],
            radioDataSample : '',
            ocosBU : 'BU 1'
        }
    }

    componentDidMount() {
      
        let payload = {
                recon_value: this.state.radioSelectorReconValue,
                radiovalue :  "optimus",
        }
        this.props.getNewDefaultMonthDataDynamic(payload) 
        this.props.getNewDefaultMonthData()
      
        this.handleNewAccess()

        let linkToValidatePAB = localStorage.getItem('linkToValidatePAB')
        if (linkToValidatePAB) {
            linkToValidatePAB = JSON.parse(linkToValidatePAB)
            this.setState({
                radioSelectorReconValue: this.radioSelectorReconMap[linkToValidatePAB.selectedRadio],
                yearSelectorValue : '',
                tabSelectorValue: parseInt(linkToValidatePAB.tabKey)

            })
        }

        let newTabData = localStorage.getItem('openInNewTab');
        if (newTabData){
             let defaultRadio = "summary"
            if (this.state.tabSelectorValue == 1) {
                defaultRadio = "New BU 1"
            } else if (this.state.tabSelectorValue == 2) {
                defaultRadio = "New BU 2"
            }
            this.setState({
                radioArrSelected : "Default",
                radioType : "region", 
                radioSelectorValue: '6',
                hemisphereClicked: {},
                radioSelectorValueSO : defaultRadio,
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })

            localStorage.removeItem('openInNewTab')
        }
       
    }

    handleNewAccess = () => {
        const { authReducerState } = this.props;
        const { NewTabList } = this.state;

        let tabList = [
            { text: 'Summary', value: 'SUMMARY' },
            { text: 'New BU 1', value: 'New BU 1' },
            { text: 'New BU 2', value: 'New BU 2' },
            { text: 'Validation to BU P&L', value: 'Validation to BU P&L' },
            { text: 'Pipeline analysis', value: 'Pipeline analysis' },
            { text: 'Analytics (Mockup Only)', value: 'Analytics (Mockup Only)' },
            // { text: 'DGS Automation', value: 'DGS Automation (Under Build)' },
        ]

        let finalTabList = []

        if (authReducerState &&
            authReducerState.userData &&
            authReducerState.userData.new_access &&
            authReducerState.userData.new_access.group &&
            authReducerState.userData.new_access.group.length) {
            let isAllGroups = false;
            authReducerState.userData.new_access.group.some(groupItem => {
                if (groupItem.value == 'All Groups') {
                    isAllGroups = true;
                    return true;
                }
                finalTabList.push(tabList.find(x => x.value == groupItem.value));
                return false;
            })
            if (isAllGroups) {
                finalTabList = [...tabList];
            }
        } else {
            finalTabList = [...tabList];
        }

        this.setState({ NewTabList: finalTabList })


    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    refreshChart = () => {
        this.setState({
          chartDataSO: this.props.incomeStatementState.pivotRowDataChart,
          additionalData :  this.props.incomeStatementState.additionalData,
          TableDataSO: this.props.incomeStatementState.pivotRowData,
          HeaderTableDataSO: this.props.incomeStatementState.pivotHeaderData,
        })
      }
    componentDidUpdate(prevProps) {
        const { yearFilterArr, defaultYear, defaultMonth, lastIngestion, radioArr } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
         
               
                this.setState({
                    invoiceDetailStateNew : this.props.incomeStatementState.invoiceDetailState,
                    invoiceDetailFilter : this.props.incomeStatementState.invoiceDetailStateFilter,
                    showInvoiceDetailModal: true,
                    showLoader: false
                })
         
          
           
        }

        if (prevProps.incomeStatementState.NewUpdateLoading && !this.props.incomeStatementState.NewUpdateLoading) {
            const { invoiceObject } = this.state;
            let payload = { ...invoiceObject, 
            }
            payload["sort"] = this.sortParam
            this.handleInvoiceClick(payload);
        }

        if (prevProps.incomeStatementState.NewCommentChartLoader && !this.props.incomeStatementState.NewCommentChartLoader) {
            const { invoiceObject } = this.state;
            let payload = { ...invoiceObject, 
            }
            payload["sort"] = this.sortParam
            this.handleInvoiceClick(payload);
            this.toggleCommentModal()
        }
        console.log(prevProps.incomeStatementState, this.props, "cekProps")
        if (prevProps.incomeStatementState.chartModalLoading && !this.props.incomeStatementState.chartModalLoading){
            console.log("keluar 1")
            this.setState({
                invoiceDetailStateNew : this.props.incomeStatementState.invoiceDetailState,
                invoiceDetailFilter : this.props.incomeStatementState.invoiceDetailStateFilter,
                showInvoiceDetailModalChart: true,
                showLoader: false
            })
        }
        if (prevProps.incomeStatementState.chartLoading && !this.props.incomeStatementState.chartLoading){
   
            this.refreshChart()
        }
        if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
            this.handleNewAccess()
            let linkToValidatePAB = localStorage.getItem('linkToValidatePAB')
            if (linkToValidatePAB) {
                this.setState({
                    monthSelectorValue: defaultMonth,
                    // lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
                },()=>{
                    localStorage.removeItem('linkToValidatePAB')
                })
            }else {
                this.setState({
                    yearSelectorValue: defaultYear,
                    // lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
                })
            }
            
        }

        if (radioArr && radioArr.length > 0){
            let firstData = radioArr[1] && radioArr[1].label
            // console.log(firstData, this.state.radioDataSample, "--sample")
            if(this.state.radioDataSample != firstData){
                this.setState({ radioData : radioArr, radioDataSample : firstData })
            }
        }

       
    }

    handleRadioChange = (event) => {
        const { defaultYear } = this.props.incomeStatementState
        let defaultRadio = "summary"
        if(this.state.tabSelectorValue == 1){
            defaultRadio = "New BU 1"
        }else  if(this.state.tabSelectorValue == 2){
            defaultRadio = "New BU 2"
        }
        this.setState({
            radioArrSelected : "Default",
            radioType : event.target.value == 5 ?"hemisphere" : "region", 
            radioSelectorValue: event.target.value,
            hemisphereClicked: {},
            radioSelectorValueSO : defaultRadio,
            // qtrSelectorValue: '',
            // monthSelectorValue: '',
            // yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangeLE = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValueLE: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangeSO = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValueSO: event.target.value,
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangePlant = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValuePlant: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleDynamicRadioArrCountry = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
            // let defaultMonth = ""
            // if (val == "lawson"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthLawson
            // }else if (val == "slbsap"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
            // } else {
                // defaultMonth = this.props.incomeStatementState.defaultMonth
             
            // }
            this.setState({
                radioType : "country_name",
                radioArrCountrySelected: val ,
                // monthSelectorValue : defaultMonth,
                // qtrSelectorValue: '',
                // yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleDynamicRadioArr = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
            // let defaultMonth = ""
            // if (val == "lawson"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthLawson
            // }else if (val == "slbsap"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
            // } else {
                // defaultMonth = this.props.incomeStatementState.defaultMonth
             
            // }
            this.setState({
                radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
                radioArrSelected: val ,
                radioArrCountrySelected : "Default",
                // monthSelectorValue : defaultMonth,
                // qtrSelectorValue: '',
                // yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleRadioDGSAutomation = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
            let defaultMonth = ""
            if (val == "lawson"){
                defaultMonth = this.props.incomeStatementState.defaultMonthLawson
            }else if (val == "slbsap"){
                defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
            } else {
                defaultMonth = this.props.incomeStatementState.defaultMonth
             
            }
            this.setState({
                radioSelectorValueDGSAutomation: val ,
                monthSelectorValue : defaultMonth,
                radioSelectorDynanicDGS : 'Total',
                qtrSelectorValue: '',
                yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleDynamicRadioDGSAutomation = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorDynanicDGS: event.target.value,
            qtrSelectorValue: '',
            yearSelectorValue:  '',
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })
    };

    handleFetchAOPData = (isExport) => {
        const {aopTabState, monthSelectorValue, yearSelectorValue, qtrSelectorValue} = this.state
        const payload = {
            ...aopTabState,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
			year: yearSelectorValue ? yearSelectorValue : undefined,
			quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            export: isExport
        }
        this.props.getAOPTableData(payload)
    }

    handleRadioReconChange = (event) => {
        const { defaultYear, tabSelectorValue } = this.props.incomeStatementState
// dateDefault = this.props.incomeStatementState.monthFilterArr[0]
        // if(event.target.value == 0){
          
            let val = event.target.value
            if (event.target.value == 4  ){
                let val2 =  this.state.radioSelectorValueDGSAutomation
                let defaultMonth = ""
                if (val2 == "lawson"){
                    defaultMonth = this.props.incomeStatementState.defaultMonthLawson
                }else if (val2 == "slbsap"){
                    defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
                } else {
                    defaultMonth = this.props.incomeStatementState.defaultMonth
                 
                }
                this.setState({
                    monthSelectorValue : defaultMonth,
                    // tabSelectorValue: 5,
                    yearSelectorValue :  '',
                    isDetailedView: false,
                    radioSelectorReconValue:val,
                    radioSelectorValue: '0',
                    hemisphereClicked: {}
                }, () => {
                    this.handleFetchNewData()
                })
            }else if (event.target.value == 5  ){
               
                    console.log(this.props.incomeStatementState.defaultMonth, "checkDefaultMonth")
                    this.setState({
                        monthSelectorValue :this.props.incomeStatementState.defaultMonthOptimus &&
                        this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonthOptimus : '' ,
                        // tabSelectorValue: 5,
                        yearSelectorValue :  '',
                        isDetailedView: false,
                        radioSelectorReconValue:val,
                        radioSelectorValue: '0',
                        hemisphereClicked: {}
                    }, () => {
                        this.handleFetchNewData()
                        
                    })
            }else if (event.target.value == 6  ){
               
                // console.log(this.props.incomeStatementState.defaultMonth, "checkDefaultMonth")
                this.setState({
                    monthSelectorValue :this.props.incomeStatementState.defaultMonth &&
                    this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonth : '' ,
                    radioSelectorReconValue:val,
                    yearSelectorValue : ''
                }, () => {
                    this.handleFetchNewData()
                    
                })
        }else if (event.target.value == 7  ){
            let defaultRadio = "summary"
            if(this.state.tabSelectorValue == 1){
                defaultRadio = "New BU 1"
            }else  if(this.state.tabSelectorValue == 2){
                defaultRadio = "New BU 2"
            }

            this.setState({
                radioArrSelected : "Default",
                radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
                radioSelectorValue: "2",
                hemisphereClicked: {},
                radioSelectorValueSO : defaultRadio,
                radioSelectorReconValue:val,
                // qtrSelectorValue: '',
                // monthSelectorValue: '',
                // yearSelectorValue: defaultYear,
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    
            // this.setState({
            //     radioArrSelected : "Default",
            //     radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
            //     radioSelectorValue: 2,
            //     hemisphereClicked: {},
            //     radioSelectorValueSO : defaultRadio,
            //     monthSelectorValue :this.props.incomeStatementState.defaultMonthOptimus &&
            //     this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonthOptimus : '' ,
            //     // tabSelectorValue: 5,
            //     yearSelectorValue :  '',
            //     isDetailedView: false,
            //     radioSelectorReconValue:val,
            //     // qtrSelectorValue: '',
            //     // monthSelectorValue: '',
            //     // yearSelectorValue: defaultYear,
            //     excludeDataFilter: []
            // }, () => {
            //     this.handleFetchNewData()
    
            // })
        }else{
               
                    this.setState({
                        radioSelectorReconValue: val,
                        monthSelectorValue: '',
                        yearSelectorValue : this.props.incomeStatementState.defaultYear
                        //     radioSelectorValue: '0'
                    }, () => {
                        this.handleFetchNewData()
                    })
               
            }
      
        // }else{
        // this.setState({
        //     radioSelectorReconValue: event.target.value,
        // })
        // }
        // this.setState({
        //     radioSelectorValue: event.target.value,
        //     qtrSelectorValue: '',
        //     monthSelectorValue: '',
        //     yearSelectorValue: defaultYear,
        //     excludeDataFilter: []
        // }, () => {
        //     this.handleFetchNewData()

        // })

    };

    handleNewRadioChange = (event) => {
        const { defaultYear } = this.props.incomeStatementState
        this.setState({
            newRadioSelectorValue: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        })
    };


    handleMonthChange = (event, value) => {
        const {tabSelectorValue} = this.state

        if (event.target) {
            this.setState({
                monthSelectorValue: value,
                qtrSelectorValue: '',
                yearSelectorValue: ''
            }, () => {
                if (tabSelectorValue != 4){
                    this.handleFetchNewData()

                }else {
                    this.handleFetchAOPData()
                }
            });
        }
    };

    handleQtrChange = (event, value) => {
        const {tabSelectorValue} = this.state
        if (event.target) {
            this.setState({
                qtrSelectorValue: value,
                monthSelectorValue: '',
                yearSelectorValue: ''
            }, () => {
                if (tabSelectorValue != 4){
                    this.handleFetchNewData()

                }else {
                    this.handleFetchAOPData()
                }

            })
        }
    };

    handleYearChange = (event, value) => {
        const {tabSelectorValue} = this.state

        if (event.target) {
            this.setState({
                yearSelectorValue: value,
                qtrSelectorValue: '',
                monthSelectorValue: ''
            }, () => {
                if (tabSelectorValue != 4){
                    this.handleFetchNewData()

                }else {
                    this.handleFetchAOPData()
                }
            })
        }
    };

    handleFetchNewData = (additionalPayload) => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue,ocosBU, radioSelectorValueSO,  radioArrSelected,radioArrCountrySelected, radioSelectorReconValue,radioSelectorValuePlant, radioSelectorValueLE, radioSelectorDynanicDGS, radioSelectorValueDGSAutomation, hemisphereClicked } = this.state;
        
        if(additionalPayload){
            this.setState({
                ocosBU : additionalPayload.bu,
            })
        }

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3 && radioSelectorReconValue != 7,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            radioArrSelected :radioArrSelected,
            radioArrCountrySelected : radioArrCountrySelected, 
            dgsAutomationType : radioSelectorValueDGSAutomation,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue,
            radioSelectorValueLE: radioSelectorValueLE,
            radioSelectorDynanicDGS : radioSelectorDynanicDGS,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined,
            buPlant : radioSelectorValuePlant,
            classificationSO : radioSelectorValueSO,
            buOCOSByCountry : additionalPayload != undefined ? additionalPayload.bu : ocosBU,
            loadOcosSummary : additionalPayload != undefined ? additionalPayload.loadSummary : true
        }



        console.log(payload, "cekPaylaod")
        if(radioSelectorReconValue == 4) {
            this.props.getDGSLawson(payload)
          
        }else if(radioSelectorReconValue == 5) {
            this.props.getOptimusAutomation(payload)
        }else if(radioSelectorValue == 6) {
            this.props.getChartSO(payload)
        }else{

            this.props.getNewPivotData(payload)
            // if(radioSelectorReconValue == 2){
            //     this.props.getNewOCOSSummary(payload)
            // }
        }

        if ((tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && (radioSelectorValue == 3 || radioSelectorValue == 4 || radioSelectorValue == 5)) {
            this.fetchNewPieDataFn()
        }

    }

    handleTabChange = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        if (newValue == 5 ){
            this.setState({
                monthSelectorValue : this.props.incomeStatementState.defaultMonth &&
                this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonth : '' ,
                tabSelectorValue: newValue,
                yearSelectorValue :  '',
                isDetailedView: false,
                radioSelectorReconValue: '0',
                radioSelectorValue: '0',
                hemisphereClicked: {}
            }, () => {
                if (newValue != 4) {
                    this.handleFetchNewData()
                }
            })
        }else{
            this.setState({
                tabSelectorValue: newValue,
                isDetailedView: false,
                radioSelectorReconValue: '0',
                radioSelectorValue: '0',
                hemisphereClicked: {}
            }, () => {
                if (newValue != 4) {
                    this.handleFetchNewData()
                }
            })
        }
       
    }
    handleSaveDrilldownChanges = (payload) => {
        this.props.saveDrilldownChanges(payload)
    }

    handleInvoiceClick = (payload) => {

        const { qtrSelectorValue, radioSelectorValuePlant,radioSelectorReconValue, radioArrSelected, radioArrCountrySelected, radioType, radioSelectorValueLE, monthSelectorValue, yearSelectorValue, radioSelectorValue, tabSelectorValue, radioSelectorValueDGSAutomation} = this.state
        let subTab
        let radio = radioType
        if (radioSelectorValue == 2) {
            subTab = radioSelectorValueLE
        } else {
            subTab = this.radioMap[radioSelectorValue]
        }
        let tabValue
        if (tabSelectorValue == 3 && radioSelectorReconValue == 2) {
            tabValue = "summary"
            subTab = "summary"
        }else if (tabSelectorValue == 3 && payload.sub_grid_header_value == "new"){
            tabValue = "validation"
            subTab = "default"
        }else if (tabSelectorValue == 3 && (radioSelectorReconValue == 4 || radioSelectorReconValue == 5)){
            subTab = payload.sub_tab_value
        }else{
            tabValue = "summary"
        }
        if (radioSelectorValue == 6 ){
            subTab = "so_chart"
            radio = ""
        }
        // console.log(tabValue,subTab,tabSelectorValue,payload.sub_grid_header_value "checkSelector")

        let drilldownPayload = {
            ...payload,
            sub_tab_value: subTab,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            tab_value: tabValue,
            location_radio_value : radioArrCountrySelected == "Default"? radioArrSelected : radioArrCountrySelected,
            location_radio : radio,
            typeSelector: "Summary",
            view: "month"
        }
        this.setState({ invoiceObject: payload
        })
        
        if(radioSelectorReconValue == "6"){
            // this.props.getDropDown(drilldownPayload)
            this.props.getPABValidationdrilldown(drilldownPayload)
            return
        }
     
        if(subTab == "optimus_automation"){
            // this.props.getDropDown(drilldownPayload)
            this.props.getOptimusAutoArilldown(drilldownPayload)
        }else if (tabSelectorValue == 3 && radioSelectorReconValue == 4) {
            // console.log("enterHere")
            this.props.getLawsonDetail(drilldownPayload);
        } else if (payload.sub_grid_header_value == "current"  ) {
            if(drilldownPayload.sub_tab_value == "plant" && radioSelectorValuePlant == "validation"){
                drilldownPayload["bu"] = "MSB"
            }
            // console.log(drilldownPayload,radioSelectorValuePlant, "---this payload")
            this.props.getBUPLInvoiceDetail(drilldownPayload);
        }else  if (radioSelectorValue == 6 ){

            this.props.fetchNewDrilldownChartData(drilldownPayload)
    
    }else {
            console.log(drilldownPayload, "cekPayload")
           
            this.props.fetchNewDrilldownData(drilldownPayload)
        }
    }

    handleBackClick = () => {
        this.setState({
            isDetailedView: false,
            radioArrCountrySelected : "Default",
            radioSelectorValue: this.state.hemisphereClicked.label ? '5' : '3',
            regionClicked: {},
            hemisphereClicked: {}
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleRegionHeaderClick = (payload) => {
        this.setState({
            isDetailedView: true,
            radioSelectorValue: '4',
            regionClicked: payload,
            hemisphereClicked: {}
        }, () => {
            this.handleFetchNewData()
        })
    }
    handleSubHeaderClick = (payload, parentData) => {
        const { qtrSelectorValue, radioSelectorValue, monthSelectorValue, yearSelectorValue, tabSelectorValue} = this.state

        let drilldownPayload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            subHeader : payload.key,
            header: parentData.key,
            subHeaderLabel : payload.label,
            headerLabel: parentData.label,
            radioSelectorValue: radioSelectorValue,
            tabSelectorValue: tabSelectorValue,
        }
        // console.log(drilldownPayload, "checkSubHeaderClick1")
        this.setState({
            showLoader: true,
        }, () => {
        this.props.fetchNewSubHeaderDrilldownData(drilldownPayload)
        })
        // this.setState({
        //     isDetailedView: true,
        //     radioSelectorValue: '4',
        //     regionClicked: payload,
        //     hemisphereClicked: {}
        // }, () => {
        //     this.handleFetchNewData()
        // })
    }
    
    handleHemisphereHeaderClick = (payload) => {
        console.log('heyyyyyyy', payload)
        this.setState({
            // isDetailedView: true,
            radioSelectorValue: '3',
            hemisphereClicked: payload
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleToolTipValue = (index) => {
        let reactComp = (
            <div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p className="mb-0">New orders trend vs revenue is indicative of future growth.</p>
                    <p className="mt-0">Filters allow for focus on respective unit of performance.</p>
                    <p><i>*No of months sales in Orderbook can also be used as a measure.</i></p>
                </div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                    Data required:<br></br>
                    New bookings data for BU 1 and BU 2 businesses appears to be available on the BI reports, this needs to be confirmed and new bookings data for PAB and DGS will be required to enable this chart.
                </div>
            </div>
        );
        if (index == 1) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart</h3>
                        <p> Margin growth analysis, to make sure that:</p>
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>Inflationary cost pressures as a minimum are being passed on the client.</li>
                            <li>Improvement in products and new products are returning higher margins.</li>
                            <li>Product mix is managed to deliver higher margins vs otherwise.</li>
                        </ol>
                        <p>This analysis can be supplemented with a Volume Price analysis by Products and Customers to enable above strategies.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales and COGS data by Customer and Product will be required to enable additional Price Volume analysis to support standard margin management.
                    </div>
                </div>
            );
        }
        if (index == 2) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p>Other costs of sales is most likely fixed costs, and should be monitored to improve as a % of sales with efficiencies or sales growth.</p>
                </div>);
        }
        if (index == 3) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart:</h3>
                        <p>Lead in to market activity / share information.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales by Customer will be required to enable this chart.
                    </div>
                </div>
            );
        }
        if (index == 4) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <p className='mt-0'>
                        Provides overview of key focus areas at a glance.<br></br>Digital solutions can be quickly built to improve performance in areas that are lagging.
                    </p>
                    <p>
                        An automated follow-up email can be sent to respective locations / metrics owners with a cc to the Senior Management Team.<br></br>Refer Overdue AR Automated Follow-up Solution as an example.
                    </p>
                </div>
            )
        }
        return reactComp
    }

    handleExcelDownload = () => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, ocosBU,
            monthSelectorValue, radioSelectorReconValue,radioSelectorValueSO, regionClicked, radioSelectorValuePlant, radioSelectorValueLE, radioArrSelected, radioArrCountrySelected, radioSelectorDynanicDGS,radioSelectorValueDGSAutomation, hemisphereClicked } = this.state;

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: radioSelectorReconValue == 2 ? false : tabSelectorValue == 3,
            export: true,
            reconValue: radioSelectorReconValue,
            apiEndPoint: radioSelectorValue,
            tabValue: tabSelectorValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            countries : radioSelectorDynanicDGS,
            dgsAutomationType :radioSelectorValueDGSAutomation,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined,
            locationRadio : radioArrSelected,
            locationRadioCountry : radioArrCountrySelected,
            radioSelectorValueLE: radioSelectorValueLE,
            buPlant : radioSelectorValuePlant,
            classificationSO : radioSelectorValueSO,
            buOCOSByCountry : ocosBU,
        }

        if(tabSelectorValue != 4){
            this.props.downloadNewPivotData(payload)

        }else {
            this.handleFetchAOPData(true)
        }


    }
    invoiceModalCallBack = () => {
        if(this.state.refreshOnModalClose){
            this.handleFetchNewData()
        }

        this.setState({ arrayDataSelected : [], showInvoiceDetailModal: false, showInvoiceDetailModalChart: false, isInvoiceDetailModal: false, refreshOnModalClose : false });
    }

    setAOPTabState = (payload) => {
        this.setState({
            aopTabState: payload
        })
    }

    fetchNewPieDataFn = () => {

        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, hemisphereClicked } = this.state;
        let payload = {
            tabValue: tabSelectorValue,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            apiEndPoint: radioSelectorValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined

        }

        this.props.fetchNewPieData(payload)

    }

    handlePostManualData = (data) => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, radioSelectorReconValue } = this.state;

        let mainData = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue
        }

        let payload = {
            manualData: { ...data },
            mainData
        }
        this.props.postNewManualAmountData(payload)
    }

    handlePostCommentData = (data) => {
        // let payload = {
        //     commentData: { ...data },
        //     mainData
        // }
        console.log(data, "--ini yang di kirim")
        this.props.postNewComments(data)
        // console.log(data)
    }

    handleChangeView = (event) => {
        // const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
        // if (!updatedData) {
          this.setState({ GraphView: event.target.checked }, this.refreshChart);
        // } else {
        //   this.setState({ showExitModal: true, firstLevelSwitchValue: event.target.checked })
        // }
      };
      toggleCommentModal = (rowData) => {
        let visib = !this.state.commentModal

        this.setState({ commentModal: visib, commentValue: '' , selectedDrilldown: rowData})
    }

    handleCommentSubmitChart = () => {
        // console.log(this.state.selectedDrilldown, "--", this.state.commentValue, "-- 0101")
        let {selectedDrilldown} = this.state
        let bu = selectedDrilldown.bu
        let yearmonth =  selectedDrilldown.yearmonth_new.split("T")[0]
        if(bu == "BU 1"){
            bu = "BU 1"
        }
        if (this.state.commentValue) {
            let commentPayload = {
                comment : this.state.commentValue,
                bu : bu,
                report_date : yearmonth,
                so_item : selectedDrilldown.so_item,
                project_id : selectedDrilldown.project_id,
                comment_id : selectedDrilldown.comment_id,
            }
            
            const { invoiceObject } = this.state;
            let drilldownPayload = {
                    ...invoiceObject
            }

            let payload = {
                comment : commentPayload,
                drilldown : drilldownPayload,
                type : "chart"
            }
            console.log(payload,"-- 010101")
            this.handlePostCommentData(payload)
        }
    }

    handleCommentChangeChart = (event) => {
        this.setState({ commentValue: event.target.value })
    }
    render() {
        const { quarterfilterArr, monthFilterArr, yearFilterArr, countries, radioArr, NewCommentChartLoader } = this.props.incomeStatementState
      
        const { monthSelectorValue, radioSelectorValueLE, radioArrSelected, radioSelectorValueSO, radioArrCountrySelected, radioSelectorValuePlant, DGAAutomationRadioList, radioSelectorValueDGSAutomation, radioSelectorDynanicDGS, radioSelectorValue, radioSelectorReconValue, newRadioSelectorValue, yearSelectorValue, isDetailedView, tabSelectorValue, comparativeSelectorValue, NewTabList, radioData } = this.state;
        // var def = tabSelectorValue == 5 ? (this.props.incomeStatementState.countries[0] != undefined ?  this.props.incomeStatementState.countries[0] : ''): ''
        console.log(monthFilterArr[0], "checkProps")
        return (
            <div className='buplComponent'>
                <div className='flex jc-space-between'>
                {tabSelectorValue == 6 &&
                <Grid container spacing={3}>
                     <Grid item xs={12} sm={4} className='maxw-200'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Month</InputLabel>
                                    <Select
                                        value={monthSelectorValue}
                                        label="Month"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleMonthChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                </Grid>
    }
                    {tabSelectorValue != 6 &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Month</InputLabel>
                                    <Select
                                        value={monthSelectorValue}
                                        label="Month"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleMonthChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={monthFilterArr}
                                    value={monthSelectorValue}
                                    onChange={this.handleMonthChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Month' placeholder='Select'  />
                                    )}
                                />

                            </Grid>
                        
                            <Grid item xs={12} sm={4} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Quarter</InputLabel>
                                    <Select
                                        value={this.state.qtrSelectorValue}
                                        label="Quarter"
                                        disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleQtrChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                                            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={quarterfilterArr}
                                    value={this.state.qtrSelectorValue}
                                    onChange={this.handleQtrChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Quarter' placeholder='Select'  />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={4} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Annual</InputLabel>
                                    <Select
                                        value={yearSelectorValue}
                                        label="Annual"
                                        onChange={this.handleYearChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={yearFilterArr}
                                    value={yearSelectorValue}
                                    onChange={this.handleYearChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Year' placeholder='Select'  />
                                    )}
                                />

                            </Grid>
                        </Grid>
                    }
                    <Grid container justify="flex-end">

                        <Tooltip title={'Download to Excel'} className='pointer'>
                            <img
                                src={DownloadExcel}
                                className='pointer'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleExcelDownload();
                                }} />
                        </Tooltip>
                    </Grid>
                </div>
                <div>
                    <Tabs
                        value={tabSelectorValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className='mt-16 mb-16'
                    >
                        {NewTabList && NewTabList.length ?
                            NewTabList.map((tabItem, index) => {
                                return <Tab label={tabItem.value}  {...this.a11yProps(index)} />
                            })
                            : ''}
                        {/* <Tab label="SUMMARY"  {...this.a11yProps(0)} />
                        <Tab label="DASS"  {...this.a11yProps(1)} />
                        <Tab label="CP"  {...this.a11yProps(2)} />
                        <Tab label="Validation to BU P&L"  {...this.a11yProps(3)} />
                        <Tab label="Analytics (Mockup Only)"  {...this.a11yProps(4)} /> */}

                    </Tabs>
                    <TabPanel value={'a'} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={'b'} index={1}>
                        Item Two
                    </TabPanel>
                    {

                        isDetailedView ?
                            <div className='rowDetails flex ai-center mb-10'>
                                <Button onClick={this.handleBackClick} color="primary" startIcon={<ChevronLeft />}>back</Button>
                                {/* {radioSelectorValue == 1 ? <>
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Plant ID: <b>{detailPlantId}</b></p>
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Plant Name: <b>{plantMetaData.plant_name}</b></p>
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Legal Entity Name: <b>{plantMetaData.legal_entity_name}</b></p>
                                </> : <p className='mt-0 mb-0 ml-20 mr-20'>Country Name: <b>{countryMetaName}</b></p>
                                }
                                <FormControl component="fieldset">
                                    <RadioGroup name="radio" onChange={this.handleToggleChange} value={this.state.checked} className='radioGroupHorizontal'>
                                        <FormControlLabel value="month" control={<Radio color="primary" />} label="Monthly" />
                                        <FormControlLabel value="quarter" control={<Radio color="primary" />} label="Quarterly" />
                                    </RadioGroup>
                                </FormControl> */}
                                <div>
                                    <h3 className="country-view-info">Country View</h3>
                                </div>
                            </div> :
                            tabSelectorValue != 4 && tabSelectorValue != 5 && tabSelectorValue != 6 &&
                        <Grid container spacing={3} className="pl-10">
                            <React.Fragment>
                               
                                <FormControl component="fieldset">
                                    <RadioGroup name="radio" value={radioSelectorValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
                                        {<FormControlLabel value="0" control={<Radio color="primary" />} label="Summary" />}
                                        {(tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && <FormControlLabel value="5" control={<Radio color="primary" />} label="By Hemisphere" />}
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="By Region" />
                                        {tabSelectorValue == 2 && <FormControlLabel value="1" control={<Radio color="primary" />} label="By Plant" />}
                                        {/* {tabSelectorValue == 0 && <FormControlLabel value="2" control={<Radio color="primary" />} label="By ERP" />} */}
        
                                        {<FormControlLabel value="6" control={<Radio color="primary" />} label="SO's with negative margins" />}
                                    </RadioGroup>
                                   
                                </FormControl>
                               
                                {radioSelectorValue == 6 &&
                                 <div className="flex ai-center jc-space-between mb-10">
                            
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueSO} onChange={this.handleRadioChangeSO} className='radioGroupHorizontal'>
                                            {(tabSelectorValue == 0) &&  <FormControlLabel value="summary" control={<Radio color="primary" />} label="Summary" />  }                                              
                                            {(tabSelectorValue == 0 || tabSelectorValue == 1) &&   <FormControlLabel value="New BU 1" control={<Radio color="primary" />} label="New BU 1" /> }
                                            {(tabSelectorValue == 0 || tabSelectorValue == 2) &&  <FormControlLabel value="New BU 2" control={<Radio color="primary" />} label="New BU 2" /> }
                                            </RadioGroup>
                                        </FormControl>
                               

                                
                                    <div className='ml-20'>
                                        <label>Graph</label>
                                        <Switch
                                        className="custom-switch"
                                        checked={this.state.GraphView}
                                        onChange={this.handleChangeView}
                                        color="primary"
                                        name="toggleTableGraph"
                                        />
                                        <label>Table</label>
                                    </div> 
                                    <div>
                                    <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className = "ml-20"
                                endIcon={<Icon>visibility</Icon>}
                                onClick={() => null}
                              >
                                View Email
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className = "ml-20"
                                endIcon={<Icon>send</Icon>}
                                onClick={() => null}
                              >
                                Send Email
                              </Button>
                                    </div>
                                        </div>
                                    }
                                
                                
                               
                                {radioSelectorValue == 1 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValuePlant} onChange={this.handleRadioChangePlant} className='radioGroupHorizontal'>
                                                <FormControlLabel value="summary" control={<Radio color="primary" />} label="BU 1 & BU 2" />
                                                <FormControlLabel value="BU 1" control={<Radio color="primary" />} label="BU 1" />
                                                <FormControlLabel value="BU 2" control={<Radio color="primary" />} label="BU 2" />
                                                <FormControlLabel value="validation" control={<Radio color="primary" />} label="Validation to BU 1 BU Plant P&L" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                }
                                 {radioSelectorValue == 5 || radioSelectorValue == 3   ?
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioArrSelected} onChange={this.handleDynamicRadioArr} className='radioGroupHorizontal'>
                                                        {radioData && radioData.length ?
                                                            radioData.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.key} control={<Radio color="primary" />} label={tabItem.label} />
                                                            })
                                                            : ''}
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div> : ""

                                            }
                               
                            </React.Fragment>
                            </Grid>
                            
                    }
              
                     
                          {isDetailedView == true ?
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioArrCountrySelected} onChange={this.handleDynamicRadioArrCountry} className='radioGroupHorizontal'>
                                                        {radioData && radioData.length ?
                                                            radioData.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.key} control={<Radio color="primary" />} label={tabItem.label} />
                                                            })
                                                            : ''}
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div> : ""

                            }
                            {tabSelectorValue == 4 && 
                            <AOPForecastComponent 
                            monthSelectorValue={monthSelectorValue}
                            qtrSelectorValue={this.state.qtrSelectorValue}
                            yearSelectorValue={yearSelectorValue}
                            setAOPTabState={this.setAOPTabState}
    
                        />
                        }
                    {
                        tabSelectorValue == 3 &&
                        <Grid container spacing={3} className="pl-10">
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={radioSelectorReconValue} onChange={this.handleRadioReconChange} className='radioGroupHorizontal'>
                                    {<FormControlLabel value="0" control={<Radio color="primary" />} label="Default View" />}
                                    {<FormControlLabel value="1" control={<Radio color="primary" />} label="Reconciling Items" />}
                                    {<FormControlLabel value="2" control={<Radio color="primary" />} label="OCOS Validation" />}
                                    {<FormControlLabel value="3" control={<Radio color="primary" />} label="OCOS Allocation Logic" />}
                                    {<FormControlLabel value="4" control={<Radio color="primary" />} label="ERP Automation 1" />}
                                    {<FormControlLabel value="5" control={<Radio color="primary" />} label="ERP Automation 2" />}
                                    {<FormControlLabel value="6" control={<Radio color="primary" />} label="ERP Automation 3" />}
                                    {<FormControlLabel value="7" control={<Radio color="primary" />} label="By ERP" />}
                                </RadioGroup>
                            </FormControl>
                                {radioSelectorReconValue == 4 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueDGSAutomation} onChange={this.handleRadioDGSAutomation} className='radioGroupHorizontal'>
                                                <FormControlLabel value="lawson" control={<Radio color="primary" />} label="Lawson" />
                                                <FormControlLabel value="slbsap" control={<Radio color="primary" />} label="SLB SAP" />
                                                <FormControlLabel value="ifs" control={<Radio color="primary" />} label="IFS" />
                                                <FormControlLabel value="new" control={<Radio color="primary" />} label="new SAP" />
                                                <FormControlLabel value="total" control={<Radio color="primary" />} label="Total" />
                                            </RadioGroup>
                                        </FormControl>
                                            {radioSelectorReconValue == 4 && radioSelectorValueDGSAutomation != "total" &&
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioSelectorDynanicDGS} onChange={this.handleDynamicRadioDGSAutomation} className='radioGroupHorizontal'>
                                                        {countries && countries.length ?
                                                            countries.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.code} control={<Radio color="primary" />} label={tabItem.name} />
                                                            })
                                                            : ''}
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div>

                                            }
                                    </div>
                                }

{radioSelectorReconValue == 7 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueLE} onChange={this.handleRadioChangeLE} className='radioGroupHorizontal'>
                                                <FormControlLabel value="summaryLE" control={<Radio color="primary" />} label="Summary for all LE's" />
                                                <FormControlLabel value="non7400view" control={<Radio color="primary" />} label="new Co Codes (except 7400)" />
                                                <FormControlLabel value="7400view" control={<Radio color="primary" />} label="Co Code 7400" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                }
                         
                        </React.Fragment>
                        </Grid>
                    }
                       
                </div>

                {tabSelectorValue != 4 && tabSelectorValue != 5 && radioSelectorReconValue != 3 && radioSelectorValue != 6 &&
                    <NewSummaryTableComponent
                        {...this.props}
                        tabSelectorValue={tabSelectorValue}
                        radioSelectorValueDGSAutomation = {radioSelectorValueDGSAutomation}
                        radioSelectorValue={radioSelectorValue}
                        radioSelectorDynanicDGS={radioSelectorDynanicDGS}
                        radioSelectorReconValue={radioSelectorReconValue}
                        handleRegionHeaderClick={this.handleRegionHeaderClick}
                        handleSubHeaderClick={this.handleSubHeaderClick}
                        handleHemisphereHeaderClick={this.handleHemisphereHeaderClick}
                        handleSaveDrilldownChanges={this.handleSaveDrilldownChanges}
                        handlePropsInvoiceClick={this.handleInvoiceClick}
                        handlePropsGetDropdown={this.handleGetDropdown}
                        fetchNewPieData={this.fetchNewPieDataFn}
                        postManualAmountData={this.handlePostManualData}
                        postCommentData={this.handlePostCommentData}
                        fetchMainData={this.handleFetchNewData}
                        radioSelectorValuePlant={radioSelectorValuePlant}
                        radioArrSelected = {radioArrSelected}
                        curMonth = {monthFilterArr[0]}
                        monthSelected = {monthSelectorValue}
                        radioArrCountrySelected = {radioArrCountrySelected}
                    />}
                    { radioSelectorValue == 6 &&
                    <ChartContainer
                    {...this.props}
                    chartData={this.state.chartDataSO}
                    tableData={this.state.TableDataSO}
                    headerTable={this.state.HeaderTableDataSO}
                    additionalData = {this.state.additionalData}
                    handleInvoiceClick={this.handleInvoiceClick}
                    graphView = {this.state.GraphView}
                    toggleCommentModal={this.toggleCommentModal}
                    loading={this.props.incomeStatementState.chartLoading}
                    // chartDataUnbilled={this.state.chartDataUnbilled}
                    // chartTitleUnbilled={this.state.chartTitleUnbilled}
                    // chartDataDSOBUWise={this.state.chartDataDSOBUWise}
                    // chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
                    // externalTabValue={externalTabValue}
                  >
                  </ChartContainer>

                  
    }
    {this.state.commentModal &&
            <Modal
                                open={this.state.commentModal}
                                onClose={this.toggleCommentModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.commentModal}>
                                    <div className='customModal dataValidationCommentModal'>
                                    {NewCommentChartLoader && <div className='progressfullpage'><CircularProgress /></div>}
                                        <div className={'header'}>
                                            <h2>Add New Explanatory Comment</h2>
                                            <Icon onClick={this.toggleCommentModal}>close</Icon>
                                        </div>
                                        <div className={'modal-content'} style={{ padding: '30px' }}>
                                            <TextField
                                                fullWidth
                                                label="Comment"
                                                multiline
                                                rows={10}
                                                // value={this.state.commentValue}
                                                onBlur={this.handleCommentChangeChart}
                                                variant="filled"
                                            />
                                            <div className="flex jc-flex-end mt-30">
                                                <Button variant="contained" color="primary" onClick={this.handleCommentSubmitChart}>Submit</Button>
                                                <Button variant="contained" className='ml-16' onClick={this.toggleCommentModal}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </Modal>
        }
{this.state.showInvoiceDetailModalChart &&
                    <InvoiceDetailModal
                        showModal={this.state.showInvoiceDetailModalChart}
                        invoiceDetailState={this.state.invoiceDetailStateNew}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailStateFilter={this.state.invoiceDetailFilter}
                        isExpandable={true}
                        showGlDrilldownFilters={false}
                        showAmountFilters={false}
                        toggleCommentModal={this.toggleCommentModal}
                        NewChart={true}
                    />
                }
{ radioSelectorReconValue == 3 &&
 <div className='flex ai-flex-start'>
    <img width="100%" className="mr-10" src={this.imgOcos[0]} style={{ maxWidth: '900px', height: '600px' }} />
    {/* {this.handleToolTipValue(newRadioSelectorValue)} */}
</div>
    }
                {tabSelectorValue == 5 &&
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                <FormControlLabel value="4" control={<Radio color="primary" />} label="Dashboard" />
                                {<FormControlLabel value="0" control={<Radio color="primary" />} label="Growth" />}
                                {<FormControlLabel value="1" control={<Radio color="primary" />} label="Margin Analysis" />}
                                {<FormControlLabel value="2" control={<Radio color="primary" />} label="Economies of Scale" />}
                                <FormControlLabel value="3" control={<Radio color="primary" />} label="Customer Change Analysis" />

                            </RadioGroup>
                        </FormControl>

                    </React.Fragment>}
                {
                    tabSelectorValue == 5 &&
                    <div className='flex ai-flex-start'>
                        <img width="100%" className="mr-10" src={this.imgMap[newRadioSelectorValue]} style={{ maxWidth: '900px', height: '600px' }} />
                        {this.handleToolTipValue(newRadioSelectorValue)}
                    </div>
                }
            </div>
        );
    }
}

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducerState: state.authReducer,
            }
        },
        dispatch => {
            return {
                getNewDefaultMonthData() {
                    dispatch({
                        type: 'FETCH_New_DEFAULT_MONTH'
                    });
                },
                getNewDefaultMonthDataDynamic(data) {
                    dispatch({
                        type: 'FETCH_New_DEFAULT_MONTH_DYNAMIC',
                        payload: data
                    });
                },
                getNewPivotData(data) {
                    dispatch({
                        type: 'FETCH_New_PIVOT_ALL',
                        payload: data

                    });
                },
                getNewOCOSSummary(data) {
                    dispatch({
                        type: 'FETCH_New_OCOS_SUMMARY',
                        payload: data

                    });
                },
                getAOPTableData(data) {
                    dispatch({
                        type: 'FETCH_AOPDESTINATION_TABLE_DATA',
                        payload: data
                    });
                },
                getDGSLawson(data) {
                    dispatch({
                        type: 'FETCH_New_DGS_LAWSON',
                        payload: data

                    });
                },
                getChartSO(data) {
                    dispatch({
                        type: 'FETCH_New_CHART_SO',
                        payload: data

                    });
                },
                getOptimusAutomation(data) {
                    dispatch({
                        type: 'FETCH_New_OPTIMUS_AUTOMATION',
                        payload: data

                    });
                },
                downloadNewPivotData(data) {
                    dispatch({
                        type: 'DOWNLOAD_EXCEL_New_PIVOT_ALL',
                        payload: data

                    });
                },
                getBUPLInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_BUPL_INVOICE_DETAIL',
                        payload: data
                    });
                },
                getLawsonDetail(data) {
                    dispatch({
                        type: 'FETCH_LAWSON_DETAIL',
                        payload: data
                    });
                },
                getDropDown(data) {
                    dispatch({
                        type: 'FETCH_DROPDOWN',
                        payload: data
                    });
                },
                getOptimusAutoArilldown(data) {
                    dispatch({
                        type: 'FETCH_OPTIMUS_AUTO_DETAIL',
                        payload: data
                    });
                },
                getPABValidationdrilldown(data) {
                    dispatch({
                        type: 'FETCH_PAB_VALIDATION_DETAIL',
                        payload: data
                    });
                },
                getUserDetail() {
                    dispatch({
                        type: 'GET_USER_DETAILS'
                    });
                },
                fetchNewSubHeaderDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_SUB_HEADER_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewDrilldownChartData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_CHART_DATA',
                        payload: data
                    });
                },
                saveDrilldownChanges(data) {
                    dispatch({
                        type: 'SAVE_New_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewPieData(data) {
                    dispatch({
                        type: 'FETCH_New_PIE_CHART_DATA',
                        payload: data
                    });
                },
                postNewManualAmountData(data) {
                    dispatch({
                        type: 'POST_new_MANUAL_DATA',
                        payload: data
                    });
                },
                postNewComments(data) {
                    dispatch({
                        type: 'POST_new_COMMENT_DATA',
                        payload: data
                    });
                },
            };
        }
    )(NewComponent);
