import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting"
import highchartsOfflineExporting from "highcharts/modules/offline-exporting"

highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});
function HighChart(props) {

let options = props.options
options = {...options, 
    credits: {
    enabled: false
}
}

    return  (
      <div className="mat-shadow-2 labelClass analytic-studio-chart">
        <HighchartsReact
            highcharts={Highcharts}
            options = {options}
        />
      </div>
  );
}

export default HighChart;
