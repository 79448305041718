import React from 'react';
import { useState } from 'react';
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";
import ReactTable from 'react-table-6';
import FormControl from "@material-ui/core/FormControl";
import Button from '@material-ui/core/Button';
//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";
import TableComponent from '../../TableComponent';

let savePayload = {};

export class KpiFormComponent extends React.Component {
    constructor(props) {
        super(props);

         this.arrMonthly = [
            {"key": "apr23", "label": "Apr-23"},
            {"key": "may23", "label": "May-23"},
            {"key": "jun23", "label": "Jun-23"},
            {"key": "jul23", "label": "Jul-23"},
            {"key": "aug23", "label": "Aug-23"},
            {"key": "sep23", "label": "Sep-23"},
            {"key": "oct23", "label": "Oct-23"},
            {"key": "nov23", "label": "Nov-23"},
            {"key": "dec23", "label": "Dec-23"},
            {"key": "jan24", "label": "Jan-24"},
            {"key": "feb24", "label": "Feb-24"},
            {"key": "mar24", "label": "Mar-24"}
        ]

        this.hdpArr = [
            {"key": "monthly", "label": "Monthly"},
            {"key": "quarterly", "label": "Quarterly"},
            {"key": "yearly", "label": "Yearly"},
        ]

        this.ignoreList = [
            "Total Variable cost of cement Per T",
            "EBIDTA Per T",
            "EBIDTA (In '000s)",
        ]
        
        this.state = {
            tableHeaderData: [],
            tableRowData: [],
            loading : false,
            planDetails: {},
            hdpArr: this.hdpArr,
            noOfMonthArr: [],
            noOfMonthArrBudget: [],
            hdpValue: {"key": "", "label": ""},
            hdpValueBudget: {"key": "", "label": ""},
            periodValues: [],
            periodValuesBudget: []
        }
    }    

    componentDidMount() {
        const items = JSON.parse(localStorage.getItem('edit_plan'));

        if(items){
            if(items.is_new){
                this.fetchKPIPlanningData({"is_new" : true})
            }else{
                this.fetchKPIPlanningData({"_id" : items.id ,"first_load" : true})
            }
            localStorage.setItem('edit_plan', null);
        }else{
            this.fetchKPIPlanningData({"first_load" : true})
        }
    }

    fetchKPIPlanningData = (payload) => {
        this.props.fetchKPIPlanning(payload)
        // this.props.dtkpi(tableRowData.map(el => {
        //     return {"key": el.key, "kpi": el.kpi};
        // }))
    }

    
    componentDidUpdate(prevProps) {
        const {rowData, rowHeader, loading, planDetails} = this.props.functionalPlanningState.KPIData
        // console.log(prevProps.functionalPlanningState.KPIData.loading, this.props.functionalPlanningState.KPIData.loading, "--props loading")
        
        if (prevProps.functionalPlanningState.KPIData.editLoading && !this.props.functionalPlanningState.KPIData.editLoading) {
            this.handleFilter();
        }

        if (prevProps.functionalPlanningState.KPIData.loading && !this.props.functionalPlanningState.KPIData.loading) {
            this.setState({
                tableHeaderData : rowHeader,
                tableRowData : rowData,
                loading : loading,
            })

            this.props.dtkpi(rowData.map(el => {
                return {"key": el.key, "kpi": el.kpi};
            }).filter(x=>{ return  this.ignoreList.indexOf(x.kpi) == -1   })
            )

            if (planDetails) {
                let filterObj = {"_id": planDetails._id, "budget_value" : planDetails.budget_value} // this filter is for save pop up
                this.setState({planDetails : planDetails})
                this.props.plandetails(planDetails)

                if( this.state.planDetails._id == planDetails._id || planDetails._id == "000000000000000000000000"){
                    return
                }

                

                let hdp = this.hdpArr.filter(x=>{ return x.key == planDetails.period_type })
                if(hdp.length > 0){
                    filterObj["period_type"] =  hdp[0].key
                    this.handleSetHdpChange(null,  hdp[0]) 
                }

                let period = this.arrMonthly.filter(x=>{ return planDetails.period.indexOf(x.label) > -1 })
                if(period.length > 0){
                    let perValues = period.map(el => {
                        return el.label;
                    })
                    filterObj["period"] = perValues
                    this.handleSetPeriodValues(null, period) 
                }

                let budget = this.arrMonthly.filter(x=>{ return planDetails.budget_period.indexOf(x.label) > -1 })
                if(period.length > 0){
                    let perValues = budget.map(el => {
                        return el.label;
                    })
                    filterObj["budget_period"] = perValues
                     this.handleSetPeriodValuesBudget(null, budget) 
                }

                 // e.preventDefault();

                this.props.filters(filterObj)
                // this.props.filters({
                //     "_id": planDetails._id,
                //     "period_type": planDetails.period_type,
                //     "period": planDetails.period,
                //     "budget_period": planDetails.budget_period
                // })
            }
        }
    }

    handleSetHdpChange(event, newvalue) {
        this.setState({ hdpValue: newvalue, hdpValueBudget : newvalue })
        switch (newvalue.key) {
            case "monthly":
                
                this.setState({ noOfMonthArr: this.arrMonthly, noOfMonthArrBudget: this.arrMonthly })
                break;
            case "quarterly":
                let quarterly = [
                    {"key": "q1", "label": "Q1"},
                    {"key": "q2", "label": "Q2"},
                    {"key": "q3", "label": "Q3"},
                    {"key": "q4", "label": "Q4"},
                ]
                this.setState({ noOfMonthArr: quarterly, noOfMonthArrBudget: quarterly})
                break;
            case "yearly":
                this.setState({ noOfMonthArr: [
                    {"key": "fy23", "label": "FY-23"},
                ], noOfMonthArrBudget: [
                    {"key": "fy23", "label": "FY-23"},
                ] })
                break;
            default:
                this.setState({ noOfMonthArr: [], noOfMonthArrBudget: []})
                break;
        }
    }

    handleSetPeriodValues(event, newvalue) {
        let pvals = []
        let pvalKeys = []
        newvalue.forEach((item, index) => {
            pvals.push(item.label)
            pvalKeys.push(item.key)
        })

        let budgetPeriod = this.arrMonthly.filter(x=>{ return pvalKeys.indexOf(x.key) == - 1 })

        if(event != null){
            this.setState({ noOfMonthArrBudget : budgetPeriod, periodValues: newvalue}, this.handleFilter)
        }else{
            this.setState({ noOfMonthArrBudget : budgetPeriod, periodValues: newvalue})
        }
    }

    handleSetPeriodValuesBudget(event, newvalue) {
        if(event != null){
            this.setState({ periodValuesBudget: newvalue}, this.handleFilter)
        }else{
            this.setState({ periodValuesBudget: newvalue})
        }
    }


    handleFilter() {
        // e.preventDefault();
        const { hdpValue, periodValues, periodValuesBudget, planDetails } = this.state
        let perValues = periodValues.map(el => {
            return el.label;
        })
        let perValuesBudget = periodValuesBudget.map(el => {
            return el.label;
        })

        this.props.filters({
            "_id": planDetails._id,
            "period_type": hdpValue.key,
            "period": perValues,
            "budget_period": perValuesBudget,
            "budget_value" : planDetails.budget_value
        })
        
        let payload = {
            "_id": planDetails._id,
            "period_type" : hdpValue.key,
            "period" : perValues,
            "budget_period": perValuesBudget,
            "export": false
        }
        this.fetchKPIPlanningData(payload)
    }
    
    handleClear() {  
        this.setState({
            hdpValue: {"key": "", "label": ""},
            periodValues: [],
            periodValuesBudget: []
        })
        this.fetchKPIPlanningData({"first_load" : true})
    }

    handleColumnDataClick(_data, parentData, itemData) {
        // console.log(_data, parentData, itemData);
    }

    handleSaveChange(_value, props, item, parentData) {
        const uniqKey = props.key + '_' + [parentData.key] + '_' + [item.key]
        // console.log("_value : ",_value, " :props: ",props, " :item: ", item, " :parentData: ", parentData , " ::uniqKey: ",uniqKey);
        savePayload[uniqKey] = { "key" : props.key, "month" : item.label, "value" : Number(_value) }
    }

    handleSaveInlineEditing() {
        const {planDetails} = this.state;
        const budgetValueInline = []
        Object.keys(savePayload).forEach(item => {
            budgetValueInline.push(savePayload[item])
        })
        let payload = {
            "_id": planDetails._id,
            "budget_value_inline": budgetValueInline
        }
        this.props.saveKPIBudgetInline(payload)
        this.handleCancelInlineEditing()
    }

    handleCancelInlineEditing() {
        this.props.cancelInlineEditing(true);
    }
    
    render() {
        const {isEditData} = this.props;
        const {tableHeaderData, tableRowData, loading, hdpArr, noOfMonthArr, hdpValueBudget, hdpValue, periodValues, noOfMonthArrBudget, periodValuesBudget} = this.state;
        
        const SelectDropdownMulti = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder }) => {
            return (
                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <Autocomplete
                        multiple
                        className = 'date-selection new-autocomplete'
                        fullWidth
                        disablePortal
                        disableListWrap
                        size='small'
                        value={selectedValue}
                        options={menuItems}
                        onChange={handleChange}
                        getOptionLabel={(option)=> option.label}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        renderInput={(params) => (
                            <TextField
                                className="test-input-box"
                                {...params}
                                variant="standard"
                                label = {inputLabel}
                                placeholder={placeHolder}
                            />
                            )}                                                       
                    />
                </FormControl>
            );
        };

        const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder, dis }) => {
            return (
                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <Autocomplete
                        className = 'date-selection new-autocomplete'
                        disabled={dis}
                        fullWidth
                        disablePortal
                        disableListWrap
                        size='small'
                        value={selectedValue}
                        options={menuItems}
                        onChange={handleChange}
                        getOptionLabel={(option)=> option.label}
                        renderInput={(params) => (
                            <TextField
                                className="test-input-box"
                                {...params}
                                variant="standard"
                                label = {inputLabel}
                                placeholder={placeHolder}
                            />
                            )}                                                       
                    />
                </FormControl>
            );
        };

        
        return (
            <React.Fragment>
                <Grid container xs={12} sm={12} justifyContent='left'>
                    <Grid item xs={12} sm={12}>
                        {/* <div className='top-header'>
                            <div>
                                <b>Select Plans To Compare</b>
                            </div>
                        </div> */}

                        <Grid  container className="dropdown-filter">
                        <Grid container xs={6} className="dropdown-filter" >
                            <Grid item xs={3} className="maxw-200 mr-10">
                                <SelectDropdown
                                        menuItems={hdpArr}
                                        handleChange={(e, val) => this.handleSetHdpChange(e, val)}
                                        inputLabel={'Historical Data Period'}
                                        selectedValue={hdpValue}
                                        placeHolder={'Select Historical Data Period'}
                                    />
                            </Grid>
                            <Grid item xs={4} className="maxw-300 ">
                                <SelectDropdownMulti
                                        menuItems={noOfMonthArr}
                                        handleChange={(e, val) => this.handleSetPeriodValues(e, val)}
                                        inputLabel={'No Of Month'}
                                        selectedValue={periodValues}
                                        placeHolder={'Select No Of Month'}
                                    />
                            </Grid>
                        </Grid>
                        <Grid container xs={6} className="dropdown-filter" style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <Grid item xs={3} className="maxw-200 mr-10">
                                <SelectDropdown
                                        dis={true}
                                        menuItems={hdpArr}
                                        // handleChange={(e, val) => setPlanTypeValues(val)}
                                        inputLabel={'Budget Data Period'}
                                        selectedValue={hdpValueBudget}
                                        placeHolder={'Select Budget Data Period'}
                                    />
                            </Grid>
                            <Grid item xs={4} className="maxw-300 ">
                                <SelectDropdownMulti
                                        menuItems={noOfMonthArrBudget}
                                        handleChange={(e, val) => this.handleSetPeriodValuesBudget(e, val)}
                                        inputLabel={'Budget No Of Month'}
                                        selectedValue={periodValuesBudget}
                                        placeHolder={'Select Budget No Of Month'}
                                    />
                            </Grid>
                            </Grid>
                            {/* <Button className="newButton btnBlue mr-10"
                                variant="outlined" color="primary"
                                onClick={this.handleFilter.bind(this)}>
                                    OK
                            </Button>
                            <Button className="newButton btnBlue mr-10"
                                variant="outlined" color="primary"
                                onClick={this.handleClear.bind(this)}>
                                    RESET
                            </Button> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='planningModelsTable-container mt-10'>
                        <TableComponent
                            isEditData={isEditData}
                            rowHeader={tableHeaderData}
                            rowData={tableRowData}
                            loading={loading}
                            handleColumnDataClick={this.handleColumnDataClick}
                            handleSaveChange={this.handleSaveChange}
                        />
                    </Grid>
                    {isEditData ? <Grid item xs={12} sm={12} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', margin:'10px 0'}}>
                        <Button className="newButton btnBlue mr-10"
                            variant="outlined" color="primary"
                            onClick={this.handleSaveInlineEditing.bind(this)}>
                                SAVE
                        </Button>
                        <Button className="newButton btnBlue mr-10"
                            variant="outlined" color="primary"
                            onClick={this.handleCancelInlineEditing.bind(this)}>
                                CANCEL
                        </Button>
                    </Grid> : ''}
                    
                </Grid>
                {/* <Grid container justifyContent='left'>
                    <Grid item xs={12} sm={12}>
                      
                    </Grid>
                </Grid> */}
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                functionalPlanningState : state.functionalPlanningState
                }
        },
        dispatch => {
            return {
                fetchKPIPlanning(data) {
                    dispatch({
                      type: 'FETCH_KPI_PLANNING',
                      payload: data,
                    });
                  },
                saveKPIBudgetInline(data) {
                    dispatch({
                      type: 'SAVE_KPI_BUDGET_PLANNING_INLINE',
                      payload: data,
                    });
                  },
            };
        }
    )(KpiFormComponent);
