import React from 'react';
import { Switch, Route } from "react-router-dom";
import LoginPage from "./Login";
import Appbar from './Appbar';
import Panel from "./Panel";
import Sidebar from "./Sidebar";


import "./index.scss";

function RouteProfitabaility() {
    const [breadcrumbsList, setBreadcrumbsList] = React.useState([]);
    const [showMenu, setShowMenu] = React.useState(false)
    const [activeMenu, setActiveMenu] = React.useState("");


    const setShowingMenu = (isShow) =>{
        setShowMenu(isShow)
    }

    const setMenuActive = (menu) =>{
        setActiveMenu(menu)
        console.log(activeMenu)
    }

    return (
         <main className='route-profitability'>
            <Switch>
                <Route path="/route-profitability/login" exact>
                    <LoginPage/>
                </Route>
                 <Route path="/route-profitability/panel" exact>
                    <Appbar />
                    <Panel setShowMenu={setShowingMenu} setActiveMenu={setMenuActive}/>
                </Route>
                <Route path="/route-profitability/">
                    <Appbar setShowMenu={setShowingMenu} showMenu={showMenu} activeMenu={activeMenu} setActiveMenu={setMenuActive}/>
                    <Sidebar activeMenu={activeMenu}/>
                </Route>
            </Switch>
        </main>
        
    )
}

export default RouteProfitabaility;