export const transformFilterData = (filterData) => {

  let filterObj = {
    quarterfilterArr: [],
    monthFilterArr: [],
    yearFilterArr: [],
    plantArr: [],
    countryArr: [],
    legalEntityArr: [],
    radioArr: [],
    periodArr: [],
    buArr: []
  }

  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'yearmonth') {
        filterObj.monthFilterArr = item.value
      }
      if (item.key == 'quarter') {
        filterObj.quarterfilterArr = item.value
      }
      if (item.key == 'year') {
        filterObj.yearFilterArr = item.value
      }
      if (item.key == 'legal_entity') {
        filterObj.legalEntityArr = item.value
      }
      if (item.key == 'plant') {
        filterObj.plantArr = item.value
      }
      if (item.key == 'country') {
        filterObj.countryArr = item.value
      }
      if (item.key == 'radio') {
        filterObj.radioArr = item.value
      }
      if (item.key == 'period') {
        filterObj.periodArr = item.value
      }
      if (item.key == 'bu') {
        filterObj.buArr = item.value
      }
    })
  }
  return filterObj
}
export const OrderDataPAB = (_data) => {
  let bu = ['North America', 'LAR', 'EMEA', 'APAC', '']
  _data.sort((a, b) => bu.indexOf(a.group) - bu.indexOf(b.group) );
  return _data
}
export const OrderData = (_data, budgetGroupFilter) => {
  // let filterArr = []
  let bu = ['BU 1', 'BU 2', 'BU 3', 'BU 4', 'OH', 'Total Cost']
  let sortedData = Object.keys(_data).sort(
    
    (a, b) =>  console.log(b, "testzzz")
    // (a, b) => bu.indexOf(a) - bu.indexOf(b)
  )
 
if (budgetGroupFilter == "SummaryView") {

  let cat = ['Technology', 'Sales', 'Proposals', 'Marketing', 'Total Cost']
  let newbu = ['BU 1', 'BU 2', 'BU 3', 'BU 4', 'OH', 'Total Cost']
  let keyAct = ["K01 - Salaries & Wages", "K01a - Overtime Salaries", "K02 - Benefits", "K03 - Commissions, Bonus & Recognition", "K04 - Recruiting & Relocation","K05 - Miscellaneous", "K06 - Supplies, Equipment & Telecom", "K07 - Repairs & Maintenance","K08 - Outside Services & Professional Fees", "K09 - Vehicles", "K10 - Travel & Entertainment", "K11 - Marketing & Advertising", "K12 - Occupancy & Facilities", "K13 - Depreciation", "K13 - Depreciation & Amortization", "K14 - Projects & Labor Recovery", "K15 - Other Allocations", "K16 - Interco Recharges", "zBlank Key Account"]
    _data.sort((a, b) => cat.indexOf(a.data_field) - cat.indexOf(b.data_field) );
  _data.forEach(x => {
   
    let subrows = x.sub_rows
    if(x.data_field != "Total Cost") {
      subrows.sort((a, b) => newbu.indexOf(a.data_field) - newbu.indexOf(b.data_field) );
      subrows.map(y => {
        if(y.data_field == "MSB"){
          y.data_field = "BU 1"
        }
        let subSubRows = y.sub_rows
        subSubRows.sort((a, b) => keyAct.indexOf(a.data_field) - keyAct.indexOf(b.data_field) );
        y.sub_rows.map(z => {
         
          if(z.data_field == "zBlank Key Account"){
            z.data_field = "Blank Key Account"
          }
        })
      })
       
    }
   
     
   
  })
}else{
  _data.sort((a, b) => bu.indexOf(a.data_field) - bu.indexOf(b.data_field) );

  _data.map(x => {
    if(x.data_field == "MSB"){
      x.data_field = "BU 1"
    }
  })
}
  


 
  // for (var keys in _data) {
  //   for (var keyss in keys) {
  //     console.log(keyss, "testKeyss")
  //   }
   
  // }
  return _data
}

export const transformExcludeFilterData = (_data) => {
  let filterArr = []
  if (_data && _data.length) {
    _data.forEach(item => {
      if (item.label) {
        filterArr.push(item)
      }
    })
  }
  return filterArr
}


export const transformDefaultHeaderValues = (_data) => {
  const newData = JSON.parse(JSON.stringify(_data))
  const dummyArr = []
  newData.forEach((item, index) => {
    if (index == 0){
      dummyArr.push(item)
    }else{
      item.key = item.key.toUpperCase()
      dummyArr.push(item)
    }
  })

  return dummyArr.slice(0,4)
}

export let changeValuesOfData = (data) => {
  if (data && data.length) {
    let transformedData = data.map((item) => {
      if (item.commentArr == undefined) {
        item['commentArr'] = [];
        item['isChatConnection'] = false;
        item['obj'] = {};
      }

      return item;
    });

    return transformedData;
  }
  return [];
};

export const setAllRowCommentHistoryData = (rowData, chatData) => {

  return rowData.map(item => {

      if(chatData[item.channel]){
          item['commentArr'] = chatData[item.channel]
      }

      return item
  })

}
