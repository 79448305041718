
export let changeValuesOfData = (data) => {
  if (data && data.length) {
    let transformedData =  data.map((item) => {
      if (item.commentArr == undefined) {
        item['commentArr'] = [];
        item['isChatConnection'] = false;
        item['obj'] = {};
      }

      return item;
    });

    return transformedData;
  }

  return [];
};


export let appendChannelId = (data, invoice_no, channelId) => {
  let returnData = {
    updatedItem: {},
    transformedArr: []
  }

  let transformedData = []
  if (data && data.length) {
    transformedData =  data.map((item) => {
      if (item.document_number == invoice_no) {
        item['channel'] = channelId;

        returnData['updatedItem'] = item;
      }

      return item;
    });

    returnData['transformedArr'] = transformedData
  }

  return returnData;
};

export let appendActionOwner = (data, payload) => {
 
  let transformedData = []
  if (data && data.length) {
    transformedData =  data.map((item) => {
      if (item.document_number == payload.invoice_no) {
        item['action_owner'] = payload.action_owner;
      }
      return item;
    });
  }

  return transformedData;
};


export const changeCollectionForecastData = (rowData, payload) => {

  return rowData.map(item => {
    if(item.company_code == payload.company_code && item.customer == payload.customer){

      item['collection_forecast_confirmed_by'] = payload['collection_forecast_confirmed_by']
      item['collection_forecast_date'] = payload['collection_forecast_date']
      item['collection_forecast'] = payload['collection_forecast']
    }

    return item

  })

}

export const changeCollectionInvoiceForecastData = (rowData, payload) => {

  return rowData.map(item => {
    console.log(item.company_code , payload.company_code , item.customer , payload.customer , item.document_number , payload.invoice_no)
    if(item.company_code == payload.company_code && item.customer == payload.customer && item.document_number == payload.invoice_no){
      item['collection_forecast_confirmed_by'] = payload['collection_forecast_confirmed_by']
      item['collection_forecast_date'] = payload['collection_forecast_date']
      item['collection_forecast'] = payload['collection_forecast']
    }

    return item

  })

}


export const setAllRowCommentHistoryData = (rowData, chatData) => {

  return rowData.map(item => {

      if(chatData[item.channel]){
          item['commentArr'] = chatData[item.channel]
      }

      return item
  })

}


export const transformFilterData = (filterData) => {

  let filterObj = {
    yearMonthArr: [],
    agingCategoryArr: [],
    actionOwnerArr: [],
    customerNameArr: [],
    regionArr: []
  }

  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'yearmonth') {
        filterObj.yearMonthArr = item.value
      }
      if (item.key == 'aging_category') {
        filterObj.agingCategoryArr = item.value
      }

      if (item.key == 'action_owner') {
        filterObj.actionOwnerArr = item.value
      }

      if (item.key == 'customer_name') {
        filterObj.customerNameArr = item.value
      }

      if (item.key == 'region') {
        filterObj.regionArr = item.value
      }

    })
  }
  return filterObj

}