import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_COLORS } from '../../../constant';
import { numberWithCommas } from '../../../utils/numberUtils'
import { useSelector } from "react-redux";

function HighChart() {
  const {gapToTarget: {bu, loc}} = CHART_COLORS;
  const ecfmState = useSelector(state => state.ecfmState);
  const {loading,  chartRowData} = ecfmState
// console.log(chartRowData, "--chart row data")

  const numbers = []
  var rowData = chartRowData?.data

  for (var idx in rowData) {
    var data = rowData[idx].data

    for (var data_idx in data) {
      var target = data[data_idx].target
      var current = data[data_idx].current

      numbers.push(target)
      numbers.push(current)
    }
  }

  var max_yaxis = Math.max.apply(Math, numbers.map(function(o) { return o; }))


  return chartRowData?.data && chartRowData?.data?.length > 0 ? (
      <div className="mat-shadow-2 labelClass">
        <HighchartsReact
            highcharts={loading ? null : Highcharts}
            options={
              {
                chart: {
                  type: 'column',
                },
                title: {
                  // text: function(){
                  //   return <div>
                  //                <div className="dot mr-5" style={{background: bu}}/> <span>Gap To Target</span>
                  //                <div className="dot ml-10 mr-5" style={{background: loc}}/> <span>Current FCF</span>
                  //        </div>
                  // },
                  text:'',
                },
                xAxis: {
                  categories: chartRowData.x_axis,
                },   
                yAxis: {
                  max: Math.ceil(max_yaxis),
                  // min: 0,
                  // tickInterval: 10,
                  title: {
                    text: '',
                  },
                  // gridLineWidth: 0,
                  // minorGridLineWidth: 0,
                },
                credits: {
                  enabled: false
                },

                tooltip: {
                  formatter: function () {
                      return '<b>' + this.x + ' - '+ this.series.name+ ' </b> <br/> • ' + 
                      "Current - $" +  numberWithCommas(this.point.current) + '<br/> • ' +
                      "Impact - $" +  numberWithCommas(this.point.gap) + '<br/> • ' +
                      "Target - $" +  numberWithCommas(this.point.target) ;
                  }
                },  

                legend: {
                  align: 'center',
                  verticalAlign: 'top',
                  shadow: false,
                  labelFormatter: function () { 
                        return this.name; 
                  } 
                  // enabled: false,
                },

                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                series: chartRowData.data,
                // [ 

                //   { 
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       formatter: function() {
                //         if(this.y == 37){
                //           return 'BU 1'
                //         }
                //       },
                //       y: -85
                //     },

                //     name: 'B1',
                //     data: [37, 30, 30],
                //     stack: 'Locn 1',
                //     color: bu,
                    
                //   },
                //   {
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       formatter: function() {
                //         if(this.y == 60){
                //           return 'BU 2'
                //         }
                //       },
                //       y: -120
                //      },
                //     name: 'B2',
                //     data: [60,15,11],
                //     stack: 'Locn 2',
                //     color: bu,
                //     showInLegend: false
                //   }, 
                //   {
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       formatter: function() {
                //         if(this.y == 50){
                //           return 'BU 3'
                //         }
                //       },
                //       y: -100
                //      },
                //     name: 'B3',
                //     data: [50,4,10],
                //     stack: 'Locn 3',
                //     color: bu,
                //     showInLegend: false
                //   },
                //   { 
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       y: -170,
                //       formatter: function() {
                //         if(this.y == 22 || this.y == 23){
                //           return 'LOC 1'
                //         }
                //       },
                //      },
                //     name: 'LOC1',
                //     data: [36,22,23],
                //     stack: 'Locn 1',
                //     color: loc,
                    
                //   },
                //   {
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       formatter: function() {
                //         if(this.y == 35 || this.y == 37){
                //           return 'LOC 2'
                //         }
                //       },
                //       y: -130,
                //       x:5,
                //      },
                //     name: 'LOC2',
                //     data: [27,35,37],
                //     stack: 'Locn 2',
                //     color: loc,
                //     showInLegend: false
                //   },
                //    { 
                //     dataLabels: {
                //       enabled: true,
                //       color: '#FFFFFF',
                //       formatter: function() {
                //         if(this.y == 56 || this.y == 46){
                //           return 'LOC 3'
                //         }
                //       },
                //       y:-135,
                //       x:5
                //      },
                //     name: 'LOC3',
                //     data: [23,56,46],
                //     stack: 'Locn 3',
                //     color: loc,
                //     showInLegend: false
                //   }],
              }
            }
        />
      </div>
  ) : "";
}

export default HighChart;
