import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
// import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch, Route } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import NegativeBarGraph from './TornadoChart';
import TableComponents from './table';
import InvoiceDetailModal from './DrillDown';
import SummaryComponent from './summary';
import { Button } from '@material-ui/core';

function LinkTab(props) {
    return (
        <Tab
            component='a'
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const tabValueMap = {
    0: 'Q1-2022',
    1: 'Q3-2022',
    2: 'Q4-2022',
    3: ''
}

const tornadoLabelMapping = (newValue) => {
    let rightLabelStr = ""
    let leftLabelStr = ""

    switch (newValue) {
        case 'AOP23 vs FY22':
            rightLabelStr = 'AOP23 > FY22'
            leftLabelStr = 'AOP23 < FY22'
            break;
        case 'Q1-23 vs Q4-22':
            rightLabelStr = 'Q1-23 > Q4-22'
            leftLabelStr = 'Q1-23 < Q4-22'
            break;
        case 'Q2-23 vs Q1-23':
            rightLabelStr = 'Q2-23 > Q1-23'
            leftLabelStr = 'Q2-23 < Q1-23'
            break;
        case 'Q2-23 vs Q1-23':
            rightLabelStr = 'Q2-23 > Q1-23'
            leftLabelStr = 'Q2-23 < Q1-23'
            break;
        case 'Q3-23 vs Q2-23':
            rightLabelStr = 'Q3-23 > Q2-23'
            leftLabelStr = 'Q3-23 < Q2-23'
            break;
        case 'Q4-23 vs Q3-23':
            rightLabelStr = 'Q4-23 > Q3-23'
            leftLabelStr = 'Q4-23 < Q3-23'
            break;

        default:
            rightLabelStr = 'AOP23 > FY22'
            leftLabelStr = 'AOP23 < FY22'
            break;
    }



    return { rightLabel: rightLabelStr, leftLabel: leftLabelStr }
}

const payloadMapping = (newValue) => {
    let result = {
        period_one: "",
        period_two: ""
    }

    let newArr = []
    if (newValue !== "Summary") {
        let valueArray = newValue.split(" ")
        valueArray.forEach((item, idx) => {
            newArr[idx] = item.includes('Q') ?
                (item.substr(0, 3) + '20' + item.substr(3))
                : (item.includes('AOP') ? (item.replace('AOP', 'FY'))
                    : (item))
        })
    }

    result.period_one = newArr[0]
    result.period_two = newArr[2]
    return result
}
export class DASSAOPComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            barChartView: true,
            barChartViewMargin: true,
            tabSelectorValue: 0,
            NewValue: 'Summary',
            tornadoLabel: {
                rightLabel: 'AOP23 > FY22',
                leftLabel: 'AOP23 < FY22'
            },
            showInvoiceDetailModal: false,
            hemisphereSelectedValue: '', 
            subRegionSelectedValue: '', 
            subBUSelectedValue: '', 
            newBUSelectedValue: '',
            typeSelectedValue: 'summary',
            currentFrontlogType: 'Summary View',
            isDrillDownEnable: true,
            isPaginationEnable: false
        }
        this.showDrilldownHeaderValue = ''
    }

    componentDidMount() {
        let str = this.state.NewValue
        this.fetchDataFromServer(str)
        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'
            })
        }, 3000)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    fetchDataFromServer = (str) => {
        const { newBUSelectedValue, subRegionSelectedValue, hemisphereSelectedValue, subBUSelectedValue, typeSelectedValue, NewValue } = this.state
        var str = str? str : NewValue
        let payload_obj = {}

        switch (str) {
            case "New BU 1 Frontlog Conv":
                payload_obj = {
                    "new_bu" : newBUSelectedValue,
                    "sub_region": subRegionSelectedValue,
                    "hemisphere": hemisphereSelectedValue,
                    "bu" : subBUSelectedValue,
                    "type" : typeSelectedValue, // summary or detail
                }
                this.props.fetchDassAopFrontlog(payload_obj)
                break;
            case "AOP 23 analysis":
                let strArr = ['AOP23 vs FY22', 'Q1-23 vs Q4-22', 'Q2-23 vs Q1-23', 'Q3-23 vs Q2-23', 'Q4-23 vs Q3-23']
                let payloadArr = []
                strArr.forEach(item => {
                    payload_obj = payloadMapping(item)
                    const label_obj = tornadoLabelMapping(item)

                    const obj = {
                        payload: payload_obj,
                        label: label_obj,
                        title: item
                    }

                    payloadArr.push(obj)
                })
                this.props.fetchDASSSummary(payloadArr)
                break;
            case "Summary":
                const payload = {
                    "period_one": "FY23",
                    "period_two": "FY22"
                }
                this.props.fetchDASSSummaryTableData(payload);
                break;
            default:
                payload_obj = payloadMapping(str)
                this.props.fetchDASSAOP(payload_obj)
                break;
        }
    }

    handleNewChange = (event, newValue) => {
        const label = tornadoLabelMapping(newValue)

        this.setState({
            tornadoLabel: label,
            NewValue: newValue,
        })

        this.fetchDataFromServer(newValue)
    }

    getActual = (arr) => {
        // UP
        let result = [];
        if (arr !== undefined) {
            arr.forEach(item => {
                if (item.value >= 0) {
                    result.push(item)
                }
            })
        }
        return result
    }

    getForecast = (arr) => {
        // DOWN
        let result = [];
        if (arr !== undefined) {
            arr.forEach(item => {
                if (item.value < 0) {
                    const obj = {
                        field: item.field,
                        value: item.value * -1,
                        valueStr: item.valueStr
                    }

                    result.push(obj)
                }
            })
        }
        return result
    }

    rowClick = (state, rowInfo, column, instance) => {
        const { isDrillDownEnable } = this.state
        const arr = ['hemisphere', 'region', 'bu', 'new_bu']
        let isClickAble = true

        arr.forEach(item => {
            isClickAble = column.id === item ? false : isClickAble
        })

        return {
            onClick: (e, handleOriginal) => {
                if (isClickAble && isDrillDownEnable) {
                    const payload = {
                        header: column.id,
                        sub_region: rowInfo.original.sub_region,
                        bu: rowInfo.original.bu,
                        hemisphere: rowInfo.original.hemisphere,
                    }
                    this.setState({ showInvoiceDetailModal: true })
                    this.props.fetchDassAopFrontlogDrillDown(payload)
                }
            }
        }
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }


    handleHemisphereChange = (event) => {

        if (event.target) {
            this.setState({
                hemisphereSelectedValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleRegionChange = (event) => {

        if (event.target) {
            this.setState({
                subRegionSelectedValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleSubBUChange = (event) => {

        if (event.target) {
            this.setState({
                subBUSelectedValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleNewBUChange = (event) => {

        if (event.target) {
            this.setState({
                newBUSelectedValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleReseButton = () => {
        this.setState({
            hemisphereSelectedValue: '',
            subRegionSelectedValue: '',
            subBUSelectedValue: '',
            newBUSelectedValue: '',
        }, () => {
            this.fetchDataFromServer()
        });
    }

    filterComponent = () => {

        const { hemisphereSelectedValue, subRegionSelectedValue, subBUSelectedValue, newBUSelectedValue } = this.state
        const { incomeStatementState, authReducer } = this.props
        const { dassAOPData } = incomeStatementState
        const { summary, frontlog } = dassAOPData
        const frontlogData = frontlog['rowData']
        const frontlogFilter = frontlog.filters

        let hemisphereArr = []
        let subRegionArr = []
        let subBUArr = []
        let newBUArr = []
        if(frontlogFilter && frontlogFilter.length) {
            hemisphereArr = frontlogFilter.find(x => x.key === 'hemisphere_destination').value
            subRegionArr = frontlogFilter.find(x => x.key === 'region_destination').value
            subBUArr = frontlogFilter.find(x => x.key === 'bu').value
            newBUArr = frontlogFilter.find(x => x.key === 'new_bu').value
        }
        
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Hemisphere</InputLabel>
                        <Select
                            value={hemisphereSelectedValue}
                            label="Hemisphere"
                            onChange={this.handleHemisphereChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {hemisphereArr && hemisphereArr.length && hemisphereArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Sub Region</InputLabel>
                        <Select
                            value={subRegionSelectedValue}
                            label="Sub Region"
                            onChange={this.handleRegionChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {subRegionArr && subRegionArr.length && subRegionArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Sub BU</InputLabel>
                        <Select
                            value={subBUSelectedValue}
                            label="Sub BU"
                            onChange={this.handleSubBUChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {subBUArr && subBUArr.length && subBUArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>New BU</InputLabel>
                        <Select
                            value={newBUSelectedValue}
                            label="New BU"
                            onChange={this.handleNewBUChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {newBUArr && newBUArr.length && newBUArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <Button variant='contained' color="primary" className='reset-button' onClick={this.handleReseButton}>Reset</Button>
                </Grid>
            </Grid>)
    }

    changeFrontlogType = (event, newValue) => {
        let typeStr = ''
        let paginationEnable = false
        let drilldownEnable = false

        switch (newValue) {
            case 'Summary View':
                drilldownEnable = true
                typeStr = 'summary'
                paginationEnable = false
                break;
            
            case 'Detail View' :
                drilldownEnable = false
                typeStr = 'detail'
                paginationEnable = true
                break;
            default:
                break;
        }

        this.setState({
            currentFrontlogType : newValue,
            typeSelectedValue: typeStr,
            isDrillDownEnable: drilldownEnable,
            isPaginationEnable: paginationEnable,
        }, () => {
            this.fetchDataFromServer()
        })
    }

    renderSwitch = () => {
        const { incomeStatementState, authReducer } = this.props

        const { dassAOPData } = incomeStatementState

        const { summary, frontlog } = dassAOPData

        const { tornadoLabel, NewValue, showInvoiceDetailModal, currentFrontlogType, isDrillDownEnable, isPaginationEnable, typeSelectedValue } = this.state

        switch (NewValue) {
            case "AOP 23 analysis":
                return (<div>
                    <Grid container className='dass-aop-tornado-container'>
                        {/* <Grid item xs={12} sm={12}>
                            <h2 style={{ marginBottom: '10px' }}>By BU</h2>
                        </Grid> */}
                        {summary.data && summary.data.length ? (
                            summary.data.map(item => {
                                return <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                                    <NegativeBarGraph
                                        title={item.title}
                                        forecast={this.getForecast(item.data)}
                                        actual={this.getActual(item.data)}
                                        tornadoData={item.data}
                                        currentLimit={'Top 20'}
                                        label={item.label}
                                    />
                                </Grid>
                            })
                        ) : (
                            <CircularProgress style={{ margin: '0 auto' }} />
                        )}

                    </Grid>
                </div>)
                break;

            case "New BU 1 Frontlog Conv":
                return (
                    <div className="frontlog-container">
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={currentFrontlogType}
                                onChange={this.changeFrontlogType}
                                name='radio'
                                className='blockRadio'
                            >
                                <FormControlLabel
                                    value={'Summary View'}
                                    control={<Radio color='primary' />}
                                    label='AOP23 Orderbook Summary Table'
                                />
                                <FormControlLabel
                                    value={'Detail View'}
                                    control={<Radio color='primary' />}
                                    label='AOP23 Orderbook Detail View'
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            <div style={{ marginBottom: '20px' }}>
                                {this.filterComponent()}
                            </div>
                        }
                        <div className='table-container'>
                            {
                                typeSelectedValue === 'summary'? (
                                     
                                    frontlog?.rowHeader?.length && !frontlog?.loading ?
                                        <TableComponents
                                            className="ReactTable -striped -highlight"
                                            tableData={frontlog}
                                            rowClick={this.rowClick}
                                            paginationEnable={isPaginationEnable}
                                            pageSize={frontlog?.rowData?.length === undefined ? 1 : frontlog?.rowData?.length}
                                        /> : <CircularProgress style={{ margin: '0 50%' }} />
                                    
                                ) : 
                                (
                                    frontlog?.rowHeader?.length && !frontlog?.loading ?
                                        <SummaryComponent
                                            className="ReactTable -striped -highlight"
                                            tableData={frontlog}
                                            addClassName={'detail'}
                                            paginationEnable={isPaginationEnable}
                                        /> : <CircularProgress style={{ margin: '0 50%' }} />
                                )
                            }
                            {/* {
                                frontlog?.rowHeader?.length && !frontlog?.loading ?
                                    <TableComponents
                                        className="ReactTable -striped -highlight"
                                        tableData={frontlog}
                                        rowClick={this.rowClick}
                                        paginationEnable={isPaginationEnable}
                                        // pageSize={frontlog?.rowData?.length === undefined ? 1 : frontlog?.rowData?.length}
                                    /> : <CircularProgress style={{ margin: '0 50%' }} />
                            } */}
                            {isDrillDownEnable && showInvoiceDetailModal &&
                                <InvoiceDetailModal
                                    showModal={showInvoiceDetailModal}
                                    modalCallBack={this.invoiceModalCallBack}
                                    invoiceDetailState={frontlog.drilldown}
                                    invoiceLoading={frontlog.drilldown.loading}
                                    showDrilldownHeaderValue={this.showDrilldownHeaderValue}

                                />
                            }
                        </div>
                        
                    </div>
                )
                break;

            case "Summary":
                return (
                    <div style={{ marginTop: '20px' }}>
                        {
                            summary?.rowHeader?.length ?
                                <SummaryComponent
                                    className="ReactTable -striped -highlight"
                                    tableData={summary}
                                /> : <CircularProgress style={{ margin: '0 50%' }} />
                        }
                    </div>
                )
                break;

            default:
                return (<div>
                    <Grid container className='dass-aop-tornado-container'>
                        <Grid item xs={12} sm={12}>
                            <h2 style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>By BU</h2>
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            <NegativeBarGraph
                                title={'New BU 1 Revenue'}
                                forecast={this.getForecast(dassAOPData.by_bu.DASS)}
                                actual={this.getActual(dassAOPData.by_bu.DASS)}
                                // tornadoData={tornadoData}
                                tornadoData={dassAOPData.by_bu.DASS}
                                // fetchData={this.props.fetchTornadoData}
                                // tabSelectorValue = {this.props.tabSelectorValue}
                                // tornadoRadioButtonValue={tornadoRadioButtonValue}
                                // lastDate={currentMonth}
                                // pastMonth={pastMonth}
                                // transformData={(number) => this.handleNumberTransform(number)}
                                // handlePrintCallback={this.handlePrintClick}
                                // doExport={this.state.doExport}
                                currentLimit={'Top 20'}
                                // fileName={this.state.exportFileName}
                                label={tornadoLabel}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            <NegativeBarGraph
                                title={'Solution Revenue'}
                                forecast={this.getForecast(dassAOPData.by_bu.Service)}
                                actual={this.getActual(dassAOPData.by_bu.Service)}
                                tornadoData={dassAOPData.by_bu.Service}
                                currentLimit={'Top 20'}
                                label={tornadoLabel}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            <NegativeBarGraph
                                title={'Services Revenue'}
                                forecast={this.getForecast(dassAOPData.by_bu.Solution)}
                                actual={this.getActual(dassAOPData.by_bu.Solution)}
                                tornadoData={dassAOPData.by_bu.Solution}
                                currentLimit={'Top 20'}
                                label={tornadoLabel}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className='dass-aop-tornado-container'>
                        <Grid item xs={12} sm={12}>
                            <h2 style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>By Geo</h2>
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            <NegativeBarGraph
                                title={'New BU 1 Revenue'}
                                forecast={this.getForecast(dassAOPData.by_geo.DASS)}
                                actual={this.getActual(dassAOPData.by_geo.DASS)}
                                tornadoData={dassAOPData.by_geo.DASS}
                                currentLimit={'Top 20'}
                                label={tornadoLabel}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            {/* <TornadoChart graphData={dummyData[0]}/> */}
                            <NegativeBarGraph
                                title={'Solution Revenue'}
                                forecast={this.getForecast(dassAOPData.by_geo.Service)}
                                actual={this.getActual(dassAOPData.by_geo.Service)}
                                tornadoData={dassAOPData.by_geo.Service}
                                currentLimit={'Top 20'}
                                label={tornadoLabel}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} className='dass-aop-tornado-item'>
                            <NegativeBarGraph
                                title={'Services Revenue'}
                                forecast={this.getForecast(dassAOPData.by_geo.Solution)}
                                actual={this.getActual(dassAOPData.by_geo.Solution)}
                                tornadoData={dassAOPData.by_geo.Solution}
                                currentLimit={'Top 20'}
                                label={tornadoLabel}
                            />
                        </Grid>
                    </Grid>
                </div>)
                break;
        }
    }


    render() {
        const { tornadoLabel, NewValue } = this.state

        return (

            <div>

                {<div>
                    <div>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={NewValue}
                                onChange={this.handleNewChange}
                                name='radio'
                                className='blockRadio'
                            >
                                <FormControlLabel
                                    value={'Summary'}
                                    control={<Radio color='primary' />}
                                    label='Revenue Summary Table'
                                />
                                <FormControlLabel
                                    value={'New BU 1 Frontlog Conv'}
                                    control={<Radio color='primary' />}
                                    label='New BU 1 Frontlog Conv'
                                />
                                <FormControlLabel
                                    value={'AOP 23 anlysis'}
                                    control={<Radio color='primary' />}
                                    label='AOP 23 analysis'
                                />
                                <FormControlLabel
                                    value={'AOP23 vs FY22'}
                                    control={<Radio color='primary' />}
                                    label='AOP23 vs FY22'
                                />
                                <FormControlLabel
                                    value={'Q1-23 vs Q4-22'}
                                    control={<Radio color='primary' />}
                                    label='Q1-23 vs Q4-22'
                                />
                                <FormControlLabel
                                    value={'Q2-23 vs Q1-23'}
                                    control={<Radio color='primary' />}
                                    label='Q2-23 vs Q1-23'
                                />
                                <FormControlLabel
                                    value={'Q3-23 vs Q2-23'}
                                    control={<Radio color='primary' />}
                                    label='Q3-23 vs Q2-23'
                                />
                                <FormControlLabel
                                    value={'Q4-23 vs Q3-23'}
                                    control={<Radio color='primary' />}
                                    label='Q4-23 vs Q3-23'
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div>
                        {this.renderSwitch()}
                    </div>

                </div>}

            </div>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchOrderbookData(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_DATA',
                        payload: payload
                    });
                },
                fetchOrderbookDataDrillDown(payload) {
                    dispatch({
                        type: 'FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS',
                        payload: payload
                    });
                },
                fetchOrderbookDataComparison(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_COMPARISON_ANALYSIS',
                        payload: payload
                    });
                },
                fetchDASSAOP(payload) {
                    dispatch({
                        type: 'FETCH_DASS_AOP_REVENUE',
                        payload: payload
                    });
                },
                fetchDASSSummary(payload) {
                    dispatch({
                        type: 'FETCH_DASS_SUMMARY',
                        payload: payload
                    });
                },

                fetchDASSSummaryTableData(payload) {
                    dispatch({
                        type: 'FETCH_DASS_SUMMARY_TABLE_DATA',
                        payload: payload
                    });
                },



                fetchDassAopFrontlog(payload) {
                    dispatch({
                        type: 'FETCH_DASS_AOP_FRONTLOG',
                        payload: payload
                    });
                },
                fetchDassAopFrontlogDrillDown(payload) {
                    dispatch({
                        type: 'FETCH_DASS_AOP_FRONTLOG_DRILLDOWN',
                        payload: payload
                    });
                }
            };
        }
    )(DASSAOPComponent);