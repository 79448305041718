import React, { useState } from 'react';
import ReactTableComponent from './pureTable'
import { useSelector, useDispatch } from "react-redux";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../../utils/numberUtils'
import Tooltip from '@material-ui/core/Tooltip';


export default function TableComponent(props) {
    const receivableDataState = useSelector(state => state.dashboardState.receivableData);
    const { compareData } = receivableDataState
    const { rowData, loading, rowHeader } = compareData

   

    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        return (
                            <div className={item.key == 'business_unit' ? '' : 'text-center'}>
                                {
                                    item.key == 'business_unit' ?

                                        <span><b>{props.original[item.key]}</b></span> :

                                        <span>{handleTableTransform(props.original, item)}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : ''
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        backgroundColor: '#b1bace',
                        // backgroundColor: this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    const pivotHeaderData = changeKeysOfHeader(rowHeader)
    const pivotRowData = rowData
    let tableLen = pivotRowData && pivotRowData.length || 5;


    return <div>

        <ReactTableComponent
            pivotRowData={pivotRowData}
            className={'newCustomTable fixedFirstColumTable'}
            loading={loading}
            pivotHeaderData={pivotHeaderData}
            tableLen={tableLen}
            showInvoiceDetailModal={false}
            showLoader={false}
        />
    </div>
}