import React from 'react';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CONFIG } from '../../../config'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { getAccessByMenuKey } from "../../../utils/userManagement";
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
import "../index.css"



export default function SimpleModal(props) {
    const [inputValue, setInputValue] = React.useState('');
    const [taggedUser, setTaggedUser] = React.useState([]);
    const [refMenuAnchorEl, setRefMenuAnchorEl] = React.useState(null);

    // const handleClose = () => {
    //     props.modalCallBack(false);
    // };

    // const handleSendSubmit = () => {
    //     if (inputValue && inputValue == '') {
    //         return;
    //     }
    //     props.sendCommmentCallback(props.commentsData, inputValue, taggedUser)
    //     setInputValue('')
    //     setTaggedUser([])
    // }

    // const handleChatChangeFn = (event) => {
    //     if (event && event.target) {
    //         setInputValue(event.target.value)
    //     }
    //     const valueLen = event.target.value.length;
    //     const targetValue = event.target.value;
    //     if (valueLen && targetValue[valueLen - 1] == '@') {
    //         setRefMenuAnchorEl(event.currentTarget);
    //     }
    // }

    // const onImageInputHandler = (event, data) => {
    //     props.onImageInputHandler(event, data)
    // }

    // const handleImageOptions = (event, data) => {
    //     props.handleImageOptions(event, data)
    // }

    const handleRefMenuClose = (item) => {
        setTaggedUser([...taggedUser, item.login_id])
        if (refMenuAnchorEl && refMenuAnchorEl.value) {
            setInputValue(inputValue + (item.name || ''));
        }
        setRefMenuAnchorEl(null);
    };

    const body = (
        <div className='cModal cmModal'>
            <div className='header'>
                <span style={{fontWeight: '600', fontSize: '15px'}}>Collaborate</span>
                {/* <Icon onClick={() => { handleClose() }}>close</Icon> */}
            </div>
            <div className='modal-content'>
                <form>
                    <div className='commentInputSection flex ai-center jc-space-between'>
                        {/* <Menu
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            anchorEl={refMenuAnchorEl}
                            keepMounted
                            open={Boolean(refMenuAnchorEl)}
                            onClose={handleRefMenuClose}
                        >
                            {/* {
                                props.allUsers && props.allUsers.length &&
                                props.allUsers.map((item, index) => {
                                    return <MenuItem key={index} value={item.full_name} onClick={() => handleRefMenuClose(item)}>{item.full_name}</MenuItem>
                                })
                            } */}
                        {/* </Menu>
                         */} 
                        <TextField
                            label="Add Comment"
                            name="comment"
                            placeholder="Placeholder"
                            multiline
                            variant="outlined"
                            size="small"
                            // onChange={(event) => { handleChatChangeFn(event) }}
                        />
                        <input style={{ display: 'none' }} id="upload-icon" type="file" 
                        // onChange={(event) => onImageInputHandler(event, props.commentRowData)} 
                        />
                        { 
                        // getAccessByMenuKey(props.menuData,"aging_reports","upload") &&
                                <Tooltip title='Attach File'>
                                    <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0'>
                                        <label htmlFor="upload-icon">
                                            <Icon fontSize="small">attach_file</Icon>
                                        </label>
                                    </IconButton>
                                </Tooltip>
                        }
                        { 
                        // getAccessByMenuKey(props.menuData,"aging_reports","add") &&
                        <Tooltip title='Send'>
                            <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0' 
                            // onClick={() => handleSendSubmit(props.commentsData)}
                            >
                                <Icon fontSize="small">send</Icon>
                            </IconButton>
                        </Tooltip>
                        }
                    </div>
                    <div className='commentsSection'>
                        {/* {props.uploadImageObj[props.commentRowData.channel] &&
                            <div className="uploadedFilePreview">
                                <img src={props.uploadImageObj[props.commentRowData.channel].imagePreview} height="50" width="50" />
                                <span>{props.uploadImageObj[props.commentRowData.channel].imageName}</span>
                                <IconButton aria-label="approve upload" onClick={() => handleImageOptions('approve', props.commentRowData)}>
                                    <Icon fontSize="small">check</Icon>
                                </IconButton>
                                <IconButton aria-label="cancel upload" onClick={() => handleImageOptions('cancel', props.commentRowData)}>
                                    <Icon fontSize="small">clear</Icon>
                                </IconButton>
                            </div>
                        } */}
                        {/* {props.commentsData &&
                            props.commentsData.commentArr &&
                            props.commentsData.commentArr.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {(props.userDataState.login_id == item.login_id) ?
                                            <div className='chat-right'>
                                                <div className='date'>{formatDate(item.timestamp)}</div>
                                                {item.type == 'image' ?
                                                    <div>
                                                        {item.name} : <img width='100' src={`${CONFIG.api_base_url}v1/chat/channel/file/${props.commentsData.channel}/${item.body}`} />
                                                    </div> :
                                                    item.type == 'doc' ?
                                                        <div>
                                                            {item.name} : <a href={`${CONFIG.api_base_url}v1/chat/channel/file/${props.commentsData.channel}/${item.body}`}>
                                                                {item.file_name}
                                                            </a>
                                                        </div> :
                                                        <div>{item.name} : {item.body}</div>
                                                }
                                            </div> :
                                            <div className='chat-left'>
                                                <div className='date'>{formatDate(item.timestamp)}</div>
                                                {item.type == 'image' ?
                                                    <img width='100' src={`${CONFIG.api_base_url}v1/chat/channel/file/${props.commentsData.channel}/${item.body}`} /> :
                                                    item.type == 'doc' ?
                                                        <div><a href={`${CONFIG.api_base_url}v1/chat/channel/file/${props.commentsData.channel}/${item.body}`}>
                                                            {item.file_name}
                                                        </a> </div> :
                                                        <div>{item.name} : {item.body}</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                );
                            })
                        } */}
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div> {body} </div>
        // 
    );
}