import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});
function HighChart(props) {

let options = props.options

    return  (
      <div className="mat-shadow-2 labelClass analytic-studio-chart">
        <HighchartsReact
            highcharts={Highcharts}
            options = {options}
        />
      </div>
  );
}

export default HighChart;
