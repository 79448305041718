import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartContainer from "./chartContainer"
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export class YearlyChartComponent extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;

    this.state = {
      externalTabValue: 0,
      chartTitleDefault: "Monthly Overdue Trend",
      chartTitleBilled: "",
      RegionView: 'segment',
      viewWidth: 0,
      BuSelectedFilter: props.yearlyChartState.filterBu[0],
      RegionSelectedFilter: props.yearlyChartState.filterRegion[0],
      SegmentSelectedFilter:  props.yearlyChartState.filterSegment[0],
      CountrySelectedFilter:  props.yearlyChartState.filterCountry[0],
      BuFilter : props.yearlyChartState.filterBu,
      RegionFilter : props.yearlyChartState.filterRegion,
      SegmentFilter: props.yearlyChartState.filterSegment,
      CountryFilter: props.yearlyChartState.filterCountry,
      month: props.filterState.selectedDate
    }
  }

  handleMonthChange = (event) => {
    if (event.target && this.state.month != event.target.value) {
      this.setState({ month: event.target.value }, () => {
        this.fetchBarChart()
      });
    }
  }

  handleFilterChange = (event) => {
    const {RegionView, BuSelectedFilter, RegionSelectedFilter, SegmentSelectedFilter, CountrySelectedFilter} = this.state

    let filter = ""
    let newState = {}
    switch (RegionView) {
      case "bu":
          filter = BuSelectedFilter
          newState = { BuSelectedFilter: event.target.value }
          break;
      case "region":
          filter = RegionSelectedFilter
          newState = { RegionSelectedFilter: event.target.value }
          break;
      case "country":
          filter = CountrySelectedFilter
          newState = { CountrySelectedFilter: event.target.value }
          break;
      case "segment":
          filter = SegmentSelectedFilter
          newState = { SegmentSelectedFilter: event.target.value }
          break
      default:
          break;
    }
    
    if (event.target && filter != event.target.value) {
      this.setState(newState, () => {
          this.fetchBarChart()
      });
    }
  }

  fetchBarChart = () =>{
    const {RegionView, month, BuSelectedFilter, RegionSelectedFilter , SegmentSelectedFilter, CountrySelectedFilter} = this.state
    let chartTypes = ""
    if(this.props.tabSelectorValue == 1){
      chartTypes="new"
    }
    let payload = {
      report_date : month,
      chart_type : chartTypes
    }

    switch (RegionView) {
      case "bu":
          payload.view = "B"
          if(BuSelectedFilter !== "ALL"){
            payload.bu = BuSelectedFilter
          }
          
          break;
      case "region":
          payload.view = "R"
          if(RegionSelectedFilter !== "ALL"){
            payload.region = RegionSelectedFilter
          }
          break;
        case "country":
          payload.view = "C"
          if(CountrySelectedFilter !== "ALL"){
            payload.country = CountrySelectedFilter
          }
          break;
      case "segment":
          payload.view = "S"
          if(SegmentSelectedFilter !== "ALL"){
            payload.segment = SegmentSelectedFilter
          }
          break
      default:
          break;
    }

    this.props.getArYearlyChart(payload);
  }

  componentDidMount() {
    this.fetchBarChart()
  }


  componentDidUpdate(prevProps) {
    
    let myWidth = this.container.offsetWidth
    const { viewWidth } = this.state

    if (viewWidth != myWidth && !prevProps.yearlyChartState.loading) { //refresh chart when the width change
      this.setState({ viewWidth: myWidth })
    }

  }

  handleChangeView = (event) => {
    this.setState({ RegionView: event.target.value }, this.fetchBarChart);
  };

  render() {
    const { externalTabValue,month,viewWidth, RegionView , chartTitleDefault, BuSelectedFilter, RegionSelectedFilter, BuFilter, RegionFilter , SegmentSelectedFilter, CountrySelectedFilter, CountryFilter, SegmentFilter} = this.state
    const { filterState, yearlyChartState } = this.props

    // console.log(yearlyChartState)

    const arrayDate = filterState.reportDateArr
    const newArray = []
    const idx = arrayDate.length
    let Newidx = 0
    for (var keys in arrayDate) {
      Newidx++ 
      
      newArray.push(arrayDate[(idx-Newidx)])
    }
    const datelen = filterState.reportDateArr && filterState.reportDateArr.length;
    const updatedMonth = filterState.selectedDate

    let selectedFilter = ""
    let filter = []

    switch (RegionView) {
      case "bu":
          selectedFilter = BuSelectedFilter
          filter = BuFilter
          break;
      case "region":
          selectedFilter = RegionSelectedFilter
          filter = RegionFilter
          break;
      case "segment":
          selectedFilter = SegmentSelectedFilter
          filter = SegmentFilter
          break;
       case "country":
          selectedFilter = CountrySelectedFilter
          filter = CountryFilter
          break

      default:
          break;
    }

    let subChart = yearlyChartState.subChartData;

    // let subChart = []
    // subChartData.forEach(([key, value]) => {
    //   subChart.push({"key" : key, "value" : value})
    // }); 

    if(selectedFilter !== "ALL"){
      subChart = subChart.filter(function(item){
          return item.key === selectedFilter;
      });
    }


    return (
      <>
        <div ref={el => (this.container = el)}>
          {filterState && filterState.reportDateArr && filterState.reportDateArr.length ?
            <>
              <div className="flex ai-center jc-space-between mb-10">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={9} className='maxw-400'>
                      <FormControl component="fieldset">
                       <RadioGroup name="radio" onChange={this.handleChangeView} value={RegionView} className='radioGroupHorizontal'>
                        <FormControlLabel value="segment" control={<Radio color="primary" />} label="Segment View" />
                        <FormControlLabel value="country" control={<Radio color="primary" />} label="Country View" />
                        <FormControlLabel value="bu" control={<Radio color="primary" />} label="BU View" />
                        <FormControlLabel value="region" control={<Radio color="primary" />} label="Region View" />

                      </RadioGroup>
                      </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                    <FormControl className='w-200'>
                      <Select
                        value={month || updatedMonth}
                        onChange={this.handleMonthChange}
                        MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                        {newArray.map((item, index) => {
                          return <MenuItem value={item} key={index}>{item}</MenuItem>
                        })
                        }
                      </Select>
                    </FormControl>
                </Grid>
                </div>
                <Grid item xs={12} sm={12} className="bu-list">
                  <FormControl component="fieldset">
                    <RadioGroup name="radio" onChange={this.handleFilterChange} value={selectedFilter} className='blockRadio'>
                    {filter.map((item, i) => {
                      let text = item
                      if(text.toLowerCase() == "slb"){
                        text = "Interco"
                      }else if(text.toLowerCase() == "pemex"){
                        text = "Key Customer 1"
                      }
                      return <FormControlLabel value={item} key={i} control={<Radio />} label={text} />
                     })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              
              {
                  <ChartContainer
                    subChartData = {subChart}
                    chartData={yearlyChartState.data}
                    chartTitle={chartTitleDefault}
                    loading={yearlyChartState.loading}
                    width={viewWidth}
                  ></ChartContainer>
              }
            </> : null
          }
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        yearlyChartState: state.dashboardState.dashboardAnalytic.YearlyChartView,
        filterState:state.dashboardState.dashboardAnalytic.ArOverdueChartView,
      }
    },
    (dispatch) => {
      return {
        getArYearlyChart(data) {
          dispatch({
            type: "FETCH_AR_YEARLY_CHART",
            payload: data,
          });
        }
      }
    }
  )(YearlyChartComponent)
);