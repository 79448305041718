import { numberWithCommas } from '../../utils/numberUtils'

export const dummyUnbilledConvert = (rowData, type, reportDate) => {
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (type == 'billing') {
        if (reportDate == '31-Aug-2020') {
          if (item.business_unit == 'PAB') {
            item['unbilled'] = 33532850;
            item['total'] = 74537093;
          }
          if (item.business_unit == 'Grand Total') {
            item['total'] = 126523713;
          }
        }
        if (reportDate == '30-Sep-2020') {
          if (item.business_unit == 'PAB') {
            item['unbilled'] = 35328242;
            item['total'] = 69300395;
          }
          if (item.business_unit == 'Grand Total') {
            item['unbilled'] = 47309886;
            item['total'] = 122529885;
          }
        }
      }
      if (type == 'regional') {
        if (reportDate == '31-Aug-2020') {
          if (item.business_unit == 'PAB') {
            item['EMEA'] = 54574256;
            item['total'] = 75437093;
          }
          if (item.business_unit == 'Grand Total') {
            item['total'] = 126523714;
          }
        }
        if (reportDate == '30-Sep-2020') {
          if (item.business_unit == 'PAB') {
            item['EMEA'] = 53973559;
            item['total'] = 69300394;
          }
          if (item.business_unit == 'Grand Total') {
            item['EMEA'] = 84007586;
            item['total'] = 115505074;
          }
        }
      }
      return item;
    })
    rowData.row_data = updatedRowData
  }
  return rowData
}

export const dummyDeltaConvert = (rowData, type, reportDate) => {
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (type == 'billing') {
        if (reportDate == '31-Aug-2020') {
          if (item.business_unit == 'PAB') {
            item['unbilled'] = 0;
            item['total'] = -800909;
          }
          if (item.business_unit == 'Grand Total') {
            item['total'] = 1764142;
          }
        }
        if (reportDate == '30-Sep-2020') {
          if (item.business_unit == 'PAB') {
            item['unbilled'] = 0;
            item['total'] = 856082;
          }
          if (item.business_unit == 'Grand Total') {
            item['unbilled'] = -4073918;
            item['total'] = 2933893;
          }
        }
      }
      if (type == 'regional') {
        if (reportDate == '31-Aug-2020') {
          if (item.business_unit == 'PAB') {
            item['EMEA'] = 19836206;
            item['total'] = -800908;
          }
          if (item.business_unit == 'Grand Total') {
            item['total'] = 1764140;
          }
        }
        if (reportDate == '30-Sep-2020') {
          if (item.business_unit == 'PAB') {
            item['EMEA'] = -20302847;
            item['total'] = 856083;
          }
          if (item.business_unit == 'Grand Total') {
            item['EMEA'] = -14915561;
            item['total'] = 9958705;
          }
        }
      }
      return item;
    })
    rowData.row_data = updatedRowData
  }
  return rowData
}

export const deltaTotalValue = (rowData, type) => {
  let deltaValue = 0;
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (type == 'billing') {
        if (item.business_unit == 'Grand Total' && item['unbilled'] != undefined) {
          deltaValue = numberWithCommas(Math.round(item['total']));
        }
      }
      if (type == 'regional') {
        if (item.business_unit == 'Grand Total') {
          deltaValue = numberWithCommas(Math.round(item['total']));
        }
      }
      return item;
    })
  }
  return deltaValue
}

export const deltaIncomeTotalValue = (rowData, type) => {
  let deltaValue = 0;
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (type == 'billing') {
        if (item.business_unit == 'Grand Total') {
          deltaValue = numberWithCommas(Math.round(item['total']));
        }
      }
      if (type == 'region') {
        if (item.business_unit == 'Grand Total') {
          deltaValue = numberWithCommas(Math.round(item['total']));
        }
      }
      return item;
    })
  }
  return deltaValue
}

export const transformFilterData = (filterData) => {
  let filterObj = {
    quarterfilterArr: [],
    monthFilterArr: [],
    yearFilterArr: [],
    ytdFilterArr: []
  }
  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'yearmonth') {
        filterObj.monthFilterArr = item.value
      }
      if (item.key == 'quarter') {
        filterObj.quarterfilterArr = item.value
      }
      if (item.key == 'year') {
        filterObj.yearFilterArr = item.value
      }
      if (item.key == 'ytd') {
        filterObj.ytdFilterArr = item.value
      }
    })
  }
  return filterObj
}
