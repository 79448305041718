import axios from 'axios';
import {CONFIG} from '../../../config'

export async function getFCFImpactAnalysisService(payload) {
    let queryParam = `?dimension=${payload.dimension}`
    queryParam += `&period=${payload.period}`
    if(payload.kpi_category != undefined){
    queryParam += `&kpi_category=${payload.kpi_category}`
    }
    if(payload.kpi_type != undefined){
    queryParam += `&kpi_type=${payload.kpi_type}`
    }
    if(payload.kpi != undefined){
        queryParam += `&kpi=${payload.kpi}`
    }

    if(payload.yearmonth != undefined){
        queryParam += `&yearmonth=${payload.yearmonth}`
    }

    if(payload.year_label != undefined){
        queryParam += `&year_label=${payload.year_label}`
    }

    if(payload.quarter != undefined){
        queryParam += `&quarter=${payload.quarter}`
    }

    // console.log(payload, "--ini payload")

    if(payload.isAutomated){
        queryParam += `&automated_reporting=true`
    }

    if (payload.export) {
      queryParam += `&export=true`

      return axios.get(`${CONFIG.api_base_url}fcfImpact/getdata${queryParam}`, {responseType: 'blob'})
      .then(response => {
          var x = response.data;
          console.log(response, x)
          var u = URL.createObjectURL(x)
          var a = document.createElement("a");
          a.href = u;
          a.download = (response.headers['content-disposition']).split('=')[1];
          a.click();
          window.URL.revokeObjectURL(u);
        
        return response;
      }
      ).catch(err => err.response);
  
    }else {
        return axios.get(`${CONFIG.api_base_url}fcfImpact/getdata${queryParam}`).then(
            response => {
                return response;
            }
        ).catch(err => {

            return err.response
        });
    }
}


export async function getFCFFilterService(payload) {
    let queryParam = ''
    
    return axios.get(`${CONFIG.api_base_url}fcfImpact/getfilters${queryParam}`).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function getFCFImprovementService(payload) {
    let queryParam = ''

    if (payload.timePeriod) {
        queryParam = `&time_period=${payload.timePeriod}`
    }
    if (payload.dimension) {
        queryParam = `&dimension=${payload.dimension}`
    }
    if (payload.kpi) {
        queryParam = `&kpi=${payload.kpi}`
    }
    
    return axios.get(`${CONFIG.api_base_url}fcfImpact/getdatamonthlyperformance?period=fy${queryParam}`).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}



export async function getEmailTemplateAutomatedReportingService(payload) {
    return axios.get(`${CONFIG.api_base_url}automationreport/email-getautomationreport`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function saveEmailTemplateAutomatedReportingService(payload) {
    return axios.post(`${CONFIG.api_base_url}automationreport/email-updateautomationreport`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function sendEmailAutomatedReportingService(payload) {
    return axios.post(`${CONFIG.api_base_url}fcfImpact/sendemail`, payload)
        .then(response => response)
        .catch(err => err.response)
}


export async function updateActionOwnerService(payload) {

    return axios.post(`${CONFIG.api_base_url}automationreport/saveactionowner`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchAllCommentHistoryService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload, { withCredentials: true }).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchMetaDataAnalyticStudioService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/getmetadata`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDataAnalyticStudioService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/getdata`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}


export async function fetchCreatePlanFormDataService(payload) {
    return axios.get(`${CONFIG.api_base_url}improvementPlan/getdatamaster`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchCreatePlanIDDataService(payload) {
    return axios.post(`${CONFIG.api_base_url}improvementPlan/getipid`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function getAnalyticStudioService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/getanalyticstudio`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function shareChartService(data) {
    let payload = {
        options: JSON.stringify(data.userOptions),
        filename: 'LineChart',
        b64 : true,
      }

      let url = "https://export.highcharts.com/"

    return axios.post(url, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function sendEmailForChartService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/sendemail`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchSavePlanFormDataService(payload) {
    return axios.post(`${CONFIG.api_base_url}improvementPlan/save`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function saveAnalyticStudioService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/saveanalyticstudio`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function deleteAnalyticStudioService(payload) {
    return axios.post(`${CONFIG.api_base_url}analyticsStudio/delete`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {
        return err.response
    });
}

export async function fetchCadenceBoardDataService(payload) {
    return axios.get(`${CONFIG.api_base_url}improvementPlan/getdata`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchImprovementPlanHistoryDataService(payload) {
    let queryParam = `?plan_id=${payload.plan_id}`

    return axios.get(`${CONFIG.api_base_url}improvementPlan/getloghistory${queryParam}`).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function saveCadenceBoardDataService(payload) {
    return axios.post(`${CONFIG.api_base_url}improvementPlan/save`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function getFCFImpactDrilldownService(payload) {
    let queryParam = `?dimension=${payload.dimension}`
    queryParam += `&period=${payload.period}`
    if(payload.kpi_category != undefined){
    queryParam += `&kpi_category=${payload.kpi_category}`
    }
    if(payload.kpi_type != undefined){
    queryParam += `&kpi_type=${payload.kpi_type}`
    }
    if(payload.kpi != undefined){
        queryParam += `&kpi=${payload.kpi}`
    }

    if(payload.yearmonth != undefined){
        queryParam += `&yearmonth=${payload.yearmonth}`
    }

    if(payload.year_label != undefined){
        queryParam += `&year_label=${payload.year_label}`
    }

    if(payload.quarter != undefined){
        queryParam += `&quarter=${payload.quarter}`
    }

    if(payload.business_unit != undefined){
        queryParam += `&business_unit=${payload.business_unit}`
        }

    if(payload.business_head != undefined){
        queryParam += `&business_head=${payload.business_head}`
        }
    // console.log(payload, "--ini payload")

    if(payload.isAutomated){
        queryParam += `&automated_reporting=true`
    }

    if (payload.export) {
      queryParam += `&export=true`

      return axios.get(`${CONFIG.api_base_url}fcfImpact/getdatadrilldown${queryParam}`, {responseType: 'blob'})
      .then(response => {
          var x = response.data;
          console.log(response, x)
          var u = URL.createObjectURL(x)
          var a = document.createElement("a");
          a.href = u;
          a.download = (response.headers['content-disposition']).split('=')[1];
          a.click();
          window.URL.revokeObjectURL(u);
        
        return response;
      }
      ).catch(err => err.response);
  
    }else {
        return axios.get(`${CONFIG.api_base_url}fcfImpact/getdatadrilldown${queryParam}`).then(
            response => {
                return response;
            }
        ).catch(err => {

            return err.response
        });
    }
}