import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function BarChart(props) {
    const { chartData } = props
    const monthArr = chartData.map(x => monthNames[Number(x.month.split('-')[1]) - 1] + '-' + x.month.split('-')[0].toString().substr(-2))
    const userCountArr = chartData.map(x => {return {y: x.user_count, name: x.session_time, loginCount: x.login_count}})
    const loginCountArr = chartData.map(x => x.login_count)
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        title: {
                            text: "",
                            useHTML: true
                        },
                        xAxis: {
                            categories: monthArr
                        },
                        yAxis: {
                            title: ''
                        },
                        legend: {
                            enabled: false
                        },
                        yAxis: [{
                            labels: {
                                format: '{value}'
                            },
                            title: {
                                text: 'No. of users'
                            }
                        }, {
                            title: {
                                text: 'No. of logins'
                            },
                            labels: {
                                format: '{value}'
                            },
                            opposite: true
                        }],
                        exporting: {
                            enabled: false
                        },
                        tooltip: {
                            hideDelay: 0,
                            animation: false,
                            snap: 1,
                            followPointer: true,
                            followTouchMove: true,
                            formatter: function (point) {
                                return 'No. of users: ' + '<b>' + this.y + '</b><br>'+
                                    'No. of logins: ' + '<b>' + this.point.options.loginCount + '</b>';
                            }
                        },
                        series: [{
                            type: 'column',
                            name: 'No. of users',
                            data: userCountArr,
                            color: '#685aa999',
                            pointWidth: 40,
                            dataLabels: {
                                enabled: true,
                                format: '{point.name} mins',
                                verticalAlign: 'top',
                                y: -20
                            },
                        }, {
                            name: 'No. of logins',
                            yAxis: 1,
                            data: loginCountArr,
                            color: '#000',
                            enableMouseTracking: false
                        }],
                        credits: {
                            enabled: false
                        },
                    }
                }
            />
        </div>
    )
}
export default BarChart;