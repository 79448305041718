import React from 'react';
import ChartImg1 from "../../../images/KOC/operational1.png"
import ChartImg2 from "../../../images/KOC/operational2.png"
import ChartImg3 from "../../../images/KOC/operational3.png"
import Grid from '@material-ui/core/Grid';

function ChartView() {
    return (
         <div className="chart-view">
             <Grid container spacing={3}>
                <Grid item xs={12}>
                  <img src={ChartImg1}/>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mt-30">
                <Grid item xs={6}>
                  <div className="title"><strong>Motor temperature, Motor current, Flow rate</strong></div>
                  <img src={ChartImg2} className="mt-20"/>
                </Grid>
                <Grid item xs={6}>
                  <div className="title"><strong>Motor temperature, Pump intake temperature, Pump intake pressure, Pump discharge pressure</strong></div>
                  <img src={ChartImg3}/>
                </Grid>
              </Grid>
         </div>
        
    )
}

export default ChartView;