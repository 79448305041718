import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function BarGraph(props) {
    let { graphData } = props
    const chartArr = {
        xCategoriesArr: [],
        seriesActualData: [],
        seriesForecastData: []
    }
    let colorpallete = ["#92a8d1", "#034f84", "#eea29a", "#f7786b"];
    let finalData=[];
    let actualDetailSize= 0;

    graphData = graphData.map(x => {
        return { ...x, month: props.transformValues(x.report_date) }
    }).sort((a, b) => new Date(b.report_date).getTime() - new Date(a.report_date).getTime())

    if (props.viewType === 'month' || props.externalTabValue != 0) {
        chartArr.xCategoriesArr = graphData.map(x => x.month)
        chartArr.seriesForecastData = graphData.map(x => x.forecast)
        let regionMap = [];
        chartArr.seriesActualData = graphData.map(x => {      //SG-629, just used to calculate actualDetailSize and not intended for use this variable.

            if(x.actual_details){
                let currDetails = x.actual_details;
                currDetails.forEach((item, index) => {
                    let found = regionMap.find( x => x == item.file_unique_name)
                    if(!found){
                        regionMap.push(item.file_unique_name);
                    }
                })
                if(x.actual_details.length > actualDetailSize){
                    actualDetailSize = x.actual_details.length;
                }               
                return 0;
            }else{
                return x.actual
            }
                 
        })
        console.log("regionMap -> ", regionMap);
        let j=0;
        let data=[];

        for(let i=0; i<actualDetailSize; i++){

            let obj= {
                arr:[],
                fileName: ""
            } 
            graphData.forEach( x => {
                 
                if(x.actual_details){
                    let specificRegion = regionMap[j]; 
                    let currDetails = x.actual_details;
                    let found = currDetails.find((item) => item.file_unique_name == regionMap[j])
                    if(found){
                        let value = currDetails.map(x => {
                            if(x.file_unique_name == regionMap[j]){
                                return x.total_actual;
                            }
                        })
                        let finalValue = value.find(x => x != undefined)
                        obj.arr.push(finalValue);
                        obj.fileName = regionMap[j];
                    }else{
                        obj.arr.push(0);
                        obj.fileName = specificRegion;
                    }
                    
                }else {
                    obj.arr.push(0);
                }
            })
            console.log("OBJECT IS ", obj);

            data[j]= obj;
            j++;
        }

        let totalActualSumArray=[];
        for( var t =0; t<data.length; t++){
            let sum=0;

            data.forEach( x => {
                sum += x.arr[t];
            })
          
            totalActualSumArray[chartArr.xCategoriesArr[t]] = sum;
        }

        finalData = data.map( (x,index) => {
            var color= index % 4;
            return {
                name: x.fileName,
                color: colorpallete[color],
                data: x.arr,
                stack: "actual",
                totalActual: totalActualSumArray,
                showInLegend: true,
            }
        })  
        
        finalData.push({
            name: 'Actual',
            color: '#49a8ec',
            data: chartArr.seriesActualData,
            stack: "actual",
            showInLegend: true
        },{
            name: 'Forecast',
            color: '#f69038',
            data: chartArr.seriesForecastData,
            stack: "forecast"
        });
        
    } else {
        chartArr.xCategoriesArr = graphData.map(x => x.bu)
        chartArr.seriesActualData = graphData.map(x => x.actual)
        chartArr.seriesForecastData = graphData.map(x => x.forecast)
        finalData.push({
            name: 'Actual',
            color: '#49a8ec',
            data: chartArr.seriesActualData,
            stack: "actual",
            showInLegend: true
        },
        {
            name: 'Forecast',
            color: '#f69038',
            data: chartArr.seriesForecastData,
            stack: "forecast",
            showInLegend: true
        }
        );
    }

    Highcharts.setOptions({
        lang: {
            numericSymbols: ["k", "M", "B", "T"]
        }
    });

    console.log('finalData', chartArr.xCategoriesArr, finalData)

    return (
        <div className="mat-shadow-2" style={{ maxWidth: '600px' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column',
                            width: 600,
                        },
                        title: {
                            text: "",
                        },
                        subtitle: { text: props.chartTitle },
                        xAxis: {
                            title: '',
                            categories: chartArr.xCategoriesArr
                        },
                        yAxis: {
                            title: ''
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal'
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:11px"></span><br>',
                            pointFormat: `<div>
                            <span style="color:{point.color}" >{series.name}</span>: <b>{point.y:,.0f}</b><br/>
                            <span style="color:{point.color}" >Total</span>: <b>{point.totalActual:,.0f}</b><br/>
                            </div>`,
                            formatter: function() {
                                // console.log('pointFormat', this)
                                let name = this.series.name;
                                let pointY = this.point.y;
                                let pointX = this.x;
                                let actualVal = this.series.userOptions.totalActual;
                                console.log("actualval", actualVal);
                                console.log("pointX", pointX);
                                return `<span style="color:{point.color}" >${name}</span>: <b>${Highcharts.numberFormat(pointY, 0)}</b><br/>
                                ${actualVal ? `<span style="color:{point.color}" >Total</span>: <b>${Highcharts.numberFormat(actualVal[pointX], 0)}</b><br/>` : ''}`;

                            }
                        },
                        exporting: {
                            enabled: false
                        },
                        series:finalData,
                        credits: {
                            enabled: false
                        },
                    }
                }
            />
        </div>
    )
}
export default BarGraph;
