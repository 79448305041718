import React, { useCallback, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import { Autocomplete, TextField } from "@mui/material";
import './filters.scss';

export default function Filters({ itemArray, onChange, itemId }) {
    const isMultiple = itemId.includes("Variance") ? false : true;
    const [dataArray, setDataArray] = useState(itemArray);

    const changeHandler = useCallback(
        (data, event) => {
            const value = event?.target?.value;

            setDataArray((prev) => {
                const index = prev.indexOf(data);

                if (index > -1) {
                    let dataTemp = prev[index];

                    const dashIndex = itemId.indexOf("-");
                    const itemIdOri = itemId.substring(0, dashIndex);

                    switch (itemIdOri) {
                        case "Variance":
                            if ((index == 0 && prev[1].default_value[0] == value) || (index == 1 && prev[0].default_value[0] == value)) {
                                let toastrOptions = {
                                    timeOut: 3000, // by setting to 0 it will prevent the auto close
                                    position: "top-center",
                                };

                                toastr.warning(`${value} is selected already`, toastrOptions);
                            } else {
                                dataTemp.default_value = [value];
                            }
                            break;

                        default:
                            const allOption = dataTemp.values.find((value) => value.text == "All");

                            if (allOption) {
                                dataTemp.default_value = value;

                                if (dataTemp.default_value.length > 0) {
                                    const allIndex = dataTemp.default_value.indexOf("All");

                                    if (allIndex == 0) {
                                        dataTemp.default_value.splice(allIndex, 1);
                                    } else if (allIndex > 0) {
                                        dataTemp.default_value = ["All"];
                                    }
                                } else {
                                    dataTemp.default_value = ["All"];
                                }
                            } else dataTemp.default_value = value;

                            break;
                    }

                    // if( itemId.includes('Variance'))
                    // {
                    //     if(((index == 0 && prev[1].default_value[0] == value) ||
                    //         (index == 1 && prev[0].default_value[0] == value))) {

                    //            let toastrOptions = {
                    //                 timeOut: 3000, // by setting to 0 it will prevent the auto close
                    //                 position: 'top-center'
                    //               }

                    //         toastr.warning(`${value} is selected already`, toastrOptions)
                    //     } else {
                    //         dataTemp.default_value = [value]
                    //     }
                    // }
                    // else if( itemId.includes('Legacy BU') ) {
                    //     dataTemp.default_value = value

                    //     if(dataTemp.default_value.length > 0) {
                    //         const allIndex = dataTemp.default_value.indexOf('All')

                    //         if(allIndex == 0) {
                    //             dataTemp.default_value.splice(allIndex, 1)
                    //         }
                    //         else if(allIndex > 0) {
                    //             dataTemp.default_value = ['All']
                    //         }
                    //     }
                    //     else {
                    //         dataTemp.default_value = ['All']
                    //     }
                    // }
                    // else
                    // {
                    //     dataTemp.default_value = value
                    // }

                    onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];
            });
        },
        [setDataArray]
    );

    const changeHandlerNew = useCallback( 
        (newValue, data) =>{
            const value = [...newValue].map((obj)=> (obj.value))

            setDataArray((prev)=>{
                const index = prev.indexOf(data);
                
                if (index > -1) {
                    let dataTemp = prev[index];
                   
                    const dashIndex = itemId.indexOf("-");
                    const itemIdOri = itemId.substring(0, dashIndex);
                    
                    switch (itemIdOri) {
                        case "Variance":
                            if ((index == 0 && prev[1].default_value[0] == value) || (index == 1 && prev[0].default_value[0] == value)) {
                                let toastrOptions = {
                                    timeOut: 3000, // by setting to 0 it will prevent the auto close
                                    position: "top-center",
                                };

                                toastr.warning(`${value} is selected already`, toastrOptions);
                            } else {
                                dataTemp.default_value = [value];
                            }
                            break;
                        default:
                            console.log ('datatempvalues',dataTemp.values)
                            const allOption = dataTemp.values.find((value) => value.text == "All");
                            console.log ('alloption', allOption)

                            if (allOption) {
                                dataTemp.default_value = value;

                                if (dataTemp.default_value.length > 0) {
                                    const allIndex = dataTemp.default_value.indexOf("All");

                                    if (allIndex == 0) {
                                        dataTemp.default_value.splice(allIndex, 1);
                                    } else if (allIndex > 0) {
                                        dataTemp.default_value = ["All"];
                                    }
                                } else {
                                    dataTemp.default_value = ["All"];
                                }
                            } else dataTemp.default_value = value;
                            
                            break;

                    }

                    onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];

            })
        }

    ,[setDataArray]);

    return (
        <div>
            {dataArray && dataArray.length ? (
                dataArray.map((data) => {

                    const selectedOptions = (a) =>{
                        return data.values.filter((v) => a.indexOf(v.value) > -1)                        
                    };

                    return (
                        <Box className="commonfilter-box-option " key={`${data.label}`}>
                            {/* <FormControl fullWidth>
                                <InputLabel id="commonfilter-page-type">{data.label}</InputLabel>
                                <Select value={data?.default_value} label={`${data.label}`} multiple={isMultiple} onChange={(event) => changeHandler(data, event)}>
                                    <MenuItem value="" disabled>
                                        <em>Select</em>
                                    </MenuItem>
                                    {data.values &&
                                        data.values.length &&
                                        data.values.map((item) => {
                                            return (
                                                <MenuItem value={item?.value ? item?.value : item} key={item?.value ? item?.value : item}>
                                                    {item?.text ? item?.text : item}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl> */}
                            <Autocomplete
                                className = 'commonfilter-page-type new-autocomplete'
                                fullWidth
                                disablePortal
                                disableCloseOnSelect
                                limitTags={2}
                                disableListWrap
                                multiple={isMultiple}
                                size='small'
                                value={selectedOptions(data.default_value)}
                                options={data.values}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> changeHandlerNew(newValue, data)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`${data.label}`}
                                      placeholder={data?.default_value.length == 0 ? "Select" : ""}
                                    />
                                    )}
                                renderTags={(value, getTagProps)=> 
                                    value.map((option, index) => {
                                        if (index==value.length-1) {return <span key={index}  style={{ fontSize: '14px' }}  {...getTagProps({ index })}>{option.text}</span>}
                                        else if (index<value.length-1){return <span key={index} style={{ fontSize: '14px' }} {...getTagProps({ index })}>{option.text}, </span>}
                                        })                                      
                                }
                            />
                        </Box>
                    );
                })
            ) : (
                <div className="div-wait-report">No items added...</div>
            )}
        </div>
    );
}
