import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
// import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import AnalysisTnEComponent from './AnalysisTnEComponent';
import AnalysisOvertime from './AnalysisOvertime';
import AdhhocInsideComponent from './AdhocChart';
import { Switch, Route } from "react-router-dom";
import DataValidationComponent from './../DataBrowser/DataValidation'
import { getAccessByMenuKey } from "../../../utils/userManagement";
import { DashboardComponents } from './dashboard'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import OrderBookComponent from './Orderbook'
import DASSByGeoComponent from './DASSByGeo';
import DASSAOPComponent from './DASS-AOP';
import PABBacklogComponent from './PABBacklog'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SmallestNumber(arr) {
    return Math.min.apply(Math, arr)
}

function AccessibleMenus(arr) {
    let result = []

    arr.forEach(x => {
        if(x.isAccessible === true) {
            result.push(x.idx)
        }
    })

    return result
}


export class AdhocChartComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            barChartView: true,
            barChartViewMargin: true,
            tabSelectorValue: 1
        }
    }

    menuMap = [
        {
            idx : 0,
            menu_name: 'dass_by_geo',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "dass_aop", "view"),
            // isAccessible : false,
            label: 'New BU 1 Revenue Trend By Geo',
            component: <DASSByGeoComponent isCustomer={false}/>,
        },
        {
            idx : 1,
            menu_name: 'dass_by_customer',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "dass_aop", "view"),
            // isAccessible : false,
            label: 'New BU 1 Revenue by Customer',
            component: <DASSByGeoComponent isCustomer={true}/>,
        },
        {
            idx :2,
            menu_name: 'dass_aop',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "dass_aop", "view"),
            // isAccessible : false,
            label: 'New BU 1 AOP 23 revenue analysis',
            component: <DASSAOPComponent/>,
        },
        {
            idx : 3,
            menu_name: 'backlog',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "backlog", "view"),
            // isAccessible : false,
            label: 'New BU 1 AOP 23 – PAB backlog analysiss',
            component: <PABBacklogComponent/>,
        },
        {
            idx : 4,
            menu_name: 'order_book',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "order_book", "view"),
            // isAccessible : false,
            label: 'OrderBook Analysis',
            component: <OrderBookComponent/>,
        },
        {
            idx : 5,
            menu_name: 'cost_analysis',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "cost_analysis", "view"),
            label: 'Cost Analysis',
            component: <DashboardComponents isCost={true} menuData={this.props.authReducer.menuData} />,
        },
        {
            idx : 6,
            menu_name: 'adhoc_chart',
            isAccessible : getAccessByMenuKey(this.props.authReducer.menuData, "adhoc_chart", "view"),
            label: 'Ad Hoc Charts',
            component: <DashboardComponents isCost={false} menuData={this.props.authReducer.menuData} />,
        },
    ]

    handleChangeView = (event) => {
        this.setState({ barChartView: event.target.checked })
        this.setState({ barChartViewMargin: event.target.checked })
    };

    handleExcelDownload = () => {
        this.props.fetchAdhocChart({ export: true })
    }

    componentDidMount() {
        this.defaultTab()
        this.props.fetchAdhocChart({})

        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'
            })
        }, 3000)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabSelectorValue: newValue,
        })
    }

    defaultTab = () => {
        let indexArr = AccessibleMenus(this.menuMap)
        let defaultTabNumber = SmallestNumber(indexArr)

        this.setState({
            tabSelectorValue: defaultTabNumber
        })
    }


    render() {
        const { incomeStatementState, authReducer } = this.props

        const { tabSelectorValue, barChartView, barChartViewMargin } = this.state
        const { adhocChartData } = incomeStatementState
        return (
            // <Switch>
            //     { getAccessByMenuKey(authReducer.menuData,"tne","view") &&
            //     <Route path={`/dashboard/income-statement/ad-hoc/tne`} exact>
            //         <AnalysisTnEComponent />
            //     </Route>
            //     }
            //     { getAccessByMenuKey(authReducer.menuData,"overtime","view") &&
            //         <Route path={`/dashboard/income-statement/ad-hoc/overtime`} exact>
            //         <AnalysisOvertime />
            //         </Route>    
            //     }
            //     { getAccessByMenuKey(authReducer.menuData,"adhoc_chart","view") &&
            //         <Route path={`/dashboard/income-statement/ad-hoc/adhoc-chart`} exact>
            //         <AdhhocInsideComponent />
            //         </Route>    
            //     }
            //  </Switch>
            <div>
                <div>
                <div className="header flex ai-center jc-space-between ad-hoc-label">
                            <span className='ad-hoc-label-text' >Charts, Dashboard & Analytics</span>
                        </div>
                </div>
                <Tabs
                    value={tabSelectorValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className='mt-16 mb-16'
                >
                    {this.menuMap && this.menuMap.length && this.menuMap.map(item => {
                        const returnComponent = item.isAccessible ? (<Tab value={item.idx} label={item.label}  {...this.a11yProps(item.idx)} />) : '' 
                        return returnComponent
                    })}
                    {/* {getAccessByMenuKey(authReducer.menuData, "dass_aop", "view") &&
                    <Tab value={0} label="DASS AOP 23 revenue analysis"  {...this.a11yProps(0)} />
                    }
                    {getAccessByMenuKey(authReducer.menuData, "backlog", "view") &&
                    <Tab value={1} label="DASS AOP 23 – PAB backlog analysis"  {...this.a11yProps(1)} />
                    }
                    {getAccessByMenuKey(authReducer.menuData, "order_book", "view") &&
                    <Tab value={2} label="OrderBook Analysis"  {...this.a11yProps(2)} />
                    }
                    {getAccessByMenuKey(authReducer.menuData, "cost_analysis", "view") &&
                    <Tab value={3} label="Cost Analysis"  {...this.a11yProps(3)} />
                    }
                    {getAccessByMenuKey(authReducer.menuData, "adhoc_chart", "view") &&
                    <Tab value={4} label="Ad Hoc Charts"  {...this.a11yProps(4)} />
                    } */}


                </Tabs>
                {this.menuMap && this.menuMap.length && this.menuMap.map(item => {
                    const returnComponent = tabSelectorValue === item.idx && item.isAccessible ? (item.component) : '' 
                    return returnComponent
                })}
                {/* { tabSelectorValue == 0 && getAccessByMenuKey(authReducer.menuData, "dass_aop", "view") &&
                     <DASSAOPComponent />
                }
                { tabSelectorValue == 1 && getAccessByMenuKey(authReducer.menuData, "backlog", "view") &&
                     <PABBacklogComponent />
                }

                { tabSelectorValue == 2 && getAccessByMenuKey(authReducer.menuData, "order_book", "view") &&
                     <OrderBookComponent />
                }


                { tabSelectorValue == 3 && getAccessByMenuKey(authReducer.menuData, "cost_analysis", "view") &&
                     <DashboardComponents isCost={true} menuData={authReducer.menuData} />
                }

                { tabSelectorValue == 4 &&  getAccessByMenuKey(authReducer.menuData, "adhoc_chart", "view") &&
                    <DashboardComponents isCost={false} menuData={authReducer.menuData} />
                } */}

                
               
                


            </div>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchAdhocChart(payload) {
                    dispatch({
                        type: 'FETCH_ADHOC_CHART',
                        payload: payload
                    });
                }
            };
        }
    )(AdhocChartComponent);