import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Analysis from "../Analysis";
import AnalysisSidebar from "../Analysis/sidebar.jsx";
import OperationalSidebar from "../Operational/sidebar.jsx";
import Operational from "../Operational";
import RCASidebar from "../Rca/sidebar.jsx";
import RCA from "../Rca";
import { Switch, Route } from "react-router-dom";

export default function Sidebar(props) {
  const [screenAnalysis, setScreenAnalysis] = React.useState("Overview");

  const handleClickChangeScreenAnalysis = (screen) => {
    setScreenAnalysis(screen)
  }

  return (
    <div className="content-wrapper">
      
      <main >
        <div className="main-content">
          <Breadcrumbs aria-label="breadcrumb">
                {props.breadcrumbsList && props.breadcrumbsList.length > 0 ? props.breadcrumbsList.map((item, index) => (
                    <Link
                        key={index}
                        color="inherit"
                        href={item.link}
                        className={index + 1 == props.breadcrumbsList.length ? "active" : ""}
                    >
                        {item && item.text}
                    </Link>
                )) : ''}
          </Breadcrumbs>
          <div className="mt-20">
             <Switch>
                <Route path="/new-dashboard/analysis" exact>
                    <div className="side-navigation">
                        <AnalysisSidebar handleClickChangeScreenAnalysis={handleClickChangeScreenAnalysis}/>
                    </div>
                    <Analysis screenAnalysis={screenAnalysis}/>
                </Route>
                <Route path="/new-dashboard/operational" exact>
                    <div className="side-navigation">
                        <OperationalSidebar/>
                    </div>
                    <Operational/>
                </Route>
                <Route path="/new-dashboard/rca" exact>
                    <div className="side-navigation">
                        <RCASidebar/>
                    </div>
                    <RCA/>
                </Route>
            </Switch>
          </div>
        </div>
      </main>
    </div>
  );
}
