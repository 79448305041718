import React from 'react';
import "react-table-6/react-table.css";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Grid, Box, InputLabel, MenuItem,  Select, Button } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@mui/material";
// import { Autocomplete, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function SimpleModal(props) {
    const {filterPeriod, dtkpi, plandetails} = props
    const dispatch = useDispatch();
    const [consoleEditing, setConsoleEditing] = useState(false)
    const [oprArr, setOprArr] = useState([
        {"key": "increase", "label": "Increase"},
        {"key": "decrease", "label": "Decrease"},
    ])
    const [oprValues, setOprValues] = useState([]);
    const [valValues, setValValues] = useState([]);

    const toTitleCase = (str) => {
        return str.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    React.useEffect(() => {
        if (dtkpi && plandetails) {
            let oprValuess = [];
            let valValuess = [];
            dtkpi.forEach((x, idx) => {
                let buValue = plandetails.budget_value? plandetails.budget_value.find(item => {
                    return x.key == item.key;
                }) : null
                if (buValue) {
                    oprValuess[idx] = {"key": buValue.operation, "label": toTitleCase(buValue.operation)};
                    valValuess[idx] = buValue.value;
                }
            })
            setOprValues(oprValuess);
            setValValues(valValuess);
        }
    }, [dtkpi, plandetails])

    const closeEditKpiBudget = () => {
        setTimeout(() => {
            setConsoleEditing(false);
        }, 400);
        props.modalCallBack(false);
    };

    const handleSaveKpiBudget = (event) => {
        event.preventDefault();
        let payload = {
            "_id": filterPeriod._id
        }
        
        let budgetValue = [];
        dtkpi.forEach((item, index) => {
            budgetValue.push({
                key: item.key,
                operation: '',
                value: 0
            })
        })
       
        dtkpi.forEach((item, index) => {
            let oprValue = oprValues[index] ? oprValues[index].key : '';
            let valValue = Math.floor(valValues[index]);
            budgetValue[index]['operation'] = oprValue ? oprValue : ''
            budgetValue[index]['value'] = valValue ? valValue : 0
        })
        payload["budget_value"] = budgetValue.filter(el => {
            return el.operation != '' && el.value != 0;
        })

        props.saveKpiBudgetModels(payload);
        // handleReload();
        closeEditKpiBudget();
    };

    // const handleReload = () => {
    //     let thisPayload = filterPeriod
    //     thisPayload.export = false
    //     dispatch({
    //         type: 'FETCH_KPI_PLANNING',
    //         payload: thisPayload,
    //     });
    // }

    const handleOperationChange = (index, value) => {
        const newInputValues = [...oprValues];
        newInputValues[index] = value;
        setOprValues(newInputValues);
    };

    const handleValueChange = (index, value) => {
        const newInputValues = [...valValues];
        newInputValues[index] = value;
        setValValues(newInputValues);
    };

    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder, dis }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <Autocomplete
                    className = 'date-selection new-autocomplete'
                    disabled={dis}
                    fullWidth
                    disablePortal
                    disableListWrap
                    size='small'
                    value={selectedValue}
                    options={menuItems}
                    onChange={handleChange}
                    getOptionLabel={(option)=> option.label}
                    renderInput={(params) => (
                        <TextField
                            className="test-input-box"
                            {...params}
                            variant="standard"
                            label = {inputLabel}
                            placeholder={placeHolder}
                        />
                        )}                                                       
                />
            </FormControl>
        );
    };

    const handleInline = () => {
        props.modalCallBack(false);
        props.inlineEditing(true);
    }
    
    const handleConsole = () => {
        setConsoleEditing(true);
        props.inlineEditing(false);
    }

return (
    <div>
        <Modal
            open={props.editKpiBudget}
            onClose={closeEditKpiBudget}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.editKpiBudget}>
                <div className='customModal viewEmailModal'>
                    <div className='header'>
                        <h2>{props.header}</h2>
                    </div>
                    <div className='modal-content email-template'>
                        {consoleEditing ? 
                        <form onSubmit={handleSaveKpiBudget}>
                            {
                                dtkpi.map((item, index) => (
                                    // <span className='indent' key={index}>{item.key}</span>
                                    <Grid key={index} container className="dropdown-filter" style={{ margin: '15px 0' }}>
                                        {/* <Grid item xs={3} className="maxw-200 mr-10"> */}
                                        <Grid item xs={5} className="maxw-200" style={{ margin: '10px 10px' }}>
                                            <span>{item.kpi}</span>
                                        </Grid>
                                        <Grid item xs={4} className="maxw-200" style={{ margin: '0px 10px' }}>
                                            <SelectDropdown
                                                    menuItems={oprArr}
                                                    handleChange={(e, val) => handleOperationChange(index, val)}
                                                    // inputLabel={'Operation'}
                                                    selectedValue={oprValues[index]}
                                                    placeHolder={'Select Operation'}
                                                />
                                        </Grid>
                                        <Grid item xs={2} className="maxw-200" style={{ margin: '-5px 10px' }}>
                                            <TextField
                                                type="number"
                                                id={'value_'+index}
                                                // label="Pct (%)"
                                                // placeHolder='Percentage (%)'
                                                    // style={{ margin: 8 }}
                                                placeholder="in %"
                                                helperText=""
                                                InputProps={{
                                                    inputProps: { 
                                                        max: 100, min: 0 
                                                    },
                                                  }}
                                                fullWidth
                                                value={valValues[index] || ''}
                                                onChange={(e) => handleValueChange(index, e.target.value)}
                                                // margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    
                                ))
                            }
                            <div className="flex jc-center mt-30">
                                <Button type="submit" variant="contained" className='ml-16' color="primary">SAVE</Button>
                                <Button variant="contained" className='ml-16' onClick={() => { closeEditKpiBudget() }}>
                                    Cancel</Button>
                            </div>
                        </form> :
                        <Grid container xs={12} sm={12} justifyContent='center' className="mt-10">
                            <Button className="newButton btnBlue mr-10"
                                variant="outlined" color="primary"
                                onClick={handleInline}>
                                    Inline Editing
                            </Button>
                            <Button className="newButton btnBlue mr-10"
                                variant="outlined" color="primary"
                                onClick={handleConsole}>
                                    Console Editing
                            </Button>
                        </Grid>
                        }
                    </div>
                </div>

            </Fade>
        </Modal>
    </div>
    );
}