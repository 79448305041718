import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Highchart from './highchart';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../../ImprovementPlan/comment';
import { getAccessByMenuKey } from "../../../../utils/userManagement";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function CompareAllBenchmarks(props) {

    const [breakdownByArr, setBreakdownByArr] = React.useState(['Monthly', 'Quarterly'])
    const [filterByArr, setFilterByArr] = React.useState(['Line of Business', 'Functions', 'Projects'])
    const [stagesArr, setStagesArr] = React.useState(['EBITDA', 'Inventory', 'Recievables', 'Revenue', 'Cost'])
    const [breakdownByValue, setBreakdownByValue] = React.useState(null)
    const [filterByValue, setFilterByValue] = React.useState(null)
    const [stagesValue, setStagesValue] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const handleOpen = ()=>{ setOpen(true)};
    const handleClose = ()=> {setOpen(false)};

    const handleBreakdownByChange = (event) => {
        if (event.target) {
            setBreakdownByValue(event.target.value)
        }
    };

    const handleFilterByChange = (event) => {
        if (event.target) {
            setFilterByValue(event.target.value)
        }
    };

    const handleStagesChange = (event) => {
        if (event.target) {
            setStagesValue(event.target.value)
        }
    };

    return (
        <div className="MonthlyPerformace mb-20">
            <Grid container spacing={3} className="mb-20 filter-options">                
                <Grid item xs={12} sm={2} className='maxw-200 breakdown-by-dropdownlist'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Time Period</InputLabel>
                        <Select
                            value={breakdownByValue}
                            label="Month"
                            onChange={handleBreakdownByChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {breakdownByArr && breakdownByArr.length && breakdownByArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className='maxw-200 filter-by-custom-ddl'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Dimensions</InputLabel>
                        <Select
                            value={filterByValue}
                            label="Quarter"
                            onChange={handleFilterByChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {filterByArr && filterByArr.length && filterByArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className='maxw-200 stages-custom-ddl'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>KPI's</InputLabel>
                        <Select
                            value={stagesValue}
                            label="EBITDA"
                            onChange={handleStagesChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {stagesArr && stagesArr.length && stagesArr.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className="collaborate-button text-right">
              {getAccessByMenuKey(props.menuData, "variance_analysis", "chat")  &&
                    <Button variant="contained" className='newButton btnBlue btnCollaborate uppercase' size="small" color="primary" onClick={handleOpen}>Collaborate</Button>
                        }
                </Grid>
                <Modal
                className="collaborate-modal"
                open ={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <div style={style}>
                        <CommentBox/>
                    </div>
                </Modal>    
            </Grid>
            <Highchart />
        </div>
    )
}
