import React from 'react';
import { connect } from "react-redux";
import GeneratePasswordComponent from '../../Auth/ChangePasswordModal'

export class GeneratePassword extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { authReducer } = this.props
        return (
            <div>
                <GeneratePasswordComponent 
                    showModal={
                        (authReducer.showResetModal)
                      }
                />
            </div>
        );
    }
}

export default
    connect(
        state => {
            return {
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                },
                getAllUser(data) {
                    dispatch({
                        type: "FETCH_ALL_USER",
                        payload: data,
                    });
                },
            };
        }
    )(GeneratePassword);