import React from 'react';
import DragList from './DragList';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


export default function Kanban() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
        <Grid item xs={12}>
            <Grid container>
                  <Grid item xs={12} className="flex ai-center jc-space-between">
                      <div className="description text-primary-custom">Drag and Drop plans to move from one tile to other</div>
                      <div className="action-hide-show">
                        <div className="title mb-10">Click to hide/show</div>
                        <div className="btn-list">
                          <Button variant="contained" className="mr-20 btn-custom nextWeek" size="small">DUE NEXT WEEK</Button>
                          <Button variant="contained" className="mr-20 btn-custom overdue" size="small">OVERDUE</Button>
                          <Button variant="contained" className="btn-custom completed" size="small">Completed</Button>
                        </div>
                      </div>
                  </Grid>
              </Grid>
              <DragList/>
        </Grid>
    </Grid>
  );
}
