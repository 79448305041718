import React from 'react';
import { connect } from 'react-redux';
import 'react-table-6/react-table.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import { getAccessByMenuKey } from "../../../utils/userManagement";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AOPForecastTableComponent from './AOPForecastTableComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}
export class AOPForecastComponent extends React.Component {
	constructor(props) {
		super(props);
		this.MPLClassificationMap = {
            0: 'summary',
            1: 'New BU 1',
            2: 'New BU 2',
            3: 'BU 1',
            4: 'BU 2',
            5: 'BU 3',
            6: 'BU 4',
            7: 'OH',
        }
		this.MPLDrilldownMap = {
            all: 'All',
            wh: 'West',
            eh: "East"
        }
	
		this.state = {
			loading: true,
			tabValue: 0,
			hemisphereRadioValue: 'all',
			aopRadioValue: 'original',
			topSideRadioValue: 0,
			buRadioValue: 'summary',
		};
	}

	componentDidMount() {
		this.fetchDataFromServer()
	}

	handleChange = (event, newValue) => {
		let aopValue = ''
		if(newValue == '0'){
			aopValue = 'original'
		}else if( newValue == '1'){
			aopValue ='q1_original'
		}else if (newValue == '2'){
			aopValue = 'nov'
		}
		this.setState({
			tabValue: newValue,
			aopRadioValue :aopValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleaopRadioChange = (event, newValue) => {
		this.setState({
			aopRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleTopSideRadioChange = (event, newValue) => {
		this.setState({
			topSideRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleBURadioValue = (event, newValue) => {

		this.setState({
			buRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})

	};

	handleHemisphereRadioValue = (event, newValue) => {
		this.setState({
			hemisphereRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};
	handleThreeLevelInvoiceClick = (payload) => {
        const { buRadioValue,hemisphereRadioValue,  radioSelectorValueMPR, radioSelectorValue} = this.state
		const { monthSelectorValue, yearSelectorValue, qtrSelectorValue } = this.props
		let drilldownPayload = {
            ...payload,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            origination :  "true",
            classification :buRadioValue,
            hemisphere : this.MPLDrilldownMap[hemisphereRadioValue],
        }
        this.props.fetchNewOriginationDrilldownData(drilldownPayload)
    }

	fetchDataFromServer = () => {
		const { tabValue, hemisphereRadioValue, aopRadioValue, topSideRadioValue, buRadioValue } = this.state
		const { monthSelectorValue, yearSelectorValue, qtrSelectorValue } = this.props

		let payload = {
			classification: buRadioValue,
			aop: aopRadioValue,
			hemisphere: hemisphereRadioValue,
			yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
			year: yearSelectorValue ? yearSelectorValue : undefined,
			quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
		}

		this.props.setAOPTabState(payload)

		this.props.getAOPTableData(payload)

	}

	render() {
		const { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
		const {
			tabValue,
			hemisphereRadioValue,
			aopRadioValue,
			topSideRadioValue,
			buRadioValue,
		} = this.state;

		console.log(this.props);

		return (
			<div>
				<div>
					<Tabs
						value={tabValue}
						onChange={this.handleChange}
						indicatorColor='primary'
						textColor='primary'
						className='blockTabs mb-10'
					>
						<LinkTab value={0} label='AOP' href='/drafts' {...a11yProps(0)} />
						<LinkTab
							value={1}
							label='Qtr Forecast'
							href='/trash'
							{...a11yProps(1)}
						/>
						<LinkTab value={2} label='MPR' href='/spam' {...a11yProps(2)} />
					</Tabs>
				</div>
				<div>
				{tabValue == 0 && <FormControl component='fieldset'>
						<RadioGroup
							value={aopRadioValue}
							onChange={this.handleaopRadioChange}
							name='radio'
							className='blockRadio'
						>
							<FormControlLabel
								value={'original'}
								control={<Radio color='primary' />}
								label='AOP Original'
							/>
							<FormControlLabel
								value={'topside'}
								control={<Radio color='primary' />}
								label='AOP Topside'
							/>
							<FormControlLabel
								value={'final'}
								control={<Radio color='primary' />}
								label='AOP Final'
							/>
						</RadioGroup>
					</FormControl>}
					{tabValue == 1 && <FormControl component='fieldset'>
						<RadioGroup
							value={aopRadioValue}
							onChange={this.handleaopRadioChange}
							name='radio'
							className='blockRadio'
						>
							<FormControlLabel
								value={'q1_original'}
								control={<Radio color='primary' />}
								label='Q1 Original'
							/>
							<FormControlLabel
								value={'q1_topside'}
								control={<Radio color='primary' />}
								label='Q1 Topside'
							/>
							<FormControlLabel
								value={'q1_final'}
								control={<Radio color='primary' />}
								label='Q1 Final'
							/>

							<FormControlLabel
								value={'q2_original'}
								control={<Radio color='primary' />}
								label='Q2 Original'
							/>
							<FormControlLabel
								value={'q2_topside'}
								control={<Radio color='primary' />}
								label='Q2 Topside'
							/>
							<FormControlLabel
								value={'q2_final'}
								control={<Radio color='primary' />}
								label='Q2 Final'
							/>

							<FormControlLabel
								value={'q3_original'}
								control={<Radio color='primary' />}
								label='April Forecast'
							/>
							<FormControlLabel
								value={'q3_topside'}
								control={<Radio color='primary' />}
								label='Q3 Topside'
							/>
							<FormControlLabel
								value={'q3_final'}
								control={<Radio color='primary' />}
								label='Q3 Final'
							/>

							<FormControlLabel
								value={'q4_original'}
								control={<Radio color='primary' />}
								label='Q4 Original'
							/>
							<FormControlLabel
								value={'q4_topside'}
								control={<Radio color='primary' />}
								label='Q4 Topside'
							/>
							<FormControlLabel
								value={'q4_final'}
								control={<Radio color='primary' />}
								label='Q4 Final'
							/>
						</RadioGroup>
					</FormControl>}

					{tabValue == 2 && <FormControl component='fieldset'>
						<RadioGroup
							value={aopRadioValue}
							onChange={this.handleaopRadioChange}
							name='radio'
							className='blockRadio'
						>
							{/* <FormControlLabel
								value={'oct'}
								control={<Radio color='primary' />}
								label='Oct'
							/> */}
							<FormControlLabel
								value={'nov'}
								control={<Radio color='primary' />}
								label='Nov'
							/>
							<FormControlLabel
								value={'dec'}
								control={<Radio color='primary' />}
								label='Dec'
							/>
							{/* <FormControlLabel
								value={'jan'}
								control={<Radio color='primary' />}
								label='Jan'
							/> */}
							<FormControlLabel
								value={'feb'}
								control={<Radio color='primary' />}
								label='Feb'
							/>
							<FormControlLabel
								value={'mar'}
								control={<Radio color='primary' />}
								label='Mar'
							/>
							{/* <FormControlLabel
								value={'apr'}
								control={<Radio color='primary' />}
								label='Apr'
							/> */}
							<FormControlLabel
								value={'may'}
								control={<Radio color='primary' />}
								label='May'
							/>
							<FormControlLabel
								value={'jun'}
								control={<Radio color='primary' />}
								label='Jun'
							/>
							{/* <FormControlLabel
								value={'jul'}
								control={<Radio color='primary' />}
								label='Jul'
							/> */}
							<FormControlLabel
								value={'aug'}
								control={<Radio color='primary' />}
								label='Aug'
							/>
							<FormControlLabel
								value={'sep'}
								control={<Radio color='primary' />}
								label='Sep'
							/>
							
						</RadioGroup>
					</FormControl>}
				</div>
				<div>
					<FormControl component='fieldset'>
						<RadioGroup
							value={buRadioValue}
							onChange={this.handleBURadioValue}
							name='radio'
							className='radioGroupHorizontal'
						>
							<FormControlLabel
								value={'summary'}
								control={<Radio color='primary' />}
								label='Summary'
							/>
							<FormControlLabel
								value={'New BU 1'}
								control={<Radio color='primary' />}
								label='New BU 1'
							/>
							<FormControlLabel
								value={'New BU 2'}
								control={<Radio color='primary' />}
								label='New BU 2'
							/>
							<FormControlLabel
								value={'BU 1'}
								control={<Radio color='primary' />}
								label='BU 1'
							/>
							<FormControlLabel
								value={'BU 2'}
								control={<Radio color='primary' />}
								label='BU 2'
							/>
							<FormControlLabel
								value={'BU 3'}
								control={<Radio color='primary' />}
								label='BU 3'
							/>
							<FormControlLabel
								value={'BU 4'}
								control={<Radio color='primary' />}
								label='BU 4'
							/>
							<FormControlLabel
								value={'OH'}
								control={<Radio color='primary' />}
								label='OH'
							/>
						</RadioGroup>
					</FormControl>
				</div>
				<div>
					<FormControl fullWidth>
						<RadioGroup
							value={hemisphereRadioValue}
							onChange={this.handleHemisphereRadioValue}
							aria-label='radio filter'
							className='blockRadio'
						>
							<FormControlLabel
								value={'all'}
								key={0}
								control={<Radio />}
								label={'Summary'}
							/>
							<FormControlLabel
								value={'wh'}
								key={1}
								control={<Radio />}
								label={'WH'}
							/>
							<FormControlLabel
								value={'eh'}
								key={2}
								control={<Radio />}
								label={'EH'}
							/>
							<FormControlLabel
								 value={'Canada,US'}
								key={3}
								control={<Radio />}
								label={'Total NAM'}
							/>
							<FormControlLabel
								value={'Canada'}
								key={3}
								control={<Radio />}
								label={'Canada'}
							/>
							<FormControlLabel
								value={'US'}
								key={3}
								control={<Radio />}
								label={'US'}
							/>
								<FormControlLabel
								value={'UK AND NORTH EUROPE,RUSSIA AND CASPIAN,SOUTH EUROPE / NA,MEA'}
								key={4}
								control={<Radio />}
								label={'TOTAL EMEA'}
							/>
							<FormControlLabel
								value={'UK AND NORTH EUROPE'}
								key={5}
								control={<Radio />}
								label={'UK AND NORTH EUROPE'}
							/>
							<FormControlLabel
								value={'RUSSIA AND CASPIAN'}
								key={6}
								control={<Radio />}
								label={'RUSSIA AND CASPIAN'}
							/>
							<FormControlLabel
								value={'SOUTH EUROPE / NA'}
								key={7}
								control={<Radio />}
								label={'SOUTH EUROPE/NA'}
							/>
							<FormControlLabel
								value={'MEA'}
								key={8}
								control={<Radio />}
								label={'MEA'}
							/>
							
							<FormControlLabel
								value={'ASIA PACIFIC'}
								key={9}
								control={<Radio />}
								label={'ASIA PACIFIC'}
							/>
							<FormControlLabel
								value={'MEX,LAM'}
								key={4}
								control={<Radio />}
								label={'TOTAL LAR'}
							/>
							<FormControlLabel
								value={'MEX'}
								key={4}
								control={<Radio />}
								label={'MEX'}
							/>
							<FormControlLabel
								value={'LAM'}
								key={10}
								control={<Radio />}
								label={'LAR'}
							/>
						</RadioGroup>
					</FormControl>
				</div>
				<div>
					{loading ? <div className='progressfullpage'><CircularProgress /></div> : 
					<AOPForecastTableComponent
					{...this.props}
						pivotHeaderData={pivotHeaderData}
						pivotRowData={pivotRowData}
						loading={loading}
						handlePropsThreeLevelInvoiceClick={this.handleThreeLevelInvoiceClick}
					/>
					}

				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			incomeStatementState: state.incomeStatement,

		};
	},
	(dispatch) => {
		return {
			getAOPTableData(data) {
				dispatch({
					type: 'FETCH_AOPDESTINATION_TABLE_DATA',
					payload: data
				});
			},
			fetchNewOriginationDrilldownData(data) {
				dispatch({
					type: 'FETCH_New_DRILLDOWN_ORIGINATION',
					payload: data
				});
			},
		};
	}
)(AOPForecastComponent);
