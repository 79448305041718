import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DownloadExcel from '../../../src/images/download_excel.svg';
import CCAImg from '../../images/cca.png';
import Dash1Img from '../../images/dash1.png';
import Prof2Img from '../../images/eco1.png';
import Prof1Img from '../../images/marg1.png';
import OcosAllocation from '../../images/ocosAllocation.png';
import AnalsisImg from '../../images/sus1.png';
import MapComponent from './MapBox';
import Legend from './MapBox/Legend';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			{value === index && { children }}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export class GeoLocationComponent extends React.Component {
	constructor(props) {
		super(props);

		this.tabMap = {
			0: 'summary',
			1: 'BU 1',
			2: 'BU 2',
			3: 'BU 3',
			4: 'BU 4',
			5: 'OH',
		};

		this.imgMap = {
			0: AnalsisImg,
			1: Prof1Img,
			2: Prof2Img,
			3: CCAImg,
			4: Dash1Img,
		};
		this.imgOcos = {
			0: OcosAllocation,
		};

		this.radioMap = {
			0: 'all',
			1: 'hemisphere',
			2: 'region',
		};

		this.state = {
			showLoader: true,
			isDetailedView: false,
			regionClicked: {},
			hemisphereClicked: {},
			monthSelectorValue: '',
			qtrSelectorValue: '',
			additionalData: [],
			chartDataSO: [],
			TableDataSO: [],
			invoiceObject: null,
			yearSelectorValue:
				this.props.incomeStatementState.defaultYear &&
				this.props.incomeStatementState.yearFilterArr.length
					? this.props.incomeStatementState.defaultYear
					: '',
			radioSelectorValue: '0',
			radioSelectorReconValue: '0',
			comparativeSelectorValue: '1',
			tabSelectorValue: 'Summary',
			radioSelectorValueLE: 'summaryLE',
			radioSelectorValuePlant: 'all',
			radioSelectorValueDGSAutomation: 'lawson',
			radioSelectorDynanicDGS: 'Total',
			newRadioSelectorValue: '4',
			NewTabList: [],
			radioSelectorValueSO: 'all',
			radioType: '',
			GraphView: true,
			radioArrSelected: 'Default',
			radioArrCountrySelected: 'Default',
			commentModal: false,
			DGAAutomationRadioList: [
				{ text: 'Dynamic Radio 1', value: 'Dynamic Radio 1' },
				{ text: 'Dynamic Radio 2', value: 'Dynamic Radio 2' },
				{ text: 'Dynamic Radio 3', value: 'Dynamic Radio 3' },
			],
			invoiceDetailStateNew: '',
			invoiceDetailFilter: '',
			showInvoiceDetailModal: false,
			showInvoiceDetailModalChart: false,
			selectedDrilldown: null,
			commentValue: '',
			radioData: [],
			radioDataSample: '',
		};
	}

	componentDidMount() {
		let payload = {
			recon_value: this.state.radioSelectorReconValue,
			radiovalue: 'optimus',
		};
		this.props.getNewDefaultMonthDataDynamic(payload);
		this.props.getNewDefaultMonthData();
		this.handleNewAccess();
		this.props.incomeStatementState.defaultYear && this.getMapData();
	}

	componentDidUpdate(prevProps) {
		const { yearFilterArr, defaultYear } = this.props.incomeStatementState;
		if (
			prevProps.incomeStatementState.yearFilterArr.length !=
			yearFilterArr.length
		) {
			this.setState(
				{
					yearSelectorValue: defaultYear,
				},
				() => this.getMapData()
			);
		}
	}

	getMapData = () => {
		const {
			tabSelectorValue,
			radioSelectorValue,
			monthSelectorValue,
			yearSelectorValue,
			qtrSelectorValue,
		} = this.state;
		const payload = {
			bu: tabSelectorValue,
			type:
				radioSelectorValue == '0'
					? 'country_name'
					: radioSelectorValue == '1'
					? 'hemisphere'
					: 'region',
			month: monthSelectorValue.replaceAll(' ', '-'),
			year: yearSelectorValue,
			quarter: qtrSelectorValue,
		};
		this.props.getGeospacialAnalysisData(payload);
	};

	handleNewAccess = () => {
		let tabList = [
			{ text: 'Summary', value: 'Summary' },
			{ text: 'New BU 1', value: 'New BU 1' },
			{ text: 'New BU 2', value: 'New BU 2' },
		];
		this.setState({ NewTabList: tabList });
	};

	a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	handleMonthChange = (event) => {
		if (event.target) {
			this.setState(
				{
					monthSelectorValue: event.target.value,
					qtrSelectorValue: '',
					yearSelectorValue: '',
				},
				() => this.getMapData()
			);
		}
	};

	handleQtrChange = (event) => {
		if (event.target) {
			this.setState(
				{
					qtrSelectorValue: event.target.value,
					monthSelectorValue: '',
					yearSelectorValue: '',
				},
				() => this.getMapData()
			);
		}
	};

	handleYearChange = (event) => {
		if (event.target) {
			this.setState(
				{
					yearSelectorValue: event.target.value,
					qtrSelectorValue: '',
					monthSelectorValue: '',
				},
				() => this.getMapData()
			);
		}
	};

	handleTabChange = (event, newValue) => {
		this.setState(
			{
				tabSelectorValue: newValue,
				radioSelectorValue: '0',
			},
			() => this.getMapData()
		);
	};

	handleRadioChange = (event) => {
		this.setState(
			{
				radioSelectorValue: event.target.value,
			},
			() => this.getMapData()
		);
	};

	render() {
		const { quarterfilterArr, monthFilterArr, yearFilterArr } =
			this.props.incomeStatementState;
		const {
			monthSelectorValue,
			radioSelectorValue,
			yearSelectorValue,
			tabSelectorValue,
			comparativeSelectorValue,
			NewTabList,
		} = this.state;
		const { mapDataStatus, mapData } = this.props.geospacialState;

		const legendData = {
			legendRevenueLabel: [
				['>=20%', '#015ABB'],
				['5-20%', '#70B5FA'],
				['<5%', '#B6D3F3'],
			],
			legendDSOLabel: [
				['<5 Days', '#008000'],
				['+/-5 Days', '#f3b700'],
				['>5 Days', '#d2222d'],
			],
			legendMarginLabel: [
				['>2%', '#008000'],
				['+/-2%', '#f3b700'],
				['<2%', '#d2222d'],
			],
		}

		return (
			<>
				{mapDataStatus === 'loading' && (
					<div className='progressfullpage'>
						<CircularProgress />
					</div>
				)}
				<div className='flex jc-space-between'>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4} className='maxw-200'>
							<FormControl
								variant='outlined'
								className='selectOutlineSmall'
								fullWidth
								size='small'
							>
								<InputLabel>Month</InputLabel>
								<Select
									value={monthSelectorValue}
									label='Month'
									onChange={this.handleMonthChange}
								>
									<MenuItem value=''>
										<em>Select</em>
									</MenuItem>
									{monthFilterArr &&
										monthFilterArr.length &&
										monthFilterArr.map((item) => {
											return (
												<MenuItem value={item} key={item}>
													{item}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4} className='maxw-200'>
							<FormControl
								variant='outlined'
								className='selectOutlineSmall'
								fullWidth
								size='small'
							>
								<InputLabel>Quarter</InputLabel>
								<Select
									value={this.state.qtrSelectorValue}
									label='Quarter'
									disabled={
										radioSelectorValue == 4 &&
										(comparativeSelectorValue == 1 ||
											comparativeSelectorValue == 3 ||
											comparativeSelectorValue == 4)
									}
									onChange={this.handleQtrChange}
								>
									<MenuItem value=''>
										<em>Select</em>
									</MenuItem>
									{quarterfilterArr &&
										quarterfilterArr.length &&
										quarterfilterArr.map((item) => {
											return (
												<MenuItem value={item.value} key={item.value}>
													{item.label}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4} className='maxw-200'>
							<FormControl
								variant='outlined'
								className='selectOutlineSmall'
								fullWidth
								size='small'
							>
								<InputLabel>Annual</InputLabel>
								<Select
									value={yearSelectorValue}
									label='Annual'
									onChange={this.handleYearChange}
								>
									<MenuItem value=''>
										<em>Select</em>
									</MenuItem>
									{yearFilterArr &&
										yearFilterArr.length &&
										yearFilterArr.map((item) => {
											return (
												<MenuItem value={item} key={item}>
													{item}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container justify='flex-end'>
						<Legend
							stops={legendData.legendRevenueLabel}
							dso={legendData.legendDSOLabel}
							margins={legendData.legendMarginLabel}
						/>
						<Tooltip title={'Download to Excel'} className='pointer'>
							<img src={DownloadExcel} className='pointer' height='30' />
						</Tooltip>
					</Grid>
				</div>
				<Tabs
					value={tabSelectorValue}
					onChange={this.handleTabChange}
					indicatorColor='primary'
					textColor='primary'
					className='mt-16 mb-16'
				>
					{NewTabList &&
						NewTabList.map((tabItem, index) => (
							<Tab
								label={tabItem.value}
								value={tabItem.value}
								{...this.a11yProps(index)}
							/>
						))}
				</Tabs>
				<FormControl component='fieldset'>
					<RadioGroup
						name='radio'
						value={radioSelectorValue}
						className='radioGroupHorizontal'
						onChange={this.handleRadioChange}
					>
						<FormControlLabel
							value='0'
							control={<Radio color='primary' />}
							label='All'
						/>
						<FormControlLabel
							value='1'
							control={<Radio color='primary' />}
							label='By Hemisphere'
						/>
						<FormControlLabel
							value='2'
							control={<Radio color='primary' />}
							label='By Region'
						/>
					</RadioGroup>
				</FormControl>
				<MapComponent type={radioSelectorValue} mapData={mapData} />
			</>
		);
	}
}

export default connect(
	(state) => {
		return {
			incomeStatementState: state.incomeStatement,
			authReducerState: state.authReducer,
			geospacialState: state.geospacialState,
		};
	},
	(dispatch) => {
		return {
			getNewDefaultMonthData() {
				dispatch({
					type: 'FETCH_New_DEFAULT_MONTH',
				});
			},
			getNewDefaultMonthDataDynamic(data) {
				dispatch({
					type: 'FETCH_New_DEFAULT_MONTH_DYNAMIC',
					payload: data,
				});
			},
			getGeospacialAnalysisData(data) {
				dispatch({
					type: 'FETCH_GEOSPACIAL_ANALYSIS_DATA',
					payload: data,
				});
			},
		};
	}
)(GeoLocationComponent);
