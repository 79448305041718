import React from 'react';
import { connect } from "react-redux";
import DropdownComponent from './Dropdown';
import ExternalComponent from '../ExternalComponent'

export class BusinessUnitComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {
            externalfilterArr,
            handleApplyFilter,
            summaryRowValue,
            handleSendComment,
            buValue,
            summaryState,
            parentGroup,
            externalFilterObject,
            selectedMonthFilter,
            receivableTabSelectorValue
        } = this.props;
        return (
            <>
                {
                    <React.Fragment>
                        <DropdownComponent
                            externalfilterArr={externalfilterArr}
                            handleApplyFilter={handleApplyFilter}
                            parentGroup={parentGroup}
                            buValue={buValue}
                            selectedMonthFilter={selectedMonthFilter}
                            receivableTabSelectorValue={receivableTabSelectorValue}
                            externalFilterObject={externalFilterObject}
                        />
                        <ExternalComponent
                            summaryRowValue={summaryState.buRowData}
                            handleSendComment={handleSendComment}
                            buValue={buValue}
                            receivableTabSelectorValue={receivableTabSelectorValue}
                            parentGroup={parentGroup}
                            externalFilterObject={externalFilterObject}
                        />
                    </React.Fragment>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                getSummaryDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_DATA'
                    });
                }
            };
        }
    )(BusinessUnitComponent);