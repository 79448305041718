import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../../../utils/numberUtils'


function EmailContainer(props) {
     return (
        <>
         <div className='commentInputSection flex ai-center jc-space-between'>
         </div>
        </>
    )
}

export default EmailContainer;