import axios from "axios";
import { toastr } from "react-redux-toastr";
import history from './history';


export const createAxiosResponseInterceptor = (performLogoutCB) => {

    const interceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Reject promise if usual error
            console.log('axios interceptor', error)
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            /*
             * go back if api gives 401
             */
            // toastr.error('Session expired. Please login again.')
            history.push(`/?redirectUri=${window.location.href}`)
        }
    );
}