import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import React from 'react';
import { connect } from "react-redux";
import NewPagination from "../../../common/components/CustomPagination";


export class UserAnalyticsTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
            sortedData: [],
            totalRowData: {}
        }
        if(this.props.data) {
            this.totalRowData = this.props.data.filter(x => x.data_field === 'Total')[0]
        }
    }

    componentDidMount() {
    }

    handleSubColumnChangeHeader = (data, key) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    Header: item.label,
                    id: item.key,
                    width: item.key == 'Last Login' ? 130: 100,
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    <span>{props.original.sub_column && props.original.sub_column[key] && props.original.sub_column[key][item.key]}</span>
                                }
                            </div>
                        )
                    },
                    Footer: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    <span>{this.totalRowData && this.totalRowData.sub_column[key] && this.totalRowData.sub_column[key][item.key]}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                        cursor: item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        overflow: 'visible',
                        //fontSize: '13px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }
            })
        }
    }

    changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                if (item.key == 'data_field' || item.key == 'user_role') {
                    return {
                        Header: '',
                        id: item.key,
                        columns: [
                            {
                                Header: item.label,
                                id: item.key,
                                Cell: (props) => {
                                    return (
                                        <div>
                                            {
                                                <span>{props.original[item.key]}</span>
                                            }
                                        </div>
                                    )
                                },
                                headerStyle: {
                                    borderRight: item.key == 'user_role' ? '1px solid #423970' : ''
                                },
                                style: {
                                    borderRight: item.key == 'user_role' ? '1px solid #423970' : ''
                                },
                                Footer: item.key == 'data_field' ? 'Grand Total' : ''
                            }
                        ],
                        headerStyle: {
                            textAlign: 'center',
                            borderRight: item.key == 'user_role' ? '1px solid #423970' : ''
                            
                        },
                        style: {
                            textAlign: 'center',
                            borderRight: item.key == 'user_role' ? '1px solid #423970' : ''
                        }
                    }
                }
                return {
                    Header: item.label,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item.key),
                    headerStyle: {
                        borderRight: '1px solid #423970'
                    },
                    style: {
                        borderRight: '1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    sortData = (newSorted, column, additive) => {
        let { data } = this.props;
        data = data.filter(x => x.data_field !== 'Total')
        const headerKey = column.parentColumn.id;
        const subHeaderKey = column.id;
        const sortedData = [...data.sort((a, b) => {
            if (a.sub_column[headerKey] && b.sub_column[headerKey]
                && a.sub_column[headerKey][subHeaderKey]
                && b.sub_column[headerKey][subHeaderKey]) {
                return a.sub_column[headerKey][subHeaderKey] - b.sub_column[headerKey][subHeaderKey]
            }
        })]
        this.setState({ sortedData })
    }

    render() {
        let { loading, rowHeaderData } = this.props.userAnalyticsState;
        let {data} = this.props;
        const { sortedData } = this.state
        if (data){
            data = data.filter(x => x.data_field !== 'Total')
        } else {
            data = {}
        }
        
        rowHeaderData = this.changeKeysOfHeader(rowHeaderData)

        return (
            <React.Fragment>
                {data && data.length ?
                 <ReactTable
                    data={sortedData.length ? sortedData : data}
                    columns={rowHeaderData}
                    loading={loading}
                    pageSize={data.length > 10 ? 10 : data.length}
                    className=' xpna-react-table lastRowBlueColored roleTable xScrollHidden heightOverFlowAllowed -striped -highlight'  
                    onSortedChange={this.sortData}
                    PaginationComponent={NewPagination}
                    pageSizeOptions={[10, 20, 30]}
                    resizable={true}
                /> : null}
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                userAnalyticsState: state.userManagementState.userAnalytics,
                authReducer: state.authReducer,
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(UserAnalyticsTableComponent);