import {getPlanFilter, getPlanCadence} from './transformer'

const initialState = {
    detailDataPlanning:{
        loading: false,
        rowData: [],
        rowHeader: [],
    },
    kpiDataPlanningModels:{
        loading: false,
        rowData: [],
        rowHeader: []
    },
    KPIData :{
        loading: false,
        editLoading: false,
        rowData: [],
        rowHeader: [],
        planDetails: {}
    },
    KPIDataCompare: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    kpiDataPlanningReview: {
        loading: false,
        rowData: [],
        rowHeader: []
    },
    kpiDataPlanningFilter: {
        rowData: [],
    },
    kpiDataPlanningCadence: {
        data : {},
        loading : false,
    }
};

export default (state = initialState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'FETCH_DETAIL_PLANNING_SALES_VOLUME_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_SALES_VOLUME_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DETAIL_PLANNING_NET_SALES_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_NET_SALES_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DETAIL_PLANNING_DIST_COST_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_DIST_COST_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;

        case 'FETCH_DETAIL_PLANNING_VAR_COST_CLINKER_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_VAR_COST_CLINKER_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DETAIL_PLANNING_VAR_COST_CEMENT_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_VAR_COST_CEMENT_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;

        case 'FETCH_DETAIL_PLANNING_FIXED_COST_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_FIXED_COST_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DETAIL_PLANNING_EBITDA_AIN000S_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_EBITDA_AIN000S_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_DETAIL_PLANNING_EBITDA_PERT_LOADER':
            newState.detailDataPlanning.loading = action.loading;
            return newState;
        case 'FETCH_DETAIL_PLANNING_EBITDA_PERT_SUCCESS':
            newState.detailDataPlanning.loading = action.loading;
            newState.detailDataPlanning.rowData = action.data.row_data;
            newState.detailDataPlanning.rowHeader = action.data.row_header;
            return newState;

        case 'FETCH_PLANNING_MODELS_DATA_LOADER':
            // newState.emailTemplateData.Ebitda.loading = action.loading;
            return newState;
        case 'FETCH_PLANNING_MODELS_DATA_SUCCESS':
            // newState.emailTemplateData.Ebitda.loading = false;
            // newState.emailTemplateData.Ebitda.data = action.data;
            return newState;
        case 'FETCH_KPI_PLANNING_MODELS_DATA_SUCCESS':
            newState.kpiDataPlanningModels.loading = false;
            newState.kpiDataPlanningModels.rowData = action.data.row_data;
            newState.kpiDataPlanningModels.rowHeader = action.data.row_header;
            return newState;
        case 'FETCH_KPI_PLANNING_MODELS_DATA_LOADER':
            newState.kpiDataPlanningModels.loading = action.loading;
            return newState;
        case 'FETCH_KPI_PLANNING_DATA_SUCCESS':
            newState.KPIData.loading = false;
            newState.KPIData.rowData = action.data.row_data;
            newState.KPIData.rowHeader = action.data.row_header;
            newState.KPIData.planDetails = action.data.plan_details;
            return newState;
        case 'SAVE_KPI_PLANNING_SUCCESS':
            newState.KPIData.planDetails = action.data[0];
        return newState
        case 'FETCH_KPI_PLANNING_DATA_LOADER':
            newState.KPIData.loading = action.loading;    
            return newState;
        case 'EDIT_KPI_PLANNING_DATA_LOADER':
            newState.KPIData.editLoading = action.loading;    
            newState.kpiDataPlanningCadence.loading = action.loading;
            return newState;
        case 'FETCH_KPI_COMPARE_PLANNING_DATA_SUCCESS':
            newState.KPIDataCompare.loading = false;
            newState.KPIDataCompare.rowData = action.data.row_data;
            newState.KPIDataCompare.rowHeader = action.data.row_header;
            // newState.KPIDataCompare.planDetails = action.data.plan_details;
            return newState;
        case 'FETCH_KPI_COMPARE_PLANNING_DATA_LOADER':
            newState.KPIDataCompare.loading = action.loading;    
            return newState;

        case 'FETCH_KPI_PLANNING_REVIEW_DATA_SUCCESS':
            newState.kpiDataPlanningReview.loading = false;
            newState.kpiDataPlanningReview.rowData = action.data.row_data;
            newState.kpiDataPlanningReview.rowHeader = action.data.row_header;

            newState.kpiDataPlanningFilter.rowData = getPlanFilter(action.data.row_data)
            newState.kpiDataPlanningCadence.data = getPlanCadence(action.data.row_data)
            return newState;
        case 'FETCH_KPI_PLANNING_REVIEW_DATA_LOADER':
            newState.kpiDataPlanningReview.loading = action.loading;
            newState.kpiDataPlanningCadence.loading = action.loading;
            return newState;
        default:
            return newState;
    }
}