import axios from 'axios';
import { CONFIG } from '../../config';

export async function performRecievableFetchService(payload, selectedData) {
	let url = `v1/receivables/all/aging/summery/?report_date=${payload}`;

	if (selectedData && selectedData.receivableTabSelectorValue == 1) {
		url = `v1/receivables/all/aging/New/summery/?report_date=${payload}`;
	}

	if (selectedData && selectedData.customerNameSelected) {
		url = url + '&customer_name=' + selectedData.customerNameSelected;
	}

	return axios
		.get(`${CONFIG.api_base_url}${url}`)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function performRecievableMonthlyRollingFetchService(payload) {
	console.log('performRecievableMonthlyRollingFetchService', payload);

	let apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/monthlyrolling/`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/New/monthlyrolling/`;
	}

	return axios
		.get(apiUrl)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function performNewHemisphere(payload) {
	let report_date = payload.report_date;
	let getUrl = 'pivotallbyhemisphere';
	if (payload.tabHemisphere == 'region') {
		getUrl = 'pivotallbyregion';
	}
	let region = '';
	if (payload.region != '' && payload.region != undefined) {
		region = '&hemisphere=' + payload.region;
	}
	if (payload.type == 'country') {
		getUrl = 'pivotbycountry';
		region = '&region=' + payload.region;
	}
	let exports = '';
	if (payload.export) {
		exports = '&export=' + payload.export;
	}
	let customer = '';
	if (payload.customer && payload.customer.length > 0) {
		customer = '&customer=' + payload.customer;
	}
	// return axios.get(`${CONFIG.api_base_url}v1/receivables/all/aging/New/${getUrl}?report_date=${report_date}${region}`)
	//     .then(res => res)
	//     .catch(err => err.response)  .catch(err => err.response)
	let apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/New/${getUrl}?report_date=${report_date}${region}${customer}${exports}`;
	if (payload.export) {
		return axios({
			url: apiUrl,
			method: 'GET',
			responseType: 'blob',
			data: payload,
		})
			.then((response) => {
				var x = response.data;
				console.log(response, x);
				var u = URL.createObjectURL(x);
				var a = document.createElement('a');
				a.href = u;
				a.download = response.headers['content-disposition'].split('=')[1];
				a.click();
				window.URL.revokeObjectURL(u);
				return response;
			})
			.catch((err) => err.response);
	}
	// payload.export = "false"
	return axios
		.get(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function performRecievableContractLiability(date, payload) {
	// let payload  = {}
	// payload.bu = "Summary"
	console.log(payload, 'cekPayload');
	return axios
		.get(
			`${CONFIG.api_base_url}v1/hfm/summery/?view=billing&report_date=${date}&receivableTabSelectorValue=${payload.receivableTabSelectorValue}`
		)
		.then((res) => res)
		.catch((err) => err.response);
	// return axios.post(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/dso/`, payload)
	//     .then(response => response)
	//     .catch(err => err.response)
}
export async function performRecievableExternalFetchService(payload) {
	let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/data/`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/data/`;
	}

	if (payload.export) {
		return axios({
			url: apiUrl,
			method: 'POST',
			responseType: 'blob',
			data: payload,
		})
			.then((response) => {
				var x = response.data;
				console.log(response, x);
				var u = URL.createObjectURL(x);
				var a = document.createElement('a');
				a.href = u;
				a.download = response.headers['content-disposition'].split('=')[1];
				a.click();
				window.URL.revokeObjectURL(u);
				return response;
			})
			.catch((err) => err.response);
	}
	// payload.export = "false"
	return axios
		.post(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function performRecievableExternalImprovementPlanFetchService(
	payload
) {
	let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/agingimprovementplan/data/`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/agingimprovementplan/New/data/`;
	}

	return axios
		.post(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function sendCommentService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/comment`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getExternalFilterService(payload) {
	let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/data/filter/`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/data/filter/`;
	}

	return axios
		.post(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getPipMasterService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/getdatamaster/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function savePipDataService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/save/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function savePipCommentsService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/addcomments/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getPIPIdService(payload) {
	payload.bu = payload.bu && payload.bu.toLowerCase();

	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/getpipid/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getPIPListService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/get/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function editPIPDataService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/pip/edit/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getAllUserService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/getall`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getCommentDataService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/comment/get`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function performSummaryBUFetchService(payload) {
	let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/summery/`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/summery/`;
	}

	return axios
		.post(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchAllCommentHistoryService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchOneCommentHistoryService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/chat/channel/history/${payload.channelId}`)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchPLFileUploadErrorService(payload) {
	return axios
		.get(
			`${CONFIG.api_base_url}v1/automation/getauditerrors/?process_id=${payload.processId}`
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function createChannelService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v2/receivables/all/aging/channel/`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchAgingReportDateService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/receivables/all/aging/reportdate/`)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchAgingCustomerNameService(payload) {
	return axios
		.get(
			`${CONFIG.api_base_url}v1/receivables/all/aging/customers-list/?report_date=${payload}`
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function sendCollectionForecastService(payload) {
	return axios
		.put(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/collection/forecast/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function updateActionOwnerService(payload) {
	return axios
		.patch(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/data/actionowner/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchOverdueChartSummary(payload) {
	let tabValue = 0;
	if (payload && payload.chartType) {
		tabValue = 1;
	}
	return axios
		.get(
			`${CONFIG.api_base_url}v1/receivables/all/chart/chartsummery?chartType=${tabValue}`
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchOverdueChartByBU(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/chartsummery/filter/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchDsoChartSummary(payload) {
	let url = '';
	console.log(payload, 'cekURL');
	if (payload.receivablesTab == 0) {
		url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/dso/`;
	} else {
		url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/`;
		if (payload.graphType == 'hemisphere') {
			url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/hemispherewisedso`;
		}
		if (payload.graphType == 'region') {
			url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/regionwisedso`;
		}
		if (payload.graphType == 'country') {
			url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/countrywisedso`;
		}

		if (payload.monthFilter && payload.graphType != 'hemisphere') {
			url = url + `?report_date=${payload.monthFilter}`;
		}
	}
	if (payload.export) {
		if (payload.receivablesTab == 0) {
			url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/dso/?export=true`;
		} else {
			url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/?export=true`;
			if (payload.graphType == 'hemisphere') {
				url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/hemispherewisedso?export=true`;
			}
			if (payload.graphType == 'region') {
				url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/regionwisedso?export=true`;
			}
			if (payload.graphType == 'country') {
				url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/New/dso/countrywisedso?export=true`;
			}

			if (payload.monthFilter && payload.graphType != 'hemisphere') {
				url = url + `&report_date=${payload.monthFilter}`;
			}
		}
		return axios({
			url: url,
			method: 'POST',
			responseType: 'blob',
			data: payload,
		})
			.then((response) => {
				var x = response.data;
				console.log(response, x);
				var u = URL.createObjectURL(x);
				var a = document.createElement('a');
				a.href = u;
				a.download = response.headers['content-disposition'].split('=')[1];
				a.click();
				window.URL.revokeObjectURL(u);
				return response;
			})
			.catch((err) => err.response);
	}
	return axios
		.post(url, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function saveDSOdata(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/receivables/all/chart/updateDSO/`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchDsoChartBUWise(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/dso/buwise`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchDsoChartBUWiseDSO(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/dso/buwisedso`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function saveSSDSOData(payload) {
	console.log(payload, 'cekData');
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/all/chart/saveSSDSOData/`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function fetchCollectionGraphService(payload) {
	console.log(payload, 'cekPayload');
	let url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/actualforecast/`;
	if (payload && payload.receivableTabSelectorValue == 1) {
		url = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/chart/onsenew/actualforecast/`;
	}

	return axios
		.post(url, payload)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function fetchAROverdueTableSummary(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/aroverdueanalysis/tableview`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function fetchAROverdueAnalysisAction(payload) {
	let apiUrl = `${CONFIG.api_base_url}v1/aroverdueanalysis/analysis-action`;
	if (payload.receivableTabSelectorValue == 1) {
		apiUrl = `${CONFIG.api_base_url}v1/aroverdueanalysis/analysis-action?new=true`;
	}

	return axios
		.post(apiUrl, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchScatterChartView(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/aroverdueanalysis/chartview`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function fetchScatterChartViewFirstLoad(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/aroverdueanalysis/chartview/filter`, payload)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchAROverdueYearlyChart(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/barchart/comparative`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function sendEmailAnalysisAction(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/send-email-analysis-action`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function saveActionOwnerAnalysis(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/analysis-action/action-party`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function getEmailTemplateAnalysisAction(payload) {
	return axios
		.get(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/email-analysis-action`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function getEmailTemplateUserAnalytics(payload) {
	return axios
		.get(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/email-user-analytics`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function saveEmailTemplateAnalytics(payload) {
	return axios
		.put(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/email-user-analytics`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function saveEmailTemplateAnalysisAction(payload) {
	return axios
		.put(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/email-analysis-action`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchDGSEmailTemplate(payload) {
	console.log('cekParam', payload);
	return axios
		.get(`${CONFIG.api_base_url}v1/receivables/all/aging/email-dgs-validation`)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function sendEmailDGSData(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/all/aging/emailagingforecast`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function saveEmailRecipientDGSData(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/receivables/all/aging/emailsaverecipient`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}

export async function fetchCompareFilterOptionsService(payload) {
	let newPayload = ``
	if (payload){
		newPayload = payload
	}

	return axios.get(`${CONFIG.api_base_url}v1/receivables/all/aging/data/filter/compare/` + newPayload).then(
		response => {
			return response;
		}
	).catch(err => {
		return err.response
	});
}


export async function fetchCompareDataForReceivableService(payload) {

	if (payload.export) {
	 return axios({
		url: `${CONFIG.api_base_url}v1/receivables/all/aging/summery/compare`,
		method: 'POST',
		responseType: 'blob',
		data: payload
	})
		.then(
			response => {
			  var x = response.data;
			  console.log(response, x)
			  var u = URL.createObjectURL(x)
			  var a = document.createElement("a");
			  a.href = u;
			  a.download = (response.headers['content-disposition']).split('=')[1];
			  a.click();
			  window.URL.revokeObjectURL(u);
				return response
			}
		)
		.catch(err => err.response)
	}
	return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/summery/compare`, payload).then(
		response => {
			return response;
		}
	).catch(err => {
  
		return err.response
	});
  }