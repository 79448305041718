import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Grid } from "@material-ui/core";

import ImprovmentInitiativesImage from '../../../../images/improvement-initiatives-ebitda.png';

export  default class ImprovementInitiativesComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            newRadioSelectorValue: '0',
        }
    }    

    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }

    render (){
        const {newRadioSelectorValue} = this.state
        
        return(
            <React.Fragment>
                <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                    <FormControl component="fieldset">
                        <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                            <div className="group">
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="Quality" />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Procurement" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="HR" />
                                <FormControlLabel value="3" control={<Radio color="primary" />} label="Plant" />
                                <FormControlLabel value="4" control={<Radio color="primary" />} label="Sales Region" />
                                <FormControlLabel value="5" control={<Radio color="primary" />} label="Product" />
                                <FormControlLabel value="6" control={<Radio color="primary" />} label="Channels" />
                                <FormControlLabel value="7" control={<Radio color="primary" />} label="Logistics" />
                                <FormControlLabel value="8" control={<Radio color="primary" />} label="Godown" />
                                <FormControlLabel value="9" control={<Radio color="primary" />} label="Packaging" />
                                <FormControlLabel value="10" control={<Radio color="primary" />} label="Mines" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <img src={ImprovmentInitiativesImage} />
                

            </React.Fragment>
        )
    }
}