import React from 'react';
import { connect } from "react-redux";

export class DashboardAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log(this.props.tabSelectorValue, "cek123")
    }

    render() {
       
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}

export default
    connect()(DashboardAnalysis);