import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';
import NewTableComponent from './newTable'
import DownloadExcel from "../../../../images/download_excel.svg";
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import CommentBox from '../../../ECFM/ImprovementPlan/comment';
import Icon from "@material-ui/core/Icon";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class CashflowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.state = {
            newRadioSelectorValue: '0',
            topRadioSelectorValue: '1',
            tabValue: 1,
            openChat: false,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    componentDidMount() {
        // console.log( this.props, "props")
        this.props.fetchCementData({})
    }

    handleExcelDownload = () => {
        this.props.fetchCementData({ export: true })
    }

    handleExternalTabChange = (event) => {
        this.setState({
            reportTabValue: event.target.value
        })
    }

    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }
    handleOpen = () => {
        this.setState({ openChat: true })
    };
    handleClose = () => {
        this.setState({ openChat: false })
    };


    handleTabChange = (event, value) => {
        this.setState({
            tabValue: value
        })
    }


    render() {

        const { newRadioSelectorValue, tabValue } = this.state
        const {cementState, handShowCompare} = this.props

        return (
            <React.Fragment>
                <Grid container xs={12} sm={12} className="pb-15 buttons-section">
                    <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                    <Grid container xs={12} sm={12} className="pb-15 flex justify-between buttons-section">
                                <Grid item xs={12} sm={12} className="pt-10 download-section" >
                                    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                        <Button variant='contained' className='run-button mr-10 newButton btnBlue btnCompare' onClick={handShowCompare}>Search and Compare</Button>
                                        <Tooltip title={"Collaborate"}>
                                            <Icon
                                                className="fs-20 primary mr-10"
                                                color={"inherit"}
                                                disabled
                                                onClick={() => {
                                                    this.props.chatClick();
                                                }
                                                }
                                            >
                                                chat_bubble_filled
                                            </Icon>
                                        </Tooltip>
                                        <Tooltip title={'Download to Excel'}>
                                            <img
                                                src={DownloadExcel}
                                                className='pointer mr-10'
                                                height='30'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.handleExcelDownload();
                                                }} />
                                        </Tooltip>
                                        <Tooltip title={'Download to PDF'}>
                                            <PictureAsPdfIcon fontSize="large"
                                                height='30'
                                                className="pointer mr-10"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    //   this.printDocument();
                                                }} />
                                        </Tooltip>
                                    </div>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12} className="pt-0">
                                <div style={{ maxHeight: '700px',  maxWidth: '1800px', overflow: 'scroll' }}>
                                <NewTableComponent tableData={cementState}/>
                                </div>
                            </Grid>
                    </Grid>

                </Grid>


                <Modal
                    className="collaborate-modal"
                    open={this.state.openChat}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div style={style}>
                        <CommentBox />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                authReducer: state.authReducer,
                cementState: state.shreeCementState.salesLogisticCementData

            }
        },
        dispatch => {
            return {
                fetchCementData(data) {
                    dispatch({
                        type: "FETCH_SALES_LOGISTIC_CEMENT_DATA",
                        payload: data
                    });
                }
            };
        }
    )(CashflowComponent);
