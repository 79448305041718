import React from 'react';
import NewReactTable from './NewReactTable';



class ReactTableComponent extends React.Component {

    shouldComponentUpdate(nextProps) {

        if (this.props.loading != nextProps.loading) {
            return true
        }

        return false
    }

    render() {

        const { pivotRowData, loading, pivotHeaderData,  className } = this.props;


        return (
            !loading && pivotRowData.length ?
            <NewReactTable
                data={pivotRowData}
                className={className}
                loading={loading}
                columns={pivotHeaderData}
            //  tableLen={tableLen}
            //  subComponent={this.subComponent}
            //  expandIcon={this.expandIcon}
            //  rowClick={this.rowClick}
            //  showInvoiceDetailModal={showInvoiceDetailModal}
            //  showLoader={showLoader}
            /> : null
        )

    }
}

export default ReactTableComponent;