import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'fixed',
    // width: theme.spacing.unit * 50,
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
    padding: theme.spacing(4),
    outline: 'none',
  },
});

class SimpleModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalNameValue: ''
    }
  }

  componentDidMount() {
    console.log('SimpleModal', this.props.currentData)
    this.setState({ modalNameValue: this.props.currentData ? this.props.currentData : '' });
  }
  setModalNameValue = (value) => {
		this.setState({ modalNameValue: value });
	};

  saveData = (value) => {
    const {modalNameValue} = this.state
    let payload = {
      label : modalNameValue,
    }
    this.props.handleSaveData(payload)
  }

  render() {
    const { classes } = this.props;
    const {modalNameValue} = this.state
    

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={() => {this.props.toggleModal()}}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <a className="close-modal"
                        title={"Close Modal"}
                        onClick={this.props.toggleModal}
                    >
                        <CloseIcon className="close-icon"/>
                    </a>
                    <TextField id="name-textfield" value={modalNameValue} label="Current Label" variant="standard" onChange={(e) => {this.setModalNameValue(e.target.value)}} fullWidth/>
                    <Button variant='contained' color="primary" className='newButton btnBlue btnSubmit uppercase' fullWidth onClick={this.saveData}>OK</Button>
                </div>
            </Modal>
        </div>
      );
  }
}

// SimpleModal.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// We need an intermediary variable for handling the recursive nesting.
const CustomFunctionModal = withStyles(styles)(SimpleModal);

export default CustomFunctionModal;
