import axios from "axios";
import { CONFIG } from "../../../config";
import { toastr } from "react-redux-toastr";

export async function uploadEPForecastService(payload) {
    return axios
        .post(`${CONFIG.api_base_url}v1/extendedplan/uploadforecast/`, payload)
        .then((response) => response)
        .catch((err) => err.response);
}

export async function fetchActualBUTableDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchActualvsPlanTableDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actualvsplan`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actualvsplan`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchBUPlanTableDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getplan`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getplan`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchActualBUFilterService() {
    return axios
        .get(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/filter`)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchSimulationValueService() {
    return axios
        .get(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getsimulation`)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchSimulationListService() {
    return axios
        .get(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getsimulationlist`)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchSimulationMetaDataService() {
    return axios
        .get(`${CONFIG.api_base_url}v1/extendedplan/getmetadata`)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function saveSimulationService(payload) {
    if(payload.is_save == true){
    return axios
        .post(`${CONFIG.api_base_url}v1/extendedplan/savesimulation`, payload)
        .then((res) => res)
        .catch((err) => err.response);
    }else{
        if (payload.export == "true") {
            return axios({
                url: `${CONFIG.api_base_url}v1/extendedplan/getsamplesimulation`,
                method: "POST",
                responseType: "blob",
                data: payload,
            })
                .then((response) => {
                        // success blob file
                        var x = response.data;
                        var u = URL.createObjectURL(x);
                        var a = document.createElement("a");
                        a.href = u;
                        a.download = response.headers["content-disposition"].split("=")[1];
                        a.click();
                        window.URL.revokeObjectURL(u);
                        return response;
                })
                .catch(async (err) => { 
                    const jsonStr = JSON.parse(await err.response.data.text());
                    err.response.data = jsonStr
                    return err.response 
                });
        }

        return axios
        .post(`${CONFIG.api_base_url}v1/extendedplan/getsamplesimulation`, payload)
        .then((res) => res)
        .catch((err) => err.response);
    }
}

export async function fetchSimulationDataService(payload) {
    return axios
        .get(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getsimulationlist?_id=${payload}`)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function saveSimulationValueService(payload) {
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/savesimulation`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function saveBUPlanDataService(payload) {
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/saveplan`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchActualBUDrilldownDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual/drilldown`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual/drilldown`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function fetchBUPlanDrilldownDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getplan/drilldown`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/getplan/drilldown`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}