import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting"
import highchartsOfflineExporting from "highcharts/modules/offline-exporting"

highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);

function NegativeBarGraph(props) {
    const { graphData, doExport, fileName } = props
    const graphRef = useRef(null);
    useEffect(() => {
        if(doExport) {
            graphRef.current.chart.exportChartLocal({
                type: "application/pdf",
                filename: fileName
            }, {
                title: {
                    text: "Budget Analysis"
                }
            })
            props.handlePrintCallback()
        }
    }, [doExport]);
    const varianceData = graphData.map(res => {
        let per = 0
        let color = ''
        if(res.variance < 0) {
            per = -res.percentage
            color = '#d00909'
        } else {
            per = res.percentage
            color = '#4caf50'
        }
        per *= 100

        if (res.percentage <= 0.10) {
            color = '#ff9502'
        }
        return {
            y: per,
            name: res.budget_group,
            color: color,
            variance: res.variance
        }
    }).sort((a, b) => b.y - a.y);
    const minValue = varianceData[0] > Math.abs(varianceData[varianceData.lenght - 1]) ? varianceData[0] : varianceData[varianceData.lenght - 1];
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                ref={graphRef}
                options={
                    {
                        chart: {
                            type: 'bar',
                            height: 800,
                            numberFormatter: props.transformData,
                            spacing: [20, 30, 20, 20]
                        },
                        title: {
                            text: "",
                        },
                        xAxis: {
                            alignTicks: true,
                            min: minValue,
                            title: {
                                text: 'Above Budget',
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                enabled: false
                            }
                        },
                        yAxis: {
                            min: minValue,
                            title: {
                                text: 'Below Budget',
                                align: 'high',
                                rotation: 90,
                                offset: -225,
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                enabled: false,
                                step: 0
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    crop: false,
                                    overflow: 'none',
                                    color: '#7b7b7b',
                                }
                            }
                        },
                        series: [{
                            data: varianceData,
                            borderRadius: 5,
                            pointWidth: 10,
                            dataLabels: [
                                {
                                    format: '{point.name}',
                                    verticalAlign: 'bottom'
                                },
                                {   
                                    verticalAlign: 'top',
                                    formatter: function () {
                                        const numberFormatter = this.series.chart.numberFormatter;
                                        return typeof this.point.variance !== 'number' ? '' : numberFormatter(this.point.variance, -1);
                                    },
                                }
                            ]
                        }],
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false,
                            fallbackToExportServer: false,
                        }
                    }
                }
            />
        </div>
    )
}
export default NegativeBarGraph;