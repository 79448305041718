import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from "react-redux";
import AddColumn from './AddColumn'
import AddVariance from './AddVariance'
import './index.scss'


export class IncomeStatementComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showAddColumnModal: false,
            showAddVarianceModal: false,
        }
    }

    handleAddColumnClick = () => {
        this.setState({
            showAddColumnModal: true
        })
    }

    handleAddColumnClose = () => {
        this.setState({
            showAddColumnModal: false
        })
    }
    handleAddVarianceClick = () => {
        this.setState({
            showAddVarianceModal: true
        })
    }

    handleAddVarianceClose = () => {
        this.setState({
            showAddVarianceModal: false
        })
    }

    handleSaveData = (payload) => {
        const {yearmonth, year, quarter} = this.props
        let newPayload = {
            ...payload,
            year: year ? year : undefined,
            quarter: quarter ? quarter : undefined,
            yearmonth: yearmonth ? yearmonth : undefined,
        }

        this.props.saveColumnFunctionData(newPayload)
        this.handleAddColumnClose()
        this.handleAddVarianceClose()
    }
    render() {

        const {showAddColumnModal, showAddVarianceModal} = this.state
        const {pivotHeaderData} = this.props.incomeStatementState
        return (
            <div className='add-column-variance'>
            <Button className="button newButton btnBlue btnAdd uppercase" onClick={this.handleAddColumnClick}>Add Column</Button>
            <Button className="button newButton btnBlue btnAdd uppercase" onClick={this.handleAddVarianceClick}>Add Function</Button>    

            <AddColumn 
            showModal={showAddColumnModal}
            handleClose={this.handleAddColumnClose}
            handleSaveData={this.handleSaveData}
            />
            <AddVariance 
            showModal={showAddVarianceModal}
            handleClose={this.handleAddVarianceClose}
            handleSaveData={this.handleSaveData}
            pivotHeaderData={pivotHeaderData}
            />
            </div>
        );
    }
}

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                saveColumnFunctionData(payload) {
                    dispatch({
                        type: 'SAVE_COLUMN_FUNCTION_DATA',
                        payload
                    });
                }
            };
        }
    )(IncomeStatementComponent);