import React, { useState, useEffect } from "react";
// Import Highcharts
import Highcharts from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import HighchartsReact from "highcharts-react-official";
import HighchartComponent from './highchart'
import { useSelector } from "react-redux";

export default function MapsChart(props) {
  const freeCashFlowState = useSelector(state => state.freeCashFlowState);
  const { loading, chartRowData } = freeCashFlowState.analyticStudio;

  console.log("trace 0", chartRowData);

  const onPointClick = (event) => {
    // console.log("trace 0", event.point["hc-key"]);
    console.log("trace 0", event.point);
  };

  const [options, setOptions] = useState({
    chart: {
      map: worldMap
    },
    title: {
      text: 'Revenue'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox"
      }
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        name: "Revenue",
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        point: {
          events: {
            click: (e) => {
                onPointClick(e);
            }
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        },
        allAreas: true,
        data: [
          ["fo", 51749274],
          ["um", 110749274],
          ["us", 1749274],
          ["jp", 22749274],
          ["sc", 9749274],
          ["in", 749274],
          ["fr", 6749274],
          ["fm", 222749274],
          ["cn", 8749274],
          ["au", 220749274]
        ]
      }
    ]
  });

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={options}
    />
  );
};

// render(<App />, document.getElementById("root"));
