import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KanbanBoard from './kanban.jsx';
import SolutionWorkFlow from './solution.jsx';
import FailureMechanism from './failure.jsx';
import PreventiveMeasure from './preventive.jsx';
import RCATable from './table.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="pt-30">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


export default function Rca() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="rca-content">
      <AppBar position="static" color="default" className="custom-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Kanban Board" {...a11yProps(0)} />
          <Tab label="Failure Mechanism" {...a11yProps(1)} />
          <Tab label="Solution Workflow" {...a11yProps(2)} />
          <Tab label="RCA table" {...a11yProps(3)} />
          <Tab label="Preventive Measure" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className="tab-panel">
          <KanbanBoard/>
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-panel">
          <FailureMechanism/>
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-panel">
          <SolutionWorkFlow/>
      </TabPanel>
      <TabPanel value={value} index={3} className="tab-panel">
          <RCATable/>
      </TabPanel>
      <TabPanel value={value} index={4} className="tab-panel">
          <PreventiveMeasure/>
      </TabPanel>
    </div>
  );
}
