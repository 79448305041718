import React from "react";
import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import DownloadExcel from "../../../images/download_excel.svg";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';

//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";

export default function (props) {
    const {planningData} = props;
    const {rowData, filter} = planningData;
    const { planTypeArr, periodArr, viewByArr, regionArr, tradeArr } = filter;
    
    const [topRadioSelectorValue, setTopRadioValues] = useState('sales'); 
    const [planTypeValues, setPlanTypeValues] = useState({"key": "", "label": ""});
    const [periodValues, setPeriodValues] = useState({"key": "", "label": ""});
    const [viewByValues, setViewByValues] = useState({"key": "", "label": ""});
    const [regionValues, setRegionValues] = useState({"key": "", "label": ""});
    const [tradeValues, setTradeValues] = useState({"key": "", "label": ""});
    const [formSelectorValue, setFormSelectorValue] = useState(0); 


    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <Autocomplete
                    className = 'date-selection new-autocomplete'
                    fullWidth
                    disablePortal
                    disableListWrap
                    size='small'
                    value={selectedValue}
                    options={menuItems}
                    onChange={handleChange}
                    getOptionLabel={(option)=> option.label}
                    renderInput={(params) => (
                        <TextField
                            className="test-input-box"
                            {...params}
                            variant="standard"
                            label = {inputLabel}
                            placeholder={placeHolder}
                        />
                        )}                                                       
                />
            </FormControl>
        );
    };

    const TestRunOkay = ()=>{
        console.log("planTypeValues:: ",planTypeValues)
        console.log("periodValues:: ",periodValues)
        console.log("viewByValues:: ",viewByValues)
        
        console.log("topRadioSelectorValue:: ",topRadioSelectorValue)

        console.log("regionValues:: ",regionValues)
        console.log("tradeValues:: ",tradeValues)
    }

    const handleTopRadioSelectorValueChange = (event) => {
        setTopRadioValues(event.target.value)
    }

    return (
    <Grid container xs={12} sm={12} className="pb-15 buttons-section">
        <Grid item xs={12} sm={12} className="pt-0 flex ai-center jc-space-between mt-5 mb-5">
            <Grid item xs={9} sm={9}>
                <Grid container className="dropdown-filter">
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={planTypeArr}
                                handleChange={(e, val) => setPlanTypeValues(val)}
                                inputLabel={'Budget'}
                                selectedValue={planTypeValues}
                                placeHolder={'Select Budget'}
                            />
                    </Grid>
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={periodArr}
                                handleChange={(e, val) => setPeriodValues(val)}
                                inputLabel={'Time Period'}
                                selectedValue={periodValues}
                                placeHolder={'Select Time Period'}
                            />
                    </Grid>
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={viewByArr}
                                handleChange={(e, val) => setViewByValues(val)}
                                inputLabel={'View By Function'}
                                selectedValue={viewByValues}
                                placeHolder={'Select View By Function'}
                            />
                    </Grid>
                </Grid>
                
                <FormControl component="fieldset" style={{margin:'15px 0px 5px 0px'}}>
                        <RadioGroup name="radio" className='radioGroupHorizontal' value={topRadioSelectorValue} onChange={handleTopRadioSelectorValueChange}> 
                            <div className="group">
                                <FormControlLabel value={'plant'} control={<Radio color="primary" />} label="Plant" />
                                <FormControlLabel value={'sales'} control={<Radio color="primary" />} label="Sales" />
                                <FormControlLabel value={'logistics'} control={<Radio color="primary" />} label="Logistics" />
                                <FormControlLabel value={'godowns'} control={<Radio color="primary" />} label="Godowns" />
                                <FormControlLabel value={'mines'} control={<Radio color="primary" />} label="Mines" />
                            </div>
                        </RadioGroup>
                </FormControl>

                <Grid container className="dropdown-filter">
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={regionArr}
                                handleChange={(e, val) => setRegionValues(val)}
                                inputLabel={'Region'}
                                selectedValue={regionValues}
                                placeHolder={'Select Region'}
                            />
                    </Grid>
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={tradeArr}
                                handleChange={(e, val) => setTradeValues(val)}
                                inputLabel={'Channel'}
                                selectedValue={tradeValues}
                                placeHolder={'Select Channel'}
                            />
                    </Grid>
                </Grid>

                <Grid item xs={7} sm={7} className="mt-15">
                        <Tabs
                        value={formSelectorValue}
                        onChange={(e, val) => setFormSelectorValue(val)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="KPI Form" />
                        <Tab label="Detail Form" />
                    </Tabs>
                </Grid>
            </Grid>
            <Grid item xs={3} sm={3} className="pt-10 download-section" >
                <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    {/* <Button variant='contained' className='run-button mr-10 btnsearchcompare' onClick={handShowCompare}>Search and Compare</Button> */}
                    <Button className="newButton btnBlue mr-10"
                    variant="outlined" color="primary"
                        >
                        Edit
                    </Button>
                    <Button className="newButton btnBlue mr-10"
                    variant="outlined" color="primary"
                        >
                        Save
                    </Button>
                    <Button className="newButtonOutlined btnRed btnReset mr-10"
                    variant="outlined" 
                    color="primary"
                    startIcon={<Icon>lock</Icon>}
                        >
                        Lock
                    </Button>
                    <Tooltip title={"Collaborate"}>
                        <Icon
                            className="fs-20 primary mr-10"
                            color={"inherit"}
                            disabled
                            onClick={() => {
                                // handleOpen();
                            }
                            }
                        >
                            chat_bubble_filled
                        </Icon>
                    </Tooltip>
                    <Tooltip title={'Download to Excel'}>
                        <img
                            src={DownloadExcel}
                            className='pointer mr-10'
                            height='30'
                            onClick={(event) => {
                                // event.preventDefault();
                                // fetchTableData(true);
                            }} />
                    </Tooltip>
                    <Tooltip title={'Download to PDF'}>
                        <PictureAsPdfIcon fontSize="large"
                            height='30'
                            className="pointer mr-10"
                            onClick={(event) => {
                                // event.preventDefault();
                                //   this.printDocument();
                            }} />
                    </Tooltip>
                </div>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} className="pt-0">
        {/* <Button type="submit" variant="contained" color="primary" onClick={TestRunOkay}>Run</Button> */}
            {/* {actualForecastCementData.loading ? <div className='progressfullpage'><CircularProgress /></div> :
                <div style={{maxHeight:'700px', overflow:'scroll'}}>
                <NewTableComponent className={'ebitdaCustomTable fixedFirstTwoColumTable expandableRows'} tableData={actualForecastCementData} />
                </div>
            } */}
        </Grid>
    </Grid>

    );
}

