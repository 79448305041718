import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { Link as Linkroute } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import NotAvailableAlert from '../../common/components/Alerts/Alerts';
import {
	getAccessByMenuKey,
	getIsLeafByMenuKey,
} from '../../../utils/userManagement';
import OrangeTick from '../../../images/landingpage/orange-tick.svg';
import BankImg from '../../../images/landingpage/bank.svg';
import OilGasImg from '../../../images/landingpage/OilGasNew.svg';
import RenewableImg from '../../../images/landingpage/RenewableEnergyNew.svg';
import PrivateImg from '../../../images/landingpage/privateEquity.svg';
import RetailImg from '../../../images/landingpage/consumerProduct.svg';
import IndustrialImg from '../../../images/landingpage/industrial.svg';
import Aviation from '../../../images/landingpage/aviation.svg';
import VideoIcon from '../../../images/landingpage/video-icon.svg';
import ProtoIcon from '../../../images/landingpage/protoIcon.svg';
import VideoIconWhite from '../../../images/landingpage/video-icon-white.svg';
import ProtoIconWhite from '../../../images/landingpage/protoIconWhite.svg';
import "./index.scss";

//UserMgmt Icons
import RoleMasterIcon from "../../../images/svgicons/role-master.svg";
import UserMasterIcon from "../../../images/svgicons/user-master.svg";
import UserAnalyticsIcon from "../../../images/svgicons/user-analytics.svg";



//Home Page
import UserFileUpload from "../../../images/svgicons/user-file-upload.svg";
import CustomReports from "../../../images/svgicons/custom-reports.svg";
import DashboardsInsights from "../../../images/svgicons/dashboards&insights.svg";
import AnalyticsStudio from "../../../images/svgicons/analytics-studio.svg";
import ImpactAnalysis from "../../../images/svgicons/impact-analysis.svg";
import Initiatives from "../../../images/svgicons/initiatives.svg";
import PlanningSimulation from "../../../images/svgicons/planning&simulation.svg";
import AISolutions from "../../../images/svgicons/ai-solutions.svg";
import FCFImprovement from "../../../images/svgicons/viewFCFimprovement.svg";
import { Button } from '@material-ui/core';

import EbitdaNew from '../../../images/svgicons/ebitda-mgmt.svg';
import GeoSpatialNew from '../../../images/svgicons/geospatial-overview.svg';
import GroupOverheadNew from '../../../images/svgicons/GroupOverhead.svg';
import ProcessOverheadNew from '../../../images/svgicons/ProcessOverhead.svg';
import CapexNew from '../../../images/svgicons/Capex.svg';
import AiBasedNew from '../../../images/svgicons/AiBased.svg';
import PlanningNew from '../../../images/svgicons/planning.svg';



const useStyles= makeStyles({
	t: {
	  backgroundColor: 'transparent',
	},
	wideTooltip:{
		maxWidth:600
	}
  })
const DashboardLanding = [
	// {
	// 	id: 1,
	// 	text: 'Income Statements',
	// 	link: '/dashboard/income-statement',
	// 	icon: '/income-statement-new.png',
	// 	show: true,
	// 	key: 'income_statement',
	// },
	{
		id: 2,
		text: 'Planning',
		subText: '',
		link: '/dashboard/sandbox/planning',
		icon: PlanningNew,
		show: true,
		key: 'income_statement',
	},
	{
		id: 1,
		text: 'EBITDA Management',
		subText: '',
		link: '/dashboard/sandbox/ebitda',
		icon: EbitdaNew,
		show: true,
		key: 'income_statement',
	},
	{
		id: 4,
		text: 'Geospatial Overview',
		subText: '',
		link: '/dashboard/sandbox/geospatialoverview',
		icon: GeoSpatialNew,
		show: true,
		key: 'income_statement',
	},
	{
		id: 5,
		text: 'Capex Management',
		subText: '',
		link: '/dashboard/panel',
		icon: CapexNew,
		show: true,
		key: 'income_statement',
	},
	{
		id: 0,
		text: 'Process Overhead Allocation',
		subText: '',
		link: '/dashboard/panel',
		icon: ProcessOverheadNew,
		show: true,
		key: 'receivables',
	},
	{
		id: 0,
		text: 'General Overhead Allocation',
		subText: '',
		link: '/dashboard/panel',
		icon: GroupOverheadNew,
		show: true,
		key: 'receivables',
	},
	{
		id: 0,
		text: 'AI based Sales, Mfg, Logistics Planning',
		subText: '',
		link: '/dashboard/panel',
		icon: AiBasedNew,
		show: true,
		key: 'receivables',
	},
	
];

const UserManagementLanding = [
	{
		id: 1,
		text: 'Role Master',
		link: '/usermanagement/role-master',
		icon: RoleMasterIcon,
		show: true,
		key: 'role_master',
	},
	{
		id: 2,
		text: 'User Master',
		link: '/usermanagement/user-master',
		icon: UserMasterIcon,
		show: true,
		key: 'user_master',
	},
	{
		id: 3,
		text: 'User Analytics',
		link: '/usermanagement/user-analytics',
		icon: UserAnalyticsIcon,
		show: true,
		key: 'user_analytics',
	},
	// {
	//   id: 4,
	//   text: 'Upload Data Validation',
	//   link: '/usermanagement/p&l-raw-data-validation',
	//   icon: PLValMain,
	//   show: true,
	//   key : 'automated_data_validation'
	// }
];

let thisUrl = window.location.pathname
if(thisUrl.indexOf("menu-master") == -1 && thisUrl.indexOf("analytics-studio") == -1){
		localStorage.removeItem('show-back-fcf')
}

function LandingPage(props) {
	const loginState = useSelector((state) => state.authReducer);
	const [OpenNA, setOpenNA] = React.useState(false);
	const [blockArray, setBlockArray] = React.useState(null);
		const classes = useStyles()

	React.useEffect(() => {
		if (window.location.pathname.split('/')['1'] == 'dashboard') {
			setBlockArray(DashboardLanding);
		} else {
			setBlockArray(UserManagementLanding);
		}
	});

	const handleSetOpenNA = () => {
		setOpenNA(false);
	};

	const handleMainListItemClick = (data) => {
		setOpenNA(true);
		return;
	};


	const handleChildClick = (child) => {
		if (child.includes('Cash')) {
			props.history.push('/dashboard/receivables/free-cashflow')

		}

		if (child.includes('Focus')) {
			props.history.push('/dashboard/receivables/key-focus')

		}

		if (child.includes('Improvement Plans')) {
			props.history.push('/dashboard/income-statement/improvement-plans')

		}

		if (child.includes('Balanced')) {
			props.history.push('/dashboard/income-statement/analytics-module')
		}

		if (child.includes('Data Browser')) {
			const { data_browser_url } = window['runConfig']
			window.open(data_browser_url, '_blank');
		}
	}


	const handleChildClickOilAndGas = () => {
		window.open('/new-dashboard/oil-gas', '_blank')
	}

	const handleChildClickOilAndGasVideo = () => {
		window.open('https://vimeo.com/761814112', '_blank')
	}
	
	const handleSandboxClick = () => {
		window.open('/sandbox/panel', '_blank')
	}

	const handleBankClick = () => {
		window.open('https://pr.to/J777GO/', '_blank')
	}

	const handleBankClickVideo = () => {
		window.open('https://vimeo.com/794770417', '_blank')
	}
	

	const handlePrivateEquityClick = () => {
		window.open('https://pr.to/K9PTGV/', '_blank')
	}

	const handlePrivateEquityClickVideo = () => {
		window.open('https://vimeo.com/742581068', '_blank')
	}

	const handleRetailClick = () => {
		window.open('https://pr.to/9YVSVU/', '_blank')
	}

	const handleRetailClickVideo = () => {
		window.open('https://vimeo.com/746910803', '_blank')
	}

	const handleIndustrialClick = () => {
		window.open('https://pr.to/C9U5VJ/', '_blank')
	}

	const handleIndustrialClickSecond = () => {
		window.open('https://pr.to/B6I324/', '_blank')
	}

	const handleIndustrialClickVideo = () => {
		window.open('https://vimeo.com/731882416', '_blank')
	}

	const handleConsumerGoodClick = () => {
		window.open('https://vimeo.com/746910803', '_blank')
	}

	const handleRenewableClickVideo = () => {
		window.open('https://vimeo.com/694279320', '_blank')
	}

	const handleRenewableClick = () => {
		window.open('https://pr.to/TCY2CO/', '_blank')
	}

	const handleChildClickRouteProfitability = () => {
		window.open('/route-profitability/login', '_blank')
	}

	const handleChildClickRouteProfitabilityVideo = () => {
		window.open('https://vimeo.com/765604992', '_blank')
	}


	const handleWebClick = (key) => {
		switch(key){
			case 'OilGas' : handleChildClickOilAndGas();
			break;
			case 'Renewable' : handleRenewableClick();
			break;
			case 'Bank' : handleBankClick();
			break;
			case 'Private' : handlePrivateEquityClick();
			break;
			case 'Retail' : handleRetailClick();
			break;
			case 'Industrial' : handleIndustrialClick();
			break;
			case 'Industrial-Second' : handleIndustrialClickSecond();
			break;
			case 'Aviation' : handleChildClickRouteProfitability();
			break;
			default: handleChildClickOilAndGas();
		}
	}

	const handleVideoClick = (key) => {
		switch(key){
			case 'OilGas' : handleChildClickOilAndGasVideo();
			break;
			case 'Renewable' : handleRenewableClickVideo();
			break;
			case 'Bank' : handleBankClickVideo();
			break;
			case 'Private' : handlePrivateEquityClickVideo();
			break;
			case 'Retail' : handleRetailClickVideo();
			break;
			case 'Industrial' : handleIndustrialClickVideo();
			break;
			case 'Aviation' : handleChildClickRouteProfitabilityVideo();
			break;
			default: handleChildClickOilAndGas();
		}
	}

	const handleToolTipData = (key) => {
       
           return (
                <div>
						<React.Fragment>
							<div className='hoverIconsDashboard'>
								<img src={VideoIcon} onClick={() => handleVideoClick(key)}/>
								<span className='videoIcon'></span>
								<img src={ProtoIcon} onClick={() => handleWebClick(key)}/>
							</div>
						</React.Fragment>
                </div>)
        
    }

	const bottomTileStyles = (icon) => {
		return {
			width:'158px',
			height:'148px',
			backgroundSize:'cover',
			backgroundPosition:'center',
			backgroundImage: `url(${icon})`};
		};


	return (
		<div className='homeBlockDesign'>
			<NotAvailableAlert open={OpenNA} setOpen={handleSetOpenNA}></NotAvailableAlert>
			{
				window.location.pathname !== '/dashboard/panel' && (
					<Grid item xs={false} sm={12} md={12} className="app-breadcrumbs secondary">
						<span className={props.userManagementPanel ? 'usr-mngmt-breadcrumbs' : 'home-breadcrumbs'}>{props.userManagementPanel ? 'User Management' : 'Home'}</span>
					</Grid>
				)
			}

			<Grid container direction="row" className={props.userManagementPanel ? 'blockContainer user-mgmt-menu-list' : 'blockContainer-home'} spacing={2}>
				{props.userManagementPanel ?
					blockArray && blockArray.map((item, index) => (
						<Grid key={index} item xs={12} sm={4}>
							<Linkroute to={item.link} className="block">
								<img className='colored-icons main-icon' src={item.icon} />
								<h5>
									{item.text}<br></br>
									{item.subText && item.subText}
								</h5>
							</Linkroute>
							{
								item.children && item.children.map(child => (
									<p
										style={{ cursor: child.includes('Balanced') || child.includes('Improvement') || child.includes('Cash') || child.includes('Focus') || child.includes('Data') ? 'pointer' : '' }}
										onClick={() => handleChildClick(child)} className="child" key={child}>{child}</p>
								))
							}
						</Grid>
					))
					:
					<Grid container direction="row" className={props.userManagementPanel ? 'blockContainer user-mgmt-menu-list' : 'blockContainer-home'} spacing={2}>
						<Grid item xs={12} sm={12} className="middle-navigation">
							<Grid item xs={12} sm={12} className="header-chevron">
								<div className="chevron-child">
									<div className="cnt">
										<p className='top-row'><img className='orange-tick' src={OrangeTick} />Financial and Operational Excellence</p>
									</div>
								</div>
								<div className="chevron-child">
									<div className="cnt">
										<p className='top-row'><img className='orange-tick' src={OrangeTick} />Improvement Initiatives</p>
									</div>
								</div>
								<div className="chevron-child">
									<div className="cnt">
										<p className='top-row'><img className='orange-tick' src={OrangeTick} />Automation and Collaboration</p>
									</div>
								</div>
							</Grid>
							<div className='text-center horizontal-solution'>
									<span className='standalone-header'>Enterprise Performance Management (EPM)</span>
							</div>
							<div className="box-menu upper-section">
								{
									blockArray && blockArray.map((item, index) => (
										<Grid className='upper-section-icons' key={index} item xs={10} sm={2}>
											<Tooltip classes={{ tooltip: 'eaciitTooltip' }} placement="top" title={item.subText ? item.subText : ''}>
												<Linkroute to={item.link} className="block">
													<img className='colored-icons main-icon' src={item.icon} />
													<h5 className='Text-subText'>
														{item.text}
														<br></br>
														{item.subText}
													</h5>
												</Linkroute>
											</Tooltip>
											{
												item.children && item.children.map(child => (
													<p
														style={{ cursor: child.includes('Balanced') || child.includes('Improvement') || child.includes('Cash') || child.includes('Focus') || child.includes('Data') ? 'pointer' : '' }}
														onClick={() => handleChildClick(child)} className="child" key={child}>{child}</p>
												))
											}
										</Grid>
									))
								}
							</div>
						</Grid>

					</Grid>
				}

			</Grid>
			
				<div className="eaciitTooltipText">
				<Tooltip placement="right" title={
				<React.Fragment>
					<p style={{fontSize:'14px'}}>Reads all data – structured, unstructured, external, and internal.</p>
					<p style={{fontSize:'14px'}}>Schema free NoSQL and other databases – tabular, key value pairs.</p>
					<p style={{fontSize:'14px'}}>Analysis – data orchestration, AI algorithms, without unique joins</p>
				</React.Fragment>
				} classes={{ tooltip: 'eaciitTooltip' , tooltip:classes.wideTooltip}}>
					<span>Powered by EACIIT Platform</span>
					</Tooltip>
					</div>
			
		</div>
	);
}

export default withRouter(LandingPage);
