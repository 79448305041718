import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../../constant";

function HighChart(props) {
    const {performanceAnalysis: { compareAllBenchmarks: { varianceOrGapColor, actualsColor } }} = CHART_COLORS;
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: 'Comparison with Forecast/Budget/Targets'
                        },
                        credits: {
                            enabled: false
                          },
                        xAxis: [{
                            categories: ['Actual', 'Forecast', 'Budget', 'Target'],
                        }],
                        yAxis: [{ // Primary yAxis
                            labels: {
                                format: '${value}',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'USD millions',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            }
                        }, { // Secondary yAxis
                            title: {
                                text: '',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                        }],
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            }
                        },
                        series: [ {
                            name: 'Variance/Gap',
                            data: [0, 4, 1, 3.5],              
                            color: varianceOrGapColor
                        },{
                            name: 'Actuals',
                            data: [4, 4, 4, 4],
                            tooltip: {
                                valueSuffix: ''
                            },
                            color: actualsColor,
                        }]
                    }
                }
            />
        </div>
    )
}
export default HighChart;