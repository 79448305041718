import React from 'react';
import NewReactTable from './NewReactTable';
import './../../ReportComponent/index.scss'
import withDraggableColumns from 'react-table-hoc-draggable-columns';
import 'react-table-hoc-draggable-columns/dist/styles.css';

const ReactTableDraggableColumns = withDraggableColumns(NewReactTable);

class ReactTableComponent extends React.Component {

    
      shouldComponentUpdate(nextProps) {

        if (this.props.loading != nextProps.loading || this.props.showCellId != nextProps.showCellId) {
            return true
        }

        return false
    }
    

    render() {

        const { pivotRowData, loading, pivotHeaderData, draggableColumns, className } = this.props;
        return (
            !loading && pivotRowData.length ?
            <NewReactTable
                data={pivotRowData}
                draggableColumns={draggableColumns}
                className={className}
                loading={loading}
                columns={pivotHeaderData}
            /> : <p className="text-center mt-10"><i>Click Generate Report to see the table</i> </p>
        )

    }
}

export default ReactTableComponent;