import React from 'react';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CONFIG } from '../../../../../config'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { getAccessByMenuKey } from "../../../../../utils/userManagement";
import CircularProgress from '@material-ui/core/CircularProgress';


export default function SimpleModal(props) {
    const [inputValue, setInputValue] = React.useState('');
    const [refMenuAnchorEl, setRefMenuAnchorEl] = React.useState(null);

    const handleClose = () => {
        props.modalCallBack(false);
    };

    const handleSendSubmit = () => {
        if (inputValue && inputValue == '') {
            return;
        }
        props.sendCommmentCallback(inputValue)
        setInputValue('')
    }

    const handleChatChangeFn = (event) => {
        if (event && event.target) {
            setInputValue(event.target.value)
        }
        const valueLen = event.target.value.length;
        const targetValue = event.target.value;
        if (valueLen && targetValue[valueLen - 1] == '@') {
            setRefMenuAnchorEl(event.currentTarget);
        }
    }


    const handleRefMenuClose = (item) => {
        if (refMenuAnchorEl && refMenuAnchorEl.value) {
            setInputValue(inputValue + (item.name || ''));
        }
        setRefMenuAnchorEl(null);
    };

    const body = (
        <div className='customModal commentModal'>
            <div className='header'>
            { props.loading  ? "" : <h2>{ props.planID ? props.planID : '' } Comments</h2> }
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
            { props.loading  ? <div className='progressfullpage'><CircularProgress /></div> :
                <form>
                    <div className='commentInputSection flex ai-center jc-space-between'>
                        <Menu
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            anchorEl={refMenuAnchorEl}
                            keepMounted
                            open={Boolean(refMenuAnchorEl)}
                            onClose={handleRefMenuClose}
                        >
                            {
                                props.allUsers && props.allUsers.length &&
                                props.allUsers.map((item, index) => {
                                    return <MenuItem key={index} value={item.full_name} onClick={() => handleRefMenuClose(item)}>{item.full_name}</MenuItem>
                                })
                            }
                        </Menu>
                        {(props.userDataState.login_id == props.pipPartyData.login_id) ?
                        <>
                        <TextField
                            label="Add Comment"
                            name="comment"
                            placeholder="Placeholder"
                            multiline
                            variant="outlined"
                            size="small"
                            value={inputValue}
                            onChange={(event) => { handleChatChangeFn(event) }}
                        />
                         {getAccessByMenuKey(props.menuData,"plan_summary","add") &&
                        <Tooltip title='Send'>
                            <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0' onClick={() => handleSendSubmit()}>
                                <Icon fontSize="small">send</Icon>
                            </IconButton>
                        </Tooltip>}
                        </>
                        : ""}
                    </div>
                    <div className='commentsSection'>
                        {
                            props.commentsData &&
                            props.commentsData.length == 0 ? <div>No comments available.</div> : ""
                        }

                        {props.commentsData &&
                           props.commentsData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {(props.userDataState.login_id == item.login_id) ?
                                            <div className='chat-right'>
                                                <div className='date'>{formatDate(item.date)}</div>
                                                <div>{props.pipPartyData.name} : {item.message}</div>
                                            </div> :
                                            <div className='chat-left'>
                                                <div className='date'>{formatDate(item.date)}</div>
                                                <div>{props.pipPartyData.name} : {item.message}</div>
                                            </div>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </form>
            }
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}