export const getAllCommentHistoryData = (activePage, rowData, reportDate) => {
    const pageLimit = 60;
    const startPoint = activePage * pageLimit
    const endPoint = startPoint + pageLimit
    let getCommentPayload = {
        "channel_ids": [],
    }
    if (rowData && rowData.length) {
        // let splicedData = rowData.slice(startPoint, endPoint)
        let splicedData = rowData;
        let channelArr = []
        splicedData.forEach(item => {
            if (item.channel) {
                channelArr.push(item.channel)
            }
            if (item.channel_customer) {
                channelArr.push(item.channel_customer)
            }
        })
        getCommentPayload['channel_ids'] = channelArr
        if (reportDate) {
            getCommentPayload['report_date'] = reportDate
        }
        // console.log(getCommentPayload, "channelArrchannelArr", channelArr, startPoint, endPoint)
    }
    return getCommentPayload
}