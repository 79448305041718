import { put, takeLatest, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import {
    fetchGeospacialAnalysisService,
    fetchChatDataService

} from "./service";

function* fetchGeospacialAnalysisStatement(data) {
    yield put({ type: "FETCH_GEOSPACIAL_ANALYSIS_DATA_STATUS", payload: 'loading' });
    const response = yield call(fetchGeospacialAnalysisService, data);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_GEOSPACIAL_ANALYSIS_DATA_SUCCESS", payload: { status: 'success', data: response.data } });
    } else yield put({ type: "FETCH_GEOSPACIAL_ANALYSIS_DATA_FAIL" });
}

function* fetchChatData(data) {
    yield put({ type: "FETCH_CHAT_DATA_LOADER", loading: true });
    const response = yield call(fetchChatDataService, data.payload);
    
    if (response && response.status == 200 ) {
        yield put({ type: "FETCH_CHAT_DATA_SUCCESS", data: response.data });
        yield put({ type: "FETCH_CHAT_DATA_LOADER", loading: false });
    } 
}


export default function* actionWatcher() {
    yield takeLatest("FETCH_GEOSPACIAL_ANALYSIS_DATA", fetchGeospacialAnalysisStatement)
    yield takeLatest("FETCH_CHAT_DATA", fetchChatData)
}
