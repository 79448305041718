import { put, takeLatest, call } from 'redux-saga/effects';
import {
    performFetchInvoiceDetailService,
    performFetchCreditLimitDetailService
} from './service'
import { store } from '../../index'

function* fetchInvoiceDetail(data) {
    yield put({ type: "FETCH_INVOICE_DETAIL_LOADER", loading: true });
    const state = store.getState();
    data.payload['report_date'] = state.dashboardState.selectedDate
    const response = yield call(performFetchInvoiceDetailService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_INVOICE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchCreditLimitDetail(data) {
    if (!data.payload.export){
        yield put({ type: "FETCH_INVOICE_DETAIL_LOADER", loading: true });

    }
    const state = store.getState();
    data.payload['report_date'] = state.dashboardState.selectedDate
    const response = yield call(performFetchCreditLimitDetailService, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_INVOICE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}

export default function* actionWatcher() {
    yield takeLatest('FETCH_INVOICE_DETAIL', fetchInvoiceDetail)
    yield takeLatest('FETCH_CREDIT_LIMIT_DETAIL', fetchCreditLimitDetail)
}


