import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from "@material-ui/core/Grid";
import PLComponent from './PLTab';
import FixedCostComponent from './FixedCost';
import SalesLogisticComponent from './SalesLogistic';
import ClinkerComponent from './ClinkerCement';
import CementCostComponent from './CementCost';
import DashboardComponent from './Dashboards';
import SimulationEbitdaComponent  from './SimulationEbitda';
import CompareModal from '../Compare';
import './index.scss';
import CommentModal from '../../../Receivable/Ageing/ExternalComponent/CommentModal';
import {isSocketConnectionOpen, startWS} from '../../../../utils/web-socket';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class CashflowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.socketCollection = {};
        this.chatDataCollection = {};

        this.state = {
            showCompareModal: false,
            newRadioSelectorValue: '0',
            topRadioSelectorValue: '1',

            showCommentModal: false,
            commentRow: { 
              channel : 'ebitda', 
              obj: {},
              commentArr : [] 
            },
            uploadImageObj: {},
            chatDataCollection: {},


            tabValue: 1,
            openChat: false,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    handleTopRadioSelectorValueChange = (event) => {
        this.setState({
            topRadioSelectorValue: event.target.value
        })
    }

    handleCompareClose = () => {
        this.setState({ showCompareModal: false })
    }
    handleShowCompare = () => {
        this.setState({ showCompareModal: true })
    }

   
    showCommentModal = (data) => {
        this.setState({
          showCommentModal: true,
        });
      };
    
    commentCallBack = () => {
        this.setState({showCommentModal: false});
    };

    setCommentModalData = () => {
        const {commentRow} = this.state;
        const {chatState} = this.props;
        let commentArrData = [];
        if (chatState?.data?.chat) {
            commentArrData = chatState.data.chat[commentRow.channel]
            commentRow.commentArr = commentArrData
            this.setState({ commentRow  })
        }
        return commentArrData
      };

      
  handleSocketConnectionError = (e) => {
    this.props.socketConnectionError(true);
  };

  handleConnectionClose = (channelId) => {
    console.log(channelId, 'connection closed');
    if (this.socketCollection[channelId]) {
      delete this.socketCollection[channelId];
    }
  };

  establishSocketConnection = (data) => {
    console.log(data, 'establishSocketConnection');
    const {commentRow } = this.state

    if (!this.socketCollection[commentRow.channel]) {
      const socketInfo = startWS(
        commentRow.channel,
        "",
        "",
        "",
        commentRow.commentArr,
          this.handleChatUpdation,
          this.handleConnectionClose,
          "",
          this.handleSocketConnectionError,
      );
      this.socketCollection[commentRow.channel] = socketInfo;
    }
  };

  handleChatUpdation = (gData) => {
    const { commentRow } = this.state;
  
        if (!commentRow['obj'][gData.chatData[0].id]) {
            commentRow['isChatConnection'] = true;
            commentRow.commentArr.unshift(gData.chatData[0]);
            commentRow['obj'][gData.chatData[0].id] = true;
        }
       
        if (commentRow.commentArr &&
            commentRow.length) {
          let obj = {};
          let commentModifiedArr = [];
          commentRow.commentArr.forEach(item => {
            if (!obj[item.id]) {
              let usr = this.props.usersList.find(x=>{ return x.login_id == item.login_id });
              if(usr){
                item.name = usr.full_name;
              }
              commentModifiedArr.push(item);
              obj[item.id] = true;
            }
          });
          commentRow.commentArr = [
            ...[],
            ...commentModifiedArr];
        }
        this.setState({ commentRow  })
  };

  handleSendChatAttachmentFunction = (attachmentData) => {
    const {commentRow } = this.state

    const sendChatObject = {
      'body': attachmentData.id,
      'type': attachmentData.type,
      'file_name': attachmentData.original_name,
    };
    this.props.uploadChatFileToggle();
    if (isSocketConnectionOpen(this.socketCollection[commentRow.channel])) {
      this.socketCollection[commentRow.channel].send(
          JSON.stringify(sendChatObject));
    }
  };

  handleSendChatFunction = (rowData) => {
    const {commentRow } = this.state
    let sendChatObject = {};
    if (document.getElementById(`outlined-basic-${commentRow.channel}`).value) {
      sendChatObject = {
        'body': document.getElementById(
            `outlined-basic-${commentRow.channel}`).value,
        'type': 'text',
        'tagged_user': this.taggedUser,
      };
      if (!isSocketConnectionOpen(this.socketCollection[commentRow.channel])) {
        delete this.socketCollection[commentRow.channel];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(this.socketCollection[commentRow.channel])) {
        this.socketCollection[commentRow.channel].send(
            JSON.stringify(sendChatObject));
        this.chatDataCollection[commentRow.channel] = '';
        document.getElementById(`outlined-basic-${commentRow.channel}`).value = '';
        this.taggedUser = [];
      }
    }
  };

  handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
    const {commentRow } = this.state
    const {chatDataCollection} = this.state;
    const sendChatObject = {
      'body': inputValue,
      'type': 'text',
      'tagged_user': taggedUser,
    };
    if (inputValue) {
      if (!isSocketConnectionOpen(this.socketCollection[commentRow.channel])) {
        delete this.socketCollection[commentRow.channel];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(this.socketCollection[commentRow.channel])) {
        this.socketCollection[commentRow.channel].send(
            JSON.stringify(sendChatObject));
        chatDataCollection[commentRow.channel] = '';
        this.setState({'chatDataCollection': chatDataCollection});
      }
    }
  };

  handleChatChangeFn = (event, rowData) => {
    const {commentRow } = this.state
    this.chatDataCollection[rowData.channel] = event.target.value;

    if (event.target && event.target.value) {
      const valueLen = event.target.value.length;
      const targetValue = event.target.value;
      if (valueLen && targetValue[valueLen - 1] == '@') {
        this.setState({
          refMenuAnchorEl: event.currentTarget,
          channelId: commentRow.channel,
          menuPositionX: event.currentTarget.getBoundingClientRect().left,
          menuPositionY: event.currentTarget.getBoundingClientRect().top,
        });
      }
    }
  };

  onImageInputHandler = (event, props) => {
    const {commentRow } = this.state
    let imagePreview;
    if (event.target.files[0].type == 'application/pdf' ||
        event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      imagePreview = '';
    } else {
      imagePreview = URL.createObjectURL(event.target.files[0]);
    }
    let imageName = event.target.files[0].name;
    const {uploadImageObj} = this.state;
    uploadImageObj[commentRow.channel] = {
      imagePreview,
      imageName,
      file: event.target.files[0],
    };
    this.setState({'uploadImageObj': uploadImageObj});
  };

  handleImageOptions = (event) => {
    const {uploadImageObj, commentRow} = this.state;
    if (event === 'approve') {
      const imageObj = uploadImageObj[commentRow.channel];
      if (imageObj) {
        const payloadData = {
          file: imageObj.file,
          id: commentRow.channel,
        };
        this.props.uploadChatFile(payloadData);
      }
    }
    if (event === 'cancel') {
      this.imagePreview = null;
    }
    if (document.getElementById('upload-icon')) {
      document.getElementById(`upload-icon`).value = '';
    }
    // document.getElementById(`upload-icon${commentRow.channel}`).value = '';
    delete uploadImageObj[commentRow.channel];
    this.setState({'uploadImageObj': uploadImageObj});
  };

  callGetAllCommentHistoryFunc = () => {
    this.props.fetchChatDataHistory({ "channel_ids" : [ this.state.commentRow.channel ] });
  };

  chatClick = () => {
      const { channel } = this.state.commentRow
      this.setCommentModalData()
      this.showCommentModal()
      this.establishSocketConnection(); 
  };

  componentDidMount(){
    this.props.getAllUser({})
    this.callGetAllCommentHistoryFunc()
  }

  componentDidUpdate(prevProps) {
    const {chatStoreState} = this.props;

    if (chatStoreState.uploadFile) {
      this.handleSendChatAttachmentFunction(chatStoreState.data);
    }
  }

    render() {

        const {  topRadioSelectorValue, showCommentModal, commentRow, uploadImageObj ,showCompareModal} = this.state
        const {
            authState,
            plPivotState,
            getEmailTemplateEbitdaPnlBudget, getEmailTemplateEbitdaSearchCompare, saveEmailTemplate
          } = this.props;
        const menus = authState.menuData;
        return (
            <React.Fragment>
                <div className='dayrepots-100k-120'>
                    <Grid container xs={12} sm={12} className="pb-15 buttons-section">
                        <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={topRadioSelectorValue} onChange={this.handleTopRadioSelectorValueChange} className='radioGroupHorizontal'>
                                    <div className="group">
                                        <FormControlLabel value={'1'} control={<Radio color="primary" />} label="P&L" />
                                        <FormControlLabel value={'-1'} control={<Radio color="primary" />} label="Simulation" />
                                        <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Dashboard" />
                                        <FormControlLabel value={'2'} control={<Radio color="primary" />} label="Clinker Cost" />
                                        <FormControlLabel value={'3'} control={<Radio color="primary" />} label="Cement Cost" />
                                        <FormControlLabel value={'4'} control={<Radio color="primary" />} label="Sales & Logistics" />
                                        <FormControlLabel value={'5'} control={<Radio color="primary" />} label="Fixed Cost" />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>

                    {topRadioSelectorValue == -1 && <SimulationEbitdaComponent 
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}
                    {topRadioSelectorValue == 0 && < DashboardComponent
                        handShowCompare={this.handleShowCompare}
                    />}
                    {topRadioSelectorValue == 1 && <PLComponent 
                        getEmailTemplateEbitdaPnlBudget={getEmailTemplateEbitdaPnlBudget}
                        emailTemplateData={this.props.tableViewState}
                        saveEmailTemplate={saveEmailTemplate}
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}
                    {topRadioSelectorValue == 5 && <FixedCostComponent 
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}
                    {topRadioSelectorValue == 4 && <SalesLogisticComponent 
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}
                    {topRadioSelectorValue == 3 && <CementCostComponent
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}
                    {topRadioSelectorValue == 2 && <ClinkerComponent 
                        handShowCompare={this.handleShowCompare}
                        chatClick={this.chatClick}
                    />}

                    {showCommentModal &&
                    <CommentModal
                        showModal={showCommentModal}
                        commentRowData={commentRow}
                        modalCallBack={this.commentCallBack}
                        commentsData={commentRow}
                        userDataState={authState.userData}
                        sendCommmentCallback={this.handleSendChatModalFunction}
                        buValue={this.props.buValue}
                        onImageInputHandler={this.onImageInputHandler}
                        handleImageOptions={this.handleImageOptions}
                        uploadImageObj={uploadImageObj}
                        allUsers={this.props.externalState.allUser}
                        menuData={menus}
                    />
                    }

                </div>

                <CompareModal 
                    showModal={showCompareModal}
                    // tabSelectorValue={tabSelectorValue}
                    // radioSelectorValue={radioSelectorValue}
                    users={this.props.externalState.allUser}
                    handleClose={this.handleCompareClose}
                    plPivotState={plPivotState}
                    getEmailTemplateEbitdaSearchCompare={getEmailTemplateEbitdaSearchCompare}
                    emailTemplateData={this.props.tableViewState}
                    saveEmailTemplate={saveEmailTemplate}
                    />

               
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                plPivotState: state.shreeCementState.compareDataPNLEbitda,
                authState: state.authReducer,
                chatState: state.geospacialState.chatData,
                chatStoreState: state.chatStore,
                externalState: state.dashboardState.receivableData.ageingData.external
            }
        },
        dispatch => {
            return {
                fetchChatDataHistory(data) {
                    dispatch({
                      type: 'FETCH_CHAT_DATA',
                      payload: data,
                    });
                  },
                socketConnectionError(data) {
                    dispatch({
                      type: 'SHOW_ERROR_PAGE',
                      payload: data,
                    });
                  },
                fetchCementData(data) {
                    dispatch({
                        type: "FETCH_CEMENT_DATA",
                        payload: data
                    });
                },
                getAllUser(data) {
                  dispatch({
                      type: "FETCH_ALL_USER",
                      payload: data,
                  });
              },
              uploadChatFile(data) {
                dispatch({
                  type: 'CHAT_UPLOAD_FILE',
                  payload: data,
                });
              },
              uploadChatFileToggle() {
                dispatch({
                  type: 'CHAT_UPLOAD_FILE_TOGGLE',
                });
              },
            };
        }
    )(CashflowComponent);
