import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import Button from '@material-ui/core/Button';
import FunctionalPivotTableComponent from './TableComponent'
import { formatDate } from '../../../utils/dateFormat'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../utils/numberUtils'
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { format } from 'date-fns/esm';
import NegativeBarGraph from './TornadoChart';
import PieChartComponent from './PieCharts';
import {
    downloadExcel,
    pdfFileName,
    mapExcelData,
    mapExcelHeader,
    mapExcelHeaderComparativeST,
    mapExcelDataSTComparative,
    mapExcelDataPLComparative,
    mapDataPDF,
    mapPDFDataPLComparativeST
} from '../../../utils/exportExcel';
import { Autocomplete, TextField } from '@mui/material';

export class FunctionalPLComponent extends React.Component {
    constructor(props) {
        super(props);
        this.excelData = {};
        this.excelDataComparison = {};
        this.state = {
            loading: true,
            monthSelectorValue: props.incomeStatementState.defaultMonth,
            qtrSelectorValue: '',
            showPieChart: false,
            yearSelectorValue: '',
            analyticsRadioValue:'0',
            tabSelectorValue: 1,
            budgetGroup: ['Summary','Technology', 'Sales', 'Proposals', 'Marketing', 'Analytics'],
            radioFilterValue: 'Summary',
            comparativeSelectorValue: '1',
            isShowTsaChecked: false,
            excludeDataFilter: [],
            doExport: false,
            exportFileName: '',
            pieChartData: {},
            isAllFunc: true,
            countryMetaName: '',
            checked: 'month',
            isDetailedView: false,
            budgets: [],

            refreshExcel: null
        }

    }

    componentDidMount() {
        var arr = [];
        var hasAllFunc;
        var budgetFromAuth = this.props.budgetGp;
        this.handleSalesTechAccess()
        console.log()
        if (budgetFromAuth) {
            for (var i = 0; i < budgetFromAuth.length; i++) {
                var current = budgetFromAuth[i];
                if (current.value == "All Function") {
                    hasAllFunc = true;
                }
                arr.push(current.value);
            }
            if (!hasAllFunc) {
                hasAllFunc = false;
            }
            // // this.setState({
            //     isAllFunc: hasAllFunc,
            // //     budgets: arr,
            // // })
        }
        // this.props.getBudgetGroup()
        this.props.getFunctionalDefaultMonthData({ budgetGroupUser: arr })
        // this.fetchSalesTechnologyPieDataFn()

        // this.fetchFunctionalPivotData()
    }

    handleMountingTornado = () => {
        let payloadTornado = {
            yearmonth: this.state.monthSelectorValue,
            isComparative : true,
            data_type: "SummaryView"
        }
        this.props.fetchTornadoData(payloadTornado)
    }

    handleSalesTechAccess = () => {
        const {authReducerState} = this.props;
        const {budgetGroup} = this.state;
        //console.log(budgetGroup.length, "testbudgetGroup")
    
          let finalTabList = []
        let addition = true
          budgetGroup.forEach(item => {
              
              if (authReducerState && 
                  authReducerState.userData &&
                  authReducerState.userData.sales_and_technologies_access && 
                  authReducerState.userData.sales_and_technologies_access && 
                  authReducerState.userData.sales_and_technologies_access.group &&
                  authReducerState.userData.sales_and_technologies_access.group.length){
                      authReducerState.userData.sales_and_technologies_access.group.forEach(groupItem => {
                          if(groupItem.text == item || groupItem.text == 'All Groups'){
                              finalTabList.push(item)
                          }
                          if(groupItem.text == 'All Groups'){
                            addition = false
                          }
                      })
              }
          })
  
           if(addition == true){

            finalTabList.push('Analytics')
           }
          this.setState({
              budgetGroup: finalTabList,
              radioFilterValue: finalTabList && finalTabList.length ? finalTabList[0] : ''
            })
  
  
      }

    fetchFunctionalPivotData = () => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, countryMetaName, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let reportType = 'month'
        console.log(this.state, "testType")
        if (comparativeSelectorValue == 1) {
            reportType = 'month'
        } else if (comparativeSelectorValue == 2) {
            reportType = 'comparative'
        } else {
            reportType = 'country'
        }


        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            reportType: reportType,
            countryMetaName: countryMetaName,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue == "Summary"? "SummaryView" : radioFilterValue,
            excludeData: excludeDataFilter.join(','),
            isComparative: comparativeSelectorValue == 2
        }
        if(radioFilterValue == "Analytics"){
            this.fetchSalesTechnologyPieDataFn()
            this.handleMountingTornado()
        }
        if (this.state.tabSelectorValue == 1) {
            this.props.getFunctionalPivotView(payload)
        } else {
            if (comparativeSelectorValue == 1) {
                // payload.budgetGroup = null;
                this.props.getFunctionalPivotView(payload)
            } else if (comparativeSelectorValue == 2) {
                this.props.getFunctionalPivotView(payload)
            } else {
                this.props.getFunctionalPivotView(payload)
            }
        }
    }

    fetchFunctionalSummaryData = () => {
        const { qtrSelectorValue, monthSelectorValue, yearSelectorValue, comparativeSelectorValue, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            isComparative: comparativeSelectorValue == 2,
            budgetGroupUser: budgets
        }
        this.props.getFunctionalSummaryView(payload)
    }

    handleMonthChange = (event, value) => {
        if (value) {
            let dateSelected = ""
            if (value == "") {
                dateSelected = this.state.monthSelectorValue
            } else {
                dateSelected = value
            }
            if (this.state.isDetailedView == true) {
                this.setState({
                    monthSelectorValue: dateSelected,
                    qtrSelectorValue: '',
                    yearSelectorValue: ''
                })
                let payload = {
                    yearmonth: dateSelected,
                    year: "",
                    reportType: 'month',
                    countryMetaName: this.state.countryMetaName,
                    quarter: "",
                    excludeData: this.state.excludeDataFilter.join(','),
                    isComparative: this.state.comparativeSelectorValue == 2
                }
                
                this.props.getFunctionalPivotView(payload)
            } else {

                let payloadTornado = {
                    yearmonth: dateSelected,
                    isComparative : true,
                    data_type: "SummaryView"
                }

                this.setState({
                    monthSelectorValue: dateSelected,
                    qtrSelectorValue: '',
                    yearSelectorValue: '',
                    showPieChart: false
                }, () => {
                    if (this.state.radioFilterValue != 'Analytics'){
                        this.fetchFunctionalPivotData()

                    }else {
                        this.fetchSalesTechnologyPieDataFn()
                        
                        this.props.fetchTornadoData(payloadTornado)

                    }
                })
            }

        }
    };

    handleQtrChange = (event, value) => {
        if (value) {
            let dateSelected = ""
            if (value == "") {
                dateSelected = this.state.qtrSelectorValue
            } else {
                dateSelected = value
            }

            if (this.state.isDetailedView == true) {
                this.setState({
                    qtrSelectorValue: dateSelected,
                    monthSelectorValue: '',
                    yearSelectorValue: ''
                })
                let payload = {
                    yearmonth: "",
                    year: "",
                    reportType: 'month',
                    countryMetaName: this.state.countryMetaName,
                    quarter: dateSelected,
                    excludeData: this.state.excludeDataFilter.join(','),
                    isComparative: this.state.comparativeSelectorValue == 2
                }
                this.props.getFunctionalPivotView(payload)
            } else {
                this.setState({
                    qtrSelectorValue: dateSelected,
                    monthSelectorValue: '',
                    yearSelectorValue: '',
                    showPieChart: false
                }, () => {
                    if (this.state.radioFilterValue != 'Analytics'){
                        this.fetchFunctionalPivotData()

                    }else {
                        this.fetchSalesTechnologyPieDataFn()

                    }
                })
            }
        }
    };

    handleYearChange = (event, value) => {
        if (value) {
            let dateSelected = ""
            if (value == "") {
                dateSelected = this.state.yearSelectorValue
            } else {
                dateSelected = value
            }

            if (this.state.isDetailedView == true) {
                this.setState({
                    yearSelectorValue: dateSelected,
                    qtrSelectorValue: '',
                    monthSelectorValue: ''
                })
                let payload = {
                    yearmonth: "",
                    year: dateSelected,
                    reportType: 'month',
                    countryMetaName: this.state.countryMetaName,
                    quarter: "",
                    excludeData: this.state.excludeDataFilter.join(','),
                    isComparative: this.state.comparativeSelectorValue == 2
                }
                this.props.getFunctionalPivotView(payload)
            } else {
                this.setState({
                    yearSelectorValue: dateSelected,
                    qtrSelectorValue: '',
                    monthSelectorValue: '',
                    showPieChart: false
                }, () => {
                    if (this.state.radioFilterValue != 'Analytics'){
                        this.fetchFunctionalPivotData()

                    }else {
                        this.fetchSalesTechnologyPieDataFn()

                    }
                })
            }
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({ tabSelectorValue: newValue, comparativeSelectorValue: '2' }, () => {
            this.fetchFunctionalPivotData()
        })
        
    };

    handleRadioFilterChange = (event) => {
        this.setState(
            { radioFilterValue: event.target.value, refreshExcel: true },
            () => {
                this.fetchFunctionalPivotData()
            });
           
        console.log(this.state.radioFilterValue, "radioFilterValue");
    }

    handleShowTsaChange = (event) => {
        this.setState(
            { isShowTsaChecked: event.target.checked },
            () => {
                this.fetchFunctionalPivotData()
            });
    }

    componentDidUpdate(prevProps) {
        const { yearFilterArr, filterColumnArr,salesTechnologyLoader,salesTechnologyPieData, defaultMonth } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
            this.setState({
                yearSelectorValue: '',
                monthSelectorValue: defaultMonth
            })
        }

        if (prevProps.incomeStatementState.salesTechnologyLoader != salesTechnologyLoader && !salesTechnologyLoader) {
            let pieChartData = this.state.analyticsRadioValue == 0 && salesTechnologyPieData ?  salesTechnologyPieData.region : salesTechnologyPieData.country;
            this.setState({
                pieChartData,
                showPieChart: true
            })
        }
    }

    handleExcludeFilterChange = (event) => {
        if (event.target.value) {
            console.log(event.target.value, "checkExclude")
            const { isDetailedView, countryMetaName, comparativeSelectorValue, monthSelectorValue, qtrSelectorValue, yearSelectorValue, radioFilterValue, excludeDataFilter } = this.state

            if (isDetailedView == true) {
                this.setState(
                    { excludeDataFilter: event.target.value })
                let payload = {
                    yearmonth: monthSelectorValue,
                    year: yearSelectorValue,
                    reportType: 'month',
                    countryMetaName: countryMetaName,
                    quarter: qtrSelectorValue,
                    budgetGroup: radioFilterValue,
                    excludeData: event.target.value.join(','),
                    isComparative: comparativeSelectorValue == 2
                }

                this.props.getFunctionalPivotView(payload)
            } else {
                this.setState(
                    { excludeDataFilter: event.target.value }, () => {
                        this.fetchFunctionalPivotData()
                    })
            }



        }
    };

    handleExcludeFilterChangeNew = (event, newvalueArr) => {
        if (newvalueArr) {
            const { isDetailedView, countryMetaName, comparativeSelectorValue, monthSelectorValue, qtrSelectorValue, yearSelectorValue, radioFilterValue, excludeDataFilter } = this.state
            const valArr = newvalueArr.map((newvalue)=>(newvalue.value))

            if (isDetailedView == true) {
                this.setState(
                    { excludeDataFilter: valArr })
                let payload = {
                    yearmonth: monthSelectorValue,
                    year: yearSelectorValue,
                    reportType: 'month',
                    countryMetaName: countryMetaName,
                    quarter: qtrSelectorValue,
                    budgetGroup: radioFilterValue,
                    excludeData: valArr.join(','),
                    isComparative: comparativeSelectorValue == 2
                }

                this.props.getFunctionalPivotView(payload)
            } else {
                this.setState(
                    { excludeDataFilter: valArr }, () => {
                        this.fetchFunctionalPivotData()
                    })
            }



        }
    };

    handleResetExcludeFilter = () => {
        this.setState(
            { excludeDataFilter: [] }, () => {
                this.fetchFunctionalPivotData()
            })
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    getFileName = () => {
        const { monthSelectorValue, qtrSelectorValue, yearSelectorValue } = this.state
        let sel = monthSelectorValue + qtrSelectorValue + yearSelectorValue;
        return sel;
    }

    handleFileName = () => {
        const name = "Functional P&L - "
        return pdfFileName(name)
    }

    formatComments = (data) => {
        data.map(x => {
            let comm = "";
            if (x.comments && x.comments.length) {
                x.comments.forEach((y, idx) => {
                    comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                    if (idx != x.comments.length - 1) {
                        comm += "\n"
                    }
                })
            }
            if (x.sub_rows && x.sub_rows.length) {
                x.sub_rows = this.formatComments(x.sub_rows)
            }
            x.comment = comm
            return x
        })

        return data
    }

    handleExcelDownload = () => {
        let data = []
        let header = {};
        let merge = [];

        
        
        if (this.state.comparativeSelectorValue == 3 && this.state.isDetailedView == false ) {
            let datas = this.excelData
            header = datas.header;
            console.log(datas.data, "testcoloms")
           
            data = mapExcelDataSTComparative(datas.data, header, "id")
            let newHeader = mapExcelHeaderComparativeST(header, "Header");
            let idx = 0
            // data.map(x => {
            //     if(idx > 0){
            //         // x.map(y => {
                     
            //             console.log( x.key, "testcoloms12")
            //         // })
                   
            //     }
            //     idx ++
               
            // })
            // console.log(data, "testcoloms1")
            // console.log(newHeader, "testcoloms2")
            header = newHeader.data;
            merge = newHeader.merge;
        }else  {
            let datas = this.excelData
            header = datas.header;
            console.log(datas, "EXCELDATA")
            datas.data = this.unwindHeaderKeyVal(datas.data);
            datas.data = this.formatComments(datas.data)
    
            data = mapExcelData(datas.data, header, "id")
    
            header = mapExcelHeader(header, "headerTitle")
            }
        let fileName = " Income Statement - Sales & Technology P&L - " + this.getFileName()
        if (this.financialDuration) {
            fileName = fileName + " - " + this.financialDuration
        }
        console.log(data, fileName, header, false, merge)
        const { currentValue, previousValue } = this.props.incomeStatementState;
        let sheetName = 'Data';
        // if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
        //   sheetName = `${currentValue} - ${previousValue}`
        // }
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }

    handleExcelDataChange = (data, header) => {
        this.excelData.data = data;
        this.excelData.header = header;
    }

    handleExcelDataChangeComparison = (data, header) => {
        this.excelDataComparison.data = data;
        this.excelDataComparison.header = header;
    }

    handleAnalyticsRadioChange = (event) => {
       
        const { salesTechnologyPieData} = this.props.incomeStatementState
            let thisRadio = event.target.value
            this.setState({
                analyticsRadioValue: thisRadio,
                showPieChart: false
            }, () => {
                let pieChartData = this.state.analyticsRadioValue == 0 && salesTechnologyPieData ?  salesTechnologyPieData.region : salesTechnologyPieData.country;
            this.setState({
                pieChartData,
                showPieChart: true
            })

           
            })
    

    }

    handleComparativeRadioChange = (event) => {
        const { defaultMonth } = this.props.incomeStatementState;
        const { tabSelectorValue, yearSelectorValue } = this.state;
        console.log(defaultMonth, 'kkkkk>>>>', tabSelectorValue, event.target.value)
        console.log("budgets", this.state.monthSelectorValue);
        // this.setState({
        //     // comparativeSelectorValue: event.target.value,
        //     monthSelectorValue: '',
        //     qtrSelectorValue : '' ,
        //     yearSelectorValue:''
        // })
        if (event.target.value == 2) {
            this.setState({
                comparativeSelectorValue: event.target.value,
                qtrSelectorValue: '',
                refreshExcel: true,
                yearSelectorValue: '',
                monthSelectorValue: (this.state.monthSelectorValue == undefined || this.state.monthSelectorValue == '') ? defaultMonth : this.state.monthSelectorValue,
                // yearSelectorValue: (tabSelectorValue == 0 && event.target.value == 1) ? '' : yearSelectorValue
            }, () => {
                this.fetchFunctionalPivotData()
            })
        } else {
            this.setState({
                comparativeSelectorValue: event.target.value,
                refreshExcel: true,
                monthSelectorValue: (this.state.monthSelectorValue == undefined) ? defaultMonth : this.state.monthSelectorValue,
                // yearSelectorValue: (tabSelectorValue == 0 && event.target.value == 1) ? '' : yearSelectorValue

            }, () => {
                this.fetchFunctionalPivotData()
            })
        }

    }

    handleCommentSendFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendPivotCommentData(commentPayload)
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    handlePrintClick = () => {
        this.setState({
            doExport: false,
            exportFileName: ''
        })
    }

    printDocument = () => {
        let data = []
        let header = {};
        let merge = [];
        let datax = this.props.incomeStatementState
        let pivotHeaderData = datax.pivotHeaderData
        const pdf = new jsPDF('l', 'pt', 'letter');
        // if (this.state.tabSelectorValue == 1) {
        // } 
        const { tabSelectorValue, comparativeSelectorValue } = this.state;
        let page = ""
        let FileName = ""
        if (tabSelectorValue == 0) {
            // page = "Function Summary PDF"
            page = "xP&A Demo-Income Statement-Functional P&L-Function Summary"
            FileName = "new_Reporter-Income_Statement-Functional_P&L-Function Summary_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
        } else {
            // page = "View By Functions"
            page = "xP&A Demo-Income Statement-Sales & Technology-Monthly Rolling View"
            FileName = "new_Reporter-Income_Statement-Sales & Technology-Monthly Rolling View_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
        }
        // let FileName = page + ' - ' + this.financialDuration ? this.financialDuration : '' + format(new Date, " - MM-dd-yyyy HH-mm-ss")

        if (this.state.comparativeSelectorValue == 3 && this.state.isDetailedView == false) {
        // let page = "Functional P&L Comparative View"
        let datas = this.excelData
        header = datas.header;
        console.log(header, "this is OldHeader")

      
        data = mapPDFDataPLComparativeST(datas.data, header, "id", pivotHeaderData)
        console.log(pivotHeaderData, 'pppp', datas.data, data);
        let newHeader = mapExcelHeaderComparativeST(header, "Header");
        header = newHeader.data;
        let header2 = data.header;
        merge = newHeader.merge;
        let rows = []
        let i = 0
        console.log(header2, "headerTest")
        header.forEach(x => {
            // if (x != "") {
                if (i == 0) {
                    rows.push({ content: x, rowSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                if (i == 1) {
                    rows.push({ content: x, colSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                if (i == 3) {
                    rows.push({ content: x,  styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                if (i == 4) {
                    rows.push({ content: x, colSpan: 6, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                if (i == 10) {
                    rows.push({ content: x, colSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                if (i == 12) {
                    rows.push({ content: "Grand Total", rowSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
            // }
            i++;
        })
        let rows2 = []
        let j = 0

        // rows2.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
        header2.forEach(x => {
            // if (j < 6) {
                rows2.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                // j++;
            // }
            // if (j >= 6 && j < 9) {
            //     rows2.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })

            // }



            j++;

        })
        let heads = [
            rows,
            rows2,
        ];

        let rowsDataFix = []
        let dataCount = 0
        let dataFix = data.data
        console.log(header, "dataFix")
        console.log(rows2, "dataFix")
        console.log(dataFix, "dataFix")
        dataFix.forEach(x => {
            let i = 1
            let rowsData = []
            //   console.log(x, "ini datax")
            let dataField = ""
            x.forEach(y => {
                if (i == 1) {
                    dataField = y
                    if (dataField == "Total Cost") {
                        rowsData.push({ content: y, styles: { fillColor: [223, 217, 251] } })
                    } else {
                        rowsData.push({ content: y })
                    }

                } else {
                    if (y.includes("%")) {
                        if (y.includes("-")) {
                            y = y.replace("%", "");
                        }
                        rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                    } else {
                        if (dataField == "Total Cost") {

                            rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })


                        } else {
                           
                                rowsData.push({ content: y, styles: { halign: 'center' } })
                          



                        }
                    }

                }
                i++;

            })
            dataCount += 1
            rowsDataFix.push(rowsData)
        })
        let totalPages = 1

        if (dataCount > 22) {
            totalPages = Math.ceil(dataCount / 22)
        }
        pdf.autoTable({
            head: heads,
            body: rowsDataFix,
            theme: 'grid',
            didDrawPage: function (data) {
                // Header
                pdf.setFontSize(20);
                pdf.setTextColor(40);
                pdf.setFontStyle('heltevica');
                pdf.text(page, data.settings.margin.left + 15, 30);
                if (totalPages > 1) {
                    // Footer
                    var str = "Page " + pdf.internal.getNumberOfPages()
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof pdf.putTotalPages === 'function') {
                        str = str;
                    }
                    pdf.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    var pageSize = pdf.internal.pageSize;
                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    pdf.text(str, data.settings.margin.left, pageHeight - 10);
                }
            },
            styles: {
                lineWidth: 1,
                lineColor: [38, 38, 38],
                width: 'auto'
            },
            margin: { top: 50 }
        })
        pdf.save(FileName + '.pdf')
        }else  {
        let datas = this.excelData
        header = datas.header;
        datas.data = this.unwindHeaderKeyVal(datas.data);
        datas.data = datas.data.map(x => {
            let comm = "";
            if (x.comments && x.comments.length) {
                x.comments.forEach((y, idx) => {

                    comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                    if (idx != x.comments.length - 1) {
                        comm += "\n"
                    }
                })
            }
            x.comment = comm
            return x
        })
        data = mapDataPDF(datas.data, "id", pivotHeaderData)
        console.log(data, header, datas.data, pivotHeaderData)
        header = mapExcelHeader(header, "headerTitle")
        // let page = "Functional P&L Pivot View"
        let rows = []
        let i = 0
        header.forEach(x => {
            if (i == 0) {
                rows.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
            } else if (i < 9) {
                rows.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
            }
            i++;
        })
        let heads = [
            rows
        ];
        let rowsDataFix = []
        let dataCount = 0
        let dataFix = data
        dataFix.forEach(x => {
            let i = 0
            let rowsData = []
            console.log(x.length, "ini datax")
            let dataField = ""
            x.forEach(y => {
                if (i == 0) {
                    dataField = y
                    if (dataField == "Total Cost") {
                        rowsData.push({ content: y, styles: { fillColor: [223, 217, 251] } })
                    } else {
                        rowsData.push({ content: y })
                    }

                } else {
                    if (y.includes("%")) {
                        if (y.includes("-")) {
                            y = y.replace("%", "");
                        }
                        rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                    } else {
                        if (dataField == "Total Cost") {
                            rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                        } else {

                            // if (i == (x.length - 1)) {
                            //     rowsData.push({ content: y, styles: { halign: 'left' } })
                            // } else {
                            rowsData.push({ content: y, styles: { halign: 'center' } })
                            // }
                        }
                    }

                }
                i++;

            })
            dataCount += 1
            rowsDataFix.push(rowsData)
        })
        let totalPages = 1

        if (dataCount > 22) {
            totalPages = Math.ceil(dataCount / 22)
        }
        pdf.autoTable({
            head: heads,
            body: rowsDataFix,
            theme: 'grid',

            didDrawPage: function (data) {
                // Header
                pdf.setFontSize(20);
                pdf.setTextColor(40);
                pdf.setFontStyle('heltevica');
                pdf.text(page, data.settings.margin.left + 15, 30);
                // if (totalPages > 1 ) {
                // Footer
                var str = "Page " + pdf.internal.getNumberOfPages()
                // Total page number plugin only available in jspdf v1.0+
                if (typeof pdf.putTotalPages === 'function') {
                    str = str;
                }
                pdf.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = pdf.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                pdf.text(str, data.settings.margin.left, pageHeight - 10);
                // }
            },
            styles: {
                lineWidth: 1,
                lineColor: [38, 38, 38],
            },
            margin: { top: 50 }
        })
        pdf.save(FileName + '.pdf')
        }
    }

    handleCommentSendFunctionalFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendFunctionalCommentData(commentPayload)
    }

    handleNumberTransform = (number) => {
        const isNegative = number < 0
        const positiveTransformedValue = isNegative ? Math.abs(Math.round(number)) : Math.round(number)
        let modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        return modifiedData
    }

    handleNestedColumnCallback = (payload) => {

        let drilldownPayload = {
            ...payload,
            countryMetaName: this.state.countryMetaName,
            yearmonth: this.state.monthSelectorValue,
            quarter: this.state.qtrSelectorValue,
            year: this.state.yearSelectorValue
            // report_date: '2021-01-31T00:00:00.000Z'
        }
        this.props.sendFunctionalDrilldownData(drilldownPayload)
    }
    handleDownloadNestedColumnCallback = (payload) => {
        let drilldownPayload = {
            ...payload,
            yearmonth: this.state.monthSelectorValue,
            countryMetaName: this.state.countryMetaName,
            quarter: this.state.qtrSelectorValue,
            year: this.state.yearSelectorValue
            // report_date: this.state.monthSelectorValue
            // report_date: '2021-01-31T00:00:00.000Z'
        }
        this.props.downloadFunctionalDrilldownData(drilldownPayload)
    }

    handleRowHeaderClick = (data) => {
        const { comparativeSelectorValue, monthSelectorValue, qtrSelectorValue, yearSelectorValue, radioFilterValue, excludeDataFilter } = this.state
        // if (tabSelectorValue == 1 && radioSelectorValue == 1 && !isDetailedView) {

        //   this.setState({
        //     isDetailedView: true,
        //     detailPlantId: data.id,
        //     plantMetaData: data.metaData,
        //     countryMetaName: '',
        //     checked: 'month'
        //   }, () => {
        //     this.handleRowDataFetch()
        //   })
        // } else if (tabSelectorValue != 0 && radioSelectorValue == 0 && !isDetailedView) {
        if (comparativeSelectorValue == 3) {
            console.log("checkChek")

            this.setState({
                isDetailedView: true, countryMetaName: data.id,
            })
            let payload = {
                yearmonth: monthSelectorValue,
                year: yearSelectorValue,
                reportType: 'month',
                countryMetaName: data.id,
                quarter: qtrSelectorValue,
                budgetGroup: radioFilterValue,
                excludeData: excludeDataFilter.join(','),
                isComparative: comparativeSelectorValue == 2
            }

            this.props.getFunctionalPivotView(payload)

        }

        // }
    }
    handleBackClick = () => {
        const { comparativeSelectorValue, monthSelectorValue, qtrSelectorValue, yearSelectorValue } = this.state
        this.setState({
            monthSelectorValue: monthSelectorValue,
            isDetailedView: false,
            countryMetaName: "",
            qtrSelectorValue: qtrSelectorValue,
            yearSelectorValue: yearSelectorValue
        }, () => {
            this.fetchFunctionalPivotData()
        })
    }

    fetchSalesTechnologyPieDataFn = () => {

        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue,regionClicked,
            monthSelectorValue } = this.state;
            let payload = {
                year: yearSelectorValue ? yearSelectorValue : undefined,
                quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
                yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            }

            this.props.fetchSalesTechnologyPieData(payload)

    }


    render() {
        const { quarterfilterArr, monthFilterArr, filterColumnArr, yearFilterArr, budgetAnalysis, salesTechnologyPieData, salesTechnologyLoader, tornadoData } = this.props.incomeStatementState
        const { tabSelectorValue,monthSelectorValue, yearSelectorValue, excludeDataFilter, refreshExcel, comparativeSelectorValue, isDetailedView, radioFilterValue,pieChartData,showPieChart, countryMetaName, loading, budgetGroup,analyticsRadioValue, budgets, isAllFunc, qtrSelectorValue } = this.state;
        const AllFunctionRadio = <FormControlLabel value="" control={<Radio />} label="All Functions" />;
        // console.log(budgetGroup, "ceheck");
        // console.log(tornadoData, "tornadodata");
        let excFilterColArr =[]
        if ((typeof filterColumnArr !== 'string') && filterColumnArr?.length > 0) {
            excFilterColArr=[...filterColumnArr.map((bu)=> ({...bu, value: bu.key}))]
        }

        const selectedOptions = (a, data) => {
            if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
            else return {label:'', value:''}
            
        };

        let dateFilterValue = monthSelectorValue ? monthSelectorValue :  yearSelectorValue ? yearSelectorValue :  qtrSelectorValue ? qtrSelectorValue: ''
        return (
            <>
                {!monthFilterArr.length ? <div className='text-center'><CircularProgress /></div> :
                    <>
                        <div className='flex jc-space-between'>
                            <Grid container spacing={3}>
                                {monthFilterArr && monthFilterArr.length ? <Grid item xs={12} sm={2} className='maxw-200'>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={monthSelectorValue}
                                            label="Month"
                                            onChange={this.handleMonthChange}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        fullWidth
                                        size='small'
                                        className='xpna-dropdown single'
                                        options={monthFilterArr}
                                        value={this.state.monthSelectorValue}
                                        onChange={this.handleMonthChange}
                                        renderInput={(params)=>(
                                            <TextField {...params} variant='standard' label='Month' placeholder='Select'  />
                                        )}
                                    />
                                </Grid> : <></>}
                                {comparativeSelectorValue != 2 || radioFilterValue == 'Analytics'?
                                    <>
                                        {quarterfilterArr && quarterfilterArr.length ?
                                            <Grid item xs={12} sm={2} className='maxw-200'>
                                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                    <InputLabel>Quarter</InputLabel>
                                                    <Select
                                                        value={this.state.qtrSelectorValue}
                                                        label="Quarter"
                                                        onChange={this.handleQtrChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                                                            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    fullWidth
                                                    size='small'
                                                    className='xpna-dropdown single'
                                                    options={quarterfilterArr}
                                                    value={this.state.qtrSelectorValue}
                                                    onChange={this.handleQtrChange}
                                                    renderInput={(params)=>(
                                                        <TextField {...params} variant='standard' label='Quarter' placeholder='Select'  />
                                                    )}
                                                />

                                            </Grid>
                                            : <></>
                                        }
                                        {yearFilterArr && yearFilterArr.length ?
                                            <Grid item xs={12} sm={2} className='maxw-200'>
                                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                    <InputLabel>Annual</InputLabel>
                                                    <Select
                                                        value={yearSelectorValue}
                                                        label="Annual"
                                                        onChange={this.handleYearChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    fullWidth
                                                    size='small'
                                                    className='xpna-dropdown single'
                                                    options={yearFilterArr}
                                                    value={yearSelectorValue}
                                                    onChange={this.handleYearChange}
                                                    renderInput={(params)=>(
                                                        <TextField {...params} variant='standard' label='Year' placeholder='Select'  />
                                                    )}
                                                />

                                            </Grid> :
                                            <></>}
                                    </> : ""
                                }
                            </Grid>
                            {radioFilterValue != 'Analytics' &&
                                <>
                                <Tooltip title={'Download to PDF'} className='pointer'>
                                <PictureAsPdfIcon fontSize="large" className='pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.printDocument();
                                    }} />

                            </Tooltip>
                            <Tooltip title={'Download to Excel'} className='pointer'>
                                <img
                                    src={DownloadExcel}
                                    className='ml-10 pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.handleExcelDownload();
                                    }} />
                            </Tooltip>
                            </>
                            }
                        </div>
                        {
                            <div style={{ marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <RadioGroup aria-label="radio filter" value={radioFilterValue} onChange={this.handleRadioFilterChange} className='blockRadio'>


                                        {
                                            budgetGroup && budgetGroup.length > 0 ? budgetGroup.map((item, i) => {
                                                return <FormControlLabel value={item} key={i} control={<Radio />} label={item} />
                                            }) : ""

                                        }

                                    </RadioGroup>
                                </FormControl>
                            </div>

                        }
                        <div className='flex jc-space-between ai-center'>
                            {this.state.isDetailedView ?
                                <div className='rowDetails flex ai-center mb-10'>
                                    <Button onClick={this.handleBackClick} variant='contained' className='ml-10 newButton btnRed btnBack uppercase' color="primary" startIcon={<ChevronLeft />}>Back</Button>
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Country Name: <b>{countryMetaName}</b> - Rolling View</p>

                                </div> :
                                radioFilterValue != 'Analytics' ? 
                                (<FormControl component="fieldset">
                                    <RadioGroup name="radio" value={comparativeSelectorValue} onChange={this.handleComparativeRadioChange} className='radioGroupHorizontal'>
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Default Monthly Rolling View" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Comparative Analysis" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="By Region/Country" />

                                    </RadioGroup>
                                </FormControl>):
                                <FormControl component="fieldset">
                                <RadioGroup name="radio" value={analyticsRadioValue} onChange={this.handleAnalyticsRadioChange} className='radioGroupHorizontal'>
                                    <FormControlLabel value="0" control={<Radio color="primary" />} label="By Region" />
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="By Country" />
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Variance vs Budget" />

                                </RadioGroup>
                            </FormControl>
                                
                                }
                            {radioFilterValue != 'Analytics' && <div className='flex' style={{alignItems: 'center'}}>
                            {((comparativeSelectorValue == 3 && isDetailedView == true) || comparativeSelectorValue != 3) && <>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall minw-200 maxw-200' fullWidth size='small'>
                                        <InputLabel>Exclude Columns</InputLabel>
                                        <Select
                                            multiple
                                            value={excludeDataFilter}
                                            label="Exclude Columns"
                                            onChange={this.handleExcludeFilterChange}
                                        >
                                            {filterColumnArr && filterColumnArr.length && filterColumnArr.map(item => {
                                                return item.key != "grand_total" ? <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem> : ""
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        disableCloseOnSelect
                                        multiple
                                        className='xpna-dropdown multiple minw-200 maxw-200 mb-10'
                                        limitTags={1}
                                        size='small'
                                        options={excFilterColArr}
                                        getOptionLabel={(option)=>option.label}
                                        //value={excludeDataFilter}
                                        value={excFilterColArr && excFilterColArr.length ? selectedOptions(excludeDataFilter,excFilterColArr): []}
                                        onChange={this.handleExcludeFilterChangeNew}
                                        renderInput={(params)=>(<TextField {...params} variant='standard' label= 'Exclude columns' placeholder='Select' />)}
                                    />
                                    <Button variant='outlined' className='ml-10 newButtonOutlined btnBlue uppercase' onClick={this.handleResetExcludeFilter} color="primary">Reset</Button>
                                </>}
                            </div>}
                        </div>                          

                        {radioFilterValue != 'Analytics' ?
                            <FunctionalPivotTableComponent
                                {...this.props}
                                handleRowHeaderClick={this.handleRowHeaderClick}
                                isDetailedView={isDetailedView}
                                handleExcelDataChange={this.handleExcelDataChange}
                                handleCommentSendFn={this.handleCommentSendFn}
                                handleNestedColumnCallback={this.handleNestedColumnCallback}
                                handleDownloadNestedColumnCallback={this.handleDownloadNestedColumnCallback}
                                radioFilterValue={radioFilterValue}
                                tabSelectorValue={tabSelectorValue}
                                refreshExcel={refreshExcel}
                                selectedDateFilter={dateFilterValue}
                                comparativeSelectorValue={comparativeSelectorValue}

                            /> : 
                            showPieChart && analyticsRadioValue != 2 ?
                            <Grid container spacing={3}>
                                <Grid item xs={6} className="chart-div">
                                      {pieChartData.Technology &&  <div className="top-chart">
                                            {<PieChartComponent
                                                // fetchNewPieData={this.props.fetchNewPieData}
                                                pieData={pieChartData.Technology}
                                                loader={salesTechnologyLoader}
                                                seriesName={'Technology'}
                                            />
                                            }
                                        </div>}
                                       { pieChartData.Proposals && <div className="border-top-gray">
                                            {<PieChartComponent
                                                // fetchNewPieData={this.props.fetchNewPieData}
                                                pieData={pieChartData.Proposals}
                                                loader={salesTechnologyLoader}
                                                seriesName={'Proposals'}
                                            />
                                            }
                                        </div>
                                       }
                                    </Grid>
                                {
                                    <Grid item xs={6} className="chart-div">
                                       {pieChartData.Sales && <div className="top-chart">
                                            {<PieChartComponent
                                                // fetchNewPieData={this.props.fetchNewPieData}
                                                pieData={pieChartData.Sales}
                                                loader={salesTechnologyLoader}
                                                seriesName={'Sales'}
                                            />
                                            }
                                        </div>
                                        }
                                        {pieChartData.Marketing && <div className="border-top-gray">
                                            {<PieChartComponent
                                                // fetchNewPieData={this.props.fetchNewPieData}
                                                pieData={pieChartData.Marketing}
                                                loader={salesTechnologyLoader}
                                                seriesName={'Marketing'}
                                            />
                                            }
                                        </div>
                                        }   
                                    </Grid>
                                }                               
                            </Grid> : 
                            analyticsRadioValue == 2 ?
                            <div className='mt-20 budgetAnalysis'>
                                    <div>
                                        {tornadoData.length > 0 ? <h3 className='primary text-center'><b>Variance vs Budget</b></h3> : ''}
                                        {tornadoData.length > 0 ?
                                            <NegativeBarGraph graphData={tornadoData} transformData={(number) => this.handleNumberTransform(number)} handlePrintCallback={this.handlePrintClick} doExport={this.state.doExport} fileName={this.state.exportFileName} /> :
                                            <div>
                                                <div className='text-center'><CircularProgress /></div>
                                                <h3 className='text-center'>Loading data for the selected date</h3>
                                            </div>
                                        }
                                    </div>
                               </div> :
                            <div className='text-center'><CircularProgress /></div>
                        }

                    </>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state,
                incomeStatementState: state.incomeStatement,
                budgetGp: state.authReducer.userData.budgetGroup,
                authReducerState: state.authReducer,
            }
        },
        dispatch => {
            return {
                getBudgetGroup() {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_BUDGET_GROUP'
                    });
                },
                getFunctionalPivotView(data) {
                    dispatch({
                        type: 'FETCH_SALES_TECHNOLOGY_PIVOT_DATA',
                        payload: data
                    });
                },
                getFunctionalSummaryView(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_SUMMARY_DATA',
                        payload: data
                    });
                },
                getFunctionalDefaultMonthData(data) {
                    dispatch({
                        type: 'FETCH_SALES_TECHNOLOGY_DEFAULT_MONTH',
                        payload: data
                    });
                },
                sendPivotCommentData(data) {
                    dispatch({
                        type: 'SEND_PIVOT_COMMENT_DATA_ST',
                        payload: data
                    });
                },
                sendFunctionalCommentData(data) {
                    dispatch({
                        type: 'SEND_FUNCTIONAL_COMMENT_DATA',
                        payload: data
                    });
                },
                sendFunctionalDrilldownData(data) {
                    dispatch({
                        type: 'SEND_FUNCTIONAL_ST_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                downloadFunctionalDrilldownData(data) {
                    dispatch({
                        type: 'DOWNLOAD_FUNCTIONAL_ST_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchFunctionalBudgetGraph(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_BUDGET_GRAPH_DATA',
                        payload: data
                    })
                },
                fetchSalesTechnologyPieData(data) {
                    dispatch({
                        type: 'FETCH_SALES_TECHNOLOGY_PIE_CHART_DATA',
                        payload: data
                    });
                },
                fetchTornadoData(data) {
                    dispatch({
                        type: 'FETCH_SALES_TORNADO_DATA_MONTHLY',
                        payload: data,
                    })
                }
            };
        }
    )(FunctionalPLComponent);


