import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas } from '../../../../utils/numberUtils'

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});

function BarChart(props) {
    console.log(props.graphView, props.chartData, "cekVIew")
    console.log(props, "this props2")
    return (
        <div id="column_chart_overdue" class="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'column',
                            height: 400,
                            options3d: {
                                enabled: true,
                                alpha: 0,
                                beta: 0,
                                depth: 20,
                                viewDistance: 25
                            }
                        },
                        title: {
                            text: props.chartTitle,
                            useHTML: true,
                            align: "left",
                            margin: 25,
                            style: {
                                fontSize: "16px",   
                            }
                        },
                        subtitle: { text: ""},
                        xAxis: {
                            type: 'category',
                        },
                        yAxis: {
                            title: { text: '' },
                            visible: props.externalTabValue == 0 && props.dsoChart == true ? false : true,
                        },
                        tooltip: {
                            formatter: function () {
                                if (props.externalTabValue == 0) {
                                    if (props.chartTitle == 'DSO') {
                                        return '<span>' + this.series.name + '</span>: <b>' + numberWithCommas(this.y) + '</b><br/> <span>Average</span>: <b>' + numberWithCommas(this.point.totals) + '</b>';
                                    } else {
                                        return '<span>' + this.series.name + '</span>: <b>' + numberWithCommas(Math.round(this.y)) + '</b><br/> <span>Total</span>: <b>' + numberWithCommas(Math.round(this.point.totals)) + '</b>';
                                    }
                                } else {
                                    if (props.chartTitle == 'DSO') {
                                        return '<span>' + this.series.name + ' DSO </span>: <b>' + numberWithCommas(this.y) + '</b><br/>';
                                    } else {
                                        return '<span>' + this.series.name + ' Unbilled </span>: <b>' + numberWithCommas(this.y) + '</b><br/><span>Total O/S </span>: <b>' + numberWithCommas(Math.round(this.point.grand_total)) + '</b>';
                                    }
                                }
                            }
                            // headerFormat: '<span style="font-size:11px"></span><br>',
                            // pointFormat: '<span>{series.name}</span>: <b>{point.y:,.0f}</b><br/> <span>Total</span>: <b>{point.totals:,.0f}</b>',
                        },
                        plotOptions: {
                            column: {
                                depth: 25,
                                stacking: 'normal',
                            },
                            series: {
                                opacity: 1,
                                borderWidth: 0,
                                dataLabels: {
                                    enabled: props.SeriesLabelEnabled,
                                    format: '{point.label}',
                                    color: props.SeriesLabelColor,
                                    style: {
                                        textOutline: 'none'
                                    }
                                },
                            }
                        },
                        series: props.chartData,
                        // [
                        //     {
                        //         // name: props.chartData.name,
                        //         // colorByPoint: true,
                        //         data: props.chartData
                        //     }
                        // ],
                        // responsive: {
                        //     rules: [{
                        //         condition: { maxWidth: 450 },
                        //     }]
                        // },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            squareSymbol: false,
                            symbolHeight: 6,
                            symbolWidth: 12,
                            symbolRadius: 0
                        },
                        navigation: {
                            buttonOptions: {
                                verticalAlign: 'top',
                                y: -10
                            }
                        },
                    }
                }
            />

        </div>
    )
}
export default BarChart;