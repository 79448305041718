import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Divider from '@material-ui/core/Divider';
import { handleTableTransform } from '../../../../../utils/numberUtils'
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../../utils/dateFormat'
import CircularProgress from '@material-ui/core/CircularProgress';

export class DataValidationTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.headerColorArr = ['#f7f7f7', '#685aa9', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5']
        this.state = {
            seriesData: [],
            commentValue: '',
            commentModal: false,
            dateSelected: new Date()
        }
    }

    businessUnitClick = (data, type, rowData) => {
        const { summaryState } = this.props;
        var headerData = []
        if (summaryState.internalData &&
            summaryState.internalData.row_header &&
            summaryState.internalData.row_header.length
        ) {
            var _data = [...[], ...summaryState.internalData.row_header]
            headerData = _data.filter(item => {
                if (!(item.key == "business_unit" || item.key == "grand_total")) {
                    return item
                }
            })
        }
        headerData = this.changeKeysOfHeader(headerData)
        this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
    }

    changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    item.key == 'business_unit' ?
                                        props.original[item.key] == "Grand Total" ?
                                            <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                            <span
                                                onClick={() => {
                                                    // this.businessUnitClick(props.original[item.key], type, props.original) 
                                                }}>
                                                {props.original[item.key]}
                                            </span> :
                                        <>{handleTableTransform(props.original, item)}</>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        color: 'white',
                        backgroundColor: this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    changeValuesOfData = (data) => {
        if (data && data.length) {
            return data.map(item => {
                return item
            })
        }
        return []
    }

    toggleCommentModal = () => {
        this.setState({ commentModal: !this.state.commentModal, commentValue: '' })
    }
    saveDate = (date) => {

        this.setState({ selectedDate: date })
        console.log(date)
    }
    handleCommentChange = (event) => {
        this.setState({ commentValue: event.target.value })
    }

    handleCommentSubmit = () => {
        const { monthValue, yearValue, quarterValue } = this.props;
        if (this.state.commentValue) {
            let commentPayload = {
                view: this.props.selectedTab,
                comment: this.state.commentValue,
                filter_values: {
                    yearmonth: monthValue ? monthValue : undefined,
                    year: yearValue ? yearValue : undefined,
                    quarter: quarterValue ? quarterValue : undefined

                }
            }
            this.props.postDataBrowserValidationComment(commentPayload)
            this.toggleCommentModal()
        }
    }

    render() {
        let { dataBrowserState, selectedDate, selectedTab } = this.props;
        let externalDataColumns = this.changeKeysOfHeader(dataBrowserState.hfmBalance.row_header, 'recievables')
        let externalDataList = this.changeValuesOfData(dataBrowserState.hfmBalance.row_data);
        let internalDataColumns = this.changeKeysOfHeader(dataBrowserState.slBalance.row_header, 'slBalance')
        let balanceData = dataBrowserState.slBalance.row_data
        let internalDataList = this.changeValuesOfData(balanceData);
        let unbilledDataColumns = this.changeKeysOfHeader(dataBrowserState.delta.row_header, 'delta')
        let deltaData = dataBrowserState.delta.row_data
        let unbilledDataList = this.changeValuesOfData(deltaData);
        let commentsData = dataBrowserState.comments

        return (
            <>
                {
                    <div className={'hfmDataTable xOverFlowHidden firstChildBorder'}>
                        <div className={'tableHeader'}>
                            <span>Balances as per HFM</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={externalDataList}
                            columns={externalDataColumns}
                            defaultPageSize={5}
                            pageSize={externalDataList && externalDataList.length}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table  -striped -highlight"
                            sortable={false}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                        />}

                        <div className={'tableHeader'}>
                            <span>Balances as per xP&A Demo</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={internalDataList}
                            columns={internalDataColumns}
                            defaultPageSize={5}
                            pageSize={internalDataList && internalDataList.length}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table -striped -highlight"
                            sortable={false}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                        />}
                        <div className={'tableHeader'}>
                            <span>Delta = HFM - xP&A Demo</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={unbilledDataList}
                            columns={unbilledDataColumns}
                            defaultPageSize={5}
                            pageSize={unbilledDataList && unbilledDataList.length}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table  -striped -highlight"
                            sortable={false}

                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                        />}

                       
{selectedTab == "billing" && <h5 className='mt-16 mb-0'>Comments to explain Delta</h5>}
                        {selectedTab == "billing" && 
                        <div className='commentSection'>
                            <div className='comments'>
                                {commentsData.data && commentsData.data.length ?
                                    commentsData.data.map((item, index) => {
                                        return (
                                            <div className='block' key={index}>
                                                <h6 style={{ margin: 0 }}>{item.created_by}, {formatDate(item.created_at)}</h6>
                                                <p>{item.comment}</p>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </div>
                            {/* <div className='date' style={{ paddingBottom: '60px',  }}>
                                <Grid item xs={12} className={'report-date-picker'} >
                                    <InputLabel>Date</InputLabel>
                                </Grid>
                                <Grid item xs={12}  >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        openTo="month"
                                        size="small"
                                        format="dd-MM-yyyy"
                                        className='pr-3'
                                        value= {this.state.dateSelected}
                                        onChange={this.saveDate}
                                    />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                
                            </div> */}
                            <div className='infoArea' style={{ textAlign: 'center' }}>

                                <div className='buttonGroup' style={{ marginBottom: '16px' }}>
                                    <Button variant="contained" disabled style={{ color: 'rgba(0,0,0,0.8)' }}>{`Delta Value = ${dataBrowserState.deltaValue}`}</Button>
                                </div>
                                <Button variant="contained" color="primary" fullWidth onClick={this.toggleCommentModal}>Add New Comment</Button>
                            </div>
                        </div>}
                        <Modal
                            open={this.state.commentModal}
                            onClose={this.toggleCommentModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={this.state.commentModal}>
                                <div className='customModal dataValidationCommentModal'>
                                    <div className={'header'}>
                                        <h2>Add New Comment</h2>
                                        <Icon onClick={this.toggleCommentModal}>close</Icon>
                                    </div>
                                    <div className={'modal-content'} style={{ padding: '30px' }}>
                                        <p>Please enter the comment for the variation of the xP&A Demo Data with that of HFM Data</p>
                                        <TextField
                                            fullWidth
                                            label="Comment"
                                            multiline
                                            rows={10}
                                            value={this.state.commentValue}
                                            onChange={this.handleCommentChange}
                                            variant="filled"
                                        />
                                        <div className="flex jc-flex-end mt-30">
                                            <Button variant="contained" color="primary" onClick={this.handleCommentSubmit}>Submit</Button>
                                            <Button variant="contained" className='ml-16' onClick={this.toggleCommentModal}>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                dataBrowserState: state.dataBrowser,
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                getDataBrowserValidationData(data) {
                    dispatch({
                        type: 'FETCH_DATABROWSER_INCOME_VALIDATION_DATA',
                        payload: data
                    });
                },
                postDataBrowserValidationComment(data) {
                    dispatch({
                        type: 'SEND_DATABROWSER_INCOME_VALIDATION_COMMENT',
                        payload: data
                    });
                }
            };
        }
    )(DataValidationTableComponent);
