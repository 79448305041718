import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function createDataESP(Failure, ESP, Time, Reason, Impact, TimeReplacement, CostReplacement, DetectionMethod, MitigationMethod){
  return {Failure, ESP, Time, Reason, Impact, TimeReplacement, CostReplacement, DetectionMethod, MitigationMethod}
}

const rows = [
  createDataESP("Electrical", "Cable", "", "", "", "", "", "Sudden operational cessation sensor failure or malfunction", "Cable replacement and better insulation"),
  createDataESP("", "Overloading", "", "", "", "", "", "Surface reading", "Proper ESP material selection"),
  createDataESP("", "Connection Severance", "", "", "", "", "", "Sudden operational cessation", "Replacement of sensor with proper material"),
  createDataESP("Mechanical", "Breakage", "", "", "", "", "", "Fluid analysis on the surface. Also abnormal pump or motor operating condition", "Replacement of component with a more durable material"),
  createDataESP("", "Corrosion", "", "", "", "", "", "", ""),
  createDataESP("", "Breakage", "", "", "", "", "", "", ""),
  createDataESP("Operational", "Temperature", "", "", "", "", "", "Temperature and pressure sensors operational cessation", "Use temperature resistant material"),
  createDataESP("", "Pressure", "", "", "", "", "", "", "Avoid excessive pressure abnormalities"),
  createDataESP("", "Multiphase", "", "", "", "", "", "Produced  fluid analysis", "Gas prevention and venting")
];



function RCATable() {

    return (
         <div className="table-view">
             <Grid container spacing={3}>
                <Grid item xs={12}>
                   <TableContainer>
                      <Table aria-label="a dense table" className="custom-table" size="small"> 
                        <TableHead>
                          <TableRow>
                            <TableCell>Failure Classification</TableCell>
                            <TableCell>ESP Failure Distribution</TableCell>
                            <TableCell>Time period since installation</TableCell>
                            <TableCell>Reason for failure</TableCell>
                            <TableCell>Impact</TableCell>
                            <TableCell>Time for replacement</TableCell>
                            <TableCell>Cost of replacement</TableCell>
                            <TableCell>Detection Method</TableCell>
                            <TableCell>Mitigation Method</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{row.Failure}</TableCell>
                                <TableCell align="center">{row.ESP}</TableCell>
                                <TableCell align="center">{row.Time}</TableCell>
                                <TableCell align="center">{row.Reason}</TableCell>
                                <TableCell align="center">{row.Impact}</TableCell>
                                <TableCell align="center">{row.TimeReplacement}</TableCell>
                                <TableCell align="center">{row.CostReplacement}</TableCell>
                                <TableCell align="center">{row.DetectionMethod}</TableCell>
                                <TableCell align="center">{row.MitigationMethod}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </Grid>
              </Grid>
         </div>
        
    )
}

export default RCATable;