import React from 'react';
import { connect } from "react-redux";

export class ImprovementPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}

export default
    connect()(ImprovementPlans);