import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Modal from '@material-ui/core/Modal';
// import CommentBox from '../ImprovementPlan/comment';
import BoardViewComponent from './boardView'
import Modal from "./ModalCadence";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function CadenceBoard(props) {
    const [dataModal, setDataModal] = React.useState({})
    const [open, setOpen] = React.useState(false);

    const handleOpen = (data) => {
          setOpen(true)
          setDataModal(data)
    };

    const handleSave = (data) => {
        // const data = {  
        //     "_id" : dataModal._id,
        //     "plan_id": dataModal.plan_id,
        //     "plan_title":"",
        //     "created_on": dataModal.created_on,
        //     "closed_on": selectedDates.closed_on,
        //     "cost":dataModal.cost,
        //     "user_name":"",
        //     // "user_name":dataModal.user_name,
        //     "report_to":dataModal.report_to,
        //     "time_frame":dataModal.time_frame,
        //     "actual_cost":2,
        //     "fcf":dataModal.fcf,
        //     "ownership" : {
        //         "business_head" : dataModal.business_head
        //     },
        //     "component":dataModal.component,
        //     "gap":dataModal.gap,
        //     "benefit":3,
        //     "kpi_type":dataModal.kpi_type,
        //     "kpi":dataModal.kpi,
        //     "kpi_category":dataModal.kpi_category,
        //     "problem":dataModal.problem,
        //     "root_cause":dataModal.root_cause,
        //     "remedial_plan":dataModal.remedial_plan,
        //     "status":dataModal.status,
        //     "comments": dataModal.comments,
        //     "l1": selectedDates.l1,
        //     "l2": selectedDates.l2,
        //     "l3": selectedDates.l3
        // }

        props.saveDate(data);
        handleClose()
    };

    const handleChangeBoard = (input) => {
        props.saveDate(input);
    }

    const handleClose = () => {
        setOpen(false)
    }



    return (
        <div className="cadenceBoard mt-20">
            <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} sm={12} className="text-right cadence-board">
                <BoardViewComponent handleOpen={handleOpen} handleChangeBoard={handleChangeBoard} planTypeValue={props.planTypeValue} dateStatusSelected={props.dateStatusSelected} />
            </Grid>
            </Grid>
           <Modal menuData={props.menuData} open={open} handleClose={handleClose} handleSave={handleSave} data={dataModal}/>

           {/* <Modal
                className="collaborate-modal"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style}>
                    <CommentBox />
                </div>
            </Modal>*/}
        </div>

    )
}
