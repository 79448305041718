import React, { useState } from 'react';
import ReactTableComponent from '../../TableComnponent/pureTable'
import { useSelector, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../../utils/numberUtils'
import Tooltip from '@material-ui/core/Tooltip';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const levelMap = {
    PlTotal: 'P&L Total',
    PlGroup: 'P& L Group',
    GroupName2: 'Group Name2',
    GroupName: 'Group Name',
    GroupName3: 'Group Name3',
    AccountName: 'Account Name',
    CostCenter: 'Cost Centre2'
}
export default function TableComponent(props) {
    const incomeStatementState = useSelector(state => state.incomeStatement);
    const { compareDataBUPL } = incomeStatementState
    const {tabSelectorValue, radioSelectorValue} = props
    const {loading, rowHeader, rowData} = compareDataBUPL

    const checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    const checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    const expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt": rowInfo.original.sub_rows !== null ? 1 : 0
        };
    }

    const subComponent = (row) => {
        let pivotHeaderData = rowHeader
        let nestedFlag = checkForNestedLevel(row.original.sub_rows)
        pivotHeaderData = changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        getTrProps={expandIcon}
                        SubComponent={subComponent}
                        showPagination={false}
                        sortable={false}
                    />
                    : null
                }
            </>
        );
    };

    const handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    const handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }

    const handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={

                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>

                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }

        return tooltipData;
    }

    const handleTooltipData = (_data) => {
        let tooltipData = ''

        if (_data && _data.original) {
            let { custom_level, levels } = _data.original;
            if (levels && levels.length) {
                tooltipData = levelMap[levels[custom_level]] ? levelMap[levels[custom_level]] : levels[custom_level]
            }
        }


        return tooltipData;
    }


    const changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {

                item['operation'] = item.key == 'data_field' ? '' : 7;

                if (item.key == 'data_field') {

                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        Cell: (props) => {

                            let newOriginal = handleDifferentBUResponse(props.original, item.key)
                            // let zeroData = newOriginal[item.key]
                            let tooltipData = handleTooltipData(props)
                            return (
                                <div>
                                    <Tooltip title={tooltipData}>
                                        <span>{handleTableTransform(newOriginal, item)}</span>
                                    </Tooltip>
                                </div>

                            )
                        }
                    }

                } else if (item.key) {
                    return {
                        Header: handleColumnHeaderTooltip(item),
                        headerTitle: item.label,
                        id: item.key,
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        Cell: (props) => {

                            let newOriginal = handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal ? newOriginal[item.key] : undefined
                            let showPercentage = handleAppendPercentage(props.original);
                            let disableDrillDown = props.original.disable_drill_down;
                            let myClassName = showPercentage ? 'percentageRowData' : ''
                            myClassName += disableDrillDown ? '' : ' dontenableDrillDown'
                            return (
                                <React.Fragment>
                                    <span className={myClassName}>{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                                </React.Fragment>

                            )
                        },
                        accessor: (_data) => {
                            if (item.operation) {
                                let modifiedData;
                                let neOriginal = handleDifferentBUResponse(_data, item.key)
                                let columnData = neOriginal[item.key]
                                switch (item.operation) {
                                    case 0:
                                        modifiedData = item.key;
                                        break;
                                    case 1:
                                        modifiedData = Math.round(columnData)
                                        break;
                                    case 2:
                                        modifiedData = numberWithCommas(columnData)
                                        break;
                                    case 4:
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 3:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = numberWithCommas(modifiedData)
                                        break;
                                    case 5:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 6:
                                        var isNegative = columnData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 7:
                                        modifiedData = Math.round(columnData)
                                        var isNegative = modifiedData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    default:
                                        break;
                                }

                                return modifiedData;
                            } else {

                                return _data[item.key];
                            }
                        },
                    }

                }

                return {
                    Header: item.label,
                    id: item.key,
                    className: isNested ? '' : 'noNesting',
                    metaData: item.metadata,
                    Cell: (props) => {

                        let newOriginal = handleDifferentBUResponse(props.original, item.key)
                        let zeroData = newOriginal ? newOriginal[item.key] : undefined
                        let showPercentage = handleAppendPercentage(props.original);
                        return (
                            <React.Fragment>
                                <>{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</>
                            </React.Fragment>
                        )
                    }
                }
            })
        }
        return []
    }

    const pivotHeaderData = changeKeysOfHeader(rowHeader)
    const pivotRowData = rowData
    let tableLen = pivotRowData && pivotRowData.length || 5;


    return <div>
        <ReactTableComponent
                    pivotRowData={pivotRowData}
                    className={ (tabSelectorValue == 1 || tabSelectorValue == 2) && radioSelectorValue == 1 ? 'newCustomTable fixedFirstColumTable expandableRows plantView' : 'newCustomTable fixedFirstColumTable expandableRows' }
                    loading={loading}
                    pivotHeaderData={pivotHeaderData}
                    tableLen={tableLen}
                    subComponent={subComponent}
                    expandIcon={expandIcon}
                    showInvoiceDetailModal={false}
                    showLoader={false}
                />
    </div>
}