import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import history from '../../history'
import { changeValuesOfData, setAllRowCommentHistoryData } from './transformer'
import {
    chatUploadFileService,
    chatDownloadFileService
} from './service'
import { store } from '../../index'

function* uploadChatFile(data) {
    const state = store.getState();
    var formData = new FormData();
    formData.append('file', data.payload.file);
    const response = yield call(chatUploadFileService, formData, data.payload.id);
    if (response && response.status == 200) {
        yield put({ type: "CHAT_UPLOAD_FILE_SUCCESS", loading: false, data: response.data });
    }
}

function* downloadChatFile(data) {
    const state = store.getState();
    data.payload['report_date'] = state.dashboardState.selectedDate
    const response = yield call(chatDownloadFileService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "CHAT_DOWNLOAD_FILE_SUCCESS", loading: false, data: response.data });
    }
}

export default function* actionWatcher() {
    yield takeLatest('CHAT_UPLOAD_FILE', uploadChatFile)
    yield takeLatest('CHAT_DOWNLOAD_FILE', downloadChatFile)
}
