import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import DataValidationTableComponent from './DataValidationTable'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tooltip from '@material-ui/core/Tooltip';
import { downloadExcel, mapExcelData, mapDataPDF, mapExcelHeader } from '../../../../utils/exportExcel'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import jsPDF from "jspdf";
import 'jspdf-autotable'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns/esm';
export class DataValidationComponent extends React.Component {

  constructor(props) {
    super(props);
    const datelen = props.dashboardState.reportDateArr && props.dashboardState.reportDateArr.length;
    this.state = {
      loading: true,
      hfmRadioValue: "billing",
      month: props.dashboardState.reportDateArr && props.dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
    }
  }

  componentDidMount() {
    this.props.getDataBrowserValidationComment(this.state.hfmRadioValue)
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      hfmRadioValue: event.target.value
    }, () => {
      this.props.getDataBrowserValidationComment(this.state.hfmRadioValue)
    })
  };

  handleMonthChange = (event) => {
    if (event.target && this.state.month != event.target.value) {
      this.setState({ month: event.target.value }, () => {
        this.props.updateSelectedDate(event.target.value)
        this.props.getDataBrowserValidationData(this.state.hfmRadioValue)
        this.props.getDataBrowserValidationComment(this.state.hfmRadioValue)
      });
    }
  }

  handleDownloadExcel = () => {
    let data = this.props.dataBrowserState;
    let delta = data.delta;
    let hfm = data.hfmBalance
    let sl = data.slBalance
    let header = delta.row_header
    delta = mapExcelData(delta.row_data, header)
    hfm = mapExcelData(hfm.row_data, header)
    sl = mapExcelData(sl.row_data, header)

    delta.map(x => {
      x.data_type = "Delta = HFM - xP&A Demo"
      return x
    })

    hfm.map(x => {
      x.data_type = "Balances as per HFM"
      return x
    })

    sl.map(x => {
      x.data_type = "Balances as per xP&A Demo"
      return x
    })

    header = header.map(x => {
      return x.label
    })
    header.push("Data Type")
    let finalData = hfm.concat(sl).concat(delta)
    downloadExcel(finalData, ` Receivables - ${this.state.hfmRadioValue} View - ${this.state.month}`, header)
  }

  
printDocument = () => {
  let data = this.props.dataBrowserState;
  const pdf = new  jsPDF('l', 'pt', 'letter');
  let delta = data.delta;
  let hfm = data.hfmBalance
  let sl = data.slBalance
  let deltaValue = data.deltaValue
  let header = delta.row_header
  delta = mapExcelData(delta.row_data, header)
  hfm = mapExcelData(hfm.row_data, header)
  sl = mapExcelData(sl.row_data, header)
  let hfmData = mapDataPDF(hfm, "id", header)
  let slData = mapDataPDF(sl, "id", header)
  let deltaData = mapDataPDF(delta, "id", header)
  header = mapExcelHeader(header, "label")
  let typesHFM = this.state.hfmRadioValue
  let page = ""
  if(typesHFM == 'billing'){
    page = "xP&A Demo-Receivables-Data Browser-Data Validation-Summary By BU"
  }else{
    page = "xP&A Demo-Receivables-Data Browser-Data Validation-Summary By Region"
  }

  let rows = []
    let i = 0
    header.forEach(x => {
      rows.push({content: x,  styles: {halign: 'center', fillColor: [223, 217, 251], textColor : [131, 6, 206]}} )
    })
    let heads = [
      rows
    ];
    pdf.autoTable({
      head: heads,
      body: hfmData,
      theme: 'grid',
   
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text(page, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
            str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
    },
    styles: {
      lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
    },
    margin: {top: 50}
    })

    pdf.autoTable({
      head: heads,
      body: slData,
      theme: 'grid',
   
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text(page, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
            str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
    },

    styles: {
      lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
    },
    margin: {top: 50}
    })

    pdf.autoTable({
      head: heads,
      body: deltaData,
      theme: 'grid',
      startY: pdf.lastAutoTable.finalY + 230,
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        // pdf.text("Delta Value " + deltaValue, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
            str = str;
        }
        pdf.setFontSize(10);

      
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
    },
    styles: {
      lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
    },
    margin: {top: 50}
    })
    let finalY = pdf.lastAutoTable.finalY + 50; // The y position on the page
    pdf.setFontStyle('sans-serif');
    pdf.text(570, finalY, "Delta Value : "+deltaValue)
  // console.log(hfmData, "data hfmData")
  // console.log(data, "data ORI")
  // console.log(header, "header")
  // console.log(sl, "sl")
  // console.log(hfm, "hfm")
  // console.log(delta, "delta")
  let FileName = page + "_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
  pdf.save(FileName + '.pdf')
}

  render() {
    const { hfmRadioValue, month } = this.state
    const { dashboardState } = this.props
    const datelen = dashboardState.reportDateArr && dashboardState.reportDateArr.length;
    const updatedMonth = dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
  
    const arrayDate = dashboardState.reportDateArr
    const newArray = []
    const idx = arrayDate.length
    let Newidx = 0
    for (var keys in arrayDate) {
      Newidx++ 
      
      newArray.push(arrayDate[(idx-Newidx)])
    }
    console.log(newArray, "checkDate")
    return (
      <div className='dataValidation'>
        {dashboardState && dashboardState.reportDateArr && dashboardState.reportDateArr.length ?
          <>
            <div className="flex ai-center jc-space-between mb-10">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2} className='maxw-200'>
                  <FormControl className='w-200'>
                    <Select
                      value={month || updatedMonth}
                      onChange={this.handleMonthChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      {newArray.map((item, index) => {
                        return <MenuItem value={item} key={index}>{item}</MenuItem>
                      })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Tooltip title={'Download to PDF'} className='pointer'>
              <PictureAsPdfIcon fontSize="large" className='pointer'
              height='30'
              onClick={(event) => {
                event.preventDefault();
                this.printDocument();
              }}/>  
              </Tooltip>
              <Tooltip title={'Download to Excel'}>
                <img
                  src={DownloadExcel}
                  className='ml-10 pointer'
                  height='30'
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleDownloadExcel();
                  }} />
              </Tooltip>
            </div>
            <RadioGroup aria-label="hfm data" name="hfm" value={hfmRadioValue} onChange={this.handleChange}>
              <FormControlLabel value="billing" control={<Radio />} label="Summary by BU" />
              {/* <FormControlLabel value="regional" control={<Radio />} label="Summary by Region" /> */}
            </RadioGroup>
            {<DataValidationTableComponent
              selectedTab={hfmRadioValue}
              selectedDate={month}
            />}
          </> : null
        }
      </div>
    );
  }
}


export default
  connect(
    state => {
      return {
        ageingData: state.dashboardState.receivableData.ageingData,
        dashboardState: state.dashboardState,
        dataBrowserState: state.dataBrowser,
      }
    },
    dispatch => {
      return {
        getDataBrowserValidationData(data) {
          dispatch({
            type: 'FETCH_DATABROWSER_VALIDATION_DATA',
            payload: data
          });
        },
        updateSelectedDate(data) {
          dispatch({
            type: "UPDATE_SELECTED_DATE",
            payload: data,
          });
        },
        getDataBrowserValidationComment(data) {
          dispatch({
            type: 'FETCH_DATABROWSER_VALIDATION_COMMENT',
            payload: data
          });
        },
      };
    }
  )(DataValidationComponent);