import React from 'react';
import "react-table-6/react-table.css";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Grid, Box, InputLabel, MenuItem,  Select, Button } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { format } from 'date-fns';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function SimpleModal(props) {
    // const {userDataState} = props
    const [planID, setPlanID] = useState('')
    const [versionName, setVersionName] = useState('')
    const [versionNo, setVersionNo] = useState('')
    const [isDefault, setIsDefault] = useState('')

    // React.useEffect(() => {
    //     if (props.formPlan && Object.keys(emailTemplateData).length > 0) {
    //         setEmailTitle(emailTemplateData.email_title);
    //     }
    // }, [emailTemplateData])

    if(props.planDetails){
        const {planDetails} = props

        if(planDetails._id != planID){
            setPlanID(planDetails._id)
            setVersionName( planDetails.name)
            setVersionNo( planDetails.version)
            setIsDefault(planDetails.is_default)
        }
    }

    const handleToggleChange = (event) => {
       setIsDefault(event.target.checked)
    }

    const closeSavePlan = () => {
        props.modalCallBack(false);
    };

    const handleSavePlan = (event) => {
        event.preventDefault();
        let payload = {
            "_id": planID,
            "name" : versionName,
            "period_type" : props.filterPeriod.period_type,
            "period" : props.filterPeriod.period,
            "budget_period" : props.filterPeriod.budget_period,
            "version" : versionNo,
            "is_default" : isDefault,
        }
        // console.log("payload :: ",payload, " :: ",props.filterPeriod)
        props.savePlanModels([payload]);
        closeSavePlan();
    };

    const handleSaveAsPlan = (event) => {
        event.preventDefault();
        let payload = {
            "_id": "",
            "name" : versionName,
            "period_type" : props.filterPeriod.period_type,
            "period" : props.filterPeriod.period,
            "budget_period" : props.filterPeriod.budget_period,
            "budget_value" : props.filterPeriod.budget_value,
            "version" : versionNo,
            "is_default" : isDefault,
        }
        // console.log("payload :: ",payload, " :: ",props.filterPeriod)
        props.savePlanModels([payload]);
        closeSavePlan();
    }; 

return (
    <div>
        <Modal
            open={props.formPlan}
            onClose={closeSavePlan}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.formPlan}>
                {
                // tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> :
                    <div className='customModal viewEmailModal'>
                        <div className='header'>
                            <h2>Save Plans</h2>
                        </div>
                        <div className='modal-content email-template'>
                            <form onSubmit={handleSavePlan}>
                                <TextField
                                    id="version_name"
                                    label="Version Name"
                                    style={{ margin: 8 }}
                                    placeholder=""
                                    helperText=""
                                    fullWidth
                                    // defaultValue={emailTemplateData.email_title}
                                    // value={emailTemplateData.email_title}
                                    value={versionName}
                                    onChange={(newValue) => {
                                        setVersionName(newValue.target.value);
                                    }}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="user_name"
                                    label="Username"
                                    style={{ margin: 8 }}
                                    defaultValue={props.userDataState.login_id}
                                    fullWidth
                                    margin="normal"
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="version_no"
                                    label="Version No"
                                    style={{ margin: 8 }}
                                    placeholder=""
                                    helperText=""
                                    fullWidth
                                    value={versionNo}
                                    onChange={(newValue) => {
                                        setVersionNo(newValue.target.value);
                                    }}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="date_now"
                                    label="Date"
                                    style={{ margin: 8 }}
                                    defaultValue={format(new Date(), 'dd MMM yyyy')}
                                    margin="normal"
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="time_now"
                                    label="Time"
                                    style={{ margin: 8 }}
                                    defaultValue={format(new Date(), 'kk:mm')}
                                    margin="normal"
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormControlLabel labelPlacement="start" control={<Switch checked={isDefault} className='switchMUI mt-12' onChange={handleToggleChange} name="toggleValue" />} label="Set as Default"/>

                                <div className="flex jc-center mt-30">
                                    <Button type="submit" variant="contained" className='ml-16' color="primary">OK</Button>
                                    <Button onClick={handleSaveAsPlan} variant="contained" className='ml-16' color="primary">SAVE AS..</Button>
                                    <Button variant="contained" className='ml-16' onClick={() => { closeSavePlan() }}>
                                     {/* onClick={this.closeViewEmail}> */}
                                        Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                }

            </Fade>
        </Modal>
    </div>
    );
}