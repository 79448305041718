import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { format, parseJSON } from 'date-fns'
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// import { getActionOwneruser } from "../../../../../utils/userManagement";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        // name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const dummydata = [{ title: 'Sales', label: 1994 },
                  { title: 'Functionality', label: 1972 }]

export default function SimpleModal(props) {
    let subCatData = [];
    const [isBeforeTarget, setIsBeforeTarget] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [stageDataMaster, setStageDataMaster] = React.useState([]);
    const [category, setCategory] = React.useState('');
    const [subcategory, setSubCategory] = React.useState('');
    const [resparty, setResParty] = React.useState('');
    const [selectedResparty, setSelectedResParty] = React.useState({});
    const [targetVal, setTargetVal] = React.useState('');
    const [impAction, setImpAction] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [completionDate, setCompletionDate] = React.useState(null);
    const [reasonForDelays, setReasonForDelays] = React.useState("");
    const [stage, setStage] = React.useState("");
    const [pipType, setPipType] = React.useState("");

    const handleClose = () => {
        props.modalCallBackPip(false);
    };

    // React.useEffect(() => {
    //     if (props.cleanUpPipData) {
    //         handleResetPopUp();
    //         props.modalCallBackcleanUpPipData();
    //         if (props.editMode) {
    //             loadEditData();
    //         }
    //     }
    //     setPipType(props.type == undefined ? "60+" : props.type)
    // },[]);

    // const handleResetPopUp = () => {
    //     setCategory("");
    //     setSubCategory("");
    //     setResParty("");
    //     setTargetVal("");
    //     setSelectedDate(null);
    //     setCompletionDate(null);
    //     setImpAction("");
    //     setReasonForDelays("");
    //     setBtnDisabled(true)
    //     setIsBeforeTarget(false)
    // }

    // const loadEditData = () => {
    //     let data = props.editData;
    //     setCategory(data.category);
    //     setStage(data.stage);
    //     changeSubCatData(data.category)
    //     setSubCategory(data.subcategory);
    //     setResParty(data.responsibleparty);
    //     setTargetVal(data.targetvalue);
    //     setSelectedDate(parseJSON(data.targetdate));
    //     setCompletionDate(data.completiondate != null ? parseJSON(data.completiondate) : parseJSON(data.targetdate));

    //     let curDate = new Date()
    //     console.log(new Date(), parseJSON(data.targetdate), "--target date")
    //     if(curDate <= parseJSON(data.targetdate)){
    //         setCompletionDate(null)
    //         setIsBeforeTarget(true)
    //         console.log("---masssssok")
    //     }

    //     setImpAction(data.improvementaction);
    //     setReasonForDelays(data.reasonfordelays);
    //     setStageDataMaster(props.stageData);
    //     let sel = props.allUser.find(x => { return x.login_id == data.responsibleparty });
    //     setSelectedResParty(sel);
    //     // console.log(props.allUser,data.responsibleparty, sel, "---sel")
    // }

    // const handleResetSubmit = (event) => {
    //     event.preventDefault();
    // };

    // const changeSubCatData = (catData) => {
    //     props.changeSubCatData(catData);
    // }

    // const handleChangeCat = (event) => {
    //     setCategory(event.target.value);
    //     changeSubCatData(event.target.value);
    //     setSubCategory("");
    // };

    // const handleChangeSubCat = (event) => {
    //     setSubCategory(event.target.value);
    // };

    // const handleChangeResParty = (data) => {
    //     if (data == null) {
    //         setResParty("");
    //         setSelectedResParty({});
    //         return
    //     }
    //     setSelectedResParty(data);
    //     setResParty(data.login_id);
    // };

    // const handleChangeTargetVal = (event) => {
    //     setTargetVal(event.target.value);
    // };

    // const handleChangeImpAction = (event) => {
    //     setImpAction(event.target.value);
    // };

    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    // };

    // const handleCompletionDateChange = (date) => {
    //     setCompletionDate(date);
    // };

    // const handleReasonForDelays = (event) => {
    //     setReasonForDelays(event.target.value);
    // };

    // const handleStageChange = (event) => {
    //     setStage(event.target.value);
    // };

    // const handleSavePip = () => {
    //     if (props.editMode) {
    //         var data = {}
    //         data._id = props.editData.bsonid;
    //         data.id = props.editData.id;
    //         data.bu = props.buValue;
    //         data.category = category;
    //         data.sub_category = subcategory;
    //         data.username = props.userDataState.login_id;
    //         data.customer_id = props.customerData.customer.toString();
    //         if (pipType == "100k") {
    //             data.invoice_no = props.customerData.document_number.toString();
    //         }
    //         data.responsible_party = resparty;
    //         data.target_value = parseFloat(targetVal);
    //         data.target_date = format(selectedDate, "dd-MM-yyyy");
    //         data.improvement_action = impAction;
    //         data.stage = stage;
    //         data.type = pipType;
    //         data.reason_for_delays = reasonForDelays;
    //         if(completionDate != null){
    //             data.completion_date = format(completionDate, "dd-MM-yyyy");
    //         }
    //         props.updateCallback(data);
    //     } else {
    //         var data = {}
    //         data.bu = props.buValue;
    //         data.category = category;
    //         data.sub_category = subcategory;
    //         data.username = props.userDataState.login_id;
    //         data.customer_id = props.customerData.customer.toString();
    //         if (pipType == "100k") {
    //             data.invoice_no = props.customerData.document_number.toString();
    //         }
    //         data.responsible_party = resparty;
    //         data.target_value = parseFloat(targetVal);
    //         data.target_date = format(selectedDate, "dd-MM-yyyy");
    //         data.improvement_action = impAction;
    //         data.stage = "Ideation";
    //         data.type = pipType;
    //         props.saveCallback(data);
    //     }
    // }

    // React.useEffect(() => {
    //     if (props.editMode) {
    //         if (
    //             category != "" &&
    //             subcategory != "" &&
    //             resparty != "" &&
    //             targetVal != "" &&
    //             targetVal != "" && parseFloat(targetVal) > 0 &&
    //             (selectedDate != "" && selectedDate != null) &&
    //             impAction != "" &&
    //             ((completionDate != "" && completionDate != null) || isBeforeTarget )) {
    //             if (completionDate > selectedDate) { //if delay
    //                 if (reasonForDelays != "") //reason is mandatory
    //                     setBtnDisabled(false)
    //                 else
    //                     setBtnDisabled(true)
    //             } else {
    //                 setBtnDisabled(false)
    //             }
    //         }
    //         else
    //             setBtnDisabled(true)
    //     } else {
    //         if (
    //             category != "" &&
    //             subcategory != "" &&
    //             resparty != "" &&
    //             targetVal != "" && parseFloat(targetVal) > 0 &&
    //             (selectedDate != "" && selectedDate != null) &&
    //             impAction != "")
    //             setBtnDisabled(false)
    //         else
    //             setBtnDisabled(true)
    //     }
    // }, [category, subcategory, resparty, targetVal, selectedDate, impAction, completionDate, reasonForDelays])
    const ActionOwnerPopper = function (props) {
        return <Popper {...props} style={{ width: 'fit-content' }} placement="top" />;
    }

    const body = (
        <div className={'customModal addImprovementPlanModel ' + (props.editMode ? "editImprovementPlanModel" : "")} >
            <div className='header'>
            <h2> Improvement Plan</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                {
                // props.loading ? <div className='text-center'><CircularProgress /></div> :
                    <React.Fragment>
                        <Grid container spacing={2} className='mb-20 dropdown-pip-container'>
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="outlined" size='small' className='w-100'>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        disabled={props.viewMode}
                                        label="Category"
                                        style={{ borderRadius: '20px' }}
                                        id="pip-category-select"
                                        value={category}
                                        // onChange={handleChangeCat}
                                    >
                                        {}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <FormControl variant="outlined" size='small' className='w-100'>
                                    <InputLabel>Sub-Category</InputLabel>
                                    <Select
                                        disabled={props.viewMode}
                                        label="Sub-Category"
                                        style={{ borderRadius: '20px' }}
                                        id="pip-subcategory-select"
                                        value={subcategory}
                                        // onChange={handleChangeSubCat}
                                    >
                                        {}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-plan-id"
                                    label="Username"
                                    value= 'Username'
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-created-on"
                                    label="Bussiness Unit"
                                    value = "BU-1"
                                    // value={props.buValue.toUpperCase()}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-plan-id"
                                    label="Plan ID"
                                    // value={props.editMode ? props.editData.id : props.pipIDData}
                                    value= 'Plan ID'
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-created-on"
                                    label="Created On"
                                    value= 'Created On'
                                    // value={props.editMode ? props.editData.createdon ? format(parseJSON(props.editData.createdon), 'MM/dd/yyyy HH:mm') : format(new Date(), 'MM/dd/yyyy HH:mm'): format(new Date(), 'MM/dd/yyyy HH:mm')}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-created-on"
                                    label="Customer Name"
                                    value="Customer Name"
                                    // value={props.customerData.customer_name}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    disabled
                                    id="pip-plan-id"
                                    label="Customer ID"
                                    value="Customer ID"
                                    // value={props.editMode ? props.editData.customerid : props.customerData.customer}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <FormControl variant="outlined" className='w-100' size="small">
                                    {/* <Autocomplete
                                        disabled={props.viewMode}
                                        getOptionSelected={(option, value) => option.login_id == props.editData.responsibleparty}
                                        getOptionSelected={'option'}
                                        className='pipSelect'
                                        options={getActionOwneruser(props.allUser, props.buValue)}
                                        size='small'
                                        PopperComponent={ActionOwnerPopper}
                                        getOptionLabel={(option) => option.full_name}
                                        // onChange={(event, data) => { handleChangeResParty(data) }}
                                        value={selectedResparty}
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ fontSize: '16px' }}
                                                {...params}
                                                label="Responsible Party"
                                                variant="outlined"
                                            />
                                        )}
                                        renderOption={(option) => <Typography
                                            style={{ fontSize: '13px', width: '250px' }}
                                            className='flex jc-space-between minw-200 ai-center'
                                            noWrap>
                                            <span className='pr-10'>{option.full_name}</span>
                                            <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                        </Typography>}
                                    /> */}
                                    <Autocomplete
                                        disabled={props.viewMode}
                                        className='pipSelect'
                                        size='small'
                                        options={dummydata}
                                        getOptionLabel={(option) => option.title}
                                        // style={{ width: 300 }}
                                        renderInput={(params) => <TextField 
                                                                style={{ fontSize: '16px' }}
                                                                {...params}
                                                                label="Responsible Party"
                                                                variant="outlined" />}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                props.editMode ?
                                    <Grid item xs={12} sm={6} >
                                        <FormControl variant="outlined" className='w-100' size="small">
                                            <InputLabel>Stage of Execution</InputLabel>
                                            <Select
                                        disabled={props.viewMode}
                                        label="Stage of Execution"
                                                id="pip-stage"
                                                value={stage}
                                                // onChange={handleStageChange}
                                            >
                                                {
                                                    stageDataMaster ? stageDataMaster.map((val) => (
                                                        val ? <MenuItem value={val.value} key={val.value}>
                                                            {val.text}
                                                        </MenuItem> : ''
                                                    )) : null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    :
                                    ""
                            }
                            <Grid item xs={12} sm={6} >
                                <TextField
                                        disabled={props.viewMode}
                                        id="pip-target-value"
                                    label="Target $ Value"
                                    value={targetVal}
                                    // onChange={handleChangeTargetVal}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,

                                    }}
                                    error={parseFloat(targetVal) < 0 && targetVal != ""}
                                    helperText={parseFloat(targetVal) < 0 && targetVal != "" ? "Target value cannot be negative" : ""}
                                />
                            </Grid>

                            {props.editMode ?
                                <Grid container xs={12} sm={6} className='mt-12 pl-12 pr-12'>
                                    <Grid item xs={12} sm={ isBeforeTarget ? 12 : 6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                disabled
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                size="small"
                                                className='pr-3'
                                                error={false}
                                                helperText={null}
                                                label="Target Date"
                                                value={selectedDate}
                                                // onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={new Date()}
                                                maxDate={completionDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        {  isBeforeTarget ? "" :
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disabled={props.viewMode}
                                                disableToolbar
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                size="small"
                                                className='pl-3'
                                                label="Completion Date"
                                                minDateMessage="Completion date cannot be before Target date"
                                                value={completionDate}
                                                // onChange={handleCompletionDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={selectedDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                        }
                                    </Grid>
                                </Grid>
                                : ""
                            }
                            <Grid item xs={12} sm={6} >
                                <TextField
                                        disabled={props.viewMode}
                                        id="pip-imp-action"
                                    label="Type Improvement Action"
                                    multiline
                                    value={impAction}
                                    // onChange={handleChangeImpAction}
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            {props.editMode ?
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        disabled={props.viewMode}
                                        id="pip-reason-for-delays"
                                        label="Responsible Party Comments"
                                        multiline
                                        value={reasonForDelays}
                                        // onChange={handleReasonForDelays}
                                        rows={4}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                :
                                <Grid item xs={12} sm={6} >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            size="small"
                                            fullWidth
                                            label="Target Date"
                                            // value={selectedDate}
                                            // onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            minDate={new Date()}
                                            maxDate={completionDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            }
                        </Grid>
                        <Grid container justify="flex-end" className='mt-20'>
                            { props.viewMode ? "" :
                                <>
                            <Button
                            //  onClick={handleSavePip}
                              disabled={btnDisabled} variant="contained" color="primary">Submit</Button>
                            <Button variant="contained" onClick={handleClose} className='ml-10'>Cancel</Button>
                                </>
                            }
                        </Grid>
                    </React.Fragment>
                }
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}