import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from "../../Shared/InvoiceDetail";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTableComponent from './pureTable';
import { Autocomplete, TextField } from '@mui/material';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = ''
        this.currentAmountValue = ''
        this.sortList = {}
        this.previousAmountValue = ''
        this.isPrevious = false;
        this.state = {
            loading: true,
            showBothVersion: false,
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            filterSelectorValue: [],
            isShowVarianceChecked: false,
            showLoader: false,
            invoiceObject: null
        }
        this.excelData = []
    }

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }

    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }

    addExcelDataMain = (data, level, idx) => {
        idx += 1;
        let excelData = this.excelData;
        let curLevel = excelData[idx] && excelData[idx].custom_level;
        let prevLevel = excelData[idx - 1] && excelData[idx - 1].custom_level;
        // console.log(curLevel, prevLevel,"level")
        if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
            excelData = this.removeExcelDataMain(data, curLevel)
        } else {
            level += 1
            data && data.forEach((x, i) => { //inject data after the parent
                x.sub_column.current.data_field = this.addSpaceLevel(x.sub_column.current.data_field, level)
                // console.log(x.custom_level)
                excelData && excelData.splice && excelData.splice(idx + i, 0, x)
            })
        }
        this.excelData = excelData
        console.log(data, this.excelData, "dataax1")
    }

    removeExcelDataMain = (data, idx) => {
        let excelData = this.excelData;
        let fields;
        if(data!=null){
            if (data.length != 0){
                fields= data[0].sub_column.current.levels_field;
                excelData = excelData.filter((val, i) => {
                    // console.log(i >= idx && val.pl_total == data[0].pl_total)
                    // console.log(i, idx ,val.pl_total , data[0].pl_total)
                    if (fields && fields.length) {
                        return !(val.custom_level >= idx && val.sub_column.current[fields[0]] == data[0].sub_column.current[fields[0]])
                    }
                })
                console.log(excelData, "dataax2")
                return excelData
            } 
        }
         
    }

    addExcelData = (dataval, header) => {
        let excelData = this.excelData;
        let data = JSON.parse(JSON.stringify(dataval))
        data.sub_rows = this.checkLevel(data.sub_rows, data.custom_level);
        //console.log(excelData, data,dataval,header,"asdasdasdasd")
        if(excelData != undefined && excelData !=null){
            if (excelData.length == 0) {
                this.excelData = data;
            } else {
                let fields = data.sub_column.current.levels_field
                switch (data.custom_level) {
                    case 0:
                        var idx = excelData && excelData.findIndex && excelData.findIndex((val) => (
                            val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                            val.custom_level == data.custom_level
                        )) //find the parent index
                        this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                        break;
                    case 1:
                        var idx = excelData.findIndex((val) => (
                            val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                            val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                            val.custom_level == data.custom_level
                        )) //find the parent index
                        this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                        break;
                    case 2:
                        var idx = excelData.findIndex((val) => (
                            val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                            val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                            val.sub_column.current[fields[2]] == data.sub_column.current[fields[2]] &&
                            val.custom_level == data.custom_level
                        )) //find the parent index
                        this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                        break;
                    case 3:
                        var idx = excelData.findIndex((val) => (
                            val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                            val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                            val.sub_column.current[fields[2]] == data.sub_column.current[fields[2]] &&
                            val.sub_column.current[fields[3]] == data.sub_column.current[fields[3]] &&
                            val.custom_level == data.custom_level
                        )) //find the parent index
                        this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                        break;
                }
            }
        }
        this.handleExcelDataChange(this.excelData, header)
    }


    dynamicHeaderValueOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].label : item.label
            return data
        }
        return item.label
    }

    dynamicHeaderKeyOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].key : item.key
            return data
        }
        return item.key
    }

    changeKeysOfHeaderComparativeVariance = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                item['operation'] = item.key == 'data_field' ? '' : 7;

                if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        fixed: 'left',
                        id: item.key,
                        className: isNested ? '' : 'noNesting',
                        Cell: (props) => {
                            item['operation'] = item.key == 'data_field' ? '' : 7;
                            let newOriginal = this.handleDifferentBUResponse(props.original.sub_column.variance, item.key)
                            let zeroData = newOriginal[item.key]
                            return (
                                <React.Fragment>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</React.Fragment>
                            )
                        }
                    }
                }
                return {
                    Header: item.label,
                    id: item.key,
                    mainId: item.key,
                    className: isNested ? '' : 'noNesting',
                    Cell: (props) => {
                        item['operation'] = 7;
                        let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column.variance, item.key)
                        let zeroData = newOriginal && newOriginal[item.key]
                        let showPercentage = this.handleAppendPercentage(props.original.sub_column.variance);
                        let disableDrillDown = props.original.sub_column.current.disable_drill_down;
                        let myClassName = showPercentage ? 'percentageRowData' : ''
                        myClassName += disableDrillDown ? '' : ' enableDrillDown'
                        return (
                            <React.Fragment>
                                <span className={myClassName}>{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                            </React.Fragment>
                        )
                    },
                    accessor: (_data) => {
                        if (item.operation) {
                            let modifiedData;
                            let columnData = _data[item.key]
                            switch (item.operation) {
                                case 0:
                                    modifiedData = item.key;
                                    break;
                                case 1:
                                    modifiedData = Math.round(columnData)
                                    break;
                                case 2:
                                    modifiedData = numberWithCommas(columnData)
                                    break;
                                case 4:
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 3:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 5:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 6:
                                    var isNegative = columnData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 7:
                                    modifiedData = Math.round(columnData)
                                    var isNegative = modifiedData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                default:
                                    break;
                            }
                            return modifiedData;
                        } else {
                            return _data[item.key];
                        }
                    },
                    // style: { cursor: 'pointer' }

                }
            })
        }
        return []
    }


    changeKeysOfHeaderComparative = (data, isNested) => {
        const { isShowVarianceChecked } = this.state;
        if (isShowVarianceChecked) {
            return this.changeKeysOfHeaderComparativeVariance(data, isNested);
        } else {
            if (data && data.length) {
                console.log(data, "cekDataComparative")
                return data.map((item, i) => {
                    item['operation'] = item.key == 'data_field' ? '' : 7;
                    if (item.key == 'data_field') {
                        return {
                            Header: item.label,
                            fixed: 'left',
                            columns: [{
                                Header: item.label,
                                id: item.key,
                                className: isNested ? '' : 'noNesting',
                                Cell: (props) => {
                                    item['operation'] = item.key == 'data_field' ? '' : 7;
                                    let newOriginal = this.handleDifferentBUResponse(props.original.sub_column.current, item.key)
                                    let zeroData = newOriginal[item.key]
                                    let showPercentage = this.handleAppendPercentage(props.original.sub_column.current);

                                    return (
                                        <React.Fragment>
                                            <>{handleTableTransform(newOriginal, item)}</>
                                        </React.Fragment>
                                    )
                                }
                            }]
                        }
                    }
                    return {
                        Header: item.label,
                        columns: [{
                            Header: this.dynamicHeaderValueOfColumn(item, 0),
                            id: this.dynamicHeaderKeyOfColumn(item, 0),
                            mainId: item.key,
                            mainHeaderTitle: item.label,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = 7;
                                let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column.current, item.key)
                                let zeroData = newOriginal && newOriginal[item.key]
                                let showPercentage = this.handleAppendPercentage(props.original.sub_column.current);
                                let disableDrillDown = props.original.sub_column.current.disable_drill_down;
                                let myClassName = disableDrillDown ? '' : ' enableDrillDown'
                                return (
                                    <React.Fragment>
                                        <span className={myClassName} >{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                                    </React.Fragment>
                                )
                            },
                            accessor: (_data) => {
                                if (item.operation) {
                                    let modifiedData;
                                    let columnData = _data.sub_column[this.dynamicHeaderKeyOfColumn(item, 0)]
                                    columnData = columnData ? columnData[item.key] : columnData

                                    switch (item.operation) {
                                        case 0:
                                            modifiedData = item.key;
                                            break;
                                        case 1:
                                            modifiedData = Math.round(columnData)
                                            break;
                                        case 2:
                                            modifiedData = numberWithCommas(columnData)
                                            break;
                                        case 4:
                                            modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 3:
                                            modifiedData = Math.round(columnData)
                                            modifiedData = numberWithCommas(modifiedData)
                                            break;
                                        case 5:
                                            modifiedData = Math.round(columnData)
                                            modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 6:
                                            var isNegative = columnData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 7:
                                            modifiedData = Math.round(columnData)
                                            var isNegative = modifiedData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        default:
                                            break;
                                    }
                                    return modifiedData;
                                } else {
                                    return _data[item.key];
                                }
                            },
                            // style: { cursor: 'pointer' }
                        }, {
                            Header: this.dynamicHeaderValueOfColumn(item, 1),
                            id: this.dynamicHeaderKeyOfColumn(item, 1),
                            mainId: item.key,
                            mainHeaderTitle: item.label,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column.previous, item.key)
                                let zeroData = newOriginal[item.key]
                                let showPercentage = this.handleAppendPercentage(props.original.sub_column.previous);

                                let disableDrillDown = props.original.sub_column.current.disable_drill_down;
                                let myClassName = disableDrillDown ? '' : ' enableDrillDown'
                                return (
                                    <React.Fragment>
                                        <span className={myClassName} >{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                                    </React.Fragment>
                                )
                            },
                            accessor: (_data) => {
                                if (item.operation) {
                                    let modifiedData;
                                    let columnData = _data.sub_column[this.dynamicHeaderKeyOfColumn(item, 1)]
                                    columnData = columnData ? columnData[item.key] : columnData

                                    switch (item.operation) {
                                        case 0:
                                            modifiedData = item.key;
                                            break;
                                        case 1:
                                            modifiedData = Math.round(columnData)
                                            break;
                                        case 2:
                                            modifiedData = numberWithCommas(columnData)
                                            break;
                                        case 4:
                                            modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 3:
                                            modifiedData = Math.round(columnData)
                                            modifiedData = numberWithCommas(modifiedData)
                                            break;
                                        case 5:
                                            modifiedData = Math.round(columnData)
                                            modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 6:
                                            var isNegative = columnData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 7:
                                            modifiedData = Math.round(columnData)
                                            var isNegative = modifiedData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        default:
                                            break;
                                    }
                                    return modifiedData;
                                } else {
                                    return _data[item.key];
                                }
                            },
                            // style: { cursor: 'pointer' }
                        }, {
                            Header: this.dynamicHeaderValueOfColumn(item, 2),
                            id: this.dynamicHeaderKeyOfColumn(item, 2),
                            mainId: item.key,
                            mainHeaderTitle: item.label,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = 7;
                                let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column.variance, item.key)
                                let zeroData = newOriginal && newOriginal[item.key]
                                let showPercentage = this.handleAppendPercentage(props.original.sub_column.previous);
                                let disableDrillDown = props.original.sub_column.variance.disable_drill_down;
                                // if(this.props.plantType == 3){
                                //     disableDrillDown = true
                                // }
                                let myClassName = disableDrillDown ? '' : ' enableDrillDown'
                                return (
                                    <React.Fragment>
                                        <span className={myClassName} >{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                                    </React.Fragment>
                                )
                            },
                            accessor: (_data) => {
                                if (item.operation) {
                                    let modifiedData;
                                    let columnData = _data.sub_column[this.dynamicHeaderKeyOfColumn(item, 2)]
                                    columnData = columnData ? columnData[item.key] : columnData
                                    switch (item.operation) {
                                        case 0:
                                            modifiedData = item.key;
                                            break;

                                        case 6:
                                            var isNegative = columnData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        case 7:
                                            modifiedData = Math.round(columnData)
                                            var isNegative = modifiedData < 0
                                            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                            modifiedData = numberWithCommas(positiveTransformedValue)
                                            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                            modifiedData = modifiedData == '0' ? '' : modifiedData
                                            break;
                                        default:
                                            break;
                                    }
                                    return modifiedData;
                                } else {
                                    return _data.sub_column[this.dynamicHeaderKeyOfColumn(item, 2)];
                                }
                            },
                            // style: { cursor: 'pointer' }
                        }]
                    }
                })
            }
            return []
        }
    }

    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    subComponent = (row) => {
        let { pivotHeaderDataComparative } = this.props.incomeStatementState;
        const { radioSelectorValue } = this.props
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        pivotHeaderDataComparative = this.changeKeysOfHeaderComparative(pivotHeaderDataComparative, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowDataComparative = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowDataComparative}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderDataComparative}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }

    transformDataAmount = (columnData) => {
        let modifiedData = columnData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '' : modifiedData

        return modifiedData
    }


    handleColumnClick = (state, colInfo, columnDetail, etc) => {
        console.log(state, colInfo, columnDetail, etc)
        const { isShowVarianceChecked } = this.state;
        const { sub_column } = colInfo.original;
        const { mainId, id, Header, mainHeaderTitle } = columnDetail
        const { current } = sub_column;
        const {legalEntitySelectorValue, plantSelectorValue} = this.props

        let amountValue;

        let dataVal = current && current.parents && current.parents.length ? [...current.parents, current.data_field].join(',') : current.data_field;


        if (Header) {

            if (isShowVarianceChecked) {

                this.isPrevious = false;
                amountValue = sub_column['variance']['header_key_value'] ? sub_column['variance']['header_key_value'][mainId] : sub_column['variance'][mainId];
                amountValue = this.transformDataAmount(amountValue)
                this.setState({
                    showLoader: true,
                    showBothVersion: true,
                    invoiceObject: { grid_header: mainId == 'grand_total' ? '' : mainId, level_values: dataVal, sub_grid_header_value: 'variance' }

                })
                this.showDrilldownHeaderValue = `${current.data_field} (${Header}) - USD ${checkNan(amountValue)}`
                this.props.getInvoiceDetail({ legal_entity : legalEntitySelectorValue, plant : plantSelectorValue ,grid_header: mainId == 'grand_total' ? '' : mainId, level_values: dataVal, sub_grid_header_value: 'variance' });

            } else {
                // if(this.props.plantType == 3){ // don't apply previous for plant view
                //     if(id == "variance"){
                //         return false
                //     }
                // }

                this.setState({
                    showLoader: true,
                    showBothVersion: id == "variance"
                })

                amountValue = sub_column[id]['header_key_value'] ? sub_column[id]['header_key_value'][mainId] : sub_column[id][mainId];
                amountValue = this.transformDataAmount(amountValue)
                this.showDrilldownHeaderValue = `${current.data_field} (${mainHeaderTitle}) - USD ${checkNan(amountValue)}`
                this.setState({
                    showLoader: true,
                    showBothVersion: id == "variance",
                    invoiceObject: { grid_header: mainId == 'grand_total' ? '' : mainId, level_values: dataVal, sub_grid_header_value: id == 'grand_total' ? '' : id }
                })
                if(this.props.plantType == 3  && id == "variance"){ // don't apply previous for plant view
                    this.setState({
                        showLoader: true,
                        showBothVersion: false,
                        invoiceObject: { grid_header: mainId == 'grand_total' ? '' : mainId, level_values: dataVal, sub_grid_header_value: id == 'grand_total' ? '' : id }
                    })
                }
                this.props.getInvoiceDetail({legal_entity : legalEntitySelectorValue, plant : plantSelectorValue , grid_header: mainId == 'grand_total' ? '' : mainId, level_values: dataVal, sub_grid_header_value: id == 'grand_total' ? '' : id });

                if (id == 'previous') {
                    this.isPrevious = true;
                } else {
                    this.isPrevious = false;

                }

                if(this.props.plantType == 3){ // don't apply previous for plant view
                    this.isPrevious = false
                }

            }
            let previouVal = sub_column['previous']['header_key_value'] ? sub_column['previous']['header_key_value'][mainId] : sub_column['previous'][mainId]
            let currentVal = sub_column['current']['header_key_value'] ? sub_column['current']['header_key_value'][mainId] : sub_column['current'][mainId]
            this.previousAmountValue = this.transformDataAmount(previouVal)
            this.currentAmountValue = this.transformDataAmount(currentVal)
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                if (!rowInfo.original.sub_column.current.disable_drill_down){
                    this.handleColumnClick(state, rowInfo, column, instance)
                }
                // console.log(rowInfo, "--comparative info")
                if (column.expander) {
                    let { pivotRowDataComparative, pivotHeaderDataComparative, loading } = this.props.incomeStatementState;
                    let nestedFlag = this.checkForNestedLevel(pivotRowDataComparative)
                    // pivotHeaderDataComparative = this.changeKeysOfHeaderComparative(pivotHeaderDataComparative, nestedFlag);
                    this.addExcelData(rowInfo.original, pivotHeaderDataComparative);
                    handleOriginal()
                }
            }
        }
    }

    prepareExcelData = () => {
        this.excelData = []
        this.handleExcelDataChange([], [])
        let { pivotRowDataComparative, pivotHeaderDataComparative } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowDataComparative)
        // pivotHeaderDataComparative = this.changeKeysOfHeaderComparative(pivotHeaderDataComparative, nestedFlag);
        pivotRowDataComparative = this.checkLevel(pivotRowDataComparative);
        this.addExcelData(pivotRowDataComparative, pivotHeaderDataComparative);
    }

    componentDidUpdate(prevProps) {
        this.prepareExcelData();
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
            this.setState({
                showInvoiceDetailModal: true,
                showLoader: false
            })
        }
    }

    componentDidMount() {
        this.prepareExcelData();
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    handlefilterChange = (event) => {
        if (event.target.value) {
            this.setState({ filterSelectorValue: event.target.value })
        } else {
            this.setState({ filterSelectorValue: [] })
        }
    };


    handleShowVarianceChange = (event) => {
        
    
        this.setState({ isShowVarianceChecked: event.target.checked });
        this.props.isShowVarianceChecked(event.target.checked)
    };

    handleDownloadFromBECallback = () => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            export: true,
        }
      
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value.amount,
            filter_gl: value.gl
        }
        this.setState({
            invoiceObject: payload
        })
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;

        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }

    handleGLInvoiceSorting = (glInvoiceObject, columnId) => {

        let payload = {
            ...glInvoiceObject
        }
        if (columnId) {
            this.sortListGL[columnId] = this.sortListGL[columnId] != undefined ? !this.sortListGL[columnId] : false

            payload['sort'] = {
                key: columnId,
                desc: this.sortListGL[columnId]
            }

        }

        this.props.getInvoiceDetailGL(payload)
    }

    expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt":  rowInfo.original.sub_rows && rowInfo.original.sub_rows !== null && rowInfo.original.sub_rows.length > 0 ? 1 : 0
        };
    }

    render() {
        let { invoiceDetailStateFilter, pivotRowDataComparative, invoiceLoading, pivotHeaderDataComparative, filterColumnArr, loading, invoiceDetailState, invoiceDetailStatePrevious, currentValue, previousValue, invoiceDetailStateGL } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowDataComparative)
        pivotHeaderDataComparative = this.changeKeysOfHeaderComparative(pivotHeaderDataComparative, nestedFlag);
        const { excludeDataFilter, handleExcludeFilterChangeNew, handleResetExcludeFilter, comparativeSelectorValue, tabSelectorValue } = this.props
        const { showInvoiceDetailModal, isShowVarianceChecked, showLoader, showBothVersion } = this.state
        let tableLen = pivotRowDataComparative && pivotRowDataComparative.length || 6;
        pivotRowDataComparative = this.checkLevel(pivotRowDataComparative);
        let excFilterColArr =[]
        if ((typeof filterColumnArr !== 'string') && filterColumnArr?.length > 0) {
            excFilterColArr=[...filterColumnArr.map((bu)=> ({...bu, value: bu.key}))]
        }

        const selectedOptions = (a, data) => {
            if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
            else return {label:'', value:''}
            
        };
        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                { this.props.plantType != 3 ?
                <div className='flex jc-space-between mb-10 ai-center'>
                    <div>
                        <FormControlLabel
                            label="Only Show Variance"
                            control={
                                <Checkbox
                                    checked={isShowVarianceChecked}
                                    onChange={this.handleShowVarianceChange}
                                    name="isVariance"
                                    color="primary"
                                />
                            }
                        />
                        <span>| *<b>{comparativeSelectorValue == 1 ? 'Current Month' : (comparativeSelectorValue == 2 ? 'Current Quarter' : 'Current')}</b> {currentValue} </span>
                        <span>| *<b>{comparativeSelectorValue == 1 ? 'Last Month' : (comparativeSelectorValue == 2 ? 'Last Quarter' : 'Last')}</b> {previousValue}</span>
                    </div>
                    {tabSelectorValue != 0 && <div className='flex'>
                        {/* <FormControl variant="outlined" className='selectOutlineSmall minw-200 maxw-200' fullWidth size='small'>
                            <InputLabel>Exclude Columns</InputLabel>
                            <Select
                                multiple
                                value={excludeDataFilter}
                                label="Exclude Columns"
                                onChange={handleExcludeFilterChangeNew}
                            >
                                {filterColumnArr && filterColumnArr.length && filterColumnArr.map(item => {
                                    return <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            disablePortal
                            disableClearable
                            disableCloseOnSelect
                            multiple
                            className='xpna-dropdown multiple minw-200 maxw-200 mb-10'
                            limitTags={1}
                            size='small'
                            options={excFilterColArr}
                            getOptionLabel={(option)=>option.label}
                            value={excFilterColArr && excFilterColArr.length ? selectedOptions(excludeDataFilter,excFilterColArr): []}
                            onChange={this.handleExcludeFilterChangeNew}
                            renderInput={(params)=>(<TextField {...params} variant='standard' label= 'Exclude columns' placeholder='Select' />)}
                        />
                        <Button onClick={handleResetExcludeFilter} color="primary">Reset</Button>
                    </div>}
                </div> : ""
    }
                {/* <ReactTableFixedColumns
                    data={pivotRowDataComparative}
                    className='newCustomTable fixedFirstColumTable'
                    loading={loading}
                    columns={pivotHeaderDataComparative}
                    defaultPageSize={6}
                    pageSize={tableLen}
                    SubComponent={this.subComponent}
                    showPagination={false}
                    sortable={false}
                    // getTheadThProps={this.columnHeaderClick}
                    getTdProps={this.rowClick}

                /> */}

                {loading ? <div className='progressfullpage'><CircularProgress /></div> : <ReactTableComponent
                    pivotRowData={pivotRowDataComparative}
                    className='xpna-reactFixed-table functional-pl -striped -highlight lastRowBlueColored'
                    loading={loading}
                    pivotHeaderData={pivotHeaderDataComparative}
                    tableLen={tableLen}
                    expandIcon={this.expandIcon}
                    subComponent={this.subComponent}
                    // columnHeaderClick={this.columnHeaderClick}
                    rowClick={this.rowClick}
                    showInvoiceDetailModal={showInvoiceDetailModal}
                    showLoader={showLoader}
                />}
                {showInvoiceDetailModal &&
                    <InvoiceDetailModal
                        getInvoiceDetailGL={this.handleGLInvoiceSorting}
                        showModal={showInvoiceDetailModal}
                        invoiceDetailStateGL={invoiceDetailStateGL}
                        isPrevious={this.isPrevious}
                        showBothVersion={showBothVersion}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceLoading={invoiceLoading}
                        invoiceDetailState={invoiceDetailState}
                        invoiceDetailStatePrevious={invoiceDetailStatePrevious}
                        invoiceDetailStateFilter={invoiceDetailStateFilter}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        previousAmountValue={this.previousAmountValue}
                        currentAmountValue={this.currentAmountValue}
                        currentDateValue={currentValue}
                        previousDateValue={previousValue}
                        handleFilterChange={this.handleInvoiceFilterChange}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                        financialDuration={this.props.financialDuration}
                        handleDownloadFromBE={true}
                        handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                    />
                }
            </>
        );
    }
}

export default TableComponent;