import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';

export default function ModalCadence(props) {
	return (
        <div className="mt-20">
            <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="sm" className="plan-modal-koc">
		        <DialogTitle>Create Initiative / Action Plan</DialogTitle>
		        <DialogContent>
		          <div className="form-cadence" className="mt-10">
			          <TextField size="small" id="name" fullWidth variant="outlined" label="Action Plan"/>
			      </div>
			      <Grid container spacing={3} className="mt-5">
			          <Grid item xs={6}>
			              <TextField size="small" id="name" fullWidth variant="outlined" label="Route Profit/Loss"/>
			          </Grid>
			          <Grid item xs={6}>
			              <TextField size="small" id="name" fullWidth variant="outlined" label="Current Gap to target"/>
			          </Grid>
			      </Grid>
			      <Grid container spacing={3}>
			          <Grid item xs={6}>
			               <FormControl variant="outlined" size="small" fullWidth>
					        <InputLabel id="demo-simple-select-outlined-label">Date</InputLabel>
					        <Select
					          labelId="demo-simple-select-outlined-label"
					          id="demo-simple-select-outlined"
					          label="Date"
					        >
					          <MenuItem value="">
					            <em>None</em>
					          </MenuItem>
					        </Select>
					      </FormControl>
			          </Grid>
			          <Grid item xs={6}>
			             <FormControl variant="outlined" size="small" fullWidth>
					        <InputLabel id="demo-simple-select-outlined-label">File Size</InputLabel>
					        <Select
					          labelId="demo-simple-select-outlined-label"
					          id="demo-simple-select-outlined"
					          label="File Size"
					        >
					          <MenuItem value="">
					            <em>None</em>
					          </MenuItem>
					        </Select>
					      </FormControl>
			          </Grid>
			      </Grid>
			      <Grid container spacing={3}>
			          <Grid item xs={6}>
			              <TextField size="small" id="name" fullWidth variant="outlined" label="Plan Status"/>
			          </Grid>
			          <Grid item xs={6}>
			              <TextField size="small" id="name" fullWidth variant="outlined" label="Time Frame"/>
			          </Grid>
			      </Grid>
		        </DialogContent>
		        <DialogActions>
		          <Button variant="contained" size="small" color="primary" className="btn-custom">Save</Button>
		          <Button variant="contained" size="small" className="btn-cancel" onClick={()=>{props.handleClose()}}>Cancel</Button>
		        </DialogActions>
		    </Dialog>
        </div>
    )
}
