import axios from 'axios';

export const getWaterFallData = () => {
    return axios.get('http://localhost:8080/v1/common/financial/chart');
}

export const transformWaterFallData = (data) => {
    let arrData = [];
    arrData = data.map(item => {
        let obj = {};
        obj['name'] = item.label;
        obj['y'] = item.value;
        if (item.label.includes('EBITDA')) {
            obj['drilldown'] = 'revenue';
        }
        if (item.increment) {
            obj['color'] = '#008000'
        } else {
            obj['color'] = '#FF0000'
        }
        if (item.label.includes('Final')) {
            obj['isSum'] = true
            obj['color'] = '#0e07ee'
        }
        if (item.label.includes('FCF') || item.label.includes('Revenue') || item.label.includes('Cost')) {
            obj['color'] = '#0e07ee'
        }
        return { ...obj, ...item };
    })
    return arrData;
}

export const changeAnalysisParam = (data, seriesData) => {
    seriesData.forEach(item => {
        if (item.label.includes('EBITDA')) {
            item['drilldown'] = data;
        }
    })
    return seriesData;
}

export const getWaterFallDrillDownData = (id) => {
    return axios.get('http://localhost:8080/v1/common/financial/chart?id=2&view=location&analysis=' + id);
}

export const transformDrillDownData = (data) => {
    let arrData = [];
    arrData = data.map(item => {
        return [item.label, item.value]
    })
    return arrData;
}

export const getWaterFallDataByRange = (range) => {
    return axios.get('http://localhost:8080/v1/common/financial/chart?range=' + range);
}

export const getWaterFallDataByView = (range) => {
    return axios.get('http://localhost:8080/v1/common/financial/chart?view=' + range);
}
