import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

function Table() {
  const columns = [
    {
      Header: "",
      accessor: "name"
    },
    {
      Header: "Q4-19",
      accessor: "q419"
    },
    {
      Header: "Q1-20",
      accessor: "q120"
    },

    {
      Header: "Change",
      accessor: "change"
    }
  ];

  var dummyData = [
    {name: "Revenue", q419: 300, q120: 350  , change: null},
    {name: "Cost", q419: 180, q120: 210  , change: null},
    {name: "Margin", q419: 120, q120: 140  , change: null},
    {name: "Overhead Costs", q419: 90, q120: 90  , change: null},
    {name: "Net Margin", q419: 30, q120: 50  , change: null},
    {name: "Add back : non cash charge", q419: 30, q120: 30  , change: null},
    {name: "EBITDA - 20%", q419: 60, q120: 80  , change: 20},
    {name: "AR - 60 days", q419: 200, q120: 230  , change: 30},
    {name: "AP - 30 days", q419: 60, q120: 70  , change: 10},
    {name: "Inventory - 2 turns", q419: 360, q120: 340  , change: 20},
  ];

  return (
      <ReactTable
          columns={columns}
          data={dummyData}
          minRows={0}
          showPagination={false}
          className="-striped -highlight simpleCustomTable"
      />
  )
}

export default Table;
