import React from "react";
import PieMenu, { Slice, PieCenter } from "react-pie-menu";
import AddIcon from '@material-ui/icons/Add';
import TableIcon from '@material-ui/icons/TableChart';
import ColumnIcon from '@material-ui/icons/InsertChart';
import LineIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PieIcon from '@material-ui/icons/PieChart';
import WaterfallIcon from '@material-ui/icons/Assessment';
import ViewListIcon from '@material-ui/icons/ViewList';
import Timeline from "@material-ui/icons/Timeline";


export default function Menu(props){
  // const menuArray = ['Bar', 'Line', 'Waterfall'];
  const menuArray = [
    {name: 'Bar', icon : <BarChartIcon className="bar-icon"/>},
    {name: 'Line', icon : <LineIcon />},
    {name: 'Column', icon : <BarChartIcon />},
  ]
  const [showMenu, setShowMenu] = React.useState(false);

  const captureStartPosition = () => {
    setShowMenu(true)
  }

  const clearPositions = () => {
    setShowMenu(false)
  }

 	const Center = props => (
	  <PieCenter {...props}>
	  	<AddIcon className="icon-plus" onMouseOver={captureStartPosition}/>
	  </PieCenter>
	);


  return (
    <div className="App text-center circle-menu" onClick={clearPositions}>

      <PieMenu Center={Center}>
        {/* Contents */}
        {/* <Slice><div className="menu-title">Table  <TableIcon/></div></Slice> */}
        {/* <Slice><div className="menu-title">Column <ColumnIcon/></div></Slice> */}
        {/* <Slice><div className="menu-title">Bar <BarIcon/></div></Slice> */}
        
          {/* {showMenu && (
            <Slice onSelect= {() => props.handleCreateChart(props.tabId)}><div className="menu-title">Line  <LineIcon/> </div></Slice>
          )} */}
          {/* {showMenu && ( */}
            {menuArray && menuArray.length && menuArray.map( value => {
              return <Slice onSelect= {() => props.handleCreateChart(props.tabId, `${value.name}`)} ><div className="menu-title">{value.name}{value.icon}</div></Slice>
            })}
          {/* )} */}
        
        {/* <Slice><div className="menu-title">Waterfall <WaterfallIcon/></div></Slice> */}
        {/* <Slice><div className="menu-title">Pie <PieIcon/></div></Slice> */}
        {/* <Slice><div className="menu-title">List<ViewListIcon/></div></Slice> */}
        
      </PieMenu>
    </div>
  );
}
