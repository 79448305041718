const reducer = (state = {
   uploadFile: false,
   data: {},
   error: false
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {

      case 'CHAT_UPLOAD_FILE_SUCCESS':
         newState.uploadFile = true;
         newState.data = action.data;
         return newState;

      case 'CHAT_UPLOAD_FILE_TOGGLE':
         newState.uploadFile = false;
         return newState;

      case 'CHAT_DOWNLOAD_FILE_SUCCESS':
         newState.loading = action.loading;
         newState.data = action.data;
         return newState;

      default:
         return newState;
   }
};
export default reducer;

