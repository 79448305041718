import React from 'react';
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";
import ReactTable from 'react-table-6';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import NewTableComponent from './newTable'

export class DetailFormComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            topRadioSelectorValue: '-1',
        }
    }

    componentDidMount() {
        this.props.fetchDetailPlanningSalesVolume({})
    }

    handleTopRadioSelectorValueChange = (event) => {
        this.setState({
            topRadioSelectorValue: event.target.value
        })

        switch (event.target.value) {
            case '0':
                this.props.fetchDetailPlanningNetSales({})
                break;
            case '1':
                this.props.fetchDetailPlanningDistCost({})
                break;
            case '2':
                this.props.fetchDetailPlanningVarCostClinker({})
                break;
            case '3':
                this.props.fetchDetailPlanningVarCostCement({})
                break;
            case '4':
                this.props.fetchDetailPlanningFixedCost({})
                break;
            case '5':
                this.props.fetchDetailPlanningEbitdaAin000s({})
                break;
            case '6':
                this.props.fetchDetailPlanningEbitdaPert({})
                break;
            default: //-1
                this.props.fetchDetailPlanningSalesVolume({})
                break;
        }
    }

    
    render() {
        const {topRadioSelectorValue} = this.state;
        const {detailDatastate} = this.props;
        
        const detailData = detailDatastate ? detailDatastate : {
            loading: undefined,
            rowHeader:[
                {"key": "data_field","label": "","operation": 0},
                // {"key": "actual_10","label": "10-Actual","operation": 0},
                // {"key": "frcst_20","label": "20-Forecast","operation": 0},
                // {"key": "total_10_20","label": "Total","operation": 0},
                // {"key": "actual_20","label": "20-Actual","operation": 0},
                // {"key": "frcst_10","label": "10-Forecast","operation": 0},
                // {"key": "total_20_10","label": "Total","operation": 0},  
                // {"key": "actual_30","label": "30-Actual","operation": 0},
                // {"key": "budget","label": "Budget","operation": 0},
            ],
            rowData: [
                // {data_field: 'Gap to Sales Volume', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'Gap to Sales EBITDA', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'Production Mix Impact', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'WIP Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'Sales Mix Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'FG Inventory', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
                // {data_field: 'Improvement initiatives', actual_10:'',frcst_20:'', actual_20:'', total_10_20:'',frcst_10:'',total_20_10:'', actual_30:'',budget:''    },
            ]
        }

        return (
            <React.Fragment>
                <div className='dayrepots-100k-120'>
                    <Grid container xs={12} sm={12} className="pb-15 buttons-section">
                        <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={topRadioSelectorValue} onChange={this.handleTopRadioSelectorValueChange} className='radioGroupHorizontal'>
                                    <div className="group">
                                        <FormControlLabel value={'-1'} control={<Radio color="primary" />} label="Sales Volume" />
                                        <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Net Sales" />
                                        <FormControlLabel value={'1'} control={<Radio color="primary" />} label="Distribution Cost" />
                                        <FormControlLabel value={'2'} control={<Radio color="primary" />} label="Variable Cost Clinker" />
                                        <FormControlLabel value={'3'} control={<Radio color="primary" />} label="Variable Cost Cement" />
                                        <FormControlLabel value={'4'} control={<Radio color="primary" />} label="Fixed Cost" />
                                        <FormControlLabel value={'5'} control={<Radio color="primary" />} label="EBITDA in 000S" />
                                        <FormControlLabel value={'6'} control={<Radio color="primary" />} label="EBITDA Per T" />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                    
                    {topRadioSelectorValue == -1 && <NewTableComponent 
                        // className='newNewCustomTable fixedFirstColumTable expandableRows custom-borders' 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 0 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 1 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 2 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 3 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 4 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 5 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}
                    {topRadioSelectorValue == 6 && <NewTableComponent 
                        className='planningCustomTable fixedFirstSixColumTable custom-borders' 
                        tableData={detailData}/>}

                </div>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                detailDatastate: state.functionalPlanningState.detailDataPlanning,
            }
        },
        dispatch => {
            return {
                fetchDetailPlanningSalesVolume(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_SALES_VOLUME',
                      payload: data,
                    });
                  },
                fetchDetailPlanningNetSales(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_NET_SALES',
                      payload: data,
                    });
                  },
                fetchDetailPlanningDistCost(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_DIST_COST',
                      payload: data,
                    });
                  },
                fetchDetailPlanningVarCostClinker(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_VAR_COST_CLINKER',
                      payload: data,
                    });
                  },
                fetchDetailPlanningVarCostCement(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_VAR_COST_CEMENT',
                      payload: data,
                    });
                  },

                fetchDetailPlanningFixedCost(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_FIXED_COST',
                      payload: data,
                    });
                  },
                fetchDetailPlanningEbitdaAin000s(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_EBITDA_AIN000S',
                      payload: data,
                    });
                  },
                fetchDetailPlanningEbitdaPert(data) {
                    dispatch({
                      type: 'FETCH_DETAIL_PLANNING_EBITDA_PERT',
                      payload: data,
                    });
                  },
            };
        }
    )(DetailFormComponent);
