import React from "react";
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";
import DownloadExcel from "../../../images/download_excel.svg";
// import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
// import { getAccessByMenuKey } from '../../../../utils/userManagement';
// import './index.scss';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import FormControl from "@material-ui/core/FormControl";
//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";
import Button from '@material-ui/core/Button';
import TableComponent from '../TableComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ComparePlansComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tableHeaderData: [],
            tableRowData: [],

            planArr: [],
            planOneArr: [],
            planTwoArr: [],
            planOneValue: {"key": "", "label": ""},
            planTwoValue: {"key": "", "label": ""},
        };
    }

    componentDidMount() {
		this.props.getDDLPlanModels({});
	}

    componentDidUpdate(prevProps) {
        const {loading, rowData} = this.props.kpiPlanningDatastate;
        if (prevProps.kpiPlanningDatastate.loading && !loading) {
           if (!loading && rowData && rowData.length > 0) {
                const planArr = rowData.map((val)=> {
                    return {"key": val.id, "label": val.name + " - " + val.version};
                })
                this.setState({planArr: planArr,planOneArr: planArr, planTwoArr: planArr});
            }
        }

        if (prevProps.kpiPlanningCompareState.loading && !this.props.kpiPlanningCompareState.loading) {
            this.setState({
                tableHeaderData : this.props.kpiPlanningCompareState.rowHeader,
                tableRowData : this.props.kpiPlanningCompareState.rowData,
                loading : this.props.kpiPlanningCompareState.loading,
            });
        }
    }

    setPlanOneValue(val) {
        this.setState({
            planTwoArr: this.state.planArr.filter(x=>{ return x.key !== val.key }),
            planOneValue: val
        });
    }

    setPlanTwoValue(val) {
        this.setState({
            planOneArr: this.state.planArr.filter(x=>{ return x.key !== val.key }),
            planTwoValue: val});
    }

    comparePlans() {
        const { planOneValue, planTwoValue } = this.state;
        const kplanOneValue = planOneValue.key;
        const kplanTwoValue = planTwoValue.key;
        if (kplanOneValue !== "" && kplanTwoValue !== "") {
            let payload = {
                "first_id": kplanOneValue,
                "second_id": kplanTwoValue,
                "export": false
            }
            this.props.getKpiComparePlanning(payload);
        }
    }

    render() {
        const {tableHeaderData, tableRowData, loading, planOneArr, planTwoArr, planOneValue, planTwoValue } = this.state;

        const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder }) => {
            return (
                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <Autocomplete
                        className = 'date-selection new-autocomplete'
                        fullWidth
                        disablePortal
                        disableListWrap
                        size='small'
                        value={selectedValue}
                        options={menuItems}
                        onChange={handleChange}
                        getOptionLabel={(option)=> option.label}
                        renderInput={(params) => (
                            <TextField
                                className="test-input-box"
                                {...params}
                                variant="standard"
                                label = {inputLabel}
                                placeholder={placeHolder}
                            />
                            )}                                                       
                    />
                </FormControl>
            );
        };

        const body = (
            <React.Fragment>        
            <Grid container xs={12} sm={12} justifyContent='left' spacing={2}>
                <Grid item xs={12} sm={12}>
                    <div className='top-header'>
                        <div>
                            <b>Select Plans To Compare</b>
                        </div>
                    </div>

                    <Grid container className="dropdown-filter">
                        <Grid item xs={4} className="maxw-200 mr-10">
                            <SelectDropdown
                                    menuItems={planOneArr}
                                    handleChange={(e, val) => this.setPlanOneValue(val)}
                                    inputLabel={'Plan 1'}
                                    selectedValue={planOneValue}
                                    placeHolder={'Select Plan 1'}
                                />
                        </Grid>
                        <Grid item xs={4} className="maxw-200 mr-10">
                            <SelectDropdown
                                    menuItems={planTwoArr}
                                    handleChange={(e, val) => this.setPlanTwoValue(val)}
                                    inputLabel={'Plan 2'}
                                    selectedValue={planTwoValue}
                                    placeHolder={'Select Plan 2'}
                                />
                        </Grid>
                        <Button className="newButton btnBlue mr-10"
                            variant="outlined" color="primary"
                            onClick={this.comparePlans.bind(this)}
                            >
                            Compare
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className='planningModelsTable-container'>
                    { loading ? <div className='progressfullpage'><CircularProgress /></div> : ''}
                    <TableComponent
                        rowHeader={tableHeaderData}
                        rowData={tableRowData}
                        loading={loading}
                    />
                </Grid>
            </Grid>
              
            </React.Fragment>
        )

        return body;
    }
}

export default
    connect(
        state => {
            return {
                kpiPlanningDatastate: state.functionalPlanningState.kpiDataPlanningModels,
                kpiPlanningCompareState: state.functionalPlanningState.KPIDataCompare
            }
        },
        dispatch => {
            return {
                getDDLPlanModels(data) {
                    dispatch({
                        type: "FETCH_KPI_PLANNING_MODELS",
                        payload: data,
                    })
                },
                getKpiComparePlanning(data) {
                    dispatch({
                        type: "FETCH_KPI_COMPARE_PLANNING",
                        payload: data,
                    })
                },
            };
        }
    )(ComparePlansComponent);