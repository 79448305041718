import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleTableTransform } from '../../../../utils/numberUtils'
import { checkToOpenInvoiceDetail } from '../utils'
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NewComponent from './../NewComponent'
export class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.sortList = {}
        this.headerColorArr = ['white', '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']
        this.headerColorArrNew = ['white', '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', '#c2c2a3', 'white']
        this.state = {
            seriesData: [],
            loading: true,
            showInvoiceDetailModal: false,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: '',
            invoicePayload: null,
            radioValue : "summary",
        }
    }

    componentDidMount() {
        if (this.props.customerNameSelected && this.props.customerNameSelected.length == 0){
            let payload = {
                receivableTabSelectorValue: this.props.receivableTabSelectorValue
            }
            this.props.getSummaryDetail(payload)
        }
    }

    businessUnitClick = (data, type, rowData) => {
        const { summaryState } = this.props;
        var headerData = []
        if (summaryState.internalData &&
            summaryState.internalData.row_header &&
            summaryState.internalData.row_header.length
        ) {
            var _data = [...[], ...summaryState.internalData.row_header]
            headerData = _data.filter(item => {
                if (!(item.key == "business_unit" || item.key == "grand_total")) {
                    return item
                }
            })
        }
        headerData = this.changeKeysOfHeader(headerData)
        this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
    }

    appendHyperLinkRowData = (item, type, props) => {
        if (type == 'recievables') {
            return (
                <span>
                    {props.original['business_unit_label']}
                </span>
            )
        }

        return (
            <span
                className='pointer primary-dark'
                onClick={() => { this.businessUnitClick(props.original[item.key], type, props.original) }}>
                <u>{props.original['business_unit_label']}</u>
            </span>)

    }

    changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    item.key == 'business_unit' ?
                                        props.original[item.key] == "Grand Total" ?
                                            <span><b>{props.original[item.key]}</b></span> :
                                            <>{this.appendHyperLinkRowData(item, type, props)}</> :
                                        <span>{handleTableTransform(props.original, item)}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : ''
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        backgroundColor: this.props.receivableTabSelectorValue ==1 ? this.headerColorArrNew[i] : this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    handleColumnClick = (state, colInfo, columnDetail, etc) => {
        console.log(colInfo, '>>>>', columnDetail)
        const { business_unit, parent_group } = colInfo.original;
        const payload = {
            aging_category: columnDetail.id,
            parent_group,
            bu: business_unit
        }
        // if (columnDetail.id != 'grand_total' && business_unit != "Grand Total") {
        //     if (checkToOpenInvoiceDetail(columnDetail.id)) {
        //         this.props.getInvoiceDetail(payload);
        //         this.setState({
        //             showInvoiceDetailModal: true,
        //             invoicePayload: payload

        //         })
        //     }
        // }


    }

    columnClickFunction = (state, colInfo, abc, etc) => {
        if (colInfo) {
            return {
                onClick: (e) => {
                    this.handleColumnClick(state, colInfo, abc, etc)
                }
            }
        } else {
            return {}
        }
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    changeValuesOfData = (data, type) => {
        if (data && data.length) {
            return data.map(item => {
                item['parent_group'] = type;
                return item
            })
        }
        return []
    }


    handleInvoiceSorting = (columnId) => {
        const { invoicePayload } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoicePayload
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }
    handleRadioChange = (event) => {
        // let newFilterPayload = {
        //   action_owner: [],
        //   aging_category: [],
        //   yearmonth: [],
        //   company_code: [],
        //   bu: [],
        //   document_number: [],
        //   category: '',
        //   customer_name: [],
        //   skip: 0,
        //   limit: 10,
        //   data_type : event.target.value
        // };
        console.log( event.target.value, "cekVal")
        this.setState({ radioValue: event.target.value });
        this.props.radioHemisphareChanges(event.target.value)
         
    
    };
    handleRegionHeaderClick = (data) => {
        let payload = {
            region : data,
            type : "country",
            radioValue : "region"
        }
        this.setState({ radioValue: "region" });
        this.props.handleRegionHeaderClick(payload)
    }
    handleHemisphereHeaderClick = (data) => {
        let payload = {
            region : data,
            type : "region",
            radioValue : "region"
        }
        this.setState({ radioValue: "region" });
        this.props.handleRegionHeaderClick(payload)
    }
    render() {
        let { summaryState, invoiceDetailState, receivableTabSelectorValue } = this.props;
        const { showInvoiceDetailModal, radioValue } = this.state;
        let externalDataColumns = summaryState.externalData && summaryState.externalData.row_header ? this.changeKeysOfHeader(summaryState.externalData.row_header, 'external') : [];
        let externalDataList = summaryState.externalData && summaryState.externalData.row_data ? this.changeValuesOfData(summaryState.externalData.row_data, 'external') : [];
        let internalDataColumns = summaryState.internalData && summaryState.internalData.row_header ? this.changeKeysOfHeader(summaryState.internalData.row_header, 'slb') : [];
        let internalDataList = summaryState.internalData && summaryState.internalData.row_data ? this.changeValuesOfData(summaryState.internalData.row_data, 'slb') : [];
        let unbilledDataColumns = summaryState.unbilledData && summaryState.unbilledData.row_header ? this.changeKeysOfHeader(summaryState.unbilledData.row_header, 'unbilled') : [];
        let unbilledDataList = summaryState.unbilledData && summaryState.unbilledData.row_data ? this.changeValuesOfData(summaryState.unbilledData.row_data, 'unbilled') : [];
        let totalDataColumns = summaryState.totalData && summaryState.totalData.row_header ? this.changeKeysOfHeader(summaryState.totalData.row_header, 'recievables') : [];
        let totalDataList = summaryState.totalData && summaryState.totalData.row_data ? this.changeValuesOfData(summaryState.totalData.row_data, 'recievables') : [];
        

        let mainColumnHeader = externalDataColumns || internalDataColumns || unbilledDataColumns ||totalDataColumns;
        // let NewHead =  summaryState?.externalData?.row_header

        // let NewHeader = this.changeKeysOfHeader(NewHead, 'receivables');
        // let NewExternalDataColumns = this.changeKeysOfHeader(NewHead, 'external');
        // let NewUnbilledDataColumns = this.changeKeysOfHeader(NewHead, 'unbilled');
        // let NewTotalDataColumns = this.changeKeysOfHeader(NewHead, 'receivables');

        // let NewInternalDataList = internalDataList.map( x=>{
        //     x.contract_liability = 0;
        //     return x;
        // })
        // NewInternalDataList = this.changeValuesOfData(NewInternalDataList)

        // let  NewExternalDataList = externalDataList.map( x=>{
        //     x.contract_liability = 0;
        //     return x;
        // })
        // NewExternalDataList = this.changeValuesOfData(NewExternalDataList)

        // let NewUnbilledDataList = unbilledDataList.map( x=>{
        //     x.contract_liability = 0;
        //     return x;
        // })
        // NewUnbilledDataList = this.changeValuesOfData(NewUnbilledDataList);
        

        // let NewTotalDataList = unbilledDataList.map( x=>{
        //     x.contract_liability = 0;
        //     return x;
        // })
        // NewTotalDataList = this.changeValuesOfData(NewTotalDataList);


        return (
            <>
                {summaryState.loading ?
                    <div className='progressfullpage'><CircularProgress /></div> :
                    <div className='summaryPage'>
                        {this.props.receivableTabSelectorValue == 1 ?
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={radioValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
                                <FormControlLabel value="summary" control={<Radio color="primary" />} label="BU" />
                                <FormControlLabel value="hemisphere" control={<Radio color="primary" />} label="Hemisphere" />
                                <FormControlLabel value="region" control={<Radio color="primary" />} label="Region" />                                    
                                </RadioGroup>
                            </FormControl>
                        </React.Fragment>  : ""}
                        {radioValue == "summary" &&
                        <>
                        <div className='firstContainer-wrapper'>
                            <Grid container className='firstContainer'>
                                <Grid item xs={3} sm={2}></Grid>
                                <Grid item xs={6} sm={8} className='text-center'>
                                    <b>Overdue Days</b>
                                </Grid>
                                <Grid item xs={3} sm={2}></Grid>
                            </Grid>
                            <ReactTable
                                data={ internalDataList}
                                columns={ mainColumnHeader}
                                defaultPageSize={0}
                                NoDataComponent={() => null}
                                pageSizeOptions={[2, 4, 6]}
                                showPagination={false}
                            />
                        </div>

                        {externalDataList && externalDataList.length ?
                            <div>
                                <label className='title'>3rd Party Receivables</label>
                                <ReactTable
                                    TheadComponent={props => null}
                                    data={externalDataList}
                                    columns={externalDataColumns}
                                    defaultPageSize={5}
                                    pageSize={externalDataList.length}
                                    className="xpna-react-table -striped -highlight"
                                    pageSizeOptions={[2, 4, 6]}
                                    showPagination={false}
                                    getTdProps={this.columnClickFunction}
                                />
                            </div> : null}

                        {internalDataList && internalDataList.length ?
                        <div>
                            <label className='title'>SLB Receivables</label>
                            <ReactTable
                                TheadComponent={props => null}
                                data={ internalDataList}
                                columns={ internalDataColumns}
                                defaultPageSize={5}
                                pageSize={internalDataList.length}
                                className="xpna-react-table -striped -highlight"
                                pageSizeOptions={[2, 4, 6]}
                                showPagination={false}
                                getTdProps={this.columnClickFunction}
                            />

                        </div> : null} 

                        { unbilledDataList && unbilledDataList.length ? <div>
                            <label className='title'>Unbilled</label>
                            <ReactTable
                                TheadComponent={props => null}
                                data={unbilledDataList}
                                columns={unbilledDataColumns}
                                defaultPageSize={5}
                                pageSize={unbilledDataList.length}
                                className="xpna-react-table -striped -highlight"
                                pageSizeOptions={[2, 4, 6]}
                                showPagination={false}
                                getTdProps={this.columnClickFunction}
                            />
                        </div> : null}

                       { totalDataList && totalDataList.length ? <div>
                            <label className='title'>Total by BU</label>
                            <ReactTable
                                TheadComponent={props => null}
                                data={totalDataList}
                                columns={ totalDataColumns}
                                defaultPageSize={5}
                                pageSize={totalDataList.length}
                                className="xpna-react-table -striped -highlight"
                                pageSizeOptions={[2, 4, 6]}
                                showPagination={false}
                                getTdProps={this.columnClickFunction}
                            />
                        </div> : null}
                        </>}

                        {(radioValue == "hemisphere" || radioValue == "region") &&
                      
                         <NewComponent 
                            radioValue = {radioValue}
                            selectedMonthFilter = {this.props.selectedMonthFilter}
                            handleRegionHeaderClick = {this.handleRegionHeaderClick}
                            handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
                         />
                        }

                    </div>
                }
                {
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                    />
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                invoiceDetailState: state.invoiceDetailState
            }
        },
        dispatch => {
            return {
                getSummaryDetail(data) {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_DATA',
                        payload: data,
                    });
                },
                getInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_INVOICE_DETAIL',
                        payload: data
                    });
                }
            };
        }
    )(Summary);
