import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import PaymentGrid from "./PaymentGrid";
import '../../userManagement.scss'
import '../../../Automation/Automation.scss'
export class ARUploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <PaymentGrid></PaymentGrid>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                userState: state.userManagementState.userState,
                authReducer: state.authReducer,
            }
        },
        dispatch => {
            return {

            };
        }
    )(ARUploadComponent);