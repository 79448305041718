import React from 'react';
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartContainerSummary from "./chartContainerSummary"
import { numberWithCommas } from '../../../../utils/numberUtils'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import ModalUser from "./chartPopUp";
import ChartContainer from './chartContainer';
import ExitWithoutSaveModal from './exitWithoutSaveModal';
import DownloadExcel from "../../../../images/download_excel.svg";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

export class DSOComponent extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      externalTabValue: 0,
      chartDataDSOBUWise: [],
      chartDataUnbilled: [],
      dso_improvement:[],
      chartTitleDSOSummaryDefault: "DSO Summary",
      chartTitleDSOBUWiseDefault: " Receivables - Trending Graph <br/>All Activity",
      chartTitleDSOBUWise: "",
      chartTitleUnbilledDefault: " Receivables - Trending Graph <br/>All Activity",
      chartTitleUnbilled: "",
      GraphView: true,
      topTenCountry : true,
      filterChart : 'All',
      filterArr : [],
      monthFilterArr:[],
      GraphType: "gross",
      viewWidth: 0,
      showImprovementLineChart: true,
      showModal: false,
      oldValue: 0,
      status: false,
      tittleModal: "",
      chartTitleDSOSummary: "",
      dataForModal: [],
      chartDataDSOSummary: [],
      TableDataDSOSummary: [],
      HeaderDataDSOSummary: [],
      showExitModal: false,
      nextLocation: '',
      confirmedNavigation: false,
      firstLevelTabValue: null,
      firstLevelRadioValue: null,
      firstLevelSwitchValue: null,
      regionSelected : '',
      selectedMonth:'',
      hemisphereSelected: ''
    }
    this.reverseBUnitIndex = {
      0: "Summary",
      1: "BU 1",
      2: "BU 2",
      3: "BU 3",
      4: "BU 4",
    }
    this.reverseNewIndex = {
      0: "Summary",
      1: "New BU 1",
      2: "New BU 2",
      3: "Unallocated",
    }
    this.reverseBUnitIndexLabel = {
      0: "Summary",
      1: "BU 1",
      2: "BU 2",
      3: "BU 3",
      4: "BU 4",
    }

    this.renderEditable = this.renderEditable.bind(this);
    this.renderActionColumn = this.renderActionColumn.bind(this);
  }

  setTitleChart = (bu) => {
    const { chartTitleDSOSummaryDefault, GraphType, selectedMonth,showImprovementLineChart, filterChart } = this.state
    let chartTitleValue = chartTitleDSOSummaryDefault;
    let showImprovementLine = showImprovementLineChart;
    if(GraphType == 'region') {
      chartTitleValue = 'DSO by Region'
    }else if(GraphType == 'country'){
      chartTitleValue = 'DSO by Country'
    }

    if (selectedMonth && !chartTitleValue.includes('Summary')) {
      if(filterChart == [] || filterChart == ''){
        chartTitleValue = chartTitleValue + ' - ' + selectedMonth.split('-')[1] + '-'+ selectedMonth.split('-')[2]
      }else{
        chartTitleValue = 'DSO ' + filterChart
      }
    }
    

    if (GraphType == 'region' || GraphType == 'country') {
        if(filterChart == [] || filterChart == '' || filterChart == 'All' || filterChart == 'Top 10'){
          showImprovementLine = false
        }else {
          showImprovementLine = true
        }
      }


    this.setState({
      chartTitleDSOSummary: chartTitleValue,
      chartTitleUnbilled: "Unbilled - as % of Receivable",
      chartTitleDSOBUWise: "DSO",
      showImprovementLineChart: showImprovementLine
    })
  }

  handleExternalTabChange = (event, newValue) => {
    console.log("cekMasukSini", newValue)
    // const updatedData =  false
    // if(this.props.tabSelectorValue == 1){
      console.log("cekMasukSini", newValue)
    //   updatedData = true
    // }else{
      const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
    // }
    if (!updatedData) {
      this.setState({ 
        externalTabValue: newValue, 
        status: false, 
        chartDataDSOSummary: [], 
        GraphType: 'gross', 
        showImprovementLineChart: true
      }, this.fetchChartData);
    } else {
      this.setState({ 
        showExitModal: true, 
        firstLevelTabValue: newValue, 
        chartDataDSOSummary: [], 
        showImprovementLineChart: true,
        GraphType: 'gross'
      })
    }
  };

  fetchChartData = () => {
    if (this.state.GraphType == 'gross') {
      let payload = {}
      // payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      if( this.props.tabSelectorValue == 0 ){
        payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      }else{
        payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
      }
      payload['receivablesTab'] =  this.props.tabSelectorValue
      payload['graphType'] = this.state.GraphType
      this.props.getDSOSummary(payload)
      this.setTitleChart(this.reverseBUnitIndexLabel[this.state.externalTabValue])
    } else if (this.state.GraphType == 'bu') {
      let payload = {}
      // payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      if( this.props.tabSelectorValue == 0 ){
        payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      }else{
        payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
      }
      payload['receivablesTab'] =  this.props.tabSelectorValue
      payload['graphType'] = this.state.GraphType
      this.props.getDSOBUWise(payload)
      this.props.getDSOBUWiseDSO(payload)
    } else if (this.state.GraphType == 'hemisphere' ||  this.state.GraphType == "region"  ||  this.state.GraphType == "country") {
      let payload = {}
      // payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      if( this.props.tabSelectorValue == 0 ){
        payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
      }else{
        payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
      }

      if(this.state.GraphType == "region" && this.state.filterChart != ""){
        payload['region'] = [this.state.filterChart]
      }else  if(this.state.GraphType == "country" && this.state.filterChart != ""){
        payload['country'] = [this.state.filterChart]
      }

      if(this.state.GraphType == "country"){
        payload['top_ten_country'] = this.state.topTenCountry
      }
      if(this.state.hemisphereSelected != ""){
        payload['hemisphere_table'] = this.state.hemisphereSelected
      }else  if(this.state.regionSelected != ""){
        payload['region_table'] = this.state.regionSelected
      }
      payload['receivablesTab'] =  this.props.tabSelectorValue
      payload['graphType'] = this.state.GraphType
      payload['topTenCountry'] = this.state.topTenCountry
      payload['monthFilter'] = this.state.selectedMonth
      this.props.getDSOSummary(payload)
      this.setTitleChart(this.reverseBUnitIndexLabel[this.state.externalTabValue])
    }
  }

  componentDidMount() {
    let payload = {}
    if( this.props.tabSelectorValue == 0 ){
      payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
    }else{
      payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
    }
    payload['receivablesTab'] =  this.props.tabSelectorValue
    payload['graphType'] = this.state.GraphType
    this.props.getDSOSummary(payload)
    this.setTitleChart("Summary")
  }

  refreshChart = () => {
    let newHeader = this.changeKeysOfHeader(this.props.dsoState.header)
    console.log(this.props.tabSelectorValue, this.state.GraphType , "cekHeader")
    if(this.props.tabSelectorValue == 1 && (this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country" ) ) {
      newHeader = this.changeKeysOfHeader2(this.props.dsoState.header)
     
    }
    console.log(this.props.dsoState.data, "cekHeader")
    this.setState({
      chartDataDSOSummary: this.props.dsoState.data.summary,
      dso_improvement: this.props.dsoState.data.dso_improvement,
      TableDataDSOSummary: this.props.dsoState.data.summaryTable,
      oldValue: JSON.parse(JSON.stringify(this.props.dsoState.data.summaryTable)),
      HeaderDataDSOSummary: newHeader,
      filterArr: this.props.dsoState.filter,
      monthFilterArr: this.props.dsoState.monthFilter,
      selectedMonth:this.props.dsoState.monthFilter && this.state.selectedMonth == '' ? this.props.dsoState.monthFilter[0] : this.state.selectedMonth,
      // filterChart:this.props.dsoState.filter && this.state.filterChart == [] ? this.props.dsoState.filter[0] : this.state.filterChart
    }, ()=>{
      this.setTitleChart('')
    })
  }

  refreshChartTable = () => {
    let payload = {}
    // payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
    if( this.props.tabSelectorValue == 0 ){
      payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
    }else{
      payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
    }
    payload['receivablesTab'] =  this.props.tabSelectorValue
    this.props.getDSOSummary(payload)
  }

  saveChanges = () => {
    let buSelect
    if( this.props.tabSelectorValue == 0 ){
      buSelect = this.reverseBUnitIndex[this.state.externalTabValue];
    }else{
      buSelect = this.reverseNewIndex[this.state.externalTabValue];
    }
    // payload['receivablesTab'] =  this.props.tabSelectorValue
    const dataToSend = this.state.TableDataDSOSummary
      .filter(x => x.netSalesedited || x.osedited)
      .map(res => {
        return {
          month: res.date,
          bu: buSelect,
          os: Number(res.os),
          revenue: Number(res.netSales)
        }
      })
    this.props.editData({ 'data_dso': [...dataToSend] })
  }

  columnClickFunction = (state, colInfo) => {
    if (colInfo) {
      return {
        onClick: (e) => {
          this.handleColumnClick(this.state.chartDataDSOSummary, colInfo)
        }
      }
    } else {
      return {}
    }
  }

  handleColumnClick = (state, colInfo) => {
    const { date, name, period } = colInfo.original;
    const data = this.state.chartDataDSOSummary
    const dataForModal = []
    const dateName = name.trimEnd()
    const dataPeriod = period?period.trimEnd():''
    data.map(function (d) {
      const dataDetail = d.data
      dataDetail.map(function (datay) {
        if (dataPeriod !== "" && (dateName == "East" || dateName == "West") && datay.hemisphere == dateName && datay.name == dataPeriod && datay.category !== 'DSO'){
          dataForModal.push(datay)
        } else if (datay.name == dateName && datay.category !== 'DSO') {
          dataForModal.push(datay)
        }
      })
    })
    this.setState({
      showModal: true,
      dataForModal: dataForModal,
      tittleModal: dateName
    })
  }

  modalCallBack = () => {
    this.setState({ showModal: false });
  };
  handleSaveSS = (data) => {
    let payload = {}
    payload['blob'] = data
    console.log(payload, "checkData")
  this.props.saveSS(payload)
  }
  handleDifferentBUResponse = (data, key) => {
    if (data) {
      if (key == 'data_field' || key == 'grand_total') {
        return data;
      } else {
        return data['header_key_value'] ? data['header_key_value'] : data
      }
    }
    return data
  }

  refreshChartBUWise = () => {
    this.setState({
      chartDataUnbilled: this.props.dsoState.data.buWise,
      dso_improvement: this.props.dsoState.data.dso_improvement,

    })
  }

  refreshChartDSOBUWise = () => {
    this.setState({
      chartDataDSOBUWise: this.props.dsoState.data.buWiseDSO,
      dso_improvement: this.props.dsoState.data.dso_improvement,
    })
  }
  changeKeysOfHeader2 = (data, isNested) => {
    if (data && data.length) {
        return data.map((item, i) => {
            console.log(item.key, isNested, "cekKeyss")

            if (!item.sub_headers) {
                var isManualKey = false;
               
                return {
                    Header: item.label,
                    id: item.key,
                    metaData: item.metadata,
                    getProps: (state, rowInfo, column) => {
                        if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                            return { style: { background: '#b1bace' } }
                        }
                        return {}
                    },
                    Cell: (props) => {
                      let calculate = true
                        if(item.key== "name" || item.key== "period"){
                          calculate = false
                        }
                        console.log(item.key, props.original[item.key], "cekKey")
                        return (
                            <div className='text-center'>
                                {
                                    <span>{props.original ? (calculate == true ?this.modifyColumnData(props.original[item.key]) : props.original[item.key] ): '-'}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor:  isManualKey == true ? 'pointer' : '',
                        borderRight: item.key != "dso" ? '1px solid #423970' : ''
                        // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                      overflow: 'visible',
                      fontSize: '13px',
                      fontWeight: 'bold',
                      borderRight: item.key != "dso" ? '1px solid #423970' : ''
                  }
                }
            }



            return {
                Header:  this.state.GraphType == "region" || this.state.GraphType == "hemisphere" ?<> <Tooltip title={this.handleHeaderTooltip()}>
                <span onClick={() => {
                       if (this.state.GraphType == "region"){
                           this.handleRegionHeaderClick(item.key)

                       }else if(this.state.GraphType == "hemisphere"){
                           this.handleHemisphereHeaderClick(item.key)

                       }
                      

                 

           }
           }
           
           >
              
                 {item.label}
               </span></Tooltip></> : item.label,
                id: item.key,
                columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
                style: {
                    borderRight: '1px solid #423970',
                   
                },
                headerStyle: {
                    overflow: 'visible',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    borderRight:'1px solid #423970',
                    cursor: this.state.GraphType == "region" || this.state.GraphType == "hemisphere" ? 'pointer' : ''
                }
            }
        })
    }
    return []
}
handleHeaderTooltip = () => {
  return 'Click here for the region view'
}
handleRegionHeaderClick = (value) => {
  this.setState({ regionSelected: value, GraphType: "country", hemisphereSelected: "" }, () => {
    this.fetchChartData()
  })
}
handleHemisphereHeaderClick = (value) => {
  console.log(value, "cekhemisphereclick")
  this.setState({ 
    hemisphereSelected: value, 
    regionSelected : "",
    GraphType: "region" 
  }, () => {
    this.fetchChartData()
  })
}
modifyColumnData = (columnData, operations) => {
  let modifiedData;
  modifiedData = Math.round(columnData)
  var isNegative = modifiedData < 0
  var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
  modifiedData = numberWithCommas(positiveTransformedValue)
  modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
  modifiedData = modifiedData == '0' ? '-' : modifiedData

  if (operations == "percentage") {
      modifiedData += "%"
  }

  return modifiedData
}


handleSubColumnHeader = (data) => {

  const {HeaderSummary} = this.props.dsoState
  let returnString = (
    <div>
        {data.label}
    </div>
)
  let modifiedData;
  if (HeaderSummary && HeaderSummary[data.key]) {
    modifiedData = Math.round(Math.round(HeaderSummary[data.key]))
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData

    returnString = ( 
      <div>
        <div>{data.label}</div>
        <div>{modifiedData}</div>
        
    </div>
    )
  }
 
  return returnString;
}

handleSubColumnChangeHeader = (data, parentData) => {
  console.log(data, parentData, "testHere123")
  if (data && data.length) {
      return data.map((item, i) => {
          var isManualKey = false;
          return {
              Header: this.handleSubColumnHeader(item),
              id: item.key,
              Cell: (props) => {
                  // let showPercentage = this.handleAppendPercentage(props.original);
                  // console.log(props.original.data_field, item.key, parentData.key, "cekData")
                  let myClassName = props.original.data_field == "Total Receivables" ? 'percentageRowData' : ''    
                  let classNames = ""
                  classNames = 'text-center'
                  isManualKey= false
                  return (
                      <div className={classNames} >
                          {
                              <span className={myClassName}>{props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? this.modifyColumnData(props.original.sub_column[parentData.key][item.key], props.original.sub_column[parentData.key]["operations"]) : '-'}</span>
                          }
                      </div>
                  )
              },
              style: {
                  fontSize: '12px',
                  borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                  cursor: isManualKey && isManualKey == true ? 'pointer' : ''
                  // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
              },
              headerStyle: {
                  overflow: 'visible',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  borderRight: i == data.length - 1 ? '1px solid #423970' : ''
              }
          }
      })
  }
}
  changeKeysOfHeader = (data) => {
    let tabValue = this.state.externalTabValue
    if (data && data.length) {
      data = data.map((item, i) => {
        let obj = {
          Header: item.label,
          id: item.key,
          accessor: item.key,
          headerStyle: {
            whiteSpace: "break-spaces",
            width: '50px !important',
            overflow: "visible",
            fontSize: '13px',
            fontWeight: 'bold',
            backgroundColor: 'white'
          },
          style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "hidden", fontSize: '13px', textAlign: 'center',pointerEvents: 'none', },
        }
        if (item.key == "netSales" || item.key == "os" || item.key == 'billed' || item.key == 'unbilled' || item.key == 'liability'  || item.key == 'New BU 1'  || item.key == 'Connected Products') {
          obj.style.pointerEvents = 'auto';
          obj.Cell = this.renderEditable
        }
        return obj
      })
      if (tabValue != 0) {
        data.push({
          Header: "Action",
          id: "action",
          accessor: "action",
          headerStyle: {
            whiteSpace: "break-spaces",
            width: '50px !important',
            overflow: "visible",
            fontSize: '13px',
            fontWeight: 'bold',
            backgroundColor: 'white'
          },
          Cell: this.renderActionColumn,
          style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "hidden", fontSize: '13px', textAlign: 'center' },
        })
      }
      return data
    }
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.state.TableDataDSOSummary];
    let split = (event.target.value).replace(/,/g, '');
    data[cellInfo.index][cellInfo.column.id] = split;
    if (data[cellInfo.index][cellInfo.column.id + "Reverted"] == true) {
      data[cellInfo.index][cellInfo.column.id + "Reverted"] = false
      this.setState({ status: true });
    }
    data[cellInfo.index][cellInfo.column.id + "edited"] = true
    this.props.updateExitWithoutSaveFlag(true)
    this.setState({ TableDataDSOSummary: data });
  };

  revertChanges = (cellInfo) => {
    let data = this.state.TableDataDSOSummary;
    let dataOld = this.state.oldValue;
    data[cellInfo.index]["netSales"] = dataOld[cellInfo.index]["netSales"]
    data[cellInfo.index]["os"] = dataOld[cellInfo.index]["os"]
    data[cellInfo.index]["netSalesReverted"] = true
    data[cellInfo.index]["netOSReverted"] = true
    data[cellInfo.index]["osedited"] = false
    data[cellInfo.index]["netSalesedited"] = false
    data[cellInfo.index]["osedited"] = false
    this.setState({ TableDataDSOSummary: data });

    const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
    if (!updatedData) {
      this.props.updateExitWithoutSaveFlag(false)
    }
  }

  handleOnFocus = (cellInfo, event) => {
    let data = this.state.TableDataDSOSummary;
    data[cellInfo.index][cellInfo.column.id + "onFocus"] = true;
    this.setState({ TableDataDSOSummary: data });
  };

  renderActionColumn = cellInfo => {
    const osOnFocus = this.state.TableDataDSOSummary[cellInfo.index]["osedited"]
    const netOnFocus = this.state.TableDataDSOSummary[cellInfo.index]["netSalesedited"]
    const onFocus = osOnFocus || netOnFocus
    return (
      <Tooltip title={"Revert changes"}>
        <Icon variant="contained" color={onFocus ? 'inherit' : 'disabled'} disabled={!onFocus} onClick={() => { this.revertChanges(cellInfo) }}>replay</Icon>
      </Tooltip>
    );
  };

  renderEditable = cellInfo => {
    console.log(this.state.TableDataDSOSummary, cellInfo.index, cellInfo.column.id, "cekRender")
    const cellValue = parseInt(this.state.TableDataDSOSummary[cellInfo.index][cellInfo.column.id]);
    const monthClicked = this.state.TableDataDSOSummary[cellInfo.index]["name"]
    let tabValue = this.state.externalTabValue
    var nf = new Intl.NumberFormat();
    const onFocus = this.state.TableDataDSOSummary[cellInfo.index][cellInfo.column.id + "onFocus"]
    let modifiedData;
    let columnData = cellValue
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0;
    var positiveTransformedValue = isNegative ?
    Math.abs(modifiedData) :
    modifiedData;
    modifiedData = numberWithCommas(positiveTransformedValue)

    modifiedData = isNegative ?
      '(' + modifiedData + ')' :
      modifiedData;
    modifiedData = modifiedData == '0' ? '' : modifiedData;

    if (tabValue == 0) {
      return (
        <div className={"dso-input"} >
          <span className='pointer' onClick={() => { this.columnClickFunction(this.state, cellInfo) }} >{modifiedData}</span>
        </div>
      );
    } else {
      return (
        <div className={"dso-input"} >
          <TextField
            placeholder="type here"
            name="input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            variant="outlined"
            size="small"
            onBlur={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
          />
        </div>
      );
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    if(prevState.selectedMonth == '' && this.state.selectedMonth == '' && this.state.monthFilterArr && this.state.monthFilterArr.length){
      this.setState(
        {selectedMonth: this.state.monthFilterArr[0]
        },() => {
          this.setTitleChart('')
        })
    }

    if (prevProps.dsoState.loading && !this.props.dsoState.loading){
      this.refreshChart()
      // this.setTitleChart('')
    }

    if (prevProps.tabSelectorValue != this.props.tabSelectorValue) {
      this.setState({
        GraphType: 'gross'
      }, this.fetchChartData)
    }

    if (prevProps.dsoState.saveLoading && !this.props.dsoState.saveLoading)
      this.refreshChartTable()

    if (prevProps.dsoState.buWiseLoading && !this.props.dsoState.buWiseLoading)
      this.refreshChartBUWise()

    if (prevProps.dsoState.buWiseDSOLoading && !this.props.dsoState.buWiseDSOLoading)
      this.refreshChartDSOBUWise()

    let myWidth = this.container.offsetWidth
    const { viewWidth } = this.state

    if ((myWidth - viewWidth) > 100 && !prevProps.dsoState.loading) { //refresh chart when the width change
      this.setState({ viewWidth: myWidth})
      if (viewWidth != 0) {
        this.fetchChartData()
      }
    }

    if (!prevProps.showExitWithoutSaveModal.showModal && this.props.showExitWithoutSaveModal.showModal) {
      this.setState({ showExitModal: true, nextLocation: this.props.showExitWithoutSaveModal.nextLocation })
    }

    if (prevState.GraphType !== this.state.GraphType) {
      this.refreshChartTable()
    }
  }
  handleChangeViewCountry = (event) => {
    // const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false

    // if (!updatedData) {
      this.setState({ topTenCountry: event.target.checked,chartDataDSOSummary: [], filterChart: '', }, this.fetchChartData);
    // } else {
    //   this.setState({ showExitModal: true, firstLevelSwitchValue: event.target.checked })
    // }
  };
  handleChangeView = (event) => {
    const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false

    if (!updatedData) {
      this.setState({ GraphView: event.target.checked }, this.refreshChart);
    } else {
      this.setState({ showExitModal: true, firstLevelSwitchValue: event.target.checked })
    }
  };

  handleComparativeRadioChange = (event) => {
    const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
    if (!updatedData) {
      this.setState({ GraphType: event.target.value, chartDataDSOSummary: [], filterChart: event.target.value == 'country' ? 'Top 10' : "All", hemisphereSelected: '', regionSelected : ''}, this.fetchChartData)
    } else {
      this.setState({ showExitModal: true, firstLevelRadioValue: event.target.value })
    }
  }

  handleDSOSave = (data) => {
    this.props.editData(data);
    this.setState({
      showModal: false,
      TableDataDSOSummary: this.props.dsoState.data.summaryTable,
    })
  }

  exitModalCallBack = (flag) => {
    const { firstLevelTabValue, firstLevelRadioValue, firstLevelSwitchValue } = this.state;
    if (flag) {
      this.saveChanges()
    }
    this.props.updateExitWithoutSaveFlag(false)
    this.setState({ showExitModal: false, confirmedNavigation: true }, () => {
      if (this.state.nextLocation.pathname) {
        this.props.history.push({ pathname: this.state.nextLocation.pathname })
      }
    })
    if (firstLevelTabValue !== null) {
      this.setState({ externalTabValue: firstLevelTabValue, status: false, GraphType: "gross", firstLevelTabValue: null }, this.fetchChartData)
    } else if (firstLevelRadioValue) {
      this.setState({ GraphType: firstLevelRadioValue, firstLevelRadioValue: null }, this.fetchChartData)
    } else if (firstLevelSwitchValue) {
      this.setState({ GraphView: firstLevelSwitchValue, firstLevelSwitchValue: null }, this.refreshChart)
    }
  }

  handleRouteChangeForUnsavedData = (nextLocation) => {
    const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
    if (!this.state.confirmedNavigation && updatedData) {
      this.setState({ showExitModal: true, nextLocation })
      return false
    }
    return true
  }

  handleExcelDownload = () => {
    let payload = {}
    // payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
    if( this.props.tabSelectorValue == 0 ){
      payload['bu'] = this.reverseBUnitIndex[this.state.externalTabValue];
    }else{
      payload['bu'] = this.reverseNewIndex[this.state.externalTabValue];
    }
    payload['receivablesTab'] =  this.props.tabSelectorValue
    payload['export'] = true;
    payload['graphType'] = this.state.GraphType
    this.props.getDSOSummary(payload)
  }
  handleChangeFilterChart = (event, value)  => {
    if (value) {
      // let arr = JSON.parse(JSON.stringify(this.state.filterChart))
      // arr.push(event.target.value)
      console.log(event.target.value, value, "cekRadio")
        this.setState({ filterChart: value },this.fetchChartData)
      
    }
}

handleMonthChange = (event, value) => {
  console.log('handleMonthChangehandleMonthChange', value, event.target.value)
  if (event.target.value) {
    this.setState({ selectedMonth: event.target.value },this.fetchChartData)
  }
}

  render() {
    const { externalTabValue, showExitModal, filterChart, filterArr, monthFilterArr, selectedMonth } = this.state
    const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
    console.log(this.props.tabSelectorValue, "cekcek")
    // let filterArr = ["ASIA PACIFIC","LAM","MEA","MEX","NAM","RUSSIA AND CASPIAN","SOUTH EUROPE / NA","UK AND NORTH EUROPE"]
    const body = (
      <>
        <Tabs
          ref={el => (this.container = el)}
          value={externalTabValue}
          indicatorColor="primary"
          textColor="primary"
          className='mb-10'
          onChange={this.handleExternalTabChange}
        >
           
          
          <LinkTab label="Summary" href="/summary" className="list-nested" {...a11yProps("sum")} />
          {this.props.tabSelectorValue == 0 && <LinkTab label="BU 1" href="/msm" className="list-nested" {...a11yProps(0)} />}
           {this.props.tabSelectorValue == 0  && <LinkTab label="BU 2" href="/lcs" className="list-nested" {...a11yProps(1)} />}
           {this.props.tabSelectorValue == 0   && <LinkTab label="BU 3" href="/dgs" className="list-nested" {...a11yProps(2)} />}
           {this.props.tabSelectorValue == 0   && <LinkTab label="BU 4" href="/pab" className="list-nested" {...a11yProps(3)} /> }
          
            {this.props.tabSelectorValue == 1  && <LinkTab label="New BU 1" href="/dass" className="list-nested" {...a11yProps(0)} />}
            {this.props.tabSelectorValue == 1  && <LinkTab label="New BU 2" href="/lcs" className="list-nested" {...a11yProps(1)} />}
            {this.props.tabSelectorValue == 1  && <LinkTab label="Unallocated" href="/unallocated" className="list-nested" {...a11yProps(2)} /> }
        
        </Tabs>
        <div className={'flex mb-10 ai-center jc-space-between'}>
          <FormControl>
            <RadioGroup name="radio" value={this.state.GraphType} onChange={this.handleComparativeRadioChange} className='radioGroupHorizontal'>
              {this.props.tabSelectorValue == 0  && <FormControlLabel value="gross" control={<Radio color="primary" />} label="DSO Trend Analysis" />}
              {this.props.tabSelectorValue == 0  &&  <FormControlLabel value="bu" control={<Radio color="primary" />} label="DSO Trend Details" />}

              {this.props.tabSelectorValue == 1  &&  <FormControlLabel value="gross" control={<Radio color="primary" />} label="DSO Summary" />}
              {this.props.tabSelectorValue == 1  && <FormControlLabel value="hemisphere" control={<Radio color="primary" />} label="DSO by Hemisphere" /> }
              {this.props.tabSelectorValue == 1  && <FormControlLabel value="region" control={<Radio color="primary" />} label="DSO by Region" /> }
              {this.props.tabSelectorValue == 1  && <FormControlLabel value="country" control={<Radio color="primary" />} label="DSO by Country" /> }
            </RadioGroup>

            {this.props.tabSelectorValue == 1  && (this.state.GraphType == "region" || this.state.GraphType == "country") && 
            <Grid item xs={9} sm={9} style={{marginBottom: '5px'}}>
              <FormControl className='w-200'>
                <Select
                  value={selectedMonth}
                  onChange={this.handleMonthChange}
                  MenuProps={{ onExited: () => document.activeElement.blur() }}
                >
                  {monthFilterArr && monthFilterArr.length &&
                    monthFilterArr.map((item, index) => {
                      return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
                
            </Grid> 
            
            
            
            }

            {this.props.tabSelectorValue == 1 && this.state.GraphView == true  && (this.state.GraphType == "region" || this.state.GraphType == "country") && 
            <Grid item xs={9} sm={9}>
              
                <FormControl variant="outlined" fullWidth >
                    {/* <InputLabel>{this.state.GraphType == "region" ? "Region" : "Country" }</InputLabel> */}
               
                   
                    <RadioGroup name="radio" value={filterChart} onChange={this.handleChangeFilterChart} className='blockRadio'>
                    {filterArr && filterArr.length && filterArr.map((item, i) => {
                            return <FormControlLabel value={item} control={<Radio color="primary" />} label={`${item}`}  checked={filterChart== item ? true : false}/>
                        })}
                      </RadioGroup>


                      {/* <Autocomplete
                            multiple
                            id="tags-standard"
                            options={filterArr ? filterArr : []}
                            defaultValue={filterChart}
                            getOptionSelected = {(option, val) => option == val}
                            getOptionLabel = {(option) => option}
                            onChange={this.handleChangeFilterChart}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={this.state.GraphType == "region" ? "Region" : "Country" }
                                placeholder={this.state.GraphType == "region" ? "Region" : "Country" }
                                />
                            )}
                            renderOption={(option) => {
                                return (
                                    <Typography style={{fontSize:'15px'}} noWrap >
                                        <span>{option}</span>
                                    </Typography> 
                                );
                              }}
                        />  */}
                </FormControl>
                {this.state.GraphType == "country"  ?
           <>
                <label>All</label>
                <Switch
                  className="custom-switch"
                  checked={this.state.topTenCountry}
                  onChange={this.handleChangeViewCountry}
                  color="primary"
                  name="toggleTableGraph"
                />
                <label>Top 10</label> </>: ""
            }
            </Grid> 
            
            
            
            }
           
          </FormControl>
         
          <div className='flex ai-center'>
         
            {this.state.GraphType == "gross" || this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country"  ?
              <div className='ml-20'>
                <label>Table</label>
                <Switch
                  className="custom-switch"
                  checked={this.state.GraphView}
                  onChange={this.handleChangeView}
                  color="primary"
                  name="toggleTableGraph"
                />
                <label>Graph</label>
                
              </div> : ""
            }
            {!this.state.GraphView && <Tooltip title={'Download to Excel'} className='pointer'>
              <img
                src={DownloadExcel}
                className='pointer ml-10'
                height='30'
                onClick={(event) => {
                  event.preventDefault();
                  this.handleExcelDownload();
                }} />
            </Tooltip>}
          </div>
        </div>
        <TabPanel value={externalTabValue} index={0}>
          {this.state.GraphType == "gross"  || this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country"  ?
            <ChartContainerSummary
              chartData={this.state.chartDataDSOSummary}
              chartTitle={this.state.chartTitleDSOSummary}
              showImprovementLineChart={this.state.showImprovementLineChart}
              tableData={this.state.TableDataDSOSummary}
              headerData={this.state.HeaderDataDSOSummary}
              graphView={this.state.GraphView}
              dso_improvement={this.state.dso_improvement}
              loading={this.props.dsoState.loading}
              topTenCountry={this.state.topTenCountry}
              clicked={this.columnClickFunction}
              saveCapture={this.handleSaveSS}
              externalTabValue={externalTabValue}
              graphType = {this.state.GraphType}
              receivablesTab = {this.props.tabSelectorValue} 
              handleRegionHeaderClick = {this.handleRegionHeaderClick}
              handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
            ></ChartContainerSummary>
            :
            <ChartContainer
              loading={this.props.dsoState.buWiseLoading}
              showImprovementLineChart={this.state.showImprovementLineChart}
              chartDataUnbilled={this.state.chartDataUnbilled}
              chartTitleUnbilled={this.state.chartTitleUnbilled}
              chartDataDSOBUWise={this.state.chartDataDSOBUWise}
              chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
              externalTabValue={externalTabValue}
            >
            </ChartContainer>
          }
        </TabPanel>
        <TabPanel value={externalTabValue} index={1}>
          {this.state.GraphType == "gross"  || this.state.GraphType == "hemisphere"  || this.state.GraphType == "region"    || this.state.GraphType == "country"  ?
            <ChartContainerSummary
              chartData={this.state.chartDataDSOSummary}
              chartTitle={this.state.chartTitleDSOSummary}
              tableData={this.state.TableDataDSOSummary}
              showImprovementLineChart={this.state.showImprovementLineChart}
              headerData={this.state.HeaderDataDSOSummary}
              graphView={this.state.GraphView}
              topTenCountry={this.state.topTenCountry}
              dso_improvement={this.state.dso_improvement}
              loading={this.props.dsoState.loading}
              externalTabValue={externalTabValue}
              saveTableChanges={this.saveChanges}
              graphType = {this.state.GraphType}
              receivablesTab = {this.props.tabSelectorValue} 
              handleRegionHeaderClick = {this.handleRegionHeaderClick}
              handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
            ></ChartContainerSummary>
            :
            <ChartContainer
              loading={this.props.dsoState.buWiseLoading}
              showImprovementLineChart={this.state.showImprovementLineChart}
              chartDataUnbilled={this.state.chartDataUnbilled}
              chartTitleUnbilled={this.state.chartTitleUnbilled}
              chartDataDSOBUWise={this.state.chartDataDSOBUWise}
              chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
              externalTabValue={externalTabValue}
            >
            </ChartContainer>
          }
        </TabPanel>
        <TabPanel value={externalTabValue} index={2}>
          {this.state.GraphType == "gross"  || this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country"   ?
            <ChartContainerSummary
              chartData={this.state.chartDataDSOSummary}
              chartTitle={this.state.chartTitleDSOSummary}
              tableData={this.state.TableDataDSOSummary}
              showImprovementLineChart={this.state.showImprovementLineChart}
              headerData={this.state.HeaderDataDSOSummary}
              dso_improvement={this.state.dso_improvement}
              topTenCountry={this.state.topTenCountry}
              graphView={this.state.GraphView}
              loading={this.props.dsoState.loading}
              externalTabValue={externalTabValue}
              saveTableChanges={this.saveChanges}
              graphType = {this.state.GraphType}
              receivablesTab = {this.props.tabSelectorValue} 
              handleRegionHeaderClick = {this.handleRegionHeaderClick}
              handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
            ></ChartContainerSummary>
            :
            <ChartContainer
              loading={this.props.dsoState.buWiseLoading}
              showImprovementLineChart={this.state.showImprovementLineChart}
              chartDataUnbilled={this.state.chartDataUnbilled}
              chartTitleUnbilled={this.state.chartTitleUnbilled}
              chartDataDSOBUWise={this.state.chartDataDSOBUWise}
              chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
              externalTabValue={externalTabValue}
            >
            </ChartContainer>
          }
        </TabPanel>
        <TabPanel value={externalTabValue} index={3}>
          {this.state.GraphType == "gross"  || this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country"   ?
            <ChartContainerSummary
              chartData={this.state.chartDataDSOSummary}
              chartTitle={this.state.chartTitleDSOSummary}
              tableData={this.state.TableDataDSOSummary}
              showImprovementLineChart={this.state.showImprovementLineChart}
              topTenCountry={this.state.topTenCountry}
              headerData={this.state.HeaderDataDSOSummary}
              graphView={this.state.GraphView}
              dso_improvement={this.state.dso_improvement}
              loading={this.props.dsoState.loading}
              externalTabValue={externalTabValue}
              saveTableChanges={this.saveChanges}
              graphType = {this.state.GraphType}
              receivablesTab = {this.props.tabSelectorValue} 
              handleRegionHeaderClick = {this.handleRegionHeaderClick}
              handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
            ></ChartContainerSummary>
            :
            <ChartContainer
              loading={this.props.dsoState.buWiseLoading}
              showImprovementLineChart={this.state.showImprovementLineChart}
              chartDataUnbilled={this.state.chartDataUnbilled}
              chartTitleUnbilled={this.state.chartTitleUnbilled}
              chartDataDSOBUWise={this.state.chartDataDSOBUWise}
              chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
              externalTabValue={externalTabValue}
            >
            </ChartContainer>
          }
        </TabPanel>
        <TabPanel value={externalTabValue} index={4}>
          {this.state.GraphType == "gross"  || this.state.GraphType == "hemisphere" || this.state.GraphType == "region"  || this.state.GraphType == "country"   ?
            <ChartContainerSummary
              chartData={this.state.chartDataDSOSummary}
              chartTitle={this.state.chartTitleDSOSummary}
              tableData={this.state.TableDataDSOSummary}
              showImprovementLineChart={this.state.showImprovementLineChart}
              headerData={this.state.HeaderDataDSOSummary}
              graphView={this.state.GraphView}
              dso_improvement={this.state.dso_improvement}
              loading={this.props.dsoState.loading}
              externalTabValue={externalTabValue}
              saveTableChanges={this.saveChanges}
              graphType = {this.state.GraphType}
              receivablesTab = {this.props.tabSelectorValue} 
              handleRegionHeaderClick = {this.handleRegionHeaderClick}
              handleHemisphereHeaderClick = {this.handleHemisphereHeaderClick}
            ></ChartContainerSummary>
            :
            <ChartContainer
              loading={this.props.dsoState.buWiseLoading}
              showImprovementLineChart={this.state.showImprovementLineChart}
              chartDataUnbilled={this.state.chartDataUnbilled}
              chartTitleUnbilled={this.state.chartTitleUnbilled}
              chartDataDSOBUWise={this.state.chartDataDSOBUWise}
              chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
              externalTabValue={externalTabValue}
            >
            </ChartContainer>
          }
        </TabPanel>
        <Prompt when={updatedData} message={this.handleRouteChangeForUnsavedData} />
        <ExitWithoutSaveModal showModal={showExitModal} exitModalCallBack={(flag) => this.exitModalCallBack(flag)}></ExitWithoutSaveModal>
      </>
    )
    return (
      <React.Fragment>
        {body}
        {
          <ModalUser
            showModal={this.state.showModal}
            modalCallBack={this.modalCallBack}
            data={this.state.dataForModal}
            tittleModal={this.state.tittleModal}
            saveCallback={this.handleDSOSave}
            loading={this.props.dsoState.saveLoading}
          />
        }
      </React.Fragment>
    );
  }
}
export default withRouter(
  connect(
    (state) => {
      return {
        dsoState: state.dashboardState.dashboardAnalytic.DSOAnalysis,
        showExitWithoutSaveModal: state.dashboardState.showExitWithoutSaveModal
      }
    },
    (dispatch) => {
      return {
        updateExitWithoutSaveFlag(data) {
          dispatch({
            type: "UPDATE_EXIT_WITHOUT_SAVE_CHECK",
            payload: data,
          });
        },
        updateExitWithoutSaveModalFlag(data) {
          dispatch({
            type: "SHOW_EXIT_WITHOUT_SAVE_MODAL",
            payload: data,
          });
        },
        getDSOSummary(data) {
          dispatch({
            type: "FETCH_DSO_CHART",
            payload: data,
          });
        },

        editData(data) {
          dispatch({
            type: "EDIT_DSO_DATA",
            payload: data,
          });
        },
        getDSOBUWise(data) {
          dispatch({
            type: "FETCH_DSO_BU_WISE_CHART",
            payload: data,
          });
        },
        getDSOBUWiseDSO(data) {
          dispatch({
            type: "FETCH_DSO_BU_WISE_DSO_CHART",
            payload: data,
          });
        },
        saveSS(data) {
          dispatch({
            type: "FETCH_SAVE_SS",
            payload: data,
          });
        },
      }
    }
  )(DSOComponent)
);