import axios from 'axios';
import {CONFIG} from '../../../../../config'


export async function fetchMonthlyStatusReportDataService(payload) {
    let apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/New/monthlystatusreport/`
    if (payload.export) {
       console.log("payload", payload)
        return axios({
            url: apiUrl,
            method: 'POST',
            responseType: 'blob',
            data: payload
        })
            .then(
                response => {
                  var x = response.data;
                  console.log(response, x, "cek1123")
                  var u = URL.createObjectURL(x)
                  var a = document.createElement("a");
                  a.href = u;
                  a.download = (response.headers['content-disposition']).split('=')[1];
                  a.click();
                  window.URL.revokeObjectURL(u);
                  
                    return response
                }
            )
            .catch(err => err.response)
           
    }
    return axios.post(apiUrl, payload)
    .then(response => response)
    .catch(err => err.response)
}

export async function saveActionOwnerMonthlyStatus(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/New/monthlystatusreport/action`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function getEmailTemplateMonthlyStatusActionService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/receivables/all/aging/New/email-monthlystatusreport`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function saveEmailTemplateMonthlyStatusService(payload) {
    return axios.put(`${CONFIG.api_base_url}v1/receivables/all/aging/New/email-monthlystatusreport`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function sendEmailMonthlyStatusService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/New/send-email-monthlystatusreport`, payload)
        .then(response => response)
        .catch(err => err.response)
}
