export const regionNames = {
	NAM: 'North America (NAM)',
	MEX: 'Mexico and Central America (MEX)',
	LAM: 'Latin America (LAM)',
	'ASIA PACIFIC': 'Asia and Pacific Region (APAC)',
	MEA: 'Middle East (MEA)',
	'SOUTH EUROPE / NA': 'South Europe and North Africa (SEA)',
	'UK AND NORTH EUROPE': 'UK and North Europe (UKNE)',
	'RUSSIA AND CASPIAN': 'Russia and Caspian region (RCA)',
};

export const countriesListByHemisphere = {
	West: [
		{
			_id: '6054dd13ee69734fbc68a8cc',
			country_code: 'CA',
			country_name: 'Canada',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ce',
			country_code: 'MX',
			country_name: 'Mexico',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8cf',
			country_code: 'GT',
			country_name: 'Guatemala',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d2',
			country_code: 'SV',
			country_name: 'El Salvador',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d3',
			country_code: 'BZ',
			country_name: 'Belize',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d6',
			country_code: 'EC',
			country_name: 'Ecuador',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d7',
			country_code: 'SR',
			country_name: 'Suriname',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8da',
			country_code: 'PY',
			country_name: 'Paraguay',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8db',
			country_code: 'GY',
			country_name: 'French guiana',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8de',
			country_code: 'BR',
			country_name: 'Brasil',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e0',
			country_code: 'PE',
			country_name: 'Peru',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e1',
			country_code: 'VE',
			country_name: 'Venezuela',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '60588a0b85d4bfd8b41a2dc9',
			country_code: 'LC',
			country_name: 'Saint Lucia',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8cd',
			country_code: 'US',
			country_name: 'US',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d0',
			country_code: 'HN',
			country_name: 'Honduras',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d1',
			country_code: 'NI',
			country_name: 'Nicaragua',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d4',
			country_code: 'CR',
			country_name: 'Costa rica',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d5',
			country_code: 'PA',
			country_name: 'Panama',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d8',
			country_code: 'GY',
			country_name: 'Guyana',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d9',
			country_code: 'UY',
			country_name: 'Uruguay',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8dc',
			country_code: 'CO',
			country_name: 'Colombia',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8dd',
			country_code: 'TT',
			country_name: 'Trinidad',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8df',
			country_code: 'BO',
			country_name: 'Bolivia',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e2',
			country_code: 'CL',
			country_name: 'Chile',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e3',
			country_code: 'AR',
			country_name: 'Argentina',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8c2',
			country_code: 'PR',
			country_name: 'Puerto Rico',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
	],
	East: [
		{
			_id: '6054dd13ee69734fbc68a8e4',
			country_code: 'IN',
			country_name: 'India',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e5',
			country_code: 'CN',
			country_name: 'China',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e8',
			country_code: 'KH',
			country_name: 'cambodia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e9',
			country_code: 'PG',
			country_name: 'Papua New Guinea',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ec',
			country_code: 'KR',
			country_name: 'Korea',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ed',
			country_code: 'TH',
			country_name: 'Thailand',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f0',
			country_code: 'SG',
			country_name: 'Singapore',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f1',
			country_code: 'AU',
			country_name: 'Australia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f4',
			country_code: 'VN',
			country_name: 'Vietnam',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f5',
			country_code: 'NZ',
			country_name: 'New Zealand',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f7',
			country_code: 'BN',
			country_name: 'Brunei',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f9',
			country_code: 'OM',
			country_name: 'Oman',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fa',
			country_code: 'KW',
			country_name: 'Kuwait',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fd',
			country_code: 'PK',
			country_name: 'Pakistan',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fe',
			country_code: 'YE',
			country_name: 'Yemen',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a901',
			country_code: 'QA',
			country_name: 'Qatar',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a902',
			country_code: 'AE',
			country_name: 'UAE',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a905',
			country_code: 'SS',
			country_name: 'South sudan',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a906',
			country_code: 'MZ',
			country_name: 'Mozambique',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a909',
			country_code: 'SI',
			country_name: 'slovenia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90a',
			country_code: 'RS',
			country_name: 'serbia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90d',
			country_code: 'XK',
			country_name: 'kosovo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90e',
			country_code: 'ME',
			country_name: 'montenegro',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a911',
			country_code: 'DE',
			country_name: 'Germany',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a912',
			country_code: 'HU',
			country_name: 'Hungary',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a915',
			country_code: 'AT',
			country_name: 'Austria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a916',
			country_code: 'LY',
			country_name: 'Libya',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a919',
			country_code: 'CH',
			country_name: 'Switzerland',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91a',
			country_code: 'CZ',
			country_name: 'Czech Rep',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91d',
			country_code: 'PL',
			country_name: 'Poland',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91e',
			country_code: 'HR',
			country_name: 'Croatia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a921',
			country_code: 'LT',
			country_name: 'Lithuania',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a922',
			country_code: 'LV',
			country_name: 'Latvia',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a925',
			country_code: 'GR',
			country_name: 'Greece',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a926',
			country_code: 'TN',
			country_name: 'Tunisia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a929',
			country_code: 'DZ',
			country_name: 'Algeria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92a',
			country_code: 'EG',
			country_name: 'Egypt',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92d',
			country_code: 'CM',
			country_name: 'Cameroon',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92e',
			country_code: 'CG',
			country_name: 'Congo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a931',
			country_code: 'TD',
			country_name: 'Chad',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a932',
			country_code: 'GQ',
			country_name: 'Equatorial Guinea',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a935',
			country_code: 'IE',
			country_name: 'Ireland',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a936',
			country_code: 'FI',
			country_name: 'Finland',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a939',
			country_code: 'NO',
			country_name: 'Norway',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93a',
			country_code: 'NG',
			country_name: 'Nigeria',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93d',
			country_code: 'ZA',
			country_name: 'South Africa',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93e',
			country_code: 'TZ',
			country_name: 'Tanzania',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a941',
			country_code: 'AZ',
			country_name: 'Azerbaijan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a942',
			country_code: 'BY',
			country_name: 'Belarus',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a945',
			country_code: 'UZ',
			country_name: 'Uzbekistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a946',
			country_code: 'UA',
			country_name: 'Ukraine',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e6',
			country_code: 'PH',
			country_name: 'Philippines',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e7',
			country_code: 'LA',
			country_name: 'laos',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ea',
			country_code: 'MN',
			country_name: 'Mongolia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8eb',
			country_code: 'HK',
			country_name: 'Hong Kong',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ee',
			country_code: 'MY',
			country_name: 'Malaysia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ef',
			country_code: 'ID',
			country_name: 'Indonesia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f2',
			country_code: 'TW',
			country_name: 'Taiwan',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f3',
			country_code: 'JP',
			country_name: 'Japan',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f6',
			country_code: 'BD',
			country_name: 'Bangladesh',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f8',
			country_code: 'MM',
			country_name: 'Myanmar',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fb',
			country_code: 'OM',
			country_name: 'Oman',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fc',
			country_code: 'EG',
			country_name: 'Egypt',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ff',
			country_code: 'LB',
			country_name: 'Lebanon',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a900',
			country_code: 'BH',
			country_name: 'Bahrain',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a903',
			country_code: 'SA',
			country_name: 'Saudi Arabia',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a904',
			country_code: 'IQ',
			country_name: 'Iraq',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a907',
			country_code: 'IT',
			country_name: 'Italy',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a908',
			country_code: 'ES',
			country_name: 'Spain',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90b',
			country_code: 'MA',
			country_name: 'morocco',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90c',
			country_code: 'MD',
			country_name: 'moldova',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90f',
			country_code: 'MK',
			country_name: 'north macedonia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a910',
			country_code: 'BA',
			country_name: 'bosnia and herzegovina',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a913',
			country_code: 'BE',
			country_name: 'Belgium',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a914',
			country_code: 'ES',
			country_name: 'Spain',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a917',
			country_code: 'RO',
			country_name: 'Romania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a918',
			country_code: 'TR',
			country_name: 'Turkey',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91b',
			country_code: 'IL',
			country_name: 'Israel',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91c',
			country_code: 'SK',
			country_name: 'Slovakia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91f',
			country_code: 'SE',
			country_name: 'Sweden',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a920',
			country_code: 'EE',
			country_name: 'Estonia',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a923',
			country_code: 'AL',
			country_name: 'Albania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a924',
			country_code: 'BG',
			country_name: 'Bulgaria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a927',
			country_code: 'PT',
			country_name: 'Portugal',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a928',
			country_code: 'FR',
			country_name: 'France',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92b',
			country_code: 'LY',
			country_name: 'LIbya',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92c',
			country_code: 'AO',
			country_name: 'Angola',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92f',
			country_code: 'CD',
			country_name: 'DR Congo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a930',
			country_code: 'CI',
			country_name: 'Ivory Coast',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a933',
			country_code: 'GA',
			country_name: 'Gabon',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a934',
			country_code: 'DK',
			country_name: 'Denmark',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a937',
			country_code: 'NL',
			country_name: 'Netherlands',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a938',
			country_code: 'GB',
			country_name: 'UK',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93b',
			country_code: 'KE',
			country_name: 'Kenya',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93c',
			country_code: 'GH',
			country_name: 'Ghana',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93f',
			country_code: 'TM',
			country_name: 'Turkmenistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a940',
			country_code: 'GE',
			country_name: 'Georgia',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a943',
			country_code: 'KG',
			country_name: 'kyrgyzstan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a944',
			country_code: 'TJ',
			country_name: 'tajikistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a947',
			country_code: 'KZ',
			country_name: 'Kazakhstan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a948',
			country_code: 'RU',
			country_name: 'Russia',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6138a9107a6523e328f9530b',
			country_code: 'MR',
			country_name: 'Mauritania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
	],
};

export const countriesListByRegion = {
	NAM: [
		{
			_id: '6054dd13ee69734fbc68a8cc',
			country_code: 'CA',
			country_name: 'Canada',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '60588a0b85d4bfd8b41a2dc9',
			country_code: 'LC',
			country_name: 'Saint Lucia',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8cd',
			country_code: 'US',
			country_name: 'US',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8dd',
			country_code: 'TT',
			country_name: 'Trinidad',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8c2',
			country_code: 'PR',
			country_name: 'Puerto Rico',
			hemisphere: 'West',
			region: 'NAM',
			region_name: 'North America (NAM)',
		},
	],
	MEX: [
		{
			_id: '6054dd13ee69734fbc68a8ce',
			country_code: 'MX',
			country_name: 'Mexico',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8cf',
			country_code: 'GT',
			country_name: 'Guatemala',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d2',
			country_code: 'SV',
			country_name: 'El Salvador',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d3',
			country_code: 'BZ',
			country_name: 'Belize',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '606553e685d4bfd8b41a3555',
			bu: 'PAB',
			bu_group: 'RA-PAB',
			country_name: 'Mexico',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d0',
			country_code: 'HN',
			country_name: 'Honduras',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d1',
			country_code: 'NI',
			country_name: 'Nicaragua',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d4',
			country_code: 'CR',
			country_name: 'Costa rica',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d5',
			country_code: 'PA',
			country_name: 'Panama',
			hemisphere: 'West',
			region: 'MEX',
			region_name: 'Mexico and Central America (MEX)',
		},
	],
	LAM: [
		{
			_id: '6054dd13ee69734fbc68a8d6',
			country_code: 'EC',
			country_name: 'Ecuador',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d7',
			country_code: 'SR',
			country_name: 'Suriname',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8da',
			country_code: 'PY',
			country_name: 'Paraguay',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8db',
			country_code: 'GY',
			country_name: 'French guiana',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8de',
			country_code: 'BR',
			country_name: 'Brasil',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e0',
			country_code: 'PE',
			country_name: 'Peru',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e1',
			country_code: 'VE',
			country_name: 'Venezuela',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d8',
			country_code: 'GY',
			country_name: 'Guyana',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8d9',
			country_code: 'UY',
			country_name: 'Uruguay',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8dc',
			country_code: 'CO',
			country_name: 'Colombia',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8df',
			country_code: 'BO',
			country_name: 'Bolivia',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e2',
			country_code: 'CL',
			country_name: 'Chile',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e3',
			country_code: 'AR',
			country_name: 'Argentina',
			hemisphere: 'West',
			region: 'LAM',
			region_name: 'Latin America (LAM)',
		},
	],
	'ASIA PACIFIC': [
		{
			_id: '6054dd13ee69734fbc68a8e4',
			country_code: 'IN',
			country_name: 'India',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e5',
			country_code: 'CN',
			country_name: 'China',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e8',
			country_code: 'KH',
			country_name: 'cambodia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e9',
			country_code: 'PG',
			country_name: 'Papua New Guinea',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ec',
			country_code: 'KR',
			country_name: 'Korea',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ed',
			country_code: 'TH',
			country_name: 'Thailand',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f0',
			country_code: 'SG',
			country_name: 'Singapore',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f1',
			country_code: 'AU',
			country_name: 'Australia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f4',
			country_code: 'VN',
			country_name: 'Vietnam',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f5',
			country_code: 'NZ',
			country_name: 'New Zealand',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f7',
			country_code: 'BN',
			country_name: 'Brunei',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e6',
			country_code: 'PH',
			country_name: 'Philippines',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8e7',
			country_code: 'LA',
			country_name: 'laos',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ea',
			country_code: 'MN',
			country_name: 'Mongolia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8eb',
			country_code: 'HK',
			country_name: 'Hong Kong',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ee',
			country_code: 'MY',
			country_name: 'Malaysia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ef',
			country_code: 'ID',
			country_name: 'Indonesia',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f2',
			country_code: 'TW',
			country_name: 'Taiwan',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f3',
			country_code: 'JP',
			country_name: 'Japan',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f6',
			country_code: 'BD',
			country_name: 'Bangladesh',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
		{
			_id: '6054dd13ee69734fbc68a8f8',
			country_code: 'MM',
			country_name: 'Myanmar',
			hemisphere: 'East',
			region: 'ASIA PACIFIC',
			region_name: 'Asia and Pacific Region (APAC)',
		},
	],
	MEA: [
		{
			_id: '6054dd13ee69734fbc68a8f9',
			country_code: 'OM',
			country_name: 'Oman',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fa',
			country_code: 'KW',
			country_name: 'Kuwait',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fd',
			country_code: 'PK',
			country_name: 'Pakistan',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fe',
			country_code: 'YE',
			country_name: 'Yemen',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a901',
			country_code: 'QA',
			country_name: 'Qatar',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a902',
			country_code: 'AE',
			country_name: 'UAE',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a905',
			country_code: 'SS',
			country_name: 'South sudan',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a906',
			country_code: 'MZ',
			country_name: 'Mozambique',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fb',
			country_code: 'OM',
			country_name: 'Oman',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8fc',
			country_code: 'EG',
			country_name: 'Egypt',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a8ff',
			country_code: 'LB',
			country_name: 'Lebanon',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a900',
			country_code: 'BH',
			country_name: 'Bahrain',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a903',
			country_code: 'SA',
			country_name: 'Saudi Arabia',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a904',
			country_code: 'IQ',
			country_name: 'Iraq',
			hemisphere: 'East',
			region: 'MEA',
			region_name: 'Middle East (MEA)',
		},
	],
	'SOUTH EUROPE / NA': [
		{
			_id: '6054dd13ee69734fbc68a909',
			country_code: 'SI',
			country_name: 'slovenia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90a',
			country_code: 'RS',
			country_name: 'serbia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90d',
			country_code: 'XK',
			country_name: 'kosovo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90e',
			country_code: 'ME',
			country_name: 'montenegro',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a911',
			country_code: 'DE',
			country_name: 'Germany',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a912',
			country_code: 'HU',
			country_name: 'Hungary',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a915',
			country_code: 'AT',
			country_name: 'Austria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a916',
			country_code: 'LY',
			country_name: 'Libya',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a919',
			country_code: 'CH',
			country_name: 'Switzerland',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91a',
			country_code: 'CZ',
			country_name: 'Czech Rep',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91d',
			country_code: 'PL',
			country_name: 'Poland',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91e',
			country_code: 'HR',
			country_name: 'Croatia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a925',
			country_code: 'GR',
			country_name: 'Greece',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a926',
			country_code: 'TN',
			country_name: 'Tunisia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a929',
			country_code: 'DZ',
			country_name: 'Algeria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92a',
			country_code: 'EG',
			country_name: 'Egypt',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92d',
			country_code: 'CM',
			country_name: 'Cameroon',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92e',
			country_code: 'CG',
			country_name: 'Congo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a931',
			country_code: 'TD',
			country_name: 'Chad',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a932',
			country_code: 'GQ',
			country_name: 'Equatorial Guinea',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a907',
			country_code: 'IT',
			country_name: 'Italy',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a908',
			country_code: 'ES',
			country_name: 'Spain',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90b',
			country_code: 'MA',
			country_name: 'morocco',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90c',
			country_code: 'MD',
			country_name: 'moldova',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a90f',
			country_code: 'MK',
			country_name: 'north macedonia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a910',
			country_code: 'BA',
			country_name: 'bosnia and herzegovina',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a913',
			country_code: 'BE',
			country_name: 'Belgium',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a914',
			country_code: 'ES',
			country_name: 'Spain',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a917',
			country_code: 'RO',
			country_name: 'Romania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a918',
			country_code: 'TR',
			country_name: 'Turkey',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91b',
			country_code: 'IL',
			country_name: 'Israel',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a91c',
			country_code: 'SK',
			country_name: 'Slovakia',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a923',
			country_code: 'AL',
			country_name: 'Albania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a924',
			country_code: 'BG',
			country_name: 'Bulgaria',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a927',
			country_code: 'PT',
			country_name: 'Portugal',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a928',
			country_code: 'FR',
			country_name: 'France',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92b',
			country_code: 'LY',
			country_name: 'LIbya',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92c',
			country_code: 'AO',
			country_name: 'Angola',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a92f',
			country_code: 'CD',
			country_name: 'DR Congo',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a930',
			country_code: 'CI',
			country_name: 'Ivory Coast',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6054dd13ee69734fbc68a933',
			country_code: 'GA',
			country_name: 'Gabon',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
		{
			_id: '6138a9107a6523e328f9530b',
			country_code: 'MR',
			country_name: 'Mauritania',
			hemisphere: 'East',
			region: 'SOUTH EUROPE / NA',
			region_name: 'South Europe and North Africa (SEA)',
		},
	],
	'UK AND NORTH EUROPE': [
		{
			_id: '6054dd13ee69734fbc68a921',
			country_code: 'LT',
			country_name: 'Lithuania',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a922',
			country_code: 'LV',
			country_name: 'Latvia',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a935',
			country_code: 'IE',
			country_name: 'Ireland',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a936',
			country_code: 'FI',
			country_name: 'Finland',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a939',
			country_code: 'NO',
			country_name: 'Norway',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93a',
			country_code: 'NG',
			country_name: 'Nigeria',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93d',
			country_code: 'ZA',
			country_name: 'South Africa',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93e',
			country_code: 'TZ',
			country_name: 'Tanzania',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a91f',
			country_code: 'SE',
			country_name: 'Sweden',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a920',
			country_code: 'EE',
			country_name: 'Estonia',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a934',
			country_code: 'DK',
			country_name: 'Denmark',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a937',
			country_code: 'NL',
			country_name: 'Netherlands',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a938',
			country_code: 'GB',
			country_name: 'UK',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93b',
			country_code: 'KE',
			country_name: 'Kenya',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
		{
			_id: '6054dd13ee69734fbc68a93c',
			country_code: 'GH',
			country_name: 'Ghana',
			hemisphere: 'East',
			region: 'UK AND NORTH EUROPE',
			region_name: 'UK and North Europe (UKNE)',
		},
	],
	'RUSSIA AND CASPIAN': [
		{
			_id: '6054dd13ee69734fbc68a941',
			country_code: 'AZ',
			country_name: 'Azerbaijan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a942',
			country_code: 'BY',
			country_name: 'Belarus',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a945',
			country_code: 'UZ',
			country_name: 'Uzbekistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a946',
			country_code: 'UA',
			country_name: 'Ukraine',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a93f',
			country_code: 'TM',
			country_name: 'Turkmenistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a940',
			country_code: 'GE',
			country_name: 'Georgia',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a943',
			country_code: 'KG',
			country_name: 'kyrgyzstan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a944',
			country_code: 'TJ',
			country_name: 'tajikistan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a947',
			country_code: 'KZ',
			country_name: 'Kazakhstan',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
		{
			_id: '6054dd13ee69734fbc68a948',
			country_code: 'RU',
			country_name: 'Russia',
			hemisphere: 'East',
			region: 'RUSSIA AND CASPIAN',
			region_name: 'Russia and Caspian region (RCA)',
		},
	],
};

export const countriesData = {
	Albania: {
		country: 'Albania',
		alpha2: 'AL',
		alpha3: 'ALB',
		numeric: 8,
		latitude: 41,
		longitude: 20,
	},
	Algeria: {
		country: 'Algeria',
		alpha2: 'DZ',
		alpha3: 'DZA',
		numeric: 12,
		latitude: 28,
		longitude: 3,
	},
	'American Samoa': {
		country: 'American Samoa',
		alpha2: 'AS',
		alpha3: 'ASM',
		numeric: 16,
		latitude: -14.3333,
		longitude: -170,
	},
	Andorra: {
		country: 'Andorra',
		alpha2: 'AD',
		alpha3: 'AND',
		numeric: 20,
		latitude: 42.5,
		longitude: 1.6,
	},
	Angola: {
		country: 'Angola',
		alpha2: 'AO',
		alpha3: 'AGO',
		numeric: 24,
		latitude: -12.5,
		longitude: 18.5,
	},
	Anguilla: {
		country: 'Anguilla',
		alpha2: 'AI',
		alpha3: 'AIA',
		numeric: 660,
		latitude: 18.25,
		longitude: -63.1667,
	},
	Antarctica: {
		country: 'Antarctica',
		alpha2: 'AQ',
		alpha3: 'ATA',
		numeric: 10,
		latitude: -90,
		longitude: 0,
	},
	'Antigua and Barbuda': {
		country: 'Antigua and Barbuda',
		alpha2: 'AG',
		alpha3: 'ATG',
		numeric: 28,
		latitude: 17.05,
		longitude: -61.8,
	},
	Argentina: {
		country: 'Argentina',
		alpha2: 'AR',
		alpha3: 'ARG',
		numeric: 32,
		latitude: -34,
		longitude: -64,
	},
	Armenia: {
		country: 'Armenia',
		alpha2: 'AM',
		alpha3: 'ARM',
		numeric: 51,
		latitude: 40,
		longitude: 45,
	},
	Aruba: {
		country: 'Aruba',
		alpha2: 'AW',
		alpha3: 'ABW',
		numeric: 533,
		latitude: 12.5,
		longitude: -69.9667,
	},
	Australia: {
		country: 'Australia',
		alpha2: 'AU',
		alpha3: 'AUS',
		numeric: 36,
		latitude: -27,
		longitude: 133,
	},
	Austria: {
		country: 'Austria',
		alpha2: 'AT',
		alpha3: 'AUT',
		numeric: 40,
		latitude: 47.3333,
		longitude: 13.3333,
	},
	Azerbaijan: {
		country: 'Azerbaijan',
		alpha2: 'AZ',
		alpha3: 'AZE',
		numeric: 31,
		latitude: 40.5,
		longitude: 47.5,
	},
	Bahamas: {
		country: 'Bahamas',
		alpha2: 'BS',
		alpha3: 'BHS',
		numeric: 44,
		latitude: 24.25,
		longitude: -76,
	},
	Bahrain: {
		country: 'Bahrain',
		alpha2: 'BH',
		alpha3: 'BHR',
		numeric: 48,
		latitude: 26,
		longitude: 50.55,
	},
	Bangladesh: {
		country: 'Bangladesh',
		alpha2: 'BD',
		alpha3: 'BGD',
		numeric: 50,
		latitude: 24,
		longitude: 90,
	},
	Barbados: {
		country: 'Barbados',
		alpha2: 'BB',
		alpha3: 'BRB',
		numeric: 52,
		latitude: 13.1667,
		longitude: -59.5333,
	},
	Belarus: {
		country: 'Belarus',
		alpha2: 'BY',
		alpha3: 'BLR',
		numeric: 112,
		latitude: 53,
		longitude: 28,
	},
	Belgium: {
		country: 'Belgium',
		alpha2: 'BE',
		alpha3: 'BEL',
		numeric: 56,
		latitude: 50.8333,
		longitude: 4,
	},
	Belize: {
		country: 'Belize',
		alpha2: 'BZ',
		alpha3: 'BLZ',
		numeric: 84,
		latitude: 17.25,
		longitude: -88.75,
	},
	Benin: {
		country: 'Benin',
		alpha2: 'BJ',
		alpha3: 'BEN',
		numeric: 204,
		latitude: 9.5,
		longitude: 2.25,
	},
	Bermuda: {
		country: 'Bermuda',
		alpha2: 'BM',
		alpha3: 'BMU',
		numeric: 60,
		latitude: 32.3333,
		longitude: -64.75,
	},
	Bhutan: {
		country: 'Bhutan',
		alpha2: 'BT',
		alpha3: 'BTN',
		numeric: 64,
		latitude: 27.5,
		longitude: 90.5,
	},
	Bolivia: {
		country: 'Bolivia, Plurinational State of',
		alpha2: 'BO',
		alpha3: 'BOL',
		numeric: 68,
		latitude: -17,
		longitude: -65,
	},
	'Bosnia and Herzegovina': {
		country: 'Bosnia and Herzegovina',
		alpha2: 'BA',
		alpha3: 'BIH',
		numeric: 70,
		latitude: 44,
		longitude: 18,
	},
	Botswana: {
		country: 'Botswana',
		alpha2: 'BW',
		alpha3: 'BWA',
		numeric: 72,
		latitude: -22,
		longitude: 24,
	},
	'Bouvet Island': {
		country: 'Bouvet Island',
		alpha2: 'BV',
		alpha3: 'BVT',
		numeric: 74,
		latitude: -54.4333,
		longitude: 3.4,
	},
	Brazil: {
		country: 'Brazil',
		alpha2: 'BR',
		alpha3: 'BRA',
		numeric: 76,
		latitude: -10,
		longitude: -55,
	},
	'British Indian Ocean Territory': {
		country: 'British Indian Ocean Territory',
		alpha2: 'IO',
		alpha3: 'IOT',
		numeric: 86,
		latitude: -6,
		longitude: 71.5,
	},
	Brunei: {
		country: 'Brunei Darussalam',
		alpha2: 'BN',
		alpha3: 'BRN',
		numeric: 96,
		latitude: 4.5,
		longitude: 114.6667,
	},
	Bulgaria: {
		country: 'Bulgaria',
		alpha2: 'BG',
		alpha3: 'BGR',
		numeric: 100,
		latitude: 43,
		longitude: 25,
	},
	'Burkina Faso': {
		country: 'Burkina Faso',
		alpha2: 'BF',
		alpha3: 'BFA',
		numeric: 854,
		latitude: 13,
		longitude: -2,
	},
	Burundi: {
		country: 'Burundi',
		alpha2: 'BI',
		alpha3: 'BDI',
		numeric: 108,
		latitude: -3.5,
		longitude: 30,
	},
	Cambodia: {
		country: 'Cambodia',
		alpha2: 'KH',
		alpha3: 'KHM',
		numeric: 116,
		latitude: 13,
		longitude: 105,
	},
	Cameroon: {
		country: 'Cameroon',
		alpha2: 'CM',
		alpha3: 'CMR',
		numeric: 120,
		latitude: 6,
		longitude: 12,
	},
	Canada: {
		country: 'Canada',
		alpha2: 'CA',
		alpha3: 'CAN',
		numeric: 124,
		latitude: 60,
		longitude: -105,
	},
	'Cape Verde': {
		country: 'Cape Verde',
		alpha2: 'CV',
		alpha3: 'CPV',
		numeric: 132,
		latitude: 16,
		longitude: -24,
	},
	'Cayman Islands': {
		country: 'Cayman Islands',
		alpha2: 'KY',
		alpha3: 'CYM',
		numeric: 136,
		latitude: 19.5,
		longitude: -80.5,
	},
	'Central African Republic': {
		country: 'Central African Republic',
		alpha2: 'CF',
		alpha3: 'CAF',
		numeric: 140,
		latitude: 7,
		longitude: 21,
	},
	Chad: {
		country: 'Chad',
		alpha2: 'TD',
		alpha3: 'TCD',
		numeric: 148,
		latitude: 15,
		longitude: 19,
	},
	Chile: {
		country: 'Chile',
		alpha2: 'CL',
		alpha3: 'CHL',
		numeric: 152,
		latitude: -30,
		longitude: -71,
	},
	China: {
		country: 'China',
		alpha2: 'CN',
		alpha3: 'CHN',
		numeric: 156,
		latitude: 35,
		longitude: 105,
	},
	'Christmas Island': {
		country: 'Christmas Island',
		alpha2: 'CX',
		alpha3: 'CXR',
		numeric: 162,
		latitude: -10.5,
		longitude: 105.6667,
	},
	'Cocos (Keeling) Islands': {
		country: 'Cocos (Keeling) Islands',
		alpha2: 'CC',
		alpha3: 'CCK',
		numeric: 166,
		latitude: -12.5,
		longitude: 96.8333,
	},
	Colombia: {
		country: 'Colombia',
		alpha2: 'CO',
		alpha3: 'COL',
		numeric: 170,
		latitude: 4,
		longitude: -72,
	},
	Comoros: {
		country: 'Comoros',
		alpha2: 'KM',
		alpha3: 'COM',
		numeric: 174,
		latitude: -12.1667,
		longitude: 44.25,
	},
	Congo: {
		country: 'Congo',
		alpha2: 'CG',
		alpha3: 'COG',
		numeric: 178,
		latitude: -1,
		longitude: 15,
	},
	'Congo, the Democratic Republic of the': {
		country: 'Congo, the Democratic Republic of the',
		alpha2: 'CD',
		alpha3: 'COD',
		numeric: 180,
		latitude: 0,
		longitude: 25,
	},
	'Cook Islands': {
		country: 'Cook Islands',
		alpha2: 'CK',
		alpha3: 'COK',
		numeric: 184,
		latitude: -21.2333,
		longitude: -159.7667,
	},
	'Costa Rica': {
		country: 'Costa Rica',
		alpha2: 'CR',
		alpha3: 'CRI',
		numeric: 188,
		latitude: 10,
		longitude: -84,
	},
	"Côte d'Ivoire": {
		country: "Côte d'Ivoire",
		alpha2: 'CI',
		alpha3: 'CIV',
		numeric: 384,
		latitude: 8,
		longitude: -5,
	},
	Croatia: {
		country: 'Croatia',
		alpha2: 'HR',
		alpha3: 'HRV',
		numeric: 191,
		latitude: 45.1667,
		longitude: 15.5,
	},
	Cuba: {
		country: 'Cuba',
		alpha2: 'CU',
		alpha3: 'CUB',
		numeric: 192,
		latitude: 21.5,
		longitude: -80,
	},
	Cyprus: {
		country: 'Cyprus',
		alpha2: 'CY',
		alpha3: 'CYP',
		numeric: 196,
		latitude: 35,
		longitude: 33,
	},
	'Czech Republic': {
		country: 'Czech Republic',
		alpha2: 'CZ',
		alpha3: 'CZE',
		numeric: 203,
		latitude: 49.75,
		longitude: 15.5,
	},
	Denmark: {
		country: 'Denmark',
		alpha2: 'DK',
		alpha3: 'DNK',
		numeric: 208,
		latitude: 56,
		longitude: 10,
	},
	Djibouti: {
		country: 'Djibouti',
		alpha2: 'DJ',
		alpha3: 'DJI',
		numeric: 262,
		latitude: 11.5,
		longitude: 43,
	},
	Dominica: {
		country: 'Dominica',
		alpha2: 'DM',
		alpha3: 'DMA',
		numeric: 212,
		latitude: 15.4167,
		longitude: -61.3333,
	},
	'Dominican Republic': {
		country: 'Dominican Republic',
		alpha2: 'DO',
		alpha3: 'DOM',
		numeric: 214,
		latitude: 19,
		longitude: -70.6667,
	},
	Ecuador: {
		country: 'Ecuador',
		alpha2: 'EC',
		alpha3: 'ECU',
		numeric: 218,
		latitude: -2,
		longitude: -77.5,
	},
	Egypt: {
		country: 'Egypt',
		alpha2: 'EG',
		alpha3: 'EGY',
		numeric: 818,
		latitude: 27,
		longitude: 30,
	},
	'El Salvador': {
		country: 'El Salvador',
		alpha2: 'SV',
		alpha3: 'SLV',
		numeric: 222,
		latitude: 13.8333,
		longitude: -88.9167,
	},
	'Equatorial Guinea': {
		country: 'Equatorial Guinea',
		alpha2: 'GQ',
		alpha3: 'GNQ',
		numeric: 226,
		latitude: 2,
		longitude: 10,
	},
	Eritrea: {
		country: 'Eritrea',
		alpha2: 'ER',
		alpha3: 'ERI',
		numeric: 232,
		latitude: 15,
		longitude: 39,
	},
	Estonia: {
		country: 'Estonia',
		alpha2: 'EE',
		alpha3: 'EST',
		numeric: 233,
		latitude: 59,
		longitude: 26,
	},
	Ethiopia: {
		country: 'Ethiopia',
		alpha2: 'ET',
		alpha3: 'ETH',
		numeric: 231,
		latitude: 8,
		longitude: 38,
	},
	'Falkland Islands (Malvinas)': {
		country: 'Falkland Islands (Malvinas)',
		alpha2: 'FK',
		alpha3: 'FLK',
		numeric: 238,
		latitude: -51.75,
		longitude: -59,
	},
	'Faroe Islands': {
		country: 'Faroe Islands',
		alpha2: 'FO',
		alpha3: 'FRO',
		numeric: 234,
		latitude: 62,
		longitude: -7,
	},
	Fiji: {
		country: 'Fiji',
		alpha2: 'FJ',
		alpha3: 'FJI',
		numeric: 242,
		latitude: -18,
		longitude: 175,
	},
	Finland: {
		country: 'Finland',
		alpha2: 'FI',
		alpha3: 'FIN',
		numeric: 246,
		latitude: 64,
		longitude: 26,
	},
	France: {
		country: 'France',
		alpha2: 'FR',
		alpha3: 'FRA',
		numeric: 250,
		latitude: 46,
		longitude: 2,
	},
	'French Guiana': {
		country: 'French Guiana',
		alpha2: 'GF',
		alpha3: 'GUF',
		numeric: 254,
		latitude: 4,
		longitude: -53,
	},
	'French Polynesia': {
		country: 'French Polynesia',
		alpha2: 'PF',
		alpha3: 'PYF',
		numeric: 258,
		latitude: -15,
		longitude: -140,
	},
	'French Southern Territories': {
		country: 'French Southern Territories',
		alpha2: 'TF',
		alpha3: 'ATF',
		numeric: 260,
		latitude: -43,
		longitude: 67,
	},
	Gabon: {
		country: 'Gabon',
		alpha2: 'GA',
		alpha3: 'GAB',
		numeric: 266,
		latitude: -1,
		longitude: 11.75,
	},
	Gambia: {
		country: 'Gambia',
		alpha2: 'GM',
		alpha3: 'GMB',
		numeric: 270,
		latitude: 13.4667,
		longitude: -16.5667,
	},
	Georgia: {
		country: 'Georgia',
		alpha2: 'GE',
		alpha3: 'GEO',
		numeric: 268,
		latitude: 42,
		longitude: 43.5,
	},
	Germany: {
		country: 'Germany',
		alpha2: 'DE',
		alpha3: 'DEU',
		numeric: 276,
		latitude: 51,
		longitude: 9,
	},
	Ghana: {
		country: 'Ghana',
		alpha2: 'GH',
		alpha3: 'GHA',
		numeric: 288,
		latitude: 8,
		longitude: -2,
	},
	Gibraltar: {
		country: 'Gibraltar',
		alpha2: 'GI',
		alpha3: 'GIB',
		numeric: 292,
		latitude: 36.1833,
		longitude: -5.3667,
	},
	Greece: {
		country: 'Greece',
		alpha2: 'GR',
		alpha3: 'GRC',
		numeric: 300,
		latitude: 39,
		longitude: 22,
	},
	Greenland: {
		country: 'Greenland',
		alpha2: 'GL',
		alpha3: 'GRL',
		numeric: 304,
		latitude: 72,
		longitude: -40,
	},
	Grenada: {
		country: 'Grenada',
		alpha2: 'GD',
		alpha3: 'GRD',
		numeric: 308,
		latitude: 12.1167,
		longitude: -61.6667,
	},
	Guadeloupe: {
		country: 'Guadeloupe',
		alpha2: 'GP',
		alpha3: 'GLP',
		numeric: 312,
		latitude: 16.25,
		longitude: -61.5833,
	},
	Guam: {
		country: 'Guam',
		alpha2: 'GU',
		alpha3: 'GUM',
		numeric: 316,
		latitude: 13.4667,
		longitude: 144.7833,
	},
	Guatemala: {
		country: 'Guatemala',
		alpha2: 'GT',
		alpha3: 'GTM',
		numeric: 320,
		latitude: 15.5,
		longitude: -90.25,
	},
	Guernsey: {
		country: 'Guernsey',
		alpha2: 'GG',
		alpha3: 'GGY',
		numeric: 831,
		latitude: 49.5,
		longitude: -2.56,
	},
	Guinea: {
		country: 'Guinea',
		alpha2: 'GN',
		alpha3: 'GIN',
		numeric: 324,
		latitude: 11,
		longitude: -10,
	},
	'Guinea-Bissau': {
		country: 'Guinea-Bissau',
		alpha2: 'GW',
		alpha3: 'GNB',
		numeric: 624,
		latitude: 12,
		longitude: -15,
	},
	Guyana: {
		country: 'Guyana',
		alpha2: 'GY',
		alpha3: 'GUY',
		numeric: 328,
		latitude: 5,
		longitude: -59,
	},
	Haiti: {
		country: 'Haiti',
		alpha2: 'HT',
		alpha3: 'HTI',
		numeric: 332,
		latitude: 19,
		longitude: -72.4167,
	},
	'Heard Island and McDonald Islands': {
		country: 'Heard Island and McDonald Islands',
		alpha2: 'HM',
		alpha3: 'HMD',
		numeric: 334,
		latitude: -53.1,
		longitude: 72.5167,
	},
	'Holy See (Vatican City State)': {
		country: 'Holy See (Vatican City State)',
		alpha2: 'VA',
		alpha3: 'VAT',
		numeric: 336,
		latitude: 41.9,
		longitude: 12.45,
	},
	Honduras: {
		country: 'Honduras',
		alpha2: 'HN',
		alpha3: 'HND',
		numeric: 340,
		latitude: 15,
		longitude: -86.5,
	},
	'Hong Kong': {
		country: 'Hong Kong',
		alpha2: 'HK',
		alpha3: 'HKG',
		numeric: 344,
		latitude: 22.25,
		longitude: 114.1667,
	},
	Hungary: {
		country: 'Hungary',
		alpha2: 'HU',
		alpha3: 'HUN',
		numeric: 348,
		latitude: 47,
		longitude: 20,
	},
	Iceland: {
		country: 'Iceland',
		alpha2: 'IS',
		alpha3: 'ISL',
		numeric: 352,
		latitude: 65,
		longitude: -18,
	},
	India: {
		country: 'India',
		alpha2: 'IN',
		alpha3: 'IND',
		numeric: 356,
		latitude: 20,
		longitude: 77,
	},
	Indonesia: {
		country: 'Indonesia',
		alpha2: 'ID',
		alpha3: 'IDN',
		numeric: 360,
		latitude: -5,
		longitude: 120,
	},
	'Iran, Islamic Republic of': {
		country: 'Iran, Islamic Republic of',
		alpha2: 'IR',
		alpha3: 'IRN',
		numeric: 364,
		latitude: 32,
		longitude: 53,
	},
	Iraq: {
		country: 'Iraq',
		alpha2: 'IQ',
		alpha3: 'IRQ',
		numeric: 368,
		latitude: 33,
		longitude: 44,
	},
	Ireland: {
		country: 'Ireland',
		alpha2: 'IE',
		alpha3: 'IRL',
		numeric: 372,
		latitude: 53,
		longitude: -8,
	},
	'Isle of Man': {
		country: 'Isle of Man',
		alpha2: 'IM',
		alpha3: 'IMN',
		numeric: 833,
		latitude: 54.23,
		longitude: -4.55,
	},
	Israel: {
		country: 'Israel',
		alpha2: 'IL',
		alpha3: 'ISR',
		numeric: 376,
		latitude: 31.5,
		longitude: 34.75,
	},
	Italy: {
		country: 'Italy',
		alpha2: 'IT',
		alpha3: 'ITA',
		numeric: 380,
		latitude: 42.8333,
		longitude: 12.8333,
	},
	Jamaica: {
		country: 'Jamaica',
		alpha2: 'JM',
		alpha3: 'JAM',
		numeric: 388,
		latitude: 18.25,
		longitude: -77.5,
	},
	Japan: {
		country: 'Japan',
		alpha2: 'JP',
		alpha3: 'JPN',
		numeric: 392,
		latitude: 36,
		longitude: 138,
	},
	Jersey: {
		country: 'Jersey',
		alpha2: 'JE',
		alpha3: 'JEY',
		numeric: 832,
		latitude: 49.21,
		longitude: -2.13,
	},
	Jordan: {
		country: 'Jordan',
		alpha2: 'JO',
		alpha3: 'JOR',
		numeric: 400,
		latitude: 31,
		longitude: 36,
	},
	Kazakhstan: {
		country: 'Kazakhstan',
		alpha2: 'KZ',
		alpha3: 'KAZ',
		numeric: 398,
		latitude: 48,
		longitude: 68,
	},
	Kenya: {
		country: 'Kenya',
		alpha2: 'KE',
		alpha3: 'KEN',
		numeric: 404,
		latitude: 1,
		longitude: 38,
	},
	Kiribati: {
		country: 'Kiribati',
		alpha2: 'KI',
		alpha3: 'KIR',
		numeric: 296,
		latitude: 1.4167,
		longitude: 173,
	},
	"Korea, Democratic People's Republic of": {
		country: "Korea, Democratic People's Republic of",
		alpha2: 'KP',
		alpha3: 'PRK',
		numeric: 408,
		latitude: 40,
		longitude: 127,
	},
	'South Korea': {
		country: 'Korea, Republic of',
		alpha2: 'KR',
		alpha3: 'KOR',
		numeric: 410,
		latitude: 37,
		longitude: 127.5,
	},
	Kuwait: {
		country: 'Kuwait',
		alpha2: 'KW',
		alpha3: 'KWT',
		numeric: 414,
		latitude: 29.3375,
		longitude: 47.6581,
	},
	Kyrgyzstan: {
		country: 'Kyrgyzstan',
		alpha2: 'KG',
		alpha3: 'KGZ',
		numeric: 417,
		latitude: 41,
		longitude: 75,
	},
	"Lao People's Democratic Republic": {
		country: "Lao People's Democratic Republic",
		alpha2: 'LA',
		alpha3: 'LAO',
		numeric: 418,
		latitude: 18,
		longitude: 105,
	},
	Latvia: {
		country: 'Latvia',
		alpha2: 'LV',
		alpha3: 'LVA',
		numeric: 428,
		latitude: 57,
		longitude: 25,
	},
	Lebanon: {
		country: 'Lebanon',
		alpha2: 'LB',
		alpha3: 'LBN',
		numeric: 422,
		latitude: 33.8333,
		longitude: 35.8333,
	},
	Lesotho: {
		country: 'Lesotho',
		alpha2: 'LS',
		alpha3: 'LSO',
		numeric: 426,
		latitude: -29.5,
		longitude: 28.5,
	},
	Liberia: {
		country: 'Liberia',
		alpha2: 'LR',
		alpha3: 'LBR',
		numeric: 430,
		latitude: 6.5,
		longitude: -9.5,
	},
	Libya: {
		country: 'Libya',
		alpha2: 'LY',
		alpha3: 'LBY',
		numeric: 434,
		latitude: 25,
		longitude: 17,
	},
	'South Sudan': {
		country: 'South Sudan',
		alpha2: 'SS',
		alpha3: 'SSD',
		numeric: 434,
		latitude: 4.859363,
		longitude: 31.571251,
	},
	Liechtenstein: {
		country: 'Liechtenstein',
		alpha2: 'LI',
		alpha3: 'LIE',
		numeric: 438,
		latitude: 47.1667,
		longitude: 9.5333,
	},
	Lithuania: {
		country: 'Lithuania',
		alpha2: 'LT',
		alpha3: 'LTU',
		numeric: 440,
		latitude: 56,
		longitude: 24,
	},
	Luxembourg: {
		country: 'Luxembourg',
		alpha2: 'LU',
		alpha3: 'LUX',
		numeric: 442,
		latitude: 49.75,
		longitude: 6.1667,
	},
	Macao: {
		country: 'Macao',
		alpha2: 'MO',
		alpha3: 'MAC',
		numeric: 446,
		latitude: 22.1667,
		longitude: 113.55,
	},
	'Macedonia, the former Yugoslav Republic of': {
		country: 'Macedonia, the former Yugoslav Republic of',
		alpha2: 'MK',
		alpha3: 'MKD',
		numeric: 807,
		latitude: 41.8333,
		longitude: 22,
	},
	Madagascar: {
		country: 'Madagascar',
		alpha2: 'MG',
		alpha3: 'MDG',
		numeric: 450,
		latitude: -20,
		longitude: 47,
	},
	Malawi: {
		country: 'Malawi',
		alpha2: 'MW',
		alpha3: 'MWI',
		numeric: 454,
		latitude: -13.5,
		longitude: 34,
	},
	Malaysia: {
		country: 'Malaysia',
		alpha2: 'MY',
		alpha3: 'MYS',
		numeric: 458,
		latitude: 2.5,
		longitude: 112.5,
	},
	Maldives: {
		country: 'Maldives',
		alpha2: 'MV',
		alpha3: 'MDV',
		numeric: 462,
		latitude: 3.25,
		longitude: 73,
	},
	Mali: {
		country: 'Mali',
		alpha2: 'ML',
		alpha3: 'MLI',
		numeric: 466,
		latitude: 17,
		longitude: -4,
	},
	Malta: {
		country: 'Malta',
		alpha2: 'MT',
		alpha3: 'MLT',
		numeric: 470,
		latitude: 35.8333,
		longitude: 14.5833,
	},
	'Marshall Islands': {
		country: 'Marshall Islands',
		alpha2: 'MH',
		alpha3: 'MHL',
		numeric: 584,
		latitude: 9,
		longitude: 168,
	},
	Martinique: {
		country: 'Martinique',
		alpha2: 'MQ',
		alpha3: 'MTQ',
		numeric: 474,
		latitude: 14.6667,
		longitude: -61,
	},
	Mauritania: {
		country: 'Mauritania',
		alpha2: 'MR',
		alpha3: 'MRT',
		numeric: 478,
		latitude: 20,
		longitude: -12,
	},
	Mauritius: {
		country: 'Mauritius',
		alpha2: 'MU',
		alpha3: 'MUS',
		numeric: 480,
		latitude: -20.2833,
		longitude: 57.55,
	},
	Mayotte: {
		country: 'Mayotte',
		alpha2: 'YT',
		alpha3: 'MYT',
		numeric: 175,
		latitude: -12.8333,
		longitude: 45.1667,
	},
	Mexico: {
		country: 'Mexico',
		alpha2: 'MX',
		alpha3: 'MEX',
		numeric: 484,
		latitude: 23,
		longitude: -102,
	},
	'Micronesia, Federated States of': {
		country: 'Micronesia, Federated States of',
		alpha2: 'FM',
		alpha3: 'FSM',
		numeric: 583,
		latitude: 6.9167,
		longitude: 158.25,
	},
	'Moldova, Republic of': {
		country: 'Moldova, Republic of',
		alpha2: 'MD',
		alpha3: 'MDA',
		numeric: 498,
		latitude: 47,
		longitude: 29,
	},
	Monaco: {
		country: 'Monaco',
		alpha2: 'MC',
		alpha3: 'MCO',
		numeric: 492,
		latitude: 43.7333,
		longitude: 7.4,
	},
	Mongolia: {
		country: 'Mongolia',
		alpha2: 'MN',
		alpha3: 'MNG',
		numeric: 496,
		latitude: 46,
		longitude: 105,
	},
	Montenegro: {
		country: 'Montenegro',
		alpha2: 'ME',
		alpha3: 'MNE',
		numeric: 499,
		latitude: 42,
		longitude: 19,
	},
	Montserrat: {
		country: 'Montserrat',
		alpha2: 'MS',
		alpha3: 'MSR',
		numeric: 500,
		latitude: 16.75,
		longitude: -62.2,
	},
	Morocco: {
		country: 'Morocco',
		alpha2: 'MA',
		alpha3: 'MAR',
		numeric: 504,
		latitude: 32,
		longitude: -5,
	},
	Mozambique: {
		country: 'Mozambique',
		alpha2: 'MZ',
		alpha3: 'MOZ',
		numeric: 508,
		latitude: -18.25,
		longitude: 35,
	},
	Myanmar: {
		country: 'Myanmar',
		alpha2: 'MM',
		alpha3: 'MMR',
		numeric: 104,
		latitude: 22,
		longitude: 98,
	},
	Namibia: {
		country: 'Namibia',
		alpha2: 'NA',
		alpha3: 'NAM',
		numeric: 516,
		latitude: -22,
		longitude: 17,
	},
	Nauru: {
		country: 'Nauru',
		alpha2: 'NR',
		alpha3: 'NRU',
		numeric: 520,
		latitude: -0.5333,
		longitude: 166.9167,
	},
	Nepal: {
		country: 'Nepal',
		alpha2: 'NP',
		alpha3: 'NPL',
		numeric: 524,
		latitude: 28,
		longitude: 84,
	},
	Netherlands: {
		country: 'Netherlands',
		alpha2: 'NL',
		alpha3: 'NLD',
		numeric: 528,
		latitude: 52.5,
		longitude: 5.75,
	},
	'Netherlands Antilles': {
		country: 'Netherlands Antilles',
		alpha2: 'AN',
		alpha3: 'ANT',
		numeric: 530,
		latitude: 12.25,
		longitude: -68.75,
	},
	'New Caledonia': {
		country: 'New Caledonia',
		alpha2: 'NC',
		alpha3: 'NCL',
		numeric: 540,
		latitude: -21.5,
		longitude: 165.5,
	},
	'New Zealand': {
		country: 'New Zealand',
		alpha2: 'NZ',
		alpha3: 'NZL',
		numeric: 554,
		latitude: -41,
		longitude: 174,
	},
	Nicaragua: {
		country: 'Nicaragua',
		alpha2: 'NI',
		alpha3: 'NIC',
		numeric: 558,
		latitude: 13,
		longitude: -85,
	},
	Niger: {
		country: 'Niger',
		alpha2: 'NE',
		alpha3: 'NER',
		numeric: 562,
		latitude: 16,
		longitude: 8,
	},
	Nigeria: {
		country: 'Nigeria',
		alpha2: 'NG',
		alpha3: 'NGA',
		numeric: 566,
		latitude: 10,
		longitude: 8,
	},
	Niue: {
		country: 'Niue',
		alpha2: 'NU',
		alpha3: 'NIU',
		numeric: 570,
		latitude: -19.0333,
		longitude: -169.8667,
	},
	'Norfolk Island': {
		country: 'Norfolk Island',
		alpha2: 'NF',
		alpha3: 'NFK',
		numeric: 574,
		latitude: -29.0333,
		longitude: 167.95,
	},
	'Northern Mariana Islands': {
		country: 'Northern Mariana Islands',
		alpha2: 'MP',
		alpha3: 'MNP',
		numeric: 580,
		latitude: 15.2,
		longitude: 145.75,
	},
	Norway: {
		country: 'Norway',
		alpha2: 'NO',
		alpha3: 'NOR',
		numeric: 578,
		latitude: 62,
		longitude: 10,
	},
	Oman: {
		country: 'Oman',
		alpha2: 'OM',
		alpha3: 'OMN',
		numeric: 512,
		latitude: 21,
		longitude: 57,
	},
	Pakistan: {
		country: 'Pakistan',
		alpha2: 'PK',
		alpha3: 'PAK',
		numeric: 586,
		latitude: 30,
		longitude: 70,
	},
	Palau: {
		country: 'Palau',
		alpha2: 'PW',
		alpha3: 'PLW',
		numeric: 585,
		latitude: 7.5,
		longitude: 134.5,
	},
	'Palestinian Territory, Occupied': {
		country: 'Palestinian Territory, Occupied',
		alpha2: 'PS',
		alpha3: 'PSE',
		numeric: 275,
		latitude: 32,
		longitude: 35.25,
	},
	Panama: {
		country: 'Panama',
		alpha2: 'PA',
		alpha3: 'PAN',
		numeric: 591,
		latitude: 9,
		longitude: -80,
	},
	'Papua New Guinea': {
		country: 'Papua New Guinea',
		alpha2: 'PG',
		alpha3: 'PNG',
		numeric: 598,
		latitude: -6,
		longitude: 147,
	},
	Paraguay: {
		country: 'Paraguay',
		alpha2: 'PY',
		alpha3: 'PRY',
		numeric: 600,
		latitude: -23,
		longitude: -58,
	},
	Peru: {
		country: 'Peru',
		alpha2: 'PE',
		alpha3: 'PER',
		numeric: 604,
		latitude: -10,
		longitude: -76,
	},
	Philippines: {
		country: 'Philippines',
		alpha2: 'PH',
		alpha3: 'PHL',
		numeric: 608,
		latitude: 13,
		longitude: 122,
	},
	Pitcairn: {
		country: 'Pitcairn',
		alpha2: 'PN',
		alpha3: 'PCN',
		numeric: 612,
		latitude: -24.7,
		longitude: -127.4,
	},
	Poland: {
		country: 'Poland',
		alpha2: 'PL',
		alpha3: 'POL',
		numeric: 616,
		latitude: 52,
		longitude: 20,
	},
	Portugal: {
		country: 'Portugal',
		alpha2: 'PT',
		alpha3: 'PRT',
		numeric: 620,
		latitude: 39.5,
		longitude: -8,
	},
	'Puerto Rico': {
		country: 'Puerto Rico',
		alpha2: 'PR',
		alpha3: 'PRI',
		numeric: 630,
		latitude: 18.25,
		longitude: -66.5,
	},
	Qatar: {
		country: 'Qatar',
		alpha2: 'QA',
		alpha3: 'QAT',
		numeric: 634,
		latitude: 25.5,
		longitude: 51.25,
	},
	Réunion: {
		country: 'Réunion',
		alpha2: 'RE',
		alpha3: 'REU',
		numeric: 638,
		latitude: -21.1,
		longitude: 55.6,
	},
	Romania: {
		country: 'Romania',
		alpha2: 'RO',
		alpha3: 'ROU',
		numeric: 642,
		latitude: 46,
		longitude: 25,
	},
	Russia: {
		country: 'Russian Federation',
		alpha2: 'RU',
		alpha3: 'RUS',
		numeric: 643,
		latitude: 61.5,
		longitude: 105,
	},
	Rwanda: {
		country: 'Rwanda',
		alpha2: 'RW',
		alpha3: 'RWA',
		numeric: 646,
		latitude: -2,
		longitude: 30,
	},
	'Saint Helena, Ascension and Tristan da Cunha': {
		country: 'Saint Helena, Ascension and Tristan da Cunha',
		alpha2: 'SH',
		alpha3: 'SHN',
		numeric: 654,
		latitude: -15.9333,
		longitude: -5.7,
	},
	'Saint Kitts and Nevis': {
		country: 'Saint Kitts and Nevis',
		alpha2: 'KN',
		alpha3: 'KNA',
		numeric: 659,
		latitude: 17.3333,
		longitude: -62.75,
	},
	'Saint Lucia': {
		country: 'Saint Lucia',
		alpha2: 'LC',
		alpha3: 'LCA',
		numeric: 662,
		latitude: 13.8833,
		longitude: -61.1333,
	},
	'Saint Pierre and Miquelon': {
		country: 'Saint Pierre and Miquelon',
		alpha2: 'PM',
		alpha3: 'SPM',
		numeric: 666,
		latitude: 46.8333,
		longitude: -56.3333,
	},
	'Saint Vincent and the Grenadines': {
		country: 'Saint Vincent and the Grenadines',
		alpha2: 'VC',
		alpha3: 'VCT',
		numeric: 670,
		latitude: 13.25,
		longitude: -61.2,
	},
	Samoa: {
		country: 'Samoa',
		alpha2: 'WS',
		alpha3: 'WSM',
		numeric: 882,
		latitude: -13.5833,
		longitude: -172.3333,
	},
	'San Marino': {
		country: 'San Marino',
		alpha2: 'SM',
		alpha3: 'SMR',
		numeric: 674,
		latitude: 43.7667,
		longitude: 12.4167,
	},
	'Sao Tome and Principe': {
		country: 'Sao Tome and Principe',
		alpha2: 'ST',
		alpha3: 'STP',
		numeric: 678,
		latitude: 1,
		longitude: 7,
	},
	'Saudi Arabia': {
		country: 'Saudi Arabia',
		alpha2: 'SA',
		alpha3: 'SAU',
		numeric: 682,
		latitude: 25,
		longitude: 45,
	},
	Senegal: {
		country: 'Senegal',
		alpha2: 'SN',
		alpha3: 'SEN',
		numeric: 686,
		latitude: 14,
		longitude: -14,
	},
	Serbia: {
		country: 'Serbia',
		alpha2: 'RS',
		alpha3: 'SRB',
		numeric: 688,
		latitude: 44,
		longitude: 21,
	},
	Seychelles: {
		country: 'Seychelles',
		alpha2: 'SC',
		alpha3: 'SYC',
		numeric: 690,
		latitude: -4.5833,
		longitude: 55.6667,
	},
	'Sierra Leone': {
		country: 'Sierra Leone',
		alpha2: 'SL',
		alpha3: 'SLE',
		numeric: 694,
		latitude: 8.5,
		longitude: -11.5,
	},
	Singapore: {
		country: 'Singapore',
		alpha2: 'SG',
		alpha3: 'SGP',
		numeric: 702,
		latitude: 1.3667,
		longitude: 103.8,
	},
	Slovakia: {
		country: 'Slovakia',
		alpha2: 'SK',
		alpha3: 'SVK',
		numeric: 703,
		latitude: 48.6667,
		longitude: 19.5,
	},
	Slovenia: {
		country: 'Slovenia',
		alpha2: 'SI',
		alpha3: 'SVN',
		numeric: 705,
		latitude: 46,
		longitude: 15,
	},
	'Solomon Islands': {
		country: 'Solomon Islands',
		alpha2: 'SB',
		alpha3: 'SLB',
		numeric: 90,
		latitude: -8,
		longitude: 159,
	},
	Somalia: {
		country: 'Somalia',
		alpha2: 'SO',
		alpha3: 'SOM',
		numeric: 706,
		latitude: 10,
		longitude: 49,
	},
	'South Africa': {
		country: 'South Africa',
		alpha2: 'ZA',
		alpha3: 'ZAF',
		numeric: 710,
		latitude: -29,
		longitude: 24,
	},
	'South Georgia and the South Sandwich Islands': {
		country: 'South Georgia and the South Sandwich Islands',
		alpha2: 'GS',
		alpha3: 'SGS',
		numeric: 239,
		latitude: -54.5,
		longitude: -37,
	},
	Spain: {
		country: 'Spain',
		alpha2: 'ES',
		alpha3: 'ESP',
		numeric: 724,
		latitude: 40,
		longitude: -4,
	},
	'Sri Lanka': {
		country: 'Sri Lanka',
		alpha2: 'LK',
		alpha3: 'LKA',
		numeric: 144,
		latitude: 7,
		longitude: 81,
	},
	Sudan: {
		country: 'Sudan',
		alpha2: 'SD',
		alpha3: 'SDN',
		numeric: 736,
		latitude: 15,
		longitude: 30,
	},
	Suriname: {
		country: 'Suriname',
		alpha2: 'SR',
		alpha3: 'SUR',
		numeric: 740,
		latitude: 4,
		longitude: -56,
	},
	'Svalbard and Jan Mayen': {
		country: 'Svalbard and Jan Mayen',
		alpha2: 'SJ',
		alpha3: 'SJM',
		numeric: 744,
		latitude: 78,
		longitude: 20,
	},
	Swaziland: {
		country: 'Swaziland',
		alpha2: 'SZ',
		alpha3: 'SWZ',
		numeric: 748,
		latitude: -26.5,
		longitude: 31.5,
	},
	Sweden: {
		country: 'Sweden',
		alpha2: 'SE',
		alpha3: 'SWE',
		numeric: 752,
		latitude: 62,
		longitude: 15,
	},
	Switzerland: {
		country: 'Switzerland',
		alpha2: 'CH',
		alpha3: 'CHE',
		numeric: 756,
		latitude: 47,
		longitude: 8,
	},
	'Syrian Arab Republic': {
		country: 'Syrian Arab Republic',
		alpha2: 'SY',
		alpha3: 'SYR',
		numeric: 760,
		latitude: 35,
		longitude: 38,
	},
	Taiwan: {
		country: 'Taiwan, Province of China',
		alpha2: 'TW',
		alpha3: 'TWN',
		numeric: 158,
		latitude: 23.5,
		longitude: 121,
	},
	Tajikistan: {
		country: 'Tajikistan',
		alpha2: 'TJ',
		alpha3: 'TJK',
		numeric: 762,
		latitude: 39,
		longitude: 71,
	},
	'Tanzania, United Republic of': {
		country: 'Tanzania, United Republic of',
		alpha2: 'TZ',
		alpha3: 'TZA',
		numeric: 834,
		latitude: -6,
		longitude: 35,
	},
	Thailand: {
		country: 'Thailand',
		alpha2: 'TH',
		alpha3: 'THA',
		numeric: 764,
		latitude: 15,
		longitude: 100,
	},
	'Timor-Leste': {
		country: 'Timor-Leste',
		alpha2: 'TL',
		alpha3: 'TLS',
		numeric: 626,
		latitude: -8.55,
		longitude: 125.5167,
	},
	Togo: {
		country: 'Togo',
		alpha2: 'TG',
		alpha3: 'TGO',
		numeric: 768,
		latitude: 8,
		longitude: 1.1667,
	},
	Tokelau: {
		country: 'Tokelau',
		alpha2: 'TK',
		alpha3: 'TKL',
		numeric: 772,
		latitude: -9,
		longitude: -172,
	},
	Tonga: {
		country: 'Tonga',
		alpha2: 'TO',
		alpha3: 'TON',
		numeric: 776,
		latitude: -20,
		longitude: -175,
	},
	'Trinidad and Tobago': {
		country: 'Trinidad and Tobago',
		alpha2: 'TT',
		alpha3: 'TTO',
		numeric: 780,
		latitude: 11,
		longitude: -61,
	},
	Tunisia: {
		country: 'Tunisia',
		alpha2: 'TN',
		alpha3: 'TUN',
		numeric: 788,
		latitude: 34,
		longitude: 9,
	},
	Turkey: {
		country: 'Turkey',
		alpha2: 'TR',
		alpha3: 'TUR',
		numeric: 792,
		latitude: 39,
		longitude: 35,
	},
	Turkmenistan: {
		country: 'Turkmenistan',
		alpha2: 'TM',
		alpha3: 'TKM',
		numeric: 795,
		latitude: 40,
		longitude: 60,
	},
	'Turks and Caicos Islands': {
		country: 'Turks and Caicos Islands',
		alpha2: 'TC',
		alpha3: 'TCA',
		numeric: 796,
		latitude: 21.75,
		longitude: -71.5833,
	},
	Tuvalu: {
		country: 'Tuvalu',
		alpha2: 'TV',
		alpha3: 'TUV',
		numeric: 798,
		latitude: -8,
		longitude: 178,
	},
	Uganda: {
		country: 'Uganda',
		alpha2: 'UG',
		alpha3: 'UGA',
		numeric: 800,
		latitude: 1,
		longitude: 32,
	},
	Ukraine: {
		country: 'Ukraine',
		alpha2: 'UA',
		alpha3: 'UKR',
		numeric: 804,
		latitude: 49,
		longitude: 32,
	},
	UAE: {
		country: 'United Arab Emirates',
		alpha2: 'AE',
		alpha3: 'ARE',
		numeric: 784,
		latitude: 24,
		longitude: 54,
	},
	UK: {
		country: 'United Kingdom',
		alpha2: 'GB',
		alpha3: 'GBR',
		numeric: 826,
		latitude: 54,
		longitude: -2,
	},
	US: {
		country: 'United States',
		alpha2: 'US',
		alpha3: 'USA',
		numeric: 840,
		latitude: 38,
		longitude: -97,
	},
	'United States Minor Outlying Islands': {
		country: 'United States Minor Outlying Islands',
		alpha2: 'UM',
		alpha3: 'UMI',
		numeric: 581,
		latitude: 19.2833,
		longitude: 166.6,
	},
	Uruguay: {
		country: 'Uruguay',
		alpha2: 'UY',
		alpha3: 'URY',
		numeric: 858,
		latitude: -33,
		longitude: -56,
	},
	Uzbekistan: {
		country: 'Uzbekistan',
		alpha2: 'UZ',
		alpha3: 'UZB',
		numeric: 860,
		latitude: 41,
		longitude: 64,
	},
	Vanuatu: {
		country: 'Vanuatu',
		alpha2: 'VU',
		alpha3: 'VUT',
		numeric: 548,
		latitude: -16,
		longitude: 167,
	},
	Venezuela: {
		country: 'Venezuela, Bolivarian Republic of',
		alpha2: 'VE',
		alpha3: 'VEN',
		numeric: 862,
		latitude: 8,
		longitude: -66,
	},
	Vietnam: {
		country: 'Vietnam',
		alpha2: 'VN',
		alpha3: 'VNM',
		numeric: 704,
		latitude: 16,
		longitude: 106,
	},
	'Virgin Islands, British': {
		country: 'Virgin Islands, British',
		alpha2: 'VG',
		alpha3: 'VGB',
		numeric: 92,
		latitude: 18.5,
		longitude: -64.5,
	},
	'Virgin Islands, U.S.': {
		country: 'Virgin Islands, U.S.',
		alpha2: 'VI',
		alpha3: 'VIR',
		numeric: 850,
		latitude: 18.3333,
		longitude: -64.8333,
	},
	'Wallis and Futuna': {
		country: 'Wallis and Futuna',
		alpha2: 'WF',
		alpha3: 'WLF',
		numeric: 876,
		latitude: -13.3,
		longitude: -176.2,
	},
	'Western Sahara': {
		country: 'Western Sahara',
		alpha2: 'EH',
		alpha3: 'ESH',
		numeric: 732,
		latitude: 24.5,
		longitude: -13,
	},
	Yemen: {
		country: 'Yemen',
		alpha2: 'YE',
		alpha3: 'YEM',
		numeric: 887,
		latitude: 15,
		longitude: 48,
	},
	Zambia: {
		country: 'Zambia',
		alpha2: 'ZM',
		alpha3: 'ZMB',
		numeric: 894,
		latitude: -15,
		longitude: 30,
	},
	Zimbabwe: {
		country: 'Zimbabwe',
		alpha2: 'ZW',
		alpha3: 'ZWE',
		numeric: 716,
		latitude: -20,
		longitude: 30,
	},
	Afghanistan: {
		country: 'Afghanistan',
		alpha2: 'AF',
		alpha3: 'AFG',
		numeric: 4,
		latitude: 33,
		longitude: 65,
	},
};

export const hemisphereLngLtd = {
	West: [-105, 60],
	East: [70, 30],
};

export const regionLngLtd = {
	NAM: [-105, 60],
	MEX: [-102, 23],
	LAM: [-64, -34],
	'ASIA PACIFIC': [105, 35],
	MEA: [45, 25],
	'SOUTH EUROPE / NA': [3, 28],
	'UK AND NORTH EUROPE': [15, 62],
	'RUSSIA AND CASPIAN': [68, 48],
};
