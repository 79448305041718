import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform } from '../../../../../utils/numberUtils'
import { formatDate } from '../../../../../utils/dateFormat'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import FunctionDrilldownDetailModal from "../FunctionDrillDown";

import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTableComponent from './pureTable'

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.sortList = {}
        this.chatDataCollection = {}
        this.levelMap = {
            PlTotal: 'P&L Total',
            PlGroup: 'P& L Group',
            GroupName2: 'Group Name2',
            GroupName: 'Group Name',
            GroupName3: 'Group Name3',
            AccountName: 'Account Name',
            CostCenter: 'Cost Centre2'
        }
        this.state = {
            showCommentModal: false,
            loading: true,
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            showFunctionalDrilldownModal: false,
            filterSelectorValue: [],
            showLoader: false,
            invoiceObject: null,
            drillDownData: {},
            updateComment: false,
            renderExcel: true,
            selectedRow:{},
            oldData: [],
        }
        this.excelData = []
    }

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }

    addExcelDataMain = (data, level, idx) => {
        idx += 1;
        let excelData = this.excelData;
        console.log(data, "level")
        if (data) {
            console.log(data[0].parents.length, "levelLength")
            let lenthParent = data[0].parents.length
            let parents = data[0].parents[lenthParent - 1]
            console.log(parents, "testLevel0")
            let indexParent = 0
            let parents2 = ""
            let parents3 = ""
            if ((lenthParent - 1) == 1 ){

                 parents2 = data[0].parents[0]
                
            }else if  ((lenthParent - 1) == 2 ){
                parents2 = data[0].parents[0]
                parents3 = data[0].parents[1]
            }
            indexParent = excelData.findIndex(x => x.budget_group === parents)

            let curLevel = excelData[indexParent + 1] && excelData[indexParent + 1].custom_level;
            console.log(excelData, "testLevel1")
            console.log(indexParent, "testLevel22")
            console.log(excelData[indexParent], "testLevel3")
            let prevLevel = excelData[indexParent] && excelData[indexParent].custom_level;
            console.log(curLevel, "testLevel4")
            console.log(prevLevel, "testLevel5")
            if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
                idx = indexParent + 1
                excelData = this.removeExcelDataMain(data, indexParent)
            } else {
                level += 1
                let idxori = idx
                data && data.forEach((x, i) => { //inject data after the parent

                    i += 1
                    x.data_field = this.addSpaceLevel(x.data_field, level)
                    idx = indexParent + i
                    excelData.splice(idx, 0, x)
                })
            }
            this.excelData = excelData
            console.log(this.excelData, "value level")
        }
      
    }

    removeExcelDataMain = (data, idx) => {
        let excelData = this.excelData;
        let idxSubrow = excelData[idx].sub_rows.length
        let idxMax = idx + idxSubrow
      
        console.log(excelData, "data Hapus SAebelum", idx)
        excelData = excelData.filter((val, i) => {
            if (excelData[idx].custom_level == 0){
                if (val.parents){
                    console.log( excelData[idx].budget_group, "data Hapus idx", i)
                    console.log( val.parents[0], "data Hapus idx", i)
                    return !( val.custom_level >= excelData[idx].custom_level &&  val.parents[0] == excelData[idx].budget_group)
                }else{
                    return val
                }
               
            }else{
                if (val.parents){
               
                    return !( val.custom_level >= excelData[idx].custom_level &&  val.parents[val.parents.length-1] == excelData[idx].budget_group)
                }else{
                    return val
                   
                }
               
            }
        })
        console.log(excelData, "data Hapus Setelah ")
        return excelData
    }

    addExcelData = (dataval, header) => {
        let data = JSON.parse(JSON.stringify(dataval))
        data.sub_rows = this.checkLevel(data.sub_rows, data.custom_level);
        this.excelData = data;
        this.handleExcelDataChange(this.excelData, header)
    }

    handleFindMatchRecordComments = (Resources, data_field)=>{
       let _this = this
       var data = [];
       for(var item of Resources) {
         console.log(item.data_field, data_field)
         if (item.data_field == data_field) {
           data = item.comments;
           break;
         }else{
            for(var i of item.sub_rows) {
             if (i.data_field == data_field) {
               data = i.comments;
               break;
             }else{
                for (const idx in i.sub_rows) {
                    const filter = _this.handleFindMatchRecordComments([i.sub_rows[idx]],data_field)
                    if(filter?.length !==0){
                        data = filter
                        break;
                    }
                }
             }
            }
            
         }
       };
       return data
    }

    handleUpdateCommentCell = (selectedRow, newData)=>{
        let newComments = this.handleFindMatchRecordComments(newData, selectedRow.original.data_field)
        var element = document.getElementById(`comment-area-${selectedRow.original.data_field}`);
        element.innerHTML = ""
        newComments.forEach((item, index) => {
            console.log(item)
            element.innerHTML +=
                "<div class='block' key='comment-area-${selectedRow.original.data_field}'>"+
                    "<div class='date'>"+item.created_by +","+ formatDate(item.created_at)+"</div>"+
                    "<div>"+item.comment+"</div></div>"
        })

        console.log("TEST",newComments,selectedRow, newData)
        this.setState({
            showLoader: false,
            updateComment: false,
            selectedRow: {},
            oldData: []
        })
    }

    handleTooltipData = (props) => {
        let { custom_level, levels } = props.original;
        let tooltipData = ''
        if (levels && levels.length) {
            tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
        }
        return tooltipData;
    }

    handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={
                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>
                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }
        return tooltipData;
    }

    handleChatChangeFn = (event, rowData) => {
        if (event.target && event.target.value) {
            this.chatDataCollection[rowData.data_field] = event.target.value;
        }
    }

    handleChatSendFn = (rowData) => {
        this.setState({
            showLoader: true,
            oldData:this.props.incomeStatementState.pivotRowData,
            selectedRow:rowData,
            updateComment: true,
            renderExcel : false,
        });
        console.log(rowData, 'ppppppppp')
        let sendChatObject = {}
        if (document.getElementById(`outlined-basic-${rowData.original.data_field}`).value) {
            let newRowId = ''
            if (rowData.original.parents && rowData.original.parents.length) {
                rowData.original.parents.forEach(item => {
                    newRowId += item.toLowerCase() + '/'
                })
            }
            newRowId += rowData.original.data_field && rowData.original.data_field.toLowerCase()
            console.log('>>>>>>>', newRowId)
            sendChatObject = {
                comment: document.getElementById(`outlined-basic-${rowData.original.data_field}`).value,
                commentRowId: newRowId,
                view: rowData.original.data_field,
                reportDate: rowData.original.current_month
            }


            this.props.handleCommentSendFn(sendChatObject)
            this.chatDataCollection[rowData.original.data_field] = ''
            document.getElementById(`outlined-basic-${rowData.original.data_field}`).value = ''

        }
    }

    handleNestedColumnClick = (rowData, clickedValue, gridHeader, isNested) => {
        console.log(rowData, '>>>>>>>ppppppppp',clickedValue, gridHeader)
        const value = clickedValue.innerHTML

        let payload = {
            yearmonth: gridHeader.key,
            plant: rowData.header_key_value.plant,
            legal_entity: rowData.header_key_value.legal_entity,
            functional_area: rowData.header_key_value.fa_name,
            data_field: rowData.data_field,
            location: rowData.header_key_value.country,
            parents: rowData.parents,
            cost_center : rowData.header_key_value.cost_center,
            text : rowData.header_key_value.text,
        }

        this.setState({
            drillDownData: payload
        })
        this.showDrilldownHeaderValue = `${rowData.header_key_value.expense_key_acct} (${gridHeader.key}) - USD ${value}`

        this.props.handleNestedColumnCallback(payload)
    }

    handleDrilldownExcelDownload = () => {
        const {drillDownData} = this.state;

        let payload= {
           ...drillDownData,
           export: true
        }

        this.props.handleDownloadNestedColumnCallback(payload)


    }

    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                 if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#d8dce6'} }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal[item.key]
                            let tooltipData = this.handleTooltipData(props)
                            return (
                                <div>
                                    <Tooltip title={tooltipData}>
                                        <span>{newOriginal[item.key]}</span>
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                } else if (item.key) {
                    var isFA = item.key == "fa_name" ||  item.key == "cost_center" ||  item.key == "text" 
                    var isEmployee = item.key == "employee_name"
               
                    return {
                        Header: this.handleColumnHeaderTooltip(item),
                        headerTitle: item.label,
                        id: item.key,
                        className: isNested ? isFA ? 'fa-cell-body' : isEmployee ? 'emp-cell-body' : '' : isFA ? 'noNesting fa-cell-body' :  isEmployee ? 'noNesting emp-cell-body' : 'noNesting',
                        headerClassName : isFA ? 'fa-cell-header' :  isEmployee ? 'emp-cell-header' :  '',
                        metaData: item.metadata,
                        Cell: (props) => {
                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let stringVal = (item.key == 'legal_entity' || item.key == 'employee_name') && newOriginal[item.key] == "" ? "-" :  newOriginal[item.key]
                            // let zeroData = newOriginal[item.key]
                            // console.log(newOriginal, "--new original")
                            return (
                                <div className={ item.operation == 7 ? 'pointer' : ''} 
                                onClick={(event) => {
                                    if(item.operation == 7){
                                        this.handleNestedColumnClick(props.original, event.target, item, isNested)
                                    }
                                    }}>
                                    <React.Fragment>{ item.operation == 7 ? handleTableTransform(newOriginal, item) :   stringVal }</React.Fragment>
                                    {/* zeroData ? handleTableTransform(newOriginal, item) : '-' */}
                                </div>
                                
                            )
                        },
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#d8dce6' } }
                            }
                            return {}
                        },
                       
                    }
                }
                return {
                    Header: item.label,
                    id: item.key,
                    className: isNested ? '' : 'noNesting',
                    metaData: item.metadata,
                    Cell: (props) => {

                        let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                        let zeroData = newOriginal[item.key]
                        return (
                            <React.Fragment>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</React.Fragment>

                        )
                    }
                }
            })
        }
        return []
    }

    updateExcel = () => {
        setTimeout(function () {
            this.setState({  renderExcel : true })
        }.bind(this), 2000)
    }
    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt": rowInfo.original.sub_rows !== null ? 1 : 0
        };
    }
    subComponent = (row) => {
        let { pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        console.log(pivotHeaderData, "checkNested")
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        getTrProps={this.expandIcon}
                        showPagination={false}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }

    columnHeaderClick = (state, someData, colInfo) => {
        const { showCursor } = this.props;
        if (showCursor) {
            return {
                onClick: (e) => {
                    this.handleColumnHeaderClick(colInfo)
                },
                style: { cursor: 'pointer' }
            }
        }
        return {}
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        console.log(state, colInfo, columnDetail, etc, event.target.innerHTML)
        const { data_field, parents } = colInfo.original;
        const { headerTitle } = columnDetail;
        if (headerTitle) {
            const value = Math.ceil(Math.abs(colInfo.row._original.header_key_value[columnDetail.id])).toLocaleString();
            this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${value}`
            console.log('lsksossss>>>>>>', this.showDrilldownHeaderValue)
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        if (column.Header != 'Comment') {
            return {
                onClick: (e, handleOriginal) => {
                    if (!rowInfo.original.disable_drill_down) {
                        this.handleColumnClick(state, rowInfo, column, instance, e)
                    }
                    console.log(column, rowInfo, "--column")
                    if (column.expander) {
                        this.setState({ 
                            showLoader : true 
                        })

                        let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                        let nestedFlag = this.checkForNestedLevel(pivotRowData)
                        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                        setTimeout(
                            handleOriginal
                        , 4000)
                        setTimeout(() => { this.setState({ showLoader : false }) }, 8000)
                    }
                }
            }
        } else {
            return {
                onClick: () => { }
            }
        }

    }

    prepareExcelData = () => {
        this.excelData = []
        this.handleExcelDataChange([], [])
        let { pivotRowData, pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        pivotRowData = this.checkLevel(pivotRowData);
        this.addExcelData(pivotRowData, pivotHeaderData);
    }

    componentDidUpdate(prevProps) {
       
        console.log(this.state.renderExcel, "cekcek1")
            console.log(this.props.excelRefresh, "cekcek2")
        if(this.state.renderExcel && this.props.excelRefresh){

            this.prepareExcelData();
        }
        if (prevProps.incomeStatementState.loading && !this.props.incomeStatementState.loading) {
            this.setState({ renderExcel : true },   this.prepareExcelData);
        }
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
         
            this.setState({
                showInvoiceDetailModal: true,
                showLoader: false,
                renderExcel : false,
            })
        }
        if (prevProps.incomeStatementState.drilldownLoader && !this.props.incomeStatementState.drilldownLoader) {
            this.setState({
                showFunctionalDrilldownModal: true,
                renderExcel : false
            })
        }

        let  pivotRowData = this.props.incomeStatementState.pivotRowData
        let _this = this
        if(pivotRowData.length > 0 && this.state.oldData.length > 0){
            if(JSON.stringify(pivotRowData) !==  JSON.stringify(_this.state.oldData)){
                if(_this.state.updateComment){
                    _this.handleUpdateCommentCell(_this.state.selectedRow,pivotRowData)
                    this.setState({
                        renderExcel : false
                    })
                }
            }
            
        }
    }

    componentDidMount() {
        this.prepareExcelData();
    }

    invoiceModalCallBack = () => {
        this.excelData = []
        this.setState({ showInvoiceDetailModal: false },   this.updateExcel);
    }
    functionalDrilldownModalCallBack = () => {
        this.excelData = []
        this.setState({ showFunctionalDrilldownModal: false },   this.updateExcel);
    }

    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value
        }
        this.setState({
            invoiceObject: payload
        })
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false
        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }


    render() {
        let { pivotRowData, pivotHeaderData, loading, drilldownRowHeader, drilldownRowData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
      
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const { showFunctionalDrilldownModal, showLoader } = this.state
        let tableLen = pivotRowData && pivotRowData.length || 6;
        console.log(pivotRowData, "checkNestedBefore")
        pivotRowData = this.checkLevel(pivotRowData);
        console.log(pivotRowData, "checkNestedAfter")
        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                {loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                 <ReactTableComponent 

                    pivotRowData={pivotRowData}
                    className={nestedFlag ? 'newCustomTable fixedFirstColumTable tneTable expandableRows TnEAnalysis' : 'newCustomTable fixedFirstColumTable -striped -highlight'}
                    loading={loading}
                    pivotHeaderData={pivotHeaderData}
                    tableLen={tableLen}
                    subComponent={nestedFlag && this.subComponent}
                    expandIcon={this.expandIcon}
                    columnHeaderClick={this.columnHeaderClick}
                    rowClick={this.rowClick}
                    showFunctionalDrilldownModal={false}
                    showLoader={showLoader}
                 />
                }
                {showFunctionalDrilldownModal && drilldownRowHeader && drilldownRowHeader.length && (drilldownRowData && drilldownRowData.length) && (this.showDrilldownHeaderValue !== undefined) ? 
                    <FunctionDrilldownDetailModal
                        showModal={showFunctionalDrilldownModal}
                        modalCallBack={this.functionalDrilldownModalCallBack}
                        drilldownRowHeader={drilldownRowHeader}
                        drilldownRowData={drilldownRowData}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleExcelDownload={this.handleDrilldownExcelDownload}
                    /> : ''
                }
            </>
        );
    }
}

export default TableComponent;