import React, { useState } from 'react';
import { connect } from "react-redux";
import Modal from '@material-ui/core/Modal';
import { useDispatch } from "react-redux"
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    "&:invalid": {
      backgroundColor: '#ce93d8',
    },
  }
}));

export function SimpleModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.showModal || false);
  const dispatch = useDispatch();
  const [isPasswordValue, setPasswordValue] = useState();
  const [isConfirmPasswordValue, setConfirmPasswordValue] = useState();
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const { userData } = props.authReducer;
  const name = userData.name;

  const validPassword = RegExp(/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetSubmit = (event) => {
    event.preventDefault();

    let payload = {
      // new_password: event.currentTarget.password.value,
      // confirm_password: event.currentTarget.cnfPassword.value
    }

    dispatch({
      type: "PERFORM_GENERATE_PASSWORD",
      payload
    })  
  }

  const handleChangePassword = (event) => {
    const err = validPassword.test(event.target.value) ? "" : "Password must be according to specifications !"
    setPasswordValue(event.target.value)
    setError(err);
  }

  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setshowPassword(!showPassword)
    }
    if (type === 'confirmPassword') {
      setshowConfirmPassword(!showConfirmPassword)
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const body = (
    <Grid>
      <Grid item xs={6}>
        <div className='customModal changePasswordModal resetPage'>
          <div className='header'>
            <h2>Create System Generated Password</h2>
          </div>
          <div className='message'>
            <Typography variant="body2" gutterBottom>
              Dear {name},
           <Typography variant="body2" gutterBottom>
             <br />
           Welcome to xP&A Application<br /> <br/>

           Please click on Submit button to get the auto generated password on registered email id.

           <br /> 
           <br/>


           For any help or query contact support at <a href="mailto:new.support@eaciit.com">new.support@eaciit.com</a>
           </Typography>
              {/* <Typography variant="body2" >
                <ul>
                  Password Requirements:
             <li>Total password length to be 8-15 characters</li>
                  <li>At least 1 uppercase character</li>
                  <li>At least 1 lowercase character</li>
                  <li>At least 1 special character</li>
                  <li>At least 1 numerical value</li>
                  <li>Should not be same as default Password</li>
                </ul>
              </Typography> */}
            </Typography>
          </div>
          <form onSubmit={handleResetSubmit} className='modal-content'>
            {/* <FormControl variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                required
                type={showPassword ? 'text' : 'password'}
                id="password"
                onChange={(event) => { handleChangePassword(event) }}
                labelWidth={70}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('password')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {error.length > 0 && <div className='error'>{error}</div>}
            <FormControl variant="outlined">
              <InputLabel htmlFor="password">Confirm New Password</InputLabel>
              <OutlinedInput
                required
                type={showConfirmPassword ? 'text' : 'password'}
                id="cnfPassword"
                onChange={(event) => setConfirmPasswordValue(event.target.value)}
                labelWidth={70}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('confirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl> */}
            <div className={classes.root}>
              <Button
                type="submit"
                className="resetPasswordBtn"
                disabled={isPasswordValue !== isConfirmPasswordValue || error.length > 0}
                variant="contained"
                color="primary"
                size="medium"
              >Submit </Button>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={true}
      >
        <Fade in={open}>
          {body}
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(
  (state) => {
    return {
      authReducer: state.authReducer
    };
  }, () => { return {} }
)(SimpleModal);