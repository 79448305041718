import axios from 'axios';
import { CONFIG } from '../../../../../config'


export async function fetchInvoiceReportDataService(payload) {
    // let url = "list"
    // if(payload.export == true){
    //     url = "list/export"
    // }
    // return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/${url}`, payload).then(
    //     response => {
    //         return response;
    //     }
    // ).catch(err => {

    //     return err.response
    // });


    let apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/list`;
    if (payload.receivableTabSelectorValue == 1) {
        apiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/list?new=true`;
    }


    if (payload.export) {
        let exportApiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/list/export`;
        if (payload.receivableTabSelectorValue == 1) {
            exportApiUrl = `${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/list/export?new=true`;
        }
        return axios({
            url: exportApiUrl,
            method: 'POST',
            responseType: 'blob',
            data: payload
        })
            .then(
                response => {
                    var x = response.data;
                    console.log(response, x)
                    var u = URL.createObjectURL(x)
                    var a = document.createElement("a");
                    a.href = u;
                    a.download = (response.headers['content-disposition']).split('=')[1];
                    a.click();
                    window.URL.revokeObjectURL(u);
                    return response
                }
            )
            .catch(err => err.response)
    }

    return axios.post(apiUrl, payload)
        .then(response => response)
        .catch(err => err.response);
}

export async function performFetchDropDown(payload) {


    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/optimusdropdown`)
        .then(response => response)
        .catch(err => err.response);
}

export async function fetchAllCommentHistoryService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function createChannelService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/invoice/channel/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchOneCommentHistoryService(payload) {

    return axios.get(`${CONFIG.api_base_url}v1/chat/channel/history/${payload.channelId}`).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function sendCollectionForecastService(payload) {

    return axios.put(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/collection/forecast/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function sendCollectionForecastInvoiceService(payload) {

    return axios.put(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/collection/forecast/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function updateActionOwnerService(payload) {

    return axios.patch(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/actionowner/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function updateDropDownService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/automation/updateinvoicelist`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}
export async function getInvoiceListFilterService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/invoice/report/list/filter`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function getReceivablesTornadoDataAction(payload) {
    console.log(payload, "cekcekpayload")
    let apiUrl = `chart/actualforecast-tornado?report_date=${payload.selectedDate}`
    if (payload && payload.receivableTabSelectorValue == 1) {
        apiUrl = `chart/New/actualforecast-tornado?report_date=${payload.selectedDate}`
    }
    let limitObj = {
        'Top 20': '20',
        'Top 40': '40',
        'All': 'all'
    }
    if (payload.radioType == "SLB") {
        apiUrl += `&parent_group=${payload.radioType}`
    }

    if (payload.currentLimit) {

        apiUrl += `&limit=${limitObj[payload.currentLimit]}`
    }
    return axios.get(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/${apiUrl}`)
        .then(
            response => {
                return response;
            }
        ).catch(err => {
            return err.response
        });
}
