import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Pagination from "../Pagination/Pagination";
import { handleTableTransform } from '../../../../utils/numberUtils'
import { formatDate } from "../../../../utils/dateFormat";

import DownloadExcel from "../../../../images/download_excel.svg";
// import { downloadExcel, mapExcelData, mapExcelHeader, downloadExcelMultiSheet } from '../../../../utils/exportExcel'
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import { format, parseJSON } from "date-fns";
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function SimpleModal(props) {
    let tableData = [];
    const { loading } = props.generalTableState;

    const handleClose = () => {
        props.modalCallBack(false);
    };



    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            tableData = data.map((item, i) => {
                return {
                    Header: <div className='flex ai-center jc-center'>
                        <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                        {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                        {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                    </div>,
                    HeaderLabel: item.label,
                    id: item.key,
                    // width: 110,
                    Cell: (props) => {
                        item['operation'] = ((item.key == 'usd') || (item.key == 'amountinlocalcurrency_new')) ? 7 : '';

                        if (item.key == 'usd') {
                            return (
                                <Tooltip title={props.original.gl_available ? 'Click here to open GL drill down' : 'No drill down available'}>
                                    <div className='text-center pointer'>
                                        {handleTableTransform(props.original, item)}
                                    </div>
                                </Tooltip>
                            )
                        }

                        return (
                            <div className='text-center'>
                                {handleTableTransform(props.original, item)}
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: "break-spaces",
                        overflow: "visible",
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }
            })
        }
        return []
    }

    const validateDate = (data) => {
        if (data.indexOf("1901") > -1) {
            return ""
        }

        return data
    }

    const convertNumberToDate = (data) => {
        data = data.map(x => {
            if (x.document_date_format && x.document_date_format.indexOf("/") == -1 && x.document_date_format != "")
                x.document_date_format = validateDate(format(parseJSON(x.document_date_format), "dd/MM/yyyy"))

            if (x.posting_date_new && x.posting_date_new.indexOf("/") == -1 && x.posting_date_new != "")
                x.posting_date_new = validateDate(format(parseJSON(x.posting_date_new), "dd/MM/yyyy"))

                if(x.created_at){
                    x.created_at = formatDate(x.created_at)
                }


            return x
        })
        return data
    }


    let columnDataValue = [];
    
        changeKeysOfHeader(props.generalTableState && props.generalTableState.rowHeaderData);
        columnDataValue = props.generalTableState && props.generalTableState.rowData ? convertNumberToDate(props.generalTableState.rowData) : []
    


    const handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            props.handleInvoiceSorting(colInfo.id)
        }
    }



    const columnHeaderClick = (state, someData, colInfo) => {

        return {
            onClick: (e) => {
                handleColumnHeaderClick(colInfo)
            },
            style: { cursor: 'pointer' }
        }
    }





    const body = (
        <div className='customModal'>
            <div className='header'>
                {<h2>{''}</h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content pt-5'>
                <div className='text-right'>
                    <Tooltip title={'Download to Excel'}>
                        <img
                            src={DownloadExcel}
                            className='pointer'
                            height='30'
                            onClick={(event) => {
                                event.preventDefault();
                                // handleExcelDownload();
                            }} />
                    </Tooltip>
                </div>
                {
                        <Grid item xs={12}>
                            <ReactTable
                                columns={tableData}
                                data={columnDataValue}
                                defaultPageSize={10}
                                // pageSize={columnDataValue.length}
                                loading={loading}
                                className="-striped -highlight"
                                PaginationComponent={columnDataValue ? Pagination : null}
                                sortable={false}
                                // getTheadThProps={columnHeaderClick}

                            />
                        </Grid>
                    }

            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
