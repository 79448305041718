import React, { useEffect } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';



const DragDropContextContainer = styled.div`
  padding: 20px;
  border: 4px solid indianred;
  border-radius: 6px;
`;

const ListGrid = styled.div`
  display: flex;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

// fake data generator
const statusArr = ["blank", "late", "completed", "ahead"];
const getItems = (count, prefix) =>
  Array.from({ length: count }, (v, k) => k).map((k) => {
    const randomId = Math.floor(Math.random() * 1000);
    const randomStatus = Math.floor(Math.random() * statusArr.length);
    return {
      status: statusArr[randomStatus],
      id: `item-${randomId}`,
      prefix,
      content: `item ${randomId}`
    };
  });

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const lists = ["Ideation", "Feasible", "Under Execution", "Achieved", "Closed"];

const generateLists = () =>
  lists.reduce(
    (acc, listKey) => ({ ...acc, [listKey]: getItems(10, listKey) }),
    {}
  );

function DragList(props) {
  const ecfmState = useSelector(state => state.ecfmState);
  const { cadenceBoardSortedData, cadenceBoardLoading } = ecfmState
  // const [elements, setElements] = React.useState(cadenceBoardSortedData);

  console.log ('data',cadenceBoardSortedData)

  useEffect(() => {
    // setElements(generateLists())
  }, []);

  const onDragEnd = (result) => {

    const data = cadenceBoardSortedData[result.source.droppableId].find(el => el._id = result.draggableId)
    if (result.destination.droppableId != data.status){
      data['status'] = result.destination.droppableId;
      delete data['audit_trail'];
      delete data['date_status'];
      delete data['last_updated_at'];
      data['actual_cost'] = 2;
      data['plan_title'] = "";
      data['user_name'] = "";
      props.handleChangeBoard(data)
    }

    
    // if (!result.destination) {
    //   return;
    // }
    // const listCopy = { ...elements };

    // const sourceList = listCopy[result.source.droppableId];
    // const [removedElement, newSourceList] = removeFromList(
    //   sourceList,
    //   result.source.index
    // );
    // listCopy[result.source.droppableId] = newSourceList;
    // const destinationList = listCopy[result.destination.droppableId];
    // listCopy[result.destination.droppableId] = addToList(
    //   destinationList,
    //   result.destination.index,
    //   removedElement
    // );

    // setElements(listCopy);
  };

  return (
    <DragDropContextContainer className="dnd-cadence-board">
          {cadenceBoardLoading ? <div  className='text-center mt-10'><CircularProgress /></div> : 
        <DragDropContext onDragEnd={onDragEnd} >
          <ListGrid>
           { lists.map((listKey) => (
              <DraggableElement
                elements={cadenceBoardSortedData[listKey]}
                key={listKey}
                prefix={listKey}
                handleOpen={props.handleOpen}
                planTypeValue={props.planTypeValue}
                dateStatusSelected = {props.dateStatusSelected}
              />
            ))
           }
          </ListGrid>
        </DragDropContext>
          }
      </DragDropContextContainer>
  );
}

export default DragList;
