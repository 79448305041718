import React from 'react';
import "react-table-6/react-table.css";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Grid, Box, InputLabel, MenuItem,  Select, Button } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function SimpleModal(props) {
    const {emailTemplateData} = props
    const [emailTitle, setEmailTitle] = useState('')
    const [editorMsgBody, setEditorMsgBody] = useState(null)

    React.useEffect(() => {
        if (props.viewEmail && Object.keys(emailTemplateData).length > 0) {
            setEmailTitle(emailTemplateData.email_title);
        }
    }, [emailTemplateData])

    const closeViewEmail = () => {
        props.modalCallBack(false);
    };

    const handleSubmitTemplate = (event) => {
        event.preventDefault();
        let payload = {
            type: props.emailType,
            // cc_emails: [event.currentTarget.cc_emails.value],
            email_title: emailTitle,//event.currentTarget.email_title.value,
            // email_salutation: event.currentTarget.email_salutation.value,
            email_msg: editorMsgBody.getData(),
            // email_signature: event.currentTarget.email_signature.value
        }
        // console.log("payload :: ",payload, " :emailTitle: ",emailTitle)
        props.saveEmailTemplate(payload);
        closeViewEmail();
    };

return (
    <div>
        <Modal
            open={props.viewEmail}
            onClose={closeViewEmail}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.viewEmail}>
                {
                // tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> :
                    <div className='customModal viewEmailModal'>
                        <div className='header'>
                            <h2>View Email</h2>
                        </div>
                        <div className='modal-content email-template'>
                            <form onSubmit={handleSubmitTemplate}>
                                <TextField
                                    id="email_title"
                                    label="Title of Email"
                                    style={{ margin: 8 }}
                                    placeholder=""
                                    helperText=""
                                    fullWidth
                                    // defaultValue={emailTemplateData.email_title}
                                    // value={emailTemplateData.email_title}
                                    value={emailTitle}
                                    onChange={(newValue) => {
                                        setEmailTitle(newValue.target.value);
                                    }}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="email_salutation"
                                    label="Salutation"
                                    style={{ margin: 8 }}
                                    defaultValue="Dear username,"
                                    margin="normal"
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormControl style={{ marginTop: 8, marginLeft: 10 }}>
                                    <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                    <CKEditor
                                        config={{
                                            removePlugins: ['Link', 'EasyImage', 'CKFinder', 'Indent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                        }}
                                        id="editor-msg-body"
                                        name="emailView"
                                        editor={ClassicEditor}
                                        data={emailTemplateData.email_msg}
                                        // data={editorMsgBody}
                                        onReady={editor => {
                                            setEditorMsgBody(editor);
                                        }}
                                    />
                                </FormControl>
                                <p className="ml-10">{emailTemplateData.app_link}</p>

                                <TextField
                                    id="email_signature"
                                    label="Signature"
                                    style={{ margin: 10 }}
                                    defaultValue={`Thanks - Shree Cement Support `}
                                    margin="normal"
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <div className="flex jc-flex-end mt-30">
                                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                                    <Button variant="contained" className='ml-16'onClick={() => { closeViewEmail() }}>
                                     {/* onClick={this.closeViewEmail}> */}
                                        Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                }

            </Fade>
        </Modal>
    </div>
    );
}