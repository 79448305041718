export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

// Calculates significant figures with suffixes K/M/B/T, e.g. 1234 = 1.23K
export const convertNumber = (num, fixed) => {
    if (num === null) {
        return null;
    } // terminate early
    if (num === 0) {
        return "0";
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ["", "K", "M", "B", "T"][k]; // append power

    return e;
};

export const getCommaSeparatedTwoDecimalsNumber = (number) => {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommas = (x) => {
    if (x && (x >= 1000 || x <= -1000) ) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else  if (x){
        return x.toString()
    }

    return "";
};

export const handleTableTransform = (_data, item) => {
    if (item.operation && _data) {
        let modifiedData;
        let columnData = item.key == "base_amount" || item.key == "tran_amount" ? _data["amount"] : _data[item.key];
        switch (item.operation) {
            case 0:
                modifiedData = item.key;
                break;

            case 1:
                modifiedData = Math.round(columnData);
                break;

            case 2:
                modifiedData = numberWithCommas(columnData);
                break;

            case 4:
                modifiedData = modifiedData < 0 ? "(" + Math.abs(columnData) + ")" : columnData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZero(modifiedData, item) : modifiedData;
                break;

            case 3:
                modifiedData = Math.round(columnData);
                modifiedData = numberWithCommas(modifiedData);
                break;

            case 5:
                modifiedData = Math.round(columnData);
                modifiedData = modifiedData < 0 ? "(" + Math.abs(modifiedData) + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZero(modifiedData, item) : modifiedData;
                break;

            case 6:
                var isNegative = columnData < 0;
                var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative ? "(" + modifiedData + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZero(modifiedData, item) : modifiedData;
                break;

            case 7:
                modifiedData = Math.round(columnData);
                var isNegative = modifiedData < 0;
                var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative ? "(" + modifiedData + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZero(modifiedData, item) : modifiedData;
                modifiedData = modifiedData < 1 ? "-" : modifiedData;
                break;

            default:
                break;
        }

        return modifiedData;
    }
    return _data && _data[item.key] ? _data[item.key] : "-";
};

export const checkNan = (data) => {
    if (typeof data == "string") {
        if (data == "NaN") {
            return 0;
        }
    } else if (isNaN(data)) {
        return 0;
    }

    return data;
};

const handleHyphenOrZero = (data, item) => {
    if (item.key == "grand_total") {
        return "0";
    }

    return "-";
};

export const handleTableTransformV2 = (_data, item) => {
    if (item.operation && _data) {
        let modifiedData;
        let columnData = _data[item.key];
        switch (item.operation) {
            case 0:
                modifiedData = item.key;
                break;

            case 1:
                modifiedData = Math.round(columnData);
                break;

            case 2:
                modifiedData = numberWithCommas(columnData);
                break;

            case 4:
                modifiedData = modifiedData < 0 ? "(" + Math.abs(columnData) + ")" : columnData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZeroV2(modifiedData, item) : modifiedData;
                break;

            case 3:
                modifiedData = Math.round(columnData);
                modifiedData = numberWithCommas(modifiedData);
                break;

            case 5:
                modifiedData = Math.round(columnData);
                modifiedData = modifiedData < 0 ? "(" + Math.abs(modifiedData) + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZeroV2(modifiedData, item) : modifiedData;
                break;

            case 6:
                var isNegative = columnData < 0;
                var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative ? "(" + modifiedData + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZeroV2(modifiedData, item) : modifiedData;
                break;

            case 7:
                modifiedData = Math.round(columnData);
                var isNegative = modifiedData < 0;
                var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative ? "(" + modifiedData + ")" : modifiedData;
                modifiedData = modifiedData == "0" ? handleHyphenOrZeroV2(modifiedData, item) : modifiedData;
                break;

            default:
                break;
        }

        return checkNanV2(modifiedData);
    }
    return _data && _data[item.key];
};

export const checkNanV2 = (data) => {
    if (typeof data == "string") {
        if (data == "NaN") {
            return "N/A";
        }
    } else if (isNaN(data)) {
        return 0;
    }

    return data;
};

const handleHyphenOrZeroV2 = (data, item) => {
    if (item.key == "grand_total") {
        return "0";
    }

    return "N/A";
};

export const parsePrice = (price) => {
    if (typeof price === "number") {
        let num = Math.round(price);
        const isNegative = num < 0;
        num = Math.abs(num);
        num = num.toString().replace(/[^0-9.]/g, "");
        if (num < 1000) {
            return num;
        }
        let si = [
            { v: 1e3, s: "K" },
            { v: 1e6, s: "M" },
            { v: 1e9, s: "B" },
            { v: 1e12, s: "T" },
            { v: 1e15, s: "P" },
            { v: 1e18, s: "E" },
        ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
                break;
            }
        }
        const result = "$" + (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
        return isNegative ? `(${result})` : result;
    } else return 0;
};

export const parseNumber = (number, shouldRound = true) => {
    if (typeof number === "number") {
        let num = shouldRound ? Math.round(number) : number;
        const isNegative = num < 0;
        num = Math.abs(num);
        return isNegative ? `(${num})` : num;
    }
    return 0;
};
