import React from 'react';
import {useTable, useExpanded} from 'react-table';
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import './style.scss';

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

function NewReactTable({ columns: userColumns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns: userColumns,
			data,
		},
		useExpanded, // Use the useExpanded plugin hook
        useSticky
	);
	return (
    <div className="table-wrapper summary-table-pab">
      <table
          className='newNewCustomTable fixedFirstColumTable expandableRows'
          {...getTableProps()}
      >
          <thead>
              {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                          <th className={'header-border-right'} {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                  </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
              {rows && rows.length ? rows.map((row, i) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                              return (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              );
                          })}
                      </tr>
                  );
              }) : null}
          </tbody>
      </table>
    </div>
	);
}

export default NewReactTable;