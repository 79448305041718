import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartContainer from "./chartContainer"
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { convertNumber } from '../../../../utils/numberUtils'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function subTitleHeading(data)  {
  var subtitle = '<div class="summary"><span class="bu-keys title">Improvement in last 6 months : </span></div>'
  let subChartLen = data.length
  let classSummary = subChartLen > 1 ? "list-item" : ""
  data.map((value, index) => {
  let modifiedData = Math.round(value.value)
  let isNegative = modifiedData < 0
  var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
  let className = isNegative ? 'text-red' : 'text-green'
  subtitle += '<div class="summary '+classSummary+'"><span class="bu-keys">' + value.key + '</span>  <span class=' + className + '>' + convertNumber(positiveTransformedValue) + '</span></div>'
 });
  return '<div class="summary-content">'+subtitle+'</div>'
}
export class OverdueComponent extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      externalTabValue: 0,
      chartDataBilled: [],
      chartDataUnbilled: [],
      chartTitleBilledDefault: " - Overdue Analysis <br/>All Activity",
      chartTitleBilled: "",
      chartTitleUnbilledDefault: " - Overdue Analysis <br/>All Activity",
      chartTitleUnbilled: "",
      RegionView: 'month',
      viewWidth: 0,
      chartSubTitleBilled: "",
      chartSubTitleUnBilled: "",
    }
    this.reverseBUnitIndex = {
      0: "Summary",
      1: "BU 1",
      2: "BU 2",
      3: "BU 3",
      4: "BU 4",
    }
    this.reverseBUnitIndexNew = {
      0: "Summary",
      1: "New BU 1",
      2: "New BU 2",
      3: "Unallocated",
    }
    this.reverseBUnitIndexLabel = {
      0: "Summary",
      1: "BU 1",
      2: "BU 2",
      3: "BU 3",
      4: "BU 4",
    }
  }

  setTitleChart = (bu) => {
    const { chartTitleBilledDefault, chartTitleUnbilledDefault } = this.state
    this.setState({
      chartTitleBilled: "Billed " + bu + chartTitleBilledDefault,
      chartTitleUnbilled: "Unbilled " + bu + chartTitleUnbilledDefault,
    })
  }

  handleExternalTabChange = (event, newValue) => {
    this.setState({ externalTabValue: newValue }, this.fetchChartData);
    console.log(newValue)
  };

  fetchChartData = () => {
    let payload = {}
    let bu = this.reverseBUnitIndex[this.state.externalTabValue]
    let tittle = this.reverseBUnitIndexLabel[this.state.externalTabValue]
    if(this.props.tabSelectorValue == 1){
      bu = this.reverseBUnitIndexNew[this.state.externalTabValue]
      tittle = this.reverseBUnitIndexNew[this.state.externalTabValue]
    }
    payload['bu'] = bu;
    payload['chartType'] = this.props.tabSelectorValue
    if (this.state.externalTabValue == 0) {
      this.props.getOverDueSummary(payload)
    } else {
      this.props.getOverDueByBU(payload)
    }
    this.setTitleChart(tittle)
  }

  componentDidMount() {

    let payload = {}
    payload['receivablesTabValue'] = this.props.tabSelectorValue
    // this.props.getOverDueSummary()
    this.fetchChartData()
    this.setTitleChart("Summary")
  }

  refreshChart = () => {
    if (this.state.RegionView == 'region') {
      this.setState({
        chartDataBilled: this.props.overDueState.data.totalbilled.row_data_area,
        chartDataUnbilled: this.props.overDueState.data.totalunbilled.row_data_area
      })
    } else {
      this.setState({
        chartDataBilled: this.props.overDueState.data.totalbilled.row_data_default,
        chartDataUnbilled: this.props.overDueState.data.totalunbilled.row_data_default,

        chartSubTitleBilled: subTitleHeading(this.props.overDueState.data.overdue_improvement.billed_improvement),
        chartSubTitleUnBilled: subTitleHeading(this.props.overDueState.data.overdue_improvement.unbilled_improvement),
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.overDueState.data.totalbilled) {
      if (prevProps.overDueState.loading && !this.props.overDueState.loading)
        this.refreshChart()
    }
    let myWidth = this.container.offsetWidth
    const { viewWidth } = this.state

    if (viewWidth != myWidth && !prevProps.overDueState.loading) { //refresh chart when the width change
      console.log(viewWidth, myWidth, "cekWidth")
      this.setState({ viewWidth: myWidth })
      console.log(viewWidth, myWidth, "cekWidthAfter")
      if (viewWidth != 0 && viewWidth < myWidth) {
        this.fetchChartData()
      }
    }

  }

  handleChangeView = (event) => {
    this.setState({ RegionView: event.target.value }, this.refreshChart);
  };

  

  render() {
    const { externalTabValue } = this.state
    return (
      <>
        <Tabs
          ref={el => (this.container = el)}
          value={externalTabValue}
          indicatorColor="primary"
          textColor="primary"
          className='mb-10'
          onChange={this.handleExternalTabChange}
        >
          
              
          <LinkTab label="Summary" href="/summary" className="list-nested" {...a11yProps("sum")} />
          {this.props.tabSelectorValue == 0 && <LinkTab label="BU 1" href="/msm" className="list-nested" {...a11yProps(0)} />}
          {this.props.tabSelectorValue == 0 && <LinkTab label="BU 2" href="/lcs" className="list-nested" {...a11yProps(1)} />}
          {this.props.tabSelectorValue == 0 && <LinkTab label="BU 3" href="/dgs" className="list-nested" {...a11yProps(2)} />}
          {this.props.tabSelectorValue == 0 && <LinkTab label="BU 4" href="/pab" className="list-nested" {...a11yProps(3)} /> }
          {this.props.tabSelectorValue == 1 && <LinkTab label="New BU 1" href="/dass" className="list-nested" {...a11yProps(0)} />}
          {this.props.tabSelectorValue == 1 && <LinkTab label="New BU 2" href="/cp" className="list-nested" {...a11yProps(1)} />}
          {this.props.tabSelectorValue == 1 && <LinkTab label="Unallocated" href="/unallocated" className="list-nested" {...a11yProps(2)} />}

        </Tabs>

        <FormControl component="fieldset">
          <RadioGroup name="radio" onChange={this.handleChangeView} value={this.state.RegionView} className='radioGroupHorizontal'>
            <FormControlLabel value="month" control={<Radio color="primary" />} label="Billing View" />
            <FormControlLabel value="region" control={<Radio color="primary" />} label="Region View" />
          </RadioGroup>
        </FormControl>

        <TabPanel value={externalTabValue} index={0}>
          <ChartContainer
            chartDataBilled={this.state.chartDataBilled}
            chartDataUnbilled={this.state.chartDataUnbilled}
            chartTitleBilled={this.state.chartTitleBilled}
            chartSubTitleBilled={this.state.chartSubTitleBilled}
            chartTitleUnbilled={this.state.chartTitleUnbilled}
            chartSubTitleUnBilled={this.state.chartSubTitleUnBilled}
            loading={this.props.overDueState.loading}
          ></ChartContainer>
        </TabPanel>
        <TabPanel value={externalTabValue} index={1}>
          <ChartContainer
            chartDataBilled={this.state.chartDataBilled}
            chartDataUnbilled={this.state.chartDataUnbilled}
            chartTitleBilled={this.state.chartTitleBilled}
            chartSubTitleBilled={this.state.chartSubTitleBilled}
            chartTitleUnbilled={this.state.chartTitleUnbilled}
            chartSubTitleUnBilled={this.state.chartSubTitleUnBilled}
            loading={this.props.overDueState.loading}
          ></ChartContainer>
        </TabPanel>
        <TabPanel value={externalTabValue} index={2}>
          <ChartContainer
            chartDataBilled={this.state.chartDataBilled}
            chartDataUnbilled={this.state.chartDataUnbilled}
            chartTitleBilled={this.state.chartTitleBilled}
            chartSubTitleBilled={this.state.chartSubTitleBilled}
            chartTitleUnbilled={this.state.chartTitleUnbilled}
            chartSubTitleUnBilled={this.state.chartSubTitleUnBilled}
            loading={this.props.overDueState.loading}
          ></ChartContainer>
        </TabPanel>
        <TabPanel value={externalTabValue} index={3}>
          <ChartContainer
            chartDataBilled={this.state.chartDataBilled}
            chartDataUnbilled={this.state.chartDataUnbilled}
            chartTitleBilled={this.state.chartTitleBilled}
            chartSubTitleBilled={this.state.chartSubTitleBilled}
            chartTitleUnbilled={this.state.chartTitleUnbilled}
            chartSubTitleUnBilled={this.state.chartSubTitleUnBilled}
            loading={this.props.overDueState.loading}
          ></ChartContainer>
        </TabPanel>
        <TabPanel value={externalTabValue} index={4}>
          <ChartContainer
            chartDataBilled={this.state.chartDataBilled}
            chartDataUnbilled={this.state.chartDataUnbilled}
            chartTitleBilled={this.state.chartTitleBilled}
            chartSubTitleBilled={this.state.chartSubTitleBilled}
            chartTitleUnbilled={this.state.chartTitleUnbilled}
            chartSubTitleUnBilled={this.state.chartSubTitleUnBilled}
            loading={this.props.overDueState.loading}
          ></ChartContainer>
        </TabPanel>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        overDueState: state.dashboardState.dashboardAnalytic.OverdueAnalysis,
      }
    },
    (dispatch) => {
      return {
        getOverDueSummary(data) {
          dispatch({
            type: "FETCH_OVERDUE_CHART",
            payload: data,
          });
        },
        getOverDueByBU(data) {
          dispatch({
            type: "FETCH_OVERDUE_CHART_BY_BU",
            payload: data,
          });
        }
      }
    }
  )(OverdueComponent)
);