
import React from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../ImprovementPlan/comment';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableComponent from './table'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };


export class DetailedPlanComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: [],
            loading: true,
        }
    }

    handleFetchHistory = (data) =>{
        let payload ={
            plan_id : data._id
        }
        // console.log("--ini data nya--", data)
        this.props.getHistoryData(payload)
    }
    

    render() {
        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <div className="cadenceBoard mt-20">
                <TableComponent handleFetchHistory={this.handleFetchHistory} menuData={menuData} saveDate={this.props.saveDate} />
               
            </div>
            
        )
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state.dashboardState.receivableData.ageingData,
                authReducer: state.authReducer,
                ecfmState : state.ecfmState,
            }
        },
        dispatch => {
            return {
                getHistoryData(data) {
                    dispatch({
                        type: "FETCH_IMPROVEMENT_PLAN_HISTORY",
                        payload: data,
                    });
                },
                getCadenceBoardData(data) {
                    dispatch({
                        type: 'FETCH_CADENCE_BOARD_DATA',
                        payload: data,
                    });
                },
                getFormPlanId(data) {
                    dispatch({
                        type: "FETCH_CREATE_PLAN_ID_DATA",
                        payload: data,
                    });
                },
                saveCreatePlanData(data) {
                    dispatch({
                        type: "SAVE_PLAN_FORM_DROPDOWN_DATA",
                        payload: data,
                    });
                },
            };
        }
    )(DetailedPlanComponent);