import React from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {handleTableTransform} from '../../../../../utils/numberUtils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {format} from 'date-fns';
import parseJSON from 'date-fns/parseJSON';
import Typography from '@material-ui/core/Typography';
import DownloadExcel from '../../../../../images/download_excel.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from "../../../../../utils/dateFormat";
import NewPagination from "../../../../common/components/CustomPagination";


export default function SimpleModal(props) {
  let tableData = [];
  const {loading} = props.invoiceDetailState;

  const handleClose = () => {
    props.modalCallBack(false);
  };

  const handleDynamicWidth = (item) => {
    let widthSize;
    if (item.key.includes('company_code')) {
      widthSize = 80;
    }
    if (item.key.includes('customer_id')) {
      widthSize = 80;
    }

    return widthSize;
  };

  const changeKeysOfHeader = (data, type) => {
    if (data && data.length) {
      tableData = data.map((item, i) => {
        if (item.key == 'comment') {
          return {
            Header: item.label,
            id: item.key,
            width: handleDynamicWidth(item),
            headerStyle: {
              whiteSpace: 'break-spaces',
              overflow: 'visible',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            style: {
              whiteSpace: 'break-spaces',
              width: '50px !important',
              overflow: 'visible',
              fontSize: '11px',
              textAlign: 'left',
            },
            accessor: item.key,
            Cell: (props) => {
              return (
                  <>
                  <p>{props.original.comment_originator}, {formatDate(props.original.comment_date)} <br></br>
                  {props.original.comment}</p>
                  </>
             )

             
          },
          };

        }

        return {
          Header: <div className="flex ai-center jc-center">
            <span style={{lineHeight: 1.5}}>{item.label}</span>
            {item.isSorted && item.isSorted === 'asc' &&
            <ArrowDropDownIcon fontSize="small"/>}
            {item.isSorted && item.isSorted === 'desc' &&
            <ArrowDropUpIcon fontSize="small"/>}
          </div>,
          id: item.key,
          width: handleDynamicWidth(item),
          Cell: (props) => {
            return (

                <div className="text-center">
                  {item.key == 'viewnetduedate' ?
                     props.original['netduedate'] :
                      handleTableTransform(props.original, item)
                  }
                </div>
            );
          },
          style: {
            fontSize: '12px',
            whiteSpace: 'break-spaces',
            width: '50px !important',
            overflow: 'visible',
          },
          headerStyle: {
            whiteSpace: 'break-spaces',
            overflow: 'visible',
            fontSize: '13px',
            fontWeight: 'bold',
          },
        };
      });

    }
    return [];
  };

  changeKeysOfHeader(props.invoiceDetailState.data.row_header);

  const handleColumnHeaderClick = (colInfo) => {
    if (colInfo.id && colInfo.id != 'grand_total') {
      props.handleInvoiceSorting(colInfo.id);
    }
  };

  const columnHeaderClick = (state, someData, colInfo) => {
    return {
      onClick: (e) => {
        handleColumnHeaderClick(colInfo);
      },
      style: {cursor: 'pointer'},
    };
  };

  const columnHeaderClick2 = (state, someData, colInfo) => {
    return {};
  };

  const handleExcelDownload = () => {
    if (props.isCustomerNamePopup) {
      props.handleDownloadFromBECallback();
      return;
    }
  };

  let rowData = props.invoiceDetailState.data && props.invoiceDetailState.data.row_data;
  const activePage = props.activePage;
  const body = (
      <div className="customModal">
        <div className="header">
          {props.customerName ?
              <h2>{`View ${props.customerName} ${props.agingCategoryValue}`}</h2> :
              <h2/>}
          <Icon onClick={handleClose}>close</Icon>
        </div>
        <div className="modal-content modalData">
          {
            props.isCustomerNamePopup && (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Tooltip title={'Download to Excel'}>
                    <img
                        src={DownloadExcel}
                        className="pointer"
                        height="30"
                        onClick={(event) => {
                          event.preventDefault();
                          handleExcelDownload();
                        }}/>
                  </Tooltip>
                </div>
            )
          }
          {
            // props.parentGroup == 'unbilled' && (props.buValue == 'dgs' || props.buValue == 'lcs' || props.buValue == 'pab') ?
            //     <Typography align="center" variant="h6">Invoice Level Details Not Available.</Typography> :
                <ReactTable
                    columns={tableData}
                    data={props.invoiceDetailState.data.row_data ? props.invoiceDetailState.data.row_data : []}
                    defaultPageSize={50}
                    pageSize={rowData && rowData.length < 50 ? rowData.length : 50}
                    loading={loading}
                    className="xpna-react-table -striped -highlight"
                    PaginationComponent={NewPagination}
                    pages={props.invoiceDetailState?.data?.total_count ? Math.ceil(props.invoiceDetailState.data.total_count / 5) : 0}
                    onPageChange={props.handleTablePageChange}
                    sortable={false}
                    getTheadThProps={props.isCustomerNamePopup ? columnHeaderClick2 : columnHeaderClick}
                />
          }
        </div>
      </div>
  );

  return (
      <div>
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={props.showModal}>
            {body}
          </Fade>
        </Modal>
      </div>
  );
}
