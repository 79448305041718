import { put, takeLatest, call } from 'redux-saga/effects';
import { store } from '../../../index';
import { toastr } from "react-redux-toastr";
import { changeValuesOfData, setAllRowCommentHistoryData} from './transformer'
import { getAllCommentHistoryData } from '../../../utils/commentDataUtil'

import {
    fetchBUPlIncomeStatementPivotService,
    fetchAllBUPLIncomeService,
    fetchMonthlyQuarterBUPLService,
    fetchCountryPlantBUPLService,
    fetchDefaultMonthValueService,
    uploadExcelFileService,
    uploadNewBUFourReportDataService,
    performFetchBUPLInvoiceDetailService,
    fetchAllCommentHistoryService,
    performFetchLawsonDetail,
    performFetchOptimusAutoDetail,
    fetchNewBUFourDrilldownDataService,
    performFetchPABValidationDetail,
    fetchNewBUFourByPMDataService,
    fetchNewBUFourByLocationDataService,
    fetchNewBUFourTargetReportDataService,
    saveXPNACommentService,
    fetchNewBUFourReportDataService,
    performFetchDropDown,
    performFetchBUPLComparativeService,
    performFetchAutomationIS,
    performFetchAutomationNew,
    performFetchAutomationDSO,
    performFetchAutomationNewDSO,
    performFetchAutomationNewDSOGeoSummary,
    performFetchPaymentData,
    performFetchBUPLInvoiceDetailServiceGL,
    performFetchBUPLComparativePlantService,
    fetchDefaultMonthValueHFMService,
    fetchBudgetGroupService,
    fetchFunctionalPivotService,
    fetchFunctionalSummaryService,
    sendPivotComment,
    fetchNewOriginationDrilldownService,
    sendFunctionalComment,
    fetchTotalnewConsolidationService,
    performFetchAutomationReceivableService,
    performFetchAutomationNewReceivableService,
    performPostManualReceivableAmountService,
    performPostManualReceivableDSOAmountService,
    performPostManualISAmountService,
    fetchFunctionalBudgetGraphService,
    performFetchBuplValidationDataService,
    performPostBuplValidationAmountService,
    fetchFunctionaDrilldownService,
    fetchFunctionaSTDrilldownService,
    downloadFunctionaDrilldownService,
    downloadFunctionaSTDrilldownService,
    fetchNewDefaultMonthValueService,
    fetchNewDefaultMonthValueServiceDynamic,
    fetchAllNewService,
    fetchAllNewOCOSSummary,
    fetchAllNewDGSLawson,
    fetchAllOptimusAutomation,
    fetchAllChartSO,
    fetchNewDrilldownService,
    saveNewDrilldownService,
    fetchNewSubHeaderDrilldownService,
    downloadExcelAllNewService,
    fetchSalesTechnologyPivotService,
    fetchNewPieDataService,
    fetchSalesTechnologyPieDataService,
    postNewManualAmountDataService,
    performPostManualNewAmountService,
    postNewCommentDataService,
    postNewOriginationCommentDataService,
    postSalesTechnologyTornadoDataAction,
    performFetchAutomationNewSummary,
    fetchAOPNewService,
    fetchAOPNewDestinationService,
    fetchAdhocChartDataService,
    fetchServiceRevenueProfitabilityService,
    fetchTAndEAnalysisService,
    fetchTAndEAnalysisDrillDownService,
    fetchOvertimeAnalysisService,
    fetchOvertimeAnalysisDrillDownService,
    fetchServicesRevenueByRegionService,
    fetchDrillDownServicesRevenueByRegionService,
    fetchOrderBookDataService,
    fetchDrillDownOrderBookAnalysisService,
    fetchOrderbookAnalysisComparisonService,
    fetchDassAopRevenueAnalysisService,
    fetchDassAopFrontlogService,
    fetchDassAopFrontlogDrillDownService,
    fetchSummaryDASSTableDataService,
    fetchSummaryPABBacklogTableDataService,
    fetchDASSByGeoTableDataService,
    fetchSummaryPABBacklogDrilldownService,
    fetchDASSByGeoTableDataDrilldownService,
    fetchDASSRevenueByCustomerDataDrilldownService,
    updateDASSRevenueByCustomerDataDrilldownService,
    fetchCompareDataForBUPLService,
    fetchCompareFilterOptionsService,
    performFetchExtendedPlanningActualService
} from './service'


function* fetchBUPLIncomePivotStatement(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchBUPlIncomeStatementPivotService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchBUPLIncomePivotStatementAll(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchAllBUPLIncomeService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchNewPivotStatementAll(data) {
    // if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    // }
    const response = yield call(fetchAllNewService, data.payload);
    yield put({ type: "SET_COMMENT_PAYLOAD", data: data.payload, loading: false });

    if (!data.payload.export && response && response.status == 200) {
        if(data.payload.reconValue == 2 && data.payload.loadOcosSummary) {
            const response2 = yield call(fetchAllNewOCOSSummary, data.payload);
            if (response && response.status == 200) {
                yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
                yield put({ type: "FETCH_OCOS_SUMMARY_SUCCESS", data: response2.data, loading: false });
            }
        }else{
            if(data.payload.reconValue != 6) {
                    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
            }else{
                yield put({ type: "FETCH_BUPL_INCOME_PAB_SUCCESS", data: response.data, loading: false });
            }
           
        }
        // yield put({ type: "SET_COMMENT_PAYLOAD", data: data.payload, loading: false });
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}


function* fetchNewBUFourReportData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    }
    const response = yield call(fetchNewBUFourReportDataService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });        
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}




function* saveXPNAComment(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    }
    const response = yield call(saveXPNACommentService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_New_BU_FOUR_TARGET_REPORT_DATA", payload: { 
            year: data.payload.year,
            yearmonth: data.payload.yearmonth,
            quarter: data.payload.quarter,
        }, loading: true });
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}

function* fetchNewBUFourTargetReportData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    }
    const response = yield call(fetchNewBUFourTargetReportDataService, data.payload);


    if (!data.payload.export && response && response.status == 200) {
        response.data.row_data = changeValuesOfData(response.data.row_data)
        const getCommentPayload =  getAllCommentHistoryData(0, response.data.row_data)
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
        yield put({
            type: "FETCH_COMMENT_CHANNEL_XPNA_DATA_HISTORY",
            payload: getCommentPayload,
          })        
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}


function* fetchNewBUFourByPMData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    }
    const response = yield call(fetchNewBUFourByPMDataService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });        
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}

function* fetchNewBUFourByLocationData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    }
    const response = yield call(fetchNewBUFourByLocationDataService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });        
        
    }else {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });

    }
}

function* fetchNewAOPData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    }
    const response = yield call(fetchAOPNewService, data.payload);
    
    // yield put({ type: "FETCH_New_PIVOT_ALL", payload: { year: response.data.year, apiEndPoint: 0 }, loading: false });
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });        
    }
}
function* fetchNewAOPDesData(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    }
    const response = yield call(fetchAOPNewDestinationService, data.payload);
    
    // yield put({ type: "FETCH_New_PIVOT_ALL", payload: { year: response.data.year, apiEndPoint: 0 }, loading: false });
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });        
    }
}
function* fetchNewOCOSSummary(data) {
    yield put({ type: "FETCH_OCOS_SUMMARY_LOADER", loading: true });
    const response = yield call(fetchAllNewOCOSSummary, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_OCOS_SUMMARY_SUCCESS", data: response.data, loading: false });
    }
}
function* fetchNewDGSLawson(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchAllNewDGSLawson, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_OPTIMUS_PIVOT_SUCCESS", data: response.data, loading: false });
    }
}
function* fetchNewOptimusAutomation(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchAllOptimusAutomation, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_OPTIMUS_PIVOT_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchNewChartSO(data) {
    yield put({ type: "FETCH_CHART_LOADER", loading: true });
    const response = yield call(fetchAllChartSO, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_CHART_New_SO_SUCCESS", data: response.data, loading: false });
    }
}


function* fetchNewPieData(data) {
    yield put({ type: "FETCH_new_PIE_LOADER", loading: true });
    const response = yield call(fetchNewPieDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_new_PIE_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchSalesTechnologyPieData(data) {
    yield put({ type: "FETCH_SALES_TECHNOLOGY_PIE_LOADER", loading: true });
    const response = yield call(fetchSalesTechnologyPieDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SALES_TECHNOLOGY_PIE_SUCCESS", data: response.data, loading: false });
    }
}

function* postNewManualAmountData(data) {
    yield put({ type: "POST_new_MANUAL_DATA_LOADER", loading: true });
    const response = yield call(postNewManualAmountDataService, data.payload.manualData);
    if (response && response.status == 200) {
        yield put({ type: "POST_new_MANUAL_DATA_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_New_PIVOT_ALL", payload: data.payload.mainData, loading: false });
    }
}

function* postNewCommentData(data) {
    if(data.payload.type == "chart"){

        yield put({ type: "POST_new_COMMENT_CHART_LOADER", loading: true });
    }else{

        yield put({ type: "POST_new_COMMENT_LOADER", loading: true });
    }
    const response = yield call(postNewCommentDataService, data.payload.comment);
    if (response && response.status == 200) {
        yield put({ type: "POST_new_COMMENT_DATA_SUCCESS", data: response.data, loading: false });
        // yield put({ type: "FETCH_New_DRILLDOWN_DATA", payload: data.payload.drilldown, loading: false });
    }
}

function* postNewOriginationCommentData(data) {
    yield put({ type: "POST_new_ORIGINATION_COMMENT_LOADER", loading: true });
    const response = yield call(postNewOriginationCommentDataService, data.payload);
    if (response && response.status == 200) {
        const state = store.getState();
        const incomeStatementState = state.incomeStatement;
        yield put({ type: "FETCH_New_PIVOT_ALL", payload: incomeStatementState.commentDataPayload, loading: false });
        yield put({ type: "POST_new_ORIGINATION_COMMENT_DATA_SUCCESS", data: response.data, loading: false });
        // yield put({ type: "FETCH_New_DRILLDOWN_DATA", payload: data.payload.drilldown, loading: false });
    }
}


function* uploadExcelFileUpload(data) {
    yield put({
        type: "UPLOAD_EXCEL_FILE_LOADER",
        loading: true,
        success: false,
    });
    var formData = new FormData();
    let response;

    formData.append("files", data.payload.file);
    if (data.payload.tabSelectorValue1 == 2) {
        formData.append("year_month_quarter", data.payload.report_date);
        response =  yield call(uploadNewBUFourReportDataService, formData)
    }else {
        formData.append("report_date", data.payload.report_date);
        response = yield call(uploadExcelFileService, formData)
    }
    
    if (response && response.status == 200) {
        yield put({
            type: "UPLOAD_EXCEL_FILE_SUCCESS",
            loading: false,
            data: response.data,
            showError: false
        });
        if (data.payload.tabSelectorValue1 == 2){
            yield put({ type: "FETCH_New_BU_FOUR_REPORT_DATA", payload: data.payload, loading: false });
        }
        // if (!response.data.success) {
        //     yield put({
        //         type: "UPLOAD_EXCEL_FILE_FAIL",
        //         loading: false,
        //         errors: response.data,
        //         showError: true
        //     });
        //     return
        // }
        // const ifFileSavedButWarnings = response.data.result.filter(x => x.warnings.length >= 1)
        // if (response.data.success && ifFileSavedButWarnings && ifFileSavedButWarnings.length) {
        //     toastr.success("The comment data has been saved successfully");
        //     yield put({
        //         type: "UPLOAD_EXCEL_FILE_FAIL",
        //         loading: false,
        //         errors: response.data,
        //         showError: true
        //     });
        //     return
        // }
        // toastr.success("The comment data has been saved successfully");
        // yield put({
        //     type: "UPLOAD_EXCEL_FILE_SUCCESS",
        //     loading: false,
        //     data: response.data,
        //     showError: false
        // });
    } 
    // else {
    //     toastr.error("Please download the correct template and upload again.");
    //     yield put({
    //         type: "UPLOAD_EXCEL_FILE_LOADER",
    //         loading: false,
    //         success: false,
    //     });
    // }
}





function* downloadExcelNewPivotStatementAll(data) {
    // yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(downloadExcelAllNewService, data.payload);
    // if (response && response.status == 200) {
    //     yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
    // }
}



function* fetchMonthlyQuarterBUPlStatement(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchMonthlyQuarterBUPLService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchCountryPlantBUPlStatement(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchCountryPlantBUPLService, data.payload);
    if (response && response.status == 200) {
        if(data.payload.plantType == "3"){
           
            yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_SUCCESS", data: response.data, loading: false });
          }else{

            yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
          }
    }
}

function* fetchDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchDefaultMonthValueService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_ALL", payload: { yearmonth: response.data.month }, loading: false });
    }
}

function* fetchNewDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchNewDefaultMonthValueService, data.payload);
    if (response && response.status == 200) {
        let linkToValidatePAB = localStorage.getItem('linkToValidatePAB')
        let origin = ''
        let isXPNA = ''
        let radioXPNA = ''
        origin = data.payload ? data.payload.origination : ''
        isXPNA = data.payload ? data.payload.is_xpna : ''
        radioXPNA = data.payload ? data.payload.radioXPNAValue : ''

        if (linkToValidatePAB) {
            linkToValidatePAB = JSON.parse(linkToValidatePAB)
            response.data.month = linkToValidatePAB.selectedMonth;
            yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });

            yield put({ type: "FETCH_New_PIVOT_ALL", payload: { yearmonth: response.data.month, apiEndPoint: 0, reconValue: "6",isTotal: true, tabValue:3, buPlant: "summary"}, loading: false });
        }else {
            yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });


            if(isXPNA != "true"){
                if(origin == "true"){
                    yield put({ type: "FETCH_New_PIVOT_ALL", payload: { yearmonth: response.data.month, apiEndPoint: 0, origination :  origin, mpr :  data.payload ? data.payload.mpr : "" }, loading: false });
                }else{
                    yield put({ type: "FETCH_New_PIVOT_ALL", payload: { year: response.data.year, apiEndPoint: 0, origination :  origin, mpr :  data.payload ? data.payload.mpr : "" }, loading: false });
                }
            }else{
                if(radioXPNA == "0") {
                    yield put({ type: "FETCH_New_BU_FOUR_TARGET_REPORT_DATA", payload: { yearmonth: response.data.month }, loading: true });
                }else if(radioXPNA== "1") {
                    yield put({ type: "FETCH_New_BU_FOUR_BY_LOCATION_REPORT_DATA", payload: { yearmonth: response.data.month }, loading: true });
                }else if(radioXPNA== "2") {
                    yield put({ type: "FETCH_New_BU_FOUR_BY_PM_REPORT_DATA", payload: { yearmonth: response.data.month }, loading: true });
                }
            }
        }

    }
}

function* fetchNewDefaultMonthValueDynamic(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true});
    const state = store.getState();
    const response = yield call(fetchNewDefaultMonthValueServiceDynamic, data.payload);
    // console.log(response, "fetchNewDefaultMonthValueServiceDynamic")
    if (response && response.status == 200) {
        yield put({ type: "FETCH_New_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
    }
}


function* fetchLawsonDetail(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });

    }
    const state = store.getState();
    const response = yield call(performFetchLawsonDetail, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", dataPayload: data.payload, loading: false, data: response.data });
    }
    // yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: false });
}
function* fetchDropDown(data) {
    // if(!data.payload.export){
    //     yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });

    // }
    const state = store.getState();
    const response = yield call(performFetchDropDown, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DROPDOWN_SUCCESS", dataPayload: data.payload, loading: false, data: response.data });
    }
    // yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: false });
}
function* fetchOptimusAutoDetail(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });

    }
    const state = store.getState();
    const response = yield call(performFetchOptimusAutoDetail, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        const response2 = yield call(performFetchDropDown, data.payload);
      
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", dataPayload: data.payload, loading: false, data: response.data, data2: response2.data });
    }
    // yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: false });
}
function* fetchPABValidationDetail(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });

    }
    const state = store.getState();
    const response = yield call(performFetchPABValidationDetail, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        const response2 = yield call(performFetchDropDown, data.payload);
      
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", dataPayload: data.payload, loading: false, data: response.data, data2: response2.data });
    }
    // yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: false });
}


function* fetchBUPLInvoiceDetail(data) {
    // console.log(data, "--fetchBUPLInvoiceDetail yw")
    if(!data.payload.export){
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });

    }
    const state = store.getState();
    const response = yield call(performFetchBUPLInvoiceDetailService, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", dataPayload: data.payload, loading: false, data: response.data });
    }

    const responseEP = yield call(performFetchExtendedPlanningActualService, data.payload); //Operational Drilldown
    if (!data.payload.export && responseEP && responseEP.status == 200) {
        yield put({ type: "FETCH_BUPL_EXTENDED_PLANNING_SUCCESS",  data: responseEP.data, dataPayload : data.payload });
    }
    
    // yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: false });
}


function* fetchBUPLComparativeDetail(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(performFetchBUPLComparativeService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchAutomationIS(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(performFetchAutomationIS, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchAutomationDSO(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    let response;
    if(data.payload.radioSelectorDSOValue == "legacyBU"){
        response = yield call(performFetchAutomationDSO);
    }else if(data.payload.radioSelectorDSOValue == "NewBU"){
        response = yield call(performFetchAutomationNewDSO);
    }else if(data.payload.radioSelectorDSOValue == "NewGeoSummary"){
        response = yield call(performFetchAutomationNewDSOGeoSummary);
    }
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchAutomationNew(){
    yield put({type:"FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading:true})
    const response = yield call(performFetchAutomationNewSummary);
    if(response && response.status ==200){
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading:false, data:response.data })
    }
}

function* fetchPaymentData() {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(performFetchPaymentData);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data });
    }
}
function* fetchNewDataVal(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(performFetchAutomationNew, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data, uploadLoading: false });
    }
}



function* fetchARLatestMonth(){
    const response = yield call(fetchDefaultMonthValueService);
    if(response && response.status == 200){
        yield put({type:"SET_AR_LATEST_MONTH", data: response.data.month });
    }
}

function* fetchBuplValidationData(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(performFetchBuplValidationDataService);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data, uploadLoading: false });
    }
}
function* fetchAutomationReceivable(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    let response;
    if(data.payload.radioSelectorReceivablesValue == "legacyBU"){
        response = yield call(performFetchAutomationReceivableService);
    }else{
        response = yield call(performFetchAutomationNewReceivableService, data.payload);
    }
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AUTOMATION_IS_SUCCESS", loading: false, data: response.data });
    }
}

function* postManualAmountDataSaga(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    let response;
   
    if (data.payload.isReceivable > 0) {
        if (data.payload.isReceivable == 4){

            response = yield call(performPostManualNewAmountService, data.payload);

        }else if(data.payload.isReceivable == 1){

            response = yield call(performPostManualReceivableAmountService, data.payload);

        }else{

            response = yield call(performPostManualReceivableDSOAmountService, data.payload);

        }
    } else {
        response = yield call(performPostManualISAmountService, data.payload);
    }
    if (response && response.status == 200) {
        if (data.payload.isReceivable == 1) {
            let payload =  { radioSelectorReceivablesValue: data.payload.radioSelectorReceivablesValue };
            yield put({ type: "FETCH_AUTOMATION_RECEIVABLE", payload:payload })
        }else if (data.payload.isReceivable == 2) {
            let payload = { radioSelectorDSOValue: data.payload.radioSelectorDSOValue };
            yield put({ type: "FETCH_AUTOMATION_DSO", payload: payload})
        }else if (data.payload.isReceivable == 0) {
            yield put({ type: "FETCH_AUTOMATION_IS", payload : {radioType : data.payload.radioSelectorISValue} })
        }else if(data.payload.isReceivable == 4){
            yield put({ type: "FETCH_AUTOMATION_new_DATA_VALIDATION", payload:{
                apiEndPoint: 2
              } })

        }
    }
}

function* fetchBUPLDetailGL(data) {
    yield put({ type: "FETCH_BUPL_DETAIL_GL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(performFetchBUPLInvoiceDetailServiceGL, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DETAIL_GL_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchAdhocChartData(data) {
    yield put({ type: "FETCH_BUPL_DETAIL_GL_LOADER", loading: true });
    const response = yield call(fetchAdhocChartDataService, data.payload);
    if (response && response.status == 200 && data.payload.export != true) {
        yield put({ type: "FETCH_ADHOC_CHART_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchOrderBookData(data) {
    yield put({ type: "FETCH_ORDER_BOOK_DATA_LOADER", loading: true });
    const response = yield call(fetchOrderBookDataService, data.payload);
    if (response && response.status == 200 && data.payload.export !== "true") {
        yield put({ type: "FETCH_ORDER_BOOK_DATA_SUCCESS", loading: false, data: response.data });
    }
}



function* fetchBUPLComparativePlantDetail(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(performFetchBUPLComparativePlantService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDataBrowserDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchDefaultMonthValueHFMService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_HFM_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchFunctionalBudgetGroup(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchBudgetGroupService, data.payload);

    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_BUDGET_GROUP_SUCCESS", data: response.data.budget_group, loading: false });
    }
}

function* fetchFunctionalPivotData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchFunctionalPivotService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", data: response.data, budgetGroupUser : data.payload.budgetGroupUser, budgetGroupFilter : data.payload.budgetGroup, loading: false });
    }
}

function* fetchFunctionalPivotDataNoLoading(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: false });
    const response = yield call(fetchFunctionalPivotService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", data: response.data, budgetGroupUser : data.payload.budgetGroupUser, budgetGroupFilter : data.payload.budgetGroup, loading: false });
    }
}

function* fetchSalesTechnologyPivotData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchSalesTechnologyPivotService, data.payload);
    if (response && response.status == 200) {
        if(data.payload.budgetGroup == "Analytics"){
            yield put({ type: "FETCH_SALES_TECHNOLOGY_PIE_SUCCESS", data: response.data, loading: false });
        }else{

        yield put({ type: "FETCH_FUNCTIONAL_SALES_TECHNOLOGY_SUCCESS", data: response.data, budgetGroupUser : data.payload.budgetGroupUser, budgetGroupFilter : data.payload.budgetGroup, loading: false });

        }
    }
}




function* fetchFunctionalSummaryData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchFunctionalSummaryService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", data: response.data, budgetGroupUser : data.payload.budgetGroupUser, budgetGroupFilter : data.payload.budgetGroup, loading: false });
    }
}

function* fetchTotalnewConsolidation(data) {
    if (!data.payload.export) {
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    }
    const response = yield call(fetchTotalnewConsolidationService, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchFunctionalDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchDefaultMonthValueService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA", payload: { yearmonth: response.data.month, isComparative: true, budgetGroupUser : data.payload.budgetGroupUser }, loading: false });
    }
}

function* fetchSalesTechnologyDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const mainState = store.getState();
    let defaultOption = 'SummaryView';
    const response = yield call(fetchDefaultMonthValueService, data.payload);

    if (mainState.authReducer && mainState.authReducer.userData && mainState.authReducer.userData.sales_and_technologies_access && 
        mainState.authReducer.userData.sales_and_technologies_access.group && mainState.authReducer.userData.sales_and_technologies_access.group.length) {
          let backendValue =   mainState.authReducer.userData.sales_and_technologies_access.group[0]
          if(backendValue.text != 'All Groups'){
            defaultOption = backendValue.value
          }
    }
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_SALES_TECHNOLOGY_PIVOT_DATA", payload: { yearmonth: response.data.month, isComparative: false, budgetGroup: defaultOption }, loading: false });
    }
}

function* fetchMPRDefaultMonthValue(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchDefaultMonthValueService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_TOTAL_new_CONSOLIDATION", payload: { yearmonth: response.data.month, bu: '' }, loading: false });
    }
}
function* sendPivotCommentDataST(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(sendPivotComment, data.payload.commentData);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SALES_TECHNOLOGY_PIVOT_DATA", payload: data.payload.tablePayload, loading: false });
    }
}
function* sendPivotCommentData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: false });
    const response = yield call(sendPivotComment, data.payload.commentData);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_NO_LOADING", payload: data.payload.tablePayload, loading: false });
    }
}

function* sendFunctionalCommentData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: false });
    const response = yield call(sendFunctionalComment, data.payload.commentData);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_SUMMARY_DATA", payload: data.payload.tablePayload, loading: false });
    }
}

function* sendFunctionalDrilldownData(data) {
    yield put({ type: "FETCH_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const response = yield call(fetchFunctionaDrilldownService, data.payload);
    if (response && response.status == 200) {
         yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false });
    }
}

function* sendFunctionalSTDrilldownData(data) {
    yield put({ type: "FETCH_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const response = yield call(fetchFunctionaSTDrilldownService, data.payload);
    if (response && response.status == 200) {
         yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false });
    }
}

function* downloadFunctionalDrilldownData(data) {
    yield put({ type: "DOWNLOAD_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const response = yield call(downloadFunctionaDrilldownService, data.payload);
    if (response && response.status == 200) {
        //  yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false });
    }
}

function* downloadFunctionalSTDrilldownData(data) {
    yield put({ type: "DOWNLOAD_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const response = yield call(downloadFunctionaSTDrilldownService, data.payload);
    if (response && response.status == 200) {
        //  yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false });
    }
}


function* fetchNewDrilldownData(data) {
    yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });
    const response = yield call(fetchNewDrilldownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}


function* fetchNewOriginationDrilldownData(data) {
    yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });
    const response = yield call(fetchNewOriginationDrilldownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchNewBUFourDrilldownData(data) {
    if (!data.payload.export) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_LOADER", loading: true });
    }
    const response = yield call(fetchNewBUFourDrilldownDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_BUPL_INVOICE_DETAIL_SUCCESS", loading: false, data: response.data, isNewBuFour: true });
    }
}


function* fetchNewDrilldownChartData(data) {
    yield put({ type: "FETCH_CHART_MODAL_LOADER", loading: true });
    const response = yield call(fetchNewDrilldownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_CHART_New_SO_MODAL_SUCCESS", loading: false, data: response.data });
    }
}
function* saveNewDrilldownData(data) {
    yield put({ type: "UPDATE_OPTIMUS_New_LOADER", loading: true });
    const response = yield call(saveNewDrilldownService, data.payload);
 
    if (response && response.status == 200) {
        yield put({ type: "UPDATE_OPTIMUS_New_LOADER", loading: false });
        // console.log(store.getState(), "checkPayload")
        // yield put({ type: "FETCH_OPTIMUS_AUTO_DETAIL", loading: false });
    }
}
function* fetchNewSubHeaderDrilldownData(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(fetchNewSubHeaderDrilldownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SUB_HEADER_DRILLDOWN_SUCCESS", loading: false, data: response.data, payload :data.payload });
    }
}


function* fetchFunctionalBudgetGraphData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchFunctionalBudgetGraphService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: 'FETCH_FUNCTIONAL_BUDGET_GRAPH_DATA_SUCCESS', data: response.data, loading: false })
    }
}





function* postBuplValidationAmount(data) {
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true, uploadLoading: true });
    const response = yield call(performPostBuplValidationAmountService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_VALIDATION_DATA" })
    }
}

function* postSalesTechnologyTornadoData(data){
    const response = yield call(postSalesTechnologyTornadoDataAction, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SALES_TORNADO_DATA_MONTHLY_SUCCESS", data: response.data }); 
    }
}

function* fetchServiceRevenueProfitabilityData(data){
    yield put({ type: "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER", loading: true });
    const response = yield call(fetchServiceRevenueProfitabilityService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SERVICE_REVENUE_PROFITABILITY_DATA_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchTAndEAnalysisData(data){
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchTAndEAnalysisService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchTAndEAnalysisDataLastMonth(data){
    yield put({ type: "FETCH_T_AND_E_ANALYSIS", loading: true });
    const state = store.getState();
    const response = yield call(fetchDefaultMonthValueService, data.payload);
    // console.log(response, "fetchDefaultMonthValueService")
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_T_AND_E_ANALYSIS_DATA", payload: { year: response.data.year , bu : "SUMMARY", New_bu : "DASS", report_type: data.payload.report_type}, loading: false });
    }
}

function* fetchTAndEAnalysisDrillDownData(data) {
    yield put({ type: "FETCH_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchTAndEAnalysisDrillDownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false});
    }
}

function* fetchOvertimeAnalysisData(data){
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER", loading: true });
    const response = yield call(fetchOvertimeAnalysisService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchOvertimeAnalysisDataLastMonth(data){
    yield put({ type: "FETCH_OVERTIME_ANALYSIS", loading: true });
    const state = store.getState();
    const response = yield call(fetchDefaultMonthValueService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_BUPL_DEFAULT_MONTH_SUCCESS", data: response.data, loading: false });
        yield put({ type: "FETCH_OVERTIME_ANALYSIS_DATA", payload: { year: response.data.year , bu : "SUMMARY", New_bu : "DASS"}, loading: false });
    }
}

function* fetchOvertimeAnalysisDrillDownData(data) {
    yield put({ type: "FETCH_FUNCTION_DRILLDOWN_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchOvertimeAnalysisDrillDownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTION_DRILLDOWN_SUCCESS", data: response.data, loading: false});
    }
}

function* fetchServicesRevenueByRegion(data) {
    if(!data.payload.export){
        yield put({ type: "FETCH_SERVICE_REVENUE_BY_REGION_LOADER", loading: true });
    }
    const response = yield call(fetchServicesRevenueByRegionService, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_REVENUE_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchDrillDownServicesRevenueByRegion(data) {
    yield put({ type: "FETCH_DRILL_DOWN_SERVICE_REVENUE_BY_REGION_LOADER", loading: true });
    const response = yield call(fetchDrillDownServicesRevenueByRegionService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FUNCTION_DRILL_DOWN_REVENUE_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchDrillDownOrderBookAnalysis(data) {
    yield put({ type: "FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS_LOADER", loading: true });
    const response = yield call(fetchDrillDownOrderBookAnalysisService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS_SUCCESS", data: response.data, loading: false });
    }
}

function* fetchOrderbookAnalysisComparison(data) {
    yield put({ type: "FETCH_ORDER_BOOK_COMPARISON_LOADER", loading: true });
    const response = yield call(fetchOrderbookAnalysisComparisonService, data.payload);
    if (response && response.status == 200 && data.payload.export !== "true") {
        yield put({ type: "FETCH_ORDER_BOOK_COMPARISON_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDassAopRevenueAnalysis(data) {
    yield put({ type: "FETCH_DASS_AOP_REVENUE_LOADER", loading: true });
    const response = yield call(fetchDassAopRevenueAnalysisService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DASS_AOP_REVENUE_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchSummaryDASS(data) {
    const { payload } = data
    let data_result = []
    yield put({ type: "FETCH_DASS_SUMMARY_LOADER", loading: true });
    // const response = yield call(fetchDassAopRevenueAnalysisService, payload[0]);

    let response = {}
    for (let index = 0; index < payload.length; index++) {
        const element = payload[index];

        const obj = {
            data: [],
            label: element.label,
            title: element.title
        }

        response = yield call(fetchDassAopRevenueAnalysisService, element.payload);
        if (response && response.status == 200) {
            obj.data = response.data.by_bu.DASS
        }

        data_result.push(obj)
    }

    if(data_result) {
        yield put({ type: "FETCH_DASS_SUMMARY_SUCCESS", loading: false, data: data_result });
    }
}

function* fetchSummaryDASSTableData(data) {
  
    yield put({ type: "FETCH_DASS_SUMMARY_LOADER", loading: true });

    const response = yield call(fetchSummaryDASSTableDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DASS_SUMMARY_TABLE_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchSummaryPABBacklogTableData(data) {
  
    yield put({ type: "FETCH_PAB_BACKLOG_SUMMARY_LOADER", loading: true });

    const response = yield call(fetchSummaryPABBacklogTableDataService, data.payload);
    if (response && response.status == 200 && data.payload.export !== 'true') {
        yield put({ type: "FETCH_PAB_BACKLOG_SUMMARY_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDassByGeoTableData(data) {
  
    if(!data.payload.export){
        yield put({ type: "FETCH_DASS_BY_GEO_LOADER", loading: true });
    }

    const response = yield call(fetchDASSByGeoTableDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_DASS_BY_GEO_LOADER_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchSummaryPABBacklogDrilldown(data) {
  
    yield put({ type: "FETCH_PAB_BACKLOG_DRILLDOWN_LOADER", loading: true });

    const response = yield call(fetchSummaryPABBacklogDrilldownService, data.payload);
    if (response && response.status == 200 && data.payload.export !== 'true') {
        yield put({ type: "FETCH_PAB_BACKLOG_DRILLDOWN_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDassByGeoTableDataDrilldowm(data) {
  
    yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_LOADER", loading: true });

    const response = yield call(fetchDASSByGeoTableDataDrilldownService, data.payload);
    if (response && response.status == 200 && data.payload.export !== 'true') {
        yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDASSRevenueByCustomerDataDrilldown(data) {
  
    yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_LOADER", loading: true });

    const response = yield call(fetchDASSRevenueByCustomerDataDrilldownService, data.payload);
    if (response && response.status == 200 && data.payload.export !== 'true') {
        yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_SUCCESS", loading: false, data: response.data });
    }
}

function* updateDASSRevenueByCustomerDataDrilldown(data) {
  
    yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_LOADER", loading: true });

    var response = yield call(updateDASSRevenueByCustomerDataDrilldownService, data.payload.payloadUpdate);
    if (response && response.status == 200 && data.payload.export !== 'true') {
        var response = yield call(fetchDASSRevenueByCustomerDataDrilldownService, data.payload.payloadFetch);
        yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_LOADER", loading: true });

        if (response && response.status == 200 && data.payload.export !== 'true') {
            yield put({ type: "FETCH_DASS_BY_GEO_DRILLDOWN_SUCCESS", loading: false, data: response.data });
        }

    }
}


function* fetchCommentAllChannelHistory(data) {

    try {

        const state = store.getState();
        const { ecfmState } = state;
        const {tableRowData} = ecfmState;

        const response = yield call(fetchAllCommentHistoryService, data.payload);


        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, response.data.chat)
            yield put({ type: "FETCH_COMMENT_CHANNEL_XPNA_DATA_HISTORY_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}





function* fetchDassAopFrontlog(data) {
    yield put({ type: "FETCH_DASS_AOP_FRONTLOG_LOADER", loading: true });
    const response = yield call(fetchDassAopFrontlogService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DASS_AOP_FRONTLOG_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchDassAopFrontlogDrillDown(data) {
    yield put({ type: "FETCH_DASS_AOP_FRONTLOG_DRILLDOWN_LOADER", loading: true });
    const response = yield call(fetchDassAopFrontlogDrillDownService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DASS_AOP_FRONTLOG_DRILLDOWN_SUCCESS", loading: false, data: response.data });
    }
}

function* saveColumnFunctionData(data) {
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: true });
    const response = yield call(fetchNewBUFourTargetReportDataService, data.payload);
    if (response && response.status == 200) {
        response.data.row_data = changeValuesOfData(response.data.row_data)
        const getCommentPayload =  getAllCommentHistoryData(0, response.data.row_data)
        yield put({ type: "FETCH_BUPL_INCOME_PIVOT_SUCCESS", data: response.data, loading: false });
        yield put({
            type: "FETCH_COMMENT_CHANNEL_XPNA_DATA_HISTORY",
            payload: getCommentPayload,
          })      
    }
    yield put({ type: "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS", loading: false });
}

function* fetchCompareDataBUPL(data) {
    if (data.payload.export != true) {
        yield put({ type: "FETCH_COMPARE_DATA_BUPL_LOADER", loading: true });
    }
    const response = yield call(fetchCompareDataForBUPLService, data.payload);
    if (response && response.status == 200 && data.payload.export != true) {
        yield put({ type: "FETCH_COMPARE_DATA_BUPL_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_COMPARE_DATA_BUPL_LOADER", loading: false });
}

function* fetchCompareFilterOption(data) {
        yield put({ type: "FETCH_COMPARE_FILTER_BUPL_LOADER", loading: true });
    
    const response = yield call(fetchCompareFilterOptionsService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_COMPARE_FILTER_BUPL_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_COMPARE_DATA_BUPL_LOADER", loading: false });
}




export default function* actionWatcher() {
    //  yield takeLatest('FETCH_BUPL_INCOME_PIVOT', fetchBUPLIncomePivotStatement)
    yield takeLatest('FETCH_BUPL_INCOME_PIVOT_ALL', fetchBUPLIncomePivotStatementAll)
    yield takeLatest('FETCH_New_PIVOT_ALL', fetchNewPivotStatementAll)
    yield takeLatest('FETCH_New_BU_FOUR_REPORT_DATA', fetchNewBUFourReportData)
    yield takeLatest('FETCH_New_BU_FOUR_TARGET_REPORT_DATA', fetchNewBUFourTargetReportData)
    yield takeLatest('SAVE_New_BU_FOUR_TARGET_COMMENT_DATA', saveXPNAComment)
    
    yield takeLatest('FETCH_New_BU_FOUR_BY_PM_REPORT_DATA', fetchNewBUFourByPMData)
    yield takeLatest('FETCH_New_BU_FOUR_BY_LOCATION_REPORT_DATA', fetchNewBUFourByLocationData)
    yield takeLatest('FETCH_New_BU_FOUR_DRILLDOWN_DATA', fetchNewBUFourDrilldownData)
    

    yield takeLatest('FETCH_New_OCOS_SUMMARY', fetchNewOCOSSummary)
    yield takeLatest('FETCH_New_DGS_LAWSON', fetchNewDGSLawson)
    yield takeLatest('FETCH_New_OPTIMUS_AUTOMATION', fetchNewOptimusAutomation)
    yield takeLatest('FETCH_New_CHART_SO', fetchNewChartSO)
    yield takeLatest('DOWNLOAD_EXCEL_New_PIVOT_ALL', downloadExcelNewPivotStatementAll)

    yield takeLatest('FETCH_BUPL_MONTHLY_QUARTER_ALL', fetchMonthlyQuarterBUPlStatement)
    yield takeLatest('FETCH_BUPL_COUNTRY_PLANT_ALL', fetchCountryPlantBUPlStatement)
    yield takeLatest('FETCH_BUPL_DEFAULT_MONTH', fetchDefaultMonthValue)
    yield takeLatest('FETCH_New_DEFAULT_MONTH', fetchNewDefaultMonthValue)
    yield takeLatest('FETCH_New_DEFAULT_MONTH_DYNAMIC', fetchNewDefaultMonthValueDynamic)
    yield takeLatest('FETCH_DATABROWSER_DEFAULT_MONTH', fetchDataBrowserDefaultMonthValue)
    yield takeLatest('FETCH_BUPL_INVOICE_DETAIL', fetchBUPLInvoiceDetail)
    yield takeLatest('FETCH_LAWSON_DETAIL', fetchLawsonDetail)
    yield takeLatest('FETCH_PAB_VALIDATION_DETAIL', fetchPABValidationDetail)  
    yield takeLatest('FETCH_OPTIMUS_AUTO_DETAIL', fetchOptimusAutoDetail)
    yield takeLatest('FETCH_DROPDOWN', fetchDropDown)
    yield takeLatest('FETCH_BUPL_INVOICE_DETAIL_GL', fetchBUPLDetailGL)
    yield takeLatest('FETCH_BUPL_COMPARATIVE_DETAIL', fetchBUPLComparativeDetail)
    yield takeLatest('FETCH_BUPL_COMPARATIVE_PLANT_DETAIL', fetchBUPLComparativePlantDetail)
    yield takeLatest('FETCH_AUTOMATION_IS', fetchAutomationIS)
    yield takeLatest('FETCH_AUTOMATION_DSO', fetchAutomationDSO)
    yield takeLatest('FETCH_New_AUTOMATED_DATA', fetchAutomationNew)
    yield takeLatest('FETCH_PAYMENT_DATA_VALIDATION', fetchPaymentData)
    yield takeLatest('FETCH_AUTOMATION_RECEIVABLE', fetchAutomationReceivable)
    yield takeLatest('POST_MANUAL_AMOUNT_DATA', postManualAmountDataSaga)
    yield takeLatest('FETCH_FUNCTIONAL_BUDGET_GROUP', fetchFunctionalBudgetGroup)
    yield takeLatest('FETCH_FUNCTIONAL_PIVOT_DATA', fetchFunctionalPivotData)
    yield takeLatest('FETCH_FUNCTIONAL_PIVOT_DATA_NO_LOADING', fetchFunctionalPivotDataNoLoading)
    yield takeLatest('FETCH_SALES_TECHNOLOGY_PIVOT_DATA', fetchSalesTechnologyPivotData)
    yield takeLatest('SEND_PIVOT_COMMENT_DATA', sendPivotCommentData)
    yield takeLatest('SEND_PIVOT_COMMENT_DATA_ST', sendPivotCommentDataST)
    yield takeLatest('SEND_FUNCTIONAL_COMMENT_DATA', sendFunctionalCommentData)
    yield takeLatest('FETCH_FUNCTIONAL_SUMMARY_DATA', fetchFunctionalSummaryData)
    yield takeLatest('FETCH_FUNCTIONAL_DEFAULT_MONTH', fetchFunctionalDefaultMonthValue)
    yield takeLatest('FETCH_SALES_TECHNOLOGY_DEFAULT_MONTH', fetchSalesTechnologyDefaultMonthValue)
    yield takeLatest('FETCH_MPR_DEFAULT_MONTH', fetchMPRDefaultMonthValue)
    yield takeLatest('FETCH_TOTAL_new_CONSOLIDATION', fetchTotalnewConsolidation)
    yield takeLatest('FETCH_FUNCTIONAL_BUDGET_GRAPH_DATA', fetchFunctionalBudgetGraphData)
    yield takeLatest('FETCH_BUPL_VALIDATION_DATA', fetchBuplValidationData)
    yield takeLatest('FETCH_AUTOMATION_new_DATA_VALIDATION', fetchNewDataVal)
    yield takeLatest('POST_BUPL_VALIDATION_AMOUNT_DATA', postBuplValidationAmount)
    yield takeLatest('SEND_FUNCTIONAL_DRILLDOWN_DATA', sendFunctionalDrilldownData)
    yield takeLatest('SEND_FUNCTIONAL_ST_DRILLDOWN_DATA', sendFunctionalSTDrilldownData)
    yield takeLatest('DOWNLOAD_FUNCTIONAL_DRILLDOWN_DATA', downloadFunctionalDrilldownData)
    yield takeLatest('DOWNLOAD_FUNCTIONAL_ST_DRILLDOWN_DATA', downloadFunctionalSTDrilldownData)
    yield takeLatest('FETCH_New_DRILLDOWN_DATA', fetchNewDrilldownData)
    yield takeLatest('FETCH_New_DRILLDOWN_CHART_DATA', fetchNewDrilldownChartData)
    yield takeLatest('FETCH_New_DRILLDOWN_ORIGINATION', fetchNewOriginationDrilldownData)
    yield takeLatest('SAVE_New_DRILLDOWN_DATA', saveNewDrilldownData)
    yield takeLatest('FETCH_New_SUB_HEADER_DRILLDOWN_DATA', fetchNewSubHeaderDrilldownData)
    yield takeLatest('FETCH_New_PIE_CHART_DATA', fetchNewPieData)
    yield takeLatest('FETCH_SALES_TECHNOLOGY_PIE_CHART_DATA', fetchSalesTechnologyPieData)
    yield takeLatest('POST_new_MANUAL_DATA',    postNewManualAmountData)
    yield takeLatest('POST_new_COMMENT_DATA',    postNewCommentData)
    yield takeLatest('POST_new_ORIGINATION_COMMENT_DATA',    postNewOriginationCommentData)
    yield takeLatest('FETCH_SALES_TORNADO_DATA_MONTHLY', postSalesTechnologyTornadoData)
    yield takeLatest('FETCH_AOP_TABLE_DATA', fetchNewAOPData)
    yield takeLatest('FETCH_AOPDESTINATION_TABLE_DATA', fetchNewAOPDesData)
    yield takeLatest('FETCH_AR_LATEST_MONTH', fetchARLatestMonth)
    yield takeLatest('FETCH_ADHOC_CHART', fetchAdhocChartData)
    yield takeLatest('FETCH_ORDER_BOOK_DATA', fetchOrderBookData)
    yield takeLatest('FETCH_SERVICE_REVENUE_PROFITABILITY_DATA', fetchServiceRevenueProfitabilityData)
    yield takeLatest('FETCH_T_AND_E_ANALYSIS_DATA', fetchTAndEAnalysisData)
    yield takeLatest('FETCH_T_AND_E_ANALYSIS_DATA_LAST_MONTH', fetchTAndEAnalysisDataLastMonth)
    yield takeLatest('FETCH_T_AND_E_ANALYSIS_DATA_DRILL_DOWN', fetchTAndEAnalysisDrillDownData)
    yield takeLatest('FETCH_OVERTIME_ANALYSIS_DATA', fetchOvertimeAnalysisData)
    yield takeLatest('FETCH_OVERTIME_ANALYSIS_DATA_LAST_MONTH', fetchOvertimeAnalysisDataLastMonth)
    yield takeLatest('FETCH_OVERTIME_ANALYSIS_DATA_DRILL_DOWN', fetchOvertimeAnalysisDrillDownData)

    yield takeLatest('FETCH_SERVICE_REVENUE_BY_REGION', fetchServicesRevenueByRegion)
    yield takeLatest('FETCH_DRILL_DOWN_SERVICE_REVENUE_BY_REGION', fetchDrillDownServicesRevenueByRegion)
    yield takeLatest('FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS', fetchDrillDownOrderBookAnalysis)
    yield takeLatest('FETCH_ORDER_BOOK_COMPARISON_ANALYSIS', fetchOrderbookAnalysisComparison)
    yield takeLatest('FETCH_DASS_AOP_REVENUE', fetchDassAopRevenueAnalysis)
    yield takeLatest('FETCH_DASS_SUMMARY', fetchSummaryDASS)
    yield takeLatest('FETCH_DASS_SUMMARY_TABLE_DATA', fetchSummaryDASSTableData)
    yield takeLatest('FETCH_PAB_BACKLOG_SUMMARY_TABLE_DATA', fetchSummaryPABBacklogTableData)
    yield takeLatest('FETCH_DASS_BY_GEO_TABLE_DATA', fetchDassByGeoTableData)
    yield takeLatest('FETCH_PAB_BACKLOG_SUMMARY_DRILLDOWN', fetchSummaryPABBacklogDrilldown)
    yield takeLatest('FETCH_DASS_BY_GEO_TABLE_DATA_DRILLDOWN', fetchDassByGeoTableDataDrilldowm)
    yield takeLatest('FETCH_DASS_REVENUE_BY_CUSTOMER_DATA_DRILLDOWN', fetchDASSRevenueByCustomerDataDrilldown)
    yield takeLatest('UPDATE_DASS_REVENUE_BY_CUSTOMER_DATA_DRILLDOWN', updateDASSRevenueByCustomerDataDrilldown)
    yield takeLatest('FETCH_COMMENT_CHANNEL_XPNA_DATA_HISTORY', fetchCommentAllChannelHistory)

    
    
    yield takeLatest('FETCH_DASS_AOP_FRONTLOG', fetchDassAopFrontlog)
    yield takeLatest('FETCH_DASS_AOP_FRONTLOG_DRILLDOWN', fetchDassAopFrontlogDrillDown)
    yield takeLatest('UPLOAD_EXCEL_FILE_UPLOAD', uploadExcelFileUpload)

    yield takeLatest('SAVE_COLUMN_FUNCTION_DATA', saveColumnFunctionData)
    yield takeLatest('FETCH_COMPARE_FILTER_BUPL', fetchCompareFilterOption)
    yield takeLatest('FETCH_COMPARE_DATA_BUPL', fetchCompareDataBUPL)
}

