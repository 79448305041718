import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from "@material-ui/core/InputLabel";
import { DropzoneArea } from 'material-ui-dropzone'
import DownloadExcel from "../../../../images/download_excel.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Autocomplete, TextField } from '@mui/material';

export default function UploadModal(props) {

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [reportDate, setReportDate] = React.useState(new Date());
    const [files, setFiles] = useState(true)
    const [yearArr, setYear] = useState([])
    const [typeArr, settypeArr] = useState([
        {"value" : 'AOP', "label": 'AOP'},
        {"value" : 'Qtr Forecast', "label": 'Qtr Forecast'},
        {"value" : 'MPR', "label": 'MPR'},
        ])
    const [quarterArr, setQuarterArr] = useState([
        {"value" : 'Q1', "label": 'Q1'},
        {"value" : 'Q2', "label": 'Q2'},
        {"value" : 'Q3', "label": 'Q3'},
        {"value" : 'Q4', "label": 'Q4'},
        ])
    const [monthArr, setMonthArr] = useState([
        {"value" : 'Jan', "label": 'Jan'},
        {"value" : 'Feb', "label": 'Feb'}, 
        {"value" : 'Mar', "label": 'Mar'}, 
        {"value" : 'Apr', "label": 'Apr'}, 
        {"value" : 'May', "label": 'May'}, 
        {"value" : 'Jun', "label": 'Jun'}, 
        {"value" : 'Jul', "label": 'Jul'}, 
        {"value" : 'Aug', "label": 'Aug'}, 
        {"value" : 'Sept', "label": 'Sept'}, 
        {"value" : 'Oct', "label": 'Oct'}, 
        {"value" : 'Nov', "label": 'Nov'}, 
        {"value" : 'Dec', "label": 'Dec'}
        ])
    const [bunewArr, setBunewArr] = useState([{"value" : 'DASS', "label": 'DASS'}, {"value" : 'CP', "label": 'CP'}])
    const [regionArr, setRegion] = useState([])
    const [buArr, setBu] = useState([])
    const [yearValue, setYearValue] = useState('')
    const [typeValue, setTypeValue] = useState('')
    const [quarterValue, setQuarterValue] = useState('')
    const [monthValue, setMonthValue] = useState('')
    const [bunewValue, setBunewValue] = useState('')
    const [buValue, setBuValue] = useState('')
    
    
    const handleChangeFile = (file) => {
        setFiles(file);
    }

    const handlePreviewIcon = () => {
        return <img src={DownloadExcel} className='pointer' height='30' />
    }

    const handleSaverole = () => {
        const formData = new FormData();
        formData.append("year", yearValue);
        formData.append("type", typeValue);
        formData.append("legacy_bu", buValue);
        formData.append("New_bu", bunewValue);
        formData.append("month", monthValue);
        formData.append("quarter", quarterValue);
        formData.append("files", files[0]);
        props.saveCallback(formData);
    }

    const handleResetPopUp = () => {
        setReportDate(new Date());
        setBtnDisabled(true)
        // setUploadTypeValue('')
        setYear('')
        setBu('')
        setRegion('')
        setQuarterValue("")
        setMonthValue("")
    }
    const loadAddData = () => {
        setYear(props.filterData.year)
        setBu(props.filterData.bu)
        setRegion(props.filterData.region)
    }
    const setTypes = (item) => {
        setTypeValue(item)
        setQuarterValue("")
        setMonthValue("")
        // setYear(props.filterData.year)
    }
    // || ((typeValue == 'Qtr Forecast' && quarterValue !== '') || (typeValue == 'MPR' &&monthValue !== '')) &&
    React.useEffect(() => {
        if (files.length > 0 && yearValue != ''&& ((typeValue == 'AOP' && quarterValue == ''&& monthValue == '')|| (typeValue == 'Qtr Forecast' && quarterValue !== '') || (typeValue == 'MPR' && monthValue !== '')) && bunewValue != ''&& buValue != '' )
            setBtnDisabled(false)
        else
            setBtnDisabled(true)
    }, [files, yearValue,typeValue, bunewValue, buValue , quarterValue, monthValue])

    React.useEffect(() => {
        handleResetPopUp();
        if (props.showModal) {
            loadAddData();
        }
    }, [props.showModal]);
// console.log(typeValue, "cekTypeValue")
    let yearObjArr =[]
    if ((typeof yearArr !== 'string') && yearArr?.length > 0) {
        yearObjArr=[...yearArr.map((yr)=> ({"label": yr, "value" : yr}))]
    }

    let buObjArr =[]
    if ((typeof buArr !== 'string') && buArr?.length > 0) {
        buObjArr=[...buArr.map((bu)=> ({...bu, value: bu.key}))]
    }

    const selectedOptions = (a, data) => {
        if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
        else return {label:'', value:''}
        
    };

    const handleTypeChange = (evt, val) =>{
        if (val !== null) 
        {setTypes(val.value)}
        else {setTypes('')}
    }

    const handleQtrChange = (evt, val) =>{
        if (val !== null) 
        {setQuarterValue(val.value)}
        else {setQuarterValue('')}
    }

    const handleMonthChange = (evt, val) =>{
        if (val !== null) 
        {setMonthValue(val.value)}
        else {setMonthValue('')}
    }

    const handleBuNewChange = (evt, val) =>{
        if (val !== null) 
        {setBunewValue(val.value)}
        else {setBunewValue('')}
    }

    const handleBuChange = (evt, val) =>{
        if (val !== null) 
        {setBuValue(val.value)}
        else {setBuValue('')}
    }

    const handleYearChange = (evt, val) =>{
        if (val !== null) 
        {setYearValue(val.value)}
        else {setYearValue('')}
    }
    

    const body = (
        <div className='customModal automationModal'>
            <div className='header'>
                <h2>Upload Forecast File</h2>
                {props.saveLoading ?  <Icon onClick={props.modalCallBack}>close</Icon>  : ""}
            </div>
            <div className='modal-content' style={{ pointerEvents:  props.saveLoading ? 'none' : ''}}>
                {props.saveLoading ? <div className='text-center'><CircularProgress /></div> :
                    <React.Fragment>
                        <div>

                            {/* <FormControl className=' mb-10 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select Year</InputLabel>
                            <Select
                                value={yearValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {yearArr && yearArr.length && yearArr.map((item, i) => {
                                    return <MenuItem value={item} key={item} onClick={() => setYearValue(item)}>{item}</MenuItem>
                                })}
                            </Select>
                            </FormControl> */}
                            <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mt-10 mb-20 mr-20 w-100 maxw-200'
                                options={yearObjArr}
                                size='small'
                                value={yearObjArr && yearObjArr.length ? selectedOptions(yearValue,yearObjArr)[0]: {label:'', value:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleYearChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='Year' placeholder='Select'/>
                                }}
                            />

                        
                        </div>
                        <div style={{display:'flex'}}>
                            {/* <FormControl className=' mb-40 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select Type</InputLabel>
                            <Select
                                value={typeValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {typeArr && typeArr.length && typeArr.map((item, i) => {
                                    return <MenuItem value={item} key={item} onClick={() => setTypes(item)}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}

                            <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mb-20 mr-20 w-100 maxw-200'
                                options={typeArr}
                                size='small'
                                value={typeArr && typeArr.length ? selectedOptions(typeValue,typeArr)[0]: {label:'', value:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleTypeChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='Type' placeholder='Select'/>
                                }}
                            />
                        {typeValue == "Qtr Forecast" &&   
                        // <FormControl className='ml-40  mb-40 w-100 maxw-200'>
                        //     <InputLabel id="demo-simple-select-label">Please select Quarter</InputLabel>
                        //     <Select
                        //         value={quarterValue}
                        //     >
                        //         <MenuItem>Select Type</MenuItem>
                        //         {quarterArr && quarterArr.length && quarterArr.map((item, i) => {
                        //             return <MenuItem value={item} key={item} onClick={() => setQuarterValue(item)}>{item}</MenuItem>
                        //         })}
                        //     </Select>
                        // </FormControl>
                            <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mb-20 w-100 maxw-200'
                                options={quarterArr}
                                size='small'
                                value={quarterArr && quarterArr.length ? selectedOptions(quarterValue,quarterArr)[0]: {label:'', value:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleQtrChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='Quarter' placeholder='Select'/>
                                }}
                            /> 
                        }
                        {typeValue == "MPR" && 
                            // <FormControl className='xpna-dropdown single mb-20 w-100 maxw-200'>
                            // <InputLabel id="demo-simple-select-label">Please select Month</InputLabel>
                            // <Select
                            //     value={monthValue}
                            // >
                            //     <MenuItem>Select Type</MenuItem>
                            //     {monthArr && monthArr.length && monthArr.map((item, i) => {
                            //         return <MenuItem value={item} key={item} onClick={() => setMonthValue(item)}>{item}</MenuItem>
                            //     })}
                            // </Select>
                            // </FormControl>
                            <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mb-20 w-100 maxw-200'
                                options={monthArr}
                                size='small'
                                value={monthArr && monthArr.length ? selectedOptions(monthValue,monthArr)[0]: {label:'', value:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleMonthChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='Month' placeholder='Select'/>
                                }}
                            /> 

                        
                        }
                        </div>
                      
                        <div style={{display:'flex'}}>
                            {/* <FormControl className=' mb-40 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select BU new</InputLabel>
                            <Select
                                value={bunewValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {bunewArr && bunewArr.length && bunewArr.map((item, i) => {
                                    return <MenuItem value={item} key={item} onClick={() => setBunewValue(item)}>{item}</MenuItem>
                                })}
                            </Select>
                            </FormControl> */}
                            <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mb-20 mr-20 w-100 maxw-200'
                                options={bunewArr}
                                size='small'
                                value={bunewArr && bunewArr.length ? selectedOptions(bunewValue,bunewArr)[0]: {label:'', value:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleBuNewChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='BU New' placeholder='Select'/>
                                }}
                            /> 
                        


                        {/* <FormControl className='ml-40 mb-40 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select BU</InputLabel>
                            <Select
                                value={buValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {buArr && buArr.length && buArr.map((item, i) => {
                                    return <MenuItem value={item.key} key={item.key} onClick={() => setBuValue(item.key)}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                                disablePortal
                                className='xpna-dropdown single mb-20 w-100 maxw-200'
                                options={buObjArr}
                                size='small'
                                value={buObjArr && buObjArr.length ? selectedOptions(buValue,buObjArr)[0]: {label:'', value:'', key:''}}
                                getOptionLabel={(option)=>option.label}
                                onChange={(evt, val)=>handleBuChange(evt, val)}
                                renderInput={(params)=>{
                                    return <TextField {...params} variant='standard' label='BU' placeholder='Select'/>
                                }}
                            /> 
                        </div>
                        {/* <FormControl className='mt-40 mb-40 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select Type of Upload</InputLabel>
                            <Select
                                value={uploadTypeValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {uploadType && uploadType.length && uploadType.map((item, i) => {
                                    return <MenuItem value={i} key={i} onClick={() => setUploadTypeValue(i)}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
                        <DropzoneArea
                            filesLimit={1}
                            getPreviewIcon={handlePreviewIcon}
                            onChange={handleChangeFile.bind(this)}
                            acceptedFiles={['.xls', '.xlsx', '.xlsm']}
                            showFileNames={true}
                            showAlerts={false}
                            maxFileSize={500000000000}
                            dropzoneClass={'payment-dropzone'}
                        />
                        {!props.saveLoading &&
                            <>
                                <Grid container justify="flex-end" className='mt-30 mb-10'>
                                    <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                                    <Button variant="contained" onClick={props.modalCallBack}>Cancel</Button>
                                </Grid>
                                <Grid container justify="flex-end"><span><i>Note: P&L Data will be uploaded only for selected Report Date.</i></span></Grid>
                            </>
                        }
                    </React.Fragment>
                }
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={props.modalCallBack}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}