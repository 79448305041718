import React, { useState, useEffect } from 'react';
import HighchartComponent from './highchart';
import { useSelector } from 'react-redux';
import Highcharts from "highcharts";

let options = {
	chart: {
		type: 'column',
	},
	title: {
		text: '',
	},
	subtitle: {
		text: '',
	},
	xAxis: {
		categories: [],
		title: {
			useHTML: true,
			text: '',
		},
		crosshair: true,
	},
	yAxis: {
		min: 0,
        max: 50000000,
		title: {
			useHTML: true,
			text: '',
		},
	},
	// tooltip: {
	// 	headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
	// 	pointFormat:
	// 		'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
	// 		'<td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
	// 	footerFormat: '</table>',
	// 	shared: true,
	// 	useHTML: true,
	// },
	tooltip: {
		formatter: function () {
			
				return '<b>' + this.series.name + '</b><br/>' +
				'Value: ' + Highcharts.numberFormat(this.point.y, 0)
			
		}
	},
	plotOptions: {
		series: {
			stacking: 'normal',
		},
	},
	series: [],
};

function StackedBarChart() {
	const incomeStatementState = useSelector((state) => state.incomeStatement);
	const chartData = incomeStatementState.chartData?.data || [];
	const chartMaxData = incomeStatementState.chartData?.max || 0;
	const [optionState, setOptionState] = useState({});
	const [chartArr, setChartArr] = useState([]);

	useEffect(() => {
		handleCreateChartData();
	}, []);

	useEffect(() => {
		handleCreateChartData();
	}, [chartData]);

	const handleCreateChartData = () => {
		const { categoriesData, yAxisTitle, xAxisTitle, seriesData } =
			createSeries();
		let differentSeries = []
		if (seriesData?.length) {
			seriesData.forEach(item => {
				let myOptions = JSON.parse(JSON.stringify(options))
				myOptions.xAxis.title.text = item[0]?.key;
				myOptions.xAxis.categories = categoriesData;
				myOptions.yAxis.title.text = yAxisTitle;
				myOptions.yAxis.max = chartMaxData;
				myOptions.series = item;
				differentSeries.push(myOptions);
			})

			setChartArr(differentSeries);
		}

	};

	const createSeries = () => {
		let seriesObj = {
			name: '',
			data: [],
		};

		let tempSeriesObj = {};
		let categoriesData = [];
		let xAxisTitle = '';
		let yAxisTitle = '';

		if (chartData.length) {
			let obj = {
				name: '',
				data: [],
			};
			chartData.forEach((chart_item, chart_index) => {
				chart_item.measurement.forEach((msm_item, msm_index) => {
					// seriesObj.name = msm_item.period + ' - ' + msm_item.label;
					// seriesObj.stack = chart_index == 0 ? 'Actual' : 'Target';
					// seriesObj.data.push(msm_item.value);
					yAxisTitle = 'Millions';
					let key = msm_item.period + ' - ' + msm_item.label;

					if (tempSeriesObj[key]) {
						tempSeriesObj[key]['data'].push(msm_item.value);
						tempSeriesObj[key]['name'] = msm_item.period + ' - ' + msm_item.label;
						key = msm_item.period;
					} else {
						tempSeriesObj[key] = {
							name: msm_item.label + ' - ' + msm_item.period,
							data: [msm_item.value],
							key: msm_item.period
						};
					}
				});

				chart_item.dimension.forEach((dim_item, msm_index) => {
					categoriesData.push(dim_item.value);
					xAxisTitle = 'Country';
				});
			});
		}

		let seriesData = structureTheSeries(tempSeriesObj);

		return {
			categoriesData,
			yAxisTitle,
			xAxisTitle,
			seriesData,
		};
	};

	const structureTheSeries = (obj) => {
		let seriesObj = {};
		let seriesArr = []

		for (let item in obj) {
			if (seriesObj[obj[item].key]){
				seriesObj[obj[item].key].push(obj[item])
			}else{
				seriesObj[obj[item].key] = []
				seriesObj[obj[item].key].push(obj[item])
			}
		}

		for (let item in seriesObj) {
			seriesArr.push(seriesObj[item])
		}


		return seriesArr;
	};

	return chartArr && chartArr.length ? (
		<div className='bu-stack-chart-div'>
			{
				chartArr.map(item => {
					return (
						<span className='bu-column-stack-chart'>
							<HighchartComponent options={item} />
						</span>
					)
				})
			}</div>


	) : (
		''
	);
}

export default StackedBarChart;
