import React from 'react';
import { Box, Button, ClickAwayListener, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filters from '../components/filters';
import SimpleModalWrapped from '../components/modalSave';
import SelectedMenu from '../components/selectedMenu';
import SideMenu from '../components/sideMenu';
import TableComponent from './TableComponent';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
			style={{ padding: '10px 10px 5px', backgroundColor: '#e0dfdf' }}
		>
			{value === index && children}
		</div>
	);
}

function generateNewNumber(keyStr, itemArray) {
	let newKeyStr = `${keyStr}-${Math.floor(Math.random() * (100 - 0)) + 0}`;
	let selected = itemArray.find((item) => item.draggableId == keyStr);
	if (selected) {
		generateNewNumber(keyStr, itemArray);
	}
	return newKeyStr;
}

export class DataReportComponent extends React.Component {
	constructor(props) {
		super(props);
		this.isEditFilled = false;
		this.state = {
			modalNameValue: '',
			isModalOpen: false,
			activePopperData: {
				ref: null,
				open: false,
				itemId: '',
				itemIndex: -1,
				itemType: '',
				filters: [],
			},
		};
	}

	componentDidMount() {
		this.props.fetchMetaDataAnalysis();
		this.getReportData();
	}

	componentDidUpdate(prevProps) {
		if (
			window?.location?.search !== '' &&
			this.isEditFilled === false &&
			this.props.tabId !== prevProps.tabId
		) {
			// const generateBtn = document.getElementById('generate-data-report');
			// if (generateBtn) {
			// 	this.isEditFilled = true;
			// 	generateBtn.click();
			// }
		}
	}

	getReportData = () => {
		const query = new URLSearchParams(this.props.location.search);
		const reportId = query.get('id');
		if (reportId) {
			this.props.getAnalyticStudioSingleReport(reportId);
		}
	};

	getItem = ({ itemLabel }) => {
		const { measurementArr, dimensionArr, functionArr } =
			this.props.activeTabData;

		let selectedObj = dimensionArr.find((object) => object.label == itemLabel);

		if (!selectedObj) {
			selectedObj = functionArr.find((object) => object.label == itemLabel);

			if (!selectedObj) {
				// selectedObj = measurementArr.find(item => {
				// 	return item.details.some(item_child => item_child.label == item)
				// })
				measurementArr.forEach((item) => {
					const tempSelectedObj = item.details.find(
						(object) => object.label == itemLabel
					);
					if (tempSelectedObj) {
						selectedObj = tempSelectedObj;
					}
				});
			}
		}

		return JSON.parse(JSON.stringify(selectedObj));
	};

	onDragEnd = (result) => {
		const {
			activeTabData: { columnsArray, rowsArray },
			updateColumns,
			updateRows,
			updateColumnsAndRows,
		} = this.props;
		const { source, destination, draggableId } = result;
		const { index: sourceIndex = -1, droppableId: sourceDroppableId = '' } =
			source ?? {};
		const {
			index: destinationIndex = -1,
			droppableId: destinationDroppableId = '',
		} = destination ?? {};

		if (
			!destination ||
			(destinationDroppableId == sourceDroppableId &&
				destinationDroppableId.includes('option')) ||
			(destinationDroppableId.includes('option') &&
				sourceDroppableId.includes('option'))
		) {
			return;
		}

		if (destinationDroppableId == sourceDroppableId) {
			switch (destinationDroppableId) {
				case 'columns':
					let tempColumnsArray = [...columnsArray];
					const tempColumnArrElement = tempColumnsArray[source.index];
					tempColumnsArray[sourceIndex] = tempColumnsArray[destinationIndex];
					tempColumnsArray[destinationIndex] = tempColumnArrElement;
					updateColumns(tempColumnsArray);
					break;
				case 'rows':
					let tempRowsArray = [...rowsArray];
					const tempRowArrElement = tempRowsArray[sourceIndex];
					tempRowsArray[sourceIndex] = tempRowsArray[destinationIndex];
					tempRowsArray[destinationIndex] = tempRowArrElement;
					updateRows(tempRowsArray);
					break;
				default:
					break;
			}
		} else if (
			sourceDroppableId.includes('-option') &&
			(destinationDroppableId === 'columns' ||
				destinationDroppableId === 'rows')
		) {
			const currentItem = this.getItem({ itemLabel: draggableId });
			if (currentItem) {
				switch (destinationDroppableId) {
					case 'columns':
						const tempColumnsArr = [...columnsArray];
						let newColumnStr = generateNewNumber(
							currentItem.label,
							tempColumnsArr
						);
						currentItem.draggableId = newColumnStr;
						tempColumnsArr.splice(destinationIndex, 0, currentItem);
						updateColumns(tempColumnsArr);
						this.handleAddTile('columns', newColumnStr);
						break;
					case 'rows':
						const tempRowsArr = [...rowsArray];
						let newRowStr = generateNewNumber(currentItem.label, tempRowsArr);
						currentItem.draggableId = newRowStr;
						tempRowsArr.splice(destinationIndex, 0, currentItem);
						updateRows(tempRowsArr);
						this.handleAddTile('rows', newRowStr);
						break;
					default:
						break;
				}
			}
		} else if (
			(sourceDroppableId === 'rows' && destinationDroppableId === 'columns') ||
			(sourceDroppableId === 'columns' && destinationDroppableId === 'rows')
		) {
			let tempColumnsArr = [...columnsArray],
				tempRowsArr = [...rowsArray];

			if (
				sourceDroppableId === 'rows' &&
				destinationDroppableId === 'columns'
			) {
				const tempSourceItem = tempRowsArr[sourceIndex];
				tempRowsArr.splice(sourceIndex, 1);
				tempColumnsArr.splice(destinationIndex, 0, tempSourceItem);
			} else {
				const tempSourceItem = tempColumnsArr[sourceIndex];
				tempColumnsArr.splice(sourceIndex, 1);
				tempRowsArr.splice(destinationIndex, 0, tempSourceItem);
			}

			updateColumnsAndRows({
				columnsArray: tempColumnsArr,
				rowsArray: tempRowsArr,
			});
		}
	};

	handleAddTile = (itemType, key) => {	
		setTimeout(() => {
			if (key.includes('Custom Function')){
				const reportElement = document.querySelector(`#generate-data-report`);
				if (reportElement) {reportElement.click()}
			} else {
				const foundElement = document.querySelector(`[data-rbd-droppable-id="${itemType}"] [data-rbd-draggable-id="${key}"]`);
				if (foundElement) {foundElement.click()}
			};		
		}, 500);
	};

	handleColumnItemDelete = (index) => {
		const {
			activeTabData: { columnsArray },
			updateColumns,
		} = this.props;
		const tempColumnsArray = [...columnsArray];

		if (index > -1) {
			tempColumnsArray.splice(index, 1);
			updateColumns(tempColumnsArray);
		}
	};

	handleRowItemDelete = (index) => {
		const {
			activeTabData: { rowsArray },
			updateRows,
		} = this.props;
		const tempRowsArr = [...rowsArray];

		if (index > -1) {
			tempRowsArr.splice(index, 1);
			updateRows(tempRowsArr);
		}
	};

	setModalNameValue = (value) => {
		this.setState({ modalNameValue: value });
	};

	handleSaveData = () => {
		const { modalNameValue } = this.state;

		const payload = {
			name: modalNameValue,
		};
		this.props.saveDataReport(payload);
		this.toggleModal();
	};

	componentWillUnmount() {
		// this.props.resetTableData();
		const { resetTabData, resetTableData } = this.props;
		resetTabData();
		resetTableData();
	}

	toggleModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen });
	};

	handleTabChange = (event, newTabId) => {
		if (newTabId === 'tabProperties') {
			this.props.updateTabData();
		} else {
			this.props.updateActiveTabIndex(newTabId);
		}
	};

	handleTabDelete = (event, tabIndex) => {
		event.stopPropagation();
		const { tabsList, deleteTab } = this.props;
		let tempTabsList = [...tabsList];

		if (tempTabsList?.[tabIndex]) {
			tempTabsList.splice(tabIndex, 1);

			let tempActiveTabIndex = 0;

			if (tabsList.length - 1 > tabIndex) {
				tempActiveTabIndex = tabIndex;
			} else tempActiveTabIndex = tabIndex - 1;

			deleteTab({
				activeTab: tempActiveTabIndex,
				data: tempTabsList,
			});
		}
	};

	handleBackButton = () => {
		const { resetTabData, history } = this.props;
		resetTabData();
		history.push('/dashboard/analytics-studio');
	};

	handleOnChipClick = (event, itemId, itemIndex, itemType, filters) => {
		if (itemId.startsWith("BU")) {
			const {
				activeTabData: { columnsArray, rowsArray }
			} = this.props;
			
			var isOHRec = false
			columnsArray.forEach((ca) => {
				if ((ca.type == "measurement") &&
				(ca.additional.category.filter(ival => ival == "Receivables").length > 0)) {
					isOHRec = true
					return;
				}
			});
			if (!isOHRec) { 
				rowsArray.forEach((ca) => {
					if ((ca.type == "measurement") &&
					(ca.additional.category.filter(ival => ival == "Receivables").length > 0)) {
						isOHRec = true
						return;
					}
				});
			}

			if (!isOHRec) {
				filters.forEach((fi) => {
					fi.values = fi.values.filter(ival => ival.value !== "OH")
				});
			} else {
				filters.forEach((fi) => {
					if (fi.values.filter(ival => ival.value == "OH").length == 0) {
						fi.values.push({"text": "OH", "value": "OH"});
					}
				});
			}
		}
	
		
		this.setState({
			activePopperData: {
				open: true,
				ref: event.currentTarget,
				itemId,
				itemIndex,
				itemType,
				filters,
			},
		});
	};

	handlePopperClickAwayHandler = (event) => {
		if (event.target.localName === 'body') {
			return;
		}
		this.setState({
			activePopperData: {
				open: false,
				ref: null,
				itemId: '',
				itemIndex: -1,
				itemType: '',
				filters: [],
			},
		});
	};

	changeHandler = ({ newItemArray }) => {
		const {
			activeTabData: { columnsArray, rowsArray },
			updateColumns,
			updateRows,
		} = this.props;
		const { itemIndex, itemType } = this.state.activePopperData;
		const foundArr =
			itemType === 'columns'
				? columnsArray
				: itemType === 'rows'
				? rowsArray
				: null;
		if (foundArr && foundArr?.[itemIndex]) {
			let itemArrayTemp = [...foundArr];
			itemArrayTemp[itemIndex].filters = newItemArray;		
			switch (itemType) {
				case 'columns':
					updateColumns(itemArrayTemp);
					break;
				case 'rows':
					updateRows(itemArrayTemp);
					break;
				default:
					break;
			}
		}
	};

	render() {
		const {
			modalNameValue,
			isModalOpen,
			activePopperData: { ref, open, itemId, filters },
		} = this.state;
		const {
			moduleName,
			tabsList,
			activeTabIndex,
			editStatus,
			activeTabData: {
				measurementArr,
				dimensionArr,
				functionArr,
				columnsArray,
				rowsArray,
				customFnObj,
			},
		} = this.props;

		const renderTabs = () => {
			return tabsList.map((item, index) => (
				<Tab
					label={
						<span>
							{item.tabName}
							{index > 0 && (
								<IconButton
									size='small'
									className='ml-10'
									onClick={(event) => this.handleTabDelete(event, index)}
								>
									<CloseIcon fontSize='small' />
								</IconButton>
							)}
						</span>
					}
					id={`scrollable-auto-tab-${index}`}
					key={`scrollable-auto-tab-${index}`}
					aria-controls={`scrollable-auto-tabpanel-${index}`}
					disableRipple={true}
				/>
			));
		};

		return (
			<div className='buplComponent free-cash-flow'>
				<div className='report-table-save-button'>
					<div>
						<span className='mr-5' style={{fontSize:"18px"}}>Report Name -</span>
						<b style={{fontSize:"18px"}}>{moduleName}</b>
					</div>
					<div>
						
						<Button
							style={{ marginRight: '3px' }}
							size='small'
							variant='contained'
							color='secondary'
							className='newButton btnBlue btnSave uppercase'
							onClick={this.toggleModal}
						>
							Save
						</Button>
						<Button
							style={{ marginRight: '3px' }}
							size='small'
							variant='contained'
							className='newButton btnRed btnBack uppercase'
							color='secondary'
							onClick={this.handleBackButton}
						>
							{'< Back'}
						</Button>
					</div>
				</div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Grid container>
						<Grid
							item
							sm={2}
							variant='outlined'
							height='100%'
							className='menu-container'
						>
							<div className='side-menu-container'>
								<div className='side-menu-title mt-5' style={{display:'flex', justifyContent:'center', fontSize:'16px', fontWeight:'600'}}>Selections</div>
								<SideMenu
									keyStr={'Measurement'}
									itemArray={measurementArr}
									typeStr='universal'
								/>
								<SideMenu
									keyStr={'Dimension'}
									itemArray={dimensionArr}
									typeStr='universal'
								/>
								<SideMenu
									keyStr={'Function'}
									itemArray={functionArr}
									typeStr='universal'
								/>
							</div>
						</Grid>
						<Grid item sm={10} variant='outlined' className='main-container'>
							<div className='main-container-wrapper'>
								<AppBar position='static' color='default'>
									<Tabs
										value={activeTabIndex}
										onChange={this.handleTabChange}
										indicatorColor='primary'
										textColor='primary'
										variant='scrollable'
										scrollButtons='auto'
										aria-label='scrollable auto tabs example'
									>
										{renderTabs()}
										<Tab
											disableRipple={true}
											icon={<PostAddIcon />}
											value='tabProperties'
										/>
									</Tabs>
								</AppBar>
								<TabPanel value={activeTabIndex} index={activeTabIndex}>
									<div className='main-container-top'>
										<div className='columns-draggable-section'>
											<div className='left'>
												<FormatListBulletedIcon className='row-column-icon' />
												<span className='text'>Columns</span>
											</div>
											<SelectedMenu
												keyStr='columns'
												typeStr='universal'
												itemArray={columnsArray}
												color='primary'
												onChipClick={this.handleOnChipClick}
												onDelete={this.handleColumnItemDelete}
											/>
										</div>
										<div className='rows-draggable-section mt-10'>
											<div className='left'>
												<FormatListBulletedIcon className='row-column-icon' />
												<span className='text'>Rows</span>
											</div>
											<SelectedMenu
												keyStr='rows'
												typeStr='universal'
												itemArray={rowsArray}
												color='secondary'
												onChipClick={this.handleOnChipClick}
												onDelete={this.handleRowItemDelete}
											/>
										</div>
									</div>
									<div className='main-container-table'>
										<TableComponent
											rowArr={rowsArray}
											columnArr={columnsArray}
											openModal={this.toggleModal}
											activeTabIndex={activeTabIndex}
											editStatus={editStatus}
											customFnObj={customFnObj}
										/>
									</div>
								</TabPanel>
							</div>
						</Grid>
					</Grid>
				</DragDropContext>
				<SimpleModalWrapped
					moduleName={moduleName}
					open={isModalOpen}
					toggleModal={this.toggleModal}
					currentName={modalNameValue}
					setModalNameValue={this.setModalNameValue}
					handleSaveData={this.handleSaveData}
				/>
				<Popper
					id='column-row-popper'
					open={open}
					anchorEl={ref}
					placement='bottom'
					// disablePortal={true}
					style={{ zIndex: '4' }}
					// modifiers={{
					// 	flip: {
					// 		enabled: true,
					// 	},
					// 	preventOverflow: {
					// 		enabled: false,
					// 		boundariesElement: 'scrollParent',
					// 	},
					// }}
				>
					<ClickAwayListener onClickAway={this.handlePopperClickAwayHandler}>
						<Box className='popper-box'>
							<Filters
								itemArray={filters}
								onChange={this.changeHandler}
								itemId={itemId}
							/>
						</Box>
					</ClickAwayListener>
				</Popper>
			</div>
		);
	}
}

export default withRouter(
	connect(
		(state) => {
			const { id, activeTab, data, moduleName } = state.freeCashFlowState.tabs;
			const { editStatus } = state.freeCashFlowState.analyticStudio;
			//console.log(data, activeTab,  data[activeTab], "--activeTabData")
			return {
				tabId: id,
				tabsList: data,
				activeTabIndex: activeTab,
				activeTabData: data[activeTab],
				moduleName: moduleName,
				editStatus : editStatus,
			};
		},
		(dispatch) => {
			return {
				fetchMetaDataAnalysis() {
					dispatch({
						type: 'FETCH_META_DATA_ANALYTIC_STUDIO',
					});
				},
				getAnalyticStudioSingleReport(payload) {
					dispatch({
						type: 'GET_ANALYTIC_STUDIO_SINGLE_REPORT',
						payload,
					});
				},
				resetTableData() {
					dispatch({
						type: 'RESET_TABLE_DATA',
					});
				},
				updateMeasurements(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_MEASUREMENTS',
						data,
					});
				},
				updateDimensions(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_DIMENSIONS',
						data,
					});
				},
				updateFunctions(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_FUNCTIONS',
						data,
					});
				},
				updateColumns(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_COLUMNS',
						data,
					});
				},
				updateRows(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_ROWS',
						data,
					});
				},
				updateColumnsAndRows(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_COLUMNS_AND_ROWS',
						data,
					});
				},
				updateActiveTabIndex(data) {
					dispatch({
						type: 'UPDATE_ACTIVE_TAB_INDEX',
						data,
					});
				},
				updateTabData() {
					dispatch({
						type: 'UPDATE_TAB_DATA',
					});
				},
				deleteTab(data) {
					dispatch({
						type: 'DELETE_TAB',
						data,
					});
				},
				resetTabData() {
					dispatch({
						type: 'RESET_TAB_DATA',
					});
				},
				saveDataReport(data) {
					dispatch({
						type: 'SAVE_ANALYTIC_STUDIO_REPORT',
						payload: data,
					});
				},
			};
		}
	)(DataReportComponent)
);
