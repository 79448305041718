import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../Dashboard/comment';
import PlanModal from '../Dashboard/plan';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SmsIcon from '@material-ui/icons/Sms';
import { APP_ICONS } from '../../../constant';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';


const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

function createData(name, t360, t90, t30, t1, tPlus1, tPlus30, detail) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30, detail};
}

function createDetail(name, t360, t90, t30, t1, tPlus1, tPlus30) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30};
}

const rows = [
  createData(
    'First Class', "", "", "", "", "", "",
    [
     createDetail("Number of seats","", "", "", "", "", ""),
     createDetail("Number of passengers","", "", "", "", "", ""),
     createDetail("Occupancy","", "", "", "", "", "")
    ]
  ),
  createData(
    'Business Class',"", "", "", "", "", "",
    []
  ),
  createData(
    'Premium Economy',"", "", "", "", "", "",
    []
  ),
  createData(
    'Economy',"", "", "", "", "", "",
    []
  ),
  createData(
    'Average Flight Occupancy',"", "", "", "", "", "",
    []
  )
];

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        expandComponent.map(detail => (
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell width="200">{detail.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
         </TableRow>
        ))
      )}
    </>
  );
}


export default function LoadAI() {

  const [open, setOpen] = React.useState(false);
  const [openPlanModal, setOpenPlanModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPlanModal = () => setOpenPlanModal(true);
  const handleClosePlanModal = () => setOpenPlanModal(false);

  return (
    <div className="dashboard-content">
      <Grid container>
          <Grid item xs={5} className="flex ai-center pl-20">
              <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                <FormLabel component="legend" className="text-left">Origin</FormLabel>
                <Select label="Select"> 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                <FormLabel component="legend" className="text-left">Destination</FormLabel>
                <Select label="Select"> 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={7} className="flex jc-flex-end ai-center">
            <FormControl variant="outlined"  size="small" className="mr-30">
              <TextField id="outlined-basic" label="Date" variant="outlined" size="small" type="date" helperText={null} InputLabelProps={{ shrink: true }}/>
            </FormControl>
             <FormControl variant="outlined"  size="small" className="mr-30">
                <TextField id="outlined-basic" label="Search Flight Number" variant="outlined" size="small"/>
              </FormControl>
          </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="flex ai-center mt-30 ml-10">
          <Grid item xs={9} className="form-group flex tab-panel">
            <FormControl variant="outlined" size="small" fullWidth className="filter">
              <InputLabel htmlFor="outlined-age-native-simple">Month</InputLabel>
              <Select
                native
                label="Age"
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth className="filter">
              <InputLabel htmlFor="outlined-age-native-simple">Quarter</InputLabel>
              <Select
                native
                label="Age"
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth className="filter">
              <InputLabel htmlFor="outlined-age-native-simple">FY-21</InputLabel>
              <Select
                native
                label="Age"
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} className="flex jc-flex-end ai-center">
            <Button variant="contained" color="secondary" className="mr-20 btn-custom" size="small" onClick={()=>{handleOpen()}}><SmsIcon/></Button>
            <Button variant="contained" color="secondary" className="mr-30 btn-custom" size="small" onClick={()=>{handleOpenPlanModal()}}>Create Initiatives</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-20 btn-action flex jc-flex-end ai-center ml-30 mr-30">
            <Tooltip title={'Download to Excel'}>
              <img
                src={downloadExcelIcon}
                height='35'
                className='download-icon mr-10'
              />
            </Tooltip>
            <Tooltip title={'Download to Pdf'}>
              <img
                src={downloadPdfIcon}
                height='35'
                className='download-icon'
              />
            </Tooltip>
        </Grid>
        <Grid item xs={12} className="mt-20 mr-30 ml-10">
          <Table className="custom-table" aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell width="200"></TableCell>
                  <TableCell>Flight Number</TableCell>
                  <TableCell>Remaining days and hours</TableCell>
                  <TableCell>Current load factor</TableCell>
                  <TableCell>Target Load Factor</TableCell>
                  <TableCell>Suggested Price change</TableCell>
                  <TableCell>Channel</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <ExpandableTableRow
                    key={row.name}
                    expandComponent={row.detail}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell width="200"></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </ExpandableTableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Modal
            className="collaborate-modal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <CommentBox handleClose={handleClose}/>
            </Fade>
        </Modal>
        <PlanModal open={openPlanModal} handleClose={handleClosePlanModal}/>
    </div>
  );
}
