import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import './index.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector, useDispatch } from "react-redux";
import NewTableComponent from './TableComponent/newTable'
import DownloadExcel from "../../../../images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
import SendEmailModal from '../SendEmail';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewEmailModal from '../ViewEmail';

export default function CompareModal(props) {
    const { plPivotState, emailTemplateData, saveEmailTemplate, getEmailTemplateEbitdaSearchCompare } = props

    
    const [showViewEmail, setShowViewEmail] = useState(false);

    const [periodValues, setPeriodValues] = useState([]);
    const [dataTypeValues, setDataTypeValues] = useState([]);
    const [countryValues, setCountryValues] = useState([]);
    const [plantValues, setPlantValues] = useState([]);
    
    const [salesRegionValues, setSalesRegionValues] = useState([]);
    const [productValues, setProductValues] = useState([]);
    const [dealerValues, setDealerValues] = useState([]);
    const [channelValues, setChannelValues] = useState([]);
    const [costCenterValues, setCostCenterValues] = useState([]);
    const [customerValues, setCustomerValues] = useState([]);
    const geospacialStateN = useSelector(state => state.shreeCementState);
    const { compareDataPNLEbitda } = geospacialStateN;
    const { rowData, filter } = compareDataPNLEbitda;
    const { plantArr, dataTypeArr, countryArr, periodArr, salesRegionArr, productArr, dealerArr, channelArr, costCenterArr, customerArr } = filter;

    const [showSendEmail, setShowSendEmail] = useState(false);

    const { quarterfilterArr, monthFilterArr, yearFilterArr } = geospacialStateN
    const dispatch = useDispatch();
    const { tabSelectorValue, radioSelectorValue } = props;
    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>{inputLabel}</InputLabel>
                <Select
                    className='dropdown2-select'
                    onChange={handleChange}
                    value={selectedValue}
                    label={inputLabel}
                >
                    <MenuItem value="">Select</MenuItem>
                    {menuItems.map((item, index) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>


        );
    };

    const SelectDropdown2 = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>{inputLabel}</InputLabel>
                <Select
                    className='dropdown2-select'
                    onChange={handleChange}
                    value={selectedValue}
                    label={inputLabel}
                >
                    <MenuItem value="">Select</MenuItem>
                    {menuItems.map((item, index) => {
                        return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                    })}
                </Select>
            </FormControl>


        );
    };

    React.useEffect(() => {
        dispatch({
            type: "FETCH_COMPARE_FILTER_PNL_EBITDA"
        })
    }, [])

    // const handleBuChange = (index, value) => {
    //     const newInputValues = [...buValues];
    //     newInputValues[index] = value;
    //     setBUValues(newInputValues);
    // };

    const handlePeriodChange = (index, value) => {
        const newInputValues = [...periodValues];
        newInputValues[index] = value;
        setPeriodValues(newInputValues);
    };

    const handleDataTypeChange = (index, value) => {
        const newInputValues = [...dataTypeValues];
        newInputValues[index] = value;
        setDataTypeValues(newInputValues);
    };

    const handleCountryChange = (index, value) => {
        const newInputValues = [...countryValues];
        newInputValues[index] = value;
        setCountryValues(newInputValues);
    };

    const handlePlantChange = (index, value) => {
        const newInputValues = [...plantValues];
        newInputValues[index] = value;
        setPlantValues(newInputValues);
    };

    const handleSalesRegionChange = (index, value) => {
        const newInputValues = [...salesRegionValues];
        newInputValues[index] = value;
        setSalesRegionValues(newInputValues);
    };
    const handleProductChange = (index, value) => {
        const newInputValues = [...productValues];
        newInputValues[index] = value;
        setProductValues(newInputValues);
    };
    const handleDealerChange = (index, value) => {
        const newInputValues = [...dealerValues];
        newInputValues[index] = value;
        setDealerValues(newInputValues);
    };
    const handleChannelChange = (index, value) => {
        const newInputValues = [...channelValues];
        newInputValues[index] = value;
        setChannelValues(newInputValues);
    };
    const handleCostCenterChange = (index, value) => {
        const newInputValues = [...costCenterValues];
        newInputValues[index] = value;
        setCostCenterValues(newInputValues);
    };
    const handleCustomerChange = (index, value) => {
        const newInputValues = [...customerValues];
        newInputValues[index] = value;
        setCustomerValues(newInputValues);
    };

    const handleClose = () => {
        handleResetState()
        props.handleClose()
    }

    const handleResetState = () => {
        // setBUValues([])
        setPeriodValues([])
        setDataTypeValues([])
        setCountryValues([])
        setPlantValues([])

        setSalesRegionValues([])
        setProductValues([])
        setDealerValues([])
        setChannelValues([])
        setCostCenterValues([])
        setCustomerValues([])

        dispatch({
            type: "RESET_COMPARE_DATA_PNL_EBITDA"
        })
    }



    const handleExcelDownload = () => {
        handleRefresh(true)
    }

    const handleRefresh = (isDownload, emailPayload) => {
        let param = `exclude=&legal_entity=&`
        const payload = {
            data: [],
            'export': isDownload ? true : false,
           
        }

        if(emailPayload){
            payload["send_email"] = emailPayload.sendEmail
            payload["to"] = emailPayload.to.join(",")
            payload["cc"] = emailPayload.cc.join(",")
        }

        const payloadData = []
        // buValues.forEach((item, index) => {
        //     const newData = {
        //         period: '',
        //         bu: item,
        //         plant: '',
        //         country: ''
        //     }
        //     payloadData.push(newData)

        // })
        periodValues.forEach((item, index) => {
            const newData = {
                period: item,
                data_type: '',
                plant: '',
                country: ''
            }
            payloadData.push(newData)
        })
       
        payloadData.forEach((item, index) => {
            // payloadData[index]['period'] = periodValues[index] ? periodValues[index] : ''
            payloadData[index]['data_type'] = dataTypeValues[index] ? dataTypeValues[index] : ''
            payloadData[index]['plant'] = plantValues[index] ? plantValues[index] : ''
            payloadData[index]['country'] = countryValues[index] ? countryValues[index] : ''

        })
        payload.data = payloadData

        dispatch({
            type: "FETCH_COMPARE_DATA_PNL_EBITDA",
            payload
        })
    }

    const handleSendEmailClose = () => {
        setShowSendEmail(false)
    }

    const handleSendEmail = (payload) =>{
        handleRefresh(true, {  sendEmail : true, to : payload.to, cc : payload.cc })
    }

    const openViewEmail = () => {
        setShowViewEmail(true);
        getEmailTemplateEbitdaSearchCompare({});
    }
    
    const handleViewEmailClose = () => {
        setShowViewEmail(false);
    }

    const body = (

        <div className='customModal compare-modal'>
            <div className='header'>
                <h2>Search and Compare Data</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content bupl-Modal'>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className="accordion-summary">
                            <Typography className='bold-title'>Comparison Parameters</Typography>
                            <div className='refresh-clear-button' style={{width:'50%'}}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className="mr-10 newButton btnBlue btnEmail uppercase"
                                    endIcon={<Icon>visibility</Icon>}
                                    onClick={(event) => {  event.stopPropagation();  openViewEmail() }}
                                >
                                    View Email
                                </Button>
                                <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                className="mr-10 newButton btnBlue btnEmail uppercase"
                                                endIcon={<Icon>send</Icon>}
                                                onClick={(event)=>{   event.stopPropagation(); setShowSendEmail(true);  } }
                                            >
                                                Send Email
                                            </Button>
                                <Button variant="contained" className="newButton btnBlue uppercase btnRefresh"
                                    onClick={() => handleRefresh(false)}>
                                    Refresh
                                </Button>
                                <Button className="newButtonOutlined btnBlue btnReset"
                                variant="outlined" color="primary"
                                    onClick={handleResetState}>
                                    Reset
                                </Button>
                                {rowData.length ? <Tooltip title={'Download to Excel'} className='pointer'>
                                    <img
                                        src={DownloadExcel}
                                        className='pointer'
                                        height='30'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleExcelDownload();
                                        }} />
                                </Tooltip> : null}

                            </div>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails className=''>
                        <div className="main-div">
                            <div className='sub-section'>

                                <div className='sub-right-section'>
                                    <div className="sub-section-right-div1">
                                        {/* <span>BU</span> */}
                                        <span>Period</span>
                                        <span>Data Type</span>
                                        <span>Country</span>
                                        <span>Plant</span>

                                        <span>Sales Region</span>
                                        <span>Product</span>
                                        <span>Dealer</span>
                                        <span>Channel</span>
                                        <span>Cost Center</span>
                                        <span>Customer</span>
                                    </div>
                                    <div className='sub-section-right-div1'>
                                        {/* <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(0, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[0]}
                                            />
                                        </div> */}
                                        <div>
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(0, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[0]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dataTypeArr}
                                                handleChange={(e) => handleDataTypeChange(0, e.target.value)}
                                                inputLabel={'Data Type'}
                                                selectedValue={dataTypeValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e) => handleCountryChange(0, e.target.value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e) => handlePlantChange(0, e.target.value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[0]}
                                            />
                                        </div>}

                                        {<div>
                                            <SelectDropdown
                                                menuItems={salesRegionArr}
                                                handleChange={(e) => handleSalesRegionChange(0, e.target.value)}
                                                inputLabel={'Sales Region'}
                                                selectedValue={salesRegionValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={productArr}
                                                handleChange={(e) => handleProductChange(0, e.target.value)}
                                                inputLabel={'Product'}
                                                selectedValue={productValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dealerArr}
                                                handleChange={(e) => handleDealerChange(0, e.target.value)}
                                                inputLabel={'Dealer'}
                                                selectedValue={dealerValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={channelArr}
                                                handleChange={(e) => handleChannelChange(0, e.target.value)}
                                                inputLabel={'Channel'}
                                                selectedValue={channelValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={costCenterArr}
                                                handleChange={(e) => handleCostCenterChange(0, e.target.value)}
                                                inputLabel={'Cost Center'}
                                                selectedValue={costCenterValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={customerArr}
                                                handleChange={(e) => handleCustomerChange(0, e.target.value)}
                                                inputLabel={'Customer'}
                                                selectedValue={customerValues[0]}
                                            />
                                        </div>}
                                    </div>
                                    <div className='sub-section-right-div1'>
                                        {/* <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(1, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[1]}
                                            />
                                        </div> */}
                                        <div>
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(1, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[1]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dataTypeArr}
                                                handleChange={(e) => handleDataTypeChange(1, e.target.value)}
                                                inputLabel={'Data Type'}
                                                selectedValue={dataTypeValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e) => handleCountryChange(1, e.target.value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e) => handlePlantChange(1, e.target.value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[1]}
                                            />
                                        </div>}

                                        {<div>
                                            <SelectDropdown
                                                menuItems={salesRegionArr}
                                                handleChange={(e) => handleSalesRegionChange(1, e.target.value)}
                                                inputLabel={'Sales Region'}
                                                selectedValue={salesRegionValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={productArr}
                                                handleChange={(e) => handleProductChange(1, e.target.value)}
                                                inputLabel={'Product'}
                                                selectedValue={productValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dealerArr}
                                                handleChange={(e) => handleDealerChange(1, e.target.value)}
                                                inputLabel={'Dealer'}
                                                selectedValue={dealerValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={channelArr}
                                                handleChange={(e) => handleChannelChange(1, e.target.value)}
                                                inputLabel={'Channel'}
                                                selectedValue={channelValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={costCenterArr}
                                                handleChange={(e) => handleCostCenterChange(1, e.target.value)}
                                                inputLabel={'Cost Center'}
                                                selectedValue={costCenterValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={customerArr}
                                                handleChange={(e) => handleCustomerChange(1, e.target.value)}
                                                inputLabel={'Customer'}
                                                selectedValue={customerValues[1]}
                                            />
                                        </div>}
                                    </div>
                                    <div className='sub-section-right-div1'>
                                        {/* <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(2, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[2]}
                                            />
                                        </div> */}
                                        <div>
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(2, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[2]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dataTypeArr}
                                                handleChange={(e) => handleDataTypeChange(2, e.target.value)}
                                                inputLabel={'Data Type'}
                                                selectedValue={dataTypeValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e) => handleCountryChange(2, e.target.value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e) => handlePlantChange(2, e.target.value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[2]}
                                            />
                                        </div>}

                                        {<div>
                                            <SelectDropdown
                                                menuItems={salesRegionArr}
                                                handleChange={(e) => handleSalesRegionChange(2, e.target.value)}
                                                inputLabel={'Sales Region'}
                                                selectedValue={salesRegionValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={productArr}
                                                handleChange={(e) => handleProductChange(2, e.target.value)}
                                                inputLabel={'Product'}
                                                selectedValue={productValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={dealerArr}
                                                handleChange={(e) => handleDealerChange(2, e.target.value)}
                                                inputLabel={'Dealer'}
                                                selectedValue={dealerValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={channelArr}
                                                handleChange={(e) => handleChannelChange(2, e.target.value)}
                                                inputLabel={'Channel'}
                                                selectedValue={channelValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={costCenterArr}
                                                handleChange={(e) => handleCostCenterChange(2, e.target.value)}
                                                inputLabel={'Cost Center'}
                                                selectedValue={costCenterValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={customerArr}
                                                handleChange={(e) => handleCustomerChange(2, e.target.value)}
                                                inputLabel={'Customer'}
                                                selectedValue={customerValues[2]}
                                            />
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </AccordionDetails>

                </Accordion>

                {/* <TableComponent
                    tabSelectorValue={tabSelectorValue}
                    radioSelectorValue={radioSelectorValue}
                    // plPivotState={plPivotState}
                /> */}
                { plPivotState.loading ? <div className='progressfullpage'><CircularProgress /></div> : ''}
                <NewTableComponent 
                    className={'ebitdaCustomTable fixedFirstTwoColumTable expandableRows'} 
                    tableData={plPivotState} />
            

                <ViewEmailModal
                    viewEmail={showViewEmail}
                    modalCallBack={() => { handleViewEmailClose() }}
                    header={ "View Email" }
                    emailTemplateData={emailTemplateData}
                    emailType={ "ebitda_search_and_compare_email" }
                    saveEmailTemplate={saveEmailTemplate}
                />
            <SendEmailModal
                    showModal={showSendEmail}
                    modalCallBack={handleSendEmailClose}
                    header={ "Choose Recipient" }
                    sendEmail={handleSendEmail}
                    users={props.users}
                />
        </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
                
            </Fade>
        </Modal>
    );
}