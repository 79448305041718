import React from 'react';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export default function CommentsModal(props) {

    const body = (
        <div className='customModal commentModal'>
            <div className='header'>
                <h2>Comments</h2>
                <Icon onClick={() => { props.handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <form>
                    <div className='commentInputSection flex ai-center jc-space-between'>
                        <TextField
                            label="Add Comment"
                            name="comment"
                            placeholder="Placeholder"
                            multiline
                            variant="outlined"
                            size="small"
                        />
                        <Tooltip title='Send'>
                            <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0'>
                                <Icon fontSize="small">send</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className='commentsSection'>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div> {body} </div>
        // 
    );
}