import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import './index.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector, useDispatch } from "react-redux";
import TableComponent from './TableComponent'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { Autocomplete, TextField } from '@mui/material';


export default function CompareModal(props) {
    const [buValues, setBUValues] = useState([]);
    const [periodValues, setPeriodValues] = useState([]);
    const [countryValues, setCountryValues] = useState([]);
    const [plantValues, setPlantValues] = useState([]);
    const incomeStatementState = useSelector(state => state.incomeStatement);
    const { compareDataBUPL } = incomeStatementState
    const { rowData, filter } = compareDataBUPL
    const { buArr, plantArr, countryArr, periodArr } = filter;

    const { quarterfilterArr, monthFilterArr, yearFilterArr } = incomeStatementState
    const dispatch = useDispatch();
    const { tabSelectorValue, radioSelectorValue } = props;
    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            // <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
            //     <InputLabel>{inputLabel}</InputLabel>
            //     <Select
            //         className='dropdown2-select'
            //         onChange={handleChange}
            //         value={selectedValue}
            //         label={inputLabel}
            //     >
            //         <MenuItem value="">Select</MenuItem>
            //         {menuItems.map((item, index) => {
            //             return <MenuItem value={item} key={item}>{item}</MenuItem>
            //         })}
            //     </Select>
            // </FormControl>
            <Autocomplete
                disablePortal
                fullWidth
                disableClearable
                size='small'
                className='xpna-dropdown single'
                options={menuItems}
                value={selectedValue}
                onChange={handleChange}
                renderInput={(params)=>(<TextField {...params} variant='outlined' label={inputLabel} placeholder='Select' />)}
            />
        );
    };

    const selectedOptions = (a, data) => {
        console.log ('a', a)
        if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
        else return {label:'', value:''}
        
    };

    const SelectDropdown2 = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            // <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
            //     <InputLabel>{inputLabel}</InputLabel>
            //     <Select
            //         className='dropdown2-select'
            //         onChange={handleChange}
            //         value={selectedValue}
            //         label={inputLabel}
            //     >
            //         <MenuItem value="">Select</MenuItem>
            //         {menuItems.map((item, index) => {
            //             return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
            //         })}
            //     </Select>
            // </FormControl>
            <Autocomplete
            disablePortal
            fullWidth
            disableClearable
            size='small'
            className='xpna-dropdown single'
            options={menuItems}
            //value={selectedValue}
            value= {menuItems && menuItems.length && selectedValue? selectedOptions(selectedValue, menuItems)[0] : { label: '', value: '' }}
            onChange={handleChange}
            renderInput={(params)=>(<TextField {...params} variant='outlined' label={inputLabel} placeholder='Select' />)}
        />


        );
    };

    React.useEffect(() => {
        dispatch({
            type: "FETCH_COMPARE_FILTER_BUPL"
        })
    }, [])

    const handleBuChange = (index, value) => {
        const newInputValues = [...buValues];
        newInputValues[index] = value;
        setBUValues(newInputValues);
    };

    const handlePeriodChange = (index, newvalue) => {
        const newInputValues = [...periodValues];
        newInputValues[index] = newvalue.value;
        setPeriodValues(newInputValues);
    };

    const handleCountryChange = (index, value) => {
        const newInputValues = [...countryValues];
        newInputValues[index] = value;
        setCountryValues(newInputValues);
    };

    const handlePlantChange = (index, value) => {
        const newInputValues = [...plantValues];
        newInputValues[index] = value;
        setPlantValues(newInputValues);
    };


    const handleClose = () => {
        handleResetState()
        props.handleClose()
    }

    const handleResetState = () => {
        setBUValues([])
        setPeriodValues([])
        setCountryValues([])
        setPlantValues([])
        dispatch({
            type: "RESET_COMPARE_DATA_BUPL"
        })
    }



    const handleExcelDownload = () => {
        handleRefresh(true)
    }

    const handleRefresh = (isDownload) => {
        let param = `exclude=&legal_entity=&`
        const payload = {
            data: [],
            'export': isDownload ? true : false
        }
        const payloadData = []

        buValues.forEach((item, index) => {
            const newData = {
                period: '',
                bu: item,
                plant: '',
                country: ''
            }
            payloadData.push(newData)

        })
       
        payloadData.forEach((item, index) => {

            payloadData[index]['period'] = periodValues[index] ? periodValues[index] : ''
            payloadData[index]['plant'] = plantValues[index] ? plantValues[index] : ''
            payloadData[index]['country'] = countryValues[index] ? countryValues[index] : ''

        })
        payload.data = payloadData

        dispatch({
            type: "FETCH_COMPARE_DATA_BUPL",
            payload
        })
    }

    const body = (

        <div className='customModal'>
            <div className='header'>
                <h2>Compare Data</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content bupl-Modal'>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className="accordion-summary">
                            <span className='bold-title'>Comparison Parameters</span>
                            <div className='refresh-clear-button'>
                                <Button variant="contained" className="newButton btnBlue uppercase btnRefresh"
                                    onClick={() => handleRefresh()}>
                                    Refresh
                                </Button>
                                <Button className="newButtonOutlined btnBlue uppercase btnReset"
                                variant="outlined" color="primary"
                                    onClick={handleResetState}>
                                    Reset
                                </Button>
                                {rowData.length ? <Tooltip title={'Download to Excel'} className='pointer'>
                                    <img
                                        src={DownloadExcel}
                                        className='pointer'
                                        height='30'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleExcelDownload();
                                        }} />
                                </Tooltip> : null}

                            </div>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails className=''>
                        <div className="main-div">
                            <div className='sub-section'>

                                <div className='sub-right-section'>
                                    <div className="sub-section-right-div">
                                        <span>BU</span>
                                        <span>Period</span>
                                        <span>Country</span>
                                        <span>Plant</span>
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div >
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e, value) => handleBuChange(0, value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[0]}
                                            />
                                        </div>
                                        <div >
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e, value) => handlePeriodChange(0, value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[0]}
                                            />
                                        </div>
                                        {<div >
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e, value) => handleCountryChange(0, value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[0]}
                                            />
                                        </div>}
                                        {<div >
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e, value) => handlePlantChange(0, value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[0]}
                                            />
                                        </div >}
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div >
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e, value) => handleBuChange(1, value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[1]}
                                            />
                                        </div>
                                        <div >
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e, value) => handlePeriodChange(1, value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[1]}
                                            />
                                        </div>
                                        {<div >
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e, value) => handleCountryChange(1, value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[1]}
                                            />
                                        </div>}
                                        {<div  >
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e, value) => handlePlantChange(1, value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[1]}
                                            />
                                        </div>}
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div  >
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e, value) => handleBuChange(2, value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[2]}
                                            />
                                        </div>
                                        <div  >
                                            <SelectDropdown2
                                                menuItems={periodArr}
                                                handleChange={(e, value) => handlePeriodChange(2, value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[2]}
                                            />
                                        </div>
                                        {<div  >
                                            <SelectDropdown
                                                menuItems={countryArr}
                                                handleChange={(e, value) => handleCountryChange(2, value)}
                                                inputLabel={'Country'}
                                                selectedValue={countryValues[2]}
                                            />
                                        </div>}
                                        {<div  >
                                            <SelectDropdown
                                                menuItems={plantArr}
                                                handleChange={(e, value) => handlePlantChange(2, value)}
                                                inputLabel={'Plant'}
                                                selectedValue={plantValues[2]}
                                            />
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </AccordionDetails>

                </Accordion>

                <TableComponent
                    tabSelectorValue={tabSelectorValue}
                    radioSelectorValue={radioSelectorValue}
                />
            </div>

        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}