import React from 'react';
import Grid from '@material-ui/core/Grid';
import MapImg from "../../../images/KuwaitAirways/map_new.png"

export default function Map(props) {
  
    const handleClick = () => {
        window.location = '/route-profitability/dashboard-detail';
    }


  return (
    <Grid container className="map">
        <Grid item xs={12}>
            <img src={MapImg} className="map-img" onClick={()=>{handleClick()}}/>
        </Grid>
    </Grid>
  );
}
