import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import NewTableComponent from '../newTable'
import DownloadExcel from "../../../../../images/download_excel.svg";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

export default function (props) {
    const geospacialState = useSelector(state => state.shreeCementState);
    const dispatch = useDispatch();
    const [monthValue, setMonthValue] = useState('')
    const [monthArr, setMonthArr] = useState([])
    const { actualForecastCementData } = geospacialState;
    const { handleOpen, handShowCompare } = props

    useEffect(() => {
        fetchTableData()
    }, [monthValue])

    useEffect(() => {
        if (actualForecastCementData.filterArr) {
            setMonthArr(actualForecastCementData.filterArr)
            setMonthValue('30-Apr-2023')
        }
    }, [actualForecastCementData.filterArr])

    const fetchTableData = (isExport) => {
        const payload = {
            month: monthValue ? monthValue : '30-Apr-2023',
            export: isExport
        }
        dispatch({
            type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA",
            payload
        })
    }

    const handleMonthChange = (event) => {
        setMonthValue(event.target.value)
    }



    return (
        <Grid container xs={12} sm={12} className="pb-15">
            <Grid item xs={12} sm={12} className="pt-0 flex ai-center jc-space-between mt-5 mb-5">
                <Grid item xs={9} sm={9} className='maxw-200'>
                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={monthValue}
                            label="Month"
                            defaultValue={'30-Apr-2023'}
                            onChange={handleMonthChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {monthArr && monthArr.length && monthArr.map(item => {
                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} className="pt-10 download-section" >
                    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <Button variant='contained' className='run-button mr-10 newButton btnBlue btnCompare' onClick={handShowCompare}>Search and Compare</Button>
                        <Tooltip title={"Collaborate"}>
                            <Icon
                                className="fs-20 primary mr-10"
                                color={"inherit"}
                                disabled
                                onClick={() => {
                                    handleOpen();
                                }
                                }
                            >
                                chat_bubble_filled
                            </Icon>
                        </Tooltip>
                        <Tooltip title={'Download to Excel'}>
                            <img
                                src={DownloadExcel}
                                className='pointer mr-10'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    fetchTableData(true);
                                }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="pt-0">
                {actualForecastCementData.loading ? <div className='progressfullpage'><CircularProgress /></div> :
                    <div style={{maxHeight:'700px', overflow:'scroll'}}>
                    <NewTableComponent className={'ebitdaCustomTable fixedFirstTwoColumTable expandableRows'} tableData={actualForecastCementData} />
                    </div>
                }
            </Grid>
        </Grid>

    )
}
