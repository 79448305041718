import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function InProgressComponent(props) {
  return (
    <>
      <div className='progressfullpage'><CircularProgress /></div>
      <Modal
        aria-labelledby="upload-in-progress"
        aria-describedby="upload-in-progress"
        style={{ zIndex: 99999 }}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='customModal' style={{ width: '300px' }}>
            <div className='header'>{props.headerText}</div>
            <div className='modal-content'>
              <p className='text-center'>{props.bodyText}</p>
              <div className='text-center'><CircularProgress /></div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
