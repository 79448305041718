const showPasswordReset = localStorage.getItem('showPasswordReset')
const reducer = (state = {
  loading: false,
  userData: {},
  menuData: [],
  notificationData: { data: [], count: 0 },
  error: false,
  isEmbedIframe: false,
  resetTokenSuccess: false,
  verifyTokenLoading: false,
  showResetModal: showPasswordReset ? JSON.parse(showPasswordReset) : false,
}, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'GET_NEWS':
      return { ...state, loading: true };

    case 'PERFORM_LOGIN_LOADER':
      newState.loading = action.loading;
      newState.error = action.error;
      return newState;

    case 'PERFORM_EMBED_HANDLING' :
      newState.isEmbedIframe = true;
      return newState;

    case 'PERFORM_CHANGE_PASSWORD_SUCCESS':
      newState.showResetModal = false;
      return newState;

    case 'VERIFY_TOKEN_LOADING':
      newState.verifyTokenLoading = action.loading;
      return newState;

    case 'VERIFY_TOKEN_SUCCESS':
      newState.resetTokenSuccess = true;
      newState.verifyTokenLoading = false;
      return newState;

    case 'PERFORM_GET_NOTIFICATION_SUCCESS':
      if (action.data){
        newState.notificationData.data = action.data.data;
        newState.notificationData.count = action.data && action.data.count && action.data.count.count;
      }

      return newState;

    case 'PERFORM_UPDATE_NOTIFICATION_SUCCESS':
      newState.notificationData.count = 0;
      return newState;

    case 'PERFORM_LOGIN_SUCCESS':
      newState.loading = false;
      newState.userData = action.userData;
      newState.showResetModal = action.userData.reset_password_reminder
      return newState;

    case 'PERFORM_GENERATE_PASSWORD_MODAL':

      newState.showResetModal = true
      return newState;

    case 'PERFORM_GET_DETAIL_SUCCESS':
      newState.loading = false;
      newState.userData = action.userData;
      newState.menuData = action.userData.menus;
      return newState;

    default:
      return newState;
  }
};
export default reducer;
