import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../../utils/numberUtils'
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import NewPagination from "../../../../common/components/CustomPagination";

import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

export default function TableComponent(props) {
	const ecfmState = useSelector(state => state.ecfmState);
	const { isEditData } = props
	let {handleSaveChange} = props
	const { drilldownData } = ecfmState
	const { row_data, loading, row_header } = drilldownData

	// console.log(drilldownData, "--drilldown yw")

	
	const modifyColumnDataOCOSVal = (columnData, operations) => {
		let modifiedData;
		modifiedData = columnData.toFixed(1);
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = modifiedData == '0.0' ? '-' : modifiedData;
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;

		if (operations == 'percentage' && modifiedData != '-') {
			modifiedData += '%';
		}

		return modifiedData;
	};

	const handleAppendPercentage = (data) => {
		if (data && data['data_field']) {
			let dataField = data['data_field'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};

	const handleFCFRedirect = (value) => {
		window.localStorage.setItem('openNewTabFlag', value)
		window.open('/dashboard/free-cashflow-analytics/free-cashflow', '_blank')
	}

	const modifyNewColumnData = (columnData, operations) => {
		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};

	const handleHeaderTooltip = () => {
		return '';
	};

	const modifyColumnData = (columnData, operations, itemKey) => {
		let modifiedData;
		modifiedData = Math.round(columnData);
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage' || itemKey?.includes('_pct')) {
			modifiedData += '%';
		}

		return modifiedData;
	};

	const handleInputChange = (_value, props, item, parentData) => {
		handleSaveChange(_value, props, item, parentData)
	}

	const
		handleSubColumnChangeHeader = (data, parentData) => {
			if (data && data.length) {
				return data.map((item, i) => {

					if (item.key == 'auto_email') {
						return {
							Header: item.label,
							id: item.key,
							width: 80,
							accessor: item.key,
							Cell: (props) => {
								return (
									<div className="flex ai-center jc-space-evenly">
										{(
											<Tooltip title="Auto Emails">
												<Icon
													className="fs-20 primary"
													color={
														"inherit"
													}
													onClick={() => { handleFCFRedirect('8') }}
												>
													mail_outline
												</Icon>
											</Tooltip>
										)}
									</div>
								)
							},
							style: {
								fontSize: '12px',
								cursor: item.key == 'grand_total' ? '' : 'pointer',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							},
							headerStyle: {
								whiteSpace: 'break-spaces',
								overflow: 'visible',
								// fontSize: '13px',
								// fontWeight: 'bold',
							}
						}

					}

					if (item.key == 'initiative') {
						return {
							Header: item.label,
							id: item.key,
							width: 80,
							accessor: item.key,
							Cell: (props) => {
								return (
									<div className="flex ai-center jc-space-evenly">
										{(
											<Tooltip title="View Initiative">
												<Icon
													className="fs-20 primary"
													color={
														"inherit"
													}
													onClick={() => { handleFCFRedirect('4') }}
												>
													remove_red_eye
												</Icon>
											</Tooltip>

										)}
										<Tooltip title={"Add Initiative"}>
											<Icon
												color={"inherit"}
												disabled
												onClick={() => { handleFCFRedirect('2') }}
											>
												add_circle_outline
											</Icon>
										</Tooltip>
									</div>
								)
							},
							style: {
								fontSize: '12px',
								cursor: item.key == 'grand_total' ? '' : 'pointer',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							},
							headerStyle: {
								whiteSpace: 'break-spaces',
								overflow: 'visible',
								// fontSize: '13px',
								// fontWeight: 'bold',
							}
						}

					}

					if (item.key == 'comment') {
						return {
							Header: item.label,
							id: item.key,
							width: 80,
							accessor: item.key,
							Cell: (props) => {
								return (
									<div className="flex ai-center jc-space-evenly">
										{(
											<Tooltip title={"Comment"}>
												<Icon
													className="fs-20 primary"
													color={"inherit"}
													disabled
													onClick={() => { }}
												>
													chat_bubble_filled
												</Icon>
											</Tooltip>
										)}
									</div>
								)
							},
							style: {
								fontSize: '12px',
								cursor: item.key == 'grand_total' ? '' : 'pointer',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							},
							headerStyle: {
								whiteSpace: 'break-spaces',
								overflow: 'visible',
								// fontSize: '13px',
								// fontWeight: 'bold',
							}
						}

					}

					if (item.key == 'chat') {
						return {
							Header: item.label,
							id: item.key,
							width: 70,
							accessor: item.key,
							Cell: (props) => {
								return (
									<div className="flex ai-center jc-space-evenly">
										{(
											<Tooltip title={"Chat"}>
												<Icon
													className="fs-20 primary"
													color={"inherit"}
													disabled
													onClick={() => {
														// showCommentModal(props.original)
														// handleRowClick(props)
													}}
												>
													chat_bubble_outline
												</Icon>
											</Tooltip>
										)}
									</div>
								)
							},
							style: {
								fontSize: '12px',
								cursor: item.key == 'grand_total' ? '' : 'pointer',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							},
							headerStyle: {
								whiteSpace: 'break-spaces',
								overflow: 'visible',
								// fontSize: '13px',
								// fontWeight: 'bold',
							}
						}

					}

					return {
						Header: (
							<span
								onClick={() => { }}
							>
								{item.label}
							</span>
							//</Tooltip>
						),
						columns: handleSubColumnChangeHeader(item.sub_headers, item),
						id: item.key,
						Cell: (props) => {
							let isManualKey = false;
							let showPercentage = handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							let disableDrillDown = props.original['disable_drill_down'];
							myClassName +=
								disableDrillDown ||
									item.key == 'sub_total' ||
									item.key == 'total' ||
									item.key == 'grand_total'
									? ''
									: ' enableDrillDown';

							let classNames = '';
							if (!props.original.disable_drill_down) {
								isManualKey = true;
							}
							if (isManualKey == true) {
								classNames = 'text-center pointer';
							} else {
								classNames = 'text-center';
							}

							if (item.key == 'project_manager_name' || item.key == 'customer') {
								classNames = 'text-left word-break';
							}


							const valToDisplay = props.original.sub_column &&
								props.original.sub_column[parentData.key] &&
								props.original.sub_column[parentData.key][item.key]
								? item.operation ? modifyColumnData(
									props.original.sub_column[parentData.key][item.key],
									props.original.sub_column[parentData.key][
									'operations'
									], item.key
								) : props.original.sub_column[parentData.key][item.key]
								: '-'

							const showPerComponent = item.key.includes('_pct') ? <InputAdornment position="end">%</InputAdornment> : null
							return (
								<div className={classNames + ' BUPlanInput'}>
									{parentData.key == 'plan' && isEditData ? <div className="maxw-150">
										<FormControl sx={{ m: 1, width: '10ch' }} variant="outlined">
											<OutlinedInput
												id="outlined-adornment-weight"
												type="number"
												defaultValue={props.original.sub_column[parentData.key][item.key]}
												onChange={(event) => { handleInputChange(event.target.value, props.original, item, parentData) }}
												endAdornment={showPerComponent}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
													'aria-label': 'weight',
												}}
											/>
										</FormControl>
									</div> :
										<span className={myClassName}>
											{valToDisplay}
										</span>

									}

								</div>
							);
						},
						style: {
							fontSize: '12px',
							borderRight: i == data.length - 1 ? '1px solid #423970' : '',
							width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							overflow: 'visible',
							//fontSize: '13px',
							width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
							//fontWeight: 'bold',
							borderRight: i == data.length - 1 ? '1px solid #423970' : '',
						},
					};
				});
			}
		};


	const changeKeysOfHeader = (data, isNested) => {
		if (data && data.length) {
			return data.map((item, i) => {
				item['operation'] =
					item.key == 'data_field' ||
						item.key == 'data_group' ||
						item.key == 'data_company_code' ||
						item.key == 'data_ra' ||
						item.key == 'so_item' || item.key == 'project_id'
						? ''
						: 7;
				if (item.key == 'data_group') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								id: item.key,
								fixed: 'left',
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_group' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Grand Total'
											? 'totalLabel'
											: '';

									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'project_id' || item.key == 'project_manager_name' || item.key == 'country_name_origination') {
					return {
						Header: '',
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'data_field') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								// Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'so_item' || item.key == 'plant' || item.key == 'legal_entity') {
					return {
						Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
						id: item.key,
						metaData: item.metadata,
						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
						style: {
							textAlign: 'center',
						},
					};
				} else if (item.key == 'data_company_code') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_company_code' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Total' ? 'totalLabel' : '';
									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'data_ra') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_ra' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key.indexOf('_pct') > -1) {
					let label = item.label;
					// if(item.key == "pct_digital_services"){
					//     label = "DASS Services" +<br />+ "% of Revenue"
					// }
					return {
						Header: () => {
							let spl = item.label.split('%');
							if (spl[0] != '') {
								return (
									<span className={'multi-header'}>
										{spl[0]}
										<br />
										{' % ' + spl[1]}
									</span>
								);
							} else {
								return item.label;
							}
						},
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{
										<span>
											{props.original[item.key] != undefined
												? modifyColumnDataOCOSVal(
													props.original[item.key],
													'percentage'
												)
												: '-'}
										</span>
									}
								</div>
							);
						},
					};
				} else if (
					item.key == 'company_code' ||
					item.key == 'country' ||
					item.key == 'classification' ||
					item.key == 'source' ||
					item.key == 'summary_acct' ||
					item.key == 'summary_acct_desc' ||
					item.key == 'currency_code'
				) {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
					};
				} else if (item.key == 'grand_total') {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							let showPercentage = handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							return (
								<div className='text-center'>
									{
										<span className={myClassName}>
											{props.original
												? modifyColumnData(
													props.original[item.key],
													props.original['operations']
												)
												: '-'}
										</span>
									}
								</div>
							);
						},
						headerStyle: {
							overflow: 'visible',
							// fontSize: '13px',
							// fontWeight: 'bold',
						},
					};
				} else if (
					item.key == 'tran_amount' ||
					item.key == 'base_amount' ||
					item.key == 'base_amount_aop' ||
					item.key == 'tran_amount_aop'
				) {
					var isManualKey = true;
					// console.log(item.sub_headers, item, "test123123")
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							item['operation'] = 7;
							if (props.original.summary_acct == 'Total') {
								isManualKey = false;
							}

							return (
								<div
									className={`${isManualKey == true ? 'text-center pointer' : 'text-center'
										}`}
									onClick={(event) => {

										// handleDGSAutomationClick(props.original, item);
									}}
								>
									{
										<span
											className={`${isManualKey == true ? 'enableDrillDown' : ''
												}`}
										>
											{props.original
												? modifyColumnData(props.original[item.key])
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							cursor: isManualKey == true ? 'pointer' : '',
							// cursor: props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (!item.sub_headers) {
					// var isManualKey = false;

					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							var isManualKey = false;

							if (!props.original.disable_drill_down) {
								isManualKey = true;
							}
							let val =
								props.original[item.key] == undefined
									? 0
									: props.original[item.key];

							return (
								<div
									className={`${isManualKey == true ? 'text-center pointer' : 'text-center'
										}`}
									onClick={(event) => {
										// handleInvoiceClick(props.original, item, item);
									}}
								>
									{
										<span
											className={`${isManualKey == true ? 'enableDrillDown' : ''
												}`}
										>
											{props.original
												? modifyNewColumnData(val, props.original.operations)
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							// cursor: isManualKey == true ? 'pointer' : '',
							// cursor: props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				}


				return {
					Header: (
						<Tooltip title={handleHeaderTooltip()}>
							<span
								onClick={() => {
									if (
										props.tabSelectorValue != 3 &&
										props.radioArrSelected == 'Default'
									) {
										if (props.radioSelectorValue == 3) {
											props.handleRegionHeaderClick(item);
										} else if (props.radioSelectorValue == 5) {
											props.handleHemisphereHeaderClick(item);
										}
									}
								}}
							>
								{item.label}
							</span>
						</Tooltip>
					),
					id: item.key,
					columns: handleSubColumnChangeHeader(item.sub_headers, item),
					style: {
						borderRight: '1px solid #423970',
						cursor: '',
					},
					headerStyle: {
						overflow: 'visible',
						// fontSize: '13px',
						// fontWeight: 'bold',
						borderRight: '1px solid #423970',
					},
				};
			});
		}
		return [];
	};

	const pivotHeaderData = changeKeysOfHeader(row_header)
	const pivotRowData = row_data
	let tableLen = pivotRowData && pivotRowData.length || 5;


	return <div className="">
		<ReactTable
			columns={pivotHeaderData}
			data={pivotRowData}
			defaultPageSize={10}
			pageSize={10}
			loading={loading}
			className="xpna-react-table -striped -highlight total-sales-report"
			PaginationComponent={NewPagination}
			sortable={false}
		/>
	</div>
}