import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import NewPagination from "../../common/components/CustomPagination";

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ModalUser from "./UserMasterPopUp"
import Checkbox from '@material-ui/core/Checkbox';
// import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAccessByMenuKey } from "../../../utils/userManagement";
import 'react-table-hoc-fixed-columns/lib/styles.css'
import InputLabel from '@material-ui/core/InputLabel';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import { downloadExcel, mapExcelDataUsers, mapExcelHeader } from '../../../utils/exportExcel'
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


const ReactTableFixedColumns = withFixedColumns(ReactTable);
export class UserMaster extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      role: [],
      group: [],
      budgetGroup: [],
      new_regions:[],
      new_groups: [],
      sales_and_technologies_bus: [],
      sales_and_technologies_groups: [],
      roleVal: "",
      groupVal: "",
      rowData: this.props.userState.data,
      rowHeaderData: [],
      showModal: false,
      editData: {},
      editMode: false,
      open: false,
      searchBox: "",
    };
  }

  putSNOData = (data) => {
    data = data.sort(
      function (a, b) {
        return a.fullname > b.fullname ? 1 : -1;
      });

    data = data.map((x, idx) => {
      x.s_no = idx + 1
      return x
    })
    return data
  }

  putSNOHeader = (data) => {
    data.unshift({ key: 's_no', label: 'S.No' })
    return data
  }

  modalCallBack = () => {
    this.setState({ showModal: false });
  };

  handleChangeRole = (event) => {
    this.setState({ roleVal: event.target.value }, this.handleFilterChange);
  };

  handleChangeGroup = (event) => {
    this.setState({ groupVal: event.target.value }, this.handleFilterChange);
  };

  handleSearchBoxChange = (event) => {
    this.setState({ searchBox: event.target.value }, this.handleFilterChange);
  };

  componentDidMount() {
    this.refreshList()
    this.props.getRoleGroupData({});
  }

  handleEditClick = (data) => {
    this.setState({
      editData: data,
      editMode: true,
      showModal: true
    });
  }

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleOpenDialog = (data) => {
    this.setState({
      editData: data,
      open: true
    });
  };

  handleAddClick = (data) => {
    this.setState({
      editMode: false,
      showModal: true
    });
  }

  resetFilter = () => {
    this.setState({
      roleVal: "",
      groupVal: "",
    })
  }

  handleFilterChange = () => {
    const { roleVal, groupVal, searchBox } = this.state
    let currentData = this.props.userState.data;
    let searchLower = searchBox.toLowerCase()
    if (roleVal != "")
      currentData = currentData.filter(val => val.roledata.ID == roleVal)
    if (groupVal != "")
      currentData = currentData.filter(val => val.groupdata.value == groupVal)
    if (searchBox != "")
      currentData = currentData.filter(val => val.email.toLowerCase().indexOf(searchLower) > -1 || val.name.toLowerCase().indexOf(searchLower) > -1)

    currentData = this.putSNOData(currentData);
    this.setState({ rowData: currentData })
  }

  refreshList = () => {
    this.setState({ roleVal: "", groupVal: "" })
    let data = {}
    this.props.getUserData(data)
  }

  handleUserSave = (data) => {
    if (this.state.editMode)
      this.props.editUserData(data);
    else
      this.props.saveUserData(data)
  }

  handleUserDelete = () => {
    let data = this.state.editData
    data._id = data.bsonid;
    data.login_id = data.email;
    this.props.deleteUserData(data);
    this.handleCloseDialog();
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;
    const { rowData } = this.state;
    if (prevProps.userState.data.length != userState.data.length) {
      this.setState({ rowData: this.putSNOData(userState.data) })
      let newHeader = this.changeKeysOfHeader(this.putSNOHeader(userState.header))
      this.setState({ rowHeaderData: newHeader })
    }
    if (prevProps.userState.roleData.length != userState.roleData.length) {
      this.setState(
        { role: userState.roleData, 
          group: userState.groupData, 
          budgetGroup: userState.budgetGroupData ,
          new_groups: userState.new_groups,
          new_regions: userState.new_regions,
          sales_and_technologies_bus: userState.sales_and_technologies_bus,
          sales_and_technologies_groups: userState.sales_and_technologies_groups,
        })
    }
    if (prevProps.userState.saveLoading && !userState.saveLoading) {
      this.refreshList();
      this.modalCallBack();
    }
  }

  handleDynamicWidth = (item) => {
    let widthSize;
    switch (item.key) {
      case 'loginid':
        widthSize = 200;
        break;
      case 'email':
        widthSize = 200;
        break;
      case 's_no':
        widthSize = 80;
        break;
      default: break;
    }
    return widthSize
  }

  changeKeysOfHeader = (data) => {
    const { authReducer } = this.props
    const menus = authReducer.menuData
    if (data && data.length) {
      data.push({ label: "Action", key: "action" })
      return data.map((item) => {
        let obj = {
          Header: item.label,
          id: item.key,
          width: this.handleDynamicWidth(item),
          accessor: item.key,
          headerStyle: {
            whiteSpace: "break-spaces",
            width: '50px !important',
            overflow: "visible",
            // fontSize: '13px',
            // height: '35px',
            // fontWeight: 'bold',
          },
          style: {
            whiteSpace: "break-spaces",
            width: '50px !important',
            overflow: "hidden",
            height: '33px',
            textAlign: 'center'
          },
        }
        if (item.key == "role") {
          obj.accessor = (_data) => {
            let coldata = _data.roledata.Name
            return coldata
          }
        }
        if (item.key == "group") {
          obj.accessor = (_data) => {
            let coldata = ""
            if (_data.groupdata != undefined)
              coldata = _data.groupdata.text
            else {
              coldata = ""
            }
            return coldata
          }
        }
        if (item.key == "enable") {
          obj.Cell = (props) => (
            <Checkbox
              disabled
              checked={props.original.enable}
              color="primary"
              style={{padding:"0px"}}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )
        }
        if (item.key == "is_active") {
          return {
            Header: item.label,
            id: item.key,
            width: this.handleDynamicWidth(item),
            accessor: item.key,
            headerStyle: {
              whiteSpace: "break-spaces",
              width: '50px !important',
              overflow: "visible",
              // fontSize: '13px',
              // height: '35px',
              // fontWeight: 'bold',
            },
            style: {
              whiteSpace: "break-spaces",
              width: '50px !important',
              overflow: "hidden",
              height: '33px',
              textAlign: 'center'
            },
            Cell: (props) => {
              console.log(props)
            return (
                  <span className={props.original.is_active?"success-color":"error-color"}>{props.original.is_active?"Active":"Inactive"}</span>
          )
            }
          }
        }
        if (item.key == "action") {
          obj.Cell = (props) => (
            <React.Fragment>
              { getAccessByMenuKey(menus, "user_master", "edit") &&
                <Tooltip title={"Edit user"}>
                  <EditIcon className='primary-dark' fontSize='small' onClick={() => this.handleEditClick(props.original)} />
                </Tooltip>
              }
              {/* { getAccessByMenuKey(menus, "user_master", "delete") &&
                <Tooltip title={"Delete user"}>
                  <DeleteIcon className='primary-dark' fontSize='small' onClick={() => this.handleOpenDialog(props.original)} />
                </Tooltip>
              } */}
            </React.Fragment>
          )
        }
        return obj
      })
    }
  }

  mapExcelDownLoad = (data) => {
    data = data.map((x, idx) => {
      x.role = x.roledata.Name
      x.enable = x.enable ? "Yes" : "No"
      return x
    })

    return data
  }

  handleExcelDownload = () => {
   
    let datas = this.mapExcelDownLoad(this.state.rowData)
    let header = this.state.rowHeaderData
    header = header.filter(x => { return x.id != 'action' })
    header.splice(9, 0,{Header : "Budget Group", id : "budgetgroup"})
    header.splice(10, 0,{Header : "New - Hemisphere", id : "Newhemisphere"})
    header.splice(11, 0,{Header : "New - Region", id : "Newregion"})
    header.splice(12, 0,{Header : "New - Group", id : "Newgroup"})
    header.splice(13, 0,{Header : "Sales and Technology - BU", id : "salestechbu"})
    header.splice(14, 0,{Header : "Sales and Technology - Group", id : "salestechgroup"})
    console.log(datas, header, "checkData")
    let data = mapExcelDataUsers(datas, header, "id")
    header = mapExcelHeader(header, "Header")
    downloadExcel(data, "User Master", header, false)
  }

  render() {
    const { userState } = this.props;
    const { open, editData, editMode, showModal, 
      role, group, budgetGroup, roleVal, groupVal, 
      rowData, rowHeaderData, searchBox,
      new_regions,new_groups,
      sales_and_technologies_bus, sales_and_technologies_groups
    } = this.state;
    const { authReducer } = this.props
    const menus = authReducer.menuData
    const body = (
      <React.Fragment>
        <div className={'flex jc-space-between'}>
          <Grid container xs={9}>
            <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                id="user-category-select"
                value={roleVal}
                onChange={this.handleChangeRole}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {role.map((val) => (
                  val ? <MenuItem key={val.value} value={val.ID} >
                    {val.Name}
                  </MenuItem> : ''
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
              <InputLabel>Group</InputLabel>
              <Select
                label="Group"
                id="user-stage-select"
                value={groupVal}
                onChange={this.handleChangeGroup}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {group.map((val) => (
                  val ? <MenuItem key={val.value} value={val.value} >
                    {val.text}
                  </MenuItem> : ''
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className='selectOutlineSmall maxw-200' size='small'>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Search Email/First Name"
                value={searchBox}
                onChange={this.handleSearchBoxChange}
              />
            </FormControl>
          </Grid>
          <div style={{ float: "right" }} className='mb-10 pointer'>
            <Tooltip title={'Download to Excel'}>
              <img
                src={DownloadExcel}
                className='pointer'
                height='30'
                onClick={(event) => {
                  event.preventDefault();
                  this.handleExcelDownload();
                }} />
            </Tooltip>
          </div>
        </div>
        <Grid container justify="flex-end" className='mt-10 mb-20'>
          {getAccessByMenuKey(menus, "user_master", "add") &&

            <Button variant="contained" onClick={() => {
              this.handleAddClick()
            }} color="primary">
              Add
              </Button>
          }
        </Grid>
        {userState.loading ? <div className='text-center'><CircularProgress/></div> :
          <ReactTable
            data={rowData}
            columns={rowHeaderData}
            defaultPageSize={10}
            className="iconStyle xpna-react-table -striped -highlight"
            PaginationComponent={NewPagination}
            pageSizeOptions={[10, 20, 30]}
            resizable={true}
          />
        }
        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We will not able recover deleted data
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">Cancel</Button>
            <Button onClick={this.handleUserDelete} color="secondary" autoFocus>Delete</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {body}
        {
          <ModalUser
            showModal={showModal}
            modalCallBack={this.modalCallBack}
            roles={role}
            groups={group}
            budgetGroup={budgetGroup}
            saveLoading={userState.saveLoading}
            saveCallback={this.handleUserSave}
            editMode={editMode}
            editData={editData}
            new_regions={new_regions}
            new_groups={new_groups}
            sales_and_technologies_bus={sales_and_technologies_bus}
            sales_and_technologies_groups={sales_and_technologies_groups}
            deleteCallback={this.handleUserDelete}
            userData = {this.props.authReducer.userData}  
          />
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      userState: state.userManagementState.userState,
      authReducer: state.authReducer
    };
  },
  (dispatch) => {
    return {
      getUserData(data) {
        dispatch({
          type: "FETCH_USERMGMT_DATA",
          payload: data,
        });
      },
      getRoleGroupData(data) {
        dispatch({
          type: "FETCH_ROLE_AND_GROUP",
          payload: data,
        });
      },
      saveUserData(data) {
        dispatch({
          type: "ADD_USER_DATA",
          payload: data,
        });
      },
      editUserData(data) {
        dispatch({
          type: "EDIT_USER_DATA",
          payload: data,
        });
      },
      deleteUserData(data) {
        dispatch({
          type: "DELETE_USER_DATA",
          payload: data,
        });
      }
    };
  }
)(UserMaster);