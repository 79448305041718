import { put, takeLatest, all, call } from 'redux-saga/effects';
// import { toastr } from "react-redux-toastr";
// import history from '../../../../../history'
import { toastr } from "react-redux-toastr";
import { changeCollectionInvoiceForecastData, changeValuesOfData, setAllRowCommentHistoryData, appendChannelId, changeCollectionForecastData} from './transformer'
import { getAllCommentHistoryData } from '../../../utils/commentDataUtil'
import {
    getFCFFilterService,
    getFCFImpactAnalysisService,
    getEmailTemplateAutomatedReportingService,
    saveEmailTemplateAutomatedReportingService,
    updateActionOwnerService,
    sendEmailAutomatedReportingService,
    fetchAllCommentHistoryService,
    fetchMetaDataAnalyticStudioService,
    fetchDataAnalyticStudioService,
    fetchCreatePlanFormDataService,
    fetchSavePlanFormDataService,
    fetchCreatePlanIDDataService,
    fetchCadenceBoardDataService,
    saveCadenceBoardDataService,
    getAnalyticStudioService,
    saveAnalyticStudioService,
    deleteAnalyticStudioService,
    shareChartService,
    sendEmailForChartService,
    fetchImprovementPlanHistoryDataService,
    getFCFImpactDrilldownService,
    getFCFImprovementService
} from './service'
// import { store } from '../../../../../index'
import { store } from '../../../index'

function* fetchFCFImpactAnalysis(data) {
    if (!data.payload.export){
        yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS_LOADER", loading: true });
    }
    yield put({ type: "SAVE_PAYLOAD_FCF_IMPACT_ANALYSIS", data: data.payload });

    const response = yield call(getFCFImpactAnalysisService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
        response.data.row_data = changeValuesOfData(response.data.row_data)
        const getCommentPayload =  getAllCommentHistoryData(0, response.data.row_data)

        yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS_SUCCESS", data: response.data });
            yield put({
                type: "FETCH_COMMENT_CHANNEL_AUTOMATED_REPORTING_DATA_HISTORY",
                payload: getCommentPayload,
              })
    }

}

function* fetchFCFImprovement(data) {
    if (!data.payload.export){
        yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS_LOADER", loading: true });
    }

    const response = yield call(getFCFImprovementService, data.payload);

    if (!data.payload.export && response && response.status == 200) {
       console.log('getFCFImprovementService', response)

        yield put({ type: "FETCH_FCF_IMPROVEMENT_SUCCESS", data: response.data });
    
    }

}


function* getTemplateEmailAutomatedReportingAction(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateAutomatedReportingService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}

function* saveTemplateEmailAutomatedReporting(data){
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailTemplateAutomatedReportingService, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Template Saved')
        yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* updateActionOwner(data) {
    try {
        const response = yield call(updateActionOwnerService, data.payload);
        
        if (response && response.status == 200) {
            const state = store.getState();
            const ecfmState = state.ecfmState
            yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS", payload: ecfmState.fcfPayloadData });
        }
        
    } catch (error) {
        console.log(error);
    }
}

function* sendMailAutomatedReporting(data){
    yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const state = store.getState();
    const ecfmState = state.ecfmState
    let param = {
        data: ecfmState.tableRowData.filter(x => x.selected == true),
        dimension: data.payload.dimension,
        period: data.payload.period
    }

    const response = yield call(sendEmailAutomatedReportingService, param);
    if (response && response.status == 200) {
        toastr.success('Email sent successfully')
        yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    }else{
        toastr.error(response.error)
        // yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    }
}

function* fetchCommentAllChannelHistory(data) {

    try {

        const state = store.getState();
        const { ecfmState } = state;
        const {tableRowData} = ecfmState;

        const response = yield call(fetchAllCommentHistoryService, data.payload);

        // console.log(response, "fetchCommentAllChannelHistory")

        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, response.data.chat)
            yield put({ type: "FETCH_COMMENT_HISTORY_AUTOMATED_REPORTING_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}

function* fetchCommentAllChannelHistoryImprovementPlan(data) {
    try {
        const state = store.getState();
        const { ecfmState } = state;
        const {cadenceBoardData} = ecfmState;

        const response = yield call(fetchAllCommentHistoryService, data.payload);

        // console.log(response, "fetchCommentAllChannelHistory")

        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(cadenceBoardData.row_data, response.data.chat)
            yield put({ type: "FETCH_COMMENT_HISTORY_IMPROVEMENT_PLAN_SUCCESS", loading: false, data: updatedRowData });
        }
    } catch (error) {
        console.log(error);
    }

}

function* fetchMetaDataAnalyticStudio(data) {
    yield put({ type: "FETCH_META_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchMetaDataAnalyticStudioService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_META_DATA_ANALYTIC_STUDIO_SUCCESS", data: response.data });
    }

}

function* sendEmailForChartAnalyticStudio(data) {
 
    const response = yield call(sendEmailForChartService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "SHARE_CHART_LOADER", loading: false });
        toastr.success('Data Shared Successfully')
    }else{
         toastr.error(response.error)
    }

}

function* shareChartAnalyticStudio(data) {
    yield put({ type: "SHARE_CHART_LOADER", loading: true });
    let payload = data.payload
    const response = yield call(shareChartService, data.payload);
    if (response && response.status == 200) {
        let payloadSendEmail = {
            email_to : payload.emailTo,
            email_cc : payload.emailCc,
            attachment : "data:image/png;base64,"+response.data
        }

        yield put({ type: "SEND_EMAIL_FOR_CHART", payload : payloadSendEmail});        
    }

}

function* fetchDataAnalyticStudio(data) {
    yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchDataAnalyticStudioService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_SUCCESS", data: response.data });
        yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: false });
    }
}

function* getAnalyticStudio(data) {
    yield put({ type: "GET_ANALYTIC_STUDIO_LOADER", loading: true });
    // const response = yield call(getAnalyticStudioService, data.payload);
    // if (response && response.status == 200) {
    //     yield put({ type: "GET_ANALYTIC_STUDIO_SUCCESS", data: response.data });
    // }
}

function* fetchCreatePlanFormDropdownData(data) {
    yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchCreatePlanFormDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_CREATE_PLAN_DROPDOWN_SUCCESS", data: response.data });
    }

}



function* fetchCreatePlanIDDataData(data) {
    yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchCreatePlanIDDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_CREATE_PLAN_ID_SUCCESS", data: response.data });
    }

}


function* postSavePlanFormData(data) {
    yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchSavePlanFormDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SAVE_PLAN_SUCCESS", data: response.data });
        yield put({ type: "FETCH_CADENCE_BOARD_DATA", data: response.data });
        toastr.success('Data Saved Successfully')
    }

}


function* fetchCadenceBoardData(data) {
    yield put({ type: "FETCH_DATA_ANALYTIC_STUDIO_LOADER", loading: true });
    const response = yield call(fetchCadenceBoardDataService, data.payload);
    if (response && response.status == 200) {
        response.data.row_data = changeValuesOfData(response.data.row_data)
        const getCommentPayload =  getAllCommentHistoryData(0, response.data.row_data)

        yield put({ type: "FETCH_CADENCE_BOARD_DATA_SUCCESS", data: response.data });
        yield put({ type: "SAVE_CADENCE_BOARD_DATA_LOADER", loading: false });

        yield put({
            type: "FETCH_COMMENT_CHANNEL_IMPROVEMENT_PLAN_DATA_HISTORY",
            payload: getCommentPayload,
            })
    }

}

function* fetchImprovementPlanHistoryData(data) {
    yield put({ type: "FETCH_IMPROVEMENT_PLAN_HISTORY_LOADER", loading: true });
    const response = yield call(fetchImprovementPlanHistoryDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_IMPROVEMENT_PLAN_HISTORY_SUCCESS", data: response.data });
    }

}

function* saveCadenceBoarData(data){
    yield put({ type: "SAVE_CADENCE_BOARD_DATA_LOADER", loading: true });

    // const payload = {
    //     _id : data.payload._id,
    //     name : data.payload.name,
    //     shared_to : data.payload.shared_to,
    //     tabs : data.payload.tabs
    // }
    const payload = data.payload;

    const response = yield call(saveCadenceBoardDataService, payload);
    if (response && response.status == 200) {
        toastr.success('Data Saved Successfully')
        yield put({ type: "FETCH_CADENCE_BOARD_DATA", payload : {}});  
    }else{
        toastr.error(response.error)
    }
}

// function* saveAnalyticStudio(data){
//     yield put({ type: "SAVE_ANALYTIC_STUDIO_LOADER", loading: true });
//     const response = yield call(saveAnalyticStudioService, data.payload);
//     if (response && response.status == 200) {
//         toastr.success('Data Saved')
//         yield put({ type: "SAVE_ANALYTIC_STUDIO_LOADER", loading: false });
//     }else{
//          toastr.error(response.error)
//     }
// }

function* saveAnalyticStudio(data){
    yield put({ type: "SAVE_ANALYTIC_STUDIO_LOADER", loading: true });

    const payload = {
        _id : data.payload._id,
        name : data.payload.name,
        shared_to : data.payload.shared_to,
        tabs : data.payload.tabs
    }

    const response = yield call(saveAnalyticStudioService, payload);
    if (response && response.status == 200) {
        toastr.success('Data Saved Successfully')
        yield put({ type: "SAVE_ANALYTIC_STUDIO_LOADER", loading: false });
        yield put({ type: "GET_ANALYTIC_STUDIO", payload : {}});        
    }else{
        toastr.error(response.error)
        // yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    }
}

// function* deleteAnalyticStudio(data) {
//     yield put({ type: "DELETE_ANALYTIC_STUDIO_LOADER", loading: true });
//     const response = yield call(getAnalyticStudioService, data.payload);
//     if (response && response.status == 200) {
//         yield put({ type: "DELETE_ANALYTIC_STUDIO_SUCCESS", data: response.data });
//     }
// }

function* deleteAnalyticStudio(data){
    yield put({ type: "DELETE_ANALYTIC_STUDIO_LOADER", loading: true });

    const response = yield call(deleteAnalyticStudioService, data.payload);
    if (response && response.status == 200) {
        toastr.success('Data Deleted Successfully');
        yield put({ type: "DELETE_ANALYTIC_STUDIO_SUCCESS", loading: false });
        yield put({ type: "GET_ANALYTIC_STUDIO", payload : {}});        
    }else{
        toastr.error(response.error)
    }
}

function* fetchImpactDrilldownData(data) {
    yield put({ type: "FETCH_FCF_IMPACT_DRILLDOWN_DATA_LOADER", loading: true });
    const response = yield call(getFCFImpactDrilldownService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_FCF_IMPACT_DRILLDOWN_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_FCF_IMPACT_DRILLDOWN_DATA_LOADER", loading: false });

}

function* fetchFCFFilterData(data) {
    const response = yield call(getFCFFilterService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_FCF_FILTER_SUCCESS", data: response.data });
        // yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS", payload : { dimension : 'lob', period : 'fy', year_label : response.data.default_selection.period.year_label} });
        if(data.payload)
        yield put({ type: "FETCH_FCF_IMPACT_ANALYSIS", payload : { dimension : 'lob', period : 'fy',  yearmonth : "31 Jan 2023", isAutomated: data?.payload?.isAutomated} });
    }

}

export default function* actionWatcher() {
     yield takeLatest('FETCH_FCF_FILTER', fetchFCFFilterData)
     yield takeLatest('FETCH_FCF_IMPACT_ANALYSIS', fetchFCFImpactAnalysis)
     yield takeLatest('FETCH_FCF_IMPROVEMENT', fetchFCFImprovement)
     yield takeLatest('FETCH_EMAIL_TEMPLATE_AUTOMATED_REPORTING', getTemplateEmailAutomatedReportingAction)
     yield takeLatest('SAVE_EMAIL_TEMPLATE_AUTOMATED_REPORTING',saveTemplateEmailAutomatedReporting)
     yield takeLatest('UPDATE_ACTION_OWNER_AUTOMATED_REPORTING',updateActionOwner)
     yield takeLatest('SEND_MAIL_AUTOMATED_REPORTING', sendMailAutomatedReporting)
     yield takeLatest('FETCH_COMMENT_CHANNEL_AUTOMATED_REPORTING_DATA_HISTORY', fetchCommentAllChannelHistory)
     yield takeLatest('FETCH_META_DATA_ANALYTIC_STUDIO', fetchMetaDataAnalyticStudio)
     yield takeLatest('FETCH_DATA_ANALYTIC_STUDIO', fetchDataAnalyticStudio)
     yield takeLatest('FETCH_CREATE_PLAN_FORM_DROPDOWN_DATA', fetchCreatePlanFormDropdownData)
     yield takeLatest('FETCH_CREATE_PLAN_ID_DATA', fetchCreatePlanIDDataData)
     yield takeLatest('SAVE_PLAN_FORM_DROPDOWN_DATA', postSavePlanFormData)
     yield takeLatest('FETCH_CADENCE_BOARD_DATA', fetchCadenceBoardData)
     yield takeLatest('SAVE_CADENCE_BOARD_DATA', saveCadenceBoarData)
     yield takeLatest('GET_ANALYTIC_STUDIO', getAnalyticStudio)
     yield takeLatest('SAVE_ANALYTIC_STUDIO', saveAnalyticStudio)
     yield takeLatest('DELETE_ANALYTIC_STUDIO', deleteAnalyticStudio)
     yield takeLatest('SHARE_CHART_ANALYTIC_STUDIO', shareChartAnalyticStudio)
     yield takeLatest('SEND_EMAIL_FOR_CHART', sendEmailForChartAnalyticStudio)
     yield takeLatest('FETCH_IMPROVEMENT_PLAN_HISTORY', fetchImprovementPlanHistoryData)
     yield takeLatest('FETCH_COMMENT_CHANNEL_IMPROVEMENT_PLAN_DATA_HISTORY', fetchCommentAllChannelHistoryImprovementPlan)
    yield takeLatest('FETCH_IMPACT_DRILLDOWN_DATA', fetchImpactDrilldownData)


     


     
     


    
}