import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Divider from '@material-ui/core/Divider';
import { numberWithCommas, handleTableTransform } from '../../../utils/numberUtils'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { formatDate } from '../../../utils/dateFormat'
import CircularProgress from '@material-ui/core/CircularProgress';
// import '../ScatterChart.scss';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DownloadExcel from "../../../../src/images/download_excel.svg";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getAccessByMenuKey } from "../../../utils/userManagement";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ActionOwnerPopper = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}


export class MonthlyStatusReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.headerColorArr = ['#f7f7f7', '#685aa9', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5']
        const datelen = props.dashboardState && props.dashboardState.reportDateArr && props.dashboardState.reportDateArr.length;
        this.state = {
            openDialogTest: false,
            openDialog: false,
            loading: true,
            seriesData: [],
            commentValue: '',
            commentModal: false,
            filterView: "NAM",
            dataSourceFiltered: [],
            viewEmail: false,
            BUfilterView: "New BU 1",
            emailTemplateContent: "",
            editorMsgBody: null,
            showProjectModal: false,
            projectModalData: {},
            month: props.dashboardState && props.dashboardState.reportDateArr && props.dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
        }
    }


    getMonthlyStatusData = () => {
        let { filterView, BUfilterView } = this.state
        let payload = {
            "region": filterView,
            "new_bu": BUfilterView
        }
        this.props.getMonthlyStatusReport(payload)
    }

    componentDidMount() {
        this.getMonthlyStatusData()
    }



    handleMonthChange = (event) => {
        const { filterView } = this.state
        if (event.target && this.state.month != event.target.value) {
            this.setState({ month: event.target.value }, () => {
                let payload = {
                    "report_date": event.target.value,
                    "layout_type": filterView,
                    receivableTabSelectorValue: this.props.receivableTabSelectorValue

                }
                this.props.getAnalysisAction(payload)
            });
        }
    }

    handleFilterValue = (event) => {
        // console.log(event.target.value)
        if (event.target && this.state.filterView != event.target.value) {
            this.setState({ filterView: event.target.value }, () => {
                this.getMonthlyStatusData()
            });
        }

    }

    handleBUFilterValue = (event) => {
        // console.log(event.target.value)
        if (event.target && this.state.filterView != event.target.value) {
            this.setState({ BUfilterView: event.target.value }, () => {
                this.getMonthlyStatusData()
            });
        }

    }

    handleProjectIdClick = (rowData, item) => {
        // uncomment this to show
        // this.setState({
        //     showProjectModal:true,
        //     projectModalData: rowData
        // })
    }


    handleValueTransform = (data, keys) => {
        let modifiedData;

        if(data && !isNaN(data)){
            modifiedData = Math.round(data)
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            
        }else {
            modifiedData = data
        }

        if (keys && keys.includes('delta')) {
            modifiedData = modifiedData == '0' ? 'N/A' : modifiedData
        } else {
            modifiedData = modifiedData == '0' ? '-' : modifiedData
        }
        

        return modifiedData
    }

    handleActionOwnerChangeFn = (dataJson, data, item) => {
        const {BUfilterView, filterView} = this.state
        this.setState({loading: true});
        let action_owners = []
        let action_type = 'CC'
        if (item.key == 'to') {
            action_type = 'TO'
        }


        if(dataJson != null){
            dataJson.forEach((value, key)=>{
                action_owners.push({
                    action_party_name: value.full_name,
                    action_party_email: value.email
                })

            })
        }

        this.props.updateActionOwnerFn({
          region: filterView,
          new_bu: BUfilterView,
          action_owners : action_owners,
          action_type
        })
        // console.log(payload)
    }

    changeKeysOfHeader = (data, isNested) => {
        let buUser = this.props.externalState.allUser

            if (data && data.length) {
                return data.map((item, i) => {
                    if (item.key == 'module') {
                        return {
                            Header: "",
                            fixed: 'left',
                            id: item.key,
                            Cell: (props) => {
    
                                return (
                                    <div className='text-center'>
                                        <span>{props.original[item.key]}</span>
                                    </div>
                                )
                            },
                            headerStyle: {
                                color: '#8306ce',
                            },
                        }
                    
                    } 

                    if (item.key == 'column2') {
                        return {
                            Header: <div className='text-center'> {item.label}</div>,
                            id: item.key,
                            metaData: item.metadata,
                            headerStyle: {
                                whiteSpace: 'break-spaces',
                                overflow: 'visible',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#922ad5',
                                width: "200px !important",
                                backgroundColor: '#e0d9fa'
                            },
                            Cell: (props) => {

                                let revenueValue = props.original[item.key]
                                let colorValue = '#007000'
                                if (revenueValue <= 99 && revenueValue >= 90) {
                                    colorValue = '#E3AA00'
                                }else if(revenueValue < 90){
                                    colorValue = '#AB1C25'
                                }
                                
                                return (
                                    <div className='text-center' style={{color:colorValue}}>
                                        <span>{props.original ? this.handleValueTransform(props.original[item.key],item.key) : '-'}</span>
                                    </div>
                                )
                            },
                        }
                    
                    } 

                    if (item.key == 'collections') {
                        return {
                            Header: <div className='text-center'> {item.label}</div>,
                            id: item.key,
                            fixed: "left",
                            metaData: item.metadata,
                            headerStyle: {
                                whiteSpace: 'break-spaces',
                                overflow: 'visible',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#922ad5',
                                width: "200px !important",
                                backgroundColor: '#e0d9fa'
                            },
                            Cell: (props) => {
                                return (
                                    <div className='text-center'>
                                        {
                                            <span>{props.original ? this.handleValueTransform(props.original[item.key],item.key) : '-'}</span>
                                        }
                                    </div>
                                )
                            },
                        }
                    
                    } 
    
                    if (item.key == 'project_id_so') {
                        return {
                            Header: <div className='text-center'> {item.label}</div>,
                            id: item.key,
                            metaData: item.metadata,
                            Cell: (props) => {
                                return (
                                    <div className='text-center' onClick={() => {
                                        this.handleProjectIdClick(props.original, item)
                                    }}>
                                        {
                                            <span>{props.original ? this.handleValueTransform(props.original[item.key],item.key) : '-'}</span>
                                        }
                                    </div>
                                )
                            },
                        }
                    
                    } else if(item.key === "to" || item.key == 'cc'){
                        return {
                            Header: <div className='text-center'> {item.label}</div>,
                            id: item.key,
                            fixed: "left",
                            width: 200,
                            sortable: false,
                            accessor: item.key,
                            headerStyle: {
                                whiteSpace: 'break-spaces',
                                overflow: 'visible',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#922ad5',
                                width: "200px !important",
                                backgroundColor: '#e0d9fa'
                            },
                            Cell: (props) => {
    
                                // let defaultValueActionOwner = buUser.find(x=>{ 
                                //     console.log(props.original.action_party_finance)
    
                                //      
                                // })
                                let defaultValueActionOwner = []
                                // let label = ""
                                if(props.original[item.key] && props.original[item.key].length > 0 && props.original[item.key] !== ""){ 
                                    props.original[item.key].forEach(value => {
                                        buUser.forEach(x => {
                                            // console.log(x.email , value)
                                            if(x.email == value){
                                                defaultValueActionOwner.push(x)
                                            } 
                                        })
                                    })
                                }
    
                                
                                return (
                                    props.original.showDropdown ?
                                    <Autocomplete
                                        // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                                        multiple
                                        className='actionOwnerTabelSelect'
                                        options={buUser}
                                        size='small'
                                        disabled={this.state.filterView == 'slb' || this.state.filterView == 'pemex'}
                                        PopperComponent={ActionOwnerPopper}
                                        getOptionLabel={(option) => option.full_name}
                                        value={defaultValueActionOwner}
                                        onChange={(event, data) => {this.handleActionOwnerChangeFn(data, props.original, item)}}
                                        renderInput={(params) => (
                                            // <Tooltip title={label}>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            // </Tooltip>
                                        )}
                                        renderOption={(option) => <Typography
                                            style={{ fontSize: '12px', width: '250px' }}
                                            className='flex jc-space-between minw-200 ai-center'
                                            noWrap>
                                            <span className='pr-10'>{option.full_name}</span>
                                            <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                        </Typography>}
    
                                    /> : null
                                )
                            }
                        }
                    }
    
    
                        return {
                            Header: <div className='text-center'> {item.label}</div>,
                            id: item.key,
                            metaData: item.metadata,
                            headerStyle: {
                                whiteSpace: 'break-spaces',
                                overflow: 'visible',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#922ad5',
                                width: "200px !important",
                                backgroundColor: '#e0d9fa'
                            },
                            Cell: (props) => {
                                return (
                                    <div className='text-center'>
                                        {
                                            <span>{props.original ? this.handleValueTransform(props.original[item.key],item.key) : '-'}</span>
                                        }
                                    </div>
                                )
                            },
                        }
                    
                })
            }
        
        
        return []
    }


    handleOpenDialog = () => {
        this.setState({
            openDialog: true
        })

    }

    handleSendMail = () => {
        let param = this.props.emailParameter;
        this.props.sendEmail(param)
        this.handleCloseDialog()
    }

    handleCloseDialog = () => {
        this.setState({
            openDialog: false
        })
    }

    handleOpenDialogTest = () => {
        this.setState({
            openDialogTest: true
        })

    }

    handleSendMailTest = () => {
        const { BUfilterView, filterView } = this.state
        this.props.sendEmail(
            {
                "region": filterView,
                "new_bu": BUfilterView,
                "test_email":true
            }
        )
        this.handleCloseDialogTest()
    }

    handleCloseDialogTest = () => {
        this.setState({
            openDialogTest: false
        })
    }


   

    openViewEmail = () => {
        this.setState({ viewEmail: true })
        this.props.getEmailTemplate({})
    }

    closeViewEmail = () => {
        this.setState({ viewEmail: false })
    }

    saveTemplateEmail = () => {

    }

    handleSubmitTemplate = (event) => {
        const { editorMsgBody } = this.state

        event.preventDefault();

        let payload = {
            // cc_emails: [event.currentTarget.cc_emails.value],
            email_title: event.currentTarget.email_title.value,
            // email_salutation: event.currentTarget.email_salutation.value,
            email_msg: editorMsgBody.getData(),
            // email_signature: event.currentTarget.email_signature.value
        }

        this.props.saveEmailTemplate(payload)
        this.setState({ viewEmail: false })

    }

    onChangeTemplateEmail = (data) => {
        this.setState({ emailTemplateContent: data })
    }
    handleDownloadExcel = () => {
        let { filterView, BUfilterView } = this.state
        let payload = {
            "region": filterView,
            "new_bu": BUfilterView,
            "export" : "true"
        }
        this.props.getMonthlyStatusReport(payload)
    }

    handleClose = () => {
        this.setState({
            showProjectModal: false
        })
    }


    render() {
        const { month, openDialog, openDialogTest, filterView,BUfilterView, dataSourceFiltered, showProjectModal,projectModalData } = this.state

        let { tableViewState,monthlyStatusReportState, dashboardState } = this.props;
        const {tableRowData, tableRowHeader,tableRowHeader1,tableRowData1, regionArr,buFilterArr, loading} = monthlyStatusReportState || {}

        let pivotHeaderData = this.changeKeysOfHeader(tableRowHeader)
        let pivotHeaderData1 = this.changeKeysOfHeader(tableRowHeader1)

        const emailData = tableViewState.emailTemplate.data;
        // const datelen = dashboardState && dashboardState.reportDateArr && dashboardState.reportDateArr.length;
        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <>
                <div className="analysis-action">
                    {
                        <>
                            <div className="flex ai-center jc-space-between mb-10">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        {/* <FormControl className='w-200'>
                                            <Select
                                                value={month || updatedMonth}
                                                onChange={this.handleMonthChange}
                                                MenuProps={{ onExited: () => document.activeElement.blur() }}
                                            >
                                                {newArray.map((item, index) => {
                                                    return <MenuItem value={item} key={index}>{item}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl> */}
                                        {
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                className="mb-10 newButton btnBlue btnEmail uppercase"
                                                endIcon={<Icon>visibility</Icon>}
                                                onClick={this.openViewEmail}
                                            >
                                                View Email
                                            </Button>
                                            }
                                        <Modal
                                            open={this.state.viewEmail}
                                            onClose={this.closeViewEmail}
                                            closeAfterTransition
                                            BackdropComponent={Backdrop}
                                            BackdropProps={{
                                                timeout: 500,
                                            }}
                                        >
                                            <Fade in={this.state.viewEmail}>
                                                {
                                                // tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> :
                                                    <div className='customModal viewEmailModal'>
                                                        <div className='header'>
                                                            <h2>View Email</h2>
                                                        </div>
                                                        <div className='modal-content email-template'>
                                                            <form onSubmit={this.handleSubmitTemplate}>
                                                                <TextField
                                                                    id="email_title"
                                                                    label="Title of Email"
                                                                    style={{ margin: 8 }}
                                                                    placeholder=""
                                                                    helperText=""
                                                                    fullWidth
                                                                    defaultValue={emailData.email_title}
                                                                    margin="normal"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    id="email_salutation"
                                                                    label="Salutation"
                                                                    style={{ margin: 8 }}
                                                                    defaultValue="Dear username,"
                                                                    margin="normal"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                                <FormControl style={{ marginTop: 8, marginLeft: 10 }}>
                                                                    <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                                                    <CKEditor
                                                                        config={{
                                                                            removePlugins: ['Link', 'EasyImage', 'CKFinder', 'Indent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                                                        }}
                                                                        id="editor-msg-body"
                                                                        name="emailView"
                                                                        editor={ClassicEditor}
                                                                        data={emailData.email_msg}
                                                                        onReady={editor => {
                                                                            this.setState({ editorMsgBody: editor })
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                <p className="ml-10">{emailData.app_link}</p>

                                                                <TextField
                                                                    id="email_signature"
                                                                    label="Signature"
                                                                    style={{ margin: 10 }}
                                                                    defaultValue={`Thanks - xP&A Support `}
                                                                    margin="normal"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                                <div className="flex jc-flex-end mt-30">
                                                                    <Button type="submit" variant="contained" className='newButton btnBlue btnSubmit uppercase' color="primary">Submit</Button>
                                                                    <Button variant="contained" className='ml-16 newButton btnRed btnCancel uppercase' onClick={this.closeViewEmail}>Cancel</Button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                }

                                            </Fade>
                                        </Modal>

                                       
                                        <Dialog
                                            open={openDialogTest}
                                            onClose={this.handleCloseDialogTest}
                                        >
                                            <DialogTitle>
                                                Confirmation
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Are you sure you want to send the test email?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button autoFocus onClick={this.handleCloseDialogTest} variant='contained' className='newButton btnRed btnCancel uppercase' color="primary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={this.handleSendMailTest} variant="outlined" className='newButton btnBlue btnEmail uppercase' size="small" color="primary">
                                                    Send Test Email
                                                </Button>
                                            </DialogActions>
                                        </Dialog>


                                        {
                                            (getAccessByMenuKey(menuData, "auto_emails", "add") || getAccessByMenuKey(menuData, "auto_emails", "edit")) &&
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                className="ml-20 mb-10 newButton btnBlue btnEmail uppercase"
                                                endIcon={<Icon>send</Icon>}
                                                onClick={this.handleOpenDialog}
                                            >
                                                Send Email
                                            </Button>}
                                        <Dialog
                                            open={openDialog}
                                            onClose={this.handleCloseDialog}
                                        >
                                            <DialogTitle>
                                                Confirmation
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Are you sure you want to send it?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button autoFocus variant='contained' className='newButton btnRed btnCancel uppercase' onClick={this.handleCloseDialog} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={this.handleSendMail} variant="contained" className='newButton btnBlue btnEmail uppercase' size="small" color="primary">
                                                    Send Email
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        {/* <Tooltip title={'Download to Excel'}>
                                            <img
                                                src={DownloadExcel}
                                                height='30'
                                                className='pointer download-excel-custom'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.handleDownloadExcel();
                                                }} />
                                        </Tooltip> */}
                                    </Grid>
                                </Grid>
                            </div>
                            {
                            
                            }
                        </> 
                    }
                </div>
                
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                monthlyStatusReportState: state.monthlyStatusReportState,
                tableViewState: state.dashboardState.dashboardAnalytic.AROverdueActionAnalysis,
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                externalState: state.dashboardState.receivableData.ageingData.external,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getMonthlyStatusReport(data) {
                    dispatch({
                        type: 'FETCH_MONTHLY_STATUS_DATA',
                        payload: data
                    });
                },
                saveEmailTemplate(data) {
                    dispatch({
                        type: "SAVE_EMAIL_TEMPLATE_AUTOMATED_REPORTING",
                        payload: data
                    })
                },
                getEmailTemplate(data) {
                    dispatch({
                        type: 'FETCH_EMAIL_TEMPLATE_AUTOMATED_REPORTING',
                        payload: data
                    });
                },
                sendEmail(data) {
                    dispatch({
                        type: "SEND_MAIL_AUTOMATED_REPORTING",
                        payload: data
                    });
                },
                updateActionOwnerFn(data) {
                    dispatch({
                        type: 'UPDATE_ACTION_OWNER_MONTHLY_STATUS_ACTION',
                        payload: data
                    });
                },
            };
        }
    )(MonthlyStatusReportComponent);