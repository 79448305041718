import axios from 'axios';

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const makeSeriesData = (groupedItem, key) => {
  let arr = [];
  groupedItem.forEach(item => {
    arr.push(item[key]);

  })
  return arr;
}

export const transformStackData = (data, key) => {
  const groupedData = groupBy(data, key);
  let g1Arr = []
  let g2Arr = []
  let counter = 0
  Object.keys(groupedData).forEach((index) => {
    counter++
    let obj = {};
    obj['color'] = 'brown';
    obj['name'] = 'Gap to Target';
    if (counter == 1) {
      obj['id'] = 'g1';
    } else {
      obj['linkedTo'] = 'g1';
    }
    obj['data'] = makeSeriesData(groupedData[index], 'gap_to_target');
    obj['stack'] = index;
    g1Arr.push(obj);
    obj = {};
    obj['color'] = 'blue';
    obj['name'] = 'CurrentFCF';
    if (counter == 1) {
      obj['id'] = 'g2';
    } else {
      obj['linkedTo'] = 'g2';
    }
    obj['data'] = makeSeriesData(groupedData[index], 'current_fcf');
    obj['stack'] = index;
    g2Arr.push(obj);
  })
  return [...g1Arr, ...g2Arr];
}

export const getGapAnalysisChartByCostCenter = (id) => {
  return axios.get('http://localhost:8080/v1/common/gap/analysis/chart?cost_center=' + id);
}


export const getGapAnalysisChart = (data) => {
  if (data) {
    return axios.get('http://localhost:8080/v1/common/gap/analysis/chart?' + data);
  } else {
    return axios.get('http://localhost:8080/v1/common/gap/analysis/chart');
  }
}


export const getGapAnalysisChartByTimePeriod = (id) => {
  return axios.get('http://localhost:8080/v1/common/gap/analysis/chart?time_period=' + id);
}


export const getGapAnalysisChartByLocation = (id) => {
  return axios.get('http://localhost:8080/v1/common/gap/analysis/chart?location=' + id);
}

export const createAnchorElement = (data) => {
  var a = document.createElement('a');
  var linkText = document.createTextNode("my title text");
  a.appendChild(linkText);
  a.setAttribute('href', createMailHref(data));
  a.innerHTML = data.send_email;
  document.body.appendChild(a);
}

const createMailHref = (item) => {
  let mailText = 'mailto:';
  mailText = `${mailText}${item.send_email}?subject=${item.cost_center}: Gap to Target identified in ${item.location}. Please initiate improvement plan. &body=
   Dear ${item.unit_head},
                    %0D%0A
                    %0D%0A

                   This email is being sent to you by the Leadership team.
                   %0D%0A
                   %0D%0A

                   A free cash flow gap to target of $${item.gap_to_target} M has been identified related to ${item.cost_center} under your management. Details are as below:
                   
                   %0D%0A
                   %0D%0A
                   %0D%0A

                   Business Unit     Key Indicator       Current FCF ($ M)       Target FCF ($ M)      Gap to Target ($ M)         
                  ${item.business_unit}   ${item.key_indicator}  ${item.current_fcf}  ${item.target_fcf}    ${item.gap_to_target}
                   
                        %0D%0A

                        %0D%0A
                        %0D%0A

                   Please click on the following link to initiate an execute an improvement plan for the same.
                   %0D%0A

                   <a href=""><Link to navigate to FCF Improvement section over the application></a>

                   %0D%0A
                   %0D%0A

                   
                   Thank you for your co-operation in driving Free Cash Flow improvements within the organization.
                   %0D%0A
                   %0D%0A

                   Client Leadership Team
                   %0D%0A
                   %0D%0A

                   <Details>
   `
  return mailText
}
export const stackSeriesDataTransform = (seriesData) => {
  return seriesData.map(item => {
    item.send_email = createAnchorElement(item);
    return item
  })
}
