import React from "react";
import PropTypes from "prop-types";
import "react-table-6/react-table.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { handleTableTransform } from '../../../../utils/numberUtils'
import Tooltip from '@material-ui/core/Tooltip';
import TableComponent from './TableComponent'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import Grid from '@material-ui/core/Grid';
import { parseJSON, format } from "date-fns";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function Table(props) {
  const businessUnitIndex = {
    "BU 1": 0,
    "BU 2": 1,
    "BU 3": 2,
    "BU 4": 3,
    inter: 4
  }
  const [tabValue, setValue] = React.useState(props.defaultTab ? props.defaultTab : 0);
  const [columnValue, setColumnValue] = React.useState([]);
  const [rowValue, setRowValue] = React.useState([]);
  const [fixedHeaderValue, setFixedHeader] = React.useState(true);
  const datelen = props.reportDateArr && props.reportDateArr.length;
  const [month, setMonth] = React.useState(props.reportDateArr && props.reportDateArr[datelen > 0 ? datelen - 1 : 0]);
  const [state, setState] = React.useState({ checkedC: true });
  const dashboardState = useSelector(state => state.dashboardState);
  const [externalTabValue, setExtenalTabValue] = React.useState(props.externalFilterObject && props.externalFilterObject.bu ? businessUnitIndex[props.externalFilterObject.bu.toLowerCase()] : 0);
  const headerColorArr = ['#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']

  const handleSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleChangeSelectedParentGroup(parentGroupObj[newValue])
  };

  const handleExternalTabChange = (event, newValue) => {
    setExtenalTabValue(newValue);
    props.handleChangeSelectedBU(reverseBUnitIndex[newValue])
  };

  const changeKeysOfHeader = (data) => {
            
   
    if (data && data.length) {
      return data.map((item, i) => {
        return {
          Header: item.label,
          accessor: item.key,
          Cell: (props) => {
            return (
              <div className='text-center'>
                {handleTableTransform(props.original, item)}
              </div>
            )
          },
          style: {
            fontSize: '12px'
          },
          headerStyle: {
            whiteSpace: 'break-spaces',
            overflow: 'visible',
            fontSize: '10px',
            fontWeight: 'bold',
            backgroundColor: headerColorArr[i]
          }
        };
      });
    }
    return [];
  };

  const reverseBUnitIndex = {
    0: "BU 1",
    1: "BU 2",
    2: "BU 3",
    3: "BU 4",
    4: "inter"
  }

  const parentGroupObj = {
    1: "external",
    2: "slb",
    3: "unbilled"
  }

  const businessUnitType = {
    external: 1,
    slb: 2,
    unbilled: 3,
  }


  if (dashboardState.receivableData.ageingData.summary.fetched && fixedHeaderValue) {
    const datelen = props.reportDateArr && props.reportDateArr.length;
    const updatedMonth = props.reportDateArr[datelen > 0 ? datelen - 1 : 0]
    setMonth(updatedMonth);
    const payload = {
      mainTab: parentGroupObj[tabValue],
      secondaryTab: reverseBUnitIndex[externalTabValue],
      selectedDate: updatedMonth
    }
    props.handleDateChangeFn(payload)
    const { externalData, buRowData } = dashboardState.receivableData.ageingData.summary;
    if (externalData && externalData.row_header && externalData.row_header.length) {
      var _data = [...[], ...externalData.row_header]
      var headerData = []
      headerData = _data.filter(item => {
        if (!(item.key == "business_unit" || item.key == "grand_total")) {
          return item
        }
      })
      headerData = changeKeysOfHeader(headerData)
      if (externalTabValue < 4) {
        var rowData = [buRowData]
        setRowValue(rowData)
        setColumnValue(headerData)
        setFixedHeader(false)
      }
    }
  }

  const handleDownloadExcel = () => {
    props.handleDownloadExcel(tabValue);
  }

  const printDocument = () => {
    props.printDocument(tabValue);
  }

  const summaryScreenBUClick = (item, type, headerData, rowData) => {
    if (businessUnitIndex[item] != undefined) {
      setValue(businessUnitType[type]);
      setExtenalTabValue(businessUnitIndex[item])
    }
  }

  const handleApplyFilterFunc = (payload) => {
    payload['bu'] = reverseBUnitIndex[externalTabValue];
    props.handleApplyFilter(payload)

  }

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    if (event.target.value) {
      const payload = {
        mainTab: parentGroupObj[tabValue],
        secondaryTab: reverseBUnitIndex[externalTabValue],
        selectedDate: event.target.value
      }
      props.handleDateChangeFn(payload)
    }
  }

  const secondaryTabPanel = () => {
    
    return (
          <TableComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            handleApplyFilter={handleApplyFilterFunc}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            buValue={reverseBUnitIndex[externalTabValue]}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
          />
    );
  }

  return (
    <React.Fragment>
      <div className="flex ai-center jc-space-between mb-10">
        {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={2} className='maxw-200'>
              <FormControl className='w-200'>
                <Select
                  value={month}
                  onChange={handleMonthChange}
                  MenuProps={{ onExited: () => document.activeElement.blur() }}
                >
                  {props && props.reportDateArr && props.reportDateArr.length &&
                    props.reportDateArr.map((item, index) => {
                      return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
        </Grid> */}
        {/* {(tabValue == 0) &&  <Tooltip title={'Download to PDF'} className='pointer'>
              <PictureAsPdfIcon fontSize="large" className='pointer'
              height='30'
              onClick={(event) => {
                event.preventDefault();
                printDocument();
              }}/>  
                
             </Tooltip>} */}
             <span> Data as on : {dashboardState.selectedDate}</span>
        <Tooltip title=  {'Download to Excel'}>
          <img
            src={DownloadExcel}
            height='30'
            className='pointer'
            onClick={(event) => {
              event.preventDefault();
              handleDownloadExcel();
            }} />
        </Tooltip>
      </div>
      {/* <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary" className='blockTabs mb-10'>
        <LinkTab label="3rd Party" href="/trash" {...a11yProps(0)} />
        <LinkTab label="SLB" href="/spam" {...a11yProps(1)} />
        <LinkTab label="Unbilled" href="/slb" {...a11yProps(2)} />
      </Tabs> */}
      {/* <TabPanel value={tabValue} index={0} className="tab-content">
        {secondaryTabPanel()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {secondaryTabPanel()}
      </TabPanel>
      <TabPanel value={tabValue} index={2}> */}
        {secondaryTabPanel()}
      {/* </TabPanel> */}
    </React.Fragment>
  );
}
export default Table;