import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../../constant";

function HighChart(props) {
    const {performanceAnalysis: { cumulativePerformance: { monthlyWorkingCapitalGapColor, cumulativeEBIDTAGapColor, fcfEfficiencyColor } }} = CHART_COLORS;
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy'
                        },
                        title: {
                            text: 'FCF Cumulative Impact'
                        },
                        credits: {
                            enabled: false
                          },
                        xAxis: [{
                            categories: ['Month1', 'Month2', 'Month3', 'Month4', 'Month5', 'Month6'],
                            crosshair: true
                        }],
                        yAxis: [{ // Primary yAxis
                            labels: {
                                format: '${value}',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            }, 
                            title: {
                                text: 'USD millions',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            }
                        }, { // Secondary yAxis
                            labels: {
                                format: '{value} %',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            max:100, // need to verify this
                            title: {
                                text: '',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                        }],
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            }
                        },
                        series: [{
                            name: 'Monthly working Capital Impact',
                            type: 'column',
                            yAxis: 0,
                            data: [176.0, 144.0, 129.2,  106.4, 71.5, 49.9,],
                            tooltip: {
                                valueSuffix: ''
                            },
                            color: monthlyWorkingCapitalGapColor
                        }, {
                            name: 'Cumulative EBIDTA Impact',
                            type: 'column',
                            yAxis: 0,
                            data: [148, 124, 89, 56, 34, 29],
                            color: cumulativeEBIDTAGapColor
                        }, {
                            name: 'FCF Efficiency',
                            type: 'spline',
                            yAxis: 1,
                            data: [6,10,15,40,60,88],
                            tooltip: {
                                valueSuffix: ''
                            },
                            color: fcfEfficiencyColor
                        }]
                    }
                }
            />
        </div>
    )
}
export default HighChart;