import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Dashboard from "../Dashboard";
import DashboardDetail from "../Dashboard/profitability";
import Operational from "../Operational";
import LoadAI from "../LoadAI";
import Analytics from "../Analytics";
import AnalyticsSidebar from "../Analytics/sidebar";
import KeyInitiatives from "../KeyInitiatives";
import Datasources from "../Datasources";
import DatasourcesSidebar from "../Datasources/sidebar";
import { Switch, Route } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const DefaultSidebar = () => {
    return (
        <div className="side-navigation">
            <Drawer variant="permanent">
                <div>
                  <IconButton>
                    <MenuIcon/>
                  </IconButton>
                </div>
            </Drawer>
        </div>
    )
}
export default function Sidebar(props) {
  return (
    <div className="content-wrapper">
      <main >
        <div className="main-content">
            <div className="ml-60 mt-30">
                 <Switch>
                    <Route path="/route-profitability/dashboard" exact>
                        <DefaultSidebar/>
                        <Dashboard/>
                    </Route>
                    <Route path="/route-profitability/dashboard-detail" exact>
                        <DefaultSidebar/>
                        <DashboardDetail/>
                    </Route>
                    <Route path="/route-profitability/operational" exact>
                        <DefaultSidebar/>
                        <Operational/>
                    </Route>
                    <Route path="/route-profitability/analytics" exact>
                        <AnalyticsSidebar/>
                        <Analytics/>
                    </Route>
                    <Route path="/route-profitability/ai" exact>
                        <DefaultSidebar/>
                        <LoadAI/>
                    </Route>
                    <Route path="/route-profitability/key-initiatives" exact>
                        <DefaultSidebar/>
                        <KeyInitiatives/>
                    </Route>
                    <Route path="/route-profitability/datasources" exact>
                        <Datasources/>
                        <DatasourcesSidebar/>
                    </Route>
                </Switch>
            </div>
        </div>
      </main>
    </div>
  );
}
