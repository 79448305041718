import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Grid, Tooltip } from "@material-ui/core";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Button from '@material-ui/core/Button';
import NewPagination from "../../../common/components/CustomPagination";

import ModalEbitdaFu from "./EbitdaFUModal"
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { getAccessByMenuKey } from "../../../../utils/userManagement";
import ErrorPage from "../../../common/components/ErrorPage";
import parseJSON from 'date-fns/parseJSON'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InProgressComponent from '../../../common/components/InProgress'
import { format } from 'date-fns/esm';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class ebitdaGrid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rowData: this.props.autoEbitdaBudgetState.data,
      rowDataActual: [],
      rowDataForecast: [],
      rowHeaderForecast: [],
      rowHeaderActual: [],
      rowHeaderData: [],
      showModal: false,
      tabSelectorValue: 0,
    //   id: '',
      showErrorModal: false,
      fileName: '',
    //   showCommentError: false,
      commentErrors: []
    };
  }

  modalCallBack = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.props.getEbitdaBudgetData({});
  }

  handleAddClick = (data) => {
    if (this.state.tabSelectorValue == 2) {
        console.log("file upload forecast")
    } else {
        this.setState({
        //   editMode: false,
        showModal: true
        });
    }
  }

  refreshList = () => {
    if (this.state.tabSelectorValue == 0) {
      this.props.getEbitdaBudgetData({})
    } else if (this.state.tabSelectorValue == 1) {
      this.props.getEbitdaActualData({})
    }
  }

  handleEbitdaSave = (data) => {
    document.body.style.cursor = 'wait';
    if (this.state.tabSelectorValue == 0) {
        // console.log("upload budget data")
        this.props.saveEbitdaBudgetData(data);
    } else if (this.state.tabSelectorValue == 1) {
        // console.log("upload actual data")
        this.props.saveEbitdaActualData(data);
    }
  }

//   handleEbitdaBudgetSave = (data) => {
//     document.body.style.cursor = 'wait';
//     this.props.saveEbitdaBudgetData(data)
//   }

//   handleEbitdaActualSave = (data) => {
//     this.props.saveEbitdaActualData(data)
//   }

  updateFileName = (name) => {
    this.setState({
      fileName: name + " " + format(new Date, "MM-dd-yyyy HH-mm-ss") + ".xlsx",
    })
  }

  componentDidUpdate(prevProps) {
    const { autoEbitdaBudgetState, autoEbitdaActualState } = this.props;
    if (this.state.tabSelectorValue == 0) {
      if (prevProps.autoEbitdaBudgetState.data.length != autoEbitdaBudgetState.data.length) {
        let newHeader = this.changeKeysOfHeader(autoEbitdaBudgetState.header)

        this.setState({ rowData: autoEbitdaBudgetState.data, rowHeaderData: newHeader })
      }

    } else if (this.state.tabSelectorValue == 1) {
      if (prevProps.autoEbitdaActualState.data.length != autoEbitdaActualState.data.length) {
        let newHeader = this.changeKeysOfHeader(autoEbitdaActualState.header)
        this.setState({ rowHeaderActual: newHeader, rowDataActual: autoEbitdaActualState.data, })
      }

    }
    if ((prevProps.autoEbitdaBudgetState.saveLoading && !autoEbitdaBudgetState.saveLoading) && autoEbitdaBudgetState.saveSuccess) {
      this.refreshList();
      this.modalCallBack();
    }
    if (prevProps.autoEbitdaBudgetState.showError !== autoEbitdaBudgetState.showError && autoEbitdaBudgetState.showError) {
      this.setState({ showErrorModal: true })
    }
    document.body.style.cursor = 'default';
  }

  handleDynamicWidth = (item) => {
    let widthSize;
    switch (item.key) {
      case 'automation.file_name':
        widthSize = 280;
        break;
      default: break;
    }
    return widthSize
  }

  handleDownload = (ori) => {
    const {tabSelectorValue} = this.state;
    this.props.downloadEbitData(
      {
        path: ori.automation.file_path,
        file_name: ori.automation.file_name,
        id: ori.id, 
        type: tabSelectorValue == 0 ? 'ebitda_budget' : 'ebitda_actual'
      })
  }

  maskFileName = (props) => {
    let ori = props.original;
    let coldata = ori["automation"]['file_name'].split(")")
    coldata.splice(0, 1)
    return coldata
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabSelectorValue: newValue }, () => {
      if (newValue == 0) {
        this.props.getEbitdaBudgetData({})
      } else if (this.state.tabSelectorValue == 1) {
        this.props.getEbitdaActualData({})
      } else { //will change later
        // this.props.getEbitdaBudgetData({})
        // this.props.getEbitdaActualData({})
      }
    })
  };

  changeKeysOfHeader = (data) => {
    // const { authReducer } = this.props
    // const menus = authReducer.menuData
    if (data && data.length) {
      return data.map((item) => {
        let obj = {
          Header: item.label,
          id: item.key,
          width: this.handleDynamicWidth(item),
          accessor: item.key,
          headerStyle: {
            fontSize: '13px',
            // fontWeight: 'bold',
            height: '35px',
            // lineHeight: '30px'
          },
          style: {
            fontSize: '12px',
            textAlign: 'center',
            height: '33px',
            // lineHeight: '40px',
            paddingLeft: '4px',
          },
        }
        if (item.key == "automation.created_date") {
          obj.accessor = (_data) => {
            let coldata = format(parseJSON(_data["automation"]['created_date']), 'dd MMM yyyy')
            return coldata
          }
        }
        // if (item.key =="automation.error_details"){
        //   obj.Cell = (props)=> {

        //         let errors = props.original.automation.error_details;

        //         return errors ? 
        //         <Tooltip title= {"Open Error Details"}>
        //           <span onClick={() => this.setState({ showCommentError: true, commentErrors: errors})} className='primary-dark pointer'>
        //             <u> View </u>
        //           </span>
        //         </Tooltip>:''
        //   }
        // }
        // if (item.key == "report_date") {
        //   obj.accessor = (_data) => {
        //     let coldata = format(parseJSON(_data["report_date"]), 'dd MMM yyyy')
        //     return coldata
        //   }
        // }
        if (item.key == 'automation.file_name') {
          obj.Cell = (props) => (
            <Tooltip title={"Download Data"}>
                <span onClick={() => {
                  this.handleDownload(props.original)
                }} className='primary-dark pointer'><u>{
                  this.maskFileName(props)
                }</u></span>
              </Tooltip>
            // getAccessByMenuKey(menus, "auto_payment", "download") ?
            //   <Tooltip title={"Download Collection Data"}>
            //     <span onClick={() => {
            //       this.handleDownload(props.original)
            //     }} className='primary-dark pointer'><u>{
            //       this.maskFileName(props)
            //     }</u></span>
            //   </Tooltip>
            //   : this.maskFileName(props)
          )
        }
        return obj
      })
    }
  }

  handleDownloadTemplateClick = (event) => {
    console.log(event.currentTarget)
  };

  render() {
    const { autoEbitdaBudgetState, autoEbitdaActualState } = this.props;
    const { open,  showModal, rowData, rowDataActual, rowHeaderActual, rowHeaderData, rowDataForecast, rowHeaderForecast, tabSelectorValue, id,  showErrorModal, fileName } = this.state;
    // const menus = authReducer.menuData

    // const { autoEbitdaBudgetState, autoEbitdaActualState, authReducer } = this.props;
    // const { open, editData, editMode, showModal, rowData, rowDataActual, rowHeaderActual, showCommentModal, rowHeaderData, showInvoiceModal, tabSelectorValue, id, openPopup, anchorEl, typeInvoiceModalForecast, showErrorModal, showCommentError, commentErrors, fileName } = this.state;
    // const menus = authReducer.menuData
   
    // let newErrors=[];
    // if(commentErrors){
    //   newErrors = commentErrors.map((x)=> {
    //     let msg = x.message;
    //     return <li>{msg}</li>
    //   })
    // }
    // let backFCF = localStorage.getItem('show-back-fcf')

    const body = (
      <React.Fragment>        
        <Tabs
          value={tabSelectorValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="BUDGET" />
          <Tab label="ACTUAL" />
          <Tab label="FORECAST" />
        </Tabs>
        <Grid container justifyContent="flex-end"  className='mt-20 mb-20'>
          {/* {tabSelectorValue == 1 && getAccessByMenuKey(menus, "auto_payment", "add") && */}
          {tabSelectorValue == 1 &&
            <div>
              <Button variant="contained" className="newButton btnBlue uppercase btnUpload" onClick={() => this.handleAddClick()} color="primary">Upload Data File</Button>
              <Button variant="contained" className="ml-10 newButton btnBlue uppercase btnDownload" color="primary" onClick={this.handleDownloadTemplateClick}> Download Template </Button>
            </div>
          }
          {tabSelectorValue == 0 &&
            <div>
              <Button variant="contained" className="newButton btnBlue uppercase btnUpload" onClick={() => this.handleAddClick()} color="primary">Upload Data File</Button>
              <Button variant="contained" className="ml-10 newButton btnBlue uppercase btnDownload" color="primary" onClick={this.handleDownloadTemplateClick}> Download Template </Button>
            </div>
          }
          {tabSelectorValue == 2 &&
            <div>
              <Button variant="contained" className="newButton btnBlue uppercase btnUpload" onClick={() => this.handleAddClick()} color="primary">Upload Data File</Button>
              <Button variant="contained" className="ml-10 newButton btnBlue uppercase btnDownload" style={{ marginRight: '2px' }} color="primary" onClick={this.handleDownloadTemplateClick}> Download Template </Button>
            </div>
          }
        </Grid>
        <div>
          {autoEbitdaBudgetState.loading ? <div className='text-center'><CircularProgress /></div> :
            tabSelectorValue == 0 ?
              <ReactTable
                data={rowData}
                columns={rowHeaderData}
                defaultPageSize={10}
                className="xpna-react-table single-line-header -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              /> : tabSelectorValue == 1 ? 
              <ReactTable
                data={rowDataActual}
                columns={rowHeaderActual}
                defaultPageSize={5}
                className="xpna-react-table single-line-header -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              /> : <ReactTable
                data={rowDataForecast}
                columns={rowHeaderForecast}
                defaultPageSize={5}
                className="xpna-react-table single-line-header -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              />
          }
        </div>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        {body}
        {tabSelectorValue == 0 && autoEbitdaBudgetState.saveLoading &&
            <InProgressComponent
                headerText='Upload in Progress'
                bodyText="EBITDA Budget data Upload is in progress, please wait.."
                showModal="autoEbitdaBudgetState.saveLoading"
            />
        }
        {tabSelectorValue == 1 && autoEbitdaActualState.saveLoading &&
            <InProgressComponent
                headerText='Upload in Progress'
                bodyText="EBITDA Actual data Upload is in progress, please wait.."
                showModal="autoEbitdaBudgetState.saveLoading"
            />
        }
        {
            <ModalEbitdaFu
                saveLoading={tabSelectorValue == 0 ? autoEbitdaBudgetState.saveLoading : autoEbitdaActualState.saveLoading}
                showModal={showModal}
                modalCallBack={this.modalCallBack}
                saveCallback={this.handleEbitdaSave}
                tabSelectorValue={tabSelectorValue}
                />
        }

        
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
        autoEbitdaBudgetState: state.userManagementState.autoEbitdaBudgetState,
        autoEbitdaActualState: state.userManagementState.autoEbitdaActualState,
        // authReducer: state.authReducer,
        // dashboardState: state.dashboardState
    };
  },
  (dispatch) => {
    return {
        getEbitdaBudgetData(data) {
            dispatch({
                type: "FETCH_FILE_UPLOAD_BUDGET",
                payload: data,
            });
        },
        getEbitdaActualData(data) {
            dispatch({
                type: "FETCH_FILE_UPLOAD_ACTUAL",
                payload: data,
            });
        },
        saveEbitdaBudgetData(data) {
            dispatch({
            type: "SAVE_AUTO_EBITDA_BUDGET",
            payload: data,
            });
        },
        saveEbitdaActualData(data) {
            dispatch({
                type: "SAVE_AUTO_EBITDA_ACTUAL",
                payload: data,
            });
        },
        downloadEbitData(data) {
            dispatch({
                type: "DOWNLOAD_FILE_AUTO",
                payload: data,
            });
        },
        fetchUploadType() {
            dispatch({
            type: 'FETCH_EBITDA_UPLOAD_TYPE',
            });
        }
    };
  }
)(ebitdaGrid);
