import { transformFilterData, transformExcludeFilterData, OrderData, transformDefaultHeaderValues } from "./transformer";

const reducer = (
   state = {
      loading: false,
      pivotRowData: [],
      additionalData: [],
      chartData: {
         data: [],
         max: 4000000
      },
      pivotHeaderData: [],
      commentDataPayload: {},
      defaultMonth: "",
      defaultYear: "",
      defaultQtr: "",
      lastIngestion: "",
      defaultIncomeMonth: "",
      quarterfilterArr: [],
      monthFilterArr: [],
      yearFilterArr: [],
      legalEntityArr: [],
      invoiceDetailState: {},
      saveLoading: false,
      errorWhileSaving: [],
      showError: false,
      saveSuccess: false,
      invoiceDetailStateGL: {},
      invoiceDetailStateFilter: [],
      invoiceLoading: false,
      NewUpdateLoading: false,
      invoiceLoadingGL: false,
      chartLoading: false,
      chartModalLoading: false,
      plantArr: [],
      countryArr: [],
      pivotHeaderDataComparative: [],
      pivotRowDataComparative: [],
      currentValue: "",
      previousValue: "",
      adhocChartData: {
         salesData: [],
         marginData: []
      },
      orderBookData: {
         loading: false,
         rowHeader: [],
         rowData: [],
         drilldown: {
            loading: false,
            row_header: [],
            row_data: []
         },
         comparison: {
            loading: false,
            main: { rowHeader: [], rowData: [] },
            new: { rowHeader: [], rowData: [] },
            slippage: { rowHeader: [], rowData: [] },
            change: { rowHeader: [], rowData: [] },
            out: { rowHeader: [], rowData: [] },
            total: { rowHeader: [], rowData: [] },
         }
      },
      compareDataBUPL: {
         loading: false,
         rowHeader: [],
         rowData: [],
         defaultRowHeader: [],
         defaultRowData: [],
         filter: {
            periodArr: [],
            plantArr: [],
            countryArr: [],
            buArr: []
         }

      },
      filterColumnArr: [],
      invoiceDetailStatePrevious: {},
      budgetGroup: [],
      automationHeaderData: [],
      automationRowData: [],
      budgetAnalysis: [],
      arDateArray: [],
      currentARMonth: '',
      uploadLoading: false,
      drilldownLoader: false,
      drilldownRowData: false,
      drilldownRowHeader: false,
      NewLoader: false,
      NewPieData: {},
      salesTechnologyLoader: false,
      salesTechnologyPieData: {},
      NewManualLoader: false,
      NewCommentLoader: false,
      NewOriginationCommentLoader: false,
      NewOriginationCommentData: {},
      NewCommentChartLoader: false,
      tornadoData: {},
      qtrfilter: [],
      monthFilter: [],
      yearFilter: [],
      defaultSelectedMonth: "",
      dassAOPData: {
         loading: false,
         by_bu: {},
         by_geo: {},
         pabBacklog: {
            loading: false,
            filters: [],
            data: [],
            rowData: [],
            rowHeader: [],
            drilldown: {
               loading: false,
               row_header: [],
               row_data: []
            },
         },
         dassByGeo: {
            loading: false,
            filters: [],
            data: [],
            rowData: [],
            rowHeader: [],
            drilldown: {
               loading: false,
               row_header: [],
               row_data: []
            },
         },
         summary: {
            loading: false,
            data: [],
            rowData: [],
            rowHeader: []
         },
         frontlog: {
            loading: false,
            filters: [],
            rowHeader: [],
            rowData: [],
            drilldown: {
               loading: false,
               row_header: [],
               row_data: []
            },
         }
      }
   },
   action
) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {
      case "FETCH_BUPL_INCOME_PIVOT_LOADER":
         newState.loading = action.loading;
         newState.pivotRowData = [];
         newState.pivotHeaderData = [];
         return newState;
      case "FETCH_BUPL_INCOME_PIVOT_LOADER_SUCCESS":
         newState.loading = action.loading;
         return newState;
      case "FETCH_CHART_LOADER":
         newState.chartLoading = action.loading;
         newState.pivotRowData = [];
         newState.pivotRowDataChart = [];
         newState.pivotHeaderData = [];
         return newState;

      case "FETCH_BUPL_COMPARATIVE_DETAIL_LOADER":
         newState.loading = action.loading;
         newState.pivotRowData = [];
         newState.uploadLoading = action.uploadLoading ? action.uploadLoading : false
         return newState;

      case "FETCH_BUPL_DETAIL_GL_LOADER":
         newState.invoiceLoadingGL = action.loading;
         return newState;

      case "FETCH_BUPL_DETAIL_GL_SUCCESS":
         newState.invoiceDetailStateGL = action.data.current;
         newState.invoiceLoadingGL = action.loading;
         return newState;
      case "FETCH_CHART_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowData = action.data.row_data;
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         return newState;
      case "FETCH_OPTIMUS_PIVOT_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowData = action.data.row_data;
         newState.countries = action.data.countries;
         newState.pivotHeaderData = action.data.row_header;
         newState.pivotRowData2 = action.data.row_data2;
         newState.pivotHeaderData2 = action.data.row_header2;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;
      case "FETCH_BUPL_INCOME_PAB_SUCCESS":
         let datax = action.data.row_data
         newState.loading = action.loading;
         newState.pivotRowData = datax;
         newState.countries = action.data.countries;
         newState.pivotHeaderData = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
            radioArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         newState.radioArr = radioArr;
         return newState;
      case "FETCH_CHART_New_SO_SUCCESS":
         newState.chartLoading = action.loading;
         newState.pivotRowData = action.data.row_data;
         newState.pivotRowDataChart = action.data.row_chart;
         newState.additionalData = action.data.additional_data;
         newState.countries = action.data.countries;
         newState.pivotHeaderData = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
            radioArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         newState.radioArr = radioArr;
         return newState;

      case 'FETCH_COMMENT_HISTORY_AUTOMATED_REPORTING_SUCCESS':
         newState.pivotRowData = action.data;
         return newState

      case "FETCH_BUPL_INCOME_PIVOT_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowData = action.data.row_data;
         newState.additionalData = action.data.additional_data;
         newState.countries = action.data.countries;
         newState.pivotHeaderData = action.data.row_header;
         newState.chartData.data = action.data.row_chart?.data;
         newState.chartData.max = action.data.row_chart?.max;

         newState.compareDataBUPL.rowData = action.data.row_data;
         newState.compareDataBUPL.rowHeader = action.data?.row_header ? transformDefaultHeaderValues(action.data?.row_header) : [] ;
         newState.compareDataBUPL.defaultRowHeader = newState.compareDataBUPL.rowHeader;
         newState.compareDataBUPL.defaultRowData = action.data.row_data;

         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
            radioArr,
            countryArr
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         newState.countryArr = countryArr;
         newState.radioArr = radioArr;
         return newState;
      case "FETCH_OCOS_SUMMARY_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowDataOCOS = action.data.row_data;
         newState.countries = action.data.countries;
         newState.pivotHeaderDataOCOS = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;
      case "FETCH_BUPL_COMPARATIVE_DETAIL_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowDataComparative = action.data.row_data;
         newState.pivotHeaderDataComparative = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         newState.currentValue =
            action.data.metadata && action.data.metadata.current;
         newState.previousValue =
            action.data.metadata && action.data.metadata.previous;
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;

      case "FETCH_BUPL_DEFAULT_MONTH_SUCCESS":
         newState.loading = action.loading;
         newState.defaultMonth = action.data.month;
         newState.lastIngestion = action.data.last_ingestion;
         newState.defaultYear = action.data.year;
         newState.defaultQtr = action.data.quarter;
         return newState;
      case "FETCH_New_DEFAULT_MONTH_SUCCESS":
         // newState.loading = action.loading;
         newState.defaultMonthLawson = action.data.lawson.month;
         newState.defaultMonthOptimus = action.data.optimus.month;
         newState.defaultMonthSLBSAP = action.data.slbsap.month;
         newState.defaultYearAOP = action.data.aop && action.data.aop.year;
         return newState;
      case "FETCH_HFM_DEFAULT_MONTH_SUCCESS":
         newState.lastIngestion = action.data.last_ingestion;
         newState.loading = action.loading;
         newState.defaultIncomeMonth = action.data.month;
         return newState;
      case "FETCH_BUPL_DROPDOWN_SUCCESS":
         newState.invoiceDetailState.classification = action.classification;
         newState.invoiceDetailState.countries = action.countries;
         return newState;
      case "FETCH_BUPL_INVOICE_DETAIL_LOADER":
         newState.invoiceLoading = action.loading;
         return newState;
      case "UPDATE_OPTIMUS_New_LOADER":
         newState.NewUpdateLoading = action.loading;
         return newState;

      case "FETCH_ADHOC_CHART_SUCCESS":
         newState.adhocChartData.salesData = action.data?.sales_data;
         newState.adhocChartData.marginData = action.data?.margin_data;
         return newState;

      case "FETCH_CHART_MODAL_LOADER":
         newState.chartModalLoading = action.loading;
         return newState;

      case "FETCH_BUPL_INVOICE_DETAIL_SUCCESS":
         // console.log(action, "--action yw")
         newState.invoiceDetailState = action.data.current;
         newState.invoiceDetailStateFilter = action.data.filter;
         if (action.isNewBuFour) {
            newState.invoiceDetailState = action.data;
         }
         if (action.data2) {
            newState.invoiceDetailState.classification = action.data2.classification;
            newState.invoiceDetailState.countries = action.data2.countries;
         }
         newState.invoiceLoading = action.loading;
         if (
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "previous") ||
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "variance")
         ) {
            newState.invoiceDetailStatePrevious =
               action.data && action.data.previous;
         }
         newState.invoiceDetailState.payload = action.dataPayload

         return newState;
      case "FETCH_BUPL_EXTENDED_PLANNING_SUCCESS" : 
         newState.invoiceDetailState.EP = action.data
         return newState;
      case "FETCH_CHART_New_SO_MODAL_SUCCESS":
         newState.invoiceDetailState = action.data.current;
         newState.invoiceDetailStateFilter = action.data.filter;
         if (action.data2) {
            newState.invoiceDetailState.classification = action.data2.classification;
            newState.invoiceDetailState.countries = action.data2.countries;
         }
         newState.chartModalLoading = action.loading;
         if (
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "previous") ||
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "variance")
         ) {
            newState.invoiceDetailStatePrevious =
               action.data && action.data.previous;
         }
         return newState;
      case "FETCH_FUNCTIONAL_SALES_TECHNOLOGY_SUCCESS":
         newState.loading = action.loading;
         let datas = action.data.row_data
         // let dataBudgetFilter = action.budgetGroupFilter
         // let dataBudgetGroup = action.budgetGroupUser
         // let isAllFunc = false
         // let isSelectedBudget = false
         // dataBudgetGroup && dataBudgetGroup.forEach(y => {
         //    if (y == "All Function") {
         //       isAllFunc = true
         //    }
         // })
         // if (dataBudgetFilter != undefined) {
         //    if (dataBudgetFilter != '') {
         //       isSelectedBudget = true
         //    } else {
         //       isSelectedBudget = false
         //    }

         // }

         // data.forEach(x => {
         //    if (isAllFunc == true || isSelectedBudget == true) {
         //       rows.push(x)
         //    } else {

         //       dataBudgetGroup.forEach(y => {
         //          if (x['data_field'] == y) {
         //             rows.push(x)
         //          }

         //       })


         //    }
         // })
         newState.pivotRowData = OrderData(datas, action.budgetGroupFilter);
         newState.pivotHeaderData = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;


      case "FETCH_FUNCTIONAL_PIVOT_DATA_SUCCESS":
         newState.loading = action.loading;
         let rows = []
         let data = action.data.row_data
         // let dataBudgetFilter = action.budgetGroupFilter
         // let dataBudgetGroup = action.budgetGroupUser
         // let isAllFunc = false
         // let isSelectedBudget = false
         // dataBudgetGroup && dataBudgetGroup.forEach(y => {
         //    if (y == "All Function") {
         //       isAllFunc = true
         //    }
         // })
         // if (dataBudgetFilter != undefined) {
         //    if (dataBudgetFilter != '') {
         //       isSelectedBudget = true
         //    } else {
         //       isSelectedBudget = false
         //    }

         // }

         // data.forEach(x => {
         //    if (isAllFunc == true || isSelectedBudget == true) {
         //       rows.push(x)
         //    } else {

         //       dataBudgetGroup.forEach(y => {
         //          if (x['data_field'] == y) {
         //             rows.push(x)
         //          }

         //       })


         //    }
         // })
         newState.pivotRowData = data;
         newState.pivotHeaderData = action.data.row_header;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;

      case "FETCH_FUNCTIONAL_BUDGET_GROUP_SUCCESS":
         newState.budgetGroup = action.data;
         return newState;

      case "FETCH_FUNCTION_DRILLDOWN_LOADER":
         newState.drilldownLoader = action.loading;

         return newState;

      case "FETCH_FUNCTION_DRILLDOWN_SUCCESS":
         // newState.drilldownDetailState = action.data;
         newState.drilldownLoader = false;
         newState.drilldownRowHeader = action.data.row_header;
         newState.drilldownRowData = action.data.row_data;
         return newState;
      case "RESET_COMPARE_DATA_BUPL":
         // newState.drilldownDetailState = action.data;
         newState.compareDataBUPL.loading = false;
         newState.compareDataBUPL.rowData = newState.compareDataBUPL.defaultRowData;
         newState.compareDataBUPL.rowHeader = newState.compareDataBUPL.defaultRowHeader;
         return newState;
      case "FETCH_COMPARE_DATA_BUPL_SUCCESS":
         // newState.drilldownDetailState = action.data;
         newState.compareDataBUPL.loading = false;
         newState.compareDataBUPL.rowData = action.data.row_data;
         newState.compareDataBUPL.rowHeader = action.data.row_header;
         return newState;
      case "FETCH_COMPARE_DATA_BUPL_LOADER":
         // newState.drilldownDetailState = action.data;
         newState.compareDataBUPL.loading = action.loading;
         return newState;

      case "FETCH_COMPARE_FILTER_BUPL_LOADER":
         newState.compareDataBUPL.loading = action.loading;
         return newState;
      case "FETCH_COMPARE_FILTER_BUPL_SUCCESS":
         var { 
            plantArr, 
            countryArr, 
            radioArr, 
            periodArr, 
            buArr } = transformFilterData(action.data);
         newState.compareDataBUPL.filter.buArr = buArr;
         newState.compareDataBUPL.filter.periodArr = periodArr;
         newState.compareDataBUPL.filter.countryArr = countryArr;
         newState.compareDataBUPL.filter.plantArr = plantArr;
         return newState;

      case "FETCH_AUTOMATION_IS_SUCCESS":
         newState.automationHeaderData = action.data.row_header;
         newState.automationRowData = action.data.row_data;
         newState.loading = action.loading;
         if (action.data.filter) {

            var dateArr = (action.data.filter).map(x => {
               let datevar = new Date(x).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
               }).split(' ').join('-');

               if (datevar == "30-Sept-2021") {
                  datevar = "30-Sep-2021";
               }

               return datevar;
            })
            newState.arDateArray = dateArr;
         }
         newState.uploadLoading = action.uploadLoading ? action.uploadLoading : false
         return newState;

      case "BUPL_UNMOUNT_ACTION":
         newState.yearFilterArr = [];
         return newState;

      case "SET_AR_LATEST_MONTH":
         let x = action.data;
         newState.currentARMonth = x.replaceAll(' ', '-');
         return newState;

      case "FETCH_FUNCTIONAL_BUDGET_GRAPH_DATA_SUCCESS":
         newState.budgetAnalysis = action.data;
         newState.loading = action.loading;
         return newState;

      case "SET_COMMENT_PAYLOAD":
         newState.commentDataPayload = action.data;
         return newState;



      case "FETCH_new_PIE_LOADER":
         newState.NewLoader = action.loading;
         return newState;

      case "FETCH_new_PIE_SUCCESS":
         newState.NewLoader = false;
         newState.NewPieData = action.data;

      case "FETCH_SALES_TECHNOLOGY_PIE_LOADER":
         newState.salesTechnologyLoader = action.loading;
         return newState;

      case "POST_new_MANUAL_DATA_LOADER":
         newState.NewManualLoader = action.loading;
         return newState;

      case "POST_new_MANUAL_DATA_SUCCESS":
         newState.NewManualLoader = action.loading;
         return newState;

      case "POST_new_COMMENT_LOADER":
         newState.NewCommentLoader = action.loading;
         return newState;

      case "POST_new_ORIGINATION_COMMENT_LOADER":
         newState.NewOriginationCommentLoader = action.loading;
         newState.NewOriginationCommentData = {}
         return newState;

      case "POST_new_ORIGINATION_COMMENT_DATA_SUCCESS":
         newState.NewOriginationCommentLoader = action.loading;
         newState.NewOriginationCommentData = action.data;
         return newState;

      case "POST_new_COMMENT_CHART_LOADER":
         newState.NewCommentChartLoader = action.loading;
         return newState;

      case "POST_new_COMMENT_DATA_SUCCESS":
         newState.NewCommentLoader = action.loading;
         newState.NewCommentChartLoader = action.loading;
         return newState;

      case 'FETCH_SALES_TORNADO_DATA_MONTHLY_SUCCESS':
         newState.tornadoData = action.data;
         return newState

      case "FETCH_SALES_TECHNOLOGY_PIE_SUCCESS":
         newState.salesTechnologyLoader = false;
         newState.salesTechnologyPieData = action.data;


         return newState;
      case "FETCH_SERVICE_REVENUE_PROFITABILITY_DATA_SUCCESS":
         newState.loading = action.loading;
         newState.automationHeaderData = action.data.row_header;
         newState.automationRowData = action.data.row_data;

         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
         } = transformFilterData(action.data.filter);
         newState.qtrfilter = quarterfilterArr;
         newState.monthFilter = monthFilterArr;
         newState.yearFilter = yearFilterArr;
         newState.uploadLoading = action.uploadLoading ? action.uploadLoading : false
         return newState;

      case "FETCH_T_AND_E_ANALYSIS_DRILL_DOWN_LOADER":
         newState.invoiceLoading = action.loading;
         return newState;

      case "FETCH_T_AND_E_ANALYSIS_DRILL_DOWN_SUCCESS":
         newState.invoiceDetailState = action.data.current;
         newState.invoiceDetailStateFilter = action.data.filter;
         if (action.data2) {
            newState.invoiceDetailState.classification = action.data2.classification;
            newState.invoiceDetailState.countries = action.data2.countries;
         }
         newState.invoiceLoading = action.loading;
         if (
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "previous") ||
            (action.dataPayload &&
               action.dataPayload.sub_grid_header_value == "variance")
         ) {
            newState.invoiceDetailStatePrevious =
               action.data && action.data.previous;
         }
         return newState;
      case "FETCH_REVENUE_SUCCESS":
         newState.loading = action.loading;
         newState.pivotRowData = action.data.row_data;
         newState.countries = action.data.countries;
         newState.pivotHeaderData = action.data.row_header;
         newState.pivotRowData2 = action.data.row_data2;
         newState.pivotHeaderData2 = action.data.row_header2;
         newState.filterColumnArr = transformExcludeFilterData(
            action.data.metadata && action.data.metadata.base_header
         );
         var {
            monthFilterArr,
            quarterfilterArr,
            yearFilterArr,
            legalEntityArr,
            plantArr,
         } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.legalEntityArr = legalEntityArr;
         newState.plantArr = plantArr;
         return newState;
      case "FETCH_FUNCTION_DRILL_DOWN_REVENUE_SUCCESS":
         // newState.drilldownDetailState = action.current.data;
         newState.drilldownLoader = false;
         newState.drilldownRowHeader = action.data.current.row_header;
         newState.drilldownRowData = action.data.current.row_data;

         return newState;

      case "FETCH_ORDER_BOOK_DATA_LOADER":

         newState.orderBookData.loading = action.loading;
         return newState

      case "FETCH_ORDER_BOOK_DATA_SUCCESS":
         newState.orderBookData.loading = false;
         newState.orderBookData.rowHeader = action.data.row_header;
         newState.orderBookData.rowData = action.data.row_data;
         return newState


      case "FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS_LOADER":
         // newState.drilldownDetailState = action.current.data;
         newState.orderBookData.drilldown.loading = action.loading;

         return newState;

      case "FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS_SUCCESS":
         // newState.drilldownDetailState = action.current.data;
         newState.orderBookData.drilldown.loading = action.loading;
         newState.orderBookData.drilldown.row_header = action.data.row_header;
         newState.orderBookData.drilldown.row_data = action.data.row_data;

         return newState;


      case "FETCH_ORDER_BOOK_COMPARISON_LOADER":
         newState.orderBookData.comparison.loading = action.loading;
         return newState

      case "FETCH_ORDER_BOOK_COMPARISON_SUCCESS":

         newState.orderBookData.comparison.loading = false;
         newState.orderBookData.comparison.main.rowHeader = action.data.main.row_header;
         newState.orderBookData.comparison.main.rowData = action.data.main.row_data;

         newState.orderBookData.comparison.new.rowHeader = action.data.new.row_header;
         newState.orderBookData.comparison.new.rowData = action.data.new.row_data;

         newState.orderBookData.comparison.out.rowHeader = action.data.out.row_header;
         newState.orderBookData.comparison.out.rowData = action.data.out.row_data;

         if (action.data.slippage && action.data.change) {
            newState.orderBookData.comparison.slippage.rowHeader = action.data.slippage.row_header;
            newState.orderBookData.comparison.slippage.rowData = action.data.slippage.row_data;

            newState.orderBookData.comparison.change.rowHeader = action.data.change.row_header;
            newState.orderBookData.comparison.change.rowData = action.data.change.row_data;
         }

         newState.orderBookData.comparison.total.rowHeader = action.data.total.row_header;
         newState.orderBookData.comparison.total.rowData = action.data.total.row_data;
         return newState

      case "FETCH_DASS_AOP_REVENUE_LOADER":
         newState.dassAOPData.loading = action.loading;
         return newState

      case "FETCH_DASS_AOP_REVENUE_SUCCESS":
         newState.dassAOPData.by_bu = action.data.by_bu;
         newState.dassAOPData.by_geo = action.data.by_geo;
         newState.dassAOPData.loading = false;
         return newState

      case "FETCH_DASS_SUMMARY_LOADER":
         newState.dassAOPData.summary.loading = action.loading;
         return newState

      case "FETCH_DASS_SUMMARY_SUCCESS":
         newState.dassAOPData.summary.data = action.data;
         newState.dassAOPData.summary.loading = false;
         return newState

      case "FETCH_DASS_SUMMARY_TABLE_SUCCESS":
         newState.dassAOPData.summary.rowData = action.data.row_data || [];
         newState.dassAOPData.summary.rowHeader = action.data.row_header || [];
         newState.dassAOPData.summary.loading = false;
         return newState

      case "FETCH_PAB_BACKLOG_SUMMARY_LOADER":
         newState.dassAOPData.pabBacklog.loading = true;
         return newState

      case "FETCH_DASS_BY_GEO_LOADER":
         newState.dassAOPData.dassByGeo.loading = true;
         newState.dassAOPData.dassByGeo.rowData = [];
         newState.dassAOPData.dassByGeo.rowHeader = [];
         return newState


      case "FETCH_DASS_BY_GEO_LOADER_SUCCESS":
         newState.dassAOPData.dassByGeo.filters = action.data.filters || [];
         newState.dassAOPData.dassByGeo.rowData = action.data.row_data || [];
         newState.dassAOPData.dassByGeo.rowHeader = action.data.row_header || [];
         newState.dassAOPData.dassByGeo.loading = false;
         return newState

      case "FETCH_PAB_BACKLOG_SUMMARY_SUCCESS":
         newState.dassAOPData.pabBacklog.filters = action.data.filters || [];
         newState.dassAOPData.pabBacklog.rowData = action.data.row_data || [];
         newState.dassAOPData.pabBacklog.rowHeader = action.data.row_header || [];
         newState.dassAOPData.pabBacklog.loading = false;
         return newState

      case "FETCH_PAB_BACKLOG_DRILLDOWN_LOADER":
         newState.dassAOPData.pabBacklog.drilldown.loading = action.loading;

         return newState

      case "FETCH_DASS_BY_GEO_DRILLDOWN_LOADER":
         newState.dassAOPData.dassByGeo.drilldown.loading = action.loading;

         return newState

      case "FETCH_DASS_BY_GEO_DRILLDOWN_SUCCESS":
         newState.dassAOPData.dassByGeo.drilldown.row_data = action.data.row_data;
         newState.dassAOPData.dassByGeo.drilldown.row_header = action.data.row_header;
         newState.dassAOPData.dassByGeo.drilldown.loading = action.loading;
         return newState

      case "FETCH_PAB_BACKLOG_DRILLDOWN_SUCCESS":
         newState.dassAOPData.pabBacklog.drilldown.row_data = action.data.row_data;
         newState.dassAOPData.pabBacklog.drilldown.row_header = action.data.row_header;
         newState.dassAOPData.pabBacklog.drilldown.loading = action.loading;
         return newState



      case "FETCH_DASS_AOP_FRONTLOG_LOADER":
         newState.dassAOPData.frontlog.loading = action.loading;
         return newState

      case "FETCH_DASS_AOP_FRONTLOG_SUCCESS":
         newState.dassAOPData.frontlog.rowData = action.data.row_data;
         newState.dassAOPData.frontlog.rowHeader = action.data.row_header;
         newState.dassAOPData.frontlog.filters = action.data.filters;
         newState.dassAOPData.frontlog.loading = false;
         return newState

      case "FETCH_DASS_AOP_FRONTLOG_DRILLDOWN_LOADER":
         newState.dassAOPData.frontlog.drilldown.loading = action.loading;
         return newState

      case "FETCH_DASS_AOP_FRONTLOG_DRILLDOWN_SUCCESS":
         newState.dassAOPData.frontlog.drilldown.row_data = action.data.row_data;
         newState.dassAOPData.frontlog.drilldown.row_header = action.data.row_header;
         newState.dassAOPData.frontlog.drilldown.loading = action.loading;
         return newState

      case 'UPLOAD_EXCEL_FILE_LOADER':
         newState.saveLoading = action.loading;
         return newState;

      case 'UPLOAD_EXCEL_FILE_SUCCESS':
         newState.saveLoading = action.loading;
         newState.saveSuccess = true;
         newState.showError = action.showError;
         return newState;

      case 'UPLOAD_EXCEL_FILE_FAIL':
         newState.errorWhileSaving = action.errors;
         newState.showError = action.showError;
         newState.saveLoading = action.loading;
         return newState;

      default:
         return newState;
   }
};
export default reducer;