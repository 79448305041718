import React from 'react';
import 'date-fns';
import {connect} from 'react-redux';
import {useTheme} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import {Chip, Tooltip, Typography} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function DropdownComponent(props) {
  const {externalFilterObject} = props;
  let defaultCustomerState = externalFilterObject &&
  externalFilterObject.customer_name ? externalFilterObject.customer_name : [];
  const theme = useTheme();
  const [dateFilter, setDateFilter] = React.useState([]);
  const [customerName, setCustomerName] = React.useState(defaultCustomerState);
  const [commentGroup, setCommentGroup] = React.useState([]);
  const [actionOwner, setActionOwner] = React.useState([]);
  const [soldToCountry, setSoldToCountry] = React.useState([]);
  const [regionArr, setRegionArr] = React.useState([]);
  const [companyCode, setCompanyCodeArr] = React.useState([]);
  const [projectID, setProjectIDArr] = React.useState([]);
  const [agingCategory, setAgingCategory] = React.useState([]);

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  React.useEffect(() => {

    setTimeout(() => {
      if (props && props.externalFilterObject && props.externalFilterObject.customer_name && props.externalFilterObject.customer_name.length) {
        setCustomerName(props.externalFilterObject.customer_name)
      }
    },2000)
   

  },[])

  const handleCustomerChange = (event, value) => {
    setCustomerName(value);
    document.activeElement.blur();
  };

  const handleProjectChange = (event, value) => {
    setProjectIDArr(value);
    document.activeElement.blur();
  };

  const handleCompanyCodeChange = (event, value) => {
    setCompanyCodeArr(value);
    document.activeElement.blur();
  };

  const handleCommentChange = (event) => {
    setCommentGroup(event.target.value);
  };

  const handleActionChange = (event, value) => {
    setActionOwner(value);
    document.activeElement.blur();
  };

  const handleRegionChange = (event, value) => {
    setRegionArr(event.target.value);
  };

  const handleSoldChange = (event, value) => {
    setSoldToCountry(value);
    document.activeElement.blur();
  };

  const handleAgingChange = (event) => {
    setAgingCategory(event.target.value);

  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let dateFilterArr = [];
  let companyCodeArr = [];
  let projectIDArr = [];
  let customerNameArr = [];
  let commentGroupArr = [];
  let actionOwnerArr = [];
  let soldToCountryArr = [];
  let agingCategoryArr = [];
  let regionNameArr = [];

  if (props && props.externalfilterArr && props.externalfilterArr.length) {
    props.externalfilterArr.forEach(item => {
      switch (item.key) {
        case 'customer_name':
          customerNameArr = item.value;
          break;
        case 'company_code':
          companyCodeArr = item.value;
          break;
        case 'project_id':
          projectIDArr = item.value;
          break;
        case 'action_owner':
          actionOwnerArr = item.value;
          break;
        case 'sold_to_country':
            soldToCountryArr = item.value;
            break;
        case 'yearmonth':
          dateFilterArr = item.value;
          break;
        case 'aging_category':
          agingCategoryArr = item.value;
          break;
        case 'comment_group':
          commentGroupArr = item.value;
          break;
        case 'region':
          regionNameArr = item.value;
          break;
        default:
          break;
      }
    });
  }

  function getStyles(name, data, theme) {
    return {
      fontWeight:
          data && data.indexOf && data.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
    };
  }

  const handleApplyFilter = () => {
    props.updateActivePage(0);
    let act = JSON.parse(JSON.stringify(actionOwner));
    let sold = JSON.parse(JSON.stringify(soldToCountry));
    let cust = JSON.parse(JSON.stringify(customerName));

    let filterPayload = {
      action_owner: act.map(x => {
        return x.value;
      }),
      aging_category: agingCategory,
      category: '',
      yearmonth: dateFilter,
      company_code: companyCode,
      project_id: projectID,
      sold_to_country : sold.map(x => {
        return x.value;
      }),
      parent_group: props.parentGroup,
      receivableTabSelectorValue: props.receivableTabSelectorValue,
      customer_name: cust.map(x => {
        return x;
      }),
      region: regionArr,
      skip: 0,
      limit: 60,
    };
    props.handleApplyFilter(filterPayload);
  };

  const handleResetFilter = () => {
    props.updateActivePage(0);
    let filterPayload = {
      action_owner: [],
      aging_category: [],
      yearmonth: [],
      sold_to_country: [],
      company_code: [],
      region: [],
      category: '',
      parent_group: props.parentGroup,
      receivableTabSelectorValue: props.receivableTabSelectorValue,
      customer_name: [],
      skip: 0,
      limit: 60,
    };
    setDateFilter([]);
    setCustomerName([]);
    setCompanyCodeArr([]);
    setProjectIDArr([]);
    setCommentGroup([]);
    setActionOwner([]);
    setAgingCategory([]);
    setRegionArr([]);
    setSoldToCountry([]);
    props.handleApplyFilter(filterPayload);
  };

  const refreshFilter = () => {
    setDateFilter([]);
    setCustomerName([]);
    setCompanyCodeArr([]);
    setProjectIDArr([]);
    setCommentGroup([]);
    setActionOwner([]);
    setAgingCategory([]);
    setRegionArr([]);
    setSoldToCountry([]);
  };

  // const refreshCustomerNameFilter = () => {
  //     setCustomerName(props.externalFilterObject.customer_name)
  // }

  React.useEffect(() => {
    refreshFilter();
  }, [props.buValue, props.selectedMonthFilter]);
  let filter;

  if (props.buValue.toLowerCase() == 'pab' && (props.parentGroup == 'unbilled' || props.parentGroup == 'liability')) {
    filter = (<FormControl>
      <Autocomplete
          multiple
          limitTags={1}
          value={projectID}
          options={projectIDArr.sort()}
          getOptionLabel={(option) => option}
          onChange={handleProjectChange}
          renderInput={(params) => (
              <TextField
                  {...params}
                  variant="standard"
                  label="Project ID"
                  placeholder="Project ID"
              />
          )}
          getOptionSelected={(option, val) => option == val}
          renderTags={(value, {params, onDelete}) =>
              value.map((option, index) => (
                  <Tooltip title={option}>
                    <Chip
                        {...params}
                        key={option}
                        label={option.length > 5 ?
                            option.substr(0, 3) + '..' :
                            option}
                        size="small"
                        onDelete={onDelete}
                    />
                  </Tooltip>
              ))
          }
          renderOption={(option) => <Typography
              style={{fontSize: '10px'}}
              noWrap>
            <span>{option}</span>
          </Typography>}
      />

    </FormControl>);

  }

  // if (props.externalFilterObject) {
  //     React.useEffect(() => {
  //         refreshCustomerNameFilter();
  //     }, [props.externalFilterObject.customer_name]);
  // }
  let filters;
  let filters2;
  let filters3;

  if (props.buValue.toLowerCase() == 'pab' && (props.parentGroup == 'unbilled' || props.parentGroup == 'liability')) {
    filters = (<FormControl>
      <Autocomplete
          multiple
          limitTags={1}
          value={actionOwner}
          options={actionOwnerArr}
          getOptionLabel={(option) => option.label}
          onChange={handleActionChange}
          getOptionSelected={(option, val) => option.value == val.value}
          renderTags={(value, {params, onDelete}) =>
              value.map((option, index) => (
                  <Tooltip title={option.label}>
                    <Chip
                        {...params}
                        key={option.value}
                        label={option.label.length > 5 ?
                            option.label.substr(0, 3) + '..' :
                            option.label}
                        size="small"
                        onDelete={onDelete}
                    />
                  </Tooltip>
              ))
          }
          renderInput={(params) => (
              <TextField
                  {...params}
                  variant="standard"
                  style={{fontSize: '12px'}}
                  label="Project Manager"
                  placeholder="Project Manager"
              />
          )}
          renderOption={(option) => <Typography
              style={{fontSize: '12px'}}
              noWrap>
            <span>{option.label}</span>
          </Typography>}
      />
    </FormControl>);
  } else {
    filters = (<FormControl>
      <Autocomplete
          multiple
          limitTags={1}
          value={actionOwner}
          options={actionOwnerArr}
          getOptionLabel={(option) => option.label}
          onChange={handleActionChange}
          getOptionSelected={(option, val) => option.value == val.value}
          renderTags={(value, {params, onDelete}) =>
              value.map((option, index) => (
                  <Tooltip title={option.label}>
                    <Chip
                        {...params}
                        key={option.value}
                        label={option.label.length > 5 ?
                            option.label.substr(0, 3) + '..' :
                            option.label}
                        size="small"
                        onDelete={onDelete}
                    />
                  </Tooltip>
              ))
          }
          renderInput={(params) => (
              <TextField
                  {...params}
                  variant="standard"
                  style={{fontSize: '12px'}}
                  label="Action Owner"
                  placeholder="Action Owner"
              />
          )}
          renderOption={(option) => <Typography
              style={{fontSize: '12px'}}
              noWrap>
            <span>{option.label}</span>
          </Typography>}
      />
    </FormControl>);
    filters2 = (<FormControl>
      <InputLabel>Aging Category</InputLabel>
      <Select
          multiple
          value={agingCategory}
          onChange={handleAgingChange}
          input={<Input/>}
          MenuProps={MenuProps}
      >
        {agingCategoryArr.map((name) => (
            <MenuItem key={name} value={name}
                      style={getStyles(name, agingCategory, theme)}>
              {name}
            </MenuItem>
        ))}
      </Select>
    </FormControl>);
  }

    if (props.buValue == 'New BU 1' || props.buValue == 'New BU 2' || props.buValue == 'Unallocated' ) {
    filters3 = (<FormControl>
      <Autocomplete
          multiple
          limitTags={1}
          value={soldToCountry}
          options={soldToCountryArr}
          getOptionLabel={(option) => option.label}
          onChange={handleSoldChange}
          getOptionSelected={(option, val) => option.value == val.value}
          renderTags={(value, {params, onDelete}) =>
              value.map((option, index) => (
                  <Tooltip title={option.label}>
                    <Chip
                        {...params}
                        key={option.value}
                        label={option.label.length > 12 ?
                            option.label.substr(0, 7) + '..' :
                            option.label}
                        size="small"
                        onDelete={onDelete}
                    />
                  </Tooltip>
              ))
          }
          renderInput={(params) => (
              <TextField
                  {...params}
                  variant="standard"
                  style={{fontSize: '12px'}}
                  label="Sold To Country"
                  placeholder="Sold To Country"
              />
          )}
          renderOption={(option) => <Typography
              style={{fontSize: '12px'}}
              noWrap>
            <span>{option.label}</span>
          </Typography>}
      />
    </FormControl>);
  }else{
    filters3 = (
      <FormControl>
      <InputLabel>Region</InputLabel>
      <Select
          multiple
          value={regionArr}
          onChange={handleRegionChange}
          input={<Input/>}
          MenuProps={MenuProps}
      >
        {regionNameArr.map((name) => (
            <MenuItem key={name} value={name}
                      style={getStyles(name, regionArr.map(x => {
                        return x;
                      }), theme)}>
              {name}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
    )
  }

  return (
      <div className="ageingPageDropdown flex jc-flex-start ai-center mb-16">
        <FormControl>
          <Autocomplete
              multiple
              limitTags={1}
              value={companyCode}
              options={companyCodeArr.sort()}
              getOptionLabel={(option) => option}
              onChange={handleCompanyCodeChange}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="standard"
                      label="Company Code"
                      placeholder="Company Code"
                  />
              )}
              getOptionSelected={(option, val) => option == val}
              renderTags={(value, {params, onDelete}) =>
                  value.map((option, index) => (
                      <Tooltip title={option}>
                        <Chip
                            {...params}
                            key={option}
                            label={option.length > 5 ?
                                option.substr(0, 3) + '..' :
                                option}
                            size="small"
                            onDelete={onDelete}
                        />
                      </Tooltip>
                  ))
              }
              renderOption={(option) => <Typography
                  style={{fontSize: '10px'}}
                  noWrap>
                <span>{option}</span>
              </Typography>}
          />

        </FormControl>
        {filter}
        {/* <FormControl>
           <InputLabel>Year/Month</InputLabel>
           <Select
               multiple
               value={dateFilter}
               onChange={handleDateChange}
               input={<Input />}
               MenuProps={MenuProps}
           >
               {dateFilterArr.map((name) => (
                   <MenuItem key={name} value={name} style={getStyles(name, dateFilter, theme)}>
                       {name}
                   </MenuItem>
               ))}
           </Select>
       </FormControl> */}
        <FormControl>
          <Autocomplete
              multiple
              limitTags={1}
              value={customerName}
              options={customerNameArr.sort()}
              getOptionLabel={(option) => option}
              onChange={handleCustomerChange}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="standard"
                      label="Customer Name"
                      placeholder="Customer Name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        id: 'field-1',
                      }}
                  />
              )}
              getOptionSelected={(option, val) => option == val}
              renderTags={(value, {params, onDelete}) =>
                  value.map((option, index) => (
                      <Tooltip title={option}>
                        <Chip
                            {...params}
                            key={option}
                            label={option.length > 5 ?
                                option.substr(0, 3) + '..' :
                                option}
                            size="small"
                            onDelete={onDelete}
                        />
                      </Tooltip>
                  ))
              }
              renderOption={(option) => <Typography
                  style={{fontSize: '10px'}}
                  noWrap>
                <span>{option}</span>
              </Typography>}
          />
        </FormControl>
        {/* <FormControl>
          <InputLabel>Region</InputLabel>
          <Select
              multiple
              value={regionArr}
              onChange={handleRegionChange}
              input={<Input/>}
              MenuProps={MenuProps}
          >
            {regionNameArr.map((name) => (
                <MenuItem key={name} value={name}
                          style={getStyles(name, regionArr.map(x => {
                            return x;
                          }), theme)}>
                  {name}
                </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {/* <FormControl>
                <InputLabel>Improvement Category</InputLabel>
                <Select
                    multiple
                    value={commentGroup}
                    onChange={handleCommentChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="">
                        <em>Filter in progress. To be enabled soon.</em>
                    </MenuItem>
                    {commentGroupArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, commentGroup, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}
        {filters3}
        {filters}
        {filters2}
        <Button color="primary" variant="contained" className="mr-10 mt-5 newButton btnBlue btnRefresh uppercase"
                onClick={handleApplyFilter}>Refresh</Button>
        <Button color="primary" variant="contained" className="mt-5 newButton btnRed btnClear uppercase"
                onClick={handleResetFilter}>Clear</Button>
      </div>
  );
}

// export default DropdownComponent;

export default connect(null, (dispatch) => {
      return {
        updateActivePage(data) {
          dispatch({
            type: 'UPDATE_ACTIVE_PAGE_EXTERNAL',
            payload: data,
          });
        },
      };
    },
)(DropdownComponent);
