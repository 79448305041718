import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format, parseJSON } from "date-fns";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js';
import { numberWithCommas } from './numberUtils'
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export async function downloadExcelV1(options) {
  let col = options.columns;
  let dataType = options.dataType;
  let alignment = options.alignment;
  let merge = options.merge;
  let data = options.data;
  let font = options.font;
  let fileName = options.fileName;
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet()
  let header = col.map((x, idx) => {
    let y = {}
    y.style = {};
    y.header = x;
    
    y.style.alignment = {"horizontal":"center"}
    if (dataType[idx] == 'number') {
      y.style.numFmt = "#,##0;(#,##0)"
    }
    return y
  })
  
  ws.columns = header;
  header.forEach((x, idx) => {
    
    let alph = (idx + 10).toString(36).toUpperCase()
    // console.log(idx, x, "testColumn1")
    ws.getCell(alph + "1").alignment = {"horizontal":"center"}
    ws.getCell(alph + "1").font = { bold : true }

    // console.log(, "testColumn2")
  })
  if (data.length > 0) {
    
    data.forEach((x, idx) => {
        ws.addRow(x);

        for (var key in alignment) { // set alignment, by column
          ws.getCell(alphabet[key] + (idx+2)).alignment = alignment[key]
          // console.log(alphabet[key] + (idx+2) , "ok gaes")
        }
    })
  }

  //set auto width
  for (let i = 0; i < ws.columns.length; i += 1) {
    let dataMax = 0;
    const column = ws.columns[i];
    
    for (let j = 1; j < column.values.length; j += 1) {
      const columnLength = column.values[j] != undefined?  column.values[j].length : 0;
      if (columnLength > dataMax) {
        dataMax = columnLength;
      }
    }
    column.width = dataMax < 10 ? 15 : (dataMax + 5);
  }

  if(merge != undefined){ // set merged cells
    merge.forEach(x=> {
      ws.mergeCells(alphabet[x.colStart] + (x.rowStart +2) + ":" + alphabet[x.colFinish] + (x.rowFinish +2))
    })

      merge.forEach(x=> {
        ws.getCell(alphabet[x.colStart] + (x.rowStart +2)).alignment =  { vertical: 'top', horizontal: 'left' };
      })
  }

  if(font != undefined){ // set font
    font.forEach(x=> {
      ws.getCell(alphabet[x.column] + (x.row +2)).font =  x.font
    })
  }


  const buf = await wb.xlsx.writeBuffer()
  fileName = "xP&A Demo - " + fileName + format(new Date, " - MM-dd-yyyy HH-mm-ss") + fileExtension;
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension)
}

export const pdfFileName = (name) => {
  name = "xP&A Demo - " + name + format(new Date, " - MM-dd-yyyy HH-mm-ss");
  return name;
}

export const ExportPdf = (excelData, excelDataComparison, radioSelectorValue) => {
}

export const downloadExcel = (csvData, fileName, header, isTitleCase, merge, headerDataType, sheetName) => {
  isTitleCase = isTitleCase == undefined ? true : isTitleCase;
  headerDataType = headerDataType == undefined ? [] : headerDataType;
  // csvData = replaceZeroorBlank(csvData)
  const ws = XLSX.utils.json_to_sheet([], { header: header });
  XLSX.utils.sheet_add_json(ws, csvData, { skipHeader: true, origin: "A2" });
  ws["!cols"] = autofitColumns(csvData)
  if (merge != undefined && merge.length > 0)
    ws["!merges"] = merge;
  /* new format */
  var fmt = "#,##0;(#,##0)";
  /* change cell format to numeric */
  var range = { s: { r: 0, c: 0 }, e: { r: csvData.length, c: header.length - 1 } };
  for (var R = range.s.r; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
      var curType = headerDataType[C]
      // console.log(curType,headerDataType,C);
      if (!cell || cell.t != 'n' || (curType != 'number' && curType != undefined)) continue; // only format numeric cells
      cell.z = fmt;
    }
  }
  sheetName = sheetName == undefined ? 'Data' : sheetName;
  const sheetNamez = { [sheetName]: ws };
  const wb = { Sheets: sheetNamez, SheetNames: [sheetName] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  fileName = "xP&A Demo - " + fileName + format(new Date, " - MM-dd-yyyy HH-mm-ss") + fileExtension;
  fileName = isTitleCase ? toTitleCase(fileName) : fileName
  FileSaver.saveAs(data, fileName);
}

export const downloadExcelMultiSheet = (csvDataArr, fileName, isTitleCase) => {
  isTitleCase = isTitleCase == undefined ? true : isTitleCase;
  let sheets = {}
  let allsheetName = []
  csvDataArr.forEach((data) => {
    let csvData = data.data;
    let header = data.header;
    let sheetName = data.sheetName;
    let merge = data.merge;
    let headerDataType = data.headerDataType;
    headerDataType = headerDataType == undefined ? [] : headerDataType;
    csvData = replaceZeroorBlank(csvData)
    const ws = XLSX.utils.json_to_sheet([], { header: header });
    XLSX.utils.sheet_add_json(ws, csvData, { skipHeader: true, origin: "A2" });
    ws["!cols"] = autofitColumns(csvData)
    if (merge != undefined && merge.length > 0)
      ws["!merges"] = merge;
    /* new format */
    var fmt = "#,##0;(#,##0)";
    /* change cell format to numeric */
    var range = { s: { r: 0, c: 0 }, e: { r: csvData.length, c: header.length - 1 } };
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        var curType = headerDataType[C]
        // console.log(curType,headerDataType,C);
        if (!cell || cell.t != 'n' || (curType != 'number' && curType != undefined)) continue; // only format numeric cells
        cell.z = fmt;
      }
    }
    sheets[sheetName] = ws;
    allsheetName.push(sheetName)
  })
  const wb = { Sheets: sheets, SheetNames: allsheetName };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  fileName = "xP&A Demo - " + fileName + format(new Date, " - MM-dd-yyyy HH-mm-ss") + fileExtension;
  fileName = isTitleCase ? toTitleCase(fileName) : fileName
  FileSaver.saveAs(data, fileName);
}

export const mapExcelHeader = (header, field) => {
  return header.map(x => {
    return x[field]
  })
}
export const mapExcelHeaderComparativeAutomated = (header, field) => {
  let head = header.map((x) => {
    console.log(x, "checkSplit")
    return x["Header"] +"|"+x['columns']?.length
  })
  let newHead = [];
  let newMerge = [];
  let lastColumnIdx = 1
  let endColumn = 0
  let pl = 0
  let i = 0
  head.forEach((el, idx) => {
    console.log(el, "checkSplit")
    let val = el.split("|")
    console.log(val[0], "checkSplit")
    console.log(val[1], "checkSplit")
    newHead.push(val[0])

    // if (idx > 0 && idx < 5 ) {
     
     
    //  for(let i=0; i<val[1]-1;i++){

    //   newHead.push("")
    //  }
    console.log(val[0], val[1], "testidx")
    
      // if (val[0] === "NA"){
      pl = parseInt(val[1])
     
      // newHead.push("")
      // }else if (val[0] === "LAR"){
      //   pl = 1
      // }else if (val[0] === "EMEA"){
      //   pl = 6
      //   newHead.push("")
      //   newHead.push("")
      //   newHead.push("")
      //   newHead.push("")
      //   newHead.push("")
      // }else if (val[0] === "APAC"){
      //   pl = 2
      //   newHead.push("")
      // }
      if( i > 0){
        for(let z=0;z < pl-1;z++){
          newHead.push("")
        }
        newMerge.push({ s: { r: 0, c: lastColumnIdx }, e: { r: 0, c: endColumn +(pl)} })
        endColumn += pl
        lastColumnIdx += pl
      }
    
      i ++
    // }
  });
  return { data: newHead, merge: newMerge }
}
export const mapExcelHeaderComparativeST = (header, field) => {
  let head = header.map((x) => {
    return x[field] +"|"+x['columns'].length
  })
  let newHead = [];
  let newMerge = [];
  let lastColumnIdx = 1
  let endColumn = 0
  let pl = 0
  head.forEach((el, idx) => {
    
    let val = el.split("|")
    console.log(val[0], "checkSplit")
    console.log(val[1], "checkSplit")
    newHead.push(val[0])

    if (idx > 0 && idx < 5 ) {
     
     
    //  for(let i=0; i<val[1]-1;i++){

    //   newHead.push("")
    //  }
    console.log(idx, "testidx")
    
      if (val[0] === "NA"){
      pl = 2
      newHead.push("")
      }else if (val[0] === "LAR"){
        pl = 1
      }else if (val[0] === "EMEA"){
        pl = 6
        newHead.push("")
        newHead.push("")
        newHead.push("")
        newHead.push("")
        newHead.push("")
      }else if (val[0] === "APAC"){
        pl = 2
        newHead.push("")
      }
      newMerge.push({ s: { r: 0, c: lastColumnIdx }, e: { r: 0, c: endColumn +(pl)} })
      endColumn += pl
      lastColumnIdx += pl
    }
  });
  return { data: newHead, merge: newMerge }
}

export const mapExcelHeaderComparative = (header, field) => {
  let head = header.map((x) => {
    return x[field]
  })
  let newHead = [];
  let newMerge = [];
  let lastColumnIdx = 1
  head.forEach((el, idx) => {
    newHead.push(el)

    if (idx > 0) {
      newHead.push("")
      newHead.push("")
      newMerge.push({ s: { r: 0, c: lastColumnIdx }, e: { r: 0, c: idx * 3 } })
      lastColumnIdx += 3
    }
  });
  return { data: newHead, merge: newMerge }
}

export const mapPDFHeaderComparative = (header, field) => {
  let head = header.map((x) => {
    return x[field]
  })
  let newHead = [];
  let newMerge = [];
  let lastColumnIdx = 1
  head.forEach((el, idx) => {
    newHead.push(el, "this EL")
    if (idx > 0) {
      // newHead.push("")
      // newHead.push("")
      newMerge.push({ s: { r: 0, c: lastColumnIdx }, e: { r: 0, c: idx * 3 } })
      lastColumnIdx += 3
    }
  });
  return { data: newHead, merge: newMerge }
}

export const mapExcelData = (data, header, field) => {
  field = field == undefined ? "key" : field
  data = data.map(x => {
    let newX = {};
    header.map(z => {
      if(x[z[field]] == undefined){
        newX[z[field]] = ""
      } else if(Array.isArray(x[z[field]])) {
        console.log("found array !!", x[z[field]])
        newX[z[field]] = x[z[field]].join()
      } else {
        newX[z[field]] = x[z[field]] 
      }
    })
    return newX
  })
  return data
}
export const mapExcelDataUsers = (data, header, field) => {
  field = field == undefined ? "key" : field
  data = data.map(x => {
    let newX = {};
    header.map(z => {
     
      if(z["id"] == "budgetgroup" &&  x["budgetgroup"] != undefined){
        let budgetGroup = x["budgetgroup"]
        if(budgetGroup.length > 1){
          let bg = ""
          let i = 1
          budgetGroup.map(b => {
            if(i == 1){
              bg = b[0]["Value"] + ", "
            }else if(i > 1 && i < budgetGroup.length){
              bg = bg + b[0]["Value"] + ", "
            }else if(i ==budgetGroup.length) {
              bg =  bg +  b[0]["Value"] 

            }
         i++
          })
          newX[z[field]] = bg
          // console.log(x["name"], bg, "checkHeader")
        }else{
          newX[z[field]] = budgetGroup[0][0]["Value"]
        }
          
      
      }else if(z["id"].includes("New") == true &&  x["onesensiaaccess"] != undefined){
      
        let keyone = ""
        if(z["id"] == "Newhemisphere"){
          keyone = "hemisphere"
        }else if(z["id"] == "Newgroup"){
          keyone = "group"
        }else if(z["id"] == "Newregion"){
          keyone = "region"
        }
        let NewAcc = x["onesensiaaccess"]
       
        if (NewAcc[keyone] != undefined) {
          // console.log(z["id"],  NewAcc[keyone], "masuksini")
          if(NewAcc[keyone].length > 1){
            let bg = ""
            let i = 1
            NewAcc[keyone].map(b => {
              if(i == 1){
                bg = b["text"] + ", "
              }else if(i > 1 && i < NewAcc[keyone].length){
                bg = bg + b["text"] + ", "
              }else if(i ==NewAcc[keyone].length) {
                bg =  bg +  b["text"] 
  
              }
           i++
            })
            newX[z[field]] = bg
            // console.log(x["name"], bg, "checkHeader")
          }else if(NewAcc[keyone].length == 1){
            // console.log(z["id"],  NewAcc[keyone][0]["text"], "masuksini")
            newX[z[field]] = NewAcc[keyone][0]["text"]
            if(keyone == "hemisphere"){
              if(NewAcc[keyone][0]["region"]!= undefined){
                let bg = ""
                let i = 1
                NewAcc[keyone][0]["region"].map(b => {
                  if(i == 1){
                    bg = b["text"] + ", "
                  }else if(i > 1 && i < NewAcc[keyone][0]["region"].length){
                    bg = bg + b["text"] + ", "
                  }else if(i ==NewAcc[keyone][0]["region"].length) {
                    bg =  bg +  b["text"] 
      
                  }
               i++
                })
                newX["Newregion"] = bg
              }
            }
           
          }
       }
       
       if (keyone == "region") {
        if (NewAcc["hemisphere"] != undefined) {
          if (NewAcc["hemisphere"][0]["region"] == undefined) {
            newX["Newregion"] = "-"
          }
        }else{
          newX["Newregion"] = "-"
        }
      
      }
      
      }else if(z["id"].includes("salestech") == true &&  x["salesandtechnologiesaccess"] != undefined){
        let keyone = ""
        if(z["id"] == "salestechbu"){
          keyone = "bu"
        }else if(z["id"] == "salestechgroup"){
          keyone = "group"
        }
        let NewAcc = x["salesandtechnologiesaccess"]
       
        if (NewAcc[keyone] != undefined) {
          // console.log(z["id"],  NewAcc[keyone], "masuksini")
          if(NewAcc[keyone].length > 1){
            let bg = ""
            let i = 1
            NewAcc[keyone].map(b => {
              if(b["text"]== "MSB"){
                b["text"]= "BU 1"
              }
              if(i == 1){
                
                bg = b["text"] + ", "
              }else if(i > 1 && i < NewAcc[keyone].length){
                bg = bg + b["text"] + ", "
              }else if(i ==NewAcc[keyone].length) {
                bg =  bg +  b["text"] 
  
              }
           i++
            })
            newX[z[field]] = bg
            console.log(x["name"], bg, "checkHeader")
          }else if(NewAcc[keyone].length == 1){
            if(NewAcc[keyone][0]["text"]== "MSB"){
              NewAcc[keyone][0]["text"]= "BU 1"
            }
            // console.log(z["id"],  NewAcc[keyone][0]["text"], "masuksini")
            newX[z[field]] = NewAcc[keyone][0]["text"]
          }
       }
      
      }else{

        newX[z[field]] = x[z[field]] == undefined ? "" : x[z[field]]
      }
    })
    return newX
  })
  return data
}
export const mapExcelDataPayment = (data, header, field) => {
  field = field == undefined ? "key" : field
  data = data.map(x => {
    let newX = {};
  
    header.map(z => {
      console.log(z["key"], "checkData")
      var n = z[field].includes("automation");
      if( n == true ){
        let splitStr = z[field].split(".");
        console.log(  x[splitStr[0]][splitStr[1]], "test123")
        let coldata = x[splitStr[0]][splitStr[1]]
        if (z[field] == "automation.created_date") {
         
             coldata = format(parseJSON(x[splitStr[0]][splitStr[1]]), 'dd MMM yyyy')
          
        }
       
        newX[z[field]] = coldata == undefined ? "" :  coldata
      }else{
        let coldata =x[z[field]]
        if (z[field]== "report_date") {
             coldata = format(parseJSON(x[z[field]]), 'dd MMM yyyy')
            
        }
        newX[z[field]] = coldata == undefined ? "" : coldata
      }
      
    })
    return newX
  })
  return data
}
export const mapExcelDataVariance = (data, header, field) => {
  console.log(field, "thisisnewField")
  field = field == undefined ? "key" : field
  console.log(field, "thisisnewField")
  data = data.map(x => {
    let newX = {};
    header.map(z => {
      newX[z[field]] = x[z[field]] == undefined ? "" : x[z[field]]
    })
    console.log(newX, "thisisnewX")
    return newX
  })
  console.log(data, "thisisdata")
  return data
}

export const handleDifferentBUResponse = (data, key) => {
  if (data) {
    if (key == 'data_field' || key == 'grand_total') {
      return data;
    } else {
      return data['header_key_value'] ? data['header_key_value'] : data
    }
  }
  return data
}
export const mapDataPDF = (data, field, pivotHeaderData) => {

  field = field == undefined ? "key" : field
  data = data.map(x => {
    let newX = [];
    // console.log(header, "this is x")
    // console.log(x, "testDatakey")
    pivotHeaderData.map(z => {
      let datas
    
      if (z.operation && z.key != "comment") {
       
        let modifiedData;
        let neOriginal = handleDifferentBUResponse(x, z.key)
        let columnData = neOriginal[z.key]
        
       
        switch (z.operation) {
          case 0:
            modifiedData = z.key;
            break;
          case 1:
            modifiedData = Math.round(columnData)
            break;
          case 2:
            modifiedData = numberWithCommas(columnData)
            break;
          case 4:
            modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
            modifiedData = modifiedData == '0' ? '' : modifiedData
            break;
          case 3:
            modifiedData = Math.round(columnData)
            modifiedData = numberWithCommas(modifiedData)
            break;
          case 5:
            modifiedData = Math.round(columnData)
            modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
            modifiedData = modifiedData == '0' ? '' : modifiedData
            break;
          case 6:
            var isNegative = columnData < 0
            var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            modifiedData = modifiedData == '0' ? '' : modifiedData
            break;
          case 7:
            modifiedData = Math.round(columnData)
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            break;
          default:
            break;
        }
        if (x.operations == "percentage") {
          if (modifiedData == "NaN") {
            datas = "-%"
          }else{
            datas = modifiedData +"%"
          }
         
        }else{
          datas = modifiedData
        }

       
      } else {
        if(x[z.key] == "BU 1"){
          x[z.key] = "BU 1"
        }
        datas = x[z.key] == undefined ? "-" : x[z.key]
      }
      if (datas == 'NaN' || datas == '') {
        datas = '-'
      }
      newX.push(datas);
    })

    return newX
  })
  return data
}

export const mapDataPDFAS = (data, field, header) => {

  field = field == undefined ? "key" : field
  data = data.map(x => {
    let newX = [];
    header.map(z => {
      let datas
      if(z.sub_headers && z.sub_headers.length > 0){
          z.sub_headers.forEach( y => {
            let newHeader  = {}
            newHeader["key"] = z.key + "|" + y.key
            newHeader["operation"] = y.operation
            datas = checkOperation(x,newHeader)
            newX.push(datas);
          })
      }else{
        datas = checkOperation(x,z)
        newX.push(datas);
      }

      
    })

    return newX
  })
  return data
}

const checkOperation = (x,z) =>{
      let datas
    if (z.operation && z.key != "comment") {
       
    let modifiedData;
    let neOriginal = handleDifferentBUResponse(x, z.key)
    let columnData = neOriginal[z.key]
    
   
    switch (z.operation) {
      case 0:
        modifiedData = z.key;
        break;
      case 1:
        modifiedData = Math.round(columnData)
        break;
      case 2:
        modifiedData = numberWithCommas(columnData)
        break;
      case 4:
        modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
        modifiedData = modifiedData == '0' ? '' : modifiedData
        break;
      case 3:
        modifiedData = Math.round(columnData)
        modifiedData = numberWithCommas(modifiedData)
        break;
      case 5:
        modifiedData = Math.round(columnData)
        modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '' : modifiedData
        break;
      case 6:
        var isNegative = columnData < 0
        var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '' : modifiedData
        break;
      case 7:
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        break;
      default:
        break;
    }
    if (x.operations == "percentage") {
      if (modifiedData == "NaN") {
        datas = "-%"
      }else{
        datas = modifiedData +"%"
      }
     
    }else{
      datas = modifiedData
    }
  } else {
    datas = x[z.key] == undefined ? "-" : x[z.key]
  }

  if (datas == 'NaN' || datas == '') {
    datas = '-'
  }

  return datas
}

export const mapExcelDataV1 = (data, header, field, dataType) => {
  field = field == undefined ? "key" : field;
  dataType = dataType == undefined ? {} : dataType;
  let newData = data.map(x => {
    let newX = [];
    header.map((z, idx) => {
      let val = x[z[field]] == undefined ? "" : x[z[field]];

      let dt = dataType[idx]
      if (dt == 'date') {//convert to date
        try {
          val = format(parseJSON(val), "dd MMM yyyy")
        } catch (e) {
          console.log(e)
        }
      }

      newX.push(val)
    })
    return newX
  })
  return newData
}

const unwindHeaderKeyVal = (data) => {
  var obj = data.header_key_value
  if (obj != undefined) {
    for (var prop in obj) {
      data[prop] = obj[prop]
    }
  }
  return data
}

export const unwindSubColumn = (data) => {
    data.forEach(x => {
      var obj = x.sub_column
      if (obj != undefined) {
        for (var key in obj) {
          let objSub = obj[key].sub_column
          if (objSub != undefined) {
            for (var keyIn in objSub) {
              x[key + "|" + keyIn] = objSub[keyIn]
            }
          }
        }
      }
    })
    return data
}

const unwindHeaderPLKeyVal = (data) => {
  var obj = data
  if (obj != undefined) {
    for (var prop in obj) {
      data[prop] = obj[prop]
    }
  }
  return data
}
export const mapExcelDataComparativePlant = (data, header, field) => {
  field = field == undefined ? "key" : field
  let subHeader = [{ key: "current", label: "Destination" },
  { key: "previous", label: "Origination" },
  { key: "variance", label: "Variance" }]

  data = data.map(x => {
    let newX = {};
    let subCol = x.sub_column;


    header.map(z => {

      if (z.key == "data_field") {
        newX["data_field"] = subCol.current.data_field
      } else {
        subHeader.forEach(y => {
          subCol[y.key] = unwindHeaderKeyVal(subCol[y.key])
          // console.log(subCol,"subcoll")
          newX[z.key + "|" + y.key] = subCol[y.key][z.key] == undefined ? "" : subCol[y.key][z.key]
        })
      }

    })
    return newX
  })

  let headerData = {};
  header.forEach(x => {
    if (x.key == "data_field") {
      headerData["data_field"] = ""
    } else {
      subHeader.forEach(y => {
        headerData[x.key + "|" + y.key] = y.label;
      })
    }
  })

  data.unshift(headerData);
  // console.log(data, "data excel")
  return data
}
export const mapExcelDataComparative = (data, header, field) => {
  field = field == undefined ? "key" : field
  let subHeader = [{ key: "current", label: "Current" },
  { key: "previous", label: "Last" },
  { key: "variance", label: "Variance" }]

  data = data.map(x => {
    let newX = {};
    let subCol = x.sub_column;


    header.map(z => {

      if (z.key == "data_field") {
        newX["data_field"] = subCol.current.data_field
      } else {
        subHeader.forEach(y => {
          subCol[y.key] = unwindHeaderKeyVal(subCol[y.key])
          // console.log(subCol,"subcoll")
          newX[z.key + "|" + y.key] = subCol[y.key][z.key] == undefined ? "" : subCol[y.key][z.key]
        })
      }

    })
    return newX
  })

  let headerData = {};
  header.forEach(x => {
    if (x.key == "data_field") {
      headerData["data_field"] = ""
    } else {
      subHeader.forEach(y => {
        headerData[x.key + "|" + y.key] = y.label;
      })
    }
  })

  data.unshift(headerData);
  // console.log(data, "data excel")
  return data
}
export const dynamicHeaderKeyOfColumn = (item, index) => {
  if (item && item.sub_headers && item.sub_headers.length) {
    let data = item.sub_headers[index] ? item.sub_headers[index].key : item.key
    return data
  }
  return item.key
}
export const mapPDFDataComparative = (data, header, field, pivotHeaderData) => {
  field = field == undefined ? "key" : field
  let subHeader = [{ key: "current", label: "Current" },
  { key: "previous", label: "Last" },
  { key: "variance", label: "Variance" }]

  data = data.map(x => {
    let newX = [];
    let subCol = x.sub_column;


    pivotHeaderData.map(z => {
      let datas

      if (z.operation) {
        console.log(subCol)
        let i = 0;
        subHeader.forEach(y => {
          let modifiedData;
          let columnData = x.sub_column[dynamicHeaderKeyOfColumn(z, i)]
          columnData = columnData ? columnData[z.key] : columnData
          switch (z.operation) {
            case 0:
              modifiedData = z.key;
              break;
            case 1:
              modifiedData = Math.round(columnData)
              break;
            case 2:
              modifiedData = numberWithCommas(columnData)
              break;
            case 4:
              modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 3:
              modifiedData = Math.round(columnData)
              modifiedData = numberWithCommas(modifiedData)
              break;
            case 5:
              modifiedData = Math.round(columnData)
              modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 6:
              var isNegative = columnData < 0
              var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 7:
              modifiedData = Math.round(columnData)
              var isNegative = modifiedData < 0
              var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            default:
              break;
          }
          datas = modifiedData;
          if (datas == 'NaN') {
            datas = '-'
          }
          newX.push(datas);
          i++
        })

      } else {
        datas = subCol.current.data_field
        if (datas == 'NaN') {
          datas = '-'
        }
        newX.push(datas);
      }

    })
    return newX
  })


  let headerData = [];
  header.forEach(x => {
    let datas = ""
    if (x.key == "data_field") {
      datas = ""
      headerData.push(datas);
    } else {
      subHeader.forEach(y => {
        datas = y.label
        headerData.push(datas);
      })
    }
  })
  // data.unshift(headerData);
  return { data: data, header: headerData }
}
export const mapPDFDataComparativePlant = (data, header, field, pivotHeaderData) => {
  field = field == undefined ? "key" : field
  let subHeader = [{ key: "current", label: "Current" },
  { key: "previous", label: "Last" },
  { key: "variance", label: "Variance" }]

  data = data.map(x => {
    let newX = [];
    let subCol = x.sub_column;


    pivotHeaderData.map(z => {
      let datas

      if (z.operation) {
        console.log(subCol)
        let i = 0;
        subHeader.forEach(y => {
          let modifiedData;
          let columnData = x.sub_column[dynamicHeaderKeyOfColumn(z, i)]
          columnData = columnData ? columnData[z.key] : columnData
          switch (z.operation) {
            case 0:
              modifiedData = z.key;
              break;
            case 1:
              modifiedData = Math.round(columnData)
              break;
            case 2:
              modifiedData = numberWithCommas(columnData)
              break;
            case 4:
              modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 3:
              modifiedData = Math.round(columnData)
              modifiedData = numberWithCommas(modifiedData)
              break;
            case 5:
              modifiedData = Math.round(columnData)
              modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 6:
              var isNegative = columnData < 0
              var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 7:
              modifiedData = Math.round(columnData)
              var isNegative = modifiedData < 0
              var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            default:
              break;
          }
          datas = modifiedData;
          if (datas == 'NaN') {
            datas = '-'
          }
          newX.push(datas);
          i++
        })

      } else {
        datas = subCol.current.data_field
        if (datas == 'NaN') {
          datas = '-'
        }
        newX.push(datas);
      }

    })
    return newX
  })


  let headerData = [];
  header.forEach(x => {
    let datas = ""
    if (x.key == "data_field") {
      datas = ""
      headerData.push(datas);
    } else {
      subHeader.forEach(y => {
        datas = y.label
        headerData.push(datas);
      })
    }
  })
  // data.unshift(headerData);
  return { data: data, header: headerData }
}
export const dynamicHeaderKeyColumn = (item, index) => {
  if (item && item.sub_column && item.sub_column.length) {
    let data = item.sub_column[index] ? item.sub_column[index].key : item.key
    return data
  }
  return item.key
}
export const mapPDFDataPLComparative = (data, header, field, pivotHeaderData) => {
  field = field == undefined ? "key" : field
  //   let subHeader = [{key: "total_expense", label: "Total Expense"},
  //   {key: "tsa", label: "TSA"}
  // ]
  let subHeader = [{ key: "actual", label: "ACTUALS" },
  { key: "budget", label: "BUDGETS" },
  { key: "compare", label: "B(W) THAN Budget" }]

  data = data.map(x => {
    let newX = [];
    let subCol = x.sub_column;


    pivotHeaderData.map(z => {
      let datas

      if (z.operation && z.key != 'comments') {

        let i = 0;
        subHeader.forEach(y => {
          console.log(subCol, "testZzsubCol")
          console.log(z, "testZz")
          console.log(i, "testZz1")
          
          let modifiedData;
          let columnData = subCol[dynamicHeaderKeyColumn(z, i)]
          console.log(columnData, "testZzcolumnData")
          console.log("testZz>>>>>>>>>")
          columnData = columnData ? columnData[y.key] : columnData
          switch (z.operation) {
            case 0:
              modifiedData = z.key;
              break;
            case 1:
              modifiedData = Math.round(columnData)
              break;
            case 2:
              modifiedData = numberWithCommas(columnData)
              break;
            case 4:
              modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 3:
              modifiedData = Math.round(columnData)
              modifiedData = numberWithCommas(modifiedData)
              break;
            case 5:
              modifiedData = Math.round(columnData)
              modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 6:
              var isNegative = columnData < 0
              var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            case 7:
              modifiedData = Math.round(columnData)
              var isNegative = modifiedData < 0
              var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
              break;
            default:
              break;
          }

          datas = modifiedData;
          if (datas == 'NaN' || datas == '') {
            datas = '-'
          }
          newX.push(datas);
          i++
        })

      } else if (z.key == 'comments') {
        datas = x.comment
        newX.push(datas);

      } else {
        datas = x.data_field
        if (datas == 'NaN' || datas == '') {
          datas = '-'
        }
        newX.push(datas);
      }

    })
    return newX
  })


  let headerData = [];
  header.forEach(x => {
    let datas = ""
    if (x.key == "budget_group") {
      datas = ""
      headerData.push(datas);
    } else {
      subHeader.forEach(y => {
        datas = y.label
        headerData.push(datas);
      })
    }
  })
  // data.unshift(headerData);
  return { data: data, header: headerData }
}

export const mapPDFDataPLComparativeST = (data, header, field, pivotHeaderData) => {
  field = field == undefined ? "key" : field
  //   let subHeader = [{key: "total_expense", label: "Total Expense"},
  //   {key: "tsa", label: "TSA"}
  // ]
  let subHeader =[{key: "USA", label: "USA"},
  {key: "Canada", label: "Canada"},
  {key: "Mexico", label: "Mexico"},
  {key: "UK", label: "UK"},
  {key: "Italy", label: "Italy"},
  {key: "Oman", label: "Oman"},
  {key: "Kuwait", label: "Kuwait"},
  {key: "UAE (SLB)", label: "UAE"},
  {key: "Netherlands", label: "Netherlands"},
  {key: "China", label: "China"},
  {key: "India", label: "India"},
  {key: "grand_total", label: "Grand Total"},
]
console.log(pivotHeaderData, "testZz")
  data = data.map(x => {
    let newX = [];
    let subCol = x.header_key_value;


    pivotHeaderData.map(z => {
      let datas

      if (z.operation && z.key != 'comments') {

        let i = 0;
        let gt = 0
        subHeader.forEach(y => {
          // console.log(subCol, "testZzcolumnData1")
          // console.log(z.sub_headers, "testZzcolumnData2")
          let modifiedData;
         
          // let columnData = subCol[dynamicHeaderKeyColumn(z.sub_headers, i)]
          let columnData = subCol[y.key]
         
          if (y.key != "grand_total" ){
            console.log(y.key, columnData,"grandTotal")
            if(columnData != undefined){

              gt+= columnData
            }
          }else{
            console.log(gt,"grandTotal")
            columnData = gt
          }
          // columnData = columnData ? columnData[y.key] : columnData
          // console.log(columnData, "testZzcolumnData3")
     
              modifiedData = Math.round(columnData)
              var isNegative = modifiedData < 0
              var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
              modifiedData = numberWithCommas(positiveTransformedValue)
              modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
              modifiedData = modifiedData == '0' ? '' : modifiedData
        

          datas = modifiedData;
          if (datas == 'NaN' || datas == '') {
            datas = '-'
          }
          // if(i < 11){
          newX.push(datas);
          // }
          i++
        })

      }else{
        datas = x.data_field
        if (datas == 'NaN' || datas == '') {
          datas = '-'
        }
        newX.push(datas);
      }

    })
    return newX
  })


  let headerData = [];
  // header.forEach(x => {
    // let datas = ""
  //   if (x.key == "data_field") {
  //     datas = ""
  //     headerData.push(datas);
  //   } else {
      subHeader.forEach(y => {
        // datas = y.label
        headerData.push(y.label);
      })
    // }
  // })

  // data.unshift(headerData);
  return { data: data, header: headerData }
}
export const mapExcelDataSTComparative = (data, header, field) => {
  field = field == undefined ? "key" : field
  let subHeader = [{key: "USA", label: "USA"},
  {key: "Canada", label: "Canada"},
  {key: "Mexico", label: "Mexico"},
  {key: "China", label: "China"},
  {key: "India", label: "India"},
  {key: "UK", label: "UK"},
  {key: "Italy", label: "Italy"},
  {key: "Oman", label: "Oman"},
  {key: "Kuwait", label: "Kuwait"},
  {key: "UAE (SLB)", label: "UAE"},
  {key: "Netherlands", label: "Netherlands"},
  {key: "grand_total", label: "Grand Total"},
]

  // let subHeader = [{ key: "total_expense", label: "Total Expense" },
  // { key: "tsa", label: "TSA" }
  // ]

  console.log(data, header, field, 'MMMMMMMMM')

  data = data.map(x => {
    let newX = {};
    let subCol = x.header_key_value;
    header.map(z => {
    
      if (z.id == "data_field") {
        newX["data_field"] = x.data_field
      } 
      else {
        let gt = 0
        subHeader.forEach(y => {
        
         
          subCol[y.key] = unwindHeaderPLKeyVal(subCol[y.key])
       
          z && z.columns.forEach(columnData => {
            let columnDatas = subCol[columnData.id]
          
              newX[z.id + "|" + columnData.id] = columnDatas == undefined ? "" : columnDatas
            // }
            //   console.log(y.key, columnDatas,"grandTotal")
            //   if(subCol[columnData.id] != undefined){
  
            //     gt+= subCol[columnData.id]
            //   }
            // }else{
            //   console.log(gt,"grandTotal")
            //   columnDatas = gt
            // }
            // if (subCol[columnData.id] && columnData.id) {
             
            // }

          })
        })
      }

    })
    console.log(newX, "newX")
      newX["grand_total|grand_total"] = x.grand_total
    
    return newX
  })

  let headerData = {};
  header.forEach(x => {
    if (x.id == "data_field") {
      headerData["data_field"] = ""
    } else {
      x && x.columns.forEach(y => {
        headerData[x.id + "|" + y.id] = y.Header;
      })
    }
  })

  data.unshift(headerData);
  // console.log(data, "data excel")
  return data
}
export const mapExcelDataComparativeAutomatedValidation = (data, header, headerExcel, field) => {
  field = field == undefined ? "key" : field
  let subHeader =[]
  header.forEach(y => {
    if( y.sub_headers != undefined) {
      y.sub_headers.forEach(x => {
        subHeader.push(x)
      })
    }
  
    

  });
  console.log(subHeader, "checkYEYE")
  // let subHeader = [{key: "actual", label: "ACTUALS"},
  // {key: "budget", label: "BUDGETS"},
  // {key: "compare", label: "B(W) THAN Budget"}]

  // let subHeader = [{ key: "total_expense", label: "Total Expense" },
  // { key: "tsa", label: "TSA" }
  // ]

  // console.log(data, header, field, 'MMMMMMMMM')

  data = data.map(x => {
    let newX = {};
    let subCol = x.sub_column;
    console.log(x, "testA3")


    headerExcel.map(z => {

      // if (z.operation && z.key != 'comments') {
        console.log(z.id, "checkID")
           if (z.id == "data_group") {
          newX["data_group"] = "";
          newX["data_group|data_group"] = x.data_group
        }  else if (z.id == "data_field") {
          newX["data_field"] = x.data_field
        }
        else {
          let i = 0;
          let gt = 0
          subHeader.forEach(y => {
            // console.log(subCol, "testZzcolumnData1")
            // console.log(z.sub_headers, "testZzcolumnData2")
            let modifiedData;
          
            // let columnData = subCol[dynamicHeaderKeyColumn(z.sub_headers, i)]
            let columnData = subCol[y.key]
          subCol[y.key] = unwindHeaderPLKeyVal(subCol[y.key])
        
            z && z.columns.forEach(columnData => {
              let columnDatas = subCol[columnData.id]
            
                newX[z.id + "|" + columnData.id] = columnDatas == undefined ? "" : columnDatas
              // }
              //   console.log(y.key, columnDatas,"grandTotal")
              //   if(subCol[columnData.id] != undefined){
    
              //     gt+= subCol[columnData.id]
              //   }
              // }else{
              //   console.log(gt,"grandTotal")
              //   columnDatas = gt
              // }
              // if (subCol[columnData.id] && columnData.id) {
              
              // }

            })
          })

      }

    })
    console.log(newX, "newX")

    return newX
  })

  let headerData = {};
  headerExcel.forEach(x => {
    if (x.id == "data_field") {
      headerData["data_field"] = ""
    } else {
      x && x.columns && x.columns.forEach(y => {
        headerData[x.id + "|" + y.id] = y.Header;
      })
    }
  })

  data.unshift(headerData);
  // console.log(data, "data excel")
  return data
}

export const mapExcelDataPLComparative = (data, header, field) => {
  field = field == undefined ? "key" : field
  // let subHeader = [{key: "actual", label: "ACTUALS"},
  // {key: "budget", label: "BUDGETS"},
  // {key: "compare", label: "B(W) THAN Budget"}]

  let subHeader = [{ key: "total_expense", label: "Total Expense" },
  { key: "tsa", label: "TSA" }
  ]

  console.log(data, header, field, 'MMMMMMMMM')

  data = data.map(x => {
    let newX = {};
    let subCol = x.sub_column;
    console.log(x, "testA3")


    header.map(z => {

      if (z.id == "budget_group") {
        newX["budget_group"] = x.budget_group
      } else if (z.id == 'comments') {
        newX[z.id + "|" + 'comments'] = x.comments
      }
      else {
        subHeader.forEach(y => {
          subCol[y.key] = unwindHeaderPLKeyVal(subCol[y.key])
          //  console.log(z,"subcoll", subCol)
          z && z.columns.forEach(columnData => {
            if (subCol[z.id] && columnData.id) {
              newX[z.id + "|" + columnData.id] = subCol[z.id][columnData.id] == undefined ? "" : subCol[z.id][columnData.id]
            }

          })
        })
      }

    })
    console.log(newX, "newX")

    return newX
  })

  let headerData = {};
  header.forEach(x => {
    if (x.id == "budget_group") {
      headerData["budget_group"] = ""
    } else {
      x && x.columns.forEach(y => {
        headerData[x.id + "|" + y.id] = y.Header;
      })
    }
  })

  data.unshift(headerData);
  // console.log(data, "data excel")
  return data
}

const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const replaceZeroorBlank = (json) => {
  if (json.length > 0) {
    const jsonKeys = Object.keys(json[0]);

    let objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      let value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        let curVal = value[jsonKeys[j]] 
        if (typeof curVal == 'number') {
          curVal = Math.round(curVal)
          value[jsonKeys[j]] = curVal
        // console.log(jsonKeys[j],curVal)

        }
        if (curVal.toString() == "" ) {
        // console.log(jsonKeys[j],curVal , "--masuk blank")
        value[jsonKeys[j]] = "-"
        }
      }
    }
  }
  return json
}

const autofitColumns = (json, header) => {
  let objectMaxLength = [];

  if (json.length > 0) {
    const jsonKeys = header ? header : Object.keys(json[0]);

    for (let i = 0; i < json.length; i++) {
      let value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        let curVal = value[jsonKeys[j]] ? value[jsonKeys[j]] : ""
        if (typeof curVal == 'number') {
          curVal = curVal.toString().split(".")[0]
        }

        if (curVal.toString() == "" || curVal == 0) {
          curVal = "-"
        }

        const l = curVal.length + 8;
        // console.log(curVal)
        objectMaxLength[j] =
          objectMaxLength[j] >= l
            ? objectMaxLength[j]
            : l;
      }

      let key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
          objectMaxLength[j] >= key[j].length
            ? objectMaxLength[j]
            : key[j].length;
      }
    }
  }

  return objectMaxLength.map(w => { return { width: w } });
}