import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const ActionOwnerPopper = function (props) {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'fixed',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

class SimpleModal extends React.Component {
  render() {
    const { classes } = this.props;

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={() => {this.props.toggleModal()}}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <a className="close-modal "
                        title={"Close Modal"}
                        onClick={this.props.toggleModal}
                    >
                        <CloseIcon className="close-icon mb-10"/>
                    </a>

                                  <Autocomplete
                                    // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                                    multiple
                                    className='actionOwnerTabelSelect mb-10'
                                    options={this.props.userList}
                                    size='small'
                                    // disabled={this.state.filterView == 'slb' || this.state.filterView == 'pemex'}
                                    PopperComponent={ActionOwnerPopper}
                                    getOptionLabel={(option) => option.full_name}
                                    value={this.props.currentTo}
                                    onChange={(event, data) => { this.props.setModalShareToValue(data) }}
                                    renderInput={(params) => (
                                        // <Tooltip title={label}>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Share To"
                                        />
                                        // </Tooltip>
                                    )}
                                    renderOption={(option) => <Typography
                                        style={{ fontSize: '12px', width: '250px' }}
                                        className='flex jc-space-between minw-200 ai-center'
                                        noWrap>
                                        <span className='pr-10'>{option.full_name}</span>
                                        <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                    </Typography>}

                                />

                                    <Autocomplete
                                    // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                                    multiple
                                    className='actionOwnerTabelSelect'
                                    options={this.props.userList}
                                    size='small'
                                    // disabled={this.state.filterView == 'slb' || this.state.filterView == 'pemex'}
                                    PopperComponent={ActionOwnerPopper}
                                    getOptionLabel={(option) => option.full_name}
                                    value={this.props.currentCc}
                                    onChange={(event, data) => { this.props.setModalShareCcValue(data) }}
                                    renderInput={(params) => (
                                        // <Tooltip title={label}>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Share CC"
                                        />
                                        // </Tooltip>
                                    )}
                                    renderOption={(option) => <Typography
                                        style={{ fontSize: '12px', width: '250px' }}
                                        className='flex jc-space-between minw-200 ai-center'
                                        noWrap>
                                        <span className='pr-10'>{option.full_name}</span>
                                        <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                    </Typography>}

                                />
                         {this.props.isLoading ? <div  className='text-center mt-10'><CircularProgress /></div> : 
                    <Button variant='contained' color="primary" className='modal-save-button' onClick={() => {this.props.handleShareChart()}}>Share</Button>
                                    }
                    </div>
            </Modal>
        </div>
      );
  }
}

// SimpleModal.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
