import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTable, useExpanded, usePagination, useSortBy } from 'react-table';
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import './style.scss';

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
    text-align: center;
  }
`;

function PageArray(dataLength, pageRow) {
  let result = []

  let temp = dataLength / pageRow
  for (let index = 0; index < temp; index++) {
    result.push(index + 1)
  }
  return result
}

function NewReactTable({ columns: userColumns, data, addClassName, paginationEnable }) {
  // const pageCount = PageArray(Math.ceil(data.length), 10)

  const [dataToShow, setDataToShow] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const handleDataToShow = () => {
    const startRow = (currentPage - 1) * 10
    const endRow = startRow + 10
    const showData = data.slice(startRow, endRow)

    setDataToShow(showData)
  }

  useEffect(() => {
    handleDataToShow()
  }, [currentPage])

  const handleCurrentPage = (newValue) => {
    setCurrentPage(newValue)
  }

  const handlePrev = () => {
    const isAblePrev = currentPage === 1 ? false : true
    if (isAblePrev) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    const isAbleNext = currentPage === Math.ceil(data.length / 10) ? false : true
    if (isAbleNext) {
      setCurrentPage(currentPage + 1)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns: userColumns,
      data: data,
      addClassName,

      // manualPagination: true,
      // manualSortBy: true,
      // autoResetPage: false,
      // autoResetSortBy: false,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useSticky,
    usePagination
  );

  const addClassNameStr = addClassName ? `rev-summary-table-${addClassName}` : 'rev-summary-table'
  return (
    <div className={`xpna-table-wrapper ${addClassNameStr}`}>
      <table
        className='xpna-pure-table xpna-aop-rev-table'
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={'header-border-right'} {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page && page.length ? page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          }) : null}
        </tbody>
      </table>
      {paginationEnable &&
        <div className="pagination" style={{"textAlign": "right"}}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      }
    </div>
  );
}

export default NewReactTable;