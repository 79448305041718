import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import history from '../../history'
import {
    updateUserNotification,
    performLogoutService,
    performLoginService,
    performForgotPwdService,
    performResetPwdService,
    performVerifyTokenService,
    getUserDetailsService,
    getUserNotification,
    performProfilePatchService,
    performProfilePicUploadService,
    performProfilePicRemoveService,
    performGeneratePwdService
} from './service'
import { sha256 } from 'js-sha256';
import { store } from '../../index'

function* fetchNews() {
    const json = yield fetch('https://newsapi.org/v1/articles?source = cnn & apiKey=c39a26d9c12f48dba2a5c00e35684ecc')
        .then(response => response.json(),);
    yield put({ type: "NEWS_RECEIVED", json: json.articles, });
}

function* performLogin(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    data.payload.password = sha256(data.payload.password);
    const response = yield call(performLoginService, data.payload);

    switch (response && response.status) {

        case 200:
            yield put({ type: "PERFORM_LOGIN_SUCCESS", loading: false, userData: response.data });
            localStorage.setItem('performReload', true)
            localStorage.setItem('showPasswordReset', response.data.reset_password_reminder)
            if (response.data && response.data.reset_password_reminder){
                history.push('/dashboard/generate-password')
            }else{

                let searchQuery = window.location.search;
                if (searchQuery && searchQuery.includes('redirectUri')) {

                    let redirectUri = searchQuery.split('redirectUri=')[1]
                    if (redirectUri == 'https://dev.newreporter.eaciitapp.com/' || 
                    redirectUri == 'https://uat.newreporter.eaciitapp.com/' || 
                    redirectUri == 'https://newreporter.eaciitapp.com/' || 
                    redirectUri.includes('redirectUri') ||
                    redirectUri.includes('?')

                    ) {
                        history.push('/dashboard/panel')
                    }else {
                        window.location.href = redirectUri;
                    }
                }else {
                    history.push('/dashboard/panel')

                }

            }
            break;

        case 401:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            toastr.error("Invalid username or password.")
            break;

        case 400:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            let msg = response.data.error.debug_msg
            msg = msg == undefined || msg == "" ? response.data.error.msg : msg;
            if (msg == "Invalid password") {
                toastr.error(" Please enter correct Password!")
            } else if (msg == "User has been disabled") {
                toastr.error(" User Account has been Disabled. Please contact Admin.")
            } else {
                toastr.error("Error!" + msg);
            }
            break;

        default:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            toastr.error("Please enter correct Email Address!")
    }
}

function* performForgotPassword(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    const response = yield call(performForgotPwdService, data.payload);
    if (response && response.status === 200) {
        toastr.success(`Link to reset the password has been sent to the ${data.payload.login_id}. Please reset the password and try logging in again.`)
    } else if (response && response.status === 401) {
        toastr.error(response.data.error.msg)
    }
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
}

function* getUserNotificationDetails(data) {
    const response = yield call(getUserNotification, data.payload);
    if (response && response.status === 200) {
        yield put({ type: "PERFORM_GET_NOTIFICATION_SUCCESS", loading: false, data: response.data });
    } else {
        toastr.error("Error get notification")
    }
}

function* updateUserNotificationDetails(data) {
    const response = yield call(updateUserNotification, data.payload);
    if (response && response.status === 200) {
        yield put({ type: "PERFORM_UPDATE_NOTIFICATION_SUCCESS", loading: false, data: response.data });
    }
}

function* getUserDetails(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });

    const response = yield call(getUserDetailsService);
    const performReload = localStorage.getItem('performReload')
    const state = store.getState();
    const loginState = state.authReducer

    switch (response && response.status) {

        case 200:
            yield put({ type: "PERFORM_GET_DETAIL_SUCCESS", loading: false, userData: response.data });
            if (!response.data.enable) {
                toastr.error('Error! User has been disabled!')
                yield put({ type: "PERFORM_LOGOUT" });
            }

            if (performReload) {
                localStorage.removeItem('performReload')
                if (!loginState.isEmbedIframe) {
                    window.location.reload()
                }
            }
            break;

        case 401:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            history.push('/')
            break;

        case 403:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            yield put({ type: "PERFORM_GENERATE_PASSWORD_MODAL" });
            
            localStorage.setItem('showPasswordReset', 'true')

            history.push('/dashboard/generate-password')
            break;

        default:
            yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
            toastr.error('Enter valid credentials')
    }
}

function* performResetPassword(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    data.payload.new_password = sha256(data.payload.new_password);
    data.payload.confirm_password = sha256(data.payload.confirm_password);

    const response = yield call(performResetPwdService, data.payload);
    if (response && response.status) {
        if (response.status == 200) {
            toastr.success('Password has been reset.')
            localStorage.removeItem('showPasswordReset')
            yield put({ type: "PERFORM_LOGOUT" });
            yield put({ type: "PERFORM_CHANGE_PASSWORD_SUCCESS", showResetModal: false });
        } else {
            toastr.error('Something went wrong. Please try again.')
        }
        yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
    }
}

function* performGeneratePassword(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    // data.payload.new_password = sha256(data.payload.new_password);
    // data.payload.confirm_password = sha256(data.payload.confirm_password);

    const response = yield call(performGeneratePwdService, data.payload);
    if (response && response.status) {
        if (response.status == 200) {
            // toastr.success('Password has been reset.')
            localStorage.removeItem('showPasswordReset')
            yield put({ type: "PERFORM_LOGOUT" });
            yield put({ type: "PERFORM_CHANGE_PASSWORD_SUCCESS", showResetModal: false });
        } else {
            toastr.error('Something went wrong. Please try again.')
        }
        yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
    }
}

function* performLogout(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    const response = yield call(performLogoutService);
    if (response && response.status) {
        localStorage.clear()
        yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
        if (data.payload) {
            history.push(`/?redirectUri=${window.location.href}`)
        }else{
            history.push(`/`)

        }
    } else {
        yield put({ type: "PERFORM_LOGIN_LOADER", loading: false });
    }
}

function* verifyToken(data) {
    let verifyTokenPayload = {
        token: data.payload.token
    }
    let resetPasswordToken = {
        new_password: data.payload.new_password,
        confirm_password: data.payload.confirm_password,
    }
    const state = store.getState();
    const loginState = state.authReducer
    if (!loginState.resetTokenSuccess) {
        const response = yield call(performVerifyTokenService, verifyTokenPayload);
        yield put({ type: "VERIFY_TOKEN_LOADING", loading: true });
        if (response && response.status === 200) {
            yield put({ type: "VERIFY_TOKEN_SUCCESS", success: true });
            yield put({ type: "PERFORM_RESET_PASSWORD", payload: resetPasswordToken });
        } else {
            toastr.error('Invalid token. Please try again.')
            history.push('/')
        }
    } else {
        yield put({ type: "PERFORM_RESET_PASSWORD", payload: resetPasswordToken });
    }
}

function* performProfileUpdate(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    const response = yield call(performProfilePatchService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "PERFORM_PROFILE_UPDATE_SUCCESS", loading: false });
        toastr.success('Profile updated successfully.')
    }
}

function* performProfilePicUpload(data) {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    var formData = new FormData();
    formData.append('file', data.payload.file);
    const response = yield call(performProfilePicUploadService, formData);
    if (response && response.status == 200) {
        yield put({ type: "PERFORM_PROFILE_UPDATE_SUCCESS", loading: false });
    }
}

function* performProfilePicRemove() {
    yield put({ type: "PERFORM_LOGIN_LOADER", loading: true });
    const response = yield call(performProfilePicRemoveService);
    if (response && response.status == 200) {
        yield put({ type: "PERFORM_PROFILE_UPDATE_SUCCESS", loading: false });
    }
}

export default function* actionWatcher() {
    yield takeLatest('GET_NEWS', fetchNews)
    yield takeLatest('PERFORM_LOGIN', performLogin)
    yield takeLatest('PERFORM_FORGOT_PASSWORD', performForgotPassword)
    yield takeLatest('PERFORM_RESET_PASSWORD', performResetPassword)
    yield takeLatest('PERFORM_GENERATE_PASSWORD', performGeneratePassword)
    yield takeLatest('PERFORM_VERIFY_TOKEN', verifyToken)
    yield takeLatest('GET_USER_DETAILS', getUserDetails)
    yield takeLatest('GET_USER_NOTIFICATION', getUserNotificationDetails)
    yield takeLatest('UPDATE_USER_NOTIFICATION', updateUserNotificationDetails)
    yield takeLatest('PERFORM_LOGOUT', performLogout)
    yield takeLatest('PERFORM_PROFILE_UPDATE', performProfileUpdate)
    yield takeLatest('PERFORM_PROFILE_PIC_UPLOAD', performProfilePicUpload)
    yield takeLatest('PERFORM_PROFILE_PIC_REMOVE', performProfilePicRemove)
}
