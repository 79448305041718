import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleTableTransform, handleTableTransformV2 } from '../../../../utils/numberUtils'
import { checkToOpenInvoiceDetail } from '../utils'
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Grid from '@material-ui/core/Grid';
import BusinessUnitComponent from '../BusinessUnitComponent'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const ReactTableFixedColumns = withFixedColumns(ReactTable);
export class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.sortList = {}
        this.headerColorArr = ['white', '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']
        this.state = {
            showActionLoading: false,
            seriesData: [],
            loading: true,
            showInvoiceDetailModal: false,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: '',
            invoicePayload: null,
            radioValue : "Summary",
            headerTable : [{key: "business_unit", label: "Business Unit", operation: 0},{key: "liabillity", label: "Contract Liability", operation: 7}]
        }
    }

    componentDidMount() {
        this.setState({showActionLoading: true});
        this.props.changesRadioCL("Summary")
        let payload = {
            report_date : this.props.selectedMonthFilter,
            receivableTabSelectorValue : this.props.receivableTabSelectorValue,
            view : "billing"
        }
        console.log(payload, "cek123123")
        this.props.getContractLiability(payload)
    }

    businessUnitClick = (data, type, rowData) => {
        // const { summaryState } = this.props;
        // var headerData = []
        // if (summaryState.internalData &&
        //     summaryState.internalData.row_header &&
        //     summaryState.internalData.row_header.length
        // ) {
        //     var _data = [...[], ...summaryState.internalData.row_header]
        //     headerData = _data.filter(item => {
        //         if (!(item.key == "business_unit" || item.key == "grand_total")) {
        //             return item
        //         }
        //     })
        // }
        // headerData = this.changeKeysOfHeader(headerData)
        // this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
        this.setState({
            radioValue: data,
          })
    }
    componentDidUpdate(prevProps) {
        const { summaryState, dashboardState} = this.props;
        if (prevProps.dashboardState.loading &&
            !dashboardState.loading) {
          this.setState({
            showActionLoading: false,
          });
        }
    }
   
    appendHyperLinkRowData = (item, type, props) => {
        if (props.original['business_unit'] != 'PAB') {
            return (
                <span>
                    {props.original['business_unit']}
                </span>
            )
        }else{
            return (
                <span
                    className='pointer primary-dark'
                    onClick={() => { this.businessUnitClick(props.original[item.key], type, props.original) }}>
                    <u>{props.original['business_unit']}</u>
                </span>)
        }
// console.log(props.original, "cekLabel")
       

    }
    changeKeysOfHeader = (data) => {
        if (data && data.length) {
            let type = "liability"
            return data.map((item, i) => {
                console.log(item, "cekITEM")
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    { 
                                    
                                    // props.original[item.key] == "Grand Total" ?
                                    //     <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                    // <span>{handleTableTransformV2(props.original, item)}</span>
                                        // <span><b>{props.original[item.key]}</b></span> 
                                        item.key == 'business_unit' ?
                                        props.original[item.key] == "Grand Total" ?
                                            <span><b>{props.original[item.key]}</b></span> :
                                            <>{this.appendHyperLinkRowData(item, type, props)}</> :
                                        <span>{handleTableTransformV2(props.original, item)}</span>    
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                        },
                        headerStyle: {
                            whiteSpace: 'break-spaces',
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            backgroundColor: '#b1bace',
                            flex:''
                            // backgroundColor: this.headerColorArr[i]
                        }
                    }
               
                
            })
        }
        return []
    }

    handleColumnClick = (state, colInfo, columnDetail, etc) => {
        console.log(colInfo,'>>>>',columnDetail)
        const { business_unit, parent_group } = colInfo.original;
        const payload = {
            aging_category: columnDetail.id,
            parent_group,
            bu: business_unit
        }
        // if (columnDetail.id != 'grand_total' && business_unit != "Grand Total") {
        //     if (checkToOpenInvoiceDetail(columnDetail.id)) {
        //         this.props.getInvoiceDetail(payload);
        //         this.setState({
        //             showInvoiceDetailModal: true,
        //             invoicePayload: payload

        //         })
        //     }
        // }


    }

    columnClickFunction = (state, colInfo, abc, etc) => {
        if (colInfo) {
            return {
                onClick: (e) => {
                    this.handleColumnClick(state, colInfo, abc, etc)
                }
            }
        } else {
            return {}
        }
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    changeValuesOfData = (data, type) => {
        if (data && data.length) {
            return data.map(item => {
                item['parent_group'] = type;
                return item
            })
        }
        return []
    }


    handleInvoiceSorting = (columnId) => {
        const { invoicePayload } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoicePayload
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }
    handleData = (data) => {
        let arr = []
        // console.log(data, "checkState123")
        data.map(item => {
            // console.log(item.category, "checkState123")
            if(item.category == "DSO"){
                arr.push(item)
            }
        })
        arr.sort((x, y) => {
            let a = new Date(x.date)
            let b = new Date(y.date)
            return b.getTime() - a.getTime()
          })
        return arr
    }
    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.business_unit == 'Grand Total' ? '#b1bace' : ''
            }
          }
        }
        return {};
    }
     handleApplyFilterFunc = (payload) => {
        const { radioValue } = this.state;
        payload['bu'] = radioValue;
        payload['radioValue'] = radioValue;
        // if (props.receivableTabSelectorValue == 1) {
        //   payload.bu = reverseNewBUnitIndex[externalTabValue]
    
        // }
        this.props.handleApplyFilter(payload)
    
      }
      handleRadioChange = (event) => {
        this.setState({
            radioValue: event.target.value,
          })
          this.props.changesRadioCL(event.target.value)
          if(event.target.value == "Summary"){
            let payload = {
                report_date : this.props.selectedMonthFilter,
                receivableTabSelectorValue : this.props.receivableTabSelectorValue,
                view : "billing"
            }
            console.log(payload, "cek123123")
            this.props.getContractLiability(payload)
          }
      }
    render() {
        let { summaryState, invoiceDetailState } = this.props;
        const { showInvoiceDetailModal, headerTable, radioValue } = this.state;
       
        let externalDataColumns = this.changeKeysOfHeader(headerTable)
        let externalDataList = summaryState.externalData.data ? this.handleData(summaryState.externalData.row_date) : []
        externalDataList = summaryState.externalData.row_data
        console.log(summaryState.externalData, externalDataList, "ssdefffkState123")
        return (
            <>
             <RadioGroup name="radio" value={radioValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
            <FormControlLabel value="Summary" control={<Radio color="primary" />} label="Summary" />
            <FormControlLabel value="PAB" control={<Radio color="primary" />} label="PAB" />
                           
            </RadioGroup>
           
                { summaryState.loading ?
                    <div className='progressfullpage'><CircularProgress /></div> : 
               
                   ( radioValue == "Summary" && <div className={'hfmDataTable xOverFlowHidden firstChildBorder'}>
                    <Grid item xs={6}>
                         <ReactTable
                            data={externalDataList}
                            columns={externalDataColumns}
                            defaultPageSize={6}
                            loading={summaryState.loading}
                            className="xpna-react-table -striped -highlight"
                            sortable={false}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTrProps={this.getTrProps}
                        />
                    {/* <ReactTable
                        data={externalDataList}
                        columns={externalDataColumns}
                        defaultPageSize={externalDataList.length}
                        className="-striped -highlight"
                        PaginationComponent={Pagination}
                        pageSizeOptions={[10, 20, 30]}
                        resizable={true}
                    /> */}
                </Grid>
                </div>)
                       
                        // <ReactTable
                        //     // TheadComponent={props => null}
                        //     data={externalDataList}
                        //     defaultPageSize={6}
                        //     columns={externalDataColumns}
                        //     className="-striped -highlight"
                        //     pageSizeOptions={[10, 20, 30]}
                        //     style={{ height: '465px', }}
                        //     showPagination={false}
                        // />
                       
                      
                }
            
                {radioValue != "Summary" &&
                 <BusinessUnitComponent
                 externalfilterArr={this.props.externalfilterArr}
                 externalFilterObject={this.props.externalFilterObject}
                 handleApplyFilter={this.handleApplyFilterFunc}
                 summaryRowValue={this.props.summaryRowValue.length && this.props.summaryRowValue[0]}
                 handleSendComment={this.props.handleSendComment}
                 receivableTabSelectorValue={this.props.receivableTabSelectorValue}
                 buValue={radioValue}
                 parentGroup={this.props.parentGroup}
                 selectedMonthFilter={this.selectedMonthFilter}
     
               />

                }
                {
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                    />
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summaryMonthly,
                invoiceDetailState: state.invoiceDetailState,
                dashboardState: state.dashboardState,
            }
        },
        dispatch => {
            return {
                getContractLiability(data) {
                    dispatch({
                      type: 'FETCH_CONTRACT_LIABILITY',
                      payload: data
                    });
                  },
                getInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_INVOICE_DETAIL',
                        payload: data
                    });
                }
            };
        }
    )(Summary);
