import React from 'react';
import ReactTable from "react-table-6";
import withFixedColumns from 'react-table-hoc-fixed-columns';


const ReactTableFixedColumns = withFixedColumns(ReactTable);

 class ReactTableComponent extends React.Component {

    shouldComponentUpdate(nextProps){

        if (nextProps.showLoader){
            return false
        }

        if (nextProps.showInvoiceDetailModal || this.props.showInvoiceDetailModal){
            return false
        }

        return true
    }

    render() {

        const {pivotRowData, loading, pivotHeaderData, tableLen, subComponent, columnHeaderClick, rowClick, className, expandIcon } = this.props;


        return (
            <ReactTableFixedColumns
            data={pivotRowData}
            className={className}
            loading={loading}
            columns={pivotHeaderData}
            defaultPageSize={6}
            pageSize={tableLen}
            SubComponent={subComponent}
            showPagination={false}
            sortable={false}
            getTheadThProps={columnHeaderClick}
            getTdProps={rowClick}
            getTrProps={expandIcon}
    
        />
        )

    }
  }

export default ReactTableComponent;