import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ColumnHeader = styled.div`
  margin-bottom: 10px;
`;

const TotalPlan = styled.div`
  padding: 0px 10px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0px;
`;

const DroppableStyles = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: #d4d4d4;
`;

const DraggableElement = ({ prefix, elements }) => (
  <DroppableStyles className="panel-plan">
    <ColumnHeader className="header-plan">{prefix} <KeyboardArrowDownIcon/></ColumnHeader>
    <TotalPlan className="total">Total Plans ({elements.length})</TotalPlan>
    <Droppable droppableId={`${prefix}`} >
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="list-item-plan">
          {elements.map((item, index) => (
            <ListItem key={item.id} item={item} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DroppableStyles>
);

export default DraggableElement;
