import React, {useEffect, useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartOptions from "./highcharts.options";
import AnnotationsFactory from "highcharts/modules/annotations";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CHART_COLORS } from '../../../constant';

AnnotationsFactory(Highcharts);
HighchartOptions(Highcharts);

export default function ChartComponent(){
    const {riskManagement: { chartView: { technicalColor, commercialColor, economicColor, politicalColor, hseColor } }} = CHART_COLORS;
    return(
        <div className="mat-shadow-2">
           <HighchartsReact
                highcharts={Highcharts}
                options = {
                    {
                        chart: {
                            type: 'line',
                            backgroundColor: {
                                linearGradient: { x1:0, y1:1 , x2:1, y2:0.65 },
                                stops: [
                                    [0, '#33ff33'],
                                    [0.45, '#ffff00'],
                                    [0.8, '#ff0000'],
                                    
                                ]
                            },
                        },

                        title: {
                            text: ' '
                        },

                        credits: {
                            enabled: false
                        },          
                        
                        xAxis: {
                            title:{
                                text:"Severity",
                                offset: 30,
                            },
                            visible:true,
                            labels: {
                                format: '${value:,.0f}'
                            },
                            zoomEnabled: false,
                            type: 'logarithmic',
                            startPoint: 90000,
                            
                        },
                        yAxis:{
                            title: {
                                text: 'Probability', 
                                                        
                            },
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            visible:true,
                            labels: {
                                format: '{value}%'
                            },
                            zoomEnabled:false,
                            tickInterval:20,
                        },
                        tooltip: {
                            formatter: function(){
                                if(!this.point.noTooltip){
                                    return '● '+this.series.name +
                                    '</b><br/>Probability: <b>'+this.point.y + "%"
                                }
                                return false
                            } 
                        },
                        annotations: [{
                            labelOptions: {
                                // shape: 'connector',
                                // align: 'left',
                                verticalAlign: 'bottom',
                                justify: false,
                                crop: true,
                                style: {
                                    fontSize: '11px',     
                                }
                            },
                            shapes: [{
                                fill: 'none',
                                stroke: 'black',
                                strokeWidth: 1.2,
                                type: 'path',
                                points: [{x:10000000, y:53, xAxis:0, yAxis:0 }, {x:1000000000, y:95, xAxis:0, yAxis:0  }],
                                markerEnd: 'arrow',
                                // markerStart:'arrow'
                            },
                            {
                                fill: 'none',
                                stroke: 'black',
                                strokeWidth: 1.2,
                                type: 'path',
                                points: [ {x:10000000, y:53, xAxis:0, yAxis:0  }, {x:100000, y:10, xAxis:0, yAxis:0 }],
                                markerEnd: 'arrow',
                                // markerStart:'arrow'
                            }],
                            
                            labels: [{
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x:100000, y: 22
                                },
                                text: '0.1 ▼'
                            }, 
                            {
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x:1000000, y:43
                                },
                                text: '1.2 ▼'
                            },
                            {
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x:10000000, y:55
                                },
                                text: '1.3 ▲'
                            },
                            {
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x:100000000, y:58
                                },
                                text: '1.6 ▼'
                            },
                            {
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x:800000000, y:79
                                },
                                text: '2.3 ▲'
                            }
                        ],

                        }],

                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top'
                        },
    
                        series: [{
                            name:"Technical",
                            data:[{x:100000, y: 22}],       
                            color: technicalColor                 
                        },{
                            name:"Commercial",
                            data:[{ x:1000000, y:43 }],
                            color: commercialColor
                        },{
                            name:"Economic",
                            data:[{ x:10000000, y:55 }],
                            color: economicColor
                        },{
                            name:"Political",
                            data:[{ x:100000000, y:58 }],
                            color: politicalColor
                        },{
                            name:"HSE",
                            data:[{ x:1000000000, y:79 }],
                            color: hseColor
                        },
                        // {
                        //     name:"",
                        //     data:[{x:100000,y:10, noTooltip: true}, {x:1000000000, y:95, noTooltip: true}]
                        // }
                    ],
                    }
                }
            />
        </div>
    )
        
}
