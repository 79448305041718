import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import '../userManagement.scss'
import { Grid, Tooltip } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import ReactTableComponent from './TableComponent'
import BarChart from './ChartComponent'
import Button from '@material-ui/core/Button';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export class UserAnalyticsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.isExport = false;
    this.state = {
      Data: [],
      openDialog: false,
      loading: true,
      sessionTimeVal: '',
      finalYearVal: '',
      quarterVal: this.props.userAnalyticsState.defaultFilter,
      userRoleVal: [],
      usersFilter:"",
      rowData:this.props.userAnalyticsState.rowData,
      loginVal: '',
      toggleTableGraph: false,
      viewEmail: false,
      showLoader : false,
      usersArr : []
    }
  }

  componentDidMount() {
    this.props.getDefaultFilterUserAnalytics()
  }

  componentDidUpdate(prevProps) {
    const { defaultFilter, quarterfilterArr, rowData } = this.props.userAnalyticsState;

      if( prevProps?.userAnalyticsState?.rowData?.length != rowData?.length){
        this.setState({rowData: this.props.userAnalyticsState.rowData})
      }
      if (prevProps?.userAnalyticsState?.quarterfilterArr?.length != quarterfilterArr?.length) {
        this.setState({
          quarterVal: defaultFilter,
        })
      }
    console.log(this.props.userAnalyticsState, "checkDAta")
  //   if (prevProps.userAnalyticsState.invoiceLoading && !this.props.userAnalyticsState.invoiceLoading) {
  //     this.setState({
  //         showLoader: false
  //     })
  // }
  }

  fetchUserAnalytics = () => {
    const { toggleTableGraph, sessionTimeVal, finalYearVal, quarterVal, userRoleVal, usersFilter, loginVal } = this.state
    const payload = {
      view: toggleTableGraph ? 'graph' : 'table',
      month: quarterVal,
      session_time: sessionTimeVal,
      user_role: userRoleVal,
      // user: usersFilter,
      login_count: loginVal,
      export: this.isExport
    }

    this.props.getUserAnalyticsData(payload)
    this.isExport = false;
  }

  handleTableGrapgToggleChange = (event) => {
    this.setState({ toggleTableGraph: event.target.checked }, () => {
      this.fetchUserAnalytics()

    })
  };

  handleChangeFinalYear = (event) => {
    if (event.target) {
      this.setState({
        finalYearVal: event.target.value,
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };

  handleQuarterChange = (event) => {
    if (event.target) {
      this.setState({
        quarterVal: event.target.value,
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };

  handleUserRoleChange = (event) => {
    if (event.target) {
      this.setState({
        userRoleVal: event.target.value,
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };

  handleUserChange = (event) => {
    if (event.target) {
      // let arr = this.state.usersFilter
      // arr.push()
      console.log(event.target.value, "cekValue")
      this.setState({
        usersFilter: event.target.value,
      },this.handleFilterChange);
    }
  };

  handleFilterChange = ()=>{
    const {usersFilter} = this.state;
    let currentData = this.props.userAnalyticsState.rowData;
    let currentUser = usersFilter.toLowerCase();
    console.log("currentUser  -> ", currentUser );
    if(currentUser != ''){
      currentData = currentData?.filter(val => val?.data_field.toLowerCase().indexOf(currentUser) > -1)
    }
    this.setState({rowData: currentData});
  }

  handleLoginChange = (event) => {
    if (event.target) {
      this.setState({
        loginVal: event.target.value,
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };


  handleResetFilter = (event) => {
    if (event.target) {
      this.setState({
        sessionTimeVal: '',
        quarterVal: this.props.userAnalyticsState.defaultFilter,
        userRoleVal: [],
        usersFilter:[],
        loginVal: '',
        finalYearVal: ''
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };

  handleSessionChange = (event) => {
    if (event.target) {
      this.setState({
        sessionTimeVal: event.target.value,
      }, () => {
        this.fetchUserAnalytics()
      });
    }
  };

  handleExcelDownload = () => {
    console.log('clicked')
    this.isExport = true;
    this.fetchUserAnalytics()
  }

  handleApplyFilter = () => {
    this.fetchUserAnalytics()
  }
  openViewEmail = () => {
    this.setState({viewEmail : true})
    this.props.getEmailTemplate({})
}

handleSubmitTemplate = (event) => {
  event.preventDefault();

  let payload = {
    // cc_emails: [event.currentTarget.cc_emails.value],
    email_title: event.currentTarget.email_title.value,
    // email_salutation: event.currentTarget.email_salutation.value,
    email_msg: this.state.emailTemplateContent,
    // email_signature: event.currentTarget.email_signature.value
  }

  this.props.saveEmailTemplate(payload)
  this.setState({viewEmail : false})
  
}

onChangeTemplateEmail = (data) => {
  this.setState({emailTemplateContent: data})
}
closeViewEmail = () => {
  this.setState({viewEmail : false})
}
handleOpenDialog = () =>{
  this.setState({
      openDialog : true
  })

}

handleSendMail = () =>{
  const { month } = this.state
 
  this.setState({
    showLoader: true,
  }, () => {
    this.props.sendEmail({"report_date": month})
  });
  this.handleCloseDialog()
}

handleCloseDialog = () => {
  this.setState({
      openDialog : false
  })
}
  render() {

    const { userRoleArr,userComboArr, sessionTimeArr, loading, showLoader, loginCountArr, finalYearArr, quarterfilterArr, graphData } = this.props.userAnalyticsState
    const { sessionTimeVal, finalYearVal, quarterVal, userRoleVal, usersFilter, loginVal, toggleTableGraph, openDialog, usersArr, rowData } = this.state
    const {tableViewState} = this.props
    const emailData = tableViewState.emailTemplate.data;
  
   console.log(this.props.userAnalyticsState, "cek123123")
    
   
    return (
     
      <React.Fragment>
         {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
        <div className={'flex jc-space-between'}>
          <Grid container alignItems="center">
            {finalYearArr && finalYearArr.length > 0 ? <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
              <InputLabel>Final Year</InputLabel>
              <Select
                label="Final Year"
                value={finalYearVal}
                onChange={this.handleChangeFinalYear}
              >
                {finalYearArr.map((val) => (
                  val ? <MenuItem key={val} value={val} >
                    {val}
                  </MenuItem> : ''
                ))}
              </Select>
            </FormControl> : ''}
            {quarterfilterArr && quarterfilterArr.length > 0 ? <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
              <InputLabel>Month</InputLabel>
              <Select
                label="Quarter"
                value={quarterVal}
                onChange={this.handleQuarterChange}
              >
                {quarterfilterArr.map((val) => (
                  val ? <MenuItem key={val} value={val} >
                    {val}
                  </MenuItem> : ''
                ))}
              </Select>
            </FormControl> : ''}
            {userRoleArr && userRoleArr.length > 0 ? <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
              <InputLabel>User Roles</InputLabel>
              <Select
                multiple
                label="User Roles"
                value={userRoleVal}
                onChange={this.handleUserRoleChange}
              >
                {userRoleArr.map((val) => (
                  val ? <MenuItem key={val.key} value={val.value} >
                    {val.value}
                  </MenuItem> : ''
                ))}
              </Select>
            </FormControl> : ''}

            {!toggleTableGraph ? <FormControl variant="outlined" fullWidth className='selectOutlineSmall maxw-200' size='small'>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Users"
              value={usersFilter}
              onChange={this.handleUserChange}
            />
          </FormControl> : ""}

            {!loading && <>
              <Button color="primary" variant="contained" className='newButton btnRed btnClear uppercase' onClick={this.handleResetFilter}>Clear</Button>
              {/* <Button color="primary" variant="outlined" onClick={this.handleApplyFilter}>Apply</Button> */}
            </>
            }
           
                              
              <Button
                size="small"
                variant="contained"
                color="primary"
                className = "ml-20 newButton btnBlue btnEmail uppercase"
                endIcon={<Icon>visibility</Icon>}
                onClick={this.openViewEmail}
              >
                View User Email
              </Button>
              <Modal
                    open={this.state.viewEmail}
                    onClose={this.closeViewEmail}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.viewEmail}>
                        {tableViewState.emailTemplate.loading? <div className='text-center'><CircularProgress /></div> : 
                        <div className='customModal viewEmailModal'>
                            <div className='header'>
                                <h2>View Email</h2>
                            </div>
                            <div className='modal-content email-template'>
                                <form onSubmit={this.handleSubmitTemplate}>
                                    <TextField
                                      id="email_title"
                                      label="Title of Email"
                                      style={{ margin: 8 }}
                                      placeholder=""
                                      helperText=""
                                      fullWidth
                                      defaultValue= {emailData.email_title}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                    <TextField
                                      id="email_salutation"
                                      label="Salutation"
                                      style={{ margin: 8 }}
                                      defaultValue= "Dear username,"
                                      margin="normal"
                                      disabled 
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                    <FormControl style={{ marginTop: 8 ,  marginLeft: 10 }}>
                                        <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                        <CKEditor
                                            config={{
                                                removePlugins: ['Link','EasyImage','CKFinder','Indent','Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                            }}
                                            id="editor-msg-body"
                                            editor={ ClassicEditor }
                                            data= {emailData.email_msg}
                                            onChange={ ( event, editor ) => {
                                                this.onChangeTemplateEmail(editor.getData());
                                            } }
                                        />
                                    </FormControl>
                                    <p class="ml-10">{emailData.app_link}</p>
                                    
                                    <TextField
                                      id="email_signature"
                                      label="Signature"
                                      style={{ margin: 10 }}
                                      defaultValue= {emailData.email_signature}
                                      margin="normal"
                                      disabled 
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                    <div className="flex jc-flex-end mt-30">
                                        <Button variant="contained" className='newButton btnBlue btnSubmit uppercase' color="primary">Submit</Button>
                                        <Button variant="contained" className='ml-16 newButton btnRed btnCancel uppercase' onClick={this.closeViewEmail}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        }
                        
                    </Fade>
              </Modal>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className = "ml-20 newButton btnBlue btnEmail uppercase"
                endIcon={<Icon>send</Icon>}
                onClick={this.handleOpenDialog}
              >
                Send Email
              </Button>
              <Dialog
                open={openDialog}
                onClose={this.handleCloseDialog}
              >
                <DialogTitle>
                  Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to send it?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant='contained' className='newButton btnRed btnCancel uppercase' autoFocus onClick={this.handleCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.handleSendMail} variant="contained" className='newButton btnBlue btnEmail uppercase' size="small" color="primary">
                    Send Email
                  </Button>
                </DialogActions>
              </Dialog>
          </Grid>
          <div className='mb-10 flex ai-center'>
            <label>Table</label>
            <Switch
              checked={toggleTableGraph}
              onChange={this.handleTableGrapgToggleChange}
              color="primary"
              name="toggleTableGraph"
            />
            <label>Graph</label>
          </div>
          {!toggleTableGraph &&
            <div className='ml-20 pointer'>
              <Tooltip title={'Download to Excel'}>
                <img
                  src={DownloadExcel}
                  className='pointer'
                  height='30'
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleExcelDownload();
                  }} />
              </Tooltip>
            </div>
          }
        </div>
        <Grid container className='mb-20'>

          {/* {loginCountArr && loginCountArr.length > 0 ? <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
            <InputLabel>No. of Logins</InputLabel>
            <Select
              label="No. of Logins"
              value={loginVal}
              onChange={this.handleLoginChange}
            >
              {loginCountArr.map((val) => (
                val ? <MenuItem key={val} value={val} >
                  {val}
                </MenuItem> : ''
              ))}
            </Select>
          </FormControl> : ''}
          {sessionTimeArr && sessionTimeArr.length > 0 ? <FormControl variant="outlined" className='selectOutlineSmall maxw-200 mr-10' fullWidth size='small'>
            <InputLabel>Session Time</InputLabel>
            <Select
              label="Session Time"
              value={sessionTimeVal}
              onChange={this.handleSessionChange}
            >
              {sessionTimeArr.map((val) => (
                val ? <MenuItem key={val} value={val} >
                  {val}
                </MenuItem> : ''
              ))}
            </Select>
          </FormControl> : ''} */}

        </Grid>
        {loading ? <div className='text-center'><CircularProgress /></div> :
          <>{!toggleTableGraph ? <ReactTableComponent  data={rowData} /> : <BarChart chartData={graphData.length ? graphData : []} ></BarChart>}</>
        }
      </React.Fragment>
    );
  }
}

export default
  connect(
    state => {
      return {
        userAnalyticsState: state.userManagementState.userAnalytics,
        authReducer: state.authReducer,
        tableViewState: state.dashboardState.dashboardAnalytic.AROverdueActionAnalysis,
      }
    },
    dispatch => {
      return {
        getUserAnalyticsData(data) {
          dispatch({
            type: 'FETCH_USER_ANALYTICS_DATA',
            payload: data
          });
        },
        saveEmailTemplate(data){
          dispatch({
            type: "SAVE_EMAIL_TEMPLATE_ANALYTICS",
            payload : data
          })
      },
        getEmailTemplate(data){
          dispatch({
              type: 'FETCH_EMAIL_TEMPLATE_ANALYTICS',
              payload: data
          });
      },
      sendEmail(data){
        dispatch({
          type : "SEND_MAIL_USER_ANALYTICS",
          payload : data
        });
      },
        getDefaultFilterUserAnalytics() {
          dispatch({
            type: 'FETCH_DEFAULT_FILTER_USER_ANALYTICS'
          });
        }
      };
    }
  )(UserAnalyticsComponent);
