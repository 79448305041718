import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
// import Switch from '@material-ui/core/Switch';
import BarChart from "./chartStacked"
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import TableComponent from './summary';
import { Button, Chip, Tooltip, Typography, TextField  } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InvoiceDetailModal from './DrillDown';
import NewComponent from '../../../Receivable/Ageing/NewComponent';



function LinkTab(props) {
    return (
        <Tab
            component='a'
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const tabValueMap = {
    0: 'Q1-2022',
    1: 'Q3-2022',
    2: 'Q4-2022',
    3: ''
}

const amountFilterArr = ['>= 500k', '>= 1 million']
const limitFilterArr = ['Top 10', 'Top 20']

const typeMapping = (str) => {
    let result = ''

    switch (str) {
        case 'byRegion':
            result = 'region'
            break;
        case 'byManager':
            result = 'project_manager_name'
            break;
        case 'byProject':
            result = 'project_name'
            break;
        default:
            result = 'region'
            break;
    }

    return result
}

const amountMapping = (str) => {
    let result = ''

    switch (str) {
        case '>= 500k':
            result = '>500'
            break;
        default:
            result = '>1million'
            break;
    }
    return result
}
export class PABBacklogComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            NewValue: 'byRegion',
            typeValue: 'summary',
            isByPMFilterEnable: false,
            isAmountFilterEnable: false,
            isLimitFilterEnable: false,
            isExcelIconEnable: true,
            byPMSelectorValue: [],
            byPNameSelectorValue: [],
            byAmountSelectorValue: '',
            byLimitSelectorValue: limitFilterArr[0],
            downloadExcel: 'false',
            showInvoiceDetailModal: false
        }
    }

    componentDidMount() {
        let str = this.state.NewValue
        this.fetchDataFromServer(str)
        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'
            })
        }, 3000)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    fetchDataFromServer = (str) => {
        const { NewValue, byPMSelectorValue, byPNameSelectorValue, byAmountSelectorValue, typeValue, downloadExcel, byLimitSelectorValue } = this.state
        var str = str ? str : NewValue

        const typeStr = typeMapping(str)
       // const amountStr = amountMapping(byAmountSelectorValue)

        let limit = 10
        switch (byLimitSelectorValue) {
            case 'Top 20':
                limit = 20
                break;
        
            default:
                limit = 10
                break;
        }

        let payload = {
            year_label: '',
            type: typeStr,
            project_manager_name: [],
            project_name: [],
            view_type: typeValue,
        }

        switch (str) {
            case 'byRegion':
                payload.year_label = 'FY23'
                break;
            case 'byManager':
                payload.year_label = 'FY23'
                payload.amount_top = limit
                payload.amount_filter = byAmountSelectorValue
                payload.project_manager_name = byPMSelectorValue
                break;
            case 'byProject':
                payload.year_label = 'FY23'
                payload.amount_top = limit
                payload.amount_filter = byAmountSelectorValue
                payload.project_name = byPNameSelectorValue
                break;
            default:
                break;
        }
        if(downloadExcel === 'true') {
            payload.export = downloadExcel
            this.setState({
                downloadExcel: 'false'
            })
        }
        this.props.fetchPABSummaryTableData(payload);

    }

    handleNewChange = (event, newValue) => {
        const { typeValue } = this.state
        let pmFilterEnable = false
        let amountFilterEnable = false
        let limitFilterEnable = false
        let excelIconEnable = false

        // if(newValue !== "byRegion" && typeValue !== "chart") {
        //     pmFilterEnable = true
        //     amountFilterEnable = true
        //     excelIconEnable = true
        // } else if (newValue !== "byRegion" && typeValue === "chart") {
        //     // amountFilterEnable = true
        //     limitFilterEnable = true
        // }

        if(newValue !== "byRegion") {
            switch (typeValue) {
                case "chart":
                    limitFilterEnable = true
                    break;
                default:
                    pmFilterEnable = true
                    amountFilterEnable = true
                    excelIconEnable = true
                    break;
            }
        } else {
            switch (typeValue) {
                case "summary":
                    excelIconEnable = true
                    break;
                default:
                    break;
            } 
        }

        this.setState({
            NewValue: newValue,
            isByPMFilterEnable: pmFilterEnable,
            isAmountFilterEnable: amountFilterEnable,
            isLimitFilterEnable: limitFilterEnable,
            isExcelIconEnable: excelIconEnable,
            downloadExcel: 'false'
        })

        this.fetchDataFromServer(newValue)
    }



    handleNewBUChange = (event) => {

        if (event.target) {
            this.setState({
                newBUSelectedValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleReseButton = () => {
        this.setState({
            hemisphereSelectedValue: '',
            subRegionSelectedValue: '',
            subBUSelectedValue: '',
            newBUSelectedValue: '',
        }, () => {
            this.fetchDataFromServer()
        });
    }

    handleHemisphereChange = (event, newValue) => {
        const { NewValue } = this.state
        let pmFilterEnable = false
        let amountFilterEnable = false
        let limitFilterEnable = false
        let excelIconEnable = true

        if(NewValue !== "byRegion") {
            switch (newValue) {
                case "chart":
                    // amountFilterEnable = true
                    limitFilterEnable = true
                    excelIconEnable = false
                    break;
            
                default:
                    pmFilterEnable = true
                    amountFilterEnable = true
                    limitFilterEnable = false
                    excelIconEnable = true
                    break;
            }
        } else {
            switch (newValue) {
                case "chart":
                    excelIconEnable = false
                    break;
                default:
                    excelIconEnable = true
                    break;
            }
        }

        this.setState({
            typeValue: newValue,
            isByPMFilterEnable: pmFilterEnable,
            isAmountFilterEnable: amountFilterEnable,
            isLimitFilterEnable: limitFilterEnable,
            isExcelIconEnable: excelIconEnable
        }, () => {
            this.fetchDataFromServer()
        })
    }

    // handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
    handleColumnClick = (_data) => {
        const { item, row } = _data
        const { NewValue } = this.state

        let typeStr = typeMapping(NewValue)

        const payload = {
            "year_label": "FY23",
           "type": typeStr,
           "analysis_name": row.original.analysis_by,
           "period": item.key
         
         }
        this.props.fetchPABSummaryDrilldown(payload);
        //     this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${ checkNan(colInfo.row[columnDetail.id])}`
    }

    // rowClick = (state, rowInfo, column, instance) => {
    rowClick = (_data) => {
        console.log('dck 0', _data)
        const { item, row } = _data
        this.setState({showInvoiceDetailModal: true},
            () => {
                this.handleColumnClick({item, row})

            })
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    switchContent = () => {
        const { typeValue, NewValue, showInvoiceDetailModal } = this.state

        const { incomeStatementState, } = this.props

        const { dassAOPData } = incomeStatementState

        const { pabBacklog } = dassAOPData

        const { rowData, drilldown } = pabBacklog
        
        console.log('dck 1', drilldown)

        if(rowData && rowData.length && typeValue === "summary") {
            rowData.forEach(x => {
                if(x.sub_column) {
                    if(x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per']
                        && x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per'] !== undefined) {
                            x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per'] = x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per'].toString().includes('%') ? x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per'] : x.sub_column.backlog_margin_per.sub_column['Q1|backlog_margin_per'] + '%'
                    }

                    if(x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per']
                        && x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per'] !== undefined) {
                            x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per'] = x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per'].toString().includes('%') ? x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per'] : x.sub_column.backlog_margin_per.sub_column['Q2|backlog_margin_per'] + '%'
                    }

                    if(x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per']
                        && x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per'] !== undefined) {
                            x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per'] = x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per'].toString().includes('%') ? x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per'] : x.sub_column.backlog_margin_per.sub_column['Q3|backlog_margin_per'] + '%'
                    }

                    if(x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per']
                        && x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per'] !== undefined) {
                            x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per'] = x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per'].toString().includes('%') ? x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per'] : x.sub_column.backlog_margin_per.sub_column['Q4|backlog_margin_per'] + '%'
                    }
                }
                
            })
        }
        

        switch (typeValue) {
            case 'summary':
                return (<div>
                    {pabBacklog?.rowHeader?.length ? <TableComponent
                        tableData={pabBacklog}
                        rowClick={this.rowClick}
                    /> : <CircularProgress style={{ margin: '0 50%' }} />}
                    {showInvoiceDetailModal &&
                        // <div>Show Modal</div>
                        <InvoiceDetailModal
                            // getInvoiceDetailGL={this.handleGLInvoiceSorting}
                            showModal={showInvoiceDetailModal}
                            modalCallBack={this.invoiceModalCallBack}
                            invoiceDetailState={drilldown}
                            // invoiceDetailStateFilter={invoiceDetailStateFilter}
                            invoiceLoading={drilldown.loading}
                            // showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                            showDrilldownHeaderValue={''}
                            // handleFilterChange={this.handleInvoiceFilterChange}
                            // handleInvoiceSorting={this.handleInvoiceSorting}
                            // invoiceDetailStateGL={invoiceDetailStateGL}
                            // invoiceLoadingGL={invoiceLoadingGL}
                            // financialDuration={this.props.financialDuration}
                            // handleDownloadFromBE={true}
                            // handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                    />
                    }
                </div>)
                break;
            case 'chart':
                return (
                    <div>
                        <BarChart
                            chartData={pabBacklog?.rowData}
                            showImprovementLineChart={true}
                            chartTitle={NewValue}
                            // topTenCountry={props.topTenCountry}
                            totalData={pabBacklog?.rowData[0]}
                            // subChartData={props.dso_improvement[0] && props.dso_improvement[0].East ? props.dso_improvement[0].East : []}
                            subChartData={[]}
                            // externalTabValue={props.externalTabValue}
                            // saveCapture={props.saveCapture}
                            // graphType={props.graphType}
                            receivablesTab={0}
                            // SeriesLabelEnabled={true}
                            // SeriesLabelColor={"black"}
                            // handleRegionHeaderClick={props.handleRegionHeaderClick}
                            // handleHemisphereHeaderClick={props.handleHemisphereHeaderClick}
                        />
                    </div>
                )
                break;
            default:
                break;
        }
    }

    handleAmountChange = (event) => {
        if (event.target) {
            this.setState({
                byAmountSelectorValue: event.target.value,
                downloadExcel: 'false'
            }, () => {
                this.fetchDataFromServer()
            });
        }
    }

    handleLimitChange = (event) => {
        if (event.target) {
            this.setState({
                byLimitSelectorValue: event.target.value,
            }, () => {
                this.fetchDataFromServer()
            });
        }
    }

    handlePMChange = (event, value) => {
        const { NewValue } = this.state

        let pmValue = []
        let pNameValue = []
        switch (NewValue) {
            case 'byManager':
                pmValue = value
                pNameValue = []
                break;
            case 'byProject':
                pmValue = []
                pNameValue = value
                break;
        
            default:
                break;
        }

        this.setState({
            byPMSelectorValue: pmValue,
            byPNameSelectorValue: pNameValue
        }, () => {
            this.fetchDataFromServer()
        })
    };

    handleExcelDownload = () => {
        this.setState({
            downloadExcel: 'true'
        }, () => {
            this.fetchDataFromServer()
        })
    }

    render() {
        const { NewValue, 
            typeValue, 
            byAmountSelectorValue, 
            byPMSelectorValue, 
            byPNameSelectorValue, 
            isAmountFilterEnable, 
            isLimitFilterEnable, 
            isByPMFilterEnable,
            isExcelIconEnable, 
            byLimitSelectorValue, } = this.state
        const { incomeStatementState, } = this.props
        const { dassAOPData } = incomeStatementState
        const { pabBacklog } = dassAOPData
        const { filters } = pabBacklog

        let projectNameFilter = {}
        let projectNameValue = []
        let projectNamelabel = ""

        switch (NewValue) {
            case 'byManager':
                projectNameFilter = filters.find(f => f.key === 'project_manager_name') || { key: '', name: '', value: [] }
                projectNameValue = projectNameFilter.value
                projectNamelabel = projectNameFilter.label
                break;
            case 'byProject':
                projectNameFilter = filters.find(f => f.key === "project_name") || { key: '', name: '', value: [] }
                projectNameValue = projectNameFilter.value
                projectNamelabel = projectNameFilter.label
                break;
        
            default:
                break;
        }
        
        

        return (

            <div>

                {<div>
                    <div>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={NewValue}
                                onChange={this.handleNewChange}
                                name='radio'
                                className='blockRadio'
                            >
                                <FormControlLabel
                                    value={'byRegion'}
                                    control={<Radio color='primary' />}
                                    label='Analysis by Region'
                                />
                                <FormControlLabel
                                    value={'byManager'}
                                    control={<Radio color='primary' />}
                                    label='Analysis by Project Manager'
                                />
                                <FormControlLabel
                                    value={'byProject'}
                                    control={<Radio color='primary' />}
                                    label='Analysis by Project Name'
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <Grid container>
                            <Grid item xs={11} sm={11}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sm={2}>
                                        <FormControl component='fieldset'>
                                            <RadioGroup
                                                value={typeValue}
                                                onChange={this.handleHemisphereChange}
                                                name='radio'
                                                className='radioGroupHorizontal'
                                            >
                                                <FormControlLabel
                                                    value={'summary'}
                                                    control={<Radio color='primary' />}
                                                    label='Summary'
                                                />
                                                <FormControlLabel
                                                    value={'chart'}
                                                    key={1}
                                                    control={<Radio />}
                                                    label={'Chart'}
                                                />
                                            

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {isByPMFilterEnable && 

                                        <Grid item xs={2} sm={2}>

                                            <FormControl variant="outlined" fullWidth  className='selectOutlineSmall maxw-200'  size='small' style={{ paddingBottom: '15px', marginTop: '-10px' }}>
                                                <Autocomplete
                                                    multiple
                                                    limitTags={1}
                                                    value={NewValue === 'byManager' ? byPMSelectorValue : byPNameSelectorValue}
                                                    options={projectNameValue.sort()}
                                                    getOptionLabel={(option) => option}
                                                    onChange={this.handlePMChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label={projectNamelabel}
                                                            placeholder={projectNamelabel}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                                id:"field-1"
                                                            }}
                                                        />
                                                    )}
                                                    getOptionSelected={(option,val) => option == val}
                                                    renderTags={(value, { params, onDelete }) =>
                                                        value.map((option, index) => (
                                                            <Tooltip title={option}>
                                                            <Chip
                                                                {...params}
                                                                key={option}
                                                                label={option.length > 5 ? option.substr(0,3) + ".." : option }
                                                                size="small"
                                                                onDelete={onDelete}
                                                            />
                                                            </Tooltip>
                                                        ))
                                                    }
                                                    renderOption={(option) => <Typography
                                                        style={{ fontSize: '10px' }}
                                                        noWrap>
                                                        <span>{option}</span>
                                                    </Typography>}
                                                />
                                            </FormControl>
                                        </Grid> 
                                    }

                                    {isAmountFilterEnable && 

                                        <Grid item xs={2} sm={2}>

                                            <FormControl variant="standard" fullWidth className='selectOutlineSmall maxw-200'  size='small' style={{  marginTop: '-8px' }}>
                                                <InputLabel>Amount</InputLabel>
                                                <Select
                                                    value={byAmountSelectorValue}
                                                    label="Amount"
                                                    // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                                    onChange={this.handleAmountChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select</em>
                                                    </MenuItem>
                                                    {amountFilterArr && amountFilterArr.length && amountFilterArr.map(item => {
                                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                    }
                                    

                                    {isLimitFilterEnable && 
                                        <Grid item xs={3} sm={3} className='maxw-200'>
                                            <FormControl variant="standard" className='selectOutlineSmall' fullWidth size='small' style={{  marginTop: '-8px' }} >
                                                <InputLabel>Limit</InputLabel>
                                                <Select
                                                    value={byLimitSelectorValue}
                                                    label="Limit"
                                                    // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                                    onChange={this.handleLimitChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select</em>
                                                    </MenuItem>
                                                    {limitFilterArr && limitFilterArr.length && limitFilterArr.map(item => {
                                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>


                            </Grid>
                            {isExcelIconEnable && 
                                <Grid item xs={1} sm={1}>
                                    <Tooltip title={'Download to Excel'} style={{float: 'right'}}>
                                        <img
                                            src={DownloadExcel}
                                            className='pointer'
                                            height='30'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.handleExcelDownload();
                                            }} />
                                    </Tooltip>
                                </Grid>
                            }
                        </Grid>

                    </div>

                    {this.switchContent()}

                </div>}

            </div>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchOrderbookData(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_DATA',
                        payload: payload
                    });
                },
                fetchPABSummaryTableData(payload) {
                    dispatch({
                        type: 'FETCH_PAB_BACKLOG_SUMMARY_TABLE_DATA',
                        payload: payload
                    });
                },
                fetchPABSummaryDrilldown(payload) {
                    dispatch({
                        type: 'FETCH_PAB_BACKLOG_SUMMARY_DRILLDOWN',
                        payload: payload
                    });
                },
            };
        }
    )(PABBacklogComponent);