import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import { Switch, Route } from "react-router-dom";
import DashboardAnalysisComponent from './DashboardAnalysis'
import OverdueComponent from './DashboardAnalysis/OverdueAnalysis'
import DSOComponent from './DashboardAnalysis/DSOAnalysis'
import ScatterComponent from './DashboardAnalysis/ScatterChart'
import DataBrowserComponent from './DataBrowser'
import AutoDistribution from './AutoDistribution'
import FCFReport from '../ECFM/Reports'
import PlanSummaryComponent from './ImprovementPlans/PlanSummaryComponent'
import ImprovementPlanComponent from './ImprovementPlans'
import AgeingComponent from './Ageing';
import DayReportComponent from './Reports/DayReports'
import CollectionsComponent from './DashboardAnalysis/CollectionActuals'
import InvoiceReportComponent from './Reports/InvoiceReports'
import MonthlyStatusReportComponent from './Reports/MonthlyStatusReports'
import { getAccessByMenuKey } from "../../utils/userManagement";

export class ReceivableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: [],
            loading: true,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: ''
        }
    }

    componentDidMount() {
        this.props.getAllUser({});
    }

    render() {
        const { authReducer } = this.props
        return (
            <Switch>
                { getAccessByMenuKey(authReducer.menuData, "aging_reports", "view") &&
                    <Route path="/dashboard/receivables/reports/aging">
                        <AgeingComponent 
                        tabSelectorValue={this.props.tabSelectorValue}
                        />
                    </Route>
                }
               
                { getAccessByMenuKey(authReducer.menuData, "dashboard_overdue", "view") &&
                    <Route path="/dashboard/receivables/dashboard-analytics/overdue">
                        <OverdueComponent tabSelectorValue={this.props.tabSelectorValue}/>
                    </Route>
                }
                { getAccessByMenuKey(authReducer.menuData, "dashboard_dso", "view") &&
                    <Route path="/dashboard/receivables/dashboard-analytics/dso">
                        <DSOComponent  tabSelectorValue={this.props.tabSelectorValue}/>
                        {/* {'The functionality is in progress. It will be enabled soon'}  */}
                    </Route>
                }
                <Route path="/dashboard/receivables/dashboard-analytics/scatter">
                        <ScatterComponent 
                        tabSelectorValue={this.props.tabSelectorValue}
                            />
                        {/* {'The functionality is in progress. It will be enabled soon'}  */}
                </Route>
                <Route path={`/dashboard/receivables/dashboard-analytics/auto-distribution`}>
                    <AutoDistribution />
                </Route>
                <Route path={`/dashboard/receivables/dashboard-analytics/fcf-report`}>
                    <FCFReport />
                </Route>
                <Route path={`/dashboard/receivables/dashboard-analytics/collections`}>
                    <CollectionsComponent  tabSelectorValue={this.props.tabSelectorValue}/>
                    {/* {'The functionality is in progress. It will be enabled soon'} */}
                </Route>
                { getAccessByMenuKey(authReducer.menuData, "aging_reports", "view") &&
                    <Route path="/dashboard/receivables/reports/day-reports">
                        <DayReportComponent 
                         tabSelectorValue={this.props.tabSelectorValue}

                        />
                    </Route>
                }
                { getAccessByMenuKey(authReducer.menuData, "aging_reports", "view") &&
                    <Route path="/dashboard/receivables/reports/invoice_list">
                        <InvoiceReportComponent tabSelectorValue={this.props.tabSelectorValue}/>
                    </Route>
                }
                { getAccessByMenuKey(authReducer.menuData, "aging_reports", "view") &&
                    <Route path="/dashboard/receivables/reports/month_status_report">
                        <MonthlyStatusReportComponent tabSelectorValue={this.props.tabSelectorValue}/>
                    </Route>
                }
                
                { getAccessByMenuKey(authReducer.menuData, "dashboard_analytics", "view") &&
                    <Route path="/dashboard/receivables/dashboard-analytics" >
                        <DashboardAnalysisComponent tabSelectorValue={this.props.tabSelectorValue}/>
                    </Route>
                }
                { getAccessByMenuKey(authReducer.menuData, "databrowser", "view") &&
                    <Route path="/dashboard/receivables/data-browser">
                        <DataBrowserComponent />
                    </Route>
                }
                { getAccessByMenuKey(authReducer.menuData, "databrowser", "view") &&
                    <Route path="/dashboard/receivables/os/data-browser">
                        {/* <DataBrowserComponent /> */}
                        {'The functionality is in progress. It will be enabled soon'}

                    </Route>
                }
                 { getAccessByMenuKey(authReducer.menuData, "plan_summary", "view") &&
                <Route path={`/dashboard/receivables/improvement-plans/plan-summary`}>
                    <PlanSummaryComponent 
                     tabSelectorValue={this.props.tabSelectorValue}

                    />
                </Route>
                }
            </Switch>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state.dashboardState.receivableData.ageingData,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                },
                getAllUser(data) {
                    dispatch({
                        type: "FETCH_ALL_USER",
                        payload: data,
                    });
                },
            };
        }
    )(ReceivableComponent);