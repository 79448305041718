import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, Tooltip } from "@material-ui/core";

import HighChart from './highchart';
import Table from './table';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../ImprovementPlan/comment';
import { useSelector, useDispatch } from "react-redux";
import AutomatedReporting from './AutomatedReporting'
import ImpactAnalysis from './ImpactAnalysis'
import Icon from '@material-ui/core/Icon';
import { APP_ICONS } from '../../../constant';
import {isSocketConnectionOpen, startWS} from '../../../utils/web-socket';

import EmailComponent from './emailTemplate'
import CommentModal from '../../Receivable/Ageing/ExternalComponent/CommentModal';
import { getAccessByMenuKey } from "../../../utils/userManagement";
import './index.scss';
import DrilldownComponent from '../Shared/InvoiceDetail'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const breakDownArray = [
  { id: 'monthly', value: 'Monthly' },
  { id: 'quarterly', value: 'Quarterly' },
  { id: 'fy', value: 'FY' },
]
// const filterArr = [
//   {id: 'lob', value: 'Line of Business'},
//   {id: 'country', value: 'Country'},
//   {id: 'region', value: 'Region'},
//   {id: 'functions', value: 'Functions'},
//   {id: 'projects', value: 'Projects'},
// ]


export default function GapToTarget(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [breakdownByArr, setBreakdownByArr] = React.useState(breakDownArray);
  const [filterByArr, setFilterByArr] = React.useState([]);
  const [kpiCategoryByArr, setKpiCategoryByArr] = React.useState([]);
  const [kpiTypeByArr, setKpiTypeByArr] = React.useState([]);
  const [kpiByArr, setKpiByArr] = React.useState([]);

  const [periodFilterArr, setPeriodFilterArr] = React.useState([])
  const [monthByArr, setMonthByArr] = React.useState([]);
  const [quarterByArr, setQuarterByArr] = React.useState([]);
  const [yearByArr, setYearByArr] = React.useState([]);

  const [breakdownByValue, setBreakdownByValue] = React.useState('fy');
  const [filterByValue, setFilterByValue] = React.useState('lob');
  const [kpiCategoryByValue, setKpiCategoryByValue] = React.useState('');
  const [kpiTypeByValue, setKpiTypeByValue] = React.useState('');
  const [kpiByValue, setKpiByValue] = React.useState('');
  const [clearFilterValue, setClearFilterValue] = React.useState(0);
  const [commentModalDisplay, setCommentModalDisplay] = React.useState(false)
  const [commentModalData, setCommentModalData] = React.useState({})
  const [uploadImageObj, setUploadImageObj] = React.useState({})
  const [chatDataCollection, setChatDataCollection] = React.useState({})

  const [monthByValue, setMonthByValue] = React.useState('');
  const [quarterByValue, setQuarterByValue] = React.useState('');
  const [yearByValue, setYearByValue] = React.useState('');

  const [showDrilldownModal, setShowDrilldownModal] = React.useState(false)

  

  const ecfmState = useSelector(state => state.ecfmState);
  const authState = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  const { drilldownData } = ecfmState
  const { showDrilldown } = drilldownData

  const { filter, defaultFilter } = ecfmState
  const [filterKPI, setFilterKPIArr] = React.useState([]);

  const handleDrilldownClose = () => {
    setShowDrilldownModal(false)

  }

  React.useEffect(() => {
    if (showDrilldown) {
      setShowDrilldownModal(true)
    }
  }, [showDrilldown])


  const setAllFilter = (params, origin) => {
    // console.log("---set filter", params)
    var thisKPI = []
    var thisKPIString = []
    var thisKPIType = []
    var thisKPITypeString = []
    var thisKPICategory = []
    var thisKPICategoryString = []
    var thisDimension = []
    var thisDimensionString = []

    params.forEach(y => {

      if (thisKPIString.indexOf(y.kpi) == -1) {
        thisKPI.push({ id: y.kpi, value: y.kpi })
        thisKPIString.push(y.kpi)
      }

      if (thisKPITypeString.indexOf(y.kpi_type) == -1) {
        thisKPIType.push({ id: y.kpi_type, value: y.kpi_type })
        thisKPITypeString.push(y.kpi_type)
      }

      if (thisKPICategoryString.indexOf(y.kpi_category) == -1) {
        thisKPICategory.push({ id: y.kpi_category, value: y.kpi_category })
        thisKPICategoryString.push(y.kpi_category)
      }

      if (thisDimensionString.indexOf(y.dimension) == -1) {
        thisDimension.push({ id: y.dimension, value: y.dimension_name })
        thisDimensionString.push(y.dimension)
      }
    })

    if (origin != 'kpi') {
      setKpiByArr(thisKPI)
    }

    if (origin != 'kpi_type') {
      setKpiTypeByArr(thisKPIType)
    }

    if (origin != "kpi_category") {
      setKpiCategoryByArr(thisKPICategory)
    }

    if (origin != "dimension") {
      setFilterByArr(thisDimension)
    }
  }




  const setFilterDataSource = (origin, value) => {
    let result = []
    switch (origin) {
      case 'kpi':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi == value) {
            result.push(x)

          }
        })
        if (result.length > 0 && value != "") {
          setKpiCategoryByValue(result[0].kpi_category)
          setKpiTypeByValue(result[0].kpi_type)
          setFilterByValue(result[0].dimension)
        }
        break;
      case 'kpi_category':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi_category == value) {
            result.push(x)
          }
        })
        if (result.length > 0 && value != "") {
          setKpiByValue("")
          setKpiTypeByValue("")
        }
        break
      case 'kpi_type':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi_type == value) {
            result.push(x)
          }
        })
        if (result.length > 0 && value != "") {
          setKpiCategoryByValue(result[0].kpi_category)
          setKpiByValue(result[0].kpi)
        }
        break
      case 'dimension':
        filterKPI.forEach(x => {
          if (value == "" || x.dimension == value) {
            result.push(x)
          }
        })
        if (result.length > 0 && value != "") {
          setKpiCategoryByValue(result[0].kpi_category)
          setKpiByValue(result[0].kpi)
          setKpiTypeByValue(result[0].kpi_type)
        } else {
          setKpiCategoryByValue("")
          setKpiByValue("")
          setKpiTypeByValue("")
        }
        break
    }



    setAllFilter(result, origin)
  }


  const handleBreakdownByChange = (event) => {
    if (event.target) {
      setBreakdownByValue(event.target.value);
    }
  };

  const handleYearMonthFilterChange = (event) => {
    if (event.target) {
      setMonthByValue(event.target.value);
      setYearByValue('');
      setQuarterByValue('');
    }
  };

  const handleYearLabelFilterChange = (event) => {
    if (event.target) {
      setYearByValue(event.target.value);
      setMonthByValue('');
      setQuarterByValue('');
    }
  };

  const handleQuarterFilterChange = (event) => {
    if (event.target) {
      setQuarterByValue(event.target.value);
      setYearByValue('');
      setMonthByValue('');
    }
  };

  const handleFilterByChange = (event) => {
    if (event.target) {
      setFilterByValue(event.target.value);
      setFilterDataSource('dimension', event.target.value);
    }
  };

  const handleKpiCategoryByChange = (event) => {
    if (event.target) {
      setKpiCategoryByValue(event.target.value);
      setFilterDataSource('kpi_category', event.target.value);
    }
  };

  const handleKpiTypeByChange = (event) => {
    if (event.target) {
      setKpiTypeByValue(event.target.value);
      setFilterDataSource('kpi_type', event.target.value);
    }
  };

  const handleKpiByChange = (event) => {
    if (event.target) {
      setKpiByValue(event.target.value);
      setFilterDataSource('kpi', event.target.value);
    }
  };

  const fetchImpactAnalysis = () => {
    const payload = {
      dimension: filterByValue,
      period: breakdownByValue,
      kpi_category: kpiCategoryByValue,
      kpi_type: kpiTypeByValue,
      kpi: kpiByValue,
      yearmonth : monthByValue,
      quarter : quarterByValue,
      year_label : yearByValue,
      isAutomated: props.newRadioSelectorValue == 8
    }
    props.setFCFParams({ yearMonth : payload.yearmonth, yearLabel : payload.year_label, quarter : payload.quarter,   dimension: filterByValue, period: breakdownByValue, kpi_category: kpiCategoryByValue, kpi_type: kpiTypeByValue, kpi: kpiByValue })

    props.fetchFCFImpactAnalysis(payload)

  }


  const fillFilter = () => {
    filter.forEach(x => {
      if (x.key == 'kpi') {
        setAllFilter(x.value, "")
        setFilterKPIArr(x.value)
      }

      else if (x.key == 'yearmonth') {
        setMonthByArr(x.value)
        setMonthByValue(x.value[9])
      }

      else if (x.key == 'quarter') {
        // var val = x.value
        // var tmp = []
        // for (var key of Object.keys(val)) {
        //   var val_two = val[key]
        //   for (var key_two of Object.keys(val_two)) {
        //     if (key_two == "label") {
        //       tmp.push(val_two[key_two])
        //     }
        //   }
        // }
        setQuarterByArr(x.value)
      }

      else if (x.key == 'year_label') {
        setYearByArr(x.value)
        // setYearByValue(x.value[0])
      }
    })
  }

  const cleanFilter = () => {
    setBreakdownByValue("fy")
    setFilterByValue("lob")
    setFilterDataSource('dimension', "lob");
    setKpiByValue("")
    setKpiCategoryByValue("")
    setKpiTypeByValue("")
    fillFilter()
    setMonthByValue("31 Jan 2023")
    // setMonthByValue("")
    setQuarterByValue("")
    setYearByValue("")
    // setYearByValue(defaultFilter.period.year_label)
  }

  if (filter && filter.length > 0 && kpiByArr && kpiByArr.length == 0) {
    fillFilter()
  }

  const clearFilter = () => {
    cleanFilter();
    setClearFilterValue(clearFilterValue + 1)
  }

  const showCommentModal = (data) => {
    setCommentModalDisplay(true);
    setCommentModalData(data)
  }

  const  modalCallBack = () => {
    setCommentModalDisplay(false)
  };


  const  handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
    const sendChatObject = {
      'body': inputValue,
      'type': 'text',
      'tagged_user': taggedUser,
    };
    // console.log(sendChatObject,this.socketCollection, "--ini nih obj")
    if (inputValue) {
      if (!isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        delete this.socketCollection[rowData.channel];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        this.socketCollection[rowData.channel].send(
            JSON.stringify(sendChatObject));
        chatDataCollection[rowData.channel] = '';
        setChatDataCollection(chatDataCollection)
      }
    }
  };

  const onImageInputHandler = (event, props) => {
    let imagePreview;
    if (event.target.files[0].type == 'application/pdf' ||
        event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      imagePreview = '';
    } else {
      imagePreview = URL.createObjectURL(event.target.files[0]);
    }
    let imageName = event.target.files[0].name;
    uploadImageObj[props.channel] = {
      imagePreview,
      imageName,
      file: event.target.files[0],
    };

    setUploadImageObj(uploadImageObj)
  };

  const handleImageOptions = (event, props) => {
    if (event === 'approve') {
      const imageObj = uploadImageObj[props.channel];
      if (imageObj) {
        const payloadData = {
          file: imageObj.file,
          id: props.channel,
        };
        this.props.uploadChatFile(payloadData);
      }
    }
    if (event === 'cancel') {
      this.imagePreview = null;
    }
    if (document.getElementById('upload-icon')) {
      document.getElementById(`upload-icon`).value = '';
    }
    document.getElementById(`upload-icon${props.channel}`).value = '';
    delete uploadImageObj[props.channel];
    setUploadImageObj(uploadImageObj)

  };


  const handleColumnDataClick = (_data, parentData, itemData) => {
      setShowDrilldownModal(true)
      // console.log(_data, "--data yw")
      const payload = {
        year_label: yearByValue ? yearByValue : undefined,
        quarter: quarterByValue ? quarterByValue : undefined,
        yearmonth: monthByValue ? monthByValue : undefined,
        ..._data["row"]
      }

      dispatch({
        type: "FETCH_IMPACT_DRILLDOWN_DATA",
        payload
      })
  }



  React.useEffect(() => {
    if(clearFilterValue == 0){
      dispatch({
        type: 'FETCH_FCF_FILTER',
        payload: {isAutomated: props.newRadioSelectorValue == 8}
    });
    }
    // props?.fetchFCFFilter()
    // props.setFCFParams({ dimension : filterByValue, period : breakdownByValue, kpi_category : kpiCategoryByValue, kpi_type : kpiTypeByValue, kpi : kpiByValue })
  }, [clearFilterValue])

  // React.useEffect(() => {
  //   props.setFCFParams({ dimension : filterByValue, period : breakdownByValue, kpi_category : kpiCategoryByValue, kpi_type : kpiTypeByValue, kpi : kpiByValue })
  // }, [filterByValue, breakdownByValue, kpiCategoryByValue, kpiTypeByValue, kpiByValue])


  const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

  return (
    <div className="gapToTarget mt-20">
      
      <div className="filterSection">
      <Grid container spacing={3}>
          <Grid item xs={11}>
             <h4 className="text-center">FCF Target Breakdown by Key Indicators </h4>
          </Grid>
          <Grid item xs={1} className="text-end">
            {
          (getAccessByMenuKey(props.menuData, "auto_emails", "download") || getAccessByMenuKey(props.menuData, "impact_analysis", "download")) &&
            <Tooltip title={'Download to Excel'}>
              <img
                src={downloadExcelIcon}
                height='35'
                className='download-icon'
                onClick={(event) => {
                  event.preventDefault();
                  props.handleDownloadExcel();
                }}
              />
            </Tooltip>
          }
          </Grid>
      </Grid>
      {
        props.newRadioSelectorValue == 8 &&
        <EmailComponent emailParameter={{dimension: filterByValue,
          period: breakdownByValue,
          kpi_category: kpiCategoryByValue,
          kpi_type: kpiTypeByValue,
          kpi: kpiByValue}}
        />
      }
      <div className="flex ai-center jc-space-between">
        <Grid container spacing={1} className="dropdown-filter">
          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Month</InputLabel>
              <Select
                value={monthByArr.length > 0? monthByValue : ''}
                label="Month"
                onChange={handleYearMonthFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {monthByArr && monthByArr.length &&
                    monthByArr.map(item => {
                      return <MenuItem value={item} key={item}>{item}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Quarter</InputLabel>
              <Select
                value={quarterByArr.length > 0? quarterByValue : ''}
                label="Quarter"
                onChange={handleQuarterFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {quarterByArr && quarterByArr.length &&
                    quarterByArr.map(item => {
                      return <MenuItem value={item.value} key={item.label} >{item.label}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Annual</InputLabel>
              <Select
                value={yearByArr.length > 0 ? yearByValue : ''}
                label="Annual"
                onChange={handleYearLabelFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {yearByArr && yearByArr.length &&
                    yearByArr.map(item => {
                      return <MenuItem value={item} key={item} >{item}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div className="flex ai-center  margin-top">
        <Grid container spacing={1} className="dropdown-filter">
          <div className="filter-section-impact-analysis">
            <Grid item xs={3} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>Dimensions</InputLabel>
                <Select
                  value={filterByArr.length > 0 ? filterByValue : ""}
                  label="Quarter"
                  onChange={handleFilterByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {filterByArr && filterByArr.length &&
                    filterByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={2} className="maxw-200"/> */}

            <Grid item xs={4} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>KPI Category</InputLabel>
                <Select
                  value={kpiCategoryByArr.length > 0 ? kpiCategoryByValue : ""}
                  label="Quarter"
                  onChange={handleKpiCategoryByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {kpiCategoryByArr && kpiCategoryByArr.length &&
                    kpiCategoryByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>KPI Type</InputLabel>
                <Select
                  value={kpiTypeByArr.length > 0 ? kpiTypeByValue : ""}
                  label="Quarter"
                  onChange={handleKpiTypeByChange}
                >

                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {kpiTypeByArr && kpiTypeByArr.length &&
                    kpiTypeByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>KPI</InputLabel>
                <Select
                  value={kpiByValue}
                  label="Quarter"
                  onChange={handleKpiByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {kpiByArr && kpiByArr.length &&
                    kpiByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          </div>
          <Grid item xs={3}className="">
          <div className="btn-section" style={{display:'flex', justifyContent:'flex-end'}}>
              {(getAccessByMenuKey(props.menuData, "impact_analysis", "add") || getAccessByMenuKey(props.menuData, "impact_analysis", "edit")) &&
            <Button  variant="contained" size="small" className="newButton btnBlue btnApply uppercase mr-10 w-30" onClick={fetchImpactAnalysis}>Apply</Button>
              }
              {(getAccessByMenuKey(props.menuData, "impact_analysis", "add") || getAccessByMenuKey(props.menuData, "impact_analysis", "edit")) &&
              <Button variant="contained" size="small" className="newButton btnRed btnClear uppercase mr-10 w-30" onClick={clearFilter}>Clear</Button>
                  }
          </div>
            </Grid>
          
          
        </Grid>
      </div>
      </div>
      
      <Grid container spacing={3} alignItems="center" className="mt-10">
        <Grid item xs={12} sm={props.newRadioSelectorValue == 8 ? 12 : 8} className="">
          {props.newRadioSelectorValue == 8 ?
            <AutomatedReporting
              usersList={props.usersList}
              handleCollaborateClick={props.handleCollaborateClick}
              commentModalDisplay={commentModalDisplay}
              handleImprovementPlanClick={props.handleImprovementPlanClick}
              commentModalData={commentModalData}

            />
            :
            <ImpactAnalysis
              usersList={props.usersList}
              handleCollaborateClick={props.handleCollaborateClick}
              commentModalDisplay={commentModalDisplay}
              handleImprovementPlanClick={props.handleImprovementPlanClick}
              commentModalData={commentModalData}
              handleColumnClick={handleColumnDataClick}
            />
            }
        </Grid>
        {
          props.newRadioSelectorValue != 8 &&
          <Grid item xs={12} sm={4}>
            <HighChart />
          </Grid>}
      </Grid>

      <Modal
        className="collaborate-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <CommentBox />
        </div>
      </Modal>
      {commentModalDisplay && <CommentModal
        showModal={commentModalDisplay}
        commentRowData={commentModalData}
        modalCallBack={modalCallBack}
        commentsData={commentModalData}
        userDataState={authState.userData}
        sendCommmentCallback={handleSendChatModalFunction}
        buValue={''}
        onImageInputHandler={onImageInputHandler}
        handleImageOptions={handleImageOptions}
        uploadImageObj={uploadImageObj}
        allUsers={props.usersList}
        menuData={authState.menuData}
      />}

    <DrilldownComponent
        showModal={showDrilldownModal}
        handleClose={handleDrilldownClose} />
    </div>
  );
}
