import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DragList from './DragList';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


export default function Dashboard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-content">
      <Grid container>
          <Grid item xs={12} className="ml-10 mb-10">
              <AppBar position="static" color="default" className="custom-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Kanban board" {...a11yProps(0)} />
                  <Tab label="View Improvement Plan" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
          </Grid>
      </Grid>
      <Grid container>
          <Grid item xs={12}>
              <TabPanel value={value} index={0} className="tab-panel">
                <Grid container>
                    <Grid item xs={12} className="flex ai-center jc-space-between">
                        <div className="description text-primary-custom">Drag and Drop plans to move from one tile to other</div>
                        <div className="action-hide-show">
                          <div className="title mb-10">Click to hide/show</div>
                          <div className="btn-list">
                            <Button variant="contained" className="mr-20 btn-custom nextWeek" size="small">DUE NEXT WEEK</Button>
                            <Button variant="contained" className="mr-20 btn-custom overdue" size="small">OVERDUE</Button>
                            <Button variant="contained" className="btn-custom completed" size="small">Completed</Button>
                          </div>
                        </div>
                    </Grid>
                </Grid>
                <DragList/>
              </TabPanel>
          </Grid>
      </Grid>
    </div>
  );
}
