import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import React from 'react';
import { connect } from "react-redux";
import NewPagination from "../../../common/components/CustomPagination";

import { numberWithCommas } from '../../../../utils/numberUtils'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class MPRTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
            manualAmountValue:'',
            selectedDataField:'',
            selectedKey:'',
            showModal: false
        }
    }

    componentDidMount() {
    }

    handleValueTransform = (data, _oo, showPercentage) => {
        console.log('data, _oo', data, _oo)
        let modifiedData;
        modifiedData = Math.round(data)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (showPercentage) {
            modifiedData += '%'
        }
        return modifiedData 
    }

    handleClose = () => {
        this.setState({ showModal: false })
    };

    handleSubmit = () => {
        console.log('submit=====>')
        const {manualAmountValue, selectedKey, selectedDataField} = this.state;
        let payload = {
            key: selectedKey,
            data_field: selectedDataField,
            value : parseFloat(manualAmountValue)
        }

        this.props.postManualAmountData(payload)
        this.handleClose()

    }

    handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }     
        }
        return false
    }

    handleSubColumnChangeHeader = (data, key) => {
        if (data && data.length) {
            return data.map((item, i) => {
               
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        let showPercentage = this.handleAppendPercentage(props.original);
                        let myClassName = showPercentage ? 'percentageRowData': ''

                        return (
                            <div className='text-center'>
                                {

                                    <span className={myClassName}>{props.original.sub_column && props.original.sub_column[item.key] ? this.handleValueTransform(props.original.sub_column[item.key][key], props.original, showPercentage) : ''}</span>
                                    // <span>{handleTableTransform(props.original, item)}</span>
                                }
                            </div>
                        )
                    },
                    headerStyle: {
                        overflow: 'visible',
                        borderRight: i == data.length -1 ? '1px solid #423970' : ''

                    },
                    style: {
                        borderRight: i == data.length -1 ? '1px solid #423970' : ''
                    }
                }
            })
        }
    }

    changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        fixed: 'left',
                        columns: [{
                            Header: item.sub_headers && item.sub_headers.length ? item.sub_headers[0].label : item.label,
                            id: item.key,
                            Cell: (props) => {

                                return (
                                    <React.Fragment>
                                        <>{props.original.data_field}</>
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }
                return {
                    Header: item.label,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item.key),
                    headerStyle: {
                        borderRight: '1px solid #423970'
                    },
                    style: {
                        borderRight: '1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    handleValueOnChange = (event) => {
        this.setState({manualAmountValue: event.target.value.replace(/,/g, '')})
    }

    render() {

        let { loading, pivotHeaderData, pivotRowData } = this.props.incomeStatementState;
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData)

        const body = (
            <div className='customModal forecastModal'>
                <div className='header'>
                    <h2>Add Manual Amount</h2>
                    <Icon onClick={this.handleClose}>close</Icon>
                </div>
                <div className='modal-content'>
                    <form>
                        <FormControl className="w-50 mb-20 flex">
                            <TextField label="Amount"
                                 value={this.state.manualAmountValue}
                                placeholder={'Amount'}
                            onChange={this.handleValueOnChange}
                            />
                        </FormControl>
                    </form>
                    <Grid container justify="flex-end" className='mt-20'>
                        <Button variant="contained" color="primary" className='mr-10' onClick={this.handleSubmit}>Submit</Button>
                        <Button variant="contained" onClick={this.handleClose}>Cancel</Button>
                    </Grid>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                {loading && <div className='progressfullpage'><CircularProgress /></div>}
                {pivotRowData && pivotRowData.length ? <ReactTableFixedColumns
                    data={pivotRowData}
                    columns={pivotHeaderData}
                    loading={loading}
                    defaultPageSize={10}
                    pageSize={pivotRowData.length}
                    className={'newCustomTable fixedFirstColumTable lastRowDefaultBGColor'}
                    PaginationComponent={NewPagination}
                    pageSizeOptions={[10, 20, 30]}
                    resizable={true}
                /> : null}
                <Modal
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModal}>
                        {body}
                    </Fade>
                </Modal>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducer: state.authReducer,
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(MPRTableComponent);
