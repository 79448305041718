import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import BUPLComponent from './BU-pl'
import FunctionalComponent from './Functional-pl'
import SalesTechnologyComponent from './Sales-Technology'
import MPRComponent from './MPR'
import NewComponent from './New'
import NewOriginationComponent from './NewOrigination'
import AdhocChartComponent from './AdhocChart'
import DataBrowserComponent from './DataBrowser'
import { Switch, Route } from "react-router-dom";
import { getAccessByMenuKey } from "../../utils/userManagement";

export class IncomeStatementComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        const { authReducer } = this.props
        return (
            <div>
                { getAccessByMenuKey(authReducer.menuData, "bu_pnl", "view") &&
                    <Switch>
                        <Route path={`/dashboard/income-statement/p&lreports/bu-pl`} exact>
                            <BUPLComponent />
                        </Route>
                        <Route path={`/dashboard/income-statement/p&lreports/one-new-origination`} exact>
                            <NewOriginationComponent />
                        </Route>
                        <Route path={`/dashboard/income-statement/p&lreports/one-new`} exact>
                            <NewComponent />
                        </Route>
                        <Route path={`/dashboard/income-statement/p&lreports/functional-pl`} exact>
                            {<FunctionalComponent />}
                            {/* {'The functionality is in progress. It will be enabled soon'} */}
                        </Route>
                        <Route path={`/dashboard/income-statement/p&lreports/sales-technology-pl`} exact>
                            {<SalesTechnologyComponent />}
                            {/* {'The functionality is in progress. It will be enabled soon'} */}
                        </Route>
                        <Route path={`/dashboard/income-statement/p&lreports/mpr`}>
                            <MPRComponent />
                        </Route>
                        {/* <Route path={`/dashboard/income-statement/dashboard-analytics`}>
                            <AdhocChartComponent />
                        </Route> */}
                        <Route path={`/dashboard/income-statement/data-browser`}>
                            <DataBrowserComponent />
                            {/* {'The functionality is in progress. It will be enabled soon'}  */}
                        </Route>
                        <Route path={`/dashboard/income-statement/ad-hoc`}>
                            <AdhocChartComponent />
                            {/* {'The functionality is in progress. It will be enabled soon'}  */}
                        </Route>    
                    </Switch>
                }
            </div>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(IncomeStatementComponent);