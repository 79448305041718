import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import CommentBox from '../../CommentFunctionality';
import CommentBox from "./comment.jsx";
import { startWS, isSocketConnectionOpen } from '../../../utils/web-socket'
import { useSelector } from "react-redux";
import { getAccessByMenuKey } from "../../../utils/userManagement";
import { toastr } from "react-redux-toastr";


function getSteps() {
  return ['Create/Update', 'Approval', 'Closure'];
}

const timeFrameArray = [
  { title: 'Monthly', value: 'monthly' },
  { title: 'Quarterly', value: 'quarterly' },
  { title: 'Half Yearly', value: 'half yearly' },
  { title: 'Annually', value: 'annually' },
]

export default function ImprovementPlan(props) {
  const authReducerState = useSelector(state => state.authReducer);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [planTitleValue, setPlanTitleValue] = React.useState('');
  const [reportDate, setReportDate] = React.useState(new Date());

  const [createdOnValue, setCreatedOnValue] = React.useState(new Date().toDateString());
  const [closedOnValue, setClosedOnValue] = React.useState(new Date());
  const [createdByValue, setCreatedByValue] = React.useState(authReducerState?.userData?.full_name || '');
  const [planDescriptionValue, setPlanDescriptionValue] = React.useState('');
  const [gapValue, setGapValue] = React.useState('');
  const [actualCostValue, setActualCostValue] = React.useState('');
  const [L0Value, setL0Value] = React.useState('');
  const [L1Value, setL1Value] = React.useState('');
  const [L2Value, setL2Value] = React.useState('');
  const [L3Value, setL3Value] = React.useState('');
  const [commentValue, setCommentValue] = React.useState('');
  const [filterKPI, setFilterKPIArr] = React.useState([]);
  const [planStatusArr, setPlanStatusArr] = React.useState([]);
  const [timeFrameArr, setTimeFrameArray] = React.useState(timeFrameArray);
  const [timeFrameValue, setTimeFrameValue] = React.useState('');
  const [kpiByValue, setKpiByValue] = React.useState('');

  const [componentValue, setComponentValue] = React.useState('');
  const [kpiType, setKPIType] = React.useState('');
  const [kpiByArr, setKpiByArr] = React.useState([]);

  const [kpiCategory, setKPICategory] = React.useState('');
  const [remedialValue, setRemedialValue] = React.useState('');
  const [rootCauseValue, setRootCauseValue] = React.useState('');
  const [incrementalBenefitValue, setIncrementalBenefitValue] = React.useState('');

  const [currentProblemValue, setCurrentProblemValue] = React.useState('');

  const [planStatusValue, setPlanStatusValue] = React.useState('Ideation');
  const [businessValue, setBusinessValue] = React.useState('');
  const [respPartyChange, setRespPartyChange] = React.useState('');
  const steps = getSteps();
  const externalState = useSelector(state => state.dashboardState.receivableData.ageingData.external);
  const ecfmState = useSelector(state => state.ecfmState);

  const [kpiCategoryArr, setKPICategoryArr] = React.useState([]);
  const [kpiTypeArr, setKPITypeArr] = React.useState([]);


  React.useEffect(() => {
    if (props.prefilledPlanValue){
      const {kpi, kpi_category, kpi_type, business_head, current, gap, target} = props.prefilledPlanValue;
      // console.log(props.prefilledPlanValue,"--ini dia")
      setTimeout(() => {
        setKpiByValue(kpi)
        // setFilterDataSource('kpi', kpi);
         setKPICategory(kpi_category)
          setKPIType(kpi_type)
      }, 1000);
     
      setBusinessValue(business_head)
      setGapValue(gap)
      setActualCostValue(current)
    }
  }, [])


  const handlePlanStatusChange = (event, value) => {
    console.log(event.target.value, 'ppspspssp', value)
    setPlanStatusValue(event.target.value);
  };

  const handleCurrentProblemChange = (event, value) => {
    setCurrentProblemValue(event.target.value);
  };

  const handleRootCauseAnalysisChange = (event, value) => {
    setRootCauseValue(event.target.value);
  };

  const handleRemedialPlanChange = (event, value) => {
    setRemedialValue(event.target.value);
  };

  const handlePlanTitleChange = (event, value) => {
    setPlanTitleValue(event.target.value);
  };

  const handleCreatedByValueChange = (event, value) => {
    setCreatedByValue(event.target.value);
  };

  const handlePlanDescriptionValueChange = (event, value) => {
    setPlanDescriptionValue(event.target.value);
  };

  const handleActualCostChange = (event, value) => {
    setActualCostValue(event.target.value);
  };

  const handleCostChange = (event, value) => {
    setGapValue(event.target.value);
  };

  const handleL0Change = (event, value) => {
    setL0Value(event.target.value);
  };

  const handleL1Change = (event, value) => {
    setL1Value(event.target.value);
  };

  const handleClosedOnValueChange = (event, value) => {
    setClosedOnValue(event.target.value);
  };

  const handleL3Change = (event, value) => {
    setL3Value(event.target.value);
  };


  const handleKpiByChange = (event) => {
    if (event.target) {
      setKpiByValue(event.target.value);
      setFilterDataSource('kpi', event.target.value);
    }
  };

  const handleKPITypeChange = (event, value) => {
    console.log(event.target, 'ppspspssp', value)
    setKPIType(event.target.value);
    setFilterDataSource('kpi_type', event.target.value);
  }

  const handleKPICategoryChange = (event) => {

    setKPICategory(event.target.value);
    setFilterDataSource('kpi_category', event.target.value);
  }

  const handleResponsiblePartyChange = (event) => {
    setRespPartyChange(event.target.value);
  }

  const handleCommentValueChange = (event) => {
    setCommentValue(event.target.value);
  }

  const handleTimeFrameChange = (event) => {
    setTimeFrameValue(event.target.value);
  }

  const handleIncrementalBenefitValueChange = (event) => {
    setIncrementalBenefitValue(event.target.value);
  }

  const handleComponentValueChange = (event) => {
    setComponentValue(event.target.value);
  }

  const handleBusinessValueChange = (event) => {
    setBusinessValue(event.target.value);
  }


  const handleSubmit = (event) => {
    if(planDescriptionValue && planDescriptionValue != ''){
      let payload = {
        plan_id: ecfmState.createPlanFormIdData,
        plan_title: planTitleValue,
        created_on: new Date(createdOnValue).toJSON(),
        closed_on: new Date(closedOnValue).toJSON(),
        user_name: createdByValue,
        report_to: respPartyChange,
        time_frame: timeFrameValue,
        cost: parseInt(actualCostValue),
        fcf: planDescriptionValue,
        business_head: businessValue,
        component: componentValue,
        gap: parseInt(gapValue),
        benefit: parseInt(incrementalBenefitValue),
        kpi_type: kpiType,
        kpi: kpiByValue,
        kpi_category: kpiCategory,
        problem: currentProblemValue,
        root_cause: rootCauseValue,
        remedial_plan: remedialValue,
        status: planStatusValue,
        comments: commentValue

      }

      props.handleSubmitData(payload)

      clearFilter()
    }
    else {
      toastr.error("FCF Improvement Plan cannot be blank")
    }
    

  }


  const setAllFilter = (params, origin) => {
    // console.log("---set filter", params)
    var thisKPI = []
    var thisKPIString = []
    var thisKPIType = []
    var thisKPITypeString = []
    var thisKPICategory = []
    var thisKPICategoryString = []


    params.forEach(y => {

      if (thisKPIString.indexOf(y.kpi) == -1) {
        thisKPI.push({ id: y.kpi, value: y.kpi })
        thisKPIString.push(y.kpi)
      }

      if (thisKPITypeString.indexOf(y.kpi_type) == -1) {
        thisKPIType.push({ id: y.kpi_type, value: y.kpi_type })
        thisKPITypeString.push(y.kpi_type)
      }

      if (thisKPICategoryString.indexOf(y.kpi_category) == -1) {
        thisKPICategory.push({ id: y.kpi_category, value: y.kpi_category })
        thisKPICategoryString.push(y.kpi_category)
      }
    })

    if (origin != 'kpi') {
      setKpiByArr(thisKPI)
    }

    if (origin != 'kpi_type') {
      setKPITypeArr(thisKPIType)
    }

    if (origin != "kpi_category") {
      setKPICategoryArr(thisKPICategory)
    }

  }




  const setFilterDataSource = (origin, value) => {
    let result = []
    switch (origin) {
      case 'kpi':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi == value) {
            result.push(x)

          }
        })
        if (result.length > 0 && value != "") {
          setKPICategory(result[0].kpi_category)
          setKPIType(result[0].kpi_type)
        }
        break;
      case 'kpi_category':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi_category == value) {
            result.push(x)
          }
        })
        if (result.length > 0 && value != "") {
          setKPIType("")
        }
        break
      case 'kpi_type':
        filterKPI.forEach(x => {
          if (value == "" || x.kpi_type == value) {
            result.push(x)
          }
        })
        if (result.length > 0 && value != "") {
          setKPICategory(result[0].kpi_category)
          setKpiByValue(result[0].kpi)
        }
        break
    }



    setAllFilter(result, origin)
  }

  if (ecfmState && ecfmState.createPlanFormData && ecfmState.createPlanFormData.length && kpiByArr.length == 0) {
    ecfmState.createPlanFormData.forEach(item => {
      if (item.key == 'kpi') {
        setAllFilter(item.value, "")
        setFilterKPIArr(item.value)
      } else if (item.key == 'plan_status') {
        setPlanStatusArr(item.value)
      }
    })
  }


  const clearFilter = () => {
    setCommentValue('')
    setRespPartyChange('')
    setKPIType('')
    setKpiByValue('')
    setKPICategory('')
    setIncrementalBenefitValue('')
    setRootCauseValue('')
    setRemedialValue('')
    setCurrentProblemValue('')
    setBusinessValue('')
    setTimeFrameValue('')
    setGapValue('')
    setActualCostValue('')
    setPlanDescriptionValue('')
    // setCreatedByValue('')
    setClosedOnValue(new Date())
    setPlanTitleValue('')
    setPlanStatusValue('Ideation')
    setComponentValue('')
  }

  const handleCancel = () => {
    clearFilter()
  }


  return (
    <Grid className="mt-20 improvementPlan">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
          // }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }

          return (
            <Step key={label}>
              <StepLabel completed={completed[index]}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 &&
        <Grid container spacing={2} className="flex .jc-space-between">
          <Grid item xs={12} sm={7}>
            <div className="mt-30 form-improvement filterSection">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} >
                  <TextField
                    disabled
                    id="pip-plan-id"
                    label="Plan ID"
                    value={ecfmState.createPlanFormIdData}
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <TextField

                    id="pip-created-on"
                    label="Created On"
                    disabled
                    value={createdOnValue}
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        openTo="date"
                        size="small"
                        format="dd-MMM-yyyy"
                        views={["year", "month", "date"]}
                        className='maxw-200'
                        label="Closed On"
                        value={closedOnValue}
                        minDate={new Date()}
                        onChange={setClosedOnValue}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                </Grid>
                {/* <Grid item xs={12} sm={3} >
                  <TextField
                    id="pip-plan-plan-title"
                    label="Plan Title"
                    value={planTitleValue}
                    onChange={handlePlanTitleChange}
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid> */}
                <Grid item xs={12} sm={4} >
                  <TextField
                    disabled
                    id="pip-plan-created-by"
                    label="Username"
                    value={createdByValue}
                    onChange={handleCreatedByValueChange}
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>Report To</InputLabel>
                    <Select
                      value={respPartyChange}
                      onChange={handleResponsiblePartyChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {externalState.allUser && externalState.allUser.length &&
                        externalState.allUser.map((item, index) => {
                          return <MenuItem value={item.full_name} key={index}>{item.full_name}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>Time Frame</InputLabel>
                    <Select
                      value={timeFrameValue}
                      onChange={handleTimeFrameChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {timeFrameArr && timeFrameArr.length &&
                        timeFrameArr.map((item, index) => {
                          return <MenuItem value={item.value} key={index}>{item.title}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} >
                  <TextField

                    id="pip-plan-desc"
                    label="FCF Improvement Plan Overview"
                    value={planDescriptionValue}
                    onChange={handlePlanDescriptionValueChange}
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <TextField
                    type="number"
                    id="pip-plan-cost"
                    label="Current Gap to Target"
                    value={gapValue}
                    fullWidth
                    onChange={handleCostChange}
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <TextField
                    type="number"
                    id="pip-plan-actual-cost"
                    label="Incremental Cost"
                    value={actualCostValue}
                    fullWidth
                    onChange={handleActualCostChange}
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <TextField
                    type="number"
                    id="pip-plan-actual-cost"
                    label="Incremental Benefit"
                    value={incrementalBenefitValue}
                    fullWidth
                    onChange={handleIncrementalBenefitValueChange}
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>KPI Type</InputLabel>
                    <Select
                      value={kpiType}
                      onChange={handleKPITypeChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {kpiTypeArr && kpiTypeArr.length &&
                        kpiTypeArr.map((item, index) => {
                          return <MenuItem value={item.value} key={index.value}>{item.value}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>KPI Category</InputLabel>
                    <Select
                      value={kpiCategory}
                      onChange={handleKPICategoryChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {kpiCategoryArr && kpiCategoryArr.length &&
                        kpiCategoryArr.map((item, index) => {
                          return <MenuItem value={item.value} key={index.value}>{item.value}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>KPI</InputLabel>
                    <Select
                      value={kpiByValue}
                      onChange={handleKpiByChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {kpiByArr && kpiByArr.length &&
                        kpiByArr.map((item, index) => {
                          return <MenuItem value={item.value} key={index.value}>{item.value}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                   


                    <TextField
                      disabled
                    id="pip-created-on"
                    label="Plan Status"
                    value={planStatusValue}
                    fullWidth
                    // onChange={handleBusinessValueChange}
                    variant="standard"
                    size="small"
                  />
                      
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <TextField
                    id="pip-created-on"
                    label="Business Head"
                    value={businessValue}
                    fullWidth
                    onChange={handleBusinessValueChange}
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <FormControl variant="standard" size='small' className='w-100'>
                    <InputLabel>Component</InputLabel>
                    <Select
                      value={componentValue}
                      onChange={handleComponentValueChange}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {planStatusArr && planStatusArr.length &&
                        planStatusArr.map((item, index) => {
                          return <MenuItem value={item.value} key={index.value}>{item.value}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={currentProblemValue}
                    onChange={handleCurrentProblemChange}
                    id="pip-created-on"
                    label="Current Problem"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={rootCauseValue}
                    onChange={handleRootCauseAnalysisChange}
                    id="pip-created-on"
                    label="Root Cause Analysis"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={remedialValue}
                    onChange={handleRemedialPlanChange}
                    id="pip-created-on"
                    label="Remedial Plan"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={commentValue}
                    onChange={handleCommentValueChange}
                    id="pip-created-on"
                    label="Comment"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>

              </Grid>
              <Grid container justify="flex-end" className='mt-20'>
                <>
                  {/* <Button variant="contained" className='mr-10'>Save as Draft</Button> */}
              {getAccessByMenuKey(props.menuData, "create_initiatives", "add") &&
                  <Button className='mr-10 newButton btnBlue btnSave uppercase' variant="contained" color="primary" onClick={() => handleSubmit()}>Save Draft</Button>
                    }
                  <Button variant="contained" className='newButton btnRed btnCancel uppercase' onClick={() => handleCancel()}>Cancel</Button>
                </>
              </Grid>
            </div>
          </Grid>
          <div className="divider" />
          <Grid item xs={5} className="comment-box">
          {getAccessByMenuKey(props.menuData, "create_initiatives", "chat") &&

            <CommentBox
            // commentRowData={commentRow}
            // commentsData={commentModalData}
            // userDataState={authState.userData}
            // sendCommmentCallback={handleSendChatModalFunction}
            // buValue={"MSMT"}
            // onImageInputHandler={onImageInputHandler}
            // handleImageOptions={handleImageOptions}
            // uploadImageObj={uploadImageObj}
            // allUsers={externalState.allUser}
            // menuData={menus}
            />
                  }
          </Grid>
        </Grid>
      }
    </Grid>

  )
}
