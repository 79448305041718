import React from 'react';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CONFIG } from '../../../../config'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


export default function SimpleModal(props) {
    const [inputValue, setInputValue] = React.useState('');
    const {selectedCommentRowData, selectedCommentItem, selectedCommentParent} = props;
    const commentData = selectedCommentRowData && selectedCommentRowData.sub_column && 
    selectedCommentRowData.sub_column[selectedCommentParent.key] && selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'] && 
    selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key]

    const handleClose = () => {
        props.modalCallBack(false);
    };

    const handleSendSubmit = () => {
        if (inputValue && inputValue == '') {
            return;
        }
        props.sendCommmentCallback(inputValue)
        setInputValue('')
    }

    const handleChatChangeFn = (event) => {
        if (event && event.target) {
            setInputValue(event.target.value)
        }
    
    }  

    const body = (

        <div className='customModal commentModal'>
            <div className='header'>
                <h2>{selectedCommentParent && selectedCommentParent.label}</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <form>
                    <div className='commentInputSection flex ai-center jc-space-between'>
                        
                        <TextField
                            label="Add Comment"
                            name="comment"
                            placeholder="Placeholder"
                            multiline
                            variant="outlined"
                            size="small"
                            value={inputValue}
                            onChange={(event) => { handleChatChangeFn(event) }}
                        />
                     
                        <Tooltip title='Send'>
                            <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0' onClick={() => handleSendSubmit(props.commentsData)}>
                                <Icon fontSize="small">send</Icon>
                            </IconButton>
                        </Tooltip>
                        
                    </div>
                    <div className='commentsSection'>
            
                        {commentData &&
                            commentData.comments &&
                            commentData.comments.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {(props.userDataState.comment_originator == item.login_id) ?
                                            <div className='chat-right'>
                                                <div className='date'> {item.comment_originator} {formatDate(item.comment_date)}</div>
                                                {
                                                        <div>{item.comment}</div>
                                                }
                                            </div> :
                                            <div className='chat-left'>
                                                <div className='date'>{item.comment_originator} {formatDate(item.comment_date)}</div>
                                                {
                                                        <div> {item.comment}</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}