import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import DataValidationTableComponent from './DataValidationTable'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tooltip from '@material-ui/core/Tooltip';
import { downloadExcel, mapExcelData, mapDataPDF, mapExcelHeader } from '../../../../utils/exportExcel'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { parse, parseJSON } from 'date-fns';
import { format } from 'date-fns/esm';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export class DataValidationComponent extends React.Component {

  constructor(props) {
    super(props);
    const datelen = props.dashboardState.reportDateArr && props.dashboardState.reportDateArr.length;
    this.financialDuration = "";
    this.state = {
      openDialogTest: false,
      openDialog: false,
      viewEmail:false,
      loading: true,
      hfmRadioValue: "billing",
      lastIngestion: format(new Date(), "MM-dd-yy HH:mm:ss"),
      month: '',
      yearValue: '',
      quarterValue: '',
      emailData  : [],
      ytdFilterValue: this.props.incomeStatementState.defaultIncomeMonth &&
      this.props.dataBrowserState.ytdFilterArr.length ? this.props.incomeStatementState.defaultIncomeMonth : '',
      monthSelectorValue: '',
      editorMsgBody: null,
      openDialog:false,
    }
  }

  componentDidMount() {
    const { lastIngestion } = this.props.incomeStatementState;
    this.setState({
      lastIngestion: lastIngestion ? format(parseJSON(lastIngestion), "MM-dd-yy HH:mm:ss") : '',
    }, () => {
      this.props.getBUPLDefaultMonthData()
      // this.props.getEmailTemplate()
    })
  }

  handleChange = (event) => {
    this.setState({
      hfmRadioValue: event.target.value
    }, () => {
      this.handleChangeFetchApi()
    })
  };

  handleMonthChange = (event) => {
    if (event.target && this.state.month != event.target.value) {
      this.setState({
        month: event.target.value,
        ytdFilterValue: event.target.value == ''?  this.props.incomeStatementState.defaultIncomeMonth : '',
      }, () => {
        this.handleChangeFetchApi()
      });
      this.financialDuration = event.target.value;
    }
  }

  handleYearChange = (event) => {
    if (event.target && this.state.yearValue != event.target.value) {
      this.setState({
        yearValue: event.target.value,
        quarterValue: '',
        month: ''
      }, () => {
        this.handleChangeFetchApi()
      });
      this.financialDuration = event.target.value;
    }
  }

  handleYtdChange = (event) => {
    if (event.target && this.state.ytdFilterValue != event.target.value) {
      this.setState({
        ytdFilterValue: event.target.value,
        quarterValue: '',
        month: '',
        monthSelectorValue: '',
        yearValue: ''
      }, () => {
        this.handleChangeFetchApi()
      });
      this.financialDuration = event.target.value;
    }
  }

  handleQuarterChange = (event) => {
    if (event.target && this.state.quarterValue != event.target.value) {
      this.setState({
        quarterValue: event.target.value,
        yearValue: '',
        month: ''
      }, () => {
        this.handleChangeFetchApi()
      });
      this.financialDuration = event.target.value;
    }
  }

  handleChangeFetchApi = () => {
    const { hfmRadioValue, yearValue, month, quarterValue, ytdFilterValue } = this.state;
    let payload = {
      validationType: hfmRadioValue,
      year: yearValue,
      month: month || ytdFilterValue,
      quarter: quarterValue
    }
    let commentPayload = {
      view: hfmRadioValue,
      filter_values: {
        yearmonth: month ? month : undefined,
        year: yearValue ? yearValue : undefined,
        quarter: quarterValue ? quarterValue : undefined

      }
    }
    this.props.getDataBrowserValidationData(payload)
    this.props.getDataBrowserValidationComment(commentPayload)
  }

  unwindHeaderKeyVal = (data) => {
    data.forEach(x => {
      var obj = x.header_key_value
      if (obj != undefined) {
        for (var prop in obj) {
          x[prop] = obj[prop]
        }
      }
    })
    return data
  }
  
  handleDownloadExcel = () => {
    let data = this.props.dataBrowserState;
    let delta = data.delta;
    let hfm = data.hfmBalance
    let sl = data.slBalance
    let header = delta.row_header
    delta = mapExcelData(delta.row_data, header)
    hfm = mapExcelData(hfm.row_data, header)
    sl = mapExcelData(sl.row_data, header)

    delta.map(x => {
      x.data_type = "Delta = HFM - xP&A Demo"
      return x
    })

    hfm.map(x => {
      x.data_type = "Balances as per HFM"
      return x
    })

    sl.map(x => {
      x.data_type = "Balances as per xP&A Demo"
      return x
    })

    header = header.map(x => {
      return x.label
    })
    header.push("Data Type")
    let finalData = hfm.concat(sl).concat(delta)
    // downloadExcel(finalData, this.state.hfmRadioValue + " View", header)
    downloadExcel(finalData, ' Income Statement - Income Statement Validation View' + ' - ' + this.financialDuration, header)
  }

  componentDidUpdate(prevProps) {
    const { defaultIncomeMonth, lastIngestion } = this.props.incomeStatementState;
    
    if (prevProps.incomeStatementState.defaultIncomeMonth != defaultIncomeMonth) {
      this.setState({
        ytdFilterValue: defaultIncomeMonth,
        lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
      }, () => {
        this.handleChangeFetchApi();
        this.financialDuration = this.state.yearValue || this.state.month || this.state.ytdFilterValue || this.state.quarterValue;
      })
    }
    if (prevProps.dataBrowserState.tableViewState.emailTemplate.loading && !this.props.dataBrowserState.tableViewState.emailTemplate.loading) {
      console.log(this.props.dataBrowserState.tableViewState.emailTemplate.data, "cekPropsss")
      this.setState({
          emailData: this.props.dataBrowserState.tableViewState.emailTemplate.data,
      })
  }
  }

  printDocument = () => {
    let data = this.props.dataBrowserState;
    const pdf = new jsPDF('l', 'pt', 'letter');
    let delta = data.delta;
    let hfm = data.hfmBalance
    let sl = data.slBalance
    let deltaValue = data.deltaValue
    let header = delta.row_header
    delta = mapExcelData(delta.row_data, header)
    hfm = mapExcelData(hfm.row_data, header)
    sl = mapExcelData(sl.row_data, header)
    let hfmData = mapDataPDF(hfm, "id", header)
    let slData = mapDataPDF(sl, "id", header)
    let deltaData = mapDataPDF(delta, "id", header)
    header = mapExcelHeader(header, "label")
    let page = "xP&A Demo-Income Statement-Data Browser-Data Validation-Summary By Bu"
    let FileName = page + '_' + format(new Date, " - MM-dd-yyyy HH-mm-ss")
    let rows = []
    let i = 0
    header.forEach(x => {
      rows.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
    })
    let heads = [
      rows
    ];
    
    let rowsDataFix = []
    let dataCount = 0
    hfmData.forEach(x => {
      let i = 0
      let rowsData = []
      // console.log(x, "ini datax")
      x.forEach(y => {
         if (i == 0) {
          
          rowsData.push({ content: y})
          } else {
            if(y.includes("%")){
              if(y.includes("-")){
                y = y.replace("%", "");
              }
              rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251]} })
            }else{
              rowsData.push({ content: y, styles: { halign: 'center'}})
            }
           
          }
        i++;
       
      })
      dataCount += 1
      rowsDataFix.push(rowsData)
    })
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text(page, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
          lineColor: [38, 38, 38],
          width: 'auto'
      },
      margin: { top: 50 }
    })

     rowsDataFix = []
    dataCount = 0
    slData.forEach(x => {
      let i = 0
      let rowsData = []
      // console.log(x, "ini datax")
      x.forEach(y => {
         if (i == 0) {
          
          rowsData.push({ content: y})
          } else {
            if(y.includes("%")){
              if(y.includes("-")){
                y = y.replace("%", "");
              }
              rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251]} })
            }else{
              rowsData.push({ content: y, styles: { halign: 'center'}})
            }
           
          }
        i++;
       
      })
      dataCount += 1
      rowsDataFix.push(rowsData)
    })

    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text(page, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
          lineColor: [38, 38, 38],
          width: 'auto'
      },
      margin: { top: 50 }
    })

    rowsDataFix = []
    dataCount = 0
    deltaData.forEach(x => {
      let i = 0
      let rowsData = []
      // console.log(x, "ini datax")
      x.forEach(y => {
         if (i == 0) {
          
          rowsData.push({ content: y})
          } else {
            if(y.includes("%")){
              if(y.includes("-")){
                y = y.replace("%", "");
              }
              rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251]} })
            }else{
              rowsData.push({ content: y, styles: { halign: 'center'}})
            }
           
          }
        i++;
       
      })
      dataCount += 1
      rowsDataFix.push(rowsData)
    })
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      startY: pdf.lastAutoTable.finalY + 100,
      didDrawPage: function (data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        // pdf.text("Delta Value " + deltaValue, data.settings.margin.left + 15, 30);

        // Footer
        var str = "Page " + pdf.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
          lineColor: [38, 38, 38],
          width: 'auto'
      },
      margin: { top: 50 }
    })
    let finalY = pdf.lastAutoTable.finalY + 50; // The y position on the page
    pdf.setFontStyle('sans-serif');
    pdf.text(570, finalY, "Delta Value : " + deltaValue)
    pdf.save(FileName + '.pdf')
  }
  openViewEmail = () => {
    this.setState({viewEmail : true})
    let payload ={
      year : this.state.ytdFilterValue,
      month : this.state.month,
      quarter : this.state.quarterValue,
      validationType: this.state.hfmRadioValue,
    }
    this.props.getEmailTemplate(payload)
}
  closeViewEmail = () => {
    this.setState({viewEmail : false})
}
handleSubmitTemplate = (event) => {
  const {editorMsgBody} = this.state

  event.preventDefault();

  let payload = {
    // cc_emails: [event.currentTarget.cc_emails.value],
    email_title: event.currentTarget.email_title.value,
    // email_salutation: event.currentTarget.email_salutation.value,
    email_msg2: editorMsgBody.getData(),
    // email_signature: event.currentTarget.email_signature.value
  }

  this.props.saveEmailTemplate(payload)
  this.setState({viewEmail : false})
  
}


handleOpenDialog = () =>{
  this.setState({
      openDialog : true
  })

}
handleCloseDialog = () => {
  this.setState({
      openDialog : false
  })
}

handleSendMail = () =>{
  const { month, ytdFilterValue, hfmRadioValue } = this.state
  let date = ytdFilterValue
  if(month != ""){
    date = month
  }
  let payload = {
    report_date: date, 
    test_email: false, 
    type_view: hfmRadioValue
  }
  console.log(payload,ytdFilterValue,month,  "cekPaypay")
  this.props.sendEmail(payload)
  this.handleCloseDialog()
}

  render() {
    const { hfmRadioValue, openDialog, openDialogTest, month, yearValue, quarterValue, ytdFilterValue, monthSelectorValue } = this.state
    const { monthFilterArr, tableViewState, quarterfilterArr, yearFilterArr, ytdFilterArr } = this.props.dataBrowserState
    // let { tableViewState} = this.props;
   let  emailData =  tableViewState? tableViewState.emailTemplate.data : [];
    const arrayDate = monthFilterArr
    const newArray = []
    const idx = arrayDate.length

    // const emailData = tableViewState.emailTemplate.data;
    let Newidx = 0
    for (var keys in arrayDate) {
      Newidx++ 
      
      newArray.push(arrayDate[(idx-Newidx)])
    }
    return (
      <div className='dataValidation'>
        {ytdFilterArr.length ?
          <>
            <div className="flex ai-center jc-space-between mb-10">

              <Grid container spacing={3}>
                
              {hfmRadioValue == "billing" &&<Grid item xs={12} sm={4} className='maxw-200'>
                  <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <InputLabel>FY</InputLabel>
                    <Select
                      value={ytdFilterValue}
                      label="FY"
                      onChange={this.handleYtdChange}
                    >
                      {/* <MenuItem value="">
                        <em>Select</em>
                      </MenuItem> */}
                      {ytdFilterArr && ytdFilterArr.length && ytdFilterArr.sort().map(item => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>}
                {hfmRadioValue == "billing" && <Grid item xs={12} sm={4} className='maxw-200'>
                  <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={month}
                      label="Month"
                      onChange={this.handleMonthChange}
                    >
                      {/* <MenuItem value="">
                        <em>Select</em>
                      </MenuItem> */}
                      {newArray && newArray.length && newArray.map(item => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>}
               {/* <Grid item xs={12} sm={2} className='maxw-200'>
                  <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={quarterValue}
                      label="Quarter"
                      onChange={this.handleQuarterChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} className='maxw-200'>
                  <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                    <InputLabel>Annual</InputLabel>
                    <Select
                      value={yearValue}
                      label="Annual"
                      onChange={this.handleYearChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              <Grid container justify="flex-end">
                {<div>Latest Data Uploaded on<div><b>{this.state.lastIngestion}</b></div></div>}
                <Tooltip title={'Download to PDF'} className='pointer'>
                  <PictureAsPdfIcon fontSize="large" className='pointer ml-10 mr-10'
                    height='30'
                    onClick={(event) => {
                      event.preventDefault();
                      this.printDocument();
                    }} />

                </Tooltip>
                <Tooltip title={'Download to Excel'}>
                  <img
                    src={DownloadExcel}
                    className='pointer'
                    height='30'
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleDownloadExcel();
                    }} />
                </Tooltip>
       
                              
              </Grid>
            </div>
            
      
         
            <RadioGroup aria-label="hfm data" name="hfm" value={hfmRadioValue} onChange={this.handleChange}>
              <FormControlLabel value="billing" control={<Radio />} label="Summary by BU" />
              <FormControlLabel value="Monthly Rolling View" control={<Radio />} label="Monthly Rolling View" />
              {hfmRadioValue == "billing" &&
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className = "ml-20 newButton btnBlue uppercase btnEmail uppercase"
                                endIcon={<Icon>visibility</Icon>}
                                onClick={this.openViewEmail}
                              >
                                View Email
                              </Button>  }
                               
                              <Modal
                                    open={this.state.viewEmail}
                                    onClose={this.closeViewEmail}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={this.state.viewEmail}>
                                        {tableViewState  && tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> : 
                                        <div className='customModal viewEmailModal'>
                                            <div className='header'>
                                                <h2>View Email</h2>
                                            </div>
                                            <div className='modal-content email-template'>
                                                <form onSubmit={this.handleSubmitTemplate}>
                                                    <TextField
                                                      id="email_title"
                                                      label="Title of Email"
                                                      style={{ margin: 8 }}
                                                      placeholder=""
                                                      helperText=""
                                                      fullWidth
                                                      defaultValue= {emailData.email_title}
                                                      margin="normal"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <TextField
                                                      id="email_salutation"
                                                      label="Salutation"
                                                      style={{ margin: 8 }}
                                                      defaultValue= "Dear username,"
                                                      margin="normal"
                                                      disabled 
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                     <TextField
                                                      id="email_msg"
                                                      label="Header of Email"
                                                      style={{ margin: 8 }}
                                                      placeholder=""
                                                      helperText=""
                                                      fullWidth
                                                      disabled
                                                      defaultValue= {emailData.email_msg}
                                                      margin="normal"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <FormControl style={{ marginTop: 8 ,  marginLeft: 10 }}>
                                                        <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                                        <CKEditor
                                                            config={{
                                                                removePlugins: ['Link','EasyImage','CKFinder','Indent','Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                                            }}
                                                            id="editor-msg-body"
                                                            name="emailView"
                                                            editor={ ClassicEditor }
                                                            data= {emailData.email_msg2}
                                                            onReady={editor => {
                                                              this.setState({editorMsgBody: editor})
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <p className="ml-10">{emailData.app_link}</p>
                                                    
                                                    <TextField
                                                      id="email_signature"
                                                      label="Signature"
                                                      style={{ margin: 10 }}
                                                      defaultValue= {emailData.email_signature}
                                                      margin="normal"
                                                      disabled 
                                                      fullWidth
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <div className="flex jc-flex-end mt-30">
                                                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                                                        <Button variant="contained" className='ml-16' onClick={this.closeViewEmail}>Cancel</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        }
                                        
                                    </Fade>
                              </Modal>

                            
                              {/* <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className = "ml-20"
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialogTest}
                              >
                                Send Test Email
                              </Button> 
                              <Dialog
                                open={openDialogTest}
                                onClose={this.handleCloseDialogTest}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send the test email?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus onClick={this.handleCloseDialogTest} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMailTest} variant="outlined" size="small" color="primary">
                                    Send Test Email
                                  </Button>
                                </DialogActions>
                              </Dialog> */}

     
                         
{hfmRadioValue == "billing" &&<Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className = "ml-20 newButton btnBlue uppercase btnEmail uppercase"
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialog}
                              >
                                Send Email
                              </Button>}
                              <Dialog
                                open={openDialog}
                                onClose={this.handleCloseDialog}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send it?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus onClick={this.handleCloseDialog} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMail} variant="outlined" size="small" color="primary">
                                    Send Email
                                  </Button>
                                </DialogActions>
                              </Dialog>
                    
            </RadioGroup>    

            
           
            {<DataValidationTableComponent
              selectedTab={hfmRadioValue}
              monthValue={month}
              yearValue={yearValue}
              quarterValue={quarterValue}
              selectedDate={yearValue ? yearValue : month ? month : quarterValue}
            />}
          </> : null
        }
      </div>
    );
  }
}

export default
  connect(
    state => {
      return {
        ageingData: state.dashboardState.receivableData.ageingData,
        dashboardState: state.dashboardState,
        dataBrowserState: state.dataBrowser,
        incomeStatementState: state.incomeStatement
      }
    },
    dispatch => {
      return {
        getDataBrowserValidationData(data) {
          dispatch({
            type: 'FETCH_DATABROWSER_INCOME_VALIDATION_DATA',
            payload: data
          });
        },
        updateSelectedDate(data) {
          dispatch({
            type: "UPDATE_SELECTED_DATE",
            payload: data,
          });
        },
        saveEmailTemplate(data){
          dispatch({
            type: "SAVE_EMAIL_TEMPLATE_HFM",
            payload : data
          })
      },
        getEmailTemplate(data){
          dispatch({
              type: 'FETCH_EMAIL_TEMPLATE_IS_VALIDATION',
              payload : data
          });
      },
        getDataBrowserValidationComment(data) {
          dispatch({
            type: 'FETCH_DATABROWSER_INCOME_VALIDATION_COMMENT',
            payload: data
          });
        },
        sendEmail(data){
          dispatch({
            type : "SEND_MAIL_HFM",
            payload : data
          });
        },
        getBUPLDefaultMonthData() {
          dispatch({
            type: 'FETCH_DATABROWSER_DEFAULT_MONTH'
          });
        },
      };
    }
  )(DataValidationComponent);

