import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, Tooltip } from "@material-ui/core";
import { getAccessByMenuKey } from "../../../../../utils/userManagement";

// import HighChart from './highchart';
import Table from './table';
import Icon from '@material-ui/core/Icon';




export default function CashflowReport(props) {

  const [periodFilterArr, setPeriodFilterArr] = React.useState([])
  const [monthByArr, setMonthByArr] = React.useState([]);
  const [quarterByArr, setQuarterByArr] = React.useState([]);
  const [yearByArr, setYearByArr] = React.useState([]);

  const [monthByValue, setMonthByValue] = React.useState('');
  const [quarterByValue, setQuarterByValue] = React.useState('');
  const [yearByValue, setYearByValue] = React.useState('FY22');


  const [filterByArr, setFilterByArr] = React.useState([]);
  const [locationByArr, setlocationByArr] = React.useState([]);
  const [customerByArr, setcustomerByArr] = React.useState([]);
  const [projectByArr, setprojectByArr] = React.useState([]);

  const [filterByValue, setFilterByValue] = React.useState('lob');
  const [locationByValue, setlocationByValue] = React.useState('');
  const [customerByValue, setcustomerByValue] = React.useState('');
  const [projectByValue, setprojectByValue] = React.useState('');
  const [clearFilterValue, setClearFilterValue] = React.useState(0);



  const handleYearMonthFilterChange = (event) => {
    if (event.target) {
      setMonthByValue(event.target.value);
      setYearByValue('');
      setQuarterByValue('');
    }
  };

  const handleYearLabelFilterChange = (event) => {
    if (event.target) {
      setYearByValue(event.target.value);
      setMonthByValue('');
      setQuarterByValue('');
    }
  };

  const handleQuarterFilterChange = (event) => {
    if (event.target) {
      setQuarterByValue(event.target.value);
      setYearByValue('');
      setMonthByValue('');
    }
  };

  const handleFilterByChange = (event) => {
    if (event.target) {
      setFilterByValue(event.target.value);
    }
  };

  const handleLocationByChange = (event) => {
    if (event.target) {
      setlocationByValue(event.target.value);
    }
  };

  const handleCustomerByChange = (event) => {
    if (event.target) {
      setcustomerByValue(event.target.value);
    }
  };

  const handleProjectByChange = (event) => {
    if (event.target) {
      setprojectByValue(event.target.value);
    }
  };

  const fetchImpactAnalysis = () => {
    console.log("test")

  }


  const cleanFilter = () => {
    console.log("test")
  }


  const clearFilter = () => {
    cleanFilter();
    setClearFilterValue(clearFilterValue + 1)
  }

  return (
    <div className="tableChartView mt-20 filterSection">
      <div className="flex ai-center jc-space-between">
        <Grid container spacing={1} className="dropdown-filter">
          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Month</InputLabel>
              <Select
                value={monthByArr.length > 0? monthByValue : ''}
                label="Month"
                onChange={handleYearMonthFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {monthByArr && monthByArr.length &&
                    monthByArr.map(item => {
                      return <MenuItem value={item} key={item}>{item}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Quarter</InputLabel>
              <Select
                value={quarterByArr.length > 0? quarterByValue : ''}
                label="Quarter"
                onChange={handleQuarterFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {quarterByArr && quarterByArr.length &&
                    quarterByArr.map(item => {
                      return <MenuItem value={item.value} key={item.label} >{item.label}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2} className="maxw-200 mr-10">
            <FormControl variant="outlined" className="selectOutlineSmall"
              fullWidth size="small">
              <InputLabel>Annual</InputLabel>
              <Select
                value={yearByArr.length > 0 ? yearByValue : ''}
                label="Annual"
                onChange={handleYearLabelFilterChange}
              >
                 <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                {yearByArr && yearByArr.length &&
                    yearByArr.map(item => {
                      return <MenuItem value={item} key={item} >{item}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="flex ai-center jc-space-between margin-top">
        <Grid container spacing={1} className="jc-space-between dropdown-filter">
          <div className="filter-section">
            <Grid item xs={3} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>List of Business</InputLabel>
                <Select
                  value={filterByArr.length > 0 ? filterByValue : ""}
                  label="Quarter"
                  onChange={handleFilterByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {filterByArr && filterByArr.length &&
                    filterByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={2} className="maxw-200"/> */}

            <Grid item xs={2} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>Location</InputLabel>
                <Select
                  value={locationByArr.length > 0 ? locationByValue : ""}
                  label="Location"
                  onChange={handleLocationByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {locationByArr && locationByArr.length &&
                    locationByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2} className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>Customer</InputLabel>
                <Select
                  value={customerByArr.length > 0 ? customerByValue : ""}
                  label="Customer"
                  onChange={handleCustomerByChange}
                >

                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {customerByArr && customerByArr.length &&
                    customerByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}className="maxw-200 mr-10">
              <FormControl variant="outlined" className="selectOutlineSmall"
                fullWidth size="small">
                <InputLabel>Project</InputLabel>
                <Select
                  value={projectByValue}
                  label="Project"
                  onChange={handleProjectByChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {projectByArr && projectByArr.length &&
                    projectByArr.map(item => {
                      return <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          </div>
          <Grid item xs={3}className="maxw-200">
              <div className="btn-section">
              {(getAccessByMenuKey(props.menuData, "fcf_reports", "add") || getAccessByMenuKey(props.menuData, "fcf_reports", "edit")) &&
                            <Button  variant="contained" size="small" className="newButton btnBlue uppercase btnApply w-30" onClick={fetchImpactAnalysis}
                              color="primary">Apply</Button>
            }
              {(getAccessByMenuKey(props.menuData, "fcf_reports", "add") || getAccessByMenuKey(props.menuData, "fcf_reports", "edit")) &&
                              <Button variant="contained" size="small" className="newButton btnRed uppercase btnClear ml-10 w-30" onClick={clearFilter}
                              color="primary">Clear</Button>
            }
              </div>
            </Grid>
        </Grid>
      </div>
      <Grid container spacing={3} alignItems="center" className="mt-50">
        <Grid item xs={12} sm={12}>
          <Table/>
        </Grid>
      </Grid>
    </div>
  );
}
