import React from 'react';
import { connect } from "react-redux";

import './index.scss';
import ChartComponent from './chart.jsx';
import TableComponent from './tableapp.jsx';
import ImprovementModal from './modal.jsx';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class OneSensiaComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buArr = ['BU-1', 'BU-2', 'BU-3', 'BU-4']

          this.productArr = ["P-1", "P-2", "P-3", "P-4"]
          
          this.countryArr = ['INDIA', 'USA', 'UK', 'RUSSIA']
      
        this.state = {
            buValue: '',
            productValue: '',
            countryValue:'',
            toggleValue: true,
            tabSelectorValue: 0,
            tableShow: false,
            showModalPip: false,
        }
    }

    handleBuChange = (event) => {
        this.setState({
            buValue: event.target.value,
        })
    }

    handleProductChange = (event) => {
        this.setState({
            productValue: event.target.value,
        })
    }

    handleCountryChange = (event) => {
        this.setState({
            countryValue: event.target.value,
        })
    }

    handleToggleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        })
    }

    handleTabChange = ( newValue) => {
        this.setState({ tabSelectorValue: newValue })
    };

    handleTableShow = (event) => {
        this.setState({tableShow: !this.state.tableShow})
    }

    modalCallBackPip = () => {
        this.setState({showModalPip: false});
      };

    handleButtonClick = () => {
        this.setState({showModalPip: true})
    }

    render() {
        const { buValue, productValue, countryValue, toggleValue, tabSelectorValue, tableShow, showModalPip } = this.state;

        return (
            <>   
                <div className='buplComponent risk-management'>
                    { 
                        !tableShow  ?
                        <Typography variant="h5" gutterBottom className="header">Risk Management Dashboard</Typography> 
                        : 
                        <Typography variant="h5" gutterBottom className="header">Initiatives to Reduce Risk</Typography> 
                    }

                    <Typography variant= "subtitle2" className="subheading">A single app to view, assess, manage and mitigate Risks across the Organization </Typography>
                    
                    <Tabs
                        value={tabSelectorValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className='mt-16 mb-16'
                    >
                        <Tab label="Chart View" />
                    </Tabs>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <FormControl style={{ minWidth: 200 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5' size='small'>
                                <InputLabel>Business Unit</InputLabel>
                                <Select
                                value={buValue}
                                label="Business Unit"
                                onChange={this.handleBuChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {this.buArr && this.buArr.length && this.buArr.map(item => {
                                    return <MenuItem value={item} key={item}>{item}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ minWidth: 120 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5 product-filter' size='small'>
                                <InputLabel>Product</InputLabel>
                                <Select
                                value={productValue}
                                label="Product"
                                onChange={this.handleProductChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {this.productArr && this.productArr.length && this.productArr.map(item => {
                                    return <MenuItem value={item} key={item}>{item}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ minWidth: 120 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5 country-filter' size='small'>
                                <InputLabel>Country</InputLabel>
                                <Select
                                value={countryValue}
                                label="Country"
                                onChange={this.handleCountryChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {this.countryArr && this.countryArr.length && this.countryArr.map(item => {
                                    return <MenuItem value={item} key={item}>{item}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                            <Button variant='contained' size='small' className="mb-5 newButton btnBlue btnView uppercase" color="primary" disableElevation onClick={this.handleButtonClick}>Improvement Plan</Button>
                        </Grid>
                        <Grid item xs={12} sm={4} className="text-right date-toggle">
                            <span className="mr-5">31 Jan 2021</span>
                            <FormControlLabel 
                                control={<Switch 
                                    checked={toggleValue} 
                                    className='switchMUI'
                                    onChange={this.handleToggleChange} 
                                    name="toggleValue" 
                                />}
                                label="31 Mar 2021"
                            />
                        </Grid>    
                    </Grid>

                    <Grid container className="contain mt-10" spacing={1}>
                        <Grid item xs={12} sm={9}> 
                            {
                                !tableShow ?( 
                                    <div className="arrange">
                                        <ChartComponent />
                                        <div className='legendBox ml-5'> &nbsp; &nbsp; &nbsp; <u>  Legends</u> <br/> <SyncAltIcon fontSize="small"/> Glide Path</div>
                                    </div>
                                ) : <TableComponent/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={3} spacing={1}>
                            <ButtonGroup 
                                orientation="vertical"
                                aria-label="vertical contained button group"
                                variant="contained" 
                            >
                                <Button className='newButton btnBlue btnView' variant="contained" color="primary">View Initiatives to reduce Risk</Button>
                                <Button className='newButton btnBlue btnView' variant="contained" color="primary" onClick={this.handleTableShow}>View Risk Score Assessment Forms</Button>
                                <Button className='newButton btnBlue btnView' variant="contained" color="primary">View Audit Reports</Button>
                            </ButtonGroup>
                        </Grid> 
                    </Grid> 
                </div>
                <ImprovementModal
                    showModal={showModalPip}
                    modalCallBackPip={this.modalCallBackPip}
                    // userDataState={authState.userData}
                    // catData={catData}
                    // subCatData={subCatData}
                    // changeSubCatData={this.changeSubCatData}
                    // buValue={this.props.buValue}
                    // customerData={commentRow}
                    // allUser={this.props.externalState.allUser}
                    // saveCallback={this.handlePipSave}
                    // updateCallback={this.handlePipUpdate}
                    // pipIDData={externalState.pipData.pipIDData}
                    // cleanUpPipData={cleanUpPipData}
                    // modalCallBackcleanUpPipData={this.modalCallBackcleanUpPipData}
                    editMode={true}
                    viewMode={true}
                    // editData={editPipData}
                    // stageData={stageData}
                    loading={false}
                /> 
            </>      
        );
    }
}

export default
    connect(
        state => {
            return {
            }
        },
        dispatch => {
            return {
            };
        }
    )(OneSensiaComponent);
