import BarChart from "./chartStacked"
import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import NewPagination from "../../../common/components/CustomPagination";
import ReactTable from "react-table-6";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Button from '@material-ui/core/Button';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function ChartContainerSummary(props) {
    const isButtonDisabled = props.tableData.filter(x => x.netSalesedited || x.osedited).length ? false : true;
    const chartData = JSON.parse(JSON.stringify(props.chartData))
    // const chartDatas = chartData.splice(4,1)
    // const [time, setTime] = useState(0)
    let eastData = []
    let westData = [];
    let eastSplineData = [];
    let westSplineData = [];

    // React.useEffect(() => {
        if (props.graphView && props.graphType == "hemisphere") {
            let splineData = []
            props.chartData.map(x => {
                if (x.stack == 'hemi') {
                    splineData = x
                }
                if (x.stack == "East") {
                    eastData.push(x);
                }
                if (x.stack == "West") {
                    westData.push(x);
                }
            })

            if (splineData && splineData.data && splineData.data.length) {
                splineData.data.forEach(item => {
                    if (item.uniqueName == 'East') {
                         item.name = item.period
                        eastSplineData.push(item)

                    }
                    if (item.uniqueName == 'West') {
                        item.name = item.period
                        westSplineData.push(item)
                    }

                })
            }

            eastData = [...eastData, {...splineData, data:eastSplineData}]
            westData = [...westData, {...splineData, data:westSplineData}]

            // console.log(eastData, "westDatawestData", westData);


        }
    // }, [])



    let BarChartHolder = props.graphView && props.graphType == "hemisphere" ?

        <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
                <Card>
                    <CardContent>
                        <BarChart
                            chartData={westData}
                            chartTitle={'DSO West Hemisphere'}
                            showImprovementLineChart={props.showImprovementLineChart}
                            topTenCountry={props.topTenCountry}
                            totalData={props.tableData}
                            subChartData={props.dso_improvement[0] && props.dso_improvement[0].West ? props.dso_improvement[0].West : []}
                            externalTabValue={props.externalTabValue}
                            saveCapture={props.saveCapture}
                            graphType={props.graphType}
                            receivablesTab={props.receivablesTab}
                            SeriesLabelEnabled={true}
                            SeriesLabelColor={"black"}
                            handleRegionHeaderClick={props.handleRegionHeaderClick}
                            handleHemisphereHeaderClick={props.handleHemisphereHeaderClick}
                        ></BarChart>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={6}>
                <Card>
                    <CardContent>
                        <BarChart
                            chartData={eastData}
                            showImprovementLineChart={props.showImprovementLineChart}
                            chartTitle={'DSO East Hemisphere'}
                            topTenCountry={props.topTenCountry}
                            totalData={props.tableData}
                            subChartData={props.dso_improvement[0] && props.dso_improvement[0].East ? props.dso_improvement[0].East : []}
                            externalTabValue={props.externalTabValue}
                            saveCapture={props.saveCapture}
                            graphType={props.graphType}
                            receivablesTab={props.receivablesTab}
                            SeriesLabelEnabled={true}
                            SeriesLabelColor={"black"}
                            handleRegionHeaderClick={props.handleRegionHeaderClick}
                            handleHemisphereHeaderClick={props.handleHemisphereHeaderClick}
                        ></BarChart>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        :
        <Grid item xs={12} sm={12}>
            <Card>
                <CardContent>
                    <BarChart
                        chartData={chartData}
                        chartTitle={props.chartTitle}
                        showImprovementLineChart={props.showImprovementLineChart}
                        topTenCountry={props.topTenCountry}
                        totalData={props.tableData}
                        subChartData={props.dso_improvement}
                        externalTabValue={props.externalTabValue}
                        saveCapture={props.saveCapture}
                        graphType={props.graphType}
                        receivablesTab={props.receivablesTab}
                        SeriesLabelEnabled={true}
                        SeriesLabelColor={"black"}
                        handleRegionHeaderClick={props.handleRegionHeaderClick}
                        handleHemisphereHeaderClick={props.handleHemisphereHeaderClick}
                    ></BarChart>
                </CardContent>
            </Card>
        </Grid>

    return (
        <>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <Grid container spacing={2} >
                    {props.graphView ?
                        <Grid item xs={12} sm={12}>
                            {BarChartHolder}
                        </Grid>
                        :
                        <>
                            <Grid item xs={12}>
                                <ReactTableFixedColumns
                                    data={props.tableData}
                                    columns={props.headerData}
                                    defaultPageSize={props.receivablesTab == 1 ? 6 : props.tableData.length > 10 ? 10 : props.tableData.length}
                                    pageSize={props.tableData.length}
                                    className="xpna-reactFixed-table -striped -highlight"
                                    PaginationComponent={NewPagination}
                                    pageSizeOptions={[10, 20, 30]}
                                    resizable={true}
                                    getTdProps={props.clicked}
                                />
                            </Grid>
                            {props.externalTabValue !== 0 && <Grid container direction="row" justify="flex-end">
                                <Button variant="contained" color="primary" onClick={props.saveTableChanges} disabled={isButtonDisabled}>Save Changes</Button>
                            </Grid>}
                        </>
                    }
                </Grid>
            }
        </>
    )
}

export default ChartContainerSummary;