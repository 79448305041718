import React, { useState } from 'react';
import './Auth.scss';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { CircularProgress, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

function Auth() {
    const [isForgot, setIsForgot] = useState(false);
    const [showPassword, setshowPassword] = useState(false);
    const loginState = useSelector(state => state);
    const dispatch = useDispatch();
    const { authReducer } = loginState;

    let locationHref = window.location.href
    let isEmbeddedIframe = false
    if (locationHref && locationHref.includes('embed')) {
      sessionStorage.setItem('embedIframe', true)
      isEmbeddedIframe = true
    }

    const handleClickShowPassword = () => {
        setshowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginSubmit = (event, d) => {
        event.preventDefault();
        let payload = {
            login_id: event.currentTarget.email.value,
            password: event.currentTarget.password.value
        }
        dispatch({
            type: "PERFORM_LOGIN",
            payload
        })
    }

    const handleForgotSubmit = (event, d) => {
        event.preventDefault();
        let payload = {
            login_id: event.currentTarget.email.value,
        }
        dispatch({
            type: "PERFORM_FORGOT_PASSWORD",
            payload
        })
    }

    function ButtonComponent(props) {
        const { loading, text, fullWidth } = props;
        return (
            <Button type="submit"
                fullWidth={fullWidth}
                variant="contained"
                color="primary"
                className="btn-login"
                disabled={loading}>
                {loading && <CircularProgress style={{'color': 'white'}} size={25} />}
                {!loading && text}
            </Button>
        );
    }

    return (
        <Grid container component="main" className={!isEmbeddedIframe ? 'loginPage' : 'loginPage loginEmbedMainContainer'}>
            <CssBaseline />
            <Grid item xs={false} sm={6} md={8} className={ !isEmbeddedIframe? 'bannerImage' : 'loginEmbedHidebanner' }>
            </Grid>
            <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
                <div className='loginFormContainer'>
                    <h1 className="primary text-center mb-0">xP&A - Extending FP&A across the Organization</h1>
                    <p className="mt-0 dark-grey text-center"><i><b>Multiple packaged horizontal and industry-specific solutions</b></i></p>
                    {!isForgot ?
                        <form onSubmit={handleLoginSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                            />
                            <FormControl variant="outlined" className="passwordField">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    required
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    labelWidth={70}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <div className='flex ai-center jc-space-between mt-24'>
                                <ButtonComponent text={'Login'} fullWidth={false} loading={authReducer.loading} />
                                <Button className='semi-dark-grey text-capitalize' onClick={() => setIsForgot(true)}>{"Forgot password?"}</Button>
                            </div>
                        </form>
                        :
                        <form onSubmit={handleForgotSubmit}>
                            <h4 className="mt-16 mb-0">Enter your email and we'll send you a link to get back into your account.</h4>
                            <TextField style={{ display: 'none' }}></TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                autoComplete="email"
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                            />
                            <div className='flex ai-center jc-space-between mt-24'>
                                <Button variant="contained" onClick={() => setIsForgot(false)}>Cancel</Button>
                                <ButtonComponent fullWidth={false} text={'Send Link'} loading={authReducer.loading} />
                            </div>
                        </form>
                    }
                </div>
            </Grid>
        </Grid>
    );
}

export default Auth