import axios from 'axios';
import {CONFIG} from '../../../../../config'


export async function fetchDayReportDataService(payload) {
    let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/report/`
    if (payload && payload.receivableTabSelectorValue == 1) {
        apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/invoice/report/`
    }

    return axios.post(apiUrl, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}


export async function fetchAllCommentHistoryService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function createChannelService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/receivables/all/aging/invoice/channel/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchOneCommentHistoryService(payload) {

    return axios.get(`${CONFIG.api_base_url}v1/chat/channel/history/${payload.channelId}`).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function sendCollectionForecastService(payload) {

    return axios.put(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/collection/forecast/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function sendCollectionForecastInvoiceService(payload) {

    return axios.put(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/collection/forecast/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function updateActionOwnerService(payload) {

    return axios.patch(`${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/actionowner/`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function getDayFilterService(payload) {

    let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/filter/`
    if (payload && payload.receivableTabSelectorValue == 1) {
        apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/invoice/filter/`
    }

    return axios.post(apiUrl, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}
