import { useState, useEffect } from "react";
import { fetchSimulationMetaDataService } from "../store/service";

const convertMetaData = (data) => {
    if (Array.isArray(data) && data.length > 0) {
        let tempData = [];

        for (let i = 0; i < data.length; i++) {
            const tempItem = { ...data?.[i] };
            const type = tempItem?._id?.type ?? "";
            tempItem._id.name = `${type[0].toUpperCase()}${type.substring(1)}`;
            if (type === "measurement") {
                tempData.unshift(tempItem);
            } else tempData.push(tempItem);
        }
        return tempData;
    } else {
        return [];
    }
};

export default () => {
    const [data, setData] = useState({
        status: "loading",
        error: null,
        data: {
            filter_numeric_operator: [],
            filter_string_operator: [],
            formula_operator: [],
            where_operator: [],
            meta: [],
            measurements: {},
            conditions: {},
        },
    });

    useEffect(() => {
        const getSimulationMetaData = async () => {
            try {
                const response = await fetchSimulationMetaDataService();
                if (response?.status === 200 && typeof response?.data === "object") {
                    const { filter_numeric_operator = [], filter_string_operator = [], formula_operator = [], where_operator = [], meta = [] } = response?.data ?? {};
                    setData({
                        status: "success",
                        error: null,
                        data: {
                            filter_numeric_operator: Array.isArray(filter_numeric_operator) ? filter_numeric_operator : [],
                            filter_string_operator: Array.isArray(filter_string_operator) ? filter_string_operator : [],
                            formula_operator: Array.isArray(formula_operator) ? formula_operator : [],
                            where_operator: Array.isArray(where_operator) ? where_operator : [],
                            meta: convertMetaData(meta),
                        },
                    });
                } else {
                    setData((prevState) => ({
                        ...prevState,
                        status: "fail",
                        error: response,
                    }));
                }
            } catch (err) {
                console.log(err);
                setData((prevState) => ({
                    ...prevState,
                    status: "fail",
                    error: err,
                }));
            }
        };
        getSimulationMetaData();
    }, []);

    return data;
};
