import { format, parseJSON } from "date-fns";


export const transformFilterData = (filterData) => {

  let filterObj = {
    parentGroupArr: [],
    regionArr: [],
    periodArr: [],
    buArr: []
  }

  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'parent_group') {
        filterObj.parentGroupArr = item.value
      }
      if (item.key == 'report_date') {
        filterObj.periodArr = item.value
      }
      if (item.key == 'region') {
        filterObj.regionArr = item.value
      }
      if (item.key == 'bu') {
        filterObj.buArr = item.value
      }
    })
  }
  return filterObj
}

export let changeValuesOfData = (data) => {
  if (data && data.length) {
    let transformedData = data.map((item) => {
      if (item.commentArr == undefined) {
        item['commentArr'] = [];
        item['isChatConnection'] = false;
        item['obj'] = {};
      }

      return item;
    });

    return transformedData;
  }
  return [];
};

export let appendChannelId = (data, companyCode, customerId, channelId, customer_name, project_id) => {
  let returnData = {
    updatedItem: {},
    transformedArr: []
  }
  let transformedData = []
  if (data && data.length) {
    transformedData = data.map((item) => {
      if (item.company_code == companyCode && item.customer == customerId && item.customer_name == customer_name) {
        
          if (project_id) {
            if (project_id == item.project_id) {
              item['channel_customer'] = channelId;
              returnData['updatedItem'] = item;
            }
          }else{
            item['channel_customer'] = channelId;
            returnData['updatedItem'] = item;
          }
        
      }
      return item;
    });
    returnData['transformedArr'] = transformedData
  }
  return returnData;
};

export const changeCollectionForecastData = (rowData, payload) => {
  return rowData.map(item => {
    if (item.company_code == payload.company_code && item.customer == payload.customer) {
      item['collection_forecast_confirmed_by'] = payload['collection_forecast_confirmed_by']
      item['collection_forecast_date'] = payload['collection_forecast_date']
      item['collection_forecast'] = payload['collection_forecast']
    }
    return item
  })
}

export const setAllRowCommentHistoryData = (rowData, chatData) => {
  return rowData.map(item => {
    if (chatData[item.channel]) {
      if (chatData[item.channel_customer]) {
        item['commentArr'] = chatData[item.channel_customer]
      }
      item['commentArr'] = [...item['commentArr'], ...chatData[item.channel]]
    } else {
      if (chatData[item.channel_customer]) {
        item['commentArr'] = chatData[item.channel_customer]
      }
    }
    if (item['commentArr'] && item['commentArr'].length) {
      item['commentArr'].sort((x, y) => {
        let a = new Date(x.timestamp)
        let b = new Date(y.timestamp)
        return b.getTime() - a.getTime()
      })
      let singleObj = {}
      let newCommentArr = []
      item['commentArr'].forEach(singleComment => {
        if (singleObj[singleComment.id] == undefined) {
          newCommentArr.push(singleComment)
          singleObj[singleComment.id] = singleComment.id;
        }
      })

      item['commentArr'] = newCommentArr;
    }
    return item
  })
}

export const dummyConvert = (rowData, reportDate) => {
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (reportDate == '31-Aug-2020') {
        if (item.business_unit == 'PAB') {
          item['current'] = 33532850;
          item['grand_total'] = 33532850;
        }
        if (item.business_unit == 'Grand Total') {
          item['current'] = 38584095
          item['grand_total'] = 42702584;
        }
      }
      if (reportDate == '30-Sep-2020') {
        if (item.business_unit == 'PAB') {
          item['current'] = 35328242;
          item['grand_total'] = 35328242;
        }
        if (item.business_unit == 'Grand Total') {
          item['current'] = 42535524
          item['grand_total'] = 47309886;
        }
      }
      return item;
    })
    rowData.row_data = updatedRowData
  }
  return rowData
}

export const dummyTotalConvert = (rowData, reportDate) => {
  if (rowData.row_data && rowData.row_data.length) {
    let updatedRowData = [];
    updatedRowData = rowData.row_data.map(item => {
      if (reportDate == '31-Aug-2020') {
        if (item.business_unit == 'PAB') {
          item['current'] = 48691699;
          item['grand_total'] = 75437093;
        }
        if (item.business_unit == 'Grand Total') {
          item['current'] = 78856465;
          item['grand_total'] = 126523713;
        }
      }
      if (reportDate == '30-Sep-2020') {
        if (item.business_unit == 'PAB') {
          item['current'] = 52526447;
          item['grand_total'] = 69300395;

        }
        if (item.business_unit == 'Grand Total') {
          item['current'] = 83663285;
          item['grand_total'] = 122529885;
        }
      }
      return item;
    })
    rowData.row_data = updatedRowData
  }
  return rowData
}

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const mapLegend = (text, category) => {
  switch (text) {
    case "Total":
      if (category == "billed") {
        return text + " Overdue"
      } else {
        return text + " Overdue"
      }
      break
    case "60+":
      if (category == "billed") {
        return "Overdue " + text + " days"
      } else {
        return "Overdue " + text + " days"
      }
      break
  }
  return text
}

const mapToChartData = (data, category) => {
  data.map(x => {
    x.name = format(parseJSON(x.date), 'MMM yy');
    x.y = x.amount;
    x.label = x.percentage;
  })
  const grouped = groupBy(data, 'category');
  let newData = []
  for (var key in grouped) {
    let legendColors
    if (key == 'Total') {
      legendColors = '#058DC7';
    } else if (key == '60+') {
      legendColors = '#DDDF00';
    } else if (key == 'APAC') {
      legendColors = '#50B432';
    } else if (key == 'EMEA') {
      legendColors = '#ED561B';
    } else if (key == 'LAR') {
      legendColors = '#8E44AD';
    } else if (key == 'NA') {
      legendColors = '#F70303';
    }
    newData.push({ marker:{symbol:"square"}, "name": mapLegend(key, category), color: legendColors, data: grouped[key] })
  }
  return newData;
}

const colorArr = ['#5B8BA1', '#B4D5DE', '#ffdb69', '#f18226', '#0089D0','#0DB14B']

export const mapToChartDataDSOBuWise = (data, payload) => {
  // data = data.filter(x => {

  //   return x.amount > 0
  // })
 
  let alias = ""
  data.map(x => {
    if (x.category == "BU 1") {
      alias = "BU 1"
    } else {
      alias = x.category
    }
    x.aliases = alias;
    x.y = x.amount;
    x.label = "";
    x.name = format(parseJSON(x.date), 'MMM yy')
  })
   if (payload.receivablesTab == 1){
    data = data.filter(x => {
      if (x.name != "Feb 21" && x.name != "Jan 01"){
        console.log(x, "cekReturn")
        return x
      }
  
    })
  }
  const grouped = groupBy(data, 'aliases');
  let pctData = calculatePct(data);
  const grouped2 = groupBy(data, 'name');
  let arrayTotal = []
  for (var x in grouped2) {
    let array = grouped2[x]
    let key = x.replace(" ", "_")
    let total = 0
    for (var z in grouped2[x]) {
      total += grouped2[x][z].y
    }
    arrayTotal[key] = total
  }
  for (var x in grouped) {
    let arr = grouped[x]
    for (var z in arr) {
      let name = (arr[z].name).replace(" ", "_")
      grouped[x][z].totals = arrayTotal[name]
    }
  }
  let newData = []
  for (var key in grouped) {
    let order = orderNum[key]
    let seriesData = sortByDate(grouped[key]);
    let obj = { "name": key, color: colorArr[order], data: seriesData, orderNum: order };
    newData.push(obj)
  }
  console.log(newData, "testNewData")
  newData = sortByOrderNum(newData)
  newData[0].data.forEach(x => {
    x.label = pctData[x.name]
  })
  newData[0].dataLabels = {
    enabled: true,
    crop: false,
    overflow: "allow",
    y: -25,
    verticalAlign: 'top'
  };

  return sortByOrderNum(newData);
}

const calculatePct = (data) => {
  const grouped = groupBy(data, 'name');
  let res = {};
  for (var key in grouped) {
    let curData = grouped[key];
    let total = 0;
    let unbilled = 0;
    curData.forEach(element => {
      unbilled += element.amount;
      total += element.grand_total;
    })
    let pct = ((unbilled / total) * 100).toFixed(2) + "%";;
    res[key] = pct;
  }
  return res;
}

const orderNum = { "BU 1": 0, "BU 2": 1, "BU 3": 2, "BU 4": 3 }

export const mapToChartDataDSOBuWiseDSO = (data, payload) => {
  data = data.filter(x => {
    return x.y != null
  })
  if (payload.receivablesTab == 1){
    data = data.filter(x => {
      if (x.name != "Feb 21" && x.name != "Jan 21"){
        return x
      }
  
    })
  }
  let alias = ""
  data.map(x => {
    if (x.category == "BU 1") {
      alias = "BU 1"
    } else {
      alias = x.category
    }
    x.aliases = alias;
    x.label = x.y;
    x.name = format(parseJSON(x.date), 'MMM yy')
    x.dataLabels = {
      enabled: true,
      crop: false,
      color: "black"
    };
  })
  const grouped = groupBy(data, 'aliases');
  const grouped2 = groupBy(data, 'name');
  let arrayTotal = []
  for (var x in grouped2) {
    let array = grouped2[x]
    let key = x.replace(" ", "_")
    let total = 0
    for (var z in grouped2[x]) {
      if (grouped2[x][z].category == "Summary") {
        total = grouped2[x][z].y
      }
    }
    arrayTotal[key] = total
  }
  for (var x in grouped) {
    let arr = grouped[x]
    for (var z in arr) {
      let name = (arr[z].name).replace(" ", "_")
      grouped[x][z].totals = arrayTotal[name]
    }
  }
  let newData = []
  let idx = 0
  for (var key in grouped) {
    let order = orderNum[key]
    if (key != "Summary") {
      newData.push({ "name": key, color: colorArr[order], data: sortByDate(grouped[key]), orderNum: order })
      idx++
    }
  }
  newData = sortByOrderNum(newData)
  return sortByOrderNum(newData);
}

const sortByDate = (data) => {
  return data.sort((a, b) => parseJSON(a.date) - parseJSON(b.date)).reverse()
}
const sortByDateDesc = (data) => {
  return data.sort((a, b) => parseJSON(b.date) - parseJSON(a.date))
}
const sortByOrderNum = (data) => {
  return data.sort((a, b) => parseJSON(b.orderNum) - parseJSON(a.orderNum)).reverse()
}
export const convertDSOChartDataLegacy = (data, payload) => {
  let newData = [];

  const grouped = groupBy(data, 'category');

  let chartType = "column"
  let yAxisVal = 0
  let alias = ""
  let stackType = ""
  let bu = ['BU 1', 'BU 2', 'BU 3', 'BU 4', 'OH', 'DSO']  
  let sortedData = Object.keys(grouped).sort(
    (a, b) => bu.indexOf(a) - bu.indexOf(b)
  )

 
  let idx = 0
  for (var keys in sortedData) {
    var key = sortedData[keys]
    let order = orderNum[key]
    var color = colorArr[order]
    var marker = {symbol : 'square'}
   
    if (key == "DSO") {
      chartType = "spline"
      yAxisVal = 1
      alias = "DSO"
      color = '#4472c4'
      marker = {symbol:'round'}
    }
   
    if (key == "BU 1") {
      alias = "BU 1"
      color = "#5B8BA1"
    } else {
      alias = key
    }
    let dataDSO = sortByDate(grouped[key])
   
    newData.push({ marker:marker, color: color, "yAxis": yAxisVal,stack: stackType,"type": chartType, "name": alias, data:  dataDSO})
  

   
    idx++
  }
 


 
    return newData
}
export const fillDataLabelDSO = (x, dataDSO) => {
  x.dataLabels= {enabled: true, crop: false, overflow: 'allow', y: -25, verticalAlign: 'top'}
  x.data.map(n =>{
    dataDSO.map(m => {
      
      if(n.name == m.name){

        n.labelDSO=  m.y
      }
    })
  })
  return x
}
export const fillDataLabelDSORegionCountry = (x) => {
  x.dataLabels= {enabled: true, crop: false, overflow: 'allow', y: -25, verticalAlign: 'top'}
  x.data.forEach(n =>{      
      n.labelDSO=  n.chart_dso
  })
  return x
}
export const fillDataLabelChartHemisphere = (x, dataDSO, type) => {
  // x.dataLabels=  {enabled: true, crop: false, overflow: 'none', y: -25, x: 20, verticalAlign: 'top'}
  x.dataLabels= [{enabled: true, crop: false, color: 'black'}, {enabled: true, crop: false, overflow: 'allow', y: -25, verticalAlign: 'top'}]
  x.data.map(n =>{         
    dataDSO.map(m => {
      console.log(n, m , "cekMatches")
      if(n.hemisphere == m.name && n.name == m.period){
       
        if(type == "inside"){
         n.label=  n.hemisphere
        }else if(type == "top"){
          n.labelDSO=  m.sub_column['revenue'].y
        }else if(type == "both"){
          n.label=  n.hemisphere
          n.labelDSO=  m.sub_column['revenue'].y
        }

       
      }
    })
  })
  return x
}
export const fillDataLabelChartHemisphereEW = (x, dataDSO) => {
  // x.dataLabels=  {enabled: true, crop: false, overflow: 'none', y: -25, x: 20, verticalAlign: 'top'}
  x.dataLabels= [{enabled: true, crop: false, color: 'black'}, {enabled: true, crop: false, overflow: 'allow', y: -25, verticalAlign: 'top'}]
  x.data.map(n =>{         
    dataDSO.map(m => {
      if(n.name == m.name){
        n.label=  n.hemisphere
        // n.labelDSO=  m.sub_column[n.hemisphere].y
      }
    })
  })
  return x
}
export const convertDSOChartDataNew = (data, payload) => {

  let originalData = Object.assign([], data);
    originalData.forEach((newValueItem) => {
      newValueItem.uniqueName = newValueItem.name
    })

  if (payload.receivablesTab == 1){
    // let objName = {}
    // data.forEach(x => {
    
    //   if (x.category != "DSO"){
    //     objName[x.name] = x.name
      
    //   }
      
    // })
    
    // data = data.filter(x => {
    
    //   if (x.name != "Feb 21" && x.name != "Jan 21" && x.alias != "Unallocated Unassigned"){
    //     for (const key in objName) {
    //       if (key == x.name) {
    //         return x
    //       }
          
    //     }
      
    //   }
      
    // })
    
    // })
    
  }

  const grouped = groupBy(data, 'category');
  let newData = [];
  let chartType = "column"
  let yAxisVal = 0
  let alias = ""
  let stackType = ""
  // let bu = ['MSM', 'LCS', 'DGS', 'PAB', 'SES', 'DSO']
  let neworderNum = { 'New BU 1 East': 0, 'New BU 2 East': 1, 'Unallocated East': 2, 'New BU 1 West': 3, 'New BU 2 West' :4, 'Unallocated West' : 5 }
  // if (payload.receivablesTab == 1){
  let bu = ['Unallocated', 'New BU 2', 'New BU 1','DSO']
  if (payload.graphType  == "hemisphere"){
    bu = ['Unallocated East','Connected Products East','New BU 1 East','Unallocated West', 'Connected Products West', 'New BU 1 West', 'DSO']
    
  }
  // }
  
  let sortedData = Object.keys(grouped).sort(
    (a, b) => bu.indexOf(a) - bu.indexOf(b)
  )
  let idx = 0

  for (var keys in sortedData) {
    var key = sortedData[keys]
    let order = orderNum[key]
    if (payload.graphType  == "hemisphere"){
      order = neworderNum[key]
    }
    var color = colorArr[order]
    var marker = {symbol : 'square'}
  
    alias = key
    if(key.includes("New BU 1")){
      color = "#95CEFF"
    }else  if(key.includes("New BU 2")){
      color = "#f18226"
    }else  if(key.includes("Connected Products")){
      color = "#f18226"
    }else  if(key.includes("Unallocated")){
      color = "#8f8d8c"
    }
    let dataDSO = sortByDate(grouped[key])
  
    if (payload.graphType  == "hemisphere" || payload.graphType  == "gross"){
      if(key.includes("East")){
        stackType = "East"
      }else if(key.includes("West")){
        stackType = "West"
      }

     
      //TO WRITE DSO LABEL ON TOP OF THE BAR CHART
      if (key == "DSO"){
    
        if (payload.graphType  == "gross" ){
          let skipNext = false
          newData.map(x => {
              //FOR SUMMARY TAB
              if (payload.bu == "Summary"){
                
                //If have Uncallocated labeldso will be on top of unallocated 
                if (x.name.includes("Unallocated") && skipNext == false){
                  x= fillDataLabelDSO(x, dataDSO)
                  skipNext = true
                }
                 //If have CP labeldso will be on top of CP 
                 if (x.name.includes("New BU 2") && skipNext == false){
                  x= fillDataLabelDSO(x, dataDSO)
                  skipNext = true
                }
                //If have DASS labeldso will be on top of DASS 
                if (x.name.includes("New BU 1") && skipNext == false){
                  x= fillDataLabelDSO(x, dataDSO)
                  skipNext = true
                }
               
              }

              //FOR Unallocated TAB
              if (payload.bu == "Unallocated" && x.name.includes("Unallocated")){
                x= fillDataLabelDSO(x, dataDSO)
              }
              //FOR CP TAB
              if (payload.bu == "New BU 2" && x.name.includes("New BU 2")){
                x= fillDataLabelDSO(x, dataDSO)
              }
              //FOR DASS TAB
              if (payload.bu == "New BU 1" && x.name.includes("New BU 1")){
                x= fillDataLabelDSO(x, dataDSO)

              }
              x.pointWidth = 65
            
          })
        }
        if (payload.graphType  == "hemisphere") {
          let skipNext = false
          let nextName = ""
          newData.map(x => {
            if (payload.bu == "Summary"){
        
                // If have Uncallocated labeldso will be on top of unallocated 
                if (x.name.includes("Unallocated") && (nextName == "" || nextName.includes("Unallocated")) ){
                
                  x= fillDataLabelChartHemisphere(x, dataDSO, "top")
                  nextName = x.name
                  skipNext = true
                }
                //If have CP labeldso will be on top of CP 
                if (x.name.includes("Connected Products") && (nextName == "" || nextName.includes("Connected Products"))  ){
                  x= fillDataLabelChartHemisphere(x, dataDSO, "top")
                  nextName = x.name
                  skipNext = true
                }
                //If have DASS labeldso will be on top of DASS 
                if (x.name.includes("New BU 1") && (nextName == "" || nextName.includes("New BU 1")) ){
                  x= fillDataLabelChartHemisphere(x, dataDSO, "top")
                  nextName = x.name
                  skipNext = true
                }
  
                //TO GIVE WEST or EAST on DASS CHART WHEN BU on SUMMARY
                // if (x.name.includes("DASS") ){
                //   x= fillDataLabelChartHemisphere(x, dataDSO, "inside")
                // }
          
            }
            if ( x.name.includes("New BU 1") && payload.bu == "New BU 1"){
              x= fillDataLabelChartHemisphere(x, dataDSO, "top")
            }
            if ( x.name.includes("Connected Products") && payload.bu == "New BU 2"){
              x= fillDataLabelChartHemisphere(x, dataDSO, "top")
            }
            if ( x.name.includes("Unallocated") && payload.bu == "Unallocated"){
              x= fillDataLabelChartHemisphere(x, dataDSO, "top")
            }
             
            x.pointWidth = 65
            
          })
        }
       
          dataDSO = []
          // break

      }
  
    }else if (payload.graphType  == "region" ||  payload.graphType  == "country"){
      if (key != "DSO") {

        //TO TAKE CURRENT MONTH ONLY
        stackType = grouped[key][0].hemisphere
        dataDSO[0].name = grouped[key][0].hemisphere
        dataDSO[0].y = grouped[key][0].y
        dataDSO[0].dso = grouped[key][0].y
        if (grouped['DSO']) {
          grouped['DSO'].forEach(item => {
            if (item.name == grouped[key][0].hemisphere){
              dataDSO[0].total_receivable = item.os
            }
          })
        }
        
      }
    
    
    }
    if (alias != "DSO") {
      if (payload.graphType  == "region" ||  payload.graphType  == "country") {
        newData.push({ marker:marker, pointWidth:65, color: color, "yAxis": yAxisVal, "type": chartType, "name": alias, "hemisphere" : dataDSO[0].hemisphere, data:  dataDSO})
      }else {
        newData.push({ marker:marker, pointWidth:65, color: color, "yAxis": yAxisVal,stack: stackType,"type": chartType, "name": alias, data:  dataDSO})
      }
    }

  
    idx++
  }


  let regionSequence= ["NAM", "MEX", "LAM", "UK AND NORTH EUROPE", "RUSSIA AND CASPIAN", "SOUTH EUROPE / NA", "MEA", "ASIA PACIFIC"]

if(payload.graphType =="region"){

    console.log('dataDSOdataDSOdataDSO', JSON.parse(JSON.stringify(newData)));
    
    newData.sort((a,b)=> {
      return regionSequence.indexOf(a.hemisphere) - regionSequence.indexOf(b.hemisphere);
   })

   if (payload.region) {
      

          if (payload.region && !payload.region[0].includes('All')){
            newData.forEach((itemValue) => {
              if(itemValue.data.length > 1 ){
                itemValue.data[0].name = itemValue.data[0].uniqueName
              }
            })
          }
      }

  }

  if(payload.graphType == "country"){

    console.log(originalData,'dataDSOdataDSOdataDSO', newData)

    newData.sort(
      function (a, b) {
        return b.data[0].y > a.data[0].y ? 1 : -1;
      }
    );


    if ( payload.country) {

      if (payload.country && !payload.country[0].includes('All')){
        newData.forEach((itemValue) => {
          if(itemValue.data.length > 1 ){
            itemValue.data[0].name = itemValue.data[0].uniqueName
          }
        })
      }
  }


  }

  let countryOrderMap = {}
  let countryMap = []

  if (payload.graphType =="region" || payload.graphType =="country" ) {

          newData.forEach(x => {            
            x= fillDataLabelDSORegionCountry(x)
          })


          let dassArr = {
            color: "#95CEFF",
            legendIndex: 0,
            marker:{symbol: "square"},
            name: "Unallocated",
            pointWidth: 65,
            stack: "",
            type: "column",
            y:0,
            data:[]
          };
          let cpArr = {
            color: "#f18226",
            legendIndex: 2,
            marker:{symbol: "square"},
            name: "Unallocated",
            pointWidth: 65,
            stack: "",
            type: "column",
            y:0,
            data:[]
          };
          let unallocatedArr = {
            color: "#8f8d8c",
            dataLabels:{
              crop: false,
              enabled: true,
              overflow: "allow",
              verticalAlign: "top",
              y: -25
            },
            legendIndex: 2,
            marker:{symbol: "square"},
            name: "Unallocated",
            pointWidth: 65,
            stack: "",
            type: "column",
            y:0,
            data:[]
          };


          newData.forEach(item => {

            if(item && item.data && item.data.length){
              item.data.forEach(innerItem => {
                if (innerItem.new_bu == 'New BU 1'){
                  dassArr.data.push(innerItem);
                }
                if (innerItem.new_bu == 'Unallocated'){
                  unallocatedArr.data.push(innerItem);
                }
                if (innerItem.new_bu == 'Connected Products' || innerItem.new_bu == 'New BU 2'){
                  cpArr.data.push(innerItem);
                }
              })
            }

            // if (item.data[0].new_bu == 'DASS'){
            //   dassArr.data.push(item.data[0]);
            // }
            // if (item.data && item.data[1] && item.data[1].new_bu == 'DASS'){
            //   dassArr.data.push(item.data[1]);
            // }
            // if (item.data[0].new_bu == "Unallocated"){
            //   unallocatedArr.data.push(item.data[0])
            // }
            // if (item.data && item.data[1] && item.data[1].new_bu == "Unallocated"){
            //   unallocatedArr.data.push(item.data[1])
            // }
            // if (item.data[0].new_bu == 'Connected Products' || item.data[0].new_bu == 'CP'){
            //   cpArr.data.push(item.data[0])
            // }
            // if (item.data && item.data[1] && item.data[1].new_bu == 'Connected Products' || item.data && item.data[1] && item.data[1].new_bu == 'CP'){
            //   cpArr.data.push(item.data[1])
            // }
          })

          if (payload.graphType =="country") {
            dassArr.data.sort((a,b) => {
              return b.total_receivable - a.total_receivable
            })
  
            dassArr.data.forEach((item, index) => {
              countryOrderMap[item.hemisphere] = index;
              countryMap.push(item.hemisphere);
            })
  
            unallocatedArr.data.forEach(newItem => {
              newItem["orderIndex"] = countryOrderMap[newItem.hemisphere]
            })
            unallocatedArr.data.sort((a, b) => {
              return a.orderIndex - b.orderIndex
            })
          }
          


          newData = [];
          newData.push(unallocatedArr)
          newData.push(cpArr)
          newData.push(dassArr)

          console.log('dataDSOdataDSOdataDSOHere', newData)

  }



  

  if(payload.graphType=="hemisphere"){

    newData.forEach(x => {

       if(x.name == "New BU 1 West" || x.name == "New BU 1 East"){
           x.name = "New BU 1";
           x.legendIndex = 0;
       }
       if(x.name == "Connected Products West" || x.name == "Connected Products East"){
           x.name = "New BU 2";
           x.legendIndex = 1;
       }   
       if(x.name == "Unallocated East"  || x.name == "Unallocated West"){
           x.name = "Unallocated";
           x.legendIndex = 2;
       }   
   })

   console.log('hemishphereDatahemishphereData', newData);
 }
 let busChecklist = {
     "Unallocated": true,
     "Connected Products":true,
     "DASS" :true,
     "CP": true,
     "DSO": true,
 }
 if( payload.graphType=="region" || payload.graphType=="country" || payload.graphType=="gross" ){

     newData.forEach(x => {
         let bu ;
         if( payload.graphType=="region" || payload.graphType=="country"){
             bu = x.data[0].new_bu;
         }else if(payload.graphType =="gross"){
             bu = x.name;
         }else {
             bu = x.name.split(" ")[0];
             console.log(bu, "hemisphere bu")
         }

         if(busChecklist[bu]) {
             if( bu == "Connected Products" || bu == "New BU 2"){
                 x.name ="New BU 2";
                 x.legendIndex = 1;
             }
             if( bu == "New BU 1"){
                 x.name ="New BU 1";
                 x.legendIndex =0;
             }
             if( bu == "Unallocated"){
                 x.name ="Unallocated";
                 x.legendIndex = 2;
             }

             busChecklist[bu] = false;
         }else {
             x.showInLegend = false;
         }
               
     })
 }


 let idx1 = 0
    let splineData = [];
  for (let keys in sortedData) {
    let key = sortedData[keys]
    let order = orderNum[key]
    let color = colorArr[order]
    let marker = {symbol : 'square'}
    let alias = key
   
    if (key == "DSO") {
      chartType = "spline"
      yAxisVal = 1
      color = '#4472c4'
      marker = {symbol:'round'}
      let dataDSO = sortByDate(grouped[key])
      console.log(grouped[key], 'grouped[key]grouped[key]', countryOrderMap)

      if(payload.graphType =="region"){
        dataDSO =  grouped[key].sort((a,b)=> {
          return regionSequence.indexOf(a.name) - regionSequence.indexOf(b.name);
       })
      }else if(payload.graphType =="country"){
        var sortable = [];
        for (var vehicle in countryOrderMap) {
          sortable.push([vehicle, countryOrderMap[vehicle]]);
        }

        sortable.sort(function (a, b) {
          return a[1] - b[1];
        });
        sortable = sortable.map(item => {

          return item[0]
        });

        dataDSO = grouped[key].sort((a,b)=> {
          return sortable.indexOf(a.name) - sortable.indexOf(b.name);
       })
        
      }else if(payload.graphType=="hemisphere"){
        stackType = 'hemi'
      }


      splineData.push({ marker:marker, color: color, "yAxis": yAxisVal,stack: stackType,"type": chartType, "name": alias, data:  dataDSO})
      
    }
   
   
   
    idx1++
  }

  console.log('splineData', splineData)
  console.log(newData, "cekNewDataRAW")
  let refinedData = [...newData, ...splineData]
  console.log(refinedData, "refinedDatarefinedData")

  return refinedData
}
export const convertDSOChartData = (data, payload) => {
  if (data == null) {
    return []
  }
  let newData = [];
  if (payload.receivablesTab == 0){
    newData = convertDSOChartDataLegacy(data, payload)
  }
  if (payload.receivablesTab == 1){
    newData = convertDSOChartDataNew(data, payload)
    
  }
 
    return newData
}

export const getDSOTableData = (data, payload) => {
  if (data == null) {
    return []
  }
  let newData = data.filter(x => {
      return x.category == "DSO"
  })

  if (payload.receivablesTab == 1){
    newData = data.filter(x => {
      if ((x.name != "Feb 21" && x.name != "Jan 21") &&  x.category == "DSO"){
        return x
      }
  
    })
  }
  return sortByDateDesc(newData)
}

export const getYearlyChart = (data) =>{
  console.log(data)
  var worsenedData = []
  var improvedData = []

  data.forEach(item => {
    item.name = item.x
    delete item.x
    if(item.type == "R"){
       worsenedData.push(item)
    }else{
      improvedData.push(item)
    }
  })



  let worsenSeries  = {
    name: "Worsened in last 30 days",
    data : worsenedData,
    color : "#f44336"
  }

  let improvedSeries = {
    name : "Improved in last 30 days",
    data : improvedData,
    color : "#ffc107"
  }

  return [worsenSeries, improvedSeries]
  // return data;
}

export const convertChartData = (rowData) => {
  let billedDefault = rowData.totalbilled.row_data_default;
  let unbilledDefault = rowData.totalunbilled.row_data_default;
  let billedArea = rowData.totalbilled.row_data_area;
  let unbilledArea = rowData.totalunbilled.row_data_area;
  rowData.totalbilled.row_data_default = mapToChartData(billedDefault, "billed");
  rowData.totalunbilled.row_data_default = mapToChartData(unbilledDefault, "unbilled");
  rowData.totalbilled.row_data_area = mapToChartData(billedArea, "billed");
  rowData.totalunbilled.row_data_area = mapToChartData(unbilledArea, "unbilled");
  return rowData
}

export const reOrderTableViewData = (rowData, type)=>{
  if (rowData == undefined) return {row_data : [] , row_header : []}
  if(type == "region"){
    rowData.row_header.forEach(item => {
      if(item.key == "business_unit"){
        item.label = "Region"
      }
    })
  }
  if (rowData.row_data && rowData.row_data.length) {
    let grand_total = rowData.row_data.filter(function(item){
      return item.business_unit === 'Grand Total';
    });
    let dataWithoutGrandTotal = rowData.row_data.filter(function(item){
      return item.business_unit !== 'Grand Total';
    });

    rowData.row_data = [...dataWithoutGrandTotal, grand_total[0]]
  }
  return rowData;
}

export const convertARChartView = (rowData)=> {
  rowData.RedPlotData.forEach(red => {
    red.y = Number(red.y.toFixed(2))
  })

  rowData.YellowPlotData.forEach(yellow => {
    yellow.y = Number(yellow.y.toFixed(2))
  })

  return rowData
}

export const sortDataAnalysis = (rowData)=>{
  rowData.row_data.sort((a,b) => b.total - a.total);

  return rowData
}

export const getSortingSubchart = (data, sortArr) => {
  let sortedData = []

  sortArr.forEach(key => {
    sortedData.push({"key" : key, "value" : data[key]})
  }); 


  console.log(sortedData)
  return sortedData
  
}