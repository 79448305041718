import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HighChart from './highchart'

export default function MonthlyChange() {

    const [radioFilterValue, setRadioFilterValue] = React.useState('1')

    return (
        <div className="MonthlyChange mb-20">
            <RadioGroup aria-label="radio filter" value={radioFilterValue} className='blockRadio background-class mb-20'>
                <FormControlLabel value='1' control={<Radio />} label='FCF' />
                <FormControlLabel value='2' control={<Radio />} label='EBITDA' />
                <FormControlLabel value='3' control={<Radio />} label='Revenue' />
                <FormControlLabel value='4' control={<Radio />} label='Cost' />
                <FormControlLabel value='5' control={<Radio />} label='Inventory' />
                <FormControlLabel value='6' control={<Radio />} label='Recievables' />
            </RadioGroup>
            <HighChart />
        </div>
    )
}
