import ScatterChart from "./charts"
import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../../../utils/numberUtils'

function ChartContainer(props) {
    let xAxisRed = {
        max: props.chartData.RedNetDueDayMax,
        min: props.chartData.RedNetDueDayMin
    }
    let xAxisYellow = {
        max: props.chartData.YellowNetDueDayMax,
        min: props.chartData.MinNetDueDayMin
    }  
    return (
        <>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12}>
                        <Card>
                          <CardContent>
                            <ScatterChart
                                subTitle = "> 90 days overdue amounts that have worsened in last 30 days"
                                xAxis = {xAxisRed}
                                width = {props.width}
                                color="#e53935"
                                chartData={props.chartData.RedPlotData}
                                tabSelectorValue = {props.tabSelectorValue}
                                chartTitle={"Value $ " + numberWithCommas(Number.parseFloat(props.chartData.RedTotalAmount).toFixed(0)) + " - " + numberWithCommas(props.chartData.RedTotalAccounts) + " Accounts"}
                            ></ScatterChart>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Card>
                          <CardContent>
                            <ScatterChart
                                subTitle = "> 90 days overdue amounts that have improved in last 30 days"
                                xAxis = {xAxisYellow}
                                color="#f69a33"
                                width = {props.width}
                                chartData={props.chartData.YellowPlotData}
                                tabSelectorValue = {props.tabSelectorValue}
                                chartTitle={"Value $ " + numberWithCommas(Number.parseFloat(props.chartData.YellowTotalAmount).toFixed(0)) + " - " + numberWithCommas(props.chartData.YellowTotalAccounts) + " Accounts"}
                            ></ScatterChart>
                          </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ChartContainer;