import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
// import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import TableComponent from './summary';
import { Button, Chip, Tooltip, Typography, TextField } from '@material-ui/core';
import InvoiceDetailModal from './DrillDown';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toastr } from "react-redux-toastr";


function LinkTab(props) {
    return (
        <Tab
            component='a'
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const tabValueMap = {
    0: 'Q1-2022',
    1: 'Q3-2022',
    2: 'Q4-2022',
    3: ''
}

const amountFilterArr = [
    { key: '50', value: 'Top 50' },
    { key: 'all', value: 'All' },
]

export class DASSByGeoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            customerTypeValue: 'customer',
            typeValue: 'hemisphere',
            isByPMFilterEnable: false,
            customerNameSelected: [],
            isAmountFilterEnable: false,
            isLimitFilterEnable: false,
            amountSelectorValue: '50',
            isExcelIconEnable: true,
            legacyBuValue: 'BU 1',
            downloadExcel: 'false',
            showInvoiceDetailModal: false,
            drilldownHeader : '',
            year: '',
            selectedCustomerName: ''

        }
    }

    componentDidMount() {
        this.fetchDataFromServer()
        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'
            })
        }, 3000)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    fetchDataFromServer = (isDownload) => {
        console.log('trace 0', this.state)
        const { customerTypeValue,legacyBuValue,amountSelectorValue, typeValue ,customerNameSelected} = this.state
        const { isCustomer } = this.props


        let payload = {
            export: isDownload,
            type: isCustomer ? (customerTypeValue == 'customer_v2' ? 'customer' : customerTypeValue) : typeValue,
            isCustomer,
            bu: isCustomer ? legacyBuValue : undefined,
            customer_name: isCustomer ?  customerNameSelected : undefined,
            amount_top: isCustomer ?  amountSelectorValue : undefined,
            version : isCustomer? ( customerTypeValue == 'customer_v2' ? 'v2' : undefined ): undefined
        }

        this.props.fetchDASSByGeoTableData(payload);

    }


    handleHemisphereChange = (event, newValue) => {
        this.setState({
            typeValue: newValue,

        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleAmountChange = (event) => {
        if (event.target) {
            this.setState({
                amountSelectorValue: event.target.value,
                downloadExcel: false
            }, () => {
                this.fetchDataFromServer()
            });
        }
    }

    handleLegacyBuValue = (event, newValue) => {
        this.setState({
            legacyBuValue: newValue,

        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleRadioCustomerChange = (event, newValue) => {
        this.setState({
            customerTypeValue: newValue,

        }, () => {
            this.fetchDataFromServer()
        })
    }



    // handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
    handleDrilldownClick = (_data, _item) => {
        console.log('trace 7', _data, _item)
        const {typeValue, legacyBuValue, customerTypeValue} = this.state
        let payload = {}
        let drilldownTitle = ''



        switch (typeValue) {
            case 'country_name':
                payload = {
                    "type": "country_name",
                    "data_field": _data.data_field,
                    "year_label": _item.label
                }
                this.props.fetchDASSByGeoDrilldownTableData(payload);
                drilldownTitle = ''
                break;
        
            default:
                payload = {
                    "customer_name" : _data.customer_name,
                    "bu": _data.bu,
                    "year_label": _item.label,
                    "version" : customerTypeValue == 'customer_v2' ? 'v2' : undefined,
                    "legal_entity" : customerTypeValue == 'customer_le' ? _data.legal_entity : undefined
                }
                this.props.fetchDASSRevenueByCustomerDrilldownTableData(payload);
                const value = Math.ceil(Math.abs(_data[_item.label])).toLocaleString();

                drilldownTitle = _data.customer_name + " - " + _item.label + " - " + value
                break;
        }


        this.setState({ drilldownHeader : drilldownTitle, showInvoiceDetailModal: true, year: _item.label, selectedCustomerName: _data.customer_name})

    }

    handleUpdateDrilldown = (_data) => {

        const toastrConfirmOptions = {
            onOk: () => {
                const {selectedCustomerName, legacyBuValue, customerTypeValue, year} = this.state
                let payload = {
                    payloadUpdate: {
                        "customer_name": _data.customer,
                        "version" : customerTypeValue == 'customer_v2' ? 'v2' : undefined
                    },
                    payloadFetch: {
                        "customer_name" : selectedCustomerName,
                        "bu": legacyBuValue,
                        "year_label": year,
                        "version" : customerTypeValue == 'customer_v2' ? 'v2' : undefined
                    }
                }
                if(customerTypeValue == 'customer_le') {
                    payload.payloadFetch.legal_entity = _data.legal_entity
                }
                this.props.updateDASSRevenueByCustomerDrilldownTableData(payload);
            },
            onCancel: () => console.log('CANCEL: clicked')
        };


        toastr.confirm(`Are you sure you want to delete ${_data.customer}?`, toastrConfirmOptions)
        
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    switchContent = () => {
        const { typeValue, showInvoiceDetailModal, drilldownHeader, selectedCustomerName } = this.state

        const { incomeStatementState, } = this.props

        const { dassAOPData } = incomeStatementState

        const { dassByGeo } = dassAOPData

        const { rowData, drilldown } = dassByGeo



        return (<div>
            {!dassByGeo?.loading ? 
            <TableComponent
                tableData={dassByGeo}
                rowClick={this.rowClick}
                handleDrilldownClick={this.handleDrilldownClick}
            /> : <CircularProgress style={{ margin: '0 50%' }} />}
            {showInvoiceDetailModal &&
                // <div>Show Modal</div>
                <InvoiceDetailModal
                    // getInvoiceDetailGL={this.handleGLInvoiceSorting}
                    showModal={showInvoiceDetailModal}
                    modalCallBack={this.invoiceModalCallBack}
                    invoiceDetailState={drilldown}
                    // invoiceDetailStateFilter={invoiceDetailStateFilter}
                    invoiceLoading={drilldown.loading}
                    // showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                    showDrilldownHeaderValue={drilldownHeader}
                    isExpandable={true}
                    isCustomer={this.props.isCustomer}
                    handleUpdateDrilldown={this.handleUpdateDrilldown}
                    selectedCustomerName={selectedCustomerName}
                // handleFilterChange={this.handleInvoiceFilterChange}
                // handleInvoiceSorting={this.handleInvoiceSorting}
                // invoiceDetailStateGL={invoiceDetailStateGL}
                // invoiceLoadingGL={invoiceLoadingGL}
                // financialDuration={this.props.financialDuration}
                // handleDownloadFromBE={true}
                // handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                />
            }
        </div>)

    }



    handleRadioContent = () => {
        const { isCustomer } = this.props
        const { customerTypeValue, typeValue } = this.state
        if (isCustomer) {

            return (
                <FormControl component='fieldset'>
                    <RadioGroup
                        value={customerTypeValue}
                        onChange={this.handleRadioCustomerChange}
                        name='radio'
                        className='radioGroupHorizontal'
                    >
                        <FormControlLabel
                            value={'customer'}
                            control={<Radio color='primary' />}
                            label='Customer Group'
                        />
                        {/* <FormControlLabel
                            value={'customer_v2'}
                            control={<Radio color='primary' />}
                            label='Customer Group V2'
                        /> */}
                        <FormControlLabel
                            value={'customer_le'}
                            control={<Radio color='primary' />}
                            label='Customer LE'
                        />

                    </RadioGroup>
                </FormControl>
            )

        } else {
            return (
                <FormControl component='fieldset'>
                    <RadioGroup
                        value={typeValue}
                        onChange={this.handleHemisphereChange}
                        name='radio'
                        className='radioGroupHorizontal'
                    >
                        <FormControlLabel
                            value={'hemisphere'}
                            control={<Radio color='primary' />}
                            label='Hemisphere'
                        />
                        <FormControlLabel
                            value={'region'}
                            control={<Radio color='primary' />}
                            label='Sub Region'
                        />
                        <FormControlLabel
                            value={'country_name'}
                            control={<Radio color='primary' />}
                            label='Country'
                        />

                    </RadioGroup>
                </FormControl>
            )
        }
    }

    handleCustomerNameChange = (event, value) => {
        this.setState({
            customerNameSelected: value,

        }, () => {
            this.fetchDataFromServer()
        })
    }



    handleSubOptions = () => {
        const { isCustomer, incomeStatementState } = this.props
        const { dassAOPData } = incomeStatementState
        const { dassByGeo } = dassAOPData
        const {filters} = dassByGeo
        let customerNameArr = filters?.[0]?.value || []
        const { customerNameSelected, legacyBuValue, amountSelectorValue } = this.state
        if (isCustomer) {

            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4}>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={legacyBuValue}
                                onChange={this.handleLegacyBuValue}
                                name='radio'
                                className='radioGroupHorizontal'
                            >
                                <FormControlLabel
                                    value={'BU 1'}
                                    control={<Radio color='primary' />}
                                    label='BU 1'
                                />
                                <FormControlLabel
                                    value={'BU 2'}
                                    control={<Radio color='primary' />}
                                    label='BU 2'
                                />
                                <FormControlLabel
                                    value={'BU 3'}
                                    control={<Radio color='primary' />}
                                    label='BU 3'
                                />
                                <FormControlLabel
                                    value={'BU 4'}
                                    control={<Radio color='primary' />}
                                    label='BU 4'
                                />
                                <FormControlLabel
                                    value={'total'}
                                    control={<Radio color='primary' />}
                                    label='Total'
                                />

                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3} style={{position: 'relative', top: '-15px'}}>
                        <FormControl className='w-200'>
                            <Autocomplete
                                multiple
                                limitTags={1}
                                value={customerNameSelected}
                                  options={customerNameArr?.sort()}
                                // options={[]}
                                getOptionLabel={(option) => option}
                                onChange={this.handleCustomerNameChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Search By Customer Name"
                                        placeholder="Search By Customer Name"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                            id: 'field-1',
                                        }}
                                    />
                                )}
                                getOptionSelected={(option, val) => option == val}
                                renderTags={(value, { params, onDelete }) =>
                                    value.map((option, index) => (
                                        <Tooltip title={option}>
                                            <Chip
                                                {...params}
                                                key={option}
                                                label={option.length > 5 ?
                                                    option.substr(0, 3) + '..' :
                                                    option}
                                                size="small"
                                                onDelete={onDelete}
                                            />
                                        </Tooltip>
                                    ))
                                }
                                renderOption={(option) => <Typography
                                    style={{ fontSize: '10px' }}
                                    noWrap>
                                    <span>{option}</span>
                                </Typography>}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2}>
                        <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                            <InputLabel>Filter By</InputLabel>
                            <Select
                                value={amountSelectorValue}
                                label="Month"
                                // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                onChange={this.handleAmountChange}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {amountFilterArr && amountFilterArr.length && amountFilterArr.map(item => {
                                    return <MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                </React.Fragment>

            )

        }

        return null;
    }



    handleExcelDownload = () => {
        this.fetchDataFromServer(true)
    }

    render() {
        const {
            typeValue,

            isExcelIconEnable,
        } = this.state
        const { incomeStatementState, } = this.props
        const { dassAOPData } = incomeStatementState
        const { dassByGeo } = dassAOPData
        const { filters } = dassByGeo



        return (

            <div>

                {<div>
                    <div>
                        <Grid container>
                            <Grid item xs={11} sm={11}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8} sm={8}>
                                        {this.handleRadioContent()}
                                    </Grid>
                                </Grid>
                            </Grid>

                            {isExcelIconEnable &&
                                <Grid item xs={1} sm={1}>
                                    <Tooltip title={'Download to Excel'} style={{ float: 'right' }}>
                                        <img
                                            src={DownloadExcel}
                                            className='pointer'
                                            height='30'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.handleExcelDownload();
                                            }} />
                                    </Tooltip>
                                </Grid>
                            }

                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={1}>
                                    {this.handleSubOptions()}
                                </Grid>

                            </Grid>

                        </Grid>

                    </div>

                    {this.switchContent()}

                </div>}

            </div>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchOrderbookData(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_DATA',
                        payload: payload
                    });
                },
                fetchDASSByGeoTableData(payload) {
                    dispatch({
                        type: 'FETCH_DASS_BY_GEO_TABLE_DATA',
                        payload: payload
                    });
                },
                fetchDASSByGeoDrilldownTableData(payload) {
                    dispatch({
                        type: 'FETCH_DASS_BY_GEO_TABLE_DATA_DRILLDOWN',
                        payload: payload
                    });
                },
                fetchDASSRevenueByCustomerDrilldownTableData(payload) {
                    dispatch({
                        type: 'FETCH_DASS_REVENUE_BY_CUSTOMER_DATA_DRILLDOWN',
                        payload: payload
                    });
                },
                updateDASSRevenueByCustomerDrilldownTableData(payload) {
                    dispatch({
                        type: 'UPDATE_DASS_REVENUE_BY_CUSTOMER_DATA_DRILLDOWN',
                        payload: payload
                    });
                },
            };
        }
    )(DASSByGeoComponent);