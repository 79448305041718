import React from 'react';
import { useTable, useExpanded } from 'react-table';
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

const Styles = styled.div`
  padding: 1rem;

  .table {
    border: 1px solid #ddd;
 
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

let draggedCol = 0;
function NewReactTable({ columns, data }) {
    const [records, setRecords] = React.useState(data)
    const [columnData, setColumnData] = React.useState(columns)

    const getRowId = React.useCallback(row => {
        return row._myId
    }, [])

    const getColumnId = React.useCallback(column => {
      return column.accessor
  }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            data: records,
            columns: columnData,
            getRowId,
            getColumnId,
        },
        useExpanded, // Use the useExpanded plugin hook
        useSticky
    );

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex]
        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }

    React.useEffect(() => {
      mountEvents()
    })

    React.useEffect(() => {
      mountEvents()
    }, [columnData])

   const mountEvents = () => {
      var headers = Array.prototype.slice.call(document.querySelectorAll(".header-border-right"));
      headers.forEach((header, i) => {
        header.setAttribute("draggable", true);
        header.ondrag = (e) => e.stopPropagation();
        header.ondragend = (e) => e.stopPropagation();
        header.ondragover = (e) => e.preventDefault();
  
        header.ondragstart = (e) => {
          e.stopPropagation();
          draggedCol = i;
          e.dataTransfer.setData("text", "fix firefox dragevents");
        };
  
        header.ondrop = (e) => {
          e.preventDefault();
          // Remove item from array and stick it in a new position.
          // console.log(columnData, 'test>>>>', e.target, i, draggedCol, headerGroups)
          columnData.splice(i, 0, columnData.splice(draggedCol, 1)[0]);
          const newColLayout = [...[],...columnData];

          setColumnData(newColLayout)
        };
      });
    }



    const DND_ITEM_TYPE = 'row'

    const Row = ({ row, index, moveRow }) => {
        const dropRef = React.useRef(null)
        const dragRef = React.useRef(null)

        const [, drop] = useDrop({
            accept: DND_ITEM_TYPE,
            hover(item, monitor) {
                if (!dropRef.current) {
                    return
                }
                const dragIndex = item.index
                const hoverIndex = index
                // Don't replace items with themselves
                if (dragIndex === hoverIndex) {
                    return
                }
                // Determine rectangle on screen
                const hoverBoundingRect = dropRef.current.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }
                // Time to actually perform the action
                moveRow(dragIndex, hoverIndex)
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.index = hoverIndex
            },
        })

        const [{ isDragging }, drag, preview] = useDrag({
            type: DND_ITEM_TYPE,
            item: { type: DND_ITEM_TYPE, index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        })

        const opacity = isDragging ? 0 : 1

        preview(drop(dropRef))
        drag(dragRef)
        return (
            <tr ref={dropRef} style={{ opacity }}>
                
                {row.cells.map((cell,index) => {
                    return (
                        <td ref={index == 0 ? dragRef : null} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                })}
            </tr>
        )
    }


    return (
        <div className="xpna-table-wrapper">
            <DndProvider backend={HTML5Backend}>

                <table
                    className='xpna-pure-table double-layer-header'
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className={'header-border-right'} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows && rows.length ? rows.map(
                            (row, index) =>
                                prepareRow(row) || (
                                    <Row
                                        index={index}
                                        row={row}
                                        moveRow={moveRow}
                                        {...row.getRowProps()}
                                    />
                                )
                        ) : null}
                    </tbody>
                </table>
            </DndProvider>

        </div>
    );
}

export default NewReactTable;