import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../comment';
import PlanModal from '../plan';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

function createDataESP(ESP_Name, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat){
  return {ESP_Name, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat}
}

const rows = [
  createDataESP('Pressure', '10 Days', '20 Days','','',''),
  createDataESP('Volumes', '20 Days', '20 Days','','',''),
  createDataESP('Water and oil hauling', '80 Days', '20 Days','','',''),
  createDataESP('Downtimes', '30 Days', '20 Days','','',''),
  createDataESP('Treatments', '90 Days', '20 Days','','',''),
  createDataESP('Meter readings', '30 Days', '20 Days','','',''),
  createDataESP('Fluid shipments', '90 Days', '20 Days','','',''),
];



function PreventiveMeasure() {
    const [open, setOpen] = React.useState(false);
    const [openPlanModal, setOpenPlanModal] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenPlanModal = () => setOpenPlanModal(true);
    const handleClosePlanModal = () => setOpenPlanModal(false);


    return (
         <div className="table-view">
             <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className="title">ESP Maintenance-related activities</div>
                </Grid>
              </Grid>
             <Grid container spacing={3}>
                <Grid item xs={12}>
                   <TableContainer>
                      <Table aria-label="a dense table" className="custom-table" size="small"> 
                        <TableHead>
                          <TableRow>
                            <TableCell width="80">Edit</TableCell>
                            <TableCell>ESP Maintenance-related activities</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Number of Days</TableCell>
                            <TableCell>Spare parts needed</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Initiate Chat / Remedial Action </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.ESP_Name}>
                              <TableCell className="flex ai-center text-primary" width="80"><Checkbox/><EditIcon fontSize="small"/></TableCell>
                              <TableCell>{row.ESP_Name}</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="center" className="action"><ChatBubbleIcon fontSize="small" onClick={()=>{handleOpen()}}/> <AddCircleOutlineIcon fontSize="small" onClick={()=>{handleOpenPlanModal()}}/></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </Grid>
              </Grid>
              <Modal
                className="collaborate-modal"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <CommentBox handleClose={handleClose}/>
                </Fade>
            </Modal>
            <PlanModal open={openPlanModal} handleClose={handleClosePlanModal}/>
         </div>
        
    )
}

export default PreventiveMeasure;