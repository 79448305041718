import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Modal from '@material-ui/core/Modal';
import CommentBox from './comment';
import PlanModal from './plan';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SmsIcon from '@material-ui/icons/Sms';
import { APP_ICONS } from '../../../constant';
import Tooltip from '@material-ui/core/Tooltip';
import RouteOperational from './routeOperational';


function createData(name, t360, t90, t30, t1, tPlus1, tPlus30, detail) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30, detail};
}

function createDetail(name, t360, t90, t30, t1, tPlus1, tPlus30) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30};
}

const rows = [
  createData(
    'Revenue', "", "", "", "", "", "",
    [
     createDetail("Passenger","", "", "", "", "", ""),
     createDetail("Cargo","", "", "", "", "", ""),
     createDetail("Food & Beverage","", "", "", "", "", ""),
     createDetail("Other","", "", "", "", "", ""),
    ]
  ),
  createData(
    'Cost',"", "", "", "", "", "",
    [
     createDetail("Fuel Cost","", "", "", "", "", ""),
     createDetail("Crew cost","", "", "", "", "", ""),
     createDetail("Operational Cost","", "", "", "", "", ""),
     createDetail("Overhead Cost","", "", "", "", "", ""),
     createDetail("Other","", "", "", "", "", ""),
    ]
  ),
  createData(
    'Route Profit/ Loss',"", "", "", "", "", "",
    []
  )
];

const rowsDummy = [
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
  createData("Fuel Cost","", "", "", "", "", "",[]),
]

const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        expandComponent.map(detail => (
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>{detail.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
         </TableRow>
        ))
      )}
    </>
  );
};


export default function Profitabaility() {

  const [value, setValue] = React.useState('pnl_reports');
  const [open, setOpen] = React.useState(false);
  const [openPlanModal, setOpenPlanModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPlanModal = () => setOpenPlanModal(true);
  const handleClosePlanModal = () => setOpenPlanModal(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="dashboard-content">
      <Grid container>
          <Grid item xs={9} className="flex ai-center jc-flex-start pl-20">
              <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                <FormLabel component="legend">Route Number</FormLabel>
                <Select label="Select"> 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                <FormLabel component="legend">Origin</FormLabel>
                <Select label="Select"> 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                <FormLabel component="legend">Destination</FormLabel>
                <Select label="Select"> 
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={3} className="flex jc-flex-end ai-center">
            <FormControl variant="outlined"  size="small" className="mr-30">
              <TextField id="outlined-basic" label="Date" variant="outlined" size="small" type="date" helperText={null} InputLabelProps={{ shrink: true }}/>
            </FormControl>
          </Grid>
      </Grid>
      <Grid container className="mt-30">
          <Grid item xs={9} className="pl-20">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="menu" name="menu" value={value} onChange={handleChange}>
                  <FormControlLabel value="pnl_reports" control={<Radio />} label="Route P&L" className="form-label-custom mr-30"/>
                  <FormControlLabel value="route_operational" control={<Radio />} label="Route Operational Parameters" className="form-label-custom mr-30"/>
                  {/*<FormControlLabel value="route" control={<Radio />} label="Route Analytics" className="form-label-custom"/>*/}
                </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} className="flex jc-flex-end ai-center">
            <Button variant="contained" color="secondary" className="mr-20 btn-custom" size="small" onClick={()=>{handleOpen()}}><SmsIcon/></Button>
            <Button variant="contained" color="secondary" className="mr-30 btn-custom" size="small" onClick={()=>{handleOpenPlanModal()}}>Create Initiatives</Button>
            
          </Grid>
      </Grid>
      <Grid container className="mt-10 pl-10 pr-30">
        {value === "pnl_reports" && (
          <Grid container>
            <Grid container className="mb-20">
                <Grid item xs={12} className="flex jc-flex-end ai-center">
                    <Tooltip title={'Download to Excel'}>
                      <img
                        src={downloadExcelIcon}
                        height='35'
                        className='download-icon mr-10'
                      />
                    </Tooltip>
                    <Tooltip title={'Download to Pdf'}>
                      <img
                        src={downloadPdfIcon}
                        height='35'
                        className='download-icon'
                      />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Table className="custom-table" aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      <TableCell></TableCell>
                      <TableCell>T-360</TableCell>
                      <TableCell>T-90</TableCell>
                      <TableCell>T-30</TableCell>
                      <TableCell>T-1</TableCell>
                      <TableCell>T+1</TableCell>
                      <TableCell>T+30</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <ExpandableTableRow
                        key={row.name}
                        expandComponent={row.detail}
                      >
                        <TableCell component="th" scope="row">{row.name}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </ExpandableTableRow>
                    ))}
                  </TableBody>
                </Table>
            </Grid>
          </Grid>
        )}
        {value === "route_operational" && (
          // <Grid item xs={12}>
          //     <Table className="custom-table" aria-label="simple table">
          //       <TableHead>
          //         <TableRow>
          //          <TableCell></TableCell>
          //           <TableCell>Load Factor</TableCell>
          //           <TableCell>Delays</TableCell>
          //           <TableCell>Fuel Consumption</TableCell>
          //           <TableCell>Crew Information</TableCell>
          //           <TableCell>Other Operational Factors</TableCell>
          //         </TableRow>
          //       </TableHead>
          //       <TableBody>
          //         {rowsDummy.map(row => (
          //           <TableRow>
          //            <TableCell></TableCell>
          //             <TableCell></TableCell>
          //             <TableCell></TableCell>
          //             <TableCell></TableCell>
          //             <TableCell></TableCell>
          //             <TableCell></TableCell>
          //           </TableRow>
          //         ))}
          //       </TableBody>
          //     </Table>
          // </Grid>
          <RouteOperational/>
        )}
        {value === "route" && (
          <Grid item xs={12}>
              <Table className="custom-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Flight Number</TableCell>
                    <TableCell>Load Factor</TableCell>
                    <TableCell>Occupancy %</TableCell>
                    <TableCell>Crew Total</TableCell>
                    <TableCell>Fuel Consumption</TableCell>
                    <TableCell>Fuel Consumption/ km</TableCell>
                    <TableCell>On time performance</TableCell>
                    <TableCell>Avg. Turnaround time</TableCell>
                    <TableCell>Events</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsDummy.map(row => (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          </Grid>
        )}
      </Grid>
      <Modal
            className="collaborate-modal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <CommentBox handleClose={handleClose}/>
            </Fade>
        </Modal>
        <PlanModal open={openPlanModal} handleClose={handleClosePlanModal}/>
    </div>
  );
}
