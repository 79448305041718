import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting"
import highchartsOfflineExporting from "highcharts/modules/offline-exporting"
import CircularProgress from '@material-ui/core/CircularProgress';
import {withRouter} from 'react-router';

highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);

function NegativeBarGraph(props) {

    const { forecast, actual, doExport, fileName, currentLimit, lastDate, tornadoData, tornadoRadioButtonValue, tabSelectorValue, label} = props
    const graphRef = useRef(null);    

    const [mergedData, setMergeData] = React.useState([]);
    useEffect(() => {

        // let payload = {selectedDate: lastDate, bu:"Summary", radioType: tornadoRadioButtonValue,receivableTabSelectorValue : tabSelectorValue, currentLimit} ;
        // props.fetchData(payload);

        if(doExport) {
            graphRef.current.chart.exportChartLocal({
                type: "application/pdf",
                filename: fileName
            }, {
                title: {
                    text: "Budget Analysis" 
                }
            })
            props.handlePrintCallback()
        }
        
    }, [doExport, lastDate, tornadoRadioButtonValue, currentLimit]);

    useEffect(() => {

        // let payload = {selectedDate: lastDate, bu:"Summary"} ;
        // props.fetchData(payload);
        let newMergeData = []
        let greenColor = "#d00909"
        let redColor = "#4caf50"

        const actualData = actual && actual.map(res => {
            let per = 0
            let color = ''
            per = res.percentage
            color = redColor
            per *= 100
    
            // if (res.percentage <= 0.10) {
            //     color = '#ff9502'
            // }
            return {
                y: per,
                name: res.field,
                color: color,
                bu: res.bu,
                value: res.value * -1,
                valueStr: res.valueStr

            }
        }).sort((a, b) => b.y - a.y);
    
        const forecastData = forecast && forecast.map(res => {  //
            let per = 0
            let color = ''
            per = -res.percentage
            color = greenColor
            per *= 100
    
            // if (res.percentage <= 0.10) {
            //     color = '#ff9502'
            // }
            return {
                y: per,
                name: res.field,
                color: color,
                bu: res.bu,
                value: res.value,
                valueStr: res.valueStr
            }
        }).sort((a, b) => b.y - a.y);
    
        console.log(actualData, "actualData")
        console.log(forecastData, "forecastData")

        console.log('tornadoData', tornadoData)

        if(forecastData && forecastData.length){
            newMergeData = [...forecastData]
        }

        if(actualData && actualData.length){
            newMergeData = [...actualData, ...newMergeData]
        }

        setMergeData(newMergeData)
        
    }, [forecast, actual]);

   

    // const mergedData = [ ...[],...[]]

    // const varianceData = graphData.map(res => {
    //     let per = 0
    //     let color = ''
    //     if(res.variance < 0) {
    //         per = -res.percentage
    //         color = '#d00909'
    //     } else {
    //         per = res.percentage
    //         color = '#4caf50'
    //     }
    //     per *= 100

    //     if (res.percentage <= 0.10) {
    //         color = '#ff9502'
    //     }
    //     return {
    //         y: per,
    //         name: res.budget_group,
    //         color: color,
    //         variance: res.variance
    //     }
    // }).sort((a, b) => b.y - a.y);
    // let max_delta = tornadoData.max_delta;
    let heightTornado;
    let heightSLBTornado;
    if(currentLimit == "Top 20"){
        heightTornado = 800;
        heightSLBTornado =800;
    }else if( currentLimit == "Top 40"){
        heightTornado = 1600;
        heightSLBTornado = 1600;
    }else {
        console.log("no. of mergedData rows -> ", mergedData.length)
        heightTornado = (mergedData.length/40 -4)*800 ;
        heightSLBTornado = (mergedData.length/40)*800 ;
    }

    let minValue = mergedData[mergedData.length - 1]
    let maxValue = mergedData[0]
    if(maxValue && minValue){
        maxValue =  Math.abs(minValue.value) > Math.abs(maxValue.value)? Math.abs(minValue.value) : Math.abs(maxValue.value)
    }
    // console.log(mergedData, maxValue,minValue,"--ini")

    return (
        <div className="mat-shadow-2">
           {/* {tornadoData.loading ? <div className='text-center'><CircularProgress /></div> : */}
            <HighchartsReact
                highcharts={Highcharts}
                ref={graphRef}
                options={
                    {
                        chart: {
                            type: 'bar',
                            // height: tornadoRadioButtonValue == "SLB" ? heightSLBTornado : heightTornado,
                            height: '100%',
                            numberFormatter: props.transformData,
                            spacing: [10, 40, 0, 10],
                            
                        },
                        
                        title: {
                            text: props.title,
                            // y :20
                        },
                        xAxis: {
                            alignTicks: true,
                            title: {
                                text: label !== undefined ? label.leftLabel: "",
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                 enabled: false
                            }
                        },
                        yAxis: [{
                            title: {
                                text: label !== undefined ? label.rightLabel : "",
                                align: 'high',
                                rotation: 90,
                                offset: -342,
                                x : 10,
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                 enabled: false,
                                // step: 0
                            }
                        },{
                            opposite:true,
                            title:{
                                text:""
                            },
                            min: maxValue * -1,
                            max : maxValue,
                            // min:0,
                            // startOnTick:false,
                            // max:40000,
                        }],
                        legend: {
                            enabled: false
                        },
                        tooltip: {
                            enabled: true,
                            formatter: function() {
                                const numberFormatter = this.series.chart.numberFormatter;
                                        // console.log(this.point, 'dataLabels', numberFormatter(this.point.value, -1))
                                        return typeof this.point.value !== 'number' ? '' : `(${this.point.options.valueStr})`;
                            }
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    crop: false,
                                    overflow: 'none',
                                    color: '#7b7b7b',
                                },
                                cursor: 'pointer',
                                // point:{
                                //     events:{
                                //         click: function(e) {
                                //             console.log(
                                //                 'ppppppp',
                                //                 e,
                                //                 props
                                //             )
                                //             let payload = {
                                //                 bu: e.point.options.bu,
                                //                 customer_name: e.point.name,
                                //                 parent_group: props.tornadoRadioButtonValue == 'Third' ? "external" : "SLB",
                                //                 selectedDate: props.pastMonth
                                //             }

                                //             localStorage.setItem('newTabData', JSON.stringify(payload))

                                //             window.open('/dashboard/receivables/reports/aging')
                                //             // props.history.push({
                                //             //     pathname: '/dashboard/receivables/reports/aging',
                                //             //     state: payload,
                                //             //   });
                                //         }
                                //     }
                                // }
                            }
                        },
                        series: [{
                            data: mergedData.map(x => {
                                return {...x, y: (x.y > 0) ? x.value : -1* x.value}
                            }),
                            borderRadius: 5,
                            pointWidth: 10,
                            dataLabels: [
                                {
                                    format: '{point.name}',
                                    verticalAlign: 'bottom'
                                },
                                {   
                                    // verticalAlign: 'top',
                                    // formatter: function () {
                                    //     const numberFormatter = this.series.chart.numberFormatter;
                                    //     console.log(this.point, 'dataLabels', numberFormatter(this.point.value, -1))
                                    //     return typeof this.point.value !== 'number' ? '' : `(${this.point.options.valueStr})`;
                                    //     // return typeof this.point.value !== 'number' ? '' : numberFormatter(this.point.value, -1);
                                    // },
                                }
                            ],
                            yAxis:1,
                        }],
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false,
                            fallbackToExportServer: false,
                        }
                    }
                }
            />
        </div>
    )
}
export default withRouter(NegativeBarGraph);