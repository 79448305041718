import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ChartImg from "../../../images/KOC/dashboard1.png"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MapDetailImage from "../../../images/KOC/map_detail.png"
import MapComponent from "./MapBox/index.jsx";
import Modal from '@material-ui/core/Modal';
import CommentBox from '../comment';
import PlanModal from '../plan';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({  
    switch_track: {
        backgroundColor: "#9ea1a3",
    },
    switch_base: {
        color: "#fff",
        "&.Mui-disabled": {
            color: "#fff"
        },
        "&.Mui-checked": {
            color: "#fff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#fff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#fff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#34C759",
        },
    }
}));

function createDataESP(ESP_ID, Progress, Color_Progress, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat){
  return {ESP_ID, Progress, Color_Progress, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat}
}

const rows = [
  createDataESP('HHHWWW1', '30%', '#F05757' ,'10 Days', '20 Days','','',''),
  createDataESP('HHHWWW1', '50%', '#F3C954' ,'20 Days', '20 Days','','',''),
  createDataESP('HHHWWW1', '60%', '#8DD542','80 Days', '20 Days','','',''),
  createDataESP('HHHWWW1', '50%', '#F3C954','30 Days', '20 Days','','',''),
  createDataESP('HHHWWW1', '70%', '#8DD542','90 Days', '20 Days','','',''),
];


function Analysis(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openPlanModal, setOpenPlanModal] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenPlanModal = () => setOpenPlanModal(true);
    const handleClosePlanModal = () => setOpenPlanModal(false);

    return (
         <div className="analysis-content">
             <div className="filter-form">
                 <div className="top">
                      <FormControlLabel
                          value="end"
                          control={<Switch classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                                colorPrimary: classes.switch_primary,
                              }}
                              color="primary"/>}
                          label="Additional Filters"
                          labelPlacement="start"
                      />
                      <TextField id="outlined-basic" label="Search ESP ID" variant="outlined" size="small"/>
                 </div>
                 <div className="bottom">
                     <div className="form-group">
                      <FormControl variant="outlined" size="small" fullWidth className="filter">
                        <InputLabel htmlFor="outlined-age-native-simple">Filter 1</InputLabel>
                        <Select
                          native
                          label="Age"
                          inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" size="small" fullWidth className="filter">
                        <InputLabel htmlFor="outlined-age-native-simple">Filter 2</InputLabel>
                        <Select
                          native
                          label="Age"
                          inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" size="small" fullWidth className="filter">
                        <InputLabel htmlFor="outlined-age-native-simple">Filter 3</InputLabel>
                        <Select
                          native
                          label="Age"
                          inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" size="small" fullWidth className="filter">
                        <InputLabel htmlFor="outlined-age-native-simple">Filter 4</InputLabel>
                        <Select
                          native
                          label="Age"
                          inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                        </Select>
                      </FormControl>
                      </div>
                      <div className="btn-action flex jc-center ai-center mt-20">
                          <Button variant="contained" size="small" color="primary" className="mr-20 btn-apply">
                            Apply
                          </Button>
                          <Button variant="outlined" size="small" color="primary" className="btn-clear">
                            Clear
                          </Button>
                      </div>
                 </div>
             </div>
             <div className="top-summary">
                 <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Paper className="box">
                          <div className="title">Current MTBF in Days <MoreHorizIcon/></div>
                          <div className="total">10 Days <img src={ChartImg}/></div>

                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <Paper className="box">
                          <div className="title">Anticipated Days to Failure  <MoreHorizIcon/></div>
                          <div className="total mt-40">10 Days</div>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className="box">
                          <div className="title">Details of Last Failure <MoreHorizIcon/></div>
                          <div className="flex mt-30">
                            <div className="multi-summary mr-50">Date of Last Failure</div>
                            <div className="multi-summary ml-50">Date Return to Production</div>
                          </div>
                          <div className="flex mt-10">
                            <div className="multi-summary mr-80">Last MTBF</div>
                            <div className="multi-summary ml-90">Lost Production Days</div>
                          </div>
                      </Paper>
                    </Grid>
                  </Grid>
             </div>
             {
               props.screenAnalysis == "Overview" ? (
                <div>
                  <div className="mt-20">
                   <Grid container spacing={3} className="grid-table">
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table size="small" aria-label="a dense table" className="custom-table"> 
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">ESP ID</TableCell>
                              <TableCell>Current MTBF in days</TableCell>
                              <TableCell>Anticipated Days to Failure</TableCell>
                              <TableCell>Platform</TableCell>
                              <TableCell>Well</TableCell>
                              <TableCell>Initiate Chat/ Remedial Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.ESP_ID}>
                                <TableCell>{row.ESP_ID}</TableCell>
                                <TableCell className="flex ai-center">
                                    <div className="progress mr-10">
                                      <div className="bar " style={{width : row.Progress ,background : row.Color_Progress }}>
                                      </div>
                                    </div>
                                    <span style={{textDecoration : 'underline' ,color : row.Color_Progress }}>{row.Current_MTBF}</span>
                                </TableCell>
                                <TableCell align="center">{row.Anticipated_Days}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center" className="action"><ChatBubbleIcon fontSize="small" onClick={()=>{handleOpen()}}/> <AddCircleOutlineIcon fontSize="small" onClick={()=>{handleOpenPlanModal()}}/></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                 </div>
                 <Grid container spacing={3} className="mt-30 grid-table">
                    <Grid item xs={12}>
                      <Card >
                        <CardContent>
                          <div className="header-card"><span>Details of Last Failure</span></div>
                          <TableContainer>
                            <Table aria-label="a dense table" className="custom-table"> 
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date of Last Failure)</TableCell>
                                  <TableCell>Last MTBF</TableCell>
                                  <TableCell>Date Return to Production</TableCell>
                                  <TableCell>Lost Production Days</TableCell>
                                  <TableCell>RCA</TableCell>
                                  <TableCell>PCA</TableCell>
                                  <TableCell>Remedial Actions Improvement Plans</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow key={row.ESP_ID}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
               ) : (
                  <div className="geospatial-view">
                    <Grid container spacing={3} className="mt-20 grid-table">
                      <Grid item xs={6}>
                        <Card >
                          <CardContent>
                            <div className="header-card"><span>Map</span></div>
                            <div className="mapbox"><MapComponent/></div>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                         <img src={MapDetailImage}/>
                      </Grid>
                    </Grid>
                  </div>
               )
             }
           <Modal
              className="collaborate-modal"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
              }}
          >
              <Fade in={open}>
                  <CommentBox handleClose={handleClose}/>
              </Fade>
          </Modal>
          <PlanModal open={openPlanModal} handleClose={handleClosePlanModal}/>
         </div>
    )
}

export default Analysis;