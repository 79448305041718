import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DropzoneArea } from 'material-ui-dropzone'
import DownloadExcel from "../../../images/download_excel.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse, lastDayOfMonth } from 'date-fns'

export default function SimpleModal(props) {


    const handleClose = () => {
        props.modalCallBack();
    }

  
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [bu, setBU] = useState('');
    const handleChangeBU = (event) => {
        setBU(event.target.value);
    };
    const [reportDate, setReportDate] = useState(new Date());
   
    const handleSaverole = () => {
        var data = {}
        // data.bu = bu;
        data.file = files[0];
        // data.load_forecast = loadForecast;
        // data.report_date = format(lastDayOfMonth(reportDate), "dd-MMM-yyyy");
        props.saveCallback(data);
    }

    const handleDeleterole = () => {
        var data = {}
        data.deleteData = props.editData;
        props.deleteCallback(data);
        handleCloseDialog();
    }
    const [files, setFiles] = useState(true)
    const handleChangeFile = (file) => {
        setFiles(file);
    }
    React.useEffect(() => {
        if (
            files.length > 0 &&
            reportDate != ""
        )
            setBtnDisabled(false)
        else
            setBtnDisabled(true)
    }, [files])

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };


    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }
        return <img
            {...iconProps}
            src={DownloadExcel}
            className='pointer'
            height='30'
        />
    }

    const body = (
        <div className='customModal automationModal'>
            <div className='header'>
                <h2>Upload Edited Excel</h2>
                {<Icon onClick={() => { handleClose() }}>close</Icon>}
            </div>
            <div className='modal-content'>
                {props.saveLoading ? <div className='text-center'><CircularProgress /></div> : ""}
                <React.Fragment>
                    {/* <Grid container spacing={1} style={{ pointerEvents: props.saveLoading ? 'none' : '' }} >
                        <Grid item xs={3} className={'report-date-picker'} >
                            <InputLabel>Report Date</InputLabel>
                        </Grid>
                        <Grid item xs={4}  >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    openTo="month"
                                    size="small"
                                    format="MMM-yyyy"
                                    className='pr-3'
                                    views={["year", "month"]}
                                    value={reportDate}
                                    minDate={props.reportDateArr[0]}
                                    onChange={setReportDate}
                                    maxDate={props.reportDateArr[props.reportDateArr.length - 1]}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} >
                            <InputLabel>Please select one option from below:</InputLabel>
                        </Grid>
                        <Grid item xs={12} >
                            <RadioGroup name="bu" value={bu} onChange={handleChangeBU}>
                                <FormControlLabel value="C" control={<Radio />} label="Customer level upload (Aging report)" />
                                <FormControlLabel value="P" control={<Radio />} label="PAB Unbilled" />
                                <FormControlLabel value="I" control={<Radio />} label="Invoice level upload" />
                            </RadioGroup>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12} style={{ pointerEvents: props.saveLoading ? 'none' : '' }} >
                        <DropzoneArea
                            filesLimit={1}
                            getPreviewIcon={handlePreviewIcon}
                            onChange={handleChangeFile.bind(this)}
                            acceptedFiles={['.xls', '.xlsx', '.xlsm']}
                            showFileNames={true}
                            showAlerts={false}
                            maxFileSize={500000000000}
                            dropzoneClass={'payment-dropzone'}
                        />
                    </Grid>
                  
                    {!props.saveLoading &&
                        <Grid container justify="flex-end" className='mt-30'>
                            <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        </Grid>
                    }
                </React.Fragment>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}