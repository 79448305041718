import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import CadenceBoard from './KanbanBoard';
import './index.scss';


export class NewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    fetchCadenceBoard = () => {
        this.props.fetchCadenceBoardData()
    }

    fetchAllData = () => {
        this.fetchCadenceBoard()
    }

    render() {
        const { fetchCadenceBoardData } = this.props;

        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <div className='buplComponent free-cash-flow'>

                <CadenceBoard menuData={menuData} fetchCadenceData={fetchCadenceBoardData} />
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            authReducer: state.authReducer
        }
    },
    dispatch => {
        return {
            
            fetchCadenceBoardData(data) {
                dispatch({
                    type: 'FETCH_CADENCE_BOARD_DATA',
                    payload: data,
                });
            }
        };
    }
)(NewComponent));
