import axios from 'axios';
import { CONFIG } from '../../config'

export async function performLoginService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/user/login`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function performForgotPwdService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/user/password/forgot/`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function performResetPwdService(payload) {
    return axios.patch(`${CONFIG.api_base_url}v1/user/password/reset/`, payload)
        .then(res => res)
        .catch(err => err.response)
}


export async function performGeneratePwdService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/user/password/generate`)
        .then(res => res)
        .catch(err => err.response)
}

export async function performVerifyTokenService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/user/password/forgot/verifytoken?token=${payload.token}`)
        .then(res => res)
        .catch(err => err.response)
}

export async function getUserDetailsService() {
    return axios.get(`${CONFIG.api_base_url}v1/user/get/details`)
        .then(res => res)
        .catch(err => err.response)
}

export async function getUserNotification() {
    return axios.get(`${CONFIG.api_base_url}v1/notification/get/`)
        .then(res => res)
        .catch(err => err.response)
}

export async function updateUserNotification() {
    return axios.get(`${CONFIG.api_base_url}v1/notification/updatenotif/`)
        .then(res => res)
        .catch(err => err.response)
}

export async function performLogoutService() {
    return axios.delete(`${CONFIG.api_base_url}v1/user/logout`)
        .then(res => res)
        .catch(err => err.response)
}

export async function performProfilePatchService(payload) {
    return axios.patch(`${CONFIG.api_base_url}v1/user/profile`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function performProfilePicUploadService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/user/profile/upload/image`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function performProfilePicRemoveService(payload) {
    return axios.delete(`${CONFIG.api_base_url}v1/user/profile/remove/image`, payload)
        .then(res => res)
        .catch(err => err.response)
}
