import React from 'react';
import { Switch, Route } from "react-router-dom";
import Appbar from './Appbar';
import Panel from "./Panel";
import Sidebar from "./Sidebar";
import LogoImage from "../../images/KOC/koc_logo.png"
import LoginPage from "./Login";

import "./index.scss";

function SubApp() {

    const [breadcrumbsList, setBreadcrumbsList] = React.useState([]);
    const [showMenu, setShowMenu] = React.useState(false)
    const [activeMenu, setActiveMenu] = React.useState("");

    const handleClick = () => {
        window.location = '/new-dashboard/panel';
    }

    const setBreadcrumbs = (data) =>{
        console.log(data)
        setBreadcrumbsList(data)
    }

    const setShowingMenu = (isShow) =>{
        setShowMenu(isShow)
    }

    const setMenuActive = (menu) =>{
        setActiveMenu(menu)
        console.log(activeMenu)
    }


    return (
         <main className='sub-app'>
            <Switch>
                <Route path="/new-dashboard/oil-gas" exact>
                    <LoginPage/>
                </Route>
                <Route path="/new-dashboard/panel" exact>
                    <Appbar />
                    <Panel setBreadcrumbs={setBreadcrumbs} setShowMenu={setShowingMenu} setActiveMenu={setMenuActive}/>
                </Route>
                <Route path="/new-dashboard/">
                    <Appbar setShowMenu={setShowingMenu} setBreadcrumbs={setBreadcrumbs} showMenu={showMenu} activeMenu={activeMenu} setActiveMenu={setMenuActive}/>
                    <Sidebar setBreadcrumbs={setBreadcrumbs} breadcrumbsList = {breadcrumbsList} activeMenu={activeMenu}/>
                </Route>
            </Switch>
        </main>
        
    )
}

export default SubApp;