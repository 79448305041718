import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Logo from "../../../images/eaciit-white-logo.png";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import CustomizedDialogs from "../../Auth/ChangePasswordModal";
import TextField from '@material-ui/core/TextField';
import HomeIcon from '@material-ui/icons/Home';
import SettingIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import UserMenu from './UserMenu/UserMenu'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Notification from './Notification/Notification'
import { getAccessByMenuKey } from "../../../utils/userManagement";

function Appbar(props) {
  const [universalFilterValue, setUniversalFilterValue] = React.useState("");
  const { authReducer, menuData } = props;
  const loginState = useSelector(state => state.authReducer);

  const handleChange = (event) => {
    if(event && event.target && event.target.value) {
      setUniversalFilterValue(event.target.value);
    }
  };

  return (
    <React.Fragment>
      {!loginState.showResetModal ? <>
      <CssBaseline />
      {/* {!authReducer.loading && (
        <CustomizedDialogs
          showModal={
            (loginState.showResetModal)
          }
        />
      )} */}
      <AppBar position="fixed" className='app-header' elevation={0}>
        <Toolbar variant="dense">
          <img src={Logo} width='120'></img>
          { getAccessByMenuKey(menuData,"user_management","view") &&
            <Tooltip title={"Settings"}>
              <Link to="/usermanagement/panel">
                <IconButton>
                  <SettingIcon
                    className="white-color"
                    aria-label="menu"
                    color="inherit"
                  >
                  </SettingIcon>
                </IconButton>
              </Link>
            </Tooltip> 
          }

          <Tooltip title={"Home"}>
            <Link to="/dashboard/panel">
              <IconButton>
                <HomeIcon
                  className="white-color"
                  aria-label="menu"
                  color="inherit"
                >
                </HomeIcon>
              </IconButton>
            </Link>
          </Tooltip>
          <Grid item xs={false} sm={7} md={7} className='flex jc-center'>
            {/* <FormControl className='header-formControls'>
              <TextField
                type="search"
                label="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl> */}
          </Grid>
          <Grid item xs={false} sm={3} md={3} className='flex jc-flex-end'>
          <FormControl variant="outlined" size="small" className='header-formControls'>
              <InputLabel>Universal Filter</InputLabel>
              <Select
                value={universalFilterValue}
                onChange={handleChange}
                label="Universal Filter"
                MenuProps={{ onExited: () => document.activeElement.blur() }}
              >
                <MenuItem value={1}>Business Unit</MenuItem>
                <MenuItem value={10}>Time Period</MenuItem>
              </Select>
            </FormControl>
           
          </Grid>
          <Grid item xs={false} sm={1} md={1} className='flex jc-flex-end'>
            <Notification></Notification>
            <>{loginState.userData.name && <UserMenu {...props} />}</>
          </Grid>
        </Toolbar>
      </AppBar>
      </> : null }
    </React.Fragment>
  );
}

export default Appbar;
