import React from "react";
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas } from '../../../../utils/numberUtils'

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});

function BarChart(props) {

    const {seriesData} = props

    // let chartSeriesData = []
    // let chartXSeriesData = []
    // chartSeriesData =  seriesData.row_data.map(item => {
    //     chartXSeriesData.push(item.name)
    //     item.data = [item.y]
    //     return item
    // })

    return (
        <div id="column_chart_overdue" class="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                // containerProps={{ style: { height: "50vh" } }}
                options={
                    {
                        chart: {
                            type: 'column',
                            scrollablePlotArea: {
                                minWidth: 2500,
                                opacity: 0
                            },
                        },
                        title: {
                            text: "",
                        },
                         xAxis: {
                             categories: seriesData.x_axis,
                         },
                         credits: {
                            enabled: false
                          },
                        yAxis: {
                            title: {
                                text: null
                            },
                            labels: {
                                formatter: function () {
                                    return Math.abs(this.value/1000)+ 'K';
                                }
                            },
                            accessibility: {
                                description: 'Percentage population',
                                rangeDescription: 'Range: 0 to 5%'
                            },
                        },
                        tooltip: {
                            formatter: function () {
                                return '<b>' + this.x + '</b> <br/> • ' 
                                + this.series.name+ " - $" +  numberWithCommas(this.y) + '<br/> • ' 
                                + this.point.other_key + " - $" +  (numberWithCommas(this.point.other_value) == "" ? 0 : numberWithCommas(this.point.other_value))  + '<br/> • '
                                + "Total - $" + numberWithCommas(this.point.sum)
                            }
                          }, 
                        series: seriesData.data
                    }
                }
            />

        </div>
    )
}
export default BarChart;