import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import Button from '@material-ui/core/Button';
import FunctionalPivotTableComponent from './TableComponent'
import FunctionalSummaryTableComponent from './FunctionalTableComponent'
import { formatDate } from '../../../utils/dateFormat'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../utils/numberUtils'
import NegativeBarGraph from './GraphComponent'
import { format } from 'date-fns/esm';
import {
    downloadExcel,
    pdfFileName,
    mapExcelData,
    mapExcelHeader,
    mapExcelHeaderComparative,
    mapExcelDataPLComparative,
    mapDataPDF,
    mapPDFDataPLComparative
} from '../../../utils/exportExcel';
import { Autocomplete, TextField } from '@mui/material';

export class FunctionalPLComponent extends React.Component {
    constructor(props) {
        super(props);
        this.excelData = {};
        this.excelDataComparison = {};
        this.state = {
            loading: true,
            monthSelectorValue: props.incomeStatementState.defaultMonth,
            qtrSelectorValue: '',
            yearSelectorValue: '',
            tabSelectorValue: 0 ,
            radioFilterValue: '',
            comparativeSelectorValue: '2',
            isShowTsaChecked: false,
            excludeDataFilter: [],
            doExport: false,
            exportFileName: '',
            excelRefresh: false,
            isAllFunc: true,
            budgets: []
        }

    }

    componentDidMount() {
        var arr = [];
        var hasAllFunc;
        var budgetFromAuth = this.props.budgetGp;
        if(budgetFromAuth){
            for (var i = 0; i < budgetFromAuth.length; i++) {
            var current = budgetFromAuth[i];
            if (current.value == "All Function") {
                hasAllFunc = true;
            }
            arr.push(current.value);
        }
        if (!hasAllFunc) {
            hasAllFunc = false;
        }
        this.setState({
            isAllFunc: hasAllFunc,
            budgets: arr,
        })
    }

    let newTabData = localStorage.getItem('openInNewTab');

    

   

        this.props.getBudgetGroup()
        this.props.getFunctionalDefaultMonthData({ budgetGroupUser: arr })


        if (newTabData) {
            const { yearSelectorValue } = this.state;
            setTimeout(() => {
                this.setState({
                    comparativeSelectorValue: '3',
                    monthSelectorValue: this.props.incomeStatementState.defaultMonth
                }, () => {
                    this.fetchFunctionalPivotData()
                })
            }, 2000)
            
            // localStorage.removeItem('openInNewTab')
        }
    }

    fetchFunctionalPivotData = () => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            budgetGroupUser: budgets,
            isComparative: comparativeSelectorValue == 2
        }

        if (this.state.tabSelectorValue == 1) {
            this.props.getFunctionalPivotView(payload)
        } else {
            if (comparativeSelectorValue == 1) {
                payload.budgetGroup = null;
                this.props.getFunctionalPivotView(payload)
            } else if (comparativeSelectorValue == 2) {
                this.fetchFunctionalSummaryData()
            } else {
                this.props.fetchFunctionalBudgetGraph(payload)
            }
        }
    }

    fetchFunctionalSummaryData = () => {
        const { qtrSelectorValue, monthSelectorValue, yearSelectorValue, comparativeSelectorValue, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            isComparative: comparativeSelectorValue == 2,
            budgetGroupUser: budgets
        }
        this.props.getFunctionalSummaryView(payload)
    }

    handleMonthChange = (event, value) => {
        if (event.target) {
            this.setState({
                monthSelectorValue: value,
                qtrSelectorValue: '',
                yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.fetchFunctionalPivotData()
            })
        }
    };

    handleQtrChange = (event, value) => {
        if (event.target) {
            this.setState({
                qtrSelectorValue: value,
                monthSelectorValue: '',
                yearSelectorValue: ''
            }, () => {
                this.fetchFunctionalPivotData()
            })
        }
    };

    handleYearChange = (event, value) => {
        if (event.target) {
            this.setState({
                yearSelectorValue: value,
                qtrSelectorValue: '',
                monthSelectorValue: ''
            }, () => {
                this.fetchFunctionalPivotData()
            })
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({ tabSelectorValue: newValue, comparativeSelectorValue: '2' }, () => {
            this.fetchFunctionalPivotData()
        })
    };

    handleRadioFilterChange = (event) => {
        this.setState(
            { radioFilterValue: event.target.value, excelRefresh: true },
            () => {
                this.fetchFunctionalPivotData()
            });
        console.log(this.state.radioFilterValue, "radioFilterValue");
    }

    handleShowTsaChange = (event) => {
        this.setState(
            { isShowTsaChecked: event.target.checked },
            () => {
                this.fetchFunctionalPivotData()
            });
    }

    componentDidUpdate(prevProps) {
        const { yearFilterArr, filterColumnArr, defaultMonth } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
            this.setState({
                yearSelectorValue: '',
                monthSelectorValue: defaultMonth
            })
        }
    }

    handleExcludeFilterChange = (event) => {
        if (event.target.value) {
            console.log ('oldvalues', event.target.value)
            this.setState(
                { excludeDataFilter: event.target.value }, () => {
                    this.fetchFunctionalPivotData()
                })
        }
    };

    handleExcludeFilterChangeNew = (event, newvalueArr) => {
        if (newvalueArr) {
            const valArr = newvalueArr.map((newvalue)=>(newvalue.value))
            this.setState(
                { excludeDataFilter: valArr }, () => {
                    this.fetchFunctionalPivotData()
                })
        }
    };

    handleResetExcludeFilter = () => {
        this.setState(
            { excludeDataFilter: [] }, () => {
                this.fetchFunctionalPivotData()
            })
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    getFileName = () => {
        const { monthSelectorValue, qtrSelectorValue, yearSelectorValue } = this.state
        let sel = monthSelectorValue + qtrSelectorValue + yearSelectorValue;
        return sel;
    }

    handleFileName = () => {
        const name = "Functional P&L - "
        return pdfFileName(name)
    }

    formatComments = (data) => {
        data.map(x => {
            let comm = "";
            if (x.comments && x.comments.length) {
                x.comments.forEach((y, idx) => {
                    comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                    if (idx != x.comments.length - 1) {
                        comm += "\n"
                    }
                })
            }
            if (x.sub_rows && x.sub_rows.length) {
                x.sub_rows = this.formatComments(x.sub_rows)
            }
            x.comment = comm
            return x
        })

        return data
    }

    handleExcelDownload = () => {
        let data = []
        let header = {};
        let merge = [];
        if (this.state.tabSelectorValue == 1 || (this.state.tabSelectorValue == 0 && this.state.comparativeSelectorValue == 1)) {
            let datas = this.excelData
            header = datas.header;
            datas.data = this.unwindHeaderKeyVal(datas.data);
            datas.data = this.formatComments(datas.data)
            data = mapExcelData(datas.data, header, "id")
            header = mapExcelHeader(header, "headerTitle")
        } else {
            let datas = this.excelDataComparison
            header = datas.header;
            datas.data = datas.data.map(x => {
                let comm = "";
                if (x.sub_column.comments && x.sub_column.comments.length) {
                    x.sub_column.comments.forEach((y, idx) => {
                        comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                        if (idx != x.sub_column.comments.length - 1) {
                            comm += "\n"
                        }
                    })
                }
                x.comments = comm
                return x
            })
            data = mapExcelDataPLComparative(datas.data, header, "id")
            let newHeader = mapExcelHeaderComparative(header, "Header");
            header = newHeader.data;
            merge = newHeader.merge;
        }
        let fileName = " Income Statement - Functional P&L - " + this.getFileName()
        if (this.financialDuration) {
            fileName = fileName + " - " + this.financialDuration
        }
        const { currentValue, previousValue } = this.props.incomeStatementState;
        let sheetName = 'Data';
        // if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
        //   sheetName = `${currentValue} - ${previousValue}`
        // }
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }

    handleExcelDataChange = (data, header) => {
        this.excelData.data = data;
        this.excelData.header = header;
    }

    handleExcelDataChangeComparison = (data, header) => {
        this.excelDataComparison.data = data;
        this.excelDataComparison.header = header;
    }

    handleComparativeRadioChange = (event) => {
        const { defaultMonth } = this.props.incomeStatementState;
        const { tabSelectorValue, yearSelectorValue } = this.state;
        this.setState({
            comparativeSelectorValue: event.target.value,
            // monthSelectorValue: (tabSelectorValue == 0 && event.target.value == 1) ? defaultMonth : '',
            yearSelectorValue: (tabSelectorValue == 0 && event.target.value == 1) ? '' : yearSelectorValue
        }, () => {
            this.fetchFunctionalPivotData()
        })
    }

    handleCommentSendFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendPivotCommentData(commentPayload)
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    handlePrintClick = () => {
        this.setState({
            doExport: false,
            exportFileName: ''
        })
    }

    printDocument = () => {
        let data = []
        let header = {};
        let merge = [];
        let datax = this.props.incomeStatementState
        let pivotHeaderData = datax.pivotHeaderData
        const pdf = new jsPDF('l', 'pt', 'letter');
        // if (this.state.tabSelectorValue == 1) {
        // } 
        const { tabSelectorValue, comparativeSelectorValue } = this.state;
        let page = ""
        let FileName = ""
        if (tabSelectorValue == 0) {
            // page = "Function Summary PDF"
            page = "xP&A Demo-Income Statement-Functional P&L-Function Summary"
            FileName = "new_Reporter-Income_Statement-Functional_P&L-Function Summary_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
        } else {
            // page = "View By Functions"
            page = "xP&A Demo-Income Statement-Functional P&L-View By Functions"
            FileName = "new_Reporter-Income_Statement-Functional_P&L-View By Functions_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
        }
        // let FileName = page + ' - ' + this.financialDuration ? this.financialDuration : '' + format(new Date, " - MM-dd-yyyy HH-mm-ss")
        console.log(pivotHeaderData, "testcolomspivotHeaderData")
        if (tabSelectorValue == 0 && comparativeSelectorValue == 2) {
            // let page = "Functional P&L Comparative View"
            let datas = this.excelDataComparison
            header = datas.header;
            console.log(header, "this is OldHeader")

            datas.data = datas.data.map(x => {
                let comm = "";
                if (x.sub_column.comments && x.sub_column.comments.length) {
                    x.sub_column.comments.forEach((y, idx) => {
                        comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                        if (idx != x.sub_column.comments.length - 1) {
                            comm += "\n"
                        }
                    })
                }
                x.comment = comm
                return x
            })
            data = mapPDFDataPLComparative(datas.data, header, "id", pivotHeaderData)
            console.log(pivotHeaderData, 'pppp', datas.data, data);
            let newHeader = mapExcelHeaderComparative(header, "Header");
            header = newHeader.data;
            let header2 = data.header;
            merge = newHeader.merge;
            let rows = []
            let i = 0
            header.forEach(x => {
                if (x != "") {
                    if (i == 0) {
                        rows.push({ content: x, rowSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                    }
                    if (i < 7 && i > 0) {
                        rows.push({ content: x, colSpan: 3, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                    }
                    if (i == 7) {
                        rows.push({ content: x, rowSpan: 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                    }
                }
                i++;
            })
            let rows2 = []
            let j = 0

            // rows2.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
            header2.forEach(x => {
                if (j < 6) {
                    rows2.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                    // j++;
                }
                if (j >= 6 && j < 9) {
                    rows2.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })

                }



                j++;

            })
            let heads = [
                rows,
                rows2,
            ];

            let rowsDataFix = []
            let dataCount = 0
            let dataFix = data.data
            console.log(header, "dataFix")
            console.log(rows2, "dataFix")
            console.log(dataFix, "dataFix")
            dataFix.forEach(x => {
                let i = 0
                let rowsData = []
                //   console.log(x, "ini datax")
                let dataField = ""
                x.forEach(y => {
                    if (i == 0) {
                        dataField = y
                        if (dataField == "Total Cost") {
                            rowsData.push({ content: y, styles: { fillColor: [223, 217, 251] } })
                        } else {
                            rowsData.push({ content: y })
                        }

                    } else {
                        if (y.includes("%")) {
                            if (y.includes("-")) {
                                y = y.replace("%", "");
                            }
                            rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                        } else {
                            if (dataField == "Total Cost") {

                                rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })


                            } else {
                                if (i == 7) {
                                    rowsData.push({ content: y, styles: { halign: 'left' } })
                                } else {
                                    rowsData.push({ content: y, styles: { halign: 'center' } })
                                }



                            }
                        }

                    }
                    i++;

                })
                dataCount += 1
                rowsDataFix.push(rowsData)
            })
            let totalPages = 1

            if (dataCount > 22) {
                totalPages = Math.ceil(dataCount / 22)
            }
            pdf.autoTable({
                head: heads,
                body: rowsDataFix,
                theme: 'grid',
                didDrawPage: function (data) {
                    // Header
                    pdf.setFontSize(20);
                    pdf.setTextColor(40);
                    pdf.setFontStyle('heltevica');
                    pdf.text(page, data.settings.margin.left + 15, 30);
                    if (totalPages > 1) {
                        // Footer
                        var str = "Page " + pdf.internal.getNumberOfPages()
                        // Total page number plugin only available in jspdf v1.0+
                        if (typeof pdf.putTotalPages === 'function') {
                            str = str;
                        }
                        pdf.setFontSize(10);

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        var pageSize = pdf.internal.pageSize;
                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                        pdf.text(str, data.settings.margin.left, pageHeight - 10);
                    }
                },
                styles: {
                    lineWidth: 1,
                    lineColor: [38, 38, 38],
                    width: 'auto'
                },
                margin: { top: 50 }
            })
            pdf.save(FileName + '.pdf')
        } else if (tabSelectorValue == 0 && comparativeSelectorValue == 3) {
            this.setState({ doExport: true, exportFileName: FileName })
        } else {
            let datas = this.excelData
            header = datas.header;
            datas.data = this.unwindHeaderKeyVal(datas.data);
            datas.data = datas.data.map(x => {
                let comm = "";
                if (x.comments && x.comments.length) {
                    x.comments.forEach((y, idx) => {

                        comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                        if (idx != x.comments.length - 1) {
                            comm += "\n"
                        }
                    })
                }
                x.comment = comm
                return x
            })
            data = mapDataPDF(datas.data, "id", pivotHeaderData)
            console.log(data, header, datas.data, pivotHeaderData)
            header = mapExcelHeader(header, "headerTitle")
            // let page = "Functional P&L Pivot View"
            let rows = []
            let i = 0
            header.forEach(x => {
                if (i == 0) {
                    rows.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                } else if (i < 8) {
                    rows.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
                }
                i++;
            })
            let heads = [
                rows
            ];
            let rowsDataFix = []
            let dataCount = 0
            let dataFix = data
            dataFix.forEach(x => {
                let i = 0
                let rowsData = []
                console.log(x.length, "ini datax")
                let dataField = ""
                x.forEach(y => {
                    if (i == 0) {
                        dataField = y
                        if (dataField == "Total Cost") {
                            rowsData.push({ content: y, styles: { fillColor: [223, 217, 251] } })
                        } else {
                            rowsData.push({ content: y })
                        }

                    } else {
                        if (y.includes("%")) {
                            if (y.includes("-")) {
                                y = y.replace("%", "");
                            }
                            rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                        } else {
                            if (dataField == "Total Cost") {
                                rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251] } })
                            } else {

                                if (i == (x.length - 1)) {
                                    rowsData.push({ content: y, styles: { halign: 'left' } })
                                } else {
                                    rowsData.push({ content: y, styles: { halign: 'center' } })
                                }
                            }
                        }

                    }
                    i++;

                })
                dataCount += 1
                rowsDataFix.push(rowsData)
            })
            let totalPages = 1

            if (dataCount > 22) {
                totalPages = Math.ceil(dataCount / 22)
            }
            pdf.autoTable({
                head: heads,
                body: rowsDataFix,
                theme: 'grid',

                didDrawPage: function (data) {
                    // Header
                    pdf.setFontSize(20);
                    pdf.setTextColor(40);
                    pdf.setFontStyle('heltevica');
                    pdf.text(page, data.settings.margin.left + 15, 30);
                    // if (totalPages > 1 ) {
                    // Footer
                    var str = "Page " + pdf.internal.getNumberOfPages()
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof pdf.putTotalPages === 'function') {
                        str = str;
                    }
                    pdf.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    var pageSize = pdf.internal.pageSize;
                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    pdf.text(str, data.settings.margin.left, pageHeight - 10);
                    // }
                },
                styles: {
                    lineWidth: 1,
                    lineColor: [38, 38, 38],
                },
                margin: { top: 50 }
            })
            pdf.save(FileName + '.pdf')
        }
    }

    handleCommentSendFunctionalFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendFunctionalCommentData(commentPayload)
    }

    handleNumberTransform = (number) => {
        const isNegative = number < 0
        const positiveTransformedValue = isNegative ? Math.abs(Math.round(number)) : Math.round(number)
        let modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        return modifiedData
    }

    handleNestedColumnCallback = (payload) => {

        let drilldownPayload = {
            ...payload,
            report_date: this.state.monthSelectorValue
            // report_date: '2021-01-31T00:00:00.000Z'
        }
        this.props.sendFunctionalDrilldownData(drilldownPayload)
    }
    handleDownloadNestedColumnCallback = (payload) => {
        let drilldownPayload = {
            ...payload,
            report_date: this.state.monthSelectorValue
            // report_date: '2021-01-31T00:00:00.000Z'
        }
        this.props.downloadFunctionalDrilldownData(drilldownPayload)
    }

    render() {
        const { quarterfilterArr, monthFilterArr, filterColumnArr, yearFilterArr, budgetGroup, budgetAnalysis } = this.props.incomeStatementState
        const { tabSelectorValue, yearSelectorValue,excelRefresh, excludeDataFilter, comparativeSelectorValue, radioFilterValue, loading, budgets, isAllFunc } = this.state;
        const AllFunctionRadio = <FormControlLabel value="" control={<Radio />} label="Assigned Functions View" />;
        let excFilterColArr =[]
        if ((typeof filterColumnArr !== 'string') && filterColumnArr?.length > 0) {
            excFilterColArr=[...filterColumnArr.map((bu)=> ({...bu, value: bu.key}))]
        }

        const selectedOptions = (a, data) => {
            if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
            else return {label:'', value:''}
            
        };

        return (
            <>
                {!monthFilterArr.length ? <div className='text-center'><CircularProgress /></div> :
                    <>
                        <div className='flex jc-space-between'>
                            <Grid container spacing={3}>
                                {monthFilterArr && monthFilterArr.length ? <Grid item xs={12} sm={2} className='maxw-200'>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={this.state.monthSelectorValue}
                                            label="Month"
                                            onChange={this.handleMonthChange}
                                        >
                                            {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        fullWidth
                                        size='small'
                                        className='xpna-dropdown single'
                                        options={monthFilterArr}
                                        value={this.state.monthSelectorValue}
                                        onChange={this.handleMonthChange}
                                        renderInput={(params)=>(
                                            <TextField {...params} variant='standard' label='Month' placeholder='Select'  />
                                        )}
                                    />  
                                </Grid> : <></>}
                                {tabSelectorValue == 1 && comparativeSelectorValue == 1 ?
                                    <>
                                        {quarterfilterArr && quarterfilterArr.length ?
                                            <Grid item xs={12} sm={2} className='maxw-200'>
                                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                    <InputLabel>Quarter</InputLabel>
                                                    <Select
                                                        value={this.state.qtrSelectorValue}
                                                        label="Quarter"
                                                        onChange={this.handleQtrChange}
                                                    >
                                                        {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                                                            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    fullWidth
                                                    size='small'
                                                    className='xpna-dropdown single'
                                                    options={quarterfilterArr}
                                                    value={this.state.qtrSelectorValue}
                                                    onChange={this.handleQtrChange}
                                                    renderInput={(params)=>(
                                                        <TextField {...params} variant='standard' label='Quarter' placeholder='Select'  />
                                                    )}
                                                />
                                            </Grid>
                                            : <></>
                                        }
                                        {yearFilterArr && yearFilterArr.length ?
                                            <Grid item xs={12} sm={2} className='maxw-200'>
                                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                    <InputLabel>Annual</InputLabel>
                                                    <Select
                                                        value={yearSelectorValue}
                                                        label="Annual"
                                                        onChange={this.handleYearChange}
                                                    >
                                                        {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    fullWidth
                                                    size='small'
                                                    className='xpna-dropdown single'
                                                    options={yearFilterArr}
                                                    value={yearSelectorValue}
                                                    onChange={this.handleYearChange}
                                                    renderInput={(params)=>(
                                                        <TextField {...params} variant='standard' label='Year' placeholder='Select'  />
                                                    )}
                                                />
                                            </Grid> :
                                            <></>}
                                    </> : <></>
                                }
                            </Grid>
                            {<Tooltip title={'Download to PDF'} className='pointer'>
                                <PictureAsPdfIcon fontSize="large" className='pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.printDocument();
                                    }} />

                            </Tooltip>}
                            {comparativeSelectorValue != 3 && <Tooltip title={'Download to Excel'} className='pointer'>
                                <img
                                    src={DownloadExcel}
                                    className='ml-10 pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.handleExcelDownload();
                                    }} />
                            </Tooltip>}
                        </div>
                        <Tabs
                            value={tabSelectorValue}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className='mt-16 mb-16'
                        >
                            <Tab label="FUNCTION SUMMARY" />
                            <Tab label="VIEW BY FUNCTIONS" />
                        </Tabs>
                        {tabSelectorValue == 1 ?
                            <FormControl fullWidth>
                                <RadioGroup aria-label="radio filter" value={radioFilterValue} onChange={this.handleRadioFilterChange} className='blockRadio'>


                                    {AllFunctionRadio}


                                    {
                                        isAllFunc ?
                                            budgetGroup && budgetGroup.length > 0 ? budgetGroup.map((item, i) => {
                                                return <FormControlLabel value={item} key={i} control={<Radio />} label={item} />
                                            }) : "" :

                                            budgets && budgets.length > 0 ? budgets.map((item, i) => {
                                                if (item != "Total Cost") {
                                                    return <FormControlLabel value={item} key={i} control={<Radio />} label={item} />
                                                }
                                            }) : ""

                                    }

                                </RadioGroup>
                            </FormControl>
                            : ''}
                        <div className='flex jc-space-between ai-center'>
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={comparativeSelectorValue} onChange={this.handleComparativeRadioChange} className='radioGroupHorizontal'>
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Default Monthly Rolling View" />
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Comparative Analysis" />

                                    {(tabSelectorValue !== 1 && isAllFunc) ? <FormControlLabel value="3" control={<Radio color="primary" />} label="Variance vs Budget" /> : ''}

                                </RadioGroup>
                            </FormControl>
                            <div className='flex' style={{alignItems:'center'}}>
                                {tabSelectorValue == 1 && <>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall minw-200 maxw-200' fullWidth size='small'>
                                        <InputLabel>Exclude Columns</InputLabel>
                                        <Select
                                            multiple
                                            value={excludeDataFilter}
                                            label="Exclude Columns"
                                            onChange={this.handleExcludeFilterChange}
                                        >
                                            {filterColumnArr && filterColumnArr.length && filterColumnArr.map(item => {
                                                return <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disablePortal
                                        disableCloseOnSelect
                                        multiple
                                        className='xpna-dropdown multiple minw-200 maxw-200 mb-10'
                                        limitTags={1}
                                        size='small'
                                        options={excFilterColArr}
                                        getOptionLabel={(option)=>option.label}
                                        //value={excludeDataFilter}
                                        value={excFilterColArr && excFilterColArr.length ? selectedOptions(excludeDataFilter,excFilterColArr): []}
                                        onChange={this.handleExcludeFilterChangeNew}
                                        renderInput={(params)=>(<TextField {...params} variant='standard' label= 'Exclude columns' placeholder='Select' />)}
                                    />
                                    <Button onClick={this.handleResetExcludeFilter} variant="outlined" size='small' className='ml-10 newButtonOutlined btnBlue uppercase btnReset'  color="primary">Reset</Button>
                                </>}
                            </div>
                        </div>
                        {tabSelectorValue == 1 && this.state.isShowTsaChecked &&
                            <div className='rowDetails flex ai-center mb-10'>
                                {<>
                                    {/* <p className='mt-0 mb-0 ml-20 mr-20'>{`Expense Key Acct.: K08-Outside Services & Professional Fees`}</b></p> */}
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Expense Key Acct.: <b>K08-Outside Services & Professional Fees</b></p>
                                    <p className='mt-0 mb-0 ml-20 mr-20'>Account Name: <b>753900</b></p>
                                </>}
                            </div>
                        }
                        {tabSelectorValue == 1 ?
                            <FunctionalPivotTableComponent
                                {...this.props}
                                handleExcelDataChange={this.handleExcelDataChange}
                                handleCommentSendFn={this.handleCommentSendFn}
                                handleNestedColumnCallback={this.handleNestedColumnCallback}
                                handleDownloadNestedColumnCallback={this.handleDownloadNestedColumnCallback}
                                radioFilterValue={radioFilterValue}
                                tabSelectorValue={tabSelectorValue}
                                excelRefresh={excelRefresh}
                                comparativeSelectorValue={comparativeSelectorValue}
                            /> : <></>
                        }
                        {tabSelectorValue != 1 && comparativeSelectorValue == 1 &&
                            <FunctionalPivotTableComponent
                                {...this.props}
                                handleExcelDataChange={this.handleExcelDataChange}
                                handleCommentSendFn={this.handleCommentSendFn}
                                handleNestedColumnCallback={this.handleNestedColumnCallback}
                                handleDownloadNestedColumnCallback={this.handleDownloadNestedColumnCallback}
                                radioFilterValue={radioFilterValue}
                                tabSelectorValue={tabSelectorValue}
                                excelRefresh={excelRefresh}
                                comparativeSelectorValue={comparativeSelectorValue}
                            />
                        }
                        {tabSelectorValue != 1 && comparativeSelectorValue == 2 &&
                            <FunctionalSummaryTableComponent
                                {...this.props}
                                handleExcelDataChange={this.handleExcelDataChangeComparison}
                                handleCommentSendFn={this.handleCommentSendFunctionalFn}
                            />}
                        {tabSelectorValue !== 1 && comparativeSelectorValue == 3 &&
                            <div className='mt-20 budgetAnalysis'>
                                {/* <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={7} className='primary'><b>Budget Group</b></Grid>
                                        <Grid item xs={5} className='primary text-right'><b>YTD Variance($)</b></Grid>
                                    </Grid>
                                    {budgetAnalysis && budgetAnalysis.length && budgetAnalysis.map((key, i) => {
                                        return <Grid container spacing={1} key={i}>
                                            <Grid item xs={7} className='mt-5'>{key.budget_group}</Grid>
                                            <Grid item xs={5} className='mt-5 semi-dark-grey text-right'>{this.handleNumberTransform(key.variance)}</Grid>
                                        </Grid>
                                    })}
                                </div> */}
                                <div>
                                    {budgetAnalysis.length > 0 ? <h3 className='primary text-center'><b>Variance vs Budget</b></h3> : ''}
                                    {budgetAnalysis.length > 0 ?
                                        <NegativeBarGraph graphData={budgetAnalysis} transformData={(number) => this.handleNumberTransform(number)} handlePrintCallback={this.handlePrintClick} doExport={this.state.doExport} fileName={this.state.exportFileName} /> :
                                        <h3 className='text-center'>There is no data for the selected date</h3>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state,
                incomeStatementState: state.incomeStatement,
                budgetGp: state.authReducer.userData.budgetGroup,
            }
        },
        dispatch => {
            return {
                getBudgetGroup() {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_BUDGET_GROUP'
                    });
                },
                getFunctionalPivotView(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_PIVOT_DATA',
                        payload: data
                    });
                },
                getFunctionalSummaryView(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_SUMMARY_DATA',
                        payload: data
                    });
                },
                getFunctionalDefaultMonthData(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_DEFAULT_MONTH',
                        payload: data
                    });
                },
                sendPivotCommentData(data) {
                    dispatch({
                        type: 'SEND_PIVOT_COMMENT_DATA',
                        payload: data
                    });
                },
                sendFunctionalCommentData(data) {
                    dispatch({
                        type: 'SEND_FUNCTIONAL_COMMENT_DATA',
                        payload: data
                    });
                },
                sendFunctionalDrilldownData(data) {
                    dispatch({
                        type: 'SEND_FUNCTIONAL_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                downloadFunctionalDrilldownData(data) {
                    dispatch({
                        type: 'DOWNLOAD_FUNCTIONAL_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchFunctionalBudgetGraph(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_BUDGET_GRAPH_DATA',
                        payload: data
                    })
                }
            };
        }
    )(FunctionalPLComponent);

