import React from 'react';
import {connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DropdownComponent from '../../Ageing/BusinessUnitComponent/Dropdown';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from '../../../../images/download_excel.svg';
import ReactTableComponent from './ReactTableComponent';
import {downloadExcelV1, mapExcelDataV1} from '../../../../utils/exportExcel';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
      >
        {value === index && (
            <React.Fragment>{children}</React.Fragment>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export class DayReportComponent extends React.Component {

  constructor(props) {
    super(props);
    this.buMap = {
      0: 'BU 1',
      1: 'BU 2',
      2: 'BU 3',
      3: 'BU 4',
    };
    this.NewBuMap = {
      0: "New BU 1",
      1: "New BU 2",
      2: "Unallocated"
    };
    this.state = {
      externalTabValue: 0,
      month: props.dashboardState.selectedDate,
      filterPayload: {
        action_owner: [],
        aging_category: [],
        yearmonth: [],
        category: '',
        customer_name: [],
        skip: 0,
        limit: 10,
      },
    };
  }

  componentDidMount() {
    // console.log( this.props, "props")
    const datelen = this.props.dashboardState.reportDateArr &&
        this.props.dashboardState.reportDateArr.length;
    const updatedMonth = this.props.dashboardState.reportDateArr[datelen > 0 ?
        datelen - 1 :
        0];
    this.setState({month: updatedMonth});
    this.fetchReportDataFn();
    this.props.getAllUser({});
  }

  fetchReportDataFn = () => {
    const {filterPayload, month, externalTabValue} = this.state;
    const payload = {
      ...filterPayload,
      report_date: month,
      bu: this.buMap[externalTabValue],
      receivableTabSelectorValue: this.props.tabSelectorValue
    };

    if (this.props.tabSelectorValue == 1){
      payload.bu = this.NewBuMap[externalTabValue]
    }
    this.props.fetchDayReportData(payload);
    this.props.fetchDayFilterData(payload);
  };

  handlePageChange = data => {
    this.setState(prevState => ({
      ...prevState,
      filterPayload: {
        ...prevState.filterPayload,
        skip: data,
      },
    }), () => this.fetchReportDataFn());
  };

  handleExternalTabChange = (event, newValue) => {
    let newFilterPayload = {
      action_owner: [],
      aging_category: [],
      yearmonth: [],
      category: '',
      customer_name: [],
      skip: 0,
      limit: 10
    };

    this.setState({
      externalTabValue: newValue,
      filterPayload: newFilterPayload
    }, () => {
        this.fetchReportDataFn();
    });
  };

  handleMonthChange = (event) => {
    this.setState({month: event.target.value});
    if (event.target && this.state.month != event.target.value) {
      this.setState({month: event.target.value}, () => {
        this.props.updateSelectedDate(event.target.value);
        this.fetchReportDataFn();
      });
    }
  };

  handleApplyFilter = (payload) => {
    this.setState({
      filterPayload: payload,
    }, () => {
      this.fetchReportDataFn();
    });
  };

  mapUser = (data) => {
    let userData = this.props.externalData.allUser;
    data = data.map(x => {
      let label = userData.find(y => {
        return y.login_id == x.action_owner;
      });

      if (label != undefined)
        x.action_owner = label.full_name;

      label = userData.find(y => {
        return y.login_id == x.collection_forecast_confirmed_by;
      });

      if (label != undefined)
        x.collection_forecast_confirmed_by = label.full_name;

      return x;
    });
    return data;
  };

  handleDownloadExcel = () => {
    const {
      dayReportState,
    } = this.props;
    let header = JSON.parse(JSON.stringify(dayReportState.tableRowHeader));
    header = header.filter((x) => {
      let exclude = ['collection_forecast', 'improvement_plan'];
      return exclude.indexOf(x.key) == -1;
    });
    let data = JSON.parse(JSON.stringify(dayReportState.tableRowData));
    let ops = {};
    ops.dataType = {5: 'number', 8: 'number', 9: 'number', 11: 'date'};

    header.splice(11, 0,
        {key: 'collection_forecast', label: 'Collection Forecast'});
    header.splice(12, 0, {
      key: 'collection_forecast_confirmed_by',
      label: 'Collection Forecast Confirmed By',
    });
    header.splice(13, 0,
        {key: 'collection_forecast_date', label: 'Collection Forecast Date'});

    data = data.map(x => {
      let comm = '';
      x.commentArr.forEach((y, idx) => {
        if (y.type == 'doc') {
          comm += y.name + ':\n<document>';
        } else {
          comm += y.name + ':\n' + y.body;
        }

        if (idx != x.commentArr.length - 1) {
          comm += '\n';
        }
      });
      x.comments = comm;
      if (x.collection_forecast == -1) {
        x.collection_forecast = '';
      }
      return x;
    });
    const {externalTabValue} = this.state;
    let bu = this.buMap[externalTabValue];
    if (this.props.tabSelectorValue == 1){
      bu = this.NewBuMap[externalTabValue]
    }
    let datax = mapExcelDataV1(this.mapUser(data), header, undefined,
        ops.dataType);
    header = header.map(x => {
      return x.label;
    });
    var filename = ` Receivables - 100k-120 Day Reports - ${bu.toUpperCase()} - ${this.state.month}`;
    console.log(header, 'testDataHeader');
    console.log(datax, 'testData');
    ops.fileName = filename;
    ops.columns = header;
    ops.data = datax;
    ops.alignment = {
      15: {wrapText: true, horizontal: 'left'},
      1: {horizontal: 'left'},
    };
    // console.log(data,ops,"--ops")
    downloadExcelV1(ops);
  };

  handleLegacyTabView = () => {

    let NewBU = [
      { id: 'New BU 1', value: 'New BU 1' },
      { id: 'New BU 2', value: 'New BU 2' },
      { id: 'Unallocated', value: 'Unallocated' }
    ]

    let legacyBU = [
      { id: 'BU 1', value: 'BU 1' },
      { id: 'BU 2', value: 'BU 2' },
      { id: 'BU 3', value: 'BU 3' },
      { id: 'BU 4', value: 'BU 4' },
    ]

    let tabResponse = []

    if (this.props.tabSelectorValue == 1) {

      tabResponse = NewBU.map((item, index) => {
        return (
          <Tab label={item.id} className="list-nested" id="nav-tab-0"
          aria-controls="nav-tabpanel-0"/>          )
      })

    } else {

      tabResponse = legacyBU.map((item, index) => {
        return (
          <Tab label={item.id} className="list-nested" id="nav-tab-0"
          aria-controls="nav-tabpanel-0"/>        )
      })
    }

    return tabResponse
  }

  render() {
    const {
      dashboardState,
      dayReportState,
    } = this.props;
    const {month, externalTabValue} = this.state;
    const arrayDate = dashboardState.reportDateArr;
    const newArray = [];
    const idx = arrayDate.length;
    let Newidx = 0;
    for (var keys in arrayDate) {
      Newidx++;

      newArray.push(arrayDate[(idx - Newidx)]);
    }
    // const datelen = dashboardState.reportDateArr && dashboardState.reportDateArr.length;
    // const updatedMonth = dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]

    return (
        <React.Fragment>
          <div className="flex ai-center jc-space-between mb-10">
            <FormControl className="w-200">
              <Select
                  // value={month || updatedMonth}
                  value={month}
                  onChange={this.handleMonthChange}
                  MenuProps={{onExited: () => document.activeElement.blur()}}
              >
                {newArray && newArray.length &&
                newArray.map((item, index) => {
                  return <MenuItem value={item} key={index}>{item}</MenuItem>;
                })
                }
              </Select>
            </FormControl>
            <Tooltip title={'Download to Excel'}>
              <img
                  src={DownloadExcel}
                  className="pointer"
                  height="30"
                  alt="download excel"
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleDownloadExcel();
                  }}/>
            </Tooltip>
          </div>
          <Tabs
              value={externalTabValue}
              indicatorColor="primary"
              textColor="primary"
              className="mb-10"
              onChange={this.handleExternalTabChange}
          >
            {this.handleLegacyTabView()}
          </Tabs>
          <TabPanel value={externalTabValue} index={0}>
          </TabPanel>
          <TabPanel value={externalTabValue} index={1}>
          </TabPanel>
          <TabPanel value={externalTabValue} index={2}>
          </TabPanel>
          <TabPanel value={externalTabValue} index={3}>
          </TabPanel>
          <DropdownComponent
              externalfilterArr={dayReportState.dayFilterArr}
              handleApplyFilter={this.handleApplyFilter}
              parentGroup={''}
              buValue={this.props.tabSelectorValue == 1 ? this.NewBuMap[externalTabValue] : this.buMap[externalTabValue]}
          />
          <ReactTableComponent
              buValue={this.props.tabSelectorValue == 1 ? this.NewBuMap[externalTabValue] : this.buMap[externalTabValue]}
              handleRefresh={this.fetchReportDataFn}
              onPageChange={this.handlePageChange}
          />
        </React.Fragment>
    );
  }
}

export default connect(
    state => {
      return {
        summaryState: state.dashboardState.receivableData.ageingData.summary,
        dashboardState: state.dashboardState,
        dayReportState: state.dayReportState,
        externalData: state.dashboardState.receivableData.ageingData.external,
      };
    },
    dispatch => {
      return {
        updateSelectedDate(data) {
          dispatch({
            type: 'UPDATE_SELECTED_DATE',
            payload: data,
          });
        },
        fetchDayReportData(data) {
          dispatch({
            type: 'FETCH_DAY_REPORT_DATA',
            payload: data,
          });
        },
        fetchDayFilterData(data) {
          dispatch({
            type: 'FETCH_DAY_FILTER_DATA',
            payload: data,
          });
        },
        getAllUser(data) {
          dispatch({
            type: 'FETCH_ALL_USER',
            payload: data,
          });
        },
      };
    },
)(DayReportComponent);
