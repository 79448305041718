import React from "react";

const Legend = (props) => {
  const renderLegendKeys = (stop, i) => {
    return (
      <div key={i} className="txt-s">
        <span
          className="mr6 w18 h12 inline-block align-middle"
          style={{ backgroundColor: stop[1] }}
        />
        <span style={{float: 'right'}}>{`${stop[0]}`}</span>
      </div>
    );
  };
  const renderDSOLegendKeys = (dso, i) => {
    return (
      <div key={i} className="txt-s">
        <div className="circle" style={{ background: dso[1] }}/>
        <span style={{float: 'right'}}>{`${dso[0]}`}</span>
      </div>
    );
  };
  const renderMarginLegendKeys = (margin, i) => {
    return (
      <div key={i} className="txt-s">
        <div className="triangle-up" style={{ borderColor: margin[1] }}/>
        <span style={{float: 'right'}}>{`${margin[0]}`}</span>
      </div>
    );
  };

  return (
    <div className="legend-wrapper">
      <div className="bg-white relative py12 px12 shadow-darken10 round z1 revenue-legends">
        <div className="mb6">
          <h2 className="txt-bold txt-m block align-center">Revenue</h2>
        </div>
        {props.stops.map(renderLegendKeys)}
      </div>
      <div className="relative-legends">
          {/* <div className="relative-label relative py12 px1">Relative Performance</div> */}
          <div className="flex">
            <div className="dso-legend bg-white relative py12 px12 shadow-darken10 round z1 mr12">
              <div className="mb6">
                <h2 className="txt-bold txt-m block align-center">DSO Days</h2>
              </div>
              {props.dso.map(renderDSOLegendKeys)}
            </div>
            <div className="margin-legend bg-white relative py12 px12 shadow-darken10 round z1">
              <div className="mb6">
                <h2 className="txt-bold txt-m block align-center">Std Margin %</h2>
              </div>
              {props.margins.map(renderMarginLegendKeys)}
            </div>
          </div>
        </div>
    </div>
  );
};

export default Legend;