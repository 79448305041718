import React from 'react';
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
// import FirstScreen from "../../../../../images/cashflow/excel.png"
// import SecondScreen from "../../../../../images/cashflow/tabelchart.png"
// import ThirdScreen from "../../../../../images/cashflow/simulation.png"
import CashflowReport from './CashflowReport';
import TableChartView from './TableChartView';
import SimulationView from './SimulationView';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class CashflowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        // this.imageMap = {
        //     0: FirstScreen,
        //     1: SecondScreen,
        //     2: ThirdScreen,
        // }
        this.state = {
            newRadioSelectorValue: '0',
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    componentDidMount() {
        // console.log( this.props, "props")
        // this.props.getAllUser({})
    }


    handleExternalTabChange = (event) => {
        this.setState({
            reportTabValue: event.target.value
        })
    }

    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }


    render() {

        const {  newRadioSelectorValue } = this.state
        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <React.Fragment>
                <div className='dayrepots-100k-120'>
                    <FormControl component="fieldset">
                        <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                            <div className="group">
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="Cashflow Report" />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Table & Chart View" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="Simulation View" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                    {(newRadioSelectorValue == 0) && 
                        <CashflowReport menuData={menuData}/>
                    }
                    {(newRadioSelectorValue == 1) && 
                        <TableChartView/>
                    }
                    {(newRadioSelectorValue == 2) && 
                        <SimulationView/>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                authReducer: state.authReducer

            }
        },
        dispatch => {
            return {
                updateSelectedDate(data) {
                    dispatch({
                        type: "UPDATE_SELECTED_DATE",
                        payload: data,
                    });
                }
            };
        }
    )(CashflowComponent);
