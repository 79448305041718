import { put, takeLatest, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import {
    getUserService,
    addUser,
    editUser,
    getRoleandGroup,
    deleteUserService,
    getMenuService,
    getRoleService,
    addRoleService,
    editRoleService,
    deleteRoleService,
    getCustomerCommentsService,
    getNewCommentsService,
    deleteCustomerCommentsService,
    fetchAgingReportDateServiceMonthly,
    getPaymentDataService,
    deletePaymentDataService,
    savePaymentDataService,
    savePaymentPABDataService,
    saveCommentDataService,
    saveCommentNewDataService,
    downloadFileService,
    fetchPaymentInvoiceService,
    fetchUserAnalyticService,
    fetchDefaultFilterUserAnalyticService,
    fetchISFileDataService,
    fetchPABFileDataService,    
    uploadPABFileService,
    fetchImpactAnalysisFileDataService,
    uploadImpactAnalysisFileService,
    fetchFilterDataService,
    uploadISFileService,
    uploadForecastFileService,
    deleteISFileService,
    sendEmailUserAnalytics,
    fetchForecastFileDataService,
    getEbitdaBudgetDataService,
    getEbitdaActualDataService,
    saveEbitdaBudgetDataService,
    saveEbitdaActualDataService
} from "./service";

function* fetchUserData(data) {
    yield put({ type: "FETCH_USERMGMT_DATA_LOADER", loading: true });
    const response = yield call(getUserService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_USERMGMT_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* AddUserData(data) {
    yield put({ type: "ADD_USER_DATA_LOADER", loading: true });
    const response = yield call(addUser, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_USER_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The user has been added successfully");
    } else {
        toastr.error("Error " + response.data.error.msg);
    }
}

function* EditUserData(data) {
    yield put({ type: "ADD_USER_DATA_LOADER", loading: true });
    console.log(data.payload, "testSave")
    const response = yield call(editUser, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_USER_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The user has been edited successfully");
        window.location.reload();
    } else {
        toastr.error("Error " + response.data.error.msg);
    }
}

function* DeleteUserData(data) {
    yield put({ type: "ADD_USER_DATA_LOADER", loading: true });
    const response = yield call(deleteUserService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_USER_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The user has been deleted successfully");
    } else {
        let msg = response.data.error.debug_msg;
        msg = msg == undefined || msg == "" ? response.data.error.msg : msg;
        toastr.error("Error! " + msg);
    }
}

function* GetRoleandGroup(data) {
    yield put({ type: "FETCH_ROLE_AND_GROUP_LOADER", loading: true });
    const response = yield call(getRoleandGroup, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_ROLE_AND_GROUP_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchRoleData(data) {
    yield put({ type: "FETCH_ACLGROUP_DATA_LOADER", loading: true });
    const response = yield call(getRoleService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_ACLGROUP_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchMenuData(data) {
    yield put({ type: "FETCH_MENUMASTER_DATA_LOADER", loading: true });
    const response = yield call(getMenuService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_MENUMASTER_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* addRoleData(data) {
    yield put({ type: "ADD_ACLGROUP_DATA_LOADER", loading: true });
    const response = yield call(addRoleService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_ACLGROUP_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The role data has been saved successfully");
    } else {
        toastr.error("Error " + response.data.error.msg);
    }
}

function* editRoleData(data) {
    yield put({ type: "ADD_ACLGROUP_DATA_LOADER", loading: true });
    const response = yield call(editRoleService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_ACLGROUP_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The role data has been edited successfully");
        window.location.reload();
    } else {
        toastr.error("Error " + response.data.error.msg);
    }
}

function* deleteRoleData(data) {
    yield put({ type: "ADD_ACLGROUP_DATA_LOADER", loading: true });
    const response = yield call(deleteRoleService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "ADD_ACLGROUP_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The role data has been deleted successfully");
    } else {
        toastr.error("Delete role failed! " + response.data.error.debug_msg);
    }
}

function* deletePaymentData(data) {
    yield put({ type: "MAIN_PAYMENT_DATA_LOADER", loading: true });
    const response = yield call(deletePaymentDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "DELETE_PAYMENT_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
        toastr.success("The payment data has been deleted successfully");
        yield put({ type: "FETCH_AUTO_PAYMENT" });
    } else {
        toastr.error("Delete payment failed! " + response.data.error.debug_msg);
    }
}

function* fetchPaymentData(data) {
    yield put({ type: "MAIN_PAYMENT_DATA_LOADER", loading: true });
    const response = yield call(getPaymentDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_PAYMENT_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}
function* fetchAgingReportDateMonthly(data) {
    try {
        const response = yield call(fetchAgingReportDateServiceMonthly, data.payload);
        if (response && response.status == 200) {
            yield put({ type: "FETCH_AGING_REPORT_DATE_MONTHLY_SUCCESS", data: response.data });
        }
    } catch (error) {
    }
}
function* fetchCustomerComments(data) {
    yield put({ type: "MAIN_CUSTOMER_COMMENTS_DATA_LOADER", loading: true });
    const response = yield call(getCustomerCommentsService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_CUSTOMER_COMMENTS_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchNewComments(data) {
    yield put({ type: "MAIN_CUSTOMER_COMMENTS_DATA_LOADER", loading: true });
    const response = yield call(getNewCommentsService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_CUSTOMER_COMMENTS_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* deleteCustomerComments(data) {
    yield put({ type: "MAIN_CUSTOMER_COMMENTS_DATA_LOADER", loading: true });
    const response = yield call(deleteCustomerCommentsService, data.payload);
    if (response && response.status == 200) {
        toastr.success("The customer comment data has been deleted successfully");
        yield put({ type: "FETCH_AUTO_CUSTOMER_COMMENTS" });
    } else {
        toastr.error(
            "Delete customer comment failed! " + response.data.error.debug_msg
        );
    }
}

function* downloadFile(data) {
    const response = yield call(downloadFileService, data.payload);
    if (response && response.status == 200) {
    } else {
        toastr.error("Dowload Failed! ");
    }
}

function* savePaymentData(data) {
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("bu", data.payload.bu);
    formData.append("load_forecast", data.payload.load_forecast == "true");
    formData.append("report_date", data.payload.report_date);
    yield put({
        type: "SAVE_PAYMENT_DATA_LOADER",
        loading: true,
        success: false,
    });
    let response = undefined;
    // console.log(data.payload.bu, "cekBU")
    if (data.payload.bu == 'PAB') {
        response = yield call(savePaymentPABDataService, formData);

    }else {
        response = yield call(savePaymentDataService, formData);

    }
    if (response && response.status == 200) {
        toastr.success("The payment data has been saved successfully");
        yield put({
            type: "SAVE_PAYMENT_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    } else {
        toastr.error(
            "Upload payment data failed! " + response.data.error.debug_msg
        );
        yield put({
            type: "SAVE_PAYMENT_DATA_LOADER",
            loading: false,
            success: false,
        });
    }
}

function* saveCommentData(data) {
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("upload_type", data.payload.bu);
    formData.append("load_forecast", data.payload.load_forecast == "true");
    formData.append("report_date", data.payload.report_date);
    yield put({
        type: "SAVE_PAYMENT_DATA_LOADER",
        loading: true,
        success: false,
        showError: false
    });
    const response = yield call(saveCommentDataService, formData);
    if (response && response.status == 200) {
        if (!response.data.success) {
            yield put({
                type: "SAVE_PAYMENT_DATA_FAIL",
                loading: false,
                errors: response.data,
                showError: true
            });
            return
        }
        const ifFileSavedButWarnings = response.data.result.filter(x => x.warnings.length >= 1)
        if (response.data.success && ifFileSavedButWarnings && ifFileSavedButWarnings.length) {
            toastr.success("The comment data has been saved successfully");
            yield put({
                type: "SAVE_PAYMENT_DATA_FAIL",
                loading: false,
                errors: response.data,
                showError: true
            });
            return
        }
        toastr.success("The comment data has been saved successfully");
        yield put({
            type: "SAVE_PAYMENT_DATA_SUCCESS",
            loading: false,
            data: response.data,
            showError: false
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "SAVE_PAYMENT_DATA_LOADER",
            loading: false,
            success: false,
        });
    }
}
function* saveCommentNewData(data) {
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("upload_type", data.payload.bu);
    formData.append("load_forecast", data.payload.load_forecast == "true");
    formData.append("report_date", data.payload.report_date);
    yield put({
        type: "SAVE_PAYMENT_DATA_LOADER",
        loading: true,
        success: false,
        showError: false
    });
    const response = yield call(saveCommentNewDataService, formData);
    if (response && response.status == 200) {
        if (!response.data.success) {
            yield put({
                type: "SAVE_PAYMENT_DATA_FAIL",
                loading: false,
                errors: response.data,
                showError: true
            });
            return
        }
        const ifFileSavedButWarnings = response.data.result.filter(x => x.warnings.length >= 1)
        if (response.data.success && ifFileSavedButWarnings && ifFileSavedButWarnings.length) {
            toastr.success("The comment data has been saved successfully");
            yield put({
                type: "SAVE_PAYMENT_DATA_FAIL",
                loading: false,
                errors: response.data,
                showError: true
            });
            return
        }
        toastr.success("The comment data has been saved successfully");
        yield put({
            type: "SAVE_PAYMENT_DATA_SUCCESS",
            loading: false,
            data: response.data,
            showError: false
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "SAVE_PAYMENT_DATA_LOADER",
            loading: false,
            success: false,
        });
    }
}
function* fetchPaymentInvoice(data) {
    yield put({ type: "FETCH_PAYMENT_INVOICE_DETAIL_LOADER", loading: true });
    const response = yield call(fetchPaymentInvoiceService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_PAYMENT_INVOICE_DETAIL_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchUserAnalyticsData(data) {
    if (!data.payload.export) {
        yield put({ type: "FETCH_USER_ANALYTICS_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchUserAnalyticService, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({
            type: "FETCH_USER_ANALYTICS_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchDefaultFilterUserAnalytic(data) {
    yield put({
        type: "FETCH_DEFAULT_FILTER_USER_ANALYTICS_LOADER",
        loading: true,
    });
    const response = yield call(
        fetchDefaultFilterUserAnalyticService,
        data.payload
    );
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_DEFAULT_FILTER_USER_ANALYTICS_SUCCESS",
            data: response.data.month,
            loading: false
        });
        yield put({
            type: "FETCH_USER_ANALYTICS_DATA",
            payload: { view: "table", month: response.data.month },
            loading: false
        });
    }
}

function* fetchISFileData(data) {
    yield put({ type: "FETCH_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(fetchISFileDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_IS_FILE_DATA_SUCCESS",
            loading: false,
            data: response.data
        });
    }
}
function* fetchForecastFileData(data) {
    yield put({ type: "FETCH_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(fetchForecastFileDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_IS_FILE_DATA_SUCCESS",
            loading: false,
            data: response.data
        });
    }
}
function* fetchFilterData(data) {
    yield put({ type: "FETCH_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(fetchFilterDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_IS_FILTER_DATA_SUCCESS",
            loading: false,
            data: response.data
        });
    }
}
function* fetchPABFileData(data) {
    yield put({ type: "FETCH_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(fetchPABFileDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_IS_FILE_DATA_SUCCESS",
            loading: false,
            data: response.data
        });
    }
}

function* fetchImpactAnalysisFileData(data) {
    yield put({ type: "FETCH_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(fetchImpactAnalysisFileDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_IS_FILE_DATA_SUCCESS_2",
            loading: false,
            data: response.data
        });
    }
}

function* sendMailUserAnalytics(data){
    yield put({ type: "FETCH_USER_ANALYTICS_EMAIL_LOADER", loading: true });
    const response = yield call(sendEmailUserAnalytics, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Send')
        yield put({
            type: "FETCH_USER_ANALYTICS_EMAIL_SUCCESS",
            loading: false,
            data: response.data,
        });
    }

    // if (!data.payload.export) {
    //     yield put({ type: "FETCH_USER_ANALYTICS_DATA_LOADER", loading: true });
    // }
    // const response = yield call(sendEmailUserAnalytics, data.payload);
    // if (!data.payload.export && response && response.status == 200) {
    //     toastr.success('Email Send')
    //     yield put({
    //         type: "FETCH_USER_ANALYTICS_EMAIL_SUCCESS",
    //         loading: false,
    //         data: response.data,
    //     });
    // }
}

function* uploadForecastFile(data) {
    yield put({ type: "UPLOAD_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(uploadForecastFileService, data.payload);
    if (response && response.status == 200) {
        toastr.success("The data has been saved successfully");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_SUCCESS",
            loading: false,
            success: true,
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_LOADER",
            loading: false
        });
    }
}
function* uploadISFile(data) {
    yield put({ type: "UPLOAD_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(uploadISFileService, data.payload);
    if (response && response.status == 200) {
        toastr.success("The data has been saved successfully");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_SUCCESS",
            loading: false,
            success: true,
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_LOADER",
            loading: false
        });
    }
}

function* uploadPABFile(data) {
    yield put({ type: "UPLOAD_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(uploadPABFileService, data.payload);
    if (response && response.status == 200) {
        toastr.success("The data has been saved successfully");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_SUCCESS",
            loading: false,
            success: true,
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_LOADER",
            loading: false
        });
    }
}

function* fetchEbitdaUploadType(data) {
    // try {
    //     const response = yield call(fetchEbitdaUploadTypeService, data.payload);
    //     if (response && response.status == 200) {
    //         yield put({ type: "FETCH_ABITDA_UPLOAD_TYPE_SUCCESS", data: response.data });
    //     }
    // } catch (error) {
    // }
}

function* fetchEbitdaBudgetData(data) {
    yield put({ type: "EBITDA_DATA_LOADER", loading: true });
    const response = yield call(getEbitdaBudgetDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_EBITDA_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* fetchEbitdaActualData(data) {
    yield put({ type: "EBITDA_ACTUAL_DATA_LOADER", loading: true });
    const response = yield call(getEbitdaActualDataService, data.payload);
    if (response && response.status == 200) {
        yield put({
            type: "FETCH_EBITDA_ACTUAL_DATA_SUCCESS",
            loading: false,
            data: response.data,
        });
    }
}

function* saveEbitdaBudgetData(data) {
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("upload_type", data.payload.upload_type);
    yield put({
        type: "SAVE_EBITDA_BUDGET_DATA_LOADER",
        loading: true,
        success: false,
    });
    let response = undefined;
    response = yield call(saveEbitdaBudgetDataService, formData);
    if (response && response.status == 200) {
        toastr.success("The EBITDA Budget data has been saved successfully");
        yield put({
            type: "SAVE_EBITDA_BUDGET_DATA_LOADER",
            loading: false,
            data: response.data,
        });
    } else {
        toastr.error(
            "Upload EBITDA Budget data failed! " + response.data.error.debug_msg
        );
        yield put({
            type: "SAVE_EBITDA_BUDGET_DATA_SUCCESS",
            loading: false,
            success: false,
        });
    }
}

function* saveEbitdaActualData(data) {
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("upload_type", data.payload.upload_type);
    yield put({
        type: "SAVE_EBITDA_ACTUAL_DATA_LOADER",
        loading: true,
        success: false,
    });
    let response = undefined;
    response = yield call(saveEbitdaActualDataService, formData);
    if (response && response.status == 200) {
        toastr.success("The EBITDA Actual data has been saved successfully");
        yield put({
            type: "SAVE_EBITDA_ACTUAL_DATA_LOADER",
            loading: false,
            data: response.data,
        });
    } else {
        toastr.error(
            "Upload EBITDA Actual data failed! " + response.data.error.debug_msg
        );
        yield put({
            type: "SAVE_EBITDA_ACTUAL_DATA_SUCCESS",
            loading: false,
            success: false,
        });
    }
}

function* uploadImpactAnalysisFile(data) {
    yield put({ type: "UPLOAD_IS_FILE_DATA_LOADER", loading: true });
    const response = yield call(uploadImpactAnalysisFileService, data.payload);
    if (response && response.status == 200) {
        toastr.success("The data has been saved successfully");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_SUCCESS",
            loading: false,
            success: true,
        });
    } else {
        toastr.error("Please download the correct template and upload again.");
        yield put({
            type: "UPLOAD_IS_FILE_DATA_LOADER",
            loading: false
        });
    }
}



export default function* actionWatcher() {
    
    yield takeLatest('SEND_MAIL_USER_ANALYTICS', sendMailUserAnalytics)
    yield takeLatest("FETCH_USERMGMT_DATA", fetchUserData);
    yield takeLatest("ADD_USER_DATA", AddUserData);
    yield takeLatest("EDIT_USER_DATA", EditUserData);
    yield takeLatest("DELETE_USER_DATA", DeleteUserData);
    yield takeLatest("FETCH_ROLE_AND_GROUP", GetRoleandGroup);
    yield takeLatest("FETCH_ACLGROUP", fetchRoleData);
    yield takeLatest("FETCH_MENU", fetchMenuData);
    yield takeLatest("ADD_ACLGROUP", addRoleData);
    yield takeLatest("EDIT_ACLGROUP", editRoleData);
    yield takeLatest("DELETE_ACLGROUP", deleteRoleData);
    yield takeLatest("FETCH_AUTO_PAYMENT", fetchPaymentData);
    yield takeLatest("DELETE_AUTO_PAYMENT", deletePaymentData);
    yield takeLatest("FETCH_AUTO_CUSTOMER_COMMENTS", fetchCustomerComments);
    yield takeLatest("FETCH_AUTO_new_COMMENTS", fetchNewComments);
    yield takeLatest("DELETE_AUTO_CUSTOMER_COMMENTS", deleteCustomerComments);
    yield takeLatest("SAVE_AUTO_PAYMENT", savePaymentData);
    yield takeLatest("SAVE_AUTO_COMMENT", saveCommentData);
    yield takeLatest("SAVE_AUTO_COMMENT_new", saveCommentNewData);
    yield takeLatest("DOWNLOAD_FILE_AUTO", downloadFile);
    yield takeLatest('FETCH_AGING_REPORT_DATE_MONTHLY', fetchAgingReportDateMonthly)
    yield takeLatest("FETCH_PAYMENT_INVOICE_DETAIL", fetchPaymentInvoice);
    yield takeLatest("FETCH_USER_ANALYTICS_DATA", fetchUserAnalyticsData);
    yield takeLatest("FETCH_DEFAULT_FILTER_USER_ANALYTICS", fetchDefaultFilterUserAnalytic);
    yield takeLatest("FETCH_IS_FILE_DATA", fetchISFileData);
    yield takeLatest("FETCH_PAB_FILE_DATA", fetchPABFileData);
    yield takeLatest("FETCH_IMPACT_ANALYSIS_FILE_DATA", fetchImpactAnalysisFileData);
    yield takeLatest("UPLOAD_IS_FILE_DATA", uploadISFile);
    yield takeLatest("UPLOAD_PAB_FILE_DATA", uploadPABFile);
    yield takeLatest("UPLOAD_IMPACT_ANALYSIS_FILE_DATA", uploadImpactAnalysisFile);
    yield takeLatest("FETCH_FORECAST_FILE_DATA", fetchForecastFileData);
    yield takeLatest("FETCH_FILTER_DATA", fetchFilterData);    
    yield takeLatest("UPLOAD_FORECAST_FILE_DATA", uploadForecastFile);

    yield takeLatest('FETCH_EBITDA_UPLOAD_TYPE', fetchEbitdaUploadType);
    yield takeLatest("FETCH_FILE_UPLOAD_BUDGET", fetchEbitdaBudgetData);
    yield takeLatest("FETCH_FILE_UPLOAD_ACTUAL", fetchEbitdaActualData);
    yield takeLatest("SAVE_AUTO_EBITDA_BUDGET", saveEbitdaBudgetData);
    yield takeLatest("SAVE_AUTO_EBITDA_ACTUAL", saveEbitdaActualData);
}
