import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import { changeValuesOfData, setAllRowCommentHistoryData, appendChannelId, changeCollectionForecastData } from './transformer'
import {
    fetchOverdueChartByBU,
    fetchOverdueChartSummary,
    performRecievableFetchService,
    performRecievableMonthlyRollingFetchService,
    performRecievableExternalFetchService,
    performRecievableExternalImprovementPlanFetchService,
    sendCommentService,
    getExternalFilterService,
    getPipMasterService,
    getAllUserService,
    savePipDataService,
    getCommentDataService,
    performSummaryBUFetchService,
    fetchAllCommentHistoryService,
    fetchOneCommentHistoryService,
    getPIPIdService,
    getPIPListService,
    fetchAgingCustomerNameService,
    editPIPDataService,
    createChannelService,
    fetchAgingReportDateService,
    sendCollectionForecastService,
    updateActionOwnerService,
    fetchDsoChartSummary,
    saveDSOdata,
    fetchDsoChartBUWise,
    fetchDsoChartBUWiseDSO,
    saveSSDSOData,
    fetchCollectionGraphService,
    savePipCommentsService,
    fetchPLFileUploadErrorService,
    fetchScatterChartView,
    fetchScatterChartViewFirstLoad,
    fetchAROverdueTableSummary,
    fetchAROverdueYearlyChart,
    fetchAROverdueAnalysisAction,
    sendEmailAnalysisAction,
    sendEmailUserAnalytics,
    saveActionOwnerAnalysis,
    getEmailTemplateAnalysisAction,
    getEmailTemplateUserAnalytics,
    saveEmailTemplateAnalysisAction,
    saveEmailTemplateAnalytics,
    fetchDGSEmailTemplate,
    sendEmailDGSData,
    saveEmailRecipientDGSData,
    performRecievableContractLiability,
    performNewHemisphere,
    fetchCompareFilterOptionsService,
    fetchCompareDataForReceivableService
} from './service'

import { store } from '../../index'
import { getAllCommentHistoryData } from '../../utils/commentDataUtil'

function* getReceivableAgeingSummaryData(data) {
    const state = store.getState();
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    const response = yield call(performRecievableFetchService, state.dashboardState.selectedDate, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "RECEIVABLE_AGEING_SUMMARY_DATA_SUCCESS", reportDate: state.dashboardState.selectedDate, loading: false, data: response.data, payload: data.payload });
    }
}
function* getReceivableAgeingMonthlyRollingViewData(data) {
    const state = store.getState();
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    const response = yield call(performRecievableMonthlyRollingFetchService, { ...data.payload, selectedDate: state.dashboardState.selectedDate });
    if (response && response.status == 200) {
        yield put({ type: "RECEIVABLE_MONTHLY_ROLLING_DATA_SUCCESS", reportDate: state.dashboardState.selectedDate, loading: false, data: response.data });
    }
}
function* getReceivableContractLiability(data) {
    const state = store.getState();
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });

    const response = yield call(performRecievableContractLiability, state.dashboardState.selectedDate, data.payload);

    if (response && response.status == 200) {
        yield put({ type: "RECEIVABLE_CONTRACT_LIABILITY_DATA_SUCCESS", reportDate: state.dashboardState.selectedDate, loading: false, data: response.data });
    }
}
function* getNewHemisphere(data) {
    const state = store.getState();
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });

    const response = yield call(performNewHemisphere, data.payload);
    if (response && response.status == 200) {
        if (data.payload.export == "true") {
            yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: false });
        } else {
            yield put({ type: "RECEIVABLE_New_HEMISPHERE_SUCCESS", reportDate: state.dashboardState.selectedDate, loading: false, data: response.data });

        }
    }
}
function* getReceivableAgeingExternalData(data) {
    try {
        const state = store.getState();
        yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const { activePage, selectedFilter } = ageingData.external;
        let updateSelectedFilter = Object.assign({}, selectedFilter)
        updateSelectedFilter['skip'] = activePage * updateSelectedFilter['limit'] || 0
        data.payload['report_date'] = state.dashboardState.selectedDate

        const response = yield call(performRecievableExternalFetchService, data.payload);
        if (response && response.status == 200) {

            // adding column Contract Liability
            // if(data.payload.receivableTabSelectorValue == 1){

            //     response.data.row_data.forEach((x)=>{
            //         x.contract_liability = 0
            //     })

            //     let k = response.data.row_header.slice(0,11);
            //     k.push({key: "contract_liability", label: "Contract Liability", operation: 7}) ;
            //     k.push({key: 'overdue_amount', label: 'Net Overdue', operation: 7})
            //     k = k.concat(response.data.row_header.slice(12));
            //     response.data.row_header = k;

            // }

            //

            if (data.payload.export == "true") {
                yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: false });
            } else {
                response.data.row_data = changeValuesOfData(response.data.row_data)
                const getCommentPayload = getAllCommentHistoryData(activePage, response.data.row_data, state.dashboardState.selectedDate)
                yield put({ type: "RECEIVABLE_AGEING_EXTERNAL_DATA_SUCCESS", loading: false, data: response.data, payload: data.payload });
                yield put({
                    type: "FETCH_COMMENT_CHANNEL_DATA_HISTORY",
                    payload: getCommentPayload,
                })
            }

        }
    } catch (error) {
    }
}

function* getReceivableAgeingExternalImprovementPlanData(data) {
    try {
        const state = store.getState();
        yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const { activePage, selectedFilter } = ageingData.external;
        let updateSelectedFilter = Object.assign({}, selectedFilter)
        updateSelectedFilter['skip'] = activePage * updateSelectedFilter['limit'] || 0
        data.payload['report_date'] = state.dashboardState.selectedDate
        const response = yield call(performRecievableExternalImprovementPlanFetchService, data.payload);
        if (response && response.status == 200) {
            response.data.row_data = changeValuesOfData(response.data.row_data)
            // const getCommentPayload = getAllCommentHistoryData(activePage, response.data.row_data)
            yield put({ type: "RECEIVABLE_AGEING_EXTERNAL_DATA_SUCCESS", loading: false, data: response.data });
            // yield put({
            //     type: "FETCH_COMMENT_CHANNEL_DATA_HISTORY",
            //     payload: getCommentPayload,
            // })
        }
    } catch (error) {
    }
}

function* getReceivableSummaryBUData(data) {
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    const state = store.getState();
    data.payload['report_date'] = state.dashboardState.selectedDate
    const response = yield call(performSummaryBUFetchService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "SUMMARY_BU_DATA_SUCCESS", data: response.data });
    }
}

function* getReceivableAgeingExternalFilterData(data) {
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    const state = store.getState();
    data.payload['report_date'] = state.dashboardState.selectedDate
    const response = yield call(getExternalFilterService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_EXTERNAL_FILTER_DATA_SUCCESS", data: response.data.filter });
    }
}

function* getReceivableAgeingPipMasterData(data) {
    yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    const response = yield call(getPipMasterService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_MASTER_PIP_DATA_SUCCESS", data: response.data });
    }
}

function* getAllUserData(data) {
    const response = yield call(getAllUserService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_ALL_USER_SUCCESS", data: response.data });
    }
}

function* savePipData(data) {
    yield put({ type: "FETCH_PIP_DATA_LOADER", loading: true });
    const response = yield call(savePipDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "SAVE_PIP_DATA_SUCCESS", data: response.data });
        toastr.success('The plan has been submitted successfully and the mail with details has been sent to related parties.')
    } else {
        toastr.error("Error " + response.status)
    }
}

function* savePipCommentsData(data) {
    yield put({ type: "SAVE_PIP_COMMENTS_DATA_LOADER", loading: true });
    const response = yield call(savePipCommentsService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "SAVE_PIP_COMMENTS_DATA_SUCCESS", data: response.data });
    } else {
        toastr.error("Error " + response.status)
    }
}

function* getPipData(data) {
    yield put({ type: "CLEAR_POP_UP", loading: true });
    yield put({ type: "FETCH_PIP_DATA_LOADER", loading: true });
    const response = yield call(getPIPListService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_PIP_DATA_SUCCESS", data: response.data });
    }
}

function* updatePIPData(data) {
    yield put({ type: "FETCH_PIP_DATA_LOADER", loading: true });
    const response = yield call(editPIPDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "UPDATE_PIP_DATA_SUCCESS", data: response.data });
        toastr.success('The plan has been updated successfully')
    } else {
        toastr.error("Error " + response.status)
    }
}

function* getPipIDData(data) {
    yield put({ type: "FETCH_PIP_DATA_LOADER", loading: true });
    const response = yield call(getPIPIdService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_PIP_ID_SUCCESS", data: response.data });
    }
}

function* getReceivableAgeingExternalCommentData(data) {
    yield put({ type: "FETCH_EXTERNAL_COMMENT_DATA_LOADER", loading: true });
    const response = yield call(getCommentDataService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_EXTERNAL_COMMENT_DATA_SUCCESS", loading: false, data: response.data.comments });
    }
}

function* sendCommentFunction(data) {
    yield put({ type: "SEND_COMMENTS_ACTION_LOADER", loading: true });
    const response = yield call(sendCommentService, data.payload.commentPayload);
    if (response && response.status == 200) {
        toastr.success('Comment Added Successfully')
        yield put({ type: "SEND_COMMENTS_ACTION_SUCCESS", loading: false, data: response.data });
        yield put({ type: "FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA", payload: data.payload.filterPayload });

    } else {
        yield put({ type: "SEND_COMMENTS_ACTION_LOADER", loading: false });
    }
}

function* fetchCommentAllChannelHistory(data) {
    try {
        const state = store.getState();
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const externalRowData = ageingData.external.data.row_data
        const response = yield call(fetchAllCommentHistoryService, data.payload);
        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(externalRowData, response.data.chat)
            yield put({ type: "FETCH_COMMENT_HISTORY_ALL_SUCCESS", loading: false, data: updatedRowData });
        }
    } catch (error) {
    }
}

function* fetchOneCommentChannelHistory(data) {
    try {
        const state = store.getState();
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const externalRowData = ageingData.external.data.row_data
        const response = yield call(fetchOneCommentHistoryService, data.payload);
        if (response && response.status == 200) {
            const transformResponse = {
                [data.payload.channelId]: response.data
            }
            const updatedRowData = setAllRowCommentHistoryData(externalRowData, transformResponse)
            yield put({ type: "FETCH_COMMENT_HISTORY_ALL_SUCCESS", loading: false, data: updatedRowData });
        }
    } catch (error) {
    }
}

function* createChannelId(data) {
    try {
        const state = store.getState();
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const externalRowData = ageingData.external.data.row_data
        yield put({ type: 'CHANGE_CREATE_CHANNEL_FLAG', payload: false })
        const response = yield call(createChannelService, data.payload);
        if (response && response.status == 200) {
            const transformedData = appendChannelId(externalRowData, data.payload.company_code, data.payload.customer, response.data, data.payload.customer_name, data.payload.project_id)
            yield put({ type: "CREATE_CHANNEL_SUCCESS", payload: true, data: transformedData.transformedArr, updatedItem: transformedData.updatedItem });
        }
    } catch (error) {
    }
}

function* fetchAgingReportDate(data) {
    try {
        const response = yield call(fetchAgingReportDateService, data.payload);
        if (response && response.status == 200) {
            const dateLen = response.data.dates.length;
            let selectedDate = dateLen > 0 ? response.data.dates[dateLen - 1] : '';
            yield put({ type: "FETCH_AGING_CUSTOMER_NAME", payload: selectedDate });
            yield put({ type: "FETCH_AGING_REPORT_DATE_SUCCESS", data: response.data });
        }
    } catch (error) {
    }
}

function* fetchAgingCustomerName(data) {
    try {
        const response = yield call(fetchAgingCustomerNameService, data.payload);
        if (response && response.status == 200) {
            yield put({ type: "FETCH_AGING_CUSTOMER_NAME_SUCCESS", data: response.data });
        }
    } catch (error) {
    }
}



function* sendCollectionForecast(data) {
    try {
        const state = store.getState();
        const response = yield call(sendCollectionForecastService, data.payload);
        const { dashboardState: { receivableData: { ageingData } } } = state;
        const externalRowData = ageingData.external.data.row_data
        if (response && response.status == 200) {
            const updatedRowData = changeCollectionForecastData(externalRowData, data.payload)
            yield put({ type: "SEND_COLLECTION_FORECAST_SUCCESS", data: updatedRowData });
        }
    } catch (error) {
    }
}

function* updateActionOwner(data) {
    try {
        yield put({ type: "UPDATE_ACTION_OWNER_LOADER", loading: true });
        const state = store.getState();
        const response = yield call(updateActionOwnerService, data.payload);
        const { dashboardState } = state;
        const payloadData = dashboardState.receivableData.payloadData


        if (response && response.status == 200) {
            const tableResponse = yield call(performRecievableExternalFetchService, payloadData);
            if (tableResponse && tableResponse.status == 200) {
                tableResponse.data.row_data = changeValuesOfData(tableResponse.data.row_data)
                yield put({ type: "RECEIVABLE_AGEING_EXTERNAL_DATA_SUCCESS", loading: false, data: tableResponse.data, payload: payloadData });
                yield put({ type: "UPDATE_ACTION_OWNER_SUCCESS" });

            }
        }
    } catch (error) {
    }
}

function* getOverDueChartByBU(data) {
    yield put({ type: "FETCH_OVERDUE_DATA_LOADER", loading: true });
    const response = yield call(fetchOverdueChartByBU, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_OVERDUE_DATA_SUCCESS", data: response.data });
    }
}

function* getOverDueChartSummary(data) {
    yield put({ type: "FETCH_OVERDUE_DATA_LOADER", loading: true });
    const state = store.getState();

    const response = yield call(fetchOverdueChartSummary, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_OVERDUE_DATA_SUCCESS", data: response.data });
    }
}

function* getAROverDueTableSummary(data) {
    yield put({ type: "FETCH_AR_OVERDUE_TABLE_DATA_LOADER", loading: true });
    const response = yield call(fetchAROverdueTableSummary, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_TABLE_DATA_SUCCESS", data: response.data });
    }
}

function* getAROverDueYearlyChart(data) {
    yield put({ type: "FETCH_AR_OVERDUE_YEARLY_LOADER", loading: true });
    const response = yield call(fetchAROverdueYearlyChart, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_YEARLY_DATA_SUCCESS", data: response.data });
    }
}

function* getDsoChartSummary(data) {
    if (!data.payload.export) {
        yield put({ type: "FETCH_DSO_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchDsoChartSummary, data.payload);
    if (!data.payload.export && response && response.status == 200) {
        yield put({ type: "FETCH_DSO_DATA_SUCCESS", data: response.data, payload: data.payload });
    }
}

function* editDSOData(data) {
    yield put({ type: "ADD_DSO_DATA_LOADER", loading: true });
    const response = yield call(saveDSOdata, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DSO_DATA_EDITED_SUCCESS", data: response.data });
        toastr.success('The data has been edited successfully')
    } else {
        toastr.error("Error " + response.data.error.msg)
    }
}

function* getDsoChartBUWise(data) {
    yield put({ type: "FETCH_DSO_BU_WISE_DATA_LOADER", loading: true });
    const response = yield call(fetchDsoChartBUWise, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DSO_BU_WISE_DATA_SUCCESS", data: response.data, payload: data.payload });
    }
}

function* getDsoChartBUWiseDSO(data) {
    yield put({ type: "FETCH_DSO_BU_WISE_DATA_DSO_LOADER", loading: true });
    const response = yield call(fetchDsoChartBUWiseDSO, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DSO_BU_WISE_DATA_DSO_SUCCESS", data: response.data, payload: data.payload });
    }
}
function* saveSSDSO(data) {
    yield put({ type: "FETCH_DSO_BU_WISE_DATA_DSO_LOADER", loading: true });
    const response = yield call(saveSSDSOData, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DSO_BU_WISE_DATA_DSO_SUCCESS", data: response.data, payload: data.payload });
    }
}
function* updateExitWithoutSaveFlag(data) {
    yield put({ type: "UPDATE_EXIT_WITHOUT_SAVE_FLAG", data: data.payload });
}

function* showExitWithoutSaveModal(data) {
    yield put({ type: "UPDATE_EXIT_WITHOUT_SAVE_MODAL", data: data.payload });
}

function* getCollectionGraphData(data) {
    yield put({ type: "FETCH_COLLECTIONS_DATA_LOADER", loading: true });
    let tabValue = data.payload.receivableTabSelectorValue;
    let buValue = data.payload.bu;
    const response = yield call(fetchCollectionGraphService, data.payload);

    if (tabValue == 1 && buValue == 'Summary') {
        let dt = response?.data[0]?.summaryByBUResult;
        let unallocatedExist = true;
        dt.forEach(x => {
            if (x.bu == "Unallocated" && x.forecast == 0 && x.actual == 0) {
                unallocatedExist = false;
            }
        })

        yield put({ type: "FETCH_UNALLOCATED_EXIST", unallocatedExist: unallocatedExist });
    }


    if (response && response.status == 200) {
        yield put({ type: "FETCH_COLLECTIONS_DATA_SUCCESS", data: response.data });
    }
}

function* getAROverDueAnalysisAction(data) {
    yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const response = yield call(fetchAROverdueAnalysisAction, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_DATA_SUCCESS", data: response.data });
    }
}

function* getTemplateEmailAnalysisAction(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateAnalysisAction, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}
function* getTemplateEmailUserAnalytics(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateUserAnalytics, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}
function* showErrorPage(data) {
    // yield put({ type: "SHOW_ERROR_PAGE_REDUCER", data: data.payload });
    const mainState = store.getState();
    toastr.error('Connection Error', `
    Dear ${mainState.authReducer && mainState.authReducer.userData && mainState.authReducer.userData.full_name},
We are facing some trouble saving the chat messages currently. Request to report this to the support team for review and fix. Sincere apologies for the inconvenience.`)

}



function* fetchPLFileUploadError(data) {
    yield put({ type: "FETCH_GENERAL_TABLE_STATE_LOADER", loading: true });
    const response = yield call(fetchPLFileUploadErrorService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_GENERAL_TABLE_STATE_SUCCESS", loading: false, data: response.data });
    }
}

function* getScatterChartView(data) {
    yield put({ type: "FETCH_AR_OVERDUE_CHART_VIEW_DATA_LOADER", loading: true });
    const response = yield call(fetchScatterChartView, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_CHART_VIEW_DATA_SUCCESS", data: response.data });
    }
}
function* getScatterChartViewFirstLoad(data) {
    const state = store.getState();
    const response = yield call(fetchScatterChartViewFirstLoad, data.payload);
    if (response && response.status == 200) {
        var result = response.data
        yield put({ type: "FETCH_AR_OVERDUE_CHART_VIEW_FILTER_DATA_SUCCESS", data: response.data, chartType: data.payload.chart_type });
        yield put({ type: "FETCH_AR_OVERDUE_CHART_VIEW_DATA_LOADER", loading: true });
        const responseChart = yield call(fetchScatterChartView, { "report_date": "31-Aug-2021", "chart_type": data.payload.chart_type });
        if (responseChart && responseChart.status == 200) {
            yield put({ type: "FETCH_AR_OVERDUE_CHART_VIEW_DATA_SUCCESS", data: responseChart.data });
        }

    }
}


function* saveTemplateEmailAnalysis(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailTemplateAnalysisAction, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Template Saved')
        yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    } else {
        toastr.error(response.error)
    }
}
function* saveTemplateEmailAnalytics(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailTemplateAnalytics, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Template Saved')
        yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    } else {
        toastr.error(response.error)
    }
}
function* sendMailAnalysis(data) {
    yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const response = yield call(sendEmailAnalysisAction, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_DATA_SUCCESS", data: response.data });
        toastr.success('Email sent successfully')
    }
}

function* saveActionOwnerAnalysisAction(data) {
    // yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const response = yield call(saveActionOwnerAnalysis, data.payload);
    if (response && response.status == 200) {
        toastr.success('Action Owner 2 saved successfully')
    } else if (response && response.status == 400) {
        toastr.error(response && response.data && response.data.error && response.data.error.debug_msg)
    }
}



function* fetchEmailTemplate(data) {
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchDGSEmailTemplate, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS", loading: false, data: response.data });
    }
}

function* sendMailDGS(data) {
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const response = yield call(sendEmailDGSData, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: false });
        // yield put({ type: "FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
        toastr.success('Email sent successfully')
    }
}
function* saveRecipientMailDGS(data) {
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailRecipientDGSData, data.payload);
    if (response && response.status == 200) {
        // yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: false });
        yield put({ type: "FETCH_DATABROWSER_EMAIL_SAVE_RECIPIENT_SUCCESS", data: response.data });
        toastr.success('Email Recipient Save successfully')
    }
}

function* fetchCompareFilterOption(data) {
    yield put({ type: "FETCH_COMPARE_FILTER_RECEIVABLE_LOADER", loading: true });

    const response = yield call(fetchCompareFilterOptionsService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_COMPARE_FILTER_RECEIVABLE_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_COMPARE_DATA_RECEIVABLE_LOADER", loading: false });
}

function* fetchCompareDataReceivable(data) {
    if (data.payload.export != true) {
        yield put({ type: "FETCH_COMPARE_DATA_RECEIVABLE_LOADER", loading: true });
    }
    const response = yield call(fetchCompareDataForReceivableService, data.payload);
    if (response && response.status == 200 && data.payload.export != true) {
        yield put({ type: "FETCH_COMPARE_DATA_RECEIVABLE_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_COMPARE_DATA_RECEIVABLE_LOADER", loading: false });
}

export default function* actionWatcher() {
    yield takeLatest('SEND_PIP_COMMENTS', savePipCommentsData)
    yield takeLatest('FETCH_RECEIVABLE_DATA', getReceivableAgeingSummaryData)
    yield takeLatest('FETCH_MONTHLY_ROLLING_VIEW_DATA', getReceivableAgeingMonthlyRollingViewData)
    yield takeLatest('FETCH_CONTRACT_LIABILITY', getReceivableContractLiability)
    yield takeLatest('FETCH_New_HEMISPHERE', getNewHemisphere)
    yield takeLatest('FETCH_RECEIVABLE_AGEING_IMPROVEMENT_PLAN_EXTERNAL_DATA', getReceivableAgeingExternalImprovementPlanData)
    yield takeLatest('FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA', getReceivableAgeingExternalData)
    yield takeLatest('SEND_COMMENT_ACTION', sendCommentFunction)
    yield takeLatest('FETCH_EXTERNAL_FILTER_DATA', getReceivableAgeingExternalFilterData)
    yield takeLatest('FETCH_MASTER_PIP_DATA', getReceivableAgeingPipMasterData)
    yield takeLatest('FETCH_ALL_USER', getAllUserData)
    yield takeLatest('SAVE_PIP_DATA', savePipData)
    yield takeLatest('UPDATE_PIP_DATA', updatePIPData)
    yield takeLatest('FETCH_PIP_DATA', getPipData)
    yield takeLatest('FETCH_PIP_ID', getPipIDData)
    yield takeLatest('FETCH_EXTERNAL_COMMENT_DATA', getReceivableAgeingExternalCommentData)
    yield takeLatest('FETCH_SUMMARY_BU_DATA', getReceivableSummaryBUData)
    yield takeLatest('FETCH_COMMENT_CHANNEL_DATA_HISTORY', fetchCommentAllChannelHistory)
    yield takeLatest('FETCH_COMMENT_ONE_CHANNEL_DATA_HISTORY', fetchOneCommentChannelHistory)
    yield takeLatest('CREATE_CHANNEL_ID', createChannelId)
    yield takeLatest('FETCH_AGING_REPORT_DATE', fetchAgingReportDate)
    yield takeLatest('SEND_COLLECTION_FORECAST', sendCollectionForecast)
    yield takeLatest('UPDATE_ACTION_OWNER', updateActionOwner)
    yield takeLatest('FETCH_OVERDUE_CHART', getOverDueChartSummary)
    yield takeLatest('FETCH_OVERDUE_CHART_BY_BU', getOverDueChartByBU)
    yield takeLatest('FETCH_DSO_CHART', getDsoChartSummary)
    yield takeLatest('EDIT_DSO_DATA', editDSOData)
    yield takeLatest('FETCH_DSO_BU_WISE_CHART', getDsoChartBUWise)
    yield takeLatest('FETCH_DSO_BU_WISE_DSO_CHART', getDsoChartBUWiseDSO)
    yield takeLatest('FETCH_SAVE_SS', saveSSDSO)

    yield takeLatest('UPDATE_EXIT_WITHOUT_SAVE_CHECK', updateExitWithoutSaveFlag)
    yield takeLatest('SHOW_EXIT_WITHOUT_SAVE_MODAL', showExitWithoutSaveModal)
    yield takeLatest('FETCH_COLLECTIONS_GRAPH', getCollectionGraphData)
    yield takeLatest('FETCH_EMAIL_TEMPLATE_DGS_VALIDATION', fetchEmailTemplate)
    yield takeLatest('SEND_MAIL_DGS', sendMailDGS)
    yield takeLatest('SAVE_EMAIL_RECIPIENT_DGS', saveRecipientMailDGS)
    yield takeLatest('SHOW_ERROR_PAGE', showErrorPage)
    yield takeLatest('FETCH_PL_FILE_UPLOAD_ERROR', fetchPLFileUploadError)
    yield takeLatest('FETCH_SCATTER_CHART_VIEW', getScatterChartView)
    yield takeLatest('FETCH_SCATTER_CHART_VIEW_FIRST_LOAD', getScatterChartViewFirstLoad)
    yield takeLatest('FETCH_AR_OVERDUE_TABLE', getAROverDueTableSummary)
    yield takeLatest('FETCH_AR_YEARLY_CHART', getAROverDueYearlyChart)
    yield takeLatest('FETCH_AR_OVERDUE_ANALYSIS_ACTION', getAROverDueAnalysisAction)
    yield takeLatest('SEND_MAIL_ANALYSIS_ACTION', sendMailAnalysis)
    yield takeLatest('UPDATE_ACTION_OWNER_ANALYSIS_ACTION', saveActionOwnerAnalysisAction)
    yield takeLatest('FETCH_EMAIL_TEMPLATE_AR', getTemplateEmailAnalysisAction)
    yield takeLatest('FETCH_EMAIL_TEMPLATE_ANALYTICS', getTemplateEmailUserAnalytics)
    yield takeLatest('SAVE_EMAIL_TEMPLATE_ANALYTICS', saveTemplateEmailAnalytics)
    yield takeLatest('SAVE_EMAIL_TEMPLATE_AR', saveTemplateEmailAnalysis)
    yield takeLatest('FETCH_AGING_CUSTOMER_NAME', fetchAgingCustomerName)
    yield takeLatest('FETCH_COMPARE_FILTER_RECEIVABLE', fetchCompareFilterOption)
    yield takeLatest('FETCH_COMPARE_DATA_RECEIVABLE', fetchCompareDataReceivable)
}
