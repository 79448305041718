import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableComponent from './TableComponent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import CircularProgress from '@material-ui/core/CircularProgress';
import DrilldownComponent from '../Shared/InvoiceDetail'

const rollingViewMap = {
  0: '',
  1: 'monthly',
  2: 'quarterly',
  3: 'yearly'
}

export default function () {
  const [radioSelectorValue, setRadioSelectorValue] = useState('view_byLocation')
  const [qtrSelectorValue, setQtrSelectorValue] = useState('')
  const [yearSelectorValue, setYearSelectorValue] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  const [showDrilldownModal, setShowDrilldown] = useState(false)
  const extendedPlanningState = useSelector(state => state.extendedPlanning);
  const { actualBUData } = extendedPlanningState
  const { filter, drilldownData } = actualBUData
  const {showDrilldown} = drilldownData
  const { yearArr, qtrArr, monthArr, defaultDate } = filter
  const dispatch = useDispatch();
  const [monthSelectorValue, setMonthSelectorValue] = useState('')
  const [radioRollingSelectorValue, setRollingRadioSelectorValue] = useState('0')


  useEffect(() => {
    if (showDrilldown) {
      setShowLoader(false)
      setShowDrilldown(true)
    }
  }, [showDrilldown])
  
  const handleMonthChange = (event) => {
    setYearSelectorValue('')
    setQtrSelectorValue('')
    setMonthSelectorValue(event.target.value)
  }
  const handleQtrChange = (event) => {
    setQtrSelectorValue(event.target.value)
    setYearSelectorValue('')
    setMonthSelectorValue('')
  }
  const handleYearChange = (event) => {
    setYearSelectorValue(event.target.value)
    setQtrSelectorValue('')
    setMonthSelectorValue('')
  }

  const handleRollingRadioChange = (event) => {
    setRollingRadioSelectorValue(event.target.value)
  }

  useEffect(() => {
    if (defaultDate) {
      setMonthSelectorValue(defaultDate)
    }
  },[defaultDate])

  useEffect(() => {
    handleFetchData()
  }, [monthSelectorValue, qtrSelectorValue, yearSelectorValue, radioSelectorValue])


  useEffect(() => {
    let thisMonth = monthSelectorValue
    if (radioRollingSelectorValue == 1 || radioRollingSelectorValue == 0) {
      setMonthSelectorValue(defaultDate)
      setQtrSelectorValue('')
      setYearSelectorValue('')
      if(thisMonth == defaultDate){
        handleFetchData()
      }
    }else if(radioRollingSelectorValue == 2) {
      setQtrSelectorValue(qtrArr[0].value)
      setMonthSelectorValue('')
      setYearSelectorValue('')
    }else {
      setYearSelectorValue(yearArr[0].value)
      setMonthSelectorValue('')
      setQtrSelectorValue('')
    }
  },[radioRollingSelectorValue])

  const handleFetchData = (isExport = false) => {
    const payload = {
      export: isExport,
      year: yearSelectorValue ? yearSelectorValue : undefined,
      quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
      yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
      view_type: radioSelectorValue,
      view_period: rollingViewMap[radioRollingSelectorValue]
    }

    dispatch({
      type: "FETCH_ACTUAL_BU_TABLE_DATA",
      payload
    })
  }

  const handleRadioChange = (event) => { 
    setRadioSelectorValue(event.target.value)
  }

  const handleColumnDataClick = (_data, parentData, itemData) => {
    //console.log(_data, parentData, itemData);
  if (radioRollingSelectorValue == 0) {
    setShowLoader(true)
    const payload = {
      year: yearSelectorValue ? yearSelectorValue : undefined,
      quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
      yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
      ..._data['sub_column']['info']
    }

    dispatch({
      type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA",
      payload
    })
  }
   
  }

  const handleDrilldownClose = () => {
    setShowDrilldown(false)

  }

  return (
    <div>
      {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
      <div className='flex jc-space-between'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Month</InputLabel>
              <Select
                value={monthSelectorValue}
                label="Month"
                disabled={radioRollingSelectorValue == 2 || radioRollingSelectorValue == 3}
                onChange={handleMonthChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {monthArr && monthArr.length && monthArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Quarter</InputLabel>
              <Select
                value={qtrSelectorValue}
                label="Quarter"
                disabled={radioRollingSelectorValue == 1 || radioRollingSelectorValue == 3}
                onChange={handleQtrChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {qtrArr && qtrArr.length && qtrArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Annual</InputLabel>
              <Select
                value={yearSelectorValue}
                label="Annual"
                disabled={radioRollingSelectorValue == 1 || radioRollingSelectorValue == 2}
                onChange={handleYearChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {yearArr && yearArr.length && yearArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end'>
						<Tooltip title={'Download to Excel'} className='pointer'>
							<img onClick={() => {handleFetchData(true)}} src={DownloadExcel} className='pointer' height='30' />
						</Tooltip>
					</Grid>
      </div>
      <div>
          <FormControl component="fieldset">
                <RadioGroup name="radio" value={radioRollingSelectorValue} onChange={handleRollingRadioChange} className='radioGroupHorizontal'>
                <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Summary" />
                  <FormControlLabel value={'1'} control={<Radio color="primary" />} label="Monthly Rolling View" />
                  <FormControlLabel value={'2'} control={<Radio color="primary" />} label="Quarterly Rolling View" />
                  <FormControlLabel value={'3'} control={<Radio color="primary" />} label="Yearly Rolling View" />
                </RadioGroup>
              </FormControl>
          </div>
      <div>
        <FormControl component="fieldset">
          <RadioGroup name="radio" value={radioSelectorValue} onChange={handleRadioChange} className='radioGroupHorizontal'>
            <FormControlLabel value="view_byLocation" control={<Radio color="primary" />} label="By Country" />
            <FormControlLabel value="view_byPM" control={<Radio color="primary" />} label="By PM" />
            <FormControlLabel value="view_byProjectID" control={<Radio color="primary" />} label="By ProjectID" />
            <FormControlLabel value="view_byCustomerSegment" control={<Radio color="primary" />} label="By Customer Segment" />

          </RadioGroup>
        </FormControl>
        </div>
      <br />
      <div>
        <TableComponent 
          handleColumnDataClick={handleColumnDataClick}
        />
      </div>
      <DrilldownComponent 
      showModal={showDrilldownModal}
      handleClose={handleDrilldownClose}
      />
    </div>
  )
}