import React from "react";
import { Chip } from "@material-ui/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import "../ReportComponent/index.scss";

function getListStyle(isDraggingOver) {
    return { background: isDraggingOver ? "white" : "white" };
}

export default function SelectedMenu({ keyStr, itemArray, color, typeStr, onChipClick, onDelete }) {
    return (
        <Droppable key={keyStr} droppableId={keyStr} type={typeStr} direction={"horizontal"}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)} className="right">
                    {itemArray && itemArray.length > 0
                        ? itemArray.map((item, index) => {
                              const { label = "" ,  filters: itemFilters = [], draggableId = "" } = item ?? {};
                            //   let alias = item?.alias? " / "+ item.alias : "";
                              return (
                                  <Draggable key={label + index} draggableId={draggableId} index={index}>
                                      {(provided) => {
                                          return (
                                              <Chip
                                                  size="small"
                                                  label={`${index + 1}. ${label} `  }
                                                  onClick={(event) => onChipClick && onChipClick(event, draggableId, index, keyStr, itemFilters)}
                                                  onDelete={() => onDelete && onDelete(index)}
                                                  color={color}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                              />
                                          );
                                      }}
                                  </Draggable>
                              );
                          })
                        : null}
                    {provided.placeholder}
                    {itemArray && itemArray.length < 7  &&!snapshot.isDraggingOver
                        ?
                        <p className="ml-10" style={{fontSize:'13px', color:"#A1A1A1"}}> (Drag and Drop from Selections) </p>
                        : null
                    }
                </div>
            )}
        </Droppable>
    );
}
