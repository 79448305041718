import axios from 'axios';
import { CONFIG } from '../../../config'

export async function fetchBUPlIncomeStatementPivotService(payload) {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/pivot`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchAllBUPLIncomeService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/pivotall${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}


export async function fetchSalesTechnologyPieDataService(payload) {


  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/salesTechnology/analytics`,payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchNewPieDataService(payload) {


  let cpApiEndPoint = {
    3: 'cppiebyregion',
    4: 'cppiebycountry',
    5: 'cppiebyhemisphere'
  }

  let dassApiEndPoint = {
    3: 'dasspiebyregion',
    4: 'dasspiebycountry',
    5: 'dasspiebyhemisphere'
  }

  let summaryApiEndPoint = {
    3: 'summarypiebyregion',
    4: 'summarypiebycountry',
    5: 'summarypiebyhemisphere'
  }

  let completeEndPoint = 'pivotall'

  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if(payload.isTotal){
    queryParam += `&istotal=true`
  }
  if(payload.apiEndPoint == 4){
    queryParam += `&region=${payload.region}`
  }

  if(payload.apiEndPoint == 3 && payload.hemisphereClicked){
    queryParam += `&hemisphere=${payload.hemisphereClicked.toProperCase()}`
  }


console.log(payload.tabValue, "checkTab")
 if(payload.tabValue == 2) {
    completeEndPoint = cpApiEndPoint[payload.apiEndPoint]
  }else if(payload.tabValue == 1){
    completeEndPoint = dassApiEndPoint[payload.apiEndPoint]
  }else if(payload.tabValue == 0){
    completeEndPoint = summaryApiEndPoint[payload.apiEndPoint]
  }


  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${completeEndPoint}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchAOPNewService(payload) {

  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  console.log('>>>>>>> aop', payload)
  
  if(payload.classification){
    queryParam += `&classification=${payload.classification}`
  }
  if (payload.classification == "New BU 1" || payload.classification == "New BU 2" || payload.classification == "summary"){
    queryParam += `&classification=${payload.classification}`
  }else if (payload.classification){
    queryParam += `&bu=${payload.classification}`
  }

  if(payload.aop){
    queryParam += `&aop=${payload.aop}`
  }

  // if(payload.hemisphere )  {
  //   queryParam += `&hemisphere=${payload.hemisphere.toProperCase()}`
  // }
  switch (payload.hemisphere){
    case "all" :
      queryParam += `&hemisphere=All`
      break;
    case "wh" :
    queryParam += `&hemisphere=West`
    break;
    case "eh" :
    queryParam += `&hemisphere=East`
    break;
    default :
    if(payload.hemisphere != undefined){
      queryParam += `&region_pnl=${payload.hemisphere}`
    }
  }

  if (payload.export) {
      queryParam += `&export=true`
    
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/pivotaop-ori${queryParam}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      
      return response;
    }
    ).catch(err => err.response);

  }else {
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/pivotaop-ori${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
  }
  
 
}
export async function fetchAOPNewDestinationService(payload) {

  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  console.log('>>>>>>> aop', payload)
  
  if (payload.classification == "New BU 1" || payload.classification == "New BU 2" || payload.classification == "summary"){
    queryParam += `&classification=${payload.classification}`
  }else if (payload.classification){
    queryParam += `&bu=${payload.classification}`
  }

  if(payload.aop){
    queryParam += `&aop=${payload.aop}`
  }

  // if(payload.hemisphere )  {
  //   queryParam += `&hemisphere=${payload.hemisphere.toProperCase()}`
  // }


    switch (payload.hemisphere){
      case "all" :
        queryParam += `&hemisphere=All`
        break;
      case "wh" :
      queryParam += `&hemisphere=West`
      break;
      case "eh" :
      queryParam += `&hemisphere=East`
      break;
      default :
      if(payload.hemisphere != undefined){
        queryParam += `&region_pnl=${payload.hemisphere}`
      }
    }

  if (payload.export) {
      queryParam += `&export=true`
    
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/pivotaopdestination-ori${queryParam}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      
      return response;
    }
    ).catch(err => err.response);

  }else {
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/pivotaopdestination-ori${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
  }
  
 
}
export async function fetchAllNewService(payload) {

  let apiEndPoint = {
    0: 'pivotall',
    1: 'pivotbyplant',
    2: 'pivotbyle',
    3: 'pivotbyregion',
    4: 'pivotbycountry',
    5: 'pivotbyhemisphere',
  }

  let cpApiEndPoint = {
    0: 'cpsummary',
    1: 'pivotbyplant',
    3: 'cpbyregion',
    4: 'cpbycountry',
    5: 'cpbyhemisphere'
  }

  let dassApiEndPoint = {
    0: 'dasssummary',
    3: 'dassbyregion',
    4: 'dassbycountry',
    5: 'dassbyhemisphere',
  }

  let completeEndPoint = 'pivotall'

  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  console.log('>>>>>>>', payload)
  if(payload.isTotal && payload.reconValue != 2){
    queryParam += `&istotal=true`
  }
  if(payload.apiEndPoint == 4 && payload.tabValue != -1){
    queryParam += `&region=${payload.region}`
  }

  if(payload.apiEndPoint == 3 && payload.hemisphereClicked){
    queryParam += `&hemisphere=${payload.hemisphereClicked.toProperCase()}`
  }



  if (payload.tabValue == 0 || payload.tabValue == 3) {
   
    completeEndPoint = apiEndPoint[payload.apiEndPoint]
    if(payload.reconValue == 1) {
      completeEndPoint ="reconciling"
    }else if(payload.reconValue == 2) {
      completeEndPoint ="ocosbycountry"

      if(payload.buOCOSByCountry && payload.buOCOSByCountry != "summary"){
        queryParam += `&bu=${payload.buOCOSByCountry}`
      }

    }else if(payload.reconValue == 6) {
      completeEndPoint ="pabvalidation"
    }

    if (payload.reconValue == 'All' || payload.reconValue == 'West' || payload.reconValue == 'East'){
      queryParam += `&hemisphere=${payload.reconValue}` 
    }else if(payload.origination == "true" ) {
      queryParam += `&region=${payload.reconValue}` 
    }

    // if(payload.apiEndPoint == 1 && payload.buPlant != "summary" ){ 
    //   queryParam += `&bu=${payload.buPlant}` 
    // }

    // if(payload.apiEndPoint == 1 && payload.buPlant != "summary" ){ 
    //   queryParam += `&bu=${payload.buPlant}` 
    // }
    // if(payload.reconValue == 1){

    // }
  }else if(payload.tabValue == 2) {
    completeEndPoint = cpApiEndPoint[payload.apiEndPoint]

    if(payload.apiEndPoint == 1 && payload.buPlant != "summary" ){ 
      queryParam += `&bu=${payload.buPlant}` 
    }

    if(payload.apiEndPoint == 1 && payload.buPlant != "summary" ){ 
      queryParam += `&bu=${payload.buPlant}` 
    }

  }else if(payload.tabValue == 1){
    completeEndPoint = dassApiEndPoint[payload.apiEndPoint]
  } else if (payload.tabValue == 5){
    queryParam += `&isreport=true` 
    queryParam += `&mpr=true`
  }
  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "non7400view") {
    completeEndPoint ="pivotbylenon7400"
  }
  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "summaryLE") {
    completeEndPoint ="pivotbyleall"
    payload.radioSelectorValueLE = "non7400view"
  }
  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "7400view") {
    completeEndPoint ="pivotbyle7400"
  }
 
  if(payload.apiEndPoint == 4 && payload.radioArrCountrySelected != "Default"){
    completeEndPoint ="pivotallbycountry"
    queryParam += `&country=${payload.radioArrCountrySelected}`
  }else   if(payload.apiEndPoint == 3 && payload.radioArrSelected != "Default"){
    completeEndPoint ="pivotallbyregion"
    queryParam += `&region=${payload.radioArrSelected}`
  }else   if(payload.apiEndPoint == 5 && payload.radioArrSelected != "Default"){
    completeEndPoint ="pivotallbyhemisphere"
    console.log("masukSini ", payload)
    queryParam += `&hemisphere=${payload.radioArrSelected}`
  }
  
  // console.log("---ori", payload.origination, payload.apiEndPoint)
  if(payload.origination == "true" && completeEndPoint != "pivotbyplant"){
    completeEndPoint += "-ori"
    queryParam += `&origination=true`
  }
  if(payload.validation == true){
    queryParam += `&validation=true`
  }

  if(payload.tabValue == -1 || payload.mpr == "true"){//if MPR
    switch (payload.mprfirstradio){
      case "0" :
      queryParam += `&classification=summary`
      break;
      case "1" :
      queryParam += `&classification=New BU 1`
      break;
      case "2" :
      queryParam += `&classification=New BU 2`
      completeEndPoint =  apiEndPoint[0] + "-ori"
      break;
      case "3" :
      queryParam += `&bu=BU 1`
      break;
      case "4" :
      queryParam += `&bu=BU 2`
      break;
      case "5" :
      queryParam += `&bu=BU 3`
      break;
      case "6" :
      queryParam += `&bu=BU 4`
      break;
      case "7" :
      queryParam += `&bu=OH`
      break;
      default : 
      queryParam += `&classification=summary`
    }
    switch (payload.mprsecondradio){
      case "summary" :
        queryParam += `&hemisphere=All`
        break;
      case "wh" :
      queryParam += `&hemisphere=West`
      break;
      case "eh" :
      queryParam += `&hemisphere=East`
      break;
      default :
      if(payload.mprsecondradio != undefined){
        queryParam += `&region_pnl=${payload.mprsecondradio}`
      }
    }
    queryParam += `&mpr=true`
  }

  if(payload.tabValueParent == 0 && payload.origination == "true", payload.tabValue == 4){
    completeEndPoint = "totalsalesreport"
    queryParam += `&radiobutton=` + payload.radioSelectorTotalSales
  }


  if (payload.export) {
      queryParam += `&export=true`
    
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${completeEndPoint}${queryParam}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      
      return response;
    }
    ).catch(err => err.response);

  }else {
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${completeEndPoint}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
  }
  
 
}

export async function fetchAllNewOCOSSummary(payload) {


  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  // if(payload.isTotal && payload.reconValue != 2){
  //   queryParam += `&istotal=true`
  // }
  // if(payload.apiEndPoint == 4){
  //   queryParam += `&region=${payload.region}`
  // }
let completeEndPoint = ''

  if(payload.reconValue == 2) {
      completeEndPoint ="ocossummary"
    }


  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${completeEndPoint}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function fetchAllNewDGSLawson(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }
  if (payload.radioSelectorDynanicDGS) {
    queryParam += `&country=${payload.radioSelectorDynanicDGS}`
  }
  
  let url = ''
  if(payload.dgsAutomationType == "lawson"){
    url = 'dgsautolawson'
  }else if(payload.dgsAutomationType == "slbsap"){
    url = 'dgsautoslbsap'
  }else if(payload.dgsAutomationType == "ifs"){
    url = 'dgsautoifs'
  }else if(payload.dgsAutomationType == "new"){
    url = 'dgsautonew'
  }else if(payload.dgsAutomationType == "total"){
    url = 'dgsautototal'
  }
  console.log(payload, "checkPayload")
  
console.log(payload, "checkPayloads")
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${url}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function fetchAllChartSO(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if (payload.classificationSO == "New BU 1"){
    queryParam += `&classification=${payload.classificationSO}`
  }else if (payload.classificationSO == "New BU 2"){
    queryParam += `&classification=${payload.classificationSO}`
  }

  let url = 'chartsalescost'
  // if(payload.dgsAutomationType == "lawson"){
  //   url = 'dgsautolawson'
  // }else if(payload.dgsAutomationType == "slbsap"){
  //   url = 'dgsautoslbsap'
  // }else if(payload.dgsAutomationType == "ifs"){
  //   url = 'dgsautoifs'
  // }else if(payload.dgsAutomationType == "new"){
  //   url = 'dgsautonew'
  // }else if(payload.dgsAutomationType == "total"){
  //   url = 'dgsautototal'
  // }
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${url}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function fetchAllOptimusAutomation(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  let url = 'optimusauto'
  // if(payload.dgsAutomationType == "lawson"){
  //   url = 'dgsautolawson'
  // }else if(payload.dgsAutomationType == "slbsap"){
  //   url = 'dgsautoslbsap'
  // }else if(payload.dgsAutomationType == "ifs"){
  //   url = 'dgsautoifs'
  // }else if(payload.dgsAutomationType == "new"){
  //   url = 'dgsautonew'
  // }else if(payload.dgsAutomationType == "total"){
  //   url = 'dgsautototal'
  // }
  
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${url}${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function downloadExcelAllNewService(payload) {
  // console.log(payload,"checkPayloadExcel")
  let apiEndPoint = {
    0: 'pivotall',
    1: 'pivotbyplant',
    2: 'pivotbyle',
    3: 'pivotbyregion',
    4: 'pivotbycountry',
    5: 'pivotbyhemisphere',
    6: 'chartsalescost'
  }
 
  let cpApiEndPoint = {
    0: 'cpsummary',
    1: 'pivotbyplant',
    3: 'cpbyregion',
    4: 'cpbycountry',
    5: 'cpbyhemisphere',
    6: 'chartsalescost'
  }

  let dassApiEndPoint = {
    0: 'dasssummary',
    3: 'dassbyregion',
    4: 'dassbycountry',
    5: 'dassbyhemisphere',
    6: 'chartsalescost'
  }

  let byBUApiEndPoint = {
    3: 'pivotallbyregion',
    4: 'pivotallbycountry',
    5: 'pivotallbyhemisphere',
  }

  let byBUApiParam = {
    3: 'region',
    4: 'country',
    5: 'hemisphere',
  }


  let completeEndPoint = 'pivotall'

  let queryParam = ''
  if(payload.tabValue != 5) {
    if (payload.year) {
      queryParam = `?year=${payload.year}`
    } else if (payload.yearmonth) {
      queryParam = `?yearmonth=${payload.yearmonth}`
    } else if (payload.quarter) {
      queryParam = `?quarter=${payload.quarter}`
    }
  }
  console.log('>>>>>>>', payload)
  
  

  if(payload.apiEndPoint == 4&& payload.tabValue != -1){
    queryParam += `&region=${payload.region}`
  }

  if(payload.apiEndPoint == 3 && payload.hemisphereClicked){
    queryParam += `&hemisphere=${payload.hemisphereClicked.toProperCase()}`
  }


  if(payload.export){
    queryParam += `&export=true`
  }

  if (payload.tabValue == 0 || payload.tabValue == 3) {
    completeEndPoint = apiEndPoint[payload.apiEndPoint]
    if(payload.reconValue == 1) {
      completeEndPoint ="reconciling"
    }else if(payload.reconValue == 2) {
      completeEndPoint ="ocosbycountry"

      if(payload.buOCOSByCountry && payload.buOCOSByCountry != "summary"){
        queryParam += `&bu=${payload.buOCOSByCountry}`
      }

    }else if(payload.reconValue == 6) {
      completeEndPoint ="pabvalidation"
    }else if(payload.reconValue == 4) {
      if(payload.dgsAutomationType == "lawson"){
        completeEndPoint = 'dgsautolawson'
      }else if(payload.dgsAutomationType == "slbsap"){
        completeEndPoint = 'dgsautoslbsap'
      }else if(payload.dgsAutomationType == "ifs"){
        completeEndPoint = 'dgsautoifs'
      }else if(payload.dgsAutomationType == "new"){
        completeEndPoint = 'dgsautonew'
      }else if(payload.dgsAutomationType == "total"){
        completeEndPoint = 'dgsautototal'
      }
      
      queryParam = `?country=${payload.countries}&yearmonth=${payload.yearmonth}`
      queryParam += `&export=true`
    } 
    if(payload.apiEndPoint == 1 && payload.buPlant != "summary" ){ 
      queryParam += `&bu=${payload.buPlant}` 
    }
    if( (payload.locationRadio != "Default" && (payload.apiEndPoint == 3 || payload.apiEndPoint == 5) ) || ( payload.apiEndPoint == 4 && payload.locationRadioCountry != "Default")){
      completeEndPoint = byBUApiEndPoint[payload.apiEndPoint]
      if(payload.apiEndPoint == 4){
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadioCountry 
      }else{
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadio
      }
    }
  }else if(payload.tabValue == 2) {
    completeEndPoint = cpApiEndPoint[payload.apiEndPoint]
    
    if( (payload.locationRadio != "Default" && (payload.apiEndPoint == 3 || payload.apiEndPoint == 5) ) || ( payload.apiEndPoint == 4 && payload.locationRadioCountry != "Default")){
      completeEndPoint = byBUApiEndPoint[payload.apiEndPoint]
      if(payload.apiEndPoint == 4){
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadioCountry 
      }else{
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadio
      }
    }

  }else if(payload.tabValue == 1){
    completeEndPoint = dassApiEndPoint[payload.apiEndPoint]
    
    if( (payload.locationRadio != "Default" && (payload.apiEndPoint == 3 || payload.apiEndPoint == 5) ) || ( payload.apiEndPoint == 4 && payload.locationRadioCountry != "Default")){
      completeEndPoint = byBUApiEndPoint[payload.apiEndPoint]
      if(payload.apiEndPoint == 4){
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadioCountry 
      }else{
        queryParam += "&" + byBUApiParam[payload.apiEndPoint] + "=" + payload.locationRadio
      }
    }
  }

  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "non7400view") {
    completeEndPoint ="pivotbylenon7400"
  }
  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "summaryLE") {
    completeEndPoint ="pivotbyleall"
    payload.radioSelectorValueLE = "non7400view"
  }
  if(payload.apiEndPoint == 2 && payload.radioSelectorValueLE == "7400view") {
    completeEndPoint ="pivotbyle7400"
  }
  if(payload.reconValue == 5) {
    completeEndPoint ="optimusauto"
  }else{
    if(payload.isTotal){
      queryParam += `&istotal=true`
    }
  }
  
  if(payload.locationRadio && payload.locationRadio != "" && payload.locationRadio != "Default"){

  }

  if(payload.tabValue == 2 && payload.apiEndPoint == 1 ){
    if(payload.buPlant == "validation"){
    queryParam += `&bu=${payload.buPlant}`
    }
  }else if ((payload.tabValue == 2 || payload.tabValue == 1) && payload.apiEndPoint == "6" ){
    if (payload.classificationSO == "New BU 1" ){
      queryParam += `&classification=${payload.classificationSO}`
    }else if (payload.classificationSO == "New BU 2" ){
      queryParam += `&classification=${payload.classificationSO}`
    }
  }


  
  if(payload.origination == "true" && completeEndPoint != "pivotbyplant"){
    completeEndPoint += "-ori"
    queryParam += `&origination=true`
  }
  if(payload.validation == true){
    queryParam += `&validation=true`
  }

  if(payload.tabValue == -1 || payload.mpr == "true"){//if MPR
    switch (payload.mprfirstradio){
      case "0" :
      queryParam += `&classification=summary`
      break;
      case "1" :
      queryParam += `&classification=New BU 1`
      break;
      case "2" :
      queryParam += `&classification=New BU 2`
      completeEndPoint =  apiEndPoint[0] + "-ori"
      break;
      case "3" :
      queryParam += `&bu=BU 1`
      break;
      case "4" :
      queryParam += `&bu=BU 2`
      break;
      case "5" :
      queryParam += `&bu=BU 3`
      break;
      case "6" :
      queryParam += `&bu=BU 4`
      break;
      case "7" :
      queryParam += `&bu=OH`
      break;
      default : 
      queryParam += `&classification=New BU 1`
    }
    switch (payload.mprsecondradio){
      case "summary" :
        queryParam += `&hemisphere=All`
        break;
      case "wh" :
      queryParam += `&hemisphere=West`
      break;
      case "eh" :
      queryParam += `&hemisphere=East`
      break;
      default :
      queryParam += `&hemisphere=West`
    }
    queryParam += `&mpr=true`
  }

// console.log(completeEndPoint, payload.radioSelectorValueLE, "check212")
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${completeEndPoint}${queryParam}`, {responseType: 'blob'})
    .then(response => {
      if (payload.export) {
        var x = response.data;
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      }
      return response;
    }
    ).catch(err => err.response);
}

export async function fetchMonthlyQuarterBUPLService(payload) {
  let queryParam = ''
  if (payload.type) {
    queryParam = `?type=${payload.type}&typeSelector=${payload.typeSelector}&bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&exclude=${payload.excludeData}`
  }
  if (payload.plant) {
    queryParam += `&plant=${payload.plant}`
  }
  if (payload.country) {
    queryParam += `&country=${payload.country}`
  }
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/pivotperiodwise${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchAutomationNewSummary(){
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/validation/summary`)
  .then(response=> response)
  .catch(err=> err.response);
}

export async function fetchCountryPlantBUPLService(payload) {
  let queryParam = ''
  if (payload.type && payload.bu) {
    queryParam = `${payload.type}?bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&country=${payload.country}&exclude=${payload.excludeData}`
  }
  if (payload.type == 'plant') {
    let plantType = ""
    if(payload.plantType == "1"){
      plantType = "destination"
    }else if(payload.plantType == "2"){
      plantType = "origination"
    }else if(payload.plantType == "3"){
      plantType = "comparative"
    }
    queryParam += `&legal_entity=${payload.legalEntity}&plant=${payload.plant}&plantType=${plantType}`
  }
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/pivot/${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchDefaultMonthValueService() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/lastmonth`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchNewDefaultMonthValueService() {
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/lastmonth`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchNewDefaultMonthValueServiceDynamic(payload) {
  let queryParam = `?reconvalue=${payload.recon_value}`
  if (payload.radiovalue) {
    queryParam += `&radiovalue=${payload.radiovalue}`
  }
  console.log()
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/lastmonthdynamic${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function performFetchLawsonDetail(payload) {
  // let queryParam = `bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&tab_value=${payload.tab_value}&sub_tab_value=${payload.sub_tab_value}&grid_header_value=${payload.grid_header}&level_values=${payload.level_values}&plant=${payload.plant}&country=${payload.country}&filter_amount=${payload.filter_amount}`
  // if (payload.sub_tab_value == 'comparative' || payload.sub_tab_value == 'comparativeByPlant') {
  //   queryParam += `&view=${payload.view}&sub_grid_header_value=${payload.sub_grid_header_value}`
  // }
  let queryPayload = {
    ...payload,
    grid_header_value: payload.grid_header
  }
  let url = ''
  if(payload.dgsAutomationType == "lawson"){
    url = 'dgsautolawson'
  }else if(payload.dgsAutomationType == "slbsap"){
    url = 'dgsautoslbsap'
  }else if(payload.dgsAutomationType == "ifs"){
    url = 'dgsautoifs'
  }else if(payload.dgsAutomationType == "new"){
    url = 'dgsautonew'
  }else if(payload.dgsAutomationType == "total"){
    url = 'dgsautototal'
  }


  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/dgsautodrilldown`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}
export async function performFetchDropDown(payload) {
  

  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/optimusdropdown`)
    .then(response => response)
    .catch(err => err.response);
}
export async function performFetchOptimusAutoDetail(payload) {
  let queryPayload = {
    ...payload,
    grid_header_value: payload.grid_header
  }

  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/optimusautodrilldown`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchPABValidationDetail(payload) {
  let queryPayload = {
    ...payload,
    grid_header_value: payload.grid_header
  }

  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/pabvalidationdrilldown`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchBUPLInvoiceDetailService(payload) {
  let queryParam = `bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&tab_value=${payload.tab_value}&sub_tab_value=${payload.sub_tab_value}&grid_header_value=${payload.grid_header}&level_values=${payload.level_values}&plant=${payload.plant}&country=${payload.country}&filter_amount=${payload.filter_amount}`
  if (payload.sub_tab_value == 'comparative' || payload.sub_tab_value == 'comparativeByPlant') {
    queryParam += `&view=${payload.view}&sub_grid_header_value=${payload.sub_grid_header_value}`
  }
  let queryPayload = {
    ...payload,
    grid_header_value: payload.grid_header
  }

  if (payload.export){
    return axios({
      url: `${CONFIG.api_base_url}v1/incomestatement/pnl/drilldown`,
      method: 'POST',
      responseType: 'blob',
      data: queryPayload
  })
      .then(
          response => {
            var x = response.data;
            var u = URL.createObjectURL(x)
            var a = document.createElement("a");
            a.href = u;
            a.download = (response.headers['content-disposition']).split('=')[1];
            a.click();
            window.URL.revokeObjectURL(u);
              return response
          }
      )
      .catch(err => err.response)
  }

  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/drilldown`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchExtendedPlanningActualService(payload) {
  let queryPayload = {
    yearmonth : payload.yearmonth,
    year : payload.year,
    quarter : payload.quarter,
    export : payload.export
  }

  switch(payload.typeSelector){
    case "Quarterly Rolling View" :
      queryPayload.quarter = payload.grid_header;
      break;
    case "Monthly Rolling View" :
      queryPayload.yearmonth = payload.grid_header;
      break;
    case "Summary" :
      if(payload.sub_tab_value == "country"){
        queryPayload.country = payload.grid_header;
      }
      break;
  }

  if (payload.export){
    return axios({
      url: `${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual`,
      method: 'POST',
      responseType: 'blob',
      data: queryPayload
  })
      .then(
          response => {
            var x = response.data;
            var u = URL.createObjectURL(x)
            var a = document.createElement("a");
            a.href = u;
            a.download = (response.headers['content-disposition']).split('=')[1];
            a.click();
            window.URL.revokeObjectURL(u);
              return response
          }
      )
      .catch(err => err.response)
  }

  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/extendedplanning/actual`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function postNewCommentDataService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/addComment`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function postNewOriginationCommentDataService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/addCommentOrigination`, payload)
    .then(response => response)
    .catch(err => err.response);
}



export async function postNewManualAmountDataService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/saveManualInput`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchBUPLInvoiceDetailServiceGL(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/drilldown/gl`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchBUPLComparativeService(payload) {
  let queryParam = `bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&view=${payload.view}&exclude=${payload.excludeData}`
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/comprative?${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchAutomationIS(payload) {
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/getDataValidationIS?type=${payload.radioType}`)
    .then(response => response)
    .catch(err => err.response);
}
export async function performFetchAutomationNew(payload) {

  let apiEndPoint = {
    0: 'NewValidation',
    1: 'NewValidationRegion',
    2: 'NewValidationUpload',
    3: 'NewPlantValidation',
    4: 'NewVsBUValidation'
  }

  if(payload.apiEndPoint == 5){
    return axios.get(`${CONFIG.api_base_url}v1/incomestatement/validation/getDataValidationARByResion?report_date=${payload.month}`)
    .then(response => response)
    .catch(err => err.response);
  }else {

    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${apiEndPoint[payload.apiEndPoint]}`)
    .then(response => response)
    .catch(err => err.response);
  }

 
}
export async function performFetchAutomationDSO() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/getDataValidationDSO`)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchAutomationNewDSO(){
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/getDataValidationDSO`)
      .then(response => response)
      .catch(err => err.response);
}
export async function performFetchAutomationNewDSOGeoSummary(){
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/getDataValidationGeoSummaryDSO`)
      .then(response => response)
      .catch(err => err.response);
}
export async function performFetchPaymentData() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/getActualForecastValidation`)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchAutomationReceivableService() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/getDataValidationReceivable`)
    .then(response => response)
    .catch(err => err.response);
}

export async function performFetchAutomationNewReceivableService(payload){
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/getDataValidationReceivable`)
    .then(response => response)
    .catch(err=> err.response)
}

export async function performFetchBUPLComparativePlantService(payload) {
  let queryParam = `bu=${payload.bu}&yearmonth=${payload.yearmonth}&year=${payload.year}&quarter=${payload.quarter}&view=${payload.view}&exclude=${payload.excludeData}`
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/comprative/plant?${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchDefaultMonthValueHFMService() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/hfm/lastmonth`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchBudgetGroupService() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/budget/group`)
    .then(response => response)
    .catch(err => err.response);
}



export async function fetchSalesTechnologyPivotService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `year=${payload.year}`
    queryParam += `&type=month`
  } else if (payload.yearmonth) {
    queryParam = `yearmonth=${payload.yearmonth}`
    queryParam += `&type=month`
  } else if (payload.quarter) {
    queryParam = `quarter=${payload.quarter}`
    queryParam += `&type=month`
  }
  if (payload.budgetGroup) {
    queryParam += `&budget_group=${payload.budgetGroup}`
  }
  if (payload.budgetGroupUser) {
    queryParam += `&budget_group_user=${payload.budgetGroupUser}`
  }
  if (payload.excludeData) {
    queryParam += `&exclude=${payload.excludeData}`
  }
  queryParam += `&tsa=${payload.tsa}`
  console.log(payload, "queryPayload")
  let bg 
  if(payload.budgetGroup == null){
bg = ["Facilities", "Finance", "HR"]
  }else{
    bg = payload.budgetGroup
  }
  let queryPayload 
  let url = "salesTechnology"
  if (payload.budgetGroup != '') {
     queryPayload = {
      year: payload.year,
      yearmonth: payload.yearmonth,
      type: payload.type == ""? 'month' : payload.type,
      reportType: payload.reportType == ""? 'month' : payload.reportType,
      quarter: payload.quarter,
      countryMetaName:  payload.countryMetaName,
      data_type: payload.budgetGroup,
      // budget_group_user : payload.budgetGroupUser.join(),
      exclude: payload.excludeData,
      tsa: payload.tsa,
      isComparative: payload.isComparative
      
    }
    if(payload.budgetGroup == "Analytics"){
      queryPayload = {
        year: payload.year,
        yearmonth: payload.yearmonth,
        quarter: payload.quarter,
        
      }
      url = "salesTechnology/analytics"
    }
  }else{
     queryPayload = {
      year: payload.year,
      yearmonth: payload.yearmonth,
      type: payload.type == ""? 'month' : payload.type,
      reportType: payload.reportType == ""? 'month' : payload.reportType,
      quarter: payload.quarter,
      countryMetaName:  payload.countryMetaName,
      // budget_group_user : payload.budgetGroupUser.join(),
      exclude: payload.excludeData,
      tsa: payload.tsa,
      isComparative: payload.isComparative
    }
  }
 
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/${url}`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchFunctionalPivotService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `year=${payload.year}`
    queryParam += `&type=month`
  } else if (payload.yearmonth) {
    queryParam = `yearmonth=${payload.yearmonth}`
    queryParam += `&type=month`
  } else if (payload.quarter) {
    queryParam = `quarter=${payload.quarter}`
    queryParam += `&type=month`
  }
  if (payload.budgetGroup) {
    queryParam += `&budget_group=${payload.budgetGroup}`
  }
  if (payload.budgetGroupUser) {
    queryParam += `&budget_group_user=${payload.budgetGroupUser}`
  }
  if (payload.excludeData) {
    queryParam += `&exclude=${payload.excludeData}`
  }
  queryParam += `&tsa=${payload.tsa}`
  console.log(payload, "queryPayload")
  let bg 
  if(payload.budgetGroup == null){
bg = ["Facilities", "Finance", "HR"]
  }else{
    bg = payload.budgetGroup
  }
  let queryPayload 
  if (payload.budgetGroup != '') {
     queryPayload = {
      year: payload.year,
      yearmonth: payload.yearmonth,
      type: 'month',
      quarter: payload.quarter,
      budget_group: payload.budgetGroup,
      budget_group_user : payload.budgetGroupUser.join(),
      exclude: payload.excludeData,
      tsa: payload.tsa,
      isComparative: payload.isComparative
    }
  }else{
     queryPayload = {
      year: payload.year,
      yearmonth: payload.yearmonth,
      type: 'month',
      quarter: payload.quarter,
      budget_group_user : payload.budgetGroupUser.join(),
      exclude: payload.excludeData,
      tsa: payload.tsa,
      isComparative: payload.isComparative
    }
  }
 
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/pivotData`, queryPayload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchFunctionalSummaryService(payload) {
  let queryParam = ''
  if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
    queryParam += `&type=month`
    queryParam += `&budget_group_user=${payload.budgetGroupUser.join()}`
  }
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/budget-summary${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function sendPivotComment(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/pivotData/comment/add`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function sendFunctionalComment(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/budget-summary/comment/add`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function performPostManualReceivableAmountService(payload) {
  let url;
  if(payload.radioSelectorReceivablesValue == "legacyBU"){
    url = `${CONFIG.api_base_url}v1/incomestatement/saveDataValidationReceivable`;
  }else{
    url = `${CONFIG.api_base_url}v2/incomestatement/New/saveDataValidationReceivable`;
  }
  return axios.post(url, payload) 
    .then(response => response)
    .catch(err => err.response);
}

export async function performPostManualReceivableDSOAmountService(payload) {
  let url;
  if( payload.radioSelectorDSOValue == "legacyBU"){
    url = `${CONFIG.api_base_url}v1/incomestatement/saveDataValidationReceivable`; 
  }else{
    url = `${CONFIG.api_base_url}v2/incomestatement/New/saveDataValidationReceivable`;
  }
  return axios.post(url, payload) 
    .then(response => response)
    .catch(err => err.response);
}

export async function performPostManualNewAmountService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/saveManualInput`, payload)
    .then(response => response)
    .catch(err => err.response);
}



export async function performPostManualISAmountService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/saveDataValidationIS`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchTotalnewConsolidationService(payload) {
  let config = {}
  if (payload.export) {
    config.responseType = 'blob'
  }
  let queryParam = ''
  if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
    queryParam += `&bu=${payload.bu}`
    queryParam += `&export=${payload.export}`
  }
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/total-consolidation${queryParam}`, config)
    .then(response => {
      if (payload.export) {
        var x = response.data;
        console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      }
      return response;
    }
    ).catch(err => err.response);
}

export async function fetchFunctionalBudgetGraphService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/budget-summary/chart`, payload)
    .then(res => res)
    .catch(err => err.response)
}

export async function performFetchBuplValidationDataService() {
  return axios.get(`${CONFIG.api_base_url}v1/incomestatement/pnl/get-upload-details`)
    .then(res => res)
    .catch(err => err.response)
}

export async function performPostBuplValidationAmountService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/update-details`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchFunctionaDrilldownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/account-details`, payload)
    .then(res => res)
    .catch(err => err.response)
}
export async function fetchFunctionaSTDrilldownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functionalSalesTechnology/account-details`, payload)
    .then(res => res)
    .catch(err => err.response)
}
export async function saveNewDrilldownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v1/automation/optimusupdate`, payload)
    .then(res => res)
    .catch(err => err.response)
}
export async function fetchNewDrilldownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/drilldown`, payload)
    .then(res => res)
    .catch(err => err.response)
}

export async function fetchNewOriginationDrilldownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/originationdrilldown`, payload)
    .then(res => res)
    .catch(err => err.response)
}

const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
export async function fetchNewSubHeaderDrilldownService(payload) {
  let apiEndPoint = ""
  if(payload.tabSelectorValue == 0){
    apiEndPoint = "pivotall"
  }else if(payload.tabSelectorValue == 1){
    apiEndPoint = "dasssummary"
  }else if(payload.tabSelectorValue == 2){
    apiEndPoint = "cpsummary"
  }
  let queryParam = ""
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if(payload.radioSelectorValue == 5){
    queryParam += `&hemisphere=${toTitleCase(payload.header)}`
  }else if(payload.radioSelectorValue == 1){
    let header = ""
    if(payload.header.includes("_")){
      header = payload.header.split('_').join(' ');
      header = header.toUpperCase()
    }else{
      header =payload.header.toUpperCase()
    }

 
    queryParam += `&plant_name=${header}`
  }else if(payload.radioSelectorValue == 3){
   let header = ""
    if(payload.header.includes("_")){
      header = payload.header.split('_').join(' ');
      header = header.toUpperCase()
    }else{
      header =payload.header.toUpperCase()
    }
    queryParam += `&region=${header}`
  }

  if (payload.subHeader) {
    queryParam += `&subHeader=${payload.subHeader}`
  }
  queryParam += `&type=drilldown`
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${apiEndPoint}${queryParam}`)
  .then(response => response)
  .catch(err => err.response);
  // return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/drilldown`, payload)
  //   .then(res => res)
  //   .catch(err => err.response)
}
export async function downloadFunctionaSTDrilldownService(payload) {
  return axios({
      url: `${CONFIG.api_base_url}v1/incomestatement/pnl/functionalSalesTechnology/account-details`,
      method: 'POST',
      responseType: 'blob',
      data: payload
  })
      .then(
          response => {
            var x = response.data;
            console.log(response, x)
            var u = URL.createObjectURL(x)
            var a = document.createElement("a");
            a.href = u;
            a.download = (response.headers['content-disposition']).split('=')[1];
            a.click();
            window.URL.revokeObjectURL(u);
              return response
          }
      )
      .catch(err => err.response)
}


export async function downloadFunctionaDrilldownService(payload) {
  return axios({
      url: `${CONFIG.api_base_url}v1/incomestatement/pnl/functional/account-details`,
      method: 'POST',
      responseType: 'blob',
      data: payload
  })
      .then(
          response => {
            var x = response.data;
            console.log(response, x)
            var u = URL.createObjectURL(x)
            var a = document.createElement("a");
            a.href = u;
            a.download = (response.headers['content-disposition']).split('=')[1];
            a.click();
            window.URL.revokeObjectURL(u);
              return response
          }
      )
      .catch(err => err.response)
}

export async function postSalesTechnologyTornadoDataAction(payload) {
  
  return axios.post(`${CONFIG.api_base_url}v1/incomestatement/pnl/functional/salesTechnologyTornadoChart`,payload).then(
    response => {
      return response;
    }
  ).catch(err => {
    return err.response 
  });
}


export async function fetchAdhocChartDataService(payload) {
    let config = {}
    let queryParam = ""
    if (payload.export) {
      config.responseType = 'blob'
      queryParam += "&export=true"
    }
    // console.log(payload, "--payload ini")
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/customerchart?year=FY22,FY21${queryParam}`, config).then(
      response => {
        if (payload.export) {
          var x = response.data;
          var u = URL.createObjectURL(x)
          var a = document.createElement("a");
          a.href = u;
          a.download = (response.headers['content-disposition']).split('=')[1];
          a.click();
          window.URL.revokeObjectURL(u);
        }
        return response;
      }
    ).catch(err => {
      return err.response 
    });
}


export async function fetchOrderBookDataService(payload) {
  
  if (payload.export) {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderbookanalysis`, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderbookanalysis`, payload)
    .then(response => response)
    .catch(err => err.response);
  }
}


export async function fetchServiceRevenueProfitabilityService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year_label=${payload.year}`
  } else if (payload.month) {
    queryParam = `?yearmonth=${payload.month}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/servicerevenueprofitability${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchTAndEAnalysisService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
    queryParam += `&bu=${payload.bu}`
    queryParam += `&New_bu=${payload.New_bu}`
    let report_type = payload.report_type
        report_type = report_type.includes('&') ? report_type.replace('&', '%26') : report_type;

    queryParam += `&report_type=${report_type}`
  }
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/gettnedata${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchTAndEAnalysisDrillDownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/gettnedatadrilldown`,payload)
  .then(response => {
      return response;
    }
  ).catch(err => {
    return err.response 
  });
}

export async function fetchOvertimeAnalysisService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
    queryParam += `&bu=${payload.bu}`
    queryParam += `&New_bu=${payload.New_bu}`
  }

  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getovertimedata${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchOvertimeAnalysisDrillDownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/gettovertimedatadrilldown`,payload)
  .then(response => {
      return response;
    }
  ).catch(err => {
    return err.response 
  });
}

export async function fetchServicesRevenueByRegionService(payload) {
  let queryParam = `?bu=${payload.bu}`;
  
  if (payload.export) {
      queryParam += `&export=true`
    
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/servicerevenuetracked${queryParam}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      
      return response;
    }
    ).catch(err => err.response);

  }else {
    return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/servicerevenuetracked${queryParam}`)
      .then(response => response)
      .catch(err => err.response);
  }
}

export async function fetchDrillDownServicesRevenueByRegionService(payload) {

  
  // return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/${url}${queryParam}`)
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/drilldownservicesrevenue`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchDrillDownOrderBookAnalysisService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderbookanalysisdrilldown`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchOrderbookAnalysisComparisonService(payload) {
  if (payload.export) {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderbookanalysiscomparison`, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderbookanalysiscomparison`, payload)
    .then(response => response)
    .catch(err => err.response);
  }
}

export async function fetchDassAopRevenueAnalysisService(payload) {
  const query = payload
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassaoprevenueanalysis`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchDassAopFrontlogService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderfrontlog`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchDassAopFrontlogDrillDownService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getorderfrontlogdrilldown`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchSummaryDASSTableDataService(payload) {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassaoprevenuedata`, payload)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchSummaryPABBacklogTableDataService(payload) {

  if (payload.export) {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis`, payload, {responseType: 'blob'})
   // return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis${query}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis`, payload)
    //return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis${query}`)
    .then(response => response)
    .catch(err => err.response);
  }
}

export async function fetchDASSByGeoTableDataService(payload) {

  let url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuetrendbygeo`

  if(payload.isCustomer){
    url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuebycustomer`
  }

  if (payload.export) {
    return axios.post(url, payload, {responseType: 'blob'})
   // return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis${query}`, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(url, payload)
    //return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysis${query}`)
    .then(response => response)
    .catch(err => err.response);
  }
}

export async function fetchSummaryPABBacklogDrilldownService(payload) {
  // return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysisdrilldown`, payload)
  //   .then(response => response)
  //   .catch(err => err.response);

  if (payload.export) {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysisdrilldown`, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(`${CONFIG.api_base_url}v2/incomestatement/adhoc/getpabbackloganalysisdrilldown`, payload)
    .then(response => response)
    .catch(err => err.response);
  }
}


export async function fetchDASSByGeoTableDataDrilldownService(payload) {

  let url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuetrendbygeodrilldown`

  if(payload.isCustomer){
    url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuetrendbygeodrilldown`
  }

  if (payload.export) {
    return axios.post(url, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(url, payload)
    .then(response => response)
    .catch(err => err.response);
  }
}

export async function fetchDASSRevenueByCustomerDataDrilldownService(payload) {

  let url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuebycustomerdrilldown `

  if(payload.isCustomer){
    url = `${CONFIG.api_base_url}v2/incomestatement/adhoc/getdassrevenuebycustomerdrilldown `
  }

  if (payload.export) {
    return axios.post(url, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(url, payload)
    .then(response => response)
    .catch(err => err.response);
  }
}

export async function updateDASSRevenueByCustomerDataDrilldownService(payload) {
  let queryParam = ''
  if (payload.customer_name) {
    queryParam += `?customer_name=${payload.customer_name}`
  } 
  if (payload.version) {
    queryParam += `?version=${payload.version}`
  } 
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/adhoc/updatecustomergroup${queryParam}`)
    .then(response => response)
    .catch(err => err.response);
}


export async function fetchNewBUFourReportDataService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if (payload.export) {
    queryParam += `&export=true`
  
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufour${queryParam}`, {responseType: 'blob'})
  .then(response => {
      var x = response.data;
      console.log(response, x)
      var u = URL.createObjectURL(x)
      var a = document.createElement("a");
      a.href = u;
      a.download = (response.headers['content-disposition']).split('=')[1];
      a.click();
      window.URL.revokeObjectURL(u);
    
    return response;
  }
  ).catch(err => err.response);

}else {
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufour${queryParam}`)
  .then(response => response)
  .catch(err => err.response);
}
}


export async function saveXPNACommentService(payload) {
   
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/xpnacomment`,payload)
  .then(response => response)
  .catch(err => err.response);
}

export async function fetchNewBUFourTargetReportDataService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if (payload.export) {
    queryParam += `&export=true`
  
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/bufourtargetaddcolumn`, {
    export: true,
    year: payload.year,
    quarter: payload.quarter,
    yearmonth: payload.yearmonth
  }, {responseType: 'blob'})
  .then(response => {
      var x = response.data;
      var u = URL.createObjectURL(x)
      var a = document.createElement("a");
      a.href = u;
      a.download = (response.headers['content-disposition']).split('=')[1];
      a.click();
      window.URL.revokeObjectURL(u);
    
    return response;
  }
  ).catch(err => err.response);

}else {
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/New/bufourtargetaddcolumn`, payload)
  .then(response => response)
  .catch(err => err.response);
}
}

export async function fetchNewBUFourByPMDataService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if (payload.export) {
    queryParam += `&export=true`
  
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufourbypm${queryParam}`, {responseType: 'blob'})
  .then(response => {
      var x = response.data;
      var u = URL.createObjectURL(x)
      var a = document.createElement("a");
      a.href = u;
      a.download = (response.headers['content-disposition']).split('=')[1];
      a.click();
      window.URL.revokeObjectURL(u);
    
    return response;
  }
  ).catch(err => err.response);

}else {
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufourbypm${queryParam}`)
  .then(response => response)
  .catch(err => err.response);
}
}

export async function fetchNewBUFourByLocationDataService(payload) {
  let queryParam = ''
  if (payload.year) {
    queryParam = `?year=${payload.year}`
  } else if (payload.yearmonth) {
    queryParam = `?yearmonth=${payload.yearmonth}`
  } else if (payload.quarter) {
    queryParam = `?quarter=${payload.quarter}`
  }

  if (payload.export) {
    queryParam += `&export=true`
  
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufourbylocation${queryParam}`, {responseType: 'blob'})
  .then(response => {
      var x = response.data;
      var u = URL.createObjectURL(x)
      var a = document.createElement("a");
      a.href = u;
      a.download = (response.headers['content-disposition']).split('=')[1];
      a.click();
      window.URL.revokeObjectURL(u);
    
    return response;
  }
  ).catch(err => err.response);

}else {
  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/New/bufourbylocation${queryParam}`)
  .then(response => response)
  .catch(err => err.response);
}
}


export async function fetchNewBUFourDrilldownDataService(payload) {

  let url = `${CONFIG.api_base_url}v2/incomestatement/New/bufourdrilldown`;
  if (payload.export) {
    return axios.post(url, payload, {responseType: 'blob'})
    .then(response => {
        var x = response.data;
        //console.log(response, x)
        var u = URL.createObjectURL(x)
        var a = document.createElement("a");
        a.href = u;
        a.download = (response.headers['content-disposition']).split('=')[1];
        a.click();
        window.URL.revokeObjectURL(u);
      return response;
    }
    ).catch(err => err.response);
  }
  else {
    return axios.post(url, payload)
    .then(response => response)
    .catch(err => err.response);
  }

}

export async function uploadNewBUFourReportDataService(payload) {
   
    return axios.post(`${CONFIG.api_base_url}v1/automation/uploadxpnatargetdatareport/`,payload)
    .then(response => response)
    .catch(err => err.response);
}



export async function uploadExcelFileService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/automation/uploadNeworiginationeditdata/`,
			payload
		)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function fetchAllCommentHistoryService(payload) {

  return axios.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload, { withCredentials: true }).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchCompareFilterOptionsService(payload) {

  return axios.get(`${CONFIG.api_base_url}v2/incomestatement/pnl/compare/filter`).then(
      response => {
          return response;
      }
  ).catch(err => {
      return err.response
  });
}


export async function fetchCompareDataForBUPLService(payload) {

  if (payload.export) {
   return axios({
      url: `${CONFIG.api_base_url}v2/incomestatement/pnl/compare`,
      method: 'POST',
      responseType: 'blob',
      data: payload
  })
      .then(
          response => {
            var x = response.data;
            console.log(response, x)
            var u = URL.createObjectURL(x)
            var a = document.createElement("a");
            a.href = u;
            a.download = (response.headers['content-disposition']).split('=')[1];
            a.click();
            window.URL.revokeObjectURL(u);
              return response
          }
      )
      .catch(err => err.response)
  }
  return axios.post(`${CONFIG.api_base_url}v2/incomestatement/pnl/compare`, payload).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

