import React from "react";
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Tooltip } from "@material-ui/core";
import ReviewPlanningComponent from './ReviewPlan';
import InputPlanningComponent from './InputPlan';
import './Planning.scss'
// import ReviewImage from "../../../images/review-approve.png";
import CadenceBoard from "./CadenceBoard";
import Button from '@material-ui/core/Button';
import PlanningModelsComponent from '../PlanningModels/index.jsx'
import ComparePlansComponent from '../ComparePlans/index.jsx'

export class PlanningComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelectorValue: 0,
        };
    }

    componentDidMount(){
        // this.props.getAllUser({})
        // this.callGetAllCommentHistoryFunc()
        this.props.fetchDropdownPlanning({})
        this.props.fetchKpiPlanningReview({})
      }

    handleTabChange = (event, newValue) => {
        this.setState({ tabSelectorValue: newValue }, () => {
          if (newValue == 0) {
            this.props.fetchDropdownPlanning({})
            this.props.fetchKpiPlanningReview({})
      } else if (this.state.tabSelectorValue == 1) {
            this.props.fetchKpiPlanningReview({})
          } else { //will change later
          }
        })
    };


     handleRedirectToCompare = (value) => {
      // localStorage.setItem('show-back-fcf','true')
      window.location.assign('/dashboard/xpna/extended-planning/compare-plans')
    }

     handleRedirectToViewPlans = (value) => {
        // localStorage.setItem('show-back-fcf','true')
      window.location.assign('/dashboard/xpna/extended-planning/planning-models')
    }

    switchTab = (tab) =>{
      this.setState({tabSelectorValue : tab})
    }

    render() {
        const {authState} = this.props;
        const {tabSelectorValue} = this.state;

        const body = (
            <React.Fragment>  

    <Grid container xs={12} sm={12} className="pb-15 buttons-section">
    <Grid item sm={12} xs={12} >
              <Tabs
                value={tabSelectorValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="INPUT" />
                <Tab label="REVIEW/APPROVE" />
                <Tab label="COMPLETION DASHBOARD" />
                <Tab label="VIEW PLANS" />
                <Tab label="COMPARE" />
              </Tabs>
              </Grid>
              </Grid>
              <Grid container className='mt-20 mb-20'>  
                {tabSelectorValue == 1 &&
                  <ReviewPlanningComponent 
                  fetchKpiPlanningReview={this.props.fetchKpiPlanningReview}
                  kpiPlanningDatas={this.props.kpiPlanningReviewDatastate}
                  menuData={authState.menuData}
                  updateStatusKpiPlanning={this.props.updateStatusKpiPlanning}
                  fetchKPIPlanning={this.props.fetchKPIPlanning}
                  kpiPlanningDataView={this.props.kpiPlanningDataViewstate}/>
                }
                {tabSelectorValue == 0 &&
                    <InputPlanningComponent 
                    fetchKPIPlanning={this.props.fetchKPIPlanning}
                    planningFilter={this.props.kpiPlanningFilter}
                    planningData={this.props.planningState}
                    userDataState={authState.userData}
                    savePlanModels={this.props.savePlanModels}
                    saveKpiBudgetModels={this.props.saveKpiBudgetModels}
                    menuData={authState.menuData}
                    updateStatusKpiPlanning={this.props.updateStatusKpiPlanning}/>
                }
                {tabSelectorValue == 2 &&
                  <div className='buplComponent free-cash-flow'>
                    <CadenceBoard/>
                  </div>
                }
                {tabSelectorValue == 4 &&
                                <ComparePlansComponent  />
                }
                {tabSelectorValue == 3 &&
                               <PlanningModelsComponent switchTab={this.switchTab}  />
                }
              </Grid>
              
            </React.Fragment>
          )

        return body;
    }
}

export default
    connect(
        state => {
            return {
                planningState: state.shreeCementState.planningData,
                authState: state.authReducer,
                // chatState: state.geospacialState.chatData,
                // chatStoreState: state.chatStore,
                kpiPlanningFilter: state.functionalPlanningState.kpiDataPlanningFilter,
                kpiPlanningReviewDatastate: state.functionalPlanningState.kpiDataPlanningReview,
                kpiPlanningDataViewstate: state.functionalPlanningState.KPIData
            }
        },
        dispatch => {
            return {
                fetchDropdownPlanning() {
                    dispatch({
                        type: "FETCH_DROPDOWN_PLANNING"
                    })
                },
                savePlanModels(data) {
                  dispatch({
                      type: "SAVE_KPI_PLANNING",
                      payload: data
                  })
                },
                saveKpiBudgetModels(data) {
                  dispatch({
                      type: "SAVE_KPI_BUDGET_PLANNING",
                      payload: data
                  })
                },
                updateStatusKpiPlanning(data) {
                  dispatch({
                      type: "UPDATE_STATUS_KPI_PLANNING",
                      payload: data
                  })
                },
                fetchKpiPlanningReview(data) {
                  dispatch({
                      type: "FETCH_KPI_PLANNING_REVIEW",
                      payload: data
                  })
                },
                fetchKPIPlanning(data) {
                  dispatch({
                    type: 'FETCH_KPI_PLANNING',
                    payload: data,
                  });
                },
            };
        }
    )(PlanningComponent);