import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";
import { useSelector} from "react-redux";

const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const DroppableStyles = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: #d4d4d4;
`;

function DraggableElement(props) {

  const { prefix, elements, handleOpen, planTypeValue, dateStatusSelected } = props;
  const [elementsArray, setElementsArray] = React.useState([]);
  const ecfmState = useSelector(state => state.functionalPlanningState.kpiDataPlanningCadence);
  const {data} = ecfmState;
  React.useEffect(() => {
    fillElements()
  }, [data])


  const fillElements = () => {
      console.log("--elements",elements)
      if(elements){
        setElementsArray(elements)
      }
  }

  return (
      <DroppableStyles className="dnd-cadence-board-child">
      <ColumnHeader className={`header-card title-${prefix}`}><span className="text-title">{`${prefix} (${elementsArray.length})` }</span></ColumnHeader>
      {/* <ColumnHeader className="total-card">Total Plans ({elementsArray.length})</ColumnHeader> */}
      <Droppable droppableId={`${prefix}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="list-item-card">
            {/* {elements.length > 0 && 
              elements.map((item, index) => (
                <ListItem key={item._id} item={item} index={index} handleOpen={handleOpen}/>
              ))
            } */}
            {elementsArray.length > 0 && 
              elementsArray.map((item, index) => (
                <ListItem key={item.id} item={item} index={index} handleOpen={handleOpen}/>
              ))
            }
          </div>
        )}
      </Droppable>
    </DroppableStyles>
  )

};

export default DraggableElement;
