import React from 'react';
import ReactTable from 'react-table-6';
import {withRouter} from 'react-router';
import 'react-table-6/react-table.css';
import IconButton from '@material-ui/core/IconButton';
import {connect} from 'react-redux';
import Icon from '@material-ui/core/Icon';
import {
	handleTableTransform,
	numberWithCommas,
	checkNan,
} from '../../../../utils/numberUtils';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from '../../Shared/InvoiceDetail';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PieChartComponent from '../PieCharts';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { fromUnixTime } from 'date-fns/esm';
import ReactTableComponent from './pureTable';
import NewReactPureTable from './pureNewTable';
import CommentModal from '../CommentModal';
import ChatCommentModal from '../../../Receivable/Ageing/ExternalComponent/CommentModal';
import {isSocketConnectionOpen, startWS} from '../../../../utils/web-socket';
import {getAllCommentHistoryData} from '../../../../utils/commentDataUtil';
import StackedBarChart from './StackedBarChart';

import NewPagination from "../../../common/components/CustomPagination";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class FunctionalTableComponent extends React.Component {
	globalIndex = 0;

	constructor(props) {
		super(props);
		this.showDrilldownHeaderValue = '';
		this.chatDataCollection = {};
		this.sortList = {};
		this.socketCollection = {};
		this.sortParam = null;
		this.levelMap = {
			PlTotal: 'P&L Total',
			PlGroup: 'P& L Group',
			GroupName2: 'Group Name2',
			GroupName: 'Group Name',
			GroupName3: 'Group Name3',
			AccountName: 'Account Name',
			CostCenter: 'Cost Centre2',
		};
		this.state = {
			loading: true,
			showModal: false,
			chatDataCollection : {},
			showCommentModal: false,
			manualAmountValue: '',
			commentRow: {},
			uploadImageObj: {},
			singleLineCommentObject:{},
			refMenuAnchorEl: null,
			plantDetailState: {},
			singleLineCommentValue: '',
			showInvoiceDetailModal: false,
			filterSelectorValue: [],
			arrayDataSelected: [],
			showLoader: false,
			showInvoiceLoader: false,
			manualClickData: {},
			invoiceObject: null,
			isExpandable: false,
			drilldownGroup: '',
			commentModal: false,
			showSingleLineComment: false,
			commentValue: '',
			invoiceDetailStateNew: '',
			selectedDrilldown: null,
			lastPayloadSent: null,
			isInvoiceDetailModal: false,
			refreshOnModalClose: false,
			isThreeLevelDrilldown: false
		};
		this.excelData = [];
	}

	handleColumnCellCreation = (cell, item) => {
		if (item.key == 'data_field') {
			let {
				row,
				cell: { value },
			} = cell;
			if (row.canExpand) {
				return (
					<span
						{...row.getToggleRowExpandedProps({
							onClick: () => {
								this.handleRowClick(row);
								row.toggleRowExpanded();
							},
						})}
					>
						<span className='unselectable'>
							{row.isExpanded ? (
								<IconButton aria-label='search'>
									<Icon>keyboard_arrow_down</Icon>
								</IconButton>
							) : (
								<IconButton aria-label='search'>
									<Icon>keyboard_arrow_right</Icon>
								</IconButton>
							)}
						</span>{' '}
						{value}
					</span>
				);
			} else {
				return (
					<span
						{...row.getToggleRowExpandedProps({
							onClick: () => {
								this.handleRowClick(row);
								row.toggleRowExpanded();
							},
						})}
					>
						{value}
					</span>
				);
			}
		}
		return cell.row.canExpand ? (
			<span style={{ fontWeight: 'bold' }}>{cell.value}</span>
		) : (
			cell.value
		);
	};

	setCommentModalData = () => {
		const {commentRow} = this.state;
		const {incomeStatementState} = this.props;
		let commentArrData = [];
		if (incomeStatementState.pivotRowData && incomeStatementState.pivotRowData.length) {
		  commentArrData = incomeStatementState.pivotRowData.filter(item => {
			if (item.channel == commentRow.channel) {
			  return item;
			}
		  });
		}
		return commentArrData && commentArrData[0];
	  };

	showCommentModal = (data) => {
		this.setState({
		  showCommentModal: true,
		  commentRow: data,
		});
	  };

	  handleChatUpdation = (gData) => {
		const {incomeStatementState, authState} = this.props;
		if (incomeStatementState.pivotRowData && incomeStatementState.pivotRowData.length) {
		  const rowDataSet = incomeStatementState.pivotRowData;
		  let indexData = -1;
		  let updatedRowDataSet = rowDataSet.map((item, index) => {
			if (!item['obj'][gData.chatData[0].id]) {
			  if (item.document_number == gData.invoice_no) {
				item['isChatConnection'] = true;
				indexData = index;
				item.commentArr.unshift(gData.chatData[0]);
				item['obj'][gData.chatData[0].id] = true;
			  }
			}
			return item;
		  });
		  if (indexData > -1) {
			if (updatedRowDataSet[indexData].commentArr &&
				updatedRowDataSet[indexData].commentArr.length) {
			  let obj = {};
			  let commentModifiedArr = [];
			  updatedRowDataSet[indexData].commentArr.forEach(item => {
				if (!obj[item.id]) {
					let usr = this.props.externalState.allUser.find(x=>{ return x.login_id == item.login_id });
					if(usr){
					  item.name = usr.full_name;
					}
				  commentModifiedArr.push(item);
				  obj[item.id] = true;
				}
			  });
			  updatedRowDataSet[indexData].commentArr = [
				...[],
				...commentModifiedArr];
			}
		  }
		  this.props.updateExternalData(updatedRowDataSet);
		}
	  };
	
	  handleConnectionClose = (channelId) => {
		if (this.socketCollection[channelId]) {
		  delete this.socketCollection[channelId];
		}
	  };

	  handleSocketConnectionError = (e) => {

		this.props.socketConnectionError(true);
	  };
	
	  establishSocketConnection = (data) => {
		if (!this.socketCollection[data.channel]) {
		  const socketInfo = startWS(
			  data.channel,
			  data.customer,
			  data.company_code,
			  data.project_id,
			  data.commentArr,
			  this.handleChatUpdation,
			  this.handleConnectionClose,
			  data.document_number,
			  this.handleSocketConnectionError,
		  );
		  this.socketCollection[data.channel] = socketInfo;
		}
	  };
	
	  handleSendChatAttachmentFunction = (attachmentData) => {
		const sendChatObject = {
		  'body': attachmentData.id,
		  'type': attachmentData.type,
		  'file_name': attachmentData.original_name,
		};
		this.props.uploadChatFileToggle();
		if (isSocketConnectionOpen(this.socketCollection[attachmentData.channel])) {
		  this.socketCollection[attachmentData.channel].send(
			  JSON.stringify(sendChatObject));
		}
	  };
	
	  handleSendChatFunction = (rowData) => {
		let sendChatObject = {};
		if (document.getElementById(`outlined-basic-${rowData.channel}`).value) {
		  sendChatObject = {
			'body': document.getElementById(
				`outlined-basic-${rowData.channel}`).value,
			'type': 'text',
			'tagged_user': this.taggedUser,
		  };
		  if (!isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
			delete this.socketCollection[rowData.channel];
			this.establishSocketConnection(rowData);
		  }
		  if (isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
			this.socketCollection[rowData.channel].send(
				JSON.stringify(sendChatObject));
			this.chatDataCollection[rowData.channel] = '';
			document.getElementById(`outlined-basic-${rowData.channel}`).value = '';
			this.taggedUser = [];
		  }
		}
	  };
	
	  handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
		const {chatDataCollection} = this.state;
		const sendChatObject = {
		  'body': inputValue,
		  'type': 'text',
		  'tagged_user': taggedUser,
		};
		if (inputValue) {
		  if (!isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
			delete this.socketCollection[rowData.channel];
			this.establishSocketConnection(rowData);
		  }
		  if (isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
			this.socketCollection[rowData.channel].send(
				JSON.stringify(sendChatObject));
			chatDataCollection[rowData.channel] = '';
			this.setState({'chatDataCollection': chatDataCollection});
		  }
		}
	  };
	
	  handleChatChangeFn = (event, rowData) => {
		this.chatDataCollection[rowData.channel] = event.target.value;
	
		if (event.target && event.target.value) {
		  const valueLen = event.target.value.length;
		  const targetValue = event.target.value;
		  if (valueLen && targetValue[valueLen - 1] == '@') {
			this.setState({
			  refMenuAnchorEl: event.currentTarget,
			  channelId: rowData.channel,
			  menuPositionX: event.currentTarget.getBoundingClientRect().left,
			  menuPositionY: event.currentTarget.getBoundingClientRect().top,
			});
		  }
		}
	  };
	
	  onImageInputHandler = (event, props) => {
		let imagePreview;
		if (event.target.files[0].type == 'application/pdf' ||
			event.target.files[0].type ==
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		  imagePreview = '';
		} else {
		  imagePreview = URL.createObjectURL(event.target.files[0]);
		}
		let imageName = event.target.files[0].name;
		const {uploadImageObj} = this.state;
		uploadImageObj[props.channel] = {
		  imagePreview,
		  imageName,
		  file: event.target.files[0],
		};
		this.setState({'uploadImageObj': uploadImageObj});
	  };
	
	  handleImageOptions = (event, props) => {
		const {uploadImageObj} = this.state;
		if (event === 'approve') {
		  const imageObj = uploadImageObj[props.channel];
		  if (imageObj) {
			const payloadData = {
			  file: imageObj.file,
			  id: props.channel,
			};
			this.props.uploadChatFile(payloadData);
		  }
		}
		if (event === 'cancel') {
		  this.imagePreview = null;
		}
		if (document.getElementById('upload-icon')) {
		  document.getElementById(`upload-icon`).value = '';
		}
		document.getElementById(`upload-icon${props.channel}`).value = '';
		delete uploadImageObj[props.channel];
		this.setState({'uploadImageObj': uploadImageObj});
	  };
	
	  handleActionOwnerChange = (payload) => {
	
		this.props.updateActionOwnerFn(payload);
	  };
	
	  handleRefMenuClose = (item) => {
	
		const {channelId} = this.state;
		this.taggedUser.push(item.login_id);
		document.getElementById(`outlined-basic-${channelId}`).value += item.name ||
			'';
	
		if (this.state.refMenuAnchorEl && this.state.refMenuAnchorEl.value) {
		  this.state.refMenuAnchorEl.value += item.name || '';
		  this.chatDataCollection[channelId] += item.name || '';
		}
		this.setState({refMenuAnchorEl: null}, () => {
		  document.getElementById(
			  `outlined-basic-${channelId}`).value += this.chatDataCollection[channelId] ||
			  '';
	
		});
	  };
	
	  handleRowClick = (rowData) => {
		if (rowData.original.channel) {
		  this.establishSocketConnection(rowData.original);
		  const commentPayload = {
			channelId: rowData.original.channel,
		  };
		  if (!this.socketCollection[rowData.original.channel]) {
			this.props.fetchOneCommentDataHistory(commentPayload);
		  }
		} else {
		  const createChannelPayload = {
	
			invoice_no: rowData.original.document_number,
		  };
		  this.props.createNewChannel(createChannelPayload);
		}
	  };

	  callGetAllCommentHistoryFunc = (activePage, rowData) => {
		const getCommentPayload = getAllCommentHistoryData(activePage, rowData);
		this.props.fetchCommentDataHistory(getCommentPayload);
	  };

	  setCommentModalData = () => {
		const {commentRow} = this.state;
		const {incomeStatementState} = this.props;
		let commentArrData = [];
		if (incomeStatementState.pivotRowData && incomeStatementState.pivotRowData.length) {
		  commentArrData = incomeStatementState.pivotRowData.filter(item => {
			if (item.channel == commentRow.channel) {
			  return item;
			}
		  });
		}
		return commentArrData && commentArrData[0];
	  };

	  modalCallBack = () => {
		this.setState({showCommentModal: false});
	  };



	changeKeysOfHeader1 = (data, type) => {
		if (data && data.length) {
			return data.map((item, i) => {
				return {
					id: item.key == 'data_field' ? 'expander' : '',
					Header: this.handleColumnHeaderCreation(item),
					accessor: item.key,
					Cell: (cell) => this.handleColumnCellCreation(cell, item),
				};
			});
		}
		return [];
	};

	handleDifferentBUResponse = (data, key) => {
		if (data) {
			if (key == 'data_field' || key == 'grand_total') {
				return data;
			} else {
				return data['header_key_value'] ? data['header_key_value'] : data;
			}
		}
		return data;
	};

	handleExcelDataChange = (data, header) => {
		this.props.handleExcelDataChange(data, header);
	};

	addSpaceLevel = (data, level) => {
		level = level * 4;
		let res = '';
		for (; level > -1; level--) {
			res += ' ';
		}
		return res + data;
	};

	handleTooltipData = (props) => {
		let { custom_level, levels } = props.original;
		let tooltipData = '';
		// if (levels && levels.length) {
		//     tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
		// }
		return tooltipData;
	};

	handleColumnHeaderTooltip = (item) => {
		let { metadata } = item;
		let tooltipData = <div>{item.label}</div>;
		if (metadata && metadata.plant_name) {
			tooltipData = (
				<div>
					<Tooltip
						title={
							<React.Fragment>
								<div style={{ textAlign: 'center' }}>Plant Details</div>
								<div>
									<span>{'Plant Name: '}</span>{' '}
									<span>{metadata && metadata.plant_name}</span>
								</div>
								<div>
									<span>{'Entity Name: '}</span>{' '}
									<span>{metadata && metadata.legal_entity_name}</span>
								</div>
							</React.Fragment>
						}
					>
						<span>{item.label}</span>
					</Tooltip>
				</div>
			);
		}
		return tooltipData;
	};

	dynamicHeaderValueOfColumn = (item, index) => {
		if (item && item.sub_headers && item.sub_headers.length) {
			let data = item.sub_headers[index]
				? item.sub_headers[index].label
				: item.label;
			return data;
		}
		return item.label;
	};

	dynamicHeaderKeyOfColumn = (item, index) => {
		if (item && item.sub_headers && item.sub_headers.length) {
			let data = item.sub_headers[index]
				? item.sub_headers[index].key
				: item.key;
			return data;
		}
		return item.key;
	};

	handleChatChangeFn = (event, rowData) => {
		if (event.target && event.target.value) {
			this.chatDataCollection[rowData.data_field] = event.target.value;
		}
	};

	handleChatSendFn = (rowData, groupName) => {
		let sendChatObject = {};
		if (
			document.getElementById(
				`outlined-basic-${groupName}-${rowData.original.data_field}`
			).value
		) {
			sendChatObject = {
				comment: document.getElementById(
					`outlined-basic-${groupName}-${rowData.original.data_field}`
				).value,
				commentRowId:
					rowData.original.data_field &&
					rowData.original.data_field.toLowerCase(),
				view: rowData.original.data_field,
				groupName: groupName,
				reportDate: rowData.original.current_month,
			};
			this.props.handleCommentSendFn(sendChatObject);
			this.chatDataCollection[rowData.original.data_field] = '';
			document.getElementById(
				`outlined-basic-${groupName}-${rowData.original.data_field}`
			).value = '';
		}
	};

	handleDGSAutomationClick = (rowData, subColumnLevel) => {
		let payload = {
			country: this.props.radioSelectorDynanicDGS,
			summary_acct: rowData.summary_acct,
			currency_code: rowData.currency_code,
			company_code: rowData.company_code,
			source: rowData.source,
			sub_tab_value: this.props.radioSelectorValueDGSAutomation,
			grid_header: subColumnLevel.key,
			grid_header_value: subColumnLevel.key,
		};
		// alert(this.props.radioSelectorValueDGSAutomation)
		this.props.handlePropsInvoiceClick(payload);
		// console.log(payload, "CheckDrillDown" )
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showLoader: true,
		});
	};
	handlePABValidationClick = (rowData, subColumnLevel, columnLevel) => {
		// console.log(rowData,subColumnLevel, columnLevel, "cekPabValidationParsing")
		let payload = {
			data_ra: rowData.data_ra,
			data_company_code: rowData.data_company_code,
			sub_tab_value: 'pab_validation',
			sub_grid_header: subColumnLevel.key,
			sub_grid_header_value: subColumnLevel.key,
			grid_header: columnLevel.key,
			grid_header_value: columnLevel.key,
		};

		// // console.log(subColumnLevel,columnLevel, "--iki lo")
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showLoader: true,
		});
		let mapping = payload.sub_grid_header_value;
		let header = '';
		if (
			mapping == 'revenue_sales_digital_solutions' ||
			mapping == 'revenue_margin_digital_solutions'
		) {
			header = 'Digital Solutions';
		}
		if (
			mapping == 'revenue_sales_digital_services' ||
			mapping == 'revenue_margin_digital_services'
		) {
			header = 'Digital Services';
		}
		if (
			mapping == 'revenue_sales_connected_products' ||
			mapping == 'revenue_margin_connected_products'
		) {
			header = 'Connected Products';
		}

		if (
			mapping == 'intercompany_sales_digital_solutions' ||
			mapping == 'intercompany_margin_digital_solutions'
		) {
			header = 'Digital Solutions';
		}
		if (
			mapping == 'intercompany_sales_digital_services' ||
			mapping == 'intercompany_margin_digital_services'
		) {
			header = 'Digital Services';
		}
		if (
			mapping == 'intercompany_sales_connected_products' ||
			mapping == 'intercompany_connected_products'
		) {
			header = 'Connected Products';
		}

		// this.setState({refreshOnModalClose : true})
		this.showDrilldownHeaderValue = `${columnLevel.label} - ${header}`;
		this.props.handlePropsInvoiceClick(payload);
	};

	expandIcon = (state, rowInfo, column) => {
		// console.log(rowInfo, "---apa ini gan")
		if (rowInfo === undefined) {
			return {};
		}
		return {
			'data-qnt': rowInfo.original.sub_rows ? 1 : 0,
		};
	};

	handleOptimusAutoClick = (rowData, subColumnLevel, columnLevel) => {
		let payload = {
			level_values: rowData.data_key,
			sub_tab_value: 'optimus_automation',
			sub_grid_header: subColumnLevel.key,
			sub_grid_header_value: subColumnLevel.key,
			grid_header: columnLevel.key,
			grid_header_value: columnLevel.key,
		};

		// console.log(subColumnLevel,columnLevel, "--iki lo")
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showLoader: true,
		});
		let mapping = payload.sub_grid_header_value;
		let header = '';
		if (mapping == 'digital_solutions') {
			header = 'Digital Solutions';
		}
		if (mapping == 'digital_services') {
			header = 'Digital Services';
		}
		if (mapping == 'connected_products') {
			header = 'Connected Products';
		}
		if (mapping == 'blank') {
			header = 'Blank';
			// this.props.handlePropsGetDropdown()
		}
		this.setState({ refreshOnModalClose: true });
		this.showDrilldownHeaderValue = `${columnLevel.label} - ${header} Mapping`;
		this.props.handlePropsInvoiceClick(payload);
	};

	//     handlePropsInvoiceClickOrigination = (rowData, subColumnLevel, columnLevel) => {
	//         console.log(rowData, subColumnLevel, columnLevel, "---ini click")

	//        let payload = {
	//            grid_header_value: subColumnLevel.key,
	//            grid_header: subColumnLevel.key,
	//            parent: rowData.parent,
	//            level_values: rowData.data_field
	//        }
	//        // console.log(payload, rowData, columnLevel.key, "checkDrilldown")
	//        let expandable = false
	//        let drilldownGroupBy = ''
	//        let col = columnLevel.key.toLowerCase()
	//        let subCol = subColumnLevel.key.toLowerCase()

	//        let levelforExpandable = ["3rd Party Sales","Total Sales","Standard Cost of Sales", "Standard Margin", "Intercompany Sales", "Intercompany Cost of Sales", "Margin", "Margin "]

	//        if(  levelforExpandable.indexOf(payload.level_values) > -1){
	//            expandable = true
	//        }

	//        this.setState({
	//            invoiceObject: payload,
	//            showLoader : true,
	//            clickedBU : columnLevel.key,
	//            drilldownGroup : drilldownGroupBy,
	//            isExpandable : expandable,
	//            isInvoiceDetailModal: this.props.radioSelectorValue == 1 || this.props.radioSelectorValue == 3 || this.props.radioSelectorValue == 5
	//        })

	//        this.props.handlePropsInvoiceClick(payload)
	//    }

	handleInvoiceClick = (rowData, subColumnLevel, columnLevel) => {
		//  console.log(rowData, subColumnLevel, columnLevel, rowData[columnLevel.key], "---ini click")
		// console.log("123123")
		let keyss = '';
		let keyBU = '';
		const {tabSelectorValue1, radioSelectorValue} = this.props;

		

		if (subColumnLevel.key == 'bu_p&l') {
			keyss = 'current';
			if (columnLevel.key == 'BU 1') {
				keyBU = 'msb';
			} else {
				keyBU = columnLevel.key;
			}
		} else {
			keyss = subColumnLevel.key;
			keyBU = columnLevel.key;
		}
		let level = rowData.data_field;
		let subGridHeader = keyss;

		if (
			this.props.tabSelectorValue == 3 &&
			this.props.radioSelectorReconValue == 2
		) {
			level = 'Total Variances & Other Cost of Sales';
			subGridHeader = 'current';
			keyss = 'current';
			if (columnLevel.key == 'BU 1') {
				keyBU = 'msb';
			} else {
				keyBU = columnLevel.key;
			}
			this.showDrilldownHeaderValue = `${level} - ${columnLevel.label} (${
				subColumnLevel.label
			}) - USD ${this.modifyColumnData(rowData[columnLevel.key])}`;
			if (columnLevel.key == 'sub_total') {
				keyss = 'grand_total_new';
				keyBU = 'grand_total_new';
			}
		} else if (this.props.NewChart == true) {
			this.showDrilldownHeaderValue = '';
			// console.log(rowData, subColumnLevel, columnLevel, "cekDrilldownTable")
			subGridHeader = rowData.so_item;
			level = 'Standard Margin';
		} else {
			if (
				(this.props.radioSelectorValue != 0 &&
					this.props.tabSelectorValue != -1) ||
				this.props.tabSelectorValue == 3
			) {
				let val = rowData.sub_column[columnLevel.key][subColumnLevel.key];
				
				this.showDrilldownHeaderValue = `${rowData.data_field} - ${
					columnLevel.label
				} (${subColumnLevel.label}) - USD ${this.modifyColumnData(val)}`;
			} else {
				this.showDrilldownHeaderValue = `${rowData.data_field} - ${
					columnLevel.label
				} (${subColumnLevel.label}) - USD ${this.modifyColumnData(
					rowData[columnLevel.key]
				)}`;
			}
		}
		// if( ){
		//     let val = rowData.sub_column[columnLevel.key][subColumnLevel.key]
		//         console.log( rowData.sub_column[columnLevel.key][subColumnLevel.key], "---ini click")
		//         this.showDrilldownHeaderValue = `${rowData.data_field} - ${columnLevel.label} (${subColumnLevel.label}) - USD ${this.modifyColumnData(val)}`

		// }
		if (
			this.props.radioSelectorValuePlant == 'BU 1' &&
			subColumnLevel.key == 'sub_total'
		) {
			if (
				rowData.data_field == 'Total Variances' ||
				rowData.data_field == 'Total Other Cost of Sales'
			) {
				level = 'Total Variances & Other Cost of Sales,' + rowData.data_field;
			} else {
				level =
					rowData.parents && rowData.parents.length
						? [...rowData.parents, rowData.data_field].join(',')
						: rowData.data_field;
			}
		}

		let payload = {
			grid_header_value: keyBU,
			grid_header: keyBU,
			sub_grid_header_value: subGridHeader,
			level_values: level,
			radioType: this.props.radioSelectorReconValue == "All"?this.props.radioSelectorReconValue : "",
			hemisphere:  this.props.radioSelectorReconValue == "West" || this.props.radioSelectorReconValue == "East"?this.props.radioSelectorReconValue : "",
			region:this.props.radioSelectorReconValue != "All" && this.props.radioSelectorReconValue != "West" && this.props.radioSelectorReconValue != "East"?this.props.radioSelectorReconValue : "",
			level: rowData.level == 3 ? rowData.parent : '',
			parent: rowData.level == 3 ? rowData.parent1 : rowData.parent,
		};

		if (rowData.data_value != undefined && rowData.data_value != '') {
			payload.level_values = rowData.data_value;
		}
		// console.log(payload, rowData, columnLevel.key, "checkDrilldown")
		let expandable = false;
		let drilldownGroupBy = '';
		let col = columnLevel.key.toLowerCase();
		let subCol = subColumnLevel.key.toLowerCase();

		let levelforExpandable = [
			'BU 1',
			'BU 2',
			'BU 3',
			'BU 4',
			'OH',
			'3rd Party Sales',
			'Total Sales',
			'Standard Cost of Sales',
			'Standard Margin',
			'Intercompany Sales',
			'Intercompany Cost of Sales',
			'Margin',
			'Margin ',
		];

		if (
			levelforExpandable.indexOf(payload.level_values) > -1 ||
			levelforExpandable.indexOf(payload.parent) > -1
		) {
			expandable = true;
		}
		let disableClick = rowData['disable_drill_down']
			? rowData['disable_drill_down']
			: false;
		if (disableClick == false) {
			this.setState({
				invoiceObject: payload,
				showLoader: true,
				clickedBU: columnLevel.key,
				drilldownGroup: drilldownGroupBy,
				isExpandable: expandable,
				isInvoiceDetailModal:
					this.props.radioSelectorValue == 1 ||
					this.props.radioSelectorValue == 3 ||
					this.props.radioSelectorValue == 5,
			});

			if (tabSelectorValue1 == 2) {
				if(radioSelectorValue == 1){
					payload = {
						country: rowData.country_name_origination,
					}
					this.showDrilldownHeaderValue = `Country - ${rowData.country_name_origination}`

				}else if(radioSelectorValue == 2){
					payload = {
						project_manager_name: rowData.project_manager_name,
					}
					this.showDrilldownHeaderValue = `Project Manager - ${rowData.project_manager_name}`

				}
			}

			this.props.handlePropsInvoiceClick(payload);
		}
	};

	handleAppendPercentage = (data) => {
		if (data && data['data_field']) {
			let dataField = data['data_field'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};

	handleFCFRedirect = (value) => {
		// window.localStorage.setItem('openNewTabFlag', value)
		// window.open('/dashboard/free-cashflow-analytics/free-cashflow', '_blank')
		if (value == '8') {
			window.open('/dashboard/receivables/dashboard-analytics/auto-distribution')
        }else if(value == '8') {
            window.open('/dashboard/initiatives/view')
        }else {
			window.open('/dashboard/initiatives/create')
		}
	}

	handleSubColumnChangeHeader = (data, parentData) => {
		// console.log(data, "testHere123")
		const {tabSelectorValue1, tabSelectorValue} = this.props
		if (data && data.length) {
			return data.map((item, i) => {

				if (item.key == 'auto_email') {
					return {
						Header: item.label,
						id: item.key,
						width: 80,
						accessor: item.key,
						Cell: (props) => {
							return (
								<div className="flex ai-center jc-space-evenly">
									{(
										<Tooltip title="Auto Emails">
											<Icon
												className="fs-20 primary"
												color={
													"inherit"
												}
												onClick={() => {this.handleFCFRedirect('8')}}
											>
												mail_outline
											</Icon>
									</Tooltip>
									)}
								</div>
							)
						},
						style: {
							fontSize: '12px',
							cursor: item.key == 'grand_total' ? '' : 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							whiteSpace: 'break-spaces',
							overflow: 'visible',
							fontSize: '13px',
							fontWeight: 'bold',
						}
					}
	
				}

				if (item.key == 'initiative') {
					return {
						Header: item.label,
						id: item.key,
						width: 80,
						accessor: item.key,
						Cell: (props) => {
							return (
								<div className="flex ai-center jc-space-evenly">
									{(
										<Tooltip title="View Initiative">
											<Icon
												className="fs-20 primary"
												color={
													"inherit"
												}
												onClick={() => {this.handleFCFRedirect('4')}}
											>
												remove_red_eye
											</Icon>
									</Tooltip>
									
									)}
									<Tooltip title={"Add Initiative"}>
										<Icon
											color={"inherit"}
											disabled
											onClick={() => {this.handleFCFRedirect('2')}}
										>
											add_circle_outline
										</Icon>
									</Tooltip>
								</div>
							)
						},
						style: {
							fontSize: '12px',
							cursor: item.key == 'grand_total' ? '' : 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							whiteSpace: 'break-spaces',
							overflow: 'visible',
						}
					}
	
				}

				if (item.key == 'comment') {
					return {
						Header: item.label,
						id: item.key,
						width: 80,
						accessor: item.key,
						Cell: (props) => {
							return (
								<div className="flex ai-center jc-space-evenly">
									{(
										<Tooltip title={"Comment"}>
											<Icon
												className="fs-20 primary"
												color={"inherit"}
												disabled
												onClick={() => {
													this.setState({
														showSingleLineComment: true,
														singleLineCommentObject: props.original,
														singleLineCommentValue: props.original?.comment?.comment || ''
													})
												}}
											>
												chat_bubble_filled
											</Icon>
										</Tooltip>
									)}
								</div>
							)
						},
						style: {
							fontSize: '12px',
							cursor: item.key == 'grand_total' ? '' : 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							whiteSpace: 'break-spaces',
							overflow: 'visible',
						}
					}
	
				}

				if (item.key == 'chat') {
					return {
						Header: item.label,
						id: item.key,
						width: 70,
						accessor: item.key,
						Cell: (props) => {
							return (
								<div className="flex ai-center jc-space-evenly">
									{(
										<Tooltip title={"Chat"}>
											<Icon
												className="fs-20 primary"
												color={"inherit"}
												disabled
												onClick={() => {
													this.showCommentModal(props.original)
													this.handleRowClick(props)
												}}
											>
												chat_bubble_outline
											</Icon>
										</Tooltip>
									)}
								</div>
							)
						},
						style: {
							fontSize: '12px',
							cursor: item.key == 'grand_total' ? '' : 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							whiteSpace: 'break-spaces',
							overflow: 'visible',
						}
					}
	
				}
				
				return {
					Header: (
						//<Tooltip title={this.handleSubHeaderTooltip(item)}>
						<>
							<span
								onClick={() => {
									if (
										item.key != 'sub_total' &&
										this.props.radioSelectorValue != 0 &&
										this.props.radioSelectorValue != 2 &&
										this.props.radioArrSelected == 'Default' &&
										this.props.radioArrCountrySelected == 'Default'
									) {
										// console.log(item, "checkSubHeaderClick2")

										this.setState(
											{
												isExpandable: false,
											},
											() => {
												this.props.handleSubHeaderClick(item, parentData);
											}
										);
									}
								}}
							>
								{item.label}
							</span>
							<div>{item.sub_label}</div>
							</>
						//</Tooltip>
					),
					columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
					id: item.key,
					Cell: (props) => {
						let isManualKey = false;
						let showPercentage = this.handleAppendPercentage(props.original);
						let myClassName = showPercentage ? 'percentageRowData' : '';
						let disableDrillDown = props.original['disable_drill_down'];
						myClassName +=
							disableDrillDown ||
							(this.props.tabSelectorValue == 3 &&
								this.props.radioSelectorReconValue != 7) ||
							item.key == 'sub_total' ||
							item.key == 'total' ||
							item.key == 'grand_total'
								? ''
								: ' enableDrillDown';
								
						let classNames = '';
						if (!props.original.disable_drill_down) {
							isManualKey = true;
						}
						if (isManualKey == true) {
							classNames = 'text-center pointer';
						} else {
							classNames = 'text-center';
						}

						if (item.key == 'project_manager_name' || item.key == 'customer') {
							classNames = 'text-left word-break';
						}

						if (tabSelectorValue1 == 2 || (tabSelectorValue1 == 0 && tabSelectorValue == 6)) {
							classNames = classNames + ' pointer'
						}

						return (
							<div
								className={classNames}
								onClick={(event) => {
									if (
										this.props.tabSelectorValue != 3 &&
										item.key != 'sub_total' &&
										item.key != 'total' &&
										!props.original['disable_drill_down']
									) {
										// console.log(item, "--item")
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (
										this.props.radioSelectorValuePlant == 'BU 1' &&
										item.key == 'sub_total'
									) {
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (
										this.props.tabSelectorValue == 3 &&
										props.original.data_field != 'Gross Margin' &&
										props.original.data_field.includes('%') != true
									) {
										// console.log(props.original, item, parentData, "itemzz")

										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (
										this.props.tabSelectorValue == 3 &&
										this.props.radioSelectorReconValue == 0 &&
										item.key == 'bu_p&l' &&
										props.original.data_field != 'Gross Margin'
									) {
										// console.log(props.original, item, parentData, "itemzz")

										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (
										this.props.tabSelectorValue == 3 &&
										this.props.radioSelectorReconValue == 0 &&
										props.original.data_field != 'Gross Margin' &&
										props.original.data_field.includes('%') != true
									) {
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (
										this.props.tabSelectorValue == 3 &&
										this.props.radioSelectorReconValue == 0 &&
										item.key == 'new' &&
										props.original.data_field != 'Gross Margin'
									) {
										// console.log(props.original, item, parentData, "itemzz")
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (this.props.radioSelectorReconValue == 5) {
										this.handleOptimusAutoClick(
											props.original,
											item,
											parentData
										);
									}
									if (this.props.radioSelectorReconValue == 6) {
										this.handlePABValidationClick(
											props.original,
											item,
											parentData
										);
									}
									if (this.props.radioSelectorReconValue == 7) {
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (this.props.tabSelectorValue == -1) {
										this.handleInvoiceClick(props.original, item, parentData);
									}
									if (props.original.isManual) {
										// console.log(item, "itemz")
										// console.log(props.original, "--props.original")
										this.setState({
											showModal: true,
											manualAmountValue:
												props.original.sub_column &&
												props.original.sub_column[parentData.key] &&
												props.original.sub_column[parentData.key][item.key]
													? props.original.sub_column[parentData.key][item.key]
													: 0,
											manualClickData: {
												group: props.original.group,
												key: item.key,
												data_field: props.original.data_field,
											},
										});
									}
								}}
							>
								{
									<span className={myClassName}>
										{props.original.sub_column &&
										props.original.sub_column[parentData.key] &&
										props.original.sub_column[parentData.key][item.key]
											?  item.operation ? this.modifyColumnData(
													props.original.sub_column[parentData.key][item.key],
													props.original.sub_column[parentData.key][
														'operations'
													], item.key
											  ) : props.original.sub_column[parentData.key][item.key]
											: '-'}
									</span>
								}
							</div>
						);
					},
					style: {
						fontSize: '12px',
						borderRight: i == data.length - 1 ? '1px solid #423970' : '',
						width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					},
					headerStyle: {
						overflow: 'visible',
						width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',

						borderRight: i == data.length - 1 ? '1px solid #423970' : '',
					},
				};
			});
		}
	};

	toggleCommentModal = (rowData) => {
		let visib = !this.state.commentModal;

		this.setState({
			commentModal: visib,
			commentValue: '',
			selectedDrilldown: rowData,
		});
	};

	toggleSingleLineComment = () => {
		let visib = !this.state.showSingleLineComment;

		this.setState({
			showSingleLineComment: visib,
		});
	};

	handleSingleCommentChange = (event) => {
		this.setState({ singleLineCommentValue: event.target.value });
	};

	handleCommentChange = (event) => {
		this.setState({ commentValue: event.target.value });
	};

	handleSingleLineCommentSubmit = () => {
		const {singleLineCommentValue, singleLineCommentObject} = this.state;
		let payload = {
			comment: singleLineCommentValue,
			comment_id: singleLineCommentObject.channel
		}
		this.props.saveNewBUFourTargetCommentFn(payload)
		this.toggleSingleLineComment()

	}

	handleCommentSubmit = () => {
		// console.log(this.state.selectedDrilldown, "--", this.state.commentValue, "-- 0101")
		let { selectedDrilldown } = this.state;
		let bu = selectedDrilldown.bu;
		let yearmonth = selectedDrilldown.yearmonth_new.split('T')[0];
		if (bu == 'BU 1') {
			bu = 'BU 1';
		}
		if (this.state.commentValue) {
			let commentPayload = {
				comment: this.state.commentValue,
				bu: bu,
				report_date: yearmonth,
				so_item: selectedDrilldown.so_item,
				project_id: selectedDrilldown.project_id,
				comment_id: selectedDrilldown.comment_id,
			};

			const { invoiceObject } = this.state;
			let drilldownPayload = {
				...invoiceObject,
			};

			let payload = {
				comment: commentPayload,
				drilldown: drilldownPayload,
			};
			// console.log(payload,"-- 010101")
			this.props.postCommentData(payload);
		}
	};
	changeKeysOfHeaderSubCol2 = (item, isNested) => {
		// console.log(item, item, 'cekKeyss2');
		// if (data && data.length) {

		//     return data.map((item, i) => {

		return {
			Header: (
				<Tooltip title={this.handleHeaderTooltip()}>
					<span
						onClick={() => {
							if (
								this.props.tabSelectorValue != 3 &&
								this.props.radioArrSelected == 'Default'
							) {
								if (this.props.radioSelectorValue == 3) {
									this.props.handleRegionHeaderClick(item);
								} else if (this.props.radioSelectorValue == 5) {
									this.props.handleHemisphereHeaderClick(item);
								}
							}
						}}
					>
						{item.label}
					</span>
				</Tooltip>
			),
			id: item.key,
			columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
			style: {
				borderRight: '1px solid #423970',
				cursor: this.showCursorPointerForHeader(),
			},
			headerStyle: {
				overflow: 'visible',
			},
		};
		// })
		// }
		return [];
	};
	changeKeysOfHeader2 = (data, isNested) => {
		if (data && data.length) {
			return data.map((item, i) => {
				// console.log(item.key, 'cekKeyss');
				item['operation'] =
					item.key == 'data_field' ||
					item.key == 'data_group' ||
					item.key == 'data_company_code' ||
					item.key == 'data_ra' ||
					item.key == 'so_item'
						? ''
						: 7;
				if (item.key == 'data_group') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								id: item.key,
								fixed: 'left',
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_group' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Grand Total'
											? 'totalLabel'
											: '';

									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'data_field') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								// Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'so_item') {
					return {
						Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
						id: item.key,
						metaData: item.metadata,
						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
						style: {
							textAlign: 'center',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (item.key == 'data_company_code') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_company_code' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Total' ? 'totalLabel' : '';
									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'data_ra') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_ra' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key.indexOf('pct_') > -1) {
					// console.log(item.sub_headers, item, 'test321');
					let label = item.label;
					// if(item.key == "pct_digital_services"){
					//     label = "DASS Services" +<br />+ "% of Revenue"
					// }
					return {
						Header: () => {
							let spl = item.label.split('%');
							if (spl[0] != '') {
								return (
									<span className={'multi-header'}>
										{spl[0]}
										<br />
										{' % ' + spl[1]}
									</span>
								);
							} else {
								return item.label;
							}
						},
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{
										<span>
											{props.original[item.key] != undefined
												? this.modifyColumnDataOCOSVal(
														props.original[item.key],
														'percentage'
												  )
												: '-'}
										</span>
									}
								</div>
							);
						},
					};
				} else if (
					item.key == 'company_code' ||
					item.key == 'country' ||
					item.key == 'classification' ||
					item.key == 'source' ||
					item.key == 'summary_acct' ||
					item.key == 'summary_acct_desc' ||
					item.key == 'currency_code'
				) {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
					};
				} else if (item.key == 'grand_total') {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							let showPercentage = this.handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							return (
								<div className='text-center'>
									{
										<span className={myClassName}>
											{props.original
												? this.modifyColumnData(
														props.original[item.key],
														props.original['operations']
												  )
												: '-'}
										</span>
									}
								</div>
							);
						},
						headerStyle: {
							overflow: 'visible',
							fontSize: '13px',
							fontWeight: 'bold',
						},
					};
				} else if (
					item.key == 'tran_amount' ||
					item.key == 'base_amount' ||
					item.key == 'base_amount_aop' ||
					item.key == 'tran_amount_aop'
				) {
					var isManualKey = true;
					// console.log(item.sub_headers, item, "test123123")
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							item['operation'] = 7;
							if (props.original.summary_acct == 'Total') {
								isManualKey = false;
							}
							
							return (
								<div
									className={`${
										isManualKey == true ? 'text-center pointer' : 'text-center'
									}`}
									onClick={(event) => {
										this.handleDGSAutomationClick(props.original, item);
									}}
								>
									{
										<span
											className={`${
												isManualKey == true ? 'enableDrillDown' : ''
											}`}
										>
											{props.original
												? this.modifyColumnData(props.original[item.key])
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							cursor: isManualKey == true ? 'pointer' : '',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (!item.sub_headers) {
					var isManualKey = false;

					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							if (
								this.props.tabSelectorValue == 3 &&
								this.props.radioSelectorReconValue == 2 &&
								props.original.disable_drill_down == false
							) {
								// console.log("masuksinigaes1234")
								isManualKey = true;
							}
							if (
								(this.props.tabSelectorValue == 0 &&
									this.props.radioSelectorReconValue == 0 &&
									this.props.radioSelectorValue == 0 &&
									props.original.disable_drill_down == false) ||
								props.original.disable_drill_down == undefined
							) {
								// console.log("masuksinigaes1234")
								isManualKey = true;
							}
							if (this.props.NewChart == true) {
								isManualKey = true;
							}
							if (this.props.tabSelectorValue == -1) {
								isManualKey = props.original['disable_drill_down']
									? props.original['disable_drill_down']
									: true;
							}
							if (props.original.disable_drill_down) {
								isManualKey = false;
							}
							let val =
								props.original[item.key] == undefined
									? 0
									: props.original[item.key];

							return (
								<div
									className={`${
										isManualKey == true ? 'text-center pointer' : 'text-center'
									}`}
									onClick={(event) => {
										this.handleInvoiceClick(props.original, item, item);
									}}
								>
									{
										<span
											className={`${
												isManualKey == true ? 'enableDrillDown' : ''
											}`}
										>
											{props.original
												? this.modifyColumnData(val, props.original.operations)
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							cursor: isManualKey == true ? 'pointer' : '',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				}

				return {
					Header: item.label,
					id: item.key,
					columns: this.changeKeysOfHeaderSubCol2(item, isNested),
					style: {
						borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						borderRight: '1px solid #423970',
					},
				};
			});
		}
		return [];
	};
	changeKeysOfHeader = (data, isNested) => {
		if (data && data.length) {
			return data.map((item, i) => {
				item['operation'] =
					item.key == 'data_field' ||
					item.key == 'data_group' ||
					item.key == 'data_company_code' ||
					item.key == 'data_ra' ||
					item.key == 'so_item' || item.key == 'project_id'
						? ''
						: 7;
				if (item.key == 'data_group') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								id: item.key,
								fixed: 'left',
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_group' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Grand Total'
											? 'totalLabel'
											: '';

									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'project_id' || item.key == 'project_manager_name' || item.key == 'country_name_origination') {
					return {
						Header: '',
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'data_field') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								// Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'so_item' || item.key == 'plant' || item.key == 'legal_entity'  ) {
					return {
						Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
						id: item.key,
						metaData: item.metadata,
						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
						style: {
							textAlign: 'center',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (item.key == 'data_company_code') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_company_code' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Total' ? 'totalLabel' : '';
									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'data_ra') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_ra' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key.indexOf('_pct') > -1) {
					let label = item.label;
					// if(item.key == "pct_digital_services"){
					//     label = "DASS Services" +<br />+ "% of Revenue"
					// }
					return {
						Header: () => {
							let spl = item.label.split('%');
							if (spl[0] != '') {
								return (
									<span className={'multi-header'}>
										{spl[0]}
										<br />
										{' % ' + spl[1]}
									</span>
								);
							} else {
								return item.label;
							}
						},
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{
										<span>
											{props.original[item.key] != undefined
												? this.modifyColumnDataOCOSVal(
														props.original[item.key],
														'percentage'
												  )
												: '-'}
										</span>
									}
								</div>
							);
						},
					};
				} else if (
					item.key == 'company_code' ||
					item.key == 'country' ||
					item.key == 'classification' ||
					item.key == 'source' ||
					item.key == 'summary_acct' ||
					item.key == 'summary_acct_desc' ||
					item.key == 'currency_code'
				) {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
					};
				} else if (item.key == 'grand_total') {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							let showPercentage = this.handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							return (
								<div className='text-center'>
									{
										<span className={myClassName}>
											{props.original
												? this.modifyColumnData(
														props.original[item.key],
														props.original['operations']
												  )
												: '-'}
										</span>
									}
								</div>
							);
						},
						headerStyle: {
							overflow: 'visible',
							fontSize: '13px',
							fontWeight: 'bold',
						},
					};
				} else if (
					item.key == 'tran_amount' ||
					item.key == 'base_amount' ||
					item.key == 'base_amount_aop' ||
					item.key == 'tran_amount_aop'
				) {
					var isManualKey = true;
					// console.log(item.sub_headers, item, "test123123")
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							item['operation'] = 7;
							if (props.original.summary_acct == 'Total') {
								isManualKey = false;
							}
							
							return (
								<div
									className={`${
										isManualKey == true ? 'text-center pointer' : 'text-center'
									}`}
									onClick={(event) => {
										
										this.handleDGSAutomationClick(props.original, item);
									}}
								>
									{
										<span
											className={`${
												isManualKey == true ? 'enableDrillDown' : ''
											}`}
										>
											{props.original
												? this.modifyColumnData(props.original[item.key])
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							cursor: isManualKey == true ? 'pointer' : '',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (!item.sub_headers) {
					// var isManualKey = false;

					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							var isManualKey = false;
							
							if (!props.original.disable_drill_down) {
								isManualKey = true;
							}
							let val =
								props.original[item.key] == undefined
									? 0
									: props.original[item.key];

							return (
								<div
									className={`${
										isManualKey == true ? 'text-center pointer' : 'text-center'
									}`}
									onClick={(event) => {
										this.handleInvoiceClick(props.original, item, item);
									}}
								>
									{
										<span
											className={`${
												isManualKey == true ? 'enableDrillDown' : ''
											}`}
										>
											{props.original
												?  this.modifyNewColumnData(val, props.original.operations)
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							// cursor: isManualKey == true ? 'pointer' : '',
							// cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				}


				return {
					Header: (
						<Tooltip title={this.handleHeaderTooltip()}>
							<span
								onClick={() => {
									if (
										this.props.tabSelectorValue != 3 &&
										this.props.radioArrSelected == 'Default'
									) {
										if (this.props.radioSelectorValue == 3) {
											this.props.handleRegionHeaderClick(item);
										} else if (this.props.radioSelectorValue == 5) {
											this.props.handleHemisphereHeaderClick(item);
										}
									}
								}}
							>
								{item.label}
							</span>
						</Tooltip>
					),
					id: item.key,
					columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
					style: {
						borderRight: '1px solid #423970',
						cursor: this.showCursorPointerForHeader(),
					},
					headerStyle: {
						overflow: 'visible',
					},
				};
			});
		}
		return [];
	};

	showCursorPointerForHeader = () => {
		if (
			this.props.tabSelectorValue != 3 &&
			(this.props.radioSelectorValue == 3 || this.props.radioSelectorValue == 5)
		) {
			return 'pointer';
		}

		return '';
	};

	handleHeaderTooltip = () => {
		if (this.props.tabSelectorValue != 3) {
			if (
				this.props.radioSelectorValue == 3 &&
				this.props.radioArrSelected == 'Default'
			) {
				return 'Click here for the country view';
			} else if (
				this.props.radioSelectorValue == 5 &&
				this.props.radioArrSelected == 'Default'
			) {
				return 'Click here for the region view';
			}
		}

		return '';
	};
	handleSubHeaderTooltip = (data) => {
		
		if (
			this.props.radioSelectorValue != 0 &&
			this.props.radioSelectorValue != 2 &&
			data.key != 'sub_total' &&
			this.props.radioArrSelected == 'Default' &&
			this.props.radioArrCountrySelected == 'Default'
		) {
			return 'Click here for detail';
		}

		return '';
	};
	checkForNestedLevel = (data) => {
		if (data && data.length) {
			let isFlag = false;
			data.forEach((item) => {
				if (item.sub_rows && item.sub_rows.length) {
					isFlag = true;
				}
			});
			return isFlag;
		}
		return false;
	};

	checkLevel = (data, level) => {
		if (data && data.length) {
			data.forEach((item) => {
				if (level == undefined) {
					item.custom_level = 0;
				} else {
					item.custom_level = level + 1;
				}
			});
		}
		return data;
	};

	subComponent = (row) => {
		let { pivotHeaderData } = this.props.incomeStatementState;
		let nestedFlag = this.checkForNestedLevel(row.original.sub_rows);
		pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
		if (this.props.tabSelectorValue == -1) {
			pivotHeaderData = this.changeKeysOfHeader2(pivotHeaderData, nestedFlag);
		}
		const subRowsLength = row.original.sub_rows && row.original.sub_rows.length;
		let pivotRowData = this.checkLevel(
			row.original.sub_rows,
			row.original.custom_level
		);

		return (
			<>
				{subRowsLength ? (
					<ReactTable
						data={pivotRowData}
						className='bupl-SubTable xpna-react-table'
						TheadComponent={(props) => null}
						columns={pivotHeaderData}
						defaultPageSize={subRowsLength}
						pageSize={subRowsLength}
						SubComponent={this.subComponent}
						showPagination={false}
						getTrProps={this.expandIcon}
						sortable={false}
						getTdProps={this.rowClick}
					/>
				) : null}
			</>
		);
	};

	handleColumnHeaderClick = (colInfo) => {
		if (colInfo.id && colInfo.id != 'grand_total') {
			this.props.handleRowHeaderClick(colInfo);
		}
	};

	columnHeaderClick = (state, someData, colInfo) => {
		const { showCursor } = this.props;
		if (showCursor) {
			return {
				onClick: (e) => {
					this.handleColumnHeaderClick(colInfo);
				},
				style: { cursor: 'pointer' },
			};
		}
		return {};
	};

	handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
		// console.log("check123")
		// console.log(state, colInfo, columnDetail, etc, event.target.value)
		const { data_field, parents } = colInfo.original;
		const { id, headerTitle } = columnDetail;
		const { legalEntitySelectorValue, plantSelectorValue } = this.props;

		let dataVal =
			parents && parents.length
				? [...parents, data_field].join(',')
				: data_field;
		if (headerTitle) {
			this.setState({
				showLoader: true,
				invoiceObject: {
					grid_header: id == 'grand_total' ? '' : id,
					level_values: dataVal,
				},
			});
			this.props.getInvoiceDetail({
				legal_entity: legalEntitySelectorValue,
				plant: plantSelectorValue,
				grid_header: id == 'grand_total' ? '' : id,
				level_values: dataVal,
			});
			this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${checkNan(
				colInfo.row[columnDetail.id]
			)}`;
		}
	};

	rowClick = (state, rowInfo, column, instance) => {
		return {
			onClick: (e, handleOriginal) => {
				// this.handleColumnClick(state, rowInfo, column, instance, e)
				if (column.expander) {
					let { pivotRowData, pivotHeaderData, loading } =
						this.props.incomeStatementState;
					let nestedFlag = this.checkForNestedLevel(pivotRowData);

					if (this.props.tabSelectorValue == -1) {
						pivotHeaderData = this.changeKeysOfHeader(
							pivotHeaderData,
							nestedFlag
						);
					} else {
						pivotHeaderData = this.changeKeysOfHeader(
							pivotHeaderData,
							nestedFlag
						);
					}
					this.addExcelData &&
						this.addExcelData(rowInfo.original, pivotHeaderData);
					// console.log(rowInfo,"cek")
					handleOriginal();
				}
			},
		};
	};

	modifyColumnData = (columnData, operations, itemKey) => {
		// console.log(columnData,operations, "--ini props")

		let modifiedData;
		modifiedData = Math.round(columnData);
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage' || itemKey?.includes('_pct')) {
			modifiedData += '%';
		}

		return modifiedData;
	};

	modifyNewColumnData = (columnData, operations) => {
		// console.log(columnData,operations, "--ini props")

		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};
	modifyColumnDataOCOSVal = (columnData, operations) => {
		let modifiedData;
		// console.log(columnData, "checkPCT")
		modifiedData = columnData.toFixed(1);
		// modifiedData = modifiedData.toFixed(1)
		// var isNegative = modifiedData < 0
		// var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
		// modifiedData = numberWithCommas(positiveTransformedValue)
		// modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = modifiedData == '0.0' ? '-' : modifiedData;
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;

		if (operations == 'percentage' && modifiedData != '-') {
			modifiedData += '%';
		}

		return modifiedData;
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.incomeStatementState.invoiceLoading &&
			!this.props.incomeStatementState.invoiceLoading
		) {
			this.setState({
				invoiceDetailStateNew:
					this.props.incomeStatementState.invoiceDetailState,
				showInvoiceDetailModal: true,
				showLoader: false,
				showInvoiceLoader: false
			});
		}
		if (
			prevProps.incomeStatementState.chartModalLoading &&
			!this.props.incomeStatementState.chartModalLoading
		) {
			this.setState({
				showLoader: false,
			});
		}
		if (
			prevProps.incomeStatementState.NewCommentLoader &&
			!this.props.incomeStatementState.NewCommentLoader
		) {
			const { invoiceObject } = this.state;
			let payload = { ...invoiceObject };
			payload['sort'] = this.sortParam;
			this.props.handlePropsInvoiceClick(payload);
			this.toggleCommentModal();
		}

		if (
			prevProps.incomeStatementState.NewOriginationCommentLoader &&
			!this.props.incomeStatementState.NewOriginationCommentLoader
		) {
			this.handleAfterCommentSend(this.props.incomeStatementState.NewOriginationCommentData)
		}

		if (
			prevProps.incomeStatementState.NewManualLoader &&
			!this.props.incomeStatementState.NewManualLoader
		) {
			this.setState({
				showModal: false,
			});
		}


		const { dayReportState, chatStoreState, commentModalDisplay, commentModalData } = this.props;
		if (dayReportState && dayReportState.createChannel) {
			this.establishSocketConnection(dayReportState.newRowItem);
			this.props.updateChannelFlag(false);
		}

		if (chatStoreState.uploadFile) {
			this.handleSendChatAttachmentFunction(chatStoreState.data);
		}

		if (!prevProps.commentModalDisplay && commentModalDisplay) {
			this.showCommentModal(commentModalData)
		}

	}

	componentDidMount() {}

	invoiceModalCallBack = () => {
		if (this.state.refreshOnModalClose) {
			this.props.fetchMainData();
		}

		this.setState({
			arrayDataSelected: [],
			showInvoiceDetailModal: false,
			isInvoiceDetailModal: false,
			refreshOnModalClose: false,
			isThreeLevelDrilldown: false
		});
	};
	handleCountryChanges = (data) => {
		let dataGrid = this.state.invoiceDetailStateNew;
		let arrayData = this.state.arrayDataSelected;
		dataGrid.row_data[data.index]['country_name_new'] = data.country_new;

		let sameID = false;
		arrayData.map((item, i) => {
			if (item['_id'] == dataGrid.row_data[data.index]['_id']) {
				sameID = true;
			}
		});
		if (sameID == true) {
			arrayData.map((item, i) => {
				if (item['_id'] == dataGrid.row_data[data.index]['_id']) {
					return dataGrid.row_data[data.index];
				} else {
					return item;
				}
			});
		} else {
			arrayData.push(dataGrid.row_data[data.index]);
		}

		this.setState({
			invoiceDetailStateNew: dataGrid,
			arrayDataSelected: arrayData,
		});
	};
	handleClassificationChanges = (data) => {
		let dataGrid = this.state.invoiceDetailStateNew;
		let arrayData = this.state.arrayDataSelected;
		dataGrid.row_data[data.index]['final_mapping_new'] = data.final_mapping_new;

		let sameID = false;
		arrayData.map((item, i) => {
			if (item['_id'] == dataGrid.row_data[data.index]['_id']) {
				sameID = true;
			}
		});
		if (sameID == true) {
			arrayData.map((item, i) => {
				if (item['_id'] == dataGrid.row_data[data.index]['_id']) {
					return dataGrid.row_data[data.index];
				} else {
					return item;
				}
			});
		} else {
			arrayData.push(dataGrid.row_data[data.index]);
		}

		this.setState({
			invoiceDetailStateNew: dataGrid,
			arrayDataSelected: arrayData,
		});
	};
	handleSaveChanges = (data) => {
		let dataNew = this.state.arrayDataSelected;
		this.setState({ arrayDataSelected: [], showLoader: true });
		this.props.handleSaveDrilldownChanges(dataNew);
		// let payload = this.state.invoiceObject
		// this.props.handlePropsInvoiceClick(payload)
	};
	handleInvoiceFilterChange = (value) => {
		const { invoiceObject } = this.state;
		let payload = {
			...invoiceObject,
			filter_amount: value,
		};
		this.setState({
			invoiceObject: payload,
		});
		this.props.handlePropsInvoiceClick(payload);
	};

	handleClose = () => {
		this.setState({ showModal: false });
	};

	handleValueOnChange = (event) => {
		this.setState({ manualAmountValue: event.target.value.replace(/,/g, '') });
	};

	handleSubmit = () => {
		// console.log('submit=====>')
		const { manualAmountValue, manualClickData } = this.state;
		let payload = {
			...manualClickData,
			value: parseFloat(manualAmountValue),
		};

		this.props.postManualAmountData(payload);
		this.handleClose();
	};

	handleInvoiceSorting = (columnId) => {
		const { invoiceObject, isThreeLevelDrilldown } = this.state;
		this.sortList[columnId] =
			this.sortList[columnId] != undefined ? !this.sortList[columnId] : false;
		let payload = {
			...invoiceObject,
		};
		payload['sort'] = {
			key: columnId,
			desc: this.sortList[columnId],
		};
		this.sortParam = payload['sort'];

		if (isThreeLevelDrilldown){
			this.props.handlePropsThreeLevelInvoiceClick(payload)
		}else {
			this.props.handlePropsInvoiceClick(payload);

		}

	};

	customGroup = (data) => {
		let idxData = {};
		data.forEach((x, idx) => {
			if (idxData[x[this.state.drilldownGroup]] == undefined)
				idxData[x[this.state.drilldownGroup]] = idx;
		});

		let obj = data.reduce((result, currentValue) => {
			(result[currentValue[this.state.drilldownGroup]] =
				result[currentValue[this.state.drilldownGroup]] || []).push(
				currentValue
			);
			return result;
		}, {});

		return { grouped: obj, index: idxData };
	};

	handleAfterCommentSend = (NewOriginationCommentData) => {
		const {selectedCommentRowData, selectedCommentItem, selectedCommentParent} = this.state;
		let updatedCommentData = selectedCommentRowData

		// const commentData = selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key]
		if (selectedCommentRowData && 
			selectedCommentRowData.sub_column && 
			selectedCommentRowData.sub_column[selectedCommentParent.key] && 
			selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'] && 
			selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key]) {
				let commentsArr = selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key].comments;
				// console.log(commentsArr, NewOriginationCommentData, 'updatedCommentDataupdatedCommentData', updatedCommentData)

				if (commentsArr && commentsArr.length) {
					commentsArr.unshift(NewOriginationCommentData)
				}else {
					commentsArr = [NewOriginationCommentData]
				}
				updatedCommentData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key].comments = commentsArr
				this.setState({
					selectedCommentRowData: updatedCommentData
				})
		}
	}

    handleCommentShowClick = (rowData, parentData, item, parentIndex, parent2Index) => {
        this.toggleCommentModal(rowData)
		// const commentData = rowData.sub_column[parentData.key]['sub_column'][item.key]

        this.setState({
            commentModal: true,
            selectedCommentRowData: rowData,
            selectedCommentItem: item,
			selectedCommentParent:parentData
        })
    }

    handleSendCommmentCallback = (inputValue) => {
        const {selectedCommentRowData, selectedCommentItem, selectedCommentParent} = this.state;

		// // const commentData = selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key]
		// if (selectedCommentRowData && 
		// 	selectedCommentRowData.sub_column && 
		// 	selectedCommentRowData.sub_column[selectedCommentParent.key] && 
		// 	selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'] && 
		// 	selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key]) {
		// 		let commentsArr = selectedCommentRowData.sub_column[selectedCommentParent.key]['sub_column'][selectedCommentItem.key].comments;
		// 		commentsArr = commentsArr ? commentsArr.push() : []

		// }
        const payload = {
			comment: inputValue,
            // report_date: "31 Oct 2021",
            comment_type: selectedCommentItem.key, 
            data_first : selectedCommentRowData.data_first, 
            data_second : selectedCommentRowData.data_second, 
            data_third : selectedCommentRowData.data_third
        }

        this.props.postOSOriginationCommentData(payload)
    }

	handleThreeLevelDrilldownClick = (rowData, parentData, subParentData, item ) => {
		// console.log(rowData, parentData, subParentData, item)
		let payload = {
			"origination": "true", //follow main grid param logic
			"mpr": "true", //follow main grid param logic
			"grid_header_value": parentData.label, // header.label
			"sub_grid_header_value": subParentData.label, // header.label
			"sub_sub_grid_header_value": item.label, // header.label
			"level_value": rowData.data_value == "CA" ? "CAN" : rowData.data_value, //data_value
			"parent": rowData.parent, //parent
			"parent_one": rowData.parent1 //parent1

		}

		this.props.handlePropsThreeLevelInvoiceClick(payload);
		this.showDrilldownHeaderValue = `${payload.grid_header_value}`

		if (payload.sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_grid_header_value}`
		}
		if (payload.sub_sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_sub_grid_header_value}`
		}
		if (payload.level_value) {
			this.showDrilldownHeaderValue += ` - ${payload.level_value}`
		}
		// console.log(payload, "CheckDrillDown" )
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showInvoiceLoader: true,
			isThreeLevelDrilldown: true
		});
	}

	newChangeHeaderKeysLevel2 = (data, parentData, level1Index) => {
		if (data && data.length > 0) {
			return data.map((item, level2Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + '_' + parentData.key,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = this.newChangeHeaderKeysLevel3(
						item.sub_headers,
						parentData,
						item
					);
				} else {
					// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

					tempItemData.Cell = (props) => {
						const { row } = props;
						// if (item.key === "data_field") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else {
						// return <span>{ 'pp'}</span>
						if (parentData.key.includes('comment')) {
							const commentData = row.original && 
							row.original.sub_column && 
							row.original.sub_column[parentData.key] && 
							row.original.sub_column[parentData.key]['sub_column'] && 
							row.original.sub_column[parentData.key]['sub_column'][item.key]
								return (
									<div 
										style={{'color': '#015ABB'}}
										onClick={() => {this.handleCommentShowClick(row.original, parentData, item, level1Index, level2Index)}}>
										<Icon>remove_red_eye</Icon>
										<Icon>add_circle_outline</Icon>
									</div>
								);
							
						}

						return (
							<span>
								{row.original && row.original.sub_column && row.original.sub_column[parentData.key] && row.original.sub_column[parentData.key]['sub_column']
									? 
									row.original.sub_column[parentData.key]['sub_column'][item.key]
									: ''}
							</span>
						);
						// }
					};
				}

				// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

	newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
		if (data && data.length > 0) {
			return data.map((item, i) => {
				const tempItemData = {
					Header: item.label,
					id:
						item.key +
						'_' +
						parentData.key +
						'_' +
						subParentData.key +
						Math.random('92129911') +
						new Date().getTime(),
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				tempItemData.Cell = (props) => {
					const { row } = props;
					let showPercentage = this.handleAppendPercentage(row.original);
					let myClassName = showPercentage ? 'percentageRowData' : '';
					let showBorder = false
					let showDrillDown = false
					if ( subParentData?.key == 'total' && item.key == 'total'){
						showBorder = true
					}

					// if (item.key === "data_field") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else {
					// return <span>{'lol'}</span>
					if (!row.original.disable_drill_down) {
						showDrillDown = true;
					}
					// console.log('row.originalrow.original', row.original, parentData, subParentData, item)
					return (
						<div 
						className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{borderRight: showBorder && '1px solid #423970', lineHeight: '40px'}}
						onClick={()=> {
							if (showDrillDown){
								this.handleThreeLevelDrilldownClick(row.original, parentData, subParentData, item)
							}
						}}
						>
							{
								<span className={myClassName}>
									{row.original &&
									row.original.sub_column &&
									row.original.sub_column[parentData.key] &&
									row.original.sub_column[parentData.key]['sub_column'] &&
									row.original.sub_column[parentData.key]['sub_column'][
										subParentData.key
									]
										? this.modifyNewColumnData(
												row.original.sub_column[parentData.key]['sub_column'][
													subParentData.key
												][item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							}
						</div>
					);
					// }
				};

				// tempItemData.Cell = ({row}) =>  <span>{row.original ? row.original.subColumn[parentData.key][subParentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

	newChangeKeysOfHeader = (data) => {
		if (data && data.length > 0) {
			return data.map((item, level1Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + this.globalIndex,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				this.globalIndex += 1;

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = this.newChangeHeaderKeysLevel2(
						item.sub_headers,
						item,
						level1Index
					);
				} else {
					if (item.key === 'data_field') {
						tempItemData.sticky = 'left';
					}
					tempItemData.Cell = (props) => {
						const { row } = props;
						if (item.key === 'data_field') {
							return row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									<span
										className={
											row.isExpanded
												? 'rt-expanded-class'
												: 'rt-collapsed-class'
										}
									></span>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							) : (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							);

							// <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						} else if (
							item.key.includes('comment')) {
							return (
								<div 
                                style={{'color': '#c0b6ed' }}
                                onClick={() => {
                                        this.handleCommentShowClick(row.original, item)
                                }}>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</div>
							);
						} else {
							return (
								<span>
									{row && row.original && row.original[item.key]
										? this.modifyNewColumnData(
												row.original[item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							);
						}
					};
				}

				return tempItemData;
			});
		} else return [];
	};

	newChangeKeysOfRow = (data) => {
		// return data
		if (data && data.length > 0) {
			return data.map((item, i) => {
				if (item.sub_rows) {
					item['subRows'] = item.sub_rows;

					this.newChangeKeysOfRow(item.sub_rows);

				} else {
					item['subRows'] = [];
				}
				return item;
			});
		} else return [];
	};

	handleDownloadFromBECallback = () => {
		this.props.handleBUFourDrilldownData(true)
	}

	render() {
		let {
			invoiceDetailStateFilter,
			pivotRowData,
			pivotRowData2,
			pivotRowDataOCOS,
			pivotHeaderData,
			pivotHeaderData2,
			pivotHeaderDataOCOS,
			loading,
			invoiceDetailState,
			invoiceLoading,
			NewLoader,
			NewPieData,
			NewCommentLoader,
			header,
			sub_header,
		} = this.props.incomeStatementState;
		const {
			authState,
			graphView
		  } = this.props;
		const menus = authState.menuData;

		let { isExpandable, isInvoiceDetailModal, invoiceDetailStateNew } =
			this.state;
		let nestedFlag = this.checkForNestedLevel(pivotRowData);
		const newPivotHeaderData = this.newChangeKeysOfHeader(pivotHeaderData);
		let newPivotRowData = [];
		if (this.props.monthSelected) {
			newPivotRowData = this.newChangeKeysOfRow(pivotRowData);
			// console.log('newPivotRowDatanewPivotRowData', newPivotRowData);
		}

		// pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
		
			pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);

		pivotHeaderDataOCOS = this.changeKeysOfHeader(
			pivotHeaderDataOCOS,
			nestedFlag
		);

		let nestedFlag2 = this.checkForNestedLevel(pivotRowData2);
		pivotHeaderData2 = this.changeKeysOfHeader(pivotHeaderData2, nestedFlag2);
		const commentModalData = this.setCommentModalData();

		const { showInvoiceDetailModal, showLoader,showInvoiceLoader, selectedCommentRowData, selectedCommentItem, selectedCommentParent, showCommentModal, commentRow, uploadImageObj } = this.state;
		const {
			tabSelectorValue,
			radioSelectorValue,
			radioSelectorReconValue,
			radioSelectorValuePlant,
			radioArrSelected,
			radioArrCountrySelected,
			radioSelectorValueDGSAutomation,
			tabSelectorValue1,
		} = this.props;
		let tableLen = (pivotRowData && pivotRowData.length) || 6;
		pivotRowData = this.checkLevel(pivotRowData);

		let tableLen2 = (pivotRowDataOCOS && pivotRowDataOCOS.length) || 6;
		pivotRowDataOCOS = this.checkLevel(pivotRowDataOCOS);

		let tableLen3 = (pivotRowData2 && pivotRowData2.length) || 6;
		pivotRowData2 = this.checkLevel(pivotRowData2);
		// let newDetailState = JSON.parse(JSON.stringify(invoiceDetailState))
		// if(this.state.drilldownGroup != "" && newDetailState.row_data != undefined){
		//     // console.log(newDetailState, "---- first")
		//     newDetailState.row_data = this.buildSubRowData(newDetailState.row_data)
		//     // console.log(newDetailState.row_data, "---- second")
		// }

		const body = (
			<div className='customModal forecastModal'>
				<div className='header'>
					<h2>Add Manual Amount</h2>
					<Icon onClick={this.handleClose}>close</Icon>
				</div>
				<div className='modal-content'>
					<form>
						<FormControl className='w-50 mb-20 flex'>
							<TextField
								label='Amount'
								value={this.state.manualAmountValue}
								placeholder={'Amount'}
								onChange={this.handleValueOnChange}
							/>
						</FormControl>
					</form>
					<Grid container justify='flex-end' className='mt-20'>
						<Button
							variant='contained'
							color='primary'
							className='mr-10'
							onClick={this.handleSubmit}
						>
							Submit
						</Button>
						<Button variant='contained' onClick={this.handleClose}>
							Cancel
						</Button>
					</Grid>
				</div>
			</div>
		);

		const modalComments = (
			<Modal
				open={this.state.showSingleLineComment}
				onClose={this.toggleSingleLineComment}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={this.state.showSingleLineComment}>
					<div className='customModalSingleLine'>
						{NewCommentLoader && (
							<div className='progressfullpage'>
								<CircularProgress />
							</div>
						)}
						<div className={'header'}>
							<h2>Add Comment</h2>
							<Icon onClick={this.toggleSingleLineComment}>close</Icon>
						</div>
						<div className={'modal-content'} style={{ padding: '30px' }}>
							<TextField
								fullWidth
								label='Comment'
								multiline
								rows={10}
								value={this.state.singleLineCommentValue}
								onChange={this.handleSingleCommentChange}
								variant='filled'
							/>
							<div className='flex jc-flex-end mt-30'>
								<Button
									variant='contained'
									color='primary'
									onClick={this.handleSingleLineCommentSubmit}
								>
									Submit
								</Button>
								<Button
									variant='contained'
									className='ml-16'
									onClick={this.toggleSingleLineComment}
								>
									Cancel
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);
		return (
			<>
			{showInvoiceLoader && <div className='progressfullpage'>
						<CircularProgress />
					</div>}
				{(showLoader || loading) ? (
					<div className='progressfullpage'>
						<CircularProgress />
					</div>
				) :

				(this.props.monthSelected && (this.props.tabSelectorValue1 == 1 || this.props.tabSelectorValue1 == -1)) ? (
					newPivotHeaderData && newPivotHeaderData.length ? 
					<NewReactPureTable
						pivotRowData={newPivotRowData}
						className='xpna-pure-table fixedFirstColumTableSummaryView expandableRows '
						loading={loading}
						pivotHeaderData={newPivotHeaderData}
						//  tableLen={tableLen}
						//  subComponent={this.subComponent}
						//  expandIcon={this.expandIcon}
						//  rowClick={this.rowClick}
						//  showInvoiceDetailModal={showInvoiceDetailModal}
						showLoader={showLoader}
					/> : 	<div className='progressfullpage'>
								<CircularProgress />
							</div>
				) : (
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
						>
							{(tabSelectorValue1 == 2 || (tabSelectorValue1 == 0 && tabSelectorValue == 6)) ? 
							graphView ? 
							
							<div className=''>
								<ReactTable
								columns={pivotHeaderData}
								data={pivotRowData}
								defaultPageSize={10}
								pageSize={10}
								loading={loading}
								className="xpna-react-table -striped -highlight total-sales-report"
								// PaginationComponent={Pagination}
								sortable={false}
							/>
							</div> : 
							<StackedBarChart />  : 
							radioSelectorReconValue == 4 ? (
								<ReactTable
									data={pivotRowData}
									className={
										'xpna-react-table -striped -highlight noFixtColumTable'
									}
									loading={loading}
									columns={pivotHeaderData}
									defaultPageSize={7}
									pageSize={pivotRowData.length}
									showPagination={false}
									sortable={false}
								/>
							) : radioSelectorReconValue == 2 &&
							  pivotRowDataOCOS != undefined ? (
								<Grid item xs='12'>
									<ReactTable
										data={pivotRowDataOCOS}
										className={
											'xpna-react-table -striped -highlight'
										}
										loading={loading}
										columns={pivotHeaderDataOCOS}
										defaultPageSize={7}
										pageSize={pivotRowDataOCOS.length}
										showPagination={false}
										sortable={false}
									/>
									<ReactTable
										data={pivotRowData}
										className={
											'xpna-react-table -striped -highlight'
										}
										loading={loading}
										columns={pivotHeaderData}
										defaultPageSize={7}
										pageSize={pivotRowData.length}
										showPagination={false}
										sortable={false}
									/>
								</Grid>
							) : radioSelectorValue == 1 &&
							  tabSelectorValue != -1 &&
							  radioSelectorValuePlant == 'BU 1' ? (
								this.props.monthSelected && (this.props.tabSelectorValue1 == 1 || this.props.tabSelectorValue1 == -1) ? (
									<NewReactPureTable
										pivotRowData={newPivotRowData}
										className='xpna-pure-table '
										loading={loading}
										pivotHeaderData={newPivotHeaderData}
										//  tableLen={tableLen}
										//  subComponent={this.subComponent}
										//  expandIcon={this.expandIcon}
										//  rowClick={this.rowClick}
										//  showInvoiceDetailModal={showInvoiceDetailModal}
										showLoader={showLoader}
									/>
								) : (
									<ReactTableComponent
										pivotRowData={pivotRowData}
										className='xpna-react-table oneColumnFixed expandableRows'
										loading={loading}
										pivotHeaderData={pivotHeaderData}
										tableLen={tableLen}
										subComponent={this.subComponent}
										expandIcon={this.expandIcon}
										rowClick={this.rowClick}
										showInvoiceDetailModal={showInvoiceDetailModal}
										showLoader={showLoader}
									/>
								)
							) : tabSelectorValue != 3 && tabSelectorValue != 6 ||
							  ((radioSelectorValue == 1 || radioSelectorValue == 0) &&
									radioSelectorValuePlant == 'summary') ? (
								this.props.monthSelected && (this.props.tabSelectorValue1 == 1 || this.props.tabSelectorValue1 == -1) ? (
									<NewReactPureTable
										pivotRowData={newPivotRowData}
										className='xpna-pure-table'
										loading={loading}
										pivotHeaderData={newPivotHeaderData}
										//  tableLen={tableLen}
										//  subComponent={this.subComponent}
										//  expandIcon={this.expandIcon}
										//  rowClick={this.rowClick}
										//  showInvoiceDetailModal={showInvoiceDetailModal}
										showLoader={showLoader}
									/>
								) : tabSelectorValue == 4 && tabSelectorValue1 == 0 ? 
								// <ReactTableFixedColumns
								// 		data={pivotRowData}
								// 		className={
								// 			'newCustomTable -striped -highlight noFixtColumTable'
								// 		}
								// 		loading={loading}
								// 		columns={pivotHeaderData}
								// 		defaultPageSize={10}
								// 		pageSize={pivotRowData.length}
								// 		showPagination={true}
								// 		sortable={false}
								// 	/>
								<div className="">
									<ReactTable
                                    columns={pivotHeaderData}
                                    data={pivotRowData}
                                    defaultPageSize={10}
                                    pageSize={10}
                                    loading={invoiceLoading}
                                    className="xpna-react-table -striped -highlight total-sales-report"
                                    PaginationComponent={NewPagination}
                                    sortable={false}
                                />
								</div>
								:  (
									<ReactTableComponent
										pivotRowData={pivotRowData}
										className='xpna-react-table'
										loading={loading}
										pivotHeaderData={pivotHeaderData}
										tableLen={tableLen}
										subComponent={this.subComponent}
										expandIcon={this.expandIcon}
										rowClick={this.rowClick}
										showInvoiceDetailModal={showInvoiceDetailModal}
										showLoader={showLoader}
									/>
								)
							) : (
								<ReactTable
									data={pivotRowData}
									className={
										(tabSelectorValue == 3 || tabSelectorValue == 6 ) && radioSelectorReconValue == 1
											? 'xpna-react-table  -striped -highlight twoColumFixedTable'
											: tabSelectorValue == 3 && radioSelectorReconValue == 6
											? 'xpna-react-table  -striped -highlight fourColumFixedTable'
											: 'xpna-react-table -striped -highlight oneColumnFixed '
									}
									loading={loading}
									columns={pivotHeaderData}
									defaultPageSize={7}
									subComponent={this.subComponent}
									expandIcon={this.expandIcon}
									pageSize={
										pivotRowData && pivotRowData.length > 7
											? pivotRowData.length
											: radioSelectorReconValue == 5
											? 3
											: 7
									}
									showPagination={true}
									PaginationComponent={NewPagination}
									sortable={false}
								/>
							)}
						</Grid>

						{/* {(tabSelectorValue == 0 ||
							tabSelectorValue == 1 ||
							tabSelectorValue == 2) &&
							(radioSelectorValue == 3 ||
								radioSelectorValue == 4 ||
								radioSelectorValue == 5) &&
							radioArrSelected == 'Default' &&
							radioArrCountrySelected == 'Default' && (
								<Grid item xs={4} className='chart-div'>
									<div className='top-chart'>
										{
											<PieChartComponent
												fetchNewPieData={this.props.fetchNewPieData}
												NewPieData={NewPieData.sales_data}
												NewLoader={NewLoader}
												seriesName={'Total'}
											/>
										}
									</div>
									<div className='border-top-gray'>
										{
											<PieChartComponent
												fetchNewPieData={this.props.fetchNewPieData}
												NewPieData={NewPieData.margin_data}
												NewLoader={NewLoader}
												seriesName={'Total'}
											/>
										}
									</div>
								</Grid>
							)} */}

						{radioSelectorReconValue == 5 && pivotRowData2 != undefined && (
							<Grid item xs='12'>
								<ReactTable
									data={pivotRowData2}
									className={
										tabSelectorValue == 3 && radioSelectorReconValue == 1
											? 'xpna-react-table -striped -highlight twoColumFixedTable'
											: tabSelectorValue == 3 && radioSelectorReconValue == 6
											? 'xpna-react-table -striped -highlight fourColumFixedTable'
											: 'xpna-react-table -striped -highlight oneColumnFixed'
									}
									loading={loading}
									columns={pivotHeaderData2}
									defaultPageSize={3}
									pageSize={pivotRowData2.length}
									showPagination={false}
									sortable={false}
								/>
							</Grid>
						)}
					</Grid>
				)}

				{showInvoiceDetailModal && (
					<InvoiceDetailModal
						getInvoiceDetailGL={this.handleGLInvoiceSorting}
						showModal={showInvoiceDetailModal}
						modalCallBack={this.invoiceModalCallBack}
						invoiceDetailState={invoiceDetailStateNew}
						invoiceDetailStateFilter={invoiceDetailStateFilter}
						invoiceLoading={invoiceLoading}
						showDrilldownHeaderValue={this.showDrilldownHeaderValue}
						handleFilterChange={this.handleInvoiceFilterChange}
						handleInvoiceSorting={this.handleInvoiceSorting}
						showGlDrilldownFilters={false}
						showAmountFilters={false}
						isExpandable={isExpandable}
						isInvoiceDetailModal={isInvoiceDetailModal}
						header={header}
						sub_header={sub_header}
						handleCountryChanges={this.handleCountryChanges}
						handleClassificationChanges={this.handleClassificationChanges}
						handleSaveChanges={this.handleSaveChanges}
						radioSelectorReconValue={this.props.radioSelectorReconValue}
						// invoiceLoadingGL={invoiceLoadingGL}
						curMonth={this.props.curMonth}
						monthSelected={this.props.monthSelected}
						financialDuration={this.props.financialDuration}
						toggleCommentModal={this.toggleCommentModal}
						NewChart={this.props.NewChart}
						handleDownloadFromBECallback={(tabSelectorValue1 == 2 || (tabSelectorValue1 == 0 && tabSelectorValue == 6)) ? this.handleDownloadFromBECallback : undefined}
					/>
				)}
				<Modal
					open={this.state.showModal}
					onClose={this.handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={this.state.showModal}>{body}</Fade>
				</Modal>
                <CommentModal 
                showModal={this.state.commentModal}
                modalCallBack={this.toggleCommentModal}
                commentArr={[]}
				selectedCommentRowData={selectedCommentRowData}
				selectedCommentItem={selectedCommentItem}
				selectedCommentParent={selectedCommentParent}
				userDataState={this.props.userDataState}
                sendCommmentCallback={this.handleSendCommmentCallback}
                />
				{showCommentModal &&
                <ChatCommentModal
                    showModal={showCommentModal}
                    commentRowData={commentRow}
                    modalCallBack={this.modalCallBack}
                    commentsData={commentModalData}
                    userDataState={authState.userData}
                    sendCommmentCallback={this.handleSendChatModalFunction}
                    buValue={this.props.buValue}
                    onImageInputHandler={this.onImageInputHandler}
                    handleImageOptions={this.handleImageOptions}
                    uploadImageObj={uploadImageObj}
                    allUsers={this.props.externalState.allUser}
                    menuData={menus}
                />
                }
				{modalComments}
			</>
		);
	}
}

export default withRouter(
    connect(
        state => {
          return {
            summaryState: state.dashboardState.receivableData.ageingData.summary,
            dashboardState: state.dashboardState,
            authState: state.authReducer,
            invoiceDetailState: state.invoiceDetailState,
            chatStoreState: state.chatStore,
            externalState: state.dashboardState.receivableData.ageingData.external,
            dayReportState: state.dayReportState,
            ecfmState: state.ecfmState
          };
        },
        dispatch => {
          return {
            updateSelectedDate(data) {
              dispatch({
                type: 'UPDATE_SELECTED_DATE',
                payload: data,
              });
            },
            createNewChannel(data) {
              dispatch({
                type: 'CREATE_CHANNEL_DAY_ID',
                payload: data,
              });
            },
            updateChannelFlag(data) {
              dispatch({
                type: 'CHANGE_CREATE_CHANNEL_DAY_FLAG',
                payload: data,
              });
            },
            updateActivePage(data) {
              dispatch({
                type: 'UPDATE_ACTIVE_PAGE_EXTERNAL',
                payload: data,
              });
            },
            fetchOneCommentDataHistory(data) {
              dispatch({
                type: 'FETCH_COMMENT_ONE_CHANNEL_DAY_DATA_HISTORY',
                payload: data,
              });
            },
            sendCollectionForecast(data) {
              dispatch({
                type: 'SEND_COLLECTION_FORECAST_INVOICE_DAY',
                payload: data,
              });
            },
            socketConnectionError(data) {
              dispatch({
                type: 'SHOW_ERROR_PAGE',
                payload: data,
              });
            },
            updateActionOwnerFn(data) {
              dispatch({
                type: 'UPDATE_ACTION_OWNER_AUTOMATED_REPORTING',
                payload: data,
              });
            },
            fetchCommentDataHistory(data) {
              dispatch({
                type: 'FETCH_COMMENT_CHANNEL_DAY_DATA_HISTORY',
                payload: data,
              });
            },
            updateExternalData(data) {
              dispatch({
                type: 'UPDATE_AUTOMATED_REPORTING_DATA_SET',
                payload: data,
              });
            },
            setSelectedEmailArr(data) {
                dispatch({
                    type: "SET_SELECTED_ROW_EMAIL_AUTOMATED_REPORTING",
                    payload: data
                });
            },
            uploadChatFile(data) {
              dispatch({
                type: 'CHAT_UPLOAD_FILE',
                payload: data,
              });
            },
            uploadChatFileToggle() {
              dispatch({
                type: 'CHAT_UPLOAD_FILE_TOGGLE',
              });
            },
          };
        },
    )(FunctionalTableComponent),
);
