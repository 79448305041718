import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { numberWithCommas, checkNan } from '../../../../../utils/numberUtils'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ReactTableComponent from './pureTable'
const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class FunctionalTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.state = {
            loading: true,
            showModal: false,
            showInvoiceDetailModal: false,
            showLoader: false,
            commentModal : false,
            commentValue : '',
        }
        this.excelData = []
    }
    
    expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt": rowInfo.original.sub_rows ? 1 : 0
        };
    }

    handleInvoiceClick = (rowData, subColumnLevel, columnLevel) => {
        let payload = {
            row_value: rowData.data_field,
            column_value : columnLevel.label,
        }

        let usd_value = 0.0;
        for(var key in rowData) {
            if(key == columnLevel.key) usd_value = rowData[key]
        }
        let params = `${rowData.data_field} (${columnLevel.label}) - USD ${ this.modifyColumnData(usd_value, 7)}`

        this.props.handlePropsInvoiceClick(payload, params);
    }

    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                console.log(item.key, "cekKeyss")
                item['operation'] = item.key == 'data_field' || item.key == 'data_group' || item.key == 'data_company_code' || item.key == 'data_ra' || item.key == 'so_item'? '' : 7;
                if (item.key == 'data_group') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: item.label,
                            id: item.key,
                            fixed: 'left',
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_group' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Grand Total" ? 'totalLabel' : ''

                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName } >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                } else if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            // Header: item.label,
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_field' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }                   
                        }]
                    }
                }else if (item.key == 'pct') {
                    return {
                        Header: item.label,
                        id: item.key,
                        width : 170,
                        // fixed: 'left',
                        metaData: item.metadata,
                        // columns: [{
                            // Header: item.label,
                        // fixed: 'left',
                        // id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_field' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key]? parseInt(props.original[item.key]) + "%": '-': ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }                   
                        // }]
                    }
                }  
                
                else if (item.key == 'so_item') {
                    return {
                        Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
                        id: item.key,
                        metaData: item.metadata,
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            textAlign:  "center"
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }else if (item.key == 'data_company_code') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_company_code' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Total" ? 'totalLabel' : ''
                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName }  >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }  else if (item.key == 'data_ra') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_ra' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }  else if (item.key.indexOf("pct_") > -1) {
                    return {
                        Header: () => {  
                            let spl = item.label.split("%")   
                            if(spl[0] != ""){ 
                            return (
                                <span className={'multi-header'}>
                                    {spl[0] }
                                    <br/>
                                    {" % " + spl[1]}
                                    </span>
                            )
                            }else{
                                return item.label
                            }
                        },
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key] != undefined ? this.modifyColumnDataOCOSVal(props.original[item.key],"percentage") : '-'}</span>
                                    }
                                </div>
                            )
                        },
                    }
                }else if (item.label.indexOf("|") > -1) {
                    // console.log(item.sub_headers, item, "test321")
                    let label = item.label
                    // if(item.key == "pct_digital_services"){
                    //     label = "DASS Services" +<br />+ "% of Revenue"
                    // }
                    return {
                        Header: () => {  
                            let spl = item.label.split("|")   
                            if(spl[0] != ""){ 
                            return (
                                <span className={'multi-header'}>
                                    {spl[0] }
                                    <br/>
                                    {spl[1]}
                                    </span>
                            )
                            }else{
                                return item.label
                            }
                        },
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key] != undefined ? this.modifyColumnData(props.original[item.key],"") : '-'}</span>
                                    }
                                </div>
                            )
                        },
                    }
                }              
                else if (item.key == 'company_code' ||item.key == 'country'||item.key == 'classification' || item.key == 'source'|| item.key == 'summary_acct' || item.key == 'summary_acct_desc'|| item.key == 'currency_code' ) {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                    }
                } else if (item.key == 'grand_total') {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let showPercentage = this.handleAppendPercentage(props.original);
                            let myClassName = showPercentage ? 'percentageRowData' : ''
                            return (
                                <div className='text-center'>
                                    {
                                        <span className={myClassName}>{props.original ? this.modifyColumnData(props.original[item.key], props.original["operations"]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        headerStyle: {
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }
                    }
                }   else if (item.key == 'tran_amount' || item.key == 'base_amount' || item.key == 'base_amount_aop'   || item.key == 'tran_amount_aop') {
                    var isManualKey = true;
                    // console.log(item.sub_headers, item, "test123123")
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                           
                            item['operation'] = 7;
                            if(props.original.summary_acct == "Total"){
                                isManualKey = false 
                            }
                            console.log(props.original.summary_acct, isManualKey, "chek123321")
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => { this.handleDGSAutomationClick(props.original, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:  isManualKey == true ? 'pointer' : ''
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }
                else if (!item.sub_headers) {
                    var isManualKey = false;
                   
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 2 && props.original.disable_drill_down == false) {
                                console.log("masuksinigaes1234")
                                isManualKey= true
                            }
                            if (this.props.NewChart == true){
                                isManualKey= true
                            }
                          
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => {   this.handleInvoiceClick(props.original, item, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:   'pointer' 
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }



                return {
                    Header:  <Tooltip>
                         <span onClick={() => {
                        if (this.props.tabSelectorValue != 3 && this.props.radioArrSelected == "Default") {

                            if (this.props.radioSelectorValue == 3){
                                this.props.handleRegionHeaderClick(item)

                            }else if(this.props.radioSelectorValue == 5){
                                this.props.handleHemisphereHeaderClick(item)

                            }
                        }

                    }
                    }
                    
                    >
                       
                          {item.label}
                        </span></Tooltip>,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
                    style: {
                        borderRight: '1px solid #423970',
                        cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight:'1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    subComponent = (row) => {
        console.log(row, "subComponentsubComponentsubComponent")

        let { pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        console.log(nestedFlag, "checkNested")
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        getTrProps={this.expandIcon}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                // this.handleColumnClick(state, rowInfo, column, instance, e)
                if (column.expander) {
                    let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                    let nestedFlag = this.checkForNestedLevel(pivotRowData)
                    pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                    this.addExcelData && this.addExcelData(rowInfo.original, pivotHeaderData);
                    // console.log(rowInfo,"cek")
                    handleOriginal()
                }
            }
        }
    }

    modifyColumnData = (columnData, operations) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (operations == "percentage") {
            modifiedData += "%"
        }

        return modifiedData
    }
    
    modifyColumnDataOCOSVal = (columnData, operations) => {
        let modifiedData;
        modifiedData = columnData.toFixed(1)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = modifiedData == '0.0' ? '-' : modifiedData
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData

        if (operations == "percentage" && modifiedData != "-") {
            modifiedData += "%"
        }

        return modifiedData
    }

    handleClose = () => {
        this.setState({ showModal: false })
    };

    render() {
        let {  pivotRowData, pivotRowData2, pivotRowDataOCOS, pivotHeaderData, pivotHeaderData2, pivotHeaderDataOCOS, loading, NewCommentLoader } = this.props.incomeStatementState;
        console.log(pivotRowDataOCOS, pivotHeaderDataOCOS, "checkDatOCOS")
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        pivotHeaderDataOCOS = this.changeKeysOfHeader(pivotHeaderDataOCOS, nestedFlag);

        let nestedFlag2 = this.checkForNestedLevel(pivotRowData2)
        pivotHeaderData2 = this.changeKeysOfHeader(pivotHeaderData2, nestedFlag2);
        const { showInvoiceDetailModal, showLoader } = this.state
        let tableLen = pivotRowData && pivotRowData.length || 6;
        pivotRowData = this.checkLevel(pivotRowData);

        pivotRowDataOCOS = this.checkLevel(pivotRowDataOCOS);

       
        pivotRowData2 = this.checkLevel(pivotRowData2);

        const modalComments = (
            <Modal
                                open={this.state.commentModal}
                                onClose={this.toggleCommentModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.commentModal}>
                                    <div className='customModal dataValidationCommentModal'>
                                    {NewCommentLoader && <div className='progressfullpage'><CircularProgress /></div>}
                                        <div className={'header'}>
                                            <h2>Add New Explanatory Comment</h2>
                                            <Icon onClick={this.toggleCommentModal}>close</Icon>
                                        </div>
                                        <div className={'modal-content'} style={{ padding: '30px' }}>
                                            <TextField
                                                fullWidth
                                                label="Comment"
                                                multiline
                                                rows={10}
                                                // value={this.state.commentValue}
                                                onBlur={this.handleCommentChange}
                                                variant="filled"
                                            />
                                            <div className="flex jc-flex-end mt-30">
                                                <Button variant="contained" color="primary" onClick={this.handleCommentSubmit}>Submit</Button>
                                                <Button variant="contained" className='ml-16' onClick={this.toggleCommentModal}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </Modal>
        )
    

        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                <Grid container spacing={3}>
                    <ReactTableComponent
                        pivotRowData={pivotRowData}
                        className='xpna-reactFixed-table -striped -highlight'
                        loading={loading}
                        pivotHeaderData={pivotHeaderData}
                        tableLen={tableLen}
                        subComponent={this.subComponent}
                        expandIcon={this.expandIcon}
                        rowClick={this.rowClick}
                        showInvoiceDetailModal={showInvoiceDetailModal}
                        showLoader={showLoader}
                    />
                </Grid>
                {/* {modalComments} */}
            </>
        );
    }
}

export default FunctionalTableComponent;
