import React,{ useMemo, useState, useEffect} from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import NewPagination from "../../common/components/CustomPagination";

// import Table from "./Table";
import './index.scss';

import "react-table-6/react-table.css";

export default function TableComnponent() {
    const data = [
                    {"id":"001", "brief_subject": "Sub 1", "key_sponsor":"CEO", "date": "30 Sep 2021"},
                    {"id":"002", "brief_subject": "Sub 2", "key_sponsor":"CFO", "date": "30 Dec 2021"},
                    {"id":"003", "brief_subject": "Sub 3", "key_sponsor":"COO", "date": "30 Mar 2022"},
                    {"id":"004", "brief_subject": "Sub 4", "key_sponsor":"Board", "date": " 30 Jun 2022"},
                    {"id":"005", "brief_subject": "Sub 5", "key_sponsor":"Board", "date": "30 Sept 2022"},
                    {"id":"006", "brief_subject": "Sub 6", "key_sponsor":"Board", "date": "30 Dec 2022"},
                ]
    const columns = useMemo(
        () => [
          {
            Header: "Initiative ID",
            accessor : "id",
            headerStyle: {
              whiteSpace: "break-spaces",
              width: '50px !important',
              overflow: "visible",
              fontSize: '13px',
              fontWeight: 'bold',
              backgroundColor: '#ff944d',
              color:"white",
            },
            style: {
              whiteSpace: "break-spaces",
              width: '50px !important',
              overflow: "visible",
              fontSize: '12px',
              textAlign: 'center',
              backgroundColor:"white", 
            },
            
          },
          {
            Header: "Brief Subject", 
            accessor:"brief_subject",
            headerStyle: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '13px',
                fontWeight: 'bold',
                backgroundColor: '#ffb380',
                color:"white",
              },
            style: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '12px',
                textAlign: 'center',
                backgroundColor:"white", 
              },        
          },
          {
            Header:"Key Sponsor",
            accessor: "key_sponsor",
            headerStyle: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '13px',
                fontWeight: 'bold',
                backgroundColor: '#ffaa00',
                color:"white",
              },
            style: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '12px',
                textAlign: 'center',
                backgroundColor:"white",
              },
          },
          {
            Header: "Likely Completion Date",
            accessor:"date",
            headerStyle: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '13px',
                fontWeight: 'bold',
                backgroundColor: '#ff944d',
                color:"white",
              },
            style: {
                whiteSpace: "break-spaces",
                width: '50px !important',
                overflow: "visible",
                fontSize: '12px',
                textAlign: 'center',
                backgroundColor:"white",
              },
          }
        ],
        []
      );
    
    return (
        <div className="riskMgmtTable" >
            <ReactTable 
            columns={columns}
            data={data}
            defaultPageSize={data.length > 10  ? 10 : data.length}
            // className="-striped -highlight"
            PaginationComponent={NewPagination}
            pageSizeOptions={[10, 20, 30]}
            />
        </div>
    )
    
}