import React from 'react';
import { Fragment } from "react"

export const FilterIcon2 = (props) => {
    const {isActive} = props
    return (
        <Fragment>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.6194 9.08291C5.49661 9.08291 5.37714 9.0475 5.27891 8.98199L3.00897 7.44294C2.86821 7.3466 2.7863 7.1972 2.78765 7.03926V5.3892L0.0637226 0.736726C-0.080448 0.489276 0.0282731 0.184753 0.306558 0.0565574C0.387582 0.0192326 0.477533 -0.000167948 0.568784 1.09528e-06H8.51357C8.71393 3.21591e-05 8.89942 0.0940098 9.0016 0.247258C9.10298 0.403384 9.10298 0.595739 9.0016 0.751864L6.16418 5.39929V8.57831C6.16568 8.77014 6.04473 8.94613 5.85206 9.03245C5.77943 9.0638 5.70011 9.081 5.6194 9.08291Z" 
                fill={isActive ? 'red': "#47505F"}
                />
            </svg>
        </Fragment>
    )
}

export default FilterIcon2;
