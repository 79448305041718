import React from "react";
import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import DownloadExcel from "../../../images/download_excel.svg";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";

import EditKpiBudgetModal from "./EditKpiBudgetModal";
import FormPlanModal from "./FormPlanModal";
import KpiFuncForm from "./KpiForm";
import DetailFuncForm from "./DetailForm";
import { useSelector, useDispatch } from "react-redux";
import FileUploadIcon from "../../../../src/images/isMain.png";
import { getAccessByMenuKey } from "../../../utils/userManagement";
import { toastr } from "react-redux-toastr";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function (props) {
    const {planningData,planningFilter, userDataState, savePlanModels, saveKpiBudgetModels, updateStatusKpiPlanning, fetchKPIPlanning} = props;
    const {rowData, filter} = planningData;
    const rowDataFilter  = planningFilter.rowData;
    const { planTypeArr,  periodArr, viewByArr, regionArr, tradeArr } = filter;
    
    const [showEditKpiBudget, setShowEditKpiBudget] = useState(false);
    const [showFormPlan, setShowFormPlan] = useState(false);
    const [topRadioSelectorValue, setTopRadioValues] = useState('sales'); 
    const [planTypeValues, setPlanTypeValues] = useState({"key": "Budget", "label": "Budget"});
    const [planListValues, setPlanListValues] = useState({"key": "", "label": ""});
    const [periodValues, setPeriodValues] = useState({"key": "", "label": ""});
    const [viewByValues, setViewByValues] = useState({"key": "", "label": ""});
    const [regionValues, setRegionValues] = useState({"key": "", "label": ""});
    const [tradeValues, setTradeValues] = useState({"key": "", "label": ""});
    const [formSelectorValue, setFormSelectorValue] = useState(0); 
    const dispatch = useDispatch();
    const [exportExcelPayload, setExportExcelPayload] = useState({});

    const [inlineEditing, setInlineEditing] = useState(false); 
    const [planDetails, setPlanDetails] = useState({}); 
    const [dtKpi, setDtKpi] = useState([]); 
    const [filterPeriod, setFilterPeriod] = useState({}); 
    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <Autocomplete
                    className = 'date-selection new-autocomplete'
                    fullWidth
                    disablePortal
                    disableListWrap
                    size='small'
                    value={selectedValue}
                    options={menuItems}
                    onChange={handleChange}
                    getOptionLabel={(option)=> option.label}
                    renderInput={(params) => (
                        <TextField
                            className="test-input-box"
                            {...params}
                            variant="standard"
                            label = {inputLabel}
                            placeholder={placeHolder}
                        />
                        )}                                                       
                />
            </FormControl>
        );
    };

    const handleTopRadioSelectorValueChange = (event) => {
        setTopRadioValues(event.target.value)
    }

    const handleEditKpiBudgetClose = (val) => {
        setShowEditKpiBudget(false);
    }

    const handleFormPlanClose = () => {
        setShowFormPlan(false);
    }

    const handleExportExcel = () => {
        let thisPayload = filterPeriod
        thisPayload.export = true
        dispatch({
            type: "FETCH_KPI_PLANNING",
            payload: thisPayload
          })
    }

    const handleRedirectToCompare = (value) => {
        // localStorage.setItem('show-back-fcf','true')
       window.location.assign('/dashboard/xpna/extended-planning/compare-plans')
    }

    const handleRedirectToViewPlans = (value) => {
        // localStorage.setItem('show-back-fcf','true')
       window.location.assign('/dashboard/xpna/extended-planning/planning-models')
    }

    const handleInlineEditing = (isInlineEditing) => {
        setInlineEditing(isInlineEditing);
    }

    const handleUpdateStatusKpiPlanning = (status) => {
        // console.log("planDetails :: ",planDetails)
        const toastrConfirmOptions = {
            onOk: () => {
                let payload = {
                    "_id": planDetails._id,
                    "status": status
                }
                updateStatusKpiPlanning([payload])
            },
            onCancel: () => console.log('CANCEL: clicked')
        }; 

        toastr.confirm(`Are you sure you want to ${status}?`, toastrConfirmOptions)
    }

    const handlePlanChange =(val) =>{
        setPlanListValues(val)
        fetchKPIPlanning({first_load : true, _id : val.key})
    }

    return (
    <Grid container xs={12} sm={12} className="pb-15 buttons-section">
        <Grid item xs={12} sm={12} className="pt-0 flex ai-center jc-space-between mt-5 mb-5">
            <Grid item xs={12} sm={12}>
                <Grid container className="dropdown-filter">
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={planTypeArr}
                                handleChange={(e, val) => setPlanTypeValues(val)}
                                inputLabel={'Budget'}
                                selectedValue={planTypeValues}
                                placeHolder={'Select Budget'}
                            />
                    </Grid>
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={rowDataFilter}
                                handleChange={(e, val) => handlePlanChange(val)}
                                inputLabel={'Plan'}
                                selectedValue={planListValues}
                                placeHolder={'Select Plan'}
                            />
                    </Grid>
                    {/* <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={periodArr}
                                handleChange={(e, val) => setPeriodValues(val)}
                                inputLabel={'Time Period'}
                                selectedValue={periodValues}
                                placeHolder={'Select Time Period'}
                            />
                    </Grid> */}
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={viewByArr}
                                handleChange={(e, val) => setViewByValues(val)}
                                inputLabel={'View By Function'}
                                selectedValue={viewByValues}
                                placeHolder={'Select View By Function'}
                            />
                    </Grid>
                </Grid>
                
                <FormControl component="fieldset" style={{margin:'15px 0px 5px 0px'}}>
                        <RadioGroup name="radio" className='radioGroupHorizontal' value={topRadioSelectorValue} onChange={handleTopRadioSelectorValueChange}> 
                            <div className="group">
                                <FormControlLabel value={'plant'} control={<Radio color="primary" />} label="Plant" />
                                <FormControlLabel value={'sales'} control={<Radio color="primary" />} label="Sales" />
                                <FormControlLabel value={'logistics'} control={<Radio color="primary" />} label="Logistics" />
                                <FormControlLabel value={'godowns'} control={<Radio color="primary" />} label="Godowns" />
                                <FormControlLabel value={'mines'} control={<Radio color="primary" />} label="Mines" />
                            </div>
                        </RadioGroup>
                </FormControl>

                <Grid container className="dropdown-filter">
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={regionArr}
                                handleChange={(e, val) => setRegionValues(val)}
                                inputLabel={'Region'}
                                selectedValue={regionValues}
                                placeHolder={'Select Region'}
                            />
                    </Grid>
                    <Grid item xs={2} className="maxw-200 mr-10">
                        <SelectDropdown
                                menuItems={tradeArr}
                                handleChange={(e, val) => setTradeValues(val)}
                                inputLabel={'Channel'}
                                selectedValue={tradeValues}
                                placeHolder={'Select Channel'}
                            />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={5} className="mt-15">
                            <Tabs
                            value={formSelectorValue}
                            onChange={(e, val) => setFormSelectorValue(val)}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="KPI Form" />
                            <Tab label="Detail Form" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={7} sm={7} className="pt-10 download-section" style={{overflow : 'auto'}} >
                    <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        {/* <Button variant='contained' className='run-button mr-10 btnsearchcompare' onClick={handShowCompare}>Search and Compare</Button> */}
                        {/* <Button className="newButton btnBlue mr-10"
                        variant="outlined" color="primary"
                        onClick={()=>{setShowEditKpiBudget(true)}}
                        >
                            Edit
                        </Button> */}
                        {/* <Button className="newButton btnBlue mr-10"
                        variant="outlined" color="primary"
                        onClick={()=>{setShowFormPlan(true)}}
                        >
                            Save
                        </Button> */}

                        {/* {getAccessByMenuKey(props.menuData, "pns_func_pnl", "review") && planDetails.status=='submit' && //review  //also check to data
                            <div className="button-border-kpi mr-5">
                                <Tooltip title={"Approve"}>
                                <Icon
                                        className="fs-20 primary mr-10 icon-in-border"
                                        color={"inherit"}
                                        disabled
                                        onClick={()=>{handleUpdateStatusKpiPlanning('approve')}}
                                >
                                        thumb_up_filled
                                    </Icon>
                                </Tooltip>
                                <Tooltip title={"Reject"}>
                                <Icon
                                        className="fs-20 primary mr-10 icon-in-border"
                                        color={"inherit"}
                                        disabled
                                        onClick={()=>{handleUpdateStatusKpiPlanning('reject')}}
                                >
                                        thumb_down_filled
                                    </Icon>
                                </Tooltip>
                            </div>
                        } */}

                        {getAccessByMenuKey(props.menuData, "pns_func_pnl", "edit") && getAccessByMenuKey(props.menuData, "pns_func_pnl", "add") && (planDetails.status=='' || planDetails.status=='reject') &&
                        <div className="button-border-kpi mr-5">
                            {getAccessByMenuKey(props.menuData, "pns_func_pnl", "edit") && planDetails._id != "000000000000000000000000" &&
                                <Tooltip title={"Edit Budget Calculation"}>
                                <Icon
                                        className="fs-20 primary mr-10 icon-in-border"
                                        color={"inherit"}
                                        disabled
                                        onClick={()=>{setShowEditKpiBudget(true)}}
                                >
                                        edit
                                    </Icon>
                                </Tooltip>
                            }

                            {getAccessByMenuKey(props.menuData, "pns_func_pnl", "add") &&
                                <Tooltip title={"Save"}>
                                <Icon
                                        className="fs-20 primary mr-10 icon-in-border"
                                        color={"inherit"}
                                        disabled
                                        onClick={()=>{setShowFormPlan(true)}}
                                    >
                                        save
                                    </Icon>
                                </Tooltip>
                            }
                        </div>
                        }

                        

                        <div className="button-border-kpi mr-5">
                        <Tooltip title={'Download to Excel'}>
                            <img
                                src={DownloadExcel}
                                className='pointer mr-10 icon-in-border-image'
                                height='30'
                                onClick={(event) => {
                                    handleExportExcel();
                                }} />
                        </Tooltip>
                        <Tooltip title={'Upload Excel'} className='pointer'>
                            <img
                                src={FileUploadIcon}
                                className='pointer icon-in-border-image'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                }} />
                        </Tooltip>
                        </div>

                        {getAccessByMenuKey(props.menuData, "pns_func_pnl", "add") && (planDetails.status=='' || planDetails.status=='reject') && //also check to data
                            <div className="mr-5">
                                {/* <Tooltip title={"Submit"}>
                                <Icon
                                        className="fs-20 primary mr-10 icon-in-border"
                                        color={"inherit"}
                                        disabled
                                        onClick={()=>{handleUpdateStatusKpiPlanning('submit')}}
                                >
                                        publish_filled
                                    </Icon>
                                </Tooltip> */}
                                <Button type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    className="btn-login "
                                    onClick={()=>{handleUpdateStatusKpiPlanning('submit')}}>
                                        Submit
                                </Button>
                            </div>
                        }

                        {/* <Button className="newButtonOutlined btnRed btnReset mr-10"
                        variant="outlined" 
                        color="primary"
                        startIcon={<Icon>lock</Icon>}
                            >
                            Lock
                        </Button> */}
                        <Tooltip title={"Lock"}>
                        <Icon
                                className="fs-20 primary mr-10"
                                color={"inherit"}
                                disabled
                                onClick={() => {
                                    // handleOpen();
                                }
                                }
                            >
                                lock
                            </Icon>
                        </Tooltip>
                        <Tooltip title={"Collaborate"}>
                            <Icon
                                className="fs-20 primary mr-10"
                                color={"inherit"}
                                disabled
                                onClick={() => {
                                    // handleOpen();
                                }
                                }
                            >
                                chat_bubble_filled
                            </Icon>
                        </Tooltip>
                        
                        <Tooltip title={'Download to PDF'}>
                            <PictureAsPdfIcon fontSize="large"
                                height='30'
                                className="pointer mr-10"
                                onClick={(event) => {
                                    // event.preventDefault();
                                    //   this.printDocument();
                                }} />
                        </Tooltip>
                      
                    </div>
                    </Grid>
                </Grid>
                <Grid container className='mt-20 mb-20' >  
                    <TabPanel value={formSelectorValue} index={0}  style={{ width : '100%'  }} >
                        <KpiFuncForm
                            filters={(v) => {setFilterPeriod(v)}}
                            dtkpi={(v) => {setDtKpi(v)}}
                            plandetails={(v) => {setPlanDetails(v)}}
                            isEditData={inlineEditing}
                            cancelInlineEditing={(isInlineEditing) => { handleInlineEditing(!isInlineEditing)}}
                            />
                    </TabPanel>
                    <TabPanel value={formSelectorValue} index={1}>
                        <DetailFuncForm/>
                    </TabPanel>
                </Grid>
            </Grid>
          
        </Grid>
        <Grid item xs={12} sm={12} className="pt-0">
        {/* <Button type="submit" variant="contained" color="primary" onClick={TestRunOkay}>Run</Button> */}
            {/* {actualForecastCementData.loading ? <div className='progressfullpage'><CircularProgress /></div> :
                <div style={{maxHeight:'700px', overflow:'scroll'}}>
                <NewTableComponent className={'ebitdaCustomTable fixedFirstTwoColumTable expandableRows'} tableData={actualForecastCementData} />
                </div>
            } */}
        </Grid>

        <FormPlanModal
            formPlan={showFormPlan}
            modalCallBack={() => { handleFormPlanClose() }}
            header={ "Save Plans" }
            userDataState={userDataState}
            filterPeriod={filterPeriod}
            savePlanModels={savePlanModels}
            planDetails={planDetails}
            />
        
        <EditKpiBudgetModal
            editKpiBudget={showEditKpiBudget}
            modalCallBack={() => { handleEditKpiBudgetClose() }}
            header={ "Edit KPI Budget Plan" }
            userDataState={userDataState}
            filterPeriod={filterPeriod}
            saveKpiBudgetModels={saveKpiBudgetModels}
            dtkpi={dtKpi}
            plandetails={planDetails}
            inlineEditing={(isInlineEditing) => { handleInlineEditing(isInlineEditing) }}
            />
    </Grid>

    
    );
}

