import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas, convertNumber } from '../../../../utils/numberUtils'
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver';
function BarChart(props) {
    var subtitle = props.showImprovementLineChart ?  '<div class="summary"><span class="bu-keys title"></span></div>' : '';
    let subChartLen = props.subChartData.length
    let classSummary = subChartLen > 1 ? "list-item" : "" 
    let titleChart = ""

    switch(props.chartTitle) {
      case 'byRegion':
        titleChart = "Regional View"
        break;
      case 'byManager':
        titleChart = "Project Manager View"
        break;
      case 'byProject':
        titleChart = "Project Name View"
        break;
      default:
        break;
    }

    if (props.showImprovementLineChart) {
        props.subChartData.map((value, index) => {
            let modifiedData = Math.round(value.value)
            let isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = convertNumber(positiveTransformedValue)
            let className = isNegative ? 'text-red' : 'text-green'
            
            subtitle += '<div class="summary '+classSummary+'"><span class="bu-keys">'+value.key+'</span>  <span class='+className+'>'+modifiedData+'</span></div>'
        });
    }

    
    const modifyColumnData = (columnData, operations) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (operations == "percentage") {
            modifiedData += "%"
        }

        return modifiedData
    }
   
    // console.log(props.graphView, props.chartData, "cekVIew")
    
    const exportAsPicture = () => {

        const input = document.getElementById('column_chart_overdue');
        html2canvas(input)
          .then((canvas) => {
            // let imgWidth = 208;
            // let imgHeight = canvas.height * imgWidth / canvas.width;
            // const imgData = canvas.toBlob("img/png")
            // const pdf = new jsPDF('p', 'mm', 'a4');
            // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            // pdf.output('dataurlnewwindow');
            // console.log(imgData, "testCheck")

            var data = canvas.toDataURL();
            var img  = document.createElement('img');
            img.setAttribute('download','myImage.png');
            img.src  = 'data:image/png;base64,' + data;
            document.body.appendChild(img);
            props.saveCapture(data)
            // saveAs(imgData, "DSOChart.png")
            //  pdf.save("download.pdf");
          })
        ;
        // setTime(t.toFixed(3))

      }
      
    return (
        <div id="column_chart_overdue" class="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'column',
                            height: 400,
                            zoomType: 'x',
                            spacing: [20, 10, 10, 10],
                            options3d: {
                                enabled: true,
                                alpha: 0,
                                beta: 0,
                                depth: 20,
                                viewDistance: 25
                            },
                        },
                        title: {
                            text: titleChart,
                            useHTML: true,
                            align: "center",
                            margin: 25,
                            style: {
                                fontSize: "16px", 
                                fontWeight: 500  
                            }
                        },
                        subtitle: { 
                            useHTML: true,
                            text: '<div class="summary-content">'+subtitle+'</div>',
                        },
                        xAxis: {
                            type: 'category',
                            title : { 
                                text :  "Periods", 
                                style: {
                                    color: '#4472c4'
                                }
                            }
                        },
                        yAxis:[
                            { // Primary yAxis
                                title: {
                                    text: '',
                                    style: {
                                        color: '#4472c4'
                                    }
                                },
                                opposite: true,
                                labels: {
                                    
                                    style: {
                                        color: '#4472c4'
                                    }
                                },
                                tickInterval: 40
                            },
                            { // Secondary yAxis
                            title: {
                                text: 'Revenue Backlog',
                                style: {
                                        color: '#4472c4'
                                    },
                                 
                            },
                            labels: {
                                formatter: function () {
                                    return this.value/1000000 + "M";
                                },
                            }
                            // min: (props.graphType == "country"  && !props.topTenCountry) || props.graphType == "region"  ?-100000 : 0,
                            // startOnTick: false
                        }, 
                        
                    ],
                    tooltip: {
                        formatter: function() {
                            const data = this.series.userOptions.data
                            // console.log(this.series, "--data")
                            const percentage = data.find(x => x.y === this.y).per
                            return (
                                '<b><h4>'+this.series.userOptions.name+'</h4><b>' + '<br/>' +
                                '<b>Backlog(USD): </b>' + modifyColumnData(this.y,7) + '<br/>' +
                                '<b>Margin Pct: </b>' + percentage + '%'
                            );
                        }
                    },
                        // tooltip: {
                        //     formatter: function () {
                               
                        //         let totData =  props.totalData
                        //         let totalDSO = 0.0
                        //         let dso_vertical = 0

                        //         console.log('formatterformatter', this.point)
                            
                        //         totData.forEach(x => {
                                  
                        //             if(props.graphType == "hemisphere" ) {
                        //                 let hemisphere = this.point.options.hemisphere
                        //                 dso_vertical = this.point.dso_vertical
                        //                 console.log(dso_vertical, this.key, this.series.name, x , "cek123")
                        //                 if (this.key == x.period && this.series.name != 'DSO' && hemisphere == x.name){
                        //                     totalDSO= x.sub_column["revenue"].os
                        //                 }
                                        
                        //             }else{
                        //                 if (this.key == x.name){
                        //                     totalDSO= x.os
                        //                 }
                        //             }
                                    
                        //             // console.log(x, "cekDatas")
                        //         })
                        //         if( props.graphType == "region" || props.graphType == "country") {
                                   
                        //             totData.forEach((x, idx) => {
                        //                 let hemisphere = this.point.options.hemisphere
                        //                 dso_vertical = this.point.dso_vertical
                        //                 let totalDSOIn = this.point.total_receivable || this.point.total
                        //                 if (this.series.name != 'DSO' && idx == 0){
                        //                     // console.log(x, "cekDatas", this.point)
                        //                     totalDSO= totalDSOIn
                        //                 }
                                   
                                    
                                    
                        //         })
                        //         }
                        //         // if( props.graphType == "country" ) {
                        //         //     // console.log(this, "cekDatas")
                        //         //         totData.forEach((x, idx) => {
                                           
                        //         //             let hemisphere = this.point.options.hemisphere
                                          
                        //         //             if (this.series.name != 'DSO' && idx == 0){
                                            
                        //         //                 totalDSO= x.sub_column[hemisphere].os
                        //         //             }
                                            
                                            
                                           
                        //         //         })
                        //         // }
                              
                        //         if (props.externalTabValue == 0) {
                        //             if (this.series.name == 'DSO') {
                        //                 return '<span>' + this.series.name + '</span>: <b>' + numberWithCommas(this.y) + '</b><br/>';
                        //             } else {
                        //              if(  props.graphType == "region" || props.graphType == "country" ) {
                                       
                        //                 return '<span>' + this.point.alias  + '</span>: <b>' + numberWithCommas( this.point.dso) + '</b><br/> <span>Total</span>: <b>' + numberWithCommas(totalDSO)+ '</b>' + `${dso_vertical ? '<br/> <span>DSO</span>: <b>' + dso_vertical+ '</b>' : ''}`;

                        //             }else{
                                       
                        //                 return '<span>' + this.series.name + '</span>: <b>' + numberWithCommas(this.y) + '</b><br/> <span>Total</span>: <b>' + numberWithCommas(totalDSO)+ '</b>'+ `${dso_vertical ? '<br/> <span>DSO</span>: <b>' + dso_vertical+ '</b>' : ''}`;

                        //              }
                        //         }
                        //         } else {
                        //             if (this.series.name == 'DSO') {
                        //                 return '<span>' + this.series.name + '</span>: <b>' + numberWithCommas(this.y) + '</b><br/>';
                        //             } else {
                        //                 return '<span>Total O/S </span>: <b>' + numberWithCommas(this.y) + '</b><br/>';
                        //             }
                        //         }
                        //     }
                        //     // headerFormat: '<span style="font-size:11px">Detail</span><br>',
                        //     // pointFormat: '<span>{series.name}</span>: <b>{point.y:,.0f}</b><br/> <span>Total</span>: <b>{point.total:,.0f}</b>',
                        // },
                        legend: {
                            squareSymbol: false,
                            symbolHeight: 6,
                            symbolWidth: 12,
                            symbolRadius: 0,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'top',
                            y: 50,
                            padding: 3,
                            itemMarginTop: 5,
                            itemMarginBottom: 5,
                            itemStyle: {
                                lineHeight: '14px'
                            }
                        },
                        navigation: {
                            buttonOptions: {
                                verticalAlign: 'top',
                                y: -10
                            }
                        },
                        plotOptions: {
                            column: {
                                depth: 25,
                                stacking: props.receivablesTab == 1  ?'normal' : '',
                                dataLabels: {
                                    enabled: false
                                },
                            },
                            // series: {
                            //     showInLegend:  props.graphType == "region" || props.graphType == "country"  ? true : true,  
                            //     stacking: props.receivablesTab == 1 ?'normal' : '',
                            //     opacity: 1,
                            //     borderWidth: 0,
                            //     stickyTracking: false,
                            //     dataLabels: props.graphType == "hemisphere"? [{
                            //         enabled: props.SeriesLabelEnabled,
                            //         format: '{point.label}',
                            //         color: props.SeriesLabelColor,
                            //         style: {
                            //             textOutline: 'none'
                            //         }
                            //     },{
                            //         enabled: props.SeriesLabelEnabled,
                            //         format: '{point.labelDSO}',
                            //         color: props.SeriesLabelColor,
                            //         style: {
                            //             textOutline: 'none',
                            //         }
                            //     }]: {
                            //         enabled: props.SeriesLabelEnabled,
                            //         format: '{point.labelDSO}',
                            //         color: props.SeriesLabelColor,
                            //         style: {
                            //             textOutline: 'none'
                            //         }
                            //     },
                            //     events: {
                            //         click: function (event) {
                            //             if( props.graphType == "hemisphere" && this.data[0].y == event.point.y){
                            //                 props.handleHemisphereHeaderClick(this.userOptions.stack)
                            //             }
                            //             if( props.graphType == "region"){
                            //                 props.handleRegionHeaderClick(this.userOptions.stack)
                            //             }
                            //         }
                            //     }
                            // }
                        },
                        series: props.chartData,
                        credits: {
                            enabled: false
                        },
                    }
                }
            />
             <button id="captureChart" onClick={exportAsPicture} style={{ backgroundColor: 'white', border: 'none' }}></button>
        </div>
    )
}
export default BarChart;