import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TableComponent from "./table.jsx";
import "./index.scss";

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "FETCH_SIMULATION_LIST",
        });
    }, []);

    return (
        <div className="simulation-logic-main-div">
            <TableComponent />
        </div>
    );
}
