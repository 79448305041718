import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from "../../Shared/InvoiceDetail";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTableComponent from './pureTable';
import { Autocomplete, TextField } from '@mui/material';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.sortList = {}
        this.renderExcel = true;
        this.sortListGL = {}
        this.levelMap = {
            PlTotal: 'P&L Total',
            PlGroup: 'P& L Group',
            GroupName2: 'Group Name2',
            GroupName: 'Group Name',
            GroupName3: 'Group Name3',
            AccountName: 'Account Name',
            CostCenter: 'Cost Centre2'
        }
        this.state = {
            loading: true,
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            filterSelectorValue: [],
            showLoader: false,
            invoiceObject: null
        }
        this.excelData = []
    }

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }

    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }

    addExcelDataMain = (data, level, idx) => {
        idx += 1;
        let excelData = this.excelData;

        if (data) {
            let parents = data[0].parents[0]
            let indexParent = excelData.findIndex(x => x.data_field === parents)
            let curLevel = excelData[indexParent + 1] && excelData[indexParent + 1].custom_level;
            let prevLevel = excelData[indexParent] && excelData[indexParent].custom_level;
            if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
                idx = indexParent + 1
                excelData = this.removeExcelDataMain(data, indexParent)
            } else {
                level += 1
                let idxori = idx
                data && data.forEach((x, i) => { //inject data after the parent

                    i += 1
                    x.data_field = this.addSpaceLevel(x.data_field, level)
                    idx = indexParent + i
                    excelData.splice(idx, 0, x)
                })
            }
            this.excelData = excelData
        }


    }

    removeExcelDataMain = (data, idx) => {
        let excelData = this.excelData;
        excelData = excelData.filter((val, i) => {

            return !(val.custom_level >= excelData[idx].custom_level && val.parents == excelData[idx].data_field)
        })
        return excelData
    }

    addExcelData = (dataval, header) => {
        let excelData = this.excelData;
        let data = JSON.parse(JSON.stringify(dataval))
        data.sub_rows = this.checkLevel(data.sub_rows, data.custom_level);
        if (excelData.length == 0) {
            this.excelData = data;
        } else {
            switch (data.custom_level) {
                case 0:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 1:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 2:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val[data.levels_field[2]] == data[data.levels_field[2]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 3:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val[data.levels_field[2]] == data[data.levels_field[2]] &&
                        val[data.levels_field[3]] == data[data.levels_field[3]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
            }
        }
        this.handleExcelDataChange(this.excelData, header)
    }

    handleTooltipData = (props) => {
        let tooltipData = ''

        if (props && props.original) {
            let { custom_level, levels } = props.original;
            if (levels && levels.length) {
                tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
            }
        }


        return tooltipData;
    }

    handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={

                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>

                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }

        return tooltipData;
    }

    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {

                item['operation'] = item.key == 'data_field' ? '' : 7;

                if (item.key == 'data_field') {

                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        Cell: (props) => {

                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            // let zeroData = newOriginal[item.key]
                            let tooltipData = this.handleTooltipData(props)
                            return (
                                <div>
                                    <Tooltip title={tooltipData}>
                                        <span>{handleTableTransform(newOriginal, item)}</span>
                                    </Tooltip>
                                </div>

                            )
                        }
                    }

                } else if (item.key) {
                    return {
                        Header: this.handleColumnHeaderTooltip(item),
                        headerTitle: item.label,
                        id: item.key,
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        Cell: (props) => {

                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal ? newOriginal[item.key] : undefined
                            let showPercentage = this.handleAppendPercentage(props.original);
                            let disableDrillDown = props.original.disable_drill_down;
                            let myClassName = showPercentage ? 'percentageRowData' : ''
                            myClassName += disableDrillDown ? '' : ' enableDrillDown'
                            return (
                                <React.Fragment>
                                    <span className={myClassName}>{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</span>
                                </React.Fragment>

                            )
                        },
                        accessor: (_data) => {
                            if (item.operation) {
                                let modifiedData;
                                let neOriginal = this.handleDifferentBUResponse(_data, item.key)
                                let columnData = neOriginal[item.key]
                                switch (item.operation) {
                                    case 0:
                                        modifiedData = item.key;
                                        break;
                                    case 1:
                                        modifiedData = Math.round(columnData)
                                        break;
                                    case 2:
                                        modifiedData = numberWithCommas(columnData)
                                        break;
                                    case 4:
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 3:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = numberWithCommas(modifiedData)
                                        break;
                                    case 5:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 6:
                                        var isNegative = columnData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 7:
                                        modifiedData = Math.round(columnData)
                                        var isNegative = modifiedData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    default:
                                        break;
                                }

                                return modifiedData;
                            } else {

                                return _data[item.key];
                            }
                        },
                    }

                }

                return {
                    Header: item.label,
                    id: item.key,
                    className: isNested ? '' : 'noNesting',
                    metaData: item.metadata,
                    Cell: (props) => {

                        let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                        let zeroData = newOriginal ? newOriginal[item.key] : undefined
                        let showPercentage = this.handleAppendPercentage(props.original);
                        return (
                            <React.Fragment>
                                <>{zeroData ? showPercentage ? handleTableTransform(newOriginal, item) + '%' : handleTableTransform(newOriginal, item) : '-'}</>
                            </React.Fragment>
                        )
                    }
                }
            })
        }
        return []
    }


    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt": rowInfo.original.sub_rows !== null ? 1 : 0
        };
    }
    subComponent = (row) => {
        let { pivotHeaderData } = this.props.incomeStatementState;
        const { radioSelectorValue } = this.props
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        getTrProps={this.expandIcon}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        sortable={false}
                        getTdProps={this.rowClick}
                    />
                    : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }



    columnHeaderClick = (state, someData, colInfo) => {
        const { showCursor } = this.props;
        if (showCursor) {
            return {
                onClick: (e) => {
                    this.handleColumnHeaderClick(colInfo)
                },
                style: { cursor: 'pointer' }
            }
        }
        return {}
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        const { data_field, parents } = colInfo.original;
        const { id, headerTitle } = columnDetail;
        const {legalEntitySelectorValue, plantSelectorValue} = this.props
        let dataVal = parents && parents.length ? [...parents, data_field].join(',') : data_field;
        if (headerTitle) {
            this.setState({
                showLoader: true,
                invoiceObject: { legal_entity : legalEntitySelectorValue, plant : plantSelectorValue , grid_header: id == 'grand_total' ? '' : id, level_values: dataVal }
            })
            this.props.getInvoiceDetail({ legal_entity : legalEntitySelectorValue, plant : plantSelectorValue ,  grid_header: id == 'grand_total' ? '' : id, level_values: dataVal });
            this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${ checkNan(colInfo.row[columnDetail.id])}`
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                if (!rowInfo.original.disable_drill_down) {
                    this.handleColumnClick(state, rowInfo, column, instance, e)
                }
                if (column.expander) {
                    let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                    let nestedFlag = this.checkForNestedLevel(pivotRowData)
                    pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                    this.addExcelData(rowInfo.original, pivotHeaderData);
                    handleOriginal()
                }
            }
        }
    }

    prepareExcelData = () => {
        this.excelData = []
        this.handleExcelDataChange([], [])
        let { pivotRowData, pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        pivotRowData = this.checkLevel(pivotRowData);
        this.addExcelData(pivotRowData, pivotHeaderData);
    }



    componentDidUpdate(prevProps) {

        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
            this.setState({
                showInvoiceDetailModal: true,
                showLoader: false
            })
        }

        if (this.state.showLoader || this.state.showInvoiceDetailModal) {
            this.renderExcel = false;
        }


        if (this.renderExcel) {
            this.prepareExcelData();
        }

    }

    componentDidMount() {
        this.prepareExcelData();
    }

    updateExcel = () => {
        setTimeout(function () {
            this.renderExcel = true;
        }.bind(this), 2000)
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false },
            this.updateExcel);
    }

    handleDownloadFromBECallback = () => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            export: true,
        }
      
        this.props.getInvoiceDetail(payload);
    }


    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value.amount,
            filter_gl: value.gl

        }

        this.setState({
            invoiceObject: payload
        })
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;

        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }

    handleGLInvoiceSorting = (glInvoiceObject, columnId) => {

        let payload = {
            ...glInvoiceObject
        }
        if (columnId) {
            this.sortListGL[columnId] = this.sortListGL[columnId] != undefined ? !this.sortListGL[columnId] : false

            payload['sort'] = {
                key: columnId,
                desc: this.sortListGL[columnId]
            }

        }

        this.props.getInvoiceDetailGL(payload)
    }

    render() {
        let { invoiceLoadingGL, invoiceDetailStateGL, invoiceDetailStateFilter, pivotRowData, pivotHeaderData, filterColumnArr, loading, invoiceDetailState, legalEntityArr, plantArr, invoiceLoading } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        const { excludeDataFilter,
            handleExcludeFilterChangeNew, tabSelectorValue,
            radioSelectorValue, plantSelectorValue,
            legalEntitySelectorValue, handleLegalEntityChange, handlePlantChange, handleResetExcludeFilter, isDetailedView } = this.props
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);

        const { showInvoiceDetailModal, showLoader } = this.state
        let tableLen = pivotRowData && pivotRowData.length || 6;
        pivotRowData = this.checkLevel(pivotRowData);
        
        let excFilterColArr =[]
        if ((typeof filterColumnArr !== 'string') && filterColumnArr?.length > 0) {
            excFilterColArr=[...filterColumnArr.map((bu)=> ({...bu, value: bu.key}))]
        }

        const selectedOptions = (a, data) => {
            if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
            else return {label:'', value:''}
            
        };



        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                <div className='flex jc-space-between mb-10'>
                    <Grid container>
                        {radioSelectorValue == 1 && !isDetailedView &&
                            <>
                                <Grid item xs={12} sm={2} className='minw-200 maxw-200 mr-10'>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Legal Entity Filter</InputLabel>
                                        <Select
                                            value={legalEntitySelectorValue}
                                            label="Legal Entity Filter"
                                            onChange={handleLegalEntityChange}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            {legalEntityArr && legalEntityArr.length && legalEntityArr.map(item => {
                                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disableClearable
                                        size='small'
                                        fullWidth
                                        className='xpna-dropdown single'
                                        options={legalEntityArr}
                                        value={legalEntitySelectorValue}
                                        onChange={handleLegalEntityChange}
                                        renderInput={(params)=>(<TextField {...params} variant='standard' label='Legal Entity Filter' placeholder='Select'/>)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} className='ml-10 minw-200 maxw-200'>
                                    {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Plant ID Filter</InputLabel>
                                        <Select
                                            value={plantSelectorValue}
                                            label="Plant ID Filter"
                                            onChange={handlePlantChange}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            {plantArr && plantArr.length && plantArr.map(item => {
                                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        disableClearable
                                        size='small'
                                        fullWidth
                                        className='xpna-dropdown single'
                                        options={plantArr}
                                        value={plantSelectorValue}
                                        onChange={handlePlantChange}
                                        renderInput={(params)=>(<TextField {...params} variant='standard' label='Plant ID Filter' placeholder='Select'/>)}
                                    />
                                </Grid>
                            </>
                        }

                    </Grid>
                    {(tabSelectorValue == 0) ? '' : <Grid container justify="flex-end">
                        {/* <FormControl variant="outlined" className='selectOutlineSmall maxw-200' fullWidth size='small'>
                            <InputLabel>Exclude Columns</InputLabel>
                            <Select
                                multiple
                                value={excludeDataFilter}
                                label="Exclude Columns"
                                onChange={handleExcludeFilterChangeNew}
                            >
                                {filterColumnArr && filterColumnArr.length && filterColumnArr.map(item => {
                                    return <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            disablePortal
                            disableClearable
                            disableCloseOnSelect
                            multiple
                            className='xpna-dropdown multiple minw-200 maxw-200 mb-10'
                            limitTags={1}
                            size='small'
                            options={excFilterColArr}
                            getOptionLabel={(option)=>option.label}
                            value={excFilterColArr && excFilterColArr.length ? selectedOptions(excludeDataFilter,excFilterColArr): []}
                            onChange={handleExcludeFilterChangeNew}
                            renderInput={(params)=>(<TextField {...params} variant='standard' label= 'Exclude columns' placeholder='Select' />)}
                        />
                        <Button onClick={handleResetExcludeFilter} color="primary">Reset</Button>
                    </Grid>}
                </div>
                {loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                <ReactTableComponent
                    pivotRowData={pivotRowData}
                    className={ (this.props.tabSelectorValue == 1 || this.props.tabSelectorValue == 2) && this.props.radioSelectorValue == 1 ? 'xpna-reactFixed-table functional-pl -striped -highlight' : 'xpna-reactFixed-table functional-pl -striped -highlight' }
                    loading={loading}
                    pivotHeaderData={pivotHeaderData}
                    tableLen={tableLen}
                    subComponent={this.subComponent}
                    expandIcon={this.expandIcon}
                    columnHeaderClick={this.columnHeaderClick}
                    rowClick={this.rowClick}
                    showInvoiceDetailModal={showInvoiceDetailModal}
                    showLoader={showLoader}
                />}
                {showInvoiceDetailModal &&
                    <InvoiceDetailModal
                        getInvoiceDetailGL={this.handleGLInvoiceSorting}
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        invoiceDetailStateFilter={invoiceDetailStateFilter}
                        invoiceLoading={invoiceLoading}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleFilterChange={this.handleInvoiceFilterChange}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                        invoiceDetailStateGL={invoiceDetailStateGL}
                        invoiceLoadingGL={invoiceLoadingGL}
                        financialDuration={this.props.financialDuration}
                        handleDownloadFromBE={true}
                        handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                    />
                }
            </>
        );
    }
}

export default TableComponent;