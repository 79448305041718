import React from 'react';
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";

export default function ExitWithoutSaveModal(props) {

    const handleClose = () => {
        props.exitModalCallBack(false);
    };

    const body = (
        <div className='customModal editModalDSO' style={{maxWidth: 400}}>
            <div className='header'>
                <h2>Exit Without Saving</h2>
                <Icon onClick={handleClose}>close</Icon>
            </div>
            <div className='modal-content'>
                <h4>Any unsaved changes will be discarded. Are you sure you want to proceed ?</h4>
                <Grid container justify="flex-end" className='mt-30'>
                    <Button variant="contained" color="primary" onClick={() => props.exitModalCallBack(true)} color="primary">Save Changes</Button>
                    <Button variant="contained" onClick={handleClose} className='ml-10'>Discard Changes</Button>
                </Grid>
            </div>
        </div>
    );

    return (
        <div >
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
