import React  from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BarGraph from "./GraphComponent"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NegativeBarGraph from './TornadoChart';
import { handleTableTransform, numberWithCommas } from '../../../../utils/numberUtils'
import Switch from '@material-ui/core/Switch';
import TableComponent from './TornadoChart/table'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import { useTheme } from "@material-ui/core/styles";


import ModalUser from "./RecipientPopUp"
import {
    downloadExcel,
    downloadExcelV1,
    mapDataPDF,
    mapExcelDataV1,
  } from '../../../../utils/exportExcel';
  import Button from '@material-ui/core/Button';
  import Icon from '@material-ui/core/Icon';
  import Modal from '@material-ui/core/Modal';
  import Backdrop from '@material-ui/core/Backdrop';
  import Fade from '@material-ui/core/Fade';
  import OutlinedInput from '@material-ui/core/OutlinedInput';
  import InputAdornment from '@material-ui/core/InputAdornment';
  import {CKEditor} from '@ckeditor/ckeditor5-react';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import TextField from '@material-ui/core/TextField';
  import Dialog from '@material-ui/core/Dialog';
  import DialogActions from '@material-ui/core/DialogActions';
  import DialogContent from '@material-ui/core/DialogContent';
  import DialogContentText from '@material-ui/core/DialogContentText';
  import DialogTitle from '@material-ui/core/DialogTitle';
  import { Typography, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { set } from 'date-fns';
import { toastr } from 'react-redux-toastr';
function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export class CollectionsComponent extends React.Component {
   
    constructor(props) {
        super(props)
        const { state } = this.props.location;
        this.state = {
            externalTabValue: 0,
            radioButtonValue: 'month',
            selectedMonth: '',
            currentMonth: '',
            pastMonth: '',
            monthArr: [],
            limitArr: [],
            currentLimit: 'Top 20',
            monthFilterArr: [],
            summaryByBUTransformedArr: [],
            summaryByBUArr: [],
            chartTitleActuals: "",
            GraphView: true,
            openDialog:false,
            loader :false,
            viewEmail:false,
            viewRecipient:false,
            tornadoRadioButtonValue: 'Third',
            emailDefault:[],
            // emailDefault2 : [],
            // budgetGroup : []

        }
        this.buName = {
            0: "Summary",
            1: "BU 1",
            2: "BU 2",
            3: "BU 3",
            4: "BU 4",
            5: "New BU 1",
            6: "New BU 2",
            7: "Unallocated",
        }
        this.newName = {
            0: "Summary",
            1: "New BU 1",
            2: "New BU 2",
            3: "Unallocated",
        }
    }
    setTitleChart = (event, externalTabValue) => {
        this.setState({
            chartTitleActuals: "Collection Actual v/s Collection Forecast",
        });

    }

    componentDidMount() {
       
        this.props.getCollectionsData({ 'bu': 'Summary', 'receivableTabSelectorValue' : this.props.tabSelectorValue})
        this.setTitleChart('month', 0);
        this.props.fetchReportDate({'isTornado': true});
       
        let limitArr = [
            {key: '20', value: 'Top 20'},
            {key: '40', value: 'Top 40'},
            // {key: 'all', value: 'All'}
        ]
        this.setState({
            limitArr,
        })
    }


    handleExternalTabChange = (event, newValue) => {
        if (newValue > 0) {
            this.setState({ radioButtonValue: 'month' });
        }
        let bu = this.buName[newValue]
        if(this.props.tabSelectorValue == 1){
            bu = this.newName[newValue]
        }
        console.log(newValue, bu, "cekNewValue")
        this.setState({ externalTabValue: newValue }, this.props.getCollectionsData({ 'bu': bu, 'receivableTabSelectorValue' : this.props.tabSelectorValue }), this.setTitleChart(this.state.radioButtonValue, newValue));
      
    };

    handleRadioButtonChange = (event) => {

        this.setState({})

        if (event.target.value) {
            this.setState({ radioButtonValue: event.target.value }, this.setTitleChart(event.target.value, 0));
        }

        if (event.target.value === 'bu') {
            this.transformSummaryByBUData()
        }
    };

    componentDidUpdate(prevProps) {

        // if (prevProps.dateArr && this.props.dateArr && prevProps.dateArr.length != this.props.dateArr.length) {
        //     let dateArrLength = this.props.dateArr && this.props.dateArr.length
        //     let currentDefaultMonth = this.props.dateArr[dateArrLength-1]
        //     console.log('currentDefaultMonthcurrentDefaultMonth', currentDefaultMonth)
        //     let currentDateIndex = this.props.dateArr.indexOf(this.props.currentMonth)
        //     this.setState({ 
        //         currentMonth: currentDefaultMonth, 
        //         monthArr: this.props.dateArr,
        //         pastMonth:  this.props.dateArr[currentDateIndex-1]
        //     })

        // }

        if (this.props.dateArr && this.props.dateArr.length && this.state.currentMonth == '') {
            let dateArrLength = this.props.dateArr && this.props.dateArr.length
            let currentDefaultMonth = this.props.dateArr[dateArrLength-1]
        //   console.log('currentDefaultMonthcurrentDefaultMonth', currentDefaultMonth)
         
            let currentDateIndex = this.props.dateArr.indexOf(currentDefaultMonth)
            this.setState({ 
                currentMonth: currentDefaultMonth, 
                monthArr: this.props.dateArr,
                pastMonth:  this.props.dateArr[currentDateIndex-1]
            })
        }
        // console.log(prevProps.recipient, this.props.recipient, "cekProps")
        // if (prevProps.recipient == undefined && this.props.recipient != undefined) {
        //     console.log(this.props.recipient, "cekProps123")
        // //     if(this.props.recipient){
        //         this.handleRecipientEmail(this.props.recipient) 
        // //     }
        // }
        if (prevProps.dataBrowserState.tableViewState.emailTemplate.loading && !this.props.dataBrowserState.tableViewState.emailTemplate.loading) {
           
            // let dataRecip = this.props.dataBrowserState.tableViewState.emailTemplate.dataRecipient
            // console.log( dataRecip.length, "cekPropsss")
            // if (dataRecip.length != undefined ){

            //     this.handleRecipientEmail(this.props.dataBrowserState.tableViewState.emailTemplate.dataRecipient) 
            // }
            this.setState({
                // m: this.props.dataBrowserState.tableViewState.emailTemplate.dataRecipient,
                loader:false
            })
            // this.closeViewEmail()
        }
    }

    handleRadioTornadoChange = (event) => {

        const { tornadoRadioButtonValue, currentMonth } = this.state;
        let payload = { selectedDate: currentMonth, bu: "Summary", radioType: event.target.value };

        if (event.target.value !== tornadoRadioButtonValue) {
            this.setState(
                { tornadoRadioButtonValue: event.target.value }
            );
        }

    };


    handleMonthChange = (event) => {
        let date = event
        if (event.target && event.target.value) {
            console.log(this.state.summaryByBUTransformedArr, 'handleMonthChange', event.target.value)
            date = event.target.value
            this.setState({ selectedMonth: event.target.value })
        }

        const buTabArr = Object.values(this.buName)
        buTabArr.shift()
        const summaryBUArr = []
        const tempArr = this.state.summaryByBUTransformedArr.map(res => res.data.filter(x => this.transformMonthvalues(x.report_date) == date)[0])
            .map((x, i) => {
                buTabArr.map((item, index) => {
                    // console.log(item, x, "test2")
                    if (x != undefined) {
                        if (item === x.bu) {
                            summaryBUArr.splice(index, 0, x)
                        }
                    }
                })
            })

        this.setState({ summaryByBUArr: summaryBUArr })
    }

    handleTornadoLimitChange = (event) => {
        if (event.target && event.target.value) {
            this.setState({ currentLimit: event.target.value })
        }
    }


    handleTornadoMonthChange = (event) => {
        let date;
        if (event.target && event.target.value) {
            let currentDateIndex = this.props.dateArr.indexOf(event.target.value)

            date = event.target.value
            this.setState({ 
                currentMonth: event.target.value,
                pastMonth: this.props.dateArr[currentDateIndex-1]
            })
        }


        if (this.state.radioButtonValue == 'bu') {
            date = date.split('-')[1]
            const buTabArr = Object.values(this.buName)
            buTabArr.shift()
            const summaryBUArr = []
            const tempArr = this.state.summaryByBUTransformedArr.map(res => res.data.filter(x =>  {
                if (this.transformMonthvalues(x.report_date).includes(date)) {
                    return true
                }
                return false
                // this.transformMonthvalues(x.report_date).includes(date) ? true: false
            })[0])
                .map((x, i) => {
                    buTabArr.map((item, index) => {
                        console.log(item, x, "test2")
                        if (x != undefined) {
                            if (item === x.bu) {
                                summaryBUArr.splice(index, 0, x)
                            }
                        }
                    })
                })
    
            this.setState({ summaryByBUArr: summaryBUArr })
        }

    }

    transformMonthvalues = (date) => {
        return monthNames[new Date(date).getMonth()] + '-' + new Date(date).getFullYear().toString().substr(-2)
    }

    transformSummaryByBUData = () => {
        const { summaryByBUData } = this.props
        const monthArr = [...summaryByBUData.sort((a, b) => new Date(b.report_date).getTime() - new Date(a.report_date).getTime())
            .map(x => this.transformMonthvalues(x.report_date))]

        this.setState({ monthFilterArr: Array.from(new Set(monthArr)), selectedMonth: monthArr[0] });

        const tempArr = Array.from(new Set(summaryByBUData.map(x => x.bu)))
            .map(x => {
                return {
                    bu: x,
                    data: summaryByBUData.filter(res => res.bu === x)
                }
            })
        this.setState({ summaryByBUTransformedArr: tempArr }, () => this.handleMonthChange(monthArr[0]))
    }

    handleNumberTransform = (number) => {
        const isNegative = number < 0
        const positiveTransformedValue = isNegative ? Math.abs(Math.round(number)) : Math.round(number)
        let modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        return modifiedData
    }


    handlePrintClick = () => {
        this.setState({
            doExport: false,
            exportFileName: ''
        })
    }

    handleChangeView = (event) => {
        if (event) {
            this.setState(
                { GraphView: event.target.checked },
                this.refreshChart);
        }
    };

    renderEditable = cellInfo => {
        console.log('cellInfocellInfo', cellInfo)
        let modifiedData;
        let columnData = cellInfo
        modifiedData = Math.round(columnData)
        modifiedData = numberWithCommas(modifiedData)
        return (
            <div className={"dso-input"} >
                <span className='pointer' >{modifiedData}</span>
            </div>
        );
    };

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleDynamicHeader = (_data) => {
        const { tornadoData } = this.props

        let returnString = '';

        returnString = (<div> {_data.label}</div>)

        if (_data.key == 'collection_actual') {
            returnString = (<div>
                <div>{_data.label}</div>
                <div>{this.handleNumberTransform(tornadoData.total_collection_actual)}</div>
            </div>)

        }
        if (_data.key == 'collection_forecast') {
            returnString = (<div>
                <div>{_data.label}</div>
                <div>{this.handleNumberTransform(tornadoData.total_collection_forecast)}</div>
            </div>)
        }


        return returnString
    }
    handleDownloadExcel = () => {
        let datax = this.props.tornadoData;
        // console.log(data, "cekDataNIh")
        let header = datax.header.map(x => {
            return x.label;
        });
      let dataNew = datax.data;
    //   let data = dataEx.concat(dataInter).concat(dataUnbill).concat(dataTotal);
    let order = ['customer', 'collection_actual', 'collection_forecast', 'value']
      let data = dataNew.map(x => {
       
        //   order.map(y => {
        //     let data 
        //       if(y == "customer"){
        //         data = x.customer
        //       }else if(y == "collection_actual"){
        //         data = x.collection_actual
        //       }else if(y == "collection_forecast"){
        //         data = x.collection_forecast
        //     }else if(y == "value"){
        //         data = x.value
        //     }
        //       return data
        //   })
        x.customer = x.customer;
        x.collection_actual = x.collection_actual;
        x.collection_forecast = x.collection_forecast;
        x.delta = x.value;
        // delete x.collection_actual;
        // delete x.collection_forecast;
        delete x.value;
        delete x.percentage;
        delete x.valueStr;
        return x;
      });
      
      console.log(data, header, "checkData")
    //   // header.push("Receivables")
      downloadExcel(data, ` Receivables - Collection Aging Vs Collection Forecast`,
          header);
        // props.handleDownloadExcel(tabValue);
      }
    changeKeysOfHeader = (data) => {
        if (data && data.length) {
            data = data.map((item, i) => {
                let obj = {
                    Header: this.handleDynamicHeader(item),
                    id: item.key,
                    accessor: item.key,
                    headerStyle: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '13px',
                        fontWeight: 'bold',
                        backgroundColor: 'white'
                    },
                    style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "hidden", fontSize: '13px', textAlign: 'center', pointerEvents: 'none', },
                    Cell: (props) => {
                        let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                        let zeroData = newOriginal[item.key]
                        return (
                            <div>
                                <React.Fragment>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</React.Fragment>
                            </div>

                        )
                    },
                }

                return obj
            })

            return data
        }
    }

    handlePreviousMonthChange = (item ,index) => {
        let pastData = this.state.monthArr[index-1]
        this.setState({
            pastMonth: pastData
        })
    }
    openViewEmail = () => {
        this.setState({viewEmail : true})
        let payload ={
          year : this.state.ytdFilterValue,
          month : this.state.month,
          quarter : this.state.quarterValue,
          validationType: this.state.hfmRadioValue,
        }
        this.props.getEmailTemplate(payload)
    }

    openViewRecipient = () => {
        let allUser = this.props.ageingData.external.allUser
        this.setState({ allUsers: allUser });
        console.log(this.state.allUser, this.props.ageingData.external.allUser, "cekRecipient")
        this.setState({viewRecipient : true})
        let payload ={
          year : this.state.ytdFilterValue,
          month : this.state.month,
          quarter : this.state.quarterValue,
          validationType: this.state.hfmRadioValue,
        }
        // this.props.getEmailTemplate(payload)
    }
      closeViewEmail = () => {
        this.setState({viewEmail : false})
    }

    closeViewRecipient = () => {
        this.setState({viewRecipient : false})
    }
    handleSubmitTemplate = (event) => {
      const {editorMsgBody} = this.state
    
      event.preventDefault();
    
      let payload = {
        // cc_emails: [event.currentTarget.cc_emails.value],
        email_title: event.currentTarget.email_title.value,
        // email_salutation: event.currentTarget.email_salutation.value,
        email_msg2: editorMsgBody.getData(),
        // email_signature: event.currentTarget.email_signature.value
      }
    
      this.props.saveEmailTemplate(payload)
      this.setState({viewEmail : false})
      
    }
    // handleSubmitRecipient = (emailDefault) => {
    //     console.log(emailDefault, "cekSubmit")
    //     // const {emailDefault} = this.state
      
    //     // event.preventDefault();
      
    //     let payload = {

    //       recipient: emailDefault,
      
    //     }
      
    //     this.props.saveEmailRecipient(payload)
    //     this.setState({viewRecipient : false})
        
    //   }
    
    handleOpenDialog = () =>{
        if(this.state.emailDefault.length == 0){
            toastr.warning("Please Choose Recipient!")
            return
        }

      this.setState({
          openDialog : true
      })
    
    }
    handleCloseDialog = () => {
      this.setState({
          openDialog : false
      })
    }
    
    handleSendMail = () =>{
       

      const { month, ytdFilterValue, hfmRadioValue } = this.state
      let date = ytdFilterValue
      if(month != ""){
        date = month
      }
      let payload = {
        report_date: date, 
        test_email: false, 
        type_view: hfmRadioValue,
        recipient : this.state.emailDefault.map(x =>{ return x.email }),
      }
    //   console.log(payload,ytdFilterValue,month,  "cekPaypay")
      this.setState({
        loader : true
    })
      this.props.sendEmail(payload)
    //   this.handleCloseDialog()
    }

     handleChangeRecipient = (event, value) => {
       
        //  console.log(value, "cekListRecipient")
         this.setState({emailDefault : value})
        // let allFunc = value.find(x => x.text == "All Function")
        // let refinedBg;
        // let errorMsg = '';
        // if (allFunc !=undefined) {
        //     errorMsg =  '* All Function includes all the Budget Groups.';
        //     refinedBg = [{text:"All Function", value:"All Function"}]
        // }else{
        //     refinedBg = value;
        // }
        // setError(errorMsg)
        // setEmailDefault(value);
        // document.activeElement.blur()
        // setBtnDisabled(false)
    };
    // handleRecipientEmail = (recipient) => {
       
           
    //     if(recipient){
    //         let emailDefaultArr = []
    //         let allUser = this.props.ageingData.external.allUser
    //         // this.setState({ budgetGroup: allUser });
    //         console.log(recipient, "cekcek123321")
    //         allUser.map((itemUser, i) => {
    //             recipient.map((itemRecipient, i) => {
    //                 if(itemUser.email == itemRecipient.email){
    //                     let recip = {
    //                         email : itemUser.email,
    //                         full_name : itemUser.full_name
    //                     }
    //                     emailDefaultArr.push(recip)
    //                 }
    //             })
                
    //         })
            
          
    //         // setEmailDefault(emailDefaultArr);
    //         this.setState({emailDefault : emailDefaultArr })
    //     }
     
    // }
    render() {
        const { summaryByMonthData, tornadoData, dateArr, recipient } = this.props
        console.log(dateArr, "dates are ");
        let newDateArr = dateArr != undefined ? dateArr.map(x => x.slice(3)) : [];
        console.log(newDateArr, "new dates are");

        const { externalTabValue, loader, radioButtonValue, selectedMonth,openDialog,currentLimit,emailDefault, limitArr, GraphView, monthFilterArr, summaryByBUArr, monthArr, currentMonth,pastMonth, tornadoRadioButtonValue } = this.state
        const { tableViewState } = this.props.dataBrowserState
        const graphData = radioButtonValue === 'month' ? summaryByMonthData : summaryByBUArr;
        if( !this.props.unallocatedExist && radioButtonValue == 'bu' && summaryByBUArr.length > 0 ){
            summaryByBUArr.forEach((x,i)=> {
                if(x.bu == "Unallocated" && x.forecast == 0 && x.actual == 0) {
                        summaryByBUArr.splice(i,1);
                }
            })

        }
        const actual = tornadoData.collection_actual;
        const forecast = tornadoData.collection_forecast;
        const tableHeaderData = this.changeKeysOfHeader(tornadoData.header);
        let  emailData =  tableViewState? tableViewState.emailTemplate.data : [];
        let users = this.props.ageingData.external.allUser;
        if( users.length > 0){
            users = users.sort((a, b) => {
                return a.full_name.trim().localeCompare(b.full_name.trim())
            })
        }
        return (
            <>
                <Tabs
                    ref={el => (this.container = el)}
                    value={externalTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    className='mb-10'
                    onChange={this.handleExternalTabChange}
                > 
                   <LinkTab label="Summary" href="/summary" className="list-nested" {...a11yProps("sum")} />
                   
                   {this.props.tabSelectorValue == 0 &&<LinkTab label="BU 1" href="/msm" className="list-nested" {...a11yProps(0)} />}
                   {this.props.tabSelectorValue == 0 &&<LinkTab label="BU 2" href="/lcs" className="list-nested" {...a11yProps(1)} />}
                   {this.props.tabSelectorValue == 0 &&<LinkTab label="BU 3" href="/dgs" className="list-nested" {...a11yProps(2)} />}
                   {this.props.tabSelectorValue == 0 &&<LinkTab label="BU 4" href="/pab" className="list-nested" {...a11yProps(3)} /> }

                  
                    {this.props.tabSelectorValue == 1 &&<LinkTab label="New BU 1" href="/dass" className="list-nested" {...a11yProps(4)} />}
                    {this.props.tabSelectorValue == 1 &&<LinkTab label="New BU 2" href="/cp" className="list-nested" {...a11yProps(5)} />}
                    { this.props.unallocatedExist && this.props.tabSelectorValue == 1 &&<LinkTab label="Unallocated" href="/unallocated" className="list-nested" {...a11yProps(6)} /> }
                  
                </Tabs>
                {externalTabValue == 3 && this.props.tabSelectorValue == 0 &&
                <Grid container spacing={3}>
                    <Grid item xs={1} sm={2} className='maxw-150'  >
              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                endIcon={<Icon>visibility</Icon>}
                                onClick={this.openViewEmail}
                              >
                                View Email
                              </Button>  
                              </Grid>
                              <Grid item xs={1} sm={2} className='maxw-150'  >

<Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialog}
                              >
                                Send Email
                              </Button>
                              </Grid>
                    <Grid item xs={1} sm={3} >

                    <Autocomplete
                    multiple
                    limitTags={1}
                    value={this.state.emailDefault}
                    options={users}
                    getOptionLabel={(option) => option.full_name}
                    onChange={this.handleChangeRecipient}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label=""
                            placeholder="Recipient"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                id:"field-1"
                            }}
                        />
                    )}
                    getOptionSelected={(option,val) => option.email == val.email}
                    renderTags={(value, { params, onDelete }) =>
                        value.map((option, index) => (
                            <Tooltip title={option.full_name}>
                            <Chip
                                {...params}
                                key={option.email}
                                label={option.full_name.length > 9 ? option.full_name.substr(0,9) + ".." : option.full_name }
                                size="small"
                                onDelete={onDelete}
                            />
                            </Tooltip>
                        ))
                    }
                    renderOption={(option) => <Typography
                        style={{ fontSize: '12px' }}
                        noWrap>
                         <span>{option.full_name}</span>
                    </Typography>}
                />

                    {/* <Select
                    multiple
                    className="minw-200"
                    value={this.state.emailDefault}
                    onChange={this.handleChangeRecipient}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    { users.map((name) => (
                        <MenuItem key={name.email} value={name.email} >
                            {name.full_name}
                        </MenuItem>
                    ))}
                </Select> */}

                              {/* <Autocomplete
                              multiple
                              id="tags-standard"
                              options={ this.props.ageingData.external.allUser != undefined ? this.props.ageingData.external.allUser : []}
                              defaultValue={this.state.emailDefault}
                              getOptionSelected = {(option, val) => option.email == val.email}
                              getOptionLabel = {(option) => option.full_name}
                              onChange={this.handleChangeRecipient}
                             
                              renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="standard"
                                  label=""
                                  placeholder="Email Recipient"
                              />
                              )}
                              renderOption={(option) => {
                                  return (
                                      <Typography style={{fontSize:'15px'}} noWrap >
                                          <span>{option.full_name}</span>
                                      </Typography> 
                                  );
                              }}
                          /> */}
                              </Grid>

                    

                              </Grid>
                              
                              }
                               
                              <Modal
                                    open={this.state.viewEmail}
                                    onClose={this.closeViewEmail}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                   
                                >
                                    <Fade in={this.state.viewEmail}>
                                        {tableViewState  && tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> : 
                                        <div className='customModal viewEmailModalWide'>
                                            <div className='header'>
                                                <h2>View Email</h2>
                                            </div>
                                            <div className='modal-content email-template'>
                                                <form onSubmit={this.handleSubmitTemplate}>
                                                    <TextField
                                                      id="email_title"
                                                      label="Title of Email"
                                                      style={{ margin: 8 }}
                                                      placeholder=""
                                                      helperText=""
                                                      fullWidth
                                                      defaultValue= {emailData.email_title}
                                                      margin="normal"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <TextField
                                                      id="email_salutation"
                                                      label="Salutation"
                                                      style={{ margin: 8 }}
                                                      defaultValue= "Dear username,"
                                                      margin="normal"
                                                      disabled 
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    
                                                    <FormControl style={{ marginTop: 8 ,  marginLeft: 10 }}>
                                                        <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                                        <CKEditor
                                                            config={{
                                                                removePlugins: ['Link','EasyImage','CKFinder','Indent','Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                                            }}
                                                            id="editor-msg-body"
                                                            name="emailView"
                                                            editor={ ClassicEditor }
                                                            disabled 
                                                            data= {emailData.email_msg}
                                                            onReady={editor => {
                                                              this.setState({editorMsgBody: editor})
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <p className="ml-10">{emailData.app_link}</p>
                                                    
                                                    <TextField
                                                      id="email_signature"
                                                      label="Signature"
                                                      style={{ margin: 10 }}
                                                      defaultValue= {emailData.email_signature}
                                                      margin="normal"
                                                      disabled 
                                                      fullWidth
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <div className="flex jc-flex-end mt-30">
                                                        {/* <Button type="submit" variant="contained" color="primary">Submit</Button> */}
                                                        <Button variant="contained" className='ml-16' onClick={this.closeViewEmail}>Cancel</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        }
                                        
                                    </Fade>
                              </Modal>

                            
                              {/* <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className = "ml-20"
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialogTest}
                              >
                                Send Test Email
                              </Button> 
                              <Dialog
                                open={openDialogTest}
                                onClose={this.handleCloseDialogTest}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send the test email?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus onClick={this.handleCloseDialogTest} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMailTest} variant="outlined" size="small" color="primary">
                                    Send Test Email
                                  </Button>
                                </DialogActions>
                              </Dialog> */}


                            
                             

                              <Dialog
                                open={openDialog}
                                onClose={this.handleCloseDialog}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send it?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus onClick={this.handleCloseDialog} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMail} variant="outlined" size="small" color="primary">
                                    Send Email
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              <Modal
                                    open={loader}
                                    onClose={loader}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                   
                                >
                                    <Fade in={loader}>
                                    {tableViewState  && tableViewState.emailTemplate.loading && loader == true? <div className='text-center'><CircularProgress /></div> :  ""}

                                        </Fade>
                                        </Modal>
                {externalTabValue === 0   &&
                    <>
                   {radioButtonValue != 'month' && <Grid container justify="flex-start" style={{ maxWidth: '600px' }}>

                    <Grid item xs={12} sm={3} className='maxw-150'>
                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={currentMonth}
                                            label="Month"
                                            onChange={this.handleTornadoMonthChange}
                                        >
                                            {newDateArr && newDateArr.length && newDateArr.map((item, i) => {
                                                return <MenuItem value={dateArr[i]} key={i} onClick={() =>{this.handlePreviousMonthChange(item, i)} }>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                </Grid> }
                        <FormControl component="fieldset">
                            <RadioGroup name="radio" onChange={this.handleRadioButtonChange} value={radioButtonValue} className='radioGroupHorizontal'>
                                <FormControlLabel value="month" control={<Radio color="primary" />} label="Collection by Month" />
                                <FormControlLabel value="bu" control={<Radio color="primary" />} label="Collection by BU" />
                                <FormControlLabel value="tornado" control={<Radio color="primary" />} label="Collections vs Forecast" />
                            </RadioGroup>
                        </FormControl>

                        {/* {radioButtonValue === 'bu' &&
                            <Grid container justify="flex-end" style={{ maxWidth: '600px' }}>
                                <Grid item xs={12} sm={4} className='maxw-200'>
                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={selectedMonth}
                                            label="Month"
                                            onChange={this.handleMonthChange}
                                        >
                                        
                                            {monthFilterArr && monthFilterArr.length && monthFilterArr.map((item, i) => {
                                                return <MenuItem value={item} key={i}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        } */}

                        {radioButtonValue === 'tornado' &&
                            <Grid container justify="flex-start">
                                

                               {GraphView && <Grid item xs={12} sm={3} className='maxw-150'>
                                    <FormControl variant="outlined" className='selectOutlineSmall maxw-200' size='small'>
                                        <InputLabel>Limit</InputLabel>
                                        <Select
                                            value={currentLimit}
                                            label="Limit"
                                            onChange={this.handleTornadoLimitChange}
                                        >
                                            {limitArr && limitArr.length && limitArr.map((item, i) => {
                                                return <MenuItem value={item.value} key={item.key}>{item.value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid> }

                                <Grid item xs={12} sm={3} className='' style={{ marginLeft: '10px' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup name="radio" onChange={this.handleRadioTornadoChange} value={tornadoRadioButtonValue} className='radioGroupHorizontal'>
                                            <FormControlLabel value="Third" control={<Radio color="primary" />} label="Third Party" />
                                            <FormControlLabel value="SLB" control={<Radio color="primary" />} label="SLB" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4} className=''>
                                    <div className='ml-20'>
                                        <label>Table</label>
                                        <Switch
                                            className="custom-switch"
                                            checked={GraphView}
                                            onChange={this.handleChangeView}
                                            color="primary"
                                            name="toggleTableGraph"
                                        />
                                        <label>Graph</label>
                                    </div>
                                </Grid>
                              
                                
                            </Grid>
                        }
                         {GraphView === false &&  <div className="flex ai-center jc-space-between mb-10"> <Grid container spacing={3}></Grid><Tooltip title={'Download to Excel'}>
                                <img
                                src={DownloadExcel}
                                height='30'
                                className='pointer'
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleDownloadExcel();
                                }} />
                                </Tooltip></div>}
                    </>
                }

                {(radioButtonValue != "tornado") ?
                    <div className="mt-20">
                        {this.props.loading ? <div className='text-center'><CircularProgress /></div> :
                            <>
                                {graphData ?
                                    <BarGraph
                                        chartTitle={this.state.chartTitleActuals}
                                        graphData={graphData}
                                        externalTabValue={externalTabValue}
                                        viewType={radioButtonValue}
                                        transformValues={(date) => this.transformMonthvalues(date)}
                                    /> :
                                    <h3 className='text-center'>There is no data for the selected BU</h3>
                                }
                            </>
                        }
                    </div> :
                    <div className='mt-20 budgetAnalysis'>
                        {GraphView ? 
                        <div>
                            <h3 className='primary text-center'><b>Collections vs Forecast</b></h3>
                            {
                                // (forecast && actual) ?
                                <NegativeBarGraph
                                    forecast={forecast}
                                    actual={actual}
                                    tornadoData={tornadoData}
                                    fetchData={this.props.fetchTornadoData}
                                    tabSelectorValue = {this.props.tabSelectorValue}
                                    tornadoRadioButtonValue={tornadoRadioButtonValue}
                                    lastDate={currentMonth}
                                    pastMonth={pastMonth}
                                    transformData={(number) => this.handleNumberTransform(number)}
                                    handlePrintCallback={this.handlePrintClick}
                                    doExport={this.state.doExport}
                                    currentLimit={currentLimit}
                                    fileName={this.state.exportFileName}
                                />
                                // :
                                // <h3 className='text-center'>There is no data for the selected date</h3>
                            }
                        </div> :
                        
                            <TableComponent
                                tornadoData={tornadoData}
                                tableHeaderData={tableHeaderData}
                                forecast={forecast}
                                actual={actual}
                                currentLimit={currentLimit}
                                fetchData={this.props.fetchTornadoData}
                                tabSelectorValue = {this.props.tabSelectorValue}
                                tornadoRadioButtonValue={tornadoRadioButtonValue}
                                lastDate={currentMonth}
                                transformData={(number) => this.handleNumberTransform(number)}
                                handlePrintCallback={this.handlePrintClick}
                                doExport={this.state.doExport}
                                fileName={this.state.exportFileName}
                            />
                        }
                    </div>
                }
  {
                <ModalUser
                  showModal={this.state.viewRecipient}
                  emailDefault ={emailDefault}
                  allUsers = {this.state.allUsers}
                //   handleChangeRecipient = {this.handleChangeRecipient}
                  handleSubmitRecipient= {this.handleSubmitRecipient}
                  closeViewRecipient = {this.closeViewRecipient}
                />
              }
            </>
         
            
            
       
        );

      
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                summaryByMonthData: state.dashboardState.dashboardAnalytic.Collections?.data[0]?.summaryByMonthResult,
                summaryByBUData: state.dashboardState.dashboardAnalytic.Collections?.data[0]?.summaryByBUResult,
                loading: state.dashboardState.dashboardAnalytic.Collections.loading,
                unallocatedExist: state.dashboardState.dashboardAnalytic.Collections.unallocatedExist,
                currentMonth: state.userManagementState.autoPaymentState.selectedDateMonthly,
                dateArr: state.userManagementState.autoPaymentState.reportDateArrMonthly,
                tornadoData: state.invoiceListReportState.tornadoData,
                dataBrowserState: state.dataBrowser,
                ageingData: state.dashboardState.receivableData.ageingData,
                recipient: state.dashboardState.dashboardAnalytic.Collections?.data[1]?.recipient,
                
            }
        },
        (dispatch) => {
            return {
                getCollectionsData(data) {
                    dispatch({
                        type: "FETCH_COLLECTIONS_GRAPH",
                        payload: data,
                    });
                },
                getEmailTemplate(data){
                    dispatch({
                        type: 'FETCH_EMAIL_TEMPLATE_DGS_VALIDATION',
                        payload : data
                    });
                },
                fetchReportDate(data) {
                    dispatch({
                        type: 'FETCH_AGING_REPORT_DATE_MONTHLY',
                        payload: data,
                    });
                },
                fetchTornadoData(data) {
                    dispatch({
                        type: 'FETCH_TORNADO_DATA_MONTHLY',
                        payload: data,
                    })
                },
                sendEmail(data){
                    dispatch({
                      type : "SEND_MAIL_DGS",
                      payload : data
                    });
                  },
                  saveEmailRecipient(data){
                    dispatch({
                      type: "SAVE_EMAIL_RECIPIENT_DGS",
                      payload : data
                    })
                },
            }
        }
    )(CollectionsComponent)
);