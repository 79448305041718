import React from "react";

export default function AI() {

    return (
        <div>
        <iframe 
        style={{ display: 'block', width: '100%', minHeight: 800 }}
        src="https://xpnademo.eaciitapp.com/dashboard/hotel-prices-analysis/"></iframe>
        </div>
    )
}