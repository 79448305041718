import { transformFilterData } from './transformer'
import { numberWithCommas } from '../../utils/numberUtils'

const reducer = (state = {
   loading: false,
   emailTemplateLoading: false,
   hfmBalance: {},
   slBalance: {},
   delta: {},
   comments: {},
   error: false,
   deltaValue: 0,
   isIncomeStatement: false,
   monthFilterArr: [],
   quarterfilterArr: [],
   yearFilterArr: [],
   ytdFilterArr: [],
   tableViewState: {
      loading: false,
      emailTemplate : {
         loading : true,
         data : {},
         dataRecipient : {}
      },
      data: {}
   },
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));

   switch (action.type) {

      case 'FETCH_DATABROWSER_DATA_LOADER':
         return { ...state, loading: action.loading };
      case 'FETCH_DATABROWSER_EMAIL_LOADER':
         newState.tableViewState.emailTemplate.loading = action.loading;
         return newState
      case 'FETCH_DATABROWSER_EMAIL_SAVE_RECIPIENT_SUCCESS':
         newState.tableViewState.emailTemplate.loading = false;
         newState.tableViewState.emailTemplate.dataRecipient = action.data;
            return newState
      case 'FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS':
         newState.tableViewState.emailTemplate.loading = false;
         newState.tableViewState.emailTemplate.data = action.data;
         return newState

      case 'FETCH_DATABROWSER_VALIDATION_SUCCESS':
         newState.loading = action.loading;
         newState.loading = false;
         newState.hfmBalance = action.data.hfm;
         newState.delta = action.data.delta
         newState.slBalance = action.data.internal
         newState.deltaValue = numberWithCommas(Math.round(action.data.delta && action.data.delta.row_delta));
         return newState;

      case 'FETCH_DATABROWSER_VALIDATION_INCOME_SUCCESS':
         newState.loading = action.loading;
         newState.loading = false;
         newState.hfmBalance = action.data.hfm;
         newState.delta = action.data.delta;
         newState.slBalance = action.data.internal;
         newState.deltaValue = numberWithCommas(Math.round(action.data.delta && action.data.delta.row_delta));
         var { monthFilterArr, quarterfilterArr, yearFilterArr, ytdFilterArr } = transformFilterData(action.data.filter);
         newState.monthFilterArr = monthFilterArr;
         newState.quarterfilterArr = quarterfilterArr;
         newState.yearFilterArr = yearFilterArr;
         newState.ytdFilterArr = ytdFilterArr;
         return newState;

      case 'FETCH_DATABROWSER_COMMENT_SUCCESS':
         newState.comments = action.data;
         return newState;

      case 'FETCH_DATABROWSER_COMMENT_INCOME_SUCCESS':
         newState.comments = action.data;
         return newState;

      case 'IS_INCOME_STATEMENT_DATA_BROWSER':
         newState.isIncomeStatement = action.data;
         return newState;

      default:
         return newState;
   }
};
export default reducer;



