import React from 'react';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
//import TextField from '@material-ui/core/TextField';
//import { Box, FormControlLabel, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

//Mui-v5 components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './filters.scss';


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'fixed',
    // width: theme.spacing.unit * 50,
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
    padding: theme.spacing(4),
    outline: 'none',
  },
});

class SimpleModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalNameValue: '',
      headerLabel: '',
      radioCopyValue : 'extend-to',
      cellsCopyValue : [],
      cellsCopyArr : [],
    }
  }

  componentDidMount() {
    const currentValue = this.props.currentData
    const headerLabel = currentValue['header_label'] ? currentValue['header_label'] :  'Formula for Cell ' + currentValue['column_index'] + currentValue['row_index']
    const cellId=currentValue['cell_id'] ? currentValue['cell_id'] : currentValue['column_index'] + currentValue['row_index']
    this.setState({ 
      modalNameValue: this.props.currentData ? this.props.currentData['value'] : '' ,
      headerLabel: headerLabel,
      cellId: cellId,
      radioCopyValue: this.props.currentData ? this.props.currentData.action ? this.props.currentData.action : '' : '',
      cellsCopyValue: this.props.currentData ? this.props.currentData.action_values ? this.props.currentData.action_values : [] : [],
      cellsCopyArr: this.props.currentData ? this.props.currentData.cellsList ?  this.props.currentData.cellsList.sort().filter((value, index, array) => array.indexOf(value) === index) : [] : []
    });
  }
  setModalNameValue = (value) => {
		this.setState({ modalNameValue: value });
	};

  setRadioCopyValue = (event) => {
		this.setState({ radioCopyValue: event.target.value });
	};

  changeCellsCopyHandler = (event, newValue) => {
		this.setState({ cellsCopyValue: newValue });
  }

  handleSave = () => {
    const {modalNameValue , radioCopyValue, cellsCopyValue} = this.state
    let payload = {
      value : modalNameValue,
      action : 'extend-to',
      action_values : cellsCopyValue,
    }

    this.props.handleSaveData(payload)
  }


  render() {
    const { classes } = this.props;
    const {modalNameValue, headerLabel, radioCopyValue, cellsCopyValue, cellsCopyArr, cellId} = this.state
  //console.log(cellsCopyArr, "---cellsCopyArr");
  console.log(cellsCopyValue, "copy value")


    

    return (
        

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={() => {this.props.toggleModal()}}
            >
              <Draggable>
                <div style={getModalStyle()} className={classes.paper}>
                    <div className="close-modal"
                        title={"Close Modal"}
                    >
                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <h1>{ headerLabel }</h1>
                        <CloseIcon className="close-icon" onClick={this.props.toggleModal}/>
                      </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'baseline'}}>
                    <div style={{width:'100px', display:'flex', justifyContent:'center', marginTop:'30px'}}>{cellId?`${cellId} =` : '='}</div>
                    <TextField id="name-textfield" value={modalNameValue} placeholder="Enter Formula" variant="standard" onChange={(e) => {this.setModalNameValue(e.target.value)}} fullWidth/>
                    </div>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'baseline'}}>
                    <div style={{width:'100px', display:'flex', justifyContent:'center',  marginTop:'30px'}}>{'Extend to'}</div>
                      {/* <FormControl fullWidth>
                            <Select value={cellsCopyValue} placeholder={"Select cell(s)"} multiple={true} onChange={ this.changeCellsCopyHandler } className='' fullWidth>
                                <MenuItem value="" disabled>
                                    <em>Select</em>
                                </MenuItem>
                                {cellsCopyArr &&
                                  cellsCopyArr.length &&
                                  cellsCopyArr.map((item) => {
                                      return <MenuItem value={item} key={item}>{item}</MenuItem>

                                    })}
                            </Select>
                      </FormControl> */}
                      <Autocomplete
                        disablePortal
                        disableCloseOnSelect
                        multiple
                        size='small'
                        className='new-autocomplete'
                        value={cellsCopyValue}
                        options={cellsCopyArr}
                        onChange={this.changeCellsCopyHandler}
                        fullWidth
                        place
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder={cellsCopyValue.length==0 ?'Select cells' : ''}
                          />)}
                        renderTags={(value, getTagProps)=> (
                          <div>
                            {value.map((option, index) => {
                             if (index==value.length-1)
                             {return <span key={index} {...getTagProps({ index })}>{option}</span>}
                             else if (index<value.length-1)
                             {return <span key={index} {...getTagProps({ index })}>{option}, </span>}
                              })}
                          </div>
                        )}

                        />


                    </div>
                    <Button variant='contained' color="primary" className='newButton btnBlue btnSubmit uppercase mt-20' fullWidth onClick={ this.handleSave }>OK</Button>
                </div>
              </Draggable>
            </Modal>
      );
  }
}

// SimpleModal.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// We need an intermediary variable for handling the recursive nesting.
const CustomFunctionModal = withStyles(styles)(SimpleModal);

export default CustomFunctionModal;
