import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import './esfForecast.scss';
import { TextField, Grid } from "@material-ui/core";
import {handleTableTransform} from "../../../utils/numberUtils";
import DownloadExcel from '../../../images/download_excel.svg';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {getAccessByMenuKey} from "../../../utils/userManagement";
import {APP_ICONS} from "../../../constant";

const ESFComponent = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [tables, setTables] = useState({
    table1: { headers: [], rowData: [], metaData: [] },
    table2: { headers: [], rowData: [], metaData: [] },
    table3: { headers: [], rowData: [], metaData: [] },
  });

  const handleDownloadExcel = async () => {
    try {
      const payload = {
        cohort: "",
        time: "",
        export: true
      };

      const response = await fetch('https://xpnademo.eaciitapp.com/api/v2/incomestatement/getesf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      // Handle blob response (Excel file)
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;

      // Correctly access the content-disposition header
      const contentDisposition = response.headers.get('content-disposition');
      console.log(contentDisposition, "Content-Disposition header");

      // Check if content-disposition header exists before splitting
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        link.download = filenameMatch ? filenameMatch[1] : 'download.xlsx';
      } else {
        link.download = 'download.xlsx';
      }

      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error:', error);
      toastr.error('There was a problem loading the data');
    }
  };

  const generateFileName = (fname) => {
    let dateFileName = fname.split("-").slice(2, 7).join("-")
    const splitDateFileName = fname.split(".")

    let extFile = "xlsx"
    if (splitDateFileName.length > 1) {
      extFile = fname.split(".")[1]
    }

    return fname.slice(0, (fname.length-dateFileName.length))+moment().format('MM-DD-YYYY hh-mm-ss')+"."+extFile;
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const payload = {
        cohort: "",
        time: "",
        export: false
      };
      const response = await fetch('https://xpnademo.eaciitapp.com/api/v2/incomestatement/getesf', {
      // const response = await fetch('http://localhost:9091/api/v2/incomestatement/getesf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('ESF', result);
      setData(result);
      setLoading(false)
    } catch (error) {
      toastr.error('There was a problem loading the data');
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      const newTables = {
        table1: {
          headers: changeKeysOfHeader(data.case_one?.row_header),
          rowData: data.case_one?.row_data || [],
          metaData: data.case_one?.additional_data || {},
        },
        table2: {
          headers: changeKeysOfHeader(data.case_two?.row_header),
          rowData: data.case_two?.row_data || [],
          metaData: data.case_two?.additional_data || {},
        },
        table3: {
          headers: changeKeysOfHeader(data.case_three?.row_header),
          rowData: data.case_three?.row_data || [],
          metaData: data.case_three?.additional_data || {},
        },
      };
      setTables(newTables);
    }
  }, [data]);

  const changeKeysOfHeader = (data) => {
    if (data && data.length) {
      return data.map((item) => ({
        Header: item.label,
        id: item.key,
        width: 120,
        accessor: item.key,
        Cell: (props) => (
          <div className='text-center'>
            <span>{props.original[item.key] ? handleTableTransform(props.original, item) : '-'}</span>
          </div>
        ),
      }));
    }
    return [];
  };

  const formatNumberWithCommas = (number) => {
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  const AdditionalDataComponent = ({ id }) => {
    const metaData = tables[`table${id}`].metaData || {};
    const { Alpha = 'NA', MAD = 'NA', MAPE = 'NA', NFE = 'NA', Forecast = 'NA' } = metaData;

    const month = id == 1 ? '30-Apr-24' : id == 2? "29-Feb-24" : "31-Mar-24"

    return (
      <div className="segment-metadata mt-10 pl-10 pt-10 pb-10 pr-10" style={{ backgroundColor: 'white' }}>
        <div className="mt-2 mb-2">Future Forecast ({ month }): {formatNumberWithCommas(Forecast)}</div>
        <div className="mt-2 mb-2">Alpha: {Alpha}</div>
        <div className="mt-2 mb-2">MAD: {formatNumberWithCommas(MAD)}</div>
        <div className="mt-2 mb-2">MAPE: {MAPE}</div>
        <div className="mt-2 mb-2">NFE: {NFE}</div>
      </div>
    );
  };

  const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

  return (
    <div>
      <div className="header-section">
        <div className="header-section-left mb-20">
          <div style={{ fontSize: "18px" }}>
            ESF Forecasting Model
          </div>
        </div>
      </div>
      <div className="filters-section">
        <div className='flex mb-10'>
          <Grid container spacing={3} style={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={2} className='maxw-200'>
              <TextField
                  label='Cohort'
                  value='Bu P&L'
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2} className='maxw-200'>
              <TextField
                  label='Time'
                  value='QTR'
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  size="small"
              />
            </Grid>
            <Grid item xs={12} sm={8} className="text-end">
                  <Tooltip title={'Download to Excel'}>
                    <img
                        src={downloadExcelIcon}
                        height='35'
                        className='pointer'
                        onClick={(event) => {
                          event.preventDefault();
                          handleDownloadExcel();
                        }}
                    />
                  </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="segment-tables flex" style={{ justifyContent: 'space-evenly', width: '100%', maxWidth: '1400px' }}>
        {['one', 'two', 'three'].map((id, index) => {
          const table = tables[`table${index + 1}`];
          return loading ? <div className="loading"> Table Loading. Please Wait</div>:
            table.rowData.length > 0 ? (
            <div key={id}>
              <div className="table-header mt-2 mb-5"> {`Qtr - Month${index + 1}`}</div>
              <ReactTable
                className="-striped -highlight xpna-react-table height-unset single-line-header"
                data={table.rowData}
                columns={table.headers}
                showPagination={false}
                minRows={0}
                sortable={false}
              />
              <AdditionalDataComponent id={index + 1} />
            </div>
          ) : (
            <div key={id}>Table not Found</div>
          );
        })}
      </div>
    </div>
  );
};

export default ESFComponent;
