import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Switch, Route } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import MapIcon from '@material-ui/icons/Map';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function Sidebar(props) {
  return (
    <Drawer
      className="drawer-sidebar-filter"
      variant="permanent"
      anchor="left"
    >
      <div className="parent-toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <div className="title">Analytics</div>
      </div>
      <Divider />
      <List>
          {["Profit Margin %", "Fuel Consumption/km", "Actual seat km", "Load Factor", "On time Performance", "Avg. Turn around time", "On time arrival"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
      </List>
     <Divider />
     <div className="parent-toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
        >
          <VisibilityIcon />
        </IconButton>
        <div className="title">Sort Routes by</div>
      </div>
      <FormControl variant="outlined" size="small" className="filter mr-10 ml-10">
        <InputLabel htmlFor="outlined-age-native-simple">Financial Performance</InputLabel>
        <Select
          native
          label="Age"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" className="filter mr-10 ml-10 mt-10">
        <InputLabel htmlFor="outlined-age-native-simple">Route Length</InputLabel>
        <Select
          native
          label="Age"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
        </Select>
      </FormControl>
      <div className="top-profitable mt-20">
          <div className="header flex jc-center">Top Profitable Routes</div>
          <div className="region-list">
              {["Region 1","Region 2","Region 3","Region 4","Region 5","Region 6","Region 7","Region 8","Region 9","Region 10"].map((text, index) => (
                <Button variant="outlined" size="small" color="primary" className="btn-region">{text}</Button>
              ))}
         </div>
      </div>
    </Drawer>
  );
}
