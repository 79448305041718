import axios from 'axios';
import { CONFIG } from '../../../config'

export async function fetchGeospacialAnalysisService(data) {
  const {bu, type, month, year, quarter} = data.payload;
  let url = `${CONFIG.api_base_url}v1/automation/New/${bu}/geospatial-analysis?type=${type}`;

  if(month){
    url += `&month=${month}`;
  }

  if(year){
    url += `&year=${year}`;
  }

  if(quarter){
    url += `&quarter=${quarter}`;
  }

  return axios.get(url)
    .then(response => response)
    .catch(err => err.response);
}

export async function fetchChatDataService(payload) {

    return axios.post(`${CONFIG.api_base_url}v1/chat/bulk/channel/history/`, payload, { withCredentials: true }).then(
        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
}

