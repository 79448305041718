import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { useHistory, Link as Linkroute } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import '../../Receivable/Receivable.scss';
import './Sidebar.scss';
import NotAvailableAlert from '../../common/components/Alerts/Alerts';
import ModalWithTable from '../../common/components/ModalWithTable';

import { Switch, Route } from 'react-router-dom';
// import ReceivableComponent from '../../Receivable';
// import ImpactAnalysis from '../../Receivable/ImpactAnalysis';
// import VarianceAnalysis from '../../Receivable/VarianceAnalysis';
// import FCFImprovement from '../../Receivable/FCFImprovement';
// import CadenceBoard from '../../Receivable/CadenceBoard';
// import CreateInitiatives from '../../Receivable/CreateInitiatives';
// import ViewInitiatives from '../../Receivable/ViewInitiatives';

// import ECFM from '../../ECFM'
// import Artificial from '../../AI'
import EBITDA from '../../poc-shreecement/Ebitda'
// import GeoSpatialOverview from '../../Ebitda/GeoSpatialOverview/index.jsx';
// import ActualBUData from '../../ExtendedPlanning/ActualBUData'
// import SimulationLogic from '../../ExtendedPlanning/SimulationLogic'
// import AddEditSimulationLogic from '../../ExtendedPlanning/SimulationLogic/AddEditSimulation'
// import BUPlanData from '../../ExtendedPlanning/BuPlan'
// import BUPlanvsActual from '../../ExtendedPlanning/BUPlanvsActual'
// import AnalyticsModule from '../../ECFM/AnalyticsModule'
// import RiskManagementModule from '../../ECFM/RiskManagement'
// import OpportunityModule from '../../ECFM/OpportunityManagement'
// import NewOriginationComponent from '../../IncomeStatement/NewOrigination'


// import IncomeStatementModule from '../../IncomeStatement';
// import DataBrowserModule from '../../DataBrowser';
// import GeoLocationComponent from '../../GeoLocation';
// import UserMaster from '../../UserManagement/UserMaster';
// import UserAnalytics from '../../UserManagement/UserAnalytics';
// import RoleMaster from '../../UserManagement/RoleMaster';
// import EBITDAUploadComponent from '../../UserManagement/MenuMaster/EBITDAUpload';
// import ARUploadComponent from '../../UserManagement/MenuMaster/ARUpload';
// import ISUploadComponent from '../../UserManagement/MenuMaster/ISUpload';
// import PABUploadComponent from '../../UserManagement/MenuMaster/PABUpload';
// import ImpactAnalysisUpload from '../../UserManagement/MenuMaster/ImpactAnalysisUpload';
// import ForecastUploadComponent from '../../UserManagement/MenuMaster/ForecastUpload';
// import NewUploadComponent from '../../UserManagement/MenuMaster/NewUpload';
import { getAccessByMenuKey } from '../../../utils/userManagement';
// import AnalyticsStudioComponent from '../../FreeCashFlow';
// import DataReportAnalyticsStudio from '../../FreeCashFlow/AnalyticsStudio-New/DataReport';


// //svg icons
// //Home page
// import UserFileUploadIcon from "../../../images/sidebar/svgicons/user-file-upload.svg";
// import CustomReportsIcon from "../../../images/sidebar/svgicons/custom-reports.svg";
// import DashboardsInsightsIcon from "../../../images/sidebar/svgicons/dashboards&insights.svg";
// import AnalyticsStudioIcon from "../../../images/sidebar/svgicons/analytics-studio.svg";
// import ImpactAnalysisIcon from "../../../images/sidebar/svgicons/impact-analysis.svg";
// import InitiativesIcon from "../../../images/sidebar/svgicons/initiatives.svg";
// import PlanningSimulationIcon from "../../../images/sidebar/svgicons/planning&simulation.svg";
// import FCFImprovementIcon from "../../../images/sidebar/svgicons/viewFCFimprovement.svg";
// import AISolutionsIcon from "../../../images/sidebar/svgicons/ai-solutions.svg";


// //Sidebar
// import FileUploadIcon from "../../../images/sidebar/svgicons/file-upload.svg";
// import AutomatedDataValidationIcon from "../../../images/sidebar/svgicons/auto-data-validate.svg";
// import DataQualityToolIcon from "../../../images/sidebar/svgicons/data-quality-tool.svg";
// import DataBrowserIcon from "../../../images/sidebar/svgicons/data-browser.svg";
// import IncomeStatementIcon from "../../../images/sidebar/svgicons/income-statement.svg";
// import ReceivablesIcon from "../../../images/sidebar/svgicons/receivables.svg";
// import AutoDistributionIcon from "../../../images/sidebar/svgicons/auto-distribution.svg";
// import FCFReportsIcon from "../../../images/sidebar/svgicons/fcf-reports.svg";
// import VarianceAnalysisIcon from "../../../images/sidebar/svgicons/var-analysis.svg";
// import AnalyticsModuleIcon from "../../../images/sidebar/svgicons/analytics-module.svg";
// import GeoSpatialAnalysisIcon from "../../../images/sidebar/svgicons/geospatial-analysis.svg";
// import KeyFocusAreasIcon from "../../../images/sidebar/svgicons/key-focus.svg";
// import OverdueARIcon from "../../../images/sidebar/svgicons/overdue-ar.svg";
// import OverheadSpendIcon from "../../../images/sidebar/svgicons/overhead-spend.svg";
// import SONegativeMarginIcon from "../../../images/sidebar/svgicons/so-negative-margin.svg";
// import ForecaseFileUploadIcon from "../../../images/sidebar/svgicons/forecast-file-upload.svg";
// import PLFileUploadIcon from "../../../images/sidebar/svgicons/pl-file-upload.svg";
// import CadenceBoardIcon from "../../../images/sidebar/svgicons/cadence-board.svg";
// import ViewInitiativesIcon from "../../../images/sidebar/svgicons/view-initiatives.svg";
// import CreateInitiativesIcon from "../../../images/sidebar/svgicons/create-initiatives.svg";
// import KPIPlanningIcon from "../../../images/sidebar/svgicons/kpi-planning.svg";
// import DetailedPlanningIcon from "../../../images/sidebar/svgicons/detailed-planning.svg";
// import ExtendedPlanningIcon from "../../../images/sidebar/svgicons/extended-pna.svg";
// import ARFileUploadIcon from "../../../images/sidebar/svgicons/ar-upload.svg";
// import ChartsDashboardsIcon from "../../../images/sidebar/svgicons/charts-dashboards.svg";
// import ReportsIcon from "../../../images/sidebar/svgicons/reports-icon.svg";
// import ImprovementPlansIcon from "../../../images/sidebar/svgicons/improvement-plans.svg";
// import PLReportsIcon from "../../../images/sidebar/svgicons/pl-reports.svg";
// import DataValidationToolIcon from "../../../images/sidebar/svgicons/data-validation-tool.svg";
// import BUTargetIcon from "../../../images/sidebar/svgicons/bu-target.svg";
// import BUSummaryLocationIcon from "../../../images/sidebar/svgicons/bu-summary-location.svg";
// import BUSummaryPMIcon from "../../../images/sidebar/svgicons/bu-summary-PM.svg";
// import RiskManagementIcon from "../../../images/sidebar/svgicons/risk-mgmt.svg";
// import OpportunityManagementIcon from "../../../images/sidebar/svgicons/opportunity-mgmt.svg";
// import BUPlanvsActualIcon from "../../../images/sidebar/svgicons/plan-vs-actual.svg";
// import ImpactAnalysisFileUploacIcon from "../../../images/sidebar/svgicons/impact-analysis-file-upload.svg";
// import BUPlanIcon from "../../../images/sidebar/svgicons/bu-plan.svg";
// import BUActualIcon from "../../../images/sidebar/svgicons/bu-actual.svg";
// import SimulationLogicIcon from "../../../images/sidebar/svgicons/simulation-logic.svg";
// import InvoiceReportsIcon from "../../../images/sidebar/svgicons/invoice-reports.svg";
// import OverdueAnalysisIcon from "../../../images/sidebar/svgicons/overdue-ar.svg";
// import HundredKdaysReportsIcon from "../../../images/sidebar/svgicons/100k-120days-report.svg";
// import PlanSummaryIcon from "../../../images/sidebar/svgicons/plan-summary.svg";
// import AgingReportIcon from "../../../images/sidebar/svgicons/aging-report.svg";
// import DSOAnalysisIcon from "../../../images/sidebar/svgicons/dso-analysis.svg";
// import OverdueAutomatedFollowupIcon from "../../../images/sidebar/svgicons/overdue-auto-followup.svg";
// import AutoEscalationIcon from "../../../images/sidebar/svgicons/auto-escalation.svg";
// import ColPlanvsActualIcon from "../../../images/sidebar/svgicons/plan-vs-actual.svg";
// import HFMDataValidationIcon from "../../../images/sidebar/svgicons/data-validate-hfm.svg";
// import SalesTechPLIcon from "../../../images/sidebar/svgicons/sales-tech-pl.svg";
// import FunctionalPLIcon from "../../../images/sidebar/svgicons/functional-pl.svg";
// import OriginationPLIcon from "../../../images/sidebar/svgicons/origination-pl.svg";
// import BUPLIcon from "../../../images/sidebar/svgicons/bu-pl.svg";
// import DestinationPLIcon from "../../../images/sidebar/svgicons/destination-pl.svg";





// //Other Icons
// import AutomatedDataValidation from "../../UserManagement/AutomatedDataValidation";
// import PLRawDataValidation from "../../UserManagement/P&LRawDataValidation";
// import RoleMasterBlack from '../../../images/role-master-black-new.png';
// import RoleMasterWhite from "../../../images/role-master-white-new.png";
// import UserMasterBlack from '../../../images/user-master-black-new.png';
// import UserMasterWhite from "../../../images/user-master-white-new.png";
// import UserAnalyticsBlack from "../../../images/user-analytics-black-new.png";
// import UserAnalyticsWhite from "../../../images/user-analytics-white-new.png";
import GeoSpatialOverview from '../../poc-shreecement/Ebitda/GeoSpatialOverview/index.jsx';
import FunctionalPlanningComponent from '../../poc-shreecement/Planning/index.jsx'


import EbitdaNew from '../../../images/svgicons/ebitda-mgmt.svg';
import GeoSpatialNew from '../../../images/svgicons/geospatial-overview.svg';
import GroupOverheadNew from '../../../images/svgicons/GroupOverhead.svg';
import ProcessOverheadNew from '../../../images/svgicons/ProcessOverhead.svg';
import CapexNew from '../../../images/svgicons/Capex.svg';
import AiBasedNew from '../../../images/svgicons/AiBased.svg';
import PlanningNew from '../../../images/svgicons/planning.svg';


import IntegratedPlanningIcon from "../../../images/svgicons/IntegratedPlanning.svg";
import FinancialPlanningIcon from "../../../images/svgicons/FinancialPlanning.svg";
import InventoryIcon from "../../../images/svgicons/Inventory.svg";
import ManufacturingIcon from "../../../images/svgicons/Manufacturing.svg";
import ReceivableIcon from "../../../images/svgicons/Receivable.svg";
import ResourcePlanningIcon from "../../../images/svgicons/ResourcePlanning.svg";
import SalesOperationIcon from "../../../images/svgicons/SalesOperation.svg";
import ExtendedPlanningIcon from "../../../images/svgicons/extended-pna.svg";
import SimulationPlanningIcon from "../../../images/svgicons/simulation-planning.svg";

import { connect, useSelector } from 'react-redux';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: '50px !important',
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
}));

const UserManagementNavigation = [
    // {
    //     text: "Role Master",
    //     link: "/usermanagement/role-master",
    //     selected: false,
    //     Icon: RoleMasterBlack,
    //     IconSelected: RoleMasterWhite,
    //     Submenu: [],
    //     routeId: 'role-master',
    //     id: 1,
    //     available: true,
    //     key: "role_master"
    // },
    // {
    //     text: "User Master",
    //     link: "/usermanagement/user-master",
    //     Icon: UserMasterBlack,
    //     IconSelected: UserMasterWhite,
    //     selected: false,
    //     Submenu: [],
    //     routeId: 'user-master',
    //     id: 2,
    //     available: true,
    //     key: "user_master"
    // },
    // {
    //     text: "User Analytics",
    //     link: "/usermanagement/user-analytics",
    //     Icon: UserAnalyticsBlack,
    //     IconSelected: UserAnalyticsWhite,
    //     selected: false,
    //     Submenu: [],
    //     routeId: 'user-analytics',
    //     id: 2,
    //     available: true,
    //     key: "user_analytics"
    // },
    // {
    //     text: "Upload Data Validation",
    //     link: "/usermanagement/p&l-raw-data-validation",
    //     Icon: PLValBlack,
    //     IconSelected: PLValWhite,
    //     IconMain: PLValMain,
    //     selected: false,
    //     Submenu: [],
    //     routeId: 'p&l-raw-data-validation',
    //     id: 2,
    //     available: true,
    //     key: "automated_data_validation"
    // },
]

const DashboardNavigation = [
	{
		id: 2,
		text: 'Planning',
		subText: '',
		link: '/dashboard/sandbox/planning',
		Icon: PlanningNew,
		show: true,
		key: 'income_statement',
        Submenu: [
            {
                text: "Functional Planning",
                link: "/dashboard/sandbox/planning/func-planning",
                Icon: PlanningNew,
                toolTipText: 'Functional Planning',
                selected: false,
                routeId: 'functional-planning',
                open: true,
                key: "income_statement",
            },
            {
                text: "Simulation Planning",
                link: "/dashboard/sandbox/planning/simulation-based",
                Icon: SimulationPlanningIcon,
                toolTipText: 'Simulation Based Planning',
                selected: false,
                routeId: 'simulation-based',
                open: true,
                key: "income_statement",
            },
            {
                text: "Integrated Planning",
                link: "/dashboard/sandbox/extended-planning",
                Icon: ExtendedPlanningIcon,
                Submenu: [
                    {
                        text: "Financial Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: FinancialPlanningIcon,
                        toolTipText: 'Financial Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },
                    {
                        text: "Sales & Operations Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: SalesOperationIcon,
                        toolTipText: 'Sales & Operations Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },
                    {
                        text: "Workforce Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: ResourcePlanningIcon,
                        toolTipText: 'Workforce Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },
                    {
                        text: "Project Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: IntegratedPlanningIcon,
                        toolTipText: 'Project Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    }, 
                    {
                        text: "Capex Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: ManufacturingIcon,
                        toolTipText: 'Production Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },                                 
                    {
                        text: "Inventory Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: InventoryIcon,
                        toolTipText: 'Inventory Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },
                    {
                        text: "Receivables Planning", 
                        link: "/dashboard/sandbox/extended-planning/bu-4-pm",
                        Icon: ReceivableIcon,
                        toolTipText: 'Receivables Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        available:false,
                        key: "income_statement",
                    },                  
                ],
                toolTipText: 'Integrated Planning',
                selected: false,
                routeId: 'extended-planning',
                open: true,
                key: "income_statement",
            }
        ],
        routeId: 'planning'

	},
	{
		id: 1,
		text: 'EBITDA Management',
		subText: '',
		link: '/dashboard/sandbox/ebitda',
		Icon: EbitdaNew,
		show: true,
		key: 'income_statement',
        routeId: 'ebitda'
	},
	{
		id: 4,
		text: 'Geospatial Overview',
		subText: '',
		link: '/dashboard/sandbox/geospatialoverview',
		Icon: GeoSpatialNew,
		show: true,
		key: 'income_statement',
        routeId: 'geospatialoverview'
	},
	{
		id: 5,
		text: 'Capex Management',
		subText: '',
		link: '/dashboard/panel',
		Icon: CapexNew,
		show: true,
		key: 'income_statement',
        available : false,
	},
	{
		id: 0,
		text: 'Process Overhead Allocation',
		subText: '',
		link: '/dashboard/panel',
		Icon: ProcessOverheadNew,
		show: true,
		key: 'receivables',
        available : false,
	},
	{
		id: 0,
		text: 'General Overhead Allocation',
		subText: '',
		link: '/dashboard/panel',
		Icon: GroupOverheadNew,
		show: true,
		key: 'receivables',
        available : false,
	},
	{
		id: 0,
		text: 'AI based Sales, Mfg, Logistics Planning',
		subText: '',
		link: '/dashboard/panel',
		Icon: AiBasedNew,
		show: true,
        available : false,
		key: 'receivables',
	},
	
];

// const DashboardNewNavigation =  [
//     {
//         id: 0,
//         text: "xP&A",
//         link: "/dashboard/xpna",
//         Icon: UserAnalyticsBlack,
//         IconSelected: UserAnalyticsWhite,
//         selected: false,
//         routeId: 'xpna',
//         key: "income_statement",
//         Submenu: [
//             {
//                 text: "BU 4 Target",
//                 link: "/dashboard/xpna/bu-4-target",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Target hoc Reports',
//                 selected: false,
//                 routeId: 'bu-4-target',
//                 open: true,
//                 key: "income_statement",
//             },
//             {
//                 text: "BU 4 Summary by Location", 
//                 link: "/dashboard/xpna/bu-4-location",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Summary by Location',
//                 selected: false,
//                 routeId: 'bu-4-location',
//                 open: true,
//                 key: "income_statement",
//             },
//             {
//                 text: "BU 4 Summary by PM", 
//                 link: "/dashboard/xpna/bu-4-pm",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Summary by PM',
//                 selected: false,
//                 routeId: 'bu-4-pm',
//                 open: true,
//                 key: "income_statement",
//             }
//         ]
//     },
//     {
//         id: 3,
//         text: "Free Cashflow Analytics",
//         link: "/dashboard/free-cashflow-analytics",
//         Icon: FreeCashFlowBlack,
//         IconSelected: FreeCashFlowWhite,
//         Submenu: [
//             {
//                 text: "Improve KPIs and Free Cashflow",
//                 link: "/dashboard/free-cashflow-analytics/free-cashflow",
//                 IconMain: ImprovementMainIcon,
//                 toolTipText: 'A collaborative planning and execution tool',
//                 Icon: ImprovementPlansBlack,
//                 IconSelected: ImprovementPlansWhite,
//                 Submenu: [],
//                 routeId: 'free-cashflow',
//                 selected: false,
//                 key: "plan_summary"
//             },
//              {
//                 text: "Analytics Module",
//                 link: "/dashboard/dash-analysis/analytics-module",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'Analytics Module',
//                 Submenu: [],
//                 selected: false,
//                 routeId: 'analytics-module',
//                 open: true,
//                 key: "dashboard_analytics"
//             },
//             {
//                 text: "Key Focus Areas",
//                 link: "/dashboard/free-cashflow-analytics/key-focus",
//                 Icon: OverdueAutomatedBlack,
//                 IconSelected: OverdueAutomatedWhite,
//                 IconMain: ReportMainIcon,
//                 toolTipText: 'Key Focus Areas',
//                 selected: false,
//                 routeId: 'key-focus',
//                 open: true,
//                 key: "dashboard_analytics",
//                 Submenu: [
//                     {
//                         text: "Risk Management",
//                         link: '/dashboard/free-cashflow-analytics/risk-management',
//                         Icon: RiskBlack,
//                         IconSelected: RiskWhite,
//                         IconMain: ImprovementMainIcon,
//                         toolTipText: 'Risk Management',
//                         selected: false,
//                         routeId: 'risk-management',
//                         key: "dashboard_analytics"
//                     },
//                     {
//                         text: "Opportunity Management",
//                         link: '/dashboard/free-cashflow-analytics/opportunity-management',
//                         Icon: RiskBlack,
//                         IconSelected: RiskWhite,
//                         IconMain: AgingReportMain,
//                         toolTipText: 'Opportunity Management',
//                         selected: false,
//                         routeId: 'opportunity-management',
//                         key: "dashboard_analytics"
//                     }

//                 ],
//             },
//             // {
//             //     text: "Risk Management",
//             //     link: "/dashboard/free-cashflow-analytics/risk-management",
//             //     Icon: FreeCashFlowBlack,
//             //     IconSelected: FreeCashFlowWhite,
//             //     IconMain: ImprovementMainIcon,
//             //     toolTipText: 'Risk Management',
//             //     Submenu: [],
//             //     selected: false,
//             //     routeId: 'risk-management',
//             //     open: true,
//             //     key: "dashboard_analytics"
//             // }
            

//         ],
//         routeId: 'free-cashflow-analytics',
//         selected: false,
//         available: true,
//         key: "improvement_modules"
//     },
//     {
//         id: 0,
//         text: "Custom Reports",
//         link: "/dashboard/custom-reports",
//         Icon: IncomeStatementBlack,
//         IconSelected: IncomeStatementWhite,
//         selected: false,
//         routeId: 'custom-reports',
//         key: "income_statement",
//         Submenu: [
//             {
//                 id: 1,
//                 text: "Income Statement",
//                 link: "/dashboard/income-statement",
//                 Icon: IncomeStatementBlack,
//                 IconSelected: IncomeStatementWhite,
//                 IconMain: ISMainNew,
//                 selected: false,
//                 routeId: 'income-statement',
//                 key: "income_statement",
//                 Submenu: [
//                     {
//                         text: "Charts, Dashboard & Analytics", //Ad hoc Charts
//                         link: "/dashboard/income-statement/ad-hoc",
//                         Icon: DashboardAnalyticsBlack,
//                         IconSelected: DashboardAnalyticsWhite,
//                         IconMain: DashboardMainIcon,
//                         toolTipText: 'Ad hoc Reports',
//                         selected: false,
//                         routeId: 'ad-hoc',
//                         open: true,
//                         key: "ad_hoc",
//                         // Submenu: [
//                         //     {
//                         //         text: "T&E Analysis",
//                         //         link: '/dashboard/income-statement/ad-hoc/tne',
//                         //         Icon: DataValidationIcon,
//                         //         IconSelected: DataValidationIcon,
//                         //         IconMain: DataValidation,
//                         //         selected: false,
//                         //         routeId: 'tne',
//                         //         key: "tne"
//                         //     },
//                         //     {
//                         //         text: "Overtime Analysis",
//                         //         link: '/dashboard/income-statement/ad-hoc/overtime',
//                         //         Icon: HFMDataIcon,
//                         //         IconSelected: HFMDataIcon,
//                         //         IconMain: HFMData,
//                         //         selected: false,
//                         //         routeId: 'overtime',
//                         //         key: "overtime"
//                         //     },
//                         //     {
//                         //         text: "Ad hoc Charts",
//                         //         link: '/dashboard/income-statement/ad-hoc/adhoc-chart',
//                         //         Icon: Scatter,
//                         //         IconSelected: Scatter,
//                         //         IconMain: ScatterMain,
//                         //         selected: false,
//                         //         routeId: 'adhoc-chart',
//                         //         key: "adhoc_chart"
//                         //     },
//                         // ]
//                     },
//                     {
//                         text: "P&L Reports",
//                         link: '/dashboard/income-statement/p&lreports',
//                         IconMain: PlMain,
//                         toolTipText: 'Income Statement - Operational and Functional Reports',
//                         Icon: PlBlack,
//                         IconSelected: PlWhite,
//                         open: false,
//                         routeId: 'p&lreports',
//                         key: "pnl_reports",
//                         Submenu: [
//                             {
//                                 text: "New Origination P&L",
//                                 link: '/dashboard/income-statement/p&lreports/one-new-origination',
//                                 Icon: NewBlack,
//                                 IconSelected: NewWhite,
//                                 IconMain: NewMain,
//                                 selected: false,
//                                 routeId: 'one-new-origination',
//                                 key: "bu_pnl"
//                             },
//                             {
//                                 text: "New Destination P&L",
//                                 link: '/dashboard/income-statement/p&lreports/one-new',
//                                 Icon: NewDestinationBlack,
//                                 IconSelected: NewDestinationWhite,
//                                 IconMain: NewDestinationMain,
//                                 selected: false,
//                                 routeId: 'one-new',
//                                 key: "bu_pnl"
//                             },
        
//                             {
//                                 text: "Functional P&L",
//                                 link: '/dashboard/income-statement/p&lreports/functional-pl',
//                                 Icon: PlfBlack,
//                                 IconSelected: PlfWhite,
//                                 IconMain: PlfMain,
//                                 selected: false,
//                                 routeId: 'functional-pl',
//                                 key: "func_pnl"
//                             },
//                             // {
//                             //     text: "MPR",
//                             //     link: '/dashboard/income-statement/p&lreports/mpr',
//                             //     Icon: MprBlack,
//                             //     IconSelected: MprWhite,
//                             //     IconMain: MprMain,
//                             //     selected: false,
//                             //     routeId: 'mpr',
//                             //     key: "mpr"
//                             // },
//                             {
//                                 text: "Sales & Technology P&L",
//                                 link: '/dashboard/income-statement/p&lreports/sales-technology-pl',
//                                 Icon: salesPlBlack,
//                                 IconSelected: salesPlWhite,
//                                 IconMain: salesPlMain,
//                                 selected: false,
//                                 routeId: 'sales-technology-pl',
//                                 key: "func_pnl"
//                             },
//                             {
//                                 text: "BU P&L",
//                                 link: '/dashboard/income-statement/p&lreports/bu-pl',
//                                 Icon: BuplBlack,
//                                 IconSelected: BuplWhite,
//                                 IconMain: BuplMain,
//                                 selected: false,
//                                 routeId: 'bu-pl',
//                                 key: "bu_pnl"
//                             },
//                         ],
//                     },
                    
//                     {
//                         text: "Data Validation Tool",
//                         link: "/dashboard/income-statement/data-browser",
//                         IconMain: DatabrowserMainIcon,
//                         Icon: DataBrowserBlack,
//                         selected: false,
//                         IconSelected: DataBrowserWhite,
//                         toolTipText: 'Data validation - HFM data reconcillation',
//                         key: "databrowser",
//                         routeId: 'data-browser',
//                         Submenu: [
//                             {
//                                 text: "Data Validation - HFM",
//                                 link: '/dashboard/income-statement/data-browser/validation',
//                                 Icon: AutomatedDataValidationBlack,
//                                 IconSelected: AutomatedDataValidationWhite,
//                                 IconMain: DataValidation,
//                                 selected: false,
//                                 routeId: 'validation',
//                                 key: "data_validation"
//                             },
//                             // {
//                             //     text: "Data Validation - SAP",
//                             //     link: '/dashboard/income-statement/data-browser/hfm',
//                             //     Icon: HFMDataIcon,
//                             //     IconSelected: HFMDataIcon,
//                             //     IconMain: HFMData,
//                             //     selected: false,
//                             //     routeId: 'hfm',
//                             //     key: "hfm_data"
//                             // },
//                         ],
//                     },
//                 ]
//             },
//             {
//                 id: 2,
//                 text: "Receivables",
//                 link: "/dashboard/receivables",
//                 selected: false,
//                 Icon: ReceivablesBlack,
//                 IconSelected: ReceivablesWhite,
//                         IconMain: RecMain,
//                         key: "receivables",
//                 routeId: 'receivables',
//                 Submenu: [
//                     {
//                         text: "Charts, Dashboard & Analytics",
//                         link: "/dashboard/receivables/dashboard-analytics",
//                         Icon: DashboardAnalyticsBlack,
//                         IconSelected: DashboardAnalyticsWhite,
//                         IconMain: DashboardMainIcon,
//                         toolTipText: 'DSO, Overdue analysis and Account Owner Performance',
//                         selected: false,
//                         routeId: 'dashboard-analytics',
//                         open: true,
//                         key: "dashboard_analytics",
//                         Submenu: [
//                             {
//                                 text: "DSO Analysis",
//                                 selected: false,
//                                 Icon: DSOAnalysisBlack,
//                                 routeId: 'dso',
//                                 IconSelected: DSOAnalysisWhite,
//                                 IconMain: DSOAnalysisMain,
//                                 link: '/dashboard/receivables/dashboard-analytics/dso',
//                                 key: "dashboard_dso"
//                             },
//                             {
//                                 text: "Overdue Analysis",
//                                 link: '/dashboard/receivables/dashboard-analytics/overdue',
//                                 selected: false,
//                                 Icon: OverdueAnalysisBlack,
//                                 IconSelected: OverdueAnalysisWhite,
//                                 IconMain: OverdueAnalysisMain,
//                                 routeId: 'overdue',
//                                 key: "dashboard_overdue"
//                             },
//                             {
//                                 text: "Overdue Automated Followup",
//                                 link: '/dashboard/receivables/dashboard-analytics/scatter',
//                                 Icon: OverdueFollowupBlack,
//                                 IconSelected: OverdueFollowupWhite,
//                                 IconMain: OverdueFollowupMain,
//                                 selected: false,
//                                 routeId: 'scatter',
//                                 key: "dashboard_scatter"
//                             },
//                             {
//                                 text: "Automated Escalations",
//                                 link: '/dashboard/receivables/dashboard-analytics/escalation',
//                                 Icon: AutomatedEscalationsBlack,
//                                 IconSelected: AutomatedEscalationsWhite,
//                                 IconMain: AutomatedEscalationsMain,
//                                 selected: false,
//                                 routeId: 'escalation',
//                                 key: "dashboard_escalations"
//                             },
//                             {
//                                 text: "Collections Actual v/s Collections Forecast",
//                                 link: '/dashboard/receivables/dashboard-analytics/collections',
//                                 Icon: CollectionsAvFBlack,
//                                 IconSelected: CollectionsAvFWhite,
//                                 IconMain: CollectionsAvFMain,
//                                 selected: false,
//                                 routeId: 'collections',
//                                 key: "dashboard_collections"
//                             },
//                         ],
//                     },
//                     {
//                         text: "Reports",
//                         link: "/dashboard/receivables/reports",
//                         IconMain: ReportMainIcon,
//                         toolTipText: 'Aging reports by Business Unit and Customer',
//                         Icon: ReportsBlack,
//                         routeId: 'reports',
//                         IconSelected: ReportsWhite,
//                         selected: false,
//                         key: "reports",
//                         Submenu: [
//                             {
//                             text: "Aging Reports",
//                             link: '/dashboard/receivables/reports/aging',
//                             Icon: AgingReportBlack,
//                             IconSelected: AgingReportWhite,
//                             IconMain: AgingReportMain,
//                             selected: false,
//                             routeId: 'aging',
//                             key: "aging_reports"
//                         },
//                         {
//                             text: "100k-120 Day Reports",
//                             link: '/dashboard/receivables/reports/day-reports',
//                             Icon: DayReportBlack,
//                             IconSelected: DayReportWhite,
//                             IconMain: DayReportMain,
//                             selected: false,
//                             routeId: 'day-reports',
//                             key: "100k-120_days_reports"
//                         },
//                         {
//                             text: "Invoice List Report",
//                             link: '/dashboard/receivables/reports/invoice_list',
//                             Icon: InvoiceListReportBlack,
//                             IconSelected: InvoiceListReportWhite,
//                             IconMain: InvoiceReportMain,
//                             selected: false,
//                             routeId: 'invoice_list',
//                             key: "invoice_list"
//                         },
//                         {
//                             text: "Monthly Status Report",
//                             link: '/dashboard/receivables/reports/month_status_report',
//                             Icon: InvoiceListReportBlack,
//                             IconSelected: InvoiceListReportWhite,
//                             IconMain: InvoiceReportMain,
//                             selected: false,
//                             routeId: 'month_status_report',
//                             key: "aging_reports"
//                         },
//                         ],
//                     },
//                     {
//                         text: "Improvement Plans",
//                         link: "/dashboard/receivables/improvement-plans",
//                         IconMain: ImprovementMainIcon,
//                         toolTipText: 'A collaborative planning and execution tool',
//                         Icon: ImprovementPlansBlack,
//                         IconSelected: ImprovementPlansWhite,
//                         Submenu: [
//                             {
//                                 text: "Plan Summary",
//                                 link: "/dashboard/receivables/improvement-plans/plan-summary",
//                                 IconMain: ImprovementMainIcon,
//                                 toolTipText: 'A collaborative planning and execution tool',
//                                 Icon: ImprovementPlansBlack,
//                                 IconSelected: ImprovementPlansWhite,
//                                 Submenu: [],
//                                 routeId: 'plan-summary',
//                                 selected: false,
//                                 key: "plan_summary"
//                             },
        
//                         ],
//                         routeId: 'improvement-plans',
//                         selected: false,
//                         key: "improvement_plan"
//                     },
//                     {
//                         text: "Data Validation Tool",
//                         link: "/dashboard/receivables/os/data-browser",
//                         IconMain: DatabrowserMainIcon,
//                         Icon: DataBrowserBlack,
//                         selected: false,
//                         routeId: 'data-browser',
//                         IconSelected: DataBrowserWhite,
//                         toolTipText: 'Data validation - HFM data reconcillation',
//                         key: "databrowser",
//                         Submenu: [
//                             // {
//                             //     text: "Data Validation - HFM",
//                             //     link: '/dashboard/receivables/data-browser/validation',
//                             //     Icon: AutomatedDataValidationBlack,
//                             //     IconSelected: AutomatedDataValidationWhite,
//                             //     IconMain: DataValidation,
//                             //     selected: false,
//                             //     routeId: 'validation',
//                             //     key: "data_validation"
//                             // },
//                             // {
//                             //     text: "Data Validation - SAP",
//                             //     link: '/dashboard/receivables/data-browser/hfm',
//                             //     Icon: HFMDataIcon,
//                             //     IconSelected: HFMDataIcon,
//                             //     IconMain: HFMData,
//                             //     routeId: 'hfm',
//                             //     selected: false,
//                             //     key: "hfm_data"
//                             // },
//                         ],
//                     },
//                 ]
//             },
//         ]
//     },
   
//     {
//         id: 4,
//         text: "Dashboard",
//         link: "/dashboard/dash-analysis",
//         Icon: GeoSpatialBlack,
//         IconSelected: GeoSpatialWhite,
//         selected: false,
//         Submenu: [
//             {
//                 text: "Geospatial Analysis",
//                 link: '/dashboard/dash-analysis/geo-location',
//                 Icon: AutomatedDataValidationBlack,
//                 IconSelected: AutomatedDataValidationWhite,
//                 IconMain: DataValidation,
//                 selected: false,
//                 routeId: 'geo-location',
//                 key: "data_validation"
//             },
//             {
//                 text: "Overdue AR",
//                 link: '/dashboard/receivables/dashboard-analytics/scatter',
//                 Icon: OverdueArBlack,
//                 IconSelected: OverdueArWhite,
//                 openInNewTab: true,
//                 IconMain: OverdueAr,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//             {
//                 text: "SO’s with negative margins",
//                 link: '/dashboard/income-statement/p&lreports/one-new',
//                 Icon: NegativeMarginBlack,
//                 IconSelected: NegativeMarginWhite,
//                 openInNewTab: true,
//                 IconMain: NegativeMargin,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//             {
//                 text: "Overhead spend vs Budget",
//                 link: '/dashboard/income-statement/p&lreports/functional-pl',
//                 Icon: OverheadSpendBlack,
//                 IconSelected: OverheadSpendWhite,
//                 openInNewTab: true,
//                 IconMain: OverheadSpend,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//         ],
//         routeId: 'dash-analysis',
//         key: "income_statement"
//     },
//     {
//         id: 5,
//         text: "Analytics Studio",
//         link: "/dashboard/analytics-studio",
//         Icon: AnalyticsStudioBlack,
//         IconSelected: AnalyticsStudioWhite,
//         selected: false,
//         Submenu: [],
//         routeId: 'analytics-studio',
//         key: "income_statement"
//     },
//     {
//         id: 6,
//         text: "Data Validation Tool",
//         link: "/dashboard/user-file-upload/main-data-browser",
//         Icon: AnalyticsStudioBlack,
//         IconSelected: AnalyticsStudioWhite,
//         selected: false,
//         Submenu: [],
//         routeId: 'main-data-browser',
//         key: "income_statement"
//     }

// ]

const NestedList = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { menuData } = props;
	const [onLoad, setOnLoad] = React.useState(true);
	const [breadcrumbsList, setBreadcrumbsList] = React.useState([]);
	const [plReportAlignment, setPlReportAlignment] = React.useState(false);
	const [adHocAlignment, setAdHocAlignment] = React.useState(false);
	const [navigationArray, setNavigationArray] = React.useState([]);
	const [navigationBoxArray, setNavigationBoxArray] = React.useState();
	const [navigationSelectedRouteId, setNavigationSelectedRouteId] =
		React.useState('');
	const [navigationBoxArrayIndex, setNavigationBoxArrayIndex] =
		React.useState(null);
	const [headerText, setHeaderText] = React.useState('Home');
	const [OpenNA, setOpenNA] = React.useState(false);
	const [showModalWithTableComponent, setShowModalWithTableComponent] =
		React.useState(false);

	// Drawer Collapsed/Expanded State
	const [levelOne, setLevelOne] = React.useState(true);
	const [levelTwo, setLevelTwo] = React.useState(true);
	const [levelThree, setLevelThree] = React.useState(true);
	const [levelFour, setLevelFour] = React.useState(true);

	// Drawer Visible/Hidden State
	const [showLevelOne, setShowLevelOne] = React.useState(true);
	const [showLevelTwo, setShowLevelTwo] = React.useState(false);
	const [showLevelThree, setShowLevelThree] = React.useState(false);
	const [showLevelFour, setShowLevelFour] = React.useState(false);

	// Top Level Active Index in navigation Array
	const [levelOneIndex, setLevelOneIndex] = React.useState(null);
	const [levelTwoIndex, setLevelTwoIndex] = React.useState(null);
	const [levelThreeIndex, setLevelThreeIndex] = React.useState(null);
	const [levelFourIndex, setLevelFourIndex] = React.useState(null);

	const [tabSelectorValue, setTabSelectorValue] = React.useState(0);
	const tabMapper = { 0: 'Legacy BU', 1: 'New BU' };
	const loginState = useSelector((state) => state);
	const { authReducer } = loginState;
    let thisUrl = window.location.pathname

    if(thisUrl.indexOf("menu-master") == -1 && thisUrl.indexOf("analytics-studio") == -1){
        localStorage.removeItem('show-back-fcf')
    }

	const handleRemoveDrawer = (id = null) => {
		switch (id) {
			case 1:
				setShowLevelOne(false);
				setShowLevelTwo(false);
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
			case 2:
				setShowLevelTwo(false);
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
			case 3:
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
            case 4:
                setShowLevelFour(false);
                break;
			default:
				break;
		}
	};

	const handleClickBreadcrums = (data, index) => {
	
		switch (index) {
			case 0:
				const shouldMoveForward = exitWithoutModalCondition(data.link);
				if (!shouldMoveForward) {
					return;
				}
				history.push(data.link);

				break;
			case 1:
				handleFirstLevelMenuClick(levelOneIndex);
				break;
			case 2:
				handleSecondLevelMenuClick(levelTwoIndex);
                break;
            case 3:
                handleThirdLevelMenuClick(levelThreeIndex);
				break;
            case 4:
                handleFourthLevelMenuClick(levelFourIndex);
			default:
				break;
		}
	};

	const handleMenuClick = (index, levelIndex) => {
		switch (levelIndex) {
			case 1:
				handleFirstLevelMenuClick(index);
				break;
			case 2:
				handleSecondLevelMenuClick(index);
				break;
			case 3:
				handleThirdLevelMenuClick(index);
				break;
            case 4:
                handleFourthLevelMenuClick(index);
                break;
			default:
				break;
		}
	};

	const setLevel = (index) => {
		switch (index) {
			case 1:
				setLevelOne(!levelOne);
				break;
			case 2:
				setLevelTwo(!levelTwo);
				break;
			case 3:
				setLevelThree(!levelThree);
				break;
            case 4:
                setLevelFour(!levelFour);
                break;
        default:
            break;
    }
	};

	const exitWithoutModalCondition = (link) => {
		if (props.exitWithoutSaveFlag) {
			props.showExitWithoutSaveModal({
				showModal: true,
				nextLocation: { pathname: link },
			});
			return false;
		} else {
			props.showExitWithoutSaveModal({
				showModal: false,
				nextLocation: '',
			});
		}
		return true;
	};

	const handleFirstLevelMenuClick = (index) => {
		const shouldMoveForward = exitWithoutModalCondition(
			navigationArray[index].link
		);
		if (!shouldMoveForward) {
			return;
		}
		if (navigationArray[index].available === false) {
			setOpenNA(true);
			return;
		}
		setLevelOneIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(false);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(navigationArray[index].Submenu);
		setNavigationSelectedRouteId(navigationArray[index].routeId);
		setNavigationBoxArrayIndex(1);
		setHeaderText(navigationArray[index].text);
		setBreadcrumbsList([breadcrumbsList[0], navigationArray[index]]);
		history.push(navigationArray[index].link);
	};

	const handleSecondLevelMenuClick = (index) => {
        let selectedItem = navigationArray[levelOneIndex]?.Submenu[index]
        if (selectedItem['openInNewTab']) {
            localStorage.setItem('openInNewTab', true)
        }
		const shouldMoveForward = exitWithoutModalCondition(
			navigationArray[levelOneIndex].Submenu[index].link
		);
		if (!shouldMoveForward) {
			return;
		}
		setLevelTwoIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[index].Submenu
		);
        let thisRoute = navigationArray[levelOneIndex].Submenu[index].routeId
		setNavigationSelectedRouteId(thisRoute)
		setNavigationBoxArrayIndex(2);
		setHeaderText(navigationArray[levelOneIndex].Submenu[index].text);
      
		if (
			thisRoute &&
			thisRoute == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				{ text: tabMapper[tabSelectorValue] },
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				navigationArray[levelOneIndex].Submenu[index],
			]);
		}
		history.push(navigationArray[levelOneIndex].Submenu[index].link);
	};

	const handleThirdLevelMenuClick = (index) => {
		setLevelThreeIndex(index);
        setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(false);
		setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(3);
		setHeaderText(navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].text);

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				breadcrumbsList[2],
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].link
		);
	};

    const handleFourthLevelMenuClick = (index) => {
		setLevelFourIndex(index);
        setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(true);
        setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(4);
		setHeaderText(navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].text);
	
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				breadcrumbsList[2],
				breadcrumbsList[3],
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index],
			]);
		history.push(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].link
		);
	};

	const handleSecondLevelMenuClickDefault = (index, levelOne) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(navigationArray[levelOne].Submenu[index].Submenu);
		// setNavigationSelectedRouteId(navigationArray[levelOne].Submenu[index].routeId)
		setNavigationBoxArrayIndex(2);
		setHeaderText(navigationArray[levelOne].Submenu[index].text);
		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[index],
			]);
		}
		history.push(navigationArray[levelOne].Submenu[index].link);
	};

	const handleThirdLevelMenuClickDefault = (index, levelOne, levelTwo) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(levelTwo);
		setLevelThreeIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(false);
		setNavigationBoxArray(null);
		// setNavigationSelectedRouteId('')
		setNavigationBoxArrayIndex(3);
		setNavigationBoxArray(navigationArray[levelOne].Submenu[levelTwo].Submenu[index].Submenu);
		setHeaderText(navigationArray[levelOne].Submenu[levelTwo].Submenu[index].text);

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOne].Submenu[levelTwo].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[levelTwo],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[index].link
		);
	};

    const handleFourthLevelMenuClickDefault = (index, levelOne, levelTwo, levelThree) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(levelTwo);
		setLevelThreeIndex(levelThree);
		setLevelFourIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(true);
		
        setNavigationBoxArray(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(4);
		setHeaderText(navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].text);
	

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].link
		);
	};

	const handleBoxItemClick = (index, item) => {

        if (item['openInNewTab']) {
            localStorage.setItem('openInNewTab', true)
            window.open(item['link'])
            return;
        }


        if (navigationBoxArrayIndex === 2) {
			setShowLevelThree(true);
			handleThirdLevelMenuClick(index);
			return;
		}

        if (navigationBoxArrayIndex === 3) {
			setShowLevelFour(true);
			handleFourthLevelMenuClick(index);
			return;
		}
		setShowLevelTwo(true);
		handleSecondLevelMenuClick(index);
	};

	const handleSetOpenNA = () => {
		setOpenNA(false);
	};

	const autoClick = () => {
        const thisPath = window.location.pathname.replace("sandbox/","")
		const urlArray = thisPath.split('/');
		if (urlArray[2]) {
			navigationArray.map((item, index) => {
				if (item.routeId == urlArray[2]) {
					if (urlArray.length > 3) {
						item.Submenu.map((sub_item, sub_index) => {
							if (sub_item.routeId == urlArray[3]) {
								if (urlArray.length > 4) {
									sub_item.Submenu.map(
										(third_level_item, third_level_index) => {
											if (third_level_item.routeId == urlArray[4]) {
                                                if (urlArray.length > 5) {
                                                    sub_item.Submenu.map(
                                                        (fourth_level_item, fourth_level_index) => {
                                                            if (fourth_level_item.routeId == urlArray[5]) {
                                                                handleFourthLevelMenuClickDefault(
                                                                    fourth_level_index,
                                                                    index,
                                                                    sub_index,
                                                                    third_level_index
                                                                );
                                                            }
                                                        }
                                                    );
                                                }else{
												handleThirdLevelMenuClickDefault(
													third_level_index,
													index,
													sub_index
												);
                                                }
											}
										}
									);
								} else {
									handleSecondLevelMenuClickDefault(sub_index, index);
								}
							}
						});
					} else {
						handleMenuClick(index, 1);
					}
				} else {
					item.selected = false;
				}
			});
		}
	};

	React.useEffect(() => {
		autoClick();
	}, [navigationArray, props.location.pathname]);

    React.useEffect(() => {
        let thisLevel = 0

        switch(navigationBoxArrayIndex){
            case 1 : 
            thisLevel = levelOneIndex
            break;
            case 2 : 
            thisLevel = levelTwoIndex
            break;
            case 3 : 
            thisLevel = levelThreeIndex
            break;
            case 4 : 
            thisLevel = levelFourIndex
            break;
        }

		handleMenuClick( thisLevel, navigationBoxArrayIndex)
	}, [navigationArray]);

	React.useEffect(() => {
		if (
			props.location.pathname.includes('income-statement/p&lreports') ||
			tabSelectorValue == 1
		) {
			setPlReportAlignment(true);
		} else {
			setPlReportAlignment(false);
		}

		// adHocAlignment

		if (
			props.location.pathname.includes('income-statement/ad-hoc') ||
			tabSelectorValue == 1
		) {
			setAdHocAlignment(true);
		} else {
			setAdHocAlignment(false);
		}
	}, [props.location.pathname]);

	React.useEffect(() => {
		if (props.location.search.includes('process_id')) {
			setShowModalWithTableComponent(true);
			let queryData = props.location.search.split('?process_id=');
			let payload = {
				processId: queryData && queryData.length ? queryData[1] : '',
			};
			props.fetchPlFileUploadError(payload);
		}
	}, [props.location.search]);

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	const handleModalWithTableClose = () => {
		setShowModalWithTableComponent(false);
	};
	if (onLoad) {
		const urlArray = window.location.pathname.split('/');
		if (
			urlArray[1] == 'usermanagement' &&
			navigationArray != UserManagementNavigation
		) {
			setNavigationArray(UserManagementNavigation);
			setBreadcrumbsList([{ text: 'Settings', link: '/usermanagement/panel' }]);
		} else {
			setNavigationArray(DashboardNavigation);
			setBreadcrumbsList([{ text: 'Home', link: '/dashboard/panel' }]);
		}
		setOnLoad(false);
	}

	const NavigationDrawer = (props) => {
		const { array, level, headerText, levelIndex, levelItemsIndex } = props;
		return (
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: level,
					[classes.drawerClose]: !level,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: level,
						[classes.drawerClose]: !level,
					}),
				}}
			>
				<List
					component='nav'
					subheader={
						<ListSubheader
							component='div'
							className='flex ai-center jc-space-between px-0'
						>
							<div className='flex ai-center'>
								<IconButton
									onClick={() => setLevel(levelIndex)}
									style={{ padding: '12px 14px' }}
								>
									{level ? <ChevronLeftIcon /> : <ChevronRightIcon />}
								</IconButton>
								<span style={{ lineHeight: '1.2' }}>{headerText}</span>
							</div>
							<IconButton onClick={() => handleRemoveDrawer(levelIndex)}>
								<CloseIcon fontSize='small' />
							</IconButton>
						</ListSubheader>
					}
				>
					{array.map(
						(item, index) =>
							getAccessByMenuKey(menuData, item.key, 'view') && (
								<Tooltip title={item.text} key={index}>
									<ListItem
										button
										key={index}
										selected={item.selected || index == levelItemsIndex}
										onClick={() => {
											handleMenuClick(index, levelIndex);
										}}
									>
										<ListItemIcon>
                                        <img className={item.selected || index == levelItemsIndex? 'colored-icons sidebar-highlighted': 'colored-icons sidebar-normal'}
												src={item.Icon}
												height='18'
											/>
										</ListItemIcon>
										<ListItemText primary={item.text} />
									</ListItem>
								</Tooltip>
							)
					)}
				</List>
			</Drawer>
		);
	};

	const handleTabChange = (event, newValue) => {
		setTabSelectorValue(newValue);
		if (newValue == 1) {
			setNavigationArray(DashboardNavigation);
		} else {
			setNavigationArray(DashboardNavigation);
		}
	};

	return (
		<>
			<NotAvailableAlert
				open={OpenNA}
				setOpen={handleSetOpenNA}
			></NotAvailableAlert>
			{showModalWithTableComponent && (
				<ModalWithTable
					modalCallBack={handleModalWithTableClose}
					showModal={showModalWithTableComponent}
					generalTableState={props.generalTableState}
				/>
			)}
			{!authReducer.isEmbedIframe && (
				<Breadcrumbs aria-label='breadcrumb' className='app-breadcrumbs'>
					{breadcrumbsList && breadcrumbsList.length > 0
						? breadcrumbsList.map((item, index) => (
								<Link
									key={index}
									color='inherit'
									onClick={() => {
										handleClickBreadcrums(item, index);
									}}
									className={
										index + 1 == breadcrumbsList.length ? 'active' : ''
									}
								>
									{item && item.text}
								</Link>
						  ))
						: ''}
				</Breadcrumbs>
			)}

			<section className='app-sidebar flex'>
				{!authReducer.isEmbedIframe && showLevelOne ? (
					<NavigationDrawer
						array={navigationArray}
						level={levelOne}
						headerText={
							navigationArray == UserManagementNavigation ? 'Settings' : 'Home'
						}
						levelIndex={1}
						levelItemsIndex={levelOneIndex}
					/>
				) : (
					''
				)}

				{showLevelTwo ? (
					<NavigationDrawer
						array={navigationArray[levelOneIndex].Submenu}
						level={levelTwo}
						headerText={navigationArray[levelOneIndex].text}
						levelIndex={2}
						levelItemsIndex={levelTwoIndex}
					/>
				) : (
					''
				)}

				{showLevelThree ? (
					<NavigationDrawer
						array={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu
						}
						level={levelThree}
						headerText={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].text
						}
						levelIndex={3}
						levelItemsIndex={levelThreeIndex}
					/>
				) : (
					''
				)}

                {showLevelFour ? (
					<NavigationDrawer
						array={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu
						}
						level={levelFour}
						headerText={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].text
						}
						levelIndex={4}
						levelItemsIndex={levelFourIndex}
					/>
				) : (
					''
				)}

				{navigationBoxArray && navigationBoxArray.length ? (
					<div className='blockDesignSidebar'>
						<div className='header flex ai-center jc-space-between'>
							<span>{headerText}</span>
							<Linkroute to='/dashboard/panel'>
								<IconButton aria-label='delete'>
									<CloseIcon />
								</IconButton>
							</Linkroute>
						</div>
						{navigationSelectedRouteId &&
							navigationSelectedRouteId == 'receivables' && (
								<Tabs
									value={tabSelectorValue}
									onChange={handleTabChange}
									indicatorColor='primary'
									textColor='primary'
									className='mt-16 mb-16'
								>
									<Tab label='Legacy BU' {...a11yProps(0)} />
									<Tab label='New BU' {...a11yProps(1)} />
								</Tabs>
							)}
						<Box
							display='flex'
							className={
								plReportAlignment
									? 'plReportAlignment'
									: adHocAlignment
									? 'adHocAlignment'
									: ''
							}
							justifyContent='flex-start'
                            flexWrap='wrap'
							m={1}
							p={1}
						>
							{navigationBoxArray.map(
								(item, index) =>
									getAccessByMenuKey(menuData, item.key, 'view') && (
										<div>
											<Tooltip
												key={index}
												title={item.toolTipText ? item.toolTipText : item.text}
											>
												<Box
													className='box'
													onClick={() => handleBoxItemClick(index, item)}
												>
													<img src={item.Icon} className='svg-icon-orange' height='75'></img>
													<h5>{item.text}</h5>
												</Box>
											</Tooltip>
										</div>
									)
							)}
						</Box>
					</div>
				) : (
					<div className='app-content'>
						<Switch>
                             <Route path={`/dashboard/sandbox/ebitda`} exact>
                                <EBITDA />
                            </Route>
                            <Route path={`/dashboard/sandbox/geospatialoverview`} exact>
                            <GeoSpatialOverview />
                            </Route> 
                            <Route  path={`/dashboard/sandbox/planning/func-planning`} exact>
                                <FunctionalPlanningComponent  />
                            </Route>
                        </Switch>
					</div>
				)}
			</section>
		</>
	);
};

export default withRouter(
	connect(
		(state) => {
			return {
				exitWithoutSaveFlag: state.dashboardState.exitWithoutSaveFlag,
				generalTableState: state.dashboardState.generalTableState,
			};
		},
		(dispatch) => {
			return {
				showExitWithoutSaveModal(data) {
					dispatch({
						type: 'SHOW_EXIT_WITHOUT_SAVE_MODAL',
						payload: data,
					});
				},
				fetchPlFileUploadError(data) {
					dispatch({
						type: 'FETCH_PL_FILE_UPLOAD_ERROR',
						payload: data,
					});
				},
			};
		}
	)(NestedList)
);
