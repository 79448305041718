export let changeValuesOfData = (data) => {
  if (data && data.length) {
    let transformedData = data.map((item) => {
      if (item.commentArr == undefined) {
        item['commentArr'] = [];
        item['isChatConnection'] = false;
        item['obj'] = {};
      }
      return item;
    });
    return transformedData;
  }
  return [];
};

export const setAllRowCommentHistoryData = (rowData, chatData) => {
  return rowData.map(item => {
    if (chatData[item.channel]) {
      item['commentArr'] = chatData[item.channel]
    }
    return item
  })
}

export const transformFilterData = (filterData) => {

  let filterObj = {
    yearArr: [],
    qtrArr: [],
    monthArr: [],
    monthArrFuture: [],
    plantArr: [],
    countryArr: [],
    simulationArr: [],
  }

  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'year') {
        filterObj.yearArr = item.value
      }
      if (item.key == 'quarter') {
        filterObj.qtrArr = item.value
      }
      if (item.key == 'yearmonth') {
        filterObj.monthArr = item.value
      }
      if (item.key == 'yearmonth_future') {
        filterObj.monthArrFuture = item.value
      }
      if (item.key == 'simulation') {
        filterObj.simulationArr = item.value
      }
      if (item.key == 'plant') {
        filterObj.plantArr = item.value
      }
      if (item.key == 'country') {
        filterObj.countryArr = item.value
      }
    })
  }
  return filterObj
}

export const transformSimulationData = (filterData) => {

  let filterObj = {
    rcpr: {},
    ior: {},
    ismr: {},
    icu: {},
    icoc: {},
  }

  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item._id == 'rcpr') {
        filterObj.rcpr = item
      }
      if (item._id == 'ior') {
        filterObj.ior = item
      }
      if (item._id == 'ismr') {
        filterObj.ismr = item
      }
      if (item._id == 'icu') {
        filterObj.icu = item
      }
      if (item._id == 'icoc') {
        filterObj.icoc = item
      }
    })
  }
  return filterObj
}