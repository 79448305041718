import React from 'react';
import { connect } from "react-redux";
import ReactTableComponent from './TableComponent'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import InputLabel from '@material-ui/core/InputLabel';
import DataValidationTool from '../../../../src/components/DataBrowser'
import 'jspdf-autotable'
import Grid from '@material-ui/core/Grid';

import { format } from 'date-fns/esm';
import {
  downloadExcel,
  pdfFileName,
  mapExcelData,
  mapExcelHeader,
  mapExcelHeaderComparativeAutomated,
  mapExcelDataComparativeAutomatedValidation,
  mapDataPDF,
  mapPDFDataPLComparative
} from '../../../utils/exportExcel'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export class AutomatedDataValidation extends React.Component {

  constructor(props) {
    super(props);
    this.excelData = {};
    this.state = {
      loading: true,
      tabSelectorValue: 3,
      radioSelectorValue: '0',
      radioSelectorARValue:'0',
      radioSelectorISValue: 'bupl',
      radioSelectorDSOValue:'legacyBU',    
      iframeSrc: 'https://newreporter.eaciitapp.com?redirectUri=https://newreporter.eaciitapp.com/dashboard/user-file-upload/automated-data-validation?embed=true',
      iframeHeight: '800px',
      iframeWidth: '100%',
      radioSelectorReceivablesValue: 'legacyBU',
      showIframe: false,
      validationTabSelector :1,
      month: '',
      monthSelectorValue: '',
      qtrSelectorValue: '',
      yearSelectorValue: ''
    }
  }

  componentDidMount() {
    // this.fetchIncomeStatementData()
    
    this.fetchNewDataValidation();
    setTimeout( ()=>{
      this.props.fetchLatestMonth();
    }, 3000); 
    let locationHref = window.location.href
    const {authReducer} = this.props;
    if (locationHref && locationHref.includes('uat.newreporter')) {
      if (authReducer.isEmbedIframe) {
        this.setState({
          showIframe: false
        })
      } else {
        this.setState({
          showIframe: true
        })
      }
    }

  }
  handleTabSelector = (event, value)=>{
    this.setState({validationTabSelector: value}, () =>{
        if(value ==0){
            this.props.getNewAutomatedData();
        }else{
           
          this.fetchNewDataValidation();
          let locationHref = window.location.href
          const {authReducer} = this.props;
          if (locationHref && locationHref.includes('uat.newreporter')) {
            if (authReducer.isEmbedIframe) {
              this.setState({
                showIframe: false
              })
            } else {
              this.setState({
                showIframe: true
              })
            }
          }

        }
    });
}

  fetchIncomeStatementData = () => {

    let payload = {
      radioType: this.state.radioSelectorISValue,
    }
    this.props.getDataIS(payload);
    // this.props.getBUPLValidationData()

  }

  fetchPaymentDataValidation = () => {
    this.props.getPaymentDataValidation();
    // this.props.getBUPLValidationData()

  }
  fetchNewDataValidation = () => {
    const { radioSelectorValue } = this.state
    let payload = {
      apiEndPoint: radioSelectorValue
    }
    this.props.getDataNewDataValidation(payload);
    // this.props.getBUPLValidationData()

  }

  fetchNewARValidation = () =>{
    this.setState({radioSelectorARValue: '5'});
    const {month} = this.state;
    let payload = {apiEndPoint: '5', month: month};
    this.props.getDataNewDataValidation(payload);
  }
  fetchServiceRevenueProfitabilityData = () =>{
    const {monthSelectorValue,qtrSelectorValue,yearSelectorValue} = this.state;
    let payload = {month: monthSelectorValue, qtr: qtrSelectorValue,year:yearSelectorValue};
    this.props.getDataServiceRevenueProfitability(payload);
  }
  fetchReceivableData = () => {
    let payload = {
      radioSelectorReceivablesValue: this.state.radioSelectorReceivablesValue
    }
    this.props.getReceivableDataIS(payload);
  }

  fetchReceivableDSOData = () => {
    let payload = {
      radioSelectorDSOValue: this.state.radioSelectorDSOValue
    }
    this.props.getDataDSO(payload);
  }

  handleRadioChange = (event) => {

    this.setState({
      radioSelectorValue: event.target.value,
    }, () => {
      this.fetchNewDataValidation()
    })

  };


  handleRadioISChange = (event) => {

    this.setState({
      radioSelectorISValue: event.target.value,
    }, () => {
      this.fetchIncomeStatementData()
    })

  };

  handleRadioReceivablesChange = (event) => {

    this.setState({
      radioSelectorReceivablesValue: event.target.value,
    }, () => {
      this.fetchReceivableData()
    })

  };

handleRadioReceivablesDSOChange = (event) => {

  this.setState({
      radioSelectorDSOValue: event.target.value,
  },() =>{
    this.fetchReceivableDSOData()
  })

};

   handleTabChange = (event, newValue) => {
    console.log(newValue, "checkNEwCa")
    let valsz
    // if(newValue == 2){
    //   valsz = 3
    // }else if (newValue == 3){
    //   valsz = 4
    // }else{
    valsz = newValue
    // }
    this.setState({ tabSelectorValue: valsz }, () => {
      if (valsz == 0) {
        this.fetchIncomeStatementData()
      } else if (valsz == 1) {
        this.fetchReceivableData()
      } else if (valsz == 2) {
        this.fetchReceivableDSOData()
      }  else if (valsz == 3) {
        this.fetchNewDataValidation()
      } else if (valsz == 4) {
        this.fetchPaymentDataValidation()
      } else if(valsz == 5) {
        
        this.setState({month:this.props.incomeStatementState.currentARMonth})
        setTimeout(()=>{
          this.fetchNewARValidation()
        }, 1000); 
      } else if(valsz == 6) {
        this.setState({   
          monthSelectorValue:this.props.incomeStatementState.currentARMonth.replaceAll('-', ' '),
          qtrSelectorValue: '',
          yearSelectorValue: ''
        })
       // setTimeout(()=>{
          this.fetchServiceRevenueProfitabilityData()
       // }, 1000); 
      }
    })
  };

  handlePostManualAmountData = (payload) => {
    let data = { ...payload, isReceivable: this.state.tabSelectorValue, radioSelectorISValue: this.state.radioSelectorISValue, radioSelectorReceivablesValue: this.state.radioSelectorReceivablesValue, radioSelectorDSOValue: this.state.radioSelectorDSOValue }
    this.props.postManualAmountData(data)
  }
  handleExcelDownload = () => {
    let data = []
    let header = {};
    let merge = [];

    let headerExcel = this.excelData.header
    // console.log(headerExcel, "testnewDataheaderExcel")
    let headerData = this.props.incomeStatementState.automationHeaderData
    let rowData = this.props.incomeStatementState.automationRowData
    let newValue = this.state.tabSelectorValue
    let radioSelectorValue = this.state.radioSelectorValue
    let nameFile = ""
    if (newValue == 0) {
      nameFile = "INCOME STATEMENT"
    } else if (newValue == 1) {
      nameFile = "RECEIVABLES"
    } else if (newValue == 2) {
      nameFile = "P&L raw data validation"
    } else if (newValue == 3) {
      nameFile = "Receivables - DSO Validation"
    } else if (newValue == 4) {
      nameFile = "New Validation"
    }else if (newValue == 6) {
      nameFile = "New BU 1 Service Revenue Profitability"
    }

    if (newValue == 3 && radioSelectorValue == 2) {
      data = mapExcelData(rowData, headerExcel, "id")
      header = mapExcelHeader(headerExcel, "Header")
      console.log(data, headerExcel, "testnewDataheaderExcel")
    } else if (newValue == 6){
      data = mapExcelData(rowData, headerExcel, "id")
      header = mapExcelHeader(headerExcel, "Header")
    }else {
      let newHeader = mapExcelHeaderComparativeAutomated(headerExcel, "label");
      data = mapExcelDataComparativeAutomatedValidation(rowData, headerData, headerExcel, "id")
      header = newHeader.data;
      merge = newHeader.merge;
      console.log(data, "testnewData")
      console.log(header, "testnewHeader")
    }


    // if (this.state.tabSelectorValue == 1 || (this.state.tabSelectorValue == 0 && this.state.comparativeSelectorValue == 1)) {
    //     let datas = this.excelData
    //     header = datas.header;
    //     datas.data = this.unwindHeaderKeyVal(datas.data);
    //     datas.data = this.formatComments(datas.data)
    //     data = mapExcelData(datas.data, header, "id")
    //     header = mapExcelHeader(header, "headerTitle")
    // } else {
    //     let datas = this.excelDataComparison
    //     header = datas.header;
    //     console.log(header, "testcoloms")
    //     datas.data = datas.data.map(x => {
    //         let comm = "";
    //         if (x.sub_column.comments && x.sub_column.comments.length) {
    //             x.sub_column.comments.forEach((y, idx) => {
    //                 comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
    //                 if (idx != x.sub_column.comments.length - 1) {
    //                     comm += "\n"
    //                 }
    //             })
    //         }
    //         x.comments = comm
    //         return x
    //     })
    //     data = mapExcelDataPLComparative(datas.data, header, "id")
    //     let newHeader = mapExcelHeaderComparative(header, "Header");
    //     console.log(data, "testcoloms1")
    //     console.log(newHeader, "testcoloms2")
    //     header = newHeader.data;
    //     merge = newHeader.merge;
    // // }

    let fileName = "AUTOMATED DATA VALIDATION - " + nameFile + "_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
    if (this.financialDuration) {
      fileName = fileName + " - " + this.financialDuration
    }
    console.log(data, fileName, header, false, merge)
    const { currentValue, previousValue } = this.props.incomeStatementState;
    let sheetName = 'Data';
    if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
      sheetName = `${currentValue} - ${previousValue}`
    }
    downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
  }
  addBUPLValidationData = (payload) => {
    console.log(payload)
    // this.props.postBUPLValidationData(data)
  }
  handleExcelDataChange = (data, header) => {
    this.excelData.data = data;
    this.excelData.header = header;
  }

  handleMonthChange = (event) => {
    this.setState({month: event.target.value}, ()=>{
      this.fetchNewARValidation();
    }) 
  }
  handleMonthChangeRevenue = (event) => {
      if (event.target) {
        this.setState({
          monthSelectorValue: event.target.value,
          qtrSelectorValue: '',
          yearSelectorValue: ''
        }, () => {
          this.fetchServiceRevenueProfitabilityData()
        })
    }
  }
  
handleQtrChangeRevenue = (event) => {
    if (event.target) {
        this.setState({
            qtrSelectorValue: event.target.value,
            monthSelectorValue: '',
            yearSelectorValue: ''
        }, () => {
            this.fetchServiceRevenueProfitabilityData()
        })
    }
};

handleYearChangeRevenue = (event) => {
    if (event.target) {
        this.setState({
            yearSelectorValue: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: ''
        }, () => {
          this.fetchServiceRevenueProfitabilityData()
        })
    }
};
  render() {

    const { validationTabSelector, month, tabSelectorValue, radioSelectorValue, radioSelectorARValue, radioSelectorISValue, radioSelectorReceivablesValue, radioSelectorDSOValue, showIframe,monthSelectorValue,qtrSelectorValue,yearSelectorValue } = this.state;
    const monthArray = this.props.incomeStatementState.arDateArray;
    const yearFilterArr = this.props.incomeStatementState.yearFilter;
    const monthFilterArr = this.props.incomeStatementState.monthFilter;
    const quarterfilterArr = this.props.incomeStatementState.qtrfilter;
    return (

      <div className='buplComponent'>
        <Grid container justify="flex-end">
          {tabSelectorValue != 2 &&
            <Tooltip title={'Download to Excel'} className='pointer'>
              <img
                src={DownloadExcel}
                className='pointer'
                height='30'
                onClick={(event) => {
                  event.preventDefault();
                  this.handleExcelDownload();
                }} />
            </Tooltip>}
        </Grid>

        <Tabs 
          value={validationTabSelector}
          onChange={this.handleTabSelector}
          indicatorColor="primary"
          textColor="primary" 
          className='blockTabs mb-10'
          >
            <Tab label="Summary Level Validation" {...a11yProps(0)} />
            <Tab label="Data Validation" {...a11yProps(1)} />
            <Tab label="Data Validation Tool" {...a11yProps(2)} />
        </Tabs>

        {
          validationTabSelector == 2 && 
          <DataValidationTool /> 
        }

        { validationTabSelector == 1 ? <Tabs
          value={tabSelectorValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className="mb-20"
        >
          <Tab label="INCOME STATEMENT" />
          <Tab label="RECEIVABLES" />
          <Tab label="Receivables - DSO Validation" />
          <Tab label="New - IS Validation" />
          <Tab label="Collection Amount" />
          <Tab label = "AR Monthly Report Data Table"/>
          <Tab label = "New BU 1 Service Revenue Profitability"/>
          {/* <Tab label="AR Payment Data Validation" /> */}
        </Tabs> : <></> 
        
        }

        {tabSelectorValue == 3 && validationTabSelector == 1 && <React.Fragment>
          <FormControl component="fieldset">
            <RadioGroup name="radio" value={radioSelectorValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
              {<FormControlLabel value="0" control={<Radio color="primary" />} label="Summary" />}
              {<FormControlLabel value="1" control={<Radio color="primary" />} label="Regional Validation" />}
              <FormControlLabel value="2" control={<Radio color="primary" />} label="New Upload Validation" />
              <FormControlLabel value="3" control={<Radio color="primary" />} label="New Plant OCOS Validation" />
              <FormControlLabel value="4" control={<Radio color="primary" />} label="New v/s BU P&L" />
            </RadioGroup>
          </FormControl>
        </React.Fragment>
        }

        {tabSelectorValue == 1 && validationTabSelector == 1 && <React.Fragment>
          <FormControl component="fieldset">
            <RadioGroup name="radio" value={radioSelectorReceivablesValue} onChange={this.handleRadioReceivablesChange} className='radioGroupHorizontal'>
              {<FormControlLabel value="legacyBU" control={<Radio color="primary" />} label="Legacy BU" />}
              {<FormControlLabel value="NewBU" control={<Radio color="primary" />} label="New BU" />}
            </RadioGroup>
          </FormControl>
        </React.Fragment>
      }

      {tabSelectorValue == 2 && validationTabSelector == 1 && <React.Fragment>
                <FormControl component="fieldset">
                  <RadioGroup name="radio" value={radioSelectorDSOValue} onChange={this.handleRadioReceivablesDSOChange} className='radioGroupHorizontal'>
                    {<FormControlLabel value="legacyBU" control={<Radio color="primary" />} label="Legacy BU" />}
                    {<FormControlLabel value="NewBU" control={<Radio color="primary" />} label="New - Summary" />}
                    {<FormControlLabel value="NewGeoSummary" control={<Radio color="primary" />} label="New - GeoSummary" />}
                  </RadioGroup>
                </FormControl>
              </React.Fragment>
            }

         {tabSelectorValue == 0 &&  validationTabSelector == 1 && <React.Fragment>
          <FormControl component="fieldset">
            <RadioGroup name="radio" value={radioSelectorISValue} onChange={this.handleRadioISChange} className='radioGroupHorizontal'>
              {<FormControlLabel value="bupl" control={<Radio color="primary" />} label="BU P&L" />}
              {<FormControlLabel value="functionalpl" control={<Radio color="primary" />} label="Functional P&L" />}
              {<FormControlLabel value="hfm" control={<Radio color="primary" />} label="HFM Data" />}
              {<FormControlLabel value="salestech" control={<Radio color="primary" />} label="Sales & Technology P&L" />}
              {<FormControlLabel value="all" control={<Radio color="primary" />} label="All" />}
            </RadioGroup>
          </FormControl>
        </React.Fragment>
        }
        { tabSelectorValue == 5 && <React.Fragment>
              <FormControl className='w-150'>
                      <Select
                      value={month}
                      onChange={this.handleMonthChange}
                      //MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                      {monthArray && monthArray.length &&
                          monthArray.map((item, index) => {
                          return <MenuItem value={item} key={index}>{item}</MenuItem>
                          })
                      }
                      </Select>
                  </FormControl>
            </React.Fragment>
          }
          { tabSelectorValue == 6 && <React.Fragment>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className='maxw-200'>
              <FormControl  className='selectOutlineSmall'
                    variant='outlined'
                    fullWidth
                    size='small'
                    >
                       <InputLabel>Month</InputLabel>
                      <Select
                      label="Month"
                      value={monthFilterArr && monthFilterArr.length?monthSelectorValue:''}
                      onChange={this.handleMonthChangeRevenue}
                      //MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                        <MenuItem value=''>
										<em>Select</em>
									</MenuItem>
                      {monthFilterArr && monthFilterArr.length &&
                          monthFilterArr.map((item, index) => {
                            if (item) {
                              return <MenuItem value={item} key={index}>{item}</MenuItem>
                            }
                         
                          })
                      }
                      </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} className='maxw-200'>
                  <FormControl  className='selectOutlineSmall'
                    variant='outlined'
                    fullWidth
                    size='small'
                    >
                      <InputLabel>Quarter</InputLabel>
                      <Select
                       label="Quarter"
                      value={qtrSelectorValue||''}
                      onChange={this.handleQtrChangeRevenue}
                     // MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                      <MenuItem value=''>
									      <em>Select</em>
									    </MenuItem>
                      {quarterfilterArr && quarterfilterArr.length &&
                          quarterfilterArr.map((item) => {
                            return  <MenuItem value={item.value} key={item.value}>
													{item.label}
												</MenuItem>
                          
                          })
                      }
                      </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} className='maxw-200'>
                  <FormControl  className='selectOutlineSmall'
                    variant='outlined'
                    fullWidth
                    size='small'
                    >
                      <InputLabel>Annual</InputLabel>
                      <Select
                      label="Annual"
                      value={yearSelectorValue||''}
                      onChange={this.handleYearChangeRevenue}
                      MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                        <MenuItem value=''>
										<em>Select</em>
									</MenuItem>
                      {yearFilterArr && yearFilterArr.length &&
                          yearFilterArr.map((item, index) => {
                          return <MenuItem value={item} key={index}>{item}</MenuItem>
                          })
                      }
                      </Select>
                  </FormControl>
                  </Grid>
                  </Grid>
                  <h2 className='mb-20 mt-10'> Service and Field Sales expense </h2>
                 
            </React.Fragment>
            
          }

        {/* {radioSelectorISValue == "salestech" ? <div> The functionality is in progress. It will be enabled soon </div>:  */}
        <ReactTableComponent
          radioSelectorISValue={radioSelectorISValue}
          postManualAmountData={this.handlePostManualAmountData}
          handleExcelDataChange={this.handleExcelDataChange}
          postBUPLValidationData={(data) => this.props.postBUPLValidationData(data)}
          tabSelectorValue={tabSelectorValue}
          radioSelectorValue={radioSelectorValue}
          radioSelectorReceivablesValue = {radioSelectorReceivablesValue}
          radioSelectorDSOValue = {radioSelectorDSOValue}
          validationTabSelector = {validationTabSelector}
          radioSelectorARValue = {radioSelectorARValue}
        />
        {showIframe && validationTabSelector == 1 &&
          <div style={{ marginTop: '4rem' }}>
            <iframe src={this.state.iframeSrc} height={this.state.iframeHeight} width={this.state.iframeWidth} />
          </div>
        }

      </div>
    );
  }
}

export default
  connect(
    state => {
      return {
        incomeStatementState: state.incomeStatement,
        authReducer: state.authReducer,
        dashboardState: state.dashboardState
      }
    },
    dispatch => {
      return {

        getNewAutomatedData(data){
          dispatch({
              type:"FETCH_New_AUTOMATED_DATA",
              payload: data
          })
        },
        fetchLatestMonth(){
          dispatch({
            type:'FETCH_AR_LATEST_MONTH', 
          })
        },
        getDataIS(data) {
          dispatch({
            type: 'FETCH_AUTOMATION_IS',
            payload: data

          });
        },
        getDataDSO(data) {
          dispatch({
            type: 'FETCH_AUTOMATION_DSO',
            payload:data,
          });
        },
        getPaymentDataValidation() {
          dispatch({
            type: 'FETCH_PAYMENT_DATA_VALIDATION',
          });
        },
        getDataNewDataValidation(data) {
          dispatch({
            type: 'FETCH_AUTOMATION_new_DATA_VALIDATION',
            payload: data
          });
        },
        getReceivableDataIS(data) {
          dispatch({
            type: 'FETCH_AUTOMATION_RECEIVABLE',
            payload: data
          });
        },
        postManualAmountData(data) {
          dispatch({
            type: 'POST_MANUAL_AMOUNT_DATA',
            payload: data
          });
        },
        getBUPLValidationData() {
          dispatch({
            type: 'FETCH_BUPL_VALIDATION_DATA',
          });
        },
        postBUPLValidationData(data) {
          dispatch({
            type: 'POST_BUPL_VALIDATION_AMOUNT_DATA',
            payload: data
          });
        },
        getDataServiceRevenueProfitability(data){
          dispatch({
            type: 'FETCH_SERVICE_REVENUE_PROFITABILITY_DATA',
            payload: data
          });
        }
      };
    }
  )(AutomatedDataValidation);





