
import React from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
// import Modal from '@material-ui/core/Modal';
// import CommentBox from '../ImprovementPlan/comment';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CadenceComponent from './cadenceBoard'
import DetailedPlanComponent from './detailedPlan'
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}


export class CadenceBoardMaster extends React.Component {
    constructor(props) {
        super(props);
        this.dateStatus = ["None","Blank", "Late", "Completed", "Ahead"]
        this.impPlanTypeArr = []
        this.totalPlanArr = []
        this.state = {
            seriesData: [],
            loading: true,
            tabValue: 0,
            month: '',
            costValue: '',
            totalPlanValue: '',
            planTypeValue: '',
            blankDateEnabled : true,
            lateEnabled : true,
            completedEnabled : true,
            aheadEnabled : true,
            dateStatusSelected : this.dateStatus
        }
    }

    componentDidMount() {
        // this.props.getCadenceBoardData();
        // this.props.fetchCadenceBoardData();
        this.props.fetchFCFFilter();
    }

    componentDidUpdate() {
        this.fillFilter();
    }

    fillFilter = () => {
        const {ecfmState} = this.props;
        const allFilter = ecfmState.filter;
       
        if(allFilter.length > 0){
            let kpiObj = {};
            allFilter.map(item => {
                if(item.key === "kpi") kpiObj = item
            })
            const kpiValueArray = kpiObj['value']
            let kpiValueArr = []
            kpiValueArray.map((item, idx) => {
                kpiValueArr[idx] = item['kpi']
            })

            this.impPlanTypeArr = [...new Set(kpiValueArr)];
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };

    handleCostChange = (event, newValue) => {
        this.setState({ costValue: newValue });
    }

    handlePlanChange = (event, newValue) => {
        this.setState({ totalPlanValue: newValue });
    }

    handleImpPlanChange = (event, newValue) => {
        this.setState({ planTypeValue: event.target.value });
    }

    saveDate = (payload) => {
        this.props.saveCadenceBoardData(payload);
    }

    handleButtonFilter = (e) => {
       let label = e.target.innerHTML

        if(label == "Blank Date"){
            this.setState({
                blankDateEnabled : !this.state.blankDateEnabled
            }, this.changeFilterStatus)
        }

        if(label == "Late"){
            this.setState({
                lateEnabled : !this.state.lateEnabled
            }, this.changeFilterStatus)
        }

        if(label == "Completed"){
            this.setState({
                completedEnabled : !this.state.completedEnabled
            }, this.changeFilterStatus)
        }

        if(label == "Ahead"){
            this.setState({
                aheadEnabled : !this.state.aheadEnabled
            }, this.changeFilterStatus)
        }

    }

    changeFilterStatus = () =>{
        const { aheadEnabled, completedEnabled, lateEnabled, blankDateEnabled } = this.state
        let status = ["None"];

        if(blankDateEnabled){
            status.push("Blank")
        }

        if(lateEnabled){
            status.push("Late")
        }

        if(completedEnabled){
            status.push("Completed")
        }

        if(aheadEnabled){
            status.push("Ahead")
        }

        this.setState({ dateStatusSelected : status })
    }


    render() {
        const { authReducer } = this.props
        const { dateStatusSelected, tabValue, planTypeValue, totalPlanValue, costValue , blankDateEnabled, lateEnabled, aheadEnabled, completedEnabled} = this.state
        const menuData = authReducer.menuData
        return (
            <div className="cadenceBoard mt-20">
                <div className='custom-tab'>
                    <Tabs value={tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" className='mb-10'>
                        <LinkTab label="Cadence View" href="/cadence" {...a11yProps(0)} />
                        <LinkTab label="Detailed Plan View " href="/plan" {...a11yProps(1)} />
                    </Tabs>
                    <Grid container spacing={2} className={'candenceMenuSection'}>
                        <Grid item xs={12} sm={5} className={'candenceMenuSubSection'}>
                            <FormControl style={{ minWidth: 200 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5' size='small'>
                                <InputLabel>Improvement Plan Type</InputLabel>
                                <Select
                                    value={planTypeValue}
                                    label="Business Unit"
                                    onChange={this.handleImpPlanChange}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {this.impPlanTypeArr && this.impPlanTypeArr.length && this.impPlanTypeArr.map(item => {
                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ minWidth: 200 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5 product-filter' size='small'>
                               {/* <InputLabel>Banked Savings</InputLabel>*/}
                               <div className="box-total-cadence">
                                   <span className="total-header">Total Plans</span>
                                   <span className="total-value">0.000</span>
                               </div>
                                {/* <Select
                                    value={totalPlanValue}
                                    label="Product"
                                    onChange={this.handlePlanChange}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {this.totalPlanArr && this.totalPlanArr.length && this.totalPlanArr.map(item => {
                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                    })}
                                </Select> */}
                            </FormControl>
                            <FormControl style={{ minWidth: 200 }} variant="outlined" className='selectOutlineSmall mr-5 mb-5 country-filter' size='small'>
                                {/*<InputLabel>Funnel Ambition</InputLabel>*/}
                                <div className="box-total-cadence">
                                   <span className="total-header">Total Cost</span>
                                   <span className="total-value">0.000</span>
                               </div>
                                {/* <Select
                                    value={costValue}
                                    label="Country"
                                    onChange={this.handleCostChange}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {this.costArr && this.costArr.length && this.costArr.map(item => {
                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                    })}
                                </Select> */}
                            </FormControl>
                        </Grid>
                        {tabValue == 0?
                        <Grid item xs={12} sm={5} className="hideShow text-center">
                            <h4>Click to hide/show plans</h4>
                            <Button variant={ blankDateEnabled ? "contained" : "outlined"} size="small" color="primary"  className= { blankDateEnabled ?"blank-btn mb-8" :"mb-8"} onClick={ this.handleButtonFilter } >Blank Date</Button>
                            <Button variant={ lateEnabled ? "contained" : "outlined"}  size="small" color="primary" className= { lateEnabled ?"ml-16 mr-16 late-btn mb-8":"ml-16 mr-16 mb-8" } onClick={ this.handleButtonFilter } >Late</Button>
                            <Button variant={ completedEnabled ? "contained" : "outlined"}  size="small" color="primary" className={ completedEnabled ?"completed-btn mb-8":"mb-8" } onClick={ this.handleButtonFilter }>Completed</Button>
                            <Button variant={ aheadEnabled ? "contained" : "outlined"}  size="small" color="primary" className={ aheadEnabled ?"ml-16 ahead-btn mb-8":"ml-16 mb-8" } onClick={ this.handleButtonFilter }>Ahead</Button>
                        </Grid>
                       :
                        <Grid item xs={12} sm={5} className="hideShow  text-center">
                            <h4>Maturity Level </h4>
                            <Button variant ="contained" size="small" className='newButton btnBlue uppercase mb-8' color="primary" >L0: Ideation</Button>
                            <Button variant ="contained" size="small" className='newButton btnBlue uppercase ml-8 mr-8 mb-8' color="primary" >L1: Feasible</Button>
                            <Button variant ="contained" size="small" className='newButton btnBlue uppercase mb-8' color="primary" >L2: Under Execution</Button>
                            <Button variant ="contained" size="small" className='newButton btnBlue uppercase ml-8 mr-8 mb-8' color="primary">L3: Achieved</Button>
                            <Button variant ="contained" size="small" className='newButton btnBlue uppercase mb-8' color="primary" >L4: Closed</Button>
                        </Grid>
                        }   
                    </Grid>
                    <TabPanel value={tabValue} index={0}>
                        <CadenceComponent dateStatusSelected={dateStatusSelected} menuData={menuData} fetchCadenceBoardData = {this.props.fetchCadenceData} saveDate={this.saveDate} planTypeValue={planTypeValue}/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} className="tab-content">
                        <DetailedPlanComponent  dateStatusSelected={dateStatusSelected} saveDate={this.saveDate} />
                    </TabPanel>
                </div>

            </div>

        )
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state.dashboardState.receivableData.ageingData,
                authReducer: state.authReducer,
                ecfmState: state.ecfmState
            }
        },
        dispatch => {
            return {
                fetchFCFFilter(data) {
                    dispatch({
                        type: 'FETCH_FCF_FILTER',
                        payload: data,
                    });
                },
                // getCadenceBoardData(data) {
                //     dispatch({
                //         type: 'FETCH_CADENCE_BOARD_DATA',
                //         payload: data,
                //     });
                // },
                fetchFCFImpactAnalysis() {
                    dispatch({
                        type: 'FETCH_FCF_IMPACT_ANALYSIS',
                        // payload: data,
                    });
                },
                getFormPlanId(data) {
                    dispatch({
                        type: "FETCH_CREATE_PLAN_ID_DATA",
                        payload: data,
                    });
                },
             
                saveCreatePlanData(data) {
                    dispatch({
                        type: "SAVE_PLAN_FORM_DROPDOWN_DATA",
                        payload: data,
                    });
                },
                saveCadenceBoardData(data) {
                    dispatch({
                        type: 'SAVE_CADENCE_BOARD_DATA',
                        payload: data,
                    });
                },
                fetchCadenceBoardData(data){
                    dispatch({
                        type: 'FETCH_CADENCE_BOARD_DATA',
                        payload: data,
                    });
                },
            };
        }
    )(CadenceBoardMaster);