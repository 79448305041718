import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import './index.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector, useDispatch } from "react-redux";
import TableComponent from './TableComponent'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { fetchCompareFilterOptionsService } from '../../../../store/dashboard/service'
import { transformFilterData } from '../../../../store/dashboard/transformer'



let indexVal = undefined
export default function CompareModal(props) {
    const [buValues, setBUValues] = useState([]);
    const [periodValues, setPeriodValues] = useState([]);
    const [parentGroupValues, setParentGroupValues] = useState([]);
    const [regionMultipleArr, setRegionMultipleArr] = useState([]);
    const [regionValues, setRegionValues] = useState([]);
    const receivableDataState = useSelector(state => state.dashboardState.receivableData);
    const { compareData } = receivableDataState
    const { rowData, filter } = compareData
    
    const { buArr, parentGroupArr, periodArr, regionArr } = filter;
    const dispatch = useDispatch();
    const { tabSelectorValue, radioSelectorValue } = props;
    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>{inputLabel}</InputLabel>
                <Select
                    className='dropdown2-select'
                    onChange={handleChange}
                    value={selectedValue}
                    label={inputLabel}
                >
                    <MenuItem value="">Select</MenuItem>
                    {menuItems?.map((item, index) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>


        );
    };

    const SelectDropdown2 = ({ menuItems, handleChange, inputLabel, selectedValue }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>{inputLabel}</InputLabel>
                <Select
                    className='dropdown2-select'
                    onChange={handleChange}
                    value={selectedValue}
                    label={inputLabel}
                >
                    <MenuItem value="">Select</MenuItem>
                    {menuItems.map((item, index) => {
                        return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                    })}
                </Select>
            </FormControl>


        );
    };

    React.useEffect(() => {
        dispatch({
            type: "FETCH_COMPARE_FILTER_RECEIVABLE"
        })
    }, [])

    React.useEffect(() => {
        handleFetchCompareFilter(indexVal)
    }, [buValues])

    const handleBuChange = (index, value) => {
        const newInputValues = [...buValues];
        newInputValues[index] = value;
        indexVal=index
        setBUValues(newInputValues);
    };

    const handlePeriodChange = (index, value) => {
        const newInputValues = [...periodValues];
        newInputValues[index] = value;
        setPeriodValues(newInputValues);
    };

    const handleParentGroupChange = (index, value) => {
        const newInputValues = [...parentGroupValues];
        newInputValues[index] = value;
        setParentGroupValues(newInputValues);
        // handleFetchCompareFilter()
    };

    const handleFetchCompareFilter = async(index) => {
        if (index != undefined) {
            const filterData = await fetchCompareFilterOptionsService(createFilterPayload(index))
            const filter = filterData.data.filter
            const {regionArr} = transformFilterData(filter)
            const newInputValues = [...regionMultipleArr];
            newInputValues[index] = regionArr;
            setRegionMultipleArr(newInputValues)
        }
    }
    const createFilterPayload = (index) => {
        let payloadArr = []
        buValues.forEach(item => {
            payloadArr.push(`?bu=${item}`)
        })
        return payloadArr[index]
    }


    const handleRegionChange = (index, value) => {
        const newInputValues = [...regionValues];
        newInputValues[index] = value;
        setRegionValues(newInputValues);
    };


    const handleClose = () => {
        handleResetState()
        props.handleClose()
    }

    const handleResetState = () => {
        setBUValues([])
        setPeriodValues([])
        setParentGroupValues([])
        setRegionValues([])
        dispatch({
            type: "RESET_COMPARE_DATA_BUPL"
        })
    }



    const handleExcelDownload = () => {
        handleRefresh(true)
    }

    const handleRefresh = (isDownload) => {
        let param = `exclude=&legal_entity=&`
        const payload = {
            data: [],
            'export': isDownload ? true : false
        }
        const payloadData = []

        periodValues.forEach((item, index) => {
            const newData = {
                period: item,
                bu: '',
                parent_group: '',
                region: ''
            }
            payloadData.push(newData)

        })
       
        payloadData.forEach((item, index) => {

            payloadData[index]['bu'] = buValues[index] ? buValues[index] : ''
            payloadData[index]['region'] = regionValues[index] ? regionValues[index] : ''
            payloadData[index]['parent_group'] = parentGroupValues[index] ? parentGroupValues[index] : ''

        })
        payload.data = payloadData

        dispatch({
            type: "FETCH_COMPARE_DATA_RECEIVABLE",
            payload
        })
    }

    const body = (

        <div className='customModal'>
            <div className='header'>
                <h2>Compare Data</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content bupl-Modal'>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className="accordion-summary">
                            <Typography className='bold-title'>Comparison Parameters</Typography>
                            <div className='refresh-clear-button'>
                                <Button variant="contained" className="newButton btnBlue btnRefresh uppercase"
                                    onClick={() => handleRefresh()}>
                                    Refresh
                                </Button>
                                <Button className="newButton btnRed btnClear uppercase"
                                variant="contained" color="primary"
                                    onClick={handleResetState}>
                                    Clear
                                </Button>
                                {rowData.length ? <Tooltip title={'Download to Excel'} className='pointer'>
                                    <img
                                        src={DownloadExcel}
                                        className='pointer'
                                        height='30'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleExcelDownload();
                                        }} />
                                </Tooltip> : null}

                            </div>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails className=''>
                        <div className="main-div">
                            <div className='sub-section'>

                                <div className='sub-right-section'>
                                    <div className="sub-section-right-div">
                                        <span>BU</span>
                                        <span>Receivables</span>
                                        <span>Period</span>
                                        <span>Region</span>
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(0, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[0]}
                                            />
                                        </div>
                                        <div>
                                            <SelectDropdown2
                                                menuItems={parentGroupArr}
                                                handleChange={(e) => handleParentGroupChange(0, e.target.value)}
                                                inputLabel={'Receivables'}
                                                selectedValue={parentGroupValues[0]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(0, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[0]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={regionMultipleArr[0]}
                                                handleChange={(e) => handleRegionChange(0, e.target.value)}
                                                inputLabel={'Region'}
                                                selectedValue={regionValues[0]}
                                            />
                                        </div>}
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(1, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[1]}
                                            />
                                        </div>
                                        <div>
                                            <SelectDropdown2
                                                menuItems={parentGroupArr}
                                                handleChange={(e) => handleParentGroupChange(1, e.target.value)}
                                                inputLabel={'Receivables'}
                                                selectedValue={parentGroupValues[1]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(1, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[1]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={regionMultipleArr[1]}
                                                handleChange={(e) => handleRegionChange(1, e.target.value)}
                                                inputLabel={'Region'}
                                                selectedValue={regionValues[1]}
                                            />
                                        </div>}
                                    </div>
                                    <div className='sub-section-right-div'>
                                        <div>
                                            <SelectDropdown
                                                menuItems={buArr}
                                                handleChange={(e) => handleBuChange(2, e.target.value)}
                                                inputLabel={'BU'}
                                                selectedValue={buValues[2]}
                                            />
                                        </div>
                                        <div>
                                            <SelectDropdown2
                                                menuItems={parentGroupArr}
                                                handleChange={(e) => handleParentGroupChange(2, e.target.value)}
                                                inputLabel={'Receivables'}
                                                selectedValue={parentGroupValues[2]}
                                            />
                                        </div>
                                        {<div>
                                            <SelectDropdown
                                                menuItems={periodArr}
                                                handleChange={(e) => handlePeriodChange(2, e.target.value)}
                                                inputLabel={'Period'}
                                                selectedValue={periodValues[2]}
                                            />
                                        </div>}
                                        {<div>
                                            <SelectDropdown
                                                menuItems={regionMultipleArr[2]}
                                                handleChange={(e) => handleRegionChange(2, e.target.value)}
                                                inputLabel={'Region'}
                                                selectedValue={regionValues[2]}
                                            />
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </AccordionDetails>

                </Accordion>

                <TableComponent
                    tabSelectorValue={tabSelectorValue}
                    radioSelectorValue={radioSelectorValue}
                />
            </div>

        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}