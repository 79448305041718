import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableComponent from '../ActualBUData/TableComponent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";


export default function (props) {
  const [radioSelectorValue, setRadioSelectorValue] = useState('view_byLocation')
  const [qtrSelectorValue, setQtrSelectorValue] = useState('')
  const [yearSelectorValue, setYearSelectorValue] = useState('')
  const [simulationSelectorValue, setSimulationSelectorValue] = useState('')
  const extendedPlanningState = useSelector(state => state.extendedPlanning);
  const { actualBUData } = extendedPlanningState
  const { filter, simulation } = actualBUData
  const {  headerText, detailText, updatedOn } = simulation
  const {  simulationArr, yearArr, qtrArr, monthArr, defaultDate } = filter
  const dispatch = useDispatch();
  const [monthSelectorValue, setMonthSelectorValue] = useState('')


  
  const handleMonthChange = (event) => {
    setYearSelectorValue('')
    setQtrSelectorValue('')
    setMonthSelectorValue(event.target.value)
  }
  const handleQtrChange = (event) => {
    setQtrSelectorValue(event.target.value)
    setYearSelectorValue('')
    setMonthSelectorValue('')
  }
  const handleYearChange = (event) => {
    setYearSelectorValue(event.target.value)
    setQtrSelectorValue('')
    setMonthSelectorValue('')
  }

  const handleSimulationChange = (event) => {
    setSimulationSelectorValue(event.target.value)
  }

  useEffect(()=>{
    dispatch({
        type: "FETCH_ACTUAL_BU_FILTER"
    })
  },[])

  useEffect(() => {
    if (defaultDate) {
      setMonthSelectorValue(defaultDate)
    }

    if(simulationArr){
      simulationArr.forEach(x=>{
        if(x.is_default){
          setSimulationSelectorValue(x._id);
        }
      })
    }
  },[defaultDate])

  useEffect(() => {
    handleFetchData()
  }, [monthSelectorValue, qtrSelectorValue, yearSelectorValue, radioSelectorValue, simulationSelectorValue])

  const handleFetchData = (isExport = false) => {
    const payload = {
      export: isExport,
      year: yearSelectorValue ? yearSelectorValue : undefined,
      quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
      yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
      simulation_id: simulationSelectorValue ? simulationSelectorValue : undefined,
      view_type: radioSelectorValue,
      plan_type: props.planType
    }

    dispatch({
      type: "FETCH_ACTUAL_VS_PLAN_TABLE_DATA",
      payload
    })
  }

  const handleRadioChange = (event) => { 
    setRadioSelectorValue(event.target.value)
  }

  return (
    <div>
      <div className='flex jc-space-between'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Month</InputLabel>
              <Select
                value={monthSelectorValue}
                label="Month"
                onChange={handleMonthChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {monthArr && monthArr.length && monthArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Quarter</InputLabel>
              <Select
                value={qtrSelectorValue}
                label="Quarter"
                onChange={handleQtrChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {qtrArr && qtrArr.length && qtrArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Annual</InputLabel>
              <Select
                value={yearSelectorValue}
                label="Annual"
                onChange={handleYearChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {yearArr && yearArr.length && yearArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          { props.planType != 'actual'  ? 
              <Grid item xs={12} sm={3} className='maxw-200'>
              <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Simulation</InputLabel>
                <Select
                  value={simulationSelectorValue}
                  label="Simulation"
                  onChange={handleSimulationChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {simulationArr && simulationArr.length && simulationArr.map(item => {
                    return <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          : ''  }
        </Grid>
        <Grid container justifyContent='flex-end'>
						<Tooltip title={'Download to Excel'} className='pointer'>
							<img onClick={() => {handleFetchData(true)}} src={DownloadExcel} className='pointer' height='30' />
						</Tooltip>
					</Grid>
      </div>
      <div>
        <FormControl component="fieldset">
          <RadioGroup name="radio" value={radioSelectorValue} onChange={handleRadioChange} className='radioGroupHorizontal'>
            <FormControlLabel value="view_byLocation" control={<Radio color="primary" />} label="By Country" />
            <FormControlLabel value="view_byPM" control={<Radio color="primary" />} label="By PM" />
            <FormControlLabel value="view_byProjectID" control={<Radio color="primary" />} label="By ProjectID" />
            <FormControlLabel value="view_byCustomerSegment" control={<Radio color="primary" />} label="By Customer Segment" />

          </RadioGroup>
        </FormControl>
        </div>
      <br />
      <div>
        <TableComponent />
      </div>
      { headerText != '' ? 
          <div className='mt-10 simulation-text '>
              <div>{ headerText + ' - ' + updatedOn }</div>
              <div className='simulation-detail' dangerouslySetInnerHTML={{ __html: detailText}}> 
              </div>
          </div>
      : '' }
    </div>
  )
}