import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import CommentModal from '../../../Ageing/ExternalComponent/CommentModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import {isSocketConnectionOpen, startWS} from '../../../../../utils/web-socket';
import {getAllCommentHistoryData} from '../../../../../utils/commentDataUtil';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import {
  getAccessByMenuKey,
  getActionOwneruser,
} from '../../../../../utils/userManagement';
import {changeKeysOfHeader} from './util';
import ForecastModal
  from '../../../Ageing/ExternalComponent/CollectionForecastModal';
import AddPipModal
  from '../../../Ageing/ExternalComponent/PerformanceImprovementComponent';
import ListPipModal
  from '../../../Ageing/ExternalComponent/PerformanceImprovementComponent/piplist';
import NewPagination from "../../../../common/components/CustomPagination";


const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class ReactTableComponent extends React.Component {

  constructor(props) {
    super(props);
    this.dataColumns = [];
    this.allUserList = [];
    this.taggedUser = [];
    this.myInterval = {};
    this.socketCollection = {};
    this.chatDataCollection = {};
    this.state = {
      showModal: false,
      channelId: '',
      showCommentModal: false,
      showModalPip: false,
      showModalListPip: false,
      showForecastModal: false,
      showInvoiceDetailModal: false,
      commentRow: {},
      customerName: '',
      age: '',
      updateFlag: false,
      tableRowData: [],
      rowHeaderData: [],
      chatDataCollection: {},
      catData: [],
      subCatData: [],
      cleanUpPipData: false,
      stageData: [],
      editModePip: false,
      editPipData: {},
      agingCategoryValue: '',
      imagePreview: null,
      imageName: '',
      uploadImageObj: {},
      refMenuAnchorEl: null,
      menuPositionX: null,
      menuPositionY: null,
      dataTable : [],
      arrChanges : {}
    };
  }

  componentDidMount() {
 
    // this.myInterval = setInterval(() => {
    //     this.callGetAllCommentHistoryFunc(this.props.externalState.activePage, this.props.invoiceListReportState.tableRowData)
    // }, 60 * 1000)
    // this.props.getPipMasterData({ bu: 'msm' })
  }

  setCommentModalData = () => {
    const {commentRow} = this.state;
    const {invoiceListReportState} = this.props;
    let commentArrData = [];
    if (invoiceListReportState.tableRowData &&
        invoiceListReportState.tableRowData.length) {
      commentArrData = invoiceListReportState.tableRowData.filter(item => {
        if (item.channel == commentRow.channel) {
          return item;
        }
      });
    }
    return commentArrData && commentArrData[0];
  };

  showCommentModal = (data) => {
    this.setState({
      showCommentModal: true,
      commentRow: data,
    });
  };

  showForecastModal = (data) => {
    this.setState({
      showForecastModal: true,
      commentRow: data,
    });
  };

  showModalListPip = (data) => {
    this.setState({
      catData: this.props.externalState.pipMaster.category,
      stageData: this.props.externalState.pipMaster.stage,
      showModalListPip: true,
      commentRow: data,
    });
  };

  showAddImporvementPlanModal = (data) => {
    this.setState({
      catData: this.props.externalState.pipMaster.category,
      stageData: this.props.externalState.pipMaster.stage,
      showModalPip: true,
      commentRow: data,
      cleanUpPipData: true,
      editModePip: false,
    });
  };

  handleChatUpdation = (gData) => {
    const {tableRowData, updateFlag} = this.state;
    const {invoiceListReportState} = this.props;
    if (invoiceListReportState.tableRowData &&
        invoiceListReportState.tableRowData.length) {
      const rowDataSet = invoiceListReportState.tableRowData;
      let indexData = -1;
      let updatedRowDataSet = rowDataSet.map((item, index) => {
        if (!item['obj'][gData.chatData[0].id]) {
          if (item.document_number == gData.invoice_no) {
            item['isChatConnection'] = true;
            indexData = index;
            item.commentArr.unshift(gData.chatData[0]);
            item['obj'][gData.chatData[0].id] = true;
          }
        }
        return item;
      });
      if (indexData > -1) {
        if (updatedRowDataSet[indexData].commentArr &&
            updatedRowDataSet[indexData].commentArr.length) {
          let obj = {};
          let commentModifiedArr = [];
          updatedRowDataSet[indexData].commentArr.forEach(item => {
            if (!obj[item.id]) {
              commentModifiedArr.push(item);
              obj[item.id] = true;
            }
          });
          updatedRowDataSet[indexData].commentArr = [
            ...[],
            ...commentModifiedArr];
        }
      }
      this.props.updateExternalData(updatedRowDataSet);
    }
  };

  handleConnectionClose = (channelId) => {
    console.log(channelId, 'connection closed');
    if (this.socketCollection[channelId]) {
      delete this.socketCollection[channelId];
    }
  };

  getPipID = (rowData) => {
    this.props.getPipIDData({bu: rowData.bu});
  };

  handleSocketConnectionError = (e) => {

    this.props.socketConnectionError(true);
  };
  establishSocketConnection = (data) => {
    console.log(data, 'establishSocketConnection');
    if (!this.socketCollection[data.channel]) {
      const socketInfo = startWS(
          data.channel,
          data.customer,
          data.company_code,
          data.project_id,
          data.commentArr,
          this.handleChatUpdation,
          this.handleConnectionClose,
          data.document_number,
          this.handleSocketConnectionError,
      );
      this.socketCollection[data.channel] = socketInfo;
    }
  };

  handleSendChatAttachmentFunction = (attachmentData) => {
    const sendChatObject = {
      'body': attachmentData.id,
      'type': attachmentData.type,
      'file_name': attachmentData.original_name,
    };
    this.props.uploadChatFileToggle();
    if (isSocketConnectionOpen(this.socketCollection[attachmentData.channel])) {
      this.socketCollection[attachmentData.channel].send(
          JSON.stringify(sendChatObject));
    }
  };

  handleSendChatFunction = (rowData) => {
    let sendChatObject = {};
    if (document.getElementById(`outlined-basic-${rowData.channel}`).value) {
      sendChatObject = {
        'body': document.getElementById(
            `outlined-basic-${rowData.channel}`).value,
        'type': 'text',
        'tagged_user': this.taggedUser,
      };
      if (!isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        delete this.socketCollection[rowData.channel];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        this.socketCollection[rowData.channel].send(
            JSON.stringify(sendChatObject));
        this.chatDataCollection[rowData.channel] = '';
        document.getElementById(`outlined-basic-${rowData.channel}`).value = '';
        this.taggedUser = [];
      }
    }
  };

  handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
    const {chatDataCollection} = this.state;
    const sendChatObject = {
      'body': inputValue,
      'type': 'text',
      'tagged_user': taggedUser,
    };
    if (inputValue) {
      if (!isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        delete this.socketCollection[rowData.channel];
        this.establishSocketConnection(rowData);
      }
      if (isSocketConnectionOpen(this.socketCollection[rowData.channel])) {
        this.socketCollection[rowData.channel].send(
            JSON.stringify(sendChatObject));
        chatDataCollection[rowData.channel] = '';
        this.setState({'chatDataCollection': chatDataCollection});
      }
    }
  };

  handleChatChangeFn = (event, rowData) => {
    console.log('kkkkkkkkkkk', event.target.value, rowData);
    this.chatDataCollection[rowData.channel] = event.target.value;

    if (event.target && event.target.value) {
      const valueLen = event.target.value.length;
      const targetValue = event.target.value;
      if (valueLen && targetValue[valueLen - 1] == '@') {
        this.setState({
          refMenuAnchorEl: event.currentTarget,
          channelId: rowData.channel,
          menuPositionX: event.currentTarget.getBoundingClientRect().left,
          menuPositionY: event.currentTarget.getBoundingClientRect().top,
        });
      }
    }
  };

  onImageInputHandler = (event, props) => {
    let imagePreview;
    if (event.target.files[0].type == 'application/pdf' ||
        event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      imagePreview = '';
    } else {
      imagePreview = URL.createObjectURL(event.target.files[0]);
    }
    let imageName = event.target.files[0].name;
    const {uploadImageObj} = this.state;
    uploadImageObj[props.channel] = {
      imagePreview,
      imageName,
      file: event.target.files[0],
    };
    this.setState({'uploadImageObj': uploadImageObj});
  };

  handleImageOptions = (event, props) => {
    const {uploadImageObj} = this.state;
    if (event === 'approve') {
      const imageObj = uploadImageObj[props.channel];
      if (imageObj) {
        const payloadData = {
          file: imageObj.file,
          id: props.channel,
        };
        this.props.uploadChatFile(payloadData);
      }
    }
    if (event === 'cancel') {
      this.imagePreview = null;
    }
    if (document.getElementById('upload-icon')) {
      document.getElementById(`upload-icon`).value = '';
    }
    document.getElementById(`upload-icon${props.channel}`).value = '';
    delete uploadImageObj[props.channel];
    this.setState({'uploadImageObj': uploadImageObj});
  };

  handleSaveChanges = () => {
    const {dataTable, arrChanges} = this.state
    let arr = []
    for (const key in arrChanges) {
      arr.push(arrChanges[key])
    }
    this.props.saveDropdownChanges(arr)
  }
  handleCountryChanges = (data) => {
    // const {invoiceListReportState} = this.props;
    // invoiceListReportState.tableRowData[data.index] = data.dataChanges
    // let arr = []
    // arr.push(data.dataChanges)
    // this.props.saveDropdownChanges(arr)
    // console.log(invoiceListReportState.tableRowData, "cekDataCountry")
    const {dataTable, arrChanges} = this.state
    let dataTableNew = [...dataTable]
    let idx =  data.index
    dataTableNew[idx] = data.dataChanges
    // dataTableNew[idx].new_bu = dataTableNew[idx].New_business_unit
    let obj = arrChanges
    obj[idx] =  data.dataChanges
    this.setState({dataTable : dataTableNew, arrChanges : obj})
  }
  handleClassificationChanges = (data) => {
    const {invoiceListReportState} = this.props;
    const {dataTable, arrChanges} = this.state
    let dataTableNew = [...dataTable]
    let idx =  data.index
    dataTableNew[idx] = data.dataChanges
    dataTableNew[idx].new_bu = dataTableNew[idx].New_business_unit
    let obj = arrChanges
    obj[idx] =  data.dataChanges
    this.setState({dataTable : dataTableNew, arrChanges : obj})
    
  }
  handleActionOwnerChange = (data, props) => {
    const payload = {
      invoice_no: props.document_number,
      action_owner: data,
      bu: props.bu,
      customer:props.customer,
      company_code: props.company_code,
      project_id: props.project_id,
      report_date: props.report_date,
    };
    props.action_owner = data;
    this.props.updateActionOwnerFn(payload);
  };

  handleRefMenuClose = (item) => {

    console.log('pppppppp>>>>>', item);
    const {channelId} = this.state;
    this.taggedUser.push(item.login_id);
    document.getElementById(`outlined-basic-${channelId}`).value += item.name ||
        '';

    if (this.state.refMenuAnchorEl && this.state.refMenuAnchorEl.value) {
      this.state.refMenuAnchorEl.value += item.name || '';
      this.chatDataCollection[channelId] += item.name || '';
    }
    this.setState({refMenuAnchorEl: null}, () => {
      document.getElementById(
          `outlined-basic-${channelId}`).value += this.chatDataCollection[channelId] ||
          '';

    });
  };

  handleRowClick = (rowData) => {
    if (rowData.original.channel) {
      this.establishSocketConnection(rowData.original);
      const commentPayload = {
        channelId: rowData.original.channel,
      };
      if (!this.socketCollection[rowData.original.channel]) {
        this.props.fetchOneCommentDataHistory(commentPayload);
      }
    } else {
      console.log(rowData.original, 'pppppp');
      const createChannelPayload = {

        invoice_no: rowData.original.document_number,
      };
      this.props.createNewChannel(createChannelPayload);
    }
  };

  rowClickFunction = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          this.handleRowClick(rowInfo);
        },
      };
    } else {
      return {};
    }
  };

  callGetAllCommentHistoryFunc = (activePage, rowData) => {
    const getCommentPayload = getAllCommentHistoryData(activePage, rowData);
    this.props.fetchCommentDataHistory(getCommentPayload);
  };

  onPageChange = (data) => {
    const {updateActivePage, onPageChange} = this.props;
    updateActivePage(data);
    if (onPageChange) {
      onPageChange(data);
    }
  };

  setCommentModalData = () => {
    const {commentRow} = this.state;
    const {invoiceListReportState} = this.props;
    let commentArrData = [];
    if (invoiceListReportState.tableRowData &&
        invoiceListReportState.tableRowData.length) {
      commentArrData = invoiceListReportState.tableRowData.filter(item => {
        if (item.channel == commentRow.channel) {
          return item;
        }
      });
    }
    return commentArrData && commentArrData[0];
  };

  handleForeCastSubmit = (payload) => {
    this.props.sendCollectionForecast(payload);
    this.forecastmodalCallBack();
  };

  forecastmodalCallBack = () => {
    this.setState({showForecastModal: false});
  };

  modalCallBack = () => {
    this.setState({showCommentModal: false});
  };

  handleCustomerIdClick = (data) => {
    this.props.history.push({
      pathname: '/dashboard/receivables/reports/aging',
      state: data,
    });
    console.log(data, 'handleCustomerIdClick');
  };

  modifyHeaders() {
    const {
      invoiceListReportState,
      authState,
      summaryState,
      externalState,
      buValue,
    } = this.props;
    return changeKeysOfHeader(
        invoiceListReportState.tableRowHeader,
        summaryState,
        authState,
        externalState,
        buValue,
        this.handleChatChangeFn,
        this.onImageInputHandler,
        this.handleSendChatFunction,
        this.handleImageOptions,
        this.state.uploadImageObj,
        this.getPipID,
        this.showCommentModal,
        this.showForecastModal,
        this.showModalListPip,
        this.showAddImporvementPlanModal,
        this.handleActionOwnerChange,
        this.handleCustomerIdClick,
        this.chatDataCollection,
        invoiceListReportState.invoiceListDropdown,
        this.handleCountryChanges,
        this.handleClassificationChanges,
    );
  }

  componentDidUpdate(prevProps) {
    const {invoiceListReportState, externalState, chatStoreState} = this.props;
    if (invoiceListReportState.createChannel) {
      this.establishSocketConnection(invoiceListReportState.newRowItem);
      this.props.updateChannelFlag(false);
    }
    if(prevProps.invoiceListReportState.loading && !this.props.invoiceListReportState.loading){
      this.setState({dataTable : invoiceListReportState.tableRowData})
      this.setState({ arrChanges : {} })
    }

    if(prevProps.invoiceListReportState.loading && !this.props.invoiceListReportState.loading && Object.getOwnPropertyNames(this.state.arrChanges).length > 0){
      this.props.handleRefresh();
    }

    if (externalState.selectedPipData.BsonID !=
        prevProps.externalState.selectedPipData.BsonID) {
      this.modalCallBackPip();
      if (!this.state.editModePip) {
        this.props.handleRefresh();
      }
    }

    if (chatStoreState.uploadFile) {
      this.handleSendChatAttachmentFunction(chatStoreState.data);
    }
  }

  modalCallBackPip = () => {
    this.setState({showModalPip: false});
  };

  modalCallBackListPip = () => {
    this.setState({showModalListPip: false});
  };

  modalCallBackcleanUpPipData = () => {
    this.setState({cleanUpPipData: false});
  };

  changeSubCatData = (cat) => {
    this.setState({
      subCatData: this.props.externalState.pipMaster.subcategory.filter(val =>
          val.parent_value == cat,
      ),
    });
  };

  handlePipSave = (data) => {
    this.props.savePipData(data);
  };

  handlePipUpdate = (data) => {
    this.props.updatePipData(data);
  };

  handleEditModePip = (data) => {
    this.setState({
      editModePip: true,
      showModalPip: true,
      editPipData: data,
      cleanUpPipData: true,
    });
  };
  // handleDataTable = (data) => {
  //   if(data.length > 0){
  //     console.log(data, "cekData123")
  //     this.setState({dataTable : data})
  //   }
  // }
  render() {
    const {
      externalState,
      dashboardState,
      authState,
      invoiceListReportState,
    } = this.props;
    const {
      cleanUpPipData,
      showModalListPip,
      stageData,
      editModePip,
      editPipData,
      showCommentModal,
      showModalPip,
      catData,
      subCatData,
      showModal,
      commentRow,
      rowHeaderData,
      uploadImageObj,
      showForecastModal,
      dataTable,
      arrChanges
    } = this.state;
    const commentModalData = this.setCommentModalData();
    const menus = authState.menuData;
    let bu = this.props.buValue;
    //default list by user BU
    let buUser;
    if (this.props.externalState && this.props.externalState.allUser) {
      let allUser = this.props.externalState.allUser;

      buUser = getActionOwneruser(allUser, bu);
    }

    const pagesCount = invoiceListReportState?.totalTableRowCount ? Math.ceil(invoiceListReportState.totalTableRowCount / 10) : 0
   
    // 
    return (
        <div className="externalComponentPage ">
          {invoiceListReportState.loading ?
              <div className="progressfullpage"><CircularProgress/></div> :
              <React.Fragment>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    id="customized-menu"
                    anchorReference="anchorPosition"
                    anchorPosition={{
                      top: this.state.menuPositionY,
                      left: this.state.menuPositionX,
                    }}
                    keepMounted
                    open={Boolean(this.state.refMenuAnchorEl)}
                    onClose={this.handleRefMenuClose}
                >
                  {
                    buUser && buUser.length &&
                    buUser.map((item, index) => {
                      return <MenuItem
                          value={item.full_name}
                          key={index}
                          onClick={() => this.handleRefMenuClose(item)}
                      >
                        <div className="flex jc-space-between w-100 ai-center">
                          <span className="pr-10">{item.full_name}</span>
                          <span style={{
                            opacity: '0.5',
                            float: 'right',
                          }}>{item.role}</span>
                        </div>
                      </MenuItem>;
                    })
                  }
                </Menu>
                <ReactTableFixedColumns
                    data={dataTable ?
                      dataTable :
                        []}
                    columns={this.modifyHeaders()}
                    style={{height : "600px" }}
                    defaultPageSize={10}
                    loading={invoiceListReportState.loading}
                    PaginationComponent={NewPagination}
                    className="xpna-reactFixed-table -striped -highlight"
                    onPageChange={this.onPageChange}
                    // getTrProps={this.rowClickFunction}
                    pages={pagesCount}
                    pageSizeOptions={[10, 20, 30]}
                    // style={{
                    //     maxHeight: "465px"
                    // }}
                />
                {showCommentModal &&
                <CommentModal
                    showModal={showCommentModal}
                    commentRowData={commentRow}
                    modalCallBack={this.modalCallBack}
                    commentsData={commentModalData}
                    userDataState={authState.userData}
                    sendCommmentCallback={this.handleSendChatModalFunction}
                    buValue={this.props.buValue}
                    onImageInputHandler={this.onImageInputHandler}
                    handleImageOptions={this.handleImageOptions}
                    uploadImageObj={uploadImageObj}
                    allUsers={this.props.externalState.allUser}
                    menuData={menus}
                />
                }
                {
                  showForecastModal && <ForecastModal
                      showModal={showForecastModal}
                      commentRowData={commentRow}
                      modalCallBack={this.forecastmodalCallBack}
                      handleForeCastSubmit={this.handleForeCastSubmit}
                  />
                }
                {
                  <AddPipModal
                      showModal={showModalPip}
                      modalCallBackPip={this.modalCallBackPip}
                      userDataState={authState.userData}
                      catData={catData}
                      subCatData={subCatData}
                      changeSubCatData={this.changeSubCatData}
                      buValue={this.props.buValue}
                      customerData={commentRow}
                      allUser={this.props.externalState.allUser}
                      saveCallback={this.handlePipSave}
                      updateCallback={this.handlePipUpdate}
                      pipIDData={externalState.pipData.pipIDData}
                      cleanUpPipData={cleanUpPipData}
                      modalCallBackcleanUpPipData={this.modalCallBackcleanUpPipData}
                      editMode={editModePip}
                      editData={editPipData}
                      stageData={stageData}
                      type={'100k'}
                      loading={externalState.pipData.loading}
                  />
                }
                {
                  <ListPipModal
                      editMode={editModePip}
                      showModalPip={showModalPip}
                      showModal={showModalListPip}
                      modalCallBackPip={this.modalCallBackListPip}
                      customerData={commentRow}
                      editAccess={getAccessByMenuKey(menus,
                          '100k-120_days_reports', 'edit')}
                      type={'100k'}
                      buValue={this.props.buValue}
                      editCallBack={this.handleEditModePip}
                  />
                }
                    <div className="flex jc-flex-end mt-10">

                {
                  Object.getOwnPropertyNames(this.state.arrChanges).length == 0 ? "" :
                 <Button color="primary" variant="contained" className='mt-5' onClick={this.handleSaveChanges}>Save Changes</Button>
                }
                  </div>

              </React.Fragment>
              
          }
        </div>
    );
  }
}
export default withRouter(
    connect(
        state => {
          return {
            summaryState: state.dashboardState.receivableData.ageingData.summary,
            dashboardState: state.dashboardState,
            authState: state.authReducer,
            invoiceDetailState: state.invoiceDetailState,
            chatStoreState: state.chatStore,
            externalState: state.dashboardState.receivableData.ageingData.external,
            invoiceListReportState: state.invoiceListReportState,
          };
        },
        dispatch => {
          return {
            getPipMasterData(data) {
              dispatch({
                type: 'FETCH_MASTER_PIP_DATA',
                payload: data,
              });
            },
            savePipData(data) {
              dispatch({
                type: 'SAVE_PIP_DATA',
                payload: data,
              });
            },
            updatePipData(data) {
              dispatch({
                type: 'UPDATE_PIP_DATA',
                payload: data,
              });
            },
            getPipIDData(data) {
              dispatch({
                type: 'FETCH_PIP_ID',
                payload: data,
              });
            },
            updateSelectedDate(data) {
              dispatch({
                type: 'UPDATE_SELECTED_DATE',
                payload: data,
              });
            },
            createNewChannel(data) {
              dispatch({
                type: 'CREATE_CHANNEL_DAY_ID',
                payload: data,
              });
            },
            updateChannelFlag(data) {
              dispatch({
                type: 'CHANGE_CREATE_CHANNEL_DAY_FLAG',
                payload: data,
              });
            },
            updateActivePage(data) {
              dispatch({
                type: 'UPDATE_ACTIVE_PAGE_EXTERNAL',
                payload: data,
              });
            },
            fetchOneCommentDataHistory(data) {
              dispatch({
                type: 'FETCH_COMMENT_ONE_CHANNEL_DAY_DATA_HISTORY',
                payload: data,
              });
            },
            sendCollectionForecast(data) {
              dispatch({
                type: 'SEND_COLLECTION_FORECAST_INVOICE_DAY',
                payload: data,
              });
            },
            updateActionOwnerFn(data) {
              dispatch({
                type: 'UPDATE_ACTION_OWNER_DAY',
                payload: data,
              });
            },
            fetchCommentDataHistory(data) {
              dispatch({
                type: 'FETCH_COMMENT_CHANNEL_DAY_DATA_HISTORY',
                payload: data,
              });
            },
            updateExternalData(data) {
              dispatch({
                type: 'UPDATE_DAY_DATA_SET',
                payload: data,
              });
            },
            uploadChatFile(data) {
              dispatch({
                type: 'CHAT_UPLOAD_FILE',
                payload: data,
              });
            },
            saveDropdownChanges(data) {
              dispatch({
                type: 'SAVE_DROPDOWN_CHANGES',
                payload: data,
              });
            },
            uploadChatFileToggle() {
              dispatch({
                type: 'CHAT_UPLOAD_FILE_TOGGLE',
              });
            },
            socketConnectionError(data) {
              dispatch({
                type: 'SHOW_ERROR_PAGE',
                payload: data,
              });
            },
          };
        },
    )(ReactTableComponent),
);
