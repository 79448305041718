import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from '../auth/reducer'
import dashboardReducer from '../dashboard/reducer'
import dataBrowser from '../dataBrowser/reducer'
import invoiceDetail from '../invoiceDetail/reducer'
import userManagement from '../userManagement/reducer'
import chatStore from '../chatStore/reducer'
import incomeStatement from '../../components/IncomeStatement/store/reducer'
import dayReport from '../../components/Receivable/Reports/DayReports/store/reducer'
import monthlyStatusReport from '../../components/Receivable/Reports/MonthlyStatusReports/store/reducer'
import invoiceListReport from '../../components/Receivable/Reports/InvoiceReports/store/reducer'
import geospacialReducer from '../../components/GeoLocation/store/reducer';
import freeCashFlowReducer from '../../components/FreeCashFlow/store/reducer'
import ECFMReducer from '../../components/ECFM/store/reducer'
import ExtendedPlanningReducer from '../../components/ExtendedPlanning/store/reducer'
import shreeCementReducer from '../../components/poc-shreecement/store/reducer';
import functionalPlanningReducer from '../../components/FunctionalPlanning/store/reducer';

export default combineReducers({
    authReducer,
    toastr: toastrReducer,
    dashboardState: dashboardReducer,
    dataBrowser: dataBrowser,
    invoiceDetailState: invoiceDetail,
    userManagementState: userManagement,
    chatStore: chatStore,
    incomeStatement: incomeStatement,
    dayReportState: dayReport,
    invoiceListReportState: invoiceListReport,
    geospacialState: geospacialReducer,
    monthlyStatusReportState: monthlyStatusReport,
    freeCashFlowState:freeCashFlowReducer,
    ecfmState: ECFMReducer,
    extendedPlanning: ExtendedPlanningReducer,
    shreeCementState: shreeCementReducer,
    functionalPlanningState: functionalPlanningReducer,
})
