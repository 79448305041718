import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NewPagination from "../../../common/components/CustomPagination";

import { handleTableTransform } from '../../../../utils/numberUtils'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { format } from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
import {
    downloadExcel,
    pdfFileName,
    mapExcelData,
    mapExcelHeader,
    mapExcelHeaderComparative,
    mapExcelDataPLComparative,
    mapDataPDF,
    mapPDFDataPLComparative
} from '../../../../utils/exportExcel'

export default function SimpleModal(props) {

    Object.keys(props.drilldownRowData).map(key => {
        const obj = props.drilldownRowData[key];
        Object.keys(obj).map((key, index) => {
            obj[key] = key.includes("date")? new Date(obj[key]).toLocaleDateString(['ban', 'id'], {year: '2-digit', month: '2-digit', day: '2-digit'}) : obj[key];
        })
    })

    let tableData = [];
    let drilldownRowData = [];
    if(props.drilldownRowData) {
        drilldownRowData = props.drilldownRowData;
    }
    const handleClose = () => {
        props.modalCallBack(false);
    };

    const unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    const unwindSubRows = (data) => {
        let newData = []
        data.forEach(x => {
            newData.push(x)
            if(x.sub_rows){
                x.sub_rows = unwindHeaderKeyVal(x.sub_rows)
                x.sub_rows.forEach(y => {
                    y.data_field = "     "+y.data_field 
                    newData.push(y)
                })
            }
        })
        return newData
    }

    const getFileName = () => {
        let sel =  props.showDrilldownHeaderValue;
        return sel;
    }



    const handleExcelDownload = () => {
        let data = []
        let header = {};
        let merge = [];

        let datas = {};
        datas.header = props.drilldownRowHeader;
        datas.data = props.drilldownRowData;
        header = datas.header;
        datas.data = unwindHeaderKeyVal(datas.data);
        datas.data = unwindSubRows(datas.data)
        data = mapExcelData(datas.data, header)
        header = mapExcelHeader(header, "label")
       
        let fileName = " Income Statement - T&E Analysis - " + getFileName()
        let sheetName = 'Data';
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }

    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            tableData = data.map((item, i) => {

                return {
                    Header: <div className='flex ai-center jc-center'>
                        <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                        {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                        {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                    </div>,
                    id: item.key,
                    Cell: (props) => {
                        return (

                            <div className='text-center'>
                                {item.key == "viewnetduedate" ? format(parseJSON(props.original["viewnetduedate"]), 'dd/MM/yy') : handleTableTransform(props.original, item)
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "hidden",
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'hidden',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }
            })

        }
        return []
    }

    changeKeysOfHeader(props.drilldownRowHeader);

    const handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            console.log(colInfo, 'pppppp')
            props.handleInvoiceSorting(colInfo.id)
        }
    }

    const body = (
        <div className='customModal'>
            <div className='header'>
                {props.showDrilldownHeaderValue ? <h2>{` ${props.showDrilldownHeaderValue}`}</h2> : <h2></h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <div className='text-right' style={{ marginBottom: '10px'}}>
                    <Tooltip title={'Download to Excel'}>
                        <img
                            src={DownloadExcel}
                            className='pointer'
                            height='30'
                            onClick={(event) => {
                                event.preventDefault();
                                handleExcelDownload();
                            }} />
                    </Tooltip>
                </div>
               {
                <ReactTable
                    columns={tableData}
                    data={drilldownRowData}
                    defaultPageSize={50}
                    pageSize={Math.min(20, drilldownRowData.length)}
                    className="-striped -highlight"
                    PaginationComponent={NewPagination}
                    sortable={false}
                    // getTheadThProps={columnHeaderClick}
                /> 
                }
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
