import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import BarChartIcon from '@material-ui/icons/BarChart';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CommentBox from '../comment';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

function createDataESP(ESP_ID, Progress, Color_Progress, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat){
  return {ESP_ID, Progress, Color_Progress, Current_MTBF, Anticipated_Days,Platform,Well,Initiate_Chat}
}

const rows = [
  createDataESP('HHHW1', '30%', '#F05757' ,'10 Days', '20 Days','','',''),
  createDataESP('HHHW1', '50%', '#F3C954' ,'20 Days', '20 Days','','',''),
  createDataESP('HHHW1', '60%', '#8DD542','80 Days', '20 Days','','',''),
  createDataESP('HHHW1', '50%', '#F3C954','30 Days', '20 Days','','',''),
  createDataESP('HHHW1', '70%', '#8DD542','90 Days', '20 Days','','',''),
];

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function TableView() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openComment, setOpenComment] = React.useState(false);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleOpen = () => setOpenComment(true);
    const handleClose = () => setOpenComment(false);

    return (
         <div className="table-view">
             <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className="btn-action text-right">
                        <Button variant="contained" size="small" color="primary" className="mr-20 btn-apply" onClick={()=>{handleOpen()}}>
                          Initiate Chat
                        </Button>
                         <Button variant="contained" size="small" className="btn-download-excel">
                          <DescriptionIcon/>
                        </Button>
                    </div>
                </Grid>
              </Grid>
             <Grid container spacing={3}>
                <Grid item xs={12}>
                   <TableContainer>
                      <Table aria-label="a dense table" className="custom-table" size="small"> 
                        <TableHead>
                          <TableRow>
                            <TableCell rowspan={2}></TableCell>
                            <TableCell rowspan={2}>ESP ID</TableCell>
                            <TableCell colspan={5}>Operational Parameters</TableCell>
                            <TableCell rowspan={2}>Performance Range</TableCell>
                            <TableCell rowspan={2}>Anticipated Days</TableCell>
                            <TableCell rowspan={2}>Days since Amber</TableCell>
                            <TableCell rowspan={2}>Days since Red</TableCell>
                            <TableCell rowspan={2}>Open Job ticket</TableCell>
                            <TableCell rowspan={2}>Due date</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>GOR </TableCell>
                            <TableCell>Pump intake pressure</TableCell>
                            <TableCell>Fluid Viscosity</TableCell>
                            <TableCell>Water Cut</TableCell>
                            <TableCell>Flow Rate</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.ESP_ID}>
                              <TableCell><Checkbox/></TableCell>
                              <TableCell className="esp-cell">{row.ESP_ID} <BarChartIcon className="ml-10 text-primary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}/></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell width="120"><a href="#" className="ml-10 text-primary">Add Job ticket</a></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography>Chart View</Typography>
                    </Popover>
                </Grid>
              </Grid>

          <Modal
              className="collaborate-modal"
              open={openComment}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
              }}
          >
              <Fade in={openComment}>
                  <CommentBox handleClose={handleClose}/>
              </Fade>
          </Modal>
         </div>
        
    )
}

export default TableView;