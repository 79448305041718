
import React from "react";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../utils/dateFormat'
import { CONFIG } from '../../../../config'
import { numberWithCommas, handleTableTransform } from '../../../../utils/numberUtils'

import { getAccessByMenuKey , getActionOwneruser} from "../../../../utils/userManagement";
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ActionOwnerPopper = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

const headerColorObj = {
    "current": '#64DD17',
    "1_30_days": '#AEEA00',
    "31_60_days": '#FFD600',
    "61_90_days": '#FFAB00',
    "91_180_days": '#FF6D00',
    "180_plus_days": '#d00909'
}

const agingCategoryArr = [
    '1_30_days',
    '31_60_days',
    '61_90_days',
    '91_180_days',
    '180_plus_days',
    'grand_total',
    'overdue_amount',
    'current'
]

const handleDynamicWidth = (item) => {
    let widthSize = 65;
    switch (item.key) {
        case 'percentage':
            widthSize = 90;
            break;
        case 'grand_total':
            widthSize = 80;
            break;
        case 'customer_name':
            widthSize = 130;
            break;
        case 'project':
            widthSize = 80;
            break;
        case 'document_number':
            widthSize = 80;
            break;
        case 'assignment':
            widthSize = 80;
            break;
        case 'overdue_amount':
            widthSize = 120;
            break;
        case 'improvement_plan':
            widthSize = 120;
            break;
        case 'collection_forecast':
            widthSize = 120;
            break;
        case 'collection_actual':
            widthSize = 120;
            break;
        case 'action_owner':
            widthSize = 150;
            break;
        case 'text':
            widthSize = 90;
            break;

        default: break;
    }
    return widthSize
}

const modifyColumnData = (columnData) => {
    let modifiedData;
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData
    return modifiedData
}

const handleSummaryColumn = (dataItem, buRowData) => {
    let returnString = '';
    returnString = dataItem.label;
    if (buRowData[dataItem.key]) {
        returnString = returnString + ' ' + modifyColumnData(buRowData[dataItem.key])
    }
    return returnString;
}

const handleFixedColumn = (item) => {
    let fixed = ''
    switch (item.key) {
        case 'customer_name':
            fixed = 'left'
            break;
        case 'customer':
            fixed = 'left'
            break;
        case 'company_code':
            fixed = 'left'
            break;

        default: break;
    }
    return fixed
}

export const changeKeysOfHeader = (
    data,
    summaryState,
    authState,
    externalState,
    buValue,
    handleChatChangeFn,
    onImageInputHandler,
    handleSendChatFunction,
    handleImageOptions,
    uploadImageObj,
    getPipID,
    showCommentModal,
    showForecastModal,
    showModalListPip,
    showAddImporvementPlanModal,
    handleActionOwnerChange,
    handleCustomerIdClick,
    chatDataCollection,
    usersList,
    handleCollaborateClickEmail,
    handleCheckboxChange,
    handleImprovementPlanClick,
    handleCollaborateClickChat,
) => {

    const handleActionOwnerChangeFn = (dataJson, data, item) => {
        // console.log(dataJson.full_name, 'lllll');
        let action_type = "action_owners_to"
        let action_owners = []
        if (item.key == 'action_owner_cc') {
            action_type = 'action_owners_cc'
        }
        if(dataJson != null){
            dataJson.forEach((value, key)=>{
                action_owners.push({
                    action_party_name: value.full_name,
                    action_party_email: value.email
                })

            })
        }
        let defaultData = {
            action_owners_to : data.action_owner_to.map((value, key)=>{
                return {
                    action_party_name: value,
                    action_party_email: value
                }
            }),
            action_owners_cc : data.action_owner_cc.map((value, key)=>{
               return {
                    action_party_name: value,
                    action_party_email: value
                }

            }),
            dimension: data.business_line,
            kpi_type: data.kpi_type,
            kpi_category: data.kpi_category,
            kpi: data.kpi
        }

        defaultData[action_type] = action_owners
        handleActionOwnerChange(defaultData)
    }
   
    const convertAmountData = (data) => {
            var modifiedData = data 
            if(modifiedData < 0 && modifiedData!= -1){
                var positiveTransformedValue = Math.abs(modifiedData)
            }else {
                var positiveTransformedValue = modifiedData
            }
        modifiedData = numberWithCommas(positiveTransformedValue)
        return modifiedData
      }
      const convertAmountDataWithoutRound = (data) => {
        var modifiedData = data
    
        if (modifiedData < 0 && modifiedData != -1) {
          var positiveTransformedValue = Math.abs(modifiedData)
        } else {
          var positiveTransformedValue = modifiedData
        }
        modifiedData = numberWithCommas(positiveTransformedValue)
        return modifiedData
      }
    const menus = authState.menuData
    let buUser = getActionOwneruser(externalState.allUser, buValue);
    // externalState.allUser.filter(item => {
    //     if (item && item.bu && buValue) {
    //         return item.bu.toLowerCase() == buValue.toLowerCase()
    //     }
    // });

    // if (buUser.length == 0) {
    //     buUser = externalState.allUser.filter(x => {
    //         let res = false;
    //         let roles = ["admin", "corporate", "functional"];
    //         roles.forEach(y => {
    //             if (!res)
    //                 res = x.role.toLowerCase().indexOf(y.toLowerCase()) > -1
    //         })
    //         return res
    //     })
    // }

    // buUser = buUser.sort(
    //     function (a, b) {
    //         if (a.role === b.role) {
    //             // order by role and name
    //             return a.full_name > b.full_name ? 1 : -1;
    //         }
    //         return a.role > b.role ? 1 : -1;
    //     });

    if (data && data.length) {
        return data.map((item) => {
            if (item.key == "collaborate_automated_reporting") {
               
                return {
                    Header: item.label,
                    id: item.key,
                    width: 400,
                    style: {
                        width: "65px",
                        whiteSpace: "break-spaces",
                        overflow: "visible",
                        minWidth: '400px',
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold'
                    },
                    accessor: item.key,
                    Cell: (props) => (
                        <div className='kcommentContainerTable'>
                            <div className='kcommentInputSection flex ai-center jc-space-between'>
                                <TextField
                                    id={`outlined-basic-${props.original.channel}`}
                                    label="Type your Comment"
                                    name="comment"
                                    style={{flex: '1'}}
                                    // value={chatDataCollection[props.original.channel]}
                                    placeholder="Comments"
                                    multiline
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => { handleChatChangeFn(event, props.original) }}
                                />
                                <input style={{ display: 'none' }} id={`upload-icon${props.original.channel}`} type="file" onChange={(event) => onImageInputHandler(event, props.original)} />
                                {getAccessByMenuKey(menus, "aging_reports", "upload") &&
                                    <Tooltip title='Attach File'>
                                        <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0 primary'>
                                            <label htmlFor={`upload-icon${props.original.channel}`}>
                                                <Icon  className="fs-20" fontSize="small">attach_file</Icon>
                                            </label>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {getAccessByMenuKey(menus, "aging_reports", "add") &&
                                    <Tooltip title='Send'>
                                        <IconButton color="primary" aria-label="upload picture" className='px-0 mx-0 primary' onClick={() => { handleSendChatFunction(props.original) }}>
                                            <Icon className="fs-20" >send</Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                            {uploadImageObj[props.original.channel] &&
                                <div className="uploadedFilePreview">
                                    <img src={uploadImageObj[props.original.channel].imagePreview} height="50" width="50" />
                                    <span>{uploadImageObj[props.original.channel].imageName}</span>
                                    <IconButton aria-label="approve upload" onClick={() => handleImageOptions('approve', props.original)}>
                                        <Icon fontSize="small">check</Icon>
                                    </IconButton>
                                    <IconButton aria-label="cancel upload" onClick={() => handleImageOptions('cancel', props.original)}>
                                        <Icon fontSize="small">clear</Icon>
                                    </IconButton>
                                </div>}
                            { props.original.commentArr && props.original.commentArr.length ? <div className='kcommentArea'>
                                {props.original &&
                                    props.original.commentArr &&
                                    props.original.commentArr.length
                                    ? props.original.commentArr.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='date'>{item.name}, {formatDate(item.timestamp)}</div>
                                                {item.type == 'image' ?
                                                   <img width='100' src={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel}/${item.body}`} /> :
                                                item.type == 'doc' ?
                                                 <div><a href={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel}/${item.body}`}>
                                                  {item.file_name}
                                                </a></div> :
                                                 <div dangerouslySetInnerHTML={{  
                                                  __html: item.body.replace(/@\S+/gi, '<strong class="primary-dark">$&</strong>')
                                                       }}></div>
                                                }
                                          </div>
                                        );
                                    })
                                    : ""}
                            </div> : ""}
                            <div className='commentModalIcon text-center'>
                                <Tooltip title='More'>
                                    <Icon className="fs-20" color="primary"
                                        onClick={() => {
                                            showCommentModal(props.original)
                                        }}>
                                        more_horiz
                    </Icon>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                };
            } 

            if (item.key == 'collaborate') {
                return {
                    Header: item.label,
                    id: item.key,
                    width: 110,
                    accessor: item.key,
                    Cell: (props) => {
                        return (
                            <div className="flex ai-center jc-space-evenly">
                                <Tooltip title="Auto Emails">
                                    <Badge
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        badgeContent={props.original.pip_count}
                                        color="primary"
                                    >
                                        <Icon
                                            className="fs-20 primary"
                                            color={
                                                "inherit"
                                            }
                                            onClick={() => {handleCollaborateClickEmail()}}
                                        >
                                            mail_outline
                                        </Icon>
                                    </Badge>
                                </Tooltip>
                                {(
                                    <Tooltip title={"Collaborate"}>
                                        <Icon
                                            className="fs-20 primary"
                                            color={"inherit"}
                                            disabled
                                            onClick={() => {
                                                showCommentModal(props.original)
                                                handleCollaborateClickChat(props)
                                            }}
                                        >
                                            chat_bubble_outline
                                        </Icon>
                                    </Tooltip>
                                )}
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }

            }


            if (item.key == 'action_owner_to' || item.key == 'action_owner_cc') {
                return {
                    Header: item.label,
                    id: item.key,
                    width: 200,
                    accessor: item.key,
                    Cell: (props) => {

                        let defaultValueActionOwner = []
                                // let label = ""
                                if(props.original[item.key] && props.original[item.key].length > 0 && props.original[item.key] !== ""){ 
                                    props.original[item.key].forEach(value => {
                                        usersList.forEach(x => {
                                            // console.log(x.email , value)
                                            if(x.email == value){
                                                defaultValueActionOwner.push(x)
                                            } 
                                        })
                                    })
                                }

                        return (
                            <Autocomplete
                                // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                                multiple
                                className='actionOwnerTabelSelect'
                                options={usersList}
                                size='small'
                                // disabled={this.state.filterView == 'slb' || this.state.filterView == 'pemex'}
                                PopperComponent={ActionOwnerPopper}
                                getOptionLabel={(option) => option.full_name}
                                value={defaultValueActionOwner}
                                onChange={(event, data) => { handleActionOwnerChangeFn(data, props.original, item) }}
                                renderInput={(params) => (
                                    // <Tooltip title={label}>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                    />
                                    // </Tooltip>
                                )}
                                renderOption={(option) => <Typography
                                    style={{ fontSize: '12px', width: '250px' }}
                                    className='flex jc-space-between minw-200 ai-center'
                                    noWrap>
                                    <span className='pr-10'>{option.full_name}</span>
                                    <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                </Typography>}

                            />
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }

            }

            if (item.key == 'checkbox') {
                return {
                    Header: item.label,
                    id: item.key,
                    width: 50,
                    accessor: item.key,
                    Cell: (props) => {
                        if (props.original.action_owner_to != null) {
                            if (props.original.action_owner_to.length > 0) {
                                return (
                                    <div className="flex ai-center jc-center">
                                        <FormControlLabel
                                            control={<Checkbox onChange={() => handleCheckboxChange(props)} />}
                                            checked={props.original.selected}
                                            label=""
                                            className="mr-0 ml-0"
                                        />
                                    </div>
                                )
                            }
                        }

                        return (<div className="flex ai-center jc-center"></div>)
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : 'pointer',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }

            }
            
            if (item.key == "collection_forecast") {
                return {
                    Header: item.label,
                    id: item.key,
                    width: handleDynamicWidth(item),
                    headerStyle: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '11px', fontWeight: 'bold', },
                    style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '11px', textAlign: 'center', cursor: 'pointer'},
                    accessor: item.key,
                    // Cell: (props) => (
                      
                    //     <div className='mt-10'>
                    //         <Tooltip title='Add/Edit Collections Forecast'>
                    //             <span
                    //                 onClick={() => {
                    //                     //   this.setState({
                    //                     //     showForecastModal: true,
                    //                     //     commentRow: props.original,
                    //                     //   });
                    //                     showForecastModal(props.original)
                    //                     getPipID();
                    //                 }}
                    //             >                  
                    //               {convertAmountData(props.original.collection_forecast) != -1 ? convertAmountData(props.original.collection_forecast): 'Not Available'}
                    //             </span>
                    //         </Tooltip>
                    //     </div>
                    // ),
                    Cell: (props) => {
                        const UserName = buUser.filter(x => x.login_id == props.original.collection_forecast_confirmed_by)
                        return (
                        <React.Fragment>
                          <Tooltip title='Add/Edit Collections Forecast'>
                            <div className='mt-10'
                             onClick={() => {
                                showForecastModal(props.original)
                                getPipID(props.original);
                              }}>
                              <div>{formatDate(props.original.collection_forecast_date, "dd/MMM/yyyy")}</div>
                              <div>{UserName && UserName.length ? UserName[0].full_name : ''}</div>
                              <div>{convertAmountDataWithoutRound(props.original.collection_forecast) != -1 ?
                                convertAmountDataWithoutRound(props.original.collection_forecast) :
                                'Not Available'
                              }</div>
                            </div>
                          </Tooltip>
                        </React.Fragment>
                      )},
                };
            } else if (item.key == "improvement_plan") {
                return {
                    Header: item.label,
                    id: item.key,
                    width: handleDynamicWidth(item),
                    headerStyle: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '13px', fontWeight: 'bold', },
                    style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '12px', textAlign: 'center' },
                    accessor: item.key,
                    Cell: (props) => (
                        <div className='mt-10 flex ai-center jc-space-evenly'>
                            <Tooltip title='View Improvement Plan'>
                                <Badge anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }} badgeContent={props.original.pip_count} color="primary">
                                    <Icon
                                        className="fs-20 text-primary"
                                        color={
                                            props.original["pip_count"] > 0 ? "inherit" :
                                                "disabled"}
                                                onClick={() => {handleImprovementPlanClick('view')}}

                                        // onClick={() => {
                                        //     if (props.original["pip_count"] > 0) {
                                        //         showModalListPip(props.original)
                                        //     }
                                        // }}
                                    >remove_red_eye
                 </Icon>
                                </Badge>
                            </Tooltip>
                            {
                                getAccessByMenuKey(menus, "100k-120_days_reports", "add") &&
                                <Tooltip title={
                                        'Add Improvement Plan'
                                }>
                                    <Icon className="fs-20 text-primary" color={
                                         "inherit" }
                                        disabled
                                        onClick={() => {handleImprovementPlanClick('add')}}
                                        // onClick={() => {
                                        //         showAddImporvementPlanModal(props.original)
                                        //         getPipID(props.original);
                                        // }}
                                    >add_circle_outline</Icon>
                                </Tooltip>
                            }
                        </div>
                    ),
                };
            } else if (item.key == "action_owner") {
                return {
                    Header: item.label,
                    id: item.key,
                    width: handleDynamicWidth(item),
                    headerStyle: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '11px', fontWeight: 'bold', },
                    style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "visible", fontSize: '11px', textAlign: 'center' },
                    accessor: item.key,
                    Cell: (props) => {
                        let label = buUser.find(x => { return x.login_id == props.original.action_owner })
                        label = label == undefined || label == null ? '' : label.full_name 

                        return (
                            <div className='dayReportPage'>
                        <Autocomplete
                            // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                            className='kactionOwnerTabelSelect'
                            options={buUser}
                            PopperComponent={ActionOwnerPopper}
                            getOptionLabel={(option) => option.full_name}
                            value={buUser.find(x=>{ return x.login_id == props.original.action_owner })}
                            onChange={(event, data) => {handleActionOwnerChangeFn(data, props.original)}}
                            renderInput={(params) => (
                                <Tooltip title={label}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                />
                                </Tooltip>
                            )}
                            renderOption={(option) => <Typography
                                style={{ fontSize: '11px', width: '250px' }}
                                className='flex jc-space-between minw-200 ai-center'
                                noWrap>
                                <span className='pr-10'>{option.full_name}</span>
                                <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                            </Typography>}
                        />
                        </div>)
                            },
                };
            }else if (item.key == "customer") {
                return {
                    Header: handleSummaryColumn(item, summaryState.buRowData),
                    id: item.key,
                    width: handleDynamicWidth(item),
                    fixed: handleFixedColumn(item),
                    headerStyle: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '11px',
                        fontWeight: 'bold',
                        backgroundColor: headerColorObj[item.key] ? headerColorObj[item.key] : 'white'
                    },
                    style: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '11px',
                        textAlign: item.key != 'customer_name' ? 'center' : '',
                        cursor: agingCategoryArr.indexOf(item.key) > -1 ? 'pointer' : ''
                    },
                    accessor: item.key,
                    Cell: (props) => (
                        <>
                            <a className='primary-dark pointer' onClick={() => {handleCustomerIdClick(props.original)}}><u>{props.original.customer}</u></a>
                        </>
                    ),
                };

            }else if (item.key == "comment") {
               
                return {
                    Header: item.label,
                    id: item.key,
                    width: handleDynamicWidth(item),
                    fixed: handleFixedColumn(item),
                    headerStyle: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '11px',
                        fontWeight: 'bold',
                        backgroundColor: headerColorObj[item.key] ? headerColorObj[item.key] : 'white'
                    },
                    style: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '11px',
                        textAlign: 'left',
                        cursor:  ''
                    },
                    accessor: item.key,
                    Cell: (props) => {
                        return (
                            <>
                                   <p>{props.original.comment_originator}, {formatDate(props.original.comment_date)} <br></br>
                  {props.original.comment}</p>
                            </>
                       )

                       
                    },
                };

            }

            return {
                Header: item.label,
                id: item.key,
                width: 110,
                accessor: item.key,
                Cell: (props) => {
                    return (
                        <div className='text-center'>
                            {

                                <span>{handleTableTransform(props.original, item)}</span>
                            }
                        </div>
                    )
                },
                sortMethod: (a, b) => {
                    if(typeof a != "string"){
                        // console.log(a, "ini A")
                        // console.log(b, "ini B")
                    return Number(a)-Number(b)
                    }else{
                        if(item.key == "collection_actual"){
                            let val = a.split(",").join("")
                            let valb = b.split(",").join("")
                            let valInt = parseInt(val)
                            let valIntB = parseInt(valb)
                            valInt = isNaN(valInt) ? 0 : valInt
                            valIntB = isNaN(valIntB) ? 0 : valIntB

                            return valInt - valIntB
                        }else{
                            if (a > b) {
                                return -1;
                              } else if (a < b) {
                                return 1;
                              }
                              return 0;
                        }
                        
                   }
                },
                style: {
                    fontSize: '12px',
                    cursor: item.key == 'grand_total' ? '' : 'pointer'
                },
                headerStyle: {
                    whiteSpace: 'break-spaces',
                    overflow: 'visible',
                    fontSize: '13px',
                    fontWeight: 'bold',
                }
            }

            
        });
    }
    return [];
};