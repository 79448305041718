import React from 'react';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import BUPLTableComponent from './TableComnponent'
import BUPLTableComponent2 from './TableComnponent/index2'
import '../IncomeStatement.scss'
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import CompareModal from './Compare'
import {
  downloadExcel, pdfFileName, mapDataPDF, mapPDFDataComparative, mapPDFDataComparativePlant, mapPDFHeaderComparative,
  mapExcelData, mapExcelHeader, mapExcelHeaderComparative, mapExcelDataComparative,mapExcelDataComparativePlant, mapExcelDataVariance
} from '../../../utils/exportExcel'
import { parseJSON } from 'date-fns';
import { format } from 'date-fns/esm';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import { JsonToTable } from "react-json-to-table";
import 'jspdf-autotable'
import { Autocomplete, TextField } from '@mui/material';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        { children }
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export class BUPLComponent extends React.Component {

  constructor(props) {
    super(props);

    this.buMap = {
      0: '',
      1: 'BU 1',
      2: 'BU 2',
      3: 'BU 3',
      4: 'BU 4',
      5: 'OH'
    }

    this.buMapDesc = {
      0: '',
      1: 'BU 1',
      2: 'BU 2',
      3: 'BU 3',
      4: 'BU 4',
      5: 'OH'
    }

    this.dateMap = {
      0: 'country',
      1: 'plant',
      2: 'month',
      3: 'quarter',
      4: 'comparative',
      5: '',
      6: 'year',
    }

    this.dateMapDescSummary = {
      0: "Summary",
      1: "By Plant",
      2: "Monthly Rolling View",
      3: "Quarterly Rolling View",
      4: "Comparative Analysis",
      6: "Yearly Rolling View"
    }

    this.dateMapDescNonSummary = {
      0: "By Country",
      1: "By Plant",
      2: "Monthly Rolling View",
      3: "Quarterly Rolling View",
      4: "Comparative Analysis",
      6: "Yearly Rolling View"
    }

    this.excelData = {};
    this.excelDataComparison = {};

    this.comparativeMap = {
      1: 'month',
      2: 'quarter',
      3: 'monthcytd',
      4: 'monthcfy'
    }

    this.financialDuration = "";

    this.state = {
      loading: true,
      hfmRadioValue: "billing",
      monthSelectorValue: '',
      showCompareModal: false,
      qtrSelectorValue: '',
      yearSelectorValue: this.props.incomeStatementState.defaultYear &&
      this.props.incomeStatementState.yearFilterArr.length ? this.props.incomeStatementState.defaultYear : '',
      radioSelectorValue: '0',
      lastIngestion: format(new Date(), "MM-dd-yy HH:mm:ss"),
      openAlert: false,
      tabSelectorValue: 0,
      comparativeSelectorValue: '1',
      plantType : '1',
      isShowVarianceChecked: false,
      checked: 'month',
      isDetailedView: false,
      legalEntitySelectorValue: '',
      plantSelectorValue: '',
      detailPlantId: '',
      plantMetaData: {},
      countryMetaName: '',
      plantDetailState: {},
      refMenuAnchorEl: null,
      excludeDataFilter: []
    }
  }

  componentDidMount() {
    this.props.getBUPLDefaultMonthData()

   
  }

  handleMonthChange = (event, value) => {
    if (value) {
      this.setState({
        monthSelectorValue: value,
        qtrSelectorValue: '',
        yearSelectorValue: ''
      }, () => {
        const { tabSelectorValue, radioSelectorValue } = this.state;
        if (tabSelectorValue == 0 && radioSelectorValue == 0) {
          this.props.getBUPLIncomePivotData({ yearmonth: value })
        } else {
          this.handleFetchBUFn()
        }
      });
      this.financialDuration = value;
    }
  };

  handleQtrChange = (event, value) => {
    if (value) {
      this.setState({
        qtrSelectorValue: value,
        monthSelectorValue: '',
        yearSelectorValue: ''

      }, () => {
        const { tabSelectorValue, radioSelectorValue } = this.state;
        if (tabSelectorValue == 0 && radioSelectorValue == 0) {
          this.props.getBUPLIncomePivotData({ quarter: value })
        } else {
          this.handleFetchBUFn()
        }
      })
      this.financialDuration = value;
    }
  };

  handleYearChange = (event, value) => {
    if (value) {
      this.setState({
        yearSelectorValue: value,
        qtrSelectorValue: '',
        monthSelectorValue: ''

      }, () => {
        const { tabSelectorValue, radioSelectorValue } = this.state;
        if (tabSelectorValue == 0 && radioSelectorValue == 0) {
          this.props.getBUPLIncomePivotData({ year: value })
        } else {
          this.handleFetchBUFn()
        }
      })
      this.financialDuration = value;
    }
  };

  handleRadioChange = (event) => {
    const {  defaultYear, defaultMonth, defaultQtr } = this.props.incomeStatementState;

    if (event.target.value == '4' || event.target.value == '5'){
      this.setState({
        radioSelectorValue: event.target.value,
        qtrSelectorValue: '',
        monthSelectorValue: '',
        plantType : '1',
        yearSelectorValue: defaultYear,
        excludeDataFilter: []
      }, () => {
        this.handleFetchBUFn()
  
      })

    } else if (event.target.value == '2') {
      this.setState({
        radioSelectorValue: event.target.value,
        qtrSelectorValue: '',
        monthSelectorValue: defaultMonth,
        yearSelectorValue: '',
        excludeDataFilter: []
      }, () => {
        this.handleFetchBUFn()
      })
    }
    else if (event.target.value == '3') {
      this.setState({
        radioSelectorValue: event.target.value,
        qtrSelectorValue: defaultQtr,
        monthSelectorValue: '',
        yearSelectorValue: '',
        excludeDataFilter: []
      }, () => {
        this.handleFetchBUFn()
      })
    }
    else if (event.target.value == '6') {
      this.setState({
        radioSelectorValue: event.target.value,
        qtrSelectorValue: '',
        monthSelectorValue: '',
        yearSelectorValue: defaultYear,
        excludeDataFilter: []
      }, () => {
        this.handleFetchBUFn()
      })
    }
    else {
      this.setState({
        radioSelectorValue: event.target.value,
        // qtrSelectorValue: '',
        // monthSelectorValue: '',
        excludeDataFilter: []
      }, () => {
        this.handleFetchBUFn()
  
      })
    }
    
  };
  

  handlePlantTypeRadioChange = (event) => {
    const { defaultYear } = this.props.incomeStatementState

    this.setState({
      plantType: event.target.value,

    }, () => {
      this.handleFetchBUFn()
    })

  }
  handleComparativeRadioChange = (event) => {
    const { defaultYear } = this.props.incomeStatementState

    this.setState({
      comparativeSelectorValue: event.target.value,
      qtrSelectorValue: '',
      monthSelectorValue: '',
      yearSelectorValue: defaultYear,

    }, () => {
      this.handleFetchBUFn()
    })

  }


  // for SG-286 commented out 231,232 and 235 line
  handleTabChange = (event, newValue) => {
    const { defaultYear, lastIngestion } = this.props.incomeStatementState
    this.setState({
      tabSelectorValue: newValue,
      radioSelectorValue: '0',
      lastIngestion: lastIngestion ? format(parseJSON(lastIngestion), "MM-dd-yy HH:mm:ss") : '',
      // qtrSelectorValue: '',
      // monthSelectorValue: '',
      plantType : '1',
      isDetailedView: false,
      countryMetaName: '',
      // yearSelectorValue: defaultYear,
      excludeDataFilter: []
    }, () => {
      const { tabSelectorValue, radioSelectorValue } = this.state;
      if (tabSelectorValue == 0 && radioSelectorValue == 0) {
        this.props.getBUPLIncomePivotData({ year: this.state.yearSelectorValue, quarter:  this.state.qtrSelectorValue, yearmonth:  this.state.monthSelectorValue,})
      } else {
        this.handleFetchBUFn()
      }
    })
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleBackClick = () => {
    this.setState({ isDetailedView: false, countryMetaName: '' }, () => {
      this.handleFetchBUFn()
    })
  }

  closeAlertBox = () => {
    this.setState({
      openAlert: false
    })
  }

  handleFetchBUFn = () => {
    const { tabSelectorValue, monthSelectorValue, yearSelectorValue, qtrSelectorValue, isDetailedView,
      radioSelectorValue, legalEntitySelectorValue, plantSelectorValue, countryMetaName, excludeDataFilter, plantType } = this.state
    let payload = {
      type: this.dateMap[radioSelectorValue],
      typeSelector: this.dateMapDescSummary[radioSelectorValue],
      bu: this.buMap[tabSelectorValue],
      yearmonth: monthSelectorValue,
      year: yearSelectorValue,
      quarter: qtrSelectorValue,
      legalEntity: legalEntitySelectorValue,
      plant: plantSelectorValue,
      country: countryMetaName,
      plantType : plantType,
      excludeData: excludeDataFilter.join(',')
    }

    if (radioSelectorValue == 2 || radioSelectorValue == 3 || radioSelectorValue == 6) {
      this.props.getBUPLMonthlyQuarterData(payload)
    } else if (isDetailedView && (radioSelectorValue == 0 || radioSelectorValue == 1)) {
      this.handleRowDataFetch()
    } else if ((tabSelectorValue != 0) && (radioSelectorValue == 0 || radioSelectorValue == 1)) {
      this.props.getBUPLCountryData(payload)
    } else if (tabSelectorValue == 0 && radioSelectorValue == 0) {
      this.props.getBUPLIncomePivotData(payload)
    } else if (radioSelectorValue == 4 || radioSelectorValue == 5) {
      this.handleComparativeFetchData()
    }
  }

  handleComparativeFetchData = () => {

    const { tabSelectorValue, monthSelectorValue, yearSelectorValue, qtrSelectorValue,
      radioSelectorValue, legalEntitySelectorValue, plantSelectorValue, comparativeSelectorValue, excludeDataFilter } = this.state
    let payload = {
      type: this.dateMap[radioSelectorValue],
      bu: this.buMap[tabSelectorValue],
      yearmonth: monthSelectorValue,
      year: yearSelectorValue,
      quarter: qtrSelectorValue,
      legalEntity: legalEntitySelectorValue,
      view: this.comparativeMap[comparativeSelectorValue],
      excludeData: excludeDataFilter.join(',')
    }

    if (radioSelectorValue == 4) {
      this.props.getBUPLComparativeDetail(payload)

    } else if (radioSelectorValue == 5) {
      this.props.getBUPLComparativePlantDetail(payload)
    }

  }

  componentDidUpdate(prevProps) {
    const { yearFilterArr, defaultYear, lastIngestion,defaultMonth } = this.props.incomeStatementState;
    if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
      this.setState({
        yearSelectorValue: '',
        monthSelectorValue: defaultMonth,
        lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
      }, () =>{


        let linkDrilldown = localStorage.getItem('linkDrilldown')
        if (linkDrilldown) {
          linkDrilldown = JSON.parse(linkDrilldown)
            this.setState({
                yearSelectorValue : linkDrilldown.year,
                monthSelectorValue : linkDrilldown.month,
                qtrSelectorValue : linkDrilldown.quarter,
                tabSelectorValue: parseInt(linkDrilldown.tab),
                radioSelectorValue :  linkDrilldown.radio,
            }, ()=>{

              this.handleFetchBUFn()
              localStorage.removeItem('linkDrilldown')

            }  )
        }

      })
    }
  }

  componentWillUnmount() {
    this.props.unmountActionCalled()
  }

  handleToggleChange = (event) => {
    this.setState(
      { checked: event.target.value, excludeDataFilter: [] }, () => {
        this.handleRowDataFetch()
      })
  }

  handleRowHeaderClick = (data) => {
    const { tabSelectorValue, radioSelectorValue, isDetailedView } = this.state
    if (tabSelectorValue == 1 && radioSelectorValue == 1 && !isDetailedView) {
     
      this.setState({
        isDetailedView: true,
        detailPlantId: data.id,
        plantMetaData: data.metaData,
        countryMetaName: '',
        checked: 'month'
      }, () => {
        this.handleRowDataFetch()
      })
    } else if (tabSelectorValue != 0 && radioSelectorValue == 0 && !isDetailedView) {
      this.setState({
        isDetailedView: true,
        countryMetaName: data.id,
        detailPlantId: '',
        plantMetaData: {},
      }, () => {
        this.handleRowDataFetch()
      })
    }
  }

  handleRowDataFetch = () => {
    const { tabSelectorValue,
      monthSelectorValue,
      yearSelectorValue,
      qtrSelectorValue,
      legalEntitySelectorValue,
      detailPlantId,
      countryMetaName,
      checked,
      radioSelectorValue,
      excludeDataFilter
    } = this.state
    let payload = {
      type: checked,
      bu: this.buMap[tabSelectorValue],
      typeSelector: this.dateMapDescSummary[radioSelectorValue],
      yearmonth: monthSelectorValue,
      year: yearSelectorValue,
      quarter: qtrSelectorValue,
      legalEntity: legalEntitySelectorValue,
      plant: detailPlantId,
      country: countryMetaName,
      excludeData: excludeDataFilter.join(',')
    }
    this.props.getBUPLMonthlyQuarterData(payload)
  }

  handleLegalEntityChange = (event, value) => {
    if (value) {
      this.setState({ legalEntitySelectorValue: value }, () => {
        this.handleFetchBUFn()
      })
    }
  }

  handlePlantChange = (event, value) => {
    if (value) {
      this.setState({ plantSelectorValue: value }, () => {
        this.handleFetchBUFn()
      })
    }
  }

  unwindHeaderKeyVal = (data) => {
    data.forEach(x => {
      var obj = x.header_key_value
      if (obj != undefined) {
        for (var prop in obj) {
          x[prop] = obj[prop]
        }
      }
    })
    return data
  }

  unwindHeaderKeyValVariance = (data) => {
    data.forEach(x => {
      var obj = x.sub_column.variance.header_key_value
      var obj2 = x.sub_column.variance
     
      if (obj != undefined) {
        for (var prop in obj) {
          
          x[prop] = obj[prop]
          
        }
        x["data_field"] = obj2["data_field"]
      }
    })
    return data
  }

  getFileName = () => {
    let bu = this.buMapDesc[this.state.tabSelectorValue]
    bu = bu == "" ? "" : bu + " - "
    let date = this.dateMapDescSummary[this.state.radioSelectorValue]
    date = bu != "" ? this.dateMapDescNonSummary[this.state.radioSelectorValue] : date
    return bu + date
  }

  handleFileName = () => {
    const name = "BU P&L - " + this.getFileName();
    return pdfFileName(name)
  }

  handleExcelDownload = () => {
    let data = []
    let header = {};
    let merge = [];
    // isShowVarianceChecked: false,
    if (this.state.radioSelectorValue < 4 && this.state.plantType != 3) {
      let datas = this.excelData
      header = datas.header;
      datas.data = this.unwindHeaderKeyVal(datas.data);
      data = mapExcelData(datas.data, header, "id")
      header = mapExcelHeader(header, "headerTitle")
    } else {
      let datas =  JSON.parse(JSON.stringify(this.excelDataComparison))
      header = datas.header;
      let newHeader
      if (this.state.isShowVarianceChecked == true) {
        datas.data = this.unwindHeaderKeyValVariance(datas.data);
        data = mapExcelDataVariance(datas.data, header)
        header = mapExcelHeader(header, "label")
       
      }else{
        if (this.state.plantType != 3){

          data = mapExcelDataComparative(datas.data, header, "id")
        }else{
          data = mapExcelDataComparativePlant(datas.data, header, "id")
        }
         newHeader = mapExcelHeaderComparative(header, "label");
         header = newHeader.data;
        merge = newHeader.merge;
      }
     
      
    }
    let fileName = " Income Statement - BU P&L - " + this.getFileName()
    if (this.financialDuration) {
      fileName = fileName + " - " + this.financialDuration
    }
    const { currentValue, previousValue } = this.props.incomeStatementState;
    let sheetName = 'Data';

    if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
      sheetName = `${currentValue} - ${previousValue}`
    }
    downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
  }

  handleExcelDataChange = (data, header) => {
    this.excelData.data = data;
    this.excelData.header = header;
    this.excelData.pivotHeaderData = this.props.incomeStatementState.pivotHeaderData;

  }

  handleExcelDataChangeComparison = (data, header) => {
    
      this.excelDataComparison.data = data;
      this.excelDataComparison.header = header;
      this.excelDataComparison.pivotHeaderData = this.props.incomeStatementState.pivotHeaderDataComparative;
    
   
  }



  getInvoiceDetail = (data) => {

    const {
      monthSelectorValue,
      yearSelectorValue,
      qtrSelectorValue,
      legalEntitySelectorValue,
      detailPlantId,
      countryMetaName,
      checked,
      isDetailedView,
      tabSelectorValue,
      radioSelectorValue,
      comparativeSelectorValue,
      plantType
    } = this.state;

    let payload = {
      ...data,
      yearmonth: monthSelectorValue,
      year: yearSelectorValue,
      quarter: qtrSelectorValue,
      typeSelector: this.dateMapDescSummary[radioSelectorValue],
      bu: this.buMap[tabSelectorValue],
      tab_value: this.buMap[tabSelectorValue] ? this.buMap[tabSelectorValue] : 'summary',
      sub_tab_value: isDetailedView ? checked : this.dateMap[radioSelectorValue],
      plant: detailPlantId,
      country: countryMetaName,
      view: this.comparativeMap[comparativeSelectorValue]

    }

    if (tabSelectorValue == 0 && radioSelectorValue == 0) {
      payload['sub_tab_value'] = 'summary'
    }

    if (radioSelectorValue == 5) {
      payload['sub_tab_value'] = 'comparativeByPlant'

    }

    if (tabSelectorValue == 1 && radioSelectorValue == 1){ //if MSMT and By Plant
      if(plantType == 2){ //if by origination
        payload['plant_field'] = "origination"
      }else if(plantType == 3){
        payload['plant_field'] = "all"
      }
    }

    this.props.getBUPLInvoiceDetail(payload);
  }


  getInvoiceDetailGL = (data) => {
    this.props.getBUPLInvoiceDetailGL(data);
  }

  getVariance = (data) => {
    
    this.state.isShowVarianceChecked = data
   
    // if(this.state.isShowVarianceChecked == true){
      
    //   this.excelData.pivotHeaderData = this.props.incomeStatementState.pivotHeaderDataComparative;
    // }
    // this.props.getBUPLInvoiceDetailGL(data);
  }
  getApp = () => {
    let data = []
    let header = {};
    let merge = [];

    if (this.state.radioSelectorValue < 4 &&  this.state.isShowVarianceChecked == true) {
      let datas = this.excelData
      header = datas.header;
      datas.data = this.unwindHeaderKeyVal(datas.data);

      data = mapDataPDF(datas.data, header, "id")
      header = mapExcelHeader(header, "headerTitle")
    } else {
      let datas = this.excelDataComparison
      header = datas.header;
      data = mapExcelDataComparative(datas.data, header, "id")
      let newHeader = mapExcelHeaderComparative(header, "label");
      header = newHeader.data;
      merge = newHeader.merge;
    }
    return (
      <div className="App">
        <h1>Hello CodeSandbox</h1>
        <h2>Start editing to see some magic happen!</h2>
        {/* ===================== */}
        {/* HOW TO USE IT         */}
        {/* ===================== */}
        <JsonToTable json={data} />
        {/* ===================== */}
      </div>
    );
  }

  printDocument = () => {
    //  ExportPdf(this.excelData, this.excelDataComparison, this.state.radioSelectorValue)
    let data = []
    let header = {};
    let pivotHeaderData = {};
    let merge = [];
    let page;
    let tabSelectorValue = this.state.tabSelectorValue
    const pdf = new jsPDF('l', 'pt', 'letter');
    pdf.autoTable({ html: '#my-table' })
    if (tabSelectorValue == 0) {
      page = "xP&A Demo-Income Statement-BU P&L-Summary"

    } else if (tabSelectorValue == 1) {
      page = "xP&A Demo-Income Statement-BU P&L-BU-1 - By Country"
    } else if (tabSelectorValue == 2) {
      page = "xP&A Demo-Income Statement-BU P&L-BU-2 - By Country"
    } else if (tabSelectorValue == 3) {
      page = "xP&A Demo-Income Statement-BU P&L-BU-3 - By Country"
    } else if (tabSelectorValue == 4) {
      page = "xP&A Demo-Income Statement-BU P&L-BU-4 - By Country"
    }
    let FileName = page + '_' + format(new Date, " MM-dd-yyyy HH-mm-ss")

    if (this.state.radioSelectorValue < 4 && this.state.plantType != 3) {
      let datas = this.excelData
      header = datas.header;
      pivotHeaderData = datas.pivotHeaderData;
      datas.data = this.unwindHeaderKeyVal(datas.data);
      data = mapDataPDF(datas.data, "id", pivotHeaderData)
      header = mapExcelHeader(header, "headerTitle")
      let rows = []
      let i = 0
      header.forEach(x => {
        if (i == 0) {
          rows.push({ content: "", styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
        } else {
          rows.push({ content: x, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } })
        }
        i++;

      })
     
      let rowsDataFix = []
      let dataCount = 0
      data.forEach(x => {
        let i = 0
        let rowsData = []
        x.forEach(y => {
           if (i == 0) {
            
            rowsData.push({ content: y})
            } else {
              if(y.includes("%")){
                if(y.includes("-")){
                  y = y.replace("%", "");
                }
                rowsData.push({ content: y, styles: { halign: 'center', fillColor: [223, 217, 251]} })
              }else{
                rowsData.push({ content: y, styles: { halign: 'center'}})
              }
             
            }
          i++;
         
        })
        dataCount += 1
        rowsDataFix.push(rowsData)
      })
      let heads = [
        rows
      ];
      let datasGrid = [
        rowsDataFix
      ];
      let totalPages = 1

      if(dataCount > 22){
        totalPages = Math.ceil(dataCount/22)
      }
      pdf.autoTable({
        head: heads,
        body: rowsDataFix,
        theme: 'grid',
        didDrawPage: function (data) {
          // Header
          pdf.setFontSize(20);
          pdf.setTextColor(40);
          pdf.setFontStyle('heltevica');
          pdf.text( page, data.settings.margin.left + 15, 30);
         
        
        //  let arrayData =  data.table
     
          if (totalPages > 1 ) {
              var str = "Page " + pdf.internal.getNumberOfPages()
              // Total page number plugin only available in jspdf v1.0+
              if (typeof pdf.putTotalPages === 'function') {
                str = str
              }
              pdf.setFontSize(10);

              // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              var pageSize = pdf.internal.pageSize;
              var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              pdf.text(str, data.settings.margin.left, pageHeight - 10);
          }

         
        },
        styles: {
          lineWidth: 1,
            lineColor: [38, 38, 38],
        },
      //   headerStyles: {
      //    lineWidth: 1,
      //       lineColor: [38, 38, 38],
      // },
        // columnStyles: {
        //   0: {lineWidth: 1,
        //     lineColor: [38, 38, 38]},
        // },
        margin: { top: 50 }
      })
      
      pdf.save(FileName + '.pdf')

    } else {

      let datas = this.excelDataComparison
      header = datas.header;
      pivotHeaderData = datas.pivotHeaderData;
      if ( this.state.plantType != 3) {
      data = mapPDFDataComparative(datas.data, header, "id", pivotHeaderData)
      }else{

        data = mapPDFDataComparativePlant(datas.data, header, "id", pivotHeaderData)
      }
      let newHeader = mapPDFHeaderComparative(header, "label");
      header = newHeader.data;
      merge = newHeader.merge;
      let header2 = data.header;


      let rows = []
      let i = 0
      header.forEach(x => {
        if (i == 0) {
          rows.push({ content: x, styles: { halign: 'center', fillColor: [131, 6, 206] } })
        } else {
          rows.push({ content: x, colSpan: 3, styles: { halign: 'center', fillColor: [131, 6, 206] } })
        }
        i++;

      })

      let rows2 = []
      header2.forEach(x => {
        rows2.push({ content: x, styles: { halign: 'center', fillColor: [127, 104, 239] } })
      })
      let heads = [
        rows,
        rows2,
      ];
      pdf.autoTable({
        head: heads,
        body: data.data,
        theme: 'grid',
        didDrawPage: function (data) {
          // Header
          pdf.setFontSize(20);
          pdf.setTextColor(40);
          pdf.setFontStyle('heltevica');
          pdf.text( page, data.settings.margin.left + 15, 30);

          // Footer
      
          var str = "Page " + pdf.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof pdf.putTotalPages === 'function') {
            str = str;
          }
          pdf.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = pdf.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          pdf.text(str, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 50 }
      })
      // pdf.save(page + '.pdf')
    }



  }

  // handleExcludeFilterChange = (event) => {
  //   if (event.target.value) {
  //     this.setState(
  //       { excludeDataFilter: event.target.value }, () => {
  //         this.handleFetchBUFn()
  //       })
  //   }
  // };

  handleExcludeFilterChangeNew = (event, newvalueArr) => {
    if (newvalueArr) {
      const valArr = newvalueArr.map((newvalue)=>(newvalue.value))
      this.setState(
        { excludeDataFilter: valArr  }, () => {
          this.handleFetchBUFn()
        })
    }
  };


  handleResetExcludeFilter = () => {
    this.setState(
      { excludeDataFilter: [] }, () => {
        this.handleFetchBUFn()
      })
  }

  handleCompareClose = () => {
    this.setState({ showCompareModal: false })
  }

  handleShowCompare = () => {
    this.setState({ showCompareModal: true })
  }

  render() {
    const { invoiceDetailStateGL, quarterfilterArr, monthFilterArr, yearFilterArr, legalEntityArr, plantArr } = this.props.incomeStatementState
    const { excludeDataFilter, tabSelectorValue, yearSelectorValue, radioSelectorValue, legalEntitySelectorValue, plantSelectorValue,
      plantMetaData, detailPlantId, countryMetaName, plantType, plantDetailState, comparativeSelectorValue, isShowVarianceChecked, isDetailedView, showCompareModal } = this.state;

    return (
      <div className='buplComponent'>
        {/* <AlertComponent setOpen={this.closeAlertBox} open={openAlert} /> */}
        <div className='flex jc-space-between'>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3} className='maxw-200'>
              {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Month</InputLabel>
                <Select
                  value={this.state.monthSelectorValue}
                  label="Month"
                  disabled={(radioSelectorValue == 4 || radioSelectorValue == 3 || radioSelectorValue == 6)  && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4 || radioSelectorValue == 3 || radioSelectorValue == 6)}
                  onChange={this.handleMonthChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                    return <MenuItem value={item} key={item}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl> */}
              <Autocomplete
                disablePortal
                disableClearable
                fullWidth
                size='small'
                className='xpna-dropdown single'
                options={monthFilterArr}
                value={this.state.monthSelectorValue}
                onChange={this.handleMonthChange}
                renderInput={(params)=>(
                    <TextField {...params} variant='standard' label='Month' placeholder='Select'  />
                )}
              />  
            </Grid>
            <Grid item xs={12} sm={3} className='maxw-200'>
              {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Quarter</InputLabel>
                <Select
                  value={this.state.qtrSelectorValue}
                  label="Quarter"
                  disabled={(radioSelectorValue == 4 || radioSelectorValue == 2 || radioSelectorValue == 6) && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                  onChange={this.handleQtrChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl> */}
              <Autocomplete
                disablePortal
                disableClearable
                fullWidth
                size='small'
                className='xpna-dropdown single'
                options={quarterfilterArr}
                value={this.state.qtrSelectorValue}
                onChange={this.handleQtrChange}
                renderInput={(params)=>(
                    <TextField {...params} variant='standard' label='Quarter' placeholder='Select'  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} className='maxw-200'>
              {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Annual</InputLabel>
                <Select
                  value={yearSelectorValue}
                  label="Annual"
                  disabled={radioSelectorValue == 2 || radioSelectorValue == 3}
                  onChange={this.handleYearChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                    return <MenuItem value={item} key={item}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl> */}
              <Autocomplete
                disablePortal
                disableClearable
                fullWidth
                size='small'
                className='xpna-dropdown single'
                options={yearFilterArr}
                value={yearSelectorValue}
                onChange={this.handleYearChange}
                renderInput={(params)=>(
                    <TextField {...params} variant='standard' label='Year' placeholder='Select'  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} className='maxw-200'>
              <Button variant='contained' className='button newButton btnCompare btnBlue uppercase' onClick={this.handleShowCompare}>Compare</Button>
            </Grid>
          </Grid>
          {/* <ReactToPrint
          trigger={()=>{
            return (
              <Tooltip title={'Download to PDF'} className='pointer'>
                <PictureAsPdfIcon fontSize="large"/>   
             </Tooltip> )
          }}w
          content={() => this.componentRef }
          documentTitle = {this.handleFileName()}
          /> */}
          <Grid container justifyContent="flex-end">
            {(tabSelectorValue == 0) &&
              <div>Latest Data Uploaded on<div><b>{this.state.lastIngestion}</b></div></div>
            }
            {(radioSelectorValue == 0) && <Tooltip title={'Download to PDF'} className='pointer'>
              <PictureAsPdfIcon fontSize="large" className='pointer ml-10 mr-10'
                height='30'
                onClick={(event) => {
                  event.preventDefault();
                  this.printDocument();
                }} />

            </Tooltip>}
            <Tooltip title={'Download to Excel'} className='pointer'>
              <img
                src={DownloadExcel}
                className='pointer'
                height='30'
                onClick={(event) => {
                  event.preventDefault();
                  this.handleExcelDownload();
                }} />
            </Tooltip>
          </Grid>
        </div>
        <Tabs
          value={tabSelectorValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className='mt-16 mb-16'
        >
          <Tab label="SUMMARY"  {...this.a11yProps(0)} />
          <Tab label="BU 1"  {...this.a11yProps(1)} />
          <Tab label="BU 2"  {...this.a11yProps(2)} />
          <Tab label="BU 3"  {...this.a11yProps(3)} />
          <Tab label="BU 4"  {...this.a11yProps(4)} />
          <Tab label="OH"  {...this.a11yProps(5)} />
        </Tabs>
        < div className="ex1" ref={el => (this.componentRef = el)} >
          <TabPanel value={'a'} index={0}>
            Item One
        </TabPanel>
          <TabPanel value={'a'} index={1}>
            Item Two
        </TabPanel>
          <TabPanel value={'a'} index={2}>
            Item Three
        </TabPanel>
          {this.state.isDetailedView ?
            <div className='rowDetails flex ai-center mb-10'>
              <Button onClick={this.handleBackClick} color="primary" startIcon={<ChevronLeft />}>back</Button>
              {radioSelectorValue == 1 ? <>
                <p className='mt-0 mb-0 ml-20 mr-20'>Plant ID: <b>{detailPlantId}</b></p>
                <p className='mt-0 mb-0 ml-20 mr-20'>Plant Name: <b>{plantMetaData.plant_name}</b></p>
                <p className='mt-0 mb-0 ml-20 mr-20'>Legal Entity Name: <b>{plantMetaData.legal_entity_name}</b></p>
              </> : <p className='mt-0 mb-0 ml-20 mr-20'>Country Name: <b>{countryMetaName}</b></p>}
              <FormControl component="fieldset">
                <RadioGroup name="radio" onChange={this.handleToggleChange} value={this.state.checked} className='radioGroupHorizontal'>
                  <FormControlLabel value="month" control={<Radio color="primary" />} label="Monthly" />
                  <FormControlLabel value="quarter" control={<Radio color="primary" />} label="Quarterly" />
                </RadioGroup>
              </FormControl>
            </div> :
            <React.Fragment>
              <FormControl component="fieldset">
                <RadioGroup name="radio" value={radioSelectorValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
                  {(tabSelectorValue != 0) && <FormControlLabel value="0" control={<Radio color="primary" />} label="By Country" />}
                  {(tabSelectorValue == 1) && <FormControlLabel value="1" control={<Radio color="primary" />} label="By Plant" />}
                  {( tabSelectorValue == 2) && <FormControlLabel value="1" control={<Radio color="primary" />} label="By Plant (Optimus Data)" />}
                  {(tabSelectorValue == 0) && <FormControlLabel value="0" control={<Radio color="primary" />} label="Summary" />}
                  <FormControlLabel value="2" control={<Radio color="primary" />} label="Monthly Rolling View" />
                  <FormControlLabel value="3" control={<Radio color="primary" />} label="Quarterly Rolling View" />
                  <FormControlLabel value="6" control={<Radio color="primary" />} label="Yearly Rolling View" />
                  <FormControlLabel value="4" control={<Radio color="primary" />} label={tabSelectorValue == 1 ? "Comparative Analysis by Country" : "Comparative Analysis"} />
                  {(tabSelectorValue == 1) && <FormControlLabel value="5" control={<Radio color="primary" />} label="Comparative Analysis by Plant" />}
                </RadioGroup>
              </FormControl>
              {radioSelectorValue == 4 &&
                <div>
                  <FormControl component="fieldset">
                    <RadioGroup name="radio" value={this.state.comparativeSelectorValue} onChange={this.handleComparativeRadioChange} className='radioGroupHorizontal'>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Current Month vs Last Month" />
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Current QTR vs Last QTR" />
                      {/* <FormControlLabel value="3" control={<Radio color="primary" />} label="CYTD vs LYTD" /> */}
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="CYTD vs LFY" />
                    </RadioGroup>
                  </FormControl>
                </div>
              }

              { radioSelectorValue == 1 &&
                <div>
                  <FormControl component="fieldset">
                    <RadioGroup name="radio" value={plantType} onChange={this.handlePlantTypeRadioChange} className='radioGroupHorizontal'>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Default View" />
                      {/* <FormControlLabel value="2" control={<Radio color="primary" />} label="By Origination" /> */}
                      {/* <FormControlLabel value="3" control={<Radio color="primary" />} label="Comparing Origination and Destination Views" /> */}
                    </RadioGroup>
                  </FormControl>
                </div>
              }
            </React.Fragment>
          }
          {(radioSelectorValue != 4 && radioSelectorValue != 5  && plantType != 3) &&
            <BUPLTableComponent
              showCursor={tabSelectorValue != 0 && (radioSelectorValue == 0 || radioSelectorValue == 1)}
              getInvoiceDetail={this.getInvoiceDetail} {...this.props} radioSelectorValue={radioSelectorValue} tabSelectorValue={tabSelectorValue}
              handleRowHeaderClick={this.handleRowHeaderClick} handleExcelDataChange={this.handleExcelDataChange}
              legalEntitySelectorValue={legalEntitySelectorValue}
              plantSelectorValue={plantSelectorValue}
              handlePlantChange={this.handlePlantChange}
              handleLegalEntityChange={this.handleLegalEntityChange}
              handleExcludeFilterChangeNew={this.handleExcludeFilterChangeNew}
              excludeDataFilter={excludeDataFilter}
              isDetailedView={isDetailedView}
              handleResetExcludeFilter={this.handleResetExcludeFilter}
              getInvoiceDetailGL={this.getInvoiceDetailGL}
              invoiceDetailStateGL={invoiceDetailStateGL}
              financialDuration={this.financialDuration}
              plantType = {plantType}
            />
          }
       {(radioSelectorValue == 1 && plantType == 3) &&
      
       <BUPLTableComponent2
       getInvoiceDetail={this.getInvoiceDetail} {...this.props} radioSelectorValue={radioSelectorValue} tabSelectorValue={tabSelectorValue}
       handleRowHeaderClick={this.handleRowHeaderClick} handleExcelDataChange={this.handleExcelDataChangeComparison}
       handleExcludeFilterChangeNew={this.handleExcludeFilterChangeNew}
       excludeDataFilter={excludeDataFilter}
       comparativeSelectorValue={comparativeSelectorValue}
       isShowVarianceChecked={this.getVariance}
       handleResetExcludeFilter={this.handleResetExcludeFilter}
       getInvoiceDetailGL={this.getInvoiceDetailGL}
       financialDuration={this.financialDuration}
       plantType = {plantType}
     />
  }
          {(radioSelectorValue == 4 || radioSelectorValue == 5) && (comparativeSelectorValue == 1 || comparativeSelectorValue == 2) &&
            <BUPLTableComponent2
              getInvoiceDetail={this.getInvoiceDetail} {...this.props} radioSelectorValue={radioSelectorValue} tabSelectorValue={tabSelectorValue}
              handleRowHeaderClick={this.handleRowHeaderClick} handleExcelDataChange={this.handleExcelDataChangeComparison}
              handleExcludeFilterChangeNew={this.handleExcludeFilterChangeNew}
              excludeDataFilter={excludeDataFilter}
              comparativeSelectorValue={comparativeSelectorValue}
              isShowVarianceChecked={this.getVariance}
              handleResetExcludeFilter={this.handleResetExcludeFilter}
              getInvoiceDetailGL={this.getInvoiceDetailGL}
              financialDuration={this.financialDuration}
              plantType = {plantType}
            />
          }
          {(radioSelectorValue == 4 && (comparativeSelectorValue == 3)) &&
            <BUPLTableComponent2
              getInvoiceDetail={this.getInvoiceDetail} {...this.props} radioSelectorValue={radioSelectorValue} tabSelectorValue={tabSelectorValue}
              handleRowHeaderClick={this.handleRowHeaderClick} handleExcelDataChange={this.handleExcelDataChangeComparison}
              handleExcludeFilterChangeNew={this.handleExcludeFilterChangeNew}
              excludeDataFilter={excludeDataFilter}
              comparativeSelectorValue={comparativeSelectorValue}
              isShowVarianceChecked={this.getVariance}
              handleResetExcludeFilter={this.handleResetExcludeFilter}
              getInvoiceDetailGL={this.getInvoiceDetailGL}
              financialDuration={this.financialDuration}
              plantType = {plantType}
            />
          }
          {(radioSelectorValue == 4 && (comparativeSelectorValue == 4)) &&
            <BUPLTableComponent2
              getInvoiceDetail={this.getInvoiceDetail} {...this.props} radioSelectorValue={radioSelectorValue} tabSelectorValue={tabSelectorValue}
              handleRowHeaderClick={this.handleRowHeaderClick} handleExcelDataChange={this.handleExcelDataChangeComparison}
              handleExcludeFilterChangeNew={this.handleExcludeFilterChangeNew}
              excludeDataFilter={excludeDataFilter}
              comparativeSelectorValue={comparativeSelectorValue}
              isShowVarianceChecked={this.getVariance}
              handleResetExcludeFilter={this.handleResetExcludeFilter}
              getInvoiceDetailGL={this.getInvoiceDetailGL}
              financialDuration={this.financialDuration}
              plantType = {plantType}
            />}
        </div>

        <CompareModal 
         showModal={showCompareModal}
         tabSelectorValue={tabSelectorValue}
         radioSelectorValue={radioSelectorValue}
         handleClose={this.handleCompareClose}
         />
      </div>
    );
  }
}

export default
  connect(
    state => {
      return {
        incomeStatementState: state.incomeStatement,
      }
    },
    dispatch => {
      return {
        getBUPLIncomePivotData(data) {
          dispatch({
            type: 'FETCH_BUPL_INCOME_PIVOT_ALL',
            payload: data
          });
        },
        getBUPLMonthlyQuarterData(data) {
          dispatch({
            type: 'FETCH_BUPL_MONTHLY_QUARTER_ALL',
            payload: data
          });
        },
        getBUPLCountryData(data) {
          dispatch({
            type: 'FETCH_BUPL_COUNTRY_PLANT_ALL',
            payload: data
          });
        },
        getBUPLDefaultMonthData() {
          dispatch({
            type: 'FETCH_BUPL_DEFAULT_MONTH'
          });
        },
        getBUPLInvoiceDetail(data) {
          dispatch({
            type: 'FETCH_BUPL_INVOICE_DETAIL',
            payload: data
          });
        },
        getBUPLInvoiceDetailGL(data) {
          dispatch({
            type: 'FETCH_BUPL_INVOICE_DETAIL_GL',
            payload: data
          });
        },
        getBUPLComparativeDetail(data) {
          dispatch({
            type: 'FETCH_BUPL_COMPARATIVE_DETAIL',
            payload: data
          });
        },
        getBUPLComparativePlantDetail(data) {
          dispatch({
            type: 'FETCH_BUPL_COMPARATIVE_PLANT_DETAIL',
            payload: data
          });
        },
        unmountActionCalled() {
          dispatch({
            type: 'BUPL_UNMOUNT_ACTION'
          });
        },

      };
    }
  )(BUPLComponent);





