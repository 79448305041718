import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import React from 'react';
import { connect } from "react-redux";
import NewPagination from "../../../common/components/CustomPagination";

import { numberWithCommas } from '../../../../utils/numberUtils'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatISO, isSameMonth, isSameYear } from 'date-fns'
import InProgressComponent from '../../../common/components/InProgress'

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class UserAnalyticsTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
            manualAmountValue: '',
            inputFileName: '',
            selectedDataField: '',
            selectedKey: '',
            isUpdate: false,
            id: '',
            selectedDataGroup: '',
            editVersion: '',
            showModal: false,
            reportDate: new Date()
        }
    }

    componentDidMount() {
    }

    handleValueTransform = (data, keys) => {
        let modifiedData;
        const {radioSelectorValue} = this.props;

        if (data) {
            modifiedData = Math.round(data)
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData

        } else {
            modifiedData = 0
        }

        if (keys && keys.includes('delta') || radioSelectorValue == 4) {
            modifiedData = modifiedData == '0' ? 'N/A' : modifiedData
        } else if (  this.props.validationTabSelector == 0 ){
            modifiedData = modifiedData == '0' || modifiedData == null ? "N/A" :modifiedData
        }else {
            modifiedData = modifiedData == '0' ? '-' : modifiedData
        }


        return modifiedData
    }

    handleClose = () => {
        this.setState({ showModal: false })
    };

    handleSubmit = () => {

        const { manualAmountValue, selectedKey, selectedDataGroup, selectedDataField, reportDate, inputFileName, isUpdate, editVersion, id } = this.state;
        // if (this.props.tabSelectorValue === 2) {
        //     let version = 'v1'
        //     // let isSameMonthYearPresent = this.props.incomeStatementState.automationRowData && this.props.incomeStatementState.automationRowData.length && this.props.incomeStatementState.automationRowData.filter(x => {
        //     //     if (isSameYear(new Date(x.report_date), new Date(reportDate)) && isSameMonth(new Date(x.report_date), new Date(reportDate))) {
        //     //         return x
        //     //     }
        //     // })
        //     // if (isSameMonthYearPresent && isSameMonthYearPresent.length) {
        //     //     isSameMonthYearPresent = isSameMonthYearPresent.sort((a, b) => b.version.split('v')[1] - a.version.split('v')[1])
        //     //     version = 'v' + (Number(isSameMonthYearPresent[0].version.split('v')[1]) + 1)
        //     // }
        //     const payload = {
        //         all_details: [
        //             {
        //                 report_date: formatISO(new Date(reportDate)),
        //                 manual_net_earnings: parseFloat(manualAmountValue),
        //                 file_name: inputFileName
        //                 // version: isUpdate ?editVersion: version,
        //                 // _id: isUpdate ? id: null
        //             }
        //         ]
        //     }
        //     this.props.postBUPLValidationData(payload)
        // } else 
        if (this.props.tabSelectorValue === 4) {
            let payload = {
                key: selectedKey,
                group: selectedDataGroup,
                data_field: selectedDataField,
                value: parseFloat(manualAmountValue)
            }
            this.props.postManualAmountData(payload)

        } else {
            let payload = {
                key: selectedKey,
                tabSelectorValue: this.props.tabSelectorValue,
                data_field: selectedDataField,
                value: parseFloat(manualAmountValue)
            }
            this.props.postManualAmountData(payload)
        }

        this.setState({ isUpdate: false })
        this.handleClose()
    }

    onKeyPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            const { manualAmountValue, selectedKey, selectedDataField } = this.state;
            let payload = {
                key: selectedKey,
                data_field: selectedDataField,
                value: parseFloat(manualAmountValue.replace(/,/g, ''))
            }

            this.props.postManualAmountData(payload)
            this.handleClose()
        }
    }
    handleDynamicWidth = (item) => {
        let widthSize = 100;
        switch (item.key) {
          case 'manual_oe_plant':
            widthSize = 150;
            break;
          case 'coded_oe_plant':
            widthSize = 150;
            break;
            case 'contract_liability_new_outstanding':
                widthSize = 120;
                break;
          default:
            break;
        }
        return widthSize;
      };
    handleSubColumnChangeHeader = (data, key) => {
        if (data && data.length) {
            let userLogin = this.props.authReducer.userData
            return data.map((item, i) => {

                if (item.key.includes('manual') && item.key !== "manual_net_earnings") {
                    return {
                        Header: item.label,
                        id: item.key,
                        width: this.handleDynamicWidth(item),
                        Cell: (props) => {
                            return (
                                this.props.tabSelectorValue === 3 ?
                                    props.original.sub_column[item.key] == 0 ?
                                        (userLogin.login_id == "yanda.widagdo@eaciit.com" || userLogin.login_id == "navdeep@eaciit.com" || userLogin.login_id == "asif@eaciit.com"|| userLogin.login_id == "sonraj.gaonkar@eaciit.com") ?

                                            <div className='text-center pointer' onClick={() => {
                                                if (props.original.sub_column) {
                                                    this.setState({
                                                        showModal: true,
                                                        selectedKey: item.key,
                                                        manualAmountValue: props.original.sub_column[item.key] == 0 ? '' : props.original.sub_column[item.key],
                                                        selectedDataField: props.original['data_field']
                                                    })
                                                }

                                            }
                                            }>
                                                {
                                                    <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : ''}</span>
                                                }
                                            </div> :
                                            <div className='text-center'>
                                                {
                                                    <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : ''}</span>
                                                }
                                            </div>

                                        :

                                        <div className='text-center'>
                                            {
                                                <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : ''}</span>
                                            }
                                        </div>
                                    :
                                    (userLogin.login_id == "yanda.widagdo@eaciit.com" || userLogin.login_id == "navdeep@eaciit.com" || userLogin.login_id == "asif@eaciit.com"|| userLogin.login_id == "sonraj.gaonkar@eaciit.com") ?
                                        <div className='text-center pointer' onClick={() => {
                                            if (props.original.sub_column) {
                                                this.setState({
                                                    showModal: true,
                                                    selectedKey: item.key,
                                                    manualAmountValue: props.original.sub_column[item.key] == 0 ? '' : props.original.sub_column[item.key],
                                                    selectedDataField: props.original['data_field']
                                                })
                                            }

                                        }
                                        }>
                                            {
                                                <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : ''}</span>
                                            }
                                        </div> :
                                        <div className='text-center'>
                                            {
                                                <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : ''}</span>
                                            }
                                        </div>
                            )
                        }
                    }
                } 
                else if (item.key.includes('delta')) {
                    return {
                        Header: item.label,
                        id: item.key,
                        // width: 100,
                        // width: item.key.includes('ca')|| item.key.includes('cf') ? 200 : "",
                        Cell: (props) => {
                            const isValue = this.handleValueTransform(props.original.sub_column[item.key], item.key) === "N/A" ? true : false
                            return (
                                <span className={isValue ? "success-color" : "error-color"}>{this.handleValueTransform(props.original.sub_column[item.key], item.key)}</span>
                            )
                        },
                        headerStyle: { 
                            // paddingLeft: item.key.includes('ca')|| item.key.includes('cf') ? '30px' : '',               
                            overflow: 'visible',
                            fontSize: '13px',
                            borderRight: i == data.length - 1 ? '1px solid #423970' : (this.props.tabSelectorValue === 3 ? '1px solid #423970' : '')
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : (this.props.tabSelectorValue === 3 ? '1px solid #423970' : '')
                        }
                    }
                }
                else if (this.props.radioSelectorValue == 4 || this.props.validationTabSelector == 0) {
                    return {
                        Header: item.label,
                    id: item.key,
                    width: this.handleDynamicWidth(item),
                    Cell: (props) => {
                        const isValue = this.handleValueTransform(props.original.sub_column[item.key], item.key) === "N/A" ? true : false
                        return (
                            <span className={isValue ? "success-color" : "error-color"}>{this.handleValueTransform(props.original.sub_column[item.key], item.key)}</span>
                        )
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                    },
                    style: {
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                    }
                }
                else if (item.key === "created_at" || item.key === "report_date") {
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    <span >{format(new Date(props.original.sub_column[item.key]), 'dd LLL yy')}</span>
                                </div>
                            )
                        },
                        headerStyle: {    
                            overflow: 'visible',
                            fontSize: '13px',
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        }
                    }
                }
                else if (item.key === "uploaded_by" || item.key == 'file_name') {
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    <span >{props.original.sub_column && props.original.sub_column[item.key] ? props.original.sub_column[item.key] : '-'}</span>
                                </div>
                            )
                        },
                        headerStyle: {  
                            overflow: 'visible',
                            fontSize: '13px',  
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        }
                    }
                }
                else if (item.key === "validation") {
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    <span >{props.original.sub_column && props.original.sub_column[item.key] ? this.handleValueTransform(props.original.sub_column[item.key]) + '%' : '-'}</span>
                                </div>
                            )
                        },
                        headerStyle: { 
                            overflow: 'visible',
                            fontSize: '13px',
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        }
                    }
                }
                else if (item.key.includes('ca_')|| item.key.includes('cf_')) {
                    return {
                        Header:   item.key == "ca_aging" || item.key == "cf_aging"? (<><div>Total</div><div>(Aging View)</div></>) : item.label,
                        id: item.key,
                        // width:  item.key == "ca_aging"? 75 : 100,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    <span >{props.original.sub_column && props.original.sub_column[item.key] ? this.handleValueTransform(props.original.sub_column[item.key])  : '-'}</span>
                                </div>
                            )
                        },
                        headerStyle: { 
                            // paddingLeft:'20px',  
                            overflow: 'visible',
                            fontSize: '13px',
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        }
                    }
                }
                else if ( this.props.radioSelectorValue == 3 && (item.key.includes('new')|| item.key.includes('bupl'))) {
                    return {
                        Header:  item.label,
                        id: item.key,
                        // width:  350,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    <span >{props.original.sub_column && props.original.sub_column[item.key] ? this.handleValueTransform(props.original.sub_column[item.key])  : '-'}</span>
                                </div>
                            )
                        },
                        headerStyle: { 
                            // paddingLeft:'20px', 
                            maxWidth : '',
                            overflow: 'visible',
                            fontSize: '13px',
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        }
                    }
                }
                else if (item.key === "manual_net_earnings") {
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center' onClick={() => {
                                    this.setState({
                                        showModal: true,
                                        isUpdate: true,
                                        editVersion: props.original.version,
                                        manualAmountValue: props.original.manual_net_earnings ? props.original.manual_net_earnings : '',
                                        report_date: new Date(props.original.report_date),
                                        id: props.original._id,
                                        inputFileName: props.original.file_name
                                    })
                                }}>
                                    <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : '-'}</span>
                                </div>
                            )
                        },
                        headerStyle: {       
                            overflow: 'visible',
                            fontSize: '13px',      
                            borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                        },
                        style: {
                            borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                            cursor: 'pointer'
                        }
                    }
                }
                return {
                    Header: item.label,
                    id: item.key,
                    width: this.handleDynamicWidth(item),
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    <span >{props.original.sub_column ? this.handleValueTransform(props.original.sub_column[item.key], item.key) : '-'}</span>
                                }
                            </div>
                        )
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                    },
                    style: {
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }

            })
        }
    }


    changeKeysOfHeader2 = (data, isNested) => {
        if (data && data.length) {
           
            return data.map((item, i) => {
                if (item.key == 'data_field') {
                    return {
                        Header: "",
                        fixed: 'left',
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-left'>
                                    {
                                        <span>{props.original ? props.original[item.key] : ''}</span>
                                    }
                                </div>
                            )
                        },
                        headerStyle: {
                            color: '#8306ce'
                        },
                    }
                }

                if (item.key == 'data_group') {
                    return {
                        Header: item.label,
                        fixed: 'left',
                        id: item.key,
                        className: 'maxWidth150',
                        headerClassName: 'maxWidth150',
                        Cell: (props) => {
                            return (
                                <span>{props.original ? props.original[item.key] : ''}</span>
                            )
                        },
                        headerStyle: {
                            color: '#8306ce'
                        },
                    }
                }

                if (!item.sub_headers) {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        style: { cursor: item.key.includes('manual') ? 'pointer' : '' },
                        Cell: (props) => {
                            return (
                                <div className='text-center' onClick={() => {
                                    if (item.key.includes('manual')) {
                                        this.setState({
                                            showModal: true,
                                            selectedKey: item.key,
                                            selectedDataField: props.original['data_field'],
                                            selectedDataGroup: props.original['group']
                                        })
                                    }

                                }}
                                >
                                    {
                                        <span>{props.original ? this.handleValueTransform(props.original[item.key], item.key) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                    }
                }


                return {
                    Header: item.label,
                    id: item.key,
                    headerStyle: {
                        borderRight: '1px solid #423970'
                    },
                    style: {
                        borderRight: '1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    changeKeysOfHeader = (data, isNested) => {
        const { tabSelectorValue, radioSelectorValue } = this.props
        // if (tabSelectorValue == 4 ) {
        //     return this.changeKeysOfHeader2(data, isNested)

        // } else {
            if (data && data.length) {
                let hasDataGroup = false
                data.map((item, i) => { 
                    if(item.key == 'data_group') {
                        hasDataGroup = true
                    }
                })
                return data.map((item, i) => {
                    if (item.key == 'data_field') {
                        let label = hasDataGroup == true? item.label : "Time Period"
                         if (tabSelectorValue == 4 && radioSelectorValue == 1 || this.props.validationTabSelector==0) {
                            return {
                                Header: "",
                                fixed: 'left',
                                id: item.key,
                                columns: [{
                                    Header: item.label,
                                    id: item.key,
                                    Cell: (props) => {

                                        return (
                                            <React.Fragment>
                                                <>{props.original.data_field}</>
                                            </React.Fragment>
                                        )
                                    }
                                }],
                                headerStyle: {
                                    color: '#8306ce'
                                },
                            }
                        } if (tabSelectorValue == 4 ) {
                            return {
                                Header: "Payment Month",
                                fixed: 'left',
                                id: item.key,
                                columns: [{
                                    Header: item.label,
                                    id: item.key,
                                    Cell: (props) => {

                                        return (
                                            <React.Fragment>
                                                <>{props.original.data_field}</>
                                            </React.Fragment>
                                        )
                                    }
                                }],
                                headerStyle: {
                                    color: '#8306ce'
                                },
                            }
                        }else {
                            return {
                                Header: label,
                                fixed: 'left',
                                id: item.key,
                                headerClassName: 'maxWidth150',
                                columns: [{
                                    Header: item.label,
                                    id: item.key,
                                    className: 'maxWidth150',
                                    headerClassName: 'maxWidth150',
                                    Cell: (props) => {

                                        return (
                                            <React.Fragment>
                                                <>{props.original.data_field}</>
                                            </React.Fragment>
                                        )
                                    }
                                }],
                                headerStyle: {
                                    color: '#8306ce'
                                },
                            }
                        }
                    }

                    if (item.key == 'data_group') {
                        return {
                            Header: item.label,
                            fixed: 'left',
                            id: item.key,
                            headerClassName: 'maxWidth150',
                            columns: [
                                {
                                    Header: '',
                                    id: item.key,
                                    className: 'maxWidth150',
                                    headerClassName: 'maxWidth150',
                                    Cell: (props) => {

                                        return (
                                            <React.Fragment>
                                                <>{props.original.data_group}</>
                                            </React.Fragment>
                                        )
                                    }
                                }
                            ],
                            headerStyle: {
                                color: '#8306ce'
                            },
                        }
                    }
                    if (item.key == 'company_code' || item.key == 'cost_center' || item.key == 'plant') {
                        return {
                            //fixed: 'left',
                            Header: item.label,
                            id: item.key,
                            metaData: item.metadata,
                            headerStyle: {
                                borderRight: '1px solid #423970'
                            },
                            style: {
                                borderRight: '1px solid #423970'
                            },
                            getProps: (state, rowInfo, column) => {
                                if (
                                    rowInfo &&
                                    rowInfo.original &&
                                    rowInfo.original.company_code === 'Total'
                                ) {
                                    return { style: { textAlign: 'center',fontWeight:'bold' } };
                                }
                                return {};
                            },
                            Cell: (props) => {
                                return (
                                    <React.Fragment>
                                    <>{props.original ? props.original[item.key] : '-'}</>
                                </React.Fragment>
                                       
                                )
                            },
                        }
                    }
                    if (item.key == 'amount') {
                        return {
                            //fixed: 'left',
                            Header: item.label,
                            id: item.key,
                            metaData: item.metadata,
                            headerStyle: {
                                borderRight: '1px solid #423970'
                            },
                            style: {
                                borderRight: '1px solid #423970'
                            },
                            Cell: (props) => {
                                return (
                                    <React.Fragment>
                                    <>{props.original ? this.handleValueTransform(props.original[item.key], item.key): '-'}</>
                                </React.Fragment>
                                )
                            },
                        }
                    }
                    if (!item.sub_headers) {
                        return {
                            Header: item.label,
                            id: item.key,
                            metaData: item.metadata,
                            Cell: (props) => {
                                return (
                                    <div className='text-center'>
                                        {
                                            <span>{props.original ? this.handleValueTransform(props.original[item.key], item.key) : '-'}</span>
                                        }
                                    </div>
                                )
                            },
                        }
                    }

                    return {
                        Header: item.label,
                        id: item.key,
                        columns: this.handleSubColumnChangeHeader(item.sub_headers, item.key),
                        headerStyle: {
                            borderRight: '1px solid #423970'
                        },
                        style: {
                            borderRight: '1px solid #423970'
                        }
                    }
                })
            }
        // }

        return []
    }

    handleValueOnChange = (event) => {
        this.setState({ manualAmountValue: event.target.value.replace(/,/g, '') })
    }

    handleFileNameValueOnChange = (event) => {
        this.setState({ inputFileName: event.target.value })
    }

    handleReportDateChange = (event) => {
        this.setState({ reportDate: event })
    }
    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }
    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }
    render() {

        let { loading, automationHeaderData, automationRowData, uploadLoading } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(automationRowData)

        automationHeaderData = this.changeKeysOfHeader(automationHeaderData, nestedFlag)
        this.handleExcelDataChange(automationRowData, automationHeaderData)
        let maxwidth = ""

        if(this.props.validationTabSelector == 0){
            maxwidth = "1060px"
        }else if ( (this.props.tabSelectorValue == 0 && (this.props.radioSelectorISValue == 'functionalpl'  || this.props.radioSelectorISValue == 'salestech')) || this.props.radioSelectorValue == '3'){
            maxwidth = "60em"           
        }else if (this.props.tabSelectorValue == 2){
            maxwidth = "100em" 
        }else if(this.props.tabSelectorValue == 3 && this.props.radioSelectorValue == 0){
            maxwidth = "1360px"
        }else if(this.props.tabSelectorValue == 6 ){
            maxwidth = "1060px"
        }
        const body = (
            <div className='customModal forecastModal'>
                <div className='header'>
                     <h2>Add Manual Amount</h2>
                    <Icon onClick={this.handleClose}>close</Icon>
                </div>
                <div className='modal-content'>
                    <form>
                        <FormControl className="w-50 mb-20 flex maxw-200">
                            {this.props.tabSelectorValue < 2 && <TextField label="Amount (USD)"
                                value={this.state.manualAmountValue}
                                placeholder={'Amount'}
                                className="mb-20"
                                onChange={this.handleValueOnChange}
                                onKeyPress={this.onKeyPress}
                            />}
                            {this.props.tabSelectorValue == 4 && <TextField label="Amount (USD)"
                                value={this.state.manualAmountValue}
                                placeholder={'Amount'}
                                className="mb-20"
                                onChange={this.handleValueOnChange}
                                onKeyPress={this.onKeyPress}
                            />}
                            {this.props.tabSelectorValue === 2 && <TextField
                                value={this.state.manualAmountValue}
                                placeholder={'Amount'}
                                className="mb-20"
                                onChange={this.handleValueOnChange}
                                onKeyPress={this.onKeyPress}
                            />
                            }
                            {/* {this.props.tabSelectorValue === 2 && <TextField label="File Name"
                                value={this.state.inputFileName}
                                placeholder={'File Name'}
                                onChange={this.handleFileNameValueOnChange}
                                onKeyPress={this.onKeyPress}
                            />} */}
                        </FormControl>
                        {/* {this.props.tabSelectorValue === 2 && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                disableFuture={true}
                                size="small"
                                label="Report Date"
                                format="dd-MM-yyyy"
                                className='maxw-200 mt-20'
                                value={this.state.reportDate}
                                minDate={new Date('07-01-2020')}
                                onChange={this.handleReportDateChange}
                            />
                        </MuiPickersUtilsProvider>} */}
                    </form>
                    <Grid container justify="flex-end" className='mt-20'>
                        <Button variant="contained" color="primary" className='mr-10' onClick={this.handleSubmit}>Submit</Button>
                        <Button variant="contained" onClick={this.handleClose}>Cancel</Button>
                    </Grid>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                {uploadLoading && <InProgressComponent headerText="Upload In Progress" bodyText="PNL Upload Process has been started we will be notifying you once done." />}
                {loading ? <div className='progressfullpage'><CircularProgress /></div> :
                    <>
                        {/* { this.props.tabSelectorValue === 2 && <div className='text-right'>
                            <Button variant="contained" className='mb-10' onClick={() => this.setState({ showModal: true })} color="primary">Add Data</Button>
                        </div>} */}
                        { 
                        <Grid item justify="flex-end"  style={{ maxWidth : maxwidth }}>
                        <ReactTableFixedColumns
                            data={automationRowData ? automationRowData : []}
                            columns={automationHeaderData}
                            loading={loading}
                            defaultPageSize={15}
                            pageSize={automationRowData && automationRowData.length}
                            className={this.props.tabSelectorValue === 2  ? 'xpna-reactFixed-table dataValidationTable  -striped -highlight' : (this.props.radioSelectorValue == '3' || this.props.tabSelectorValue === 6 ? 'xpna-reactFixed-table dataValidationTable -striped -highlight' : 'xpna-reactFixed-table dataValidationTable twoRowsFixed -striped -highlight')}                            
                            PaginationComponent={NewPagination}
                            pageSizeOptions={[15, 25, 35]}
                            sortable={false}
                            resizable={true}
                        />
                        </Grid>
                        }
                    </>
                }
                <Modal
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModal}>
                        {body}
                    </Fade>
                </Modal>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducer: state.authReducer,
                dashboardState: state.dashboardState,
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(UserAnalyticsTableComponent);
