import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Chip, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toastr } from "react-redux-toastr";


export default function SimpleModal(props) {
    const [showPassword, setshowPassword] = useState(false);
    const [showPasswordRepeat, setshowPasswordRepeat] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [loginid, setLoginID] = React.useState('');
    const [firstname, setFirstName] = React.useState('');
    const [fullname, setFullName] = React.useState('');
    const [lastname, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [role, setRole] = React.useState('');
    const [group, setGroup] = React.useState('');
    const [enable, setEnable] = React.useState(true);
    const [passwordError, setPasswordError] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [passwordrepeat, setPasswordRepeat] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [budgetGroup, setBudgetGroup] = React.useState([]);
    const [oneSensiaHemisphere, setOneSensiaHemisphere] = React.useState('');
    const [oneSensiaRegion, setOneSensiaRegion] = React.useState([]);
    const [oneSensiaGroup, setOneSensiaGroup] = React.useState([]);
    const [salesBU, setSalesBU] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);

    const [oneSensiaRegionArr,setOneSensiaRegionArr] = React.useState([]);
    const [error, setError] = React.useState('');
    const [OSGroupError, setOSGroupError] = React.useState('');
    const [STGroupError, setSTGroupError] = React.useState('');
    const [STBUError, setSTBUError] = React.useState('');
    const handleClickShowPassword = () => {
        setshowPassword(!showPassword)
    }; 

    const handleClickShowPasswordRepeat = () => {
        setshowPasswordRepeat(!showPasswordRepeat)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordRepeat = (event) => {
        event.preventDefault();
    };
    const [userLoginid, setUserLoginID] = React.useState('');
 
    React.useEffect(() => {
        if (props.showModal) {
            handleResetPopUp();
            if (props.editMode) {
                loadEditData();
            }
        }
    }, [props.showModal]);

    const handleClose = () => {
        props.modalCallBack();
    }

    const handleResetPopUp = () => {
        setLoginID("");
        setFirstName("");
        setFullName("");
        setLastName("");
        setEmail("");
        setRole("");
        setGroup("");
        setBudgetGroup([]);
        setOneSensiaHemisphere('');
        setOneSensiaRegion([]);
        setOneSensiaGroup([]);
        setSalesBU([]);
        setSalesGroup([]);
        setEnable(true);
        setPassword("Password.1");
        setPasswordRepeat("Password.1");
        setBtnDisabled(true);
        setError('');
        setUserLoginID('')
    }
    
    function f( arr ){
        var selectedBudgets = arr.map( x => {
                         return {"text": x[0].Value , "value":x[0].Value} })                
        return selectedBudgets;
    }

    const loadEditData = () => {
        let data = props.editData;
        setLoginID(data.loginid);
        setFirstName(data.name);
        setFullName(data.fullname)
        setLastName(data.lastname)
        setEmail(data.email.toLowerCase());
        setRole(data.roledata.ID);
        setGroup(data.groupdata == undefined ? "" : data.groupdata.value);
        setEnable(data.enable);
        let b = [{text:"All Function", value:"All Function"}]
        if (data.budgetgroup != undefined){
            b= f(data.budgetgroup)
        }
        console.log(data.budgetgroup, "bggggg", data)
        setBudgetGroup(b);
        let hemishphereData =  data.onesensiaaccess.hemisphere;
        setOneSensiaHemisphere(hemishphereData && hemishphereData.length ? hemishphereData[0]:'');
        setOneSensiaRegion(hemishphereData && hemishphereData.length ? hemishphereData[0].region : []);
        setOneSensiaGroup(data.onesensiaaccess.group ? data.onesensiaaccess.group :[]);
        setSalesBU(data.salesandtechnologiesaccess.bu ? data.salesandtechnologiesaccess.bu:[]);
        setSalesGroup(data.salesandtechnologiesaccess.group ? data.salesandtechnologiesaccess.group:[]);
        setUserLoginID(props.userData.login_id);
    }

    const handleChangeLoginID = (event) => {
        setLoginID(event.target.value);
    };

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
        setBtnDisabled(false)
    };

    const handleChangeFullName = (event) => {
        setFullName(event.target.value);
        setBtnDisabled(false)
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
        setBtnDisabled(false)
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value.toLowerCase());
        setBtnDisabled(false)
    };

    const handleChangeRole = (event) => {
        setRole(event.target.value);
        setBtnDisabled(false)
    };

    const handleChangeGroup = (event) => {
        setGroup(event.target.value);
        setBtnDisabled(false)
    };
    const handleChangeBudgetGroup = (event, value) => {
        let allFunc = value.find(x => x.text == "All Function")
        let refinedBg;
        let errorMsg = '';
        if (allFunc !=undefined) {
            errorMsg =  '* All Function includes all the Budget Groups.';
            refinedBg = [{text:"All Function", value:"All Function"}]
        }else{
            refinedBg = value;
        }
        setError(errorMsg)
        setBudgetGroup(refinedBg);
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    const handleChangeOSHemisphere = (event, value) => {
       console.log('valllllll', value)
       
        setOneSensiaHemisphere(value ? value.text: '');
        setOneSensiaRegion([]);
        setOneSensiaRegionArr(value && value.regions ? value.regions : [])
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    const handleChangeOSRegion = (event, value) => {
        let allFunc = value.find(x => x.text == "All Function")
        let refinedBg;
        let errorMsg = '';
        if (allFunc !=undefined) {
            errorMsg =  '* All Function includes all the Budget Groups.';
            refinedBg = [{text:"All Function", value:"All Function"}]
        }else{
            refinedBg = value;
        }
        setError(errorMsg)
        setOneSensiaRegion(refinedBg);
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    const handleChangeOSGroup = (event, value) => {
        let allFunc = value.find(x => x.text == "All Groups")
        let refinedBg;
        let errorMsg = '';
        if (allFunc !=undefined) {
            errorMsg =  '* All Groups includes all the One Sensia Groups.';
            refinedBg = [{text:"All Groups", value:"All Groups"}]
        }else{
            refinedBg = value;
        }
        setOSGroupError(errorMsg)
        setOneSensiaGroup(refinedBg);
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    const handleChangeSTBU = (event, value) => {
        let allFunc = value.find(x => x.text == "All BUs")
        let refinedBg;
        let errorMsg = '';
        if (allFunc !=undefined) {
            errorMsg =  '* All BUs includes all the BUs';
            refinedBg = [{text:"All BUs", value:"All BUs"}]
        }else{
            refinedBg = value;
        }
        setSTBUError(errorMsg)
        setSalesBU(refinedBg);
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    const handleChangeSTGroup = (event, value) => {
        let allFunc = value.find(x => x.text == "All Groups")
        let refinedBg;
        let errorMsg = '';
        if (allFunc !=undefined) {
            errorMsg =  '* All Groups includes all the One Sensia Groups.';
            refinedBg = [{text:"All Groups", value:"All Groups"}]
        }else{
            refinedBg = value;
        }
        setSTGroupError(errorMsg)
        setSalesGroup(refinedBg);
        document.activeElement.blur()
        setBtnDisabled(false)
    };

    
    const handleChangeEnable = (event) => {
        setEnable(event.target.checked);
    };

    const handleChangePassword = (event) => {
        validatePasswordStrenght(event.target.value);
        setPassword(event.target.value);
    };

    const handleChangePasswordRepeat = (event) => {
        setPasswordRepeat(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const validatePasswordStrenght = (val) =>{
            var reg = new RegExp(/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/);
            var test = reg.test(val);
            setPasswordError(!test)
    }

    const handleSaveuser = () => {
        if (props.editMode) {
            var data = {}
            data._id = props.editData.bsonid;
            data.login_id = email;
            data.name = firstname;
            data.last_name = lastname;
            data.full_name = firstname + " " + lastname;
            data.email = email;
            data.role = role;
            data.group = group;
            data.enable = enable;
            data.budgetGroup = budgetGroup.length == 0 ? [{text:"All Function", value:"All Function"}] : budgetGroup;
            data.one_sensia_access = {}
            data.sales_and_technologies_access = {}

            if ((oneSensiaHemisphere == '') || salesBU.length == 0) {
                toastr.error('Please fill mandatory values before submitting')
                return
            }

            data.one_sensia_access.hemisphere = [{
                text: oneSensiaHemisphere && oneSensiaHemisphere.text ? oneSensiaHemisphere.text : oneSensiaHemisphere,
                value:oneSensiaHemisphere && oneSensiaHemisphere.text ? oneSensiaHemisphere.text : oneSensiaHemisphere,
                region: oneSensiaRegion
            }]
            data.one_sensia_access.group = oneSensiaGroup
            data.sales_and_technologies_access.bu = salesBU
            data.sales_and_technologies_access.group = salesGroup
            props.saveCallback(data);
        } else {
            var data = {}
            data.login_id = email;
            data.name = firstname;
            data.last_name = lastname;
            data.full_name = firstname + " " + lastname;
            data.email = email;
            data.role = role;
            data.group = group;
            data.enable = enable;
            data.password = password;
            data.budgetGroup = budgetGroup.length == 0 ? [{text:"All Function", value:"All Function"}] : budgetGroup;
            data.passwordrepeat = passwordrepeat;
            props.saveCallback(data);
        }
    }

    const handleDeleteUser = () => {
        var data = {}
        data._id = props.editData.bsonid;
        data.login_id = email;
        data.name = firstname;
        data.last_name = lastname;
        data.full_name = firstname + " " + lastname;
        data.email = email;
        data.role = role;
        data.group = group;
        data.enable = enable;
        props.deleteCallback(data);
        handleCloseDialog();
    }

    const validateEmail = () => {
        if (email.indexOf("@") > -1 || email == "") {
            return true
        }
        return false
    }

    React.useEffect(() => {
        let data = props.editData;
        if (props.editMode) {
            // if (
            //     firstname.trim() != "" &&
            //     lastname.trim() != "" &&
            //     email != "" &&
            //     role != "" &&
            //     group != "" &&
            //     budgetGroup != [] &&
            //     validateEmail()
            // ) {
            //     setBtnDisabled(false)
            // }
            // else
            //     setBtnDisabled(true)
        } else {
            if (
                firstname.trim() != "" &&
                lastname.trim() != "" &&
                email != "" &&
                role != "" &&
                group != "" &&
                password.trim() != "" &&
                passwordrepeat.trim() != "" &&
                (password == passwordrepeat) &&
                !passwordError &&
                budgetGroup != [] &&
                validateEmail()
            )
                setBtnDisabled(false)
            else
                setBtnDisabled(true)
        }
    }, [firstname, lastname, email, role, group, enable, password, passwordrepeat, budgetGroup,loginid,userLoginid])

    const body = (
        
        <div className='customModal addUserModal'>
            <div className='header'>
                {props.editMode ? <h2>Edit User Data</h2> : <h2>Add User Data</h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                {props.saveLoading ? <div className='text-center'><CircularProgress/></div> : ""}
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <TextField
                                    id="user-email"
                                    label="Email ID"
                                    value={email}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChangeEmail}
                                    error={!validateEmail()}
                                    helperText={!validateEmail() ? "Email ID invalid" : ""}
                                />
                            </Grid>
                            {
                                !props.editMode ?
                                    <Grid item xs={6} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={enable}
                                                    onChange={handleChangeEnable}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable"
                                        />
                                    </Grid>
                                    : ""
                            }
                            <Grid item xs={6} >
                                <TextField
                                    id="user-firstname"
                                    label="First Name"
                                    value={firstname}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChangeFirstName}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="user-fullname"
                                    label="Last Name"
                                    value={lastname}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChangeLastName}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <FormControl variant="outlined" fullWidth size='small'>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        label="Role"
                                        id="user-role"
                                        value={role}
                                        onChange={handleChangeRole}
                                    >
                                        {props.roles.map((val) => (
                                            val ? <MenuItem value={val.ID} key={val.ID}>
                                                {val.Name}
                                            </MenuItem> : ''
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                                <FormControl variant="outlined" fullWidth size='small'>
                                    <InputLabel>Group</InputLabel>
                                    <Select
                                        label="Group"
                                        id="user-group"
                                        value={group}
                                        onChange={handleChangeGroup}
                                    >

                                        {props.groups.map((val) => (
                                            val ? <MenuItem value={val.value} key={val.value}>
                                                {val.text}
                                            </MenuItem> : ''
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                !props.editMode ?
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" className='w-100' size='small'>
                                                <InputLabel htmlFor="user-password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="user-password"
                                                    labelWidth={70}
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={handleChangePassword}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    error={ passwordError }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" className='w-100' size='small'>
                                                <InputLabel htmlFor="user-repeat-password">Repeat Password</InputLabel>
                                                <OutlinedInput
                                                    id="user-repeat-password"
                                                    labelWidth={120}
                                                    type={showPasswordRepeat ? 'text' : 'password'}
                                                    value={passwordrepeat}
                                                    onChange={handleChangePasswordRepeat}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordRepeat}
                                                                onMouseDown={handleMouseDownPasswordRepeat}
                                                            >
                                                                {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    error={password != passwordrepeat && passwordrepeat != "" }
                                                    helperText={password != passwordrepeat && passwordrepeat != "" ? "Password not match" : ""}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                    : ""}
                            {
                                props.editMode ?
                                 <React.Fragment>
                                     <Grid item xs={6} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                disabled= {loginid === userLoginid?true:false}
                                                    checked= {enable}
                                                    onChange={handleChangeEnable}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable"
                                        />
                                    </Grid>
                                     <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={props.budgetGroup}
                                            defaultValue={budgetGroup}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeBudgetGroup}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Budget Group"
                                                placeholder="Budget Group"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                         <Typography  variant ="caption" style={{color: '#f44336'}} >
                                             {error}
                                         </Typography>  
                                     </Grid>
                                     
                                    
                                     <Grid item xs={12}>
                                         <Typography  variant ="caption" >
                                             {'One Sensia Access'}
                                         </Typography>  
                                     </Grid> 
                                         <Grid item xs={6}>
                                        <Autocomplete
                                            
                                            id="tags-standard"
                                            options={props.new_regions}
                                            defaultValue={oneSensiaHemisphere}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeOSHemisphere}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Hemisphere *"
                                                placeholder="Hemisphere"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                     </Grid>
                                     <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={oneSensiaRegionArr}
                                            defaultValue={oneSensiaRegion}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeOSRegion}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Region"
                                                placeholder="Region"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                     </Grid>
                                     <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={props.new_groups}
                                            defaultValue={oneSensiaGroup}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeOSGroup}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Group"
                                                placeholder="Group"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                        <Typography  variant ="caption" style={{color: '#f44336'}} >
                                             {OSGroupError}
                                         </Typography>  
                                     </Grid>
                                     <Grid item xs={12}>
                                         <Typography  variant ="caption" >
                                             {'Sales and Technology Access'}
                                         </Typography>  
                                     </Grid> 

                                     <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={props.sales_and_technologies_bus}
                                            defaultValue={salesBU}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeSTBU}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="BU *"
                                                placeholder="BU"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                        <Typography  variant ="caption" style={{color: '#f44336'}} >
                                             {STBUError}
                                         </Typography>  
                                     </Grid>
                                     <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={props.sales_and_technologies_groups}
                                            defaultValue={salesGroup}
                                            getOptionSelected = {(option, val) => option.text == val.text}
                                            getOptionLabel = {(option) => option.text}
                                            onChange={handleChangeSTGroup}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Group"
                                                placeholder="Group"
                                               />
                                            )}
                                            renderOption={(option) => {
                                                return (
                                                    <Typography style={{fontSize:'15px'}} noWrap >
                                                        <span>{option.text}</span>
                                                    </Typography> 
                                                );
                                              }}
                                        />  
                                        <Typography  variant ="caption" style={{color: '#f44336'}} >
                                             {STGroupError}
                                         </Typography>  
                                     </Grid>

                                    
                                  </React.Fragment>  
                                    : ""
                            }
                        </Grid>
                        { passwordError ?
                        <Grid container className='mt-10'> 
                            <span style={{fontSize:'10px', color:'red' }}>
                            Password must contain minimum eight characters, at least one uppercase, one lowercase, one number and one special character
                            </span> 
                        </Grid>
                        : ""}
                         {!props.saveLoading &&
                            <Grid container justify="flex-end" className='mt-30'>
                            
                                <Button onClick={handleSaveuser} variant="contained" color="primary">Submit</Button>
                                <Button variant="contained" onClick={handleClose} className='ml-10'>Cancel</Button>
                            </Grid>
                        }
                    </React.Fragment>
            </div>

        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

        </div>
    );
}