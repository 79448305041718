import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartContainer from "./chartContainer"
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export class ChartViewComponent extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    const datelen = props.chartView.reportDateArr && props.chartView.reportDateArr.length;

    this.state = {
      externalTabValue: 0,
      month: '31-Aug-2021',
      viewWidth: 0
    }
  }


  handleExternalTabChange = (event, newValue) => {
    this.setState({ externalTabValue: newValue }, this.fetchChartData);
    console.log(newValue)
  };

  handleMonthChange = (event) => {
   
    if (event.target && this.state.month != event.target.value) {
      this.setState({ month: event.target.value }, () => {
        let chartTypes = ""
        if(this.props.tabSelectorValue == 1){
          chartTypes="new"
        }
        this.props.getChartView({ report_date: event.target.value, chart_type :  chartTypes})
      });
    }
  }

  getFirstLoad = () => {
    let chartTypes = ""
    if(this.props.tabSelectorValue == 1){
      chartTypes="new"
    }
    let payload = {
      chart_type : chartTypes
    }
    this.props.getFirstLoad(payload)
  }

  componentDidMount() {
    console.log(this.props.tabSelectorValue, "Thissss")
    this.getFirstLoad()
  }

  componentDidUpdate(prevProps) {
    let myWidth = this.container.offsetWidth
    const { viewWidth } = this.state

    if (viewWidth != myWidth && !prevProps.chartView.loading) { //refresh chart when the width change
      this.setState({ viewWidth: myWidth })
    }

  }

  render() {
    const { externalTabValue, month, viewWidth } = this.state
    const { chartView } = this.props
    const arrayDate = chartView.reportDateArr
    const newArray = []
    const idx = arrayDate.length
    let Newidx = 0
    for (var keys in arrayDate) {
      Newidx++ 
      
      newArray.push(arrayDate[(idx-Newidx)])
    }
    const datelen = chartView.reportDateArr && chartView.reportDateArr.length;
    const updatedMonth = chartView.selectedDate

    return (
        <div className='dataValidation' ref={el => (this.container = el)}>
          {chartView && chartView.reportDateArr && chartView.reportDateArr.length ?
            <>
              <div className="flex ai-center jc-space-between mb-10">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2} className='maxw-200'>
                    <FormControl className='w-200'>
                      <Select
                        value={month || updatedMonth}
                        onChange={this.handleMonthChange}
                        MenuProps={{ onExited: () => document.activeElement.blur() }}
                      >
                        {newArray.map((item, index) => {
                          return <MenuItem value={item} key={index}>{item}</MenuItem>
                        })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              {
                  <ChartContainer
                  tabSelectorValue= {this.props.tabSelectorValue}
                      chartData={chartView.data}
                      loading={chartView.loading}
                      width={viewWidth}
                  ></ChartContainer>
              }
            </> : null
          }
        </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        dashboardView : state.dashboardState,
        chartView: state.dashboardState.dashboardAnalytic.ArOverdueChartView,
      }
    },
    (dispatch) => {
      return {
        getFirstLoad(data) {
          dispatch({
            type: "FETCH_SCATTER_CHART_VIEW_FIRST_LOAD",
            payload: data,
          });
        },
        getChartView(data) {
          dispatch({
            type: "FETCH_SCATTER_CHART_VIEW",
            payload: data,
          });
        }
      }
    }
  )(ChartViewComponent)
);