import React,{forwardRef,current, useRef, useImperativeHandle} from 'react';
import 'date-fns';
import { useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import { Typography,Chip,Tooltip } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const DropdownComponent = forwardRef((props,ref)=> {
    const {externalFilterObject} = props;
    let defaultCustomerState = externalFilterObject && externalFilterObject.customer_name ? externalFilterObject.customer_name: []
    console.log(props,"parentGroup")
    const theme = useTheme();
    const [dateFilter, setDateFilter] = React.useState([]);
    const [customerName, setCustomerName] = React.useState(defaultCustomerState);
    const [commentGroup, setCommentGroup] = React.useState([]);
    const [actionOwner, setActionOwner] = React.useState([]);
    const [regionArr, setRegionArr] = React.useState([]);
    const [soldArr, setSoldArr] = React.useState([]);
    const [oneBUArr, setOneBUArr] = React.useState([]);
    const [buArr, setBUArr] = React.useState([]);
    const [documentNumberArr, setDocumentNumberArr] = React.useState([]);
    const [companyCode, setCompanyCodeArr] = React.useState([]);
    const [agingCategory, setAgingCategory] = React.useState([]);

    const handleDateChange = (event) => {
        setDateFilter(event.target.value);
    };

    const handleCustomerChange = (event, value) => {
        setCustomerName(value);
        document.activeElement.blur()
    };

    const handleCompanyCodeChange = (event, value) => {
        setCompanyCodeArr(value);
        document.activeElement.blur()
    };

    const handleCommentChange = (event) => {
        setCommentGroup(event.target.value);
    };

    const handleActionChange = (event, value) => {
        setActionOwner(value);
        document.activeElement.blur()
    };

    const handleRegionChange = (event, value) => {
        setRegionArr(event.target.value);
    };

    const handleSoldChange = (event, value) => {
        setSoldArr(event.target.value);
    };

    const handleOneBUChange = (event, value) => {
        setOneBUArr(event.target.value);
    };

    const handleBUChange = (event, value) => {
        setBUArr(event.target.value);
    };

    const handleDocumentNumberChange = (event, value) => {
        setDocumentNumberArr(value);
        document.activeElement.blur()

    };

    const handleAgingChange = (event) => {
        setAgingCategory(event.target.value);

    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    let dateFilterArr = []
    let companyCodeArr = []
    let customerNameArr = []
    let commentGroupArr = []
    let actionOwnerArr = []
    let agingCategoryArr = []
    let regionNameArr = []
    let soldToCountryArr = []
    let oneBUNameArr = []
    let buNameArr = []
    let documentArr = []

    if (props && props.externalfilterArr && props.externalfilterArr.length) {
        props.externalfilterArr.forEach(item => {
            switch (item.key) {
                case "customer_name":
                    customerNameArr = item.value;
                    break;
                case "company_code":
                    companyCodeArr = item.value;
                    break;
                case "action_owner":
                    actionOwnerArr = item.value;
                    break;
                case "yearmonth":
                    dateFilterArr = item.value;
                    break;
                case "aging_category":
                    agingCategoryArr = item.value;
                    break;
                case "comment_group":
                    commentGroupArr = item.value;
                    break;
                case "region":
                    regionNameArr = item.value;
                    break;
                case "sold_to_country":
                    soldToCountryArr = item.value;
                    break;
                case "revised_classification_new":
                    oneBUNameArr = item.value;
                    break;

                case "bu":
                    buNameArr = item.value;
                    break;

                case "document_number":
                    documentArr = item.value;
                    break;
                default: break;
            }
        })
    }

    function getStyles(name, data, theme) {
        return {
            fontWeight:
                data && data.indexOf && data.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleApplyFilter = () => {
        let act = JSON.parse(JSON.stringify(actionOwner))
        let cust = JSON.parse(JSON.stringify(customerName))

        let filterPayload = {
            action_owner: act.map(x => {
                return x.value;
              }),
            aging_category: agingCategory,
            category: "",
            yearmonth: dateFilter,
            company_code: companyCode,
            parent_group: props.parentGroup,
            customer_name: cust.map(x=> {return x}),
            region: regionArr,
            sold_to_country: soldArr,
            revised_classification_new: oneBUArr,
            bu: buArr,
            document_number: documentNumberArr,
            data_type : props.dataType,
            skip: 0,
        };
        props.handleApplyFilter(filterPayload)
    }

    useImperativeHandle(ref, ()=> ({

         handleResetFilter() {
            let filterPayload = {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                company_code : [],
                region: [],
                bu:[],
                document_number: [],
                category: "",
                parent_group: props.parentGroup,
                customer_name: [],
                skip: 0,
            };
            setDateFilter([])
            setCustomerName([])
            setCompanyCodeArr([])
            setCommentGroup([])
            setActionOwner([])
            setAgingCategory([])
            setRegionArr([])
            setSoldArr([])
            setOneBUArr([])
            setBUArr([])
            setDocumentNumberArr([])
            props.handleApplyFilter(filterPayload)
        }
    }));
    
   const forResetButtonHandleResetFilter =()=> {
        let filterPayload = {
            action_owner: [],
            aging_category: [],
            yearmonth: [],
            company_code : [],
            region: [],
            bu:[],
            document_number: [],
            category: "",
            parent_group: props.parentGroup,
            data_type : props.dataType,
            customer_name: [],
            skip: 0,
        };
        setDateFilter([])
        setCustomerName([])
        setCompanyCodeArr([])
        setCommentGroup([])
        setActionOwner([])
        setAgingCategory([])
        setRegionArr([])
        setSoldArr([])
        setOneBUArr([])
        setBUArr([])
        setDocumentNumberArr([])
        props.handleApplyFilter(filterPayload)
    }

   const refreshFilter = () => {
        setDateFilter([])
        setCustomerName([])
        setCompanyCodeArr([])
        setCommentGroup([])
        setActionOwner([])
        setAgingCategory([])
        setRegionArr([])
        setSoldArr([])
        setOneBUArr([])
        setBUArr([])
        setDocumentNumberArr([])
    }

    // const refreshCustomerNameFilter = () => {
    //     setCustomerName(props.externalFilterObject.customer_name)
    // }

    React.useEffect(() => {
        refreshFilter();
    }, [props.buValue, props.selectedMonthFilter]);



    // if (props.externalFilterObject) {
    //     React.useEffect(() => {
    //         refreshCustomerNameFilter();
    //     }, [props.externalFilterObject.customer_name]);
    // }


    return (
        <div className='ageingPageDropdown flex jc-flex-start ai-center mb-16'>
           <FormControl>
        <Autocomplete
                multiple
                limitTags={1}
                value={companyCode}
                options={companyCodeArr}
                getOptionLabel={(option) => option}
                onChange={handleCompanyCodeChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Company Code"
                        placeholder="Company Code"
                    />
                )}
                getOptionSelected={(option,val) => option == val}
                renderTags={(value, { params, onDelete }) =>
                    value.map((option, index) => (
                        <Tooltip title={option}>
                        <Chip
                            {...params}
                            key={option}
                            label={option.length > 5 ? option.substr(0,3) + ".." : option }
                            size="small"
                            onDelete={onDelete}
                        />
                        </Tooltip>
                    ))
                }
                renderOption={(option) => <Typography
                    style={{ fontSize: '10px' }}
                    noWrap>
                     <span>{option}</span>
                </Typography>}
            />
       
        </FormControl>
        {/* <FormControl>
           <InputLabel>Year/Month</InputLabel>
           <Select
               multiple
               value={dateFilter}
               onChange={handleDateChange}
               input={<Input />}
               MenuProps={MenuProps}
           >
               {dateFilterArr.map((name) => (
                   <MenuItem key={name} value={name} style={getStyles(name, dateFilter, theme)}>
                       {name}
                   </MenuItem>
               ))}
           </Select>
       </FormControl> */}
            <FormControl>
                <Autocomplete
                    multiple
                    limitTags={1}
                    value={customerName}
                    options={customerNameArr.sort()}
                    getOptionLabel={(option) => option}
                    onChange={handleCustomerChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Customer Name"
                            placeholder="Customer Name"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                id:"field-1"
                            }}
                        />
                    )}
                    getOptionSelected={(option,val) => option == val}
                    renderTags={(value, { params, onDelete }) =>
                        value.map((option, index) => (
                            <Tooltip title={option}>
                            <Chip
                                {...params}
                                key={option}
                                label={option.length > 5 ? option.substr(0,3) + ".." : option }
                                size="small"
                                onDelete={onDelete}
                            />
                            </Tooltip>
                        ))
                    }
                    renderOption={(option) => <Typography
                        style={{ fontSize: '10px' }}
                        noWrap>
                         <span>{option}</span>
                    </Typography>}
                />
            </FormControl>
            <FormControl>
                <InputLabel>Sold To Country</InputLabel>
                <Select
                    multiple
                    value={soldArr}
                    onChange={handleSoldChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {soldToCountryArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, soldArr.map(x => {return x}), theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            

            {/* <FormControl>
                <InputLabel>Document Number</InputLabel>
                <Select
                    multiple
                    value={documentNumberArr}
                    onChange={handleDocumentNumberChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {documentArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, documentNumberArr.map(x => {return x}), theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}

            <FormControl>
                <Autocomplete
                    multiple
                    limitTags={1}
                    value={documentNumberArr}
                    options={documentArr.sort()}
                    getOptionLabel={(option) => option}
                    onChange={handleDocumentNumberChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Document Number"
                            placeholder="Document Number"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                id:"field-1"
                            }}
                        />
                    )}
                    getOptionSelected={(option,val) => option == val}
                    renderTags={(value, { params, onDelete }) =>
                        value.map((option, index) => (
                            <Tooltip title={option}>
                            <Chip
                                {...params}
                                key={option}
                                label={option.length > 5 ? option.substr(0,3) + ".." : option }
                                size="small"
                                onDelete={onDelete}
                            />
                            </Tooltip>
                        ))
                    }
                    renderOption={(option) => <Typography
                        style={{ fontSize: '10px' }}
                        noWrap>
                         <span>{option}</span>
                    </Typography>}
                />
            </FormControl>
            <FormControl>
                <InputLabel>New BU</InputLabel>
                <Select
                    multiple
                    value={oneBUArr}
                    onChange={handleOneBUChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {oneBUNameArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, oneBUArr.map(x => {return x}), theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>BU</InputLabel>
                <Select
                    multiple
                    value={buArr}
                    onChange={handleBUChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {buNameArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, buArr.map(x => {return x}), theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <FormControl>
                <InputLabel>Improvement Category</InputLabel>
                <Select
                    multiple
                    value={commentGroup}
                    onChange={handleCommentChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="">
                        <em>Filter in progress. To be enabled soon.</em>
                    </MenuItem>
                    {commentGroupArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, commentGroup, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}
            <FormControl>
           
                <Autocomplete
                    multiple
                    limitTags={1}
                    value={actionOwner}
                    options={actionOwnerArr}
                    getOptionLabel={(option) => option.label}
                    onChange={handleActionChange}
                    getOptionSelected={(option,val) => option.value == val.value}
                    renderTags={(value, {params, onDelete}) =>
                        value.map((option, index) => (
                            <Tooltip title={option.label}>
                                <Chip
                                    {...params}
                                    key={option.value}
                                    label={option.label.length > 5 ?
                                        option.label.substr(0, 3) + '..' :
                                        option.label}
                                    size="small"
                                    onDelete={onDelete}
                                />
                            </Tooltip>
                        ))
                    }

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            style={{fontSize: '12px'}}
                            label="Action Owner"
                            placeholder="Action Owner"
                        />
                    )}
                    renderOption={(option) => <Typography
                        style={{fontSize: '12px'}}
                        noWrap>
                      <span>{option.label}</span>
                    </Typography>}
                />
            </FormControl>
            <FormControl>
                <InputLabel>Aging Category</InputLabel>
                <Select
                    multiple
                    value={agingCategory}
                    onChange={handleAgingChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {agingCategoryArr.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, agingCategory, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button color="primary" variant="contained" className='mr-10 mt-5 newButton btnBlue btnRefresh uppercase' onClick={handleApplyFilter}>Refresh</Button>
            <Button color="primary" variant="contained" className='mt-5 newButton btnRed btnClear uppercase' onClick={forResetButtonHandleResetFilter}>Clear</Button>
        </div>
    );
})

export default DropdownComponent;
