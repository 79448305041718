import {transformFilterData} from '../store/transformer'
export const initialState = {
    showLoader: false,
    id: "",
    simulationName: "",
    isDefaultSimulation: false,
    fromDate: "",
    toDate: "",
    fromAssign: "",
    toAssign: "",
    activeformula:"",
    simulationItems: [],
    whereClause: [],
    tabSelectorValue : 0,
    whereClauseOperator: "",
    activePopperData: {
        ref: null,
        open: false,
        itemId: "",
        itemIndex: -1,
        itemType: "",
        filters: [],
    },
    simulationTabel:{
        rowData : [],
        rowHeader : [],
        rowDataNorm : [],
        rowHeaderNorm : [],
        monthArr : [],
        monthArrFuture : [],
    }
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_FILTER_SUCCESS':
            const {
               monthArr, monthArrFuture
            } = transformFilterData(action.payload.data)
            return {
                ...state,
                simulationTabel : {monthArr : monthArr, monthArrFuture : monthArrFuture}
            }
            // console.log(state,monthArr, "--action")
        case "UPDATE_SIMULATION_DATA":
            return {
                ...state,
                id: action.payload.id,
                simulationName: action.payload.simulationName,
                isDefaultSimulation: action.payload.isDefaultSimulation,
                whereClause: action.payload.whereClause,
                whereClauseOperator: action.payload.whereClauseOperator,
                simulationItems: action.payload.simulationItems,
                toDate: action.payload.sample.to,
                fromDate: action.payload.sample.from,
                toAssign: action.payload.assign.to,
                fromAssign: action.payload.assign.from,
            };
        case "UPDATE_SIMULATION_ID":
            return {
                ...state,
                id: action.payload.id,
            };
        case "UPDATE_SIMULATION_TABEL":
            return  {
                ...state,
                simulationTabel : {
                    rowData : action.payload.row_data, 
                    rowHeader : action.payload.row_header,
                    rowDataNorm : action.payload.row_data_normalize, 
                    rowHeaderNorm : action.payload.row_header_normalize,
                    monthArr : state.simulationTabel.monthArr,
                    monthArrFuture : state.simulationTabel.monthArrFuture,
                },
                activeformula: action.payload.description.detail_text ? action.payload.description.detail_text : ""
            }
        case "UPDATE_LOADER_VISIBILITY":
            return {
                ...state,
                showLoader: action.payload,
            };
        case "UPDATE_FROM_DATE":
            return {
                ...state,
                fromDate: action.payload,
            };
        case "UPDATE_TO_DATE":
            return {
                ...state,
                toDate: action.payload,
            };
        case "UPDATE_ASSIGN_FROM_DATE":
            return {
                ...state,
                fromAssign: action.payload,
            };
        case "UPDATE_ASSIGN_TO_DATE":
            return {
                ...state,
                toAssign: action.payload,
            };
        case "TAB_CHANGE":
        return {
            ...state,
            tabSelectorValue: action.payload,
        };
        case "UPDATE_SIMULATION_NAME":
            return {
                ...state,
                simulationName: action.payload,
            };
        case "TOGGLE_DEFAULT_SIMULATION":
            return {
                ...state,
                isDefaultSimulation: !state.isDefaultSimulation,
            };
        case "UPDATE_SIMULATION_ITEMS":
            return {
                ...state,
                simulationItems: action.payload,
            };
        case "UPDATE_WHERE_CLAUSE":
            return {
                ...state,
                whereClause: action.payload,
            };
        case "UPDATE_SIMULATIONS_AND_WHERE_CLAUSE":
            return {
                ...state,
                simulationItems: action.payload.simulationItems,
                whereClause: action.payload.whereClause,
            };
        case "UPDATE_WHERE_CLAUSE_OPERATOR":
            return {
                ...state,
                whereClauseOperator: action.payload,
            };
        case "UPDATE_ACTIVE_POPPER_DATA":
            return {
                ...state,
                activePopperData: action.payload,
            };
        default:
            return state;
    }
};
