import React from "react";
import PropTypes from "prop-types";
import "react-table-6/react-table.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SummaryComponent from './SummaryComponent'
import MonthlyComponent from './MonthlyComponent'
import Button from '@material-ui/core/Button';
import ContractComponent from './ContractComponent'
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import { handleTableTransform } from '../../../utils/numberUtils'
import BusinessUnitComponent from './BusinessUnitComponent'
import DownloadExcel from "../../../../src/images/download_excel.svg";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, Tooltip, Typography, TextField } from '@material-ui/core';
import CompareModal from './Compare'

import jsPDF from "jspdf";
import 'jspdf-autotable'
import Grid from '@material-ui/core/Grid';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function Table(props) {
  const businessUnitIndex = {
    "bu 1": 0,
    "bu 2": 1,
    "bu 3": 2,
    "bu 4": 3,
    "oh":4,
    inter: 5
  }

  const NewBusinessUnitIndex = {
    "New BU 1": 0,
    "New BU 2": 1,
    unallocated: 2
  }
  const { customerNameArr } = props;

  const [ showCompareModal, setShowCompareModal] = React.useState(false);

  const [tabValue, setValue] = React.useState(props.defaultTab ? props.defaultTab : 0);
  const [columnValue, setColumnValue] = React.useState([]);
  const [rowValue, setRowValue] = React.useState([]);
  const [fixedHeaderValue, setFixedHeader] = React.useState(true);
  const datelen = props.reportDateArr && props.reportDateArr.length;
  const defaultSelectedDate = props.externalFilterObject.selectedDate;
  const [month, setMonth] = React.useState(defaultSelectedDate ? defaultSelectedDate : props.reportDateArr && props.reportDateArr[datelen > 0 ? datelen - 1 : 0]);
  const [customerNameSelected, setCustomerNameSelected] = React.useState([]);
  const [tabHemisphere, setTabHemisphere] = React.useState("");
  const [radioCL, setRadioCL] = React.useState("");
  const [radioLiability, setRadioLiability] = React.useState("");
  const [regionHemisphere, setRegionHemisphere] = React.useState("");
  const [hemisphereType, setTypeHemisphere] = React.useState("");
  const [state, setState] = React.useState({ checkedC: true });
  const dashboardState = useSelector(state => state.dashboardState);
  const [externalTabValue, setExtenalTabValue] = React.useState(props.externalFilterObject && props.externalFilterObject.bu ? businessUnitIndex[props.externalFilterObject.bu.toLowerCase()] : 0);
  const headerColorArr = ['#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']

  const { externalFilterObject } = props;
  const handleSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleChangeSelectedParentGroup(parentGroupObj[newValue])
  };

  const handleExternalTabChange = (event, newValue) => {
    setExtenalTabValue(newValue);
    if (props.receivableTabSelectorValue == 1) {
      props.handleChangeSelectedBU(reverseNewBUnitIndex[newValue])

    }else {
      props.handleChangeSelectedBU(reverseBUnitIndex[newValue])
    }
  };

  const changeKeysOfHeader = (data) => {


    if (data && data.length) {
      return data.map((item, i) => {
        return {
          Header: item.label,
          accessor: item.key,
          Cell: (props) => {
            return (
              <div className='text-center'>
                {handleTableTransform(props.original, item)}
              </div>
            )
          },
          style: {
            fontSize: '12px'
          },
          headerStyle: {
            whiteSpace: 'break-spaces',
            overflow: 'visible',
            fontSize: '10px',
            fontWeight: 'bold',
            backgroundColor: headerColorArr[i]
          }
        };
      });
    }
    return [];
  };

  const handleCompareClose = () => {
    setShowCompareModal(false)
  }

  const handleShowCompare = () => {
    setShowCompareModal(true)
  }

  const reverseBUnitIndex = {
    0: "BU 1",
    1: "BU 2",
    2: "BU 3",
    3: "BU 4",
    4: "OH",
    5: "inter"
  }

  const reverseNewBUnitIndex = {
    0: "New BU 1",
    1: "New BU 2",
    2: "Unallocated"
  }

  const parentGroupObj = {
    0: "summary",
    1: "external",
    2: "slb",
    3: "unbilled",
    4: "monthly",
    5: "liability"
  }

  const businessUnitType = {
    external: 1,
    slb: 2,
    unbilled: 3,
  }


  React.useEffect(() => {
    if (dashboardState.receivableData.ageingData.summary.fetched && fixedHeaderValue) {
      const datelen = props.reportDateArr && props.reportDateArr.length;
      const updatedMonth = props.reportDateArr[datelen > 0 ? datelen - 1 : 0]
      setMonth(updatedMonth);
      const payload = {
        mainTab: parentGroupObj[tabValue],
        secondaryTab: reverseBUnitIndex[externalTabValue],
        selectedDate: updatedMonth,
        receivableTabSelectorValue: props.receivableTabSelectorValue
      }
      if (props.receivableTabSelectorValue == 1) {
        payload.secondaryTab =  reverseNewBUnitIndex[externalTabValue]
  
      }
  
      props.handleDateChangeFn(payload)
      const { externalData, buRowData } = dashboardState.receivableData.ageingData.summary;
      if (externalData && externalData.row_header && externalData.row_header.length) {
        var _data = [...[], ...externalData.row_header]
        var headerData = []
        headerData = _data.filter(item => {
          if (!(item.key == "business_unit" || item.key == "grand_total")) {
            return item
          }
        })
        headerData = changeKeysOfHeader(headerData)
        if (externalTabValue < 5) {
          var rowData = [buRowData]
          setRowValue(rowData)
          setColumnValue(headerData)
          setFixedHeader(false)
        }
      }
    }
  }, [dashboardState?.receivableData?.ageingData?.summary?.fetched, fixedHeaderValue])

  

  const handleDownloadExcel = () => {
    // const datelen = props.reportDateArr && props.reportDateArr.length;
    // const updatedMonth = props.reportDateArr[datelen > 0 ? datelen - 1 : 0]
    const payload = {
      mainTab: parentGroupObj[tabValue],
      secondaryTab: reverseBUnitIndex[externalTabValue],
      selectedDate: month,
      export: "true",
      tabHemisphere : tabHemisphere,
      region : regionHemisphere,
      type : hemisphereType,
      customerNameSelected: customerNameSelected,
      receivableTabSelectorValue: props.receivableTabSelectorValue
    }
    if (props.receivableTabSelectorValue == 1) {
      payload.secondaryTab =  reverseNewBUnitIndex[externalTabValue]
      if( payload.mainTab == "liability") {
        payload.secondaryTab = "PAB"
      }

    }
    props.handleDateChangeFn(payload)
    // props.handleDownloadExcel(tabValue);
  }

  const printDocument = () => {
    props.printDocument(tabValue);
  }

  React.useEffect(() => {
    if (tabValue != 0) {
      setCustomerNameSelected([])

    }
  }, [tabValue])

  const summaryScreenBUClick = (item, type, headerData, rowData) => {

    

    if (businessUnitIndex[item] != undefined || NewBusinessUnitIndex[item] != undefined) {
      props.externalFilterObject.parent_group = parentGroupObj[businessUnitType[type]]
      props.externalFilterObject.bu = reverseBUnitIndex[businessUnitIndex[item]]
      props.externalFilterObject['customer_name'] = customerNameSelected
      // props.selectedBu =  businessUnitIndex[item]

      
      setValue(businessUnitType[type]);
      if (props.receivableTabSelectorValue == 1) {
        props.externalFilterObject.bu = reverseNewBUnitIndex[businessUnitIndex[item]]
        setExtenalTabValue(NewBusinessUnitIndex[item])
  
      }else {
        setExtenalTabValue(businessUnitIndex[item])

      }
    }
  }
  const changesRadioCL = (payload) => {
   setRadioCL(payload)

  }
  const handleApplyFilterFunc = (payload) => {
    payload['bu'] = reverseBUnitIndex[externalTabValue];
    if (props.receivableTabSelectorValue == 1) {
      payload.bu = reverseNewBUnitIndex[externalTabValue]

    }
    if (tabValue == 5){
      payload.bu = payload.radioValue
    }
    props.handleApplyFilter(payload)

  }

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    if (event.target.value) {
      const payload = {
        mainTab: parentGroupObj[tabValue],
        secondaryTab: reverseBUnitIndex[externalTabValue],
        selectedDate: event.target.value,
        receivableTabSelectorValue: props.receivableTabSelectorValue,
        tabHemisphere: tabHemisphere,
        radioCL: radioCL,
        tabValue : tabValue, 
      }
      if (props.receivableTabSelectorValue == 1) {
        payload.secondaryTab = reverseNewBUnitIndex[externalTabValue]
      }
      props.handleDateChangeFn(payload)
    }
  }
  const loadSummary = () => {
    const payload = {
      mainTab: parentGroupObj[tabValue],
      secondaryTab: reverseBUnitIndex[externalTabValue],
      selectedDate: month,
      receivableTabSelectorValue: props.receivableTabSelectorValue 
    }
    props.handleDateChangeFn(payload)
  }

  const radioHemisphareChanges = (data) => {
    const payload = {
      mainTab: parentGroupObj[tabValue],
      secondaryTab: reverseBUnitIndex[externalTabValue],
      selectedDate: month,
      tabHemisphere : data,
      customerNameSelected: customerNameSelected,
      receivableTabSelectorValue: props.receivableTabSelectorValue 
    }
    setTabHemisphere(data)
    props.handleDateChangeFn(payload)
  }
  const handleRegionHeaderClick = (data) => {
    const payload = {
      mainTab: parentGroupObj[tabValue],
      secondaryTab: reverseBUnitIndex[externalTabValue],
      selectedDate: month,
      tabHemisphere : data.radioValue,
      region : data.region,
      type : data.type,
      customerNameSelected: customerNameSelected,
      receivableTabSelectorValue: props.receivableTabSelectorValue 
    }
    setTypeHemisphere( data.type)
    setRegionHemisphere(data.region)
    setTabHemisphere( data.radioValue)
    props.handleDateChangeFn(payload)
  }
  const handleCustomerNameChange = (event, value) => {
    if (tabValue != 0) {
      setValue(0)
    }
    setCustomerNameSelected(value);
    const payload = {
      mainTab: parentGroupObj[tabValue],
      customerNameSelected: value,
      selectedDate: month,
      tabHemisphere :tabHemisphere,
      region :regionHemisphere,
      type : hemisphereType,
      receivableTabSelectorValue: props.receivableTabSelectorValue 
    }
    props.handleDateChangeFn(payload)

  }

  const handleLegacyTabView = () => {

    let NewBU = [
      { id: 'New BU 1', value: 'New BU 1' },
      { id: 'New BU 2', value: 'New BU 2' },
      { id: 'Unallocated', value: 'Unallocated' }
    ]

    let legacyBU = [
      { id: 'BU 1', value: 'BU 1' },
      { id: 'BU 2', value: 'BU 2' },
      { id: 'BU 3', value: 'BU 3' },
      { id: 'BU 4', value: 'BU 4' },
      { id: 'OH', value: 'OH' }
    ]

    let tabResponse = []

    if (props.receivableTabSelectorValue == 1) {

      tabResponse = NewBU.map((item, index) => {
        return (
          <LinkTab label={item.value} href={`/${item.id}`} className="list-nested" {...a11yProps(index)} />
        )
      })

    } else {

      tabResponse = legacyBU.map((item, index) => {
        return (
          <LinkTab label={item.value} href={`/${item.id}`} className="list-nested" {...a11yProps(index)} />
        )
      })
    }

    return tabResponse
  }

  const handleLegacyBUView = () => {

    if (props.receivableTabSelectorValue == 1) {
      return reverseNewBUnitIndex[externalTabValue]

    }else {
      return reverseBUnitIndex[externalTabValue]
    }

  }


  const secondaryTabPanel = () => {

    return (
      <>
        <Tabs
          value={externalTabValue}
          indicatorColor="primary"
          textColor="primary"
          className='mb-10'
          onChange={handleExternalTabChange}
        >
          {handleLegacyTabView()}
        </Tabs>
        <TabPanel value={externalTabValue} index={0}>
          <BusinessUnitComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            handleApplyFilter={handleApplyFilterFunc}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
            buValue={handleLegacyBUView()}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}
          />
        </TabPanel>
        <TabPanel value={externalTabValue} index={1}>
          <BusinessUnitComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            handleApplyFilter={handleApplyFilterFunc}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
            buValue={handleLegacyBUView()}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}

          />
        </TabPanel>
        <TabPanel value={externalTabValue} index={2}>
          <BusinessUnitComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            handleApplyFilter={handleApplyFilterFunc}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            buValue={handleLegacyBUView()}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}
          />
        </TabPanel>
        <TabPanel value={externalTabValue} index={3}>
          <BusinessUnitComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
            handleApplyFilter={handleApplyFilterFunc}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            buValue={handleLegacyBUView()}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}
          />
        </TabPanel>
        <TabPanel value={externalTabValue} index={4}>
          <BusinessUnitComponent
            externalfilterArr={props.externalfilterArr}
            externalFilterObject={props.externalFilterObject}
            receivableTabSelectorValue={props.receivableTabSelectorValue}
            handleApplyFilter={handleApplyFilterFunc}
            summaryRowValue={rowValue.length && rowValue[0]}
            handleSendComment={props.handleSendComment}
            buValue={handleLegacyBUView()}
            parentGroup={parentGroupObj[tabValue]}
            selectedMonthFilter={month}
          />
        </TabPanel>
      </>
    );
  }
  const arrayDate = props.reportDateArr
  const newArray = []
  const idx = arrayDate.length
  let Newidx = 0
  for (var keys in arrayDate) {
    Newidx++

    newArray.push(arrayDate[(idx - Newidx)])
  }
  return (
    <React.Fragment>
      <div className="flex ai-center jc-space-between mb-10">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2} className='maxw-200'>
            {(tabValue != 4)&&
              <FormControl className='w-200'>
                <Select
                  value={month}
                  onChange={handleMonthChange}
                  MenuProps={{ onExited: () => document.activeElement.blur() }}
                >
                  {newArray && newArray.length &&
                    newArray.map((item, index) => {
                      return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            }
          </Grid>
          <Grid item xs={12} sm={2} className='maxw-200' style={{ padding: '0', paddingBottom: '14px' }}>
            {
              <FormControl className='w-200'>
                <Autocomplete
                  multiple
                  limitTags={1}
                  value={customerNameSelected}
                  options={customerNameArr.sort()}
                  getOptionLabel={(option) => option}
                  onChange={handleCustomerNameChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search By Customer Name"
                      placeholder="Search By Customer Name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        id: 'field-1',
                      }}
                    />
                  )}
                  getOptionSelected={(option, val) => option == val}
                  renderTags={(value, { params, onDelete }) =>
                    value.map((option, index) => (
                      <Tooltip title={option}>
                        <Chip
                          {...params}
                          key={option}
                          label={option.length > 5 ?
                            option.substr(0, 3) + '..' :
                            option}
                          size="small"
                          onDelete={onDelete}
                        />
                      </Tooltip>
                    ))
                  }
                  renderOption={(option) => <Typography
                    style={{ fontSize: '10px' }}
                    noWrap>
                    <span>{option}</span>
                  </Typography>}
                />
              </FormControl>
            }

          </Grid>
          <Grid item xs={12} sm={2} className='maxw-200'>
              <Button className='button newButton btnBlue btnCompare uppercase' variant="contained" onClick={handleShowCompare}>Compare</Button>
            </Grid>
        </Grid>
        {(((tabValue == 0 && tabHemisphere != "hemisphere")&&(tabValue == 0 &&  tabHemisphere != "region")) || tabValue == 4  ) && <Tooltip title={'Download to PDF'} className='pointer'>
          <PictureAsPdfIcon fontSize="large" className='pointer'
            height='30'
            id='test_id_pdf'
            onClick={(event) => {
              event.preventDefault();
              printDocument();
            }} />

        </Tooltip>}
        <Tooltip title={'Download to Excell'}>
          <img
            src={DownloadExcel}
            height='30' 
            alt="download excel"
            className='ml-10 pointer'
            onClick={(event) => {
              event.preventDefault();
              handleDownloadExcel();
            }} />
        </Tooltip>
      </div>
      <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary" className='blockTabs mb-10'>
        <LinkTab value={0} label="Summary" href="/drafts" {...a11yProps(0)} />
        <LinkTab value={1} label="3rd Party" href="/trash" {...a11yProps(1)} />
        <LinkTab value={2} label="SLB" href="/spam" {...a11yProps(2)} />
        <LinkTab value={3} label="Unbilled" href="/slb" {...a11yProps(3)} />
        {props.receivableTabSelectorValue == 1 ? <LinkTab value={5} label="Contract Liability" {...a11yProps(5)} /> : ""}

        <LinkTab value={4} label="Monthly Rolling View" href="/monthly" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <SummaryComponent summaryBUClick={summaryScreenBUClick}
          customerNameSelected={customerNameSelected}
          receivableTabSelectorValue={props.receivableTabSelectorValue}
          loadSummary= {loadSummary}
          selectedMonthFilter = {month}
          radioHemisphareChanges = {radioHemisphareChanges}
          handleRegionHeaderClick = {handleRegionHeaderClick}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="tab-content">
        {secondaryTabPanel()}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {secondaryTabPanel()}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {secondaryTabPanel()}
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <MonthlyComponent 
         selectedMonthFilter={month}
         receivableTabSelectorValue={props.receivableTabSelectorValue}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <ContractComponent 
         receivableTabSelectorValue={props.receivableTabSelectorValue}
         externalfilterArr={props.externalfilterArr}
         externalFilterObject={props.externalFilterObject}
         handleApplyFilter={handleApplyFilterFunc}
         changesRadioCL={changesRadioCL}
         summaryRowValue={rowValue.length && rowValue[0]}
         handleSendComment={props.handleSendComment}
         parentGroup={parentGroupObj[tabValue]}
         selectedMonthFilter={month}
         />
      </TabPanel>
      <CompareModal 
         showModal={showCompareModal}
         handleClose={handleCompareClose}
        />
    </React.Fragment>
  );
}
export default Table;
