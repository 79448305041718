
import {transformFilterData, transformSimulationData} from './transformer'
import { format, parseJSON } from "date-fns";

const reducer = (state = {
   uploadFile: false,
   data: {},
   error: false,
   savePlanData: false,
   planBUData : {
      loading : false
   },
   actualBUData: {
      filter: {
         defaultDate: '',
         simulationArr: [],
         yearArr: [],
         qtrArr: [],
         monthArr: []
      },
      tableData: {
         loading: false,
         rowData: [],
         rowHeader: []
      },
      drilldownData: {
         loading: false,
         showDrilldown: false,
         rowData: [],
         rowHeader: []
      },
      simulation :{
         headerText : "",
         detailText : "",
         updatedOn : null,
      }
      
   },
   simulationData: {
      defaultData: {
         isFetched: false,
         rcpr: {},
         ior: {},
         ismr: {},
         icu: {},
         icoc: {}
      },
      simulationList: {
         rowData: [],
         rowHeader: []
      }
   }
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {

      case 'CHAT_UPLOAD_FILE_SUCCESS':
         newState.uploadFile = true;
         newState.data = action.data;
         return newState;

      case 'CHAT_UPLOAD_FILE_TOGGLE':
         newState.uploadFile = false;
         return newState;

      case 'FETCH_ACTUAL_BU_FILTER_SUCCESS':
         const {
            yearArr, qtrArr, monthArr, simulationArr
         } = transformFilterData(action.data)
         newState.loading = action.loading;
         newState.actualBUData.filter.yearArr = yearArr;
         newState.actualBUData.filter.simulationArr = simulationArr;
         newState.actualBUData.filter.qtrArr = qtrArr;
         newState.actualBUData.filter.monthArr = monthArr;
         newState.actualBUData.filter.defaultDate = monthArr?.[0]?.value;
         return newState;

      case 'FETCH_SIMULATION_VALUE_SUCCESS':
         const {
            rcpr, ior, ismr, icu, icoc
         } = transformSimulationData(action.data)
         newState.loading = action.loading;
         newState.simulationData.defaultData.rcpr = rcpr;
         newState.simulationData.defaultData.ior = ior;
         newState.simulationData.defaultData.ismr = ismr;
         newState.simulationData.defaultData.icu = icu;
         newState.simulationData.defaultData.icoc = icoc;
         newState.simulationData.defaultData.isFetched = true;

         return newState;
      case 'FETCH_SIMULATION_LIST_SUCCESS':
         newState.simulationData.simulationList.rowData = action.data.row_data;
         newState.simulationData.simulationList.rowHeader = action.data.row_header;
         return newState;
      case 'FETCH_ACTUAL_BU_TABLE_DATA_SUCCESS':
         newState.actualBUData.tableData.loading = false;
         newState.actualBUData.tableData.rowData = action.data.row_data;
         newState.actualBUData.tableData.rowHeader = action.data.row_header;
         newState.actualBUData.simulation.headerText = action.data.simulation.header_text;
         newState.actualBUData.simulation.updatedOn =  format(parseJSON(action.data.simulation.updated_on), "dd MMM yyyy hh:mm a") ;
         newState.actualBUData.simulation.detailText = action.data.simulation.detail_text;
         return newState;
      case 'FETCH_ACTUAL_BU_DRILLDOWN_DATA_LOADER':
         newState.actualBUData.drilldownData.loading = action.loading;
         newState.actualBUData.drilldownData.showDrilldown = false;
         return newState;

      case 'FETCH_ACTUAL_BU_DRILLDOWN_DATA_SUCCESS':
         newState.actualBUData.drilldownData.loading = false;
         newState.actualBUData.drilldownData.showDrilldown = true;
         newState.actualBUData.drilldownData.rowData = action.data.row_data;
         newState.actualBUData.drilldownData.rowHeader = action.data.row_header;
         return newState;
      case 'SAVE_BU_PLAN_DATA_SUCCESS': 
      newState.savePlanData = true;
      return newState;

      case 'UPLOAD_EP_FORECAST_LOADER': 
      newState.planBUData.loading = action.loading;
      return newState;

      case 'UPLOAD_EP_FORECAST_SUCCESS': 
      newState.planBUData.loading = false;
      return newState;

      case 'EDIT_BU_PLAN_DATA': 
      newState.savePlanData = false;
      return newState;

      case 'FETCH_ACTUAL_BU_TABLE_DATA_LOADER':
         newState.actualBUData.tableData.loading = action.loading;
      default:
         return newState;
   }
};
export default reducer;

