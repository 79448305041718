import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


function HighChart() {

  return (
      <div className="labelClass">
        <HighchartsReact 
          highcharts={Highcharts} 
          options={{
            credits: {
                enabled: false
            },
            chart: {
                type: 'column',
                height: 340,
            },
            title: {
                text: 'Cashflow from Operations',
                style: {
                    fontWeight: 'bold',
                    fontSize: '14px'
                }
            },
            xAxis: {
                categories: ['Current QTR', 'Next QTR'],
                labels: {
                    style: {
                        fontWeight: 'bolder'
                    }
                }
            },
            yAxis: {
                labels: {
                  enabled: false
                },
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bolder',
                            color: '#fff'
                        },
                        formatter: function() {return this.key},
                        inside: true,
                    },
                }
            },
                series: [
                {
                    keys: ['name', 'y', 'color'],
                    data: [
                        ['Actual', 3, "#f3b700"],
                        ['Predicted', 5, "#228b23"],
                    ]

                }
                ]
          }} />
      </div>
  )
}

export default HighChart;
