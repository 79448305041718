import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { CONFIG } from '../../../../config'
import './AddColumn.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


export default function SimpleModal(props) {
    const [inputValues, setInputValues] = useState(['']);
    const [mainHeaderValue, setMainHeaderValues] = useState('');

    const handleChange = (event, index) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = event.target.value;
        setInputValues(newInputValues);
    };

    const handleChangeMainHeader = (event) => {
        setMainHeaderValues(event.target.value);
    };

    const handleAddInput = () => {
        setInputValues([...inputValues, '']);
    };

    const handleRemoveInput = (index) => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    const handleSaveColumn = () => {
        const payload = {
            add_column: {
                header: mainHeaderValue,
                sub_header: inputValues.map(item => {
                    return {
                        value: item
                    }
                })
            }
        }
        props.handleSaveData(payload)
    }

    const body = (

        <div className='customModal'>
            <div className='header'>
                <h2>Add Columns</h2>
                <Icon onClick={() => { props.handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <div className="main-header">
                    <h2 className='main-header-heading'>Main Header</h2>
                    <div className='main-header-body'>
                        <input
                            type="text"
                            className='main-header-input'
                            value={mainHeaderValue}
                            onChange={(event) => handleChangeMainHeader(event)}
                        />
                        <AddCircleOutlineIcon fontSize="small" onClick={()=>{handleAddInput()}}/>
                    </div>
                    
                </div>
                <div className='sub-header'>
                <h2 className='sub-header-heading'>Sub Headers</h2>
                <div className="sub-header-list">
                {inputValues.map((value, index) => (
                        <div className='sub-header-item' key={index}>
                            <input
                                type="text"
                                className='sub-header-input'
                                value={value}
                                onChange={(event) => handleChange(event, index)}
                            />
                            <RemoveCircleOutlineIcon fontSize="small" onClick={() => handleRemoveInput(index)} />
                        </div>
                    ))}
                </div>
                    
                    
                </div>
            </div>
            <div className='button-row'>
                <Button disabled={mainHeaderValue === ''} className="btn btn-primary" onClick={handleSaveColumn}>Save</Button>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}