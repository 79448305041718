import React from "react";
// import TableComponent from './NewReactTable'
import Icon from '@material-ui/core/Icon';
import {
	handleTableTransform,
	numberWithCommas,
	checkNan,
} from '../../../../utils/numberUtils';
import ReactTableComponent from './pureTable'

let globalIndex = 0;

export default function SummaryTableComponent(props) {
	const { rowClick, handleDrilldownClick } = props
	const modifyNewColumnData = (columnData, operations) => {
		//  console.log(columnData,operations, "--ini props")

		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};

	const handleAppendPercentage = (data) => {
		if (data && data['backlog_margin_per']) {
			let dataField = data['backlog_margin_per'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};


	const newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
		if (data && data.length > 0) {
			return data.map((item, i) => {
				const tempItemData = {
					Header: item.label,
					id:
						item.key +
						'_' +
						parentData.key +
						'_' +
						subParentData.key +
						Math.random('92129911') +
						new Date().getTime(),
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				tempItemData.Cell = (props) => {
					const { row } = props;
					let showPercentage = handleAppendPercentage(row.original);
					let myClassName = showPercentage ? 'percentageRowData' : '';
					let showBorder = false
					let showDrillDown = false
					if (subParentData?.key == 'total' && item.key == 'total') {
						showBorder = true
					}

					// if (item.key === "data_field") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else {
					// return <span>{'lol'}</span>
					if (row.original.enabled_drill_down) {
						showDrillDown = true;
					}
					// console.log('row.originalrow.original', row.original, parentData, subParentData, item)
					return (
						<div
							className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{ borderRight: showBorder && '1px solid #423970', lineHeight: '40px' }}
							onClick={() => {
								if (showDrillDown) {
									// this.handleThreeLevelDrilldownClick(row.original, parentData, subParentData, item)
								}
							}}
						>
							{
								<span className={myClassName}>
									{row.original &&
										row.original.sub_column &&
										row.original.sub_column[parentData.key] &&
										row.original.sub_column[parentData.key]['sub_column'] &&
										row.original.sub_column[parentData.key]['sub_column'][
										subParentData.key
										]
										? modifyNewColumnData(
											row.original.sub_column[parentData.key]['sub_column'][
											subParentData.key
											][item.key],
											row.original['operations']
										)
										: '-'}
								</span>
							}
						</div>
					);
					// }
				};

				// tempItemData.Cell = ({row}) =>  <span>{row.original ? row.original.subColumn[parentData.key][subParentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

	const newChangeHeaderKeysLevel2 = (data, parentData, level1Index) => {
		if (data && data.length > 0) {
			return data.map((item, level2Index) => {
				let myClassNameHeader = ""

				const tempItemData = {
					Header: <div className={'ai-center jc-center ' + myClassNameHeader}>
						<div><span style={{ lineHeight: 1.5 }}>{item.label}</span></div>
						{item.total_amount && <div>{numberWithCommas(item.total_amount)}</div>}
						{/* {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />} */}
						{/* {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />} */}
					</div>,
					// item.label + item.total_amount,
					id: item.key + '_' + parentData.key,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel3(
						item.sub_headers,
						parentData,
						item
					);
				} else {
					// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

					tempItemData.Cell = (props) => {
						const { row } = props;
						// if (item.key === "data_field") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else {
						// return <span>{ 'pp'}</span>
						if (parentData.key.includes('comment')) {
							const commentData = row.original &&
								row.original.sub_column &&
								row.original.sub_column[parentData.key] &&
								row.original.sub_column[parentData.key]['sub_column'] &&
								row.original.sub_column[parentData.key]['sub_column'][item.key]
							return (
								<div
									style={{ 'color': '#c0b6ed' }}
									onClick={() => {
										// this.handleCommentShowClick(row.original, parentData, item, level1Index, level2Index)
									}}>
									<Icon
										color={'#c0b6ed'}
										style={{ 'color': '#c0b6ed !important' }}
									>
										remove_red_eye
									</Icon>
									<Icon
										color={'#c0b6ed'}
										style={{ 'color': '#c0b6ed !important' }}

									>
										add_circle_outline
									</Icon>
								</div>

							);

						}

						return (
							<span className={"pointer"} onClick={() => rowClick({ row, parentData, item, level1Index, level2Index })}>
								{row.original && row.original.sub_column && row.original.sub_column[parentData.key] && row.original.sub_column[parentData.key]['sub_column']
									?
									modifyNewColumnData(row.original.sub_column[parentData.key]['sub_column'][item.key], row.original['operations'])
									: ''}
							</span>
						);
						// }
					};
				}

				// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

	const newChangeKeysOfHeader = (data) => {
		if (data && data.length > 0) {
			return data.map((item, level1Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + globalIndex,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				globalIndex += 1;

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel2(
						item.sub_headers,
						item,
						level1Index
					);
				} else {
					if (item.key === 'hemisphere' || item.key === 'sub_region' || item.key === 'data_field' || item.key == 'new_bu') {
						tempItemData.sticky = 'left';
					}
					tempItemData.Cell = (props) => {
						const { row } = props;
						let showDrillDown = false
						if (!row?.original?.enable_drill_down) {
							showDrillDown = true;
						}

						if (item.key === 'hemisphere' || item.key === 'sub_region' || item.key === 'data_field' || item.key == 'new_bu') {
							return row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									<span
										className={
											row.isExpanded
												? 'rt-expanded-class'
												: 'rt-collapsed-class'
										}
									></span>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							) : (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							);

							// <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						} else if (
							item.key.includes('comment')) {
							return (
								<div
									style={{ 'color': '#c0b6ed' }}
									onClick={() => {
										// this.handleCommentShowClick(row.original, item)
									}}>
									<Icon
										color={'#c0b6ed'}
										style={{ 'color': '#c0b6ed !important' }}
									>
										remove_red_eye
									</Icon>
									<Icon
										color={'#c0b6ed'}
										style={{ 'color': '#c0b6ed !important' }}

									>
										add_circle_outline
									</Icon>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</div>
							);
						} else {
							return (
								<div
									className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''}`}
									onClick={() => {
										if (showDrillDown) {
											handleDrilldownClick(row.original, item)
										}
									}}
								>
									<span>
										{row && row.original && row.original[item.key]
											? modifyNewColumnData(
												row.original[item.key],
												row.original['operations']
											)
											: '-'}
									</span>
								</div>

							);
						}
					};
				}

				return tempItemData;
			});
		} else return [];
	};

	const handleDifferentBUResponse = (data, key) => {
		if (data) {
			if (key == 'data_field' || key == 'grand_total') {
				return data;
			} else {
				return data['header_key_value'] ? data['header_key_value'] : data
			}
		}
		return data
	}


	const changeKeysOfHeader = (data, isNested) => {
		if (data && data.length) {
			return data.map((item, i) => {
				if (item.key == 'data_field') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						className: isNested ? '' : 'noNesting',
						metaData: item.metadata,
						style: {
							borderRight: '1px solid #423970',
						},
						headerStyle: {
							overflow: 'visible',
							fontSize: '13px',
							fontWeight: 'bold',
							borderRight: '1px solid #423970'
						},
						getProps: (state, rowInfo, column) => {
							if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
								return { style: { background: '#d8dce6' } }
							}
							return {}
						},
						Cell: (props) => {
							let newOriginal = handleDifferentBUResponse(props.original, item.key)
							let zeroData = newOriginal[item.key]
							// let tooltipData = handleTooltipData(props)
							return (
								<div>
									<span>{newOriginal[item.key]}</span>
								</div>
							)
						}
					}
				}


				return {
					Header: item.label,
					id: item.key,
					className: isNested ? '' : 'noNesting',
					metaData: item.metadata,
					style: {
						borderRight: '1px solid #423970',
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						borderRight: '1px solid #423970'
					},
					Cell: (props) => {

						let newOriginal = handleDifferentBUResponse(props.original, item.key)
						let zeroData = newOriginal[item.key]
						let showDrillDown = false
						if (props?.original?.enabled_drill_down) {
							showDrillDown = true;
						}

						return (
							<div
								className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''}`}
								onClick={() => {
									if (showDrillDown) {
										handleDrilldownClick(props.original, item)
									}
								}}
							>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</div>

						)
					}
				}
			})
		}
		return []
	}


	const { rowData, rowHeader, loading } = props.tableData
	const newPivotHeaderData = changeKeysOfHeader(rowHeader);


	return (
		<div style={{maxHeight: '500px', overflow:'scroll'}}>
			<ReactTableComponent
				pivotRowData={rowData}
				className='xpna-reactFixed-table expandableRows'
				loading={loading}
				pivotHeaderData={newPivotHeaderData}
				tableLen={rowData.length}
			// subComponent={this.subComponent}
			// expandIcon={this.expandIcon}
			// rowClick={this.rowClick}
			// showInvoiceDetailModal={showInvoiceDetailModal}
			// showLoader={showLoader}
			/>
			{/* <TableComponent
				data={rowData}
				className={'newCustomTable fixedFirstColumTableSummaryView expandableRows'}
				loading={loading}
				columns={newPivotHeaderData}
			/> */}
		</div>
	)
}