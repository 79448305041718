import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import history from '../../../../../history'
import { changeCollectionInvoiceForecastData, changeValuesOfData, setAllRowCommentHistoryData, appendChannelId, changeCollectionForecastData} from './transformer'
import { getAllCommentHistoryData } from '../../../../../utils/commentDataUtil'
import {
    fetchInvoiceReportDataService,
    fetchAllCommentHistoryService,
    createChannelService,
    fetchOneCommentHistoryService,
    sendCollectionForecastService,
    sendCollectionForecastInvoiceService,
    updateActionOwnerService,
    getDayFilterService,
    getInvoiceListFilterService,
    getReceivablesTornadoDataAction,
    performFetchDropDown,
    updateDropDownService
} from './service'
import { store } from '../../../../../index'

function* fetchInvoiceReportData(data) {
    if(data.payload.export == false || !data.payload.export){
        yield put({ type: "FETCH_DAY_REPORT_LOADER", loading: true });
    }else{
        yield put({ type: "FETCH_DAY_REPORT_LOADER_EXPORT", export: true });
    }
    console.log(data.payload, "cekPayload")
    const state = store.getState();

    const response = yield call(fetchInvoiceReportDataService, data.payload);

    // console.log(response, "fetchInvoiceReportDataService")
    const { dashboardState: { receivableData: { ageingData } } } = state;
    const { activePage } = ageingData.external;

    if (response && response.status == 200) {
        const responseDropdown = yield call(performFetchDropDown, data.payload);
        // response.data.row_data = changeValuesOfData(response.data.row_data)
        // const getCommentPayload =  getAllCommentHistoryData(activePage, response.data.row_data)
if(data.payload.export == false || !data.payload.export){

    yield put({ type: "FETCH_INVOICE_REPORT_DATA_SUCCESS", loading: false, data: response.data, dataDropdown : responseDropdown.data });
}else if(data.payload.export == true){
    yield put({ type: "FETCH_INVOICE_REPORT_DATA_EXPORT_SUCCESS", export: false, data: response.data });
  
}

    }

}

function* fetchCommentAllChannelHistory(data) {

    try {

        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(fetchAllCommentHistoryService, data.payload);

        console.log(response, "fetchCommentAllChannelHistory")

        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, response.data.chat)
            yield put({ type: "FETCH_COMMENT_HISTORY_DAY_ALL_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}

function* createChannelId(data) {

    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        yield put({type: 'CHANGE_CREATE_CHANNEL_FLAG', payload: false})

        const response = yield call(createChannelService, data.payload);

        if (response && response.status == 200) {
            const transformedData = appendChannelId(tableRowData,data. payload.invoice_no, response.data)
            yield put({ type: "CREATE_CHANNEL_DAY_SUCCESS", payload: true, data: transformedData.transformedArr, updatedItem: transformedData.updatedItem  });
        }

    } catch (error) {
        console.log(error);
    }

}

function* fetchOneCommentChannelHistory(data) {

    try {

        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(fetchOneCommentHistoryService, data.payload);

        console.log(response, "fetchOneCommentHistoryService")

        if (response && response.status == 200) {
            const transformResponse = {
                [data.payload.channelId] : response.data
            }
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, transformResponse)
            yield put({ type: "FETCH_COMMENT_HISTORY_ALL_DAY_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}

function* sendCollectionForecastInvoce(data) {
    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(sendCollectionForecastInvoiceService, data.payload);
        console.log(response, "sendCollectionForecastService")


        if (response && response.status == 200) {
            const updatedRowData = changeCollectionInvoiceForecastData(tableRowData, data.payload)
            yield put({ type: "SEND_COLLECTION_FORECAST_DAY_SUCCESS", data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }
}

function* sendCollectionForecast(data) {
    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(sendCollectionForecastService, data.payload);
        console.log(response, "sendCollectionForecastService")


        if (response && response.status == 200) {
            const updatedRowData = changeCollectionForecastData(tableRowData, data.payload)
            yield put({ type: "SEND_COLLECTION_FORECAST_DAY_SUCCESS", data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }
}

function* updateActionOwner(data) {
    try {
        yield call(updateActionOwnerService, data.payload);
        
    } catch (error) {
        console.log(error);
    }
}

function* updateDropDown(data) {
    // try {
        yield put({ type: "FETCH_DAY_REPORT_LOADER", loading: true });
        const response =  yield call(updateDropDownService, data.payload);
        if (response && response.status == 200) {
            yield put({ type: "FETCH_DAY_REPORT_LOADER", loading: false });
        }
    // } catch (error) {
    //     console.log(error);
    // }
}
function* fetchInvoiceFiltersData(data) {

    const state = store.getState();

    data.payload['report_date'] = state.dashboardState.selectedDate

    const response = yield call(getInvoiceListFilterService, data.payload);


    if (response && response.status == 200) {
        yield put({ type: "FETCH_INVOICE_FILTER_DATA_SUCCESS", data: response.data.filter });
    }

}

function* getReceivablesTornadoData(data){
    yield put({ type: "FETCH_TORNADO_DATA_MONTHLY_REQUEST"});

    const response = yield call(getReceivablesTornadoDataAction, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_TORNADO_DATA_MONTHLY_SUCCESS", data: response.data });
    }
}

export default function* actionWatcher() {
     yield takeLatest('FETCH_INVOICE_LIST_REPORT_DATA', fetchInvoiceReportData)
     yield takeLatest('FETCH_COMMENT_CHANNEL_DAY_DATA_HISTORY', fetchCommentAllChannelHistory)
     yield takeLatest('CREATE_CHANNEL_DAY_ID', createChannelId)
     yield takeLatest('FETCH_COMMENT_ONE_CHANNEL_DAY_DATA_HISTORY', fetchOneCommentChannelHistory)
     yield takeLatest('SEND_COLLECTION_FORECAST_DAY', sendCollectionForecast)
     yield takeLatest('SEND_COLLECTION_FORECAST_INVOICE_DAY', sendCollectionForecastInvoce)
     yield takeLatest('UPDATE_ACTION_OWNER_DAY', updateActionOwner)
     yield takeLatest('SAVE_DROPDOWN_CHANGES', updateDropDown)
     yield takeLatest('FETCH_INVOICE_LIST_FILTER_DATA', fetchInvoiceFiltersData)
     yield takeLatest('FETCH_TORNADO_DATA_MONTHLY', getReceivablesTornadoData)
}