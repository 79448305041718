import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HighchartOptions from "./highcharts.options";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CHART_COLORS } from '../../../constant';

export default function Growth (){
const {analyticsModule: { growthAnalysis: {newOrdersColor, revenueColor} }} = CHART_COLORS;
const seriesData= [15,21,16,25,15,18,19,17,16,20,22,19];
const seriesData1 = [13,19,14,29,19,12,25,15,15,25,11,15];
let subTitle = {}

let windowSize = window.innerWidth

if(windowSize <= 667){
    subTitle = {
                    text :' Growth Rate : New Orders- XX % <br/> Revenue- XX % ',
                    y: 40,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}else{
    subTitle = {
                    text :' Growth Rate : New Orders- XX % <br/> Revenue- XX % ',
                    align: 'right',
                    x: -40,
                    y: 80,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}
return(
    <div className="px-5">
           <Card>
             <CardContent>   
            <HighchartsReact
                highcharts={Highcharts}
                options = {
                    {
                        chart: {
                            height: 440,
                        },
                        colors: [
                            newOrdersColor, 
                            revenueColor,                           
                        ],
                        title: {
                            text: 'Growth Analysis',
                        },
                        subtitle : subTitle,
                        credits: {
                            enabled: false
                        },
                        xAxis: {
                            title:{
                                text:"Months",
                                offset: 30,
                            },
                            visible:true,
                            categories: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                            zoomEnabled: false,
                            
                        },
                        yAxis:[            
                            {
                            title: {
                                text: '$M',                              
                            },
                            max:50,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            visible:true,
                            tickInterval:10,
                        }],
                        plotOptions: {
                            series: {
                                pointPadding: 0,
                                groupPadding: 0.2,
                                // borderWidth: 0.3,
                                shadow: false
                            }
                        },
                        tooltip: {
                          
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            // labelFormatter: function () { 
                            //     var x ;
                            //     if (this.name == 'Revenue'){
                            //          x = this.name  + ' &nbsp &nbsp &nbsp' + ' - Growth Rate : <br/> New Orders: XX% <br/> Revenue- XX% '
                            //     }else{
                            //         x = this.name;
                            //     }
                                
                            //     return x; 
                            // } 
                        },
                        series: [{
                            type: 'column',
                            data: seriesData,
                            name: 'New Orders',
                        },{
                            type: 'column',
                            data: seriesData1,
                            name: 'Revenue'
                            
                        },]
                    }
                }
            />
            </CardContent>
         </Card> 
        </div>
   )

}