import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
//import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import './AddVariance.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Autocomplete, TextField } from '@mui/material';

const Dropdown = ({ options, handleChange }) => {
    return (
        <select className='dropdown-select' onChange={handleChange}>
            {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};


const Dropdown2 = ({menuItems, handleChange}) =>{
    return(
        // <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
        //     <InputLabel>Select</InputLabel>
        //     <Select 
        //         className='dropdown2-select'
        //         onChange={handleChange}
        //         label='Select'
        //     >
        //         <MenuItem value="Select">Select</MenuItem>
        //         {menuItems.map((item, index) => {
        //             return <MenuItem value={item} key={index}>{item}</MenuItem>
        //         })}
        //     </Select>
        // </FormControl>
        <Autocomplete
            disablePortal
            disableClearable
            fullWidth
            className='xpna-dropdown single'
            size='small'
            options={menuItems}
            onChange={handleChange}
            renderInput={(params) => (
                    <TextField {...params} variant='standard' placeholder='Select' />
                )
            }
        />
    );
};

const Dropdown3 = ({menuItems, handleChange, selectedValue}) =>{
    return(
        // <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
        //     <InputLabel>Select</InputLabel>
        //     <Select 
        //         className='dropdown2-select'
        //         onChange={handleChange}
        //         label='Select'
        //     >
        //         <MenuItem value="Select">Select</MenuItem>
        //         {menuItems.map((item, index) => {
        //             return <MenuItem disabled={item.key === selectedValue} value={item.key} key={index}>{item.label}</MenuItem>
        //         })}
        //     </Select>
        // </FormControl>
        <Autocomplete
            disablePortal
            disableClearable
            fullWidth
            className='xpna-dropdown single'
            size='small'
            options={menuItems}
            getOptionDisabled={(option)=> (option.key === selectedValue)}
            onChange={handleChange}
            renderInput={(params) => (
                    <TextField {...params} variant='standard' placeholder='Select' />
                )
            }
        />
    );
};



export default function SimpleModal(props) {
    const [dropdownCount, setDropdownCount] = useState(1);
    const [subHeaderWithData, setSubHeaderWithData] = useState(['']);
    const [subHeaderWhatData, setSubHeaderWhatData] = useState(['']);
    const [allFunctions, setAllFunctions] = useState([]);
    const [whatSubFunctions, setWhatSubFunctions] = useState([]);
    const [withSubFunctions, setWithSubFunctions] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState('');
    const [compareWhatOption, setCompareWhatOption] = useState('');
    const [compareWithOption, setCompareWithOption] = useState('');
    const {pivotHeaderData} = props;

    React.useEffect(() => {
		createFunctionData();
	}, [pivotHeaderData]);

    const createFunctionData = () => {
        const functionData = pivotHeaderData.filter(item => item.comparable)
        setAllFunctions(functionData)
        console.log(functionData, 'functionData')
    }

    const handleCompareWhatDropdownChange = (value) => {
        setCompareWhatOption(value);
        const subFunction = allFunctions.filter(item => item.key === value)
        console.log(subFunction)
        setWhatSubFunctions(subFunction?.[0])
    };

    const handleCompareWithDropdownChange = (value) => {
        setCompareWithOption(value)
        const subFunction = allFunctions.filter(item => item.key === value)
        setWithSubFunctions(subFunction?.[0])
    };

    const handleFunctionChange = (value) => {
        setSelectedFunction(value);
    };

    const handleAddDropdown = () => {
        setDropdownCount(dropdownCount + 1);
    };

    const handleWhatChange = (index, event) => {
        const newInputValues = [...subHeaderWhatData];
        newInputValues[index] = event;
        setSubHeaderWhatData(newInputValues);
    };

    const handleWithChange = (index, event) => {
        const newInputValues = [...subHeaderWithData];
        newInputValues[index] = event;
        setSubHeaderWithData(newInputValues);
    };

    const createSubHeaderPayload = () => {
        const count = subHeaderWhatData.length > subHeaderWithData.length ? subHeaderWhatData.length : subHeaderWhatData.length;
        const subHeaderArray = [];
        for (let i = 0; i < count; i++) {
            subHeaderArray.push({
                sub_header_left: subHeaderWhatData[i],
                sub_header_right: subHeaderWithData[i],
            })
        }

        return subHeaderArray
    }

    const handleSaveColumn = () => {
        const subHeader = createSubHeaderPayload()
        const payload = {
            add_function: {
                function: selectedFunction,
                header_left: compareWhatOption,
                header_right: compareWithOption,
                details: subHeader
            }
        }

        if (subHeader.length) {
            props.handleSaveData(payload)
        }

    }

    const body = (

        <div className='customModal'>
            <div className='header'>
                <h2>Add Function</h2>
                <Icon onClick={() => { props.handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <div className="main-header-div">
                    <h2 className='main-header'>Choose Function</h2>
                    <div className='dropdown-div' style={{width:'200px'}}>
                        <Dropdown2
                            key={'index'}
                            menuItems={['Variance']}
                            handleChange={(e, value) => handleFunctionChange(value)}
                        />
                    </div>
                </div>
                <div className='sub-section'>
                    <div className='compare-what-section' style={{width:'40%'}}>
                        <h2 className='text-center'>Compare What</h2>
                        <div className="sub-header-div" style={{width:'50%'}}>
                                <Dropdown3
                                    menuItems={allFunctions}
                                    handleChange={(e, value) => handleCompareWhatDropdownChange(value)}
                                    selectedValue={compareWithOption}

                                />
                        </div>
                        {whatSubFunctions?.sub_headers?.length > 0 && 
                        <div className='sub-header-list'>
                            {whatSubFunctions?.sub_headers.map((subItem, index) => (
                                <Dropdown3
                                    key={subItem._id}
                                    menuItems={whatSubFunctions?.sub_headers}
                                    handleChange={(e, value) => handleWhatChange(index, value)}
                                />
                            ))}
                            <div>
                            </div>
                        </div>}
                    </div>
                    <div className='compare-with-section' style={{width:'40%'}}>
                        <h2 className='text-center'>Compare With</h2>
                        <div className="sub-header-div" style={{width:'50%'}}>
                                <Dropdown3
                                    menuItems={allFunctions}
                                    handleChange={(e, value) => handleCompareWithDropdownChange(value)}
                                    selectedValue={compareWhatOption}
                                />
                        </div>
                        {withSubFunctions?.sub_headers?.length > 0 && 
                        <div className='sub-header-list'>
                            {withSubFunctions?.sub_headers.map((subItem, index) => (
                                <Dropdown3
                                    key={subItem._id}
                                    menuItems={withSubFunctions?.sub_headers}
                                    handleChange={(e, value) => handleWithChange(index, value)}
                                />
                            ))}
                            <div>
                            </div>
                        </div>}
                    </div>
                </div>


            </div>
            <div className='button-row'>
                <Button className="btn btn-primary"
                onClick={handleSaveColumn}>
                    Save
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}