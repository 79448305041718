import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import AgingFunctionalComponent from './AgingFunctionalComponent';
import ErrorComponent from '../../common/components/ErrorPage';
import {
  downloadExcel,
  downloadExcelV1,
  mapDataPDF,
  mapExcelDataV1,
} from '../../../utils/exportExcel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {formatDate} from '../../../utils/dateFormat';
import {format} from 'date-fns/esm';

export class Ageing extends React.Component {

  constructor(props) {
    super(props);
    const {state} = this.props.location;
    this.currentDate = '';

    let stateData = state;

    let newTabData = localStorage.getItem('newTabData');
    if (newTabData){
      newTabData = JSON.parse(newTabData)
      stateData = newTabData
      this.props.updateSelectedDate(newTabData.selectedDate);
    }

    this.reverseParentGroupObj = {
      'summary': 0,
      'external': 1,
      'slb': 2,
      'unbilled': 3,
      'monthly': 4,
    };

    this.state = {
      externalfilterArr: [],
      hasFilterData: false,
      defaultTab: stateData ?
          this.reverseParentGroupObj[stateData.parent_group.toLowerCase()] :
          0,
      externalFilterObject: {
        action_owner: [],
        aging_category: [],
        category: '',
        receivableTabSelectorValue: this.props.tabSelectorValue,
        customer_name: stateData ? [stateData.customer_name] : [],
        customer: stateData && stateData.customer ? [stateData.customer] : [],
        skip: 0,
        selectedDate: stateData && stateData.selectedDate ? stateData.selectedDate : undefined,
        limit: 60,
        bu: stateData ? stateData.bu.toLowerCase() : 'BU 1',
        parent_group: 'external',
      },
      selectedBu: 'BU 1',
    };
  }

  componentDidMount() {

    let payload = {
      action_owner: [],
      aging_category: [],
      category: '',
      customer_name: [],
      skip: 0,
      limit: 60,
      bu: 'BU 1',
      receivableTabSelectorValue: this.props.tabSelectorValue,
      parent_group: 'external',
    };
    const {state} = this.props.location;
    let parentGroup = 'external';

    let stateData = state;

    let newTabData = localStorage.getItem('newTabData');
    if (newTabData){
      newTabData = JSON.parse(newTabData)
      stateData = newTabData
    }
    localStorage.removeItem('newTabData')
    if (stateData) {
      payload.customer_name = [stateData.customer_name];
      payload.bu = stateData.bu.toLowerCase();
      parentGroup = stateData.parent_group.toLowerCase();
      payload.parent_group = parentGroup;
      payload.selectedDate = stateData && stateData.selectedDate ? stateData.selectedDate : undefined;
    }

    this.setState({
      defaultTab: stateData ? this.reverseParentGroupObj[parentGroup] : 0,
      externalFilterObject: payload,
    });
    this.props.getPipMasterData({bu: payload.bu});
    this.props.getAllUser({});
    this.props.getFilterData(payload);

  }

  componentDidUpdate() {
    const {hasFilterData} = this.state;
    const {externalData} = this.props;
    const {external} = this.props.ageingData;
    if (!hasFilterData && external.fetched) {
      const filterArr = externalData && externalData.filterObj &&
          externalData.filterObj.data;
      this.setState({externalfilterArr: filterArr, hasFilterData: true});
    }
  }

  handleApplyFilter = (payload) => {
    this.setState({externalFilterObject: {...payload}}, () => {
      this.props.setSelectedFilter(payload);
      this.props.getSummaryBuData(payload);
      this.props.getMSMDetail(payload);
    });
  };

  handleSendComment = (commentPayload) => {
    const {externalFilterObject} = this.state;
    let transformSendComment = {
      commentPayload,
      filterPayload: externalFilterObject,
    };
    this.props.sendCommentAction(transformSendComment);
  };

  handleDateChangeFn = (payload) => {
    this.props.updateSelectedDate(payload.selectedDate);
    this.currentDate = payload.selectedDate;
    if (payload.mainTab =='summary' && payload.tabHemisphere != 'summary') {
      let payloads = {
            report_date :payload.selectedDate,
            tabHemisphere : payload.tabHemisphere,
            export: payload.export,
            type: payload.type,
            customer : payload.customerNameSelected,
            region : payload.region? payload.region.label : "",
        }
        this.props.getNewComponent(payloads)
    }
    if (payload.tabValue == '5' && payload.radioCL == "Summary") {
      let payloads = {
        report_date : payload.selectedDate,
        receivableTabSelectorValue : payload.radioCL == "Summary" ? 1 : 2,
        view : "billing"
    }
    this.props.getContractLiability(payloads)
    }
    if (payload.tabValue == '5' && payload.radioCL != "Summary") {
      payload.secondaryTab = "PAB"
    }
    if (payload.mainTab == 'summary') {
      const {externalFilterObject} = this.state;
      if (payload && payload.customerNameSelected){
        externalFilterObject['customer_name'] = [payload.customerNameSelected]
        this.setState({externalFilterObject});
      }

      this.props.getSummaryDetail(payload);
    }  else {
      const {externalFilterObject} = this.state;
      // let modExternalFilterObject = JSON.parse(JSON.stringify(externalFilterObject))
      externalFilterObject['bu'] = payload.secondaryTab;
      externalFilterObject['parent_group'] = payload.mainTab;
      externalFilterObject['export'] = payload.export;
      const {state} = this.props.location;
      let stateData = state;

      let newTabData = localStorage.getItem('newTabData');
      if (newTabData) {
        newTabData = JSON.parse(newTabData)
        stateData = newTabData
        this.props.updateSelectedDate(newTabData.selectedDate);
      }

      if (stateData){
      }else {
        externalFilterObject['customer_name'] = [];

      }
      this.setState({externalFilterObject});
      this.props.setSelectedFilter(externalFilterObject);
      this.props.getSummaryBuData(externalFilterObject);
      this.props.getMSMDetail(externalFilterObject);
      this.props.getFilterData(externalFilterObject);
    }
  };

  mapExcelDataSummary = (data) => {
    data = data.map(x => {
      delete x.overdue_amount;
      switch (x.parent_group) {
        case 'external':
          x.parent_group = '3rd Party';
          break;
        case 'slb':
          x.parent_group = 'SLB';
          break;
        case 'unbilled':
          x.parent_group = 'Unbilled';
          break;
        case 'recievables':
          x.parent_group = 'Total by BU';
          break;
      }
      return x;
    });
    return data;
  };

  mapUser = (data) => {
    let userData = this.props.externalData.allUser;
    data = data.map(x => {
      let label = userData.find(y => {
        return y.login_id == x.action_owner;
      });

      if (label != undefined)
        x.action_owner = label.full_name;

      label = userData.find(y => {
        return y.login_id == x.collection_forecast_confirmed_by;
      });

      if (label != undefined)
        x.collection_forecast_confirmed_by = label.full_name;

      return x;
    });
    return data;
  };
  unwindHeaderKeyVal = (data) => {
    data.forEach(x => {
      var obj = x.header_key_value;
      if (obj != undefined) {
        for (var prop in obj) {
          x[prop] = obj[prop];
        }
      }
    });
    return data;
  };
  printDocument = (tabValue) => {
    const pdf = new jsPDF('l', 'pt', 'letter');
    
      let ex = this.props.ageingData.summary.externalData;
      let inter = this.props.ageingData.summary.internalData;
      let total = this.props.ageingData.summary.totalData;
      let unbil = this.props.ageingData.summary.unbilledData;
      let header = ex.row_header.map(x => {
        return x.label;
      });
      let pivotHeaderData = ex.row_header;
      let dataEx = this.unwindHeaderKeyVal(ex.row_data);
      dataEx = mapDataPDF(dataEx, 'id', pivotHeaderData);
      // let dataEx = this.mapExcelDataSummary(ex.row_data)
      let dataInter = this.unwindHeaderKeyVal(inter.row_data);
      dataInter = mapDataPDF(dataInter, 'id', pivotHeaderData);
  
      let dataTotal = this.unwindHeaderKeyVal(total.row_data);
      dataTotal = mapDataPDF(dataTotal, 'id', pivotHeaderData);
  
      let dataUnbill = this.unwindHeaderKeyVal(unbil.row_data);
      dataUnbill = mapDataPDF(dataUnbill, 'id', pivotHeaderData);
      let page = 'xP&A Demo-Receivables-Reports-Aging Reports-Summary';
    if(tabValue == 4) {
      ex = this.props.ageingData.summaryMonthly.externalData;
      inter = this.props.ageingData.summaryMonthly.internalData;
       unbil = this.props.ageingData.summaryMonthly.unbilledData;
     header = ex.row_header.map(x => {
        return x.label;
      });
      pivotHeaderData = ex.row_header;
      dataEx = this.unwindHeaderKeyVal(ex.row_data);
      dataEx = mapDataPDF(dataEx, 'id', pivotHeaderData);
      // let dataEx = this.mapExcelDataSummary(ex.row_data)
      dataInter = this.unwindHeaderKeyVal(inter.row_data);
      dataInter = mapDataPDF(dataInter, 'id', pivotHeaderData);
  
  
      dataUnbill = this.unwindHeaderKeyVal(unbil.row_data);
      dataUnbill = mapDataPDF(dataUnbill, 'id', pivotHeaderData);
      page = 'xP&A Demo-Receivables-Reports-Aging Reports-Monthly Rolling View';
    }
   
   
    let rows = [];
    let i = 0;
    header.forEach(x => {
      rows.push({
        content: x,
        styles: {
          halign: 'center',
          fillColor: [223, 217, 251],
          textColor: [131, 6, 206],
        },
      });
    });
    let heads = [
      rows,
    ];
    let rowsDataFix = [];
    let dataCount = 0;
    dataEx.forEach(x => {
      let i = 0;
      let rowsData = [];
      let dataField = '';
      // console.log(x, "ini datax")
      x.forEach(y => {
        if (i == 0) {
          dataField = y;
          if (dataField == 'Grand Total') {
            rowsData.push({content: y, styles: {fontStyle: 'bold'}});
          } else {
            rowsData.push({content: y});
          }

        } else {
          if (y.includes('%')) {
            if (y.includes('-')) {
              y = y.replace('%', '');
            }
            rowsData.push({
              content: y,
              styles: {halign: 'center', fillColor: [223, 217, 251]},
            });
          } else {
            if (dataField == 'Grand Total') {
              rowsData.push(
                  {content: y, styles: {halign: 'center', fontStyle: 'bold'}});
            } else {
              rowsData.push({content: y, styles: {halign: 'center'}});
            }

          }

        }
        i++;

      });
      dataCount += 1;
      rowsDataFix.push(rowsData);
    });
    let LastY = pdf.lastAutoTable.finalY;
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',

      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text(page, data.settings.margin.left + 5, 30);

        //   let  finalY = pdf.lastAutoTable.finalY + 50; // The y position on the page
        pdf.setFontSize(11);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text('3RD PARTY RECEIVABLES', data.settings.margin.left + 15, 70);

        // Footer
        var str = 'Page ' + pdf.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ?
            pageSize.height :
            pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
        4: {fontStyle: 'bold'},
      },
      margin: {top: 80},
    });
    rowsDataFix = [];
    dataCount = 0;
    dataInter.forEach(x => {
      let i = 0;
      let rowsData = [];
      // console.log(x, "ini datax")
      let dataField = '';
      // console.log(x, "ini datax")
      x.forEach(y => {
        if (i == 0) {
          dataField = y;
          if (dataField == 'Grand Total') {
            rowsData.push({content: y, styles: {fontStyle: 'bold'}});
          } else {
            rowsData.push({content: y});
          }

        } else {
          if (y.includes('%')) {
            if (y.includes('-')) {
              y = y.replace('%', '');
            }
            rowsData.push({
              content: y,
              styles: {halign: 'center', fillColor: [223, 217, 251]},
            });
          } else {
            if (dataField == 'Grand Total') {
              rowsData.push(
                  {content: y, styles: {halign: 'center', fontStyle: 'bold'}});
            } else {
              rowsData.push({content: y, styles: {halign: 'center'}});
            }

          }

        }
        i++;

      });
      dataCount += 1;
      rowsDataFix.push(rowsData);
    });
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      startY: typeof pdf.autoTable.previous.finalY === 'undefined' ?
          80 :
          pdf.autoTable.previous.finalY + 30,
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        //   pdf.text("Report " + page, data.settings.margin.left + 15, 30);

        let finalY = pdf.lastAutoTable.finalY + 20; // The y position on the page
        pdf.setFontSize(11);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text('SLB RECEIVABLES', data.settings.margin.left + 15, finalY);
        // Footer
        var str = 'Page ' + pdf.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ?
            pageSize.height :
            pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },

      styles: {
        lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
      },
      margin: {top: 50},
    });
    rowsDataFix = [];
    dataCount = 0;
    dataUnbill.forEach(x => {
      let i = 0;
      let rowsData = [];
      // console.log(x, "ini datax")
      let dataField = '';
      // console.log(x, "ini datax")
      x.forEach(y => {
        if (i == 0) {
          dataField = y;
          if (dataField == 'Grand Total') {
            rowsData.push({content: y, styles: {fontStyle: 'bold'}});
          } else {
            rowsData.push({content: y});
          }

        } else {
          if (y.includes('%')) {
            if (y.includes('-')) {
              y = y.replace('%', '');
            }
            rowsData.push({
              content: y,
              styles: {halign: 'center', fillColor: [223, 217, 251]},
            });
          } else {
            if (dataField == 'Grand Total') {
              rowsData.push(
                  {content: y, styles: {halign: 'center', fontStyle: 'bold'}});
            } else {
              rowsData.push({content: y, styles: {halign: 'center'}});
            }

          }

        }
        i++;

      });
      dataCount += 1;
      rowsDataFix.push(rowsData);
    });
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      startY: typeof pdf.autoTable.previous.finalY === 'undefined' ?
          80 :
          pdf.autoTable.previous.finalY + 30,
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        //   pdf.text("Report " + page, data.settings.margin.left + 15, 30);
        let finalY = pdf.lastAutoTable.finalY + 20; // The y position on the page
        pdf.setFontSize(11);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text('UNBILLED', data.settings.margin.left + 15, finalY);
        // Footer
        var str = 'Page ' + pdf.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ?
            pageSize.height :
            pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
      },
      margin: {top: 50},
    });
    rowsDataFix = [];
    dataCount = 0;
    dataTotal.forEach(x => {
      let i = 0;
      let rowsData = [];
      // console.log(x, "ini datax")
      let dataField = '';
      // console.log(x, "ini datax")
      x.forEach(y => {
        if (i == 0) {
          dataField = y;
          if (dataField == 'Grand Total') {
            rowsData.push({content: y, styles: {fontStyle: 'bold'}});
          } else {
            rowsData.push({content: y});
          }

        } else {
          if (y.includes('%')) {
            if (y.includes('-')) {
              y = y.replace('%', '');
            }
            rowsData.push({
              content: y,
              styles: {halign: 'center', fillColor: [223, 217, 251]},
            });
          } else {
            if (dataField == 'Grand Total') {
              rowsData.push(
                  {content: y, styles: {halign: 'center', fontStyle: 'bold'}});
            } else {
              rowsData.push({content: y, styles: {halign: 'center'}});
            }

          }

        }
        i++;

      });
      dataCount += 1;
      rowsDataFix.push(rowsData);
    });
    if(tabValue == 0) {
    pdf.autoTable({
      head: heads,
      body: rowsDataFix,
      theme: 'grid',
      startY: pdf.autoTable.previous.finalY + 100,
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        // pdf.text("Report " + page, data.settings.margin.left + 15, 30);
        let finalY = pdf.autoTable.previous.finalY; // The y position on the page
        pdf.setFontSize(11);
        pdf.setTextColor(40);
        pdf.setFontStyle('sans-serif');
        pdf.text('TOTAL BY BU', data.settings.margin.left + 15, 40);
        // Footer
        var str = 'Page ' + pdf.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str;
        }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = pdf.internal.pageSize;
        var pageHeight = pageSize.height ?
            pageSize.height :
            pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {
        lineWidth: 1,
        lineColor: [38, 38, 38],
        width: 'auto',
      },
      margin: {top: 50},
    });
  }
    let FileName = page + '_' + format(new Date, 'MM-dd-yyyy HH-mm-ss');
    pdf.save(FileName + '.pdf');

  };

  handleDownloadExcel = (tabValue) => {

    if (tabValue == 0) {
      let ex = this.props.ageingData.summary.externalData;
      let inter = this.props.ageingData.summary.internalData;
      let total = this.props.ageingData.summary.totalData;
      let unbil = this.props.ageingData.summary.unbilledData;
      let header = ex.row_header.map(x => {
        return x.label;
      });
      let dataEx = this.mapExcelDataSummary(ex.row_data);
      let dataInter = this.mapExcelDataSummary(inter.row_data);
      let dataTotal = this.mapExcelDataSummary(total.row_data);
      let dataUnbill = this.mapExcelDataSummary(unbil.row_data);
      let data = dataEx.concat(dataInter).concat(dataUnbill).concat(dataTotal);
      data = data.map(x => {
        x.business_unit = x.business_unit_label || x.business_unit;
        delete x.business_unit_label;
        delete x.parent_group;
        return x;
      });
      
      // header.push("Receivables")
      downloadExcel(data, ` Receivables - Summary Report - ${this.currentDate}`,
          header);
    } else  if (tabValue == 4) {
      let ex = this.props.ageingData.summaryMonthly.externalData;
      let inter = this.props.ageingData.summaryMonthly.internalData;
      let unbil = this.props.ageingData.summaryMonthly.unbilledData;
      let header = ex.row_header.map(x => {
        return x.label;
      });
      let dataEx = this.mapExcelDataSummary(ex.row_data);
      let dataInter = this.mapExcelDataSummary(inter.row_data);
      let dataUnbill = this.mapExcelDataSummary(unbil.row_data);
      let data = dataEx.concat(dataInter).concat(dataUnbill);
      data = data.map(x => {
        x.business_unit = x.business_unit_label || x.business_unit;
        delete x.business_unit_label;
        delete x.parent_group;
        return x;
      });
    
      
      // header.push("Receivables")
      downloadExcel(data, ` Receivables - Monthly Rolling View Report - ${this.currentDate}`,
          header);
    } else {
//       let ex = JSON.parse(
//           JSON.stringify(this.props.ageingData.external.data.row_data));
//       let header = JSON.parse(
//           JSON.stringify(this.props.ageingData.external.data.row_header));

//       let alluser = JSON.parse(
//           JSON.stringify(this.props.ageingData.external.allUser));

//       let ops = {};
//       let exclude = ['collection_forecast', 'improvement_plan'];
//       header = header.filter((x) => {
//         return exclude.indexOf(x.key) == -1;
//       });

//       ops.dataType = {
//         4: 'number',
//         5: 'number',
//         6: 'number',
//         7: 'number',
//         8: 'number',
//         9: 'number',
//         10: 'number',
//         13: 'number',
//         14: 'number',
//         16: 'date',
//         17: 'number',
//       };
//       if (this.state.externalFilterObject.parent_group != 'unbilled' &&
//           this.state.selectedBu.toLowerCase() != 'pab') {

//         // header = header.filter((x) => {
//         //     let exclude = ["collection_forecast", "improvement_plan"]
//         //     return exclude.indexOf(x.key) == -1
//         // })
//         header.splice(14, 0,
//             {key: 'collection_forecast', label: 'Collection Forecast'});
//         header.splice(15, 0, {
//           key: 'collection_forecast_confirmed_by',
//           label: 'Collection Forecast Confirmed By',
//         });
//         header.splice(16, 0, {
//           key: 'collection_forecast_date',
//           label: 'Collection Forecast Date',
//         });
//       }
//       if (this.state.externalFilterObject.parent_group != 'unbilled' &&
//           this.state.selectedBu.toLowerCase() == 'pab') {
//         console.log('Masuk Sini', 'testHeaderBefore');

//         header.splice(14, 0,
//             {key: 'collection_forecast', label: 'Collection Forecast'});
//         header.splice(15, 0, {
//           key: 'collection_forecast_confirmed_by',
//           label: 'Collection Forecast Confirmed By',
//         });
//         header.splice(16, 0, {
//           key: 'collection_forecast_date',
//           label: 'Collection Forecast Date',
//         });
//       }
//       console.log(this.state, "masuk123", "testHeaderBefore")
//       // if(this.state.externalFilterObject.parent_group.toLowerCase() == 'unbilled' && this.state.selectedBu.toLowerCase() == 'pab'){
         

//       //     header.splice(8, 0,{key : "collection_forecast", label : "Collection Forecast"})
//       //     header.splice(9, 0,{key : "collection_forecast_confirmed_by", label : "Collection Forecast Confirmed By"})
//       //     header.splice(10, 0,{key : "collection_forecast_date", label : "Collection Forecast Date"})
//       // }

//       ex = ex.map(x => {
//         let comm = '';
//         x.commentArr.forEach((y, idx) => {
//           const selected = alluser.find(z => {
//             return z.login_id == y.login_id;
//           });
//           console.log('nameessssssssssssss', selected);
//           if (typeof selected === 'undefined') {
//             if (y.type == 'doc') {
//               comm += y.name + ' (' + formatDate(y.timestamp) + ') ' +
//                   ':\n<document>';
//             } else {
//               comm += y.name + ' (' + formatDate(y.timestamp) + ') ' + ':\n' +
//                   y.body;
//             }
//             if (idx != x.commentArr.length - 1) {
//               comm += '\n';
//             }
//           } else {
//             if (y.type == 'doc') {
//               comm += selected.full_name + ' (' + formatDate(y.timestamp) +
//                   ') ' + ':\n<document>';
//             } else {
//               comm += selected.full_name + ' (' + formatDate(y.timestamp) +
//                   ') ' + ':\n' + y.body;
//             }
//             if (idx != x.commentArr.length - 1) {
//               comm += '\n';
//             }
//           }

//         });
//         x.comments = comm;

//         if (x.collection_forecast == -1) {
//           x.collection_forecast = '';
//         }
//         return x;
//       });
//       let data = mapExcelDataV1(this.mapUser(ex), header, undefined,
//           ops.dataType);
//       header = header.map(x => {
//         return x.label;
//       });
//       var filename = 'Third Party';
//       switch (tabValue) {
//         case 2:
//           filename = 'SLB';
//           break;
//         case 3:
//           filename = 'Unbilled';
//           break;
//       }
// // console.log(data, "TestDataExcel")
//       ops.fileName = ` Receivables - ${filename} - Aging Report (${this.state.selectedBu}) - ${this.currentDate}`;
//       ops.columns = header;
//       ops.data = data;
//       // ops.alignment = {17 : { wrapText: true }}
//       if (this.state.externalFilterObject.parent_group != 'unbilled' &&
//       this.state.selectedBu.toLowerCase() != 'pab') {
//         ops.alignment = {
//           18: {wrapText: true, horizontal: 'left'},
//           2: {horizontal: 'left'},
//         };
//       }else{
//         ops.alignment = {
//           8: {wrapText: true, horizontal: 'left'},
//           3: {horizontal: 'left'},
//         };
//       }
    
//       console.log(ex, ops, '--ops');
      // downloadExcelV1(ops);
    }
  };

  handleBuChange = (bu) => {
    const {externalFilterObject} = this.state;

    let newExternalFilter = {...{}, ...externalFilterObject};
    newExternalFilter.customer_name = [];

    this.setState({
      externalFilterObject: newExternalFilter,
      selectedBu: bu == 'BU 1' ? 'BU 1' : bu.toUpperCase(),
    });
    this.props.setSelectedBusinessUnit(bu);
  };

  handleChangeSelectedParentGroup = (parentGroup) => {

    const {externalFilterObject} = this.state;

    let newExternalFilter = {...{}, ...externalFilterObject};
    newExternalFilter.customer_name = [];
    newExternalFilter.parent_group = parentGroup;
    this.setState({
      externalFilterObject: newExternalFilter,
    });

    
  };

  render() {
    const {externalFilterObject, defaultTab} = this.state;
    const {externalData, dashboardState} = this.props;
    const filterArr = externalData && externalData.filterObj && externalData.filterObj.data;
    
    return (
        <React.Fragment>
          {dashboardState.reportDateArr && dashboardState.reportDateArr.length ?
              <AgingFunctionalComponent
                  externalfilterArr={filterArr}
                  receivableTabSelectorValue={this.props.tabSelectorValue}
                  handleApplyFilter={this.handleApplyFilter}
                  handleSendComment={this.handleSendComment}
                  handleChangeSelectedBU={this.handleBuChange}
                  handleChangeSelectedParentGroup={this.handleChangeSelectedParentGroup}
                  defaultTab={defaultTab}
                  reportDateArr={dashboardState.reportDateArr}
                  customerNameArr={dashboardState.customerNameArr}
                  handleDateChangeFn={this.handleDateChangeFn}
                  handleDownloadExcel={this.handleDownloadExcel} 
                  printDocument={this.printDocument}
                  externalFilterObject={externalFilterObject}
              /> :
              null
          }
          {dashboardState.showErrorPage && <ErrorComponent/>}
        </React.Fragment>
    );
  }
}

export default withRouter(
    connect(
        state => {
          return {
            ageingData: state.dashboardState.receivableData.ageingData,
            externalData: state.dashboardState.receivableData.ageingData.external,
            dashboardState: state.dashboardState,
          };
        },
        dispatch => {
          return {
            getMSMDetail(data) {
              dispatch({
                type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA',
                payload: data,
              });
            },
            getSummaryDetail(data) {
              dispatch({
                type: 'FETCH_RECEIVABLE_DATA',
                payload: data,

              });
            },
            sendCommentAction(data) {
              dispatch({
                type: 'SEND_COMMENT_ACTION',
                payload: data,
              });
            },
            getPipMasterData(data) {
              dispatch({
                type: 'FETCH_MASTER_PIP_DATA',
                payload: data,
              });
            },
            getFilterData(data) {
              dispatch({
                type: 'FETCH_EXTERNAL_FILTER_DATA',
                payload: data,
              });
            },
            getSummaryBuData(data) {
              dispatch({
                type: 'FETCH_SUMMARY_BU_DATA',
                payload: data,
              });
            },
            setSelectedFilter(data) {
              dispatch({
                type: 'SET_SELECTED_FILTER',
                payload: data,
              });
            },
            setSelectedBusinessUnit(data) {
              dispatch({
                type: 'SET_SELECTED_BUSINESS_UNIT',
                payload: data,
              });
            },
            updateSelectedDate(data) {
              dispatch({
                type: 'UPDATE_SELECTED_DATE',
                payload: data,
              });
            },
            getAllUser(data) {
              dispatch({
                type: 'FETCH_ALL_USER',
                payload: data,
              });
            },
            getContractLiability(data) {
              dispatch({
                type: 'FETCH_CONTRACT_LIABILITY',
                payload: data
              });
            },
            getNewComponent(data) {
              dispatch({
                type: 'FETCH_New_HEMISPHERE',
                payload: data
              });
            },
          };
          
        },
    )(Ageing),
);

