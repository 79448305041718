import { getEditStudioData, varianceOptionsMap, adjustCustomFunction } from './transformer.js';

const initialState = {
	loading: false,
	filterMetaData: [],
	measurements: [],
	dimensions: [],
	functions: [],
	tabs: {
		id: '',
		activeTab: 0,
		moduleName: '',
		data: [
			{
				tabName: 'Sheet 1',
				measurementArr: [],
				dimensionArr: [],
				functionArr: [],
				columnsArray: [],
				rowsArray: [],
				functionsFilters: [],
				customFnObj: {},
			},
		],
	},
	analyticStudio: {
		loading: false,
		status: 'none',
		editStatus: 'none',
		loadingShare: false,
		tableRowData: [],
		chartRowData: [],
		chartMetaData: [],
		defaultSelection: [],
		filter: [],
		totalTableRowCount: 0,
		tableRowHeader: [],
		saveData: [],
		savedDataReport: [],
	},
};

const reducer = (state = initialState, action) => {
	let newState = JSON.parse(JSON.stringify(state));
	switch (action.type) {
		case 'SHARE_CHART_LOADER':
			newState.analyticStudio.loadingShare = action.loading;
			return newState;

		case 'FETCH_META_DATA_ANALYTIC_STUDIO_SUCCESS':
			const { measurements, dimensions, functions, filtersData } = action.data;
			newState.filterMetaData = action.data;
			newState.measurements = measurements;
			newState.dimensions = dimensions;
			newState.functions = functions;
			newState.filterMetaData = filtersData;
			newState.tabs.data[0].measurementArr = measurements;
			newState.tabs.data[0].dimensionArr = dimensions;
			newState.tabs.data[0].functionArr = functions;
			return newState;

		case 'FETCH_NEW_DATA_ANALYTIC_STUDIO_LOADER':
			newState.analyticStudio.loading = action.loading;
			newState.analyticStudio.status = 'loading';
			if (action.loading) {
				newState.analyticStudio.tableRowData = [];
			}
			return newState;

		case 'FETCH_NEW_DATA_ANALYTIC_STUDIO_SUCCESS':
			newState.analyticStudio.loading = false;
			newState.analyticStudio.status = 'success';
			newState.analyticStudio.tableRowData = action.data.row_data || [];
			newState.analyticStudio.chartRowData = action.data.chart_data || [];
			newState.analyticStudio.chartMetaData = action.data.chart_metadata || [];
			newState.analyticStudio.defaultSelection =
				action.data.default_selection || [];
			newState.analyticStudio.filter = action.data.filter || [];
			newState.analyticStudio.tableRowHeader = action.data.row_header || [];
			newState.analyticStudio.totalTableRowCount = action.data.total_count;
			return newState;

		case 'RESET_TABLE_DATA':
			newState.analyticStudio.loading = false;
			newState.analyticStudio.status = 'none';
			newState.analyticStudio.tableRowData = [];
			newState.analyticStudio.chartRowData = [];
			newState.analyticStudio.defaultSelection = [];
			newState.analyticStudio.filter = [];
			newState.analyticStudio.tableRowHeader = [];
			newState.analyticStudio.totalTableRowCount = 0;
			return newState;

		case 'GET_ANALYTIC_STUDIO_SUCCESS':
			newState.analyticStudio.saveData = action.data;
			return newState;

		case 'GET_ANALYTIC_STUDIO_REPORT_SUCCESS':
			newState.analyticStudio.savedDataReport = action.data;
			return newState;

		case 'GET_ANALYTIC_STUDIO_SINGLE_REPORT_SUCCESS':
			newState.analyticStudio.savedDataReport = action.data;
			return newState;

		case 'UPDATE_ACTIVE_TAB_MEASUREMENTS':
			newState.tabs.data[newState.tabs.activeTab].measurementArr = action.data;
			return newState;

		case 'UPDATE_ACTIVE_TAB_DIMENSIONS':
			newState.tabs.data[newState.tabs.activeTab].dimensionArr = action.data;
			return newState;

		case 'UPDATE_ACTIVE_TAB_FUNCTIONS':
			newState.tabs.data[newState.tabs.activeTab].functionArr = action.data;
			return newState;

		case 'UPDATE_ACTIVE_TAB_COLUMNS':
			// console.log(newState.tabs.data[newState.tabs.activeTab].customFnObj, "--before")
			newState.tabs.data[newState.tabs.activeTab].columnsArray = action.data;
			newState.tabs.data[newState.tabs.activeTab].customFnObj = adjustCustomFunction(
				newState.tabs.data[newState.tabs.activeTab].rowsArray,
				newState.tabs.data[newState.tabs.activeTab].columnsArray,
				newState.tabs.data[newState.tabs.activeTab].customFnObj,
			);
			return newState;

		case 'UPDATE_ACTIVE_TAB_ROWS':
			// console.log(newState.tabs.data[newState.tabs.activeTab].customFnObj, "--before")
			newState.tabs.data[newState.tabs.activeTab].rowsArray = action.data;
			newState.tabs.data[newState.tabs.activeTab].customFnObj = adjustCustomFunction(
				newState.tabs.data[newState.tabs.activeTab].rowsArray,
				newState.tabs.data[newState.tabs.activeTab].columnsArray,
				newState.tabs.data[newState.tabs.activeTab].customFnObj,
			);
			return newState;

		case 'UPDATE_ACTIVE_TAB_COLUMNS_AND_ROWS':
			newState.tabs.data[newState.tabs.activeTab].columnsArray =
				action.data.columnsArray;
			newState.tabs.data[newState.tabs.activeTab].rowsArray =
				action.data.rowsArray;
			return newState;

		case 'UPDATE_ACTIVE_TAB_INDEX':
			newState.tabs.activeTab = action.data;
			return newState;

		case 'UPDATE_TAB_DATA':
			newState.tabs.activeTab += 1;
			newState.analyticStudio.loading = false;
			newState.analyticStudio.status = 'none';
			newState.analyticStudio.tableRowData = [];
			newState.analyticStudio.chartRowData = [];
			newState.analyticStudio.defaultSelection = [];
			newState.analyticStudio.filter = [];
			newState.analyticStudio.tableRowHeader = [];
			newState.analyticStudio.totalTableRowCount = 0;
			newState.tabs.data.push({
				measurementArr: newState.measurements,
				dimensionArr: newState.dimensions,
				functionArr: newState.functions,
				columnsArray: [],
				rowsArray: [],
				tabName: `Sheet ${newState.tabs.data.length + 1}`,
			});
			return newState;

		case 'DELETE_TAB':
			newState.tabs.activeTab = action.data.activeTab;
			newState.tabs.data = action.data.data;
			return newState;

		case 'RESET_TAB_DATA':
			newState.tabs = {
				id: '',
				activeTab: 0,
				moduleName: '',
				data: [
					{
						tabName: 'Sheet 1',
						measurementArr: [],
						dimensionArr: [],
						functionArr: [],
						columnsArray: [],
						rowsArray: [],
						functionsFilters: [],
						customFnObj: {},
					},
				],
			};
			return newState;
		case 'HANDLE_EDIT_STUDIO_STATUS' :
			newState.analyticStudio.editStatus = action.status
			return newState;
		case 'HANDLE_EDIT_STUDIO':
			const { _id, tabData, name } = getEditStudioData(
				action.payload,
				newState.filterMetaData
			);
			newState.tabs.id = _id;
			newState.tabs.data = tabData;
			newState.tabs.moduleName = name;
			newState.tabs.data[newState.tabs.activeTab].measurementArr =
				newState.measurements;
			newState.tabs.data[newState.tabs.activeTab].dimensionArr =
				newState.dimensions;
			newState.tabs.data[newState.tabs.activeTab].functionArr =
				newState.functions;
			newState.analyticStudio.editStatus = 'edit-ready'
			// console.log(newState.tabs.data[newState.tabs.activeTab], "--edit data")
			return newState;

		case 'SAVE_ANALYTIC_STUDIO_SUCCESS':
			newState.tabs.id = action.data._id;
			return newState;

		case 'DELETE_ANALYTIC_STUDIO_SUCCESS':
			// newState.analyticStudio.saveData = action.data;
			return newState;

		case 'UPDATE_VARIANCE_OPTIONS_ON_FUNCTION':
			// newState.tabs.data[newState.tabs.activeTab].functionArr =
			varianceOptionsMap(
				action.data,
				newState.tabs.data[newState.tabs.activeTab]
			);
			return newState;

		case 'SAVE_CUSTOM_FUNCTION_DATA':
			// console.log("--save")
			newState.tabs.data[newState.tabs.activeTab].customFnObj = action.payload;
			return newState;

		default:
			return newState;
	}
};
export default reducer;
