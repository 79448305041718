import React, { useEffect, useState, useMemo, useReducer, useRef } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from "@material-ui/core/Popper";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DragDropContext } from "react-beautiful-dnd";
import { toastr } from "react-redux-toastr";
import SideMenu from "./components/SideMenu";
import SelectedMenu from "../../FreeCashFlow/AnalyticsStudio-New/components/selectedMenu";
import useSimulationMetaData from "./useSimulationMetaData";
import Filters from "./components/Filters";
import { initialState, reducer } from "./addEditSimulationSlice";
import { saveSimulationService, fetchSimulationDataService, fetchActualBUFilterService } from "../store/service";
import { isNumeric } from "../../../utils/numberUtils";
import "./AddEditSimulation.scss";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from './TableComponent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DownloadExcel from "../../../images/download_excel.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";



//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";



const AddEditSimulation = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const validationErrorsRef = useRef([]);
    const [resetFilter, setResetFilterData] = useState(0)

    const history = useHistory();
    const {
        status,
        data: { filter_numeric_operator, filter_string_operator, formula_operator, where_operator, meta, measurements, conditions },
    } = useSimulationMetaData();
    const {
        showLoader,
        id,
        simulationName,
        isDefaultSimulation,
        simulationItems,
        whereClause,
        whereClauseOperator,
        activePopperData: { ref, open, itemId, itemIndex, itemType, filters },
        fromDate,
        toDate,
        tabSelectorValue,
        simulationTabel,
        toAssign,
        fromAssign,
        activeformula
    } = state;

    const { monthArrFuture, monthArr, rowData, rowHeader, rowDataNorm, rowHeaderNorm } = simulationTabel
    //console.log(monthArr, "--monthArr")
    //console.log ('formulastate', activeformula)

    const selectedOptions = (a, data) => {
        return data.filter((v) => a.indexOf(v.value) > -1)
    };



    useEffect(() => {
        if (status === "success") {
            getSimulationData();
            fetchFilter();

        }
    }, [status]);

    useEffect(() => {
        if (fromDate != "" && toDate != "") {
            TrySimulation();
        }
    }, [id]);

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const useStyles = makeStyles(() => ({
        customWidth: {
            maxWidth: 500,
        }
    }));

    const classes = useStyles();

    const runTooltipText = activeformula;


    const getSimulationData = async () => {
        try {
            const query = new URLSearchParams(history?.location?.search);
            const simulationId = query.get("id");
            if (simulationId) {
                dispatch({
                    type: "UPDATE_LOADER_VISIBILITY",
                    payload: true,
                });
                const response = await fetchSimulationDataService(simulationId);
                if (response?.status === 200) {
                    if (Array.isArray(response?.data?.row_data) && response?.data?.row_data?.[0]) {
                        const { sample = {}, assign = {}, _id = "", name = "", is_default = false, condition, condition_operator, measurement } = response.data.row_data[0];
                        //console.log ('formula while loading', response)
                        dispatch({
                            type: "UPDATE_SIMULATION_DATA",
                            payload: {
                                id: _id,
                                simulationName: name,
                                isDefaultSimulation: is_default,
                                whereClause: buildConditionsDataFromResponse(condition),
                                whereClauseOperator: condition_operator,
                                simulationItems: buildMeasurementsDataFromResponse(measurement),
                                sample: sample,
                                assign: assign,
                            },
                        });


                    } else toastr.error(response?.data?.error?.debug_msg ?? "Unknown error found");
                } else toastr.error(response?.data?.error?.debug_msg ?? "Unknown error found");
            }
        } catch (err) {
            console.log(err);
            toastr.error(err);
        } finally {
            dispatch({
                type: "UPDATE_LOADER_VISIBILITY",
                payload: false,
            });
        }
    };

    const getFilterValuesById = (id, type) => {
        let result = [];
        const foundItem = meta.find((item) => item?._id?.type === type);
        if (Array.isArray(foundItem?.filters) && foundItem?.filters.length > 0) {
            const foundFilterItem = foundItem.filters.find((item) => item?._id === id);
            if (Array.isArray(foundFilterItem?.values)) {
                result = foundFilterItem?.values;
            }
        }
        return result;
    };

    const buildConditionFiltersDataFromResponse = (filters) => {
        const result = [];

        if (Array.isArray(filters) && filters.length > 0) {
            let i,
                filtersLength = filters.length;
            for (i = 0; i < filtersLength; i++) {
                const {
                    operator,
                    value,
                    meta_data: { _id, type, label },
                } = filters[i];

                result.push(
                    type === "measurement"
                        ? {
                            _id,
                            operator_default_value: operator ?? "",
                            default_value: Array.isArray(value) && value.length > 0 ? value[0] : "",
                            label,
                            type,
                            values: [],
                            operator_values: filter_numeric_operator,
                            isInputField: true,
                        }
                        : {
                            _id,
                            operator_default_value: operator ?? "",
                            default_value: value ?? [],
                            label,
                            type,
                            values: getFilterValuesById(_id, "condition"),
                        }
                );
            }
        }

        return result;
    };

    const buildConditionsDataFromResponse = (conditions) => {
        const result = [];
        if (Array.isArray(conditions)) {
            let i,
                conditionsLength = conditions.length;
            for (i = 0; i < conditionsLength; i++) {
                const { meta_data, filters } = conditions[i];
                delete meta_data.filter;
                meta_data.draggableId = generateNewNumber(meta_data?.label, conditions);
                result.push({
                    ...meta_data,
                    filters: buildConditionFiltersDataFromResponse(filters),
                });
            }
        }
        return result;
    };

    const getFilterValuesFromMeasurement = () => {
        const measurement = meta.find((item) => item?._id?.type === "measurement");
        if (measurement) {
            return Array.isArray(measurement?.filter_values) ? measurement?.filter_values : [];
        }
    };

    const buildMeasurementFiltersDataFromResponse = (filters, lastOperator, lastUserInput) => {
        const result = [];

        if (Array.isArray(filters) && filters.length > 0) {
            let i,
                filtersLength = filters.length;
            for (i = 0; i < filtersLength; i++) {
                const {
                    operator,
                    value,
                    meta_data: { _id, type, label },
                } = filters[i];

                result.push({
                    _id,
                    operator_default_value: operator ?? "",
                    default_value: value ?? [],
                    label,
                    type,
                    filter_values: getFilterValuesFromMeasurement(),
                    values: getFilterValuesById(_id, "measurement"),
                });
            }

            if (filtersLength > 1) {
                result.push({
                    _id: "filter_formula",
                    operator_default_value: lastOperator ?? "",
                    default_value: lastUserInput ?? "",
                    label: "Value",
                    type: "measurement",
                    values: [],
                    isInputField: true,
                    isMultiple: false,
                    operator_values: formula_operator,
                });
            }
        }

        return result;
    };

    const buildMeasurementsDataFromResponse = (measurements) => {
        const result = [];
        if (Array.isArray(measurements)) {
            let i,
                measurementsLength = measurements.length;
            for (i = 0; i < measurementsLength; i++) {
                const { meta_data, filters, operator = "", user_input = "" } = measurements[i];
                delete meta_data.filter;
                meta_data.draggableId = generateNewNumber(meta_data?.label, measurements);
                result.push({
                    ...meta_data,
                    filters: buildMeasurementFiltersDataFromResponse(filters, operator, user_input),
                });
            }
        }
        return result;
    };

    const handleSimulationNameChange = (event) => {
        dispatch({
            type: "UPDATE_SIMULATION_NAME",
            payload: event.target.value,
        });
    };

    const handleFromDateChange = (event) => {
        dispatch({
            type: "UPDATE_FROM_DATE",
            payload: event.target.value,
        });
    };


    const handleTabChange = (event, newValue) => {
        dispatch({
            type: "TAB_CHANGE",
            payload: newValue,
        });
    };

    const handleToDateChange = (event) => {
        dispatch({
            type: "UPDATE_TO_DATE",
            payload: event.target.value,
        });
    };



    const handleDefaultSimulationChange = () => {
        dispatch({
            type: "TOGGLE_DEFAULT_SIMULATION",
        });
    };

    const handleAssignToDateChange = (event) => {
        dispatch({
            type: "UPDATE_ASSIGN_TO_DATE",
            payload: event.target.value,
        });
    };



    const handleAssignFromDateChange = (event) => {
        dispatch({
            type: "UPDATE_ASSIGN_FROM_DATE",
            payload: event.target.value,
        });
    };


    //MuiV5 component change handlers

    const handleFromDateChangeNew = (event, newValue) => {
        dispatch({
            type: "UPDATE_FROM_DATE",
            payload: newValue.value,
        });
    }

    const handleToDateChangeNew = (event, newValue) => {
        dispatch({
            type: "UPDATE_TO_DATE",
            payload: newValue.value,
        });
    }

    const handleAssignToDateChangeNew = (event, newValue) => {
        dispatch({
            type: "UPDATE_ASSIGN_TO_DATE",
            payload: newValue.value,
        });
    }

    const handleAssignFromDateChangeNew = (event, newValue) => {
        dispatch({
            type: "UPDATE_ASSIGN_FROM_DATE",
            payload: newValue.value,
        });
    }






    const handleBackButton = () => {
        dispatch({
            type: "UPDATE_SIMULATION_ID",
            payload: { id: "" },
        });
        history.push("/dashboard/xpna/extended-planning/simulation-logic");
    };

    const updateSimulationItems = (simulationItems) => {
        dispatch({
            type: "UPDATE_SIMULATION_ITEMS",
            payload: simulationItems,
        });
    };

    const updateWhereClause = (whereClause) => {
        dispatch({
            type: "UPDATE_WHERE_CLAUSE",
            payload: whereClause,
        });
    };

    const updateSimulationsAndWhereClause = (simulationItems, whereClause) => {
        dispatch({
            type: "UPDATE_SIMULATIONS_AND_WHERE_CLAUSE",
            payload: {
                simulationItems,
                whereClause,
            },
        });
    };

    const handleWhereClauseOperatorChange = (event) => {
        dispatch({
            type: "UPDATE_WHERE_CLAUSE_OPERATOR",
            payload: event.target.value,
        });
    };

    const generateNewNumber = (keyStr, itemArray) => {
        let newKeyStr = `${keyStr}-${Math.floor(Math.random() * (100 - 0)) + 0}`;
        let selected = itemArray.find((item) => item.draggableId == keyStr);
        if (selected) {
            generateNewNumber(keyStr, itemArray);
        }
        return newKeyStr;
    };

    const getItem = (itemLabel) => {
        try {
            let selectedObj = null;
            if (Array.isArray(meta)) {
                for (let i = 0; i < meta.length; i++) {
                    const currMetaItem = meta[i];
                    if (currMetaItem) {
                        const foundItem = currMetaItem?.details?.find((object) => object.label == itemLabel);
                        if (foundItem) {
                            selectedObj = foundItem;
                            break;
                        }
                    }
                }
            }

            return selectedObj ? JSON.parse(JSON.stringify(selectedObj)) : null;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    const openFilterPopup = (itemType, key) => {
        setTimeout(() => {
            const foundElement = document.querySelector(`[data-rbd-droppable-id="${itemType}"] [data-rbd-draggable-id="${key}"]`);
            if (foundElement) foundElement.click();
        }, 500);
    };

    const parseCurrentItem = (currentItem, draggableId, destinationDroppableId) => {
        let tempCurrentItem = { ...currentItem };
        tempCurrentItem.filters = [];

        if (currentItem?.type === "measurement") {
            const desiredItem = meta.find((item) => item?._id?.type === "measurement");
            const foundItem = JSON.parse(JSON.stringify(desiredItem));

            if (destinationDroppableId === "simulations") {
                if (Array.isArray(foundItem?.filters)) {
                    const filterValues = Array.isArray(foundItem?.filter_values) ? JSON.parse(JSON.stringify(foundItem.filter_values)) : [];
                    tempCurrentItem.filters = foundItem.filters.map((item) => {
                        item.filter_values = filterValues;
                        return item;
                    });

                    tempCurrentItem.filters.push({
                        _id: "filter_formula",
                        additional: {},
                        operator_values: formula_operator,
                        operator_default_value: null,
                        values: [],
                        default_value: "",
                        isMultiple: false,
                        isInputField: true,
                        label: "Value",
                        meta: [],
                        module: [],
                        type: "measurement",
                    });
                }
            }

            if (destinationDroppableId === "clause") {
                const { _id, label, additional, meta, order, type } = foundItem?.details?.find((item) => item?.label === draggableId);
                tempCurrentItem.filters = [
                    {
                        _id,
                        additional,
                        operator_values: filter_numeric_operator,
                        operator_default_value: null,
                        values: [],
                        default_value: "",
                        isMultiple: false,
                        isInputField: true,
                        label,
                        meta,
                        module,
                        order,
                        type,
                    },
                ];
            }
        }

        if (currentItem?.type === "condition") {
            const desiredItem = meta.find((item) => item?._id?.type === "condition");
            const foundItem = JSON.parse(JSON.stringify(desiredItem));

            if (destinationDroppableId === "clause") {
                const whereClauseFilter = foundItem?.filters?.find((item) => item?.label === draggableId);
                if (whereClauseFilter) {
                    tempCurrentItem.filters = [whereClauseFilter];
                }
            }
        }

        return tempCurrentItem;
    };

    const handleDragEnd = (data) => {
        const { source, destination, draggableId } = data ?? {};
        const { index: sourceIndex = -1, droppableId: sourceDroppableId = "" } = source ?? {};
        const { index: destinationIndex = -1, droppableId: destinationDroppableId = "" } = destination ?? {};

        if (
            !destination ||
            (destinationDroppableId == sourceDroppableId && destinationDroppableId.includes("option")) ||
            (sourceDroppableId === "Condition-option" && destinationDroppableId === "simulations")
        ) {
            return;
        }

        if (destinationDroppableId == sourceDroppableId) {
            switch (destinationDroppableId) {
                case "simulations":
                    let tempSimulationItems = [...simulationItems];
                    const tempColumnArrElement = tempSimulationItems[source.index];
                    tempSimulationItems[sourceIndex] = tempSimulationItems[destinationIndex];
                    tempSimulationItems[destinationIndex] = tempColumnArrElement;
                    updateSimulationItems(tempSimulationItems);
                    break;
                case "rows":
                    let tempWhereClause = [...whereClause];
                    const tempRowArrElement = tempWhereClause[sourceIndex];
                    tempWhereClause[sourceIndex] = tempWhereClause[destinationIndex];
                    tempWhereClause[destinationIndex] = tempRowArrElement;
                    updateWhereClause(tempWhereClause);
                    break;
                default:
                    break;
            }
        } else if (sourceDroppableId.includes("-option") && (destinationDroppableId === "simulations" || destinationDroppableId === "clause")) {
            const currentItem = getItem(draggableId);

            if (currentItem) {
                switch (destinationDroppableId) {
                    case "simulations":
                        const tempSimulationItems = [...simulationItems];
                        const newSimulationItemStr = generateNewNumber(currentItem.label, tempSimulationItems);
                        currentItem.draggableId = newSimulationItemStr;
                        const parsedSimulationItem = parseCurrentItem(currentItem, draggableId, destinationDroppableId);
                        tempSimulationItems.splice(destinationIndex, 0, parsedSimulationItem);
                        updateSimulationItems(tempSimulationItems);
                        openFilterPopup("simulations", newSimulationItemStr);
                        break;
                    case "clause":
                        const tempWhereClause = [...whereClause];
                        const newWhereClauseStr = generateNewNumber(currentItem.label, tempWhereClause);
                        currentItem.draggableId = newWhereClauseStr;
                        const parsedWhereClauseItem = parseCurrentItem(currentItem, draggableId, destinationDroppableId);
                        tempWhereClause.splice(destinationIndex, 0, parsedWhereClauseItem);
                        updateWhereClause(tempWhereClause);
                        openFilterPopup("clause", newWhereClauseStr);
                        break;
                    default:
                        break;
                }
            }
        } else if ((sourceDroppableId === "simulations" && destinationDroppableId === "clause") || (sourceDroppableId === "clause" && destinationDroppableId === "simulations")) {
            let tempSimulationItems = [...simulationItems],
                tempWhereClause = [...whereClause];

            if (sourceDroppableId === "simulations" && destinationDroppableId === "clause") {
                const tempSourceItem = tempWhereClause[sourceIndex];
                tempWhereClause.splice(sourceIndex, 1);
                tempSimulationItems.splice(destinationIndex, 0, tempSourceItem);
            } else {
                const tempSourceItem = tempSimulationItems[sourceIndex];
                tempSimulationItems.splice(sourceIndex, 1);
                tempWhereClause.splice(destinationIndex, 0, tempSourceItem);
            }

            updateSimulationsAndWhereClause({
                simulationItems: tempSimulationItems,
                whereClause: tempWhereClause,
            });
        }
    };

    const handleOnChipClick = (event, itemId, itemIndex, itemType, filters) => {
        dispatch({
            type: "UPDATE_ACTIVE_POPPER_DATA",
            payload: {
                open: true,
                ref: event.currentTarget,
                itemId,
                itemIndex,
                itemType,
                filters,
            },
        });
    };

    const handleColumnItemDelete = (index) => {
        const tempSimulationItems = [...simulationItems];

        if (index > -1) {
            tempSimulationItems.splice(index, 1);
            updateSimulationItems(tempSimulationItems);
        }
    };

    const handleRowItemDelete = (index) => {
        const tempWhereClause = [...whereClause];

        if (index > -1) {
            tempWhereClause.splice(index, 1);
            updateWhereClause(tempWhereClause);
        }
    };

    const handleFiltersChange = ({ newItemArray }) => {
        const tempArray = itemType === "simulations" ? [...simulationItems] : itemType === "clause" ? [...whereClause] : null;
        if (tempArray && tempArray?.[itemIndex]) {
            tempArray[itemIndex].filters = newItemArray;
            if (itemType === "simulations") {
                updateSimulationItems(tempArray);
            } else if (itemType === "clasue") {
                updateWhereClause(tempArray);
            }
        }
    };

    const handlePopperClickAwayHandler = (event) => {
        if (event.target.localName === "body") {
            return;
        }
        dispatch({
            type: "UPDATE_ACTIVE_POPPER_DATA",
            payload: {
                open: false,
                ref: null,
                itemId: "",
                itemIndex: -1,
                itemType: "",
                filters: [],
            },
        });
    };

    const buildMeasurmentsFiltersPayload = (filters, label) => {
        let result = [];

        if (Array.isArray(filters) && filters.length > 0) {
            let i,
                filtersLength = filters.length;
            for (i = 0; i < filtersLength; i++) {
                const { _id, operator_default_value = "", default_value = null } = filters[i];
                result.push({
                    field: _id,
                    operator: operator_default_value,
                    value: default_value,
                });
            }
        }

        return result;
    };

    const buildConditionsFiltersPayload = (filters, label, type) => {
        let result = [];
        if (Array.isArray(filters) && filters.length > 0) {
            let i,
                filtersLength = filters.length;
            for (i = 0; i < filtersLength; i++) {
                const { _id, operator_default_value = "", default_value = null } = filters[i];
                const parsedValue = type === "measurement" ? (isNumeric(default_value) ? [default_value] : []) : default_value ?? [];

                if (!(operator_default_value && operator_default_value !== "")) {
                    validationErrorsRef.current.push({ id: _id, errorMsg: `\`${label}\` Filters Operator should not be empty.` });
                }

                if (!(Array.isArray(parsedValue) && parsedValue.length > 0)) {
                    validationErrorsRef.current.push({ id: _id, errorMsg: `\`${label}\` Filters Value should not be empty.` });
                }

                result.push({
                    field: _id,
                    operator: operator_default_value,
                    value: parsedValue,
                });
            }
        }

        return result;
    };

    const buildConditionsPayload = (data) => {
        let result = [];
        if (Array.isArray(data) && data.length > 0) {
            let i,
                dataLength = data.length;
            for (i = 0; i < dataLength; i++) {
                const { _id, label, type, filters } = data[i];
                result.push({
                    field: _id,
                    filters: buildConditionsFiltersPayload(filters, `${i + 1}. ${label}`, type),
                });
            }
        }
        return result;
    };

    const extractMeasurmentOperatorAndValue = (filters) => {
        const result = {
            operator: "",
            user_input: null,
        };

        if (Array.isArray(filters) && filters.length > 1) {
            const { operator_default_value, default_value } = filters?.[filters.length - 1];
            result.operator = operator_default_value ?? "";
            result.user_input = isNumeric(default_value) ? default_value : "";
        }

        return result;
    };

    const buildMeasurementsPayload = (data) => {
        let result = [];

        if (Array.isArray(data) && data.length > 0) {
            let i,
                dataLength = data.length;
            for (i = 0; i < dataLength; i++) {
                const { _id, label, type, filters } = data[i];
                const { operator, user_input } = extractMeasurmentOperatorAndValue(filters);
                const labelWithIndex = `${i + 1}. ${label}`;

                if (!(operator && operator !== "")) {
                    validationErrorsRef.current.push({ id: _id, errorMsg: `\`${labelWithIndex}\` Formula Operator should not be empty.` });
                }

                if (!(user_input && user_input !== "")) {
                    validationErrorsRef.current.push({ id: _id, errorMsg: `\`${labelWithIndex}\` Formula Value should not be empty.` });
                }

                result.push({
                    field: _id,
                    operator,
                    user_input,
                    filters: Array.isArray(filters) ? buildMeasurmentsFiltersPayload(filters.slice(0, -1), labelWithIndex, type) : [],
                });
            }
        }
        return result;
    };

    const fetchFilter = async () => {
        const response = await fetchActualBUFilterService({});
        if (response?.status === 200) {
            dispatch({
                type: "FETCH_FILTER_SUCCESS",
                payload: response,
            });
        } else {
            toastr.error(response?.data?.error?.debug_msg ?? "Unknown error found");
        }
    }

    const saveSimulation = async () => {
        try {
            dispatch({
                type: "UPDATE_LOADER_VISIBILITY",
                payload: true,
            });

            if (!(typeof simulationName === "string" && simulationName !== "")) {
                toastr.error("Simulation name should not be empty", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            if (simulationItems.length === 0) {
                toastr.error("Please add at least one simulation item", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            if (toAssign == "" || fromAssign == "") {
                toastr.error("Please choose assign to period", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            if (fromDate == "" || toDate == "") {
                toastr.error("Please choose data sample period filter", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            let msgV = validateDate()
            if (msgV != "") {
                toastr.error(msgV, {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            validationErrorsRef.current = [];

            // if (whereClause.length > 0 && !(typeof whereClauseOperator === "string" && whereClauseOperator !== "")) {
            //     toastr.error("Condition operator should not be empty.", {
            //         timeOut: 4000,
            //         position: "top-center",
            //     });
            //     return;
            // }

            const payload = {
                _id: id,
                name: simulationName,
                is_default: isDefaultSimulation,
                condition: buildConditionsPayload(whereClause),
                condition_operator: "and",
                measurement: buildMeasurementsPayload(simulationItems),
                is_save: true,
                sample: { from: fromDate, to: toDate },
                assign: { from: toAssign, to: fromAssign },
            };
            if (validationErrorsRef.current?.length > 0) {
                toastr.error(validationErrorsRef.current?.[0].errorMsg, {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }
            const response = await saveSimulationService(payload);
            if (response?.status === 200) {
                toastr.success(`Simulation ${id && id !== "" ? "updated" : "saved"} successfully`);
            } else {
                toastr.error(response?.data?.error?.debug_msg ?? "Unknown error found");
            }
        } catch (err) {
            console.log(err);
            toastr.error(err);
        } finally {
            dispatch({
                type: "UPDATE_LOADER_VISIBILITY",
                payload: false,
            });
        }
    };

    const validateDate = () => {
        var sampleFrom = new Date(fromDate)
        var sampleTo = new Date(toDate)
        var assignFrom = new Date(fromAssign)
        var assignTo = new Date(toAssign)
        var msg = ""
        if (sampleFrom > sampleTo) {
            return "Data Sample period filter invalid"
        }

        if (assignFrom > assignTo) {
            return "Assign To period filter invalid"
        }

        if (sampleTo > assignFrom) {
            return "Assign To period should be greater than Data Sample period"
        }

        return ""
    }

    useEffect(() => {
        let msgV = validateDate()
        if (msgV != "") {
            toastr.error(msgV, {
                timeOut: 4000,
                position: "top-center",
            });
        }
    }, [fromDate, toDate, toAssign, fromAssign]);

    const TrySimulation = async (allsample) => {
        try {
            dispatch({
                type: "UPDATE_LOADER_VISIBILITY",
                payload: true,
            });

            if (simulationItems.length === 0) {
                toastr.error("Please add at least one simulation item", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            if (fromDate == "" || toDate == "") {
                toastr.error("Please choose data sample period filter", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            if (fromAssign == "" || toAssign == "") {
                toastr.error("Please choose assign to period filter", {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            let msgV = validateDate()
            if (msgV != "") {
                toastr.error(msgV, {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }

            validationErrorsRef.current = [];

            // if (whereClause.length > 0 && !(typeof whereClauseOperator === "string" && whereClauseOperator !== "")) {
            //     toastr.error("Condition operator should not be empty.", {
            //         timeOut: 4000,
            //         position: "top-center",
            //     });
            //     return;
            // }

            const payload = {
                _id: id,
                condition: buildConditionsPayload(whereClause),
                condition_operator: "and",
                measurement: buildMeasurementsPayload(simulationItems),
                is_save: false,
                sample: { from: fromDate, to: toDate },
                assign: { from: fromAssign, to: toAssign },
                all_sample: allsample,
                export: allsample ? "true" : "false"
            };
            if (validationErrorsRef.current?.length > 0) {
                toastr.error(validationErrorsRef.current?.[0].errorMsg, {
                    timeOut: 4000,
                    position: "top-center",
                });
                return;
            }
            const response = await saveSimulationService(payload);
            if (response?.status === 200 && payload.export != "true") {
                dispatch({
                    type: "UPDATE_SIMULATION_TABEL",
                    payload: response.data,
                });
            } else if (response?.status != 200) {
                toastr.error(response?.data?.error?.debug_msg ?? "Unknown error found");
            }
        } catch (err) {
            console.log(err);
            toastr.error(err);
        } finally {
            dispatch({
                type: "UPDATE_LOADER_VISIBILITY",
                payload: false,
            });
        }
    };

    const buildSideMenu = useMemo(() => {
        if (Array.isArray(meta)) {
            return meta.map((item) => {
                const { _id: { type = "", name = "" } = {}, details = [] } = item ?? {};
                return <SideMenu key={type} keyStr={name} itemArray={details} typeStr="universal" />;
            });
        } else return null;
    }, [meta]);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const resetFilterData = () => {
        setResetFilterData(Math.random())
    }

    return (
        <div className="aesl-section">
            {(showLoader || status === "loading") && (
                <div className="progressfullpage">
                    <CircularProgress />
                </div>
            )}
            {status === "fail" && <div className="text-center mt-50">Error while fetching simulation meta data.</div>}
            {status === "success" && (
                <>
                    <div className="aesl-header-section">
                        <div className="aesl-header-section-left">
                            <label htmlFor="simulation-name" className="mr-10" style={{ fontSize: "18px" }}>
                                Simulation Name
                            </label>
                            <TextField id="simulation-name" label="" value={simulationName} variant="outlined" size="small" onChange={handleSimulationNameChange} />
                            <label htmlFor="default-simulation" className="ml-30 mr-10" style={{ fontSize: "18px" }}>
                                Set as Default
                            </label>
                            <Checkbox id="default-simulation" checked={isDefaultSimulation} color="primary" inputProps={{ "aria-label": "primary checkbox" }} onClick={handleDefaultSimulationChange} />
                        </div>
                        <div className="aesl-header-section-right">
                            <Button size="small" className='newButton btnBlue btnSave uppercase' variant="contained" color="secondary" onClick={saveSimulation}>
                                Save
                            </Button>
                            <Button size="small" variant="contained" className='ml-10 newButton btnRed btnBack uppercase' color="secondary" onClick={handleBackButton}>
                                {"< Back"}
                            </Button>
                        </div>
                    </div>
                    <div className="aesl-content-section">

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Grid container>
                                <Grid item sm={2} variant="outlined" height="100%" className="menu-container">
                                    <div className='side-menu-title mt-5' style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: '600' }}>Selections</div>
                                    <div className="aesl-content-menu-section-wrapper side-menu-container">{buildSideMenu}</div>
                                </Grid>
                                <Grid item sm={10} variant="outlined">
                                    <Grid item sm={12} variant="outlined" className="aesl-content-main-section">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <div className="accordion-summary">
                                                    <Typography className='bold-title'>Simulation Parameters</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails className=''>
                                                <div className="aesl-content-main-section-wrapper">
                                                    <h3 className="mt-0 mr-0 mb-5 ml-0">Simulation Items</h3>
                                                    <div className="draggable-section">
                                                        <SelectedMenu
                                                            keyStr="simulations"
                                                            typeStr="universal"
                                                            itemArray={simulationItems}
                                                            color="primary"
                                                            onChipClick={handleOnChipClick}
                                                            onDelete={handleColumnItemDelete}
                                                        />
                                                    </div>
                                                    <div className="flex ai-flex-end mt-10 ">
                                                        <h3 className="mt-0 mr-20 mb-5 ml-0">Advanced Selection</h3>
                                                        {/* <FormControl style={{ width: 150 }} size="small">
                                                    <InputLabel>Operator</InputLabel>
                                                    <Select value={whereClauseOperator} multiple={false} onChange={handleWhereClauseOperatorChange}>
                                                        <MenuItem value="" disabled>
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {where_operator.map(({ text, value }) => (
                                                            <MenuItem value={value} key={value}>
                                                                {text}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl> */}
                                                    </div>
                                                    <div className="draggable-section">
                                                        <SelectedMenu keyStr="clause" typeStr="universal" itemArray={whereClause} color="primary" onChipClick={handleOnChipClick} onDelete={handleRowItemDelete} />
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    </Grid>
                                    <Grid container spacing={4} className="aesl-table-section">
                                        <Grid item className='pb-0' xs={12} sm={12} >


                                        </Grid>
                                        {/* <Grid container xs={12} sm={12}>
                                        <Grid item xs={5} sm={5} className='ml-15 maxw-220'>
                                                    <h1>Data Sample</h1> 
                                                </Grid>
                                                <Grid item xs={4} sm={4} className='ml-55'>
                                                        <h1>Assign To</h1> 
                                                </Grid>
                                        </Grid> */}
                                        {/* <Grid container xs={12} sm={12}>
                                        <h1 className="maxw-300" >Data Sample</h1>
                                        <h1>Assign To</h1> 
                                        </Grid> */}
                                        {/* <Grid item className='pb-0' xs={12} sm={12} >
                                            <h3 >Simulation Data</h3>
                                            </Grid> */}
                                        {tabSelectorValue == 0 &&
                                            <>
                                                <Grid container xs={4} sm={4} className="pl-15 pb-15 data-sample-section">
                                                    <Grid item xs={12} sm={12} className="mb-10">Data Sample</Grid>
                                                    <Grid item xs={5} sm={5} className='maxw-150 mr-15'>
                                                        {/* <FormControl fullWidth>
                                                        <InputLabel>From</InputLabel>
                                                        <Select
                                                        value={ monthArr && monthArr.length ? fromDate : ''}
                                                        label="From"
                                                        onChange={handleFromDateChange}
                                                        >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                            {
                                                            monthArr && monthArr.length && monthArr.map(item => {
                                                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl> */}
                                                        <Autocomplete
                                                            className='date-selection new-autocomplete'
                                                            fullWidth
                                                            disablePortal
                                                            disableListWrap
                                                            size='small'
                                                            value={monthArr && monthArr.length ? selectedOptions(fromDate, monthArr)[0] : { label: '', value: '' }}
                                                            options={monthArr}
                                                            onChange={handleFromDateChangeNew}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="test-input-box"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={`From`}
                                                                    placeholder={'Select'}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} className='maxw-150'>
                                                        {/* <FormControl fullWidth>
                                                            <InputLabel>To</InputLabel>
                                                            <Select
                                                            value={monthArr && monthArr.length ? toDate : ''}
                                                            label="To"
                                                            onChange={handleToDateChange}
                                                            >
                                                            <MenuItem value="">
                                                                <em>Select</em>
                                                            </MenuItem>
                                                                {monthArr && monthArr.length && monthArr.map(item => {
                                                                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                                })}
                                                            </Select>
                                                    </FormControl> */}
                                                        <Autocomplete
                                                            className='date-selection new-autocomplete'
                                                            fullWidth
                                                            disablePortal
                                                            disableListWrap
                                                            size='small'
                                                            value={monthArr && monthArr.length ? selectedOptions(toDate, monthArr)[0] : { label: '', value: '' }}
                                                            options={monthArr}
                                                            onChange={handleToDateChangeNew}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="test-input-box"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={`To`}
                                                                    placeholder={'Select'}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container xs={3} sm={3} className="pl-15 pb-15 assign-section">
                                                    <Grid item xs={12} sm={12} className="mb-10">Assign To</Grid>
                                                    <Grid item xs={5} sm={5} className='maxw-150 mr-15'>
                                                        {/* <FormControl  fullWidth size='small'>
                                                            <InputLabel>From</InputLabel>
                                                            <Select
                                                            value={monthArrFuture && monthArrFuture.length ? fromAssign : ''}
                                                            label="From"
                                                            onChange={handleAssignFromDateChange}
                                                            >
                                                            <MenuItem value="">
                                                                <em>Select</em>
                                                            </MenuItem>
                                                                {monthArrFuture && monthArrFuture.length && monthArrFuture.map(item => {
                                                                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl> */}
                                                        <Autocomplete
                                                            className='date-selection new-autocomplete'
                                                            fullWidth
                                                            disablePortal
                                                            disableListWrap
                                                            size='small'
                                                            value={monthArrFuture && monthArrFuture.length ? selectedOptions(fromAssign, monthArrFuture)[0] : { label: '', value: '' }}
                                                            options={monthArrFuture}
                                                            onChange={handleAssignFromDateChangeNew}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="test-input-box"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={`From`}
                                                                    placeholder={'Select'}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} className='maxw-150'>
                                                        {/* <FormControl  fullWidth size='small'>
                                                                <InputLabel>To</InputLabel>
                                                                <Select
                                                                value={monthArrFuture && monthArrFuture.length ? toAssign : ''}
                                                                label="To"
                                                                onChange={handleAssignToDateChange}
                                                                >
                                                                <MenuItem value="">
                                                                    <em>Select</em>
                                                                </MenuItem>
                                                                    {monthArrFuture && monthArrFuture.length && monthArrFuture.map(item => {
                                                                        return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                                    })}
                                                                </Select>
                                                        </FormControl> */}
                                                        <Autocomplete
                                                            className='date-selection new-autocomplete'
                                                            fullWidth
                                                            disablePortal
                                                            disableListWrap
                                                            size='small'
                                                            value={monthArrFuture && monthArrFuture.length ? selectedOptions(toAssign, monthArrFuture)[0] : { label: '', value: '' }}
                                                            options={monthArrFuture}
                                                            onChange={handleAssignToDateChangeNew}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="test-input-box"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={`To`}
                                                                    placeholder={'Select'}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={4} sm={4} className="pb-15 buttons-section">

                                                    <Grid item xs={1} sm={1} className='maxw-150 pr-20' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        {runTooltipText.length == 0 ?
                                                            <Button size="small" variant="contained" color="secondary" className="ml-5 newButton btnBlue btnRun uppercase" onClick={() => { TrySimulation(false) }}>Run</Button>
                                                            :
                                                            <LightTooltip classname='custom-tooltip' classes={{ tooltip: classes.customWidth }} placement="top" title={<div dangerouslySetInnerHTML={{ __html: runTooltipText }} />}>
                                                                <Button size="small" variant="contained" color="secondary" className="ml-5 newButton btnBlue btnRun uppercase" onClick={() => { TrySimulation(false) }}>Run</Button>
                                                            </LightTooltip>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} className='maxw-150 pr-20' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Button size="small" variant="outlined" color="primary" className="ml-5 newButtonOutlined btnBlue btnReset uppercase" onClick={() => { resetFilterData() }}>
                                                            Reset filters
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={1} sm={1} className="pr-20 pt-30 download-section" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='text-right'>
                                                        <Tooltip title={'Download to Excel'}>
                                                            <img
                                                                src={DownloadExcel}
                                                                className='pointer'
                                                                height='30'
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    TrySimulation(true)
                                                                    // handleExcelDownload();
                                                                }} />
                                                        </Tooltip>
                                                    </div>
                                                </Grid>



                                                <Grid item xs={12} sm={12} className="pt-0">
                                                    <TableComponent rowData={rowData} rowHeader={rowHeader} resetFilter={resetFilter} />
                                                </Grid>
                                            </>
                                        }

                                        {/* { tabSelectorValue == 1 &&
                                                    <>
                                                    <Grid item xs={12} sm={3} className='maxw-200 pr-0'>
                                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                        <InputLabel>From</InputLabel>
                                                        <Select
                                                        value={monthArrFuture && monthArrFuture.length ? fromAssign : ''}
                                                        label="From"
                                                        onChange={handleAssignFromDateChange}
                                                        >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                            {monthArrFuture && monthArrFuture.length && monthArrFuture.map(item => {
                                                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3} className='maxw-200'>
                                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                            <InputLabel>To</InputLabel>
                                                            <Select
                                                            value={monthArrFuture && monthArrFuture.length ? toAssign : ''}
                                                            label="To"
                                                            onChange={handleAssignToDateChange}
                                                            >
                                                            <MenuItem value="">
                                                                <em>Select</em>
                                                            </MenuItem>
                                                                {monthArrFuture && monthArrFuture.length && monthArrFuture.map(item => {
                                                                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3} className='maxw-200 pl-0'>
                                                    <Button size="small" variant="outlined" color="secondary" className="ml-5" onClick={TrySimulation}>
                                                        Run
                                                    </Button>
                                                    </Grid>
                                                <Grid item xs={12} sm={12} className="pt-0">
                                                    <TableComponent rowData={rowDataNorm} rowHeader={rowHeaderNorm} />
                                                </Grid>
                                                </>
                                            } */}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </DragDropContext>

                        <Popper id="column-row-popper" open={open} anchorEl={ref} placement="bottom" style={{ zIndex: "4" }}>
                            <ClickAwayListener onClickAway={handlePopperClickAwayHandler}>
                                <Box className="popper-box" sx={{ width: "350px !important", overflow: "auto" }}>
                                    <Filters itemId={itemId} itemType={itemType} itemArray={filters} filterStringOperators={filter_string_operator} onChange={handleFiltersChange} />
                                </Box>
                            </ClickAwayListener>
                        </Popper>
                    </div>
                </>
            )}
        </div>
    );
};

export default AddEditSimulation;
