import axios from 'axios';
import { CONFIG } from '../../config'

export async function performFetchInvoiceDetailService(payload) {
    let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/`
    if (payload && payload.receivableTabSelectorValue == 1) {
        apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/invoice/?new=true`
    }

    return axios.post(apiUrl, payload)
        .then(res => res)
        .catch(err => err.response)
}


export async function performFetchCreditLimitDetailService(payload) {

    let apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/data/credit-details/`
    if (payload && payload.receivableTabSelectorValue == 1) {
        apiUrl = `${CONFIG.api_base_url}v1/receivables/${payload.bu}/aging/New/data/credit-details/`
    }

    if (payload.export){
        return axios({
          url: `${apiUrl}?export=true`,
          method: 'POST',
          responseType: 'blob',
          data: payload
      })
          .then(
              response => {
                var x = response.data;
                console.log(response, x)
                var u = URL.createObjectURL(x)
                var a = document.createElement("a");
                a.href = u;
                a.download = (response.headers['content-disposition']).split('=')[1];
                a.click();
                window.URL.revokeObjectURL(u);
                  return response
              }
          )
          .catch(err => err.response)
      }


    return axios.post(apiUrl, payload)
        .then(res => res)
        .catch(err => err.response)
}
