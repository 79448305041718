export const transformFilterData = (filterData) => {
  let filterObj = {
    quarterfilterArr: [],
    loginCountArr: [],
    sessionTimeArr: [],
    userRoleArr: [],
    finalYearArr: [],
    userComboArr: [],
  }
  if (filterData && filterData.length) {
    filterData.forEach(item => {
      if (item.key == 'login_count') {
        filterObj.loginCountArr = item.value
      }
      if (item.key == 'month') {
        filterObj.quarterfilterArr = item.value
      }
      if (item.key == 'session_time') {
        filterObj.sessionTimeArr = item.value
      }
      if (item.key == 'user_role') {
        filterObj.userRoleArr = item.value
      }
      if (item.key == 'final_year') {
        filterObj.finalYearArr = item.value
      }
      if (item.key == 'user_filter') {
        filterObj.userComboArr = item.value
      }
    })
  }
  return filterObj
}
