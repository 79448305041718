import React, { useState, useEffect } from "react";
import HighchartComponent from './highchart'
import { useSelector } from "react-redux";
import { Grid } from '@material-ui/core';

let options = {
    chart: {
        type: 'pie'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: [],
        title: {
            useHTML: true,
            text: ''
        },
        crosshair: true
    },
    yAxis: {
        title: {
            useHTML: true,
            text: ''
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
   
    series: []
}



function PieChart(props) {
    const freeCashFlowState = useSelector(state => state.freeCashFlowState);
    const { loading, chartRowData } = freeCashFlowState.analyticStudio;
    const [optionState, setOptionState] = useState([])

    useEffect(() => {
        handleCreateChartData()
    }, [])

    useEffect(() => {
        handleCreateChartData()
    }, [chartRowData])

    const createCharts = () => {
        let optionsArray = []

        if(chartRowData.length) {
            chartRowData.forEach((chart_item, chart_index) => {
                let {dimension, measurement} = chart_item

                let additional_data_name = ''
                dimension.forEach((dim_item, dim_index) => {
                    additional_data_name += `${dim_item.value} - `
                })

                measurement.forEach(msm_item => {
                    const {label, type_of_data, period, value} = msm_item

                    let data_name = `${additional_data_name} ${type_of_data} - ${period}`

                    let selected = optionsArray.find(options_item => options_item.title.text == msm_item.label)

                    if(!selected) {

                        let options = {
                            chart: {
                                type: 'pie'
                            },
                            title: {
                                text: `${label}`
                            },
                            subtitle: {
                                text: ''
                            },
                            tooltip: {
                                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                    '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                                footerFormat: '</table>',
                                shared: true,
                                useHTML: true
                            },
                           
                            series: [{
                                colorByPoint : true,
                                name: 'Amount',
                                data: [createDataSeries({data_name, value})]
                            }]
                        }

                        optionsArray.push(options)

                    }
                    else {
                        let newData = createDataSeries({data_name, value})
                        selected.series[0].data.push(newData)
                    }
                })
            })
        }

        setOptionState(optionsArray)
    }

    const createDataSeries = ({data_name, value}) => {
        let result = {
            name : data_name,
            y : Math.abs(value)
        }
        return result
    }

    const handleCreateChartData = () => {

        // const { 
        //     // categoriesData,
        //     // yAxisTitle,
        //     // xAxisTitle,
        //     seriesObj 
        // } = createSeries()
        // console.log('trace 1', seriesObj)

        // options.series = [seriesObj]

        // setOptionState(options)

        createCharts();
      
    }

    return (
        <Grid container>
            {optionState && optionState.length ? optionState.map(item => {
                return (
                    <Grid item xs={6} sm={6} key={item.title.text}>
                        <HighchartComponent
                            options={item}
                        />
                    </Grid>
                )
            }) : ""}
        </Grid>
    )
    
}

export default PieChart;
