import BarChart from "./chart"
import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

function ChartContainer(props) {
    console.log(props, "-- data unbilled")
    return (
        <>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <BarChart
                                chartData={props.chartDataUnbilled}
                                showImprovementLineChart={props.showImprovementLineChart}
                                chartTitle={props.chartTitleUnbilled}
                                SeriesLabelEnabled={true}
                                SeriesLabelColor={"black"}
                                externalTabValue={props.externalTabValue}>
                            </BarChart>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <BarChart
                            
                                showImprovementLineChart={props.showImprovementLineChart}
                                chartData={props.chartDataDSOBUWise}
                                chartTitle={props.chartTitleDSOBUWise}
                                dsoChart={true}
                                SeriesLabelEnabled={true}
                                externalTabValue={props.externalTabValue}
                            ></BarChart>
                          </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ChartContainer;