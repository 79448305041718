import React, { useCallback, useState } from "react";
import "./Filters.scss";
import { Box, FormControl, InputLabel, MenuItem, Select, Grid } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";


export default function Filters({ itemId, itemType, itemArray, filterStringOperators, onChange }) {
    const [dataArray, setDataArray] = useState(itemArray);

    const handleOperatorchange = useCallback(
        (data, event) => {
            const value = event?.target?.value;
            console.log (value)
            setDataArray((prev) => {
                const index = prev.indexOf(data);
                if (index > -1) {
                    let dataTemp = prev[index];
                    dataTemp.operator_default_value = value;
                    onChange && onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];
            });
        },
        [setDataArray]
    );

    const handleOperatorchangeNew = useCallback(
        (data, event, newValue) => {

            const value = newValue? newValue.value : ''
            setDataArray((prev) => {
                const index = prev.indexOf(data);
                if (index > -1) {
                    let dataTemp = prev[index];
                    dataTemp.operator_default_value = value;
                    onChange && onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];
            });
        },
        [setDataArray]
    );

    const handleValueChange = useCallback(
        (data, event) => {
            const value = event?.target?.value;
            setDataArray((prev) => {
                const index = prev.indexOf(data);
                if (index > -1) {
                    let dataTemp = prev[index];
                    dataTemp.default_value = value;
                    onChange && onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];
            });
        },
        [setDataArray]
    );

    const handleCSValueChange = useCallback(
        (data, event, newValue) => {
            const value = newValue?.length>0 ? [...newValue].map((obj)=> (obj.value)) : []
            setDataArray((prev) => {
                const index = prev.indexOf(data);
                if (index > -1) {
                    let dataTemp = prev[index];
                    dataTemp.default_value = value;
                    onChange && onChange({ newItemArray: [...prev], itemId });
                }

                return [...prev];
            });
        },
        [setDataArray]
    );

    if (!(Array.isArray(dataArray) && dataArray.length > 0)) {
        return <div className="div-wait-report">No items added...</div>;
    }

    const buildMenuItems = (list) => {
        if (Array.isArray(list) && list.length > 0) {
            const sortedlist = [...list].sort((a,b)=> {
                const valueA = a.value||'';
                const valueB = b.value||''; 
                return valueA.localeCompare(valueB)});

            return sortedlist.map((item) => {
                return (
                    <MenuItem value={item?.value ? item?.value : item} key={item?.value ? item?.value : item}>
                        {item?.text ? item?.text : item}
                    </MenuItem>
                );
            });
        } else return null;
    };

    const sortedMenuItems = (list) =>{
        const sortedlist = [...list].sort((a,b)=> {
            const valueA = a.value||'';
            const valueB = b.value||''; 
            return valueA.localeCompare(valueB)});

        return sortedlist
    }


    const parseFilterOptions = (options, field) => {
        let tempArr = [];

        if (Array.isArray(options) && options.length > 0) {
            options.map((item) => {
                const value = item[field];
                if (typeof value === "string" && value !== "") {
                    tempArr.push({
                        text: value,
                        value: value,
                    });
                }
            });
        }
        return tempArr;
    };

    const getFieldsOptions = (options, countryDefaultValue, pmDefaultValue) => {
        const tempOptions = Array.isArray(options) ? options : [];
        const parsedCountryDefaultValue = Array.isArray(countryDefaultValue) ? countryDefaultValue : [];
        const parsedPMDefaultValue = Array.isArray(pmDefaultValue) ? pmDefaultValue : [];

        const tempCountyOptions = [],
            seenTempCountyOptions = new Set([]);

        tempOptions.forEach((item) => {
            const { filter_country = "" } = item;
            if (typeof filter_country === "string" && filter_country !== "" && !seenTempCountyOptions.has(filter_country)) {
                seenTempCountyOptions.add(filter_country);
                tempCountyOptions.push(item);
            }
        });

        const tempPMOptions = [],
            seenTempPMOptions = new Set([]);

        tempOptions.forEach((item) => {
            const { filter_country = "", filter_project_manager = "" } = item;
            if (
                typeof filter_project_manager === "string" &&
                filter_project_manager !== "" &&
                (parsedCountryDefaultValue.length === 0 || (parsedCountryDefaultValue.length > 0 && parsedCountryDefaultValue.includes(filter_country))) &&
                !seenTempPMOptions.has(filter_project_manager)
            ) {
                seenTempPMOptions.add(filter_project_manager);
                tempPMOptions.push(item);
            }
        });

        const tempCSOptions = [],
            seenTempCSOptions = new Set([]);

        tempOptions.forEach((item) => {
            const { filter_country = "", filter_project_manager = "", filter_customer_segment = "" } = item;
            if (
                typeof filter_customer_segment === "string" &&
                filter_customer_segment !== "" &&
                (parsedCountryDefaultValue.length === 0 || (parsedCountryDefaultValue.length > 0 && parsedCountryDefaultValue.includes(filter_country))) &&
                (parsedPMDefaultValue.length === 0 || (parsedPMDefaultValue.length > 0 && parsedPMDefaultValue.includes(filter_project_manager))) &&
                !seenTempCSOptions.has(filter_customer_segment)
            ) {
                seenTempCSOptions.add(filter_customer_segment);
                tempCSOptions.push(item);
            }
        });

        return {
            countryOptions: parseFilterOptions(tempCountyOptions, "filter_country"),
            pmOptions: parseFilterOptions(tempPMOptions, "filter_project_manager"),
            csOptions: parseFilterOptions(tempCSOptions, "filter_customer_segment"),
        };
    };

    const selectedOptions = (a, arr) =>{
        return a==null || a==undefined ?  [] : arr.filter((v) => a.indexOf(v.value) > -1);                      
    };

    const buildFilterFields = () => {
        if (itemType === "simulations") {
            const countryData = itemArray?.find((item) => item?._id === "filter_country") ?? {};
            const { default_value: countryDefaultValue = [], operator_default_value: countryOperatorDefaultValue = "", filter_values: filterValues = [] } = countryData;

            const pmData = itemArray?.find((item) => item?._id === "filter_project_manager") ?? {};
            const { default_value: pmDefaultValue = [], operator_default_value: pmOperatorDefaultValue = "" } = pmData;

            const csData = itemArray?.find((item) => item?._id === "filter_customer_segment") ?? {};
            const { default_value: csDefaultValue = [], operator_default_value: csOperatorDefaultValue = "" } = csData;

            const formulaData = itemArray?.find((item) => item?._id === "filter_formula") ?? {};
            const { default_value: formulaDefaultValue = [], operator_default_value: formulaOperatorDefaultValue = "" } = formulaData;

            const { countryOptions, pmOptions, csOptions } = getFieldsOptions(filterValues, countryDefaultValue, pmDefaultValue);




            const handleCountryValueChange = (data, event, newValue) => {
                const value = newValue?.length>0 ? [...newValue].map((obj)=> (obj.value)) : []
                setDataArray((prev) => {
                    const countryDataIndex = prev.indexOf(data);
                    if (countryDataIndex > -1) {
                        let countrydDataTemp = prev[countryDataIndex];
                        countrydDataTemp.default_value = value;
                    }

                    const pmDataIndex = prev.indexOf(pmData);
                    if (pmDataIndex > -1) {
                        let pmdDataTemp = prev[pmDataIndex];
                        pmdDataTemp.default_value = [];
                    }

                    const csDataIndex = prev.indexOf(csData);
                    if (csDataIndex > -1) {
                        let pmdDataTemp = prev[csDataIndex];
                        pmdDataTemp.default_value = [];
                    }

                    onChange && onChange({ newItemArray: [...prev], itemId });

                    return [...prev];
                });
            };


            const handlePMValueChange = (data, event, newValue) => {
                const value = newValue?.length>0 ? [...newValue].map((obj)=> (obj.value)) : []
                setDataArray((prev) => {
                    const pmDataIndex = prev.indexOf(data);
                    if (pmDataIndex > -1) {
                        let pmdDataTemp = prev[pmDataIndex];
                        pmdDataTemp.default_value = value;
                    }

                    const csDataIndex = prev.indexOf(csData);
                    if (csDataIndex > -1) {
                        let pmdDataTemp = prev[csDataIndex];
                        pmdDataTemp.default_value = [];
                    }

                    onChange && onChange({ newItemArray: [...prev], itemId });

                    return [...prev];
                });
            };

            return (
                <>
                    <Box className="commonfilter-box-option" key={"Country"}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Operator</InputLabel>
                                    <Select value={countryOperatorDefaultValue} label={`Operator`} multiple={false} onChange={(event) => handleOperatorchange(countryData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(filterStringOperators)}
                                    </Select>
                                </FormControl> */}

                                <Autocomplete
                                className = 'commonfilter-operator new-autocomplete'
                                fullWidth
                                disablePortal
                                disableListWrap
                                multiple={false}
                                size='small'
                                value={
                                    selectedOptions(countryOperatorDefaultValue,sortedMenuItems(filterStringOperators)).length>0 ? 
                                        selectedOptions(countryOperatorDefaultValue,sortedMenuItems(filterStringOperators))[0] : 
                                        {text:'', value:''}}
                                options={sortedMenuItems(filterStringOperators)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> handleOperatorchangeNew(countryData, event, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Operator`}
                                      placeholder={countryOperatorDefaultValue.length == 0 ? "Select" : ""}
                                    />
                                    )}
                            />



                            </Grid>
                            <Grid item xs={8}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Country</InputLabel>
                                    <Select value={countryDefaultValue} name="Country" label={"Country"} multiple={true} onChange={(event) => handleCountryValueChange(countryData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(countryOptions)}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                className = 'commonfilter-multiple new-autocomplete'
                                fullWidth
                                disablePortal
                                disableCloseOnSelect
                                limitTags={1}
                                disableListWrap
                                multiple={true}
                                size='small'
                                value={selectedOptions(countryDefaultValue,sortedMenuItems(countryOptions))}
                                options={sortedMenuItems(countryOptions)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue) => handleCountryValueChange(countryData, event, newValue)}                                
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Country`}
                                      placeholder={countryDefaultValue?.length == 0 ? "Select" : ""}
                                    />
                                    )}
                                renderTags={(value, getTagProps)=> 
                                    value.map((option, index) => {
                                        if (index==value.length-1) {return <span key={index}  style={{ fontSize: '14px' }}  {...getTagProps({ index })}>{option.text}</span>}
                                        else if (index<value.length-1){return <span key={index} style={{ fontSize: '14px' }} {...getTagProps({ index })}>{option.text}, </span>}
                                        })}
                            />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="commonfilter-box-option" key={"Project Manager"}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Operator</InputLabel>
                                    <Select value={pmOperatorDefaultValue} label={`Operator`} multiple={false} onChange={(event) => handleOperatorchange(pmData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(filterStringOperators)}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                className = 'commonfilter-operator new-autocomplete'
                                fullWidth
                                disablePortal
                                disableListWrap
                                multiple={false}
                                size='small'
                                value={
                                    selectedOptions(pmOperatorDefaultValue,sortedMenuItems(filterStringOperators)).length>0 ? 
                                        selectedOptions(pmOperatorDefaultValue,sortedMenuItems(filterStringOperators))[0] : 
                                        {text:'', value:''}}
                                options={sortedMenuItems(filterStringOperators)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> handleOperatorchangeNew(pmData, event, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Operator`}
                                      placeholder={pmOperatorDefaultValue.length == 0 ? "Select" : ""}
                                    />
                                    )}
                            />
                            </Grid>
                            <Grid item xs={8}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Project Manager</InputLabel>
                                    <Select value={pmDefaultValue} name="Project Manager" label={"Project Manager"} multiple={true} onChange={(event) => handlePMValueChange(pmData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(pmOptions)}
                                    </Select>
                                </FormControl> */}
                                
                                <Autocomplete
                                className = 'commonfilter-multiple new-autocomplete'
                                fullWidth
                                disablePortal
                                disableCloseOnSelect
                                limitTags={1}
                                disableListWrap
                                multiple={true}
                                size='small'
                                value={selectedOptions(pmDefaultValue,sortedMenuItems(pmOptions))}
                                options={sortedMenuItems(pmOptions)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue) => handlePMValueChange(pmData, event, newValue)}                                
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Project Manager`}
                                      placeholder={pmDefaultValue?.length == 0 ? "Select" : ""}
                                    />
                                    )}
                                renderTags={(value, getTagProps)=> 
                                    value.map((option, index) => {
                                        if (index==value.length-1) {return <span key={index}  style={{ fontSize: '14px' }}  {...getTagProps({ index })}>{option.text}</span>}
                                        else if (index<value.length-1){return <span key={index} style={{ fontSize: '14px' }} {...getTagProps({ index })}>{option.text}, </span>}
                                        })}
                            />

                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="commonfilter-box-option" key={"Customer Segment"}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Operator</InputLabel>
                                    <Select value={csOperatorDefaultValue} label={`Operator`} multiple={false} onChange={(event) => handleOperatorchange(csData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(filterStringOperators)}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                className = 'commonfilter-operator new-autocomplete'
                                fullWidth
                                disablePortal
                                disableListWrap
                                multiple={false}
                                size='small'
                                value={
                                    selectedOptions(csOperatorDefaultValue,sortedMenuItems(filterStringOperators)).length>0 ? 
                                        selectedOptions(csOperatorDefaultValue,sortedMenuItems(filterStringOperators))[0] : 
                                        {text:'', value:''}}
                                options={sortedMenuItems(filterStringOperators)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> handleOperatorchangeNew(csData, event, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Operator`}
                                      placeholder={csOperatorDefaultValue.length == 0 ? "Select" : ""}
                                    />
                                    )}
                            />
                            </Grid>
                            <Grid item xs={8}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Customer Segment</InputLabel>
                                    <Select value={csDefaultValue} name="Customer Segment" label={"Customer Segment"} multiple={true} onChange={(event) => handleValueChange(csData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(csOptions)}
                                    </Select>
                                </FormControl> */}

                                <Autocomplete
                                className = 'commonfilter-multiple new-autocomplete'
                                fullWidth
                                disablePortal
                                disableCloseOnSelect
                                limitTags={1}
                                disableListWrap
                                multiple={true}
                                size='small'
                                value={selectedOptions(csDefaultValue,sortedMenuItems(csOptions))}
                                options={sortedMenuItems(csOptions)}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue) => handleCSValueChange(csData, event, newValue)}                                
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Customer Segment`}
                                      placeholder={csDefaultValue?.length == 0 ? "Select" : ""}
                                    />
                                    )}
                                renderTags={(value, getTagProps)=> 
                                    value.map((option, index) => {
                                        if (index==value.length-1) {return <span key={index}  style={{ fontSize: '14px' }}  {...getTagProps({ index })}>{option.text}</span>}
                                        else if (index<value.length-1){return <span key={index} style={{ fontSize: '14px' }} {...getTagProps({ index })}>{option.text}, </span>}
                                        })}
                            />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="commonfilter-box-option" key={"Formula"}>
                        <Grid item xs={12} className="mt-40">
                            <h2>
                                <b>Formula :</b>
                            </h2>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <FormControl fullWidth>
                                    <InputLabel>Operator</InputLabel>
                                    <Select value={formulaOperatorDefaultValue ?? ""} label={`Operator`} multiple={false} onChange={(event) => handleOperatorchange(formulaData, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(formulaData?.operator_values ?? [])}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                className = 'commonfilter-operator new-autocomplete'
                                fullWidth
                                disablePortal
                                disableListWrap
                                multiple={false}
                                size='small'
                                value={
                                    selectedOptions(formulaOperatorDefaultValue,sortedMenuItems(formulaData?.operator_values ?? [])).length>0 ? 
                                        selectedOptions(formulaOperatorDefaultValue,sortedMenuItems(formulaData?.operator_values ?? []))[0] : 
                                        {text:'', value:''}}
                                options={sortedMenuItems(formulaData?.operator_values ?? [])}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> handleOperatorchangeNew(formulaData, event, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Operator`}
                                      placeholder={formulaOperatorDefaultValue?.length == 0 ? "Select" : ""}
                                    />
                                    )}
                            />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField size="small" variant="standard" type="number" fullWidth label={`Value`} value={formulaDefaultValue} onChange={(event) => handleValueChange(formulaData, event)} />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            );
        } else {
            return dataArray.map((data) => {
                const isMultiple = data?.isMultiple ?? true,
                    isInputField = data?.isInputField ?? false,
                    value=data?.operator_default_value ?? "",
                    options=data?._id === "filter_formula" ? data?.operator_values : data?.operator_values ?? filterStringOperators,
                    newOptions=sortedMenuItems(options),
                    newValue=selectedOptions(value,newOptions).length>0 ? selectedOptions(value,newOptions)[0] : {text:'', value:''};


                return (
                    <Box className="commonfilter-box-option" key={`${data?.label}`}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Operator</InputLabel>
                                    <Select value={data?.operator_default_value ?? ""} label={`Operator`} multiple={false} onChange={(event) => handleOperatorchange(data, event)}>
                                        <MenuItem value="" disabled>
                                            <em>Select</em>
                                        </MenuItem>
                                        {buildMenuItems(data?._id === "filter_formula" ? data?.operator_values : data?.operator_values ?? filterStringOperators)}
                                    </Select>
                                </FormControl>
                                {/* <Autocomplete
                                className = 'commonfilter-operator new-autocomplete'
                                fullWidth
                                disablePortal
                                disableListWrap
                                multiple={false}
                                size='small'
                                value={newValue}
                                options={newOptions}
                                getOptionLabel={(option)=> option.text}
                                onChange={(event, newValue)=> handleOperatorchangeNew(data, event, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    className="test-input-box"
                                      {...params}
                                      variant="standard"
                                      label = {`Operator`}
                                      placeholder={data?.operator_default_value?.length == 0 ? "Select" : ""}
                                    />
                                    )}
                            /> */}
                            </Grid>
                            <Grid item xs={8}>
                                {isInputField ? (
                                    <TextField type="number" fullWidth label={`${data?.label}`} value={data?.default_value} onChange={(event) => handleValueChange(data, event)} />
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel>{data?.label}</InputLabel>
                                        <Select value={data?.default_value} label={`${data?.label}`} multiple={isMultiple} onChange={(event) => handleValueChange(data, event)}>
                                            <MenuItem value="" disabled>
                                                <em>Select</em>
                                            </MenuItem>
                                            {buildMenuItems(data?.values)}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                );
            });
        }
    };

    return (
        <div className="filters-section">
            <h2 className="mb-5">
                <b>Filters :</b>
            </h2>
            {buildFilterFields()}
        </div>
    );
}
