import {transformFilterData, transformDefaultHeaderValues} from './transformer'

const initialState = {
    mapDataStatus: 'loading',
    mapData: {},
    chatData : {
        loading : false,
        data : {},
    },
};

export default (state = initialState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'FETCH_GEOSPACIAL_ANALYSIS_DATA_STATUS':
            newState.mapDataStatus = action.payload;
            return newState;
        case 'FETCH_GEOSPACIAL_ANALYSIS_DATA_SUCCESS':
            newState.mapDataStatus = action.payload?.status;
            newState.mapData = action.payload.data;
            return newState;
        case 'FETCH_GEOSPACIAL_ANALYSIS_DATA_FAIL':
            newState.mapDataStatus = action.payload?.status;
            newState.mapData = action.payload?.data;
            return newState;

        case 'FETCH_CHAT_DATA_SUCCESS' :
            newState.chatData.data = action.data
            return newState;
        case 'FETCH_CHAT_DATA_LOADER' :
            newState.chatData.loading = action.loading
            return newState;
        default:
            return newState;
    }
}
