import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Switch, Route } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import MapIcon from '@material-ui/icons/Map';

export default function Sidebar(props) {
  const [showOverview, setShowOverview] = React.useState(true);
  
  const handleOverviewClick = () => { 
    props.handleClickChangeScreenAnalysis("Overview")
    setShowOverview(true) 
  }

  const handleGeospatialClick = () => { 
    props.handleClickChangeScreenAnalysis("Geospatial")
    setShowOverview(false) 
  }

  return (
    <Drawer
      className="drawer-sidebar"
      variant="permanent"
      anchor="left"
    >
      <div className={showOverview ? "parent-toolbar active mt-65" : "parent-toolbar mt-65"} onClick={()=>handleOverviewClick()}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <div className="title">Overview</div>
      </div>
      {showOverview && (
        <div className="filter-sidebar">
          <div className="header-filter">Filters</div>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Company</FormLabel>
            <Select> 
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Field</FormLabel>
            <Select> 
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Platform</FormLabel>
            <Select> 
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Well</FormLabel>
            <Select> 
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">ESP Vendor</FormLabel>
            <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Cable Size (AWG)</FormLabel>
            <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small" className="form-filter">
            <FormLabel component="legend">Motor Size</FormLabel>
            <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
          </FormControl>
          <div className="btn-action flex jc-center ai-center mt-20">
            <Button variant="contained" size="small" color="primary" className="mr-20 btn-apply">
              Apply
            </Button>
            <Button variant="outlined" size="small" color="primary" className="btn-clear">
              Clear
            </Button>
          </div>
        </div>
        )
      }
      <div className={!showOverview ? "parent-toolbar active" : "parent-toolbar"}  onClick={()=>handleGeospatialClick()}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
        >
          <MapIcon />
        </IconButton>
        <div className="title">Geospatial View</div>
      </div>
    </Drawer>
  );
}
