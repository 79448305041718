import React from 'react';
import { connect } from "react-redux";
import ReactTableComponent from './TableComponent'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import 'jspdf-autotable'
import Grid from '@material-ui/core/Grid';

import { format } from 'date-fns/esm';
import {
  downloadExcel,
  pdfFileName,
  mapExcelData,
  mapExcelHeader,
  mapExcelHeaderComparativeAutomated,
  mapExcelDataComparativeAutomatedValidation,
  mapDataPDF,
  mapPDFDataPLComparative
} from '../../../utils/exportExcel'
export class AutomatedDataValidation extends React.Component {

  constructor(props) {
    super(props);
    this.excelData = {};
    this.state = {
      loading: true,
      tabSelectorValue: 0,
      radioSelectorValue: '0'
    }
  }

  componentDidMount() {
    this.props.getBUPLValidationData()
    // this.fetchNewDataValidation()
  }

  fetchIncomeStatementData = () => {
    this.props.getDataIS();
    // this.props.getBUPLValidationData()

  }

  fetchDSOData = () => {
    this.props.getDataDSO();
    // this.props.getBUPLValidationData()

  }
  fetchNewDataValidation = () => {
    const {radioSelectorValue} = this.state
    let payload = {
      apiEndPoint: radioSelectorValue
    }
    this.props.getDataNewDataValidation(payload);
    // this.props.getBUPLValidationData()

  }
  fetchReceivableData = () => {
    this.props.getReceivableDataIS();
  }

  handleRadioChange = (event) => {

    this.setState({
        radioSelectorValue: event.target.value,
    },() =>{
      this.fetchNewDataValidation()
    })

};

  handleTabChange = (event, newValue) => {
    this.setState({ tabSelectorValue: newValue }, () => {
      if (newValue == 0) {
        this.props.getBUPLValidationData()
      } else if (newValue == 1) {
        this.fetchReceivableData()
      } else if (newValue == 2) {
        this.props.getBUPLValidationData()
      } else if (newValue == 3) {
        this.fetchDSOData()
      }  else if (newValue == 4) {
        this.fetchNewDataValidation()
      }
    })
  };

  handlePostManualAmountData = (payload) => {
    let data = { ...payload, isReceivable: this.state.tabSelectorValue }
    this.props.postManualAmountData(data)
  }
  handleExcelDownload = () => {
    let data = []
    let header = {};
    let merge = [];
    
    let headerExcel = this.excelData.header
    console.log(headerExcel, "testnewDataheaderExcel")
    let headerData = this.props.incomeStatementState.automationHeaderData
    let rowData =  this.props.incomeStatementState.automationRowData
    let newValue = this.state.tabSelectorValue
    let radioSelectorValue = this.state.radioSelectorValue
    let nameFile = ""
    if (newValue == 0) {
      nameFile ="P&L raw data validation"
    } else if (newValue == 1) {
      nameFile ="RECEIVABLES"
    } else if (newValue == 2) {
      nameFile ="P&L raw data validation"
    } else if (newValue == 3) {
      nameFile ="Receivables - DSO Validation"
    }  else if (newValue == 4) {
      nameFile ="New Validation"
    }
if (newValue == 4 && radioSelectorValue == 2) {
  data = mapExcelData(rowData, headerExcel, "id")
      header = mapExcelHeader(headerExcel, "Header")
}else{
  let newHeader = mapExcelHeaderComparativeAutomated(headerExcel, "label");
    data = mapExcelDataComparativeAutomatedValidation(rowData, headerData, headerExcel, "id")
    header = newHeader.data;
    merge = newHeader.merge;
    console.log(data, "testnewData")
    console.log(newHeader, "testnewHeader")
}
   
  
    // if (this.state.tabSelectorValue == 1 || (this.state.tabSelectorValue == 0 && this.state.comparativeSelectorValue == 1)) {
    //     let datas = this.excelData
    //     header = datas.header;
    //     datas.data = this.unwindHeaderKeyVal(datas.data);
    //     datas.data = this.formatComments(datas.data)
    //     data = mapExcelData(datas.data, header, "id")
    //     header = mapExcelHeader(header, "headerTitle")
    // } else {
    //     let datas = this.excelDataComparison
    //     header = datas.header;
    //     console.log(header, "testcoloms")
    //     datas.data = datas.data.map(x => {
    //         let comm = "";
    //         if (x.sub_column.comments && x.sub_column.comments.length) {
    //             x.sub_column.comments.forEach((y, idx) => {
    //                 comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
    //                 if (idx != x.sub_column.comments.length - 1) {
    //                     comm += "\n"
    //                 }
    //             })
    //         }
    //         x.comments = comm
    //         return x
    //     })
    //     data = mapExcelDataPLComparative(datas.data, header, "id")
    //     let newHeader = mapExcelHeaderComparative(header, "Header");
    //     console.log(data, "testcoloms1")
    //     console.log(newHeader, "testcoloms2")
    //     header = newHeader.data;
    //     merge = newHeader.merge;
    // // }
  
    let fileName = "AUTOMATED DATA VALIDATION - " + nameFile + "_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
    if (this.financialDuration) {
        fileName = fileName + " - " + this.financialDuration
    }
    console.log(data, fileName, header, false, merge)
    const { currentValue, previousValue } = this.props.incomeStatementState;
    let sheetName = 'Data';
    if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
      sheetName = `${currentValue} - ${previousValue}`
    }
    downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
}
  addBUPLValidationData = (payload) => {
    console.log(payload)
    // this.props.postBUPLValidationData(data)
  }
  handleExcelDataChange = (data, header) => {
    this.excelData.data = data;
    this.excelData.header = header;
}
  render() {

    const { tabSelectorValue, radioSelectorValue } = this.state;
    return (
      
      <div className='buplComponent'>
        <Grid container justify="flex-end">
        {tabSelectorValue != 0 &&
        <Tooltip title={'Download to Excel'} className='pointer'>
            <img
                src={DownloadExcel}
                className='pointer'
                height='30'
                onClick={(event) => {
                    event.preventDefault();
                    this.handleExcelDownload();
                }} />
        </Tooltip>}
       </Grid>
        
        <Tabs
          value={tabSelectorValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className="mb-20"
        >
         
          <Tab label="P&L raw data validation" />
    
        </Tabs>
      
        <ReactTableComponent
          postManualAmountData={this.handlePostManualAmountData}
          handleExcelDataChange={this.handleExcelDataChange}
          postBUPLValidationData={(data) => this.props.postBUPLValidationData(data)}
          tabSelectorValue= {tabSelectorValue}
          radioSelectorValue={radioSelectorValue}
        />
      </div>
    );
  }
}

export default
  connect(
    state => {
      return {
        incomeStatementState: state.incomeStatement,
      }
    },
    dispatch => {
      return {
        getDataIS() {
          dispatch({
            type: 'FETCH_AUTOMATION_IS',
          });
        },
        getDataDSO() {
          dispatch({
            type: 'FETCH_AUTOMATION_DSO',
          });
        },
        getDataNewDataValidation(data) {
          dispatch({
            type: 'FETCH_AUTOMATION_new_DATA_VALIDATION',
            payload: data
          });
        },
        getReceivableDataIS() {
          dispatch({
            type: 'FETCH_AUTOMATION_RECEIVABLE',
          });
        },
        postManualAmountData(data) {
          dispatch({
            type: 'POST_MANUAL_AMOUNT_DATA',
            payload: data
          });
        },
        getBUPLValidationData() {
          dispatch({
            type: 'FETCH_BUPL_VALIDATION_DATA',
          });
        },
        postBUPLValidationData(data) {
          dispatch({
            type: 'POST_BUPL_VALIDATION_AMOUNT_DATA',
            payload: data
          });
        },
      };
    }
  )(AutomatedDataValidation);





