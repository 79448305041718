import { transformFilterData, appendActionOwner } from './transformer'

const reducer = (state = {
   loading: false,
   tableRowData: [],
   totalTableRowCount: 0,
   tableRowHeader: [],
   createChannel: false,
   newRowItem: {},
   yearMonthArr: [],
   agingCategoryArr: [],
   actionOwnerArr: [],
   customerNameArr: [],
   dayFilterArr: [],
   regionArr: []
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {

      case 'FETCH_DAY_REPORT_LOADER':
         return { ...state, loading: action.loading };

      case 'FETCH_DAY_REPORT_DATA_SUCCESS':
         newState.loading = action.loading;
         newState.tableRowData = action.data.row_data;
         newState.tableRowHeader = action.data.row_header;
         newState.totalTableRowCount = action.data.total_count;

         return newState;

      case 'FETCH_COMMENT_HISTORY_DAY_ALL_SUCCESS':

         newState.tableRowData = action.data;
         return newState

      case 'CREATE_CHANNEL_DAY_SUCCESS':

         newState.createChannel = action.payload;
         newState.tableRowData = action.data;
         newState.newRowItem = action.updatedItem;
         return newState

      case 'CHANGE_CREATE_CHANNEL_DAY_FLAG':

         newState.createChannel = action.payload;
         if (!action.payload) {
            newState.newRowItem = {}
         }
         return newState

      case 'FETCH_COMMENT_HISTORY_ALL_DAY_SUCCESS':

         newState.tableRowData = action.data;
         return newState

      case 'SEND_COLLECTION_FORECAST_DAY_SUCCESS':

         newState.tableRowData = action.data;
         return newState

      case 'UPDATE_DAY_DATA_SET':

            newState.tableRowData = action.payload;
            return newState

         case 'UPDATE_ACTION_OWNER_DAY':

            newState.tableRowData = appendActionOwner(newState.tableRowData, action.payload);
            return newState

      case 'FETCH_DAY_FILTER_DATA_SUCCESS':

         const {yearMonthArr, agingCategoryArr, actionOwnerArr, customerNameArr, regionArr } = transformFilterData(action.data)
         newState.yearMonthArr = yearMonthArr;
         newState.agingCategoryArr = agingCategoryArr;
         newState.actionOwnerArr = actionOwnerArr;
         newState.customerNameArr = customerNameArr;
         newState.regionArr = regionArr;
         newState.dayFilterArr = action.data;
         return newState

      default:
         return newState;
   }
};
export default reducer;







