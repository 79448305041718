import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from "../../Shared/InvoiceDetail";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDate } from '../../../../utils/dateFormat'
import TextField from '@material-ui/core/TextField';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class FunctionalTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.chatDataCollection = {}
        this.sortList = {}
        this.levelMap = {
            PlTotal: 'P&L Total',
            PlGroup: 'P& L Group',
            GroupName2: 'Group Name2',
            GroupName: 'Group Name',
            GroupName3: 'Group Name3',
            AccountName: 'Account Name',
            CostCenter: 'Cost Centre2'
        }
        this.state = {
            loading: true,
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            filterSelectorValue: [],
            showLoader: false,
            invoiceObject: null
        }
        this.excelData = []
    }

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }

    addExcelDataMain = (data, level, idx) => {
        idx += 1;
        let excelData = this.excelData;
        let curLevel = excelData[idx] && excelData[idx].custom_level;
        let prevLevel = excelData[idx - 1] && excelData[idx - 1].custom_level;
        // console.log(curLevel, prevLevel,"level")
        if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
            excelData = this.removeExcelDataMain(data, curLevel)
        } else {
            level += 1
            data && data.forEach((x, i) => { //inject data after the parent
                x.sub_column.current.data_field = this.addSpaceLevel(x.sub_column.current.data_field, level)
                // console.log(x.custom_level)
                excelData.splice(idx + i, 0, x)
            })
        }
        this.excelData = excelData
        console.log(data, this.excelData, "dataax1")
    }

    removeExcelDataMain = (data, idx) => {
        let excelData = this.excelData;
        let fields = data[0].sub_column.current.levels_field
        excelData = excelData.filter((val, i) => {
            // console.log(i >= idx && val.pl_total == data[0].pl_total)
            // console.log(i, idx ,val.pl_total , data[0].pl_total)
            return !(val.custom_level >= idx && val.sub_column.current[fields[0]] == data[0].sub_column.current[fields[0]])
        })
        console.log(excelData, "dataax2")
        return excelData
    }

    addExcelData = (dataval, header) => {
        let excelData = this.excelData;
        let data = JSON.parse(JSON.stringify(dataval))
        data.sub_rows = this.checkLevel(data.sub_rows, data.custom_level);
        // console.log(data,dataval,header,"asdasdasdasd")
        if (excelData.length == 0) {
            this.excelData = data;
        } else {
            let fields = data.sub_column.current.levels_field
            switch (data.custom_level) {
                case 0:
                    var idx = excelData.findIndex((val) => (
                        val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 1:
                    var idx = excelData.findIndex((val) => (
                        val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                        val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 2:
                    var idx = excelData.findIndex((val) => (
                        val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                        val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                        val.sub_column.current[fields[2]] == data.sub_column.current[fields[2]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 3:
                    var idx = excelData.findIndex((val) => (
                        val.sub_column.current[fields[1]] == data.sub_column.current[fields[1]] &&
                        val.sub_column.current[fields[0]] == data.sub_column.current[fields[0]] &&
                        val.sub_column.current[fields[2]] == data.sub_column.current[fields[2]] &&
                        val.sub_column.current[fields[3]] == data.sub_column.current[fields[3]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
            }
        }
        this.handleExcelDataChange(this.excelData, header)
    }

    handleTooltipData = (props) => {
        let { custom_level, levels } = props.original;
        let tooltipData = ''
        // if (levels && levels.length) {
        //     tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
        // }
        return tooltipData;
    }

    handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={
                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>
                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }
        return tooltipData;
    }

    dynamicHeaderValueOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].label : item.label
            return data
        }
        return item.label
    }

    dynamicHeaderKeyOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].key : item.key
            return data
        }
        return item.key
    }

    handleChatChangeFn = (event, rowData) => {
        if (event.target && event.target.value) {
            this.chatDataCollection[rowData.data_field] = event.target.value;
        }
    }

    handleChatSendFn = (rowData, groupName) => {
        let sendChatObject = {}
        if (document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value) {
            sendChatObject = {
                comment: document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value,
                commentRowId: rowData.original.data_field && rowData.original.data_field.toLowerCase(),
                view: rowData.original.data_field,
                groupName: groupName,
                reportDate: rowData.original.current_month
            }
            this.props.handleCommentSendFn(sendChatObject)
            this.chatDataCollection[rowData.original.data_field] = ''
            document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value = ''
        }
    }

    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                item['operation'] = item.key == 'budget_group' ? '' : 7;
                if (item.key == 'budget_group') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: `#DEE3EC` } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal[item.key]
                            let tooltipData = this.handleTooltipData(props)
                            return (
                                <div>
                                    <Tooltip title={tooltipData}>
                                        <span>{handleTableTransform(newOriginal, item)}</span>
                                    </Tooltip>
                                </div>

                            )
                        }
                    }
                }
                else if (item.key == 'comments') {
                    return {
                        Header: item.label,
                        id: item.key,
                        accessor: item.key,
                        headerStyle: {
                            minWidth: '350px',
                            borderRight: '1px solid #423970'
                        },
                        style: {
                            width: "55px",
                            whiteSpace: "break-spaces",
                            overflow: "visible",
                            minWidth: '350px',
                            borderRight: '1px solid #423970'
                        },
                        columns: [
                            {
                                Header: '',
                                id: this.dynamicHeaderKeyOfColumn(item, 0),
                                headerStyle: {
                                    minWidth: '350px',
                                    borderRight: '1px solid #423970'
                                },
                                style: {
                                    width: "55px",
                                    whiteSpace: "break-spaces",
                                    overflow: "visible",
                                    minWidth: '350px',
                                    borderRight: '1px solid #423970',
                                    paddingLeft: '10px'
                                },
                                getProps: (state, rowInfo, column) => {
                                    if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                        return { style: { background: `#DEE3EC` } }
                                    }
                                    return {}
                                },
                                Cell: (props) => {
                                    return <>
                                        {props.original.budget_group !== 'Sub Total' && <div className='commentContainerTable'>
                                            {props.original.data_field == 'Total Cost' ? <React.Fragment></React.Fragment> :
                                                <React.Fragment>
                                                    <div className='commentInputSection flex ai-center pl-0'>
                                                        <TextField
                                                            id={`outlined-basic-totalExpense-${props.original.data_field}`}
                                                            label="Type your Comment"
                                                            name="comment"
                                                            placeholder="Comments"
                                                            multiline
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(event) => { this.handleChatChangeFn(event, props.original) }}
                                                        />
                                                        <input style={{ display: 'none' }} type="file" />
                                                        <Tooltip title='Send'>
                                                            <IconButton onClick={() => { this.handleChatSendFn(props, 'totalExpense') }} color="primary" aria-label="upload picture" className='px-0 ml-5'>
                                                                <Icon>send</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    { props.original.sub_column.comments  && props.original.sub_column.comments.length ? <div className='commentArea text-left'>
                                                        {props.original &&
                                                            props.original.sub_column &&
                                                            props.original.sub_column.comments &&
                                                            props.original.sub_column.comments.length
                                                            ? props.original.sub_column.comments.map((item, index) => {
                                                                return (
                                                                    <div className='block' key={index}>
                                                                        <div className="date">{item.created_by}, {formatDate(item.created_at)}</div>
                                                                        <div>{item.comment}</div>
                                                                    </div>
                                                                );
                                                            })
                                                            : ""}
                                                    </div> : ""}
                                                </React.Fragment>}
                                        </div>}
                                    </>
                                },
                            }
                        ]
                    }

                }
                else if (item.key == 'total_expense') {
                    return {
                        Header: item.label,
                        width: 300,
                        headerStyle: { borderRight: '1px solid #423970', minWidth: '300px', },
                        style: { borderRight: '1px solid #423970', minWidth: '300px', },
                        columns: [
                            {
                                Header: this.dynamicHeaderValueOfColumn(item, 0),
                                id: this.dynamicHeaderKeyOfColumn(item, 0),
                                getProps: (state, rowInfo, column) => {
                                    if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                        return { style: { background: `#DEE3EC` } }
                                    }
                                    return {}
                                },
                                Cell: (props) => {
                                    let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column['total_expense'] && props.original.sub_column['total_expense'].actual, item.key)
                                    let tooltipData = this.handleTooltipData(props)
                                    return (
                                        <div>
                                            <Tooltip title={tooltipData}>
                                                <span>{this.modifyColumnData(newOriginal)}</span>
                                            </Tooltip>
                                        </div>

                                    )
                                }
                            },
                            {
                                Header: this.dynamicHeaderValueOfColumn(item, 1),
                                id: this.dynamicHeaderKeyOfColumn(item, 1),
                                getProps: (state, rowInfo, column) => {
                                    if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                        return { style: { background: `#DEE3EC` } }
                                    }
                                    return {}
                                },
                                Cell: (props) => {
                                    let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column['total_expense'] && props.original.sub_column['total_expense'].budget, item.key)
                                    let tooltipData = this.handleTooltipData(props)
                                    return (
                                        <div>
                                            <Tooltip title={tooltipData}>
                                                <span>{this.modifyColumnData(newOriginal)}</span>
                                            </Tooltip>
                                        </div>

                                    )
                                }
                            },
                            {
                                Header: this.dynamicHeaderValueOfColumn(item, 2),
                                id: this.dynamicHeaderKeyOfColumn(item, 2),
                                headerStyle: {
                                    borderRight: '1px solid #423970'
                                },
                                style: {
                                    borderRight: '1px solid #423970'
                                },
                                getProps: (state, rowInfo, column) => {
                                    if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                        return { style: { background: `#DEE3EC` } }
                                    }
                                    return {}
                                },
                                Cell: (props) => {
                                    let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column['total_expense'] && props.original.sub_column['total_expense'].compare, item.key)
                                    let tooltipData = this.handleTooltipData(props)
                                    return (
                                        <div>
                                            <Tooltip title={tooltipData}>
                                                <span>{this.modifyColumnData(newOriginal)}</span>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            }
                        ],
                        id: item.key
                    }
                }

                return {
                    Header: item.label,
                    width: 300,
                    headerStyle: { borderRight: '1px solid #423970', minWidth: '300px', },
                    style: { borderRight: '1px solid #423970', minWidth: '300px', },
                    columns: [
                        {
                            Header: this.dynamicHeaderValueOfColumn(item, 0),
                            id: this.dynamicHeaderKeyOfColumn(item, 0),
                            getProps: (state, rowInfo, column) => {
                                if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                    return { style: { background: `#DEE3EC` } }
                                }
                                return {}
                            },
                            Cell: (props) => {
                                let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column['tsa'] && props.original.sub_column['tsa'].actual, item.key)
                                let tooltipData = this.handleTooltipData(props)
                                return (
                                    <div>
                                        <Tooltip title={tooltipData}>
                                            <span>{this.modifyColumnData(newOriginal)}</span>
                                        </Tooltip>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: this.dynamicHeaderValueOfColumn(item, 1),
                            id: this.dynamicHeaderKeyOfColumn(item, 1),
                            getProps: (state, rowInfo, column) => {
                                if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                    return { style: { background: `#DEE3EC` } }
                                }
                                return {}
                            },
                            Cell: (props) => {
                                let newOriginal = props.original.sub_column && props.original.sub_column['tsa'] && props.original.sub_column['tsa'].budget
                                let tooltipData = this.handleTooltipData(props)
                                return (
                                    <div>
                                        <Tooltip title={tooltipData}>
                                            <span>{this.modifyColumnData(newOriginal)}</span>
                                        </Tooltip>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: this.dynamicHeaderValueOfColumn(item, 2),
                            id: this.dynamicHeaderKeyOfColumn(item, 2),
                            getProps: (state, rowInfo, column) => {
                                if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                    return { style: { background: `#DEE3EC` } }
                                }
                                return {}
                            },
                            headerStyle: {
                                borderRight: '1px solid #423970'
                            },
                            style: {
                                borderRight: '1px solid #423970'
                            },
                            Cell: (props) => {
                                let newOriginal = this.handleDifferentBUResponse(props.original.sub_column && props.original.sub_column['tsa'] && props.original.sub_column['tsa'].compare, item.key)
                                let tooltipData = this.handleTooltipData(props)
                                return (
                                    <div>
                                        <Tooltip title={tooltipData}>
                                            <span>{this.modifyColumnData(newOriginal)}</span>
                                        </Tooltip>
                                    </div>
                                )
                            }
                        },
                    ],
                    id: item.key
                }
            })
        }
        return []
    }

    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    subComponent = (row) => {
        let { pivotHeaderData } = this.props.incomeStatementState;
        const { radioSelectorValue } = this.props
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        console.log(nestedFlag, "checkNested")
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }

    columnHeaderClick = (state, someData, colInfo) => {
        const { showCursor } = this.props;
        if (showCursor) {
            return {
                onClick: (e) => {
                    this.handleColumnHeaderClick(colInfo)
                },
                style: { cursor: 'pointer' }
            }
        }
        return {}
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        console.log(state, colInfo, columnDetail, etc, event.target.value)
        const { data_field, parents } = colInfo.original;
        const { id, headerTitle } = columnDetail;
        const { legalEntitySelectorValue, plantSelectorValue } = this.props

        let dataVal = parents && parents.length ? [...parents, data_field].join(',') : data_field;
        if (headerTitle) {
            this.setState({
                showLoader: true,
                invoiceObject: { grid_header: id == 'grand_total' ? '' : id, level_values: dataVal }
            })
            this.props.getInvoiceDetail({ legal_entity: legalEntitySelectorValue, plant: plantSelectorValue, grid_header: id == 'grand_total' ? '' : id, level_values: dataVal });
            this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${checkNan(colInfo.row[columnDetail.id])}`
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                // this.handleColumnClick(state, rowInfo, column, instance, e)
                if (column.expander) {
                    let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                    let nestedFlag = this.checkForNestedLevel(pivotRowData)
                    pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                    this.addExcelData(rowInfo.original, pivotHeaderData);
                    // console.log(rowInfo,"cek")
                    handleOriginal()
                }
            }
        }
    }

    modifyColumnData = (columnData) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData
        return modifiedData
    }

    prepareExcelData = () => {
        this.excelData = []
        this.handleExcelDataChange([], [])
        let { pivotRowData, pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        pivotRowData = this.checkLevel(pivotRowData);
        this.addExcelData(pivotRowData, pivotHeaderData);
    }

    componentDidUpdate(prevProps) {
        this.prepareExcelData();
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
            this.setState({
                showInvoiceDetailModal: true,
                showLoader: false
            })
        }
    }

    componentDidMount() {
        this.prepareExcelData();
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value

        }
        this.setState({
            invoiceObject: payload
        })
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false
        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }


    render() {
        let { invoiceDetailStateFilter, pivotRowData, pivotHeaderData, loading, invoiceDetailState, invoiceLoading } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const { showInvoiceDetailModal, showLoader } = this.state
        let tableLen = pivotRowData && pivotRowData.length || 6;
        pivotRowData = this.checkLevel(pivotRowData);

        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                {<ReactTable
                    data={pivotRowData}
                    className={nestedFlag ? 'xpna-react-table functional-pl expandableRows' : 'xpna-react-table -striped -highlight'}
                    loading={loading}
                    columns={pivotHeaderData}
                    defaultPageSize={6}
                    pageSize={tableLen}
                    showPagination={false}
                    sortable={false}
                />}
                {showInvoiceDetailModal &&
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        invoiceDetailStateFilter={invoiceDetailStateFilter}
                        invoiceLoading={invoiceLoading}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleFilterChange={this.handleInvoiceFilterChange}
                        handleInvoiceSorting={this.handleInvoiceSorting}

                    />
                }
            </>
        );
    }
}

export default FunctionalTableComponent;
