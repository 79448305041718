import React from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import NewSummaryTableComponent from './TableComponent'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SimpleModal from './DrillDown/FunctionDrillDown'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class RevenueByRegion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            drillDownLoader: true,
            drillDownRowData : {},
            drillDownRowHeader: {},
            showDrillDownModal: false,
            renderExcel: true,
            payloadDrillDown: null,
            tabSelectorValue: 0,
        }
    }

    componentDidMount() {
        this.handleNewAccess()
        this.handleFetchNewData();
    }

    handleNewAccess = () => {
        let tabList = [
            { text: 'BU 1', value: 'BU 1' },
            { text: 'BU 2', value: 'BU 2' },
            { text: 'BU 3', value: 'BU 3' },
            { text: 'BU 4', value: 'BU 4' },
            { text: 'Total', value: 'Total' },
        ]
        this.setState({ NewTabList: tabList })


    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    refreshChart = () => {
        this.setState({
          chartDataSO: this.props.incomeStatementState.pivotRowDataChart,
          additionalData :  this.props.incomeStatementState.additionalData,
          TableDataSO: this.props.incomeStatementState.pivotRowData,
          HeaderTableDataSO: this.props.incomeStatementState.pivotHeaderData,
        })
      }
    componentDidUpdate(prevProps) {
        // const { yearFilterArr, defaultYear, defaultMonth, lastIngestion, radioArr, drilldownLoader, drilldownRowHeader, drilldownRowData } = this.props.incomeStatementState;
        const { drilldownLoader, drilldownRowHeader, drilldownRowData } = this.props.incomeStatementState;
        
        if (!drilldownLoader && drilldownRowData && this.state.drillDownLoader) {
            this.setState({
                drillDownLoader : false,
                drillDownRowData : this.props.incomeStatementState.drilldownRowData,
                drillDownRowHeader : this.props.incomeStatementState.drilldownRowHeader
            });
        }
    }

    

    handleFetchNewData = () => {
        let BUType = this.getBULabel()
        let payload = {
            bu : BUType
        }

        this.props.getServiceRevenueByRegion(payload);
    }

    handleTabChange = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        this.setState({
            tabSelectorValue: newValue,
            isDetailedView: false,
            radioSelectorReconValue: '0',
            radioSelectorValue: '0',
            hemisphereClicked: {}
        }, () => {
            if (newValue != 5) {
                this.handleFetchNewData()
            }
        })
        // if (newValue == 5 ){
        //     this.setState({
        //         monthSelectorValue : this.props.incomeStatementState.defaultMonth &&
        //         this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonth : '' ,
        //         tabSelectorValue: newValue,
        //         yearSelectorValue :  '',
        //         isDetailedView: false,
        //         radioSelectorReconValue: '0',
        //         radioSelectorValue: '0',
        //         hemisphereClicked: {}
        //     }, () => {
        //         if (newValue != 4) {
        //             this.handleFetchNewData()
        //         }
        //     })
        // }else{
        //     this.setState({
        //         tabSelectorValue: newValue,
        //         isDetailedView: false,
        //         radioSelectorReconValue: '0',
        //         radioSelectorValue: '0',
        //         hemisphereClicked: {}
        //     }, () => {
        //         if (newValue != 4) {
        //             this.handleFetchNewData()
        //         }
        //     })
        // }
       
    }

    handleInvoiceClick = (payload, params) => {
        payload.bu = this.getBULabel()

        this.props.getDrillDownServiceRevenueByRegion(payload);
        this.setState({
            showDrillDownModal : true,
            payloadDrillDown: payload
        })

        this.showDrilldownHeaderValue = params;
    }

    
    getBULabel = () =>{
        let BUType = 'BU 1'

        switch (this.state.tabSelectorValue) {
            case 1:
                BUType = 'BU 2';
                break;
            case 2:
                BUType = 'BU 3';
                break;
            case 3:
                BUType = 'BU 4';
                break;
            case 4:
                BUType = 'Total';
                break;
            default:
                BUType = 'BU 1';
                break;
        }

        return BUType
    }

    handleExcelDownload = () => {

        let BUType = this.getBULabel()
        let payload = {
            bu : BUType,
            export : true,
        }

        this.props.getServiceRevenueByRegion(payload);
    }
    
    functionalDrilldownModalCallBack = () => {
        this.excelData = []
        this.setState({ showDrillDownModal: false },   this.updateExcel);
    }

    render() {
        const { loading, monthFilterArr, drilldownRowHeader, drilldownRowData } = this.props.incomeStatementState
      
        const { payloadDrillDown, showDrillDownModal, tabSelectorValue, NewTabList } = this.state;
        console.log(monthFilterArr[0], "checkProps")
        return (
            <>
                {showDrillDownModal && drilldownRowHeader && drilldownRowHeader.length && drilldownRowData && drilldownRowData.length ? (
                    <SimpleModal 
                        showModal={showDrillDownModal}
                        modalCallBack={this.functionalDrilldownModalCallBack}
                        drilldownRowHeader={drilldownRowHeader}
                        drilldownRowData={drilldownRowData}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        getInvoiceDetail = {this.props.getDrillDownServiceRevenueByRegion}
                        payloadDrillDown = {payloadDrillDown}
                    />
                ) : ''}
                {loading? <div className='progressfullpage'><CircularProgress /></div> : (
                    <div className='buplComponent'>
                        <div className='flex jc-space-between'>
                            <Grid container spacing={1}>
                                    <Grid item xs={12} sm={1} className={'mt-8'} >
                                        <Tooltip title="Back">
                                            <Button variant='contained' color="primary" className='back-button' onClick={this.props.handleBack}><ChevronLeftIcon /></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={11} className={'mt-15'} >
                                        <h2 className='mb-20'> {'New BU 1 Service Revenue by Region'}</h2>
                                    </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
        
                                <Tooltip title={'Download to Excel'} className='pointer'>
                                    <img
                                        src={DownloadExcel}
                                        className='pointer'
                                        height='30'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.handleExcelDownload();
                                        }} />
                                </Tooltip>
                            </Grid>
                        </div>
                        <div>
                            <Tabs
                                value={tabSelectorValue}
                                onChange={this.handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className='mt-16 mb-16'
                            >
                                {NewTabList && NewTabList.length ?
                                    NewTabList.map((tabItem, index) => {
                                        return <Tab label={tabItem.value}  {...this.a11yProps(index)} />
                                    })
                                    : ''}
                            </Tabs>
                        </div>
    
                        <NewSummaryTableComponent
                            {...this.props}
                            tabSelectorValue={tabSelectorValue}
                            handlePropsInvoiceClick={this.handleInvoiceClick}
                            fetchMainData={this.handleFetchNewData}
                        />
                </div>
                )}
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducerState: state.authReducer,
            }
        },
        dispatch => {
            return {
                getServiceRevenueByRegion(data) {
                    dispatch({
                        type: 'FETCH_SERVICE_REVENUE_BY_REGION',
                        payload: data

                    });
                },
                getDrillDownServiceRevenueByRegion(data) {
                    dispatch({
                        type: 'FETCH_DRILL_DOWN_SERVICE_REVENUE_BY_REGION',
                        payload: data

                    });
                },
            };
        }
    )(RevenueByRegion);
