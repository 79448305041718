import React from "react";
import { Grid } from "@material-ui/core";
import ReactTable from "react-table-6";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import NewPagination from "../../../common/components/CustomPagination";

import { handleTableTransform, numberWithCommas } from '../../../../utils/numberUtils'

const ReactTableFixedColumns = withFixedColumns(ReactTable);


function TableComponents(props) {
    const changeKeysOfHeader = (data, type) => {
        let selfProps = props
        if (data && data.length) {
            data = data.map((item, i) => {
                    let myClassNameHeader =""
                    return {
                        Header: <div className={'flex ai-center jc-center ' + myClassNameHeader}>
                            <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                            {/* {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />} */}
                            {/* {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />} */}
                        </div>,
                        HeaderLabel: item.label,
                        id: item.key,
                        accessor:item.key,
                        Cell: (props) => {
                            let ori =  props.original
                            return (
                                    <div className='text-center pointer' >
                                        {handleTableTransform(ori, item)}
                                    </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            whiteSpace: "break-spaces",
                            overflow: "visible",
                            wordBreak: 'break-word',

                        },
                        headerStyle: {
                            whiteSpace: 'break-spaces',
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            // wordBreak: 'break-word',
                            width: "50px !important",
                        }
                    }
                // }
            })
        }
        return data
    }

    const headerData = changeKeysOfHeader(props.tableData.row_header)
    const tableData = props.tableData.row_data
    return (
        <Grid item xs={12}>
            <ReactTableFixedColumns
                data={tableData}
                columns={headerData}
                // defaultPageSize={props.receivablesTab == 1 ? 6 : props.tableData.length > 10 ? 10 : props.tableData.length}
                pageSize={tableData.length}
                defaultPageSize={5}
                className="adhoc-table xpna-reactFixed-table  -striped -highlight "
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
                // getTdProps={props.clicked}
            />
        </Grid>
    )
}
export default TableComponents;