import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import history from '../../../../../history'
import { changeCollectionInvoiceForecastData, changeValuesOfData, setAllRowCommentHistoryData, appendChannelId, changeCollectionForecastData} from './transformer'
import { getAllCommentHistoryData } from '../../../../../utils/commentDataUtil'
import {
    fetchDayReportDataService,
    fetchAllCommentHistoryService,
    createChannelService,
    fetchOneCommentHistoryService,
    sendCollectionForecastService,
    sendCollectionForecastInvoiceService,
    updateActionOwnerService,
    getDayFilterService
} from './service'
import { store } from '../../../../../index'

function* fetchDayReportData(data) {
    yield put({ type: "FETCH_DAY_REPORT_LOADER", loading: true });
    const state = store.getState();


    const response = yield call(fetchDayReportDataService, data.payload);

    console.log(response, "fetchDayReportDataService")
    const { dashboardState: { receivableData: { ageingData } } } = state;
    const { activePage } = ageingData.external;

    if (response && response.status == 200) {

        response.data.row_data = changeValuesOfData(response.data.row_data)
        const getCommentPayload =  getAllCommentHistoryData(activePage, response.data.row_data)

        yield put({ type: "FETCH_DAY_REPORT_DATA_SUCCESS", loading: false, data: response.data });
            yield put({
                type: "FETCH_COMMENT_CHANNEL_DAY_DATA_HISTORY",
                payload: getCommentPayload,
              })

    }

}

function* fetchCommentAllChannelHistory(data) {

    try {

        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(fetchAllCommentHistoryService, data.payload);

        console.log(response, "fetchCommentAllChannelHistory")

        if (response && response.status == 200) {
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, response.data.chat)
            yield put({ type: "FETCH_COMMENT_HISTORY_DAY_ALL_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}

function* createChannelId(data) {

    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        yield put({type: 'CHANGE_CREATE_CHANNEL_FLAG', payload: false})

        const response = yield call(createChannelService, data.payload);

        if (response && response.status == 200) {
            const transformedData = appendChannelId(tableRowData,data. payload.invoice_no, response.data)
            yield put({ type: "CREATE_CHANNEL_DAY_SUCCESS", payload: true, data: transformedData.transformedArr, updatedItem: transformedData.updatedItem  });
        }

    } catch (error) {
        console.log(error);
    }

}

function* fetchOneCommentChannelHistory(data) {

    try {

        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(fetchOneCommentHistoryService, data.payload);

        console.log(response, "fetchOneCommentHistoryService")

        if (response && response.status == 200) {
            const transformResponse = {
                [data.payload.channelId] : response.data
            }
            const updatedRowData = setAllRowCommentHistoryData(tableRowData, transformResponse)
            yield put({ type: "FETCH_COMMENT_HISTORY_ALL_DAY_SUCCESS", loading: false, data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }

}

function* sendCollectionForecastInvoce(data) {
    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(sendCollectionForecastInvoiceService, data.payload);
        console.log(response, "sendCollectionForecastService")


        if (response && response.status == 200) {
            const updatedRowData = changeCollectionInvoiceForecastData(tableRowData, data.payload)
            yield put({ type: "SEND_COLLECTION_FORECAST_DAY_SUCCESS", data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }
}

function* sendCollectionForecast(data) {
    try {
        const state = store.getState();
        const { dayReportState } = state;
        const {tableRowData} = dayReportState;

        const response = yield call(sendCollectionForecastService, data.payload);
        console.log(response, "sendCollectionForecastService")


        if (response && response.status == 200) {
            const updatedRowData = changeCollectionForecastData(tableRowData, data.payload)
            yield put({ type: "SEND_COLLECTION_FORECAST_DAY_SUCCESS", data: updatedRowData });
        }

    } catch (error) {
        console.log(error);
    }
}

function* updateActionOwner(data) {
    try {
        yield call(updateActionOwnerService, data.payload);
        
    } catch (error) {
        console.log(error);
    }
}

function* fetchFiltersData(data) {

    const state = store.getState();

    data.payload['report_date'] = state.dashboardState.selectedDate

    const response = yield call(getDayFilterService, data.payload);


    if (response && response.status == 200) {
        yield put({ type: "FETCH_DAY_FILTER_DATA_SUCCESS", data: response.data.filter });
    }

}


export default function* actionWatcher() {
     yield takeLatest('FETCH_DAY_REPORT_DATA', fetchDayReportData)
     yield takeLatest('FETCH_COMMENT_CHANNEL_DAY_DATA_HISTORY', fetchCommentAllChannelHistory)
     yield takeLatest('CREATE_CHANNEL_DAY_ID', createChannelId)
     yield takeLatest('FETCH_COMMENT_ONE_CHANNEL_DAY_DATA_HISTORY', fetchOneCommentChannelHistory)
     yield takeLatest('SEND_COLLECTION_FORECAST_DAY', sendCollectionForecast)
     yield takeLatest('SEND_COLLECTION_FORECAST_INVOICE_DAY', sendCollectionForecastInvoce)
     yield takeLatest('UPDATE_ACTION_OWNER_DAY', updateActionOwner)
     yield takeLatest('FETCH_DAY_FILTER_DATA', fetchFiltersData)
}