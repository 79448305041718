import React from 'react';
import { connect } from 'react-redux';
import TableComponent from './table';
import Button from '@material-ui/core/Button';
import './index.css';
import DownloadExcel from '../../../../../images/download_excel.svg';
import TransposeIcon from '../../../../../images/transpose_icon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import ShowMoreComponent from './ShowMoreComponent';
import ChartComponent from './Charts';
import CustomFunctionModal from '../../components/modalCustomFunction';
import CustomFunctionLabelModal from '../../components/modalCustomFunctionLabel';
import Switch from '@material-ui/core/Switch';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jsPDF from "jspdf";
import 'jspdf-autotable'
import {  unwindSubColumn, mapDataPDFAS } from '../../../../../utils/exportExcel'
import { format } from 'date-fns/esm';
import { toastr } from 'react-redux-toastr';

export class ReportTableComponent extends React.Component {
	constructor(props) {
		super(props);
		this.customFnObjLocal = {
			row: {},
			column: {},
		};
		this.state = {
			users: [],
			loading: false,
			isTranspose: false,
			showCellId: false,
			showMore: {
				uiTypes: ['table'],
				chartType: 'none',
			},
			labelTranspose : "Transpose",
			showCustomFnModal: false,
			showCustomFnLabelModal: false,
			customFn: {
				data: {},
				item: {},
				isRow: undefined,
			},
		};
		this.TableData = {
			row : [],
			header : [],
		}
	}

	fetchReport = (isExport = false) => {
		console.log('Function called');
		const { isTranspose } = this.state;
		const { columnArr, rowArr } = this.props;
		let customFnObj = this.generateCustomFn();
		let payload = {
			isTranspose,
			export: isExport,
			columnArr,
			rowArr,
			customFnObj,
		};
		this.props.saveCustomFnData(customFnObj);
		this.props.fetchReportTableData(payload);
	};



	printDocument = () => {
		let data = this.TableData.row
		let header = this.TableData.header
		let headerPDF = []
		let rowPDF = []
		const pdf = new jsPDF('l', 'pt', 'letter');
    	pdf.autoTable({ html: '#my-table' })
		// console.log("--yw", data, header)
		let dataFlat = unwindSubColumn(data)
		let dataClean = mapDataPDFAS(dataFlat,"key", header)
		// console.log("--yw flat", dataFlat, dataClean)

		let i = 0
		let firstHeader = []
		let secondHeader = []
		header.forEach(x => {
			let obj = { content: x.label, rowSpan : 2, styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } }
			if (x.sub_headers && x.sub_headers.length > 0) {
				obj.rowSpan = 1
				obj.colSpan = x.sub_headers.length
				firstHeader.push(obj)
				x.sub_headers.forEach(y => {
					let objY = { content: y.label,  styles: { halign: 'center', fillColor: [223, 217, 251], textColor: [131, 6, 206] } }
					secondHeader.push(objY)
				})
			}else{
				firstHeader.push(obj)
			}

			i++;
		  })

		  headerPDF.push(firstHeader)
		  headerPDF.push(secondHeader)



		  let dataCount = 0
		  dataClean.forEach(x => {
			let i = 0
			let rowsData = []
			// console.log(x, "ini datax")
			x.forEach(y => {
				rowsData.push({ content: y , styles: { halign: 'center'} })
			  i++;
			})
			dataCount += 1
			rowPDF.push(rowsData)
		  })
		  
		  let totalPages = 1

		  if(dataCount > 22){
			totalPages = Math.ceil(dataCount/22)
		  }

		console.log("--yw Final", headerPDF, rowPDF)

		  pdf.autoTable({
			head:  headerPDF ,
			body: rowPDF,
			theme: 'grid',
			didDrawPage: function (data) {
				// Header
				pdf.setFontSize(20);
				pdf.setTextColor(40);
				pdf.setFontStyle('heltevica');
				pdf.text( "Analytics Studio Report", data.settings.margin.left + 15, 30);
				if (totalPages > 1 ) {
					var str = "Page " + pdf.internal.getNumberOfPages()
					// Total page number plugin only available in jspdf v1.0+
					if (typeof pdf.putTotalPages === 'function') {
					  str = str
					}
					pdf.setFontSize(10);
	  
					// jsPDF 1.4+ uses getWidth, <1.4 uses .width
					var pageSize = pdf.internal.pageSize;
					var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
					pdf.text(str, data.settings.margin.left, pageHeight - 10);
				}
	  
			   
			  },
			  styles: {
				lineWidth: 1,
				  lineColor: [38, 38, 38],
			  },
		
			  margin: { top: 50 }
			})

		  console.log("--yw save")
		  let FileName = 'Analytics Studio Report - ' + format(new Date, " MM-dd-yyyy HH-mm-ss")

		  pdf.save(FileName + '.pdf')

	}

	generateCustomFn = () => {
		const { row, column } = this.customFnObjLocal;
		console.log(this.customFnObjLocal, "--this.customFnObjLocal")
		let columnObj = {};
		let rowObj = {};
		if (Object.keys(column).length) {
			for (let i in column) {

				if(i.indexOf("_label") > -1){ // skip label data
					columnObj[i] = column[i]
					continue
				}

				let columnArr = [];
				for (let j in column[i]) {
					columnArr.push(column[i][j]);
				}
				columnObj[i] = columnArr
			}
		}
		if (Object.keys(row).length) {
			for (let i in row) {
				if(i.indexOf("_label") > -1){ // skip label data
					rowObj[i] = row[i]
					continue
				}

				let rowArr = [];
				for (let j in row[i]) {
					rowArr.push(row[i][j]);
				}
				rowObj[i] = rowArr
			}
		}

		return {
			rowData: rowObj,
			columnData: columnObj,
		};
	};

	componentDidMount() {
		this.createCustomFnMap();
		this.fetchReport();
	}

	handleGenerateReport = () => {
		this.fetchReport();
	};

	handleTransposeData = () => {
		if(Object.keys(this.customFnObjLocal.row).length > 0 || Object.keys(this.customFnObjLocal.column).length > 0){
			console.log(this.customFnObjLocal, "--this.customFnObjLocal")
			toastr.warning("Transpose disabled when custom function used")
			return
		}

		this.setState({ isTranspose: !this.state.isTranspose }, () => {
			this.fetchReport();
		});
	};

	handleExcelDownload = () => {
		this.fetchReport(true);
	};

	componentDidUpdate(prevProps) {

		if (this.props.editStatus == 'edit-ready' &&  prevProps.editStatus == 'none'){
			this.createCustomFnMap();
			this.fetchReport();
		}

		//console.log(this.props.customFnObj , prevProps.customFnObj,"-- this.props.customFnObj")
		if(Object.keys(this.props.customFnObj).length > 0 && Object.keys(prevProps.customFnObj).length){
			let rowLen = Object.keys(this.props.customFnObj.rowData).length != Object.keys(prevProps.customFnObj.rowData).length
			let colLen = Object.keys(this.props.customFnObj.columnData).length != Object.keys(prevProps.customFnObj.columnData).length

			if (rowLen || colLen){
				this.createCustomFnMap();
			}
		}

		if (this.props.activeTabIndex != prevProps.activeTabIndex  ) {

			this.setState({ isTranspose: false }, () => {
				this.createCustomFnMap();
				this.fetchReport();
			});
		}
	}
	componentWillUnmount() {
		this.customFnObjLocal = {
			row: {},
			column: {},
		};
		this.toggleModal();
	}

	handleCustomModalClick = (_data, item, isRow, cellList) => {
		let rowColumnIndex = item['column_index'] + _data['row_index'];
		cellList = cellList.filter(x=>{ return x != rowColumnIndex })
		// console.log(cellList,rowColumnIndex, "--celllist")

		let whereTo = isRow ? 'row' : 'column';
		let dragId = isRow ? _data['draggableid'] : item['draggableid'];
		let dragnum = dragId.split("-")[1];
		let formulaField = "custom_formula_" + dragnum;
		let actionField = "custom_action_" + dragnum;
		let actionVField = "custom_action_values_" + dragnum;

		if (this.customFnObjLocal[whereTo][dragId] == undefined) {
			this.customFnObjLocal[whereTo][dragId] = {}
		}

		// console.log(this.customFnObjLocal[whereTo][dragId][rowColumnIndex],cellList, "--modal data")

		if (this.customFnObjLocal[whereTo][dragId][rowColumnIndex] == undefined) {
			this.customFnObjLocal[whereTo][dragId][rowColumnIndex] = {
				value: _data[formulaField],
				label: _data['Custom Function'],
				cellsList : cellList,
				action : _data[actionField],
				action_values : _data[actionVField],
				header_label : "Formula for Cell "+ rowColumnIndex,
				cell_id : rowColumnIndex,
			};
		}else{
			this.customFnObjLocal[whereTo][dragId][rowColumnIndex]['cellsList'] =  cellList
		}

		this.setState({
			showCustomFnModal: true,
			customFn: {
				data: _data,
				item,
				isRow,
			},
		});
	};


	handleCustomLabelModalClick = (_data, item, isRow) => {
		let whereTo = isRow ? 'row' : 'column';
		let dragId = isRow ? _data['draggableid'] : item['draggableid'];

		if (this.customFnObjLocal[whereTo][dragId] == undefined) {
			this.customFnObjLocal[whereTo][dragId] = {}
		}

		if (this.customFnObjLocal[whereTo][dragId+"_label"] == undefined) {
			this.customFnObjLocal[whereTo][dragId+"_label"] = ""
		}

		this.setState({
			showCustomFnLabelModal: true,
			customFn: {
				data: _data,
				item,
				isRow,
			},
		});
	}

	handleSaveData = (modalData) => {
		const { data, item, row, column, isRow } = this.state.customFn;
		let rowColumnIndex = item['column_index'] + data['row_index'];
		let whereTo = isRow ? 'row' : 'column';
		let dragId = isRow ? data['draggableid'] : item['draggableid'];


		this.customFnObjLocal[whereTo][dragId][rowColumnIndex] = {
			...this.customFnObjLocal[whereTo][dragId][`${rowColumnIndex}`],
			value: modalData.value,
			action : modalData.action,
			action_values : modalData.action_values,
			row_index: data['row_index'],
			column_index: item['column_index'],
		};

		this.toggleModal();
	};


	handleSaveDataLabel = (modalData) => {
		const { data, item, row, column, isRow } = this.state.customFn;
		let whereTo = isRow ? 'row' : 'column';
		let dragId = isRow ? data['draggableid'] : item['draggableid'];


		this.customFnObjLocal[whereTo][dragId + "_label"] =  modalData.label
		console.log(this.customFnObjLocal[whereTo], "---local")
		this.toggleModal();
	};

	setTooltipLabel = () =>{
		let label = "Transpose"
		if(Object.keys(this.customFnObjLocal.row).length > 0 || Object.keys(this.customFnObjLocal.column).length > 0){
			label = "Transpose disabled when custom function used"
		}
		// console.log(label, "---tooltip label")
		this.setState({
			labelTranspose : label
		})
	}
	
	createCustomFnMap = () => {
		const { customFnObj } = this.props;
		this.customFnObjLocal['row'] = {}
		this.customFnObjLocal['column'] = {}
		let cloneCustom = JSON.parse(JSON.stringify(customFnObj));
		// console.log(cloneCustom, "---clone")

		if (cloneCustom.rowData != undefined) {
			Object.keys(cloneCustom.rowData).forEach((key) => {
				let item = cloneCustom.rowData[key] 

				if(key.indexOf("_label") > -1){
					this.customFnObjLocal['row'][key] = item
				}else{
					if(this.customFnObjLocal['row'][key] == undefined){
						this.customFnObjLocal['row'][key] = {}
					}
					if(item){
						item.forEach(xItem =>{
							this.customFnObjLocal['row'][key][xItem.column_index + xItem.row_index] = xItem;
						})
					}
				}


				
			});
		}

		if (cloneCustom.columnData != undefined) {
		Object.keys(cloneCustom.columnData).forEach((key) => {
				let item = cloneCustom.columnData[key] 

				if(key.indexOf("_label") > -1){
					this.customFnObjLocal['column'][key] = item
				}else{
					if(this.customFnObjLocal['column'][key] == undefined){
						this.customFnObjLocal['column'][key] = {}
					}

					if(item){
						item.forEach(xItem =>{
							this.customFnObjLocal['column'][key][xItem.column_index + xItem.row_index] = xItem;
						})
					}
				}
			});
		}
		console.log("--createCustomFnMap", cloneCustom,this.customFnObjLocal, this.props)

	};

	toggleModal = () => {
		this.setState({
			showCustomFnModal: false,
			showCustomFnLabelModal: false,
			customFn: {
				data: {},
				item: {},
				isRow: undefined,
			},
		});
	};

	handleChangeView = (event) => {
		this.setState({ showCellId: event.target.checked });
	};

	render() {
		const { freeCashFlowState } = this.props;
		const { showMore, showCustomFnModal, showCustomFnLabelModal,  showCellId , labelTranspose} = this.state;
		const analyticStudioData = freeCashFlowState.analyticStudio;
		const { tableRowHeader, tableRowData, loading } = analyticStudioData;
		const { data, item, isRow } = this.state.customFn;
		let whereTo = isRow ? 'row' : 'column';
		let dragId = isRow ? data['draggableid'] : item['draggableid'];

		this.TableData.header = tableRowHeader
		this.TableData.row = tableRowData

		return (
			<div className='report-table-analyticStudio'>
				<div className='report-table-analyticStudio-header'>
					<div>{showMore.uiTypes.includes('table') && <p>Report Table</p>}</div>
					<div className='report-table-analyticStudio-header-right-buttons'>
						{/* {tableRowData && tableRowData.length ? <div className="report-table-analyticStudio-header-right-buttons-1">
							<Button style={{ marginRight: '3px' }} size="small" variant="outlined" color="secondary" onClick={this.props.openModal}>Save</Button>
						</div> : null} */}
						<div className='report-table-analyticStudio-header-right-buttons-2'>
							<Button
								id='generate-data-report'
								style={{ marginRight: '3px' }}
								variant='outlined'
								onClick={this.handleGenerateReport}
								className='newButtonOutlined btnBlue uppercase'
							>
								Generate Report
							</Button>
							<ShowMoreComponent
								data={showMore}
								onChange={(data) => this.setState({ showMore: data })}
							/>
						</div>
					</div>
				</div>
				<div>
					{showMore.uiTypes.includes('table') &&
					tableRowData &&
					tableRowData.length ? (
						<div className='download-icons'>
							<div className='mr-40'>
								<label>Value</label>
								<Switch
									className='custom-switch'
									checked={showCellId}
									onChange={this.handleChangeView}
									color='primary'
									name='toggleTableGraph'
								/>
								<label>Cell ID</label>
							</div>
							<div>
								<Tooltip title={labelTranspose}>
									<img
										src={TransposeIcon}
										className='pointer'
										height='30'
										// style={{ background: 'lightgrey' }}
										alt='Transpose'
										onMouseOver={this.setTooltipLabel}
										onClick={(event) => {
											event.preventDefault();
											this.handleTransposeData();
										}}
									/>
								</Tooltip>
							</div>
							<div>
								<Tooltip title={'Download to PDF'} className='pointer'>
								<PictureAsPdfIcon fontSize="large" className='pointer ml-10 mr-10'
									height='30'
									onClick={(event) => {
									event.preventDefault();
									this.printDocument();
									}} />

								</Tooltip>
							</div>
							<div>
								<Tooltip title={'Download to Excel'}>
									<img
										src={DownloadExcel}
										className='pointer'
										height='30'
										alt='download excel'
										onClick={(event) => {
											event.preventDefault();
											this.handleExcelDownload();
										}}
									/>
								</Tooltip>
							</div>
						</div>
					) : null}

					{showMore.uiTypes.includes('table') && (
						<TableComponent
							handleCustomModalClick={this.handleCustomModalClick}
							handleCustomLabelModalClick={this.handleCustomLabelModalClick}
							showCellId={showCellId}
							showCustomFnModal={showCustomFnModal}
						/>
					)}
					{showMore.uiTypes.includes('chart') && (
						<ChartComponent chartType={showMore.chartType} />
					)}
					{showCustomFnModal && (
						<CustomFunctionModal
							handleSaveData={this.handleSaveData}
							toggleModal={this.toggleModal}
							open={showCustomFnModal}
							currentData={
								this.customFnObjLocal[whereTo][dragId][
									`${item['column_index'] + data['row_index']}`
								]
							}
						/>
					)}

					{showCustomFnLabelModal && (
						<CustomFunctionLabelModal
							handleSaveData={this.handleSaveDataLabel}
							toggleModal={this.toggleModal}
							open={showCustomFnLabelModal}
							currentData={
								this.customFnObjLocal[whereTo][dragId +"_label"]
							}
						/>
					)
					}
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			freeCashFlowState: state.freeCashFlowState,
		};
	},
	(dispatch) => {
		return {
			fetchReportTableData(data) {
				dispatch({
					type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			saveCustomFnData(data) {
				dispatch({
					type: 'SAVE_CUSTOM_FUNCTION_DATA',
					payload: data,
				});
			},
		};
	}
)(ReportTableComponent);
