const reducer = (state = {
   loading: false,
   data: {},
   error: false
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));

   switch (action.type) {

      case 'FETCH_INVOICE_DETAIL_LOADER':
         return { ...state, loading: action.loading };

      case 'FETCH_INVOICE_DETAIL_SUCCESS':
         newState.loading = action.loading;
         newState.data = action.data;
         return newState;

      default:
         return newState;
   }
};
export default reducer;

