const getDefaultValue = (filterArr = []) => {
    let returnArr = []
    filterArr.forEach(item => {
        returnArr.push({
            key: item._id,
            value: item.default_value
        })
    })

    return returnArr;
}

const variancePayloadMapping = (filterArr = []) => {
    let column_one = filterArr[0].default_value[0],
        column_two = filterArr[1].default_value[0]

    return {column_one, column_two}
}

const reverseGetDefaultValue = (filterArr = [], filtersMetaData = []) => {
    let returnArr = []
    filterArr.forEach(item => {
        var filterSelected = filtersMetaData.filter(meta => meta._id == item.key)
        filterSelected = filterSelected.length == 1 ? 
            filterSelected = filterSelected[0] : filterSelected.find(element => element.category == item.category)

        if(filterSelected) {
            returnArr.push({
                _id: item.key,
                default_value: item.value,
                label: filterSelected.label,
                values: filterSelected.values
            })
        } else {
            switch (item.key) {
                case 'Variance':
                    returnArr = [{
                            _id: 'Compare What',
                            default_value: item.value[0],
                            label: 'Compare What',
                            values: item.value
                        },
                        {
                            _id: 'Compare With',
                            default_value: item.value[1],
                            label: 'Compare With',
                            values: item.value
                        }
                    ]
                    break;
            
                default:
                    break;
            }
        }
    })

    return returnArr;
}

export const adjustCustomFunction = (rowsArr = [], columnArr = [], customFnObj) =>{
    let rowID = {}
    let colID = {}
    if (rowsArr.length) {
         rowsArr.forEach((item , index) => {
            let custom = customFnObj? customFnObj.rowData ? customFnObj.rowData[item.draggableId]: [] : []
            if(custom?.length > 0){
                rowID[item.draggableId] = custom;
                rowID[item.draggableId+"_label"] = customFnObj.rowData[item.draggableId+"_label"];
            }
        })
    }

    if (columnArr.length) {
        columnArr.forEach((item , index) => {
            let custom = customFnObj? customFnObj.columnData ? customFnObj.columnData[item.draggableId]: [] : []

            if(custom?.length > 0){
                colID[item.draggableId] = custom;
                colID[item.draggableId+"_label"] = customFnObj.columnData[item.draggableId+"_label"];
            }
        })
    }

    let finObj = { rowData : rowID, columnData : colID }
// console.log(finObj, "-- transformer customFnObj")
    return finObj
}

export const generateReportPayload = (rowsArr = [], columnArr = [], isTranspose, customFnObj) => {
    let rowData = [];
    let columnData = []
    let payload = {
        column: [],
        row: []
    }

    if (rowsArr.length) {
        rowData  = rowsArr.map((item , index) => {
            let obj = {
                type: item.type,
                field: item._id,
            }

            switch (item._id) {
                case 'Variance':
                    const {column_one, column_two} = variancePayloadMapping(item.filters)
                    obj.column_one = column_one
                    obj.column_two = column_two
                    break;

                case 'Custom Function':
                    obj.custom_filter = customFnObj? customFnObj.rowData ? customFnObj.rowData[item.draggableId]: [] : []
                    obj.draggableid = item.draggableId
                    obj.label = customFnObj? customFnObj.rowData ? customFnObj.rowData[item.draggableId+"_label"]: "" : ""
                    break;
                
                case 'Total':
                case 'Average':
                case 'Count':
                case 'Percent':
                    obj.draggableid = item.draggableId
                    break;
            
                default:
                    obj.filters = getDefaultValue(item.filters)
                    break;
            }

            return obj
        })
    }
    
    if (columnArr.length) {
        // console.log(customFnObj, "--customFnObj")
        columnData  = columnArr.map((item, index) => {
            let obj = {
                type: item.type,
                field: item._id,
            }

            switch (item._id) {
                case 'Variance':
                    const {column_one, column_two} = variancePayloadMapping(item.filters)
                    obj.column_one = column_one
                    obj.column_two = column_two
                    break;
                
                case 'Custom Function':
                    let cObj = customFnObj? customFnObj.columnData ? customFnObj.columnData[item.draggableId]: [] : []
                    obj.custom_filter =   cObj
                    obj.draggableid = item.draggableId
                    obj.label = customFnObj? customFnObj.columnData ? customFnObj.columnData[item.draggableId+"_label"]: "" : ""
                    break;
                
                case 'Total':
                case 'Average':
                case 'Count':
                case 'Percent':
                    obj.draggableid = item.draggableId
                    break;
            
                default:
                    obj.filters = getDefaultValue(item.filters)
                    break;
            }

            return obj
        })
    }


    payload = {
        column: isTranspose ? rowData : columnData,
        row: isTranspose ? columnData : rowData
    }


    return payload
}

export const metaDataMap = (metaData = []) => {
    let measurements = [],
        dimensions = [],
        functions = [],
        filtersData = []

    if (metaData.length) {
        metaData.forEach(data => {
            switch (data._id.type) {
                case 'measurement':
                    measurements = data.data.map(item => {
                        item.details = item.details.map(item_child => {
                            item_child.filters = item.filters

                            return item_child
                        })
                        return item
                    })


                    filtersData = filtersData.concat(data?.data[0]?.filters)
                    filtersData = filtersData.concat(data?.data[1]?.filters.find(item => item._id == 'type_of_data'))
                    break;
                case 'dimension':
                    dimensions = data.details.map(item => {
                        var obj = data.filters.find(fil => fil.label == item.label)
                        // obj.default_value = obj._id == 'Legacy BU' ? ['All'] : obj.default_value
                        obj.default_value = ['All']
                        item.filters = [obj]
                        item.selected = false

                        delete item.filter

                        return item
                    })
                    filtersData = filtersData.concat(data.filters)
                    break;
                case 'function':
                    functions = data.details.map(item => {
                        item.filters = data.filters
                        item.selected = false
                        return item
                    })
                    filtersData = filtersData.concat(data.filters)
                    break;
                default:
                    break;
            }
        })
    }


    return { measurements, dimensions, functions, filtersData }
}

const updateVarianceOnExistingArray = (_varianceValues, _dataArray) => {
    let objects = _dataArray.filter(item => item.field == 'Variance')

    if(objects) {
        objects = objects.map(item => {
            let itemFilters = item.filters
            itemFilters = itemFilters.map(item_child => {
                item_child.values = _varianceValues
            })
        })
    }
}

export function varianceOptionsMap(newData, dataArray) {
    let { functionArr, rowsArray, columnsArray } = dataArray

    let newVariance = newData.find(item => item._id == 'Variance' )
    if(newVariance) {
        updateVarianceOnExistingArray(newVariance.values, columnsArray)
        updateVarianceOnExistingArray(newVariance.values, rowsArray)
    }

    let newFiltersVarianceObj = newData.find(item => item._id == 'Variance')

    let obj = [
        {
            default_value : [],
            label: 'Compare What',
            type: 'variance',
            _id: 'Compare What',
            values: newFiltersVarianceObj.values
        },
        {
            default_value : [],
            label: 'Compare With',
            type: 'variance',
            _id: 'Compare With',
            values: newFiltersVarianceObj.values
        },
    ]

    let varianceObj = functionArr.find(item => item._id == 'Variance')
    if(varianceObj){
        varianceObj.filters = obj

    }

}


export const getSaveDataPayload = (name, tabsData, id) => {

    let tabArr = []

    function createMap(data, customFnData) {
        if (data && data.length) {
            let temp = data.map(item => {
                console.log('data, customFnData', item, customFnData)
                let cObj = customFnData? customFnData[item.draggableId]:  []
                let cLabel = customFnData? customFnData[item.draggableId+"_label"]:  "Custom Function"
                let isCF = item._id == 'Custom Function' ? true : item._id.indexOf("cf_") > -1 ? true : false

                let obj = {
                    type: item.type,
                    field: item._id,
                    filters: getDefaultValue(item.filters),
                    draggableId: item.draggableId,
                    label: cLabel,
                    order: item.order,
                    selected: item.selected,
                    _id: item._id,
                    custom_filter: isCF ? cObj : undefined
                }
                return obj
            })

            return temp
        }

        return []

    }


    if (tabsData && tabsData.length) {
        tabArr = tabsData.map((item, index) => {
            let obj = {
                selected_view: 'table',
                index,
                title: item.tabName,
                row: createMap(item.rowsArray, item.customFnObj?.['rowData']),
                column: createMap(item.columnsArray, item.customFnObj?.['columnData'])
            }
            console.log("---obj save", obj)
            return obj

        })
    }

    return {
        _id: id == "" ? undefined : id,
        name,
        tabs: tabArr
    };
}



export const getEditStudioData = (_data, originalFilters) => {
    let tabArr = []
    let rowData = {}
    let columnData = {}
    function createMap(data, type) {
     
        if (data && data.length) {
            let temp = data.map((item, index) => {
                if (type == 'row') {
                    if(item.custom_filter){
                        rowData[item.draggableid] = item.custom_filter
                        rowData[item.draggableid+"_label"] = item.label
                    }
                }else{
                    if(item.custom_filter){
                        columnData[item.draggableid] = item.custom_filter
                        columnData[item.draggableid+"_label"] = item.label
                    }
                }
               
                let obj = {
                    type: item.type,
                    field: item.field,
                    filters: reverseGetDefaultValue(item.filters, originalFilters),
                    draggableId: item.draggableid,
                    label: item.field,
                    alias : item.label ,
                    order: index,
                    selected: false,
                    _id: item.field
                }
                return obj
            })

            return temp
        }

        return []

    }


    if (_data?.tabs_props?.length) {
        _data.tabs_props.forEach((item, index) => {

            let tabObj = {
                tabName: 'Sheet 1',
                columnsArray: [],
                rowsArray: [],
                measurementArr: [],
                dimensionArr: [],
                functionArr: [],
                functionsFilters: [],
                customFnObj: {}
            }

            tabObj['rowsArray'] = createMap(item['row'],'row');
            tabObj['columnsArray'] = createMap(item['column'], 'column');
            tabObj['tabName'] = item['title']
            tabObj['customFnObj'] = {columnData, rowData}

            tabArr.push(tabObj)
        })
    }
    console.log(tabArr, 'getEditStudioData', _data)




    return { tabData: tabArr, _id: _data.id, name: _data.name }
}