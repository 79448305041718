import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NewPagination from "../../../common/components/CustomPagination";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

export default function SimpleModal(props) {
    const [datax, setdata] = React.useState([]);
    const width = '50%'
    function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
                prefix=""
            />
        );
    }
    let tableData = [];
    const { loading } = datax;

    const handleClose = () => {
        props.modalCallBack(false);
    };

    React.useEffect(() => {
        let data = props.data
        console.log(data, "dataProps")
        // for (var i in data) {
        //     if (data[i].category  == "MSM") {
        //         data[i].category = "MSMT"
        //        break;
        //     }
        //   }
        // data.sort((a, b) => a.category > b.category ? 1 : -1)
        setdata(data)
    }, [props.showModal]);

    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            const rowHeader = [{ key: "alias", label: "BU" }, { key: "y", label: "O/S" }, { key: "revenue", label: "Revenue" }]
            tableData = rowHeader.map((item, i) => {
                let obj = {
                    Header: item.label,
                    id: item.key,
                    accessor: item.key,
                    headerStyle: {
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                        fontSize: '11px',
                        fontWeight: 'bold',
                        backgroundColor: 'white'
                    },
                    style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "hidden", fontSize: '11px', textAlign: 'center' },
                }
                if ( item.key == "revenue") { //item.key == "y" ||
                    obj.Cell = renderEditable
                }
                return obj
            })
            return tableData
        }
        return []
    }

    const handleInputChange = (cellInfo, event) => {
        var datas = JSON.parse(JSON.stringify(datax));
        var dataNew = event.target.value
        datas[cellInfo.index][cellInfo.column.id] = parseFloat((dataNew.replace(/\,/g, '')));
        datas[cellInfo.index][cellInfo.column.id + "edited"] = true
        setdata(datas);
    };

    const renderEditable = cellInfo => {
        const cellValue = datax[cellInfo.index][cellInfo.column.id];
        var nf = new Intl.NumberFormat();
        const onFocus = datax[cellInfo.index][cellInfo.column.id + "onFocus"]
        return (
            <div className={"dso-input"} >
                <TextField
                    placeholder="type here"
                    name="input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,

                    }}
                    variant="outlined"
                    size="small"
                    onBlur={handleInputChange.bind(null, cellInfo)}
                    value={cellValue}
                />
            </div>
        );
    };

    const handleSavePopUp = () => {
        var data = []
        datax.map((item, i) => {
            var dataDetail = {}
            dataDetail.month = item.date
            dataDetail.bu = item.category
            dataDetail.os = item.y
            dataDetail.revenue = item.revenue
            data.push(dataDetail)
        })
        var dataSave = {}
        dataSave.data_dso = data
        console.log(dataSave, "dataSave")
        props.saveCallback(dataSave);
    }
    changeKeysOfHeader(datax);

    const body = (
        <div className='customModal editModalDSO'>
            <div className='header'>
                {props.tittleModal ? <h2>Data for {props.tittleModal}</h2> : <h2></h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <ReactTable
                    columns={tableData}
                    data={datax ? datax : []}
                    defaultPageSize={5}
                    loading={loading}
                    className="-striped -highlight"
                    PaginationComponent={NewPagination}
                    sortable={false}
                />
                <Grid container justify="flex-end" className='mt-30'>
                    <Button variant="contained" onClick={handleSavePopUp} color="primary">Submit</Button>
                    <Button variant="contained" onClick={handleClose} className='ml-10'>Cancel</Button>
                </Grid>
            </div>
        </div>
    );

    return (
        <div >
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
