import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Divider from '@material-ui/core/Divider';
import { numberWithCommas,handleTableTransform} from '../../../../../utils/numberUtils'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { formatDate } from '../../../../../utils/dateFormat'
import CircularProgress from '@material-ui/core/CircularProgress';
import '../ScatterChart.scss';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export class TableViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.headerColorArr = ['#f7f7f7', '#685aa9', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5']
        const datelen = props.dashboardState.reportDateArr && props.dashboardState.reportDateArr.length;
        this.state = {
          loading: true,
            seriesData: [],
            commentValue: '',
            commentModal: false,
            viewType: "segment",
            month: props.dashboardState.reportDateArr && props.dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
        }
    }

    componentDidMount() {
        const { dashboardState , getTableViewData} = this.props
        let chartTypes = ""
        if(this.props.tabSelectorValue == 1){
          chartTypes="new"
        }
        this.props.getTableViewData({"report_date": dashboardState.selectedDate, chart_type :  chartTypes})
    }

    componentWillReceiveProps(nextProps) {
        const { dashboardState ,  getTableViewData, selectedTab} = this.props
        let chartTypes = ""
        if(this.props.tabSelectorValue == 1){
          chartTypes="new"
        }
        if (nextProps.selectedTab != selectedTab) {
            getTableViewData({"report_date": dashboardState.selectedDate, chart_type :  chartTypes})
        }
    }

    handleMonthChange = (event) => {
        if (event.target && this.state.month != event.target.value) {
            let chartTypes = ""
            if(this.props.tabSelectorValue == 1){
              chartTypes="new"
            }
          this.setState({ month: event.target.value }, () => {
            this.props.getTableViewData({"report_date": event.target.value, chart_type :  chartTypes})
          });
        }
    }

    handleSummaryColumn = (dataItem) => {
        const { buRowData } = this.props.summaryState;
        let returnString = '';
        returnString = dataItem.label;
        if (buRowData[dataItem.key]) {
            //   returnString = returnString + ' ' + this.modifyColumnData(buRowData[dataItem.key])
            returnString = returnString + ' ' + buRowData[dataItem.key]
        }
        return returnString;
    }
    changeKeysOfHeader = (data, type) => {
     
        if (data && data.length) {
         
            return data.map((item, i) => {
                return {
                    Header: this.handleSummaryColumn(item),
                    id: item.key,
                    accessor: (_data) => {
                        if (item.operation) {
                            let modifiedData;
                            let columnData = _data[item.key]
                            switch (item.operation) {
                                case 0:
                                    modifiedData = item.key;
                                    break;
                                case 1:
                                    modifiedData = Math.round(columnData)
                                    break;
                                case 2:
                                    modifiedData = numberWithCommas(columnData)
                                    break;
                                case 4:
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 3:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 5:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 6:
                                    var isNegative = columnData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 7:
                                    modifiedData = Math.round(columnData)
                                    var isNegative = modifiedData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                default:
                                    break;
                            }

                            return modifiedData;


                        } else {

                            return _data[item.key];
                        }
                    },
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {

                                    props.original[item.key] == "Grand Total" ?
                                        <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                        <span
                                            onClick={() => {
                                                // this.businessUnitClick(props.original[item.key], type, props.original) 
                                            }}>
                                            <>{handleTableTransform(props.original, item , true)}</>
                                        </span>
                                }
                            </div>
                        )
                    },
                    sortMethod: (a, b) => {
                        let valNeg = a.indexOf("(") > -1 ? -1 : 1
                        let valNegB = b.indexOf("(") > -1 ? -1 : 1
                        let val = a.split(",").join("").replace("(", "").replace(")", "")
                        let valB = b.split(",").join("").replace("(", "").replace(")", "")
                        let valInt = parseInt(val)
                        let valIntB = parseInt(valB)
                        valInt = isNaN(valInt) ? 0 : valInt * valNeg
                        valIntB = isNaN(valIntB) ? 0 : valIntB * valNegB
                        if (item.operation)



                            return valInt - valIntB
                        else {
                            if (a > b) {
                                return -1;
                            } else if (a < b) {
                                return 1;
                            }
                            return 0;
                        }

                    },
                    style: {
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    changeValuesOfData = (data) => {
        if (data && data.length) {
            return data.map(item => {
                return item
            })
        }
        return []
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.business_unit == 'Grand Total' ? '#b1bace' : ''
            }
          }
        }
        return {};
    }

    handleChangeView = (event)=>{
        if (event.target && this.state.viewType != event.target.value) {
          this.setState({ viewType: event.target.value });
        }
    }

    render() {
        const { month,viewType } = this.state
        let { tableViewState, dashboardState} = this.props;
        let BUDataColumns = this.changeKeysOfHeader(tableViewState.buTable.row_header, 'businessUnit')
        let BUDataList = this.changeValuesOfData(tableViewState.buTable.row_data);
        let RegionDataColumns = this.changeKeysOfHeader(tableViewState.regionTable.row_header, 'region')
        let RegionDataList = this.changeValuesOfData(tableViewState.regionTable.row_data);
        let SLBDataColumns = this.changeKeysOfHeader(tableViewState.slbTable.row_header, 'slb')
        let SLBDataList = this.changeValuesOfData(tableViewState.slbTable.row_data);
        let TPDataColumns = this.changeKeysOfHeader(tableViewState.tpTable.row_header, 'tp')
        let TPDataList = this.changeValuesOfData(tableViewState.tpTable.row_data);
        let PemexDataColumns = this.changeKeysOfHeader(tableViewState.pemexTable.row_header, 'pemex')
        let PemexDataList = this.changeValuesOfData(tableViewState.pemexTable.row_data);

        const datelen = dashboardState.reportDateArr && dashboardState.reportDateArr.length;
        const updatedMonth = dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]

        const arrayDate = dashboardState.reportDateArr
        const newArray = []
        const idx = arrayDate.length
        let Newidx = 0
        for (var keys in arrayDate) {
          Newidx++ 
          
          newArray.push(arrayDate[(idx-Newidx)])
        }

        return (
            <>
                <div>
                    {dashboardState && dashboardState.reportDateArr && dashboardState.reportDateArr.length ?
                      <>
                        <div className="flex ai-center jc-space-between mb-10">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={9} className='maxw-400'>
                                <FormControl component="fieldset">
                                  <RadioGroup name="radio" onChange={this.handleChangeView} value={viewType} className='radioGroupHorizontal'>
                                    <FormControlLabel value="segment" control={<Radio color="primary" />} label="Segment View" />
                                    <FormControlLabel value="bu" control={<Radio color="primary" />} label="BU View" />
                                    <FormControlLabel value="region" control={<Radio color="primary" />} label="Region View" />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3} className='maxw-200'>
                              <FormControl className='w-200'>
                                <Select
                                  value={month || updatedMonth}
                                  onChange={this.handleMonthChange}
                                  MenuProps={{ onExited: () => document.activeElement.blur() }}
                                >
                                  {newArray.map((item, index) => {
                                    return <MenuItem value={item} key={index}>{item}</MenuItem>
                                  })
                                  }
                                </Select>
                              </FormControl>
                            </Grid>
                        </div>
                        { tableViewState.loading ? <div className='progressfullpage'><CircularProgress /></div> :
                            <div className={'hfmDataTable scatterChart'}>
                                {viewType == "bu" ?
                                    <>
                                        <div className={'tableHeader'}>
                                            <span>{'> 90 days Overdue amount as per BU'}</span>
                                            <Divider />
                                        </div>
                                         <ReactTable
                                            data={BUDataList}
                                            columns={BUDataColumns}
                                            loading={tableViewState.loading}
                                            className="xpna-react-table -striped -highlight table-view"
                                            sortable={false}
                                            showPagination={false}
                                            defaultPageSize={BUDataList.length}
                                            getTrProps={this.getTrProps}
                                        />
                                    </>: null
                                }
                                {viewType == "region" ?
                                    <>
                                        <div className={'tableHeader'}>
                                            <span>{'> 90 days Overdue amount as per Region'}</span>
                                            <Divider />
                                        </div>
                                        <ReactTable
                                            data={RegionDataList}
                                            columns={RegionDataColumns}
                                            defaultPageSize={RegionDataList.length}
                                            sortable={false}
                                            loading={tableViewState.loading}
                                            className="xpna-react-table -striped -highlight table-view"
                                            showPagination={false}
                                            getTrProps={this.getTrProps}
                                        />
                                    </>: null
                                }
                                {viewType == "segment" ?
                                    <>
                                        <div className={'tableHeader'}>
                                        <span>{'> 90 days Overdue amount as per Third Party'}</span>
                                            <Divider />
                                        </div>
                                        <ReactTable
                                            data={TPDataList}
                                            columns={TPDataColumns}
                                            defaultPageSize={TPDataList.length}
                                            sortable={false}
                                            loading={tableViewState.loading}
                                            className="xpna-react-table -striped -highlight table-view"
                                            showPagination={false}
                                            getTrProps={this.getTrProps}
                                        />
                                        <div className={'tableHeader'}>
                                        <span>{'> 90 days Overdue amount as per SLB'}</span>
                                            <Divider />
                                        </div>
                                        
                                        <ReactTable
                                            data={SLBDataList}
                                            columns={SLBDataColumns}
                                            defaultPageSize={SLBDataList.length}
                                            sortable={false}
                                            loading={tableViewState.loading}
                                            className="xpna-react-table -striped -highlight table-view"
                                            showPagination={false}
                                            getTrProps={this.getTrProps}
                                        />
                                        <div className={'tableHeader'}>
                                            <span>{'> 90 days Overdue amount as per Pemex'}</span>
                                            <Divider />
                                        </div>
                                        <ReactTable
                                            data={PemexDataList}
                                            columns={PemexDataColumns}
                                            defaultPageSize={PemexDataList.length}
                                            sortable={false}
                                            loading={tableViewState.loading}
                                            className="xpna-react-table -striped -highlight table-view"
                                            showPagination={false}
                                            getTrProps={this.getTrProps}
                                        />
                                    </> : null
                                }
                            </div>
                        }
                      </> : null
                    }
                  </div>
            </>
        );
        return(
            <>
                {
                    <div className={'hfmDataTable'}></div>
                }
            </>
        )
    }
}

export default
    connect(
        state => {
            return {
                dashboardState: state.dashboardState,
                tableViewState: state.dashboardState.dashboardAnalytic.ScatterChart,
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                getTableViewData(data) {
                    dispatch({
                        type: 'FETCH_AR_OVERDUE_TABLE',
                        payload: data
                    });
                }
            };
        }
    )(TableViewComponent);
