import { put, takeLatest, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import {
    fetchCementDataService,
    fetchFixedCostCementDataService,
    fetchSalesLogisticCementDataService,
    fetchClinkerCementDataService,
    fetchCementCostDataService,
    fetchActualForecastCementDataService,
    fetchActualForecastFilterService,
    fetchDashboardDataService,
    fetchDashboardChartDataService,
    fetchSimulationDataService,
    saveSimulationDataService,
    fetchCompareFilterOptionsEbitdaService,
    fetchCompareDataForPNLEbitdaService,
    fetchDrilldownDataService,
    getEmailTemplateEbitdaPnlBudgetService,
    getEmailTemplateEbitdaSearchCompareService,
    saveEmailTemplateEbitdaService,
    fetchDropdownMasterFilterEbitdaService,
    fetchSaveBPSimulationEbitdaService,
    fetchDropdownPlanningService

} from "./service";

function* fetchCementData(data) {
    if (data.payload?.export != true || data.payload?.sendEmail) {
        yield put({ type: "FETCH_CEMENT_DATA_LOADER", loading: true });
    }

    const response = yield call(fetchCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_CEMENT_DATA_SUCCESS", data: response.data });
    } else if( response && response.status == 200 && data.payload?.sendEmail){
        yield put({ type: "FETCH_CEMENT_DATA_LOADER", loading: false })
        toastr.success("Email sent successfully")
    }
    else yield put({ type: "FETCH_CEMENT_DATA_LOADER", loading: false });
}

function* fetchFixedCostCementData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_FIXED_COST_CEMENT_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchFixedCostCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_FIXED_COST_CEMENT_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_FIXED_COST_CEMENT_DATA_LOADER", loading: false });
}

function* fetchSalesLogisticCementData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_SALES_LOGISTIC_CEMENT_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchSalesLogisticCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_SALES_LOGISTIC_CEMENT_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_SALES_LOGISTIC_CEMENT_DATA_LOADER", loading: false });
}

function* fetchCementCostData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_CEMENT_COST_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchCementCostDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_CEMENT_COST_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_CEMENT_COST_DATA_LOADER", loading: false });
}

function* fetchClinkerCementData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_CLINKER_CEMENT_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchClinkerCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_CLINKER_CEMENT_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_CLINKER_CEMENT_DATA_LOADER", loading: false });
}

function* fetchActualForecastFilterData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchActualForecastFilterService);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_ACTUAL_FORECAST_FILTER_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA_LOADER", loading: false });
}

function* fetchActualForecastCementData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchActualForecastCementDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_ACTUAL_FORECAST_CEMENT_DATA_LOADER", loading: false });
}


function* fetchDashboardData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchDashboardDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DASHBOARD_DATA_SUCCESS", data: response.data });
    } else yield put({ type: "FETCH_DASHBOARD_DATA_LOADER", loading: false });
}

function* fetchDashboardChartData(data) {
    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DASHBOARD_CHART_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchDashboardChartDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DASHBOARD_CHART_DATA_SUCCESS", data: response.data });
        yield put({ type: "FETCH_DASHBOARD_CHART_DATA_LOADER", loading: false })
    };
}


function* fetchSimulationData(data) {

    if (data.payload?.export != true) {
        yield put({ type: "FETCH_SIMULATION_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchSimulationDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_SIMULATION_DATA_SUCCESS", data: response.data });
        yield put({ type: "FETCH_SIMULATION_DATA_LOADER", loading: false })
    }  ;
}

function* saveSimulationData(data){
    yield put({ type: "FETCH_SIMULATION_DATA_LOADER", loading: true });
    const response = yield call(saveSimulationDataService, data.payload);
    if (response && response.status == 200) {
        toastr.success('Simulation Data Saved')
        yield put({ type: "FETCH_SIMULATION_DATA", payload: {month : data.payload[0].yearmonth} });
    }else{
         toastr.error(response.error)
    }
}

function* fetchCompareDataPNLEbitda(data) {
    if (data.payload.export != true || data.payload?.send_email) {
        yield put({ type: "FETCH_COMPARE_DATA_PNL_EBITDA_LOADER", loading: true });
    }
    const response = yield call(fetchCompareDataForPNLEbitdaService, data.payload);
    if (response && response.status == 200 && data.payload.export != true) {
        yield put({ type: "FETCH_COMPARE_DATA_PNL_EBITDA_SUCCESS", data: response.data, loading: false });
    }else if( response && response.status == 200 && data.payload?.send_email){
        yield put({ type: "FETCH_COMPARE_DATA_PNL_EBITDA_LOADER", loading: false })
        toastr.success("Email sent successfully")
    }
    yield put({ type: "FETCH_COMPARE_DATA_PNL_EBITDA_LOADER", loading: false });
}

function* fetchCompareFilterOptionEbitda(data) {
        yield put({ type: "FETCH_COMPARE_FILTER_PNL_EBITDA_LOADER", loading: true });
    
    const response = yield call(fetchCompareFilterOptionsEbitdaService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_COMPARE_FILTER_PNL_EBITDA_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_COMPARE_DATA_PNL_EBITDA_LOADER", loading: false });
}

function* fetchDrilldownData(data) {

    if (data.payload?.export != true) {
        yield put({ type: "FETCH_DRILLDOWN_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchDrilldownDataService, data.payload);
    if (response && response.status == 200 && data.payload?.export != true) {
        yield put({ type: "FETCH_DRILLDOWN_DATA_SUCCESS", data: response.data });
        yield put({ type: "FETCH_DRILLDOWN_DATA_LOADER", loading: false })
    }  ;
}

function* getTemplateEmailEbitdaPnlBudget(data) {
    yield put({ type: "FETCH_EBITDA_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateEbitdaPnlBudgetService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_EBITDA_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}

function* getTemplateEmailEbitdaSearchCompare(data) {
    yield put({ type: "FETCH_EBITDA_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateEbitdaSearchCompareService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_EBITDA_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}

function* saveTemplateEmailEbitda(data){
    yield put({ type: "FETCH_EBITDA_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailTemplateEbitdaService, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Template Saved')
        yield put({ type: "FETCH_EBITDA_VIEW_EMAIL_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* fetchDropdownMasterFilterEbitda(data) {
    yield put({ type: "FETCH_DROPDOWN_MASTER_EBITDA_LOADER", loading: true });

    const response = yield call(fetchDropdownMasterFilterEbitdaService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DROPDOWN_MASTER_EBITDA_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_DROPDOWN_MASTER_EBITDA_LOADER", loading: false });
}

function* saveBpSimulationDataEbitda(data){
    yield put({ type: "FETCH_SIMULATION_DATA_LOADER", loading: true });
    const response = yield call(fetchSaveBPSimulationEbitdaService, data.payload);
    if (response && response.status == 200) {
        toastr.success('BP Simulation Data Saved')
        yield put({ type: "FETCH_SIMULATION_DATA", payload: {month : data.payload.yearmonth} });
    }else{
         toastr.error(response.error)
    }
}

function* fetchDropdownPlanning(data) {
    yield put({ type: "FETCH_DROPDOWN_PLANNING_LOADER", loading: true });

    const response = yield call(fetchDropdownPlanningService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DROPDOWN_PLANNING_SUCCESS", data: response.data, loading: false });
    }
    yield put({ type: "FETCH_DROPDOWN_PLANNING_LOADER", loading: false });
}


export default function* actionWatcher() {
    yield takeLatest("FETCH_CEMENT_DATA", fetchCementData)
    yield takeLatest("FETCH_FIXED_COST_CEMENT_DATA", fetchFixedCostCementData)
    yield takeLatest("FETCH_SALES_LOGISTIC_CEMENT_DATA", fetchSalesLogisticCementData)
    yield takeLatest("FETCH_CEMENT_COST_DATA", fetchCementCostData)
    yield takeLatest("FETCH_CLINKER_CEMENT_DATA", fetchClinkerCementData)
    yield takeLatest("FETCH_ACTUAL_FORECAST_FILTER_DATA", fetchActualForecastFilterData)
    yield takeLatest("FETCH_ACTUAL_FORECAST_CEMENT_DATA", fetchActualForecastCementData)
    yield takeLatest("FETCH_DASHBOARD_DATA", fetchDashboardData)
    yield takeLatest("FETCH_DASHBOARD_CHART_DATA", fetchDashboardChartData)
    yield takeLatest("FETCH_SIMULATION_DATA", fetchSimulationData)
    yield takeLatest("SAVE_SIMULATION_DATA", saveSimulationData)    
    yield takeLatest('FETCH_COMPARE_FILTER_PNL_EBITDA', fetchCompareFilterOptionEbitda)
    yield takeLatest('FETCH_COMPARE_DATA_PNL_EBITDA', fetchCompareDataPNLEbitda)
    yield takeLatest("FETCH_DRILLDOWN_DATA", fetchDrilldownData)

    yield takeLatest('FETCH_EMAIL_TEMPLATE_EBITDA_PNL_BUDGET', getTemplateEmailEbitdaPnlBudget)
    yield takeLatest('FETCH_EMAIL_TEMPLATE_EBITDA_SEARCHCOMPARE', getTemplateEmailEbitdaSearchCompare)
    yield takeLatest('SAVE_EMAIL_TEMPLATE_EBITDA',saveTemplateEmailEbitda)
     
    yield takeLatest('FETCH_DROPDOWN_MASTER_EBITDA', fetchDropdownMasterFilterEbitda)
    yield takeLatest('RUN_BPSIMULATION_DATA_EBITDA', saveBpSimulationDataEbitda)

    yield takeLatest('FETCH_DROPDOWN_PLANNING', fetchDropdownPlanning)
    
}
