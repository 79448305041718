import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Divider from '@material-ui/core/Divider';
import { numberWithCommas } from '../../../../../utils/numberUtils'
import { handleTableTransformV2 } from '../../../../../utils/numberUtils'
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../../../utils/dateFormat'
import CircularProgress from '@material-ui/core/CircularProgress';

export class DataValidationTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.headerColorArr = ['#f7f7f7', '#685aa9', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5']
        this.state = {
            seriesData: [],
            commentValue: '',
            commentModal: false
        }
    }

    componentDidMount() {
        this.props.getDataBrowserValidationData(this.props.selectedTab)
    }

    componentWillReceiveProps(nextProps) {
        const { selectedTab, getDataBrowserValidationData } = this.props
        if (nextProps.selectedTab != selectedTab) {
            getDataBrowserValidationData(nextProps.selectedTab)
        }
    }

    businessUnitClick = (data, type, rowData) => {
        const { summaryState } = this.props;
        var headerData = []
        if (summaryState.internalData &&
            summaryState.internalData.row_header &&
            summaryState.internalData.row_header.length
        ) {
            var _data = [...[], ...summaryState.internalData.row_header]
            headerData = _data.filter(item => {
                if (!(item.key == "business_unit" || item.key == "grand_total")) {
                    return item
                }
            })
        }
        headerData = this.changeKeysOfHeader(headerData)
        this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
    }
    handleSummaryColumn = (dataItem) => {
        const { buRowData } = this.props.summaryState;
        let returnString = '';
        returnString = dataItem.label;
        if (buRowData[dataItem.key]) {
            //   returnString = returnString + ' ' + this.modifyColumnData(buRowData[dataItem.key])
            returnString = returnString + ' ' + buRowData[dataItem.key]
        }
        return returnString;
    }
    changeKeysOfHeader = (data, type) => {
     
        if (data && data.length) {
         
            return data.map((item, i) => {
                // return {
                //     Header: item.label,
                //     id: item.key,
                //     Cell: (props) => {
                //         return (
                //             <div className='text-center'>
                //                 {
                //                     item.key == 'business_unit' ?
                //                         props.original[item.key] == "Grand Total" ?
                //                             <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                //                             <span
                //                                 onClick={() => {
                //                                     // this.businessUnitClick(props.original[item.key], type, props.original) 
                //                                 }}>
                //                                 {props.original[item.key]}
                //                             </span> :
                //                         <>{handleTableTransform(props.original, item)}</>
                //                 }
                //             </div>
                //         )
                //     },
                return {
                    Header: this.handleSummaryColumn(item),
                    id: item.key,
                    accessor: (_data) => {
                        console.log(_data, "testdata")
                        if (item.operation) {
                            let modifiedData;
                            let columnData = _data[item.key]
                            switch (item.operation) {
                                case 0:
                                    modifiedData = item.key;
                                    break;
                                case 1:
                                    modifiedData = Math.round(columnData)
                                    break;
                                case 2:
                                    modifiedData = numberWithCommas(columnData)
                                    break;
                                case 4:
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 3:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 5:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 6:
                                    var isNegative = columnData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 7:
                                    modifiedData = Math.round(columnData)
                                    var isNegative = modifiedData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                default:
                                    break;
                            }

                            return modifiedData;


                        } else {

                            return _data[item.key];
                        }
                    },
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {

                                    props.original[item.key] == "Grand Total" ?
                                        <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                        <span
                                            onClick={() => {
                                                // this.businessUnitClick(props.original[item.key], type, props.original) 
                                            }}>
                                            <>{handleTableTransformV2(props.original, item)}</>
                                        </span>
                                }
                            </div>
                        )
                    },
                    sortMethod: (a, b) => {
                        let valNeg = a.indexOf("(") > -1 ? -1 : 1
                        let valNegB = b.indexOf("(") > -1 ? -1 : 1
                        let val = a.split(",").join("").replace("(", "").replace(")", "")
                        let valB = b.split(",").join("").replace("(", "").replace(")", "")
                        let valInt = parseInt(val)
                        let valIntB = parseInt(valB)
                        valInt = isNaN(valInt) ? 0 : valInt * valNeg
                        valIntB = isNaN(valIntB) ? 0 : valIntB * valNegB
                        if (item.operation)



                            return valInt - valIntB
                        else {
                            if (a > b) {
                                return -1;
                            } else if (a < b) {
                                return 1;
                            }
                            return 0;
                        }

                    },
                    style: {
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        color: 'white',
                        backgroundColor: this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    changeValuesOfData = (data) => {
        if (data && data.length) {
            return data.map(item => {
                return item
            })
        }
        return []
    }

    toggleCommentModal = () => {
        this.setState({ commentModal: !this.state.commentModal, commentValue: '' })
    }

    handleCommentChange = (event) => {
        this.setState({ commentValue: event.target.value })
    }

    handleCommentSubmit = () => {
        if (this.state.commentValue) {
            let commentPayload = {
                view: this.props.selectedTab,
                comment: this.state.commentValue
            }
            this.props.postDataBrowserValidationComment(commentPayload)
            this.toggleCommentModal()
        }
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.business_unit == 'Grand Total' ? '#b1bace' : ''
            }
          }
        }
        return {};
    }

    render() {
        let { dataBrowserState, selectedDate, } = this.props;
        console.log(dataBrowserState.hfmBalance, "dataHFM")
        let externalDataColumns = this.changeKeysOfHeader(dataBrowserState.hfmBalance.row_header, 'recievables')
        let externalDataList = this.changeValuesOfData(dataBrowserState.hfmBalance.row_data);
        let internalDataColumns = this.changeKeysOfHeader(dataBrowserState.slBalance.row_header, 'slBalance')
        let balanceData = dataBrowserState.slBalance.row_data
        let internalDataList = this.changeValuesOfData(balanceData);
        let unbilledDataColumns = this.changeKeysOfHeader(dataBrowserState.delta.row_header, 'delta')
        let deltaData = dataBrowserState.delta.row_data
        let unbilledDataList = this.changeValuesOfData(deltaData);
        let commentsData = dataBrowserState.comments

        return (
            <>
                {
                    <div className={'hfmDataTable xOverFlowHidden firstChildBorder'}>
                        <div className={'tableHeader'}>
                            <span>Balances as per HFM</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={externalDataList}
                            columns={externalDataColumns}
                            defaultPageSize={6}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table -striped -highlight"
                            sortable={false}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTrProps={this.getTrProps}
                        />}
                        <div className={'tableHeader'}>
                            <span>Balances as per xP&A Demo</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={internalDataList}
                            columns={internalDataColumns}
                            defaultPageSize={6}
                            sortable={false}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTrProps={this.getTrProps}
                        />}
                        <div className={'tableHeader'}>
                            <span>Delta = HFM - xP&A Demo</span>
                            <Divider />
                        </div>
                        {dataBrowserState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                        <ReactTable
                            data={unbilledDataList}
                            columns={unbilledDataColumns}
                            defaultPageSize={6}
                            sortable={false}
                            loading={dataBrowserState.loading}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTrProps={this.getTrProps}
                        />}
                        {/* <h5 className='mt-16 mb-0'>Comments to explain Delta - {this.props.selectedTab == 'billing' ? 'Billing' : 'Regional'} View</h5> */}
                        <h5 className='mt-16 mb-0'>Comments to explain Delta</h5>
                        <div className='commentSection'>
                            <div className='comments'>
                                {commentsData.data && commentsData.data.length ?
                                    commentsData.data.map((item, index) => {
                                        return (
                                            <div className='block' key={index}>
                                                <h6 style={{ margin: 0 }}>{item.created_by}, {formatDate(item.created_at)}</h6>
                                                <p>{item.comment}</p>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </div>
                            <div className='infoArea' style={{ textAlign: 'center' }}>
                                <div className='buttonGroup' style={{ marginBottom: '16px' }}>
                                    {/* <Button variant="contained" disabled style={{ marginRight: '16px', color: 'rgba(0,0,0,0.8)' }}>Date: {selectedDate}</Button> */}
                                    <Button variant="contained" disabled style={{ color: 'rgba(0,0,0,0.8)' }}>{`Delta Value = ${dataBrowserState.deltaValue}`}</Button>
                                </div>
                                <Button variant="contained" className='newButton btnBlue btnAdd uppercase' color="primary" fullWidth onClick={this.toggleCommentModal}>Add New Comment</Button>
                            </div>
                        </div>
                        <Modal
                            open={this.state.commentModal}
                            onClose={this.toggleCommentModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={this.state.commentModal}>
                                <div className='customModal dataValidationCommentModal'>
                                    <div className={'header'}>
                                        <h2>Add New Comment</h2>
                                        <Icon onClick={this.toggleCommentModal}>close</Icon>
                                    </div>
                                    <div className={'modal-content'} style={{ padding: '30px' }}>
                                        <p>Please enter the comment for the variation of the xP&A Demo Data with that of HFM Data</p>
                                        <TextField
                                            fullWidth
                                            label="Comment"
                                            multiline
                                            rows={10}
                                            value={this.state.commentValue}
                                            onChange={this.handleCommentChange}
                                            variant="filled"
                                        />
                                        <div className="flex jc-flex-end mt-30">
                                            <Button variant="contained" className='newButton btnBlue btnSubmit uppercase' color="primary" onClick={this.handleCommentSubmit}>Submit</Button>
                                            <Button variant="contained" className='ml-16 newButton btnRed btnCancel uppercase' onClick={this.toggleCommentModal}>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                dataBrowserState: state.dataBrowser,
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                getDataBrowserValidationData(data) {
                    dispatch({
                        type: 'FETCH_DATABROWSER_VALIDATION_DATA',
                        payload: data
                    });
                },
                getSummaryDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_DATA'
                    });
                },
                postDataBrowserValidationComment(data) {
                    dispatch({
                        type: 'SEND_DATABROWSER_VALIDATION_COMMENT',
                        payload: data
                    });
                }
            };
        }
    )(DataValidationTableComponent);
