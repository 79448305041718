import React from 'react';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import CommentBox from './CommentFunctionality';
import { startWS, isSocketConnectionOpen } from '../../../utils/web-socket'
import Dashboard from './dashboard';
import Growth from './growth';
import MarginAnalysis from './marginAnalysis';
import Economies from './economies';
import CustomerChangeAnalysis from './customer';

// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// import Button from '@material-ui/core/Button';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import AnalsisImg from '../../../images/sus1.png'
// import Prof1Img from '../../../images/marg1.png'
// import Prof2Img from '../../../images/eco1.png'
// import CCAImg from '../../../images/cca.png'
import Dash1Img from '../../../images/dash2.png'



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class NewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.channel_id = '574d4454-f337-4c34-b083-50d75930934a'
        this.customerId = 22028246
        this.componentMap = {
            0: 'Dashboard',
            1: 'Growth',
            2: 'MarginAnalysis',
            3: 'Economies',
            4: 'Customer'
        };
        this.tabMap = {
            0: 'summary',
            1: 'BU 1',
            2: 'BU 2',
            3: 'BU 3',
            4: 'BU 4',
            5: 'OH'
        }

        // this.imgMap = {
        //     0: AnalsisImg,
        //     1: Prof1Img,
        //     2: Prof2Img,
        //     3: CCAImg,
        //     4: Dash1Img
        // }

        this.radioMap = {
            0: 'summary',
            1: 'plant',
            2: 'le',
            3: 'region',
            4: 'country',
            5: 'hemisphere'
        }

        this.socketCollection = {};

        this.state = {
            showLoader: true,
            isDetailedView: false,
            regionClicked: {},
            monthSelectorValue: '',
            qtrSelectorValue: '',
            yearSelectorValue: this.props.incomeStatementState.defaultYear &&
                this.props.incomeStatementState.yearFilterArr.length ? this.props.incomeStatementState.defaultYear : '',
            radioSelectorValue: '0',
            radioSelectorReconValue: '0',
            comparativeSelectorValue: '1',
            tabSelectorValue: 0,
            radioSelectorValueLE:"summaryLE",
            newRadioSelectorValue: '4',
            commentRow: {},
            chatDataCollection: {},
            uploadImageObj: {},
            bu_Value:'',
            regionValue:'',
            countryValue:'',
            segmentValue:'',
            keyValue:'',
            periodValue:'',
        }

        this.buFilterArr = ['BU-1', 'BU-2', 'BU-3', 'BU-4'];
        this.regionfilterArr = ['R-1', 'R-2', 'R-3', 'R-4'];
        this.countryfilterArr = ['INDIA', 'USA', 'UK', 'RUSSIA'] ;
        this.segmentfilterArr = ['S-1', 'S-2', 'S-3', 'S-4'];
        this.keyAccountsfilterArr = ['KA-1', 'KA-2', 'KA-3', 'KA-4'];
        this.periodsfilterArr = ['P-1', 'P-2', 'P-3', 'P-4'];

    }

    componentDidMount() {
        this.props.getNewDefaultMonthData()
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    componentDidUpdate(prevProps) {
        const { yearFilterArr, defaultYear, lastIngestion } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
            this.setState({
                yearSelectorValue: defaultYear,
                // lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
            })
        }
    }

    handleRadioChange = (event) => {
        const { defaultYear } = this.props.incomeStatementState
        this.setState({
            radioSelectorValue: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangeLE = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValueLE: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };    
    handleRadioReconChange = (event) => {
        const { defaultYear, tabSelectorValue } = this.props.incomeStatementState
        console.log('eventtttt', event.target.value )

        // if(event.target.value == 0){

            this.setState({
                radioSelectorReconValue: event.target.value,
            //     radioSelectorValue: '0'
            }, () => {
                this.handleFetchNewData()
            })
        // }else{
            // this.setState({
            //     radioSelectorReconValue: event.target.value,
            // })
        // }
        // this.setState({
        //     radioSelectorValue: event.target.value,
        //     qtrSelectorValue: '',
        //     monthSelectorValue: '',
        //     yearSelectorValue: defaultYear,
        //     excludeDataFilter: []
        // }, () => {
        //     this.handleFetchNewData()

        // })

    };

    // handleNewRadioChange = (event) => {
    //     const { defaultYear } = this.props.incomeStatementState
    //     this.setState({
    //         newRadioSelectorValue: event.target.value,
    //         qtrSelectorValue: '',
    //         monthSelectorValue: '',
    //         yearSelectorValue: defaultYear,
    //         excludeDataFilter: []
    //     })
    // };



    handleFetchNewData = () => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, radioSelectorReconValue, radioSelectorValueLE} = this.state;

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue,
            radioSelectorValueLE: radioSelectorValueLE

        }
        this.props.getNewPivotData(payload)

        if ((tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && (radioSelectorValue == 3 || radioSelectorValue == 4 || radioSelectorValue == 5)) {
            this.fetchNewPieDataFn()
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabSelectorValue: newValue,
            buValue:'',
            segmentValue: '',
            periodValue:'',
            countryValue:'',
            regionValue:'',
            keyValue:'',
        })
    }

    handleInvoiceClick = (payload) => {

        const { qtrSelectorValue, radioSelectorValueLE, monthSelectorValue, yearSelectorValue, radioSelectorValue, tabSelectorValue } = this.state
let subTab
        if(radioSelectorValue == 2){
            subTab = radioSelectorValueLE
        }else{
            subTab = this.radioMap[radioSelectorValue]
        }

        let drilldownPayload = {
            ...payload,
            sub_tab_value: subTab,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            tab_value: "summary",
            typeSelector: "Summary",
            view:"month"
        }
        console.log(drilldownPayload, "testtest")
        if(payload.sub_grid_header_value == "current"){
            this.props.getBUPLInvoiceDetail(drilldownPayload);
        }else{

            this.props.fetchNewDrilldownData(drilldownPayload)
        }
    }

    handleBackClick = () => {
        this.setState({
            isDetailedView: false,
            radioSelectorValue: '3',
            regionClicked: {}
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleRegionHeaderClick = (payload) => {
        this.setState({
            isDetailedView: true,
            radioSelectorValue: '4',
            regionClicked: payload
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleToolTipValue = (index) => {
        let reactComp = (
            <div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p className="mb-0">New orders trend vs revenue is indicative of future growth.</p>
                    <p className="mt-0">Filters allow for focus on respective unit of performance.</p>
                    <p><i>*No of months sales in Orderbook can also be used as a measure.</i></p>
                </div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                    Data required:<br></br>
                    New bookings data for BU-1 and BU-2 businesses appears to be available on the BI reports, this needs to be confirmed and new bookings data for BU-3 and BU-4 will be required to enable this chart.
                </div>
            </div>
        );
        if (index == 1) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart</h3>
                        <p> Margin growth analysis, to make sure that:</p>
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>Inflationary cost pressures as a minimum are being passed on the client.</li>
                            <li>Improvement in products and new products are returning higher margins.</li>
                            <li>Product mix is managed to deliver higher margins vs otherwise.</li>
                        </ol>
                        <p>This analysis can be supplemented with a Volume Price analysis by Products and Customers to enable above strategies.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales and COGS data by Customer and Product will be required to enable additional Price Volume analysis to support standard margin management.
                    </div>
                </div>
            );
        }
        if (index == 2) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p>Other costs of sales is most likely fixed costs, and should be monitored to improve as a % of sales with efficiencies or sales growth.</p>
                </div>);
        }
        if (index == 3) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart:</h3>
                        <p>Lead in to market activity / share information.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales by Customer will be required to enable this chart.
                    </div>
                </div>
            );
        }
        if (index == 4) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <p className='mt-0'>
                        Provides overview of key focus areas at a glance.<br></br>Digital solutions can be quickly built to improve performance in areas that are lagging.
                    </p>
                    <p>
                        An automated follow-up email can be sent to respective locations / metrics owners with a cc to the Senior Management Team.<br></br>Refer Overdue AR Automated Follow-up Solution as an example.
                    </p>
                </div>
            )
        }
        return reactComp
    }

    handleExcelDownload = () => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue,
            monthSelectorValue, radioSelectorReconValue, regionClicked } = this.state;

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3,
            export: true,
            reconValue: radioSelectorReconValue,
            apiEndPoint: radioSelectorValue,
            tabValue: tabSelectorValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,


        }
        this.props.downloadNewPivotData(payload)
    }


    fetchNewPieDataFn = () => {

        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue,regionClicked,
            monthSelectorValue } = this.state;
            let payload = {
                tabValue: tabSelectorValue,
                year: yearSelectorValue ? yearSelectorValue : undefined,
                quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
                yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
                apiEndPoint: radioSelectorValue,
                region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            }

            this.props.fetchNewPieData(payload)

    }

    handlePostManualData = (data) => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, radioSelectorReconValue } = this.state;

        let mainData = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue
        }

        let payload = {
            manualData: { ...data },
            mainData
        }
        this.props.postNewManualAmountData(payload)
    }

    handleChatUpdation = (gData) => {
        const { tableRowData, updateFlag } = this.state
        const { externalState } = this.props;
        const { parentGroup, buValue } = this.props
        if (externalState.data && externalState.data.row_data && externalState.data.row_data.length) {
          const rowDataSet = externalState.data.row_data
          let indexData = -1;
          let updatedRowDataSet = rowDataSet.map((item, index) => {
            if (!item['obj'][gData.chatData[0].id]) {
              if (parentGroup == "unbilled" && buValue == "pab") {
                if (item.customer == gData.customerId && item.company_code == gData.company_code && item.project_id == gData.project_id) {
                  item['isChatConnection'] = true;
                  indexData = index;
                  item.commentArr.unshift(gData.chatData[0])
                  item['obj'][gData.chatData[0].id] = true
                }
              } else {
                if (item.customer == gData.customerId && item.company_code == gData.company_code) {
                  item['isChatConnection'] = true;
                  indexData = index;
                  item.commentArr.unshift(gData.chatData[0])
                  item['obj'][gData.chatData[0].id] = true
                }
              }
    
            }
            return item
          })
          if (indexData > -1) {
            if (updatedRowDataSet[indexData].commentArr && updatedRowDataSet[indexData].commentArr.length) {
              let obj = {}
              let commentModifiedArr = []
              updatedRowDataSet[indexData].commentArr.forEach(item => {
                if (!obj[item.id]) {
                  commentModifiedArr.push(item)
                  obj[item.id] = true
                }
              })
              updatedRowDataSet[indexData].commentArr = commentModifiedArr;
            }
          }
          this.props.updateExternalData(updatedRowDataSet);
        }
      }
    
      handleConnectionClose = (channelId) => {
        console.log(channelId, "connection closed")
        if (this.socketCollection[channelId]) {
          delete this.socketCollection[channelId]
        }
      }
    
      getPipID = () => {
        this.props.getPipIDData({})
      }
    
      handleSocketConnectionError = (e) => {
        // this.props.socketConnectionError(true)
      }
    
      establishSocketConnection = (data) => {
        if (!this.socketCollection[this.channel_id]) {
          const socketInfo = startWS(
            this.channel_id,
            this.customerId,
            '7100',
            '',
            data ? data.commentArr : [],
            this.handleChatUpdation,
            this.handleConnectionClose,
            '',
            this.handleSocketConnectionError
          )
          this.socketCollection[this.channel_id] = socketInfo;
        }
      }
    
      handleSendChatAttachmentFunction = (attachmentData) => {
        const sendChatObject = { "body": attachmentData.id, "type": attachmentData.type, "file_name": attachmentData.original_name }
        this.props.uploadChatFileToggle();
        if (isSocketConnectionOpen(this.socketCollection[attachmentData.channel])) {
          this.socketCollection[attachmentData.channel].send(JSON.stringify(sendChatObject));
        }
      }
    
      handleSendChatFunction = (rowData) => {
        let sendChatObject = {}
        if (document.getElementById(`outlined-basic-${this.channel_id}`).value) {
          sendChatObject = {
            "body": document.getElementById(`outlined-basic-${this.channel_id}`).value,
            "type": 'text',
            "tagged_user": this.taggedUser
          }
          if (!isSocketConnectionOpen(this.socketCollection[this.channel_id])) {
            delete this.socketCollection[this.channel_id];
            this.establishSocketConnection(rowData)
          }
          if (isSocketConnectionOpen(this.socketCollection[this.channel_id])) {
            this.socketCollection[this.channel_id].send(JSON.stringify(sendChatObject));
            this.chatDataCollection[this.channel_id] = ''
            document.getElementById(`outlined-basic-${this.channel_id}`).value = ''
            this.taggedUser = []
          }
        }
      }
    
    
      handleChatChangeFn = (event, rowData) => {
        if (event.target && event.target.value) {
          this.chatDataCollection[this.channel_id] = event.target.value;
          const valueLen = event.target.value.length;
          const targetValue = event.target.value;
          if (valueLen && targetValue[valueLen - 1] == '@') {
            this.setState({ refMenuAnchorEl: event.currentTarget });
          }
        }
      }
    
      onImageInputHandler = (event, props) => {
        let imagePreview
        if (event.target.files[0].type == 'application/pdf' || event.target.files[0].type ==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          imagePreview = '';
        } else {
          imagePreview = URL.createObjectURL(event.target.files[0]);
        }
        let imageName = event.target.files[0].name;
        const { uploadImageObj } = this.state;
        uploadImageObj[props.channel_customer] = {
          imagePreview,
          imageName,
          file: event.target.files[0]
        }
        this.setState({ 'uploadImageObj': uploadImageObj })
      }
    
    handleImageOptions = (event, props) => {
        const { uploadImageObj } = this.state;
        if (event === 'approve') {
          const imageObj = uploadImageObj[props.channel_customer]
          if (imageObj) {
            const payloadData = {
              file: imageObj.file,
              id: props.channel_customer
            }
            this.props.uploadChatFile(payloadData)
          }
        }
        if (event === 'cancel') {
          this.imagePreview = null;
        }
        if (document.getElementById('upload-icon')) {
          document.getElementById(`upload-icon`).value = '';
        }
        document.getElementById(`upload-icon${props.channel_customer}`).value = '';
        delete uploadImageObj[props.channel_customer]
        this.setState({ 'uploadImageObj': uploadImageObj })
    }

    handleSendChatModalFunction = (rowData, inputValue, taggedUser) => {
        const { chatDataCollection } = this.state;
        const sendChatObject = { "body": inputValue, "type": 'text', "tagged_user": taggedUser }
        if (inputValue) {
          if (!isSocketConnectionOpen(this.socketCollection[this.channel_id])) {
            delete this.socketCollection[this.channel_id];
            this.establishSocketConnection(rowData)
          }
          if (isSocketConnectionOpen(this.socketCollection[this.channel_id])) {
            this.socketCollection[this.channel_id].send(JSON.stringify(sendChatObject));
            chatDataCollection[this.channel_id] = ''
            this.setState({ "chatDataCollection": chatDataCollection })
          }
        }
      }


    setCommentModalData = () => {
        const { commentRow } = this.state
        const { externalState } = this.props
        let commentArrData = []
        if (externalState.data.row_data && externalState.data.row_data.length) {
          commentArrData = externalState.data.row_data.filter(item => {
            if (item.channel_customer == commentRow.channel_customer) {
              return item
            }
          })
        }
        return commentArrData && commentArrData[0];
      }

    handleBUChange = (event) => {
        this.setState({
            bu_Value: event.target.value,
        })
    }

    handleRegionChange = (event) => {
        this.setState({
            regionValue: event.target.value,
        })
    }

    handleCountryChange = (event) => {
        this.setState({
            countryValue: event.target.value,
        })
    }

    handleSegmentChange = (event) => {
        this.setState({
            segmentValue: event.target.value,
        })
    }

    handleKeyAccountsChange = (event) => {
        this.setState({
            keyValue: event.target.value,
        })
    }

    handlePeriodsChange = (event) => {
        this.setState({
            periodValue: event.target.value,
        })
    }

    render() {
        const {bu_Value, regionValue, countryValue, segmentValue, keyValue, periodValue} = this.state;
        const  { externalState, dashboardState, authState, invoiceDetailState } = this.props;
        const { radioSelectorValueLE, radioSelectorValue, radioSelectorReconValue, newRadioSelectorValue, isDetailedView, tabSelectorValue, comparativeSelectorValue, commentRow, uploadImageObj } = this.state;
        const commentModalData = this.setCommentModalData();
        const menus = authState.menuData;
        let Component;
         if (tabSelectorValue == 1){
            Component =  <Growth/>
        }  else if (tabSelectorValue ==2){
            Component = <MarginAnalysis/>
        } else if (tabSelectorValue == 3){
            Component = <Economies/>
        } else {
            Component = <CustomerChangeAnalysis/>
        }

        return (
            <div className='buplComponent analyze-analytics'>
                <div className='flex jc-space-between'>
                    
                        <Grid container spacing={1}>

                            { ( tabSelectorValue == 4) &&
                            <Grid item xs={12} sm={2} className='maxw-200 segment-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Segment</InputLabel>
                                    <Select
                                        value={ segmentValue}
                                        label="Segment"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleSegmentChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.segmentfilterArr && this.segmentfilterArr.length && this.segmentfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}

                            { tabSelectorValue==0 &&
                            <Grid item xs={12} sm={3} className='maxw-200 bu-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Business Unit</InputLabel>
                                    <Select
                                        value={bu_Value}
                                        label="Business Unit"
                                        onChange={this.handleBUChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.buFilterArr && this.buFilterArr.length && this.buFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid> }

                            { 
                            <Grid item xs={12} sm={2} className='maxw-200 region-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Region</InputLabel>
                                    <Select
                                        value={regionValue}
                                        label="Region"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleRegionChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.regionfilterArr && this.regionfilterArr.length && this.regionfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}
                            
                            { tabSelectorValue > 0 && 
                            <Grid item xs={12} sm={3} className='maxw-200 country-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        value={countryValue}
                                        label="Country"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleCountryChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.countryfilterArr && this.countryfilterArr.length && this.countryfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}

                            { (tabSelectorValue == 1 || tabSelectorValue == 2 ) &&
                            <Grid item xs={12} sm={2} className='maxw-200 segment-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Segment</InputLabel>
                                    <Select
                                        value={ segmentValue}
                                        label="Segment"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleSegmentChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.segmentfilterArr && this.segmentfilterArr.length && this.segmentfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}

                            { (tabSelectorValue == 1 || tabSelectorValue ==2 || tabSelectorValue == 4) &&
                            <Grid item xs={12} sm={3} className='maxw-200 key-account-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Key Accounts</InputLabel>
                                    <Select
                                        value={keyValue}
                                        label="Key Accounts"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleKeyAccountsChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.keyAccountsfilterArr && this.keyAccountsfilterArr.length && this.keyAccountsfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}

                            { tabSelectorValue < 4 &&
                            <Grid item xs={12} sm={2} className='maxw-200 period-ddl'>
                                <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Periods</InputLabel>
                                    <Select
                                        value={periodValue}
                                        label="Periods"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handlePeriodsChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {this.periodsfilterArr && this.periodsfilterArr.length && this.periodsfilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid> }
                            
                        </Grid>
                    
                    {/* <Grid container justify="flex-end">

                        <Tooltip title={'Download to Excel'} className='pointer'>
                            <img
                                src={DownloadExcel}
                                className='pointer'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleExcelDownload();
                                }} />
                        </Tooltip>
                    </Grid>  */}
                </div>
                <div>
                    <Tabs
                        value={tabSelectorValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className='mt-16 mb-16'
                    >
                        <Tab label="Dashboard"  {...this.a11yProps(0)} />
                        <Tab label="Growth"  {...this.a11yProps(1)} />
                        <Tab label="Margin Analysis"  {...this.a11yProps(2)} />
                        <Tab label="Economies of Scale"  {...this.a11yProps(3)} />
                        <Tab label="Customer Change Analysis"  {...this.a11yProps(4)} />

                    </Tabs>
                    <TabPanel value={'a'} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={'b'} index={1}>
                        Item Two
                    </TabPanel>

                </div>
               
                {
                    <div className='flex ai-flex-start content-div'>
                        <Grid container spacing={1}>
                            <Grid item xs={8} >
                              { tabSelectorValue ==0 ? <img width="100%" className="mr-10" src={Dash1Img} style={{ maxWidth: '900px', height: '600px' }} />  : Component }  
                            </Grid>
                            <Grid item xs={4} spacing={2}>
                               {this.handleToolTipValue(tabSelectorValue)}
                                <CommentBox
                                commentRowData={commentRow}
                                commentsData={commentModalData}
                                userDataState={authState.userData}
                                sendCommmentCallback={this.handleSendChatModalFunction}
                                buValue={this.props.buValue}
                                onImageInputHandler={this.onImageInputHandler}
                                handleImageOptions={this.handleImageOptions}
                                uploadImageObj={uploadImageObj}
                                allUsers={this.props.externalState.allUser}
                                menuData={menus}
                                />
                            </Grid>

                        </Grid>
                        
                    </div>
                }
            </div>
        );
    }
}

export default connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                externalState: state.dashboardState.receivableData.ageingData.external,
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                dashboardState: state.dashboardState,
                authState: state.authReducer,
                invoiceDetailState: state.invoiceDetailState,
                chatStoreState: state.chatStore
            }
        },
        dispatch => {
            return {
                getNewDefaultMonthData() {
                    dispatch({
                        type: 'FETCH_New_DEFAULT_MONTH'
                    });
                },
                getNewPivotData(data) {
                    dispatch({
                        type: 'FETCH_New_PIVOT_ALL',
                        payload: data

                    });
                },
                downloadNewPivotData(data) {
                    dispatch({
                        type: 'DOWNLOAD_EXCEL_New_PIVOT_ALL',
                        payload: data

                    });
                },
                getBUPLInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_BUPL_INVOICE_DETAIL',
                        payload: data
                    });
                },
                getUserDetail() {
                    dispatch({
                        type: 'GET_USER_DETAILS'
                    });
                },
                fetchNewDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewPieData(data) {
                    dispatch({
                        type: 'FETCH_New_PIE_CHART_DATA',
                        payload: data
                    });
                },
                postNewManualAmountData(data) {
                    dispatch({
                        type: 'POST_new_MANUAL_DATA',
                        payload: data
                    });
                },
            };
        }
    )(NewComponent);
