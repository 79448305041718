import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from 'react';
import  { parseNumber, parsePrice } from '../../../../../utils/numberUtils';
import { regionNames, hemisphereLngLtd, regionLngLtd } from './map-data';
import './map-style.css';
import './mapbox-gl.css';
import {CONFIG} from '../../../../../config';

const getDSOIconType = (DSO) => {
	let iconType = '';

	if (DSO < 5) {
		iconType = 'dso-green';
	}

	if (-5 <= DSO && DSO <= 5) {
		iconType = 'dso-yellow';
	}

	if (DSO > 5) {
		iconType = 'dso-red';
	}

	return iconType;
};

const getMarginIconType = (margin) => {
	let iconType = '';

	if (margin < 2) {
		iconType = 'margin-red';
	}

	if (-2 <= margin && margin <= 2) {
		iconType = 'margin-yellow';
	}

	if (margin > 2) {
		iconType = 'margin-green';
	}

	return iconType;
};



mapboxgl.accessToken ='pk.eyJ1Ijoic2Vuc2lhLWVhY2lpdCIsImEiOiJja3RqbWIzNDExZGIwMnJqeWpldzh2Z3hxIn0.SZjYVrM2Ng5UTj-Y1zQ-aA';

const plantlocations = [
	[74.31825,26.10467], //Beawar
	[85.85636,22.75382], //Jharkhand
	[84.35076,24.77529], //Aurangabad
	[82.05075,21.60489], //Bhatapara
	[76.88926,29.39544], //Panipat
	[77.22087,17.05329], //Karnataka
	[74.46642,18.47401], //Maharashtra
	[85.72539,20.5357],  //Odisha
	[78.06349,29.75736], //Haridwar
	[75.39496,26.93273], //Jaipur
	[76.77863,28.12223], //Khushkhera
	[74.19309,26.27037], //Bangur City-Ras
	[73.86614,29.15712], //Suratgarh
	[77.65598,28.49556], //Bulandshahar
];

// A function to make a directions request from website
async function getRoute(map, coords) {

	const createRouteUrl = (coords) =>{
		let url="https://api.mapbox.com/directions/v5/mapbox/driving/"
		coords.map((point, index)=> {
			if (index<coords.length-1){
				url+=point[0]+','+point[1]+';'
			} else {
				url+=point[0]+','+point[1]
			}
			
		})
		return url+"?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=" + mapboxgl.accessToken
	}

	const queryUrl = createRouteUrl(coords)

	const query = await fetch(queryUrl,{ method: 'GET' });
	const json = await query.json();
	const data = json.routes[0];
	const route = data.geometry.coordinates;
	const geojson = {
		type: 'Feature',
		properties: {},
		geometry: {
		type: 'LineString',
		coordinates: route
		}
	};
	//Add layers
	const layername=`Route from ${coords[0][0]},${coords[0][1]} to ${coords[coords.length-1][0]}, ${coords[coords.length-1][1]}`;


	//Check if there's already a route between these 2 points
	if (map.getSource('route')) {
		map.getSource('route').setData(geojson);
		//console.log ('updation loop', map.getStyle().layers)

	}
	// otherwise, we'll make a new request
	else {
		map.addLayer({
		id: layername,
		type: 'line',
		source: {
			type: 'geojson',
			data: geojson
		},
		layout: {
			'line-join': 'round',
			'line-cap': 'round'
		},
		paint: {
			'line-color': '#3887be',
			'line-width': 5,
			'line-opacity': 0.75
		}
		});
		//console.log ('new addition loop', map.getStyle().layers)
	}

}

// Function to generate geojson circles without having to use another dependency. Increase the value for points for higher resolution circles.
const createGeoJSONCircle = function(center, radiusInKm, points) {
    if(!points) points = 100;

    var coords = {
        latitude: center[1],
        longitude: center[0]
    };

    var km = radiusInKm;

    var ret = [];
    var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
    var distanceY = km/110.574;

    var theta, x, y;
    for(var i=0; i<points; i++) {
        theta = (i/points)*(2*Math.PI);
        x = distanceX*Math.cos(theta);
        y = distanceY*Math.sin(theta);

        ret.push([coords.longitude+x, coords.latitude+y]);
    }
    ret.push(ret[0]);

    return {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ret]
                }
            }]
        }
    };
};




async function getNearestRoute (map,mapData,popupFail){ //Nearest plant to a city
	const coords = mapData.geometry.coordinates.slice();
	const name = mapData.properties.description;
	const url = `${CONFIG.api_base_url}shreeCement/getroutes?coordinate=${coords[0]},${coords[1]}&name=${name}`;
	const query = await fetch(url,{ method: 'GET' });
	const json = await query.json();
	
	if (query.status==200){	
		const cityCoords= json.city.geometry.coordinates;
		const plantCoords =  json.plant.geometry.coordinates;
		// console.log ('Plant coords', plantCoords);
		// console.log ('City coords', cityCoords);

		const getPopupPlacement = (cityCoords, plantCoords) =>{
			let direction = ''
			const diffLng = plantCoords[0]- cityCoords[0];
			const diffLat = plantCoords[1]- cityCoords[1];
			function calcAngleDegrees(x, y) {
				const degree = (Math.atan2(y, x) * 180) / Math.PI;
				if (degree >= 0 ) return degree;
				else {return (degree+360)}
			}
			const angle = calcAngleDegrees(diffLng, diffLat)
			if (angle >=0 && angle < 22.5){
				direction = 'right'
			} else if (angle >= 22.5 && angle < 67.5) {
				direction = 'top-right'
			} else if (angle >=67.5 && angle < 112.5) {
				direction = 'top'
			} else if (angle >= 112.5 && angle < 157.5) {
				direction = 'top-left'
			} else if (angle >= 157.5 && angle < 202.5 ) {
				direction = 'left'
			} else if (angle >= 202.5 && angle <247.5 ) {
				direction = 'bottom-left'
			} else if (angle >= 247.5 && angle < 292.5) {
				direction = 'bottom'
			} else if (angle >= 292.5 && angle < 337.5) {
				direction = 'bottom-right'
			} else if (angle >=337.5) {
				direction = 'right'
			} else {direction = 'center'}
		
			return direction
		
		}

		
		const direction = getPopupPlacement(cityCoords, plantCoords)
		// console.log ('direction', direction)


		const routes = json.route.routes; //Routes between Source and Destination
		let minRoute = {};
		if (routes.length >=2){
			const route1dist = routes[0].distance;
			const route2dist = routes[1].distance;
			minRoute = (route1dist<= route2dist) ? routes[0] : routes[1]
		} else if (routes.length==1){
			minRoute = routes[0]
		}
		const geojson = {
			type: 'Feature',
			properties: {},
			geometry: {
			type: 'LineString',
			coordinates: minRoute.geometry.coordinates
			}
		};
		const minRouteDistance =  minRoute ? minRoute.distance : 0;
		const roundedDistance = Math.round(minRouteDistance/1000)
		const popupHTML=`<p>City: ${name}</p> <p>Plant: ${json.plant.properties.location ? json.plant.properties.location : 'Name Unavailable'}</p><p>Distance: ${roundedDistance} km</p>`;
		//Check if there's already a route between these 2 points
		if (map.getSource('route')) {
			map.getSource('route').setData(geojson);
	
		}
		// otherwise, we'll make a new request
		else {
			map.addLayer({
			id: 'route',
			type: 'line',
			source: {
				type: 'geojson',
				data: geojson
			},
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-color': '#015ABB',
				'line-width': 5,
				'line-opacity': 0.75
			}
			});
				
		}

		const popupSuc = new mapboxgl.Popup({
			closeButton: true,
			closeOnClick: true,
			anchor: direction
		});

		popupSuc.setLngLat(coords).setHTML(popupHTML).addTo(map)

	} else {
		popupFail.setLngLat(coords).setText('Error finding route to nearest plant').addTo(map)
	}
}




const MapBoxContainer = (props) => {
	const options = [
		{
			name: 'Revenue',
			description: '',
			property: 'pop_est',
			legendRevenueLabel: [
				['>=20%', '#7130ae'],
				['5-20%', '#9b74c5'],
				['<5%', '#d7c8e9'],
			],
			legendDSOLabel: [
				['<5 Days', '#008000'],
				['+/-5 Days', '#f3b700'],
				['>5 Days', '#d2222d'],
			],
			legendMarginLabel: [
				['>2%', '#008000'],
				['+/-2%', '#f3b700'],
				['<2%', '#d2222d'],
			],
			stops: [
				[-1, '#e7e7e7'],
				[0, '#7130ae'],
				[1000000, '#9b74c5'],
				[5000000, '#d7c8e9'],
			],
		},
	];
	const mapContainerRef = useRef(null);
	const [active, setActive] = useState(options[0]);
	const [map, setMap] = useState(null);

	const { type, mapData, radius } = props;
	const data = mapData?.data ?? [];
	const radiusinKM= radius? radius : 100;
	const summary_dso = mapData?.summary_dso ?? 0;
	const summary_margin = mapData?.summary_margin ?? 0;
	

	const kmToPixelsAtMaxZoom = (km, latitude) => {
		let meters = km*1000;
		return meters / 0.075 / Math.cos(latitude * Math.PI / 180);
	}

	// Initialize map when component mounts
	useEffect(() => {
		const totalRevenue = data.reduce(
			(prevVal, currVal) => prevVal + currVal?.stats?.revenue ?? 0,
			0
		);
		const map = new mapboxgl.Map({ //Code for the basic map layout. The ends of the map and the zoom levels.
			container: mapContainerRef.current,
			//style: 'mapbox://styles/sensia-eaciit/cktogi18x18im17n26hpvs4gz',
			style:'mapbox://styles/mapbox/streets-v12',
			center: [83, 23], //[Longitude, Latitude]
			zoom: 5,
			minZoom: 0.1,
			showPlaceLabels : false,
			showRoadLabels : false,
			maxZoom:8.5,
			maxBounds:[
				[65, 5], // Southwest coordinates [Longitude, Latitude]
				[101, 38] // Northeast coordinates [Longitude, Latitude]
				]
		});

		//Detailing starts from below
		map.on('load', () => {
			map.style.stylesheet.layers.forEach(function(x) {
				if (x.type === 'symbol') {
					map.removeLayer(x.id);
				}
			});

			map.addSource('countries', {
				type: 'vector',
				url: 'mapbox://mapbox.country-boundaries-v1',
			});


			map.addSource('indiastates', { //States geojson as the source
				type: 'geojson',
				data:'/assets/indiastates.geojson',
				tolerance: 0.7
			})

			map.addSource('clientplantlocations',{ //client plant locations geojson as the source
				type:'geojson',
				data:'/assets/shreecementplants.geojson'
			})

			map.addSource('top200cities',{ //client plant locations geojson as the source
				type:'geojson',
				data:'/assets/200cities.geojson',
				generateId: true
			})


			plantlocations.map((point)=>{
				const name=`Radius for ${point}`;
				// map.addSource(name, createGeoJSONCircle(point, radius)); //the number is the circle radius in km
				// map.addLayer({ //Circle fill
				// 	"id": name,
				// 	"type": "fill",
				// 	"source": name,
				// 	"layout": {},
				// 	"paint": {
				// 		"fill-color": "#ff0000",
				// 		"fill-opacity": 0.4,
				// 	}
				// });
				// map.addLayer({ //Circle Border. Defining separately allows for having variable border width at different zoom levels.
				// 	id: `${name}-outline`,
				// 	type: 'line',
				// 	source: name,
				// 	layout: {
				// 		'line-join': 'round',
				// 		'line-cap': 'round'
				// 	},
				// 	paint: {
				// 		'line-color': '#FF0000',
				// 		'line-width': 0.5,
				// 		'line-opacity': 0.5
				// 	}
				// 	});	
				
				map.addSource(name, {
					"type": "geojson",
					"data": {
					  "type": "FeatureCollection",
					  "features": [{
						"type": "Feature",
						"geometry": {
						  "type": "Point",
						  "coordinates": point
						}
					  }]
					}
				  });
			
				  map.addLayer({
					"id": `${name}-circle`,
					"type": "circle",
					"source": name,
					"paint": {
					  "circle-radius": {
						stops: [
						  [0, 0],
						  [20, kmToPixelsAtMaxZoom(radius, point[1])]
						],
						base: 2
					  },
					  "circle-color": "red",
					  "circle-opacity": 0.4
					}
				  });
			})



			map.addLayer({
				'id': 'plantlocations',
				'type': 'circle',
				'source': 'clientplantlocations',
				'paint': {
					'circle-color': '#4264fb',
					'circle-radius': 8,
					'circle-stroke-width': 2,
					'circle-stroke-color': '#ffffff'
				}
			});

			map.addLayer({
				'id': 'citydots',
				'type': 'circle',
				'source': 'top200cities',
				'paint': {
					'circle-color': '#000000',
					'circle-radius': 4,
					'circle-stroke-width': 1,
					'circle-stroke-color': '#ffffff'
				}
			});

			const popup = new mapboxgl.Popup({
				closeButton: true,
				closeOnClick: true,
				//closeOnMove:true
			});

			const popup2 = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				// closeOnMove:true
			});

			const popup3 = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				closeOnMove:true
			});

			const popup4 = new mapboxgl.Popup({
				closeButton: true,
				closeOnClick: true,
				//closeOnMove:true
			});

			map.on('mouseover', 'citydots', (e) => { //Showing city name on hover

				map.getCanvas().style.cursor = 'pointer';

				if (e.features.length === 0) return;
				
				const coordinates = e.features[0].geometry.coordinates.slice();
				const description = e.features[0].properties.description;
				popup2.setLngLat(coordinates).setHTML(description).addTo(map);
				popup3.remove()

	
			})

			map.on ('mouseout', 'citydots', (e)=>{ //Remove popup when out of hover
				map.getCanvas().style.cursor = '';
				popup2.remove();

			} )

			map.on ('click', 'citydots', (e)=>{			
				getNearestRoute(map,e.features[0], popup3) ;
				popup2.remove();
				popup3.remove();
			})

			map.on('click', 'plantlocations', (e) => { //Showing Plant details on click
				// Change the cursor style as a UI indicator.

				//map.getCanvas().style.cursor = 'pointer';
	
				// Copy coordinates array.
				const coordinates = e.features[0].geometry.coordinates.slice();
				const description = e.features[0].properties.description;
	
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}
	
				// Populate the popup and set its coordinates
				// based on the feature found.
				popup.setLngLat(coordinates).setHTML(description).addTo(map);
				popup2.remove();
				popup3.remove();
			});

			map.on ('mouseover', 'plantlocations', (e)=>{ //Remove popup when out of hover
				map.getCanvas().style.cursor = 'pointer';
			} )

			map.on ('mouseout', 'plantlocations', (e)=>{ //Remove popup when out of hover
				map.getCanvas().style.cursor = '';
			} )




			//Custom layers built on top of one another to get a desired default sensia template. Layers for functionality to be built on top of these.

			// map.addLayer( //Put state borders
			// 	{
			// 		id: 'state-borders',
			// 		"type": "line",
    		// 		"source": "indiastates",
			// 		"paint": {
			// 			//"fill-color": ["match",["get","name"],"Andhra Pradesh","rgb(255, 0, 0)", "rgba(0,0,0,0)"],
			// 			"line-color": "#AEB1D9",
			// 			"line-width": ["interpolate",["linear"],["zoom"],3,0.5,10,3],
			// 			"line-dasharray": [10,0]
			// 		}
			// 	},
			// );

			// map.addLayer( //India base colour
			// 	{
			// 		id: 'india-fill',
			// 		"type": "fill",
    		// 		"source": "indiastates",
			// 		"paint": {
			// 			"fill-color": "rgba(0,0,0,0)",
			// 		}
			// 	},
			// 	'state-borders'
			// );

			// map.addLayer(// Clean SAARC region boundary overlap because state borders are loaded from a different geoJSOn and there are boundary mismatches
			// 	{
			// 		'id': 'countries-join',
			// 		'type': 'fill',
			// 		'source': 'countries',
			// 		'source-layer': 'country_boundaries',
			// 		'paint': {
			// 			'fill-color':["match",["get","iso_3166_1_alpha_3"],"rgba(0, 0, 0, 0)"]
			// 		},
			// 		filter : [ "all", [ "==", ["get", "disputed"], "false" ], [ "any", [ "==", "all", ["get", "worldview"] ], [ "in", 'IN', ["get", "worldview"] ] ] ]
			// 	},
			// // 	'india-fill'
			// // )
		
			// map.addLayer( //add country borders on the map
			// 	{
			// 		id: 'country-borders',
			// 		"type": "line",
    		// 		"source": "composite",
    		// 		"source-layer": "admin",
			// 		"metadata": {
			// 			"mapbox:featureComponent": "admin-boundaries",
			// 			"mapbox:group": "Administrative boundaries, admin"
			// 		},
			// 		"minzoom": 1,
			// 		"filter": [
			// 			"all",
			// 			["==",["get","admin_level"],0],
			// 			["==",["get","disputed"],"false"],
			// 			["==",["get","maritime"],"false"],
			// 			["match",["get","worldview"],["all","IN"],true,false]
			// 		],
			// 		"layout": {
			// 			"line-join": "round",
			// 			"line-cap": "round"
			// 		},
			// 		"paint": {
			// 			"line-color": 'rgb(147,147,147)',
			// 			"line-width": ["interpolate",["linear"],["zoom"],3,0.5,10,2],
			// 			"line-dasharray": [10,0]
			// 		}
			// 	},
			// 	'countries-join'
			// );

			// map.addLayer( //Cover inlandwater areas 
			// 	{
			// 		id: 'country-areas-fill',
			// 		type: 'fill',
			// 		source: 'countries',
			// 		'source-layer': 'country_boundaries',
			// 		paint: {
			// 			'fill-color': '#rgba(0,0,0,0)' ,
			// 		},
			// 	},
			// 	'country-borders'
			// );

			
			//The following filters are needed for Indian Official maps for the disputed boundaries (Setting worldview to India)
			
			map.setFilter('admin-0-boundary-disputed', [
				'all',
				['==', ['get', 'disputed'], 'true'],
				['==', ['get', 'admin_level'], 0],
				['==', ['get', 'maritime'], 'false'],
				['match', ['get', 'worldview'], ['all', 'IN'], true, false]
				]);

			map.setFilter('admin-0-boundary', [
				'all',
				['==', ['get', 'admin_level'], 0],
				['==', ['get', 'disputed'], 'false'],
				['==', ['get', 'maritime'], 'false'],
				['match', ['get', 'worldview'], ['all', 'IN'], true, false]
				]);
				
			map.setFilter('admin-0-boundary-bg', [
				'all',
				['==', ['get', 'admin_level'], 0],
				['==', ['get', 'maritime'], 'false'],
				['match', ['get', 'worldview'], ['all', 'IN'], true, false]
				])

			


			// console.log ('Total layers', map.getStyle().layers)
			// console.log ('Total Sources', map.getStyle())

			// Build a GL match expression that defines the color for every vector tile feature
			// Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
			const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
			const hemisphereUniArr = [],
				regionUniArr = [];

			data.forEach((cData) => {
				const {
					area,
					stats: { dso, margin, revenue = 0 },
					countries = [],
				} = cData;
				const parsedDso = dso - summary_dso;
				const parsedDsoString = parseNumber(dso, false);
				const parsedMargin = margin - summary_margin;
				const parsedMarginString = parseNumber(margin);
				const calculatedRevenue = ((revenue / totalRevenue) * 100).toFixed(2);
				const parsedRevenue = parsePrice(revenue);

				if (type == '0') {
					const { iso_code, latitude, longitude } = cData;

					if (latitude && latitude) {
						// Convert the range of data values to a suitable color
						let color = null;

						if (dso) {
							const iconType = getDSOIconType(parsedDso);

							if (iconType !== '') {
								// create a HTML element for each feature
								const el = document.createElement('div');
								el.className = 'circle marker-' + iconType;

								// make a marker for each feature and add it to the map
								new mapboxgl.Marker({ element: el, offset: [0, -10] })
									.setLngLat([longitude, latitude])
									.setPopup(
										new mapboxgl.Popup() // add popups
											.setHTML(
												`<span>Country - <strong>${area}</strong></span><br/>` +
													`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
													`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
													`<span>Margin  - <strong>${parsedMarginString} %</strong></span><br/>`
											)
									)
									.addTo(map);
							}
						}

						if (margin) {
							let iconType = getMarginIconType(parsedMargin);

							if (iconType !== '') {
								// create a HTML element for each feature
								const el = document.createElement('div');
								el.className = 'triangle-up marker-' + iconType;

								// make a marker for each feature and add it to the map
								new mapboxgl.Marker({ element: el, offset: [0, 10] })
									.setLngLat([longitude, latitude])
									.setPopup(
										new mapboxgl.Popup() // add popups
											.setHTML(
												`<span>Country - <strong>${area}</strong></span><br/>` +
													`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
													`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
													`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
											)
									)
									.addTo(map);
							}
						}

						if (calculatedRevenue) {
							if (calculatedRevenue >= 20) {
								color = 'rgb(113, 48, 174)';
							}

							if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
								color = 'rgb(155, 116, 197)';
							}

							if (calculatedRevenue < 5) {
								color = 'rgb(215, 200, 233)';
							}

							matchExpression.push(iso_code, color);
						}
					}
				} else if (type == '1') {
					if (dso) {
						const iconType = getDSOIconType(parsedDso);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'circle marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, -10] })
								.setLngLat(hemisphereLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Hemisphere - <strong>${area}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					if (margin) {
						let iconType = getMarginIconType(parsedMargin);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'triangle-up marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, 10] })
								.setLngLat(hemisphereLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Hemisphere - <strong>${area}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					countries.forEach(({ iso_code }) => {
						if (!hemisphereUniArr.includes(iso_code)) {
							hemisphereUniArr.push(iso_code);
							if (calculatedRevenue) {
								let color = null;

								if (calculatedRevenue >= 20) {
									color = 'rgb(113, 48, 174)';
								}

								if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
									color = 'rgb(155, 116, 197)';
								}

								if (calculatedRevenue < 5) {
									color = 'rgb(215, 200, 233)';
								}

								matchExpression.push(iso_code, color);
							}
						}
					});
				} else if (type == '2') {
					if (dso) {
						const iconType = getDSOIconType(parsedDso);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'circle marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, -10] })
								.setLngLat(regionLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Region - <strong>${regionNames[area]}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					if (margin) {
						let iconType = getMarginIconType(parsedMargin);

						if (iconType !== '') {
							// create a HTML element for each feature
							const el = document.createElement('div');
							el.className = 'triangle-up marker-' + iconType;

							// make a marker for each feature and add it to the map
							new mapboxgl.Marker({ element: el, offset: [0, 10] })
								.setLngLat(regionLngLtd[area])
								.setPopup(
									new mapboxgl.Popup() // add popups
										.setHTML(
											`<span>Region - <strong>${regionNames[area]}</strong></span><br/>` +
												`<span>Revenue - <strong>${parsedRevenue}</strong></span><br/>` +
												`<span>DSO     - <strong>${parsedDsoString} Days</strong></span><br/>` +
												`<span>Margin - <strong>${parsedMarginString} %</strong></span><br/>`
										)
								)
								.addTo(map);
						}
					}

					countries.forEach(({ iso_code }) => {
						if (!regionUniArr.includes(iso_code)) {
							regionUniArr.push(iso_code);

							if (calculatedRevenue) {
								let color = null;

								if (calculatedRevenue >= 20) {
									color = 'rgb(113, 48, 174)';
								}

								if (5 <= calculatedRevenue && calculatedRevenue <= 20) {
									color = 'rgb(155, 116, 197)';
								}

								if (calculatedRevenue < 5) {
									color = 'rgb(215, 200, 233)';
								}

								matchExpression.push(iso_code, color);
							}
						}
					});
				}
			});

			// Last value is the default, used where there is no data
			matchExpression.push('rgba(0, 0, 0, 0)');

			// Add layer from the vector tile source to create the choropleth
			// Insert it below the 'admin-1-boundary-bg' layer in the style
			map.addLayer(
				{
					id: 'countries-join',
					type: 'fill',
					source: 'countries',
					'source-layer': 'country_boundaries',
					paint: {
						'fill-color': matchExpression,
					},
				},
				'admin-1-boundary-bg'
			);

			setMap(map);
			map.resize();
		});

		// Clean up on unmount
		return () => map.remove();
	}, [JSON.stringify(data)]);

	useEffect(() => {
		paint();
	}, [active]);

	useEffect(() => {
		if (map) {
			plantlocations.map((point)=>{
				const name=`Radius for ${point}`;
				var cirRadius = {
					stops: [
						[0, 0],
						[20, kmToPixelsAtMaxZoom(radiusinKM, point[1])]
					],
					base: 2
				};
				map.setPaintProperty(`${name}-circle`, 'circle-radius', cirRadius);
			})
		}
	}, [radiusinKM]);

	const paint = () => {
		if (map) {
			map.setPaintProperty('countries', 'fill-color', {
				property: active.property,
				stops: active.stops,
			});
		}
	};

	const changeState = (i) => {
		setActive(options[i]);
		map.setPaintProperty('countries', 'fill-color', {
			property: active.property,
			stops: active.stops,
		});
	};

	return (
			<div
				ref={mapContainerRef}
				id='states-map'
				//className='map-container top right left bottom'
			/>
	);
};

export default MapBoxContainer;
