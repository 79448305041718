import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NewPagination from "../../../common/components/CustomPagination";
import { handleTableTransform } from '../../../../utils/numberUtils'
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import { downloadExcel, mapExcelData, mapExcelHeader, downloadExcelMultiSheet } from '../../../../utils/exportExcel'
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import { formatDate, excelDateToJSDate } from '../../../../utils/dateFormat'
import { format, parseJSON, parse } from "date-fns";
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { TableGL } from '../../../common/components/ExpandableGrid/TableGL';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SimpleModal(props) {
    let tableData = [];
    const { invoiceLoading } = props;

    const handleClose = () => {
        props.modalCallBack(false);
    };

    React.useEffect(() => {
        if (props.showModal) {
            tableData = []
            columnDataValue = []
            curHeader = []
            // console.log(tableData,columnDataValue,curHeader, "----UPPP")
        }
    }, [props.showModal]);

    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            tableData = data.map((item, i) => {
                return {
                    Header: 
                    <div className='flex ai-center jc-center'>
                        <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                        {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                        {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                    </div>,
                    id: item.key,
                    accessor:item.key,
                    // width: 100,
                    HeaderLabel : item.label,
                    Cell: (props) => {
                        item['operation'] = ( (item.key == 'base_amount_new') || (item.key == 'amountinlocalcur_new') || (item.key == 'usd') || (item.key == 'amountinlocalcurrency') || (item.key == 'amountinlocalcurrency2')) ? 7 : '';
                        let ori = multiYear ? props.row.original : props.original
                        if(item.key == 'amountinlocalcurrency'){
                            return (
                                <div className='text-center' >
                                    {handleTableTransform(ori, item)}
                                </div>
                            )
                        }

                        return (
                            <div className='text-center'>
                                {handleTableTransform(ori, item)}
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: "break-spaces",
                        overflow: "visible",
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }
            })
        }
        return []
    }

    let baseAmountNew = 0;
    let headerLabel = "";

    const validateDate = (data) => {
        if(data.indexOf("1901") > -1){
            return ""
        }

        return data
    }


    const convertNumberToDate = (data) => {

        data = data.map(x => {
            if (x.posting_date_new != undefined && x.posting_date_new.indexOf("/") == -1){
                if(x.posting_date_new != undefined && x.posting_date_new != "")
                x.posting_date_new = validateDate(format(parseJSON(x.posting_date_new), "dd/MM/yyyy"))
            }
            if (x.date_new != undefined && x.date_new.indexOf("/") == -1){
                if(x.date_new != undefined && x.date_new != "")
                x.date_new = validateDate(format(parseJSON(x.date_new), "dd/MM/yyyy"))
            }

            if (x.month != undefined && x.month.indexOf("/") == -1){
                if(x.month != undefined && x.month != "")
                x.month = validateDate(format(parseJSON(x.month), "dd/MM/yyyy"))
            }

            if(x.base_amount_new != undefined)
            baseAmountNew += x.base_amount_new
            
            return x
        })

        return data
    }

        


    let columnDataValue = [];
    let curHeader = props.invoiceDetailStateGL.row_header ? JSON.parse(JSON.stringify(props.invoiceDetailStateGL.row_header)) : [];
    columnDataValue = props.invoiceDetailStateGL && props.invoiceDetailStateGL.row_data ? convertNumberToDate(props.invoiceDetailStateGL.row_data) : []

    if(props.bu == "DGS"){
        let number = handleTableTransform({'num' :  baseAmountNew }, { key : 'num' , operation : 7 })
        headerLabel = props.headertext + " - Base Amount "+ number +" - GL Details" 
    }else{
        headerLabel = props.headertext + " - GL Details" + props.headertextAdditional
    }
    // console.log(columnDataValue, "--columnDataValue")

    let groupedData = columnDataValue
    .sort((a, b) => parseJSON(b.month_period) - parseJSON(a.month_period))
    .reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue["month_period"]] = result[currentValue["month_period"]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {});

    const buildSubRowData =  () => {
        let newData = [];
        curHeader.unshift({ key : "header_month", label :"Month" })
        Object.keys(groupedData).map(key => {
            let cur = {}
            curHeader.forEach(y => {
                cur[y.key] = ""
            })
            cur.header_month = format(parseJSON(key), 'MMM yy')
            cur.month_period = key
            let curGrouped = groupedData[key];
            curGrouped = curGrouped.map(x => {
                x.header_month = cur.header_month
                return x
            })
           
            cur.subRows = groupedData[key]
            
            newData.push(cur)
        });
        // console.log(newData, "-- new data push")

        // newData = newData.sort((a, b) => parseJSON(b.month_period) - parseJSON(a.month_period))

        return newData;
    }

    let multiYear = Object.keys(groupedData).length > 1
    if (multiYear){
        columnDataValue = buildSubRowData()
    }

    changeKeysOfHeader(props.invoiceDetailStateGL && curHeader);

    // console.log(groupedData, "--groupedData")

    const handleExcelDownload = () => {
        let sheetOne = {};
        let sheetTwo = {};
        let fileName = headerLabel;
        if (props.financialDuration) {
            fileName = fileName + " - " + props.financialDuration
        }
        let newTableData = [...[],...tableData]
        newTableData = newTableData.filter(x=>{
            return x.id != "expander"
        })
        let datas = columnDataValue
        let data = mapExcelData(datas, newTableData, "id")
        let header = mapExcelHeader(newTableData, "HeaderLabel")
        sheetOne.data = data;
        sheetOne.header = header;

        if(multiYear){
            let all = [];
            
            // newTableData.splice(0,1)
            // header.splice(0,1)
            // Object.keys(groupedData)
            //         .sort((a, b) => parseJSON(a) - parseJSON(b))
            //         .forEach(function(v, i) {
            //             groupedData[v] = groupedData[v]
            //         });
            Object.keys(groupedData).map(key =>{
                let curSheet = {}
                curSheet.sheetName = format(parseJSON(key), 'MMM yy');
                curSheet.header = header;
                curSheet.data = mapExcelData(groupedData[key],newTableData, "id")
                all.push(curSheet)
            })
            // console.log(header,all,newTableData,"-- ada apa export")
            downloadExcelMultiSheet(all, " Income Statement - " + fileName, false)
            return
        }
        
            downloadExcel(sheetOne.data, " Income Statement - " + fileName, sheetOne.header, false)
    }

    const handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            props.handleGlInvoiceSorting(colInfo.id)
        }
    }



    const columnHeaderClick = (state, someData, colInfo) => {

        return {
            onClick: (e) => {
                handleColumnHeaderClick(colInfo)
            },
            style: { cursor: 'pointer' }
        }
    }


    const body = (
        <div className='customModal'>
            <div className='header'>
                {<h2>{ headerLabel }</h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content pt-5'>
              { invoiceLoading ? <div className='text-center mt-10'><CircularProgress /></div> :
                <>
                <div className='text-right'>
                    <Tooltip title={'Download to Excel'}>
                        <img
                            src={DownloadExcel}
                            className='pointer'
                            height='30'
                            onClick={(event) => {
                                event.preventDefault();
                                handleExcelDownload();
                            }} />
                    </Tooltip>
                </div>
                    <Grid item xs={12} className={multiYear ? "multi-gl-container" : "" }>
                    {
                       
                        multiYear ?
                        <TableGL className="-striped -highlight"
                        columns={tableData}
                        data={columnDataValue} 
                    />
                        :
                        <Grid item xs={12} className= "modalData">
                        <ReactTable
                            columns={tableData}
                            data={columnDataValue}
                            defaultPageSize={50}
                            pageSize={columnDataValue.length}
                            loading={invoiceLoading}
                            className="-striped -highlight"
                            PaginationComponent={NewPagination}
                            sortable={false}
                            getTheadThProps={columnHeaderClick}
                        />
                        </Grid>
                        
                }

                    </Grid>
                </>
            }
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
