import React from 'react';
import { connect } from "react-redux";
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { parseJSON, formatDistance } from 'date-fns'

export class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.myInterval = {};
    this.state = {
      anchorElNotification: null
    }
  }

  componentDidMount() {
    this.props.getUserNotification();
    this.myInterval = setInterval(() => {
      // this.props.getUserNotification();
    }, 65 * 1000)
  }

  handleNotificationOpen = (event) => {
    this.setState({ anchorElNotification: event.currentTarget });
    this.handleUpdateNotification();
  };

  handleUpdateNotification = () => {
    this.props.updateUserNotification();
  }

  handleNotificationClose = () => {
    this.setState({ anchorElNotification: null });
  };

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render() {
    const { authReducer } = this.props;
    return (
      <>
        <IconButton
          onClick={this.handleNotificationOpen}
          aria-haspopup="true"
          aria-controls={'notif-menu'}
          aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={authReducer.notificationData.count} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          id="notif-menu"
          className="notificationMenu"
          anchorEl={this.state.anchorElNotification}
          keepMounted
          open={Boolean(this.state.anchorElNotification)}
          onClose={this.handleNotificationClose}
          PaperProps={{
            style: {
              maxHeight: 100 * 4.5,
              width: "40ch",
            },
          }}
        >
          {
            authReducer.notificationData.data == null ?
              <div><p>{"There is no notification"}</p></div>
              :
              authReducer.notificationData.data.map((option, idx) => (
                <div key={idx}>
                  <p>{option.notification_content}</p>
                  <h5 className='primary-dark mb-10'>{formatDistance(parseJSON(option.status_change_on), new Date())}</h5>
                </div>
              ))
          }
        </Menu>
      </>
    )
  }
}

export default connect(
  (state) => {
    return {
      authReducer: state.authReducer
    };
  },
  (dispatch) => {
    return {
      getUserNotification() {
        dispatch({
          type: 'GET_USER_NOTIFICATION'
        });
      },
      updateUserNotification() {
        dispatch({
          type: 'UPDATE_USER_NOTIFICATION'
        });
      }
    }
  }
)(Notification);