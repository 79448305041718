import React from 'react';
import SolutionImg from "../../../images/KOC/solutionWorkflow.png"
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

function SolutionWorkFLow() {
    return (
         <div className="chart-view">
             <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className="px-20">
                    <img src={SolutionImg}/>
                  </Card>
                </Grid>
              </Grid>
         </div>
        
    )
}

export default SolutionWorkFLow;