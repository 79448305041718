import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import {
    fetchActualBUTableDataService,
    fetchActualvsPlanTableDataService,
    fetchActualBUFilterService,
    fetchSimulationValueService,
    saveSimulationValueService,
    fetchBUPlanTableDataService,
    saveBUPlanDataService,
    fetchBUPlanDrilldownDataService,
    fetchActualBUDrilldownDataService,
    fetchSimulationListService,
    uploadEPForecastService
} from './service'

function* fetchActualBUTableData(data) {
    if (!data.payload.export) {

        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchActualBUTableDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: false });

}

function* fetchActualvsPlanTableData(data) {
    if (!data.payload.export) {

        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchActualvsPlanTableDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: false });

}

function* fetchBUPlanTableData(data) {
    if (!data.payload.export) {

        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: true });
    }
    const response = yield call(fetchBUPlanTableDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_ACTUAL_BU_TABLE_DATA_LOADER", loading: false });

}

function* fetchActualBUFilter(data) {
    const response = yield call(fetchActualBUFilterService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_ACTUAL_BU_FILTER_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchSimulationValue(data) {
    const response = yield call(fetchSimulationValueService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SIMULATION_VALUE_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchSimulationList(data) {
    const response = yield call(fetchSimulationListService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_SIMULATION_LIST_SUCCESS", loading: false, data: response.data });
    }
}


function* saveSimulationValue(data) {
    const response = yield call(saveSimulationValueService, data.payload);
    if (response && response.status == 200) {
        toastr.success('BU Plan Data has been updated!')
    }
}

function* resetSimulationValue(data) {
    const response = yield call(saveSimulationValueService, data.payload);
    if (response && response.status == 200) {
        toastr.success('The default values are restored!')
    }
}

function* saveBUPlanData(data) {
    const response = yield call(saveBUPlanDataService, data.payload);
    if (response && response.status == 200) {
         yield put({ type: "SAVE_BU_PLAN_DATA_SUCCESS", loading: false, data: response.data });
    }
}

function* uploadEPForecastData(data) {
    yield put({ type: "UPLOAD_EP_FORECAST_LOADER", loading: true });
    var formData = new FormData();
    formData.append("files", data.payload.file);
    formData.append("period", data.payload.period);
    formData.append("view_period", data.payload.view_period);

    const response = yield call(uploadEPForecastService, formData);
    if (response && response.status == 200) {
        toastr.success('Excel Uploaded Successfully')
         yield put({ type: "UPLOAD_EP_FORECAST_SUCCESS", loading: false, data: response.data });
    }
}

function* fetchActualBUDrilldownData(data) {

    yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_LOADER", loading: true });
    const response = yield call(fetchActualBUDrilldownDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_LOADER", loading: false });

}

function* fetchBUPlanDrilldownData(data) {
    yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_LOADER", loading: true });
    const response = yield call(fetchBUPlanDrilldownDataService, data.payload);
    if (response && response.status == 200 && !data.payload.export) {
        yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_SUCCESS", loading: false, data: response.data });
    }
    yield put({ type: "FETCH_ACTUAL_BU_DRILLDOWN_DATA_LOADER", loading: false });

}

export default function* actionWatcher() {
    yield takeLatest('FETCH_ACTUAL_BU_TABLE_DATA', fetchActualBUTableData)
    yield takeLatest('FETCH_ACTUAL_VS_PLAN_TABLE_DATA', fetchActualvsPlanTableData)
    yield takeLatest('FETCH_BU_PLAN_TABLE_DATA', fetchBUPlanTableData)
    yield takeLatest('FETCH_ACTUAL_BU_FILTER', fetchActualBUFilter)
    yield takeLatest('FETCH_SIMULATION_VALUE', fetchSimulationValue)
    yield takeLatest('SAVE_SIMULATION_VALUE', saveSimulationValue)
    yield takeLatest('RESET_SIMULATION_VALUE', resetSimulationValue)
    yield takeLatest('FETCH_SIMULATION_LIST', fetchSimulationList)
    yield takeLatest('SAVE_BU_PLAN_DATA', saveBUPlanData)
    yield takeLatest('UPLOAD_BU_PLAN_EXCEL', uploadEPForecastData)
    yield takeLatest('FETCH_ACTUAL_BU_DRILLDOWN_DATA', fetchActualBUDrilldownData)
    yield takeLatest('FETCH_BU_PLAN_DRILLDOWN_DATA', fetchBUPlanDrilldownData)
}

