import React, { useState, useCallback, useEffect } from 'react';
import FilterComponent from './filters'
import FilterIcon from '../../../../../images/filter_icon.png';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { toastr } from "react-redux-toastr";
import { handleTableTransform, numberWithCommas } from "../../../../../utils/numberUtils";
import NewReactPureTable from './pureNewTable';
import { Button, Typography, Paper, Popper, CircularProgress, Tooltip } from '@material-ui/core';
import { useSelector } from "react-redux";

let globalIndex = 0;
let draggableColumns = { mode: 'reorder', draggable: [] }


export default function Table(props) {
    const anchorRef = React.useRef(null);
    const [openFilterDimention, setOpenFilterDimention] = React.useState(false);
    const [filterArrDimention, setFilterArrDimention] = React.useState([]);
    const anchorRefMeasurement = React.useRef([]);
    const [openFiltersMeasurement, setOpenFiltersMeasurement] = useState([false, false])
    let cellsList = []
    const {showCustomFnModal, showCellId} = props



    const handleOpenFilterDimension = (event) => {
        setOpenFilterDimention((prevOpen) => !prevOpen);
    }
    const handleFilterChangeCb = (_data) => {
    }
    const refreshFilter = (i, item) => {
        handleFilterMeasurement(i, item)
    }

    const getFilters = (item) => {
    }
    const handleCustomClick = (_data, item, isRow) => {
        // console.log(cellsList, "---cellsList")
        props.handleCustomModalClick(_data, item, isRow, cellsList)
    }
    const handleCustomLabelClick = (_data, item, isRow) => {
        // console.log(cellsList, "---cellsList")
        props.handleCustomLabelModalClick(_data, item, isRow)
    }
    const handleFilterMeasurement = (index, item) => {
    }
    const handleDeleteColumn = useCallback((index, item) => {
    }, [])


    const handleShowCellId = (modifiedData, item, _data,  customFormula, actualRowData) => {
        const {showCellId} = props
        let cellId = item['column_index'] + _data['row_index']
        let isCustomFn = item['is_custom'] || _data['is_custom']
        let isRow = true;
        if (item['is_custom']) {
            isRow = false
        }
        // console.log(showCellId, cellId, isCustomFn, actualRowData,item.key,customFormula, "---cell id:: ",modifiedData)
        if (showCellId) {
            if (isCustomFn) {
                // if (modifiedData == "Custom Function") {
                //     modifiedData = actualRowData['custom_lable']
                // }else if(modifiedData != '-'){
                //     modifiedData = customFormula
                // }
                if(item['column_index']=="A"){
                    modifiedData = cellId
                } else{
                    if(modifiedData != '-'){
                        modifiedData = cellId+' = '+ customFormula
                    } else {  modifiedData = cellId }
                }
                

                // let newkey = item.key.toLowerCase().replace(' ', "_")
                // ///dsads
                //     if(isRow){
                //         modifiedData = actualRowData['custom_formula_' + newkey]
                //         if(item['column_index'] == "A"){
                //             modifiedData = actualRowData[item.key]
                //         }
                //     }else{
                //         let numID = item.key.split("-")[1]
                //         modifiedData = actualRowData['custom_formula_' + numID]
                //     }
               
            }else{
                modifiedData = cellId
            }    
        }

        if (modifiedData == "Custom Function") {
            let value = actualRowData['custom_lable'] ? actualRowData['custom_lable'] :modifiedData 
            modifiedData = value
        }
        
        if(item.operation == 7 && (item['is_custom'] || _data['is_custom']))
        cellsList.push(cellId)

        return modifiedData;
    };

    const setCustomFormula = (item, actualRowData) => {
        let customFormula;
        let isRowN = true;
        if (item['is_custom']) {
            isRowN = false;
        }
        let newkey = item.key.toLowerCase().replace(' ', "_");
        if(isRowN){
            customFormula = actualRowData['custom_formula_' + newkey];
            if(item['column_index'] == "A"){
                customFormula = actualRowData[item.key];
            }
        }else{
            let numID = item.key.split("-")[1];
            customFormula = actualRowData['custom_formula_' + numID];
        }
        return customFormula;
    };

    const modifyFunctionKey = (item) => {
        let itemKey = item;
        if (item.startsWith('Total')) {
            itemKey = "Total";
        } else if (item.startsWith('Average')) {
            itemKey = "Average";
        } else if (item.startsWith('Count')) {
            itemKey = "Count";
        } else if (item.startsWith('Percent')) {
            itemKey = "Percent";
        }
        return itemKey;
    };

    const modifyNewColumnData = (columnData, operations) => {

        let modifiedData;
        let stringVersion = String(columnData);
        if (stringVersion.includes('.') && columnData && columnData.toFixed) {
            modifiedData = Math.round(columnData)
        } else {
            modifiedData = columnData;
        }
        var isNegative = modifiedData < 0;
        var positiveTransformedValue = isNegative
            ? Math.abs(modifiedData)
            : modifiedData;
        modifiedData = numberWithCommas(positiveTransformedValue);
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
        modifiedData = modifiedData == '0' ? '-' : modifiedData;

        if (operations == 'percentage') {
            modifiedData += '%';
        }

        modifiedData = modifiedData ? modifiedData : '-'
        return modifiedData;
    };

    const handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false;
    };

    const handleColumnHeaderTooltip = (item, _data, displayValue, customFormula, actualRowData) => {
        let cellId = item['column_index'] + _data['row_index']
        let isCustomFn = item['is_custom'] || _data['is_custom']

        let tooltipData = (
            <div>
                <span>{'Cell ID: '}</span><span>{cellId}</span>
            </div>
        )

        if(props.showCellId){
            tooltipData = (
                <div>
                    <span>{'Value: '}</span><span>{displayValue}</span>
                </div>
            )
        }

        if (isCustomFn) {
            if(customFormula && displayValue != '-' ){
                tooltipData = (
                    <React.Fragment>
                        <div style={{ textAlign: 'center' }}>Details</div>
                        <div><span>{'Formula: '}</span> <span><b>{customFormula}</b></span></div>
                        <div><span>{'Result: '}</span> <span><b>{displayValue}</b></span></div>
                        <span><i>Click to change formula</i></span>
                        </React.Fragment>
    
                )
            }else{
                tooltipData = (
                    <React.Fragment>
                        <span><i>Click to add formula</i></span>
                    </React.Fragment>
    
                )
               
            }
            
        }


        if(displayValue == 'Custom Function'){
            let value = actualRowData['custom_lable'] ? actualRowData['custom_lable'] :displayValue 
            tooltipData = (
                <div>
                    <span>{'Value: '}</span><span>{value}</span>
                </div>
            )
        }

        return tooltipData;
    }

    const newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
        if (data && data.length > 0) {
            return data.map((item, i) => {
                const tempItemData = {
                    Header: item.label,
                    id:
                        item.key +
                        '_' +
                        parentData.key +
                        '_' +
                        subParentData.key +
                        Math.random('92129911') +
                        new Date().getTime(),
                    metaData: item.metadata,
                    style: {
                        // borderRight: '1px solid #423970',
                        // cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        // borderRight: '1px solid #423970'
                    },
                };

                const isNested = item.hasOwnProperty('sub_headers');

                tempItemData.Cell = (props) => {
                    const { row } = props;
                    let isCustomFn = item['is_custom'] || row.original['is_custom']

                    let showPercentage = handleAppendPercentage(row.original);
                    let myClassName = showPercentage ? 'percentageRowData' : '';
                    let showBorder = false
                    let showDrillDown = false
                    let inlinecellid= showCellId ? false : showCustomFnModal ? true : false
                    let cellId = item['column_index'] + row.original['row_index']    
                    if (subParentData?.key == 'total' && item.key == 'total') {
                        showBorder = true
                    }

                    let displayValue = '-';
                    let customFormula = '';
                    let valueToShow = '-';
                    let actualRowData = row.original &&
                    row.original.sub_column &&
                    row.original.sub_column[parentData.key] &&
                    row.original.sub_column[parentData.key]['sub_column'] &&
                    row.original.sub_column[parentData.key]['sub_column'][
                    subParentData.key
                    ]

                    if (actualRowData) {
                        displayValue = modifyNewColumnData(
                            row.original.sub_column[modifyFunctionKey(parentData.key)]['sub_column'][
                            subParentData.key
                            ][item.key],
                            row.original['operations']
                        )
                
                        // // customFormula =  actualRowData['custom_formula']
                        // // actualRowData['custom_formula_' + ]
                        // // customFormula = setCustomFunction(item, actualRowData)

                        customFormula = setCustomFormula(item, actualRowData)
                        valueToShow = handleShowCellId(displayValue, item,
                            row.original,
                            customFormula, actualRowData )
                    }else{
                        customFormula = setCustomFormula(item, {})                            
                        valueToShow = handleShowCellId(
                            displayValue, 
                            item,
                            row.original,
                            customFormula, {} )
                    }

                    if (!row.original.disable_drill_down) {
                        showDrillDown = true;
                    }
                    return (
                        <Tooltip title={handleColumnHeaderTooltip(item,row.original, displayValue, customFormula, actualRowData)}>
                            <div style={{display:'flex', flexDirection:'column', lineHeight: '40px'}}>
                            <div
                                className={`text-center ${isCustomFn ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{ borderRight: showBorder && '1px solid #423970', lineHeight: '40px' }}
                                onClick={() => {
                                    if (isCustomFn) {
                                        let isRow = true;
                                        if (item['is_custom']) {
                                            isRow = false
                                        }

                                        if(isRow && item.column_index == "A"){
                                            handleCustomLabelClick(row.original, item, isRow)
                                        }else{
                                            handleCustomClick(row.original, item, isRow)
                                        }
                                    }
                                }}
                            >
                                {
                                    <span className={myClassName}>
                                    {valueToShow ? valueToShow: '-'}
                                    </span>
                                }
                            </div>
                            <span style={{display: inlinecellid ? 'flex' : 'none', flexDirection:'row-reverse', lineHeight:'20px', marginRight:'5px',fontSize:'9px'}}>{cellId}</span>
                            </div>
                        </Tooltip>
                    );
                };

                return tempItemData;
            });
        } else return [];
    };

    const newChangeHeaderKeysLevel2 = (data, parentData, level1Index) => {
        const isClosable = data.length > 2 ? true : false
        if (data && data.length > 0) {
            return data.map((item, level2Index) => {
                const tempItemData = {
                    Header: () => {
                        let checkLastElement = level1Index == data.length - 1



                        if (parentData.key == 'dimension') {
                            return (
                                <div className="flex ai-center jc-center">
                                    <span className="header-table">{item.label}</span>
                                    {/* {item.filter_button && <span className="flex ai-center" ref={anchorRef} onClick={(e) => handleOpenFilterDimension(e)}>
                                        <img src={FilterIcon} className="ai-center icon-filter" />
                                    </span>} */}
                                </div>
                            )
                        }

                        return (
                            <div className="flex ai-center jc-center">
                                <span className="header-table">{item.label}</span>
                                {/* {<span className="flex ai-center">
                                    {checkLastElement && item.add_button && <AddCircleIcon className="mr-5" onClick={() => { handleAddColumn(item) }} />}
                                    {item.filter_button && <img id={item.key} src={FilterIcon} className="ai-center icon-filter" ref={anchorRefMeasurement.current[level2Index]} onClick={(e) => handleFilterMeasurement(level2Index, item)} />}
                                    {isClosable && level2Index > 1 && <HighlightOffIcon onClick={() => handleDeleteColumn(level2Index, item)} />}

                                </span>} */}
                            </div>
                        )

                    },
                    id: item.key + '_' + parentData.key,
                    metaData: item.metadata,
                    style: {
                        // borderRight: '1px solid #423970',
                        // cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        // borderRight: '1px solid #423970'
                    },
                };

                const isNested = item.hasOwnProperty('sub_headers');

                if (isNested) {
                    tempItemData.columns = newChangeHeaderKeysLevel3(
                        item.sub_headers,
                        parentData,
                        item
                    );
                } else {

                    tempItemData.Cell = (props) => {
                        const { row } = props;
                        let showBorder = false
                        let isCustomFn = item['is_custom'] || row.original['is_custom']
                        let displayValue = '-';
                        let valueToShow = '-';
                        let customFormula = '';
                        let actualRowData = row.original && row.original.sub_column && row.original.sub_column[parentData.key] && row.original.sub_column[parentData.key]['sub_column']
                        let inlinecellid= showCellId ? false : showCustomFnModal ? true : false
                        let cellId = item['column_index'] + row.original['row_index']    
                        if (actualRowData) {
                            displayValue = modifyNewColumnData(
                                row.original.sub_column[modifyFunctionKey(parentData.key)]['sub_column'][item.key], 
                                row.original['operations']
                                )
                            // customFormula = actualRowData['custom_formula']
                            customFormula = setCustomFormula(item, actualRowData)                            
                            valueToShow = handleShowCellId(
                                displayValue, 
                                item,
                                row.original,
                                customFormula, actualRowData )
                        }else{
                            customFormula = setCustomFormula(item, {})                            
                            valueToShow = handleShowCellId(
                                displayValue, 
                                item,
                                row.original,
                                customFormula, {} )
                        }
                            return (
                                <Tooltip title={handleColumnHeaderTooltip(item,row.original, displayValue, customFormula, actualRowData)}>
                                    <div style={{display:'flex', flexDirection:'column', lineHeight: '40px', marginTop: inlinecellid ? '5px' : ''}}>
                                    <span
                                        className={`text-center ${isCustomFn ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{ borderRight: showBorder && '1px solid #423970', lineHeight: inlinecellid ? '20px' : '40px' }}
                                        onClick={() => {
                                            if (isCustomFn) {
                                                let isRow = true;
                                                if (item['is_custom']) {
                                                    isRow = false
                                                }
    
                                                if(isRow && item.column_index == "A"){
                                                    handleCustomLabelClick(row.original, item, isRow)
                                                }else{
                                                 handleCustomClick(row.original, item, isRow)
                                                }
                                            }
                                        }}>
                                        {valueToShow ? valueToShow: '-'}
                                    </span>
                                <span style={{display: inlinecellid ? 'flex' : 'none', flexDirection:'row-reverse', lineHeight:inlinecellid ? '15px' : '20px', marginRight:'5px',fontSize:'9px'}}>{cellId}</span>
                                </div>
                                </Tooltip>
                            );
                                             
                    };
                }

                return tempItemData;
            });
        } else return [];
    };

    const newChangeKeysOfHeader = (data) => {

        if (data && data.length > 0) {
            return data.map((item, level1Index) => {
                if (level1Index) {
                    draggableColumns.draggable.push(item.key)
                }

                let isCustomFn = item['is_custom'] 

                const tempItemData = {
                    Header: () => {
                        return (<span   className= {`text-center ${isCustomFn ? 'draggable-header pointer' : 'draggable-header'}`} 
                          onClick={
                          () => {
                                        if (isCustomFn) {
                                            let isRow = true;
                                            if (item['is_custom']) {
                                                isRow = false
                                            }
                                            handleCustomLabelClick(null, item, isRow)
                                        }
                                    }}>
                            {item.label}
                        </span>)
                    },
                    accessor: item.key,
                    metaData: item.metadata,
                    // accessor: item.key,
                    style: {
                        // borderRight: '1px solid #423970',
                        // cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        // borderRight: '1px solid #423970'
                    },
                };

                globalIndex += 1;

                const isNested = item.hasOwnProperty('sub_headers');

                if (isNested) {
                    tempItemData.columns = newChangeHeaderKeysLevel2(
                        item.sub_headers,
                        item,
                        level1Index
                    );
                } else {
                    if (item.key === 'dimension' || item.key === 'sub_region' || item.key === 'sub_bu' || item.key == 'new_bu') {
                        tempItemData.sticky = 'left';
                    }
                    tempItemData.Cell = (props) => {
                        const { row } = props;
                        let showBorder = false
                        let isCustomFn = item['is_custom'] || row.original['is_custom']
                        let displayValue = '-';
                        let valueToShow = '-';
                        let customFormula = '';
                        let actualRowData = row && row.original && row.original
                        let inlinecellid= showCellId ? false : showCustomFnModal ? true : false
                        let cellId = item['column_index'] + row.original['row_index']

    
                        if (actualRowData) {
                            displayValue = modifyNewColumnData(
                                row.original[modifyFunctionKey(item.key)],
                                row.original['operations']
                            )
                            // customFormula = actualRowData['custom_formula']
                            customFormula = setCustomFormula(item, actualRowData)
                            valueToShow = handleShowCellId(
                                displayValue, 
                                item,
                                row.original,
                                customFormula,
                                actualRowData )
                            
                        }

                        return row.canExpand ? (
                            <Tooltip title={handleColumnHeaderTooltip(item,row.original, displayValue, customFormula, actualRowData)}>
                                <div style={{display:'flex', flexDirection:'column', lineHeight: '40px', marginTop: inlinecellid ? '5px' : ''}}>
                                <span
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}

                                    className={`text-center ${isCustomFn ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{ borderRight: showBorder && '1px solid #423970', lineHeight: '40px' }}
                                    onClick={() => {
                                        if (isCustomFn) {
                                            let isRow = true;
                                            if (item['is_custom']) {
                                                isRow = false
                                            }

                                            if(isRow && item.column_index == "A"){
                                                handleCustomLabelClick(row.original, item, isRow)
                                            }else{
                                                handleCustomClick(row.original, item, isRow)
                                            }
                                        }
                                    }}
                                >
                                    <span
                                        className={
                                            row.isExpanded
                                                ? 'rt-expanded-class'
                                                : 'rt-collapsed-class'
                                        }
                                    ></span>
                                    {valueToShow ? valueToShow: '-'}
                                </span>
                            <span style={{display: inlinecellid ? 'flex' : 'none', flexDirection:'row-reverse', lineHeight: inlinecellid? '15px':'20px', marginRight:'5px',fontSize:'9px'}}>{cellId}</span>
                                </div>
                            </Tooltip>

                        ) :                             
                           (<Tooltip title={handleColumnHeaderTooltip(item,row.original, displayValue, customFormula, actualRowData)}>
                            <div style={{display:'flex', flexDirection:'column', lineHeight: '40px'}}>
                            <span
                                className={`text-center ${isCustomFn ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{ borderRight: showBorder && '1px solid #423970',  lineHeight:'20px'}}
                                onClick={() => {
                                    if (isCustomFn) {
                                        let isRow = true;
                                        if (item['is_custom']) {
                                            isRow = false
                                        }

                                        if(isRow && item.column_index == "A"){
                                            handleCustomLabelClick(row.original, item, isRow)
                                        }else{
                                            handleCustomClick(row.original, item, isRow)
                                        }
                                    }
                                }}
                            >
                                {valueToShow ? valueToShow :'-'}
                            </span>
                            <span style={{display: inlinecellid ? 'flex' : 'none', flexDirection:'row-reverse', lineHeight:'20px', marginRight:'5px',fontSize:'9px'}}>{cellId}</span>
                            </div>
                            </Tooltip>
                        )
                    };
                }

                return tempItemData;
            });
        } else return [];
    };

    const newChangeKeysOfRow = (data) => {

        // return data
        if (data && data.length > 0) {
            return data.map((item, i) => {


                let meas_el = item.measurement
                for (var key in meas_el) {
                    let number = Math.ceil(meas_el[key])
                    item['measurement.' + key] = number
                }


                if (item.sub_rows) {
                    item['subRows'] = item.sub_rows

                    newChangeKeysOfRow(item.sub_rows)

                } else {
                    item['subRows'] = []

                }
                item['_myId'] = new Date().getUTCMilliseconds() + i + Math.random() * 902129120
                return item
            });
        } else return [];
    }


    const freeCashFlowState = useSelector(state => state.freeCashFlowState);
    const analyticStudioData = freeCashFlowState.analyticStudio;
    const { tableRowHeader, tableRowData, loading } = analyticStudioData
    const [transformedRowData, setTransformedRowData] = useState([])
    const [transformedRowHeader, setTransformedRowHeader] = useState([])
    const [loadingState, setLoadingState] = useState(loading)
    
   

    useEffect(() => {
        setLoadingState(true)
        let transformedRowData = newChangeKeysOfRow(tableRowData)
        let transformedRowHeader = newChangeKeysOfHeader(tableRowHeader)
        setTransformedRowData(transformedRowData)
        setTransformedRowHeader(transformedRowHeader)
        setTimeout(() => setLoadingState(loading),400)
        }, [props.showCellId,showCustomFnModal, loading])

    return (
        <div className='buplComponent free-cash-flow pl-10 pr-10 pb-10' >
            {loadingState ? <div className='text-center mt-10'>
                <CircularProgress />
            </div> : <NewReactPureTable
                pivotRowData={transformedRowData}
                loading={loadingState}
                draggableColumns={draggableColumns}
                pivotHeaderData={transformedRowHeader}
                showLoader={false}
                showCellId={props.showCellId}
            />
            }
        </div>
    )
}