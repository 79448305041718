import React from "react";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../../constant";
import { useSelector } from "react-redux";
import { useRef } from "react";

require("highcharts/modules/exporting.js")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);

function HighChart(props) {
    const {performanceAnalysis: { monthlyPerformance: { gapToTargetColor, fcfEfficiencyColor } }} = CHART_COLORS;
    const ecfmState = useSelector(state => state.ecfmState);
    const {loading,  chartRowData} = props.analyticStudioDataActiveTab;
    const chartType = props.chartType.toString();
    const chartElement = useRef();

    props.getChartSVG(chartElement)

    const options = {
        chart: {
            zoomType: 'xy',
            type: chartType,
            height : '75%',
            marginTop: 25
        },
        title: {
            text: ' '
        },
        credits: {
            enabled: false
          },
        xAxis: [{
            categories: chartRowData.x_axis,
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                // format: '${value}',
                formatter: function () {
                    let value = this.value
                    // let value = Math.ceil(max_yaxis);
                    let result = '';
                    result = max_yaxis > 2000000 ? ( parseInt(value/1000000).toString() + ' M') : (max_yaxis > 2000? (parseInt(value/1000).toString() + ' K') : (parseInt(value).toString()));
                    return '$' + result;
                },
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            title: {
                text: 'USD',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            }
        }, { // Secondary yAxis
            labels: {
                format: '{value} %',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            max:100, // need to verify this
            title: {
                text: '',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        }],
        credits: {
          enabled: false
        },
        legend: {
            verticalAlign: 'bottom',
        },
        series: chartRowData.data,
        exporting: {
            menuItemDefinitions: {
                share: {
                    onclick: props.share,
                    text: 'Share'
                },
            },
            buttons: {
                contextButton: {
                    menuItems: ['share', 'separator', "viewFullscreen",  "separator", "downloadJPEG", "downloadPDF"]
                }
            }
        }
    }

    var rowData = chartRowData.data[0].data;
    let yData = []

    for(var idx in rowData) {
        yData[idx] = rowData[idx].y;
    }

    var max_yaxis = Math.max.apply(Math, yData.map(function(o) { return o; }))
console.log(max_yaxis, "--max axis")

    return chartRowData.data && chartRowData.data.length > 0 ? (
      <div className="mat-shadow-2 labelClass analytic-studio-chart">
        <HighchartsReact
            ref={chartElement} 
            highcharts={loading ? null : Highcharts}
            options = {options}
        />
      </div>
  ) : "";
}

export default HighChart;
