import React from 'react';
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import ManagementComponent from './Management';
import ImprovementInitiativesComponent from './ImprovementInitiatives';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class ReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.state = {
            // showCompareModal: false,
            reportTabValue: 0,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            },
            viewEmail: false,
            editorMsgBody: null
        }
    }

    componentDidMount() {
        // console.log( this.props, "props")
        // this.props.getAllUser({})
    }


    handleExternalTabChange = (event, value) => {
        this.setState({
            reportTabValue: value
        })
    }

    // handleCompareClose = () => {
    //     this.setState({ showCompareModal: false })
    // }
    // handleShowCompare = () => {
    //     this.setState({ showCompareModal: true })
    // }

    render() {
        // const { plPivotState } = this.props
        const { reportTabValue, showCompareModal } = this.state

        let { tableViewState } = this.props;
        const emailTemplateData = tableViewState.data;

        return (
            <React.Fragment>
                {/* <Button className='button' style={{position:'fixed', right:'60%', zIndex: 1 }} onClick={this.handleShowCompare}>Search & Compare</Button> */}
                <div className='dayrepots-100k-120'>
                {/*  */}
                    <Grid container xs={12} sm={12} className="pr-20 pt-10 download-section" >
                        <Grid item xs={6} sm={6} className="pr-20 pt-10 buttons-section" >
                            <Tabs
                                value={reportTabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                className='mb-10'
                                onChange={this.handleExternalTabChange}
                            >
                                <Tab label="EBITDA Management" className="list-nested" id='nav-tab-1' aria-controls='nav-tabpanel-1' />
                                <Tab label="Improvement Initiatives" className="list-nested" id='nav-tab-0' aria-controls='nav-tabpanel-0' />

                            </Tabs>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} className="pr-20 pt-10 buttons-section" >
                            <Button className='button newButton btnBlue btnCompare' onClick={this.handleShowCompare}>Search and Compare</Button>
                        </Grid> */}
                    </Grid>
                    <TabPanel value={reportTabValue} index={0}>
                        <ManagementComponent 
                            getEmailTemplateEbitdaPnlBudget={this.props.getEmailTemplateEbitdaPnlBudget}
                            getEmailTemplateEbitdaSearchCompare={this.props.getEmailTemplateEbitdaSearchCompare}
                            tableViewState={emailTemplateData}
                            saveEmailTemplate={this.props.saveEmailTemplate}
                        />
                        {/* emailTemplateData={emailTemplateData}/> */}
                    </TabPanel>
                    <TabPanel value={reportTabValue} index={1}>

                        <ImprovementInitiativesComponent />
                    </TabPanel>

                </div>
                
                {/* <CompareModal 
                    showModal={showCompareModal}
                    // tabSelectorValue={tabSelectorValue}
                    // radioSelectorValue={radioSelectorValue}
                    handleClose={this.handleCompareClose}
                    plPivotState={plPivotState}
                    /> */}

            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                // plPivotState: state.shreeCementState.compareDataPNLEbitda
                tableViewState: state.shreeCementState.emailTemplateData.Ebitda
            }
        },
        dispatch => {
            return {
                updateSelectedDate(data) {
                    dispatch({
                        type: "UPDATE_SELECTED_DATE",
                        payload: data,
                    });
                },
                saveEmailTemplate(data) {
                    dispatch({
                        type: "SAVE_EMAIL_TEMPLATE_EBITDA",
                        payload: data
                    })
                },
                getEmailTemplateEbitdaPnlBudget(data) {
                    dispatch({
                        type: 'FETCH_EMAIL_TEMPLATE_EBITDA_PNL_BUDGET',
                        payload: data
                    });
                },
                getEmailTemplateEbitdaSearchCompare(data) {
                    dispatch({
                        type: 'FETCH_EMAIL_TEMPLATE_EBITDA_SEARCHCOMPARE',
                        payload: data
                    });
                }
            };
        }
    )(ReportComponent);
