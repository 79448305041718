import React from 'react';
import "react-table-6/react-table.css";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import './index.scss';

//Mui V5 components
import { Autocomplete, TextField } from "@mui/material";

export default function SimpleModal(props) {
    const {headerModal, yearMonth, filterHbp} = props

    const dispatch = useDispatch();
    const { dataArr, tradeArr, customerArr, dealerArr, regionArr, plantArr, costCenterArr } = filterHbp;
    
    const [checkTrade, setCheckTrade] = useState(false);
    const [checkNonTrade, setCheckNonTrade] = useState(false);
    const [tradeValues, setTradeValues] = useState([]);
    const [customerValues, setCustomerValues] = useState([]);
    const [dealerValues, setDealerValues] = useState([]);
    const [regionValues, setRegionValues] = useState([]);
    const [plantValues, setPlantValues] = useState([]);
    const [costCenterValues, setCostCenterValues] = useState([]);

    const SelectDropdown = ({ menuItems, handleChange, inputLabel, selectedValue, placeHolder }) => {
        return (
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <Autocomplete
                    multiple
                    className = 'date-selection new-autocomplete'
                    fullWidth
                    disablePortal
                    disableListWrap
                    size='small'
                    value={selectedValue}
                    options={menuItems}
                    onChange={handleChange}
                    getOptionLabel={(option)=> option.label}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    renderInput={(params) => (
                        <TextField
                            className="test-input-box"
                            {...params}
                            variant="standard"
                            label = {inputLabel}
                            placeholder={placeHolder}
                        />
                        )}                                                       
                />
            </FormControl>
        );
    };

    const setCheckTradeAndNonTrade = (newvalue) => {
        if (newvalue.length == 2) {
            newvalue.forEach(item => {
                if (item.key == "trade") {
                    setCheckTrade(true)
                } else if (item.key == "non trade") {
                    setCheckNonTrade(true)
                }
            })
        } else if(newvalue.length == 1) {
            newvalue.forEach(item => {
                if (item.key == "trade") {
                    setCheckTrade(true)
                    if (checkNonTrade) {
                        setCheckNonTrade(false)
                    }
                } else if (item.key == "non trade") {
                    setCheckNonTrade(true)
                    if (checkTrade) {
                        setCheckTrade(false)
                    }
                }
            })
        } else {
            setCheckTrade(false)
            setCheckNonTrade(false)
        }
    }

    const handleSetTradeChange = (event, newvalue) => {
        setCheckTradeAndNonTrade(newvalue)
        setTradeValues(newvalue)
    };


    React.useEffect(() => {
        if (dataArr!= undefined && dataArr.length > 0) {
            let valTrades = selectedOptions(dataArr[0].sales_volume.trade, tradeArr)
            setCheckTradeAndNonTrade(valTrades)
            setTradeValues(valTrades)

            setCustomerValues(selectedOptions(dataArr[0].sales_volume.customer, customerArr))
            setDealerValues(selectedOptions(dataArr[0].sales_volume.dealer, dealerArr))
            setRegionValues(selectedOptions(dataArr[0].sales_volume.region, regionArr))
            // setCostCenterValues(selectedOptions(dataArr[0].vpc.cost_center, costCenterArr))
            // setPlantValues(selectedOptions(dataArr[0].vpc.plant, plantArr))
            setPlantValues(selectedOptions(dataArr[0].vpc.cost_center, costCenterArr))
        }
    }, [dataArr])

    const selectedOptions = (a, data) =>{
        return data.filter((v) => a.indexOf(v.key) > -1)                        
    };

    const closeHbpSimulation = () => {
        props.modalCallBack(false);
    };

    const valueKey = (data) => {
        return data.map((item) => {
            return item.key
        })
    }

    const handleRunSimulation = (event) => {
        event.preventDefault();
        let cusVal = []
        let dealerVal = []
        if (checkTrade && checkNonTrade) {
            cusVal = valueKey(customerValues)
            dealerVal = valueKey(dealerValues)
        } else if (checkTrade && !checkNonTrade) {
            dealerVal = valueKey(dealerValues)
        } else if (!checkTrade && checkNonTrade) {
            cusVal = valueKey(customerValues)
        }
        let salVol = {
            "trade" : valueKey(tradeValues),
            "region" : valueKey(regionValues),
            "customer" : cusVal,
            "dealer" : dealerVal
        }

        let payload =  {
            "yearmonth": yearMonth,
            // "vpc" :{ "cost_center" :  valueKey(costCenterValues), "plant" :  valueKey(plantValues) }
            "vpc" :{ "cost_center" :  valueKey(plantValues), "plant" :  valueKey(plantValues) },
            "sales_volume": salVol    
        }
        
        dispatch({
            type: "RUN_BPSIMULATION_DATA_EBITDA",
            payload: payload
        })

        closeHbpSimulation();
    };

return (
    <div>
        <Modal
            open={props.hbpSimulation}
            onClose={closeHbpSimulation}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Fade in={props.hbpSimulation}>
                {
                    <div className='customModal hbpSimulationModal'>
                    {/* <div className='customModal'> */}
                        <div className='header'>
                            <h2>{headerModal}</h2>
                        </div>
                        <div className='modal-content email-template'>
                        {/* <div className='modal-content'> */}
                            <form onSubmit={handleRunSimulation}>
                                <div className="main-div">
                                <div className='sub-section-row'>
                                    <div className='sub-section-one'>
                                        <div className="sub-section-hbpmodal-title">
                                            <span>Sales Volume</span>
                                        </div>
                                        <div className="sub-section-hbpmodal-title">
                                            <span>VPC</span>
                                        </div>
                                    </div>
                                    <div className='sub-section-one'>
                                        <div className="sub-section-hbpmodal">
                                            {/* <span>Sales Volume</span> */}
                                            <SelectDropdown
                                                    menuItems={regionArr}
                                                    handleChange={(e, val) => setRegionValues(val)}
                                                    inputLabel={'Region'}
                                                    selectedValue={regionValues}
                                                    placeHolder={'Select Region'}
                                                />

                                            <SelectDropdown
                                                    menuItems={tradeArr}
                                                    handleChange={(e, val) => handleSetTradeChange(e, val)}
                                                    inputLabel={'Trade'}
                                                    selectedValue={tradeValues}
                                                    placeHolder={'Select Trade'}
                                                />
                                            {checkNonTrade && <SelectDropdown
                                                    menuItems={customerArr}
                                                    handleChange={(e, val) => setCustomerValues(val)}
                                                    inputLabel={'Customer'}
                                                    selectedValue={customerValues}
                                                    placeHolder={'Select Customer'}
                                                />
                                            }
                                            {checkTrade && <SelectDropdown
                                                    menuItems={dealerArr}
                                                    handleChange={(e, val) => setDealerValues(val)}
                                                    inputLabel={'Dealer'}
                                                    selectedValue={dealerValues}
                                                    placeHolder={'Select Dealer'}
                                                />
                                            }
                                            
                                        </div>
                                        <div className="sub-section-hbpmodal">
                                            {/* <span>VPC</span> */}
                                            <SelectDropdown
                                                    menuItems={[]}
                                                    handleChange={(e, val) => setCostCenterValues(val)}
                                                    inputLabel={'Cost Center'}
                                                    selectedValue={costCenterValues}
                                                    placeHolder={'Select Cost Center'}
                                                />
                                            <SelectDropdown
                                                    menuItems={costCenterArr}
                                                    handleChange={(e, val) => setPlantValues(val)}
                                                    inputLabel={'Plant'}
                                                    selectedValue={plantValues}
                                                    placeHolder={'Select Plant'}
                                                />
                                        </div>
                                    </div>
                                </div> 
                                </div>
                                
                               

                                <div className="flex jc-center mt-30">
                                    <Button type="submit" variant="contained" className='newButton btnBlue btnRun uppercase' color="primary">Run</Button>
                                    <Button variant="contained" className='ml-16 newButton btnRed btnCancel uppercase'onClick={() => { closeHbpSimulation() }}>Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                }

            </Fade>
        </Modal>
    </div>
    );
}