import React from 'react';
import SavedDataTable from './ReportComponent';

export default function AnalyticStudio(props) {
	return (
		<div>
			<SavedDataTable
				menuData={props.menuData}
				shareChart={props.shareChart}
				fetchMetaDataAnalysis={props.fetchMetaDataAnalysis}
				fetchDataAnalysis={props.fetchDataAnalysis}
				users={props.users}
				saveData={props.saveData}
				deleteSavedData={props.deleteSavedDataReport}
				saveReportData={props.saveReportData}
				handleEditStudio={props.handleEditStudio}
			/>
		</div>
	);
}
