import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Grid, Tooltip } from "@material-ui/core";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Button from '@material-ui/core/Button';
import NewPagination from "../../common/components/CustomPagination";

import ModalRole from "./RoleMasterPopUp"
import 'react-table-hoc-fixed-columns/lib/styles.css'
// import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAccessByMenuKey } from "../../../utils/userManagement";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
export class RoleMaster extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menu: [],
      rowData: this.props.roleState.data,
      rowHeaderData: [],
      showModal: false,
      editData: {},
      editMode: false,
    };
  }

  modalCallBack = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.refreshList()
    this.props.getMenuData({});
  }

  handleEditClick = (data) => {
    this.setState({
      editData: data,
      editMode: true,
      showModal: true
    });
  }

  handleAddClick = (data) => {
    this.setState({
      editMode: false,
      showModal: true
    });
  }

  refreshList = () => {
    let data = {}
    this.props.getRoleData(data)
  }

  handleRoleSave = (data) => {
    if (this.state.editMode)
      this.props.editRoleData(data);
    else
      this.props.saveRoleData(data)
  }

  handleRoleDelete = (data) => {
    this.props.deleteRoleData(data);
  }

  componentDidUpdate(prevProps) {
    const { roleState } = this.props;
    const { rowData } = this.state;
    if (prevProps.roleState.data.length != roleState.data.length) {
      this.setState({ rowData: roleState.data })
      let newHeader = this.changeKeysOfHeader(roleState.header)
      this.setState({ rowHeaderData: newHeader })
    }
    if (prevProps.roleState.menuData.length != roleState.menuData.length) {
      this.setState({ menu: roleState.menuData })
    }
    if (prevProps.roleState.saveLoading && !roleState.saveLoading) {
      this.refreshList();
      this.modalCallBack();
    }
  }

  handleDeleterole = () => {
    var data = {}
    data._id = this.state.editData._id;
    this.handleRoleDelete(data);
    this.handleCloseDialog();
  }

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleOpenDialog = (data) => {
    this.setState({
      editData: data,
      open: true
    });
  };

  changeKeysOfHeader = (data) => {
    const { authReducer } = this.props
    const menus = authReducer.menuData
    if (data && data.length) {
      data.push({ label: "Action", key: "action" })
      return data.map((item) => {
        let obj = {
          Header: item.label,
          id: item.key,
          accessor: item.key,
          headerStyle: {
            // fontSize: '13px',
            // fontWeight: 'bold',
            // height: '35px',
          },
          style: {
            fontSize: '12px',
            textAlign: 'center',
            height: '33px',
          },
        }
        if (item.key == "action") {
          obj.Cell = (props) => (
            <React.Fragment>
              { getAccessByMenuKey(menus, "role_master", "edit") &&
                <Tooltip title={"Edit role"}>
                  <EditIcon fontSize='small' className='primary-dark' onClick={() => this.handleEditClick(props.original)} />
                </Tooltip>
              }
              {/* { getAccessByMenuKey(menus, "role_master", "delete") &&
                <Tooltip title={"Delete role"}>
                  <DeleteIcon fontSize='small' className='primary-dark' onClick={() => this.handleOpenDialog(props.original)} />
                </Tooltip>
              } */}
            </React.Fragment>
          )
        }
        return obj
      })
    }
  }

  render() {
    const { roleState } = this.props;
    const { open, editData, editMode, showModal, role, group, roleVal, groupVal, rowData, rowHeaderData } = this.state;
    const { authReducer } = this.props
    const menus = authReducer.menuData

    const body = (
      <React.Fragment>
        <Grid container xs={4} justify="flex-end" className='mt-20 mb-20'>
          {getAccessByMenuKey(menus, "role_master", "add") &&
            <Button variant="contained" onClick={() => this.handleAddClick()} color="primary">Add</Button>
          }
        </Grid>
        <Grid container xs={4}>
          <ReactTable
            data={rowData}
            columns={rowHeaderData}
            pageSize={rowData.length <= 10 ? rowData.length: 10}
            className="iconStyle xpna-react-table -striped -highlight w-100"
            PaginationComponent={NewPagination}
            pageSizeOptions={[10, 20, 30]}
            resizable={true}
          />
        </Grid>
        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We will not able recover deleted data
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">Cancel</Button>
            <Button onClick={this.handleDeleterole} color="secondary" autoFocus>Delete</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {body}
        {
          <ModalRole
            showModal={showModal}
            modalCallBack={this.modalCallBack}
            menuData={this.state.menu}
            saveCallback={this.handleRoleSave}
            editMode={editMode}
            editData={editData}
            deleteCallback={this.handleRoleDelete}
          />
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      roleState: state.userManagementState.roleState,
      authReducer: state.authReducer
    };
  },
  (dispatch) => {
    return {
      getRoleData(data) {
        dispatch({
          type: "FETCH_ACLGROUP",
          payload: data,
        });
      },
      getMenuData(data) {
        dispatch({
          type: "FETCH_MENU",
          payload: data,
        });
      },
      saveRoleData(data) {
        dispatch({
          type: "ADD_ACLGROUP",
          payload: data,
        });
      },
      editRoleData(data) {
        dispatch({
          type: "EDIT_ACLGROUP",
          payload: data,
        });
      },
      deleteRoleData(data) {
        dispatch({
          type: "DELETE_ACLGROUP",
          payload: data,
        });
      }
    };
  }
)(RoleMaster);