import React from 'react';
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import CashflowComponent from './Cashflow'
import PLReportComponent from './P&LReports'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class ReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.state = {
            reportTabValue: 0,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    componentDidMount() {
        // console.log( this.props, "props")
        // this.props.getAllUser({})
    }


    handleExternalTabChange = (event, value) => {
        this.setState({
            reportTabValue: value
        })
    }


    render() {

        const { reportTabValue } = this.state


        return (
            <React.Fragment>
                <div className='dayrepots-100k-120'>
                    <Tabs
                        value={reportTabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        className='mb-10'
                        onChange={this.handleExternalTabChange}
                    >
                        <Tab label="Cashflow" className="list-nested" id='nav-tab-1' aria-controls='nav-tabpanel-1' />
                        <Tab label="P&L Report" className="list-nested" id='nav-tab-0' aria-controls='nav-tabpanel-0' />

                    </Tabs>
                    <TabPanel value={reportTabValue} index={0}>
                        <CashflowComponent />
                    </TabPanel>
                    <TabPanel value={reportTabValue} index={1}>

                        <PLReportComponent />
                    </TabPanel>

                </div>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                updateSelectedDate(data) {
                    dispatch({
                        type: "UPDATE_SELECTED_DATE",
                        payload: data,
                    });
                }
            };
        }
    )(ReportComponent);
