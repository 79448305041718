import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
// import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch, Route } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TableComponents from './table';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InvoiceDetailModal from './DrillDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadExcel from "../../../../../src/images/download_excel.svg";


function LinkTab(props) {
    return (
        <Tab
            component='a'
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const riskFilterArr = [
    { text: 'Total', value: 'total' },
    { text: 'No Risk', value: 'no' },
    { text: 'With Risk', value: 'yes' },
    { text: 'Blank', value: 'blank' }]
const forecastFilterArr = ['Total', 'Forecast', 'Forecast to Upside', 'Upside to Forecast', 'Upside']
const typeFilterArr = ['All', 'AOP FY22', 'Monthly Update', 'Oct FY22 Forecast', 'Jan FY22 Forecast', 'Apr FY22 Forecast', 'Jul FY22 Forecast']
const monthFilterArr = ['Month', 'Qtr', 'FY']
const tabValueMap = {
    0: 'Q1-2022',
    1: 'Q3-2022',
    2: 'Q4-2022',
    3: ''
}
const amountFilterArr = ['>= 500k', '>= 1 million', '>= 2 million']
export class OrderBookComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            barChartView: true,
            barChartViewMargin: true,
            tabSelectorValue: 0,
            NewValue: 'total',
            subRegionValue: 'total',
            hemisphereRadioValue: 'total',
            forecastTypeValue: [],
            typeSelectValue: '',
            monthValue: '',
            tabValue: 0,
            amountSelectorValue: '',
            showInvoiceDetailModal: false,
            downloadExcel : false
        }
        this.showDrilldownHeaderValue = ''
    }

    handleChangeView = (event) => {
        this.setState({ barChartView: event.target.checked })
        this.setState({ barChartViewMargin: event.target.checked })
    };

    handleExcelDownload = () => {
        this.setState({
            downloadExcel: true
        })

        let payload = {}
        if(this.state.tabValue === 3) {
            payload = {
                "quarter" : "Q4-2022",
                "export": "true"
            }
            this.props.fetchOrderbookDataComparison(payload)
        } else {
            let { forecastTypeValue, tabValue,amountSelectorValue, monthValue, subRegionValue, NewValue, hemisphereRadioValue, typeSelectValue, riskValue } = this.state
            payload = {
                New_bu: NewValue,
                hemisphere: hemisphereRadioValue,
                sub_region: subRegionValue,
                type: typeSelectValue,
                view: monthValue || "Month",
                forecast_type: forecastTypeValue,
                risk: riskValue,
                quarter: tabValueMap[tabValue],
                export: "true"
            }
            this.props.fetchOrderbookData(payload)
        }
    }

    componentDidMount() {
        this.fetchDataFromServer()
        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'
            })
        }, 3000)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // handleTabChange = (event, newValue) => {
    //     this.setState({
    //         tabSelectorValue: newValue,
    //     })
    // }

    fetchDataFromServer = () => {
        let { forecastTypeValue, tabValue, tabSelectorValue,amountSelectorValue, monthValue, subRegionValue, NewValue, hemisphereRadioValue, typeSelectValue, riskValue } = this.state
        if(tabValue === 3) {
            this.setState({ downloadExcel: false })
            let payload = {
                "quarter" : "Q4-2022",
                amount_filter:amountSelectorValue
            }
            this.props.fetchOrderbookDataComparison(payload)
        }
        else {
            let payload = {
                New_bu: NewValue,
                hemisphere: hemisphereRadioValue,
                sub_region: subRegionValue,
                type: typeSelectValue,
                view: monthValue || "Month",
                forecast_type: forecastTypeValue,
                risk: riskValue,
                quarter: tabValueMap[tabValue],
            }

            this.props.fetchOrderbookData(payload)
        }
        

    }

    handleSubRegionChange = (event, newValue) => {
        this.setState({
            subRegionValue: newValue,
        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleNewChange = (event, newValue) => {
        this.setState({
            NewValue: newValue,
        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleHemisphereChange = (event, newValue) => {
        this.setState({
            hemisphereRadioValue: newValue,
        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleTypeChange = (event) => {

        if (event.target) {
            this.setState({
                typeSelectValue: event.target.value
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleForecaseTypeChange = (event) => {

        if (event.target) {
            this.setState({
                forecastTypeValue: event.target.value
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleRiskChange = (event) => {

        if (event.target) {
            this.setState({
                riskValue: event.target.value
            }, () => {
                this.fetchDataFromServer()
            });
        }
    };

    handleAmountChange = (event) => {
        if (event.target) {
            this.setState({
                amountSelectorValue: event.target.value,
                downloadExcel: false
            }, () => {
                this.fetchDataFromServer()
            });
        }
    }

    handleMonthChange = (event) => {
        if (event.target) {
            this.setState({
                monthValue: event.target.value
            }, () => {
                this.fetchDataFromServer()
            });
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue,
        }, () => {
            this.fetchDataFromServer()
        })
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        // console.log(state, colInfo, columnDetail, etc, event.target.value)
        const { data_field, parents } = colInfo.original;
        const { id, headerTitle } = columnDetail;
        const { NewValue, subRegionValue, hemisphereRadioValue, forecastTypeValue, typeSelectValue, monthValue, tabValue} = this.state
        var quarter = ''
        switch (tabValue) {
            case 0:
                quarter = "Q1-2022"
                break;
            case 1:
                quarter = "Q3-2022"
                break;
            case 2:
                quarter = "Q4-2022"
                break;
            case 3:
                quarter = "Q4-2022"
                break;
                    
            default:
                break;
        }
        const payload = {
            "New_bu": NewValue,
            "hemisphere": hemisphereRadioValue,
            "sub_region": subRegionValue,
            "type": typeSelectValue,
            "view": monthValue,
            "forecast_type": forecastTypeValue,
            "header": id, //please send header key
            "quarter" : quarter
          }
        this.props.fetchOrderbookDataDrillDown(payload);
        //     this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${ checkNan(colInfo.row[columnDetail.id])}`
    }

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                this.setState({showInvoiceDetailModal: true})

                this.handleColumnClick(state, rowInfo, column, instance, e)
            }
        }
    }

    invoiceModalCallBack = () => {
        // this.setState({ showInvoiceDetailModal: false },
        //     this.updateExcel);
        this.setState({ showInvoiceDetailModal: false });
    }

    render() {
        const { incomeStatementState, authReducer } = this.props

        const { downloadExcel, NewValue, subRegionValue,amountSelectorValue, tabValue, monthValue, hemisphereRadioValue, typeSelectValue, forecastTypeValue, riskValue, showInvoiceDetailModal } = this.state
        const { orderBookData } = incomeStatementState
        const { drilldown, comparison } = orderBookData

        return (

            <div>
                <div>
                    <Grid container>
                        <Grid item xs={7.5} sm={7.5}> 
                            <Tabs
                                value={tabValue}
                                onChange={this.handleTabChange}
                                indicatorColor='primary'
                                textColor='primary'
                                className='blockTabs mb-10'
                            >
                                <LinkTab value={0} label='AOP FY22' href='/drafts' {...this.a11yProps(0)} />
                                <LinkTab
                                    value={1}
                                    label='Q3 FCST FY22'
                                    href='/trash'
                                    {...this.a11yProps(1)}
                                />
                                <LinkTab value={2} label='Q4 FCST FY22' href='/spam' {...this.a11yProps(2)} />
                                <LinkTab value={3} label='Var Q4 vs Q3 FCST' href='/spam' {...this.a11yProps(3)} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Tooltip title={'Download to Excel'} style={{marginBottom: '10px'}}>
                                <img
                                    src={DownloadExcel}
                                    className='pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.handleExcelDownload();
                                    }} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
               { (tabValue == 0 || tabValue == 1 || tabValue == 2) && <div>
                    <div>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={NewValue}
                                onChange={this.handleNewChange}
                                name='radio'
                                className='blockRadio'
                            >
                                <FormControlLabel
                                    value={'total'}
                                    control={<Radio color='primary' />}
                                    label='Total'
                                />
                                <FormControlLabel
                                    value={'New BU 1'}
                                    control={<Radio color='primary' />}
                                    label='New BU 1'
                                />
                                <FormControlLabel
                                    value={'New BU 2'}
                                    control={<Radio color='primary' />}
                                    label='New BU 2'
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={hemisphereRadioValue}
                                onChange={this.handleHemisphereChange}
                                name='radio'
                                className='radioGroupHorizontal'
                            >
                                <FormControlLabel
                                    value={'total'}
                                    control={<Radio color='primary' />}
                                    label='Total'
                                />
                                <FormControlLabel
                                    value={'West'}
                                    key={1}
                                    control={<Radio />}
                                    label={'WH'}
                                />
                                <FormControlLabel
                                    value={'East'}
                                    key={2}
                                    control={<Radio />}
                                    label={'EH'}
                                />

                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl fullWidth>
                            <RadioGroup
                                value={subRegionValue}
                                onChange={this.handleSubRegionChange}
                                aria-label='radio filter'
                                className='blockRadio'
                            >
                                <FormControlLabel
                                    value={'total'}
                                    key={0}
                                    control={<Radio />}
                                    label={'Total'}
                                />
                                <FormControlLabel
                                    value={'Canada,US'}
                                    key={1}
                                    control={<Radio />}
                                    label={'Total NAM'}
                                />
                                <FormControlLabel
                                    value={'Canada'}
                                    key={2}
                                    control={<Radio />}
                                    label={'Canada'}
                                />
                                <FormControlLabel
                                    value={'US'}
                                    key={3}
                                    control={<Radio />}
                                    label={'US'}
                                />
                                <FormControlLabel
                                    value={'UK AND NORTH EUROPE,RUSSIA AND CASPIAN,SOUTH EUROPE / NA,MEA'}
                                    key={4}
                                    control={<Radio />}
                                    label={'TOTAL EMEA'}
                                />
                                <FormControlLabel
                                    value={'UK AND NORTH EUROPE'}
                                    key={5}
                                    control={<Radio />}
                                    label={'UK AND NORTH EUROPE'}
                                />
                                <FormControlLabel
                                    value={'RUSSIA AND CASPIAN'}
                                    key={6}
                                    control={<Radio />}
                                    label={'RUSSIA AND CASPIAN'}
                                />
                                <FormControlLabel
                                    value={'SOUTH EUROPE / NA'}
                                    key={7}
                                    control={<Radio />}
                                    label={'SOUTH EUROPE/NA'}
                                />
                                <FormControlLabel
                                    value={'MEA'}
                                    key={8}
                                    control={<Radio />}
                                    label={'MEA'}
                                />

                                <FormControlLabel
                                    value={'ASIA PACIFIC'}
                                    key={9}
                                    control={<Radio />}
                                    label={'ASIA PACIFIC'}
                                />
                                <FormControlLabel
                                    value={'MEX,LAM'}
                                    key={12}
                                    control={<Radio />}
                                    label={'TOTAL LAR'}
                                />
                                <FormControlLabel
                                    value={'MEX'}
                                    key={11}
                                    control={<Radio />}
                                    label={'MEX'}
                                />
                                <FormControlLabel
                                    value={'LAM'}
                                    key={10}
                                    control={<Radio />}
                                    label={'LAR'}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        {
                            orderBookData?.rowHeader?.length || downloadExcel === true ?
                                <TableComponents
                                    className="ReactTable -striped -highlight"
                                    tableData={orderBookData} 
                                    rowClick={this.rowClick}
                                    pageSize={orderBookData?.rowData?.length === undefined? 1: orderBookData?.rowData?.length}
                                    /> : null
                        }
                    </div>
                    {showInvoiceDetailModal &&
                        // <div>Show Modal</div>
                        <InvoiceDetailModal
                            // getInvoiceDetailGL={this.handleGLInvoiceSorting}
                            showModal={showInvoiceDetailModal}
                            modalCallBack={this.invoiceModalCallBack}
                            invoiceDetailState={drilldown}
                            // invoiceDetailStateFilter={invoiceDetailStateFilter}
                            invoiceLoading={drilldown.loading}
                            showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                            // handleFilterChange={this.handleInvoiceFilterChange}
                            // handleInvoiceSorting={this.handleInvoiceSorting}
                            // invoiceDetailStateGL={invoiceDetailStateGL}
                            // invoiceLoadingGL={invoiceLoadingGL}
                            // financialDuration={this.props.financialDuration}
                            // handleDownloadFromBE={true}
                            // handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                    />
                    }
                </div>}

                {(tabValue == 3 && 
                    <div>
                        {comparison?.main?.rowHeader?.length === 0 && comparison.loading === true? (
                            <CircularProgress style={{ margin: '50px 35vw'}}/>
                        ) : (
                            <div>
                                {
                                    comparison?.main?.rowHeader?.length ?
                                        <div style={{ marginTop: '20px' }}>

                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.main} 
                                                rowClick={this.rowClick}
                                            /> 
                                            
                                            {showInvoiceDetailModal &&
                                                <InvoiceDetailModal
                                                    // getInvoiceDetailGL={this.handleGLInvoiceSorting}
                                                    showModal={showInvoiceDetailModal}
                                                    modalCallBack={this.invoiceModalCallBack}
                                                    invoiceDetailState={drilldown}
                                                    // invoiceDetailStateFilter={invoiceDetailStateFilter}
                                                    invoiceLoading={drilldown.loading}
                                                    showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                                                    // handleFilterChange={this.handleInvoiceFilterChange}
                                                    // handleInvoiceSorting={this.handleInvoiceSorting}
                                                    // invoiceDetailStateGL={invoiceDetailStateGL}
                                                    // invoiceLoadingGL={invoiceLoadingGL}
                                                    // financialDuration={this.props.financialDuration}
                                                    // handleDownloadFromBE={true}
                                                    // handleDownloadFromBECallback={this.handleDownloadFromBECallback}
                                                />
                                            }
                                        </div>: null
                                }

                                {
                                    <Grid style={{ marginTop: '20px' }} container>
                                        <Grid item xs={12} sm={12} className='maxw-200'>
                                            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                                <InputLabel>Variance Amount</InputLabel>
                                                <Select
                                                    value={amountSelectorValue}
                                                    label="Month"
                                                    // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                                    onChange={this.handleAmountChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select</em>
                                                    </MenuItem>
                                                    {amountFilterArr && amountFilterArr.length && amountFilterArr.map(item => {
                                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>                                    
                                    </Grid> 
                                }

                                {
                                    comparison?.new?.rowHeader?.length && comparison.loading === false || downloadExcel === true ?
                                        <div style={{ marginTop: '10px' }}>
                                            <h2 style={{ marginBottom: '10px' }}>New in Q4 Forecast FY-2022</h2>
                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.new} 
                                                pageSize={4}
                                                /> 
                                        </div> : <CircularProgress style={{ margin: '50px 35vw'}}/>

                                }

                                {
                                    comparison?.slippage?.rowHeader?.length ? (
                                        comparison.loading === false || downloadExcel === true ?
                                        <div style={{ marginTop: '30px' }}>
                                            <h2 style={{ marginBottom: '10px' }}>Slippage from Q3 in Q3 Forecast 2022</h2>
                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.slippage} 
                                                pageSize={4}
                                                /> 
                                        </div> : <CircularProgress style={{ margin: '50px 35vw'}}/>
                                    ) : null 
                                }

                                {
                                    comparison?.change?.rowHeader?.length ? (
                                        comparison.loading === false || downloadExcel === true ? 
                                        <div style={{ marginTop: '30px' }}>
                                            <h2 style={{ marginBottom: '10px' }}>Change in Order Value (Q4 vs Q3 Forecast)</h2>
                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.change} 
                                                pageSize={4}
                                                /> 
                                        </div> : <CircularProgress style={{ margin: '50px 35vw'}}/>
                                    ) : null 
                                }

                                {
                                    comparison?.out?.rowHeader?.length && comparison.loading === false || downloadExcel === true ? 
                                        <div style={{ marginTop: '30px' }}>
                                            <h2 style={{ marginBottom: '10px' }}>Out/slippage from Q3 Forecast</h2>
                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.out} 
                                                pageSize={4}
                                                /> 
                                        </div> : <CircularProgress style={{ margin: '50px 35vw'}}/>

                                }

                                {/* {
                                    comparison?.total?.rowHeader?.length && comparison.loading === false || downloadExcel === true ?
                                        <div style={{ marginTop: '50px' }}>
                                            <TableComponents
                                                className="ReactTable -striped -highlight"
                                                tableData={comparison.total} 
                                                /> 
                                        </div> : <CircularProgress style={{ margin: '50px 35vw'}}/>

                                } */}
                            </div>
                        )}
                        
                        
                    </div>)
                }
            </div>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchOrderbookData(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_DATA',
                        payload: payload
                    });
                },
                fetchOrderbookDataDrillDown(payload) {
                    dispatch({
                        type: 'FETCH_DRILL_DOWN_ORDERBOOK_ANALYSIS',
                        payload: payload
                    });
                },
                fetchOrderbookDataComparison(payload) {
                    dispatch({
                        type: 'FETCH_ORDER_BOOK_COMPARISON_ANALYSIS',
                        payload: payload
                    });
                }
            };
        }
    )(OrderBookComponent);