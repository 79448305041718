import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import UserMasterGrid from "./UserMasterGrid";
import '../userManagement.scss'

export class UserMasterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <UserMasterGrid></UserMasterGrid>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                userState: state.userManagementState.userState,
                authReducer: state.authReducer,
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(UserMasterComponent);