import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";

export class WarningAlert extends React.Component {

    constructor(props) {
        super(props);
    }

    handleCloseDialog = () => {
        this.props.setOpen();
    };

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleCloseDialog}
            >
                <DialogTitle id="alert-dialog-title">{"Dear " + this.props.authState.userData.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The functionality is in progress. It will be enabled soon
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default connect(
    (state) => {
        return {
            authState: state.authReducer
        };
    },
    (dispatch) => {
        return {
        }
    }
)(WarningAlert);