import React from 'react';
import { connect } from 'react-redux';
import 'react-table-6/react-table.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import { getAccessByMenuKey } from "../../../utils/userManagement";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AOPForecastTableComponent from './AOPForecastTableComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

export class AOPForecastComponent extends React.Component {
	constructor(props) {
		super(props);

		this.MPLClassificationMap = {
            0: 'summary',
            1: 'New BU 1',
            2: 'New BU 2',
            3: 'BU 1',
            4: 'BU 2',
            5: 'BU 3',
            6: 'BU 4',
            7: 'OH',
        }

        this.viewBy = [
        	{value: 'all', key:0, label :'Summary'},
			{value: 'wh', key:1, label :'WH'},
			{value: 'eh', key:2, label :'EH'},
			{value: 'Canada,US', key:3, label :'Total NAM'},
			{value: 'Canada', key:4, label :'Canada'},
			{value: 'US', key:5, label :'US'},
			{value: 'UK AND NORTH EUROPE,RUSSIA AND CASPIAN,SOUTH EUROPE / NA,MEA', key:6, label :'TOTAL EMEA'},
			{value: 'UK AND NORTH EUROPE', key:7, label :'UK AND NORTH EUROPE'},
			{value: 'RUSSIA AND CASPIAN', key:8, label :'RUSSIA AND CASPIAN'},
			{value: 'SOUTH EUROPE / NA', key:9, label :'SOUTH EUROPE/NA'},
			{value: 'MEA', key:10,label :'MEA'},
			{value: 'ASIA PACIFIC', key:11, label :'ASIA PACIFIC'},
			{value: 'MEX,LAM', key:12, label :'TOTAL LAR'},
			{value: 'MEX', key:13, label :'MEX'},
			{value: 'LAM', key:14, label :'LAR'}
       	]
        	 
        
		this.MPLDrilldownMap = {
            all: 'All',
            wh: 'West',
            eh: "East"
        }
		this.state = {
			loading: true,
			tabValue: 0,
			hemisphereRadioValue: 'all',
			aopRadioValue: 'original',
			topSideRadioValue: 0,
			buRadioValue: 'summary',
		};
	}

	componentDidMount() {
		this.fetchDataFromServer()
	}

	handleChange = (event, newValue) => {
		let aopValue = ''
		if(newValue == '0'){
			aopValue = 'original'
		}else if( newValue == '1'){
			aopValue ='q1_original'
		}else if (newValue == '2'){
			aopValue = 'nov'
		}
		this.setState({
			tabValue: newValue,
			aopRadioValue :aopValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleaopRadioChange = (event, newValue) => {
		this.setState({
			aopRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleTopSideRadioChange = (event, newValue) => {
		this.setState({
			topSideRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})
	};

	handleBURadioValue = (event, newValue) => {

		this.setState({
			buRadioValue: newValue,
		}, () => {
			this.fetchDataFromServer()
		})

	};

	handleHemisphereRadioValue = (event, newValue) => {
		this.setState({
			hemisphereRadioValue: newValue.value,
		}, () => {
			this.fetchDataFromServer()
		})
	};


	fetchDataFromServer = () => {
		const { tabValue, hemisphereRadioValue, aopRadioValue, topSideRadioValue, buRadioValue } = this.state
		const { monthSelectorValue, yearSelectorValue, qtrSelectorValue } = this.props

		let payload = {
			classification: buRadioValue,
			aop: aopRadioValue,
			hemisphere: hemisphereRadioValue,
			yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
			year: yearSelectorValue ? yearSelectorValue : undefined,
			quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
		}

		this.props.setAOPTabState(payload)

		this.props.getAOPTableData(payload)

	}
	handleThreeLevelInvoiceClick = (payload) => {
        const { buRadioValue,hemisphereRadioValue,aopRadioValue,  radioSelectorValueMPR, radioSelectorValue} = this.state
		const { monthSelectorValue, yearSelectorValue, qtrSelectorValue } = this.props
		let drilldownPayload = {
            ...payload,
			aop: aopRadioValue,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            origination :  "true",
            classification :buRadioValue,
            hemisphere : this.MPLDrilldownMap[hemisphereRadioValue],
        }
        this.props.fetchNewOriginationDrilldownData(drilldownPayload)
    }
	render() {
		const { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
		const {
			tabValue,
			hemisphereRadioValue,
			aopRadioValue,
			topSideRadioValue,
			buRadioValue,
		} = this.state;



		console.log(this.props);

		return (
			<div>
				<div>
					<Tabs
						value={tabValue}
						onChange={this.handleChange}
						indicatorColor='primary'
						textColor='primary'
						className='blockTabs mb-10'
					>
						<LinkTab value={0} label='AOP' href='/drafts' {...a11yProps(0)} />
						<LinkTab
							value={1}
							label='Qtr Forecast'
							href='/trash'
							{...a11yProps(1)}
						/>
						<LinkTab value={2} label='MPR' href='/spam' {...a11yProps(2)} />
					</Tabs>
				</div>
				<div>
				{tabValue == 0 && <FormControl component='fieldset'>
						<Tabs
	                        value={aopRadioValue}
							onChange={this.handleaopRadioChange}
	                        indicatorColor="primary"
	                        textColor="primary"
	                        className="mb-16"
	                    >
	                        <Tab value={"original"} label="AOP Original" index="0"/>
	                        <Tab value={"topside"} label="AOP Topside" index="1"/>
	                        <Tab value={"final"} label="AOP Final" index="2"/>
	                    </Tabs>    
					</FormControl>}
					{tabValue == 1 && <FormControl component='fieldset'>
						<Tabs
	                        value={aopRadioValue}
							onChange={this.handleaopRadioChange}
	                        indicatorColor="primary"
	                        textColor="primary"
	                        className="mb-16"
	                    >
	                        <Tab value={"q1_original"} label="Q1 Original" index="0"/>
	                        <Tab value={"q1_topside"} label="Q1 Topside" index="1"/>
	                        <Tab value={"q1_final"} label="Q1 Final" index="2"/>
	                        <Tab value={"q2_original"} label="Q2 Original" index="3"/>
	                        <Tab value={"q2_topside"} label="Q2 Topside" index="4"/>
	                        <Tab value={"q2_final"} label="Q2 Final" index="5"/>
	                        <Tab value={"q3_original"} label="Q3 Original" index="6"/>
	                        <Tab value={"q3_topside"} label="Q3 Topside" index="7"/>
	                        <Tab value={"q3_final"} label="Q3 Final" index="8"/>
	                        <Tab value={"q4_original"} label="Q4 Original" index="9"/>
	                        <Tab value={"q4_topside"} label="Q4 Topside" index="10"/>
	                        <Tab value={"q4_final"} label="Q4 Final" index="11"/>
	                    </Tabs>    
					</FormControl>}

					{tabValue == 2 && <FormControl component='fieldset'>
						<Tabs
	                        value={aopRadioValue}
							onChange={this.handleaopRadioChange}
	                        indicatorColor="primary"
	                        textColor="primary"
	                        className="mb-16"
	                    >
	                        <Tab value={'nov'} label="Nov" index="0"/>
	                        <Tab value={'dec'} label="Dec" index="1"/>
	                        <Tab value={'feb'} label="Feb" index="2"/>
	                        <Tab value={'mar'} label="Mar" index="3"/>
	                        <Tab value={'may'} label="May" index="4"/>
	                        <Tab value={'jun'} label="Jun" index="5"/>
	                        <Tab value={'aug'} label="Aug" index="6"/>
	                        <Tab value={'sep'} label="Sep" index="7"/>
	                    </Tabs>
					</FormControl>}
				</div>
				<div>
					<FormControl component='fieldset'>
						<RadioGroup
							value={buRadioValue}
							onChange={this.handleBURadioValue}
							name='radio'
							className='tabs-radio radioGroupHorizontal mb-16'
						>
							<FormControlLabel
								value={'summary'}
								control={<Radio color='primary' />}
								label='Summary'
							/>
							<FormControlLabel
								value={'New BU 1'}
								control={<Radio color='primary' />}
								label='New BU 1'
							/>
							<FormControlLabel
								value={'New BU 2'}
								control={<Radio color='primary' />}
								label='New BU 2'
							/>
							<FormControlLabel
								value={'BU 1'}
								control={<Radio color='primary' />}
								label='BU 1'
							/>
							<FormControlLabel
								value={'BU 2'}
								control={<Radio color='primary' />}
								label='BU 2'
							/>
							<FormControlLabel
								value={'BU 3'}
								control={<Radio color='primary' />}
								label='BU 3'
							/>
							<FormControlLabel
								value={'BU 4'}
								control={<Radio color='primary' />}
								label='BU 4'
							/>
							<FormControlLabel
								value={'OH'}
								control={<Radio color='primary' />}
								label='OH'
							/>
						</RadioGroup>
					</FormControl>
				</div>
				<div>
					<Autocomplete
					  // value={hemisphereRadioValue}
					  onChange={this.handleHemisphereRadioValue}
					  id="combo-box-demo"
					  options={this.viewBy}
					  sx={{ width: 270 }}
					  className="mb-16 xpna-dropdown-single"
					  variant="standard"
					  size="small"
					  getOptionLabel={option => option.label}
					  defaultValue={this.viewBy[0]}
					  getOptionSelected={(option, value) => option.value === value.value}
					  renderInput={(params) => <TextField {...params} label="View By"/>}
					/>
				</div>
				<div>
					{loading ? <div className='progressfullpage'><CircularProgress /></div> : 
					<AOPForecastTableComponent
					{...this.props}
						pivotHeaderData={pivotHeaderData}
						pivotRowData={pivotRowData}
						loading={loading}
						handlePropsThreeLevelInvoiceClick={this.handleThreeLevelInvoiceClick}
					/>
					}

				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			incomeStatementState: state.incomeStatement,

		};
	},
	(dispatch) => {
		return {
			getAOPTableData(data) {
				dispatch({
					type: 'FETCH_AOP_TABLE_DATA',
					payload: data
				});
			},
			fetchNewOriginationDrilldownData(data) {
				dispatch({
					type: 'FETCH_New_DRILLDOWN_ORIGINATION',
					payload: data
				});
			},
		};
	}
)(AOPForecastComponent);
