import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Grid } from "@material-ui/core";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import FormControl from "@material-ui/core/FormControl";
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import NewPagination from "../../../../common/components/CustomPagination";
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { format } from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';

const ReactTableFixedColumns = withFixedColumns(ReactTable);
export class PipList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      category: this.props.externalState.pipMaster.category,
      subcategory: [],
      catVal: "",
      subCatVal: "",
      partyVal: "",
      stageVal: "",
      rowHeaderData: this.changeKeysOfHeader(this.props.externalState.pipMaster.rowheader),
      rowData: this.props.externalState.pipData.listPipData,
      stageMaster: this.props.externalState.pipMaster.stage
    };
  }

  componentDidMount() { }

  handleChangeCat = (event) => {
    this.setState({ catVal: event.target.value }, this.handleFilterChange);
    this.setState({
      subcategory: this.props.externalState.pipMaster.subcategory.filter(val =>
        val.parent_value == event.target.value
      )
    })
    this.setState({ subCatVal: "" }, this.handleFilterChange);
  };

  handleChangeSubCat = (event) => {
    this.setState({ subCatVal: event.target.value }, this.handleFilterChange);
  };

  handleChangeParty = (event) => {
    this.setState({ partyVal: event.target.value }, this.handleFilterChange);
  };

  handleChangeStage = (event) => {
    this.setState({ stageVal: event.target.value }, this.handleFilterChange);
  };

  handlePlanClick = (data) => {
    this.props.editCallBack(data);
  }

  resetFilter = () => {
    this.setState({
      catVal: "",
      subCatVal: "",
      partyVal: "",
      stageVal: "",
    })
  }

  handleFilterChange = () => {
    const { stageVal, partyVal, catVal, subCatVal } = this.state
    let currentData = this.props.externalState.pipData.listPipData
    if (stageVal != "")
      currentData = currentData.filter(val => val.stage == stageVal)
    if (partyVal != "")
      currentData = currentData.filter(val => val.responsibleparty == partyVal)
    if (catVal != "")
      currentData = currentData.filter(val => val.category == catVal)
    if (subCatVal != "")
      currentData = currentData.filter(val => val.subcategory == subCatVal)
    this.setState({ rowData: currentData })
  }

  refreshList = () => {
    if(this.props.customerData.customer){
      const { externalState } = this.props;
      let pipType = (this.props.type == undefined ? "60+" : this.props.type)
      let data = {
        customer_id: this.props.customerData.customer.toString(),
        type: pipType,
        bu: this.props.buValue,
      }

      if (pipType == "100k") {
        data["invoice_no"] = this.props.customerData.document_number.toString()
      }

      this.props.getPipData(data)
      // let newHeader = this.changeKeysOfHeader(externalState.pipMaster.rowheader)
      // this.setState({ rowHeaderData: newHeader })
    }
  }

  componentDidUpdate(prevProps) {
    const { externalState, dashboardState } = this.props;
    const { rowData, rowHeaderData } = this.state;
    if (!prevProps.showModal && this.props.showModal) {
      this.setState({ rowData: [] }, this.refreshList)
      this.resetFilter();
     
    }
    if ((prevProps.showModalPip && !this.props.showModalPip) && this.props.editMode) {
      this.setState({ rowData: [] }, this.refreshList)
      this.resetFilter();
    }
    if (prevProps.externalState.pipData.listPipData.length != externalState.pipData.listPipData.length) {
      this.setState({ rowData: this.props.externalState.pipData.listPipData })
     
    }

    if (externalState.pipMaster.rowheader && rowHeaderData && rowHeaderData.length != externalState.pipMaster.rowheader.length){
      let newHeader = this.changeKeysOfHeader(externalState.pipMaster.rowheader)
      this.setState({ rowHeaderData: newHeader })
    }

    
  }

  handleClose = () => {
    this.props.modalCallBackPip(false);
  };

  changeKeysOfHeader = (data) => {
    const { authState } = this.props
    const menus = authState.menuData
    if (data && data.length) {
      return data.map((item) => {
        let obj = {
          Header: item.label,
          id: item.key,
          accessor: item.key.replace("_", ""),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: '50px !important',
            overflow: "visible",
            backgroundColor: 'white'
          },
          style: { whiteSpace: "break-spaces", width: '50px !important', overflow: "hidden", textAlign: 'center' },
        }
        if (item.key == "target_date") {
          obj.accessor = (_data) => {
            let coldata = format(parseJSON(_data["targetdate"]), 'MM/dd/yyyy')
            return coldata
          }
        }
        if (item.key == "target_value") {
          obj.accessor = (_data) => {
            var nf = new Intl.NumberFormat();

            let coldata = nf.format(_data["targetvalue"])
            return coldata
          }
        }
        if (item.key == "id") {
          if (this.props.editAccess) {
            obj.Cell = (props) => (
              <span onClick={() => {
                this.handlePlanClick(props.original)
              }} className='primary-dark pointer'><u>{props.original.id}</u></span>
            )
          }
        }
        return obj
      })
    }
  }

  render() {
    const { externalState, dashboardState, authState } = this.props;
    const { partyVal, stageVal, rowData, stageMaster, category, subcategory, catVal, subCatVal, rowHeaderData } = this.state;
    const body = (
      <div className='customModal'>
        <div className='header'>
          <h2>View Improvement Plan</h2>
          <Icon onClick={() => { this.handleClose() }}>close</Icon>
        </div>
        <div className='modal-content'>
          {externalState.pipData.loading ? <div className='text-center'><CircularProgress /></div> :
            <React.Fragment>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3} className='maxw-200'>
                  <FormControl variant="outlined" size='small' className='w-100 mb-20'>
                    <InputLabel>Category</InputLabel>
                    <Select
                      label="Category"
                      style={{ borderRadius: '20px' }}
                      id="pip-category-select"
                      value={catVal}
                      onChange={this.handleChangeCat}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      { category && category.map((val) => (
                        val ? <MenuItem key={val.value} value={val.value} >
                          {val.text}
                        </MenuItem> : ''
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200' >
                  <FormControl variant="outlined" size='small' className='w-100 mb-20'>
                    <InputLabel>Stage of Excecution</InputLabel>
                    <Select
                      label="Stage of Excecution"
                      style={{ borderRadius: '20px' }}
                      id="pip-stage-select"
                      value={stageVal}
                      onChange={this.handleChangeStage}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      { stageMaster && stageMaster.map((val) => (
                        val ? <MenuItem key={val.value} value={val.value} >
                          {val.text}
                        </MenuItem> : ''
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200' >
                  <FormControl variant="outlined" size='small' className='w-100 mb-20'>
                    <InputLabel>Responsible Party</InputLabel>
                    <Select
                      label="Responsible Party"
                      style={{ borderRadius: '20px' }}
                      id="pip-resp-select"
                      value={partyVal}
                      onChange={this.handleChangeParty}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {externalState.allUser.map((val) => (
                        <MenuItem value={val.login_id} >
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} className='maxw-200'>
                  <FormControl variant="outlined" size='small' className='w-100 mb-20'>
                    <InputLabel>Sub-Category</InputLabel>
                    <Select
                      label="Sub-Category"
                      style={{ borderRadius: '20px' }}
                      id="pip-subcategory-select"
                      value={subCatVal}
                      onChange={this.handleChangeSubCat}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {subcategory.map((val) => (
                        val ? <MenuItem key={val.value} value={val.value} >
                          {val.text}
                        </MenuItem> : ''
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <ReactTableFixedColumns
                data={rowData}
                columns={rowHeaderData}
                defaultPageSize={10}
                className="xpna-reactFixed-table -striped -highlight"
                PaginationComponent={NewPagination}
                pageSizeOptions={[10, 20, 30]}
                resizable={true}
              />
            </React.Fragment>
          }
        </div>
      </div>
    )

    return (
      <Modal
        open={this.props.showModal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.props.showModal}>
          {body}
        </Fade>
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {
      externalState: state.dashboardState.receivableData.ageingData.external,
      summaryState: state.dashboardState.receivableData.ageingData.summary,
      dashboardState: state.dashboardState,
      authState: state.authReducer
    };
  },
  (dispatch) => {
    return {
      getPipData(data) {
        dispatch({
          type: "FETCH_PIP_DATA",
          payload: data,
        });
      }
    };
  }
)(PipList);