import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

export default function ErrorPage(props) {
  console.log('props=======>>>>', props)

  return (
    <div className='flex jc-flex-end'>
      <Modal
        open={props.showModal}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.showModal}>
          <div className='customModal columnFilterModal' style={{ maxWidth: 500 }}>
            <div className='header'>
              <h2>{props.headerText ? props.headerText : `Something Seems WRONG!!!`}</h2>
              <Icon onClick={props.handleClose}>close</Icon>
            </div>
            <div className='modal-content'>
              {props.contentText ? props.contentText : <h3 className='text-center'>It's not you, its us. Please try refreshing the page and finger crossed that this problem goes away miraculously.</h3>}
              <div className='text-center'><Button variant="contained" color="primary" onClick={props.handleClose} className='mt-30'>Ok</Button></div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
