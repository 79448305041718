import React from 'react';
import DatasourcesImg from "../../../images/KuwaitAirways/datasources.png"
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

function Datasources() {
    return (
         <div className="flex ai-center jc-center mt-20 datasources-content">
             <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className="px-20">
                    <img src={DatasourcesImg}/>
                  </Card>
                </Grid>
              </Grid>
         </div>
        
    )
}

export default Datasources;