import React from "react";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import AddPipModal from "../../../Ageing/ExternalComponent/PerformanceImprovementComponent";
import ListPipModal from "../../../Ageing/ExternalComponent/PerformanceImprovementComponent/piplist";
import InvoiceDetailModal from "../../../Ageing/Shared/InvoiceDetail";
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../../../utils/numberUtils'
import { formatDate } from '../../../../../utils/dateFormat'
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import NewPagination from "../../../../common/components/CustomPagination";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { checkToOpenInvoiceDetail } from '../../../Ageing/utils'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { CONFIG } from '../../../../../config'
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import { getAccessByMenuKey, getActionOwneruser } from "../../../../../utils/userManagement";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Chip, CardContent } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { format } from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import CommentModal from "../CommentModal";



const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ActionOwnerPopper = function (props) {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

export class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.dataColumns = []
    this.sortList = {}
    this.allUserList = []
    this.taggedUser = []
    // this.myInterval = {};
    this.socketCollection = {};
    this.chatDataCollection = {};
    // this.headerColorObj = {
    //   "current": '#64DD17',
    //   "1_30_days": '#AEEA00',
    //   "31_60_days": '#FFD600',
    //   "61_90_days": '#FFAB00',
    //   "91_180_days": '#FF6D00',
    //   "180_plus_days": '#d00909'
    // }

    this.agingCategoryArr = [
      // 'customer_name',
      '1_30_days',
      '31_60_days',
      '61_90_days',
      '91_180_days',
      '180_plus_days',
      'grand_total',
      'overdue_amount',
      'current'
    ]

    this.state = {
      showModal: false,
      showLoader: false,
      invoicePayload: null,
      showModalPip: false,
      showModalListPip: false,
      showInvoiceDetailModal: false,
      showCommentsModal : false,
      commentRow: {},
      customerName: '',
      age: "",
      updateFlag: false,
      tableRowData: [],
      pipComments : [],
      rowHeaderData: [],
      chatDataCollection: {},
      catData: this.props.externalState.pipMaster.category,
      subCatData: [],
      cleanUpPipData: false,
      stageData: this.props.externalState.pipMaster.stage,
      editModePip: false,
      viewModePip: false,
      editPipData: {},
      agingCategoryValue: '',
      imagePreview: null,
      imageName: '',
      uploadImageObj: {},
      refMenuAnchorEl: null
    };
  }

  componentDidMount() {
    const { externalFilterObject } = this.props;
    let defaultCustomerState = externalFilterObject.customer
    let payload = {
      action_owner: [],
      aging_category: [],
      category: "",
      customer: defaultCustomerState,
      skip: 0,
      parent_group: this.props.parentGroup,
      receivableTabSelectorValue: this.props.receivableTabSelectorValue,
      bu: this.props.buValue
    };
    this.props.getMSMDetail(payload);
    this.props.getSummaryBuData(payload);
    this.props.getFilterData(payload);
    // this.myInterval = setInterval(() => {
    //   this.callGetAllCommentHistoryFunc(this.props.externalState.activePage, this.props.externalState.data.row_data)
    // }, 60 * 1000)
  }

  // componentWillUnmount() {
  //   clearInterval(this.myInterval)
  // }

  modifyColumnData = (columnData) => {
    let modifiedData;
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '' : modifiedData
    return modifiedData
  }

  handlePipSave = (data) => {
    this.props.savePipData(data)
  }

  handlePipUpdate = (data) => {
    this.props.updatePipData(data)
  }

  handleEditModePip = (data) => {
    this.setState({
      editModePip: true,
      viewModePip: true,
      showModalPip: true,
      editPipData: data,
      cleanUpPipData: true,
    })
  }

  handleSummaryColumn = (dataItem) => {
    const { buRowData } = this.props.summaryState;
    let returnString = '';
    returnString = dataItem.label;
    // if (buRowData[dataItem.key]) {
    //   returnString = returnString + ' ' + this.modifyColumnData(buRowData[dataItem.key])
    // }
    return returnString;
  }

  handleDynamicWidth = (item) => {
    let widthSize = undefined;
    switch (item.key) {
      // case 'percentage':
      //   widthSize = 90;
      //   break;
      // case 'project_id':
      //   widthSize = 90
      //   break;
      // case 'grand_total':
      //   widthSize = 80;
      //   break;
      case 'customer_name':
        widthSize = 250;
        break;
      case 'overdue_amount':
        widthSize = 150;
        break;
      // case 'improvement_plan':
      //   widthSize = 90;
      //   break;
      // case 'collection_forecast':
      //   widthSize = 90;
      //   break;
      // case 'collection_actual':
      //   widthSize = 90;
      //   break;
      // case 'action_owner':
      //   widthSize = 150;
      //   break;
      // case 'id':
      //   widthSize = 100;
      //   break;
      case 'responsible_party':
            widthSize = 175;
            break;
      case 'reason_for_delays':
            widthSize = 150;
            break; 
      case 'created_on':
        widthSize = 120;
        break; 
          // case 'stage':
      //   widthSize = 175;
      //   break;
      // case 'target_date':
      //   widthSize = 90;
      //   break;
      // case 'target_value':
      //   widthSize = 100;
      //   break;
      // case 'improvement_action':
      //   widthSize = 150;
      //   break;
      default: break;
    }
    return widthSize
  }

  handleFixedColumn = (item) => {
    let fixed = ''
    switch (item.key) {
      case 'bu':
        fixed = 'left'
        break;
      case 'customer':
        fixed = 'left'
        break;
      case 'company_code':
        fixed = 'left'
        break;
      case 'parent_group':
        fixed = 'left'
        break;
      default: break;
    }
    return fixed
  }

  

  getPipID = (rowData) => {
    this.props.getPipIDData({bu: rowData.bu})
  }


  handleActionOwnerChange = (data, props) => {
    data = data == null ? { login_id: "" } : data;
    const payload = {
      company_code: props.company_code,
      action_owner: data.login_id,
      // customer: Number.parseInt(props.customer),
      project_id: props.project_id,
      customer: props.customer,
      bu: props.bu
    }
    props.action_owner = data.login_id
    this.props.updateActionOwnerFn(payload)
  }

  handleRefMenuClose = (item) => {
    this.taggedUser.push(item.login_id)
    if (this.state.refMenuAnchorEl && this.state.refMenuAnchorEl.value) {
      this.state.refMenuAnchorEl.value += item.name || ''
    }
    this.setState({ refMenuAnchorEl: null })
  };

  convertAmountData = (data) => {
    var modifiedData = Math.round(data)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)

    return modifiedData
  }

  convertAmountDataWithoutRound = (data) => {
    var modifiedData = data

    if (modifiedData < 0 && modifiedData != -1) {
      var positiveTransformedValue = Math.abs(modifiedData)
    } else {
      var positiveTransformedValue = modifiedData
    }
    modifiedData = numberWithCommas(positiveTransformedValue)
    return modifiedData
  }


  handleShowCursor = (item) => {
    let cursorPointer = ''
    if (this.agingCategoryArr.indexOf(item.key) > -1) {
      cursorPointer = 'pointer'
    }

    const {parentGroup, buValue} = this.props;
    // if (parentGroup == "unbilled"){
    //   if (buValue == 'lcs' || buValue == 'pab' || buValue == 'dgs'){
    //     cursorPointer = ''
    //   }
    // }

    return cursorPointer;
  }

  changeKeysOfHeader = (data) => {
    const { authState } = this.props
    const menus = authState.menuData
    let bu = this.props.buValue
    let parentGroup = this.props.parentGroup
    // console.log(bu, "thisBU")
    // console.log(parentGroup, "thisBU")
    let allUser = this.props.externalState.allUser;
    //default list by user BU
    let buUser = getActionOwneruser(allUser, bu);
    if (data && data.length) {
      // console.log('data====>>>', data);
      return data.map((item) => {
        if (item.key == "plan_id") {
          return {
            Header: item.label,
            id: item.key,
            width: this.handleDynamicWidth(item),
            headerStyle: { 
              //whiteSpace: "break-spaces", 
              width: '50px !important', 
              //overflow: "visible", 
              fontSize: '13px', 
              fontWeight: 'bold', },
            style: { 
              //whiteSpace: "break-spaces", 
              width: '50px !important', 
              //overflow: "visible", 
              fontSize: '12px', 
              textAlign: 'center' },
            accessor: item.key,
            Cell: (props) => (
              <div className='mt-10 flex ai-center jc-space-evenly'>
                <Tooltip title='View Improvement Plan'>
                  <Badge anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }} badgeContent={props.original.pip_count} color="primary">
                    <Icon
                      color={
                        props.original["pip_count"] > 0 ? "inherit" :
                          "disabled"}
                      onClick={() => {
                        if (props.original["pip_count"] > 0) {
                          this.setState({
                            showModalListPip: true,
                            commentRow: props.original,
                            // showLoader: true
                          });
                        }
                      }}
                    >remove_red_eye
                 </Icon>
                  </Badge>
                </Tooltip>
                {
                  getAccessByMenuKey(menus, "aging_reports", "add") &&
                  <Tooltip title={
                      'Add Improvement Plan' 
                  }>
                    <Icon color={
                    "inherit" }
                      disabled
                      onClick={() => {
                          this.setState({
                            showModalPip: true,
                            commentRow: props.original,
                            cleanUpPipData: true,
                            editModePip: false,
                            // showLoader: true
                          });
                          this.getPipID(props.original);
                      }}
                    >add_circle_outline</Icon>
                  </Tooltip>
                }
              </div>
            ),
          };
        } else if (item.key == "id" || item.key == 'overdue_amount' || item.key == "responsible_party" || item.key == "id" || item.key == "stage" || item.key == "created_on" || item.key == "target_date"|| item.key == "overdue_amount_pip" || item.key == "target_value" || item.key == "reason_for_delays" ) {
          return {
            Header: this.handleSummaryColumn(item),
            id: item.key,
            width: this.handleDynamicWidth(item),
            fixed: this.handleFixedColumn(item),
            headerStyle: {
              // whiteSpace: "break-spaces",
              width: '50px !important',
              //overflow: "visible",
              fontSize: '13px',
              fontWeight: 'bold',
            },
            style: {
              // whiteSpace: "break-spaces",
              width: '50px !important',
              //overflow: "visible",
              fontSize: '12px',
              textAlign: item.key != 'customer_name' ? 'center' : '',
              padding:  '0px',
            },
            Cell: (props) => (
              <div>
              { props.original.pip_details != null  ?
              props.original.pip_details.map((itemIn, index) => {
                  var moreThanOne =  props.original.pip_details.length > 1
                  var stageClass = "pip-"+itemIn["status"];

                  switch(item.key){
                    case "id" :
                      return <div className={ moreThanOne ? 'row-span-div primary-dark pointer' : 'row-normal-div primary-dark pointer' } onClick={() => {
                        this.fetchPiPDetails(itemIn)
                        this.setState({ showModalPip : true })
                      }} ><u>{itemIn[item.key]}</u></div>
                      
                    case "reason_for_delays" :
                      return <div 
                      onClick={() => {
                        this.setState({ showCommentsModal : true })
                        this.fetchPiPDetails(itemIn)
                      }}
                      className={(moreThanOne ? 'row-span-div ' : 'row-normal-div ')+ 'primary-dark pointer'}><u>View</u></div>
                    case "stage" :
                        return <div className={(moreThanOne ? 'row-span-div ' : 'row-normal-div ') + stageClass}>{itemIn[item.key]}</div>
                    case "target_date" : 
                    case "created_on" :
                      return <div className={moreThanOne ? 'row-span-div' : 'row-normal-div'}>{ format(parseJSON(itemIn[item.key]), 'dd MMM yyyy') }</div>
                    case "overdue_amount" :
                      return <div className={moreThanOne ? 'row-span-div' : 'row-normal-div'}>{ this.numericFormatting(props.original, item)}</div>
                    default :
                      return <div className={moreThanOne ? 'row-span-div' : 'row-normal-div'}>{ this.numericFormatting(itemIn, item)}</div>
                  }
               }) : ""
              }
              </div>
            )
          }
        } else if (item.key == "customer_name") {
          return {
            Header: this.handleSummaryColumn(item),
            id: item.key,
            width: this.handleDynamicWidth(item),
            fixed: this.handleFixedColumn(item),
            headerStyle: {
              // whiteSpace: "break-spaces",
              width: '50px !important',
              //overflow: "visible",
              fontSize: '13px',
              fontWeight: 'bold',
            },
            style: {
              // whiteSpace: "break-spaces",
              width: '50px !important',
              //overflow: "visible",
              fontSize: '12px',
              textAlign: item.key != 'customer_name' ? 'center' : '',
              cursor: this.agingCategoryArr.indexOf(item.key) > -1 ? (this.props.parentGroup == 'unbilled' && this.props.buValue == 'pab' ? '' : this.handleShowCursor(item)) : ''
              
              // cursor: this.handleShowCursor(item)

            },
            Cell: (props) => (
              <>
                <p className='mt-0'>{props.original.customer_name}</p>
                {/* <Tooltip title='test'> <p className='mt-0'>{props.original.customer_name}</p></Tooltip> */}
                {/* <div className='usernameCreditBlock'>
                  {props.original.credit_limit_note != null ?
                    <div>Credit Limit: {props.original.credit_limit_note}</div> :
                    props.original.credit_limit == null ?
                      <div>Credit Limit not available</div>
                      :
                      <>
                        <div>Credit Limit: ${this.convertAmountData(props.original.credit_limit)}</div>
                        <div>{props.original.credit_billed_unbilled.label}: ${this.convertAmountData(props.original.credit_billed_unbilled.value)}</div>
                        <div className={props.original.credit_status < 0 ? 'negative' : 'positive'}>Credit Status: ${this.convertAmountData(props.original.credit_status)}</div>
                      </>
                  }
                </div> */}
              </>
            ),
          }
        }

        return {
          Header: this.handleSummaryColumn(item),
          id: item.key,
          width: this.handleDynamicWidth(item),
          fixed: this.handleFixedColumn(item),
          accessor: (_data) => {
            return this.numericFormatting(_data, item)
          },
          sortMethod: (a, b) => {
            if (item.operation) {
              let valNeg = a.indexOf("(") > -1 ? -1 : 1
              let valNegB = b.indexOf("(") > -1 ? -1 : 1
              let val = a.split(",").join("").replace("(", "").replace(")", "")
              let valB = b.split(",").join("").replace("(", "").replace(")", "")
              let valInt = parseInt(val)
              let valIntB = parseInt(valB)
              valInt = isNaN(valInt) ? 0 : valInt * valNeg
              valIntB = isNaN(valIntB) ? 0 : valIntB * valNegB
              return valInt - valIntB
            } else {
              if (a > b) {
                return -1;
              } else if (a < b) {
                return 1;
              }
              return 0;
            }
          },
          headerStyle: {
            //whiteSpace: "break-spaces",
            width: '50px !important',
            //overflow: "visible",
            fontSize: '13px',
            fontWeight: 'bold',
          },
          style: {
            //whiteSpace: "break-spaces",
            width: '50px !important',
            //overflow: "visible",
            fontSize: '12px',
            textAlign: item.key != 'customer_name' ? 'center' : '',
            cursor: this.handleShowCursor(item)
          },
        };
      });
    }
    return [];
  };

  numericFormatting = (_data,item) => {
    if (item.operation) {
      let modifiedData;
      let columnData = _data[item.key]

      switch (item.operation) {
        case 0:
          modifiedData = item.key;
          break;
        case 1:
          modifiedData = Math.round(columnData)
          break;
        case 2:
          modifiedData = numberWithCommas(columnData)
          break;
        case 4:
          modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
          modifiedData = modifiedData == '0' ? '' : modifiedData
          break;
        case 3:
          modifiedData = Math.round(columnData)
          modifiedData = numberWithCommas(modifiedData)
          break;
        case 5:
          modifiedData = Math.round(columnData)
          modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
          modifiedData = modifiedData == '0' ? '' : modifiedData
          break;
        case 6:
          var isNegative = columnData < 0
          var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
          modifiedData = numberWithCommas(positiveTransformedValue)
          modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
          modifiedData = modifiedData == '0' ? '' : modifiedData
          break;
        case 7:
          modifiedData = Math.round(columnData)
          var isNegative = modifiedData < 0
          var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
          modifiedData = numberWithCommas(positiveTransformedValue)
          modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
          modifiedData = modifiedData == '0' ? '' : modifiedData
          break;
        default:
          break;
      }

      return modifiedData;


    } else {
      return _data[item.key];
    }
  }

  modalCallBack = () => {
    this.setState({ showModal: false });
  };

  // forecastmodalCallBack = () => {
  //   this.setState({ showForecastModal: false });
  // };

  modalCallBackPip = () => {
    this.setState({ showModalPip: false });
  };

  modalCallBackListPip = () => {
    this.setState({ showModalListPip: false });
  };

  modalCallBackcleanUpPipData = () => {
    this.setState({ cleanUpPipData: false });
  }
  invoiceModalCallBack = () => {
    this.setState({ showInvoiceDetailModal: false });
  }

  commentsModalCallBack = () => {
    this.setState({ showCommentsModal: false });
  }

  changeSubCatData = (cat) => {
    this.setState({
      subCatData: this.props.externalState.pipMaster.subcategory.filter(val =>
        val.parent_value == cat
      )
    })
  }

  

  filterRowHeaderData = (payload) => {
    if (this.props.parentGroup == "unbilled")
      return payload.filter(x => {
        return x.id.toLowerCase() != 'collection_actual'
      })

    return payload
  }

  componentDidUpdate(prevProps) {
    const { externalState, dashboardState, chatStoreState } = this.props;
    const {showCommentsModal} = this.state;

    if (!(this.state.rowHeaderData && this.state.rowHeaderData.length) &&
      externalState.data && externalState.data.row_header && externalState.data.row_header.length
    ) {

      let dataColumns = this.changeKeysOfHeader(
        externalState && externalState.data && externalState.data.row_header
      );
      dataColumns = this.filterRowHeaderData(dataColumns)
      this.setState({ rowHeaderData: dataColumns })
    }
    if ((prevProps.summaryState.buRowData.business_unit != this.props.summaryState.buRowData.business_unit) ||
      (prevProps.externalState.data.row_data &&
        this.props.externalState.data.row_data &&
        prevProps.externalState.data.row_data.length != this.props.externalState.data.row_data.length)
    ) {

      let dataColumns = this.changeKeysOfHeader(
        externalState && externalState.data && externalState.data.row_header
      );
      dataColumns = this.filterRowHeaderData(dataColumns)
      this.setState({ rowHeaderData: dataColumns })
    }
    if ((prevProps.summaryState.buRowData.overdue_amount != this.props.summaryState.buRowData.overdue_amount)
    ) {

      let dataColumns = this.changeKeysOfHeader(
        externalState && externalState.data && externalState.data.row_header
      );
      dataColumns = this.filterRowHeaderData(dataColumns)
      this.setState({ rowHeaderData: dataColumns })
    }
    // if (externalState.activePage != prevProps.dashboardState.receivableData.ageingData.external.activePage) {
    //   this.callGetAllCommentHistoryFunc(externalState.activePage, externalState.data.row_data);
    // }
    if (externalState.selectedPipData.BsonID != prevProps.externalState.selectedPipData.BsonID) {
      this.modalCallBackPip();
      if (externalState.selectedPipData.BsonID != undefined) {
        let payload = {
          action_owner: [],
          aging_category: [],
          category: "",
          customer_name: [],
          skip: 0,
          parent_group: this.props.parentGroup,
          bu: this.props.buValue,
          receivableTabSelectorValue: this.props.receivableTabSelectorValue,

        };
        this.props.getMSMDetail(payload);
      }
    }

    if(externalState.pipMaster.category && !this.state.catData){
      // console.log(externalState.pipMaster, "apa iniiii")
      this.setState({ catData : externalState.pipMaster.category, stageData : externalState.pipMaster.stage  })
    }
   
    if (this.props.externalState.allUser.length) {
      this.allUserList = this.props.externalState.allUser
    }

    if (prevProps.externalState.pipData.listPipData.length == 0 && externalState.pipData.listPipData.length > 0 && !showCommentsModal ) {
      // console.log(this.props.externalState.pipData.listPipData, "----anyeong")
      this.handleEditModePip(this.props.externalState.pipData.listPipData[0])
    }

    if (prevProps.externalState.pipData.listPipData.length != externalState.pipData.listPipData.length && showCommentsModal ) { // prepare comments data
      this.setState({
        editPipData: this.props.externalState.pipData.listPipData.length > 0 ? this.props.externalState.pipData.listPipData[0] : {},
      })
    }

    if (prevProps.externalState.pipData.pipComments.length != externalState.pipData.pipComments.length && showCommentsModal ) { // prepare comments data
      // console.log(this.props.externalState.pipData.listPipData, "----anyeong")
      this.setState({ pipComments : this.props.externalState.pipData.pipComments.reverse()})
    }

  }

  handleRowClick = (rowData) => {
   
  }

  handleColumnClick = (state, colInfo, columnDetail, etc) => {
   
  }

  columnClickFunction = (state, colInfo, abc, etc) => {
    if (colInfo) {
      return {
        onClick: (e) => {
          this.handleColumnClick(state, colInfo, abc, etc)
        }
      }
    } else {
      return {}
    }
  }

  rowClickFunction = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {

      return {
        onClick: (e) => {
          this.handleRowClick(rowInfo)
        }
      }
    } else {
      return {}
    }
  }

 

  onPageChange = (data) => {
    const { externalState } = this.props
    this.props.updateActivePage(data);
  }

  

  handleInvoiceSorting = (columnId) => {
    const { invoicePayload } = this.state;

    this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

    let payload = {
      ...invoicePayload
    }
    payload['sort'] = {
      key: columnId,
      desc: this.sortList[columnId]
    }
    this.props.getInvoiceDetail(payload)
  }

  fetchPiPDetails = (param) => {
    let data = {
      customer_id: param.customer_id.toString(),
      type: param.type,
      bu: param.bu,
      plan_id : param.id
    }

    this.props.getPipData(data)
  }

  sortNotNull = (data) =>{
    data = data.map(x =>{
        let det = x.pip_details
        det = det.map(y =>{
          let split = y.id.split("-")
          y.plantInt = parseInt(split[1])
          return y
        })
        det = det.sort((a,b) =>{
          return (a.plantInt > b.plantInt) ? 1 : -1
        })
        x.pip_details = det;
        x.pip_oldest = det[0].plantInt
      return x
    })

    return data.sort(function(a, b) {
      return (a.pip_oldest > b.pip_oldest) ? 1 : -1
    }).reverse();
  }

  sendComments = (inputValue) => {
    const { pipComments, editPipData } = this.state
    pipComments.unshift({
      login_id : editPipData.resonfordelays,
      date : new Date(),
      message : inputValue
    })
    this.setState({pipComments :  pipComments})

    let payloadComments =   [...[], ...pipComments]
    payloadComments = payloadComments.filter(x => { return x.main != true }) //remove reason for delays
    let payload = { id : editPipData.id, additional_comments : payloadComments }


    this.props.savePipComments(payload)
  }

  render() {
    const { externalState, dashboardState, authState, invoiceDetailState } = this.props;
    const { cleanUpPipData,
      catData, subCatData, showModal, showModalListPip,
      showModalPip, showInvoiceDetailModal, commentRow,
      age, tableRowData, rowHeaderData, customerName,
      stageData, editModePip, viewModePip, editPipData, agingCategoryValue,
      showForecastModal, uploadImageObj, showCommentsModal, pipComments
    } = this.state;
    // const commentModalData = this.setCommentModalData();
    const menus = authState.menuData;
    const externalDataLen = externalState && externalState.data && externalState.data.row_data ? externalState.data.row_data.length : 0;

    let bu = this.props.buValue
    //default list by user BU
    let buUser
    if (this.props.externalState && this.props.externalState.allUser) {
      let allUser = this.props.externalState.allUser;

      buUser = getActionOwneruser(allUser, bu);
    }

    let newPipComments = pipComments.sort((a, b) => parseJSON(b.date) - parseJSON(a.date))


    return (
      <div className='externalComponentPage planDataTable1'>
        {/* {this.state.showLoader && <div className='progressfullpage'><CircularProgress /></div>} */}
        {  externalState.loading || dashboardState.loading ? <div className='progressfullpage'><CircularProgress /></div> :
          <React.Fragment>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              id="customized-menu"
              anchorEl={this.state.refMenuAnchorEl}
              keepMounted
              open={Boolean(this.state.refMenuAnchorEl)}
              onClose={this.handleRefMenuClose}
            >
              {
                buUser && buUser.length &&
                buUser.map((item, index) => {
                  return <MenuItem
                    value={item.full_name}
                    key={index}
                    onClick={() => this.handleRefMenuClose(item)}
                  >
                    <div className='flex jc-space-between w-100 ai-center'>
                      <span className='pr-10'>{item.full_name}</span>
                      <span style={{ opacity: '0.5', float: 'right' }}>{item.role}</span>
                    </div>
                  </MenuItem>
                })
              }
            </Menu>
            <ReactTable
              data={this.sortNotNull(externalState.data.row_data)}
              columns={rowHeaderData}
              defaultPageSize={10}
              pageSize={externalDataLen >= 10 ? 10 : externalDataLen}
              loading={dashboardState.loading}
              PaginationComponent={NewPagination}
              className="xpna-react-table -striped -highlight"
              onPageChange={this.onPageChange}
              getTrProps={this.rowClickFunction}
              getTdProps={this.columnClickFunction}
              // pageSizeOptions={[10, 20, 30]}
              style={{
                //maxHeight: "465px"
              }}
            />
            <Grid container justify="flex-end">
            <Card className={'pip-legend'}>
              <CardContent>
                <table>
                  <tr>
                    <td> 
                      <Typography gutterBottom variant="subtitle2" >
                        Stages of Execution
                      </Typography>
                    </td>
                    <td><div className={'pip-legend-box green'} /></td>
                    <td><span className={'pip-legend-label'}>Target Date Not Reached</span></td>
                    <td><div className={'pip-legend-box red'} /></td>
                    <td><span className={'pip-legend-label'}>Target Date Exceeded</span></td>
                    <td><div className={'pip-legend-box orange'} /></td>
                    <td><span className={'pip-legend-label'}>Target Date Exceeded, Completion Date Not Reached</span></td>
                  </tr>
                </table>
                </CardContent>  
            </Card>    
            </Grid>
            {
              <CommentModal
                showModal={showCommentsModal}
                pipPartyData={editPipData.otherData ? editPipData.otherData.party : {}}
                planID ={editPipData.id}
                modalCallBack={this.commentsModalCallBack}
                commentsData={newPipComments}
                userDataState={authState.userData}
                sendCommmentCallback={this.sendComments}
                allUsers={this.props.externalState.allUser}
                menuData={menus}
                loading={externalState.pipData.loading}
              />
            }
            {
              <InvoiceDetailModal
                showModal={showInvoiceDetailModal}
                modalCallBack={this.invoiceModalCallBack}
                invoiceDetailState={invoiceDetailState}
                customerName={customerName}
                agingCategoryValue={agingCategoryValue}
                handleInvoiceSorting={this.handleInvoiceSorting}
                parentGroup={this.props.parentGroup}
                buValue={this.props.buValue}
              />
            }
            {
              <AddPipModal
                showModal={showModalPip}
                modalCallBackPip={this.modalCallBackPip}
                userDataState={authState.userData}
                catData={catData}
                subCatData={subCatData}
                changeSubCatData={this.changeSubCatData}
                buValue={editPipData.bu != undefined ? editPipData.bu : ""}
                customerData={commentRow}
                allUser={this.props.externalState.allUser}
                saveCallback={this.handlePipSave}
                updateCallback={this.handlePipUpdate}
                pipIDData={externalState.pipData.pipIDData}
                cleanUpPipData={cleanUpPipData}
                modalCallBackcleanUpPipData={this.modalCallBackcleanUpPipData}
                editMode={editModePip}
                viewMode={viewModePip}
                editData={editPipData}
                stageData={stageData}
                loading={externalState.pipData.loading}
              />
            }
            {
              <ListPipModal
                editMode={editModePip}
                showModalPip={showModalPip}
                showModal={showModalListPip}
                modalCallBackPip={this.modalCallBackListPip}
                customerData={commentRow}
                buValue={this.props.buValue}
                editAccess={getAccessByMenuKey(menus, "aging_reports", "edit")}
                editCallBack={this.handleEditModePip}
              />
            }
          </React.Fragment>
        }
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      externalState: state.dashboardState.receivableData.ageingData.external,
      summaryState: state.dashboardState.receivableData.ageingData.summary,
      dashboardState: state.dashboardState,
      authState: state.authReducer,
      invoiceDetailState: state.invoiceDetailState,
      chatStoreState: state.chatStore
    };
  },
  (dispatch) => {
    return {
      getPipData(data) {
        dispatch({
          type: "FETCH_PIP_DATA",
          payload: data,
        });
      },
      savePipData(data) {
        dispatch({
          type: "SAVE_PIP_DATA",
          payload: data,
        });
      },
      savePipComments(data) {
        dispatch({
          type: "SEND_PIP_COMMENTS",
          payload: data,
        });
      },
      updatePipData(data) {
        dispatch({
          type: "UPDATE_PIP_DATA",
          payload: data,
        });
      },
      getPipIDData(data) {
        dispatch({
          type: "FETCH_PIP_ID",
          payload: data,
        });
      },
      getMSMDetail(data) {
        dispatch({
          type: "FETCH_RECEIVABLE_AGEING_IMPROVEMENT_PLAN_EXTERNAL_DATA",
          payload: data,
        });
      },
      getFilterData(data) {
        dispatch({
          type: "FETCH_EXTERNAL_FILTER_DATA",
          payload: data,
        });
      },
      getCommentData(data) {
        dispatch({
          type: "FETCH_EXTERNAL_COMMENT_DATA",
          payload: data,
        });
      },
      getSummaryBuData(data) {
        dispatch({
          type: "FETCH_SUMMARY_BU_DATA",
          payload: data,
        });
      },
      updateExternalData(data) {
        dispatch({
          type: "UPDATE_EXTERNAL_DATA_SET",
          payload: data,
        });
      },
      updateActivePage(data) {
        dispatch({
          type: "UPDATE_ACTIVE_PAGE_EXTERNAL",
          payload: data,
        });
      },
    
    
      getInvoiceDetail(data) {
        dispatch({
          type: 'FETCH_INVOICE_DETAIL',
          payload: data
        });
      },
    
     
     
      updateActionOwnerFn(data) {
        dispatch({
          type: 'UPDATE_ACTION_OWNER',
          payload: data
        });
      }
    };
  }
)(TableComponent);

