import React from 'react';

import NewTableComponent from '../newTable'
import Grid from "@material-ui/core/Grid";

import CircularProgress from '@material-ui/core/CircularProgress';

export default function (props) {

    const { plCementState } = props
    return (
        <Grid item xs={12} sm={12} className="pt-0">
            {plCementState.loading ? <div className='progressfullpage'><CircularProgress /></div> : <div style={{ maxHeight: '700px', overflow: 'scroll' }}>
                <NewTableComponent className={'ebitdaCustomTable fixedFirstTwoColumTable expandableRows'} tableData={plCementState} />
            </div>}
        </Grid>
    )
}
