import { Draggable } from "react-beautiful-dnd";
import { LoremIpsum } from "lorem-ipsum";
import { generateFromString } from "generate-avatar";
import React, { useMemo } from "react";
import styled from "styled-components";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';


const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border: 3px solid white;
  border-radius: 50%;
`;

const CardHeader = styled.div`
  font-weight: 500;
`;

const CardTitle = styled.div`
  font-weight: 500;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;
const CardFooter = styled.div`
  width: 100%;
  display: flex;
  
  align-items: left;
`;

const DragItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

const lorem = new LoremIpsum();

const ListItem = (props) => {

  const { item, index, handleOpen} = props;
  const randomHeader = useMemo(() => lorem.generateWords(5), []);

  const dateStatus = item.date_status === undefined ? 'None' : item.date_status;

  const handleClick = () =>{
    handleOpen(item);
    console.log(props)
  }

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // className={`list-card-${item.date_status}`}
            className={`list-card-${dateStatus}`}
          >
            {/* <CardHeader className={`list-header-card-${item.date_status} text-right`}> */}
            <CardHeader className={`list-header-card-${dateStatus} text-right`}>
              <Tooltip title="Edit">
                  <EditIcon onClick={(e) => {e.preventDefault(); handleClick()}}/>
              </Tooltip>
              </CardHeader>
            <CardTitle>{item.plan_id}</CardTitle>
            <span>{'Gap To Target: '+ item.gap}</span>
            <span>{'Cost: '+ item.cost}</span>
            <span>{'Benefit: '+ item.benefit}</span>
            <CardFooter >
              <span>{item.content}</span>
              <Avatar
                  src={`data:image/svg+xml;utf8,${generateFromString(item._id)}`}
                />
              <Author>
                {item.report_to}
              </Author>
            </CardFooter>
          </DragItem>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
