import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from '../../common/components/ExpandableGrid/Table';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InfoIcon from '@material-ui/icons/Info';

export default function SimpleModal(props) {

    React.useEffect(() => {
        handleResetPopUp();

        if (props.showModal) {
            if (props.editMode) {
                loadEditData();
            } else {
                loadAddData();
            }
        }
    }, [props.showModal]);


    const handleClose = () => {
        props.modalCallBack();
    }

    const handleResetPopUp = () => {
        setName("");
        setMenus([])
        setBtnDisabled(true)
    }

    const loadEditData = () => {
        let data = JSON.parse(JSON.stringify(props.editData));
        setName(data.name);
        setMenus(data.menus);
        maskData(data.menus);
    }

    const loadAddData = () => {
        let data = JSON.parse(JSON.stringify(props.menuData));
        setMenus(data);
        maskData(data);
        // console.log(data, "dataxxx")
    }

    const [btnDisabled, setBtnDisabled] = useState(true)


    const [name, setName] = React.useState('');
    const [time, setTime] = React.useState('');
    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const [menus, setMenus] = React.useState([]);
    const [maskedMenus, setMaskedMenus] = React.useState([]);
    const defaultValue = JSON.stringify({chat:false, download: false, upload: false, view: false, add: false, edit: false, delete: false });

    const maskData = (menusx) => {
        console.log("masked")
        let masked = menusx.map(x => {
            x.subRows = normalizeData(x.child)
            x.access = x.access == undefined ? JSON.parse(defaultValue) : x.access;
            return x;
        })
        setMaskedMenus(masked);
    }

    const normalizeData = (child) => {
        if (child == null) {
            return []
        }
        return child.map(x => {
            x.subRows = x.child
            x.access = x.access == undefined ? JSON.parse(defaultValue) : x.access;

            if (x.child != null)
                x.subRows = normalizeData(x.child)

            return x
        })
    }

    const handleSaverole = () => {
        if (props.editMode) {
            var data = {}
            data._id = props.editData._id;
            data.name = name;
            data.menus = menus;
            props.saveCallback(data);
        } else {
            var data = {}
            data._id = name.toLowerCase().trim();
            data.name = name;
            data.menus = menus;
            props.saveCallback(data);
        }
    }

    const handleDeleterole = () => {
        var data = {}
        data._id = props.editData._id;
        props.deleteCallback(data);
        handleCloseDialog();
    }

    React.useEffect(() => {
        if (props.editMode) {
            if (
                name.trim() != ""
            ) {
                setBtnDisabled(false)
            }
            else
                setBtnDisabled(true)
        } else {
            if (
                name.trim() != ""
            )
                setBtnDisabled(false)
            else
                setBtnDisabled(true)
        }
    }, [name])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };


    const checkbox = (rowData, fieldname) => (
        <Checkbox
            checked={rowData.access[fieldname]}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled =  {(rowData.key =="income_statement" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="chat") ) ||
                (rowData.key =="pnl_reports" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="chat" ) ) || 
                (rowData.key =="bu_pnl" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="chat" ) ) ||
                (rowData.key =="receivables" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="download" ) ) ||
                (rowData.key =="reports" && (fieldname=="delete"  ) ||
                (rowData.key =="aging_reports" && ( fieldname=="delete") ) ||
                (rowData.key =="improvement_plan" && (fieldname =="view" || fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname =="download" || fieldname=="chat") ) ||
                (rowData.key =="databrowser" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="chat") ) ||
                (rowData.key =="data_validation" && (fieldname=="add" || fieldname=="edit" || fieldname=="delete" || fieldname=="upload" || fieldname=="chat") ) ||
                (rowData.key =="hfm_data" && ( fieldname=="delete" || fieldname=="upload" || fieldname=="chat" ) ) ||
                (rowData.key =="user_management" && (fieldname=="delete" || fieldname=="upload" || fieldname=="chat" ) ) ||
                (rowData.key =="role_master" && ( fieldname=="upload" || fieldname=="chat" ) ) ||
                (rowData.key =="user_master" && (fieldname=="upload" || fieldname=="chat" ) )  ) ? true: false 
                
     } onClick={() => {
                console.log(rowData, fieldname, "onok")
                handleChangeCheckBox(rowData, fieldname)
            }}
        />
    )

    const roleLabel = (rowData) => (
        <span
            style={{
                paddingLeft: `${rowData.level - 1}rem`
            }}
        >{rowData.label}</span>
    )

    const setChildTrue = (allData, fieldname, state) => {
        allData.map(x => {
            x.access[fieldname] = x.access[fieldname] == undefined ? false : x.access[fieldname];
            x.access[fieldname] = state
            if (x.child != null) {
                x.child = setChildTrue(x.child, fieldname, state)
            }
        })
        return allData
    }

    const handleCheckBoxOnClick = (allData, rowData, fieldname) => {
        allData.map(x => {
            if (x._id == rowData._id) {
                x.access[fieldname] = x.access[fieldname] == undefined ? false : x.access[fieldname];
                x.access[fieldname] = !x.access[fieldname]
                if (x.child != null) {
                    x.child = setChildTrue(x.child, fieldname, x.access[fieldname])
                }
            } else {
                if (x.child != null) {
                    x.child = handleCheckBoxOnClick(x.child, rowData, fieldname)
                }
            }
            return x
        })
        return allData
    }

    const handleChangeCheckBox = (rowData, fieldname) => {
        let allData = maskedMenus;
        allData = handleCheckBoxOnClick(allData, rowData, fieldname);
        setMaskedMenus(allData);
        setTime(new Date().getUTCMilliseconds())
    }

    React.useEffect(() => {
        handleResetPopUp();
        if (props.showModal) {
            if (props.editMode) {
                loadEditData();
            } else {
                loadAddData();
            }
        }
    }, [props.showModal]);

    const body = (
        <div className='customModal roleMasterModal'>
            <div className='header'>
                {props.editMode ? <h2>Edit Role Data</h2> : <h2>Add Role Data</h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                {props.saveLoading ? <CircularProgress /> :
                    <React.Fragment>
                        <Grid item xs={6} >
                            <TextField
                                label="Name"
                                value={name}
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <h3 className='mt-30'>Menus</h3>
                        { (name==="Admin" || name ==="Corporate User"  || name ==="EACIIT Admin") ?
                            <Table className="-striped -highlight"
                            columns={[
                                { Header: 'Menu Name', accessor: 'label', Cell: rowData => roleLabel(rowData.row.original) },
                                { Header: 'View', accessor: 'access.view', Cell: rowData => checkbox(rowData.row.original, 'view') },
                                { Header: 'Add', accessor: 'access.add', Cell: rowData => checkbox(rowData.row.original, 'add') },
                                { Header: 'Edit', accessor: 'access.edit', Cell: rowData => checkbox(rowData.row.original, 'edit')  },
                                { Header: 'Delete', accessor: 'access.delete', Cell: rowData => checkbox(rowData.row.original, 'delete')} ,
                                { Header: 'Upload', accessor: 'access.upload', Cell: rowData => checkbox(rowData.row.original, 'upload') },
                                { Header: 'Download', accessor: 'access.download', Cell: rowData => checkbox(rowData.row.original, 'download') },
                                { Header: 'Chat', accessor: 'access.chat', Cell: rowData => checkbox(rowData.row.original, 'chat') },
                            ]}
                            data={maskedMenus}
                        /> :
                        <Table className="-striped -highlight"
                            columns={[
                                { Header: 'Menu Name', accessor: 'label', Cell: rowData => roleLabel(rowData.row.original) },
                                { Header: 'View', accessor: 'access.view', Cell: rowData => checkbox(rowData.row.original, 'view') },
                                { Header: 'Add', accessor: 'access.add', Cell: rowData => checkbox(rowData.row.original, 'add') },
                                { Header: 'Edit', accessor: 'access.edit', Cell: rowData => checkbox(rowData.row.original, 'edit')  },
                                { Header: 'Upload', accessor: 'access.upload', Cell: rowData => checkbox(rowData.row.original, 'upload') },
                                { Header: 'Download', accessor: 'access.download', Cell: rowData => checkbox(rowData.row.original, 'download') },
                                { Header: 'Chat', accessor: 'access.chat', Cell: rowData => checkbox(rowData.row.original, 'chat') },
                            ]}
                            data={maskedMenus}
                        />
                        }
                        <div> 
                            <p style={{fontSize: 14 }}> <InfoIcon style={{ fontSize: 18 }}/> &ensp; We have enabled Roles only for the available sections.</p> 
                        </div>
                        <Grid container justify="flex-end" className='mt-30'>
                            <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        </Grid>
                    </React.Fragment>
                }
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}