import axios from 'axios';
import { CONFIG } from '../../config';

export async function getUserService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/getallcomplete/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getRoleandGroup(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/getroleandgroup/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function addUser(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/add`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function editUser(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/edit`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function deleteUserService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/user/delete`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getRoleService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/aclgroup/getall/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function addRoleService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/aclgroup/add/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function editRoleService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/aclgroup/edit/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function deleteRoleService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/aclgroup/delete/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getMenuService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/menumaster/getall/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getCustomerCommentsService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getcustomercomments/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function getNewCommentsService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getNewcomments/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function deleteCustomerCommentsService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/automation/deletecustomercomments/`,
			payload
		)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getPaymentDataService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getpayment/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function deletePaymentDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/deletepayment/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function fetchAgingReportDateServiceMonthly(payload) {
	let url = 'receivables/all/aging/reportdateMonthly/';
	if (payload.isTornado) {
		url = url + `?isTornado=true`;
	}
	return axios
		.get(`${CONFIG.api_base_url}v1/${url}`)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function savePaymentDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/uploadpayment/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function savePaymentPABDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/upload-forecast/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function saveCommentDataService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/automation/uploadcustomercomments/`,
			payload
		)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function saveCommentNewDataService(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/automation/uploadNewcomments/`,
			payload
		)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function downloadFileService(payload) {
	return axios({
		url: `${CONFIG.api_base_url}v1/automation/downloadfile/?path=${payload.path}&file_name=${payload.file_name}&id=${payload.id}&type=${payload.type}`,
		method: 'GET',
		responseType: 'blob',
	})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', payload.file_name);
			document.body.appendChild(link);
			link.click();
			return response;
		})
		.catch((err) => err.response);
}

export async function fetchPaymentInvoiceService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/getagingpayment/ `, payload)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function sendEmailUserAnalytics(payload) {
	return axios
		.post(
			`${CONFIG.api_base_url}v1/aroverdueanalysis/send-email-user-analytics`,
			payload
		)
		.then((response) => response)
		.catch((err) => err.response);
}
export async function fetchUserAnalyticService(payload) {
	let config = {};
	if (payload.export) {
		config.responseType = 'blob';
	}
	return axios
		.post(`${CONFIG.api_base_url}v1/analytics/user`, payload, config)
		.then((response) => {
			if (payload.export) {
				var x = response.data;
				var u = URL.createObjectURL(x);
				var a = document.createElement('a');
				a.href = u;
				a.download = response.headers['content-disposition'].split('=')[1];
				a.click();
				window.URL.revokeObjectURL(u);
			}
			return response;
		})
		.catch((err) => err.response);
}

export async function fetchDefaultFilterUserAnalyticService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/analytics/lastquarter`)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function fetchISFileDataService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getuploadeddata/`)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function fetchForecastFileDataService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getuploadeddataforecast/`)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function fetchFilterDataService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/getfilterforecast/`)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function fetchPABFileDataService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/uploaded-pab-data/`)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function fetchImpactAnalysisFileDataService() {
	return axios
		.get(`${CONFIG.api_base_url}fcfImpact/getuploadeddata`)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function uploadISFileService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/uploadpnldata/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function uploadPABFileService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/uploadpabdata/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function uploadImpactAnalysisFileService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}fcfImpact/uploadfile`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function uploadForecastFileService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}v1/automation/uploadforecastdata/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}
export async function deleteISFileService() {
	return axios
		.get(`${CONFIG.api_base_url}v1/automation/deletepnldata`)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getEbitdaBudgetDataService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}shreeCement/getuploadeddatabudget/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function getEbitdaActualDataService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}shreeCement/getuploadeddataactual/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function saveEbitdaBudgetDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}shreeCement/uploaddatabudget/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}

export async function saveEbitdaActualDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}shreeCement/uploaddataactual/`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}
