import { all } from 'redux-saga/effects';
import actionWatcher from '../auth/saga'
import dashboardActionWatcher from '../dashboard/saga'
import dataBrowserActionWatcher from '../dataBrowser/saga'
import invoiceDetailActionWatcher from '../invoiceDetail/saga'
import userMgmgtActionWatcher from '../userManagement/saga'
import chatStoreActionWatcher from '../chatStore/saga'
import incomeStatementActionWatcher from '../../components/IncomeStatement/store/saga'
import dayReportActionWatcher from '../../components/Receivable/Reports/DayReports/store/saga'
import monthlyStatusReportActionWatcher from '../../components/Receivable/Reports/MonthlyStatusReports/store/saga'
import invoiceReportActionWatcher from '../../components/Receivable/Reports/InvoiceReports/store/saga'
import geospacialActionWatcher from '../../components/GeoLocation/store/saga';
import freeCashFlowWatcher from '../../components/FreeCashFlow/store/saga'
import ECFMWatcher from '../../components/ECFM/store/saga'
import ExtendedPlanningWatcher from '../../components/ExtendedPlanning/store/saga'
import shreeCementWatcher from '../../components/poc-shreecement/store/saga';
import functionalPlanningWatcher from '../../components/FunctionalPlanning/store/saga';

export default function* rootSaga() {
    yield all([
        actionWatcher(),
        dashboardActionWatcher(),
        dataBrowserActionWatcher(),
        userMgmgtActionWatcher(),
        invoiceDetailActionWatcher(),
        chatStoreActionWatcher(),
        incomeStatementActionWatcher(),
        dayReportActionWatcher(),
        invoiceReportActionWatcher(),
        geospacialActionWatcher(),
        monthlyStatusReportActionWatcher(),
        freeCashFlowWatcher(),
        ECFMWatcher(),
        ExtendedPlanningWatcher(),
        shreeCementWatcher(),
        functionalPlanningWatcher()
    ]);
}
