import React,{ useMemo } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import { getAccessByMenuKey } from "../../../utils/userManagement";
import { LoremIpsum } from "lorem-ipsum";
import { format } from "date-fns";

export default function ModalHistory(props) {
	const lorem = new LoremIpsum();
	const randomHeader = useMemo(() => lorem.generateWords(5), []);
	const randomContent = useMemo(() => lorem.generateWords(2), []);
	return (
        <div className="cadenceBoard-modal mt-20">
            <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="sm" className="cadenceModal">
		        <DialogTitle>Histories</DialogTitle>
		        <DialogContent>
					{  props.data.map((thisData) => (
						  <div className="history-content">
						  <span className="history-header">{format(new Date(thisData.action_time), "do-MMM-yyyy H:mma")} - { thisData.action_by }</span><br/>
						  <ul className="history-detail">
						  		<li><strong>ACTION : </strong>{thisData.action_type}</li>
							{
								thisData.action_type == "Edit" && thisData.changes != null ? thisData.changes.map((changesData)=>(
									<li><strong>{changesData.field.toUpperCase()} : </strong>{changesData.old_value == null ? "Blank"  : changesData.old_value } → {changesData.new_value}</li>
								)) : ""
							}
						  </ul>

					  </div>
					))}
		        
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={props.handleClose} color="primary" ariant="contained" size="small">Close</Button>
		        </DialogActions>
		    </Dialog>
        </div>

    )
}
