import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { alpha } from '@material-ui/core/styles';
import { getAccessByMenuKey } from "../../../utils/userManagement";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const checkData = (input) => {
	if(input === null || input === undefined) {
		return null
	}
	var [day, month, year] = input.split('/');
	let newInput = `${month}/${day}/${year}`
	return new Date(newInput)
}

export default function ModalCadence(props) {
	const [value1, setValue1] = React.useState(null);
	const [value2, setValue2] = React.useState(null);
	const [value3, setValue3] = React.useState(null);
	const [value4, setValue4] = React.useState(null);

	const fillArray = () => {
		const l1 = checkData(props.data.l1);
		const l2 = checkData(props.data.l2);
		const l3 = checkData(props.data.l3);
		const l4 = checkData(props.data.closed_on);

		setValue1(l1);
		setValue2(l2);
		setValue3(l3);
		setValue4(l4);
	}

	React.useEffect(() => {
		fillArray();
	}, [props.data]);

	const maskDate = (date) =>{
		console.log(date, date.substr(0,10),"cek date")
		return new Date(date.substr(0,10)).toISOString()
	}

	const savedData = (date) => {
		if (typeof date === 'object') {
			if (date === null) {
				return null;
			}
			return maskDate(date.toISOString());
		}
		return maskDate(new Date(date).toISOString())
	}

	const handleSave = () => {
		const l1 = savedData(value1);
		const l2 = savedData(value2);
		const l3 = savedData(value3);
		const l4 = savedData(value4);

		// const data = {  
        //     "l1": l1,
        //     "l2": l2,
        //     "l3": l3,
        //     "closed_on": l4,
        // }
		let data = props.data;
		data["l1"] = l1;
		data["l2"] = l2;
		data["l3"] = l3;
		data["closed_on"] = l4;
		props.handleSave(data);
	};

	return (
        <div className="cadenceBoard-modal mt-20">
            <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="sm" className="cadenceModal">
		        <DialogTitle>Edit Plan</DialogTitle>
		        <DialogContent>
		          <div className="form-cadence">
			          <InputLabel htmlFor="name" className="label-input">FCF Plan Name </InputLabel>
			          <TextField size="small" id="name" value={props.data.plan_id} fullWidth variant="outlined" disabled={true}/>
			      </div>
			      <div className="form-cadence">
			          <InputLabel htmlFor="l1" className="label-input">L1 </InputLabel>
			          <MuiPickersUtilsProvider utils={DateFnsUtils}>
				          <KeyboardDatePicker className="input-date-picker"
					          disableToolbar
					          InputAdornmentProps={{ position: "end" }}
					          variant="outlined"
					          format="dd-MMM-yyyy"
					          id="l1"
					          value={value1}
						        onChange={(newValue) => {
						          setValue1(newValue);
						        }}
					          KeyboardButtonProps={{
					            "aria-label": "change date"
					          }}
					        />
					   </MuiPickersUtilsProvider>
			      </div>
			      <div className="form-cadence">
			          <InputLabel htmlFor="l2" className="label-input">L2 </InputLabel>
			          <MuiPickersUtilsProvider utils={DateFnsUtils}>
				          <KeyboardDatePicker className="input-date-picker"
					          disableToolbar
					          InputAdornmentProps={{ position: "end" }}
					          variant="outlined"
					          format="dd-MMM-yyyy"
					          id="l2"
					          value={value2}
						        onChange={(newValue) => {
						          setValue2(newValue);
						        }}
					          KeyboardButtonProps={{
					            "aria-label": "change date"
					          }}
					        />
					   </MuiPickersUtilsProvider>
			      </div>
			      <div className="form-cadence">
			          <InputLabel htmlFor="l3" className="label-input">L3 </InputLabel>
			          <MuiPickersUtilsProvider utils={DateFnsUtils}>
				          <KeyboardDatePicker className="input-date-picker"
					          disableToolbar
					          InputAdornmentProps={{ position: "end" }}
					          variant="outlined"
					          format="dd-MMM-yyyy"
					          id="l3"
					          value={value3}
						        onChange={(newValue) => {
						          setValue3(newValue);
						        }}
					          KeyboardButtonProps={{
					            "aria-label": "change date"
					          }}
					        />
					   </MuiPickersUtilsProvider>
			      </div>
			      <div className="form-cadence">
			          <InputLabel htmlFor="closedOn" className="label-input">Closed On </InputLabel>
			          <MuiPickersUtilsProvider utils={DateFnsUtils}>
				          <KeyboardDatePicker className="input-date-picker"
					          disableToolbar
					          InputAdornmentProps={{ position: "end" }}
					          variant="outlined"
					          format="dd-MMM-yyyy"
					          id="closedOn"
					          value={value4}
						        onChange={(newValue) => {
						          setValue4(newValue);
						        }}
					          KeyboardButtonProps={{
					            "aria-label": "change date"
					          }}
					        />
					   </MuiPickersUtilsProvider>
			      </div>
			      <div className="form-cadence">
			          <InputLabel htmlFor="comments" className="label-input">Comments </InputLabel>
			          <TextField size="small" id="comments" type="email" value={props.data.comments} fullWidth variant="outlined" disabled={true}/>
			      </div>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={props.handleClose}>Cancel</Button>
				  {getAccessByMenuKey(props.menuData, "cadence_board", "edit") &&
		          <Button variant="contained" size="small" color="primary" onClick={handleSave}>Save</Button>
				}
		        </DialogActions>
		    </Dialog>
        </div>

    )
}
