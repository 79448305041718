import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import ReactTable from "react-table-6";
import Button from "@material-ui/core/Button";
import "./table.scss";

export default function SavedDataTable(props) {
    const extendedPlanningState = useSelector((state) => state.extendedPlanning);
    const history = useHistory();
    const { simulationData } = extendedPlanningState;
    const { simulationList } = simulationData;

    const handleAddSimulation = () => {
        history.push("/dashboard/xpna/extended-planning/simulation-logic/add-edit");
    };

    const handleEditSimulation = (data) => {
        // dispatch({
        //     type: "FETCH_SIMULATION_VALUE_SUCCESS",
        //     data: _data.details,
        // });
        if (typeof data?._id === "string" && data.id !== "") {
            history.push(`/dashboard/xpna/extended-planning/simulation-logic/add-edit?id=${data?._id}`);
        }
    };
    
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }))(Tooltip);

      const useStyles = makeStyles(() => ({
        customWidth: {
          maxWidth: 500,
        }
      }));

      const classes= useStyles();


    const changeKeysOfHeader = (data) => {
        if (data.length) {
            return data.map((item) => {
                if (item.key==="name"){
                    return{
                        Header:"Simulation Name" ,
                        id:"name",
                        accessor:"name",
                        Cell: (thisProps)=>(
                            <div className="simulation-name-cell">{thisProps.value}
                                <LightTooltip classname='custom-tooltip' classes={{tooltip:classes.customWidth}} title={<div dangerouslySetInnerHTML={{ __html: thisProps.original.description.detail_text}}/>}>
                                <InfoOutlinedIcon className="info-icon"/>
                            </LightTooltip> 
                            </div>

                        )
                    }
                }

                if (item.key === "action") {
                    return {
                        Header: "Actions",
                        id: "buttons",
                        accessor: "buttons",
                        Cell: (thisProps) => (
                            <Tooltip title={"Edit"}>
                                <EditIcon
                                    className="icon-table"
                                    onClick={() => {
                                        handleEditSimulation(thisProps.original);
                                    }}
                                />
                            </Tooltip>
                        ),
                    };
                }

                if (item.key === "is_default") {
                    return {
                        Header: item.label,
                        accessor: item.key,
                        Cell: (thisProps) => (typeof thisProps.value === "boolean" && thisProps.value ? "true" : "false"),
                    };
                }

                return {
                    Header: item.label,
                    id: item.key,
                    accessor: item.key,
                };
            });
        }

        return [];
    };

    const { rowData, rowHeader } = simulationList;
    let tableHeaderData = changeKeysOfHeader(rowHeader);
    let tableRowData = rowData;

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={8}>
                <div className="top-header">
                    <div>
                        <b>Simulation Model Reports</b>
                    </div>
                    <Button className="newButton btnBlue btnCreate uppercase" onClick={handleAddSimulation} variant="contained" color="primary">
                        Create New Simulation
                    </Button>
                </div>
            </Grid>
            <Grid item xs={8} className="analyticStudioTable-container">
                <ReactTable
                    columns={tableHeaderData}
                    data={tableRowData}
                    // loading={loading}
                    defaultPageSize={5}
                    // pageSize={tableRowData.length > 5 ? tableRowData.length : 5}
                    showPagination={true}
                    //className="-striped -highlight newCustomTable-analyticStudio "
                    className="-striped -highlight xpna-react-table single-line-header "


                />
            </Grid>
        </Grid>
    );
}
