import axios from 'axios';
import { CONFIG } from '../../../config'

export async function fetchDPlanningSalVolDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningsalesvolume`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningsalesvolume`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDPlanningNetSalDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningnetsales`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningnetsales`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDPlanningDistCostDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningdistcost`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningdistcost`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDPlanningVarCostClinkerDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningvarcostclinker`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningvarcostclinker`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDPlanningVarCostCementDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningvarcostcement`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningvarcostcement`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function fetchDPlanningFixedCostDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningfixedcost`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningfixedcost`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}
export async function fetchDPlanningEbitdaAin000sDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningebitdain000s`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningebitdain000s`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}
export async function fetchDPlanningEbitdaPertDataService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getdplanningebitdapert`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }   

    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/getdplanningebitdapert`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {

        return err.response
    });
}

export async function saveKpiPlanningModelsService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/savekpiplanning`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function fetchKpiPlanningModelsService(payload) {
        let queryParam = ""
        if (payload.export) {
            queryParam += `?export=true`
      
            return axios.get(`${CONFIG.api_base_url}v1/functionalplan/getkpiplanningsaved${queryParam}`, {responseType: 'blob'})
            .then(response => {
                var x = response.data;
                console.log(response, x)
                var u = URL.createObjectURL(x)
                var a = document.createElement("a");
                a.href = u;
                a.download = (response.headers['content-disposition']).split('=')[1];
                a.click();
                window.URL.revokeObjectURL(u);
              
              return response;
            }
            ).catch(err => err.response);
        
          }else {
              return axios.get(`${CONFIG.api_base_url}v1/functionalplan/getkpiplanningsaved${queryParam}`).then(
                  response => {
                      return response;
                  }
              ).catch(err => {
      
                  return err.response
              });
          }
}

export async function fetchKPIPlanningService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getkpi`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v1/functionalplan/getkpi`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function saveKpiBudgetPlanningModelsService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/savekpibudget`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function fetchKPIComparePlanningService(payload) {
    if (payload.export) {
        return axios({
            url: `${CONFIG.api_base_url}v1/functionalplan/getkpicompare`,
            method: "POST",
            responseType: "blob",
            data: payload,
        })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }
    return axios
        .post(`${CONFIG.api_base_url}v1/functionalplan/getkpicompare`, payload)
        .then((res) => res)
        .catch((err) => err.response);
}

export async function saveKpiBudgetPlanningInlineModelsService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/savekpibudgetinline`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function updateStatusKpiPlanningService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/functionalplan/updatestatuskpiplanning`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function fetchKpiPlanningReviewService(payload) {
        let queryParam = ""
        if (payload.export) {
            queryParam += `?export=true`
      
            return axios.get(`${CONFIG.api_base_url}v1/functionalplan/getkpiplanningapprove${queryParam}`, {responseType: 'blob'})
            .then(response => {
                var x = response.data;
                console.log(response, x)
                var u = URL.createObjectURL(x)
                var a = document.createElement("a");
                a.href = u;
                a.download = (response.headers['content-disposition']).split('=')[1];
                a.click();
                window.URL.revokeObjectURL(u);
              
              return response;
            }
            ).catch(err => err.response);
        
          }else {
              return axios.get(`${CONFIG.api_base_url}v1/functionalplan/getkpiplanningapprove${queryParam}`).then(
                  response => {
                      return response;
                  }
              ).catch(err => {
      
                  return err.response
              });
          }
}


