import React from 'react';
import { connect } from 'react-redux';
import { getList } from '../../utils/userManagement';
import AnalyticStudio from './AnalyticsStudio-New';
import './FreeCashFlow.scss';

export class FreeCashFlowComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
		};
	}

	fetchMetaData = () => {
		const payload = { metadata_type: 'report' };
		this.props.fetchMetaDataAnalysis(payload);
	};

	getSaveDataAnalyticStudio = (payload) => {
		this.props.getSaveData(payload);
	};

	getSaveDataReportAnalyticStudio = (payload) => {
		this.props.getSavedReportData(payload);
	};

	fetchDataAnalyticsStudio = () => {
		const payload = {};
		this.props.fetchDataAnalysis(payload);
	};

	handleSaveDataAnalyticStudio = (data) => {
		this.props.saveData(data);
	};

	handleSaveDataAnalyticStudioReport = (data) => {
		this.props.saveDataReport(data);
	};

	handleDeleteSavedDataAnalyticStudio = (data) => {
		this.props.deleteSavedData(data);
	};

	handleDeleteSavedDataAnalyticStudioReport = (data) => {
		this.props.deleteSavedDataReport(data);
	};

	componentDidMount() {
		this.props.getAllUser({});
		this.getUserList();
		this.fetchMetaData();
		// this.fetchDataAnalyticsStudio()
		// this.getSaveDataAnalyticStudio({metadata_type : 'report'})
		this.getSaveDataReportAnalyticStudio({});
	}

	getUserList = async () => {
		this.setState({
			users: await getList(),
		});
	};

	render() {
		// console.log(this.props,"dghdjk")
		const { newRadioSelectorValue, users } = this.state;
		const { fetchMetaDataAnalysis, fetchDataAnalysis } = this.props;
		const { authReducer } = this.props;
		const menuData = authReducer.menuData;

		return (
			<div className='buplComponent free-cash-flow'>
				<div className='flex jc-space-between'></div>
				{/* <p>Analytic Studio</p> */}

				{
					<AnalyticStudio
						menuData={menuData}
						shareChart={this.props.shareChart}
						fetchMetaDataAnalysis={fetchMetaDataAnalysis}
						fetchDataAnalysis={fetchDataAnalysis}
						users={users}
						saveData={this.handleSaveDataAnalyticStudio}
						deleteSavedData={this.handleDeleteSavedDataAnalyticStudio}
						deleteSavedDataReport={
							this.handleDeleteSavedDataAnalyticStudioReport
						}
						getSaveDataReportAnalyticStudio={
							this.getSaveDataReportAnalyticStudio
						}
						getSaveDataAnalyticStudio={this.getSaveDataAnalyticStudio}
						saveReportData={this.handleSaveDataAnalyticStudioReport}
						handleEditStudio={this.props.handleEditStudio}
					/>
				}
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			authReducer: state.authReducer,
		};
	},
	(dispatch) => {
		return {
			fetchMetaDataAnalysis(data) {
				dispatch({
					type: 'FETCH_META_DATA_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			fetchDataAnalysis(data) {
				dispatch({
					type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO',
					payload: data,
				});
			},

			getAllUser(data) {
				dispatch({
					type: 'FETCH_ALL_USER',
					payload: data,
				});
			},

			getSaveData(data) {
				dispatch({
					type: 'GET_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			getSavedReportData(data) {
				dispatch({
					type: 'GET_ANALYTIC_STUDIO_REPORT',
					payload: data,
				});
			},
			saveData(data) {
				dispatch({
					type: 'SAVE_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			saveDataReport(data) {
				dispatch({
					type: 'SAVE_ANALYTIC_STUDIO_REPORT',
					payload: data,
				});
			},
			shareChart(data) {
				dispatch({
					type: 'SHARE_CHART_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			deleteSavedData(data) {
				dispatch({
					type: 'DELETE_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			deleteSavedDataReport(data) {
				dispatch({
					type: 'DELETE_REPORT_ANALYTIC_STUDIO',
					payload: data,
				});
			},
			handleEditStudio(data) {
				dispatch({
					type: 'HANDLE_EDIT_STUDIO',
					payload: data,
				});
			},
		};
	}
)(FreeCashFlowComponent);
