import React from 'react';
import Grid from '@material-ui/core/Grid';
import BoardViewComponent from './boardView'
import Modal from "./ModalCadence";


export default function CadenceBoard(props) {
    const [dataModal, setDataModal] = React.useState({})
    const [open, setOpen] = React.useState(false);

    const handleOpen = (data) => {
          setOpen(true)
          setDataModal(data)
    };

    const handleSave = (data) => {
        props.saveDate(data);
        handleClose()
    };

    const handleChangeBoard = (input) => {
        props.saveDate(input);
    }

    const handleClose = () => {
        setOpen(false)
    }



    return (
        <div className="cadenceBoard mt-20">
            <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} sm={12} className="text-right cadence-board">
                <BoardViewComponent handleOpen={handleOpen} handleChangeBoard={handleChangeBoard} planTypeValue={props.planTypeValue} dateStatusSelected={props.dateStatusSelected} />
            </Grid>
            </Grid>
           <Modal menuData={props.menuData} open={open} handleClose={handleClose} handleSave={handleSave} data={dataModal}/>
        </div>

    )
}
