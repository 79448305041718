import React from 'react';
import Grid from '@material-ui/core/Grid';
import LogoImg from "../../../images/KuwaitAirways/logo_login.png"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

export default function Login(props) {
  
    const handleClick = () => {
        window.location = '/route-profitability/panel';
    }


  return (
    <Grid container className="login-page flex jc-center ai-center">
        <Grid item xs={5}>
            <div className="login-bg"/>
            <div className="login-form">
                <div className="header-login">
                    <img src={LogoImg} className="logo"/>
                    <span className="title">Route Profitability</span>
                    <AccountCircleIcon fontSize="small" className="icon-user"/>
                </div>
                <div className="form-group">
                    <FormControl variant="outlined" fullWidth size="small" className="form-filter">
                      <FormLabel component="legend" className="mb-10">Your Email</FormLabel>
                      <TextField id="outlined-basic" variant="outlined" size="small"/>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth size="small" className="form-filter mt-20">
                      <FormLabel component="legend" className="mb-10">Password</FormLabel>
                      <TextField id="outlined-basic" variant="outlined" size="small"/>
                    </FormControl>
                    <div className="flex jc-center ai-center mt-20">
                      <Button variant="contained" size="small" className="btn-login" onClick={()=>{handleClick()}}>
                        Login now
                      </Button>
                    </div>
                </div>
            </div>
        </Grid>
    </Grid>
  );
}
