import React from "react";
import "./index.scss";
import Logo from "../../images/eaciit-white-logo.png";
import BackgroundImage from "../../images/login-bg.png"

function NotFound() {
    return (
        <div className="notFoundPage">
            <img src={BackgroundImage} width="100%" style={{maxHeight: '100vh'}} />
            <img src={Logo} className='logo' />
            <div className="message">
                <h2> We are undergoing a bit of scheduled maintenance. </h2>
                <h2>Sorry for the inconvenience. We'll be back up and running soon.</h2>
            </div>
        </div>
    )
}

export default NotFound;