import { transformFilterData, appendActionOwner } from './transformer'

const reducer = (state = {
   loading: false,
   tableRowData: [],
   tableRowData1: [],
   totalTableRowCount: 0,
   tableRowHeader: [],
   tableRowHeader1: [],
   createChannel: false,
   newRowItem: {},
   yearMonthArr: [],
   agingCategoryArr: [],
   actionOwnerArr: [],
   customerNameArr: [],
   dayFilterArr: [],
   buFilterArr:[],
   regionArr: []
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {

      case 'FETCH_MONTHLY_STATUS_DATA_LOADER':
         return { ...state, loading: action.loading };

      case 'FETCH_MONTHLY_STATUS_DATA_SUCCESS':
         newState.loading = action.loading;
          newState.tableRowData = action.data.row_data || [];
          newState.tableRowHeader = action.data.row_header;
          newState.tableRowHeader1 = action.data.row_header_1 || [];
          newState.tableRowData1 = action.data.row_data_1;
          newState.regionArr = action.data.filter;
          newState.buFilterArr = action.data.buFilter;

         return newState;

      default:
         return newState;
   }
};
export default reducer;







