import React from "react";
import Highcharts from "../highcharts";
import HighchartsReact from "highcharts-react-official";
import Drilldown from "highcharts/modules/drilldown";
import {
    transformStackData,
    getGapAnalysisChart,
    stackSeriesDataTransform,
} from "../utils/stack";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Switch from "react-switch";
import Select from "react-select";

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
    Drilldown(Highcharts);
}

export class Stack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filterSelected: "cost_center=location",
            costCenter: "cost_center=location",
            locationDropdown: "",
            timePeriod: "",
            timePeriodOptions: [
                { value: "quarter", label: "Quarter" },
                { value: "month", label: "Month" },
                { value: "year", label: "Year" },
            ],
            costCenterOptions: [
                { value: "lob", label: "Line Of Business" },
                { value: "product", label: "Product" },
                { value: "location", label: "Location" },
            ],
            locationOptions: [
                { value: "loc1", label: "Location 1" },
                { value: "loc2", label: "Location 2" },
                { value: "loc3", label: "Location 3" },
            ],
            seriesData: [],
            checked: false,
            dataColumns: [
                {
                    Header: "Business Unit",
                    accessor: "business_unit",
                },
                {
                    Header: "Unit Head",
                    accessor: "unit_head",
                },
                {
                    Header: "Key Indicator",
                    accessor: "key_indicator",
                },
                {
                    Header: "Current FCF ($)",
                    accessor: "current_fcf",
                },
                {
                    Header: "Target FCF ($)",
                    accessor: "target_fcf",
                },
                {
                    Header: "Gap to Target FCF",
                    accessor: "gap_to_target",
                },
                {
                    Header: "Email",
                    accessor: "send_email",
                },
            ],
            dataList: [
                {
                    id: 3,
                    businessUnit: "Product",
                    unitHead: "Product_Head",
                    keyI: "EBITDA",
                    currentFcf: 15,
                    targetfcf: 7,
                    gtp: 2,
                    email: (
                        <a
                            href="mailto:product.head@email.com?subject=<INVENTORY>: Gap to Target identified in Location1. Please initiate improvement plan. &body= 
                Dear Unit Head,
                    %0D%0A
                    %0D%0A

                   This email is being sent to you by the Leadership team.
                   %0D%0A
                   %0D%0A

                   A free cash flow gap to target of $4 M has been identified related to <INVENTORY> under your management. Details are as below:
                   
                   %0D%0A
                   %0D%0A
                   %0D%0A

                   Business Unit     Key Indicator    Current FCF ($ M)       Target FCF ($ M)      Gap to Target ($ M)         
                        Operations         INVENTORY                13                                    17                                  4
                   
                        %0D%0A

                        %0D%0A
                        %0D%0A

                   Please click on the following link to initiate an execute an improvement plan for the same.
                   %0D%0A

                   <Link to navigate to FCF Improvement section over the application>

                   %0D%0A
                   %0D%0A

                   
                   Thank you for your co-operation in driving Free Cash Flow improvements within the organization.
                   %0D%0A
                   %0D%0A

                   Client Leadership Team
                   %0D%0A

                   Location 1
                   %0D%0A

                   <Details>"
                        >
                            product.head@email.com{" "}
                        </a>
                    ),
                },
            ],
            drillDownData: [],
            xAxisLabel: "",
            yAxisLabel: "",
            options: {},
        };
    }

    callApi = (query) => {
        getGapAnalysisChart(query).then((response) => {
            console.log(response, "pppll>>>>");
            const transData = transformStackData(
                response.data.series_data,
                "location"
            );
            this.setState({
                loading: false,
                dataList: stackSeriesDataTransform(response.data.series_data),
                options: this.creatChartOption(transData),
            });
        });
    };

    createQueryParam = () => {
        const { costCenter, locationDropdown, timePeriod } = this.state;
        let query = "";
        if (costCenter) {
            query = query + costCenter + "&";
        }
        if (locationDropdown) {
            query = query + locationDropdown + "&";
        }
        if (timePeriod) {
            query = query + timePeriod + "&";
        }
        return query;
    };

    onCostChange = (ev) => {
        this.setState(
            {
                costCenter: "cost_center=" + ev.value,
            },
            () => {
                this.callApi(this.createQueryParam());
            }
        );
    };

    onTimeChange = (ev) => {
        this.setState(
            {
                timePeriod: "time_period=" + ev.value,
            },
            () => {
                this.callApi(this.createQueryParam());
            }
        );
    };

    onLocationChange = (ev) => {
        this.setState(
            {
                locationDropdown: "location=" + ev.value,
            },
            () => {
                this.callApi(this.createQueryParam());
            }
        );
    };

    handleSwitchChange = (checked) => {
        this.setState({ checked });
    };

    creatChartOption = (transData) => {
        return {
            chart: {
                type: "column",
            },
            title: {
                text: "FCF Target Chart, grouped by Key Indicator",
            },
            xAxis: {
                categories: ["EBITDA", "Inventory", "Recievables", "Payables"],
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: "USD Millions",
                },
            },
            tooltip: {
                formatter: function () {
                    return (
                        "<b>" +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        this.y +
                        "<br/>" +
                        "Total: " +
                        this.point.stackTotal
                    );
                },
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                },
            },
            series: transData,
        };
    };

    componentDidMount() {
        this.callApi(this.state.filterSelected);
    }

    render() {
        const {
            dataList,
            dataColumns,
            costCenterOptions,
            timePeriodOptions,
            locationOptions,
        } = this.state;
        const mystyle = {
            width: "25%",
            display: "inline-block",
        };
        const mystyle1 = {
            margin: "20px",
        };

        return (
            <div className="App">
                <div>
                    <div style={mystyle}>
                        <label>Cost Center</label> :
            <Select onChange={this.onCostChange} options={costCenterOptions} />
                    </div>

                    <div style={mystyle}>
                        <label>Time Period</label> :
            <Select onChange={this.onTimeChange} options={timePeriodOptions} />
                    </div>

                    <div style={mystyle}>
                        <label>Location</label> :
            <Select
                            onChange={this.onLocationChange}
                            options={locationOptions}
                        />
                    </div>
                </div>
                <br />
                <div>
                    <label>
                        <span>Switch to chart view</span>
                        <Switch
                            onChange={this.handleSwitchChange}
                            checked={this.state.checked}
                        />
                    </label>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div style={mystyle1}>
                        <ReactTable
                            data={dataList}
                            columns={dataColumns}
                            defaultPageSize={10}
                            pageSizeOptions={[2, 4, 6]}
                        />
                    </div>
                    <br />

                    <div>
                        {!this.state.loading && this.state.checked && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.options}
                                {...this.props}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Stack;
