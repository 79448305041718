import React from 'react';
import { CONFIG } from '../../config'
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactDOM from 'react-dom'
export default class DataBrowserComponent extends React.Component {
    constructor(props){
        super(props);
        this.iframeUrl = { 
            "0" : `${CONFIG.data_browser_url}/app/`,
            "1" : `${CONFIG.data_browser_url}/app/data-table/db-name=new&collection-name=income_statement_pnl_data?screenSize=fullscreen`
        }

        this.state = {
            dataBrowserSelectorValue: '0',
            iFrameHeight: '0px'
        }
    }

   

    handleDataBrowserSelectorValue = (event) => {
        this.setState({
            dataBrowserSelectorValue: event.target.value,

        })
    };

    componentDidMount() {
        const obj = ReactDOM.findDOMNode(this);
        this.setState({iFrameHeight:  obj.scrollHeight + 'px'});
     }

    render() {
        const {  dataBrowserSelectorValue } = this.state;


        return (
            <div className='buplComponent' style={ {height : '92%'} }>
            <React.Fragment>
            <div className="flex ai-center jc-space-between mb-10 menu-options">
                <FormControl component="fieldset">
                    <RadioGroup name="radio" value={dataBrowserSelectorValue} onChange={this.handleDataBrowserSelectorValue} className='radioGroupHorizontal'>
                        <div className="benchmark-group">
                        <FormControlLabel value="0" control={<Radio color="primary" />} label="Default View" />
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="BU P&L" />

                        </div>
                        </RadioGroup>
                            </FormControl>
                            </div>
                            </React.Fragment>

                <iframe
                        style={{ display: 'block', width: '100%', height: this.state.iFrameHeight }}
                        src={this.iframeUrl[dataBrowserSelectorValue]}
                        title="Data Browser - new"
                    />
            </div>
        )
    }
}