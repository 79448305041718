import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Chip, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toastr } from "react-redux-toastr";


export default function SimpleModal(props) {
    const [emailDefault, setEmailDefault] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);
    const [error, setError] = React.useState('');
    React.useEffect(() => {
        if (props.showModal) {
            handleResetPopUp();
            // if (props.editMode) {
                loadEditData();
            // }
        }
    }, [props.showModal]);

    const handleResetPopUp = () => {
        setEmailDefault([])
        setAllUsers([])
    }
    const loadEditData = () => {
        let emailDefaultProps = props.emailDefault;
        setEmailDefault(emailDefaultProps)
        let allUsersProps = props.allUsers;
        setAllUsers(allUsersProps)
    }
    const handleClose = () => {
        props.modalCallBack();
    }


    const handleCloseDialog = () => {
        props.closeViewRecipient();
    };

   
    const handleChangeRecipient = (event, value) => {
        let refinedBg;
        let errorMsg = '';
        // if (value !=undefined) {
        //     errorMsg =  '* All Function includes all the Budget Groups.';
        //     refinedBg = emailDefault
        // }else{
            refinedBg = value;
        // }
        setError(errorMsg)
        setEmailDefault(refinedBg);
        document.activeElement.blur()
        // props.handleChangeRecipient(emailDefault)
        // setBtnDisabled(false)
    }
    
    const handleSubmitRecipient = () => {
        props.handleSubmitRecipient(emailDefault)
    }
    const body = (
        
        <div className='customModal viewEmailModal'>
        <div className='header'>
            <h2>View Recipient</h2>
        </div>
        <div className='modal-content email-template'>
            {/* <form onSubmit={handleSubmitRecipient}> */}
            <Autocomplete
        multiple
        id="tags-standard"
        options={allUsers}
        defaultValue={emailDefault}
        getOptionSelected = {(option, val) => option.email == val.email}
        getOptionLabel = {(option) => option.full_name}
        onChange={handleChangeRecipient}
        renderInput={(params) => (
        <TextField
            {...params}
            variant="standard"
            label="Email Recipient"
            placeholder="Email Recipient"
           />
        )}
        renderOption={(option) => {
            return (
                <Typography style={{fontSize:'15px'}} noWrap >
                    <span>{option.full_name}</span>
                </Typography> 
            );
          }}
    />  
     <Typography  variant ="caption" style={{color: '#f44336'}} >
         {error}
     </Typography>
                <div className="flex jc-flex-end mt-30">
                <Button onClick={handleSubmitRecipient} variant="contained" color="primary">Submit</Button>
                    {/* <Button type="submit" variant="contained" color="primary">Submit</Button> */}
                    <Button variant="contained" className='ml-16' onClick={handleCloseDialog}>Cancel</Button>
                </div>
            {/* </form> */}
        </div>
    </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleCloseDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

        </div>
    );
}