import React from 'react';
import DashboardIcon from "../../../images/KOC/dashboard_icon.png"
import OperationalIcon from "../../../images/KOC/operational_icon.png"
import RCAIcon from "../../../images/KOC/rca_icon.png"
import { Link as Linkroute } from "react-router-dom";
// import "./index.scss";

const panelMenuArray = [
  {
    id: 1,
    text: 'Dashboard',
    subText: '(MTBF Analysis)',
    link: '/new-dashboard/analysis',
    icon: DashboardIcon,
    show: true,
    key: "dashboard",
    defaultBreadcrumbs : {text : "Overview", link:"#"}
  },
  {
    id: 2,
    text: 'Operational Parameters',
    subText: 'Monitoring and Analysis',
    link: '/new-dashboard/operational',
    icon: OperationalIcon,
    show: true,
    key: "operational",
    defaultBreadcrumbs : {text : "Table View", link:"#"}
  },
  {
    id: 3,
    text: 'RCA and Remedial Action',
    link: '/new-dashboard/rca',
    icon: RCAIcon,
    show: true,
    key: "rca",
    defaultBreadcrumbs : {text : "Kanban Board", link:"#"}
  }
]

function Panel(props) {

    const setBreadcrumbsList = (data)=>{
        let breadcrumbs = []
        breadcrumbs.push({text:data.text, link : data.link})
        breadcrumbs.push(data.defaultBreadcrumbs)
        props.setBreadcrumbs(breadcrumbs);
    }

    return (
        <div className="panel-page">
            <div className="header-panel">
                ESP  joint project by KOC, Journey Consulting and EACIIT <br/>
                Monitor conditions of use and remedial actions to extend MTBF, <br/>
                increase production and reduce capital and operating costs
            </div>
            <div className="box-list">
                 {
                    panelMenuArray && panelMenuArray.map((item, index) => (
                        <Linkroute to={item.link} className="box" onClick={()=>{props.setShowMenu(true); props.setActiveMenu(item.text); setBreadcrumbsList(item)}}>
                            <div className="box-icon"><img src={item.icon} className="icon-panel"/></div>
                            <div className="box-title">{item.text}<br/>{item.subText && item.subText}</div>
                        </Linkroute>
                    ))  
                  }
            </div>
        </div>
    )
}

export default Panel;