import BarChart from "./chart"
import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas,convertNumber } from '../../../../../utils/numberUtils'

function ChartContainer(props) {
    return (
        <>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12}>
                        <Card>
                          <CardContent>
                            <BarChart
                                subChart = {props.subChartData}
                                chartData={props.chartData}
                                chartTitle={props.chartTitle}
                                width={props.width}
                            ></BarChart>
                          </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ChartContainer;