import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { getActionOwneruser } from "../../../../../utils/userManagement";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { format } from 'date-fns';

export default function SimpleModal(props) {
    const { collection_forecast_confirmed_by, collection_forecast, collection_forecast_date } = props.commentRowData
    const [confirmedBy, setMonth] = React.useState(collection_forecast_confirmed_by || '');
    const [selectedConfirmedBy, setSelectedConfirmedBy] = React.useState(collection_forecast_confirmed_by || '');
    const [inputValue, setInputValue] = React.useState(collection_forecast!=-1 ? collection_forecast : '');
    var dateVal = collection_forecast_date ? new Date(collection_forecast_date) : null;
    const [selectedDate, setSelectedDate] = React.useState(dateVal);
    const dashboardState = useSelector(state => state.dashboardState);
    const listDropDownArr = getActionOwneruser(dashboardState.receivableData.ageingData.external.allUser, props.commentRowData.bu)

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleClose = () => {
        props.modalCallBack(false);
    };

    const handleConfirmedByChange = (data) => {
        if (data == null) {
            setMonth("");
            setSelectedConfirmedBy({});
            return
        }
        setMonth(data.login_id);
        setSelectedConfirmedBy(data);
    }

    React.useEffect(() => {
        let allUser = dashboardState.receivableData.ageingData.external.allUser
        let sel = allUser.find(x => { return x.login_id == confirmedBy });
        setSelectedConfirmedBy(sel);
    }, [confirmedBy])

    const handleValueOnChange = (event) => {
      if (event.target.value === '' || !isNaN(event.target.value)) {
            setInputValue(event.target.value)      
        }
      
    }

    const handleSubmit = () => {
        console.log()
        const payload = {
            "collection_actual": props.commentRowData.collection_actual,
            "collection_forecast": parseFloat(inputValue),
            "collection_forecast_confirmed_by": confirmedBy,
            "company_code": props.commentRowData.company_code,
            "customer": props.commentRowData.customer,
            "invoice_no": props.commentRowData.document_number,
            "collection_forecast_date_string": selectedDate && format(selectedDate, 'dd-MMM-yyyy'),
            "collection_forecast_date": selectedDate && selectedDate.toISOString(),
            "report_date": dashboardState.selectedDate,
            "bu": props.commentRowData.bu,
            "invoice_no": props.commentRowData.document_number
        }
        props.handleForeCastSubmit(payload)
    }

    const ActionOwnerPopper = function (props) {
        return <Popper {...props} style={{ width: 'fit-content' }} placement="top" />;
    }

    const body = (
        <div className='customModal forecastModal'>
            <div className='header'>
                <h2>{props.commentRowData.customer_name} - Add/View Forecasts</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <div className='modal-content'>
                    <form>
                        <FormControl className="w-50 mb-20 flex">
                            <TextField label="Enter Forecast Value"
                                value={inputValue}
                                placeholder={'Enter Forecast Value'}
                                onChange={handleValueOnChange}
                            />
                        </FormControl>
                        <FormControl className="w-50 mb-30 flex">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="Forecast Date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl className="w-50 mb-20 flex">
                            <Autocomplete
                                // getOptionSelected={(option, value) => option.login_id == props.editData.responsibleparty}
                                className='pipSelect'
                                options={listDropDownArr}
                                size='small'
                                PopperComponent={ActionOwnerPopper}
                                getOptionLabel={(option) => option.full_name}
                                onChange={(event, data) => { handleConfirmedByChange(data) }}
                                value={selectedConfirmedBy}
                                renderInput={(params) => (
                                    <TextField
                                        style={{ fontSize: '16px' }}
                                        {...params}
                                        label="Comfirmed by"
                                        variant="outlined"
                                    />
                                )}
                                renderOption={(option) => <Typography
                                    style={{ fontSize: '13px', width: '250px' }}
                                    className='flex jc-space-between minw-200 ai-center'
                                    noWrap>
                                    <span className='pr-10'>{option.full_name}</span>
                                    <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                </Typography>}
                            />
                        </FormControl>
                    </form>
                    <Grid container justify="flex-end" className='mt-20'>
                        <Button variant="contained" color="primary" className='mr-10' onClick={handleSubmit}>Submit</Button>
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                    </Grid>
                </div>
            }
        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}