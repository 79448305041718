import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import '../../userManagement.scss'
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Grid, Tooltip } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import NewPagination from "../../../common/components/CustomPagination";

import UploadModal from "./uploadModal"
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAccessByMenuKey } from "../../../../utils/userManagement";
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import InProgressComponent from '../../../common/components/InProgress'
import { numberWithCommas } from '../../../../utils/numberUtils'
import { formatDate } from '../../../../utils/dateFormat'
import { format } from 'date-fns/esm';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import {
  downloadExcel,
  pdfFileName,
  mapExcelDataPayment,
  mapExcelHeader,
  mapExcelHeaderComparativeAutomated,
  mapExcelDataComparativeAutomatedValidation,
  mapDataPDF,
  mapPDFDataPLComparative
} from '../../../../utils/exportExcel'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export class PABUploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
            deleteModal: false,
            uploadModal: false,
            fileName: '',
        }
    }

    componentDidMount() {
        this.props.getPABUploadData()
    }

    componentDidUpdate(prevProps) {
        const { ISFileUploadState } = this.props;
        if (prevProps.ISFileUploadState.uploadLoader && !ISFileUploadState.uploadLoader && ISFileUploadState.saveSuccess) {
            this.props.getPABUploadData()
            this.uploadModalCallBack()
        }
    }

    handleValueTransform = (data, keys) => {
        let modifiedData;
        modifiedData = Math.round(data)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
      
        if (keys && keys.includes('delta')) {
            modifiedData = modifiedData == '0' ? 'N/A' : modifiedData
        } else {
            modifiedData = modifiedData == '0' ? '-' : modifiedData
        }

        return modifiedData
    }

    maskFileName = (props) => {
        let ori = props.original;
        let coldata = ori["automation"]['file_name'].split(")")
        coldata.splice(0, 1)
        return coldata
    }

    updateFileName = () => {
        this.setState({
            fileName: "PAB RAW_Data_Template " + format(new Date, "MM-dd-yyyy HH-mm-ss")+".xlsx",
        })
    }

    handleDynamicWidth = (item)=> {
        let widthSize;
        switch(item.key){
            case 'automation.file_name':
                widthSize = 280;
                break;
            case 'net_earnings':
                widthSize = 200;
                break;   
            case 'total_usd_in_file': 
                widthSize = 150;
                break;     
            default:break;    
        }
        return widthSize;
    }

      getQuery = (query) => {
        let b = query
          .slice(0)
          .split("&")
          .map(qStr => qStr.split("="))
          .reduce((acc, inc) => {
            acc[inc[0]] = inc[1];
            return acc;
          }, {});
          return b;
      }
   
    handleLinkToValidate = (rowData, item) => {
        if (rowData && rowData[item.key]) {

            let splitData = rowData[item.key].split('?')[1]
            let QueriedData = this.getQuery(splitData)
            let payload = {
                selectedMonth: QueriedData.month,
                selectedRadio: QueriedData.type,
                tabKey: QueriedData.tabKey
            }
             localStorage.setItem('linkToValidatePAB', JSON.stringify(payload));
    
            window.open(rowData[item.key])
        }
        
    }

    handleSubColumnChangeHeader = (data, key) => {  
        if (data && data.length) {
            return data.map((item, i) => {
               if (item.key.includes('delta')) {
                return {
                    Header: item.label,
                    id: item.key,
                    
                    Cell: (props) => {
                        
                           const isValue = this.handleValueTransform(props.original[item.key], item.key) === "N/A" ? true : false
                           return (
                               <span className={isValue ? "success-color" : "error-color"}>{this.handleValueTransform(props.original[item.key], item.key)}</span>
                           ) 

                          
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        width: (item.label == "xP&A Demo BU P&L") ? '100px': '50px',
                        borderRight: (item.key == "delta_sales") ? '1px solid #423970' : '',
                        borderLeft: (item.key == "total_usd_in_file" || item.key == "total_usd_functional_area_thousand") ? '1px solid #423970' : '',
                    },
                    style: {
                        borderRight: (item.key == "delta_sales") ? '1px solid #423970' : '',
                        borderLeft: (item.key == "total_usd_in_file" || item.key == "total_usd_functional_area_thousand") ? '1px solid #423970' : '',
                    }
                }
                }
                return {
                Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    <span >{props.original ? this.handleValueTransform(props.original[item.key], item.key) : '-'}</span>
                                }
                            </div> 
                        )
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        width: (item.label == "xP&A Demo BU P&L") ? '100px': '50px',
                        borderRight: (item.key == "delta_sales") ? '1px solid #423970' : '',
                        borderLeft: (item.key == "total_usd_in_file" || item.key == "total_usd_functional_area_thousand") ? '1px solid #423970' : '',
                    },
                    style: {
                        borderRight: (item.key == "delta_sales") ? '1px solid #423970' : '',
                        borderLeft: (item.key == "total_usd_in_file" || item.key == "total_usd_functional_area_thousand") ? '1px solid #423970' : '',
                    }
                }
            })
        }
    }
    changeKeysOfHeader = (data) => {
        const { authReducer } = this.props
        const menus = authReducer.menuData
        if (data && data.length) {
            // data.push({ label: "Action", key: "action" })
            return data.map((item) => {
                let obj = {
                    Header: item.label,
                    id: item.key,
                    accessor: item.key,
                    width: this.handleDynamicWidth(item),
                    headerStyle: {                         
                        fontSize: '13px',
                        // fontWeight: 'bold',
                        height: '35px',
                        // paddingLeft: "8px",
                        // lineHeight: '30px'
                    },
                    style: {                      
                        fontSize: '12px',
                        textAlign: 'center',
                        height: '33px',
                        // lineHeight: '40px',
                        paddingLeft: "4px",
                    },
                }
                if (item.key == "action") {
                    obj.Cell = (props) => (
                        <React.Fragment>
                            { getAccessByMenuKey(menus, "auto_payment", "delete") &&
                                <Tooltip title={this.state.tabSelectorValue == 1 ? "Delete Comments Data" : "Delete Payment Data"}>
                                    <DeleteIcon fontSize='small' className='primary-dark' onClick={() => this.handleOpenDialog(props.original)} />
                                </Tooltip>
                            }
                        </React.Fragment>
                    )
                }
                if (item.key == "automation.created_date") {
                    obj.accessor = (_data) => {
                        return formatDate(_data["automation"]['created_date'])
                    }
                }
                if (item.key == "total_usd_in_file") {
                    obj.Cell = (props) => (
                        <React.Fragment>
                            {
                                props.original ? this.handleValueTransform(props.original[item.key], item.key) : '-'

                            }
                        </React.Fragment>
                    )
                }
                if (item.key == "net_earnings") {
                    obj.Cell = (props) => (
                        <React.Fragment>
                            {
                                props.original ? this.handleValueTransform(props.original[item.key], item.key) : '-'
                            }
                        </React.Fragment>
                    )
                }
                if (item.key == 'automation.file_name') {
                    obj.Cell = (props) => (
                        getAccessByMenuKey(menus, "auto_payment", "download") ?
                            <Tooltip title={"Download PAB File"}>
                                <span onClick={() => { this.handleFileDownload(props.original) }} className='primary-dark pointer'>
                                    <u>{this.maskFileName(props)}</u>
                                </span>
                            </Tooltip>
                            : this.maskFileName(props)
                    )
                }
                if (item.key == 'link_to_validate') {
                    obj.Cell = (props) => (
                                <span onClick={() => { this.handleLinkToValidate(props.original, item) }} className='primary-dark pointer'>
                                    <u>{'View'}</u>
                                </span>
                    )
                }
                if (!item.sub_headers) {
                    return obj;
                }
                return {
                    Header: item.label,
                    id: item.key,
                    width: this.handleDynamicWidth(item),
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item.key),
                }
            })
        }
    }

    handleCloseDialog = () => {
        this.setState({ deleteModal: false });
    }

    uploadModalCallBack = () => {
        this.setState({ uploadModal: false });
    }

    saveUploadedData = (data) => {
        this.props.uploadPABFileData(data)
    }

    handleFileDownload = (ori) => {
        this.props.downloadPaymentData({
            path: ori.automation.file_path,
            file_name: ori.automation.file_name,
            id: ori.id,
            type:'pnl'
        })
    }
    handleExcelDownload = () => {
        let data = []
        let header = {};
        let merge = [];
        let headers = []
          let rowData = []
          let nameFile = ""
        // if(this.state.tabSelectorValue == 0){
           headers = this.props.ISFileUploadState.header
           rowData = this.props.ISFileUploadState.data
           const rowHeaderData = this.changeKeysOfHeader(header)
           nameFile ="Payment"
           console.log(headers, rowData, rowHeaderData, "check123123")
        // }else{
        //   headers = this.state.rowHeaderCustomer
        //   rowData = this.state.rowDataCustomer
        //   nameFile ="Comment"
        // }
       
        // let headerExcel = this.changeKeysOfHeader2(headers)
        
        // let headerData = this.props.incomeStatementState.automationHeaderData
        // let rowData =  this.props.incomeStatementState.automationRowData
        // let newValue = this.state.tabSelectorValue
        // let radioSelectorValue = this.state.radioSelectorValue
        
        // if (newValue == 0) {
        //   nameFile ="INCOME STATEMENT"
        // } else if (newValue == 1) {
        //   nameFile ="RECEIVABLES"
        // } else if (newValue == 2) {
        //   nameFile ="P&L raw data validation"
        // } else if (newValue == 3) {
        //   nameFile ="Receivables - DSO Validation"
        // }  else if (newValue == 4) {
        //   nameFile ="New Validation"
        // }
        console.log(this.tabSelectorValue, "testnewData")
        console.log(headers, "testnewHeader")
        data = mapExcelDataPayment(rowData, headers, "key")
        header = mapExcelHeader(headers, "label")
              console.log(data, "testnewData")
              console.log(header, "testnewHeader")
       
      
        let fileName = "AR Upload - " + nameFile + "_" + format(new Date, "MM-dd-yyyy HH-mm-ss")
        // if (this.financialDuration) {
        //     fileName = fileName + " - " + this.financialDuration
        // }
        console.log(data, fileName, header, false, merge)
        // const { currentValue, previousValue } = this.props.incomeStatementState;
        let sheetName = 'Data';
        // if (this.state.radioSelectorValue == 4 || this.state.radioSelectorValue == 5) {
        //   sheetName = `${currentValue} - ${previousValue}`
        // }
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }

    handleBackFCFImpact  = () => {
        window.location.assign('/dashboard/free-cashflow-analytics/free-cashflow')
      }

    
    render() {
        const { deleteModal, uploadModal, fileName } = this.state
        const { ISFileUploadState, authReducer } = this.props;
        const { data, header } = ISFileUploadState
        const rowHeaderData = this.changeKeysOfHeader(header)
        const menus = authReducer.menuData
        let backFCF = localStorage.getItem('show-back-fcf')

        return (
            <>
                {ISFileUploadState.uploadLoader &&
                    <InProgressComponent
                        headerText='Upload in Progress'
                        bodyText='PAB Split data ingestion is in progress, please wait..'
                        showModal="ISFileUploadState.uploadLoader"
                    />
                }
                {/* <Grid container justify="flex-end">
      
                <Tooltip title={'Download to Excel'} className='pointer'>
                    <img
                        src={DownloadExcel}
                        className='pointer'
                        height='30'
                        onClick={(event) => {
                            event.preventDefault();
                            this.handleExcelDownload();
                        }} />
                </Tooltip>
                </Grid> */}
                <div container className={ backFCF == 'true'? 'flex jc-space-between mb-20' : 'flex jc-flex-end mb-20' } >
                                { backFCF == 'true' ?
                            <Grid item xs={12} sm={1} className={'mt-8'} >
                                                <Tooltip title="Back to Improve KPIs and Free Cashflow">
                                                    <Button variant='contained' color="primary" className='back-button' onClick={ (event) => {
                                                    event.preventDefault();
                                                    this.handleBackFCFImpact()
                                                    }}><ChevronLeftIcon /></Button>
                                                </Tooltip>
                                            </Grid> : ''
                        }

                    {getAccessByMenuKey(menus, "auto_payment", "add") &&
                        <div className='flex ai-center'>
                            <Button variant="contained" className='newButton btnBlue btnUpload uppercase' onClick={() => this.setState({ uploadModal: true })} >Upload Data File</Button>
                            <Button variant="contained" className="ml-10 newButton btnBlue btnDownload uppercase" href="/assets/PAB RAW_Data_Template.xlsx" target="_blank"
                                onClick={() => {
                                    this.updateFileName();
                                }}
                                download={fileName}
                            > Download Template</Button>
                        </div>
                    }
                </div>
                {
                    ISFileUploadState.loading ? <div className='text-center'><CircularProgress /></div> :
                        <>
                            {data.length ? <ReactTable
                                data={data}
                                columns={rowHeaderData}
                                defaultPageSize={data.length < 10 ? data.length : 10}
                                className=" xpna-react-table single-line-header -striped -highlight"
                                PaginationComponent={NewPagination}
                                pageSizeOptions={[10, 20, 30]}
                                resizable={true}
                            /> : <h4 className='text-center primary'>There is no Data. Kindly upload a data file.</h4>}
                        </>
                }
                <Dialog
                    open={deleteModal}
                    onClose={this.handleCloseDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We will not be able to recover deleted data
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">Cancel</Button>
                        {/* <Button onClick={this.handleDeletePayment} color="secondary">Delete</Button> */}
                    </DialogActions>
                </Dialog>
                <UploadModal
                    showModal={uploadModal}
                    modalCallBack={this.uploadModalCallBack}
                    saveCallback={this.saveUploadedData}
                />
            </>
        );
    }
}

export default withRouter(
    connect(
        state => {
            return {
                ISFileUploadState: state.userManagementState.ISFileUploadState,
                userState: state.userManagementState.userState,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getPABUploadData(data) {
                    dispatch({
                        type: "FETCH_PAB_FILE_DATA",
                        payload: data,
                    });
                },
                uploadPABFileData(data) {
                    dispatch({
                        type: "UPLOAD_PAB_FILE_DATA",
                        payload: data,
                    });
                },
                deleteISUploadData(data) {
                    dispatch({
                        type: "DELETE_IS_FILE_DATA",
                        payload: data,
                    });
                },
                downloadPaymentData(data) {
                    dispatch({
                        type: "DOWNLOAD_FILE_AUTO",
                        payload: data,
                    });
                },
            };
        }
    )(PABUploadComponent));