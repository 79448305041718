import React from 'react';
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Grid } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Autocomplete, TextField } from '@mui/material';

import MapComponent from './MapBox';

export default class GeoSpatialOverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            newRadioSelectorValue: '0',
            monthValue : '30-Apr-2023',
            monthArr : [],
            radius:100,
            timeSelection:{
                month:{
                    value: 'Apr-2023',
                    options: ['Apr-2023', 'May 2023', 'June 2023', 'July 2023']
                },
                quarter: {
                    value: '',
                    options:[]
                },
                annual:{
                    value: '',
                    options:[]
                }
            }
        }
    }
    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }
    handleMonthChange = (event) => {
        this.setState({monthValue : event.target.value})
        //this.props.fetchChartData({month : event.target.value})
    }
    handleRadiusChange = (event, value) =>{
        this.setState({ radius: value})
    }

    render (){
        const {newRadioSelectorValue, monthValue, monthArr, radius} = this.state;
        const circleRadius = radius;
        return(
            <React.Fragment>

                <Grid container className="pb-15 time-filter-section">
                    <Grid item xs={3} sm={3} className='maxw-200 mr-15'>
                        {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                            <InputLabel>Month</InputLabel>
                            <Select
                                label="Month"
                                disabled
                            ></Select>
                            
                        </FormControl> */}
                         <Autocomplete
                            disablePortal
                            className='maxw-200'
                            id="combo-box-demo"
                            size="small"
                            options={[]}
                            disabled={true}
                            renderInput={(params) => <TextField {...params} label="Month" />}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} className='maxw-200 mr-15'>
                        {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                            <InputLabel>Quarter</InputLabel>
                            <Select
                                label="Quarter"
                                disabled
                            >
                            </Select>
                        </FormControl> */}
                         <Autocomplete
                            disablePortal
                            className='maxw-200'
                            id="combo-box-demo"
                            size="small"
                            options={[]}
                            disabled={true}
                            renderInput={(params) => <TextField {...params} label="Quarter" />}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} className='maxw-200 mr-15'>
                        {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                            <InputLabel>FY</InputLabel>
                            <Select
                                label="FY"
                                disabled
                            >
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            disablePortal
                            className='maxw-200'
                            id="combo-box-demo"
                            size="small"
                            options={[]}
                            disabled={true}
                            renderInput={(params) => <TextField {...params} label="FY" />}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} className='maxw-200'>
                        {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                            <InputLabel>Radius</InputLabel>
                            <Select
                                label="Radius"
                                disabled
                            >
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            disablePortal
                            className='maxw-200'
                            id="combo-box-demo"
                            size="small"
                            value={circleRadius}
                            getOptionLabel = {(option)=> (`${option}`)}
                            options={[100,150,200,250,300,350,400]}
                            onChange={this.handleRadiusChange}
                            renderInput={(params) => <TextField {...params} label="Radius(km)" />}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                    <FormControl component="fieldset">
                        <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                            <div className="group">
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="All" />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="By Region" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="By State" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <div className='India-map-container' style={{width:'80%', height:'800px'}}>
                <MapComponent type={newRadioSelectorValue} radius={circleRadius} />
                </div>

            </React.Fragment>
        )
    }
}


