import axios from 'axios';
import { CONFIG } from '../../../config'

export async function fetchCementDataService(payload) {
    let url = `${CONFIG.api_base_url}shreeCement/getpnldata`
  if (payload.export && payload.sendEmail != true) {
            url += "?export=true"
            return axios({
                url: url,
                method: "GET",
                responseType: "blob",
                data: payload,
            })
            .then((response) => {
                var x = response.data;
                var u = URL.createObjectURL(x);
                var a = document.createElement("a");
                a.href = u;
                a.download = response.headers["content-disposition"].split("=")[1];
                a.click();
                window.URL.revokeObjectURL(u);
                return response;
            })
            .catch((err) => err.response);
    }else if(payload.sendEmail){
        url += "?export=true"
        if(payload.sendEmail){
            url += "&send_email=true"
            url += "&to=" + payload.to.join(",")
            url += "&cc=" + payload.cc.join(",")
        }
    }

  return axios.get(url).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchFixedCostCementDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getfixedcostdata?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
        .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getfixedcostdata`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchSalesLogisticCementDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getsalesandlogisticdata?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
        .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getsalesandlogisticdata`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchCementCostDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getcementcostdata?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
        .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getcementcostdata`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchClinkerCementDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getclinkercostdata?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
        .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getclinkercostdata`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchActualForecastFilterService() {
  
    return axios.get(`${CONFIG.api_base_url}shreeCement/getfilterdata`).then(
        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
  }

export async function fetchActualForecastCementDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getactualdata?month_filter=${payload.month}?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
        .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getactualdata?month_filter=${payload.month}`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchDashboardDataService(payload) {
  if (payload.export) {
    return axios({
        url: `${CONFIG.api_base_url}shreeCement/getdashboarddata?export=true`,
        method: "GET",
        responseType: "blob",
        data: payload,
    })
         .then((response) => {
            var x = response.data;
            var u = URL.createObjectURL(x);
            var a = document.createElement("a");
            a.href = u;
            a.download = response.headers["content-disposition"].split("=")[1];
            a.click();
            window.URL.revokeObjectURL(u);
            return response;
        })
        .catch((err) => err.response);
}

  return axios.get(`${CONFIG.api_base_url}shreeCement/getdashboarddata`).then(
      response => {
          return response;
      }
  ).catch(err => {

      return err.response
  });
}

export async function fetchDashboardChartDataService(payload) {
    const { month} = payload;
    let url = `${CONFIG.api_base_url}shreeCement/getchartdata?month_filter=${month}`;

    return axios.get(url).then(
        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
  }

  export async function fetchSimulationDataService(payload) {
    if (payload.export) {
      return axios({
          url: `${CONFIG.api_base_url}shreeCement/getsimulationdata?month_filter=${payload.month}&export=true`,
          method: "GET",
          responseType: "blob",
          data: payload,
      })
          .then((response) => {
              var x = response.data;
              var u = URL.createObjectURL(x);
              var a = document.createElement("a");
              a.href = u;
              a.download = response.headers["content-disposition"].split("=")[1];
              a.click();
              window.URL.revokeObjectURL(u);
              return response;
          })
          .catch((err) => err.response);
  }
  
    return axios.get(`${CONFIG.api_base_url}shreeCement/getsimulationdata?month_filter=${payload.month}`).then(
        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
  }

  
export async function saveSimulationDataService(payload) {
    return axios.post(`${CONFIG.api_base_url}shreeCement/editsimulationdata`, payload)
        .then(response => response)
        .catch(err => err.response)
}


export async function fetchCompareFilterOptionsEbitdaService(payload) {

    return axios.get(`${CONFIG.api_base_url}shreeCement/pnl/compare/filter`).then(
        response => {
            return response;
        }
    ).catch(err => {
        return err.response
    });
}
  
  
export async function fetchCompareDataForPNLEbitdaService(payload) {
  
    if (payload.export) {
     return axios({
        url: `${CONFIG.api_base_url}shreeCement/pnl/compare`,
        method: 'POST',
        responseType: 'blob',
        data: payload
    })
        .then(
            response => {
              var x = response.data;
              console.log(response, x)
              var u = URL.createObjectURL(x)
              var a = document.createElement("a");
              a.href = u;
              a.download = (response.headers['content-disposition']).split('=')[1];
              a.click();
              window.URL.revokeObjectURL(u);
                return response
            }
        )
        .catch(err => err.response)
    }
    return axios.post(`${CONFIG.api_base_url}shreeCement/pnl/compare`, payload).then(

        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
}

  export async function fetchDrilldownDataService(payload) {
  
    if (payload.export) {
     return axios({
        url: `${CONFIG.api_base_url}shreeCement/getdrilldownsimulation`,
        method: 'POST',
        responseType: 'blob',
        data: payload
    })
        .then(
            response => {
              var x = response.data;
              console.log(response, x)
              var u = URL.createObjectURL(x)
              var a = document.createElement("a");
              a.href = u;
              a.download = (response.headers['content-disposition']).split('=')[1];
              a.click();
              window.URL.revokeObjectURL(u);
                return response
            }
        )
        .catch(err => err.response)
    }
    return axios.post(`${CONFIG.api_base_url}shreeCement/getdrilldownsimulation`, payload).then(
        response => {
            return response;
        }
    ).catch(err => {
  
        return err.response
    });
}

export async function getEmailTemplateEbitdaPnlBudgetService(payload) {
    return axios.get(`${CONFIG.api_base_url}shreeCement/email-template-getebitdapnlbudget`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function getEmailTemplateEbitdaSearchCompareService(payload) {
    return axios.get(`${CONFIG.api_base_url}shreeCement/email-template-getebitdasearchcompare`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function saveEmailTemplateEbitdaService(payload) {
    return axios.post(`${CONFIG.api_base_url}shreeCement/email-template-updateebitda`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function fetchDropdownMasterFilterEbitdaService(payload) {
    return axios.get(`${CONFIG.api_base_url}shreeCement/getdropdownmaster`).then(
        response => {
            return response;
        }
    ).catch(err => {
        return err.response
    });
}

export async function fetchSaveBPSimulationEbitdaService(payload) {
    return axios.post(`${CONFIG.api_base_url}shreeCement/savebpsimulation`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function fetchDropdownPlanningService(payload) {
    return axios.get(`${CONFIG.api_base_url}shreeCement/getdropdownplanning`).then(
        response => {
            return response;
        }
    ).catch(err => {
        return err.response
    });
}
