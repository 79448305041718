import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DownloadExcel from "../../../../../images/download_excel.svg";
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import CommentBox from '../../../../ECFM/ImprovementPlan/comment';
import Icon from "@material-ui/core/Icon";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PLComponent from './p&lComponent'
import ActualForecast from './ActualForecast'
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import SendEmailModal from '../../SendEmail';
import ViewEmailModal from '../../ViewEmail';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class CashflowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.tabMap = {
            0: 'BU-1',
            1: 'BU-2',
            2: 'BU-3',
            3: 'BU-5'
        }
        this.state = {
            newRadioSelectorValue: '0',
            topRadioSelectorValue: '1',
            tabValue: 1,
            openChat: false,
            showViewEmail : false,
            showSendEmail : false,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    openViewEmail = () => {
        this.setState({ showViewEmail: true })
        this.props.getEmailTemplateEbitdaPnlBudget({})
    }
    handleViewEmailClose = () => {
        this.setState({showViewEmail : false})
    }
    handleSendEmailClose = () => {
        this.setState({showSendEmail : false})
    }

    handleSendEmail = (payload) =>{
        this.props.fetchCementData({ export: true, sendEmail : true, to : payload.to, cc : payload.cc })
    }

    componentDidMount() {
        // console.log( this.props, "props")
        this.props.fetchActualFilterData()
        this.props.fetchCementData({})
    }

    handleExcelDownload = () => {
        this.props.fetchCementData({ export: true })
    }

    handleExternalTabChange = (event) => {
        this.setState({
            reportTabValue: event.target.value
        })
    }

    handleNewRadioChange = (event) => {
        this.setState({
            newRadioSelectorValue: event.target.value
        })
    }
    handleOpen = () => {
        this.setState({ openChat: true })
    };
    handleClose = () => {
        this.setState({ openChat: false })
    };


    handleTabChange = (event, value) => {
        this.setState({
            tabValue: value
        })
    }

    handleImprovementPlanClick = (data) => {
        if (data == 'view') {
            this.props.history.push('/dashboard/initiatives/view')
        } else {
            this.props.history.push('/dashboard/initiatives/create')
        }
    }

    render() {

        const { newRadioSelectorValue, tabValue, showViewEmail, showSendEmail, editorMsgBodyEmailTemplate } = this.state
        const { saveEmailTemplate, emailTemplateData, plCementState, handShowCompare, externalState } = this.props

        return (
            <React.Fragment>
                <Grid container xs={12} sm={12} className="pb-15 buttons-section">
                    <Grid item xs={12} sm={12} className="pr-20 pt-10 download-section" >
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            className='mb-10'
                            onChange={this.handleTabChange}
                        >
                            <Tab label="Actual/Forecast" className="list-nested" id='nav-tab-0' aria-controls='nav-tabpanel-0' />
                            <Tab label="Budget" className="list-nested" id='nav-tab-1' aria-controls='nav-tabpanel-1' />
                            <Tab label="Revised Budget" className="list-nested" id='nav-tab-2' aria-controls='nav-tabpanel-2' />

                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                    <div className="group">
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="All" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Plant" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Sales Region" />
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="Product" />
                                        <FormControlLabel value="5" control={<Radio color="primary" />} label="Dealer" />
                                        <FormControlLabel value="6" control={<Radio color="primary" />} label="Channel" />
                                        <FormControlLabel value="7" control={<Radio color="primary" />} label="Cost Center" />
                                        <FormControlLabel value="8" control={<Radio color="primary" />} label="Customer" />
                                    </div>
                                </RadioGroup>
                            <ActualForecast handleOpen={this.props.chatClick} handShowCompare={handShowCompare} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Grid container xs={12} sm={12} className="pb-15 buttons-section">
                                <Grid item xs={7} sm={7} className="pr-20 pt-10 download-section" >
                                    <FormControl component="fieldset">
                                        <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                            <div className="group">
                                                <FormControlLabel value="0" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="2" control={<Radio color="primary" />} label="Plant" />
                                                <FormControlLabel value="3" control={<Radio color="primary" />} label="Sales Region" />
                                                <FormControlLabel value="4" control={<Radio color="primary" />} label="Product" />
                                                <FormControlLabel value="5" control={<Radio color="primary" />} label="Dealer" />
                                                <FormControlLabel value="6" control={<Radio color="primary" />} label="Channel" />
                                                <FormControlLabel value="7" control={<Radio color="primary" />} label="Cost Center" />
                                                <FormControlLabel value="8" control={<Radio color="primary" />} label="Customer" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5} sm={5} className="pt-10 download-section" >
                                    <div className='text-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                className="mr-10 newButton btnBlue btnEmail uppercase"
                                                endIcon={<Icon>visibility</Icon>}
                                                onClick={this.openViewEmail}
                                                // onClick={()=>{ this.setState({showViewEmail : true}) } }
                                            >
                                                View Email
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                className="mr-10 newButton btnBlue btnEmail uppercase"
                                                endIcon={<Icon>send</Icon>}
                                                onClick={()=>{ this.setState({showSendEmail : true}) } }
                                            >
                                                Send Email
                                            </Button>
                                        <Button variant='contained' className='run-button mr-10 newButton btnBlue btnCompare' onClick={handShowCompare}>Search and Compare</Button>
                                        <Tooltip title="View Improvement Plan">

                                            <Icon
                                                className="fs-20 primary mr-10"
                                                color={
                                                    "inherit"
                                                }
                                                onClick={() => { this.handleImprovementPlanClick('view') }}
                                            >
                                                remove_red_eye
                                            </Icon>
                                        </Tooltip>
                                        <Tooltip title={
                                            'Add Improvement Plan'
                                        }>
                                            <Icon className="fs-20 text-primary mr-10" color={
                                                "inherit"}
                                                onClick={() => { this.handleImprovementPlanClick('add') }}
                                            >add_circle_outline</Icon>
                                        </Tooltip>

                                        <Tooltip title={"Collaborate"}>
                                            <Icon
                                                className="fs-20 primary mr-10"
                                                color={"inherit"}
                                                disabled
                                                onClick={() => {
                                                    this.props.chatClick();
                                                }
                                                }
                                            >
                                                chat_bubble_filled
                                            </Icon>
                                        </Tooltip>
                                        <Tooltip title={'Download to Excel'}>
                                            <img
                                                src={DownloadExcel}
                                                className='pointer mr-10'
                                                height='30'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.handleExcelDownload();
                                                }} />
                                        </Tooltip>
                                        <Tooltip title={'Download to PDF'}>
                                            <PictureAsPdfIcon fontSize="large"
                                                height='30'
                                                className="pointer "
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    //   this.printDocument();
                                                }} />
                                        </Tooltip>
                                    </div>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12} className="pt-0">
                                <PLComponent plCementState={plCementState} handleOpen={this.handleOpen} />

                            </Grid>

                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>

                            {/* <PLReportComponent /> */}
                        </TabPanel>
                    </Grid>

                </Grid>


                <Modal
                    className="collaborate-modal"
                    open={this.state.openChat}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div style={style}>
                        <CommentBox />
                    </div>
                </Modal>

                <SendEmailModal
                    showModal={showSendEmail}
                    modalCallBack={this.handleSendEmailClose}
                    header={ "Choose Recipient" }
                    sendEmail={this.handleSendEmail}
                    users={externalState.allUser}
                    plCementState={plCementState}
                />

                <ViewEmailModal
                    viewEmail={showViewEmail}
                    modalCallBack={this.handleViewEmailClose}
                    header={ "View Email" }
                    emailTemplateData={emailTemplateData}
                    emailType={ "ebitda_pnl_budget_email" }
                    saveEmailTemplate={saveEmailTemplate}
                />

            </React.Fragment>
        );
    }
}

export default
    withRouter(connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                authReducer: state.authReducer,
                plCementState: state.shreeCementState.cementData,
                externalState: state.dashboardState.receivableData.ageingData.external
            }
        },
        dispatch => {
            return {
                fetchCementData(data) {
                    dispatch({
                        type: "FETCH_CEMENT_DATA",
                        payload: data
                    });
                },
                fetchActualFilterData() {
                    dispatch({
                        type: "FETCH_ACTUAL_FORECAST_FILTER_DATA"
                    });
                }
            };
        }
    )(CashflowComponent));

