import React from 'react';
import { useTable } from 'react-table';
import './AOPForecastTableComponent.scss';
import {  numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewReactPureTable from '../TableComponent/pureNewTable';
import InvoiceDetailModal from '../../Shared/InvoiceDetail';

class AOPForecastTableComponent extends React.Component {

    constructor(props) {
		super(props);
		this.showDrilldownHeaderValue = '';
		this.chatDataCollection = {};
		this.sortList = {};
		this.sortParam = null;
		this.levelMap = {
			PlTotal: 'P&L Total',
			PlGroup: 'P& L Group',
			GroupName2: 'Group Name2',
			GroupName: 'Group Name',
			GroupName3: 'Group Name3',
			AccountName: 'Account Name',
			CostCenter: 'Cost Centre2',
		};
		this.state = {
			loading: true,
			showModal: false,
			manualAmountValue: '',
			refMenuAnchorEl: null,
			plantDetailState: {},
			showInvoiceDetailModal: false,
			filterSelectorValue: [],
			arrayDataSelected: [],
			showLoader: false,
			showInvoiceLoader: false,
			manualClickData: {},
			invoiceObject: null,
			isExpandable: false,
			drilldownGroup: '',
			commentModal: false,
			commentValue: '',
			invoiceDetailStateNew: '',
			selectedDrilldown: null,
			lastPayloadSent: null,
			isInvoiceDetailModal: false,
			refreshOnModalClose: false,
			isThreeLevelDrilldown: false
		};
		this.excelData = [];
	}

    invoiceModalCallBack = () => {
		if (this.state.refreshOnModalClose) {
			this.props.fetchMainData();
		}

		this.setState({
			arrayDataSelected: [],
			showInvoiceDetailModal: false,
			isInvoiceDetailModal: false,
			refreshOnModalClose: false,
			isThreeLevelDrilldown: false
		});
	};
    componentDidUpdate(prevProps) {
        // console.log(prevProps.incomeStatementState, "cekPrevProps")
        // console.log(this.props.incomeStatementState, "cekThisProps")
		if (
			prevProps.incomeStatementState.invoiceLoading &&
			!this.props.incomeStatementState.invoiceLoading
		) {
			this.setState({
				invoiceDetailStateNew:
					this.props.incomeStatementState.invoiceDetailState,
				showInvoiceDetailModal: true,
				showLoader: false,
				showInvoiceLoader: false
			});
		}
    }
	modifyNewColumnData = (columnData, operations) => {

        let modifiedData;
        let stringVersion = String(columnData);
        if (stringVersion.includes('.') && columnData && columnData.toFixed) {
            modifiedData = columnData.toFixed(2)
        }else {
            modifiedData = columnData
        }
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (operations == "percentage") {
            modifiedData += "%"
        }

        return modifiedData
    }

	handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }

	newChangeHeaderKeysLevel2 = (data, parentData) => {
        if (data && data.length > 0) {
            return data.map((item, i) => {
                const tempItemData = {
                    Header: item.label,
                    id: item.key + '_' + parentData.key,
                    metaData: item.metadata,
                    headerStyle: {
                        overflow: 'visible',
                    },
                };

                const isNested = item.hasOwnProperty('sub_headers');

                if (isNested) {
                    tempItemData.columns = this.newChangeHeaderKeysLevel3(item.sub_headers, parentData, item);
                } else {

                    tempItemData.Cell = (props) => {
                        const { row } = props;
                        if (parentData.key.includes('comment')) {
							const commentData = row.original && 
							row.original.sub_column && 
							row.original.sub_column[parentData.key] && 
							row.original.sub_column[parentData.key]['sub_column'] && 
							row.original.sub_column[parentData.key]['sub_column'][item.key]
								return (
									<div 
									style={{'color': '#015ABB' }}
									onClick={() => {
                                        this.handleCommentShowClick(row.original, parentData, item)
                                	}}>
									<Icon
                                        color={'#015ABB'}
                                        style={{'color': '#015ABB !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#015ABB'}
                                        style={{'color': '#015ABB !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
								</div>
									
								);
							
						}
                        
                            return <span>{row.original ?  row.original.sub_column[parentData.key] ? row.original.sub_column[parentData.key]['sub_column'][item.key] : '' : ''}</span>
                        // }
                    }
                }

                return tempItemData;
            });
        } else return [];
    };
    handleThreeLevelDrilldownClick = (rowData, parentData, subParentData, item ) => {
		// console.log(rowData, parentData, subParentData, item)
		let payload = {
			"origination": "true", //follow main grid param logic
			"mpr": "true", //follow main grid param logic
			"grid_header_value": parentData.label, // header.label
			"sub_grid_header_value": subParentData.label, // header.label
			"sub_sub_grid_header_value": item.label, // header.label
			"level_value": rowData.data_value == "CA" ? "CAN" : rowData.data_value , //data_value
			"parent": rowData.parent, //parent
			"parent_one": rowData.parent1 //parent1

		}

		this.props.handlePropsThreeLevelInvoiceClick(payload);
		this.showDrilldownHeaderValue = `${payload.grid_header_value}`

		if (payload.sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_grid_header_value}`
		}
		if (payload.sub_sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_sub_grid_header_value}`
		}
		if (payload.level_value) {
			this.showDrilldownHeaderValue += ` - ${payload.level_value}`
		}
		// console.log(payload, "CheckDrillDown" )
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showInvoiceLoader: true,
			isThreeLevelDrilldown: true
		});
	}
    newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
        if (data && data.length > 0) {
            return data.map((item, i) => {
                const tempItemData = {
                    Header: item.label,
                    id: item.key + '_' + parentData.key + '_' + subParentData.key,
                    metaData: item.metadata,
                    headerStyle: {
                        overflow: 'visible',
                    },
                };

                tempItemData.Cell = (props) => {
                    const { row } = props;
                    let showPercentage = this.handleAppendPercentage(row.original);
                    let myClassName = showPercentage ? 'percentageRowData' : ''
                    let showBorder = false
					let showDrillDown = false
					if ( subParentData?.key == 'total' && item.key == 'total'){
						showBorder = true
					}

                    if (!row.original.disable_drill_down) {
						showDrillDown = true;
					}
                        return (
                            <div 
                            className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{borderRight: showBorder && '1px solid #423970', lineHeight: '40px'}}
                            onClick={()=> {
                                if (showDrillDown){
                                    this.handleThreeLevelDrilldownClick(row.original, parentData, subParentData, item)
                                }
                            }}
                            >
                                    {
                                        <span className={myClassName}>{row.original && row.original.sub_column && row.original.sub_column[parentData.key]['sub_column'][subParentData.key] ? this.modifyNewColumnData(row.original.sub_column[parentData.key]['sub_column'][subParentData.key][item.key], row.original["operations"])  : '-'}</span>
                                    }
                                </div>
                        )
                    // }
                }


                return tempItemData;
            });
        } else return [];
    };

    newChangeKeysOfHeader = (data) => {
        if (data && data.length > 0) {
            return data.map((item, i) => {
                const tempItemData = {
                    Header: item.label,
                    id: item.key + this.globalIndex,
                    metaData: item.metadata,
                    headerStyle: {
                        overflow: 'visible',
                    },
                };

                this.globalIndex += 1;

                const isNested = item.hasOwnProperty('sub_headers');

                if (isNested) {
                    tempItemData.columns = this.newChangeHeaderKeysLevel2(item.sub_headers, item);
                } else {
                    if (item.key === "data_field") {
                        tempItemData.sticky = "left";
                    }
                    tempItemData.Cell = (props) => {
                        const { row } = props;
                        if (item.key === "data_field") {
                            
                            return (
                                row.canExpand ?
                            <span
                                {...row.getToggleRowExpandedProps({
                                    style: {
                                        paddingLeft: `${row.depth * 2}rem`
                                    }
                                })}
                                
                            >
                                <span className={ row.isExpanded ? "rt-expanded-class" : "rt-collapsed-class" }></span>
                                {row.original && row.original[item.key] ? row.original[item.key] : ''}
                            </span> : 
                            <span> {row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
                            )


                        } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
                            return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
                        } else {
                            return <span>{row && row.original && row.original[item.key] ? this.modifyNewColumnData(row.original[item.key],row.original['operations']) : '-'}</span>
                        }
                    }
                }

                return tempItemData;
            });
        } else return [];
    };

    newChangeKeysOfRow = (data) => {

        // return data
        if (data && data.length > 0) {
            return data.map((item, i) => {
                if (item.sub_rows) {
                    item['subRows'] = item.sub_rows

                    this.newChangeKeysOfRow(item.sub_rows)

                }else {
                    item['subRows'] = []

                }
                return item
            });
        } else return [];
    }

	render() {
		const { pivotHeaderData, pivotRowData, loading} = this.props
		const columnHeaderData = this.newChangeKeysOfHeader(pivotHeaderData);
		const columnRowData = this.newChangeKeysOfRow(pivotRowData);
        const { showInvoiceDetailModal, showLoader,showInvoiceLoader, selectedCommentRowData, selectedCommentItem, selectedCommentParent } = this.state;
        let { isExpandable, isInvoiceDetailModal, invoiceDetailStateNew } = this.state;
		return (
            <>
        	{showInvoiceLoader && <div className='progressfullpage'>
						<CircularProgress />
					</div>}
                    <NewReactPureTable
			pivotRowData={columnRowData}
			loading={loading}
			pivotHeaderData={columnHeaderData}

		/>
        {showInvoiceDetailModal && (
					<InvoiceDetailModal
						getInvoiceDetailGL={this.handleGLInvoiceSorting}
						showModal={showInvoiceDetailModal}
						modalCallBack={this.invoiceModalCallBack}
						invoiceDetailState={invoiceDetailStateNew}
						// invoiceDetailStateFilter={invoiceDetailStateFilter}
						// invoiceLoading={invoiceLoading}
						showDrilldownHeaderValue={this.showDrilldownHeaderValue}
						handleFilterChange={this.handleInvoiceFilterChange}
						handleInvoiceSorting={this.handleInvoiceSorting}
						showGlDrilldownFilters={false}
						showAmountFilters={false}
						// isExpandable={isExpandable}
						// isInvoiceDetailModal={isInvoiceDetailModal}
						// header={header}
						// sub_header={sub_header}
						handleCountryChanges={this.handleCountryChanges}
						handleClassificationChanges={this.handleClassificationChanges}
						handleSaveChanges={this.handleSaveChanges}
						radioSelectorReconValue={this.props.radioSelectorReconValue}
						// invoiceLoadingGL={invoiceLoadingGL}
						curMonth={this.props.curMonth}
						monthSelected={this.props.monthSelected}
						financialDuration={this.props.financialDuration}
						toggleCommentModal={this.toggleCommentModal}
						NewChart={this.props.NewChart}
					/>
				)}
        </>);
	}
}

export default AOPForecastTableComponent;
