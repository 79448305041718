import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas } from '../../../../utils/numberUtils'
import { formatDate } from '../../../../utils/dateFormat'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from "../../Shared/InvoiceDetail";
import FunctionDrilldownDetailModal from "../../Shared/InvoiceDetail/FunctionDrilldown";

import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import CommentModal from "../../../Receivable/Ageing/ExternalComponent/CommentModal";
import ReactTableComponent from './pureTable'

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.sortList = {}
        this.chatDataCollection = {}
        this.levelMap = {
            PlTotal: 'P&L Total',
            PlGroup: 'P& L Group',
            GroupName2: 'Group Name2',
            GroupName: 'Group Name',
            GroupName3: 'Group Name3',
            AccountName: 'Account Name',
            CostCenter: 'Cost Centre2'
        }
        this.state = {
            showCommentModal: false,
            loading: true,
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            showFunctionalDrilldownModal: false,
            filterSelectorValue: [],
            showLoader: false,
            invoiceObject: null,
            dateSelected: "",
            drillDownData: {},
            renderExcel: true,
        }
        this.excelData = []
    }

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleExcelDataChange = (data, header) => {
        console.log(data, header, "checkChanges")
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }

    addExcelDataMain = (data, level, idx) => {
        // idx += 1;
        // let excelData = this.excelData;
        // let curLevel = excelData[idx] && excelData[idx].custom_level;
        // let prevLevel = excelData[idx - 1] && excelData[idx - 1].custom_level;
        // // console.log(curLevel, prevLevel,"level")
        // if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
        //     excelData = this.removeExcelDataMain(data, curLevel)
        // } else {
        //     level += 1
        //     data && data.forEach((x, i) => { //inject data after the parent
        //         x.data_field = this.addSpaceLevel(x.data_field, level)
        //         // console.log(x.custom_level)
        //         excelData.splice(idx + i, 0, x)
        //     })
        // }
        // this.excelData = excelData
        // console.log(data, this.excelData, "dataax1cccc")
        idx += 1;
        let excelData = this.excelData;
        console.log(data, "level")
        if (data) {
            console.log(data[0], "levelLength")
            console.log(data[0].parents, "levelLength")
            let lenthParent = data[0].parents.length
            let parents = data[0].parents[lenthParent - 1]
            let parents2 = ""
            if ((lenthParent - 1) != 0 ){

                 parents2 = data[0].parents[0]
                
            }
           
            if (parents == "MSB") {
                parents = "BU 1"
            }
            // console.log(x.data_field.trimStart(), "testLevel01")
            console.log(parents,parents2, "testLevel0")
            
            let indexParent = 0
            // if (data[0].custom_level <= 1 ){
            // indexParent = excelData.findIndex(x => x.budget_group === parents)
            // }else{
                if(parents2 != ""){
                    indexParent = excelData.findIndex(x => x.data_field.trimStart() === parents && x.parents[0]  === parents2 )
            
                }else{

                    indexParent = excelData.findIndex(x => x.data_field.trimStart() === parents )
                }
            // }

            let curLevel = excelData[indexParent + 1] && excelData[indexParent + 1].custom_level;
            console.log(excelData, "testLevel1")
            console.log(indexParent, "testLevel2")
            console.log(excelData[indexParent], "testLevel3")
            let prevLevel = excelData[indexParent] && excelData[indexParent].custom_level;
            console.log(curLevel, "testLevel4")
            console.log(prevLevel, "testLevel5")
            if (curLevel > prevLevel) { //if current data has children, remove it (close row expand)
                console.log("masukRemoveLevel")
                idx = indexParent + 1
                excelData = this.removeExcelDataMain(data, indexParent)
            } else {
                level += 1
                let idxori = idx
                data && data.forEach((x, i) => { //inject data after the parent

                    i += 1
                    x.data_field = this.addSpaceLevel(x.data_field, level)
                    idx = indexParent + i
                    excelData.splice(idx, 0, x)
                })
            }
            this.excelData = excelData
            console.log(this.excelData, "value level")
        }
    }

    removeExcelDataMain = (data, idx) => {
        let excelData = this.excelData;
        excelData = excelData.filter((val, i) => {
            let parent
            // return !(val.custom_level >= idx && val[val.levels_field[0]] == data[0][data[0].levels_field[0]])
            if( val.parents){
                parent = val.parents[excelData[idx].custom_level]
            }

            if(parent == "MSB"){
                parent = "BU 1"
            }
              console.log(parent, "testloopingParent")
              console.log( excelData[idx].data_field.trimStart(), "testlooping")
              console.log(excelData[idx].custom_level, "testlooping")
            return !(val.custom_level >= excelData[idx].custom_level && parent == excelData[idx].data_field.trimStart())
        })
        console.log(excelData, "dataax2cccc")
        return excelData
    }

    addExcelData = (dataval, header) => {
        let excelData = this.excelData;
        let data = JSON.parse(JSON.stringify(dataval))
        data.sub_rows = this.checkLevel(data.sub_rows, data.custom_level);
        
        if (excelData.length == 0) {
            this.excelData = data;
        } else {
            switch (data.custom_level) {
                case 0:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 1:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 2:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val[data.levels_field[2]] == data[data.levels_field[2]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
                case 3:
                    var idx = excelData.findIndex((val) => (
                        val[data.levels_field[1]] == data[data.levels_field[1]] &&
                        val[data.levels_field[0]] == data[data.levels_field[0]] &&
                        val[data.levels_field[2]] == data[data.levels_field[2]] &&
                        val[data.levels_field[3]] == data[data.levels_field[3]] &&
                        val.custom_level == data.custom_level
                    )) //find the parent index
                    this.addExcelDataMain(data.sub_rows, data.custom_level, idx)
                    break;
            }
        }
        this.handleExcelDataChange(this.excelData, header)
    }

    handleTooltipData = (props) => {
        let { custom_level, levels } = props.original;
        let tooltipData = ''
        if (levels && levels.length) {
            tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
        }
        return tooltipData;
    }

    handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={
                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>
                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }
        return tooltipData;
    }

    handleChatChangeFn = (event, rowData) => {
        if (event.target && event.target.value) {
            this.chatDataCollection[rowData.data_field] = event.target.value;
        }
    }

    handleChatSendFn = (rowData) => {
        console.log(rowData, 'ppppppppp')
        let sendChatObject = {}
        if (document.getElementById(`outlined-basic-${rowData.original.data_field}`).value) {
            let newRowId = ''
            if (rowData.original.parents && rowData.original.parents.length) {
                rowData.original.parents.forEach(item => {
                    newRowId += item.toLowerCase() + '/'
                })
            }
            newRowId += rowData.original.data_field && rowData.original.data_field.toLowerCase()
            console.log('>>>>>>>', newRowId)
            sendChatObject = {
                comment: document.getElementById(`outlined-basic-${rowData.original.data_field}`).value,
                commentRowId: newRowId,
                // filterValues: this.props.radioFilterValue,
                view: this.props.radioFilterValue,
                reportDate: rowData.original.current_month
            }
            this.props.handleCommentSendFn(sendChatObject)
            this.chatDataCollection[rowData.original.data_field] = ''
            document.getElementById(`outlined-basic-${rowData.original.data_field}`).value = ''

        }
    }

    handleNestedColumnClick = (rowData, clickedValue, columnHeadValue) => {
        console.log(rowData, 'ppppppppp')
        console.log(clickedValue, 'ppppppppp')
        console.log(this.props, "testEvent")
        let payload={}
        if (this.props.comparativeSelectorValue == 3){
            if (this.props.isDetailedView == true){
                payload= {
                    report_date:clickedValue,
                    budget_group: rowData.budget_group ? rowData.budget_group : (this.props.radioFilterValue == "Summary" ? rowData.functional_area : this.props.radioFilterValue),
                    bu: rowData.bu,
                    type: 'monthly',
                    expense_key: rowData.expense_key_acct,
                    sub_budget_group: rowData.sub_budget_group,
                    account_name: rowData.account_name
                }
            }else{
                payload= {
                    budget_group: rowData.budget_group ? rowData.budget_group :(this.props.radioFilterValue == "Summary" ? rowData.functional_area: this.props.radioFilterValue),
                    bu: rowData.bu,
                    type: 'country',
                    country:columnHeadValue,
                      report_date: this.props.selectedDateFilter,
                    expense_key: rowData.expense_key_acct,
                    sub_budget_group: rowData.sub_budget_group,
                    account_name: rowData.account_name
                }
            }
           
        }else{
             payload= {
                report_date:clickedValue,
                budget_group: this.props.radioFilterValue == "Summary" ? rowData.functional_area : this.props.radioFilterValue,
                bu: rowData.bu,
                type: 'monthly',
                expense_key: rowData.expense_key_acct,
                sub_budget_group: rowData.sub_budget_group,
                account_name: rowData.account_name
            }
    
        }
      
        this.setState({
            drillDownData: payload,
            dateSelected : clickedValue

        })
        this.showDrilldownHeaderValue = `${rowData.expense_key_acct} (${rowData.account_name}) - USD ${clickedValue}`

        this.props.handleNestedColumnCallback(payload)
    }

    handleDrilldownExcelDownload = () => {
        const {drillDownData, dateSelected} = this.state;
        let payload={}
        if (this.props.comparativeSelectorValue == 3){
            if (this.props.isDetailedView == true){
                payload= {
                    ...drillDownData,
                type: 'monthly',
                report_date: dateSelected,
                export: true
                 }
            }else{
                payload= {
                    ...drillDownData,
                    type: 'country',
                    report_date: dateSelected,
                    export: true
                 }
            }
          
        }else{
            payload= {
                ...drillDownData,
                type: 'monthly',
                report_date: dateSelected,
                export: true
             }
        }
       
        this.props.handleDownloadNestedColumnCallback(payload)


    }
    updateExcel = () => {
        setTimeout(function () {
            this.setState({  renderExcel : true })
        }.bind(this), 500)
    }

    
    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                item['operation'] = item.key == 'data_field' ? '' : 7;
                if (item.key == "comment") {
                    return {
                        Header: item.label,
                        id: item.key,
                        headerTitle: item.label,
                        headerStyle: {
                            minWidth: '400px'
                        },
                        style: {
                            width: "55px",
                            whiteSpace: "break-spaces",
                            overflow: "visible",
                            minWidth: '400px'
                        },
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        accessor: item.key,
                        Cell: (props) => {
                            console.log(props.original, "checkProps")
                            return <>
                                {props.original.budget_group !== 'Sub Total' && <div className='commentContainerTable'>
                                    {props.original.data_field == 'Total Cost' || props.original.custom_level > 0  ? <React.Fragment></React.Fragment> :
                                        
                                        props.original.parents && props.original.parents.length > 1 ? <React.Fragment></React.Fragment> :
                                        
                                        <React.Fragment>
                                            <div className='commentInputSection flex ai-center pl-0'>
                                                <TextField
                                                    id={`outlined-basic-${props.original.data_field}`}
                                                    label="Type your Comment"
                                                    name="comment"
                                                    placeholder="Comments"
                                                    multiline
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(event) => { this.handleChatChangeFn(event, props.original) }}
                                                />
                                                <input style={{ display: 'none' }} type="file" />
                                                <Tooltip title='Send'>
                                                    <IconButton onClick={() => { this.handleChatSendFn(props) }} color="primary" aria-label="upload picture" className='px-0 ml-5'>
                                                        <Icon>send</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                            { props.original.comments && props.original.comments.length ? <div className='commentArea text-left'>
                                                {props.original &&
                                                    props.original.comments &&
                                                    props.original.comments.length
                                                    ? props.original.comments.map((item, index) => {
                                                        return (
                                                            <div className='block' key={index}>
                                                                <div className="date">{item.created_by}, {formatDate(item.created_at)}</div>
                                                                <div>{item.comment}</div>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div> : "" }
                                        </React.Fragment>}
                                </div>}
                            </>
                        },
                    };
                }
                else if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal[item.key]
                            let tooltipData = this.handleTooltipData(props)
                            return (
                                <div>
                                    <Tooltip title={tooltipData}>
                                        <span>{handleTableTransform(newOriginal, item)}</span>
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                } else if (item.key) {
                    
                    return {
                        Header: this.handleColumnHeaderTooltip(item),
                        headerTitle: item.label,
                        id: item.key,
                        className: isNested ? '' : 'noNesting',
                        metaData: item.metadata,
                        style:{ cursor: !isNested  ? this.props.comparativeSelectorValue == 2 && this.props.radioFilterValue == '' ? '': 'pointer': ''},
                        Cell: (props) => {
                            let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                            let zeroData = newOriginal[item.key]
                            return (
                                <div onClick={(event) => {
                                    console.log(event.target, newOriginal,'ooooooooooo>>>>>>',props, isNested )
                                    if (props && props.original && props.original.levels && props.original.levels.length && !isNested && zeroData) {
                                        
                                        this.handleNestedColumnClick(props.original, item.key, item.key)
                                    }
                                    }}>
                                    <React.Fragment>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</React.Fragment>
                                </div>
                                
                            )
                        },
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        accessor: (_data) => {
                            if (item.operation) {
                                let modifiedData;
                                let neOriginal = this.handleDifferentBUResponse(_data, item.key)
                                let columnData = neOriginal[item.key]
                                switch (item.operation) {
                                    case 0:
                                        modifiedData = item.key;
                                        break;
                                    case 1:
                                        modifiedData = Math.round(columnData)
                                        break;
                                    case 2:
                                        modifiedData = numberWithCommas(columnData)
                                        break;
                                    case 4:
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 3:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = numberWithCommas(modifiedData)
                                        break;
                                    case 5:
                                        modifiedData = Math.round(columnData)
                                        modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 6:
                                        var isNegative = columnData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    case 7:
                                        modifiedData = Math.round(columnData)
                                        var isNegative = modifiedData < 0
                                        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                        modifiedData = numberWithCommas(positiveTransformedValue)
                                        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                        modifiedData = modifiedData == '0' ? '' : modifiedData
                                        break;
                                    default:
                                        break;
                                }
                                return modifiedData;
                            } else {
                                return _data[item.key];
                            }
                        },
                    }
                }
                console.log(item.label, "checkKey")
                return {
                    Header: item.label,
                    id: item.key,
                    className: isNested ? '' : 'noNesting',
                    metaData: item.metadata,
                    Cell: (props) => {

                        let newOriginal = this.handleDifferentBUResponse(props.original, item.key)
                        let zeroData = newOriginal[item.key]
                        return (
                            <React.Fragment>{zeroData ? handleTableTransform(newOriginal, item) : '-'}</React.Fragment>

                        )
                    }
                }
            })
        }
        return []
    }

    dynamicHeaderValueOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].label : item.label
            return data
        }
        return item.label
    }

    dynamicHeaderKeyOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].key : item.key
            return data
        }
        return item.key
    }
    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                console.log(item.data_field, "checkItem")
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }
    checkLabel = (data) => {
            data.forEach(item => {
                if(item.data_field == "MSB"){
                    item.data_field = "BU 1"
                      }
            })
        
    }
    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    subComponent = (row) => {
        let { pivotHeaderData } = this.props.incomeStatementState;
        const { radioSelectorValue } = this.props
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
       
        if (this.props.comparativeSelectorValue == 3 && this.props.isDetailedView == false) {
            pivotHeaderData = this.changeKeysOfHeader2(pivotHeaderData, nestedFlag);
          }else{
            pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
          }

        console.log(pivotHeaderData, "checkNested")
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
       
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }

    columnHeaderClick = (state, someData, colInfo) => {
        const { showCursor } = this.props;
        console.log(colInfo.id, "checkID")
        if (showCursor) {
            return {
                onClick: (e) => {
                    this.handleColumnHeaderClick(colInfo)
                },
                style: { cursor: 'pointer' }
            }
        }
        return {}
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        console.log(state, colInfo, columnDetail, etc, event.target.value)
        const { data_field, parents } = colInfo.original;
        const { id, headerTitle } = columnDetail;
        let dataVal = parents && parents.length ? [...parents, data_field].join(',') : data_field;
        if (headerTitle) {
            // this.setState({
            //     showLoader: true,
            //     invoiceObject: { grid_header: id == 'grand_total' ? '' : id, level_values: dataVal }
            // })
            // this.props.getInvoiceDetail({ grid_header: id == 'grand_total' ? '' : id, level_values: dataVal });
            this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${colInfo.row[columnDetail.id]}`
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        if (column.Header != 'Comment') {
            return {
                onClick: (e, handleOriginal) => {
                    if (!rowInfo.original.disable_drill_down) {
                        this.handleColumnClick(state, rowInfo, column, instance, e)
                    }
                    console.log(column, rowInfo, "--column")
                    if (column.expander) {
                        let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                        let nestedFlag = this.checkForNestedLevel(pivotRowData)
                        if (this.props.comparativeSelectorValue == 3 && this.props.isDetailedView == false) {
                            pivotHeaderData = this.changeKeysOfHeader2(pivotHeaderData, nestedFlag);
                          }else{
                        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                          }
                        this.addExcelData(rowInfo.original, pivotHeaderData);
                        // console.log(rowInfo,"cek")
                        handleOriginal()
                    }
                }
            }
        } else {
            return {
                onClick: () => { }
            }
        }

    }
    

    prepareExcelData = () => {
        
        this.excelData = []
        this.handleExcelDataChange([], [])
        let { pivotRowData, pivotHeaderData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        if (this.props.comparativeSelectorValue == 3 && this.props.isDetailedView == false) {
            pivotHeaderData = this.changeKeysOfHeader2(pivotHeaderData, nestedFlag);
          }else{
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
          }
        pivotRowData = this.checkLevel(pivotRowData);
        console.log(this.state.showFunctionalDrilldownModal, "checkExcelData1")
        console.log(pivotHeaderData, "checkExcelData1")
        console.log(pivotRowData, "checkExcelData1")
        this.addExcelData(pivotRowData, pivotHeaderData);
    }

    componentDidUpdate(prevProps) {
      
        if (this.renderExcel || this.props.refreshExcel) {
            this.prepareExcelData();
        }
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
            this.setState({
                showInvoiceDetailModal: true,
                showLoader: false,
            })
        }
        if (prevProps.incomeStatementState.drilldownLoader && !this.props.incomeStatementState.drilldownLoader) {
            this.setState({
                showFunctionalDrilldownModal: true,
                renderExcel : false,
            })
            
        }

        // if (prevProps.isDetailedView && !this.props.isDetailedView) {
        //     this.setState({
        //         renderExcel : false,
        //     })
            
        // }
        if ( (prevProps.comparativeSelectorValue != this.props.comparativeSelectorValue)) {
            this.updateExcel()
        }
       
        
    }

    componentDidMount() {
        this.prepareExcelData();
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false },   this.updateExcel);
    }
    functionalDrilldownModalCallBack = () => {
        this.setState({ showFunctionalDrilldownModal: false },   this.updateExcel);
    }

    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value
        }
        this.setState({
            invoiceObject: payload
        })
        this.props.getInvoiceDetail(payload);
    }

    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false
        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }

    handleColumnHeaderClick = (colInfo) => {
        
        if (colInfo.id != "" || colInfo.id != "grand_total" || colInfo.id != "data_field") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }
    columnHeaderClick = (state, someData, colInfo) => {
        const { comparativeSelectorValue } = this.props;
        if (comparativeSelectorValue == 3) {
            if (colInfo.id != undefined && colInfo.id != "grand_total" && colInfo.id != "data_field") {
                console.log(colInfo.id, "checkInfos")
                return {
                    onClick: (e) => {
                        this.handleColumnHeaderClick(colInfo)
                    },
                    style: { cursor: 'pointer' }
                }
            }
           
        }
        return {}
    }
    handleValueTransform = (data, keys) => {
        let modifiedData;

        if(data){
            modifiedData = Math.round(data)
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            
        }else {
            modifiedData = 0
        }

        if (keys && keys.includes('delta')) {
            modifiedData = modifiedData == '0' ? 'N/A' : modifiedData
        } else {
            modifiedData = modifiedData == '0' ? '-' : modifiedData
        }
        

        return modifiedData
    }

    handleSubColumnChangeHeader = (data, key, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
            if(item.key == "grand_total"){
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        console.log(props.original, "cheking")
                        return (
                            <div className='text-center'>
                                {
                                    <span >{props.original.grand_total ? this.handleValueTransform(props.original.grand_total, item.key) : '-'}</span>
                                }
                            </div>
                        )
                    },
                    headerStyle: {
                        // whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    },
                    style: {
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }
            }
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                       
                        let itemValue = props.original.header_key_value && this.handleValueTransform(props.original.header_key_value[item.key], item.key)
                        console.log(itemValue, "cheking>>>>>>", item)
                        return (
                            <div onClick={(event) => {
                                // console.log(event.target, newOriginal,'ooooooooooo>>>>>>',props, isNested )
                                if (props && props.original && props.original.levels && props.original.levels.length && !isNested) {
                                    
                                    if (props.original.header_key_value){
                                        this.handleNestedColumnClick(props.original, itemValue, item.key)

                                    }
                                }
                                }} className='text-center'>
                                {
                                    <span >
                                        {props.original.header_key_value ? itemValue : '-'}
                                        </span>
                                }
                            </div>
                        )
                    },
                    headerStyle: {
                        // whiteSpace: 'break-spaces',
                        
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    },
                    style: {
                        cursor: !isNested   ? 'pointer': '',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }

            })
        }
    }

    changeKeysOfHeader2 = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                console.log(item.key, "masukDataField")
                if (item.key == 'data_field') {
                    console.log("masukDataField2")
                    return {
                        Header: item.label,
                    id: item.key,
                        fixed: 'left',
                        columns: [{
                            Header: item.label,
                            id: item.key,
                            Cell: (props) => {

                                return (
                                    <React.Fragment>
                                        <>{props.original.data_field}</>
                                    </React.Fragment>
                                )
                            }
                        }],
                        headerStyle: {
                            color: '#8306ce',
                        },
                    }
                } 

                return {
                    Header: item.label,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item.key, isNested),
                    headerStyle: {
                        borderRight: '1px solid #423970',
                        position: 'relative',
                    },
                    style: {
                        borderRight: '1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    render() {
        let { invoiceDetailStateFilter, pivotRowData, pivotHeaderData, loading, invoiceDetailState, invoiceLoading, drilldownRowHeader, drilldownRowData } = this.props.incomeStatementState;
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
      if (this.props.comparativeSelectorValue == 3 && this.props.isDetailedView == false) {
        pivotHeaderData = this.changeKeysOfHeader2(pivotHeaderData, nestedFlag);
      }else{
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
      }
      
        const { showCommentModal, showInvoiceDetailModal, showFunctionalDrilldownModal, showLoader } = this.state
        let tableLen = pivotRowData && pivotRowData.length || 6;
        console.log(pivotHeaderData, "checkNestedBefore")
        pivotRowData = this.checkLevel(pivotRowData);
        // console.log(pivotRowData, "checkNestedAfter")
        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                {loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                 <ReactTableComponent 
                    pivotRowData={pivotRowData}
                    className={nestedFlag ? 'xpna-reactFixed-table expandableRows' : 'xpna-reactFixed-table'}
                    loading={loading}
                    pivotHeaderData={pivotHeaderData}
                    tableLen={tableLen}
                    subComponent={nestedFlag && this.subComponent}
                    columnHeaderClick={this.columnHeaderClick}
                    rowClick={this.rowClick}
                    showFunctionalDrilldownModal={showFunctionalDrilldownModal}
                    showLoader={showLoader}
                 />
                }
                {showInvoiceDetailModal &&
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        invoiceDetailStateFilter={invoiceDetailStateFilter}
                        invoiceLoading={invoiceLoading}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleFilterChange={this.handleInvoiceFilterChange}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                    />
                }
                 {showFunctionalDrilldownModal && drilldownRowHeader && drilldownRowHeader.length ? 
                    <FunctionDrilldownDetailModal
                        showModal={showFunctionalDrilldownModal}
                        modalCallBack={this.functionalDrilldownModalCallBack}
                        drilldownRowHeader={drilldownRowHeader}
                        drilldownRowData={drilldownRowData}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleExcelDownload={this.handleDrilldownExcelDownload}
                    /> : ''
                }
                {showCommentModal &&
                    <CommentModal
                        showModal={showCommentModal}
                    // commentRowData={commentRow}
                    // modalCallBack={this.modalCallBack}
                    // commentsData={commentModalData}
                    // userDataState={authState.userData}
                    // sendCommmentCallback={this.handleSendChatModalFunction}
                    // buValue={this.props.buValue}
                    // onImageInputHandler={this.onImageInputHandler}
                    // handleImageOptions={this.handleImageOptions}
                    // uploadImageObj={uploadImageObj}
                    // allUsers={this.props.externalState.allUser}
                    // menuData={menus}
                    />
                }
            </>
        );
    }
}

export default TableComponent;