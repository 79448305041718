import React from 'react';
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Grid, Tooltip } from "@material-ui/core";
import LeftMenu from "../../../../images/plreport/leftmenu.png"
import FirstScreen from "../../../../images/plreport/firstscreen.png"
import SecondScreen from "../../../../images/plreport/sec.png"
import ThirdScreen from "../../../../images/plreport/trans.png"

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class PLReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.buMap = {
            0: 'BU 1',
            1: 'BU 2',
            2: 'BU 3',
            3: 'BU 4'
        }
        this.imageMap = {
            0: FirstScreen,
            1: SecondScreen,
            2: ThirdScreen,
        }
        this.state = {
            externalTabValue: 0,
            selectedImage: 0,
            hideBackButton: true,
            filterPayload: {
                action_owner: [],
                aging_category: [],
                yearmonth: [],
                category: "",
                customer_name: [],
                skip: 0,
                limit: 60
            }
        }
    }

    componentDidMount() {
        // console.log( this.props, "props")
        // this.props.getAllUser({})
    }


    handleExternalTabChange = (event) => {
        this.setState({
            reportTabValue: event.target.value
        })
    }

    handleImageClick = () => {

        const { selectedImage } = this.state
        if (selectedImage < 2) {
            this.setState({
                selectedImage: selectedImage + 1,
                hideBackButton: false
            })
        }

    }

    handleBackClick = () => {

        const { selectedImage } = this.state
        if (selectedImage == 2) {
            this.setState({
                selectedImage: selectedImage - 1
            })
        }

        if (selectedImage == 1) {
            this.setState({
                selectedImage: selectedImage - 1,
                hideBackButton: true
            })
        }



    }


    render() {

        const { hideBackButton, selectedImage } = this.state


        return (
            <React.Fragment>
                <div className='dayrepots-100k-120'>
                    {<Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={12} className="gap-to-target-table">
                            {!hideBackButton && <div style={{cursor: 'pointer'}} onClick={this.handleBackClick}>
                                {'< Back'}
                            </div>
                            }
                        </Grid>

                    </Grid>
                    }

                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6} sm={4} className="gap-to-target-table">
                            <div >
                                <img width={'90%'} src={LeftMenu} />
                            </div>
                        </Grid>

                        <Grid item xs={6} sm={8}>
                            <div style={{cursor: 'pointer'}} onClick={this.handleImageClick}>
                                <img width={'90%'} src={this.imageMap[selectedImage]} />
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summary
            }
        },
        dispatch => {
            return {
                updateSelectedDate(data) {
                    dispatch({
                        type: "UPDATE_SELECTED_DATE",
                        payload: data,
                    });
                }
            };
        }
    )(PLReportComponent);
