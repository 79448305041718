import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartView from "./ChartView"
import YearlyChartView from "./YearlyChart"
import TableView from "./TableView"
import AnalysisAction from "./AnalysisAction"
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export class ScatterComponent extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      externalTabValue: 0,
    }
  }

  handleExternalTabChange = (event, newValue) => {
    this.setState({ externalTabValue: newValue });
  };
 

  render() {
    const { externalTabValue } = this.state
    return (
      <>
      
        <Tabs
          ref={el => (this.container = el)}
          value={externalTabValue}
          indicatorColor="primary"
          textColor="primary"
          className='mb-10'
          onChange={this.handleExternalTabChange}
        >
          <LinkTab label="Chart View" href="/scatter" className="list-nested" {...a11yProps(0)} />
          <LinkTab label="Monthly Overdue Trend" href="/bar" className="list-nested" {...a11yProps(1)} />
          <LinkTab label="Table View" href="/table" className="list-nested" {...a11yProps(2)} />
          <LinkTab label="Analysis and Actions" href="/analysisaction" className="list-nested" {...a11yProps(3)} />
        </Tabs>
     
        <TabPanel value={externalTabValue} index={0}>
          <ChartView tabSelectorValue = {this.props.tabSelectorValue}/>
        </TabPanel>
        <TabPanel value={externalTabValue} index={1}>
          <YearlyChartView tabSelectorValue = {this.props.tabSelectorValue}/>
        </TabPanel>
        <TabPanel value={externalTabValue} index={2}>
          <TableView  tabSelectorValue = {this.props.tabSelectorValue}/>
        </TabPanel>
        <TabPanel value={externalTabValue} index={3}>
          <AnalysisAction
            receivableTabSelectorValue={this.props.tabSelectorValue}
          />
        </TabPanel>

       
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        overDueState: state.dashboardState.dashboardAnalytic.OverdueAnalysis,
      }
    },
    (dispatch) => {
      return {
        getOverDueSummary(data) {
          dispatch({
            type: "FETCH_OVERDUE_CHART",
            payload: data,
          });
        },
        getOverDueByBU(data) {
          dispatch({
            type: "FETCH_OVERDUE_CHART_BY_BU",
            payload: data,
          });
        }
      }
    }
  )(ScatterComponent)
);