import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


export default function SideMenu({ keyStr, itemArray, typeStr }) {
    
    const getDnDItemStyle = (isDragging, draggableStyle) => ({
		maxWidth: isDragging ? '50%' : '100%',
		width: isDragging ? '50%' : '100%',
		// backgroundColor: isDragging ? '#d9d9d9' : '#015ABB',
		backgroundColor: isDragging ? '#015ABB' : '#f4f6f9',
		//color: isDragging ? '#000000' : '#FFFFFF',
		color: isDragging ? '#FFFFFF' : '#000000',

		borderRadius: isDragging ? 25 : 25,
		maxHeight: 15,
		marginTop: isDragging ? undefined : 5,
		marginBottom: isDragging ? undefined : 5,
		padding: isDragging ? '16px' : '16px'  ,
		alignItems: 'center',
		...draggableStyle,
	});

    return (
        <div className='side-menu-item mt-5 '>
        <Droppable key={keyStr} droppableId={`${keyStr}-option`} type={typeStr}>
            {(provided, snapshot) => (
                <Accordion
                    id={`${keyStr}-unselect-box`}
                    className="major-menu-item"
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    // isDraggingOver = {snapshot.isDraggingOver}
                    defaultExpanded
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className="major-menu-title bold-title">{keyStr}</Typography>
                    </AccordionSummary>
                    {/* {switchRender()} */}
                    {itemArray && itemArray.length
                        ? itemArray.map((item, index) => {
                              return (
                                  <Draggable
                                      key={item.label}
                                      draggableId={item.label}
                                      index={index}
                                      // isDragDisabled = {item.selected}
                                  >
                                      {(provided, snapshot) => (
                                          <AccordionDetails
                                              className="dnd-item-new"
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              innerRef={provided.innerRef}
                                              // isDragging = {snapshot.isDragging}
                                              style={getDnDItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          >
                                              <div style={{ marginLeft: 15 }}>{item.label}</div>
                                          </AccordionDetails>
                                      )}
                                  </Draggable>
                              );
                          })
                        : ""}
                </Accordion>
            )}
        </Droppable>
        </div>
    );
}
