import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../../constant";

function HighChart(props) {
    const {chartData}=props;
    const xAxis = chartData ?  chartData.x_axis ? chartData.x_axis : [] : [];
    const yAxis = chartData ? chartData.data ? chartData.data : [] :[];
    const yAxis1 = (yAxis.length>0)? yAxis[0] : Array(xAxis.length).fill(0);
    const yAxis2 = (yAxis.length>0)? yAxis[1] : Array(xAxis.length).fill(0);

    


    const {performanceAnalysis: { monthlyPerformance: { gapToTargetColor, fcfEfficiencyColor } }} = CHART_COLORS;
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            zoomType: 'xy'
                        },
                        title: {
                            text: 'Gap to Target (FCF) - Monthly Performance'
                        },
                        credits: {
                            enabled: false
                          },
                        xAxis: [{
                            categories: xAxis,
                            crosshair: true
                        }],
                        yAxis: [{ // Primary yAxis
                            labels: {
                                format: '${value}',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'USD millions',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            }
                        }, { // Secondary yAxis
                            labels: {
                                format: '{value} %',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            //max:100, // need to verify this
                            title: {
                                text: '',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                        }],
                        tooltip: {
                            shared: true
                        },
                        legend: {
                            layout: 'vertical',
                            align: 'left',
                            x: 500,
                            verticalAlign: 'top',
                            y: 40,
                            floating: true,
                            backgroundColor:
                                Highcharts.defaultOptions.legend.backgroundColor || // theme
                                'rgba(255,255,255,0.25)'
                        },
                        // series: [{
                        //     name: yAxis1.name,
                        //     type: yAxis1.type,
                        //     //yAxis: 1,
                        //     data: yAxis1.data,
                        //     tooltip: {
                        //         valueSuffix: ''
                        //     },
                        //     color: yAxis1.color
                        // }, {
                        //     name: yAxis2.name,
                        //     type: yAxis2.type,
                        //     yAxis: 1,
                        //     data: yAxis2.data,
                        //     tooltip: {
                        //         valueSuffix: ''
                        //     },
                        //     color: yAxis2.color
                        // }],
                        series: yAxis
                    }
                }
            />
        </div>
    )
}
export default HighChart;