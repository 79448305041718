import { Draggable } from "react-beautiful-dnd";
import { LoremIpsum } from "lorem-ipsum";
import { generateFromString } from "generate-avatar";
import React, { useMemo } from "react";
import styled from "styled-components";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from "date-fns";


const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border: 3px solid white;
  border-radius: 50%;
`;

const CardHeader = styled.div`
  font-weight: 500;
`;

const CardTitle = styled.div`
  font-weight: 500;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;
const CardFooter = styled.div`
  width: 100%;
  display: flex;
  
  align-items: left;
`;

const DragItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

const lorem = new LoremIpsum();

const ListItem = (props) => {

  const { item, index, handleOpen} = props;
  const randomHeader = useMemo(() => lorem.generateWords(5), []);

  let approvedDate = item.approve_date_final ? format(new Date(item.approve_date_final), "dd-MMM-yyyy H:mma") : "0001";
  approvedDate = approvedDate.indexOf("0001") > -1 || item.status !== "approve" ? "" : 'Approved Date: '+ approvedDate

  const handleClick = () =>{
    handleOpen(item);
    console.log(props)
  }

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // className={`list-card-${item.date_status}`}
            className={`list-card-${item.status}`}
          >
            <CardHeader className={`list-header-card-${item.status} text-right`}>{item.name}</CardHeader>
            {/* <CardHeader className={`list-header-card-${dateStatus} text-right`}>
              <Tooltip title="Edit">
                  <EditIcon onClick={(e) => {e.preventDefault(); handleClick()}}/>
              </Tooltip> 
              </CardHeader> */}
            {/* <CardTitle>{item.name}</CardTitle> */}
            <span>{'Version: '+ item.version}</span>
            <span>{'Created By: '+ item.created_by}</span>
            <span>{approvedDate}</span>
            {/* <CardFooter >
              <span>{item.content}</span>
              <Avatar
                  src={`data:image/svg+xml;utf8,${generateFromString(item.id)}`}
                />
              <Author>
                {item.created_by}
              </Author>
            </CardFooter> */}
          </DragItem>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
