import React from "react";
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Tooltip } from "@material-ui/core";
import InputPlanningComponent from './InputPlan';
import './Planning.scss'


export class PlanningComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelectorValue: 0,
        };
    }

    componentDidMount(){
        // this.props.getAllUser({})
        // this.callGetAllCommentHistoryFunc()
        this.props.fetchDropdownPlanning({})
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tabSelectorValue: newValue }, () => {
          if (newValue == 0) {
            this.props.fetchDropdownPlanning({})
          } else if (this.state.tabSelectorValue == 1) {
          } else { //will change later
          }
        })
    };

    render() {
        const {tabSelectorValue} = this.state;

        const body = (
            <React.Fragment>        
              <Tabs
                value={tabSelectorValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                className="sc-planning-tab"
              >
                <Tab label="INPUT" />
                <Tab label="REVIEW" />
                <Tab label="APPROVE" />
              </Tabs>
              <Grid container className='mt-20 mb-20'>  
                {tabSelectorValue == 1 &&
                  <div>
                  </div>
                }
                {tabSelectorValue == 0 &&
                    <InputPlanningComponent planningData={this.props.planningState}/>
                }
                {tabSelectorValue == 2 &&
                  <div>
                  </div>
                }
              </Grid>
              
            </React.Fragment>
          )

        return body;
    }
}

export default
    connect(
        state => {
            return {
                planningState: state.shreeCementState.planningData,
                // authState: state.authReducer,
                // chatState: state.geospacialState.chatData,
                // chatStoreState: state.chatStore,
            }
        },
        dispatch => {
            return {
                fetchDropdownPlanning() {
                    dispatch({
                        type: "FETCH_DROPDOWN_PLANNING"
                    })
                }
            };
        }
    )(PlanningComponent);