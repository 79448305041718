import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas } from '../../../../utils/numberUtils'

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
});

function BarChart(props) {
    function handleInvoiceClicked (rowData) {
        console.log(rowData, "this props2")
        let payload = {
            grid_header_value:"",
            grid_header:"",
            sub_grid_header_value: rowData.options.category,
            level_values:"Standard Margin",
    
        }
       
        props.handleInvoiceClick(payload)
    }
    let maximum =0
   if( props.chartData[1] != undefined ){
    let sales = props.chartData[1].data
    let cost = props.chartData[0].data

    let maxSales = 0
    if(sales.length > 0){
        maxSales = sales[0].y
    }
    let maxCost = 0
    if(cost.length > 0){
        maxCost = cost[0].y
    }
        maximum = maxCost
    if(maxSales < 0){
        maximum += maxSales
    }

    sales.forEach((x,idx)=>{
        let curMax = x.y
        curMax += cost[idx].y
        if(curMax < maximum){
            maximum = curMax
        }
    })


   }


   
    return (
        <div id="column_chart_overdue" class="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'bar'
                        },
                        title: {
                            text: 'TOP 20 SO With Negative Margins',
                            visible: false
                        },
                        subtitle: {
                            text: 'Note: Amounts displayed are @ Optimus USD and not AOP USD'
                        },
                        accessibility: {
                            point: {
                                valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
                            }
                        },
                        xAxis: [{
                            categories: props.chartCategories,
                            labels: {
                                step: 1
                            },
                            title : {
                                text : "SO Item - Plant - Sales Person"
                            }
                        }],
                        yAxis: {
                            title: {
                                text: null
                            },
                            labels: {
                                formatter: function () {
                                    return Math.abs(this.value/1000000)+ 'M';
                                }
                            },
                            accessibility: {
                                description: 'Percentage population',
                                rangeDescription: 'Range: 0 to 5%'
                            },
                            min : maximum ,
                            max : maximum * -1
                        },
                    
                        plotOptions: {
                            series: {
                                stacking: 'normal',
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: function () {
                                            handleInvoiceClicked(this)
                                        }
                                    }
                                }
                            }
                        },
                    
                        tooltip: {
                            formatter: function () {
                                if(this.series.name != "Margin"){
                                return '<b>' + this.series.name + '</b><br/> ' + this.point.options.category + '<br/>' +
                                    'Value: ' + Highcharts.numberFormat(this.point.y, 1)
                                }else{
                                    return '<b>' + this.series.name + '</b><br/> ' + this.point.options.category + '<br/>' +
                                    'Value: ' + Highcharts.numberFormat(this.point.label, 1)
                                }
                            }
                        },
                        series: props.chartData,
                        // props.chartData,
                        // [
                        //     {
                        //         // name: props.chartData.name,
                        //         // colorByPoint: true,
                        //         data: props.chartData
                        //     }
                        // ],
                        // responsive: {
                        //     rules: [{
                        //         condition: { maxWidth: 450 },
                        //     }]
                        // },
                        // credits: {
                        //     enabled: false
                        // },
                        // legend: {
                        //     squareSymbol: false,
                        //     symbolHeight: 6,
                        //     symbolWidth: 12,
                        //     symbolRadius: 0
                        // },
                        // navigation: {
                        //     buttonOptions: {
                        //         verticalAlign: 'top',
                        //         y: -10
                        //     }
                        // },
                    }
                }
            />

        </div>
    )
}
export default BarChart;