import { transformFilterData, transformComboData } from './transformer'

const reducer = (
   state = {
      userState: {
         loading: false,
         data: [],
         filterData: {},
         header: [],
         new_groups:[],
         new_regions:[],
         sales_and_technologies_bus:[],
         sales_and_technologies_groups:[],
         roleData: [],
         groupData: [],
         saveLoading: false
      },
      userAnalytics: {
         loading: false,
         rowHeaderData: [],
         rowData: [],
         defaultFilter: '',
         userRoleArr: [],
         userComboArr: [],
         sessionTimeArr: [],
         loginCountArr: [],
         finalYearArr: [],
         quarterfilterArr: [],
         graphData: {}
      },
      roleState: {
         loading: false,
         data: [],
         filterData: {},
         header: [],
         menuData: [],
         saveLoading: false,
         saveSuccess: false
      },
      autoPaymentState: {
         loading: false,
         invoiceLoader: true,
         invoiceHeader: [],
         invoiceData: [],
         data: [],
         filterData: {},
         header: [],
         saveLoading: false,
         errorWhileSaving: [],
         showError: false
      },
      autoCustomerCommentsState: {
         loading: false,
         invoiceLoader: true,
         invoiceHeader: [],
         invoiceData: [],
         data: [],
         filterData: {},
         header: [],
         saveLoading: false
      },
      ISFileUploadState: {
         data: [],
         header: [],
         filterData: {},
         loading: false,
         uploadLoader: false,
         saveSuccess: false
      },
      rolesData: {},
      usersData: {},
      menusData: {},
      error: false,
      autoEbitdaBudgetState: { 
         loading: false,
         data: [],
         header: [],
         saveLoading: false,
         errorWhileSaving: [],
         showError: false
      },
      autoEbitdaActualState: {
         loading: false,
         data: [],
         header: [],
         saveLoading: false,
         errorWhileSaving: [],
         showError: false
      }
   }, action) => {
   let newState = JSON.parse(JSON.stringify(state));

   switch (action.type) {

      case 'FETCH_USERMGMT_DATA_LOADER':
         newState.userState.loading = action.loading;
         newState.userState.data = [];
         return newState;

      case 'FETCH_ACLGROUP_DATA_LOADER':
         newState.roleState.loading = action.loading;
         newState.roleState.data = [];
         return newState;

      case 'FETCH_USERMGMT_DATA_SUCCESS':
         newState.userState.loading = action.loading;
         newState.userState.data = action.data.data;
         newState.userState.header = action.data.header;
         return newState;
         case 'FETCH_USER_ANALYTICS_EMAIL_SUCCESS':
             newState.userAnalytics.loading = action.loading;
         return newState;

      case 'FETCH_ACLGROUP_DATA_SUCCESS':
         newState.roleState.loading = action.loading;
         newState.roleState.data = action.data.data;
         newState.roleState.header = action.data.header;
         return newState;

      case 'FETCH_MENUMASTER_DATA_SUCCESS':
         newState.roleState.menuData = action.data;
         return newState;

      case 'FETCH_MENUMASTER_DATA_LOADER':
         newState.roleState.menuData = [];
         return newState;

      case 'ADD_USER_DATA_LOADER':
         newState.userState.saveLoading = action.loading;
         return newState;

      case 'FETCH_ROLE_AND_GROUP_LOADER':
         newState.userState.roleData = [];
         newState.userState.groupData = [];
         newState.userState.budgetGroup = [];
         return newState;

      case 'FETCH_ROLE_AND_GROUP_SUCCESS':
         newState.userState.roleData = action.data.role;
         newState.userState.groupData = action.data.group;
         newState.userState.budgetGroupData = action.data.budgetGroup;
         newState.userState.new_groups = action.data.new_bu_groups;
         newState.userState.new_regions = action.data.new_bu_regions;
         newState.userState.sales_and_technologies_bus = action.data.sales_and_technologies_bus;
         newState.userState.sales_and_technologies_groups = action.data.sales_and_technologies_groups;
         return newState;

      case 'ADD_USER_DATA_SUCCESS':
         newState.userState.saveLoading = action.loading;
         return newState;

      case 'ADD_ACLGROUP_DATA_LOADER':
         newState.roleState.saveLoading = action.loading;
         return newState;

      case 'ADD_ACLGROUP_DATA_SUCCESS':
         newState.roleState.saveLoading = action.loading;
         return newState;

      case 'MAIN_PAYMENT_DATA_LOADER':
         newState.autoPaymentState.data = [];
         newState.autoPaymentState.loading = action.loading;
         return newState;
      case 'FETCH_AGING_REPORT_DATE_MONTHLY_SUCCESS':
         newState.autoPaymentState.reportDateArrMonthly = action.data.dates;
         const dateLength = action.data.dates.length;
         newState.autoPaymentState.selectedDateMonthly = dateLength > 0 ? action.data.dates[dateLength - 1] : '';
         return newState

      case 'FETCH_PAYMENT_DATA_SUCCESS':
         if (action.data.data != null) {
            newState.autoPaymentState.data = action.data.data;
         }
         else {
            newState.autoPaymentState.data = [];
         }
         newState.autoPaymentState.header = action.data.header;
         newState.autoPaymentState.loading = action.loading;
         return newState;

      case 'DELETE_PAYMENT_DATA_SUCCESS':
         newState.autoPaymentState.loading = action.loading;
         return newState;

      case 'MAIN_CUSTOMER_COMMENTS_DATA_LOADER':
         newState.autoCustomerCommentsState.data = [];
         newState.autoPaymentState.data = [];
         newState.autoPaymentState.invoiceHeader = [];
         newState.autoPaymentState.invoiceData = [];
         newState.autoCustomerCommentsState.loading = action.loading;
         return newState;

      case 'FETCH_CUSTOMER_COMMENTS_DATA_SUCCESS':
         if (action.data.data != null) {
            newState.autoCustomerCommentsState.data = action.data.data;
         }
         else {
            newState.autoCustomerCommentsState.data = [];
         }
         newState.autoCustomerCommentsState.header = action.data.header; 
         newState.autoCustomerCommentsState.loading = action.loading;
         return newState;

      case 'DELETE_CUSTOMER_COMMENTS_DATA_SUCCESS':
         newState.autoCustomerCommentsState.loading = action.loading;
         return newState;

      case 'SAVE_PAYMENT_DATA_SUCCESS':
         newState.autoPaymentState.saveLoading = action.loading;
         newState.autoPaymentState.saveSuccess = true;
         newState.autoPaymentState.showError = action.showError;
         return newState;

      case 'SAVE_PAYMENT_DATA_FAIL':
         newState.autoPaymentState.errorWhileSaving = action.errors;
         newState.autoPaymentState.showError = action.showError;
         newState.autoPaymentState.saveLoading = action.loading;
         return newState;

      case 'FETCH_PAYMENT_INVOICE_DETAIL_LOADER':
         newState.autoPaymentState.invoiceLoader = action.loading;
         return newState;

      case 'FETCH_PAYMENT_INVOICE_DETAIL_SUCCESS':
         newState.autoPaymentState.invoiceLoader = false;
         newState.autoPaymentState.invoiceHeader = action.data.header;
         newState.autoPaymentState.invoiceData = action.data.data;
         newState.autoPaymentState.invoiceDataForecast = action.data.dataForecast;
         console.log(action.data.dataForecast, "dataForecast")
         return newState;

      case 'SAVE_PAYMENT_DATA_LOADER':
         newState.autoPaymentState.saveLoading = action.loading;
         newState.autoPaymentState.saveSuccess = action.success;
         newState.autoPaymentState.showError = action.showError;
         return newState;

      case 'FETCH_USER_ANALYTICS_DATA_LOADER':
         newState.userAnalytics.loading = action.loading;
         newState.userAnalytics.rowHeaderData = [];
         newState.userAnalytics.rowData = [];
         return newState;

      case 'FETCH_USER_ANALYTICS_DATA_SUCCESS':
         newState.userAnalytics.loading = action.loading;
         newState.userAnalytics.rowHeaderData = action.data.row_header;
         newState.userAnalytics.rowData = action.data.row_data;
         if (Array.isArray(action.data)) {
            newState.userAnalytics.graphData = action.data
            return newState
         }
         var { loginCountArr, quarterfilterArr, sessionTimeArr, userRoleArr, finalYearArr, userComboArr} = transformFilterData(action.data.filter);
       
         newState.userAnalytics.loginCountArr = loginCountArr;
         newState.userAnalytics.quarterfilterArr = quarterfilterArr;
         newState.userAnalytics.sessionTimeArr = sessionTimeArr;
         newState.userAnalytics.userRoleArr = userRoleArr;
         newState.userAnalytics.userComboArr = userComboArr
         newState.userAnalytics.finalYearArr = finalYearArr;
         console.log(newState.userAnalytics, "cekARRTrans")
         return newState;

      case 'FETCH_DEFAULT_FILTER_USER_ANALYTICS_LOADER':
         newState.userAnalytics.loading = action.loading;
         return newState;

      case 'FETCH_DEFAULT_FILTER_USER_ANALYTICS_SUCCESS':
         newState.userAnalytics.loading = action.loading;
         newState.userAnalytics.defaultFilter = action.data;
         return newState;

      case 'FETCH_IS_FILE_DATA_LOADER':
         newState.ISFileUploadState.loading = action.loading;
         return newState;

      case 'FETCH_IS_FILE_DATA_SUCCESS':
         newState.ISFileUploadState.loading = action.loading;
         newState.ISFileUploadState.data = action.data.data ? action.data.data : [];
         newState.ISFileUploadState.header = action.data.header;
         return newState;
      case 'FETCH_IS_FILE_DATA_SUCCESS_2':
         newState.ISFileUploadState.loading = action.loading;
         newState.ISFileUploadState.data = action.data.row_data ? action.data.row_data : [];
         newState.ISFileUploadState.header = action.data.row_header;
         return newState;
         case 'FETCH_IS_FILTER_DATA_SUCCESS':
            newState.ISFileUploadState.loading = action.loading;
            newState.ISFileUploadState.filterData = action.data
            return newState;
      case 'UPLOAD_IS_FILE_DATA_LOADER':
         newState.ISFileUploadState.uploadLoader = action.loading;
         return newState;

      case 'UPLOAD_IS_FILE_DATA_SUCCESS':
         newState.ISFileUploadState.uploadLoader = action.loading;
         newState.ISFileUploadState.saveSuccess = action.success;
         return newState;

      case 'DELETE_IS_FILE_DATA':
         newState.ISFileUploadState.loading = action.loading;
         newState.ISFileUploadState.data = action.data;
         return newState;
         
      case 'EBITDA_DATA_LOADER':
         newState.autoEbitdaBudgetState.data = [];
         newState.autoEbitdaBudgetState.loading = action.loading;
         return newState;
      case 'FETCH_EBITDA_DATA_SUCCESS':
         if (action.data.data != null) {
            newState.autoEbitdaBudgetState.data = action.data.data;
         }
         else {
            newState.autoEbitdaBudgetState.data = [];
         }
         newState.autoEbitdaBudgetState.header = action.data.header;
         newState.autoEbitdaBudgetState.loading = action.loading;
         return newState;
      case 'EBITDA_ACTUAL_DATA_LOADER':
         newState.autoEbitdaActualState.data = [];
         newState.autoEbitdaActualState.loading = action.loading;
         return newState;
      case 'FETCH_EBITDA_ACTUAL_DATA_SUCCESS':
         if (action.data.data != null) {
            newState.autoEbitdaActualState.data = action.data.data;
         }
         else {
            newState.autoEbitdaActualState.data = [];
         }
         newState.autoEbitdaActualState.header = action.data.header;
         newState.autoEbitdaActualState.loading = action.loading;
         return newState;
      case 'SAVE_EBITDA_BUDGET_DATA_LOADER':
         newState.autoEbitdaBudgetState.saveLoading = action.loading;
         newState.autoEbitdaBudgetState.saveSuccess = action.success;
         newState.autoEbitdaBudgetState.showError = action.showError;
         return newState;
      case 'SAVE_EBITDA_BUDGET_DATA_SUCCESS':
         newState.autoEbitdaBudgetState.saveLoading = action.loading;
         newState.autoEbitdaBudgetState.saveSuccess = true;
         newState.autoEbitdaBudgetState.showError = action.showError;
         return newState;
      case 'SAVE_EBITDA_ACTUAL_DATA_LOADER':
         newState.autoEbitdaActualState.saveLoading = action.loading;
         newState.autoEbitdaActualState.saveSuccess = action.success;
         newState.autoEbitdaActualState.showError = action.showError;
         return newState;
      case 'SAVE_EBITDA_ACTUAL_DATA_SUCCESS':
         newState.autoEbitdaActualState.saveLoading = action.loading;
         newState.autoEbitdaActualState.saveSuccess = true;
         newState.autoEbitdaActualState.showError = action.showError;
         return newState;
      default:
         return newState;
   }
};
export default reducer;

