import React from 'react';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChartContainer from './Charts/chartContainer';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import FileUploadIcon from "../../../../src/images/isMain.png";

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Tabs from '@material-ui/core/Tabs';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import NewSummaryTableComponent from './TableComponent'
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AnalsisImg from '../../../images/sus1.png'
import OcosAllocation from '../../../images/ocosAllocation.png'
import Prof1Img from '../../../images/marg1.png'
import Prof2Img from '../../../images/eco1.png'
import CCAImg from '../../../images/cca.png'
import Dash1Img from '../../../images/dash1.png'
import { fetchMonthlyQuarterBUPLService } from '../store/service';
import { formatMs } from '@material-ui/core';
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import { isThisISOWeek } from 'date-fns';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
//import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import AOPForecastComponent from './AOPForecast';
import FileUploadPopUp from './FIleUploadPopUp';
import DynamicColumn from './DynamicColumn';
import { Autocomplete, TextField } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class NewOriginationComponent extends React.Component {

    constructor(props) {
        super(props);

        this.tabMap = {
            0: 'summary',
            1: 'BU 1',
            2: 'BU 2',
            3: 'BU 3',
            4: 'BU 4',
            5: 'OH'
        }

        this.MPLClassificationMap = {
            0: 'summary',
            1: 'New BU 1',
            2: 'New BU 2',
            3: 'BU 1',
            4: 'BU 2',
            5: 'BU 3',
            6: 'BU 4',
            7: 'OH',
        }

        this.imgMap = {
            0: AnalsisImg,
            1: Prof1Img,
            2: Prof2Img,
            3: CCAImg,
            4: Dash1Img
        }
        this.imgOcos = {
            0: OcosAllocation,
        }

        this.radioMap = {
            0: 'summary',
            1: 'plant',
            2: 'le',
            3: 'region',
            4: 'country',
            5: 'hemisphere'
        }
        this.MPLDrilldownMap = {
            summary: 'All',
            wh: 'West',
            eh: "East"
        }

        this.BUFourDrilldownObj = {}

        this.state = {
            showLoader: true,
            aopTabState: {},
            isDetailedView: false,
            showUploadModal: false,
            regionClicked: {},
            hemisphereClicked: {},
            monthSelectorValue:  this.props.incomeStatementState.defaultMonth &&
            this.props.incomeStatementState.defaultMonth.length ? this.props.incomeStatementState.defaultMonth :'',
            qtrSelectorValue: '',
            additionalData:[],
            chartDataSO: [],
            TableDataSO: [],
            manuallyChanged: false,
            invoiceObject: null,
            yearSelectorValue: '',
            radioSelectorValue: this.props.xpnaMenu ? this.props.xpnaMenu :  '0',
            radioSelectorReconValue: 'All',
            comparativeSelectorValue: '1',
            tabSelectorValue: 0,
            isXPNA : this.props.isXPNA ? this.props.isXPNA : "false",
            tabSelectorValue1: this.props.isXPNA && this.props.isXPNA == "true" ? 2 :  -1,
            radioSelectorValueLE: "summaryLE",
            radioSelectorValuePlant : "BU 1",
            radioSelectorValueDGSAutomation : "lawson",
            radioSelectorDynanicDGS : "Total",
            radioSelectorTotalSales : "summary",
            newRadioSelectorValue: '4',
            NewTabList: [],
            NewTabList1: [],
            radioSelectorValueSO : "summary",
            radioSelectorValueMPR : "summary",
            defaultradioSelectorValueMPR : "wh",
            radioType : "",
            GraphView : true,
            radioArrSelected : "Default",
            radioArrCountrySelected : "Default",
            commentModal : false,
            DGAAutomationRadioList : [
                { text: 'Dynamic Radio 1', value: 'Dynamic Radio 1' },
                { text: 'Dynamic Radio 2', value: 'Dynamic Radio 2' },
                { text: 'Dynamic Radio 3', value: 'Dynamic Radio 3' },
               
            ],
            invoiceDetailStateNew : '',
            invoiceDetailFilter : '',
            showInvoiceDetailModal: false,
            showInvoiceDetailModalChart: false,
            selectedDrilldown : null,
            commentValue :'',
            radioData : [],
            radioDataSample : ''
        }
    }

    componentDidMount() {
     
        let payload = {
                recon_value: this.state.radioSelectorReconValue,
                radiovalue :  "optimus",
                is_xpna : this.state.isXPNA,
                radioXPNAValue : this.state.radioSelectorValue,
        }
        this.props.getNewDefaultMonthDataDynamic(payload) 
        this.props.getNewDefaultMonthData({origination : "true", mpr : "true", is_xpna : this.state.isXPNA, radioXPNAValue : this.state.radioSelectorValue,})
        this.handleNewAccess()

        
    }

    modalCallBack = () => {
        this.setState({ showUploadModal: false });
      };

    handleNewAccess = () => {
        const { authReducerState } = this.props;
        const { NewTabList, NewTabList1 } = this.state;

        let tabList = [
            { text: 'Summary', value: 'SUMMARY' , idx : 0},
            { text: 'New BU 1', value: 'New BU 1' , idx : 1},
            { text: 'New BU 2', value: 'New BU 2' , idx : 2},
            { text: 'Validation to Destination P&L', value: 'Validation to Destination P&L' , idx : 3},
            { text: 'Excel Report Validation', value: 'Excel Report Validation' , idx : 5},
            { text: 'Total Sales Report', value: 'Total Sales Report' , idx : 4},
            { text: 'BU 4 Report', value: 'BU 4 Report' , idx : 6}

            // { text: 'DGS Automation', value: 'DGS Automation (Under Build)' },
        ]

        let finalTabList = []

        if (authReducerState &&
            authReducerState.userData &&
            authReducerState.userData.new_access &&
            authReducerState.userData.new_access.group &&
            authReducerState.userData.new_access.group.length) {
            let isAllGroups = false;
            authReducerState.userData.new_access.group.some(groupItem => {
                if (groupItem.value == 'All Groups') {
                    isAllGroups = true;
                    return true;
                }
                finalTabList.push(tabList.find(x => x.value == groupItem.value));
                return false;
            })
            if (isAllGroups) {
                finalTabList = [...tabList];
            }
        } else {
            finalTabList = [...tabList];
        }


        let tabList1 = [
            { text: 'Summary', value: 'Monthly P&L', idx : -1 },
            { text: 'AOP & Forecast', value: 'AOP & Forecast', idx : 1 },
            // { text: 'XPNA', value: 'XPNA', idx : 2 },
            { text: 'Data Browser', value: 'Data Browser' , idx : 0},
            { text: 'Analytics (Mockup Only)', value: 'Analytics (Mockup Only)' , idx : 4},
            // { text: 'DGS Automation', value: 'DGS Automation (Under Build)' },
        ]

        let finalTabList1 = []

        if (authReducerState &&
            authReducerState.userData &&
            authReducerState.userData.new_access &&
            authReducerState.userData.new_access.group &&
            authReducerState.userData.new_access.group.length) {
            let isAllGroups = false;
            authReducerState.userData.new_access.group.some(groupItem => {
                if (groupItem.value == 'All Groups') {
                    isAllGroups = true;
                    return true;
                }
                finalTabList1.push(tabList1.find(x => x.value == groupItem.value));
                return false;
            })
            if (isAllGroups) {
                finalTabList1 = [...tabList1];
            }
        } else {
            finalTabList1 = [...tabList1];
        }

        this.setState({ NewTabList: finalTabList, NewTabList1 : finalTabList1})


    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    refreshChart = () => {
        this.setState({
          chartDataSO: this.props.incomeStatementState.pivotRowDataChart,
          additionalData :  this.props.incomeStatementState.additionalData,
          TableDataSO: this.props.incomeStatementState.pivotRowData,
          HeaderTableDataSO: this.props.incomeStatementState.pivotHeaderData,
        })
      }
    componentDidUpdate(prevProps) {
        const { yearFilterArr, defaultYear, defaultMonth,monthFilterArr, lastIngestion, radioArr } = this.props.incomeStatementState;
        const {  tabSelectorValue1, isXPNA, radioSelectorValue , yearSelectorValue, monthSelectorValue,qtrSelectorValue} = this.state
        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {
         
               
                this.setState({
                    invoiceDetailStateNew : this.props.incomeStatementState.invoiceDetailState,
                    invoiceDetailFilter : this.props.incomeStatementState.invoiceDetailStateFilter,
                    showInvoiceDetailModal: true,
                    showLoader: false
                })
         
          
           
        }

        if (prevProps.incomeStatementState.NewUpdateLoading && !this.props.incomeStatementState.NewUpdateLoading) {
            const { invoiceObject } = this.state;
            let payload = { ...invoiceObject, 
            }
            payload["sort"] = this.sortParam
            this.handleInvoiceClick(payload);
        }

        if (prevProps.incomeStatementState.NewCommentChartLoader && !this.props.incomeStatementState.NewCommentChartLoader) {
            const { invoiceObject } = this.state;
            let payload = { ...invoiceObject, 
            }
            payload["sort"] = this.sortParam
            this.handleInvoiceClick(payload);
            this.toggleCommentModal()
        }
        if (prevProps.incomeStatementState.chartModalLoading && !this.props.incomeStatementState.chartModalLoading){
            this.setState({
                invoiceDetailStateNew : this.props.incomeStatementState.invoiceDetailState,
                invoiceDetailFilter : this.props.incomeStatementState.invoiceDetailStateFilter,
                showInvoiceDetailModalChart: true,
                showLoader: false
            })
        }
        if (prevProps.incomeStatementState.chartLoading && !this.props.incomeStatementState.chartLoading){
   
            this.refreshChart()
        }
       
        if (prevProps.incomeStatementState.monthFilterArr.length != monthFilterArr.length) {
            this.handleNewAccess()
            if(prevProps.incomeStatementState.monthFilterArr.length == 0){
                this.setState({
                    yearSelectorValue: "" ,
                    monthSelectorValue : defaultMonth ,
                    // lastIngestion: lastIngestion ? format(new Date(lastIngestion.toString()), "MM-dd-yy HH:mm:ss") : '',
                })
            }

         
        }

        // if ((prevProps.incomeStatementState.defaultMonth == ""  && defaultMonth != "") && monthFilterArr.length == 0) {
        //     if(this.state.isXPNA == "true"){
        //         this.handleTabChange1(null, 2)
        //     }
        // }



        if (radioArr && radioArr.length > 0){
            let firstData = radioArr[ radioArr.length -1 ].label
            if(this.state.radioDataSample != firstData){
                this.setState({ radioData : radioArr, radioDataSample : firstData })
            }
        }

       if(prevProps.incomeStatementState.saveSuccess != this.props.incomeStatementState.saveSuccess){
        this.handleFetchNewData()
        this.setState({showUploadModal: false})
       }
    }

    handleRadioChange = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        let defaultRadio = "summary"
        if(this.state.tabSelectorValue == 1){
            defaultRadio = "New BU 1"
        }else  if(this.state.tabSelectorValue == 2){
            defaultRadio = "New BU 2"
        }        
        let    radioSelectorValueMPRValue = "summary"
        
        this.setState({
            radioArrSelected : "Default",
            radioType : newValue == 5 ?"hemisphere" : "region", 
            radioSelectorValue: newValue,
            hemisphereClicked: {},
            radioSelectorValueSO : defaultRadio,
            radioSelectorValueMPR : radioSelectorValueMPRValue,
            // qtrSelectorValue: '',
            // monthSelectorValue: '',
            // yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };

    handleRadioChangeTotaLSalesReport = (event) => {
        
        this.setState({
            radioSelectorTotalSales : event.target.value,
        }, () => {
            this.handleFetchNewData()
        })

    };

    handleRadioChangeLE = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValueLE: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangeSO = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValueSO: event.target.value,
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangeMPR = (event) => {
        const { defaultYear } = this.props.incomeStatementState
        this.setState({
            radioSelectorValueMPR: event.target.value,
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleRadioChangePlant = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorValuePlant: event.target.value,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })

    };
    handleDynamicRadioArrCountry = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
            // let defaultMonth = ""
            // if (val == "lawson"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthLawson
            // }else if (val == "slbsap"){
            //     defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
            // } else {
                // defaultMonth = this.props.incomeStatementState.defaultMonth
             
            // }
            this.setState({
                radioType : "country_name",
                radioArrCountrySelected: val ,
                // monthSelectorValue : defaultMonth,
                // qtrSelectorValue: '',
                // yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleDynamicRadioArr = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
           
            this.setState({
                radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
                radioArrSelected: val ,
                radioArrCountrySelected : "Default",
               
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleRadioDGSAutomation = (event) => {
        const { defaultYear } = this.props.incomeStatementState
            let val =  event.target.value
            let defaultMonth = ""
            if (val == "lawson"){
                defaultMonth = this.props.incomeStatementState.defaultMonthLawson
            }else if (val == "slbsap"){
                defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
            } else {
                defaultMonth = this.props.incomeStatementState.defaultMonth
             
            }
            this.setState({
                radioSelectorValueDGSAutomation: val ,
                monthSelectorValue : defaultMonth,
                radioSelectorDynanicDGS : 'Total',
                qtrSelectorValue: '',
                yearSelectorValue: '',
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    };
    handleDynamicRadioDGSAutomation = (event) => {
        const { defaultYear } = this.props.incomeStatementState

        this.setState({
            radioSelectorDynanicDGS: event.target.value,
            qtrSelectorValue: '',
            yearSelectorValue:  '',
            excludeDataFilter: []
        }, () => {
            this.handleFetchNewData()

        })
    };
    handleRadioReconChange = (event) => {
        const { defaultYear, tabSelectorValue } = this.props.incomeStatementState
// dateDefault = this.props.incomeStatementState.monthFilterArr[0]
        // if(event.target.value == 0){
          
            let val = event.target.value
            if (event.target.value == 4  ){
                let val2 =  this.state.radioSelectorValueDGSAutomation
                let defaultMonth = ""
                if (val2 == "lawson"){
                    defaultMonth = this.props.incomeStatementState.defaultMonthLawson
                }else if (val2 == "slbsap"){
                    defaultMonth = this.props.incomeStatementState.defaultMonthSLBSAP
                } else {
                    defaultMonth = this.props.incomeStatementState.defaultMonth
                 
                }
                this.setState({
                    monthSelectorValue : defaultMonth,
                    // tabSelectorValue: 5,
                    yearSelectorValue :  '',
                    isDetailedView: false,
                    radioSelectorReconValue:val,
                    radioSelectorValue: '0',
                    hemisphereClicked: {}
                }, () => {
                    this.handleFetchNewData()
                })
            }else if (event.target.value == 5  ){
                                   this.setState({
                        monthSelectorValue :this.props.incomeStatementState.defaultMonthOptimus &&
                        this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonthOptimus : '' ,
                        // tabSelectorValue: 5,
                        yearSelectorValue :  '',
                        isDetailedView: false,
                        radioSelectorReconValue:val,
                        radioSelectorValue: '0',
                        hemisphereClicked: {}
                    }, () => {
                        this.handleFetchNewData()
                        
                    })
            }else if (event.target.value == 6  ){
               
                // console.log(this.props.incomeStatementState.defaultMonth, "checkDefaultMonth")
                this.setState({
                    monthSelectorValue :this.props.incomeStatementState.defaultMonth &&
                    this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonth : '' ,
                    radioSelectorReconValue:val,
                    yearSelectorValue : ''
                }, () => {
                    this.handleFetchNewData()
                    
                })
        }else if (event.target.value == 7  ){
            let defaultRadio = "summary"
            if(this.state.tabSelectorValue == 1){
                defaultRadio = "New BU 1"
            }else  if(this.state.tabSelectorValue == 2){
                defaultRadio = "New BU 2"
            }

            this.setState({
                radioArrSelected : "Default",
                radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
                radioSelectorValue: "2",
                hemisphereClicked: {},
                radioSelectorValueSO : defaultRadio,
                radioSelectorReconValue:val,
                // qtrSelectorValue: '',
                // monthSelectorValue: '',
                // yearSelectorValue: defaultYear,
                excludeDataFilter: []
            }, () => {
                this.handleFetchNewData()
    
            })
    
            // this.setState({
            //     radioArrSelected : "Default",
            //     radioType : this.state.radioSelectorValue == 5 ?"hemisphere" : "region", 
            //     radioSelectorValue: 2,
            //     hemisphereClicked: {},
            //     radioSelectorValueSO : defaultRadio,
            //     monthSelectorValue :this.props.incomeStatementState.defaultMonthOptimus &&
            //     this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonthOptimus : '' ,
            //     // tabSelectorValue: 5,
            //     yearSelectorValue :  '',
            //     isDetailedView: false,
            //     radioSelectorReconValue:val,
            //     // qtrSelectorValue: '',
            //     // monthSelectorValue: '',
            //     // yearSelectorValue: defaultYear,
            //     excludeDataFilter: []
            // }, () => {
            //     this.handleFetchNewData()
    
            // })
        }else{
               
                    this.setState({
                        radioSelectorReconValue: val,
                        monthSelectorValue: '',
                        yearSelectorValue : this.props.incomeStatementState.defaultYear
                        //     radioSelectorValue: '0'
                    }, () => {
                        this.handleFetchNewData()
                    })
               
            }
      
        // }else{
        // this.setState({
        //     radioSelectorReconValue: event.target.value,
        // })
        // }
        // this.setState({
        //     radioSelectorValue: event.target.value,
        //     qtrSelectorValue: '',
        //     monthSelectorValue: '',
        //     yearSelectorValue: defaultYear,
        //     excludeDataFilter: []
        // }, () => {
        //     this.handleFetchNewData()

        // })

    };

    handleNewRadioChange = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        this.setState({
            newRadioSelectorValue: newValue,
            qtrSelectorValue: '',
            monthSelectorValue: '',
            yearSelectorValue: defaultYear,
            excludeDataFilter: []
        })
    };


    handleMonthChange = (event, value) => {
        const {tabSelectorValue1} = this.state

        if (event.target) {
            this.setState({
                monthSelectorValue: value,
                qtrSelectorValue: '',
                yearSelectorValue: '',
                manuallyChanged: true
            }, () => {
                if (tabSelectorValue1 != 1) {
                    this.handleFetchNewData()
                }else{
                    this.handleFetchAOPData()
                }
            });
        }
    };

    handleQtrChange = (event, value) => {
        const {tabSelectorValue1} = this.state
        if (event.target) {
            this.setState({
                qtrSelectorValue: value,
                monthSelectorValue: '',
                yearSelectorValue: '',
                manuallyChanged: true

            }, () => {
                if (tabSelectorValue1 != 1) {
                    this.handleFetchNewData()
                }else{
                    this.handleFetchAOPData()
                }            
            })
        }
    };

    handleYearChange = (event, value) => {
        const {tabSelectorValue1} = this.state

        if (event.target) {
            this.setState({
                yearSelectorValue: value,
                qtrSelectorValue: '',
                monthSelectorValue: '',
                manuallyChanged: true
            }, () => {
                if (tabSelectorValue1 != 1) {
                    this.handleFetchNewData()
                }else{
                    this.handleFetchAOPData()
                }
            })
        }
    };

    saveNewBUFourTargetCommentFn = (data) => {
        const {yearSelectorValue, qtrSelectorValue, monthSelectorValue} = this.state
        let payload = {
            ...data,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
        }

        this.props.saveNewBUFourTargetCommentData(payload)
    }

    handleFetchNewData = (isExport) => {
        const {radioSelectorTotalSales, yearSelectorValue, tabSelectorValue, tabSelectorValue1,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, radioSelectorValueMPR,radioSelectorValueSO,  radioArrSelected,radioArrCountrySelected, radioSelectorReconValue,radioSelectorValuePlant, radioSelectorValueLE, radioSelectorDynanicDGS, radioSelectorValueDGSAutomation, hemisphereClicked } = this.state;

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            // isTotal: tabSelectorValue == 3 && radioSelectorReconValue != 7,
            isTotal: false,
            validation : tabSelectorValue == 3,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            radioArrSelected :radioArrSelected,
            radioArrCountrySelected : radioArrCountrySelected, 
            dgsAutomationType : radioSelectorValueDGSAutomation,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue1 == -1 ? tabSelectorValue1 :  tabSelectorValue,
            tabValueParent : tabSelectorValue1,
            export: isExport ? true: false,
            radioSelectorValueLE: radioSelectorValueLE,
            radioSelectorTotalSales : radioSelectorTotalSales,
            radioSelectorDynanicDGS : radioSelectorDynanicDGS,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined,
            buPlant : radioSelectorValuePlant,
            classificationSO : radioSelectorValueSO,
            mprsecondradio : radioSelectorValueMPR,
            mprfirstradio : radioSelectorValue,
            origination : radioArrSelected == "Default"? "true" : "false",
        }
        // console.log(payload, "cekPaylaod")
        if(radioSelectorReconValue == 4) {
            this.props.getDGSLawson(payload)
          
        }else if(radioSelectorReconValue == 5) {
            this.props.getOptimusAutomation(payload)
        }else if(radioSelectorValue == 6 && tabSelectorValue1 == 0) {
            this.props.getChartSO(payload)
        }else if(tabSelectorValue1 == 2 ){
            if(radioSelectorValue == 0){
                this.props.getNewBUFourTargetData(payload)
            }else if (radioSelectorValue == 1){
                this.props.getNewBUFourByLocationData(payload)
            }else if (radioSelectorValue == 2){
                this.props.getNewBUFourByPMData(payload)
            }
        }
        else if(tabSelectorValue == 6 && tabSelectorValue1 == 0){
            this.props.getNewBUFourData(payload)
        }
        else{
            this.props.getNewPivotData(payload)
        }

        if ((tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && (radioSelectorValue == 3 || radioSelectorValue == 4 || radioSelectorValue == 5)) {
            if(radioArrSelected == "Default"){
                this.fetchNewPieDataFn()
            }
        }

    }
    handleTabChange1 = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        const { isXPNA , radioSelectorValue} = this.state
        if (newValue == -1 ){
            
            this.setState({
                tabSelectorValue1: newValue,
                isDetailedView: false,
                radioSelectorReconValue: 'All',
                radioSelectorValue: '0',
                tabSelectorValue: 0,
                radioSelectorValueMPR: 'summary',
                hemisphereClicked: {}
            }, () => {
                this.handleFetchNewData()
            })
        }else{
                if(newValue == 1){
                    let aopYear = this.props.incomeStatementState.defaultYearAOP
                    this.setState({
                        yearSelectorValue : aopYear, monthSelectorValue : '', qtrSelectorValue : '',
                        tabSelectorValue1: newValue,
                        isDetailedView: false,
                        radioSelectorReconValue: 'All',
                        radioSelectorValue: '0',
                        radioSelectorValueMPR: 'summary',
                        hemisphereClicked: {},
                    }, () => {
                        if(newValue != 1){
                            this.handleFetchNewData()
                        }
                    })
                }else{
                    this.setState({
                        tabSelectorValue1: newValue,
                        isDetailedView: false,
                        radioSelectorReconValue: 'All',
                        radioSelectorValue: isXPNA == "true" ? radioSelectorValue :  '0',
                        tabSelectorValue: 0,
                        radioSelectorValueMPR: 'summary',
                        hemisphereClicked: {},
                    }, () => {
                        if(newValue != 1){
                            this.handleFetchNewData()

                        }
                    })
            }
       
        }
       
    }
    handleTabChange = (event, newValue) => {
        const { defaultYear } = this.props.incomeStatementState
        if (newValue == 6 ){
            this.setState({
                monthSelectorValue : this.props.incomeStatementState.defaultMonth &&
                this.props.incomeStatementState.monthFilterArr.length ? this.props.incomeStatementState.defaultMonth : '' ,
                tabSelectorValue: newValue ,
                yearSelectorValue :  '',
                isDetailedView: false,
                radioSelectorReconValue: 'All',
                radioSelectorValue: '0',
                hemisphereClicked: {}
            }, () => {
                this.handleFetchNewData()
            })
        }else{
            this.setState({
                tabSelectorValue: newValue,
                isDetailedView: false,
                radioSelectorReconValue: 'All',
                radioSelectorValue: newValue == -1 ? '1' :'0',
                hemisphereClicked: {}
            }, () => {
                this.handleFetchNewData()
            })
        }
       
    }
    handleSaveDrilldownChanges = (payload) => {
        this.props.saveDrilldownChanges(payload)
    }

    // handlePropsInvoiceClickOrigination  = (payload) => {
    //     const { qtrSelectorValue, radioSelectorReconValue, radioArrSelected, radioArrCountrySelected, radioType, radioSelectorValueLE, monthSelectorValue, yearSelectorValue, radioSelectorValue, tabSelectorValue, radioSelectorValueDGSAutomation} = this.state
    //     let drilldownPayload = {
    //         ...payload,
    //         sub_tab_value: subTab,
    //         year: yearSelectorValue ? yearSelectorValue : undefined,
    //         quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
    //         yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
    //         tab_value: tabValue,
    //         location_radio_value : radioArrCountrySelected == "Default"? radioArrSelected : radioArrCountrySelected,
    //         location_radio : radio,
    //         typeSelector: "Summary",
    //         view: "month",
    //         origination : "true"
    //     }
    //     this.setState({ 
    //         invoiceObject: payload
    //     })

    //     this.props.fetchNewDrilldownData(drilldownPayload)
    // }

   

    handleThreeLevelInvoiceClick = (payload) => {
        const { qtrSelectorValue,radioSelectorValueMPR,  monthSelectorValue, yearSelectorValue, radioSelectorValue} = this.state
        let drilldownPayload = {
            ...payload,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            origination :  "true",
            classification : this.MPLClassificationMap[radioSelectorValue],
            hemisphere : radioSelectorValueMPR && this.MPLDrilldownMap[radioSelectorValueMPR] ? this.MPLDrilldownMap[radioSelectorValueMPR]: radioSelectorValueMPR,
        }

        this.props.fetchNewOriginationDrilldownData(drilldownPayload)
    }

    handleBUFourDrilldownData = (isExport = false) => {

        let payload = {
            ...this.BUFourDrilldownObj,
            export: isExport
        }
        this.props.fetchBUFourDrilldownData(payload)

    }

    handleInvoiceClick = (payload) => {

        const { qtrSelectorValue, radioSelectorReconValue, tabSelectorValue1, mprsecondradio,radioSelectorValueMPR, radioArrSelected, radioArrCountrySelected, radioType, radioSelectorValueLE, monthSelectorValue, yearSelectorValue, radioSelectorValue, tabSelectorValue, radioSelectorValueDGSAutomation} = this.state
        let subTab
        let radio = radioType
        if (radioSelectorValue == 2) {
            subTab = radioSelectorValueLE
        } else {
            subTab = this.radioMap[radioSelectorValue]
        }
        if (tabSelectorValue == -1) {
            subTab = "summary"
        }
        let tabValue
        if (tabSelectorValue == 3 && radioSelectorReconValue == 2) {
            tabValue = "summary"
            subTab = "summary"
        }else if (tabSelectorValue == 3 && payload.sub_grid_header_value == "new"){
            tabValue = "validation"
            subTab = "default"
        }else if (tabSelectorValue == 3 && (radioSelectorReconValue == 4 || radioSelectorReconValue == 5)){
            subTab = payload.sub_tab_value
        }else{
            tabValue = "summary"
        }
        if (radioSelectorValue == 6 && tabSelectorValue1 == 0 ){
            subTab = "so_chart"
            radio = ""
        }
        // console.log(tabValue,subTab,tabSelectorValue,payload.sub_grid_header_value "checkSelector")

        let drilldownPayload = {
            ...payload,
            sub_tab_value: subTab,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            tab_value: tabValue,
            location_radio_value : radioArrCountrySelected == "Default"? radioArrSelected : radioArrCountrySelected,
            location_radio : radio,
            typeSelector: "Summary",
            view: "month",
            origination : "true",

        }
        if(tabSelectorValue == -1){
            drilldownPayload["mpr"] = true
            let radioSelectorVal= radioSelectorValue
            let bu = ""
            let classf = ""
            // if(radioSelectorValueMPR != "summary" && radioSelectorValue == '0'){
            //     drilldownPayload["hemisphere"] = radioSelectorValueMPR == "wh"?  "West" :"East"
            // }else{
                if(payload.level_values == "West" || payload.level_values == "East") {
                    drilldownPayload["hemisphere"] = payload.level_values
                    drilldownPayload["level_values"] = payload.parent
                    drilldownPayload["parent"] = ""
                }else{
                    drilldownPayload["hemisphere"] = payload.level
                    // drilldownPayload["level"] = payload.level
                    drilldownPayload["level_values"] =payload.level_values
                    drilldownPayload["parent"] =  payload.parent
                }
                
                let arrIdx = [
                    {idx : "3", val : "BU 1"}, 
                    {idx : "4", val : "BU 2"},
                    {idx : "5", val : "BU 3"},
                    {idx : "6", val : "BU 4"},
                    {idx : "7", val : "OH"},

                ]
                if(payload.grid_header == "BU 1" || payload.grid_header == "BU 2" || payload.grid_header == "BU 3" || payload.grid_header == "BU 4" || payload.grid_header == "OH") {
                    drilldownPayload["grid_header"] = "new"
                    drilldownPayload["grid_header_value"] = "new"
                    drilldownPayload["sub_grid_header_value"] = "new"
                    arrIdx.map(x => {
                      
                        if(x.val == payload.grid_header){
                            radioSelectorVal = x.idx
                        }
                    })
                    if(radioSelectorValueMPR != "summary" && radioSelectorValue != -1){
                        let valHeader = radioSelectorValue == 1 ?"New BU 1" : "connected_products"
                        drilldownPayload["grid_header"] = valHeader
                        drilldownPayload["grid_header_value"] = valHeader
                        drilldownPayload["sub_grid_header_value"] = valHeader
                    }
                }
                if(radioSelectorValueMPR != "summary"){
                    drilldownPayload["hemisphere"] = radioSelectorValueMPR == "wh"?  "West" :"East"
                }
                if(payload.grid_header == "total_legacy" ) {
                    drilldownPayload["grid_header"] = "new"
                    drilldownPayload["grid_header_value"] = "new"
                    drilldownPayload["sub_grid_header_value"] = "new"
                    // bu = "all"
                }
            // }
          
            // if(radioSelectorValue>2){
               
           
                switch (radioSelectorVal){
                    case "0" :
                        classf += `Summary`
                        break;
                    case "1" :
                        classf = `New BU 1`
                        break;
                    case "2" :
                        classf = `New BU 2`
                        break;
                    case "3" :
                        bu = `BU 1`
                    break;
                    case "4" :
                        bu = `BU 2`
                    break;
                    case "5" :
                        bu = `BU 3`
                    break;
                    case "6" :
                        bu = `BU 4`
                    break;
                    case "7" :
                        bu = `OH`
                    break;
                  }
                if (bu != ""){
                    drilldownPayload["bu"] =  bu
                }else if (classf != "" && classf != "Summary" && classf != "Summary" && classf != "Summary" ){

                    drilldownPayload["classification"] =  classf
                }
            // }
        
           
        }
        this.setState({ invoiceObject: payload
        })

        if (tabSelectorValue == 5){
            drilldownPayload["excel_report"] = "true"
        }

        if (tabSelectorValue1 == 2) {
            drilldownPayload = {
                ...payload,
                year: yearSelectorValue ? yearSelectorValue : undefined,
                quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
                yearmonth: monthSelectorValue ? monthSelectorValue : undefined
            }
            this.BUFourDrilldownObj = drilldownPayload;

            this.handleBUFourDrilldownData()
            return
        }
        
        if(radioSelectorReconValue == "6"){
            // this.props.getDropDown(drilldownPayload)
            this.props.getPABValidationdrilldown(drilldownPayload)
            return
        }
     
        if(subTab == "optimus_automation"){
            // this.props.getDropDown(drilldownPayload)
            this.props.getOptimusAutoArilldown(drilldownPayload)
        }else if (tabSelectorValue == 3 && radioSelectorReconValue == 4) {
            // console.log("enterHere")
            this.props.getLawsonDetail(drilldownPayload);
        } else if (payload.sub_grid_header_value == "current") {
            this.props.getBUPLInvoiceDetail(drilldownPayload);
        }else  if (radioSelectorValue == 6 && tabSelectorValue1 == 0){
            // console.log("apakah masuk",radioSelectorValue , tabSelectorValue1)
            this.props.fetchNewDrilldownChartData(drilldownPayload)
    
        }else {
           
            this.props.fetchNewDrilldownData(drilldownPayload)
        }
    }

    handleBackClick = () => {
        this.setState({
            isDetailedView: false,
            radioArrCountrySelected : "Default",
            radioSelectorValue: this.state.hemisphereClicked.label ? '5' : '3',
            regionClicked: {},
            hemisphereClicked: {}
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleRegionHeaderClick = (payload) => {
        this.setState({
            isDetailedView: true,
            radioSelectorValue: '4',
            regionClicked: payload,
            hemisphereClicked: {}
        }, () => {
            this.handleFetchNewData()
        })
    }
    handleSubHeaderClick = (payload, parentData) => {
        const { qtrSelectorValue, radioSelectorValue, monthSelectorValue, yearSelectorValue, tabSelectorValue} = this.state

        let drilldownPayload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            subHeader : payload.key,
            header: parentData.key,
            subHeaderLabel : payload.label,
            headerLabel: parentData.label,
            radioSelectorValue: radioSelectorValue,
            tabSelectorValue: tabSelectorValue,
        }
        this.setState({
            showLoader: true,
        }, () => {
        this.props.fetchNewSubHeaderDrilldownData(drilldownPayload)
        })
        // this.setState({
        //     isDetailedView: true,
        //     radioSelectorValue: '4',
        //     regionClicked: payload,
        //     hemisphereClicked: {}
        // }, () => {
        //     this.handleFetchNewData()
        // })
    }
    
    handleHemisphereHeaderClick = (payload) => {
        this.setState({
            // isDetailedView: true,
            radioSelectorValue: '3',
            hemisphereClicked: payload
        }, () => {
            this.handleFetchNewData()
        })
    }

    handleToolTipValue = (index) => {
        let reactComp = (
            <div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p className="mb-0">New orders trend vs revenue is indicative of future growth.</p>
                    <p className="mt-0">Filters allow for focus on respective unit of performance.</p>
                    <p><i>*No of months sales in Orderbook can also be used as a measure.</i></p>
                </div>
                <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                    Data required:<br></br>
                    New bookings data for BU 1 and BU 2 businesses appears to be available on the BI reports, this needs to be confirmed and new bookings data for PAB and DGS will be required to enable this chart.
                </div>
            </div>
        );
        if (index == 1) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart</h3>
                        <p> Margin growth analysis, to make sure that:</p>
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>Inflationary cost pressures as a minimum are being passed on the client.</li>
                            <li>Improvement in products and new products are returning higher margins.</li>
                            <li>Product mix is managed to deliver higher margins vs otherwise.</li>
                        </ol>
                        <p>This analysis can be supplemented with a Volume Price analysis by Products and Customers to enable above strategies.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales and COGS data by Customer and Product will be required to enable additional Price Volume analysis to support standard margin management.
                    </div>
                </div>
            );
        }
        if (index == 2) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <h3>Thinking behind the chart:</h3>
                    <p>Other costs of sales is most likely fixed costs, and should be monitored to improve as a % of sales with efficiencies or sales growth.</p>
                </div>);
        }
        if (index == 3) {
            reactComp = (
                <div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                        <h3>Thinking behind the chart:</h3>
                        <p>Lead in to market activity / share information.</p>
                    </div>
                    <div className='NewAnalyticsBoxDesign mat-shadow-2 mt-16'>
                        Data required:<br></br>
                        Sales by Customer will be required to enable this chart.
                    </div>
                </div>
            );
        }
        if (index == 4) {
            reactComp = (
                <div className='NewAnalyticsBoxDesign mat-shadow-2'>
                    <p className='mt-0'>
                        Provides overview of key focus areas at a glance.<br></br>Digital solutions can be quickly built to improve performance in areas that are lagging.
                    </p>
                    <p>
                        An automated follow-up email can be sent to respective locations / metrics owners with a cc to the Senior Management Team.<br></br>Refer Overdue AR Automated Follow-up Solution as an example.
                    </p>
                </div>
            )
        }
        return reactComp
    }

    handleFetchAOPData = (isExport) => {
        const {aopTabState, monthSelectorValue, yearSelectorValue, qtrSelectorValue} = this.state
        const payload = {
            ...aopTabState,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
			year: yearSelectorValue ? yearSelectorValue : undefined,
			quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            export: isExport
        }
        this.props.getAOPTableData(payload)
    }

    handleExcelDownload = () => {
        const { yearSelectorValue, tabSelectorValue, tabSelectorValue1, aopTabState,
            qtrSelectorValue, radioSelectorValue,
            monthSelectorValue, radioSelectorReconValue,radioSelectorValueMPR,radioSelectorValueSO, regionClicked, radioSelectorValuePlant, radioSelectorValueLE, radioArrSelected, radioArrCountrySelected, radioSelectorDynanicDGS,radioSelectorValueDGSAutomation, hemisphereClicked } = this.state;

        let payload = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            // isTotal: radioSelectorReconValue == 2 ? false : tabSelectorValue == 3,
            isTotal: false,
            validation : tabSelectorValue == 3,
            export: true,
            reconValue: radioSelectorReconValue,
            apiEndPoint: radioSelectorValue,
            tabValue: tabSelectorValue1 == -1 ? tabSelectorValue1 : tabSelectorValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            countries : radioSelectorDynanicDGS,
            dgsAutomationType :radioSelectorValueDGSAutomation,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined,
            locationRadio : radioArrSelected,
            locationRadioCountry : radioArrCountrySelected,
            radioSelectorValueLE: radioSelectorValueLE,
            buPlant : radioSelectorValuePlant,
            classificationSO : radioSelectorValueSO,
            mprsecondradio : radioSelectorValueMPR,
            mprfirstradio : radioSelectorValue,
            origination : radioArrSelected == "Default"? "true" : "false",
        }

        if (tabSelectorValue1 != 1) {
            this.handleFetchNewData(true)
        }else {
            this.handleFetchAOPData(true)
        }

        // this.props.downloadNewPivotData(payload)
    }
    invoiceModalCallBack = () => {
        if(this.state.refreshOnModalClose){
            this.handleFetchNewData()
        }

        this.setState({ arrayDataSelected : [], showInvoiceDetailModal: false, showInvoiceDetailModalChart: false, isInvoiceDetailModal: false, refreshOnModalClose : false });
    }

    fetchNewPieDataFn = () => {

        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, hemisphereClicked } = this.state;
        let payload = {
            tabValue: tabSelectorValue,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            apiEndPoint: radioSelectorValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            hemisphereClicked : hemisphereClicked.label ? hemisphereClicked.label : undefined

        }

        this.props.fetchNewPieData(payload)

    }

    handlePostManualData = (data) => {
        const { yearSelectorValue, tabSelectorValue,
            qtrSelectorValue, radioSelectorValue, regionClicked,
            monthSelectorValue, radioSelectorReconValue } = this.state;

        let mainData = {
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
            isTotal: tabSelectorValue == 3,
            apiEndPoint: radioSelectorValue,
            reconValue: radioSelectorReconValue,
            region: radioSelectorValue == 4 ? regionClicked.label : undefined,
            tabValue: tabSelectorValue
        }

        let payload = {
            manualData: { ...data },
            mainData
        }
        this.props.postNewManualAmountData(payload)
    }

    setAOPTabState = (payload) => {
        this.setState({
            aopTabState: payload
        })
    }


    handleUploadExcel = () => {
        this.setState({showUploadModal: true})
    }

    handleFileUploadSave = (data) => {
        const { monthSelectorValue, yearSelectorValue, qtrSelectorValue, tabSelectorValue1} = this.state
        const payload = {
            ...data,
            report_date: monthSelectorValue ? monthSelectorValue : yearSelectorValue ? yearSelectorValue : qtrSelectorValue ? qtrSelectorValue : '',
            tabSelectorValue1,
            year: yearSelectorValue ? yearSelectorValue : undefined,
            quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
            yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
        }
        this.props.uploadFileData(payload)
      }

    handlePostCommentData = (data) => {
        
        this.props.postNewComments(data)
        // console.log(data)
    }

    postOSOriginationCommentData = (data) => {
        const {monthSelectorValue, qtrSelectorValue} = this.state

        const payload = {
            ...data,
            report_date: monthSelectorValue ? monthSelectorValue : qtrSelectorValue,
            // comment_type: monthSelectorValue ? 'monthly' : "quarterly", 
        }

        this.props.postOSOriginationCommentData(payload)
    }

    handleChangeView = (event) => {
        // const updatedData = this.state.TableDataDSOSummary.filter(x => x.netSalesedited || x.osedited).length > 0 ? true : false
        // if (!updatedData) {
          this.setState({ GraphView: event.target.checked }, this.refreshChart);
        // } else {
        //   this.setState({ showExitModal: true, firstLevelSwitchValue: event.target.checked })
        // }
      };
      toggleCommentModal = (rowData) => {
        let visib = !this.state.commentModal

        this.setState({ commentModal: visib, commentValue: '' , selectedDrilldown: rowData})
    }

    handleCommentSubmitChart = () => {
        // console.log(this.state.selectedDrilldown, "--", this.state.commentValue, "-- 0101")
        let {selectedDrilldown} = this.state
        let bu = selectedDrilldown.bu
        let yearmonth =  selectedDrilldown.yearmonth_new.split("T")[0]
        if(bu == "BU 1"){
            bu = "BU 1"
        }
        if (this.state.commentValue) {
            let commentPayload = {
                comment : this.state.commentValue,
                bu : bu,
                report_date : yearmonth,
                so_item : selectedDrilldown.so_item,
                project_id : selectedDrilldown.project_id,
                comment_id : selectedDrilldown.comment_id,
            }
            
            const { invoiceObject } = this.state;
            let drilldownPayload = {
                    ...invoiceObject
            }

            let payload = {
                comment : commentPayload,
                drilldown : drilldownPayload,
                type : "chart"
            }
            this.handlePostCommentData(payload)
        }
    }

    handleCommentChangeChart = (event) => {
        this.setState({ commentValue: event.target.value })
    }
    render() {
        const { quarterfilterArr, monthFilterArr, yearFilterArr, countries, radioArr, NewCommentChartLoader, saveLoading } = this.props.incomeStatementState

        const { isXPNA, radioSelectorTotalSales, monthSelectorValue,qtrSelectorValue, radioSelectorValueLE, radioArrSelected, radioSelectorValueMPR,radioSelectorValueSO, radioArrCountrySelected, radioSelectorValuePlant, DGAAutomationRadioList, radioSelectorValueDGSAutomation, radioSelectorDynanicDGS, radioSelectorValue, radioSelectorReconValue, newRadioSelectorValue, yearSelectorValue, isDetailedView, tabSelectorValue, tabSelectorValue1, comparativeSelectorValue, NewTabList, NewTabList1, radioData, showUploadModal } = this.state;
        return (
            <div className='buplComponent'>
                <div className='flex'>
                    {
                        <Grid container spacing={3} className={ isXPNA == "true" && radioSelectorValue == "0" ? "mb-10" : "" } style={{alignItems:'center'}}>
                            <Grid item xs={12} sm={2} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Month</InputLabel>
                                    <Select
                                        value={monthSelectorValue}
                                        label="Month"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 2 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleMonthChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={monthFilterArr}
                                    value={monthSelectorValue}
                                    onChange={this.handleMonthChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Month' placeholder='Select'  />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={2} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Quarter</InputLabel>
                                    <Select
                                        value={qtrSelectorValue}
                                        label="Quarter"
                                        // disabled={radioSelectorValue == 4 && (comparativeSelectorValue == 1 || comparativeSelectorValue == 3 || comparativeSelectorValue == 4)}
                                        onChange={this.handleQtrChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {quarterfilterArr && quarterfilterArr.length && quarterfilterArr.map(item => {
                                            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={quarterfilterArr}
                                    value={qtrSelectorValue}
                                    onChange={this.handleQtrChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Quarter' placeholder='Select'  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} className='maxw-200'>
                                {/* <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <InputLabel>Annual</InputLabel>
                                    <Select
                                        value={yearSelectorValue}
                                        label="Annual"
                                        onChange={this.handleYearChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    className='xpna-dropdown single'
                                    options={yearFilterArr}
                                    value={yearSelectorValue}
                                    onChange={this.handleYearChange}
                                    renderInput={(params)=>(
                                        <TextField {...params} variant='standard' label='Year' placeholder='Select'  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className=''>
                            <DynamicColumn 
                             year={yearSelectorValue}
                             quarter={qtrSelectorValue}
                             yearmonth={monthSelectorValue}
                            />
                            </Grid>
                            <Grid item xs={12} sm={2} className='' >
                                <div style={{display:'flex', justifyContent:'flex-end'}} >
                                <Tooltip title={'Download to Excel'} className='pointer'>
                                        <img
                                            src={DownloadExcel}
                                            className='pointer mr-10'
                                            height='30'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.handleExcelDownload();
                                            }} />
                                    </Tooltip>
                                {tabSelectorValue != 6 && <Tooltip title={'Upload Excel'} className='pointer'>
                                        <img
                                            src={FileUploadIcon}
                                            className='pointer'
                                            height='30'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.handleUploadExcel();
                                            }} />
                                    </Tooltip>
                                } 
                                </div>               
                            </Grid>
                        </Grid>
                    }

                </div>
                <div>
                    { isXPNA == "false" &&
                        <Tabs
                            value={tabSelectorValue1}
                            onChange={this.handleTabChange1}
                            indicatorColor="primary"
                            textColor="primary"
                            className='mt-16 mb-16 nested-tabs main-tabs'
                        >
                            {NewTabList1 && NewTabList1.length ?
                                NewTabList1.map((tabItem, index) => {
                                    return tabItem && <Tab value={tabItem.idx} label={tabItem.value} index={tabItem.idx}  {...this.a11yProps(tabItem.idx)} />
                                })
                                : ''
                            }

                        </Tabs>
                    }
                    {tabSelectorValue1 == 0 &&
                        <Tabs
                            value={tabSelectorValue}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className='mt-16 mb-16'
                        >
                            {NewTabList && NewTabList.length ?
                                NewTabList.map((tabItem, index) => {
                                    return <Tab value={tabItem.idx} label={tabItem.value} index={tabItem.idx}  {...this.a11yProps(tabItem.idx)} />
                                })
                            : ''}

                        </Tabs>
                    }
                    <TabPanel value={'a'} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={'b'} index={1}>
                        Item Two
                    </TabPanel>
                    {

                        isDetailedView ?
                            <div className='rowDetails flex ai-center mb-10'>
                                <Button onClick={this.handleBackClick} color="primary" startIcon={<ChevronLeft />}>back</Button>
                                <div>
                                    <h3 className="country-view-info">Country View</h3>
                                </div>
                            </div> :
                            tabSelectorValue1 == -1 ?
                            <React.Fragment>
                            <Tabs
                                value={radioSelectorValue}
                                onChange={this.handleRadioChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className='mt-16 mb-16'
                            >
                                <Tab value="0" label="Summary" index="0"/>
                                <Tab value="1" label="New BU 1" index="1"/>
                                <Tab value="2" label="New BU 2" index="2"/>
                                <Tab value="3" label="BU 1" index="3"/>
                                <Tab value="4" label="BU 2" index="4"/>
                                <Tab value="5" label="BU 3" index="5"/>
                                <Tab value="6" label="BU 4" index="6"/>
                                <Tab value="7" label="OH" index="7"/>

                            </Tabs>  
                            <div className="flex ai-center jc-space-between mb-10">
                                <FormControl component="fieldset">
                                    <RadioGroup name="radio" value={radioSelectorValueMPR} onChange={this.handleRadioChangeMPR} aria-label='radio filter'
							className='tabs-radio'>
                                       <FormControlLabel
                                            value={'summary'}
                                            key={0}
                                            control={<Radio />}
                                            label={'Summary'}
                                        />
                                        <FormControlLabel
                                            value={'wh'}
                                            key={1}
                                            control={<Radio />}
                                            label={'WH'}
                                        />
                                        <FormControlLabel
                                            value={'eh'}
                                            key={2}
                                            control={<Radio />}
                                            label={'EH'}
                                        />
                                        <FormControlLabel
                                            value={'Canada,US'}
                                            key={3}
                                            control={<Radio />}
                                            label={'Total NAM'}
                                        />
                                        <FormControlLabel
                                            value={'Canada'}
                                            key={4}
                                            control={<Radio />}
                                            label={'Canada'}
                                        />
                                        <FormControlLabel
                                            value={'US'}
                                            key={5}
                                            control={<Radio />}
                                            label={'US'}
                                        />
                                         <FormControlLabel
                                            value={'UK AND NORTH EUROPE,RUSSIA AND CASPIAN,SOUTH EUROPE / NA,MEA'}
                                            key={6}
                                            control={<Radio />}
                                            label={'TOTAL EMEA'}
                                        />
                                        <FormControlLabel
                                            value={'UK AND NORTH EUROPE'}
                                            key={7}
                                            control={<Radio />}
                                            label={'UK AND NORTH EUROPE'}
                                        />
                                        <FormControlLabel
                                            value={'RUSSIA AND CASPIAN'}
                                            key={8}
                                            control={<Radio />}
                                            label={'RUSSIA AND CASPIAN'}
                                        />
                                        <FormControlLabel
                                            value={'SOUTH EUROPE / NA'}
                                            key={9}
                                            control={<Radio />}
                                            label={'SOUTH EUROPE/NA'}
                                        />
                                        <FormControlLabel
                                            value={'MEA'}
                                            key={10}
                                            control={<Radio />}
                                            label={'MEA'}
                                        />
                                        
                                        <FormControlLabel
                                            value={'ASIA PACIFIC'}
                                            key={11}
                                            control={<Radio />}
                                            label={'ASIA PACIFIC'}
                                        />
                                       <FormControlLabel
                                            value={'MEX,LAM'}
                                            key={12}
                                            control={<Radio />}
                                            label={'TOTAL LAR'}
                                        />
                                        <FormControlLabel
                                            value={'MEX'}
                                            key={13}
                                            control={<Radio />}
                                            label={'MEX'}
                                        />
                                        <FormControlLabel
                                            value={'LAM'}
                                            key={14}
                                            control={<Radio />}
                                            label={'LAR'}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            </React.Fragment> :


                            tabSelectorValue1 == 0 && tabSelectorValue != 3 && tabSelectorValue != 4 && tabSelectorValue != 5  && tabSelectorValue != 6 && tabSelectorValue != -1 &&
                            
                            <React.Fragment>


                               
                                <FormControl component="fieldset">
                                    <RadioGroup name="radio" value={radioSelectorValue} onChange={this.handleRadioChange} className='tabs-radio mb-16'>
                                        {<FormControlLabel value="0" control={<Radio color="primary" />} label="Summary by Legacy BU" />}
                                        {(tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && <FormControlLabel value="5" control={<Radio color="primary" />} label="Hemisphere by Legacy BU" />}
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Region by Legacy BU" />
                                        {tabSelectorValue == 0 && <FormControlLabel value="1" control={<Radio color="primary" />} label="By Plant" />}
                                        {/* {tabSelectorValue == 0 && <FormControlLabel value="2" control={<Radio color="primary" />} label="By ERP" />} */}
        
                                        {<FormControlLabel value="6" control={<Radio color="primary" />} label="SO's with negative margins" />}
                                    </RadioGroup>
                                   
                                </FormControl>
                               
                                {radioSelectorValue == 6 &&
                                 <div className="flex ai-center jc-space-between mb-10">
                            
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueSO} onChange={this.handleRadioChangeSO} className='radioGroupHorizontal tabs-radio'>
                                            {(tabSelectorValue == 0) &&  <FormControlLabel value="summary" control={<Radio color="primary" />} label="Summary" />  }                                              
                                            {(tabSelectorValue == 0 || tabSelectorValue == 1) &&   <FormControlLabel value="New BU 1" control={<Radio color="primary" />} label="New BU 1" /> }
                                            {(tabSelectorValue == 0 || tabSelectorValue == 2) &&  <FormControlLabel value="New BU 2" control={<Radio color="primary" />} label="New BU 2" /> }
                                            </RadioGroup>
                                        </FormControl>
                               

                                
                                    <div className='ml-20'>
                                        <label>Graph</label>
                                        <Switch
                                        className="custom-switch"
                                        checked={this.state.GraphView}
                                        onChange={this.handleChangeView}
                                        color="primary"
                                        name="toggleTableGraph"
                                        />
                                        <label>Table</label>
                                    </div> 
                                        </div>
                                    }
                                
                               
                                {radioSelectorValue == 1 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValuePlant} onChange={this.handleRadioChangePlant} className='radioGroupHorizontal'>
                                                {/* <FormControlLabel value="summary" control={<Radio color="primary" />} label="MSMT & LCS" /> */}
                                                <FormControlLabel value="BU 1" control={<Radio color="primary" />} label="Default View BU 1" />
                                                {/* <FormControlLabel value="LCS" control={<Radio color="primary" />} label="LCS" /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                }
                                 {radioSelectorValue == 5 || radioSelectorValue == 3   ?
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioArrSelected} onChange={this.handleDynamicRadioArr} className='radioGroupHorizontal'>
                                                        {/* {radioData && radioData.length ?
                                                            radioData.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.key} control={<Radio color="primary" />} label={tabItem.label} />
                                                            })
                                                            : ''} */}
                                                            {<FormControlLabel value="Default" control={<Radio color="primary" />} label="Default View" />}
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div> : ""

                                            }
                               
                            </React.Fragment>                            
                    }                     
                          {isDetailedView == true ?
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioArrCountrySelected} onChange={this.handleDynamicRadioArrCountry} className='radioGroupHorizontal'>
                                                        {/* {radioData && radioData.length ?
                                                            radioData.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.key} control={<Radio color="primary" />} label={tabItem.label} />
                                                            })
                                                            : ''} */}
                                                        {<FormControlLabel value="Default" control={<Radio color="primary" />} label="Default View" />}
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div> : ""

                                            }
                    {tabSelectorValue1 == 2 &&
                                    <div className="flex ai-center jc-flex-end">
                               
                                    {/* <FormControl component="fieldset">
                                        <RadioGroup name="radio" value={radioSelectorValue} onChange={this.handleRadioChange} className='radioGroupHorizontal'>
                                            <FormControlLabel value="0" control={<Radio color="primary" />} label="BU 4 Target" />
                                            <FormControlLabel value="1" control={<Radio color="primary" />} label="BU 4 Summary by Location" />
                                            <FormControlLabel value="2" control={<Radio color="primary" />} label=" BU 4 Summary by PM" />
                                        </RadioGroup>
                                    </FormControl> */}
                                    { (radioSelectorValue == "1" || radioSelectorValue == "2") &&
                                        <div className='ml-20'>
                                        <label>Graph</label>
                                        <Switch
                                        className="custom-switch"
                                        checked={this.state.GraphView}
                                        onChange={this.handleChangeView}
                                        color="primary"
                                        name="toggleTableGraph"
                                        />
                                        <label>Table</label>
                                    </div> 
                                    }
                                    
                                    </div>
                                }
                    {
                        tabSelectorValue == 3 &&
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={radioSelectorReconValue} onChange={this.handleRadioReconChange} className='radioGroupHorizontal'>
                                    {<FormControlLabel value="All" control={<Radio color="primary" />} label="Summary" />}
                                    <FormControlLabel
                                                    value={'West'}
                                                    key={1}
                                                    control={<Radio />}
                                                    label={'WH'}
                                                />
                                                <FormControlLabel
                                                    value={'East'}
                                                    key={2}
                                                    control={<Radio />}
                                                    label={'EH'}
                                                />
                                                <FormControlLabel
                                                    value={'NAM'}
                                                    key={3}
                                                    control={<Radio />}
                                                    label={'NAM'}
                                                />
                                                <FormControlLabel
                                                    value={'MEX'}
                                                    key={4}
                                                    control={<Radio />}
                                                    label={'MEX'}
                                                />
                                            
                                                <FormControlLabel
                                                    value={'UK AND NORTH EUROPE'}
                                                    key={5}
                                                    control={<Radio />}
                                                    label={'UK AND NORTH EUROPE'}
                                                />
                                                <FormControlLabel
                                                    value={'RUSSIA AND CASPIAN'}
                                                    key={6}
                                                    control={<Radio />}
                                                    label={'RUSSIA AND CASPIAN'}
                                                />
                                                <FormControlLabel
                                                    value={'SOUTH EUROPE / NA'}
                                                    key={7}
                                                    control={<Radio />}
                                                    label={'SOUTH EUROPE/NA'}
                                                />
                                                <FormControlLabel
                                                    value={'MEA'}
                                                    key={8}
                                                    control={<Radio />}
                                                    label={'MEA'}
                                                />
                                                <FormControlLabel
                                                    value={'ASIA PACIFIC'}
                                                    key={9}
                                                    control={<Radio />}
                                                    label={'ASIA PACIFIC'}
                                                />
                                                    <FormControlLabel
                                                    value={'LAM'}
                                                    key={10}
                                                    control={<Radio />}
                                                    label={'LAR'}
                                                />
                                </RadioGroup>
                            </FormControl>
                                {radioSelectorReconValue == 4 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueDGSAutomation} onChange={this.handleRadioDGSAutomation} className='radioGroupHorizontal'>
                                                <FormControlLabel value="lawson" control={<Radio color="primary" />} label="Lawson" />
                                                <FormControlLabel value="slbsap" control={<Radio color="primary" />} label="SLB SAP" />
                                                <FormControlLabel value="ifs" control={<Radio color="primary" />} label="IFS" />
                                                <FormControlLabel value="new" control={<Radio color="primary" />} label="new SAP" />
                                                <FormControlLabel value="total" control={<Radio color="primary" />} label="Total" />
                                            </RadioGroup>
                                        </FormControl>
                                            {radioSelectorReconValue == 4 && radioSelectorValueDGSAutomation != "total" &&
                                                 <div>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup name="radio" value={radioSelectorDynanicDGS} onChange={this.handleDynamicRadioDGSAutomation} className='radioGroupHorizontal'>
                                                        {countries && countries.length ?
                                                            countries.map((tabItem, index) => {
                                                                return  <FormControlLabel value={tabItem.code} control={<Radio color="primary" />} label={tabItem.name} />
                                                            })
                                                            : ''}
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </div>

                                            }
                                    </div>
                                }

{radioSelectorReconValue == 7 &&
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={radioSelectorValueLE} onChange={this.handleRadioChangeLE} className='radioGroupHorizontal'>
                                                <FormControlLabel value="summaryLE" control={<Radio color="primary" />} label="Summary for all LE's" />
                                                <FormControlLabel value="non7400view" control={<Radio color="primary" />} label="new Co Codes (except 7400)" />
                                                <FormControlLabel value="7400view" control={<Radio color="primary" />} label="Co Code 7400" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                }

                                
                         
                        </React.Fragment>
                    }
                       
                </div>
                {tabSelectorValue1 == 1 && 
                    <div>
                    <AOPForecastComponent 
                        monthSelectorValue={monthSelectorValue}
                        qtrSelectorValue={qtrSelectorValue}
                        yearSelectorValue={yearSelectorValue}
                        setAOPTabState={this.setAOPTabState}

                    />
                    </div>
                }
                {tabSelectorValue1 == 0 && tabSelectorValue == 4 &&
                    <div className="flex ai-center jc-space-between mb-10">
                        <FormControl component="fieldset">
                            <RadioGroup name="radio" value={radioSelectorTotalSales} onChange={this.handleRadioChangeTotaLSalesReport} className='radioGroupHorizontal'>
                                <FormControlLabel value="summary" control={<Radio color="primary" />} label="Summary" />
                                <FormControlLabel value="New BU 2" control={<Radio color="primary" />} label="New BU 2" />
                                <FormControlLabel value="New BU 1" control={<Radio color="primary" />} label="New BU 1 Summary" />
                                <FormControlLabel value="west" control={<Radio color="primary" />} label="New BU 1 WH" />
                                <FormControlLabel value="east" control={<Radio color="primary" />} label="New BU 1 EH" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
                {tabSelectorValue1 != 1 && tabSelectorValue1 != 4 && radioSelectorReconValue != 3 && ((tabSelectorValue1 == -1) || (radioSelectorValue != 6))&&                    
                    <NewSummaryTableComponent
                        {...this.props}
                        tabSelectorValue1={tabSelectorValue1}
                        tabSelectorValue={tabSelectorValue}
                        radioSelectorValueDGSAutomation = {radioSelectorValueDGSAutomation}
                        radioSelectorValue={radioSelectorValue}
                        radioSelectorDynanicDGS={radioSelectorDynanicDGS}
                        radioSelectorReconValue={radioSelectorReconValue}
                        handleRegionHeaderClick={this.handleRegionHeaderClick}
                        handleSubHeaderClick={this.handleSubHeaderClick}
                        handleHemisphereHeaderClick={this.handleHemisphereHeaderClick}
                        handleSaveDrilldownChanges={this.handleSaveDrilldownChanges}
                        handlePropsInvoiceClick={this.handleInvoiceClick}
                        saveNewBUFourTargetCommentFn={this.saveNewBUFourTargetCommentFn}
                        handlePropsThreeLevelInvoiceClick={this.handleThreeLevelInvoiceClick}
                        // handlePropsInvoiceClickOrigination={this.handlePropsInvoiceClickOrigination}
                        handlePropsGetDropdown={this.handleGetDropdown}
                        userDataState = {this.props.authReducerState.userData}
                        fetchNewPieData={this.fetchNewPieDataFn}
                        postManualAmountData={this.handlePostManualData}
                        postCommentData={this.handlePostCommentData}
                        postOSOriginationCommentData={this.postOSOriginationCommentData}
                        fetchMainData={this.handleFetchNewData}
                        radioSelectorValuePlant={radioSelectorValuePlant}
                        radioArrSelected = {radioArrSelected}
                        graphView={this.state.GraphView}
                        curMonth = {monthFilterArr[0]}
                        monthSelected = {monthSelectorValue}
                        radioArrCountrySelected = {radioArrCountrySelected}
                        handleBUFourDrilldownData={this.handleBUFourDrilldownData}
                    />
                }
                { radioSelectorValue == 6 && tabSelectorValue1 == 0 &&
                    <ChartContainer
                    {...this.props}
                    chartData={this.state.chartDataSO}
                    tableData={this.state.TableDataSO}
                    headerTable={this.state.HeaderTableDataSO}
                    additionalData = {this.state.additionalData}
                    handleInvoiceClick={this.handleInvoiceClick}
                    graphView = {this.state.GraphView}
                    toggleCommentModal={this.toggleCommentModal}
                    loading={this.props.incomeStatementState.chartLoading}
                    // chartDataUnbilled={this.state.chartDataUnbilled}
                    // chartTitleUnbilled={this.state.chartTitleUnbilled}
                    // chartDataDSOBUWise={this.state.chartDataDSOBUWise}
                    // chartTitleDSOBUWise={this.state.chartTitleDSOBUWise}
                    // externalTabValue={externalTabValue}
                    />                  
                 }
                {this.state.commentModal &&
                    <Modal
                        open={this.state.commentModal}
                        onClose={this.toggleCommentModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.commentModal}>
                            <div className='customModal dataValidationCommentModal'>
                            {NewCommentChartLoader && <div className='progressfullpage'><CircularProgress /></div>}
                                <div className={'header'}>
                                    <h2>Add New Explanatory Comment</h2>
                                    <Icon onClick={this.toggleCommentModal}>close</Icon>
                                </div>
                                <div className={'modal-content'} style={{ padding: '30px' }}>
                                    <TextField
                                        fullWidth
                                        label="Comment"
                                        multiline
                                        rows={10}
                                        // value={this.state.commentValue}
                                        onBlur={this.handleCommentChangeChart}
                                        variant="filled"
                                    />
                                    <div className="flex jc-flex-end mt-30">
                                        <Button variant="contained" color="primary" onClick={this.handleCommentSubmitChart}>Submit</Button>
                                        <Button variant="contained" className='ml-16' onClick={this.toggleCommentModal}>Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                }       
                {this.state.showInvoiceDetailModalChart &&
                    <InvoiceDetailModal
                        showModal={this.state.showInvoiceDetailModalChart}
                        invoiceDetailState={this.state.invoiceDetailStateNew}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailStateFilter={this.state.invoiceDetailFilter}
                        isExpandable={true}
                        showGlDrilldownFilters={false}
                        showAmountFilters={false}
                        toggleCommentModal={this.toggleCommentModal}
                        NewChart={true}
                    />
                }
                { radioSelectorReconValue == 3 &&
                    <div className='flex ai-flex-start'>
                        <img width="100%" className="mr-10" src={this.imgOcos[0]} style={{ maxWidth: '900px', height: '600px' }} />
                        {/* {this.handleToolTipValue(newRadioSelectorValue)} */}
                    </div>
                }
                {tabSelectorValue1 == 4 &&
                    <React.Fragment>
                        <FormControl component="fieldset">
                           <Tabs
                                value={newRadioSelectorValue}
                                onChange={this.handleNewRadioChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className="mb-16"
                            >
                                <Tab value={'4'} label="Dashboard" index="0"/>
                                <Tab value={'0'} label="Growth" index="1"/>
                                <Tab value={'1'} label="Margin Analysis" index="2"/>
                                <Tab value={'2'} label="Economies of Scale" index="3"/>
                                <Tab value={'3'} label="Customer Change Analysis" index="4"/>
                            </Tabs>
                        </FormControl>

                    </React.Fragment>}
                {tabSelectorValue1 == 4 &&
                    <div className='flex ai-flex-start'>
                        <img width="100%" className="mr-10" src={this.imgMap[newRadioSelectorValue]} style={{ maxWidth: '900px', height: '600px' }} />
                        {this.handleToolTipValue(newRadioSelectorValue)}
                    </div>
                }
                {
                    <FileUploadPopUp
                        saveLoading={saveLoading}
                        showModal={showUploadModal}
                        modalCallBack={this.modalCallBack}           
                        saveCallback={this.handleFileUploadSave}
                    />
                }
            </div>
        );
    }
}       

export default
    connect(
        state => {
            return {
                incomeStatementState: state.incomeStatement,
                authReducerState: state.authReducer,
            }
        },
        dispatch => {
            return {
                getNewDefaultMonthData(data) {
                    dispatch({
                        type: 'FETCH_New_DEFAULT_MONTH',
                        payload: data
                    });
                },
                getNewDefaultMonthDataDynamic(data) {
                    dispatch({
                        type: 'FETCH_New_DEFAULT_MONTH_DYNAMIC',
                        payload: data
                    });
                },
                getNewPivotData(data) {
                    dispatch({
                        type: 'FETCH_New_PIVOT_ALL',
                        payload: data

                    });
                },
                getNewBUFourData(data) {
                    dispatch({
                        type: 'FETCH_New_BU_FOUR_REPORT_DATA',
                        payload: data

                    });
                },
                getNewBUFourTargetData(data) {
                    dispatch({
                        type: 'FETCH_New_BU_FOUR_TARGET_REPORT_DATA',
                        payload: data

                    });
                },
                saveNewBUFourTargetCommentData(data) {
                    dispatch({
                        type: 'SAVE_New_BU_FOUR_TARGET_COMMENT_DATA',
                        payload: data

                    });
                },
                
                getNewBUFourByPMData(data) {
                    dispatch({
                        type: 'FETCH_New_BU_FOUR_BY_PM_REPORT_DATA',
                        payload: data

                    });
                },
                getNewBUFourByLocationData(data) {
                    dispatch({
                        type: 'FETCH_New_BU_FOUR_BY_LOCATION_REPORT_DATA',
                        payload: data

                    });
                },
                getNewOCOSSummary(data) {
                    dispatch({
                        type: 'FETCH_New_OCOS_SUMMARY',
                        payload: data

                    });
                },
                getDGSLawson(data) {
                    dispatch({
                        type: 'FETCH_New_DGS_LAWSON',
                        payload: data

                    });
                },
                getChartSO(data) {
                    dispatch({
                        type: 'FETCH_New_CHART_SO',
                        payload: data

                    });
                },
                getOptimusAutomation(data) {
                    dispatch({
                        type: 'FETCH_New_OPTIMUS_AUTOMATION',
                        payload: data

                    });
                },
                downloadNewPivotData(data) {
                    dispatch({
                        type: 'DOWNLOAD_EXCEL_New_PIVOT_ALL',
                        payload: data

                    });
                },
                uploadFileData(data) {
                    dispatch({
                      type: "UPLOAD_EXCEL_FILE_UPLOAD",
                      payload: data,
                    });
                  },
                getBUPLInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_BUPL_INVOICE_DETAIL',
                        payload: data
                    });
                },
                getLawsonDetail(data) {
                    dispatch({
                        type: 'FETCH_LAWSON_DETAIL',
                        payload: data
                    });
                },
                getAOPTableData(data) {
                    dispatch({
                        type: 'FETCH_AOP_TABLE_DATA',
                        payload: data
                    });
                },
                getDropDown(data) {
                    dispatch({
                        type: 'FETCH_DROPDOWN',
                        payload: data
                    });
                },
                getOptimusAutoArilldown(data) {
                    dispatch({
                        type: 'FETCH_OPTIMUS_AUTO_DETAIL',
                        payload: data
                    });
                },
                getPABValidationdrilldown(data) {
                    dispatch({
                        type: 'FETCH_PAB_VALIDATION_DETAIL',
                        payload: data
                    });
                },
                getUserDetail() {
                    dispatch({
                        type: 'GET_USER_DETAILS'
                    });
                },
                fetchNewSubHeaderDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_SUB_HEADER_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchBUFourDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_BU_FOUR_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewOriginationDrilldownData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_ORIGINATION',
                        payload: data
                    });
                },
                fetchNewDrilldownChartData(data) {
                    dispatch({
                        type: 'FETCH_New_DRILLDOWN_CHART_DATA',
                        payload: data
                    });
                },
                saveDrilldownChanges(data) {
                    dispatch({
                        type: 'SAVE_New_DRILLDOWN_DATA',
                        payload: data
                    });
                },
                fetchNewPieData(data) {
                    dispatch({
                        type: 'FETCH_New_PIE_CHART_DATA',
                        payload: data
                    });
                },
                postNewManualAmountData(data) {
                    dispatch({
                        type: 'POST_new_MANUAL_DATA',
                        payload: data
                    });
                },
                postNewComments(data) {
                    dispatch({
                        type: 'POST_new_COMMENT_DATA',
                        payload: data
                    });
                },
                postOSOriginationCommentData(data) {
                    dispatch({
                        type: 'POST_new_ORIGINATION_COMMENT_DATA',
                        payload: data
                    });
                },
            };
        }
    )(NewOriginationComponent);
