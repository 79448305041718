import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HighchartOptions from "./highcharts.options";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CHART_COLORS } from '../../../constant';

export default function MarginAnalysis (){
const {analyticsModule: { marginAnalysis: { marginPercentageOfRevenueColor, revenueColor } }} = CHART_COLORS;
const seriesData= [15,21,16,25,15,18,19,17,16,20,22,19];

let subTitle = {}

let windowSize = window.innerWidth

if(windowSize <= 667){
    subTitle = {
                    text :' Margin Improvement- XX %  ',
                    y: 40,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}else{
    subTitle = {
                    text :' Margin Improvement- XX % ',
                    align: 'right',
                    x: -40,
                    y: 63.5,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}

return(
    <div className="px-5">
           <Card>
             <CardContent>   
            <HighchartsReact
                highcharts={Highcharts}
                options = {
                    {
                        chart: {
                            height: 440,
                        },
                        colors: [
                            marginPercentageOfRevenueColor, 
                            revenueColor,
                        ],
                        credits: {
                            enabled: false
                        },
                        title: {
                            text: 'Margin Analysis',
                        },
                        subtitle : subTitle,
                        xAxis: {
                            title:{
                                text:"Months",
                                offset: 30,
                            },
                            visible:true,
                            categories: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                            zoomEnabled: false,
                            
                        },
                        yAxis:[            
                            {
                            title: {
                                text: '$M',                              
                            },
                            max:100,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            visible:true,
                            
                            zoomEnabled:false,
                            tickInterval:20,
                        }, {
                            title: {
                                text: '$M',                              
                            },
                            max:100,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            linkedTo: 0,
                            opposite: true
                        },],
                        tooltip: {
                          
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            // labelFormatter: function () { 
                            //     let x ;
                            //     if (this.name === 'Revenue'){
                            //          x = this.name  + '  - Margin Improvement- XX%' ; 
                            //     }else{
                            //         x = this.name;
                            //     }
                                
                            //     return x; 
                            // }
                        },
                        series: [{
                            type: 'line',
                            data: [50, 59, 51, 55, 63, 61, 55, 45, 48, 57, 66, 60],
                            name: 'Margin % of Revenue',
                        },{
                            type: 'column',
                            data: seriesData,
                            name: 'Revenue'
                        }]
                    }
                }
            />
            </CardContent>
         </Card> 
        </div>
   )

}