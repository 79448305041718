import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import { Switch, Route } from "react-router-dom";
import ImprovementPlanForm from './form'


export class ReceivableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: [],
            loading: true,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: ''
        }
    }

    componentDidMount() {
     this.props.getFormDropdownData();
     this.props.getFormPlanId();
    }

    handleSubmitData = (payload) => {
        this.props.saveCreatePlanData(payload)
    }

    render() {
        const { authReducer, prefilledPlanValue } = this.props
        const menuData = authReducer.menuData
        return (
           <div>
               <ImprovementPlanForm 
               menuData = {menuData}
               handleSubmitData={this.handleSubmitData}
               prefilledPlanValue={prefilledPlanValue}
               />
           </div>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state.dashboardState.receivableData.ageingData,
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                getFormDropdownData() {
                    dispatch({
                        type: 'FETCH_CREATE_PLAN_FORM_DROPDOWN_DATA'
                    });
                },
                getFormPlanId(data) {
                    dispatch({
                        type: "FETCH_CREATE_PLAN_ID_DATA",
                        payload: data,
                    });
                },
                saveCreatePlanData(data) {
                    dispatch({
                        type: "SAVE_PLAN_FORM_DROPDOWN_DATA",
                        payload: data,
                    });
                },
            };
        }
    )(ReceivableComponent);