import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

function rand() {
	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const styles = (theme) => ({
	paper: {
		position: 'fixed',
		// width: theme.spacing.unit * 50,
		width: theme.spacing(50),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		// padding: theme.spacing.unit * 4,
		padding: theme.spacing(4),
		outline: 'none',
	},
});

const SimpleModal = (props) => {
	const {
		open,
		moduleName,
		currentName,
		setModalNameValue,
		classes,
		toggleModal,
		handleSaveData,
	} = props;

	useEffect(() => {
		if (moduleName && moduleName !== '') {
			setModalNameValue(moduleName);
		}
	}, [moduleName]);

	return (
		<Modal
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			open={open}
			onClose={toggleModal}
		>
			<div style={getModalStyle()} className={classes.paper}>
				<a className='close-modal' title={'Close Modal'} onClick={toggleModal}>
					<CloseIcon className='close-icon' />
				</a>
				<TextField
					id='name-textfield'
					value={currentName}
					label='Name'
					variant='standard'
					onChange={(e) => setModalNameValue(e.target.value)}
					fullWidth
				/>
				<Button
					variant='contained'
					color='primary'
					className='newButton btnBlue btnSave uppercase'
					onClick={handleSaveData}
					fullWidth
				>
					Save
				</Button>
			</div>
		</Modal>
	);
};

// SimpleModal.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
