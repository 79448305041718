import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NewPagination from "../../../../common/components/CustomPagination";

import { handleTableTransform, numberWithCommas } from '../../../../../utils/numberUtils'
import DownloadExcel from "../../../../../../src/images/download_excel.svg";
import { downloadExcel, mapExcelData, mapExcelHeader, downloadExcelMultiSheet } from '../../../../../utils/exportExcel'
import Tooltip from '@material-ui/core/Tooltip';
import { formatMs, Grid } from '@material-ui/core';
import { format, parseJSON } from "date-fns";
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import GLModal from "./glDrilldown";
import { TableNew } from '../../../../common/components/ExpandableGrid/TableNew';
import { formatDate } from '../../../../../utils/dateFormat'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import {Chip, Typography} from '@material-ui/core';

export default function SimpleModal(props) {
    let tableData = [];
    let amountFilterArr = props.invoiceDetailStateFilter;
    const [amountSelectorValue, setAmountSelectorValue] = useState(0)
    const [glStateValue, setGlStateValue] = useState({})
    const [isWindows, setIsWindows] = useState(true)
    const [dataz, setdata] = useState([])
    let datax = [];
    const { invoiceLoading } = props;
    const handleClose = () => {
        props.modalCallBack(false);
    };

    const [showGLModal, setShowGLModal] = useState(false)
    const [glHeader, setGlHeader] = useState('')
    const [glHeaderAdditional, setGlHeaderAdditional] = useState('')
    const [glBU, setGlBU] = useState('')

    const glModalCallBack = () => {
        setShowGLModal(false);
    }

    React.useEffect(() => {
        if (navigator.appVersion.indexOf("Win") < 0){
            setIsWindows(false)
        }

    },[])

    const handleAmountClick = (data) => {
        if (!data.gl_available) {
            return
        }
        // console.log(data, "--data clicked")
        let dataFin = {};
        dataFin.bu = data.bu;
        dataFin._id = data._id;
        dataFin.plant = data.plant;
        dataFin.usd = data.usd;
        dataFin.documentamount_abs = data.documentamount_abs;
        dataFin.functional_area = data.functional_area;
        // dataFin.local_pab_match = data.local_pab_match ? "true" : "false";
        dataFin.total_number = data.amountinlocalcurrency;
        dataFin.sap_gl_account_no = data.account;
        dataFin.company_code = data.company_code;
        dataFin.assignment = data.assignment;
        dataFin.yearmonth = data.yearmonth_new;
        dataFin.dgs_itt = data.dgs_itt;
        dataFin.dgs_lawson = data.dgs_lawson;
        dataFin.lcs_lawson = data.lcs_lawson;
        dataFin.lcs_itt = data.lcs_itt;
        props.getInvoiceDetailGL(dataFin);
        setGlStateValue(dataFin)
        let oldHeader = props.showDrilldownHeaderValue.split("-")
        oldHeader.splice(oldHeader.length - 1, 1);
        oldHeader = oldHeader.join("-").trim();
        let number = handleTableTransform({ 'num': data.usd }, { key: 'num', operation: 7 })
        let numberlocal = handleTableTransform({ 'num': data.amountinlocalcurrency_new }, { key: 'num', operation: 7 })
        let pabTag = data.pab_tag;

        if (pabTag == "ManyvsMany") {
            pabTag = " (Many to Many)"
        } else {
            pabTag = ""
        }
        setGlHeaderAdditional(pabTag)

        setGlBU(dataFin.bu)

        if (dataFin.bu == "DGS") {
            setGlHeader(oldHeader + ' - USD ' + number + " - Local Amount " + data.local_currency + " " + numberlocal)
        } else {
            setGlHeader(oldHeader + ' - USD ' + number)
        }
        setShowGLModal(true);
    }

   const handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }
    
    const checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }
    const handleSubHeaderTooltip = () => {
      
        if(this.props.radioSelectorValue != 0 && this.props.radioSelectorValue != 2){

            return 'Click here for detail'

        }

  


    return ''
}
const modifyColumnData = (columnData, operations) => {
    let modifiedData;
    modifiedData = Math.round(columnData)
    var isNegative = modifiedData < 0
    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
    modifiedData = numberWithCommas(positiveTransformedValue)
    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
    modifiedData = modifiedData == '0' ? '-' : modifiedData

    if (operations == "percentage") {
        modifiedData += "%"
    }

    return modifiedData
}
    const handleSubColumnChangeHeader = (data, parentData) => {
        let selfProps = this.props
        if (data && data.length) {
            return data.map((item, i) => {
                var isManualKey = false;
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        
                        let classNames = ""
                        if(isManualKey == true){
                            classNames = 'text-center pointer'
                        }else{
                            classNames = 'text-center'
                        }
                        item['operation'] = ((item.key == 'ytd_revenue_new_usd') ||(item.key == 'ytdcos_total_new_usd') ||(item.key == 'ytd_margin_new_usd') ||(item.key == 'cost') || (item.key == 'sales')|| (item.key == 'amount') ||(item.key == 'total_amount') ||  (item.key == 'tran_amount')|| (item.key == 'base_amount') ||(item.key == 'margin') || (item.key == 'usd') || (item.key == 'amountinlocalcurrency_new')) ? 7 : '';
                        let ori = selfProps.isExpandable ? props.row.original : selfProps.isInvoiceDetailModal ? props.row.original : props.original
                        // console.log(props,props.row.original,ori,selfProps.isExpandable, "-- ini kocak")
                        // console.log(item.key, "checkKey")
                        if (item.key == 'usd' ) {
                            if (selfProps.showGlDrilldownFilters == false){
                                return (
                                    <div className='text-center'>
                                        {handleTableTransform(ori, item)}
                                    </div>
                                )
                            }else {
                                return (
                                    <Tooltip title={ori.gl_available ? 'Click here to open GL drill down' : 'No drill down available'}>
                                        <div className='text-center pointer' onClick={() => { handleAmountClick(ori) }}>
                                            {handleTableTransform(ori, item)}
                                        </div>
                                    </Tooltip>
                                )
                            }
                            
                        }

                        if (item.key == 'comments') {
                            return ( 
                                <div className='commentSection'>
                            <div className='comments'>
                                {ori.comments && ori.comments.length ?
                                    ori.comments.map((itemx, index) => {
                                        return (
                                            <div className='block' key={index}>
                                                <h6 style={{ margin: 0 }}>{itemx.comment_originator}, {formatDate(itemx.comment_date)}</h6>
                                                <p>{itemx.comment}</p>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </div>
                            {/* <div className='infoArea' style={{ textAlign: 'center' }}>
                                <Button variant="contained" size='small' color="primary" fullWidth onClick={() => {selfProps.toggleCommentModal(ori) }}>Add Comment</Button>
                            </div> */}
                            </div>
                            )
                        }

                        // console.log(props.original, parentData,item, "testHereDisini")
                        // console.log(props, classNames, "Testitem")
                        return (
                            <div className={classNames}>
                                {
                                    <span className=''>{props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? modifyColumnData(props.original.sub_column[parentData.key][item.key], props.original.sub_column[parentData.key]["operations"]) : '-'}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                        cursor: isManualKey && isManualKey == true ? 'pointer' : ''
                        
                        // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }
            })
        }
    }
    const handleDynamicWidth = (item) => {
        let widthSize = 100;
        console.log(item.key, "cekKeys")
        switch (item.key) {
          case 'description':
            widthSize = 250;
            break;
            case 'comments':
                widthSize = 75;
                break;
          default:
            break;
        }
        return widthSize;
      };
    const changeKeysOfHeader = (data, type) => {
        let selfProps = props
       
        if (data && data.length) {
            tableData = data.map((item, i) => {
              
            //    if (item.key == 'data_field') {
            //         item['operation'] = item.key == 'data_field' || item.key == 'data_group' ? '' : 7;
            //         return {
            //             Header: item.label,
            //             id: item.key,
            //             fixed: 'left',
            //             metaData: item.metadata,
            //             columns: [{
            //                 Header: item.label,
            //                 id: item.key,
            //                 className:  '',
            //                 Cell: (props) => {
            //                     item['operation'] = item.key == 'data_field' ? '' : 7;

            //                     return (
            //                         <React.Fragment>
            //                             {
            //                                 <span>{props.original ? props.original[item.key] : ''}</span>
            //                             }
            //                         </React.Fragment>
            //                     )
            //                 }                   
            //             }]
            //         }
            //     }
                // if(item.sub_headers){
                //     return {
                //         Header:  item.label,
                //         id: item.key,
                //         columns: handleSubColumnChangeHeader(item.sub_headers, item),
                //         style: {
                //             borderRight: '1px solid #423970',
                //         },
                //         headerStyle: {
                //             overflow: 'visible',
                //             fontSize: '13px',
                //             fontWeight: 'bold',
                //             borderRight:'1px solid #423970'
                //         }
                //     }
                // }else{
                  
                    let myClassNameHeader =""
                    // // if(item.key == "description"){
                    //     myClassNameHeader = "descDrillDown"
                    // // }
                    return {
                        
                        Header: <div className={'flex ai-center jc-center ' + myClassNameHeader}>
                            <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                            {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                            {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                        </div>,
                        HeaderLabel: item.label,
                      
                        id: item.key,
                        accessor:item.key,
    
                       
                        Cell: (props) => {
                            let showPercentage = handleAppendPercentage(props.original);
                            let myClassName = showPercentage ? 'percentageRowData' : ''

                            item['operation'] = ((item.key == 'ytd_revenue_new_usd') ||(item.key == 'ytdcos_total_new_usd') ||(item.key == 'ytd_margin_new_usd')||(item.key == 'aop_cost')||(item.key == 'aop_sales')||(item.key == 'aop_margin')||(item.key == 'cost')||(item.key == 'BU 1')||(item.key == 'BU 2')||(item.key == 'BU 3')||(item.key == 'BU 4')||(item.key == 'sub_total') || (item.key == 'sales')|| (item.key == 'amount') ||(item.key == 'total_amount') ||  (item.key == 'tran_amount')|| (item.key == 'base_amount') ||(item.key == 'margin') || (item.key == 'usd') || (item.key == 'amountinlocalcurrency_new')) ? 7 : item['operation'];
                            let ori = selfProps.isExpandable ? props.row.original : selfProps.isInvoiceDetailModal ? props.row.original : props.original
                            if (!ori) {
                                ori = props.original
                            }
                            // console.log(props,props.row.original,ori,selfProps.isExpandable, "-- ini kocak")
                            // console.log(item.key, "checkKey")
                            if (item.key == 'usd' ) {
                                if (selfProps.showGlDrilldownFilters == false){
                                    return (
                                        <div className='text-center'>
                                            {handleTableTransform(ori, item)}
                                        </div>
                                    )
                                }else {
                                    return (
                                        <Tooltip title={ori.gl_available ? 'Click here to open GL drill down' : 'No drill down available'}>
                                            <div className='text-center pointer' onClick={() => { handleAmountClick(ori) }}>
                                                {handleTableTransform(ori, item)}
                                            </div>
                                        </Tooltip>
                                    )
                                }
                                
                            }
    
                            if (item.key == 'comments') {
                                return ( 
                                    <div className='commentSection'>
                                <div className='comments'>
                                    {ori.comments && ori.comments.length ?
                                        ori.comments.map((itemx, index) => {
                                            return (
                                                <div className='block' key={index}>
                                                    <h6 style={{ margin: 0 }}>{itemx.comment_originator}, {formatDate(itemx.comment_date)}</h6>
                                                    <p>{itemx.comment}</p>
                                                </div>
                                            )
                                        }) : ''
                                    }
                                </div>
                                {/* <div className='infoArea' style={{ textAlign: 'center' }}>
                                    <Button variant="contained" size='small' color="primary" fullWidth onClick={() => {selfProps.toggleCommentModal(ori) }}>Add Comment</Button>
                                </div> */}
                                </div>
                                )
                            }

                            if (item.key == 'country_name_new' && selfProps.showDrilldownHeaderValue ) {
                                let countryArr = selfProps.invoiceDetailState.countries
                                let countries = props.original['country_name_new']
                                return ( 
                                    selfProps.curMonth == selfProps.monthSelected ? 
                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <Select
                                          MenuProps={MenuProps}
                                        // value={dataz[props.index]['country_name_new']}
                                        value={countries}
                                        label="Country"
                                        onChange={(event) => { handleCountryChange(event, props.original, props.index) }}
                                    >
                                        <MenuItem value="">
                                            <em>{''}</em>
                                        </MenuItem>
                                        {countryArr && countryArr.length && countryArr.map(item => {
                                            return <MenuItem value={item.key} key={item.key}>{item.key}</MenuItem>
                                        })}
                                    </Select>
                                    
                                </FormControl> :
                                 <div className={'text-center ' + myClassName}>{
                                props.original['country_name_new'] ? props.original['country_name_new'] : '-'}
                                </div> 
                              
                                )
                            }
                            if ((item.key == 'final_mapping_new' || item.key == 'final_mapping_eaciit') && selfProps.showDrilldownHeaderValue ) {
                                let classArr = selfProps.invoiceDetailState.classification
                                let final_mapping_new = props.original['final_mapping_new'] ? props.original['final_mapping_new'] : props.original['final_mapping_eaciit']
                                console.log( selfProps.curMonth, selfProps.monthSelected,  props.original['final_mapping_new'] , "cekMOnth")
                                return ( 
                                    selfProps.curMonth == selfProps.monthSelected ? 
                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                    <Select
                                      MenuProps={MenuProps}
                                        value={final_mapping_new}
                                        label="Country"
                                        onChange={(event) => { handleMappingChange(event, props.original, props.index) }}
                                    >
                                        <MenuItem value="">
                                            <em>{''}</em>
                                        </MenuItem>
                                        {classArr && classArr.length && classArr.map(item => {
                                            return <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                                        })}
                                    </Select>
                                    
                                </FormControl> :
                                <div className={'text-center ' + myClassName}>{props.original['final_mapping_new'] ? props.original['final_mapping_new'] : '-'}
                               </div> 
                                )
                            }
                            if (item.key == "bu_group" && selfProps.showDrilldownHeaderValue) {
                            // console.log(item.key,  ori[item.key], "cekcek")
                            let buGroup = ori[item.key]
                                if (buGroup == "RA-MSM"){
                                    buGroup = "RA-MSMT"
                                }
                                return (
                                <div className={'text-center ' + myClassName}>
                                    {buGroup}
                                    </div>
                                )
                            }

                           
                            return (
                                <div className={'text-center ' + myClassName}>
                                    {handleTableTransform(ori, item) }{ showPercentage && handleTableTransform(ori, item) != '-' && !handleTableTransform(ori, item).includes('%') ? '%' : ''}
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            whiteSpace: "break-spaces",
                            overflow: "visible",
                            wordBreak: 'break-word',

                        },
                        headerStyle: {
                            whiteSpace: 'break-spaces',
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            // wordBreak: 'break-word',
                            width: "50px !important",
                        }
                    }
                // }
            })
        }
        return []
    }
    const handleCountryChange = (event, rowData, idx) => {
        if (event.target && event.target.value) {
            rowData["country_name_new"] = event.target.value;
            let payload = {
                country_new: event.target.value,
                index: idx
            }
            props.handleCountryChanges(payload)
        }
    }
    const handleMappingChange = (event, rowData, idx) => {
        if (event.target && event.target.value) {
           
            datax.push(rowData)
            rowData["final_mapping_new"] = event.target.value;
            let payload = {
                final_mapping_new: event.target.value,
                index: idx
            }
            props.handleClassificationChanges(payload)
    
        }
    }
    // const handleCountryChange = (event) => {
    //     setAgingCategory(event.target.value);
    
    //   };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
           
            width: 250,
          },
        },
      };
    const validateDate = (data) => {
        if (data.indexOf("1901") > -1) {
            return ""
        }

        return data
    }

    const convertNumberToDate = (data) => {
        data = data.map(x => {
            if (x.document_date_format && x.document_date_format.indexOf("/") == -1 && x.document_date_format != "")
                x.document_date_format = validateDate(format(parseJSON(x.document_date_format), "dd/MM/yyyy"))

            if (x.posting_date_new && x.posting_date_new.indexOf("/") == -1 && x.posting_date_new != "")
                x.posting_date_new = validateDate(format(parseJSON(x.posting_date_new), "dd/MM/yyyy"))



            return x
        })
        return data
    }


    let columnDataValue = [];
    if (props.isPrevious) {
        
        changeKeysOfHeader(props.invoiceDetailStatePrevious && props.invoiceDetailStatePrevious.row_header);
        columnDataValue = props.invoiceDetailStatePrevious && props.invoiceDetailStatePrevious.row_data ? convertNumberToDate(props.invoiceDetailStatePrevious.row_data) : []
        // console.log(props.invoiceDetailState.classification, props.invoiceDetailState.countries, "TestClass1")
    } else {
        // let dataArray = props.invoiceDetailState.row_data
        // if (dataArray.length != dataz.length ){
        //     setdata(dataArray)
        // }
        changeKeysOfHeader(props.invoiceDetailState && props.invoiceDetailState.row_header);
        columnDataValue = props.invoiceDetailState && props.invoiceDetailState.row_data ? convertNumberToDate(props.invoiceDetailState.row_data) : []
       
    }

    const maskExpandableGrid = (data, header) =>{
        let finalData = []
        let finalHeader = []
        let excelData = [...data]
        let blankData = {}
        let isExpandable = false

        excelData.forEach((element, idx) => {
            if(idx == 0){ // prepare blank data
                for(var i in element){
                    blankData[i] = ""
                }
            }

            if(element.subRows != undefined && element.subRows.length > 0){
                isExpandable = true
            }
        });

        excelData.forEach(element => {

            if(element.subRows != undefined){
                element.subRows.forEach(x =>{
                    finalData.push(x)
                })
                
                element["description"] = "SO Total"
                element["yearmonth_new_masked"] = "SO Total"
            }

            finalData.push(element)


            if(isExpandable){
                finalData.push(blankData) // add separator
            }
        });

        header.forEach(element =>{
            if(element.id != "expander"){
                finalHeader.push(element)
            }
        })
        
        return { data : finalData, header : finalHeader}
    }
    const saveChanges = () => {
        // console.log(datax, "checkDataz")
        props.handleSaveChanges(props.invoiceDetailState.row_data)
        // console.log(props.invoiceDetailState.row_data, "checkProps")
    }

    const handleExcelDownload = () => {
        if (props.handleDownloadFromBE){
            props.handleDownloadFromBECallback()
            return;
        }
        let sheetOne = {};
        let sheetTwo = {};
        let fileName = " Income Statement - " + props.showDrilldownHeaderValue;
        if (props.financialDuration) {
            fileName = fileName + " - " + props.financialDuration
        }
        let finalDatas = []; 
        let finalTableData = [];
        let masked = maskExpandableGrid(columnDataValue, tableData);
        finalDatas = masked.data;
        finalTableData = masked.header;
        let data = mapExcelData(finalDatas, finalTableData, "id")
        let header = mapExcelHeader(finalTableData, "HeaderLabel")
        sheetOne.data = data;
        sheetOne.header = header;
        if (props.showBothVersion && props.invoiceDetailStatePrevious.row_data) {
            let datasTwo = convertNumberToDate(props.invoiceDetailStatePrevious.row_data)
            let dataTwo = mapExcelData(datasTwo, tableData, "id")
            sheetTwo.data = dataTwo;
            sheetTwo.header = header;
            sheetOne.sheetName = props.currentDateValue;
            sheetTwo.sheetName = props.previousDateValue;
            let All = [];
            All.push(sheetOne);
            All.push(sheetTwo);
            // console.log(All)
            downloadExcelMultiSheet(All, fileName, false)
        } else {
            downloadExcel(sheetOne.data, fileName, sheetOne.header, false)
        }
        // console.log(sheetOne.data, fileName, sheetOne.header,datas, tableData)
    }

    const handleAmountChange = (event) => {
        if (event.target) {
            setAmountSelectorValue(event.target.value)
            props.handleFilterChange({ amount: event.target.value, gl: glSelectorValue })
            // console.log(event.target.value)
        }
    };


    const handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total" && colInfo.id != "expander") {
            props.handleInvoiceSorting(colInfo.id)
        }
    }



    const columnHeaderClick = (state, someData, colInfo) => {
        // console.log(props.invoiceDetailState.drilldownHeader, "checkDatas")
       
            return {
                onClick: (e) => {
                    if(!props.invoiceDetailState.drilldownHeader) { handleColumnHeaderClick(colInfo)}
                },
                style: { cursor: !props.invoiceDetailState.drilldownHeader ? 'pointer' : '' }
            }
        
        
    }

    const handleGlInvoiceSorting = (columnId) => {
        props.getInvoiceDetailGL(glStateValue, columnId);

    }

    const handleGLChange = (event) => {
        if (event.target) {
            let val = event.target.value
            setGLSelectorValue(val)
            props.handleFilterChange({ amount: amountSelectorValue, gl: event.target.value })
        }
    };

    const [glSelectorValue, setGLSelectorValue] = useState('all')

    let glDrilldownArr = [{ value: 'all', label: 'All' }, { value: 'av', label: 'Available' }, { value: 'na', label: 'Not Available' }]
// console.log(props, "checktableData")
    const body = (
        <div className='customModal'>
            <div className='header'>
            {props.header ?<h2 className='text-center'>{`${props.header} - ${props.sub_header}`}</h2> : <h2>{props.showDrilldownHeaderValue}</h2>}

                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content pt-5'>
            {invoiceLoading && <div className='progressfullpage'><CircularProgress /></div>}
                <div className='text-right'>
                   {/* {props.showGlDrilldownFilters == false ? '' : 
                   <> 
                    <FormControl variant="outlined" className='selectOutlineSmall maxw-150 mr-10' fullWidth size='small'>
                        <InputLabel>GL Drilldown</InputLabel>
                        <Select
                            value={glSelectorValue}
                            label="GL Drilldown"
                            onChange={handleGLChange}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {glDrilldownArr && glDrilldownArr.length && glDrilldownArr.map(item => {
                                return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    </>} */}
                    {/* {   props.showAmountFilters == false ? '' : 
                                        <FormControl variant="outlined" className='selectOutlineSmall maxw-100' fullWidth size='small'>
                                            <InputLabel>Amount</InputLabel>
                                            <Select
                                                value={amountSelectorValue}
                                                label="Amount"
                                                onChange={handleAmountChange}
                                            >
                                                <MenuItem value="">
                                                    <em>Select</em>
                                                </MenuItem>
                                                {amountFilterArr && amountFilterArr.length && amountFilterArr.map(item => {
                                                    return <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                    } */}
                    <Tooltip title={'Download to Excel'} style={{marginBottom: '10px'}}>
                        <img
                            src={DownloadExcel}
                            className='pointer'
                            height='30'
                            onClick={(event) => {
                                event.preventDefault();
                                handleExcelDownload();
                            }} />
                    </Tooltip>
                    
                </div>
                {props.showBothVersion ? (
                    <>
                        <div className='flex'>
                            <div className='fg-1'>
                                <h3 className='text-center'>{`${props.currentDateValue} - USD  ${props.currentAmountValue}`}</h3>
                                <ReactTable
                                    columns={tableData}
                                    data={columnDataValue}
                                    defaultPageSize={50}
                                    pageSize={columnDataValue.length}
                                    loading={invoiceLoading}
                                    className="xpna-react-table -striped -highlight"
                                    PaginationComponent={NewPagination}
                                    sortable={false}
                                    getTheadThProps={columnHeaderClick}

                                />
                            </div>
                            <Divider orientation="vertical" flexItem className='mx-50' style={{ width: '10px', background: '#423970' }} />
                            <div className='fg-1'>
                                <h3 className='text-center'>{`${props.previousDateValue} - USD  ${props.previousAmountValue}`}</h3>
                                <ReactTable
                                    columns={tableData}
                                    data={props.invoiceDetailStatePrevious && props.invoiceDetailStatePrevious.row_data ? convertNumberToDate(props.invoiceDetailStatePrevious.row_data) : []}
                                    defaultPageSize={50}
                                    loading={invoiceLoading}
                                    className="xpna-react-table -striped -highlight"
                                    PaginationComponent={NewPagination}
                                    sortable={false}
                                    getTheadThProps={columnHeaderClick}

                                />
                            </div>
                        </div>
                    </>
                )
                    : (
                        props.isExpandable ? 
                        <Grid item xs={12} className={isWindows ? (props.NewChart == true?"multi-gl-container-one-new-chartv2 isWindows" : "multi-gl-container-one-new isWindows") : "multi-gl-container-one-new" }>
                        <TableNew className="xpna-pure-table -striped -highlight"
                            columns={tableData}
                            data={columnDataValue}
                            handleSorting={props.handleInvoiceSorting}
                            sortable={true}
                        />
                        </Grid>
                        :
                        <Grid item xs={12} className= "modalData">
                            <ReactTable
                                columns={tableData}
                                data={columnDataValue}
                                defaultPageSize={props.header ? 7 : 50}
                                pageSize={props.invoiceDetailState.drilldownHeader ? columnDataValue.length : props.header ? 7 : 50 }
                                loading={invoiceLoading}
                                className="xpna-react-table -striped -highlight"
                                PaginationComponent={columnDataValue ? NewPagination : null}
                                sortable={false}
                                getTheadThProps={columnHeaderClick}

                            />
                        </Grid>
                    )}
                    {props.radioSelectorReconValue && props.radioSelectorReconValue == 5 ? 
                <div className='text-right'>
                    <Button variant="contained" className='mb-10' color="primary"  style={{ marginTop: '20px' }} onClick={() => {saveChanges() }}>Save Changes</Button>
                    </div> : ""}
            </div>
        </div>
    );

    

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
            {showGLModal &&
                <GLModal
                    showModal={showGLModal}
                    modalCallBack={glModalCallBack}
                    invoiceLoading={props.invoiceLoadingGL}
                    showDrilldownHeaderValue={props.showDrilldownHeaderValue}
                    invoiceDetailStateGL={props.invoiceDetailStateGL}
                    headertext={glHeader}
                    headertextAdditional={glHeaderAdditional}
                    bu={glBU}
                    handleGlInvoiceSorting={handleGlInvoiceSorting}
                    financialDuration={props.financialDuration}
                />
            }
        </div>
    );
}
