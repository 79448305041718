import axios from 'axios';
import { CONFIG } from '../../config'

export async function fetchValidationSummaryService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/hfm/summery/?view=${payload.validationType}&report_date=${payload.selectedDate}`)
        .then(res => res)
        .catch(err => err.response)
}

export async function getValidationCommentService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/hfm/summery/comment/?view=${payload.validationType}&report_date=${payload.selectedDate}`)
        .then(res => res)
        .catch(err => err.response)
}

export async function postValidationCommentService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/hfm/summery/comment/`, payload)
        .then(res => res)
        .catch(err => err.response)
}
export async function fetchISEmailTemplate(payload) {
    
    let queryParam = `view=${payload.validationType}`
    if (payload.year) {
        queryParam += `&date=${payload.year}`
    } else if (payload.month) {
        queryParam += `&date=${payload.month}`
    } else if (payload.quarter) {
        queryParam += `&date=${payload.quarter}`
    }
    console.log("cekParam", payload)
    return axios.get(`${CONFIG.api_base_url}v1/incomestatement/hfm/email-is-validation/?${queryParam}`)
        .then(res => res)
        .catch(err => err.response)
}
export async function sendEmailHFMData(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/incomestatement/hfm/send-email-hfm`, payload)
        .then(response => response)
        .catch(err => err.response)
}

export async function saveISEmailTemplate(payload) {
    return axios.put(`${CONFIG.api_base_url}v1/incomestatement/hfm/email-is-validation`, payload)
        .then(response => response)
        .catch(err => err.response)
}
export async function fetchIncomeValidationSummaryService(payload) {
    let queryParam = `view=${payload.validationType}`
    if (payload.year) {
        queryParam += `&year=${payload.year}`
    } else if (payload.month) {
        queryParam += `&yearmonth=${payload.month}`
    } else if (payload.quarter) {
        queryParam += `&quarter=${payload.quarter}`
    }
    return axios.get(`${CONFIG.api_base_url}v1/incomestatement/hfm/summery/?${queryParam}`)
        .then(res => res)
        .catch(err => err.response)
}

export async function getIncomeValidationCommentService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/incomestatement/hfm/summery/comment/get`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function postIncomeValidationCommentService(payload) {
    return axios.post(`${CONFIG.api_base_url}v1/incomestatement/hfm/summery/comment/add`, payload)
        .then(res => res)
        .catch(err => err.response)
}
