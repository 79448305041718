import React from "react";
import { useTable, useExpanded } from "react-table";
import styled from "styled-components";
import Icon from '@material-ui/core/Icon';
import IconButton from "@material-ui/core/IconButton";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid  rgba(0,0,0,0.07);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr:hover{
      background: rgba(0,0,0,0.05)!important;
    }
    tr:nth-child(even) {
      background: #f7f7f7;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.07);
      border-right: 1px solid rgba(0,0,0,0.07);
      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns: userColumns, data }) {

  userColumns.unshift(
    {
      // Build our expander column
      id: "expander", // Make sure it has an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          <span className="unselectable">
            {isAllRowsExpanded ?
              <IconButton aria-label="search"><Icon>keyboard_arrow_down</Icon></IconButton>
              :
              <IconButton aria-label="search"><Icon>keyboard_arrow_right</Icon></IconButton>}
          </span>
        </span>
      ),
      accessor: "",
      Cell: ({ row, cell: { value } }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth}rem`
              }
            })}
          >
            <span className="unselectable">
              {row.isExpanded ?
                <IconButton aria-label="search"><Icon>keyboard_arrow_down</Icon></IconButton>
                :
                <IconButton aria-label="search"><Icon>keyboard_arrow_right</Icon></IconButton>}
            </span>{" "}
            {value}
          </span>
        ) : (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth}rem`
                }
              })}
            >
              {value}
            </span>
          )
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded }
  } = useTable(
    {
      columns: userColumns,
      data
    },
    useExpanded // Use the useExpanded plugin hook
  );

  return (
    <Styles>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps({ className : ( row.canExpand ? "hasChild" : "")  })}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </Styles>
  );
}
export { Table }