import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import NewPagination from "../../../common/components/CustomPagination";

import Tooltip from '@material-ui/core/Tooltip';
import CustomTableColumnFilter from './CustomTableColumnFilter'
import { withStyles} from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

export default function TableComponent(props) {
	const { handleColumnDataClick , rowData, rowHeader, resetFilter} = props;
	const [pivotRowData, setPivotRowData] = useState([])
	const [customFiltersValue, setCustomFiltersValue] = useState({})

	useEffect(()=> {
		if (rowData?.length){
			setPivotRowData(rowData)
		}
	}, [rowData])

	useEffect(()=> {
		if ( resetFilter){
			setPivotRowData(rowData)
			setCustomFiltersValue({})
		}
	}, [resetFilter])

	const modifyColumnDataOCOSVal = (columnData, operations) => {
		let modifiedData;
		modifiedData = columnData.toFixed(1);
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = modifiedData == '0.0' ? '-' : modifiedData;
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;

		if (operations == 'percentage' && modifiedData != '-') {
			modifiedData += '%';
		}

		return modifiedData;
	};


	const handleAppendPercentage = (data) => {
		if (data && data['data_field']) {
			let dataField = data['data_field'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};

	const modifyNewColumnData = (columnData, operations) => {
		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};


	const modifyColumnData = (columnData, operations, itemKey) => {
		let modifiedData;
		modifiedData = Math.round(columnData);
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage' || itemKey?.includes('_pct')) {
			modifiedData += '%';
		}

		return modifiedData;
	};

	const generateDropdownOptions = (key , props) => {
		const {data} = props
		const options = []
		const tempObj = {}
		 data.forEach(item => {
			if (tempObj[item?._original?.sub_column?.info?.[key]]) {
				
			}else {
				if (item?._original?.sub_column?.info?.[key]) {
					options.push(item?._original?.sub_column?.info?.[key])
					tempObj[item?._original?.sub_column?.info?.[key]] = item?._original?.sub_column?.info?.[key]
				}
			}
		})

		return options
	}

	const onFilterChange = (filterValue, columnData) => {
		let newFilterValue = {...{}, ...customFiltersValue}
		newFilterValue = {...newFilterValue, [columnData.key]: filterValue}
		const newPivotData = []
		if (newFilterValue[columnData.key]){
			pivotRowData.forEach(item => {
			
				if (newFilterValue[columnData.key].length && newFilterValue[columnData.key].includes(item?.sub_column?.info?.[columnData.key])) {
					newPivotData.push(item)
				}
			
		})
		}
		setCustomFiltersValue(newFilterValue)
		setPivotRowData(newPivotData)

	}

	const handleSubColumnChangeHeader = (data, parentData) => {
			if (data && data.length) {
				return data.map((item, i) => {

					if (item.key == 'country_name' || item.key == 'project_manager_name' || item.key == 'customer_segment') {
						let options = []
						return {
							Header: (props) => {
								options = generateDropdownOptions(item.key , props)
								return (
									<span>
										{item.label}{' '}
										<span onClick={() => { }}>
											<CustomTableColumnFilter 
											options={options}
											optionLabel={item.label}
											defaultFilterValue={customFiltersValue[item.key]}
											onFilterChange={(data) => onFilterChange(data, item)}
											/>
											
										</span>
									</span>
								)								
							},
							
							
							columns: handleSubColumnChangeHeader(item.sub_headers, item),
							id: item.key,
							Cell: (props) => {
								let isManualKey = false;
								let showPercentage = handleAppendPercentage(props.original);
								let myClassName = showPercentage ? 'percentageRowData' : '';
								let disableDrillDown = props.original['disable_drill_down'];
								myClassName +=
									disableDrillDown ||
										item.key == 'sub_total' ||
										item.key == 'total' ||
										item.key == 'grand_total'
										? ''
										: ' enableDrillDown';
	
								let classNames = '';
								if (!props.original.disable_drill_down) {
									isManualKey = true;
								}
								if (isManualKey == true) {
									classNames = 'text-center pointer';
								} else {
									classNames = 'text-center';
								}
	
								if (item.key == 'project_manager_name') {
									classNames = 'text-left word-break';
								}
								if (item.key == 'customer') {
									classNames = 'justify-left text-left word-break bold';
								}
	
	
								return (
									<div
										className={classNames}
										onClick={() => handleColumnDataClick(props.original, parentData, item)}
										>
										{
											<span className={myClassName}>
												{props.original.sub_column &&
													props.original.sub_column[parentData.key] &&
													props.original.sub_column[parentData.key][item.key]
													? item.operation ? modifyColumnData(
														props.original.sub_column[parentData.key][item.key],
														props.original.sub_column[parentData.key][
														'operations'
														], item.key
													) : props.original.sub_column[parentData.key][item.key]
													: '-'}
											</span>
										}
									</div>
								);
							},
							style: {
								fontSize: '12px',
								borderRight: i == data.length - 1 ? '1px solid #423970' : '',
								width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							},
							headerStyle: {
								overflow: 'visible',
								fontSize: '13px',
								width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
								fontWeight: 'bold',
								borderRight: i == data.length - 1 ? '1px solid #423970' : '',
							},
						};
					}

					return {
						Header: (
							<>
							<span
								onClick={() => { }}
							>
								{item.label}
							</span>
							<div >
								{item.sub_label}
							</div>
							</>
							//</Tooltip>
						),
						
						columns: handleSubColumnChangeHeader(item.sub_headers, item),
						id: item.key,
						Cell: (props) => {
							let isManualKey = false;
							let showPercentage = handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							let disableDrillDown = props.original['disable_drill_down'];
							myClassName +=
								disableDrillDown ||
									item.key == 'sub_total' ||
									item.key == 'total' ||
									item.key == 'grand_total'
									? ''
									: ' enableDrillDown';

							let classNames = '';
							if (!props.original.disable_drill_down) {
								isManualKey = true;
							}
							if (isManualKey == true) {
								classNames = 'text-center';
							} else {
								classNames = 'text-center';
							}

							if (item.key == 'project_manager_name') {
								classNames = 'text-left word-break';
							}
							if (item.key == 'customer') {
								classNames = 'text-left word-break bold';
							}


							return (
								<div
									className={classNames}
									onClick={() => handleColumnDataClick(props.original, parentData, item)}
									>
									{
										<span className={myClassName}>
											{props.original.sub_column &&
												props.original.sub_column[parentData.key] &&
												props.original.sub_column[parentData.key][item.key]
												? item.operation ? modifyColumnData(
													props.original.sub_column[parentData.key][item.key],
													props.original.sub_column[parentData.key][
													'operations'
													], item.key
												) : props.original.sub_column[parentData.key][item.key]
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							borderRight: i == data.length - 1 ? '1px solid #423970' : '',
							width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						},
						headerStyle: {
							overflow: 'visible',
							fontSize: '13px',
							width: (item.key == 'project_manager_name' || item.key == 'customer') ? '150px' : '100px',
							fontWeight: 'bold',
							borderRight: i == data.length - 1 ? '1px solid #423970' : '',
						},
					};
				});
			}
		};


	const changeKeysOfHeader = (data, isNested) => {
		if (data && data.length) {
			return data.map((item, i) => {
				item['operation'] =
					item.key == 'data_field' ||
						item.key == 'data_group' ||
						item.key == 'data_company_code' ||
						item.key == 'data_ra' ||
						item.key == 'so_item' || item.key == 'project_id'
						? ''
						: 7;
				if (item.key == 'data_group') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								id: item.key,
								fixed: 'left',
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_group' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Grand Total'
											? 'totalLabel'
											: '';

									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'project_id' || item.key == 'project_manager_name' || item.key == 'country_name_origination') {
					return {
						Header: '',
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'data_field') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								// Header: item.label,
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_field' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}
							},
						],
					};
				} else if (item.key == 'so_item' || item.key == 'plant' || item.key == 'legal_entity') {
					return {
						Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
						id: item.key,
						metaData: item.metadata,
						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
						style: {
							textAlign: 'center',
						},
					};
				} else if (item.key == 'data_company_code') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_company_code' ? '' : 7;
									let thisClassName =
										props.original[item.key] == 'Total' ? 'totalLabel' : '';
									return (
										<React.Fragment>
											{
												<span className={thisClassName}>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key == 'data_ra') {
					return {
						Header: item.label,
						id: item.key,
						fixed: 'left',
						metaData: item.metadata,
						columns: [
							{
								Header: '',
								fixed: 'left',
								id: item.key,
								className: isNested ? '' : 'noNesting',
								Cell: (props) => {
									item['operation'] = item.key == 'data_ra' ? '' : 7;

									return (
										<React.Fragment>
											{
												<span>
													{props.original ? props.original[item.key] : ''}
												</span>
											}
										</React.Fragment>
									);
								},
							},
						],
					};
				} else if (item.key.indexOf('_pct') > -1) {
					let label = item.label;
					// if(item.key == "pct_digital_services"){
					//     label = "DASS Services" +<br />+ "% of Revenue"
					// }
					return {
						Header: () => {
							let spl = item.label.split('%');
							if (spl[0] != '') {
								return (
									<span className={'multi-header'}>
										{spl[0]}
										<br />
										{' % ' + spl[1]}
									</span>
								);
							} else {
								return item.label;
							}
						},
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{
										<span>
											{props.original[item.key] != undefined
												? modifyColumnDataOCOSVal(
													props.original[item.key],
													'percentage'
												)
												: '-'}
										</span>
									}
								</div>
							);
						},
					};
				} 
				
				else if (
					item.key == 'company_code' ||
					item.key == 'country' ||
					item.key == 'classification' ||
					item.key == 'source' ||
					item.key == 'summary_acct' ||
					item.key == 'summary_acct_desc' ||
					item.key == 'currency_code'
				) {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,

						Cell: (props) => {
							return (
								<div className='text-center'>
									{<span>{props.original[item.key]}</span>}
								</div>
							);
						},
					};
				} else if (item.key == 'grand_total') {
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							let showPercentage = handleAppendPercentage(props.original);
							let myClassName = showPercentage ? 'percentageRowData' : '';
							return (
								<div className='text-center'>
									{
										<span className={myClassName}>
											{props.original
												? modifyColumnData(
													props.original[item.key],
													props.original['operations']
												)
												: '-'}
										</span>
									}
								</div>
							);
						},
						headerStyle: {
							overflow: 'visible',
							fontSize: '13px',
							fontWeight: 'bold',
						},
					};
				} else if (
					item.key == 'tran_amount' ||
					item.key == 'base_amount' ||
					item.key == 'base_amount_aop' ||
					item.key == 'tran_amount_aop'
				) {
					var isManualKey = true;
					// console.log(item.sub_headers, item, "test123123")
					return {
						Header: item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							item['operation'] = 7;
							if (props.original.summary_acct == 'Total') {
								isManualKey = false;
							}

							return (
								<div
									className={`${isManualKey == true ? 'text-center ' : 'text-center'
										}`}
									onClick={(event) => {

										// handleDGSAutomationClick(props.original, item);
									}}
								>
									{
										<span
											className={`${isManualKey == true ? 'enableDrillDown' : ''
												}`}
										>
											{props.original
												? modifyColumnData(props.original[item.key])
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							// cursor: isManualKey == true ? 'pointer' : '',
							// cursor: props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				} else if (!item.sub_headers) {
					// var isManualKey = false;

					return {
						Header:item.label,
						id: item.key,
						metaData: item.metadata,
						getProps: (state, rowInfo, column) => {
							if (
								rowInfo &&
								rowInfo.original &&
								rowInfo.original.budget_group === 'Sub Total'
							) {
								return { style: { background: '#DEE3EC' } };
							}
							return {};
						},
						Cell: (props) => {
							var isManualKey = false;

							if (!props.original.disable_drill_down) {
								isManualKey = true;
							}
							let val =
								props.original[item.key] == undefined
									? 0
									: props.original[item.key];

							return (
								<div
									className={`${isManualKey == true ? 'text-center ' : 'text-center'
										}`}
									onClick={(event) => {
										// handleInvoiceClick(props.original, item, item);
									}}
								>
									{
										<span
											className={`${isManualKey == true ? 'enableDrillDown' : ''
												}`}
										>
											{props.original
												? modifyNewColumnData(val, props.original.operations)
												: '-'}
										</span>
									}
								</div>
							);
						},
						style: {
							fontSize: '12px',
							// cursor: isManualKey == true ? 'pointer' : '',
							// cursor: props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
						},
					};
				}


				return {
					Header:() => {
						if(item.key == 'actual_normalize'){
						return (
							<span >
								{item.label}
								<LightTooltip classname='custom-tooltip ' title={'Normalised Actuals are calculated in order to incorporate differences in time duration between Data sample and simulated output'}>
									<InfoOutlinedIcon className="info-icon normalize-actual-tooltip"/>
								</LightTooltip>
							</span>
						);
						}else{
							return item.label
						}
					},
					id: item.key,
					columns: handleSubColumnChangeHeader(item.sub_headers, item),
					// style: {
					// 	borderRight: '1px solid #423970',
					// 	cursor: '',
					// },
					headerStyle: {
						overflow: 'visible',
						//fontSize: '13px',
						//fontWeight: 'bold',
						borderRight: '1px solid #423970',
					},
				};
			});
		}
		return [];
	};

	const pivotHeaderData = changeKeysOfHeader(rowHeader)
	let tableLen = pivotRowData && pivotRowData.length || 5;

	// console.log(rowData, rowHeader , "-----row data")
	return <div className="">
		<ReactTable
			columns={pivotHeaderData}
			data={pivotRowData}
			defaultPageSize={9}
			pageSize={9}
			loading={false}
			className="-striped -highlight xpna-react-table single-line-header total-sales-report"
			PaginationComponent={NewPagination}
			sortable={false}
		/>
	</div>
}