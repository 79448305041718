import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HighchartOptions from "./highcharts.options";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CHART_COLORS } from '../../../constant';

export default function Economies (){
const {analyticsModule: { economicsOfScale: { otherCOSPercentageOfRevenueColor, revenueColor }}} = CHART_COLORS;
const seriesData= [15,21,16,25,15,18,19,17,16,20,22,19];
console.log(otherCOSPercentageOfRevenueColor, revenueColor);
let subTitle = {}

let windowSize = window.innerWidth

if(windowSize <= 667){
    subTitle = {
                    text :' Economies of Scale- XX %  ',
                    y: 40,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}else{
    subTitle = {
                    text :' Economies of Scale- XX % ',
                    align: 'right',
                    x: -40,
                    y: 63.5,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
}
return(
    <div className="px-5">
           <Card>
             <CardContent>   
            <HighchartsReact
                highcharts={Highcharts}
                options = {
                    {
                        chart: {
                            height: 440,
                        },
                        colors: [
                            otherCOSPercentageOfRevenueColor, 
                            revenueColor,
                        ],

                        title: {
                            text: 'Economies of Scale Analysis',
                        },
                        
                        subtitle : subTitle,

                        credits: {
                            enabled: false
                        },
                        
                        xAxis: {
                            title:{
                                text:"Months",
                                offset: 30,
                            },
                            visible:true,
                            categories: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                            zoomEnabled: false,
                            
                        },
                        yAxis:[            
                            {
                            title: {
                                text: '$M',                              
                            },
                            // max:100,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            visible:true,
                            
                            zoomEnabled:false,
                            tickInterval:20,
                        }, {
                            title: {
                                text: ' ',                              
                            },
                            max:12,
                            tickInterval:3,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            opposite: true
                        },],

                        tooltip: {
                          
                        },
                        

                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                        },
    
                        series: [{
                            type: 'line',
                            yAxis: 1,
                            data: [9, 9.3, 9.5, 8.1, 8.8, 8.5, 8.2, 7.8, 8.7, 9.2, 9.5, 8.5],
                            name: 'Other COS% of Revenue',
                        },{
                            type: 'column',
                            data: seriesData,
                            name: 'Revenue'
                            
                        }, ]
                    }
                }
            />
            </CardContent>
         </Card> 
        </div>
   )

}