
export const transformFilterData = (filterData) => {
    let filterObj = {
      yearArr: [],
      qtrArr: [],
      monthArr: [],
      monthArrFuture: [],
      plantArr: [],
      countryArr: [],
      simulationArr: [],
      
      periodArr: [],
      dataTypeArr: [],
      salesRegionArr: [],
      productArr: [],
      dealerArr: [],
      channelArr: [],
      costCenterArr: [],
      customerArr: []
    }
  
    if (filterData && filterData.length) {
      filterData.forEach(item => {
        if (item.key == 'year') {
          filterObj.yearArr = item.value
        }
        if (item.key == 'quarter') {
          filterObj.qtrArr = item.value
        }
        if (item.key == 'month_filter') {
          filterObj.monthArr = item.value
        }
  
        if (item.key == 'period') {
          filterObj.periodArr = item.value
        }
        if (item.key == 'data_type') {
          filterObj.dataTypeArr = item.value
        }
        if (item.key == 'country') {
          filterObj.countryArr = item.value
        }
        if (item.key == 'plant') {
          filterObj.plantArr = item.value
        }
        if (item.key == 'sales_region') {
          filterObj.salesRegionArr = item.value
        }
        if (item.key == 'product') {
          filterObj.productArr = item.value
        }
        if (item.key == 'dealer') {
          filterObj.dealerArr = item.value
        }
        if (item.key == 'channel') {
          filterObj.channelArr = item.value
        }
        if (item.key == 'cost_center') {
          filterObj.costCenterArr = item.value
        }
        if (item.key == 'customer') {
          filterObj.customerArr = item.value
        }
      })
    }
    return filterObj
  }
  
  export const transformDefaultHeaderValues = (_data) => {
    const newData = JSON.parse(JSON.stringify(_data))
    const dummyArr = []
    newData.forEach((item, index) => {
      if (index == 0){
        dummyArr.push(item)
      }else{
        item.key = item.key.toUpperCase()
        dummyArr.push(item)
      }
    })
  
    return dummyArr.slice(0,5)
  }
  