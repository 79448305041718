import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import BarChart from './chart';
import TableComponents from './table';
import Switch from '@material-ui/core/Switch';
import DownloadExcel from "../../../../images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';
// import { Switch, Route } from "react-router-dom";
import { getAccessByMenuKey } from "../../../../utils/userManagement";
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';


export class AdhocChartComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayProperty: '',
            barChartView: true,
            barChartViewMargin: true 
        }
    }

    handleChangeView = (event) => {
        this.setState({barChartView: event.target.checked})
        this.setState({barChartViewMargin: event.target.checked})
    };

    handleExcelDownload = () => {
        this.props.fetchAdhocChart({export : true})
    }

    componentDidMount() {
        this.props.fetchAdhocChart({})

        setTimeout(() => {
            this.setState({
                displayProperty: 'flex'   
            })
        }, 3000)
    }

    render() {
        const { incomeStatementState, authReducer } = this.props
        // const { authReducer } = this.props

        const {displayProperty, barChartView, barChartViewMargin} = this.state
        const { adhocChartData } = incomeStatementState
        return (
            <div>
                <div>
                <Grid container spacing={1} sm={6}>
                            <Grid item xs={12} sm={1} className={'mt-8'} >
                                <Tooltip title="Back">
                                    <Button variant='contained' color="primary" className='back-button' onClick={this.props.handleBack}><ChevronLeftIcon /></Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={11} className={'mt-15'} >
                                <h2 className='mb-20'> {'Revenue and Std Margin by Customer ' + (adhocChartData.salesData && adhocChartData.salesData.title)}</h2>
                            </Grid>
                   </Grid>
                    <div className={'flex ai-center jc-space-between'}>
                        {/* <div> */}
                            {'Revenue'} 
                        {/* </div> */}
                        <div className='flex ai-center'>
                            <div className='ml-20'>
                                <label>Table</label>
                                <Switch
                                className="custom-switch"
                                checked={this.state.barChartView}
                                onChange={this.handleChangeView}
                                color="primary"
                                name="toggleTableGraph"
                                />
                                <label>Bar</label>
                            </div>
                            {!barChartView ? (<Tooltip title={'Download to Excel'} className='pointer'>
                                    <img
                                        src={DownloadExcel}
                                        className='pointer ml-10'
                                        height='30'
                                        onClick={(event) => {
                                        event.preventDefault();
                                        this.handleExcelDownload();
                                        }} 
                                    />
                                </Tooltip>) 
                            : ''}
                        </div>
                    </div>
                    {/* <div style={{display: `${displayProperty ? displayProperty : 'block'}`}}> 
                        {adhocChartData?.salesData  ?
                        // adhocChartData.salesData.map(item => {
                                    <BarChart seriesData={adhocChartData.salesData} />
                        // }) 
                        : null}
                    </div> */}
                    {adhocChartData?.salesData  ?
                        (barChartView ? (<BarChart  seriesData={adhocChartData.salesData} />) : (<TableComponents className="ReactTable -striped -highlight" tableData={adhocChartData.salesData} />))
                    : null}

                </div>
                <div style={{ 'marginTop': '20px' }}>
                    <div className='mb-10'>
                        {'Std Margin'}
                    </div>
                    {adhocChartData?.marginData  ?
                        (barChartViewMargin ? (<BarChart  seriesData={adhocChartData.marginData} />) : (<TableComponents tableData={adhocChartData.marginData} />))
                    : null}
                </div>
            </div>


        );
    }
}

export default
    connect(
        state => {

            return {
                incomeStatementState: state.incomeStatement
            }
        },
        dispatch => {
            return {
                fetchAdhocChart(payload) {
                    dispatch({
                        type: 'FETCH_ADHOC_CHART',
                        payload: payload
                    });
                }
            };
        }
    )(AdhocChartComponent);