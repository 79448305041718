import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleTableTransform } from '../../../../utils/numberUtils'
import { checkToOpenInvoiceDetail } from '../utils'
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import Grid from '@material-ui/core/Grid';

export class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.sortList = {}
        this.headerColorArr = ['white', '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']
        this.state = {
            showActionLoading: false,
            seriesData: [],
            loading: true,
            showInvoiceDetailModal: false,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: '',
            invoicePayload: null
        }
    }

    componentDidMount() {
        this.setState({showActionLoading: true});
        let payload = {
            receivableTabSelectorValue: this.props.receivableTabSelectorValue
        }
        this.props.getMonthlyRollingView(payload)
    }

    businessUnitClick = (data, type, rowData) => {
        const { summaryState } = this.props;
        var headerData = []
        if (summaryState.internalData &&
            summaryState.internalData.row_header &&
            summaryState.internalData.row_header.length
        ) {
            var _data = [...[], ...summaryState.internalData.row_header]
            headerData = _data.filter(item => {
                if (!(item.key == "business_unit" || item.key == "grand_total")) {
                    return item
                }
            })
        }
        headerData = this.changeKeysOfHeader(headerData)
        this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
    }
    componentDidUpdate(prevProps) {
        const { summaryState, dashboardState} = this.props;
        if (prevProps.dashboardState.loading &&
            !dashboardState.loading) {
          this.setState({
            showActionLoading: false,
          });
        }
    }
    appendHyperLinkRowData = (item, type, props) => {
        if (type == 'recievables') {
            return (
                <span>
                    {props.original['business_unit_label']}
                </span>
            )
        }

        return (
            <span>
                <u>{props.original['business_unit_label']}</u>
            </span>)

    }

    changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    Header: item.label,
                    id: item.key,
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {
                                    item.key == 'business_unit' ?
                                        props.original[item.key] == "Grand Total" ?
                                            <span><b>{props.original[item.key]}</b></span> :
                                            <>{props.original['business_unit_label']}</> :
                                        <span>{handleTableTransform(props.original, item)}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        cursor: item.key == 'grand_total' ? '' : ''
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        //backgroundColor: '#b1bace',
                        // backgroundColor: this.headerColorArr[i]
                    }
                }
            })
        }
        return []
    }

    handleColumnClick = (state, colInfo, columnDetail, etc) => {
        console.log(colInfo,'>>>>',columnDetail)
        const { business_unit, parent_group } = colInfo.original;
        const payload = {
            aging_category: columnDetail.id,
            parent_group,
            bu: business_unit
        }
        // if (columnDetail.id != 'grand_total' && business_unit != "Grand Total") {
        //     if (checkToOpenInvoiceDetail(columnDetail.id)) {
        //         this.props.getInvoiceDetail(payload);
        //         this.setState({
        //             showInvoiceDetailModal: true,
        //             invoicePayload: payload

        //         })
        //     }
        // }


    }

    columnClickFunction = (state, colInfo, abc, etc) => {
        if (colInfo) {
            return {
                onClick: (e) => {
                    this.handleColumnClick(state, colInfo, abc, etc)
                }
            }
        } else {
            return {}
        }
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    changeValuesOfData = (data, type) => {
        if (data && data.length) {
            return data.map(item => {
                item['parent_group'] = type;
                return item
            })
        }
        return []
    }


    handleInvoiceSorting = (columnId) => {
        const { invoicePayload } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoicePayload,
            receivableTabSelectorValue: this.props.receivableTabSelectorValue
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }

    render() {
        let { summaryState, invoiceDetailState } = this.props;
        const { showInvoiceDetailModal } = this.state;
        let externalDataColumns = summaryState.externalData && summaryState.externalData.row_header ? this.changeKeysOfHeader(summaryState.externalData.row_header, 'external') : [];
        let externalDataList = summaryState.externalData && summaryState.externalData.row_data ? this.changeValuesOfData(summaryState.externalData.row_data, 'external') : [];
        let internalDataColumns = summaryState.internalData && summaryState.internalData.row_header ? this.changeKeysOfHeader(summaryState.internalData.row_header, 'slb') : [];
        let internalDataList = summaryState.internalData && summaryState.internalData.row_data ? this.changeValuesOfData(summaryState.internalData.row_data, 'slb') : [];
        let unbilledDataColumns = summaryState.unbilledData && summaryState.unbilledData.row_header ? this.changeKeysOfHeader(summaryState.unbilledData.row_header, 'unbilled') : [];
        let unbilledDataList = summaryState.unbilledData && summaryState.unbilledData.row_data ? this.changeValuesOfData(summaryState.unbilledData.row_data, 'unbilled') : [];
        let totalDataColumns = summaryState.totalData && summaryState.totalData.row_header ? this.changeKeysOfHeader(summaryState.totalData.row_header, 'recievables') : [];
        let totalDataList = summaryState.totalData && summaryState.totalData.row_data ? this.changeValuesOfData(summaryState.totalData.row_data, 'recievables') : [];
        
        let mainColumnHeader = externalDataColumns || internalDataColumns || unbilledDataColumns ||totalDataColumns;
        return (
            <>
                {summaryState.loading ?
                    <div className='progressfullpage'><CircularProgress /></div> : 
                    <div className='summaryPage'>
                       
                        <ReactTable
                            data={internalDataList}
                            columns={mainColumnHeader}
                            className="xpna-react-table"
                            defaultPageSize={0}
                            NoDataComponent={() => null}
                            pageSize={internalDataList.length}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                        />
                        <label className='title'>3rd Party Receivables</label>
                        <ReactTable
                            TheadComponent={props => null}
                            data={externalDataList}
                            columns={externalDataColumns}
                            defaultPageSize={5}
                            pageSize={externalDataList.length}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTdProps={this.columnClickFunction}
                        />
                        <label className='title'>SLB Receivables</label>
                        <ReactTable
                            TheadComponent={props => null}
                            data={internalDataList}
                            columns={internalDataColumns}
                            pageSize={internalDataList.length}
                            defaultPageSize={5}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTdProps={this.columnClickFunction}
                        />
                        <label className='title'>Unbilled</label>
                        <ReactTable
                            TheadComponent={props => null}
                            data={unbilledDataList}
                            columns={unbilledDataColumns}
                            pageSize={unbilledDataList.length}
                            defaultPageSize={5}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTdProps={this.columnClickFunction}
                        />
                        <label className='title'>Total by BU</label>
                        <ReactTable
                            TheadComponent={props => null}
                            data={totalDataList}
                            columns={totalDataColumns}
                            pageSize={totalDataList.length}
                            defaultPageSize={5}
                            className="xpna-react-table -striped -highlight"
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTdProps={this.columnClickFunction}
                        />
                        
                    </div>
                }
                {
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                    />
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summaryMonthly,
                invoiceDetailState: state.invoiceDetailState,
                dashboardState: state.dashboardState,
            }
        },
        dispatch => {
            return {
                getMonthlyRollingView(data) {
                    dispatch({
                      type: 'FETCH_MONTHLY_ROLLING_VIEW_DATA',
                      payload: data
                    });
                  },
                getInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_INVOICE_DETAIL',
                        payload: data
                    });
                }
            };
        }
    )(Summary);
