import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function HighChart() {

  return (
      <div className="labelClass">
        <HighchartsReact 
          highcharts={Highcharts} 
          options={{
            chart: {
                type: 'waterfall',
                height: 370,
            },

            title: {
                text: ''
            },

            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontWeight: 'bolder'
                    }
                }
            },

            yAxis: {
                labels: {
                  enabled: false
                },
                title: {
                    text: ''
                }
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>${point.y:,.2f}</b> USD'
            },

            credits: {
                enabled: false
            },

            plotOptions: {
              waterfall: {
                groupPadding: 0,
                pointPadding: 0,
                borderWidth: 0              }
            },

            series: [{
                data: [{
                    name: 'FCF',
                    y: 60,
                    color: "#4472c4"
                }, {
                    name: 'EBITDA',
                    y: 20,
                    color: "#64bd48"
                }, {
                    name: 'AR',
                    y: -30,
                    color: "#db4437"
                }, {
                    name: 'AP',
                    y: 10,
                    color: "#64bd48"
                }, {
                    name: 'Inventory',
                    y: 20,
                    color: "#64bd48"
                }, {
                    name: 'FCF ',
                    isSum: true,
                    color: "#4472c4"
                }],
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold'
                    }
                },
                pointPadding: 0
            }]
          }} />
      </div>
  )
}

export default HighChart;
