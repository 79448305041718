import React, { useState } from 'react';
import '../Auth.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

function ResetPassword() {
    const [isPasswordValue, setPasswordValue] = useState();
    const [isConfirmPasswordValue, setConfirmPasswordValue] = useState();
    const dispatch = useDispatch();
    const loginState = useSelector(state => state);
    const searchUrl = window.location.search;
    const [showPassword, setshowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const [error, setError] = useState("");
    const validPassword = RegExp(/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,10}$/)

    const handleResetSubmit = (event) => {
        event.preventDefault();
        let token = '';
        if (searchUrl.includes('token')) {
            token = searchUrl.split('token=')[1]
        }
        let payload = {
            new_password: event.currentTarget.password.value,
            confirm_password: event.currentTarget.cnfPassword.value,
            token,
            loginState
        }
        dispatch({
            type: "PERFORM_VERIFY_TOKEN",
            payload
        })
    }

    const handleChangePassword = (event) => {
        const err = validPassword.test(event.target.value) ? "" : "Password must be according to specifications !"
        setPasswordValue(event.target.value)
        setError(err);
    }

    const handleClickShowPassword = (type) => {
        if (type === 'password') {
            setshowPassword(!showPassword)
        }
        if (type === 'confirmPassword') {
            setshowConfirmPassword(!showConfirmPassword)
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            {
                <Grid container component="main" className='loginPage resetPage'>
                    <CssBaseline />
                    <Grid item xs={false} sm={6} md={8} className='bannerImageResetPage' >
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
                        <div className='loginFormContainer'>
                        <h1 className="primary text-center mb-0">xP&A - Extending FP&A across the Organization</h1>
                        <p className="mt-0 dark-grey text-center"><i><b>Multiple packaged horizontal and industry-specific solutions</b></i></p>
                            <form onSubmit={handleResetSubmit}>
                                <h4>Enter new password</h4>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <OutlinedInput
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        onChange={(event) => { handleChangePassword(event) }}
                                        labelWidth={70}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('password')}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {error.length > 0 && <div className='error'>{error}</div>}
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="password">Confirm New Password</InputLabel>
                                    <OutlinedInput
                                        required
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="cnfPassword"
                                        onChange={(event) => setConfirmPasswordValue(event.target.value)}
                                        labelWidth={170}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('confirmPassword')}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    className="resetPasswordBtn"
                                    fullWidth
                                    disabled={isPasswordValue !== isConfirmPasswordValue || error.length > 0}
                                    variant="contained"
                                    color="primary"
                                >
                                    Reset your password
                                    </Button>
                            </form>
                            <div>
                                Note: Password must be 6-10 characters long including one uppercase, one lowercase, one alphanumeric and one special character.
                            </div>
                        </div>
                    </Grid>
                </Grid>}
        </>
    );
}

export default ResetPassword;
