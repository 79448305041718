import _ from 'lodash'
import axios from 'axios'
import { CONFIG } from '../config'
export const getMenuByKey = (menus, menuID) => {
  let selectedMenu = null
  menus.forEach(x => {
    if (x.key == menuID) {
      selectedMenu = x
    }
    if (x.child != null) {
      let res = getMenuByKey(x.child, menuID)
      if (res != null) {
        selectedMenu = res
      }
    }
  });
  return selectedMenu
}

export const getAccessByMenuKey = (menus, menuID, access) => {
  if (menus == undefined || menus.length == 0) {
    return false
  }
  let menu = getMenuByKey(menus, menuID)
  if (menu != null) {
    return menu.access[access]
  }
  return false
}

export const getIsLeafByMenuKey = (menus, menuID, access) => {
  let menu = getMenuByKey(menus, menuID)
  if (menu != null) {
    return menu.is_leaf
  }
  return false
}

export const getActionOwneruser = (allUser, bu) => {
  //default list by user BU
  let buUser = allUser.filter(x => { return x.bu.toLowerCase() == bu?.toLowerCase() });
  let corporateUser = allUser.filter(x => { return x.role.toLowerCase().indexOf("corporate") > -1 });
  let functionalUser = allUser.filter(x => { return x.role.toLowerCase().indexOf("functional") > -1 });

  let ritvik = allUser.filter(x => { return x.name.toLowerCase() == "ritvik" });
  let sonal = allUser.filter(x => { return x.name.toLowerCase() == "sonal" });
  let asif = allUser.filter(x => { return x.name.toLowerCase() == "asif" });
  let robin = allUser.filter(x => { return x.name.toLowerCase() == "robin" });
  switch (bu && bu.toLowerCase()) {
    case "BU 1":
      buUser = buUser.concat(functionalUser);
      buUser = buUser.concat(ritvik);
      buUser = buUser.concat(sonal);
      buUser = buUser.concat(asif);
     // buUser = buUser.concat(robin);
      break;
    case "BU 2":
    case "BU 3":
      buUser = functionalUser.concat(corporateUser);
      break;
    case "BU 4":
      buUser = buUser.concat(corporateUser);
  }
  buUser = buUser.concat(robin);
  buUser = _.uniqBy(buUser, 'login_id')
  buUser = buUser.sort(
    function (a, b) {
      if (a.role === b.role) {
        // order by role and name
        return a.full_name > b.full_name ? 1 : -1;
      }
      return a.role > b.role ? 1 : -1;
    });
  return buUser
}
export async function getList() {
    const url = `${CONFIG.api_base_url}v1/user/getall`
    return axios.post(url)
        .then(response => response.data)
        .catch(err => err.response)
}