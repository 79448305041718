import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HighchartOptions from "./highcharts.options";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CHART_COLORS } from '../../../constant';

export default function CustomerChangeAnalysis () {
const {analyticsModule: { customerChangeAnalysis: { Q1FY21Color,customersWithHigherRevenueColor, customersWithLowerRevenueColor, newCustomersColor, discontinuedCustomersColor, Q2FY21Color } }} = CHART_COLORS;
const seriesData= [15,21,16,25,15,18,19,17,16,20,22,19];

return(
    <div className="px-5">
           <Card>
             <CardContent>   
            <HighchartsReact
                highcharts={Highcharts}
                options = {
                    {
                        chart: {
                            height: 400,
                        },
                        title: {
                            text: 'Market Activity and Share Analysis by Customers',
                        },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            enabled : false,
                        },
                        plotOptions: {
                            series: {
                              groupPadding: 0,
                              pointPadding: 0,
                              borderWidth: 0
                            }
                          },
                        xAxis: {
                            title:{
                                text:" ",
                                offset: 30,
                            },
                            visible:true,
                            categories: ['Q1-FY21', 'Customers with Higher Revenue', 'Customers with Lower Revenue', 'New Customers', 'Discontinued Customers', 'Q2-FY21'],
                            zoomEnabled: false,
                        },
                        yAxis:[            
                            {
                            title: {
                                text: '$M',                              
                            },
                            max:100,
                            // gridLineWidth: 0,
                            // minorGridLineWidth: 0,
                            visible:true,
                            
                            zoomEnabled:false,
                            tickInterval:10,
                        }],
                        tooltip: {
                          
                        },
                        series: [{
                            type: 'columnrange',
                            data: [{
                                low: 0,
                                high: 65,
                                color: Q1FY21Color
                            },{
                                low: 65,
                                high: 80,
                                color: customersWithHigherRevenueColor
                            },{
                                low: 68,
                                high: 85,
                                color: customersWithLowerRevenueColor
                            },{
                                low: 70,
                                high: 90,
                                color: newCustomersColor
                            },{
                                low: 68,
                                high: 80,
                                color: discontinuedCustomersColor
                            },{
                                low:0,
                                high:70,
                                color: Q2FY21Color
                            }],
                        }, ]
                    }
                }
            />
            </CardContent>
         </Card> 
        </div>
   )

}