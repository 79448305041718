import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import NewReactPureTable from "../../../common/components/NewReactTable/pureNewTable";
import Tooltip from '@material-ui/core/Tooltip';
import './table.scss'
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";

export default function TableComponent(props) {
	const { handleColumnDataClick, handleSaveChange, showHbpSimulation } = props;
	const [pivotRowData, setPivotRowData] = useState([])
	const [commentModal, setCommentModal] = useState(false)
	const [selectedCommentRowData, setSelectedCommentRowData] = useState({})
	const [selectedCommentItem, setSelectedCommentItem] = useState({})
	const [selectedCommentParent, setSelectedCommentParent] = useState({})
	const [selectedDrilldown, setSelectedDrilldown] = useState({})
	const [commentValue, setCommentValue] = useState('')
	const cementState = useSelector(state => state.shreeCementState)
	const { rowData, rowHeader, loading } = props.tableData

	const tableClassName = props.className? props.className : 'newNewCustomTable fixedFirstColumTableSummaryView expandableRows'

	const handleInputChange = (input, data, header, parent ) => {
		console.log(input, data, header, parent, "---data input")
		handleSaveChange(input, data, header, parent)
	}

	const newChangeKeysOfRow = (data) => {
		if (data && data.length > 0) {
			return data.map((item, i) => {
				if (item.sub_rows) {
					item['subRows'] = item.sub_rows;

					newChangeKeysOfRow(item.sub_rows);

				} else {
					item['subRows'] = [];
				}
				return item;
			});
		} else return [];
	};

	const modifyNewColumnData = (columnData, operations) => {

		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};

	const handleCommentShowClick = (rowData, parentData, item, parentIndex, parent2Index) => {
        toggleCommentModal(rowData)
		// const commentData = rowData.sub_column[parentData.key]['sub_column'][item.key]

		setCommentModal(true)
		setSelectedCommentRowData(rowData)
		setSelectedCommentItem(item)
		setSelectedCommentParent(parentData)
    }

	const toggleCommentModal = (rowData) => {
		let visib = !commentModal;
		setCommentModal(visib)
		setCommentValue('')
		setSelectedDrilldown(rowData)
	};

	const handleThreeLevelDrilldownClick = (rowData, parentData, subParentData, item ) => {
		/* let payload = {
			"origination": "true", //follow main grid param logic
			"mpr": "true", //follow main grid param logic
			"grid_header_value": parentData.label, // header.label
			"sub_grid_header_value": subParentData.label, // header.label
			"sub_sub_grid_header_value": item.label, // header.label
			"level_value": rowData.data_value == "CA" ? "CAN" : rowData.data_value, //data_value
			"parent": rowData.parent, //parent
			"parent_one": rowData.parent1 //parent1

		}

		this.props.handlePropsThreeLevelInvoiceClick(payload);
		this.showDrilldownHeaderValue = `${payload.grid_header_value}`

		if (payload.sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_grid_header_value}`
		}
		if (payload.sub_sub_grid_header_value) {
			this.showDrilldownHeaderValue += ` - ${payload.sub_sub_grid_header_value}`
		}
		if (payload.level_value) {
			this.showDrilldownHeaderValue += ` - ${payload.level_value}`
		}
		// console.log(payload, "CheckDrillDown" )
		this.setState({
			isExpandable: false,
			invoiceObject: payload,
			showInvoiceLoader: true,
			isThreeLevelDrilldown: true
		}); */
	}

	const handleAppendPercentage = (data) => {
		if (data && data['data_field']) {
			let dataField = data['data_field'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};

	const handleRowClick = (ori) =>{
		props.handleDrilldown(ori, false)
	}

	const newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
		if (data && data.length > 0) {
			return data.map((item, i) => {
				const tempItemData = {
					Header: item.label,
					id:
						item.key +
						'_' +
						parentData.key +
						'_' +
						subParentData.key +
						Math.random('92129911') +
						new Date().getTime(),
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				tempItemData.Cell = (props) => {
					const { row } = props;
					let showPercentage = handleAppendPercentage(row.original);
					let myClassName = showPercentage ? 'percentageRowData' : '';
					let showBorder = false
					let showDrillDown = false
					if ( subParentData?.key == 'total' && item.key == 'total'){
						showBorder = true
					}

					
					if (!row.original.disable_drill_down) {
						showDrillDown = true;
					}
					return (
						<div 
						className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} 
						style={{borderRight: showBorder && '1px solid #423970', lineHeight: '40px'}}
						onClick={()=> {
							if (showDrillDown){
								handleThreeLevelDrilldownClick(row.original, parentData, subParentData, item)
							}
						}}
						>
							{
								<span className={myClassName}>
									{row.original &&
									row.original.sub_column &&
									row.original.sub_column[parentData.key] &&
									row.original.sub_column[parentData.key]['sub_column'] &&
									row.original.sub_column[parentData.key]['sub_column'][
										subParentData.key
									]
										? modifyNewColumnData(
												row.original.sub_column[parentData.key]['sub_column'][
													subParentData.key
												][item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							}
						</div>
					);
					// }
				};

				// tempItemData.Cell = ({row}) =>  <span>{row.original ? row.original.subColumn[parentData.key][subParentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

	const newChangeHeaderKeysLevel2 = (data, parentData, level1Index) => {
		if (data && data.length > 0) {
			return data.map((item, level2Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + '_' + parentData.key,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel3(
						item.sub_headers,
						parentData,
						item
					);
				} else {

					if (item.key == 'bp') {
						// Header: item.label,
						let btnInfo = (
							<Tooltip title={"Edit Simulation"}>
							<Icon
								className="fs-20 primary  btn-edit-simulation-bp"
								color={"inherit"}
								disabled
								onClick={() => {
									showHbpSimulation();
								}
								}
							>
								info
							</Icon>
						</Tooltip>
						
						// <Button
						// 	size="small"
						// 	variant="outlined"
						// 	color="primary"
						// 	className="mr-10"
						// 	endIcon={<Icon>info</Icon>}
						// 	onClick={showHbpSimulation}
						// ></Button>
						);
						tempItemData.Header = () => { 
							return  [btnInfo, item.label];
						}
					}
					tempItemData.Cell = (props) => {
						const { row } = props;

						if(item.key == 'manual_norm' || item.key == 'target_setting'){
							let thisNum = 0.0

							if(row.original && row.original.sub_column && row.original.sub_column[parentData.key]){
										thisNum = row.original.sub_column[parentData.key][item.key]
							}
								

							return (
								<FormControl variant="outlined" fullWidth className='selectOutlineSmall maxw-150 simulation-tabel' size='small'>
									<TextField
									variant="outlined"
									size="small"
									fullWidth
									type="number"
									// label="Search Email/First Name"
									defaultValue={thisNum == 0 ? '' : thisNum}
									onChange={(event) => { handleInputChange(event.target.value, row.original, item, parentData) }}
									/>
								</FormControl>
							)
						}

						if(item.key == 'pct_change'){
							let thisNum = 0.0

							if(row.original && row.original.sub_column && row.original.sub_column[parentData.key]){
										thisNum = row.original.sub_column[parentData.key][item.key]
							}

							if(row.original["data_field"] == "Total Cement"){ //disable drilldown
								thisNum = 0
							}


							return (
								<span className={ thisNum != 0 ? 'pointer' : '' } onClick={()=>{ if(thisNum != 0 ){handleRowClick(row.original)}  }}>
									{row.original && row.original.sub_column && row.original.sub_column[parentData.key] 
										? 
	
										modifyNewColumnData(
											row.original.sub_column[parentData.key][item.key],
											row.original['operations']
									  )
										
										: ''}
								</span>
							);
						}

						
						if (parentData.key.includes('comment')) {
							
								return (
									<div 
									style={{'color': '#c0b6ed' }}
									onClick={() => {
                                        handleCommentShowClick(row.original, parentData, item, level1Index, level2Index)
                                	}}>
									<Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
								</div>
									
								);
							
						}

						return (
							<span>
								{row.original && row.original.sub_column && row.original.sub_column[parentData.key] 
									? 

									modifyNewColumnData(
										row.original.sub_column[parentData.key][item.key],
										row.original['operations']
								  )
									
									: ''}
							</span>
						);
					};
				}

				return tempItemData;
			});
		} else return [];
	};

	const newChangeKeysOfHeader = (data) => {
		if (data && data.length > 0) {
			return data.map((item, level1Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel2(
						item.sub_headers,
						item,
						level1Index
					);
				} else {
					if (item.key === 'data_field') {
						tempItemData.sticky = 'left';
					}
					tempItemData.Cell = (props) => {
						const { row } = props;
						if (item.key === 'data_field') {
							return row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}

									className={
										row.original.level == 0
											? 'rt-main-header'
											: 'rt-main-child'
									}
								>
									<span
										className={
											row.isExpanded
												? 'rt-expanded-class'
												: 'rt-collapsed-class'
										}
									></span>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							) : (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}

									className={
										row.original.level == 0
											? 'rt-main-header'
											: 'rt-main-child'
									}
								>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							);

							// <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						} else if (
							item.key.includes('comment')) {
							return (
								<div 
                                style={{'color': '#c0b6ed' }}
                                onClick={() => {
                                        handleCommentShowClick(row.original, item)
                                }}>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</div>
							);
						} else {
							return (
								<span>
									{row && row.original && row.original[item.key]
										? modifyNewColumnData(
												row.original[item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							);
						}
					};
				}

				return tempItemData;
			});
		} else return [];
	};


	let newPivotRowData = [];
	newPivotRowData = newChangeKeysOfRow(rowData);
	// console.log ('rowData', rowData)
	// console.log ('modified row data', newPivotRowData)


	let newPivotHeaderData = []
	newPivotHeaderData = newChangeKeysOfHeader(rowHeader);
		
	return <div className="modalData">
		{newPivotHeaderData.length > 0 && <NewReactPureTable
			pivotRowData={newPivotRowData}
			className={tableClassName}
			loading={loading}
			pivotHeaderData={newPivotHeaderData}
			showLoader={loading}
		/>}
	</div>
}