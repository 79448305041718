import axios from 'axios';
import { CONFIG } from '../../config'

export async function chatUploadFileService(payload, channel) {
    return axios.post(`${CONFIG.api_base_url}v1/chat/channel/file/${channel}`, payload)
        .then(res => res)
        .catch(err => err.response)
}

export async function chatDownloadFileService(payload) {
    return axios.get(`${CONFIG.api_base_url}v1/chat/channel/file/${payload.id}/${payload.fileId}`)
        .then(res => res)
        .catch(err => err.response)
}
