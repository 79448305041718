import React, { useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import NewPagination from "../../../../common/components/CustomPagination";
import ReactTable from "react-table-6";
import withFixedColumns from 'react-table-hoc-fixed-columns';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function TableComponent(props) {
    const { tornadoData, tableHeaderData, doExport,currentLimit, fileName, lastDate,  tornadoRadioButtonValue, tabSelectorValue} = props

    useEffect(() => {

        let payload = {selectedDate: lastDate, bu:"Summary", radioType: tornadoRadioButtonValue,receivableTabSelectorValue : tabSelectorValue, currentLimit} ;
        props.fetchData(payload);
        
    }, [doExport, lastDate, tornadoRadioButtonValue, currentLimit]);
    

    return (
        <> 
            {
                
                <Grid container spacing={2} >
                        <>
                            <Grid item xs={12}>
                                <ReactTableFixedColumns
                                    data={tornadoData.data}
                                    columns={tableHeaderData}
                                    defaultPageSize={10}
                                    className="xpna-reactFixed-table -striped -highlight"
                                    PaginationComponent={NewPagination}
                                    pageSizeOptions={[10, 20, 30]}
                                    resizable={true}
                                    // getTdProps={props.clicked}
                                />
                            </Grid>
                            
                        </>
                </Grid>
            }
        </>
    )
}

export default TableComponent;