import React from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router';
import ImprovementAnalysis from '../../ECFM/ImprovementAnalysis';
import './FreeCashFlow.scss';
import { APP_ICONS } from '../../../constant';
import { getList } from '../../../utils/userManagement';


export class NewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            performanceSelectorValue: '1',
            newRadioSelectorValue:  this.props.isDataBrowser == "true" ? '99' : '1',
            fcfImprovementSelectorValue: '0',
            dataBrowserSelectorValue: '0',
            fcfDimension : "lob",
            fcfPeriod : "fy", 
            fcfKPICategory : "",
            fcfKPIType : "",
            fcfKPI : "",
            yearLabel : "",
            quarter : "",
            yearMonth : "", 
            users: [],
            prefilledPlanValue: {},
            saveData: [],
        }
    }

  

    handleCollaborateClick = () => {
        this.setState({
            newRadioSelectorValue: '8',
        }, () => {this.fetchFCFData()})
    }

    handleImprovementPlanClick = (data, payload) => {
        this.setState({
            newRadioSelectorValue: data == 'view' ? '4' : '2',
            prefilledPlanValue: payload?.original
        })
    }

    handleFileUploadRedirect = (value) => {
         localStorage.setItem('show-back-fcf','true')
		window.location.assign('/dashboard/user-file-upload')
	}

    handleASRedirect = (value) => {
        localStorage.setItem('show-back-fcf','true')
       window.location.assign('/dashboard/analytics-studio')
   }

    handleNewRadioChange = (event) => {
        let thisVal = event.target.value
        
        if(thisVal == 11){
            this.handleFileUploadRedirect()
        }

        if(thisVal == 12){
            this.handleASRedirect()
        }

        this.setState({
            newRadioSelectorValue: thisVal,
        }, () => {
           
            if (this.state.newRadioSelectorValue == 8 || this.state.newRadioSelectorValue == 1) {
                this.fetchFCFData()
            }
        })
    };

    fetchFCFData = () => {
        const {newRadioSelectorValue} = this.state
        const payload = {
            dimension: this.state.fcfDimension,
            period: this.state.fcfPeriod,
            isAutomated: newRadioSelectorValue == 8
          }
        this.props.fetchFCFImpactAnalysis(payload)
    }

    fetchMetaData = () => {
        this.props.fetchMetaDataAnalysis()
    }

    fetchCadenceBoard = () => {
        this.props.fetchCadenceBoardData()
    }

    getSaveDataAnalyticStudio = () => {
        // this.props.getSaveData()
    }

    fetchDataAnalyticsStudio = () => {
        const payload = {}
        this.props.fetchDataAnalysis(payload)
    }

    handleSaveDataAnalyticStudio = (data) => {
        this.props.saveData(data);
    }

    handleDeleteSavedDataAnalyticStudio =(data) => {
        this.props.deleteSavedData(data);
    }

    componentDidMount() {
        const openNewTabFlag = window.localStorage.getItem('openNewTabFlag')
        if (openNewTabFlag) {
            this.setState({
                newRadioSelectorValue: openNewTabFlag
            }, () => {
                this.fetchAllData();
                localStorage.removeItem('openNewTabFlag')
            })
        }else {
            this.fetchAllData()
        }

       
    }

    fetchAllData = () => {
        this.props.getAllUser({})
        this.fetchFCFData()
        this.getUserList()
        this.fetchMetaData()
        this.fetchDataAnalyticsStudio()
        this.getSaveDataAnalyticStudio()
        this.fetchCadenceBoard()
    }

    getUserList = async () => {
        this.setState({
            users : await getList()
        })
    }



    handlePerformanceChange = (event) => {
        this.setState({
            performanceSelectorValue: event.target.value,

        })
    };

    handleDataBrowserSelectorValue = (event) => {
        this.setState({
            dataBrowserSelectorValue: event.target.value,

        })
    };

    handleFCFImprovementChange = (event) => {
        this.setState({
            fcfImprovementSelectorValue: event.target.value,

        })
    };

    handleDownloadExcel = () => {
        const {newRadioSelectorValue} = this.state

        const payload = {
          dimension: this.state.fcfDimension ,
          period: this.state.fcfPeriod,
          kpi : this.state.fcfKPI,
          kpi_type : this.state.fcfKPIType,
          kpi_category : this.state.fcfKPICategory,
          yearmonth : this.state.yearMonth,
          quarter : this.state.quarter,
          year_label : this.state.yearLabel,
          isAutomated : newRadioSelectorValue == 8,
          export : true,
        }
        this.props.fetchFCFImpactAnalysis(payload)
    }

    setFCFParams = (payload) => {
        this.setState({
            fcfDimension: payload.dimension ,
            fcfPeriod: payload.period,
            fcfKPICategory: payload.kpi_category,
            fcfKPIType: payload.kpi_type,
            fcfKPI: payload.kpi,
            yearMonth : payload.yearMonth,
            quarter : payload.quarter,
            yearLabel : payload.yearLabel,

        })
    }

    render() {

        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <div className='buplComponent free-cash-flow'>
            
            <ImprovementAnalysis menuData={menuData} />
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            authReducer: state.authReducer
        }
    },
    dispatch => {
        return {
            fetchFCFImpactAnalysis(data) {
                dispatch({
                    type: 'FETCH_FCF_IMPACT_ANALYSIS',
                    payload: data,
                });
            },
            fetchMetaDataAnalysis(data) {
                dispatch({
                    type: 'FETCH_META_DATA_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            fetchDataAnalysis(data) {
                dispatch({
                    type: 'FETCH_DATA_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            fetchCadenceBoardData(data){
                dispatch({
                    type: 'FETCH_CADENCE_BOARD_DATA',
                    payload: data,
                });
            },
            getAllUser(data) {
                dispatch({
                    type: "FETCH_ALL_USER",
                    payload: data,
                });
            },
            
            getSaveData(data) {
                dispatch({
                    type: 'GET_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            saveData(data) {
                dispatch({
                    type: 'SAVE_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            shareChart(data) {
                dispatch({
                    type: 'SHARE_CHART_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            deleteSavedData(data) {
                dispatch({
                    type: 'DELETE_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
        };
    }
)(NewComponent))
    ;
