import React from 'react';
import { connect } from "react-redux";
import Sidebar from './Sidebar/Sidebar'
import Appbar from './Appbar/Appbar';
import { Switch, Route } from "react-router-dom";
import Panel from "./Landing";
import NewPanel from "./Landing/LandingNew.jsx";
import { getAccessByMenuKey } from "../../utils/userManagement";
// import CircularProgress from '@material-ui/core/CircularProgress';
import GeneratePasswordComponent from './GeneratePassword'
import SidebarNew from './Sidebar/SidebarNew.jsx';

export class DashboardComponent extends React.Component {

    constructor(props) {
        super(props);
       
        
        this.state = {
            showLoader: true,
        }
    }

    componentDidMount() {
        this.props.getUserDetail();
        this.props.fetchReportDate();
    }

    handleLogoutClick = () => {
        this.props.performLogout()
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { authReducer } = this.props
        const menus = authReducer.menuData
        return (
            <main className='app'>
                {/* {this.state.showLoader &&
                <div className='progressfullpage'>
                    <CircularProgress />
                    <p>Please wait as we load the portal for you</p>
                </div>} */}
               {!authReducer.isEmbedIframe && <Appbar authReducer={this.props.authReducer} handleLogoutClick={this.handleLogoutClick} menuData={menus}></Appbar> }
                <Switch>
                    <Route path="/dashboard/panel" exact>
                        <Panel />
                    </Route>
                    <Route path="/dashboard/sandbox/panel" exact>
                        <NewPanel />
                    </Route>
                    <Route path="/dashboard/generate-password" exact>
                        <GeneratePasswordComponent />
                    </Route>
                    <Route path="/dashboard/sandbox">
                        <SidebarNew menuData={menus} userManagementNavigation={this.state.userManagementNavigation} />
                    </Route>
                    <Route path="/dashboard">
                        <Sidebar menuData={menus} userManagementNavigation={this.state.userManagementNavigation} />
                    </Route>
                    {getAccessByMenuKey(menus, "user_management", "view") &&
                        <Route path="/usermanagement/panel"  exact>
                            <Panel userManagementPanel={true} />
                        </Route>
                    }
                    {getAccessByMenuKey(menus, "user_management", "view") &&
                        <Route path="/usermanagement">
                            <Sidebar menuData={menus} userManagementNavigation={this.state.userManagementNavigation} />
                        </Route>
                    }
                </Switch>
            </main>
        );
    }
}

export default
    connect(
        state => {
            return {
                authReducer: state.authReducer
            }
        },
        dispatch => {
            return {
                performLogout() {
                    dispatch({
                        type: 'PERFORM_LOGOUT'
                    });
                },
                getUserDetail() {
                    dispatch({
                        type: 'GET_USER_DETAILS'
                    });
                },
                fetchReportDate() {
                    dispatch({
                        type: 'FETCH_AGING_REPORT_DATE'
                    });
                },
            };
        }
    )(DashboardComponent);
