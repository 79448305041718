import React from 'react';
import { connect } from "react-redux";

export class HFMComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {

                ageingData: state.dashboardState.receivableData.ageingData
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(HFMComponent);