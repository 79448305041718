import downloadExcelIcon from '../src/images/download_excel.svg';
import downloadPdfIcon from '../src/images/download-pdf-icon.png';

const CHART_COLOR_PALETTE = [
    '#4472C4', 
    '#F3B700', 
    '#ED7D31', 
    '#5B9BD5', 
    '#A5A5A5', 
    '#70AD47', 
    '#D14343'
];

export const CHART_COLORS = {
    gapToTarget: {
        bu: CHART_COLOR_PALETTE[1],
        loc: CHART_COLOR_PALETTE[0] 
    },
    improvementAnalysis: {
        cadenceAnalysis: {
            b1Color: CHART_COLOR_PALETTE[0],
            lob1Color: CHART_COLOR_PALETTE[1],
            plant1Color: CHART_COLOR_PALETTE[2],
            projectColor: CHART_COLOR_PALETTE[3]
        },
        improveCostAndResources: {
            costInventoryReceivablesColor: '#000033',
            improvementPlanOneColor: '#ff6600',
            improvementPlanTwoColor: '#ff6600',
            improvementPlanThreeColor: '#ff6600',
            improvementPlanFourColor: '#ff6600',
            targetPerformanceColor: '#000000'
        },
        improveSalesAndOutputs: {
            costInventoryReceivablesColor: '#000033',
            improvementPlanOneColor: '#ff6600',
            improvementPlanTwoColor: '#ff6600',
            improvementPlanThreeColor: '#ff6600',
            improvementPlanFourColor: '#ff6600',
            targetPerformanceColor: '#000000'
        },
    },
    performanceAnalysis: {
        monthlyPerformance: {
            gapToTargetColor: CHART_COLOR_PALETTE[0],
            fcfEfficiencyColor: CHART_COLOR_PALETTE[1]
        },
        monthlyChange: {
            negativeValueColor: CHART_COLOR_PALETTE[5],
            positiveValueColor: CHART_COLOR_PALETTE[6]
        },
        cumulativePerformance: {
            monthlyWorkingCapitalGapColor: CHART_COLOR_PALETTE[2],
            cumulativeEBIDTAGapColor: CHART_COLOR_PALETTE[0],
            fcfEfficiencyColor: CHART_COLOR_PALETTE[1]
        },
        compareAllBenchmarks: {
            varianceOrGapColor: CHART_COLOR_PALETTE[0],
            actualsColor: CHART_COLOR_PALETTE[2]
        }
    },
    overdueAr: {
        chartView: {
            overDueColor1: CHART_COLOR_PALETTE[6],
            overDueColor2: CHART_COLOR_PALETTE[1],
        },
        monthlyOverdueTrend: {
            worsenedColor: CHART_COLOR_PALETTE[6],
            improvedColor: CHART_COLOR_PALETTE[1]
        } 
    },
    riskManagement: {
        chartView: {
            technicalColor: CHART_COLOR_PALETTE[0],
            commercialColor: CHART_COLOR_PALETTE[5],
            economicColor: CHART_COLOR_PALETTE[6],
            politicalColor: CHART_COLOR_PALETTE[3],
            hseColor: CHART_COLOR_PALETTE[4]
        }
    },
    opportunityManagement: {
        chartView: {
            customerOneColor: CHART_COLOR_PALETTE[0],
            customerTwoColor: CHART_COLOR_PALETTE[5],
            customerThreeColor: CHART_COLOR_PALETTE[6],
            customerFourColor: CHART_COLOR_PALETTE[3],
            customerFiveColor: CHART_COLOR_PALETTE[4]
        }
    },
    analyticsModule: {
        growthAnalysis: {
            newOrdersColor: CHART_COLOR_PALETTE[0],
            revenueColor: CHART_COLOR_PALETTE[1]
        },
        marginAnalysis: {
            marginPercentageOfRevenueColor: CHART_COLOR_PALETTE[0],
            revenueColor:  CHART_COLOR_PALETTE[1]
        },
        economicsOfScale: {
            otherCOSPercentageOfRevenueColor: CHART_COLOR_PALETTE[0],
            revenueColor:  CHART_COLOR_PALETTE[1]
        },
        customerChangeAnalysis: {
            Q1FY21Color: CHART_COLOR_PALETTE[0],
            customersWithHigherRevenueColor: CHART_COLOR_PALETTE[5],
            customersWithLowerRevenueColor: CHART_COLOR_PALETTE[6],
            newCustomersColor: CHART_COLOR_PALETTE[5],
            discontinuedCustomersColor: CHART_COLOR_PALETTE[6],
            Q2FY21Color: CHART_COLOR_PALETTE[0]
        },
    }
}

export const APP_ICONS = {
    downloadExcelIcon,
    downloadPdfIcon
}