import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NewPagination from "../../../common/components/CustomPagination";
import { handleTableTransform } from '../../../../utils/numberUtils'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { format } from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import Typography from '@material-ui/core/Typography';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import Tooltip from '@material-ui/core/Tooltip';

export default function SimpleModal(props) {
    let tableData = [];
    let drilldownRowData = [];
    if(props.drilldownRowData) {
        drilldownRowData = props.drilldownRowData;
    }
    const handleClose = () => {
        props.modalCallBack(false);
    };

    const changeKeysOfHeader = (data, type) => {
        if (data && data.length) {
            tableData = data.map((item, i) => {

                return {
                    Header: <div className='flex ai-center jc-center'>
                        <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                        {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                        {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                    </div>,
                    id: item.key,
                    Cell: (props) => {
                        return (

                            <div className='text-center'>
                                {item.key == "viewnetduedate" ? format(parseJSON(props.original["viewnetduedate"]), 'dd/MM/yy') : handleTableTransform(props.original, item)
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: "break-spaces",
                        width: '50px !important',
                        overflow: "visible",
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }
                }
            })

        }
        return []
    }

    changeKeysOfHeader(props.drilldownRowHeader);

    const handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            console.log(colInfo, 'pppppp')
            props.handleInvoiceSorting(colInfo.id)
        }
    }

    const columnHeaderClick = (state, someData, colInfo) => {
        return {
            onClick: (e) => {
                handleColumnHeaderClick(colInfo)
            },
            style: { cursor: 'pointer' }
        }
    }

    const body = (
        <div className='customModal'>
            <div className='header'>
                {props.showDrilldownHeaderValue ? <h2>{` ${props.showDrilldownHeaderValue}`}</h2> : <h2></h2>}
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content'>
                <div className='text-right'>
                    <Tooltip title={'Download to Excel'}>
                        <img
                            src={DownloadExcel}
                            className='pointer'
                            height='30'
                            onClick={(event) => {
                                event.preventDefault();
                                props.handleExcelDownload();
                            }} />
                    </Tooltip>
                </div>
               {
                <ReactTable
                    columns={tableData}
                    data={drilldownRowData}
                    defaultPageSize={50}
                    pageSize={Math.min(20, drilldownRowData.length)}
                    className="xpna-react-table -striped -highlight"
                    PaginationComponent={NewPagination}
                    sortable={false}
                    // getTheadThProps={columnHeaderClick}
                /> 
                }
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.showModal}>
                    {body}
                </Fade>
            </Modal>
        </div>
    );
}
