import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import HFMComponent from './HFM'
import DataValidationComponent from './DataValidation'
import { Switch, Route } from "react-router-dom";
import './DataBrowser.scss';
import { getAccessByMenuKey } from "../../../utils/userManagement";

export class DataBrowserComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        const { authReducer } = this.props
        return (
            <Switch>
                { getAccessByMenuKey(authReducer.menuData,"data_validation","view") &&
                <Route path={`/dashboard/receivables/data-browser/validation`} exact>
                    <DataValidationComponent />
                </Route>
                }
                { getAccessByMenuKey(authReducer.menuData,"hfm_data","view") &&
                <Route path={`/dashboard/receivables/data-browser/hfm`} exact>
                    {/* <HFMComponent /> */}
                    {'The functionality is in progress. It will be enabled soon'}
                </Route>
                }
            </Switch>
        );
    }
}

export default
    connect(
        state => {

            return {
                authReducer: state.authReducer,
                ageingData: state.dashboardState.receivableData.ageingData
            }
        },
        dispatch => {
            return {
                getMSMDetail() {
                    dispatch({
                        type: 'FETCH_RECEIVABLE_AGEING_EXTERNAL_DATA'
                    });
                }
            };
        }
    )(DataBrowserComponent);