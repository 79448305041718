import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Switch, Route } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import MapIcon from '@material-ui/icons/Map';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function Sidebar(props) {
  return (
    <Drawer
      className="drawer-sidebar-filter"
      variant="permanent"
      anchor="left"
    >
      <div className="parent-toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <div className="title">Datasources</div>
      </div>
      <Divider />
    </Drawer>
  );
}
