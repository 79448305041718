import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from 'react';
import './map-style.css';
import './mapbox-gl.css';


mapboxgl.accessToken =
	'pk.eyJ1Ijoic2Vuc2lhLWVhY2lpdCIsImEiOiJja3RqbWIzNDExZGIwMnJqeWpldzh2Z3hxIn0.SZjYVrM2Ng5UTj-Y1zQ-aA';

const MapBoxContainer = (props) => {
	const mapContainerRef = useRef(null);
	const [map, setMap] = useState(null);

	const [lng, setLng] = useState(48.054700386711346);
	const [lat, setLat] = useState(29.111525776809206);
	const [zoom, setZoom] = useState(9);
	const defaultMarkerOilCompany = [
		{lat: 28.987871286679532, lng: 47.88578560776086},
		{lat: 29.02150076727564,  lng: 47.95994331559278},
		{lat: 29.019099024476635, lng:  47.97504951533632},
		{lat: 29.01669722582627,  lng: 48.15220403960146},
		{lat: 29.074324972551025, lng:  48.146710876058364},
		{lat: 29.111525776809206, lng:  48.054700386711346}
	]

	const markerOilGasCompany = [
		{lat: 29.32726936504198, lng: 47.94798998422306},
		{lat: 29.398808334023276,  lng: 48.00195838014139},
		{lat: 29.289151870447398, lng:  47.958528425977256},
		{lat: 29.012531271693604,  lng: 47.96676514613653},
		{lat: 29.087154793388592, lng:  48.08357672147426},
		{lat: 29.080375647104844, lng:  48.134866778778104}
	]
	 
	useEffect(() => {

		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [lng, lat],
			zoom: 9
		});

		defaultMarkerOilCompany.forEach((data) => {
			new mapboxgl.Marker({ color: 'red'})
			.setLngLat([data.lng, data.lat])
			.addTo(map);
		})

		

		markerOilGasCompany.forEach((oilgas,index) => {
			const el = document.createElement('div');
			el.className = 'oil-gas-marker';

			let marker = new mapboxgl.Marker({ element: el})
			.setLngLat([oilgas.lng, oilgas.lat])
			.addTo(map);

			var popup = new mapboxgl.Popup().setText(
				'Click to View ESP Details'
			);

			const element = marker.getElement();
			element.id = 'marker'+index

			element.addEventListener('mouseenter', () => popup.addTo(map));
			element.addEventListener('mouseleave', () => popup.remove());
		})


	})

	return (
		<div>
			<div ref={mapContainerRef} className="map-container" />
		</div>
	)
}

export default MapBoxContainer;
