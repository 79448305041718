import { CONFIG } from '../config'

export let startWS = (channel, customerId, code, project, existingArr, callbackFn, handleConnectionClose, invoice_no = '', handleSocketError) => {
     let socket;
     socket = new WebSocket(`${CONFIG.chat_protocol}${CONFIG.chat_base_url}/channel/${channel}/chat`);
     // socket = new WebSocket(`ws://localhost:9092/channel/${channel}/chat`);
     socket.onclose = function () {
          handleConnectionClose(channel)
          console.log("Connection has been closed.");
     }
     socket.onmessage = function (e) {
          var msg = JSON.parse(e.data);

          if (existingArr && Array.isArray(existingArr)) {
               existingArr.push(msg);
          }

          let obj = {
               customerId,
               company_code: code,
               project_id: project,
               chatData: [msg],
               invoice_no
          }
          console.log(obj, "callbackObj")
          callbackFn(obj);
     }

     socket.onerror = function (e){
          handleSocketError(e)

     }
     console.log(socket, "callbackObj")
     return socket
}


export const isSocketConnectionOpen = (socket) => {
     if (!socket || socket.readyState == WebSocket.CLOSED ||
          socket.readyState == WebSocket.CONNECTING ||
          socket.readyState == WebSocket.CLOSING
     ) {
          return false
     }

     return true
}