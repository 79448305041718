import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Map from './map.jsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CommentBox from './comment';
import PlanModal from './plan';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SmsIcon from '@material-ui/icons/Sms';

// import FailureMechanism from './failure.jsx';
// import PreventiveMeasure from './preventive.jsx';
// import RCATable from './table.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openPlanModal, setOpenPlanModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPlanModal = () => setOpenPlanModal(true);
  const handleClosePlanModal = () => setOpenPlanModal(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dashboard-content">
      <Grid container>
          <Grid item xs={8}>
              <AppBar position="static" color="default" className="custom-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="T-360" {...a11yProps(0)} />
                  <Tab label="T-90" {...a11yProps(1)} />
                  <Tab label="T-30" {...a11yProps(2)} />
                  <Tab label="T-1" {...a11yProps(3)} />
                  <Tab label="T+1" {...a11yProps(4)} />
                  <Tab label="T+30" {...a11yProps(5)} />
                </Tabs>
              </AppBar>
          </Grid>
          <Grid item xs={4} className="flex jc-flex-end ai-center">
            <Button variant="contained" color="secondary" className="mr-20 btn-custom" size="small" onClick={()=>{handleOpen()}}><SmsIcon/></Button>
            <Button variant="contained" color="secondary" className="mr-20 btn-custom" size="small" onClick={()=>{handleOpenPlanModal()}}>Create Initiatives</Button>
            
          </Grid>
      </Grid>
      <Grid container>
          <Grid item xs={12}>
              <TabPanel value={value} index={0} className="tab-panel">
                <Map/>
              </TabPanel>
          </Grid>
      </Grid>
      <Modal
            className="collaborate-modal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <CommentBox handleClose={handleClose}/>
            </Fade>
        </Modal>
        <PlanModal open={openPlanModal} handleClose={handleClosePlanModal}/>
    </div>
  );
}
