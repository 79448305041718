import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Switch, Route } from "react-router-dom";
import TextField from '@material-ui/core/TextField';

export default function Sidebar(props) {
  return (
    <Drawer
      className="drawer-sidebar"
      variant="permanent"
      anchor="left"
    >
      <div className="parent-toolbar active mt-65">
        <div className="title">RCA and Remedial Act</div>
      </div>
      <div className="filter-sidebar">
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <TextField id="outlined-basic" label="Enter ESP ID" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">GOR (Gasoil ratio)</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Pump intake temperature</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Pump intake pressure</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Pump discharge pressure</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Fluid viscosity</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Vibration x</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Water cut</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <FormControl variant="outlined" fullWidth size="small" className="form-filter">
          <FormLabel component="legend">Flow rate</FormLabel>
          <TextField id="outlined-basic" label="Enter" variant="outlined" size="small"/>
        </FormControl>
        <div className="btn-action flex jc-center ai-center mt-20">
          <Button variant="contained" size="small" color="primary" className="mr-20 btn-apply">
            Apply
          </Button>
          <Button variant="outlined" size="small" color="primary" className="btn-clear">
            Clear
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
