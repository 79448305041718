import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from "@material-ui/core/InputLabel";
import { DropzoneArea } from 'material-ui-dropzone'
import DownloadExcel from "../../../../images/download_excel.svg";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Autocomplete } from '@mui/material';

export default function SimpleModal(props) {
    const [uploadType, setUploadType] = useState([
        {
            "value": "all",
            "label": "P&L",
        }
    ])
    const [uploadTypeValue, setUploadTypeValue] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [uploadActual, setUploadActual] = useState([
        {
            "value": "10act",
            "label": "10 Act"
        },{
            "value": "20act",
            "label": "20 Act"
        },{
            "value": "30act",
            "label": "30 Act"
        }
    ])
    const [uploadActualValue, setUploadActualValue] = useState('')
    
    React.useEffect(() => {
        handleResetPopUp();
        // if (props.showModal) {
        //     // loadUploadType();
        // }
    }, [props.showModal]);

    React.useEffect(()=>{
        console.log ('UploadTypechanged',uploadTypeValue )
    }, [uploadTypeValue])


    // const loadUploadType = () => {
    //     let upType = [
    //     {
    //         "value": "all",
    //         "label": "P&L"
    //     },
    //     // {
    //     //     "value": "read_clinker",
    //     //     "label": "Clinker Cost Format"
    //     // },
    //     // {
    //     //     "value": "cementcost",
    //     //     "label": "Cement Cost Format"
    //     // },
    //     // {
    //     //     "value": "sales_and_logistic",
    //     //     "label": "Sales & Logistics Format"
    //     // },
    //     // {
    //     //     "value": "fixed_cost",
    //     //     "label": "Fixed cost"
    //     // }
    //     ]
    //     setUploadType(upType)
    // }

    const handleClose = () => {
        props.modalCallBack();
    }

    const handleResetPopUp = () => {
        setUploadTypeValue("");
        setUploadActualValue("");
        setBtnDisabled(true);
    }

    const handleSaverole = () => {
        var data = {}
        data.file = files[0];
        data.upload_type = uploadTypeValue
        props.saveCallback(data);
    }

    const [files, setFiles] = useState(true)
    const handleChangeFile = (file) => {
        setFiles(file);
    }
    React.useEffect(() => {
        if (
            files.length > 0 &&
            uploadTypeValue != ""
        )
            setBtnDisabled(false)
        else
            setBtnDisabled(true)
    }, [files])

    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }
        return <img
            {...iconProps}
            src={DownloadExcel}
            className='pointer'
            height='30'
        />
    }

    const selectedOptions = (a, data) => {
        if (a) {return data.filter((v) => a.indexOf(v.value) > -1)}
        else return {label:'', value:''}
        
    };
    const handleTypeChange = (evt, val) =>{
        if (val !== null) 
        {setUploadTypeValue(val.value)}
        else {setUploadTypeValue('')}
    }

    const handleActualChange = (evt, val) =>{
        if (val !== null) 
        {setUploadActualValue(val.value)}
        else {setUploadActualValue('')}
    }

    const body = (
        <div className='customModal automationModal'>
            <div className='header'>
                <h2>Upload EBITDA {props.tabSelectorValue==0 ? 'Budget' : 'Actual'} Data</h2>
                {!props.saveLoading && <Icon onClick={() => { handleClose() }}>close</Icon>}
            </div>
            <div className='modal-content'>
                {props.saveLoading ? <div className='text-center'><CircularProgress /></div> : ""}
                <React.Fragment >
                    <Grid container spacing={1}>
                    <Grid item xs={12} >
                        {/* <FormControl className='mt-10 mb-10 w-100 maxw-200'>
                            <InputLabel id="demo-simple-select-label">Please select Type of Upload</InputLabel>
                            <Select
                                value={uploadTypeValue}
                            >
                                <MenuItem>Select Type</MenuItem>
                                {uploadType && uploadType.length && uploadType.map((item, i) => {
                                    return <MenuItem value={item.value} key={item.value} onClick={() => setUploadTypeValue(item.value)}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
                        {/* Autocomplete */}
                        <Autocomplete
                            disablePortal
                            className='xpna-dropdown single mt-10 mb-10 w-100 maxw-200'
                            options={uploadType}
                            size='small'
                            value={uploadType && uploadType.length ? selectedOptions(uploadTypeValue,uploadType)[0]: {label:'', value:''}}
                            getOptionLabel={(option)=>option.label}
                            onChange={(evt, val)=>handleTypeChange(evt, val)}
                            renderInput={(params)=>{
                               return <TextField {...params} variant='standard' label='Type' placeholder='Select'/>
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        {props.tabSelectorValue==1 &&
                            // <FormControl className='mt-10 mb-10 w-100 maxw-200'>
                            //     <InputLabel id="demo-simple-select-label">Please select The Actual</InputLabel>
                            //     <Select value={uploadActualValue} >
                            //         <MenuItem>Select Actual</MenuItem>
                            //         {uploadActual && uploadActual.length && uploadActual.map((item, i) => {
                            //             return <MenuItem value={item.value} key={item.value} onClick={() => setUploadActualValue(item.value)}>{item.label}</MenuItem>
                            //         })}
                            //     </Select>
                            // </FormControl>
                            //Autocomplete
                            <Autocomplete
                            disablePortal
                            className='xpna-dropdown single mt-10 mb-10 w-100 maxw-200'
                            options={uploadActual}
                            size='small'
                            value={uploadActual && uploadActual.length ? selectedOptions(uploadActualValue,uploadActual)[0]: {label:'', value:''}}
                            getOptionLabel={(option)=>option.label}
                            onChange={(evt, val)=>handleActualChange(evt, val)}
                            renderInput={(params)=>{
                               return <TextField {...params} variant='standard' label='Actual' placeholder='Select'/>
                            }}
                            />  
                        }
                        
                    </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ pointerEvents: props.saveLoading ? 'none' : '' }} >
                        <DropzoneArea
                            filesLimit={1}
                            getPreviewIcon={handlePreviewIcon}
                            onChange={handleChangeFile.bind(this)}
                            acceptedFiles={['.xls', '.xlsx', '.xlsm']}
                            showFileNames={true}
                            showAlerts={false}
                            maxFileSize={500000000000}
                            dropzoneClass={'payment-dropzone'}
                        />
                    </Grid>
                    {!props.saveLoading &&
                        <Grid container justifyContent="flex-end" className='mt-30'>
                            <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        </Grid>
                    }
                </React.Fragment>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            // modalOptions={{ dismissible: false }}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}

        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}