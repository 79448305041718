import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting"
import highchartsOfflineExporting from "highcharts/modules/offline-exporting"

highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);

function NegativeBarGraph(props) {
    const { graphData, doExport, fileName } = props
    const graphRef = useRef(null);
    useEffect(() => {
        if(doExport) {
            graphRef.current.chart.exportChartLocal({
                type: "application/pdf",
                filename: fileName
            }, {
                title: {
                    text: "Budget Analysis"
                }
            })
            props.handlePrintCallback()
        }
    }, [doExport]);
    const varianceData = graphData.map(res => {
        let color = ''
        if(res.pct < 0) {
            color = '#d00909'
        } else {
            color = '#4caf50'
        }
       
        return {
            y: parseInt(res.pct),
            name: res.data_field,
            color: color,
            level: res.level,
            dataLabels: {
                className:   res.pct == 0 ? '' : res.pct < 0 ?  'right-chart-label' : 'left-chart-label'
            }
        }
    });
    const minValue = -12
    const maxValue = 12
   
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                ref={graphRef}
                options={
                    {
                        chart: {
                            type: 'bar',
                            height: 1900,
                            numberFormatter: props.transformData,
                            spacing: [20, 30, 20, 20]
                        },
                        title: {
                            text: "",
                        },
                        xAxis: {
                            // type : 'category',
                            // alignTicks: true,
                            // min: minValue,
                            title: {
                                text: 'Above Budget',
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                enabled: false
                            }
                        },
                        yAxis: {
                            min: minValue,
                            max : maxValue,
                            opposite : true,
                            tickInterval : 2,
                            title: {
                                text: 'Below Budget',
                                align: 'high',
                                rotation: 90,
                                offset: -225,
                                style: {
                                    color: '#7b7b7b',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontFamily: 'SCProsperSans'
                                }
                            },
                            labels: {
                                enabled: true,
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    inside : true,
                                    crop: false,
                                    overflow: 'allow',
                                    color: '#7b7b7b',
                                    useHTML: true
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>Details</b><br/>',
                            pointFormat: '{point.name}: {point.y}'
                        },
                        series: [{
                            data: varianceData,
                            // borderRadius: 5,
                            pointWidth: 10,
                            dataLabels: [
                                {
                                    align: 'center',
                                    formatter: function() {
                                        let label = this.point.name;
                                        const level = this.point.level;
                                        let fw = "normal"
                                        let fs = "11px"
                                        if(level == 0){
                                            fw = "bold"
                                            fs = "12px"
                                        }
    
                                        if(this.y == 0)
                                        return `<span style="font-size: ${fs};font-weight: ${fw}">${label}</span>`;
                                        else
                                        return ''
    
                                      }
                                },
                                {
                                align: 'left',
                                formatter: function() {
                                    let label = this.point.name;
                                    const level = this.point.level;
                                    let fw = "normal"
                                    let fs = "11px"
                                    if(level == 0){
                                        fw = "bold"
                                        fs = "12px"
                                    }


                                    if(this.y < 0)
                                    return `<span style="font-size: ${fs};font-weight: ${fw}">${label}</span>`;
                                    else
                                    return ''

                                  }
                            },
                            {
                                align: 'right',
                                formatter: function() {
                                    let label = this.point.name;
                                    const level = this.point.level;
                                    let fw = "normal"
                                    let fs = "11px"
                                    if(level == 0){
                                        fw = "bold"
                                        fs = "12px"
                                    }

                                     
                                    if(this.y > 0)
                                    return `<span style="font-size: ${fs};font-weight: ${fw}">${label}</span>`;
                                    else
                                    return ''
                                  }
                            }
                            ]
                        }],
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false,
                            fallbackToExportServer: false,
                        }
                    }
                }
            />
        </div>
    )
}
export default NegativeBarGraph;