import { toastr } from 'react-redux-toastr';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { store } from '../../../index';
import {
	deleteAnalyticStudioService,
	deleteReportAnalyticStudioService,
	fetchDataAnalyticStudioService,
	fetchMetaDataAnalyticStudioService,
	getAnalyticStudioReportService,
	getAnalyticStudioService,
	getAnalyticStudioSingleReportService,
	saveAnalyticStudioReportService,
	saveAnalyticStudioService,
	sendEmailForChartService,
	shareChartService,
} from './service';
import {
	generateReportPayload,
	getSaveDataPayload,
	metaDataMap,
} from './transformer.js';

function* fetchMetaDataAnalyticStudio(data) {
	yield put({ type: 'FETCH_META_DATA_ANALYTIC_STUDIO_LOADER', loading: true });
	const response = yield call(fetchMetaDataAnalyticStudioService, data.payload);
	if (response && response.status == 200) {
		const dataMap = metaDataMap(response.data);
		// yield put({ type: "FETCH_META_DATA_ANALYTIC_STUDIO_SUCCESS", data: response.data });
		yield put({
			type: 'FETCH_META_DATA_ANALYTIC_STUDIO_SUCCESS',
			data: dataMap,
		});
	}
}

function* getAnalyticStudio(data) {
	yield put({ type: 'GET_ANALYTIC_STUDIO_LOADER', loading: true });
	const response = yield call(getAnalyticStudioService, data.payload);
	if (response && response.status == 200) {
		yield put({ type: 'GET_ANALYTIC_STUDIO_SUCCESS', data: response.data });
	}
}

function* getAnalyticStudioReport(data) {
	yield put({ type: 'GET_ANALYTIC_STUDIO_LOADER', loading: true });
	const response = yield call(getAnalyticStudioReportService, data.payload);
	if (response && response.status == 200) {
		yield put({
			type: 'GET_ANALYTIC_STUDIO_SINGLE_REPORT_SUCCESS',
			data: response.data,
		});
	}
}

function* getAnalyticStudioSingleReport(data) {
    // yield delay(1000);

    const response = yield call(
		getAnalyticStudioSingleReportService,
		data.payload
	);
	yield put({ type: 'HANDLE_EDIT_STUDIO_STATUS', status: 'none' });
	if (response && response.status == 200) {
		if (response?.data?.row_data?.[0]?._id === data?.payload) {
			const { _id, name, tabs } = response?.data?.row_data?.[0];
            yield delay(1000);
            yield put({
				type: 'HANDLE_EDIT_STUDIO',
				payload: {
					id: _id,
					name,
					tabs_props: tabs,
				},
			});
		}
	}
}

function* fetchDataAnalyticStudio(data) {
	const { isTranspose, rowArr, columnArr, customFnObj } = data.payload;
	if (data.payload.export != true) {
		yield put({ type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO_LOADER', loading: true });
	}
	let transformedPayload = generateReportPayload(
		rowArr,
		columnArr,
		isTranspose,
		customFnObj
	);
	transformedPayload = {
		...transformedPayload,
		export: String(data?.payload?.export),
	};

	if (
		transformedPayload.row.length == 0 &&
		transformedPayload.column.length == 0
	) {
		yield delay(300);
		yield put({ type: 'RESET_TABLE_DATA' });

		return;
	}

	const response = yield call(
		fetchDataAnalyticStudioService,
		transformedPayload
	);
	if (response && response.status == 200 && data.payload.export != true) {
		yield put({
			type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO_SUCCESS',
			data: response.data,
		});
		yield put({
			type: 'UPDATE_VARIANCE_OPTIONS_ON_FUNCTION',
			data: response.data.additional_filter,
		});
	} else {
		if (data.payload.export != true) {
			let toastrOptions = {
				timeOut: 3000, // by setting to 0 it will prevent the auto close
				position: 'top-center',
			};
	
			toastr.warning(`${response?.data?.error?.debug_msg} `, toastrOptions);
		}
		

		yield put({ type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO_LOADER', loading: false });
	}
}
function* saveAnalyticStudio(data) {
	yield put({ type: 'SAVE_ANALYTIC_STUDIO_LOADER', loading: true });
	const { name } = data.payload;
	const mainState = store.getState();
	const { freeCashFlowState } = mainState;

	let payload = getSaveDataPayload(name, freeCashFlowState.tabs.data);
	const response = yield call(saveAnalyticStudioService, payload);
	if (response && response.status == 200) {
		toastr.success('Data Saved Successfully');
		yield put({ type: 'SAVE_ANALYTIC_STUDIO_LOADER', loading: false });
		yield put({
			type: 'GET_ANALYTIC_STUDIO',
			payload: { metadata_type: payload.module },
		});
	} else {
		toastr.error(response.error);
		// yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
	}
}

function* saveAnalyticStudioReport(data) {
	yield put({ type: 'SAVE_ANALYTIC_STUDIO_LOADER', loading: true });
	const { name } = data.payload;
	//thisone
	const mainState = store.getState();
	const { freeCashFlowState } = mainState;
	let savedId = freeCashFlowState.tabs.id;
	let payload = getSaveDataPayload(
		name,
		freeCashFlowState.tabs.data,
		freeCashFlowState.tabs.id
	);

	const response = yield call(saveAnalyticStudioReportService, payload);
	if (response && response.status == 200) {
		toastr.success(
			savedId ? 'Data Updated Successfully' : 'Data Saved Successfully'
		);
		yield put({ type: 'SAVE_ANALYTIC_STUDIO_SUCCESS', data: response.data });
		// yield put({ type: "GET_ANALYTIC_STUDIO_REPORT", payload : {metadata_type: payload.module}});
	} else {
		toastr.error(response.error);
		// yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
	}
}

function* deleteAnalyticStudio(data) {
	yield put({ type: 'DELETE_ANALYTIC_STUDIO_LOADER', loading: true });

	const response = yield call(deleteAnalyticStudioService, data.payload);
	if (response && response.status == 200) {
		toastr.success('Data Deleted Successfully');
		yield put({ type: 'DELETE_ANALYTIC_STUDIO_SUCCESS', loading: false });
		yield put({ type: 'GET_ANALYTIC_STUDIO', payload: {} });
	} else {
		toastr.error(response.error);
	}
}

function* deleteReportAnalyticStudio(data) {
	yield put({ type: 'DELETE_ANALYTIC_STUDIO_LOADER', loading: true });

	const response = yield call(deleteReportAnalyticStudioService, data.payload);
	if (response && response.status == 200) {
		toastr.success('Data Deleted Successfully');
		yield put({ type: 'DELETE_ANALYTIC_STUDIO_SUCCESS', loading: false });
		yield put({ type: 'GET_ANALYTIC_STUDIO_REPORT', payload: {} });
	} else {
		toastr.error(response.error);
	}
}

function* shareChartAnalyticStudio(data) {
	return;
	yield put({ type: 'SHARE_CHART_LOADER', loading: true });
	let payload = data.payload;
	const response = yield call(shareChartService, data.payload);
	if (response && response.status == 200) {
		let payloadSendEmail = {
			email_to: payload.emailTo,
			email_cc: payload.emailCc,
			attachment: 'data:image/png;base64,' + response.data,
		};

		yield put({ type: 'SEND_EMAIL_FOR_CHART', payload: payloadSendEmail });
	}
}
function* sendEmailForChartAnalyticStudio(data) {
	const response = yield call(sendEmailForChartService, data.payload);
	if (response && response.status == 200) {
		yield put({ type: 'SHARE_CHART_LOADER', loading: false });
		toastr.success('Data Shared Successfully');
	} else {
		toastr.error(response.error);
	}
}

export default function* actionWatcher() {
	yield takeLatest(
		'FETCH_META_DATA_ANALYTIC_STUDIO',
		fetchMetaDataAnalyticStudio
	);
	yield takeLatest('FETCH_NEW_DATA_ANALYTIC_STUDIO', fetchDataAnalyticStudio);
	yield takeLatest('GET_ANALYTIC_STUDIO', getAnalyticStudio);
	yield takeLatest('GET_ANALYTIC_STUDIO_REPORT', getAnalyticStudioReport);
	yield takeLatest(
		'GET_ANALYTIC_STUDIO_SINGLE_REPORT',
		getAnalyticStudioSingleReport
	);
	yield takeLatest('SAVE_ANALYTIC_STUDIO', saveAnalyticStudio);
	yield takeLatest('SAVE_ANALYTIC_STUDIO_REPORT', saveAnalyticStudioReport);
	yield takeLatest('DELETE_ANALYTIC_STUDIO', deleteAnalyticStudio);
	yield takeLatest('DELETE_REPORT_ANALYTIC_STUDIO', deleteReportAnalyticStudio);
	yield takeLatest('SHARE_CHART_ANALYTIC_STUDIO', shareChartAnalyticStudio);
	yield takeLatest('SEND_EMAIL_FOR_CHART', sendEmailForChartAnalyticStudio);
}
