import BarChart from "./chart"
import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import NewSummaryTableComponent from '../TableComponent'
import ReactTable from "react-table-6";
const ReactTableFixedColumns = withFixedColumns(ReactTable);
function ChartContainer(props) {
    console.log(props, "-- data unbilled")
    // var categories = [
    //     '0-4', '5-9', '10-14', '15-19',
    //     '20-24', '25-29', '30-34', '35-39', '40-44',
    //     '45-49', '50-54', '55-59', '60-64', '65-69',
    //     '70-74', '75-79', '80-84', '85-89', '90-94',
    //     '95-99', '100 + '
    // ];

    // var categories = [
    //     {
    //         category: "3835021",
    //         name: "Cost",
    //         y: 36712.23,
    //     },
    //     {
    //         category: "3835021",
    //         name: "Sales",
    //         y: 26380.32,
    //     },

    //     {
    //         category: "3835022",
    //         name: "Cost",
    //         y: 36712.23,
    //     },
    //     {
    //         category: "3835022",
    //         name: "Sales",
    //         y: 26380.32,
    //     },
    // ]

    // var cat = ["3835021", "3835022"]
    // var cost = []
    // var sales =[]

    // categories.map(x =>{
    //     if (x.name== "Cost"){

    //        cost.push(x.y * -1)
    //     }else if (x.name == "Sales"){

    //         sales.push(x.y)
    //     }
    //     // let category = x.category

    //     // if(category == )
    // })
    // var data = props.incomeStatementState.pivotRowDataChart
    // var categories = props.incomeStatementState.additionalData
    console.log(props, "cek Cat")

    var tittle = 'SO With Negative Margins'
   
    return (
        <>
            {props.loading ? <div className='text-center'><CircularProgress /></div> :
                <Grid container spacing={2} >
                      {props.graphView ?
                    <Grid item xs={12} sm={12}>

                        <Card>
                          <CardContent>
                            <BarChart
                                chartData={props.chartData}
                                chartCategories={props.additionalData}
                                handleInvoiceClick={props.handleInvoiceClick}
                                chartTitle={tittle}
                                SeriesLabelEnabled={true}
                                SeriesLabelColor={"black"}>
                            </BarChart>
                          </CardContent>
                        </Card>
                    </Grid>
                    :
                    <>
                    <Grid item xs={12}>
                        {/* <ReactTableFixedColumns
                            data={props.tableData}
                            columns={props.headerTable}
                            defaultPageSize={props.tableData.length > 10 ? 10 : props.tableData.length}
                            className="-striped -highlight"
                            PaginationComponent={NewPagination}
                            pageSizeOptions={[10, 20, 30]}
                            resizable={true}
                            // getTdProps={props.clicked}
                        /> */}
                         <NewSummaryTableComponent
                                 {...props}
                                 NewChart={true}
                                 handlePropsInvoiceClick={props.handleInvoiceClick}
                                 toggleCommentModal={props.toggleCommentModal}
                                />
                    </Grid>
                </>
                }
                </Grid> 
            }

                
               
        </>
    )
}

export default ChartContainer;