import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { handleTableTransform, numberWithCommas, checkNan } from '../../../../utils/numberUtils'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import InvoiceDetailModal from "../../Shared/InvoiceDetail";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PieChartComponent from '../PieCharts';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { fromUnixTime } from 'date-fns/esm';
import ReactTableComponent from './pureTable'
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class FunctionalTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDrilldownHeaderValue = '';
        this.chatDataCollection = {}
        this.sortList = {}
        this.sortParam = null
        this.levelMap = {
            PlTotal: 'P&L Total',
            PlGroup: 'P& L Group',
            GroupName2: 'Group Name2',
            GroupName: 'Group Name',
            GroupName3: 'Group Name3',
            AccountName: 'Account Name',
            CostCenter: 'Cost Centre2'
        }
        this.state = {
            loading: true,
            showModal: false,
            manualAmountValue: '',
            refMenuAnchorEl: null,
            plantDetailState: {},
            showInvoiceDetailModal: false,
            filterSelectorValue: [],
            arrayDataSelected: [],
            showLoader: false,
            manualClickData: {},
            invoiceObject: null,
            isExpandable : false,
            drilldownGroup : '',
            commentModal : false,
            commentValue : '',
            invoiceDetailStateNew : '',
            selectedDrilldown : null,
            lastPayloadSent : null,
            isInvoiceDetailModal: false,
            refreshOnModalClose : false,
            radioOcosByCountry : 'BU 1'
        }
        this.excelData = []
    }

    handleOCOSByCountryRadioButtonChange = (event) => {
        this.setState({ radioOcosByCountry: event.target.value });
        this.props.fetchMainData({ bu : event.target.value , loadSummary : false})
    };

    handleColumnCellCreation = (cell, item) => {
        if (item.key == 'data_field') {
            let { row, cell: { value } } = cell
            if (row.canExpand) {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => { this.handleRowClick(row); row.toggleRowExpanded(); }
                        })}
                    >
                        <span className="unselectable">
                            {row.isExpanded ?
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                                :
                                <IconButton aria-label="search">
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            }
                        </span>{" "}
                        {value}
                    </span>
                )
            } else {
                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            onClick: () => {
                                this.handleRowClick(row);
                                row.toggleRowExpanded();
                            }
                        })}
                    >
                        {value}
                    </span>
                )
            }
        }
        return cell.row.canExpand ? (
            <span style={{ fontWeight: "bold" }}>{cell.value}</span>
        ) : (
                cell.value
            );
    }

    changeKeysOfHeader1 = (data, type) => {
        if (data && data.length) {
            return data.map((item, i) => {
                return {
                    id: item.key == 'data_field' ? "expander" : '',
                    Header: this.handleColumnHeaderCreation(item),
                    accessor: item.key,
                    Cell: (cell) => this.handleColumnCellCreation(cell, item),
                }
            })
        }
        return []
    }

    handleDifferentBUResponse = (data, key) => {
        if (data) {
            if (key == 'data_field' || key == 'grand_total') {
                return data;
            } else {
                return data['header_key_value'] ? data['header_key_value'] : data
            }
        }
        return data
    }

    handleExcelDataChange = (data, header) => {
        this.props.handleExcelDataChange(data, header);
    }

    addSpaceLevel = (data, level) => {
        level = level * 4;
        let res = ""
        for (; level > -1; level--) {
            res += " "
        }
        return res + data;
    }



    handleTooltipData = (props) => {
        let { custom_level, levels } = props.original;
        let tooltipData = ''
        // if (levels && levels.length) {
        //     tooltipData = this.levelMap[levels[custom_level]] ? this.levelMap[levels[custom_level]] : levels[custom_level]
        // }
        return tooltipData;
    }

    handleColumnHeaderTooltip = (item) => {
        let { metadata } = item;
        let tooltipData = (
            <div>
                {item.label}
            </div>
        )
        if (metadata && metadata.plant_name) {
            tooltipData = (
                <div>
                    <Tooltip title={
                        <React.Fragment>
                            <div style={{ textAlign: 'center' }}>Plant Details</div>
                            <div><span>{'Plant Name: '}</span> <span>{metadata && metadata.plant_name}</span></div>
                            <div><span>{'Entity Name: '}</span> <span>{metadata && metadata.legal_entity_name}</span></div>
                        </React.Fragment>
                    }>
                        <span>{item.label}</span>
                    </Tooltip>
                </div>)
        }
        return tooltipData;
    }

    dynamicHeaderValueOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].label : item.label
            return data
        }
        return item.label
    }

    dynamicHeaderKeyOfColumn = (item, index) => {
        if (item && item.sub_headers && item.sub_headers.length) {
            let data = item.sub_headers[index] ? item.sub_headers[index].key : item.key
            return data
        }
        return item.key
    }

    handleChatChangeFn = (event, rowData) => {
        if (event.target && event.target.value) {
            this.chatDataCollection[rowData.data_field] = event.target.value;
        }
    }

    handleChatSendFn = (rowData, groupName) => {
        let sendChatObject = {}
        if (document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value) {
            sendChatObject = {
                comment: document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value,
                commentRowId: rowData.original.data_field && rowData.original.data_field.toLowerCase(),
                view: rowData.original.data_field,
                groupName: groupName,
                reportDate: rowData.original.current_month
            }
            this.props.handleCommentSendFn(sendChatObject)
            this.chatDataCollection[rowData.original.data_field] = ''
            document.getElementById(`outlined-basic-${groupName}-${rowData.original.data_field}`).value = ''
        }
    }

    handleDGSAutomationClick = (rowData, subColumnLevel) => {
       
        let payload = {
            country : this.props.radioSelectorDynanicDGS,
            summary_acct : rowData.summary_acct,
            currency_code : rowData.currency_code,
            company_code : rowData.company_code,
            source : rowData.source,
            sub_tab_value : this.props.radioSelectorValueDGSAutomation,
            grid_header:subColumnLevel.key,
            grid_header_value: subColumnLevel.key
        }
        // alert(this.props.radioSelectorValueDGSAutomation)
        this.props.handlePropsInvoiceClick(payload)
        // console.log(payload, "CheckDrillDown" )
        this.setState({
            isExpandable : false,
            invoiceObject: payload,
            showLoader : true,
        })
    }
    handlePABValidationClick = (rowData, subColumnLevel, columnLevel) => {
        // console.log(rowData,subColumnLevel, columnLevel, "cekPabValidationParsing")
        let payload = {
            data_ra: rowData.data_ra,
            data_company_code:  rowData.data_company_code,
            sub_tab_value : "pab_validation",
            sub_grid_header:subColumnLevel.key,
            sub_grid_header_value: subColumnLevel.key,
            grid_header:columnLevel.key,
            grid_header_value: columnLevel.key
        }

        // // console.log(subColumnLevel,columnLevel, "--iki lo")
        this.setState({
            isExpandable : false,
            invoiceObject: payload,
            showLoader : true,
        })
        let mapping = payload.sub_grid_header_value
        let header = ""
        if (mapping == "revenue_sales_digital_solutions" || mapping == "revenue_margin_digital_solutions") {
			header = "Digital Solutions"
		}
		if (mapping == "revenue_sales_digital_services" || mapping == "revenue_margin_digital_services") {
			header = "Digital Services"
		}
		if (mapping == "revenue_sales_connected_products" || mapping == "revenue_margin_connected_products") {
			header = "Connected Products"
		}

        if (mapping == "intercompany_sales_digital_solutions" || mapping == "intercompany_margin_digital_solutions") {
			header = "Digital Solutions"
		}
		if (mapping == "intercompany_sales_digital_services" || mapping == "intercompany_margin_digital_services") {
			header = "Digital Services"
		}
		if (mapping == "intercompany_sales_connected_products" || mapping == "intercompany_connected_products") {
			header = "Connected Products"
		}

        // this.setState({refreshOnModalClose : true})
        this.showDrilldownHeaderValue = `${columnLevel.label} - ${header}`
        this.props.handlePropsInvoiceClick(payload)
    }
    
    expandIcon = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
          return {};
        }
        return {
          "data-qnt": rowInfo.original.sub_rows ? 1 : 0
        };
    }

    handleOptimusAutoClick = (rowData, subColumnLevel, columnLevel) => {
        let payload = {
            level_values: rowData.data_key,
            sub_tab_value : "optimus_automation",
            sub_grid_header:subColumnLevel.key,
            sub_grid_header_value: subColumnLevel.key,
            grid_header:columnLevel.key,
            grid_header_value: columnLevel.key
        }

        // console.log(subColumnLevel,columnLevel, "--iki lo")
        this.setState({
            isExpandable : false,
            invoiceObject: payload,
            showLoader : true,
        })
        let mapping = payload.sub_grid_header_value
        let header = ""
        if (mapping == "digital_solutions") {
			header = "Digital Solutions"
		}
		if (mapping == "digital_services") {
			header = "Digital Services"
		}
		if (mapping == "connected_products") {
			header = "Connected Products"
		}
		if( mapping == "blank") {
			header = "Blank"
            // this.props.handlePropsGetDropdown()
		}
        this.setState({refreshOnModalClose : true})
        this.showDrilldownHeaderValue = `${columnLevel.label} - ${header} Mapping`
        this.props.handlePropsInvoiceClick(payload)
    }

    getQuery = (query) => {
        let b = query
          .slice(0)
          .split("&")
          .map(qStr => qStr.split("="))
          .reduce((acc, inc) => {
            acc[inc[0]] = inc[1];
            return acc;
          }, {});
          return b;
      }

    handleDrilldownURL = (url) => {
            let splitData = url.split('?')[1]
            let QueriedData = this.getQuery(splitData)
            // let payload = {
            //     selectedMonth: QueriedData.month,
            //     selectedRadio: QueriedData.type,
            //     tabKey: QueriedData.tabKey
            // }
             localStorage.setItem('linkDrilldown', JSON.stringify(QueriedData));
    
            window.open(url)
    }

    handleInvoiceClick = (rowData, subColumnLevel, columnLevel) => {
        //  console.log(rowData, 'ppppppppp', subColumnLevel, columnLevel)
        // console.log("123123")
        let keyss = ""
        let keyBU = ""
        let url = rowData["link_drilldown_" +columnLevel.key]

        if(url != undefined){
            this.handleDrilldownURL(url);
            return
        }
       
        if(subColumnLevel.key == "bu_p&l"){
            keyss= "current"
            if(columnLevel.key == "BU 1"){

                keyBU = "msb"
            }else{
                keyBU = columnLevel.key
            }
        }else{
            keyss = subColumnLevel.key 
            keyBU = columnLevel.key
        }
        let level =rowData.data_field
        let subGridHeader = keyss
        if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 2) {
            level = "Total Variances & Other Cost of Sales"
            subGridHeader = "current"
            keyss= "current"
            if(columnLevel.key == "BU 1"){

                keyBU = "msb"
            }else{
                keyBU = columnLevel.key
            }
            this.showDrilldownHeaderValue = `${level} - ${columnLevel.label} (${subColumnLevel.label}) - USD ${this.modifyColumnData(rowData[columnLevel.key])}`
            if(columnLevel.key == "sub_total"){
                keyss = "grand_total_new"
                keyBU =  "grand_total_new"
            }
        }else if (this.props.NewChart == true){
            this.showDrilldownHeaderValue =""
            console.log(rowData, subColumnLevel, columnLevel, "cekDrilldownTable")
            subGridHeader = rowData.so_item
            level = "Standard Margin"
        }else{
            this.showDrilldownHeaderValue = `${rowData.data_field} - ${columnLevel.label} (${subColumnLevel.label}) - USD ${this.modifyColumnData(rowData.sub_column[columnLevel.key][subColumnLevel.key])}`
        }

        if(this.props.radioSelectorValuePlant == "BU 1" && subColumnLevel.key == "sub_total"){
            if(rowData.data_field == "Total Variances" || rowData.data_field == "Total Other Cost of Sales"){
                level = "Total Variances & Other Cost of Sales,"+ rowData.data_field
            }else{
                level = rowData.parents && rowData.parents.length ? [...rowData.parents, rowData.data_field].join(',') : rowData.data_field;

            }

        }
        let payload = {
            grid_header_value: keyBU,
            grid_header: keyBU,
            sub_grid_header_value: subGridHeader,
            level_values: level
        }
        // console.log(payload, rowData, columnLevel.key, "checkDrilldown")
        let expandable = false
        let drilldownGroupBy = ''
        let col = columnLevel.key.toLowerCase()
        let subCol = subColumnLevel.key.toLowerCase()

        let levelforExpandable = ["Orders","3rd Party Sales","Total Sales","Standard Cost of Sales", "Standard Margin", "Intercompany Sales", "Intercompany Cost of Sales", "Margin", "Margin "]
     
        if(  levelforExpandable.indexOf(payload.level_values) > -1){
            expandable = true
        }
        
        this.setState({
            invoiceObject: payload,
            showLoader : true,
            clickedBU : columnLevel.key,
            drilldownGroup : drilldownGroupBy,
            isExpandable : expandable,
            isInvoiceDetailModal: this.props.radioSelectorValue == 1 || this.props.radioSelectorValue == 3 || this.props.radioSelectorValue == 5
        })
               
        this.props.handlePropsInvoiceClick(payload)
    }

    handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }

    handleSubColumnChangeHeader = (data, parentData) => {
        // console.log(data, "testHere123")
        if (data && data.length) {
            return data.map((item, i) => {
                var isManualKey = false;
                return {
                    Header: //<Tooltip title={this.handleSubHeaderTooltip(item)}>
                    <span onClick={() => {
                        if(item.key != "sub_total" && this.props.radioSelectorValue != 0 && this.props.radioSelectorValue != 2 && this.props.radioArrSelected == "Default"  && this.props.radioArrCountrySelected == "Default"){
                            // console.log(item, "checkSubHeaderClick2")
                               
                                this.setState({
                                    isExpandable : false,
                                }, () => {
                                    this.props.handleSubHeaderClick(item, parentData)

                                    })
                         
                       }
                    }
               }
               
               >
                  
                     {item.label}
                   </span>,//</Tooltip>,
                    id: item.key,
                    Cell: (props) => {
                        let showPercentage = this.handleAppendPercentage(props.original);
                        let myClassName = showPercentage ? 'percentageRowData' : ''
                        let disableDrillDown = props.original["disable_drill_down"]
                        myClassName += disableDrillDown || (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue != 7 ) || item.key == 'New BU 1' ||  item.key == 'sub_total' || item.key == 'total' || item.key == 'grand_total' ? '' : ' enableDrillDown'
                        
                        if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'bu_p&l' && props.original.data_field != "Gross Margin") {
                            isManualKey= true
                            // console.log(props.original, props.original.isManual, "masuk sini")
                        }else if(this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'new' && props.original.data_field != "Gross Margin"){
                            isManualKey= true
                        }else if(this.props.tabSelectorValue == 5 ){
                            // console.log("testHere123")
                            isManualKey= true
                        }else if(this.props.radioSelectorReconValue == 5 ){
                            // console.log("testHere123")
                            isManualKey= true
                        }else if(this.props.radioSelectorReconValue == 6 ){
                            // console.log("testHere123")
                            isManualKey= true
                        }
                        else if(this.props.radioSelectorValuePlant == "BU 1" && item.key == 'sub_total'){
                            console.log("testHere123")
                            myClassName += ' enableDrillDown'
                            isManualKey= true
                        }
                        else{
                            isManualKey = props.original && props.original.isManual;
                        }
                                 
                        let classNames = ""
                        if(isManualKey == true){
                            classNames = 'text-center pointer'
                        }else{
                            classNames = 'text-center'
                        }

                        
                        // console.log(props, classNames, "Testitem")
                        return (
                            <div className={classNames} onClick={(event) => {
                                if (this.props.tabSelectorValue != 3 && item.key != 'New BU 1' && item.key != 'sub_total' && item.key != 'total' && !props.original["disable_drill_down"]) {
                                    // console.log(item, "--item")
                                    this.handleInvoiceClick(props.original, item, parentData)
                                }
                                if(this.props.radioSelectorValuePlant == "BU 1" && item.key == 'sub_total'){
                                    this.handleInvoiceClick(props.original, item, parentData)
                                }
                                if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'bu_p&l' && props.original.data_field != "Gross Margin" ) {
                                    // console.log(props.original, item, parentData, "itemzz")
                                   
                                    this.handleInvoiceClick(props.original, item, parentData,)
                                }
                                if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'new' && props.original.data_field != "Gross Margin" ) {
                                    // console.log(props.original, item, parentData, "itemzz")
                                    this.handleInvoiceClick(props.original, item, parentData,)
                                }
                                if(this.props.radioSelectorReconValue == 5  ){
                                    this.handleOptimusAutoClick(props.original, item, parentData,)
                                }
                                if(this.props.radioSelectorReconValue == 6  ){
                                    this.handlePABValidationClick(props.original, item, parentData,)
                                }
                                if(this.props.radioSelectorReconValue == 7  ){
                                    this.handleInvoiceClick(props.original, item, parentData)
                                }
                                if (props.original.isManual) {
                                    // console.log(item, "itemz")
                                    // console.log(props.original, "--props.original")
                                    this.setState({
                                        showModal: true,
                                        manualAmountValue: props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? props.original.sub_column[parentData.key][item.key] : 0,
                                        manualClickData: {
                                            group: props.original.group,
                                            key: item.key,
                                            data_field: props.original.data_field
                                        }
                                    })
                                }
                            }}>
                                {
                                    <span className={myClassName}>{props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? this.modifyColumnData(props.original.sub_column[parentData.key][item.key], props.original.sub_column[parentData.key]["operations"]) : '-'}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                        cursor: isManualKey && isManualKey == true ? 'pointer' : ''
                        // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }
            })
        }
    }

    toggleCommentModal = (rowData) => {
        let visib = !this.state.commentModal

        this.setState({ commentModal: visib, commentValue: '' , selectedDrilldown: rowData})
    }

    handleCommentChange = (event) => {
        this.setState({ commentValue: event.target.value })
    }

    handleCommentSubmit = () => {
        // console.log(this.state.selectedDrilldown, "--", this.state.commentValue, "-- 0101")
        let {selectedDrilldown} = this.state
        let bu = selectedDrilldown.bu
        let yearmonth =  selectedDrilldown.yearmonth_new.split("T")[0]
        if(bu == "BU 1"){
            bu = "BU 1"
        }
        if (this.state.commentValue) {
            let commentPayload = {
                comment : this.state.commentValue,
                bu : bu,
                report_date : yearmonth,
                so_item : selectedDrilldown.so_item,
                project_id : selectedDrilldown.project_id,
                comment_id : selectedDrilldown.comment_id,
            }
            
            const { invoiceObject } = this.state;
            let drilldownPayload = {
                    ...invoiceObject
            }

            let payload = {
                comment : commentPayload,
                drilldown : drilldownPayload
            }
            // console.log(payload,"-- 010101")
            this.props.postCommentData(payload)
        }
    }

    changeKeysOfHeader = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                console.log(item.key, "cekKeyss")
                item['operation'] = item.key == 'data_field' || item.key == 'data_group' || item.key == 'data_company_code' || item.key == 'data_ra' || item.key == 'so_item'? '' : 7;
                if (item.key == 'data_group') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: item.label,
                            id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_group' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Grand Total" ? 'totalLabel' : ''

                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName } >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                } else if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            // Header: item.label,
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_field' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }                   
                        }]
                    }
                }  else if (item.key == 'so_item') {
                    return {
                        Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
                        id: item.key,
                        metaData: item.metadata,
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            textAlign:  "center"
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }else if (item.key == 'data_company_code') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_company_code' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Total" ? 'totalLabel' : ''
                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName }  >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }  else if (item.key == 'data_ra') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_ra' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }  else if (item.key.indexOf("pct_") > -1) {
                    // console.log(item.sub_headers, item, "test321")
                    let label = item.label
                    // if(item.key == "pct_digital_services"){
                    //     label = "DASS Services" +<br />+ "% of Revenue"
                    // }
                    return {
                        Header: () => {  
                            let spl = item.label.split("%")   
                            if(spl[0] != ""){ 
                            return (
                                <span className={'multi-header'}>
                                    {spl[0] }
                                    <br/>
                                    {" % " + spl[1]}
                                    </span>
                            )
                            }else{
                                return item.label
                            }
                        },
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key] != undefined ? this.modifyColumnDataOCOSVal(props.original[item.key],"percentage") : '-'}</span>
                                    }
                                </div>
                            )
                        },
                    }
                }else if (item.label.indexOf("|") > -1) {
                    // console.log(item.sub_headers, item, "test321")
                    let label = item.label
                    // if(item.key == "pct_digital_services"){
                    //     label = "DASS Services" +<br />+ "% of Revenue"
                    // }
                    return {
                        Header: () => {  
                            let spl = item.label.split("|")   
                            if(spl[0] != ""){ 
                            return (
                                <span className={'multi-header'}>
                                    {spl[0] }
                                    <br/>
                                    {spl[1]}
                                    </span>
                            )
                            }else{
                                return item.label
                            }
                        },
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key] != undefined ? this.modifyColumnData(props.original[item.key],"") : '-'}</span>
                                    }
                                </div>
                            )
                        },
                    }
                }              
                else if (item.key == 'company_code' ||item.key == 'country'||item.key == 'classification' || item.key == 'source'|| item.key == 'summary_acct' || item.key == 'summary_acct_desc'|| item.key == 'currency_code' ) {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                    }
                } else if (item.key == 'grand_total') {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let showPercentage = this.handleAppendPercentage(props.original);
                            let myClassName = showPercentage ? 'percentageRowData' : ''
                            return (
                                <div className='text-center'>
                                    {
                                        <span className={myClassName}>{props.original ? this.modifyColumnData(props.original[item.key], props.original["operations"]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        headerStyle: {
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }
                    }
                }   else if (item.key == 'tran_amount' || item.key == 'base_amount' || item.key == 'base_amount_aop'   || item.key == 'tran_amount_aop') {
                    var isManualKey = true;
                    // console.log(item.sub_headers, item, "test123123")
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                           
                            item['operation'] = 7;
                            if(props.original.summary_acct == "Total"){
                                isManualKey = false 
                            }
                            console.log(props.original.summary_acct, isManualKey, "chek123321")
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => { this.handleDGSAutomationClick(props.original, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:  isManualKey == true ? 'pointer' : ''
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }
                else if (!item.sub_headers) {
                    var isManualKey = false;
                   
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#DEE3EC' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 2 && props.original.disable_drill_down == false) {
                                console.log("masuksinigaes1234")
                                isManualKey= true
                            }
                            if (this.props.NewChart == true){
                                isManualKey= true
                            }
                          
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => {   this.handleInvoiceClick(props.original, item, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:  isManualKey == true ? 'pointer' : ''
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }



                return {
                    Header:  <Tooltip title={this.handleHeaderTooltip()}>
                         <span onClick={() => {
                        if (this.props.tabSelectorValue != 3 && this.props.radioArrSelected == "Default") {

                            if (this.props.radioSelectorValue == 3){
                                this.props.handleRegionHeaderClick(item)

                            }else if(this.props.radioSelectorValue == 5){
                                this.props.handleHemisphereHeaderClick(item)

                            }
                        }

                    }
                    }
                    
                    >
                       
                          {item.label}
                        </span></Tooltip>,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
                    style: {
                        borderRight: '1px solid #423970',
                        cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight:'1px solid #423970'
                    }
                }
            })
        }
        return []
    }

    showCursorPointerForHeader = () => {
        if (this.props.tabSelectorValue != 3 && (this.props.radioSelectorValue == 3 || this.props.radioSelectorValue == 5)){
            return 'pointer'

        }

        return ''
    }

    handleHeaderTooltip = () => {
        if (this.props.tabSelectorValue != 3){

            if(this.props.radioSelectorValue == 3 &&  this.props.radioArrSelected == "Default"){

                return 'Click here for the country view'

            }else if(this.props.radioSelectorValue == 5  && this.props.radioArrSelected == "Default"){
                return 'Click here for the region view'

            }

        }


        return ''
    }
    handleSubHeaderTooltip = (data) => {
      console.log( this.props.radioArrSelected , this.props.radioArrCountySelected, "CEKDefault")
            if(this.props.radioSelectorValue != 0 && this.props.radioSelectorValue != 2 && data.key != "sub_total" &&  this.props.radioArrSelected == "Default"  && this.props.radioArrCountrySelected == "Default"){

                return 'Click here for detail'

            }

      


        return ''
    }
    checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }

    checkLevel = (data, level) => {
        if (data && data.length) {
            data.forEach(item => {
                if (level == undefined) {
                    item.custom_level = 0
                } else {
                    item.custom_level = level + 1
                }
            })
        }
        return data
    }

    subComponent = (row) => {
        console.log(row, "subComponentsubComponentsubComponent")

        let { pivotHeaderData } = this.props.incomeStatementState;
        const { radioSelectorValue } = this.props
        let nestedFlag = this.checkForNestedLevel(row.original.sub_rows)
        console.log(nestedFlag, "checkNested")
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        const subRowsLength = row.original.sub_rows && row.original.sub_rows.length
        let pivotRowData = this.checkLevel(row.original.sub_rows, row.original.custom_level);
        return (
            <>
                {subRowsLength ?
                    <ReactTableFixedColumns
                        data={pivotRowData}
                        className='bupl-SubTable'
                        TheadComponent={props => null}
                        columns={pivotHeaderData}
                        defaultPageSize={subRowsLength}
                        pageSize={subRowsLength}
                        SubComponent={this.subComponent}
                        showPagination={false}
                        getTrProps={this.expandIcon}
                        sortable={false}
                        getTdProps={this.rowClick}
                    /> : null
                }
            </>
        );
    };

    handleColumnHeaderClick = (colInfo) => {
        if (colInfo.id && colInfo.id != "grand_total") {
            this.props.handleRowHeaderClick(colInfo)
        }
    }

    columnHeaderClick = (state, someData, colInfo) => {
        const { showCursor } = this.props;
        if (showCursor) {
            return {
                onClick: (e) => {
                    this.handleColumnHeaderClick(colInfo)
                },
                style: { cursor: 'pointer' }
            }
        }
        return {}
    }

    handleColumnClick = (state, colInfo, columnDetail, etc, event) => {
        // console.log("check123")
        // console.log(state, colInfo, columnDetail, etc, event.target.value)
        const { data_field, parents } = colInfo.original;
        const { id, headerTitle } = columnDetail;
        const { legalEntitySelectorValue, plantSelectorValue } = this.props

        let dataVal = parents && parents.length ? [...parents, data_field].join(',') : data_field;
        if (headerTitle) {
            this.setState({
                showLoader: true,
                invoiceObject: { grid_header: id == 'grand_total' ? '' : id, level_values: dataVal }
            })
            this.props.getInvoiceDetail({ legal_entity: legalEntitySelectorValue, plant: plantSelectorValue, grid_header: id == 'grand_total' ? '' : id, level_values: dataVal });
            this.showDrilldownHeaderValue = `${data_field} (${headerTitle}) - USD ${checkNan(colInfo.row[columnDetail.id])}`
        }
    }

    rowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                // this.handleColumnClick(state, rowInfo, column, instance, e)
                if (column.expander) {
                    let { pivotRowData, pivotHeaderData, loading } = this.props.incomeStatementState;
                    let nestedFlag = this.checkForNestedLevel(pivotRowData)
                    pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
                    this.addExcelData && this.addExcelData(rowInfo.original, pivotHeaderData);
                    // console.log(rowInfo,"cek")
                    handleOriginal()
                }
            }
        }
    }

    modifyColumnData = (columnData, operations) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (operations == "percentage") {
            modifiedData += "%"
        }

        return modifiedData
    }
    modifyColumnDataOCOSVal = (columnData, operations) => {
        let modifiedData;
        // console.log(columnData, "checkPCT")
        modifiedData = columnData.toFixed(1)
        // modifiedData = modifiedData.toFixed(1)
        // var isNegative = modifiedData < 0
        // var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        // modifiedData = numberWithCommas(positiveTransformedValue)
        // modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = modifiedData == '0.0' ? '-' : modifiedData
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData

        if (operations == "percentage" && modifiedData != "-") {
            modifiedData += "%"
        }

        return modifiedData
    }


    componentDidUpdate(prevProps) {

        if (prevProps.incomeStatementState.invoiceLoading && !this.props.incomeStatementState.invoiceLoading) {

            this.setState({
                invoiceDetailStateNew : this.props.incomeStatementState.invoiceDetailState,
                showInvoiceDetailModal: true,
                showLoader: false
            })
      
           
        }
        if (prevProps.incomeStatementState.chartModalLoading && !this.props.incomeStatementState.chartModalLoading) {
         
               
            this.setState({

                showLoader: false
            })
     
      
       
    }
        if (prevProps.incomeStatementState.NewCommentLoader && !this.props.incomeStatementState.NewCommentLoader) {
            const { invoiceObject } = this.state;
            let payload = { ...invoiceObject }
            payload["sort"] = this.sortParam
            this.props.handlePropsInvoiceClick(payload);
            this.toggleCommentModal()
        }

        if (prevProps.incomeStatementState.NewManualLoader && !this.props.incomeStatementState.NewManualLoader) {
            this.setState({
                showModal: false,
              
            })
        }
    }

    componentDidMount() {
    }

    invoiceModalCallBack = () => {
        if(this.state.refreshOnModalClose){
            this.props.fetchMainData()
        }

        this.setState({ arrayDataSelected : [], showInvoiceDetailModal: false, isInvoiceDetailModal: false, refreshOnModalClose : false });
    }
    handleCountryChanges = (data) => {
        let dataGrid = this.state.invoiceDetailStateNew;
        let arrayData = this.state.arrayDataSelected;
        dataGrid.row_data[data.index]["country_name_new"] = data.country_new

        let sameID = false
        arrayData.map((item, i) => {
            if(item["_id"] == dataGrid.row_data[data.index]["_id"]){
                sameID = true
            }
        })
        if (sameID == true){
            arrayData.map((item, i) => {
                if(item["_id"] == dataGrid.row_data[data.index]["_id"]){
                   return dataGrid.row_data[data.index]
                }else{
                    return item
                }
            })
        }else{
            arrayData.push( dataGrid.row_data[data.index])
        }
        
        this.setState({ invoiceDetailStateNew: dataGrid, arrayDataSelected :  arrayData});
       
    }
    handleClassificationChanges = (data) => {
        let dataGrid = this.state.invoiceDetailStateNew;
        let arrayData = this.state.arrayDataSelected;
        dataGrid.row_data[data.index]["final_mapping_new"] = data.final_mapping_new

        let sameID = false
        arrayData.map((item, i) => {
            if(item["_id"] == dataGrid.row_data[data.index]["_id"]){
                sameID = true
            }
        })
        if (sameID == true){
            arrayData.map((item, i) => {
                if(item["_id"] == dataGrid.row_data[data.index]["_id"]){
                   return dataGrid.row_data[data.index]
                }else{
                    return item
                }
            })
        }else{
            arrayData.push( dataGrid.row_data[data.index])
        }
        
        this.setState({ invoiceDetailStateNew: dataGrid, arrayDataSelected :  arrayData});
       
    }
    handleSaveChanges = (data) => {
        let dataNew = this.state.arrayDataSelected;
        this.setState({arrayDataSelected : [], showLoader : true})
        this.props.handleSaveDrilldownChanges(dataNew);
        // let payload = this.state.invoiceObject
        // this.props.handlePropsInvoiceClick(payload)
    }
    handleInvoiceFilterChange = (value) => {
        const { invoiceObject } = this.state;
        let payload = {
            ...invoiceObject,
            filter_amount: value

        }
        this.setState({
            invoiceObject: payload
        })
        this.props.handlePropsInvoiceClick(payload);
    }

    handleClose = () => {
        this.setState({ showModal: false })
    };

    handleValueOnChange = (event) => {
        this.setState({ manualAmountValue: event.target.value.replace(/,/g, '') })
    }

    handleSubmit = () => {
        // console.log('submit=====>')
        const { manualAmountValue, manualClickData } = this.state;
        let payload = {
            ...manualClickData,
            value: parseFloat(manualAmountValue)
        }

        this.props.postManualAmountData(payload)
        this.handleClose()

    }

    


    handleInvoiceSorting = (columnId) => {
        const { invoiceObject } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false
        let payload = {
            ...invoiceObject
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.sortParam = payload["sort"]

        this.props.handlePropsInvoiceClick(payload)
    }

    customGroup = (data) => {
        let idxData = {}
        data.forEach((x, idx)=>{
            if(idxData[x[this.state.drilldownGroup]] == undefined)
            idxData[x[this.state.drilldownGroup]] =  idx 
        })

        let obj = data.reduce((result, currentValue) => {
            (result[currentValue[this.state.drilldownGroup]] = result[currentValue[this.state.drilldownGroup]] || []).push(
            currentValue
            );
            return result;
        }, {});

        return { grouped : obj, index : idxData};
    }


    render() {
        let {  invoiceDetailStateFilter, pivotRowData, pivotRowData2, pivotRowDataOCOS, pivotHeaderData, pivotHeaderData2, pivotHeaderDataOCOS, loading, invoiceDetailState, invoiceLoading, NewLoader, NewPieData, NewCommentLoader, header, sub_header } = this.props.incomeStatementState;
        console.log(pivotRowDataOCOS, pivotHeaderDataOCOS, "checkDatOCOS")
        let {isExpandable, isInvoiceDetailModal, invoiceDetailStateNew, radioOcosByCountry  } = this.state
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        pivotHeaderData = this.changeKeysOfHeader(pivotHeaderData, nestedFlag);
        pivotHeaderDataOCOS = this.changeKeysOfHeader(pivotHeaderDataOCOS, nestedFlag);

        let nestedFlag2 = this.checkForNestedLevel(pivotRowData2)
        pivotHeaderData2 = this.changeKeysOfHeader(pivotHeaderData2, nestedFlag2);
        const { showInvoiceDetailModal, showLoader } = this.state
        const { tabSelectorValue, radioSelectorValue, radioSelectorReconValue,radioSelectorValuePlant, radioArrSelected, radioArrCountrySelected, radioSelectorValueDGSAutomation } = this.props
        let tableLen = pivotRowData && pivotRowData.length || 6;
        pivotRowData = this.checkLevel(pivotRowData);

        let tableLen2 = pivotRowDataOCOS && pivotRowDataOCOS.length || 6;
        pivotRowDataOCOS = this.checkLevel(pivotRowDataOCOS);

       
        let tableLen3 = pivotRowData2 && pivotRowData2.length || 6;
        pivotRowData2 = this.checkLevel(pivotRowData2);
        // let newDetailState = JSON.parse(JSON.stringify(invoiceDetailState))
        // if(this.state.drilldownGroup != "" && newDetailState.row_data != undefined){
        //     // console.log(newDetailState, "---- first")
        //     newDetailState.row_data = this.buildSubRowData(newDetailState.row_data)
        //     // console.log(newDetailState.row_data, "---- second")
        // }

        const body = (
            <div className='customModal forecastModal'>
                <div className='header'>
                    <h2>Add Manual Amount</h2>
                    <Icon onClick={this.handleClose}>close</Icon>
                </div>
                <div className='modal-content'>
                    <form>
                        <FormControl className="w-50 mb-20 flex">
                            <TextField label="Amount"
                                value={this.state.manualAmountValue}
                                placeholder={'Amount'}
                                onChange={this.handleValueOnChange}
                            />
                        </FormControl>
                    </form>
                    <Grid container justify="flex-end" className='mt-20'>
                        <Button variant="contained" color="primary" className='mr-10' onClick={this.handleSubmit}>Submit</Button>
                        <Button variant="contained" onClick={this.handleClose}>Cancel</Button>
                    </Grid>
                </div>
            </div>
        );

        const modalComments = (
            <Modal
                                open={this.state.commentModal}
                                onClose={this.toggleCommentModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.commentModal}>
                                    <div className='customModal dataValidationCommentModal'>
                                    {NewCommentLoader && <div className='progressfullpage'><CircularProgress /></div>}
                                        <div className={'header'}>
                                            <h2>Add New Explanatory Comment</h2>
                                            <Icon onClick={this.toggleCommentModal}>close</Icon>
                                        </div>
                                        <div className={'modal-content'} style={{ padding: '30px' }}>
                                            <TextField
                                                fullWidth
                                                label="Comment"
                                                multiline
                                                rows={10}
                                                // value={this.state.commentValue}
                                                onBlur={this.handleCommentChange}
                                                variant="filled"
                                            />
                                            <div className="flex jc-flex-end mt-30">
                                                <Button variant="contained" color="primary" onClick={this.handleCommentSubmit}>Submit</Button>
                                                <Button variant="contained" className='ml-16' onClick={this.toggleCommentModal}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </Modal>
        )
    

        // console.log(radioSelectorReconValue, tabSelectorValue, "testradioSelectorReconValue")
        return (
            <>
                {showLoader && <div className='progressfullpage'><CircularProgress /></div>}
                <Grid container spacing={3}>
                    <Grid item xs={((tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2  ) && ((radioSelectorValue == 3  || radioSelectorValue == 4 || radioSelectorValue == 5) &&  (radioArrSelected == "Default" && radioArrCountrySelected == "Default" ) )  ) || (tabSelectorValue == 3 && radioSelectorReconValue == 4 && radioSelectorValueDGSAutomation == 'total') ? 8 : 12}>
                    {radioSelectorReconValue == 4  ?   
                        <ReactTableFixedColumns
                            data={pivotRowData}
                            className={'xpna-reactFixed-table -striped -highlight noFixtColumTable'}
                            loading={loading}
                            columns={pivotHeaderData}
                            defaultPageSize={7}
                            pageSize={ pivotRowData.length }
                            showPagination={false}
                            sortable={false}
                        /> :
                        radioSelectorReconValue == 2 && pivotRowDataOCOS != undefined ?
                            <Grid item xs="12">
                               <ReactTableFixedColumns
                               data={pivotRowDataOCOS}
                               className={'xpna-reactFixed-table -striped -highlight noFixtColumTable'}
                               loading={loading}
                               columns={pivotHeaderDataOCOS}
                               defaultPageSize={7}
                               pageSize={ pivotRowDataOCOS.length }
                               showPagination={false}
                               sortable={false}
                           />
                            <RadioGroup name="radio" value={radioOcosByCountry} onChange={this.handleOCOSByCountryRadioButtonChange} className='radioGroupHorizontal mt-20'>
                                        {<FormControlLabel value="BU 1" control={<Radio color="primary" />} label="BU 1" />}
                                        {<FormControlLabel value="BU 2" control={<Radio color="primary" />} label="BU 2" />}
                                        {<FormControlLabel value="BU 3" control={<Radio color="primary" />} label="BU 3" />}
                                        {<FormControlLabel value="BU 4" control={<Radio color="primary" />} label="BU 4" />}
                                        {<FormControlLabel value="summary" control={<Radio color="primary" />} label="Total" />}
                            </RadioGroup>
                            <div className='xpna-table-wrapper' >
                                <ReactTableFixedColumns
                                data={pivotRowData}
                                className={'xpna-reactFixed-table -striped -highlight'}
                                loading={loading}
                                columns={pivotHeaderData}
                                defaultPageSize={7}
                                pageSize={ pivotRowData.length }
                                showPagination={false}
                                sortable={false}
                                />
                            </div>
                            
                           </Grid>
                           : 

                           radioSelectorValue == 1 && radioSelectorValuePlant == 'BU 1' ?
                           <ReactTableComponent
                                pivotRowData={pivotRowData}
                                className='xpna-reactFixed-table fixedFirstColumTable expandableRows'
                                loading={loading}
                                pivotHeaderData={pivotHeaderData}
                                tableLen={tableLen}
                                subComponent={this.subComponent}
                                expandIcon={this.expandIcon}
                                rowClick={this.rowClick}
                                showInvoiceDetailModal={showInvoiceDetailModal}
                                showLoader={showLoader}
                            />
                         : 
                        <ReactTableFixedColumns
                            data={pivotRowData}
                            className={tabSelectorValue == 3 && radioSelectorReconValue == 1  ?  'xpna-reactFixed-table -striped -highlight twoColumFixedTable' : tabSelectorValue == 3 && radioSelectorReconValue == 6  ? 'xpna-reactFixed-table -striped -highlight fourColumFixedTable' : 'xpna-reactFixed-table fixedFirstColumTable'}
                            loading={loading}
                            columns={pivotHeaderData}
                            defaultPageSize={7}
                            SubComponent={null}
                            pageSize={pivotRowData && pivotRowData.length > 7 ? pivotRowData.length : radioSelectorReconValue == 5? 3: 7}
                            showPagination={false}
                            sortable={false}
                        />}
                        
                    </Grid>
                    
                    {((tabSelectorValue == 0 || tabSelectorValue == 1 || tabSelectorValue == 2) && ((radioSelectorValue == 3  || radioSelectorValue == 4  || radioSelectorValue == 5) && (radioArrSelected == "Default" && radioArrCountrySelected == "Default")   )) &&
                        <Grid item xs={4} className="chart-div">
                            <div className="top-chart">
                                {<PieChartComponent
                                    fetchNewPieData={this.props.fetchNewPieData}
                                    NewPieData={NewPieData.sales_data}
                                    NewLoader={NewLoader}
                                    seriesName={'Total'}
                                />
                                }
                            </div>
                            <div className="border-top-gray">
                                {<PieChartComponent
                                    fetchNewPieData={this.props.fetchNewPieData}
                                    NewPieData={NewPieData.margin_data}
                                    NewLoader={NewLoader}
                                    seriesName={'Total'}
                                />
                                }
                            </div>
                        </Grid>
                    }
                   
                    {radioSelectorReconValue == 5  && pivotRowData2 != undefined  &&
                        <Grid item xs="12">
                           <ReactTableFixedColumns
                           data={pivotRowData2}
                           className={tabSelectorValue == 3 && radioSelectorReconValue == 1  ?  'xpna-reactFixed-table -striped -highlight twoColumFixedTable' : tabSelectorValue == 3 && radioSelectorReconValue == 6  ? 'xpna-reactFixed-table -striped -highlight fourColumFixedTable' : 'xpna-reactFixed-table -striped -highlight fixedFirstColumTable'}
                           loading={loading}
                           columns={pivotHeaderData2}
                           defaultPageSize={3}
                           pageSize={ pivotRowData2.length }
                           showPagination={false}
                           sortable={false}
                       />
                       </Grid>
                    }
                </Grid>

                {showInvoiceDetailModal &&
                    <InvoiceDetailModal
                        getInvoiceDetailGL={this.handleGLInvoiceSorting}
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailStateNew}
                        invoiceDetailStateFilter={invoiceDetailStateFilter}
                        invoiceLoading={invoiceLoading}
                        showDrilldownHeaderValue={this.showDrilldownHeaderValue}
                        handleFilterChange={this.handleInvoiceFilterChange}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                        showGlDrilldownFilters={false}
                        showAmountFilters={false}
                        isExpandable={isExpandable}
                        isInvoiceDetailModal={isInvoiceDetailModal}
                        header={header}
                        sub_header={sub_header}
                        handleCountryChanges={this.handleCountryChanges}
                        handleClassificationChanges={this.handleClassificationChanges}
                        handleSaveChanges={this.handleSaveChanges}
                        radioSelectorReconValue = {this.props.radioSelectorReconValue}
                        // invoiceLoadingGL={invoiceLoadingGL}
                        curMonth = {this.props.curMonth}
                        monthSelected = {this.props.monthSelected}
                        financialDuration={this.props.financialDuration}
                        toggleCommentModal={this.toggleCommentModal}
                        NewChart={this.props.NewChart}
                    />
                }
                <Modal
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModal}>
                        {body}
                    </Fade>
                </Modal>
                {modalComments}
            </>
        );
    }
}

export default FunctionalTableComponent;
