import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import pieChartIcon from '../../../../../../images/chart_icons/pie-chart-new.png';
import stackedColumnChartIcon from '../../../../../../images/chart_icons/stacked-column-chart-new.png';
import columnChartIcon from '../../../../../../images/chart_icons/column-chart-new.png';
import barChartIcon from '../../../../../../images/chart_icons/bar-chart-new.png';
import stackedAreaChartIcon from '../../../../../../images/chart_icons/stacked_chart.svg';
import waterfallChartIcon from '../../../../../../images/chart_icons/waterfall_chart.svg';
import lineChartIcon from '../../../../../../images/chart_icons/line-chart-new.png';
import radarChartIcon from '../../../../../../images/chart_icons/radar_chart.svg';
import histogramChartIcon from '../../../../../../images/chart_icons/histogram_chart.svg';
import scatterChartIcon from '../../../../../../images/chart_icons/scatter_chart.svg';
import { useSelector } from 'react-redux';
import './index.scss';

const charts = {
	bar: {
		icon: barChartIcon,
		id: 'bar',
		name: 'Bar Chart',
	},
	column: {
		icon: columnChartIcon,
		id: 'column',
		name: 'Column Chart',
	},
	histogram: {
		icon: histogramChartIcon,
		id: 'histogram',
		name: 'Histogram Chart',
	},
	line: {
		icon: lineChartIcon,
		id: 'line',
		name: 'Line Chart',
	},
	pie: {
		icon: pieChartIcon,
		id: 'pie',
		name: 'Pie Chart',
	},
	radar: {
		icon: radarChartIcon,
		id: 'radar',
		name: 'Radar Chart',
	},
	scatter: {
		icon: scatterChartIcon,
		id: 'scatter',
		name: 'Scatter Chart',
	},
	stackedArea: {
		icon: stackedAreaChartIcon,
		id: 'stackedArea',
		name: 'Stacked Area Chart',
	},
	stacked: {
		icon: stackedColumnChartIcon,
		id: 'stacked',
		name: 'Stacked Chart',
	},
	waterfall: {
		icon: waterfallChartIcon,
		id: 'waterfall',
		name: 'Waterfall Chart',
	},
};

const ShowMoreComponent = ({ data, onChange }) => {
	const [popperData, setPopperData] = useState({
		open: false,
		anchorEl: null,
	});
	const { status, chartMetaData } = useSelector(
		(state) => state.freeCashFlowState.analyticStudio
	);
	const { open, anchorEl } = popperData;
	const { uiTypes = ['table'], chartType = 'none' } = data;

	const openShowMorePopover = (event) => {
		setPopperData({
			open: true,
			anchorEl: event.currentTarget,
		});
	};

	const handleShowMorePopoverClose = () => {
		setPopperData({
			open: false,
			anchorEl: null,
		});
	};

	const handlePopperClickAwayHandler = (event) => {
		if (event.target.localName === 'body') {
			return;
		}
		setPopperData({
			open: false,
			anchorEl: null,
		});
	};

	const handleCheckBoxChange = (event) => {
		const { name } = event.target;
		let tempUITypes = [...uiTypes],
			tempChartType = chartType;

		if (name === 'table') {
			if (tempUITypes.includes('table')) {
				const index = tempUITypes.indexOf('table');
				if (index > -1) {
					tempUITypes.splice(index, 1);
				}
			} else tempUITypes.push('table');
		} else if (name === 'chart') {
			if (tempUITypes.includes('chart')) {
				const index = tempUITypes.indexOf('chart');
				if (index > -1) {
					tempUITypes.splice(index, 1);
					tempChartType = 'none';
				}
			} else {
				tempUITypes.push('chart');
				tempChartType =
					chartMetaData && chartMetaData.length > 0
						? chartMetaData[0].id
						: 'none';
			}
		}

		onChange &&
			onChange({
				uiTypes: tempUITypes,
				chartType: tempChartType,
			});
	};

	const handleChartSelect = (chartType) => {
		onChange &&
			onChange({
				uiTypes,
				chartType,
			});
	};

	const builtChartsList = chartMetaData.map(({ id, label, enabled }) => (
		<Grid item xs={3} key={`chart-${id}`}>
			<div
				className={`chart-icon-box${id === chartType ? ' selected' : ''}${
					enabled ? '' : ' disabled'
				}`}
				onClick={() => enabled && handleChartSelect(id)}
			>
				<div className='icon-wrapper'>
					{charts?.[id]?.icon ? (
						<img className='icon' src={charts[id].icon} alt={label} />
					) : null}
				</div>
				<span className='title'>{label}</span>
			</div>
		</Grid>
	));

	return (
		<>
			<Button
				variant='outlined'
				className='newButtonOutlined btnBlue uppercase'
				disabled={status !== 'success'}
				onClick={openShowMorePopover}
			>
				Show Charts
			</Button>
			<Popper
				id='show-more-popover'
				open={open}
				anchorEl={anchorEl}
				onClose={handleShowMorePopoverClose}
				placement='bottom-end'
				disablePortal={true}
			>
				<ClickAwayListener onClickAway={handlePopperClickAwayHandler}>
					<div className='show-more-popover-inner'>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											name='table'
											checked={uiTypes.includes('table')}
											onChange={handleCheckBoxChange}
										/>
									}
									label='Show Table'
								/>
								<Divider className='divider' />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											name='chart'
											checked={uiTypes.includes('chart')}
											onChange={handleCheckBoxChange}
										/>
									}
									label='Show Chart'
								/>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={1}
							className={`mb-4${uiTypes.includes('chart') ? '' : ' disabled'}`}
						>
							{builtChartsList}
						</Grid>
					</div>
				</ClickAwayListener>
			</Popper>
		</>
	);
};

export default ShowMoreComponent;
