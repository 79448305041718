import { CONFIG } from "../../../../config";

export const checkToOpenInvoiceDetail = (columnId) => {
  const columnIdArr = [
    // "customer_name",
    "1_30_days",
    "31_60_days",
    "61_90_days",
    "91_180_days",
    "180_plus_days",
    "grand_total",
    "overdue_amount",
    "current",
  ];
  let toOpenModal = false;
  if (columnIdArr.indexOf(columnId) !== -1) {
    toOpenModal = true;
  }
  return toOpenModal;
};

export const renderChatData = (data, channel) => {
  var src = `${CONFIG.api_base_url}v1/chat/channel/file/${channel}/${data.body}`;
  let returnData = "";
  switch (data.type) {
    case "image":
      var ele = document.createElement("img");
      ele.src = src;
      returnData = ele;
      break;
    case "doc":
      var ele = document.createElement("a");
      ele.href = src;
      returnData = ele;
      break;
    case "audio":
      var ele = document.createElement("a");
      ele.href = src;
      returnData = ele;
      break;
    case "video":
      break;
    default:
      returnData = `${data.name} : ${data.body}`;
      break;
  }
  return returnData;
};
