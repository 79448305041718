import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableComponent from './TableComponent'
import Tooltip from '@material-ui/core/Tooltip';
import Menu from "@material-ui/core/Menu";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import DownloadExcel from "../../../../src/images/download_excel.svg";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export class MPRComponent extends React.Component {

    constructor(props) {
        super(props);
        this.isExport = false;
        this.buMap = {
            0: '',
            1: 'msb',
            2: 'lcs',
            3: 'dgs',
            4: 'pab',
            5: 'ses'
        }
        this.state = {
            monthSelectorValue: props.incomeStatementState.defaultMonth,
            firstTabSelectorValue: 0,
            PABchildTabSelectorValue: 0,
            consolidationChildTabSelectorValue: 0,
            radioFilterValue: '',
            MPRchildRadioSelectorValue: '0',
            PABRadioSelectorValue: '0',
            anchorEl: ''
        }
    }

    componentDidMount() {
        this.props.getMPRDefaultMonthData()

    }

    componentDidUpdate(prevProps) {
        const { monthFilterArr, defaultMonth } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.monthFilterArr.length != monthFilterArr.length) {
            this.setState({
                monthSelectorValue: defaultMonth
            })
        }
    }

    handleMonthChange = (event) => {
        if (event.target) {
            this.setState({
                monthSelectorValue: event.target.value,
            },() => {
                this.fetchTotalnewConsolidation()
            });
        }
    };

    fetchTotalnewConsolidation = () => {

        const {  monthSelectorValue, consolidationChildTabSelectorValue } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            bu: this.buMap[consolidationChildTabSelectorValue],
            export: this.isExport
        }
        this.props.fetchTotalnewReport(payload)
        this.isExport = false;

    }


    handlePrimaryTabChange = (event, newValue) => {
        this.setState({
            firstTabSelectorValue: newValue
        })
    };

    handlePABchildTabChange = (event, newValue) => {
        this.setState({
            PABchildTabSelectorValue: newValue
        })
    };

    handleConsolidationChildTabChange = (event, newValue) => {
        this.setState({
            consolidationChildTabSelectorValue: newValue
        }, () => {
            this.fetchTotalnewConsolidation()
        })
    };

    handleMPRchildRadioChange = (event) => {
        this.setState({
            MPRchildRadioSelectorValue: event.target.value,
        })
    };

    handlePABRadioChange = (event) => {
        this.setState({
            PABRadioSelectorValue: event.target.value,
        })
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleDownloadIconClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleDownloadClick = (type) => {
        console.log(type);
        this.setState({ anchorEl: null })
    }

    handleExcelDownload = () => {
        this.isExport = true;
        this.fetchTotalnewConsolidation()
      }

    render() {
        const { monthFilterArr } = this.props.incomeStatementState
        const { firstTabSelectorValue,monthSelectorValue, PABchildTabSelectorValue, consolidationChildTabSelectorValue, MPRchildRadioSelectorValue, PABRadioSelectorValue,anchorEl } = this.state
        return (
            <div className='buplComponent'>
                <div className='flex jc-space-between'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} className='maxw-200'>
                            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                <InputLabel>Month</InputLabel>
                                <Select
                                    value={monthSelectorValue}
                                    label="Month"
                                    onChange={this.handleMonthChange}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {monthFilterArr && monthFilterArr.length && monthFilterArr.map(item => {
                                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className='ml-20 pointer'>
                        <Tooltip title={'Download to Excel'}>
                            <img
                                src={DownloadExcel}
                                className='pointer'
                                height='30'
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleExcelDownload();
                                }} />
                        </Tooltip>
                    </div>
                    {/* <Tooltip title={'Download'} className='pointer'>
                        <IconButton aria-label="download" onClick={this.handleDownloadIconClick} color="primary">
                            <DownloadIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => this.setState({ anchorEl: null })}
                    >
                        <MenuItem onClick={() => { this.handleDownloadClick(0) }}>Download PDF</MenuItem>
                        <MenuItem onClick={() => { this.handleDownloadClick(1) }}>Download Excel</MenuItem>
                        <MenuItem onClick={() => { this.handleDownloadClick(2) }}>Download Graph</MenuItem>
                    </Menu> */}
                </div>
                <Tabs
                    value={firstTabSelectorValue}
                    onChange={this.handlePrimaryTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className='mt-16 mb-16'
                >
                    <Tab label="MPR Reports"  {...this.a11yProps(0)} />
                    <Tab label="Risk Opportunity"  {...this.a11yProps(1)} />
                    <Tab label="Total new Consolidation"  {...this.a11yProps(2)} />
                </Tabs>
                {(firstTabSelectorValue == 0) &&
                    <>
                        <FormControl component="fieldset">
                            <RadioGroup name="radio" value={MPRchildRadioSelectorValue} onChange={this.handleMPRchildRadioChange} className='radioGroupHorizontal'>
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="ACHIEVEMENT VS PLAN" />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="REVENUE/ORDERS BOOK" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="PAB MPR REPORT" />
                            </RadioGroup>
                        </FormControl>
                        {(MPRchildRadioSelectorValue == 2) &&
                            <Tabs
                                value={PABchildTabSelectorValue}
                                onChange={this.handlePABchildTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className='mt-16 mb-16'
                            >
                                <Tab label="Achievement vs plan"  {...this.a11yProps(0)} />
                                <Tab label="Revenue/Orders Book"  {...this.a11yProps(1)} />
                            </Tabs>
                        }
                    </>
                }
                {(firstTabSelectorValue == 2) &&
                    <>
                        <Tabs
                            value={consolidationChildTabSelectorValue}
                            onChange={this.handleConsolidationChildTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className='mt-16 mb-16'
                        >
                            <Tab label="Summary"  {...this.a11yProps(0)} />
                            <Tab label="BU 1"  {...this.a11yProps(1)} />
                            <Tab label="BU 2"  {...this.a11yProps(2)} />
                            <Tab label="BU 3"  {...this.a11yProps(3)} />
                            <Tab label="BU 4"  {...this.a11yProps(4)} />
                            <Tab label="OH"  {...this.a11yProps(5)} />
                        </Tabs>
                        {(consolidationChildTabSelectorValue == 4) &&
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={PABRadioSelectorValue} onChange={this.handlePABRadioChange} className='radioGroupHorizontal'>
                                    <FormControlLabel value="0" control={<Radio color="primary" />} label="TOTAL PAB CONSOLIDATION" />
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="EMEA" />
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="APAC" />
                                    <FormControlLabel value="3" control={<Radio color="primary" />} label="NA" />
                                    <FormControlLabel value="4" control={<Radio color="primary" />} label="LAR" />
                                </RadioGroup>
                            </FormControl>
                        }

                        <TableComponent />
                    </>
                }
            </div >
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state,
                incomeStatementState: state.incomeStatement,
            }
        },
        dispatch => {
            return {
                getBudgetGroup() {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_BUDGET_GROUP'
                    });
                },
                getFunctionalPivotView(data) {
                    dispatch({
                        type: 'FETCH_FUNCTIONAL_PIVOT_DATA',
                        payload: data
                    });
                },
                fetchTotalnewReport(data) {
                    dispatch({
                        type: 'FETCH_TOTAL_new_CONSOLIDATION',
                        payload: data
                    });
                },
                getMPRDefaultMonthData() {
                    dispatch({
                        type: 'FETCH_MPR_DEFAULT_MONTH'
                    });
                },
                sendPivotCommentData(data) {
                    dispatch({
                        type: 'SEND_PIVOT_COMMENT_DATA',
                        payload: data
                    });
                },
                sendFunctionalCommentData(data) {
                    dispatch({
                        type: 'SEND_FUNCTIONAL_COMMENT_DATA',
                        payload: data
                    });
                },
            };
        }
    )(MPRComponent);

