import React from "react";
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";
import DownloadExcel from "../../../images/download_excel.svg";
// import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
// import { getAccessByMenuKey } from '../../../../utils/userManagement';
// import './index.scss';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export class PlanningModelsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // tableHeaderData: [],
            // tableRowData: [],
        };
    }

    componentDidMount() {
		this.props.getPlanModels({});
	}

    editPlan = (data) =>{
        localStorage.setItem('edit_plan', JSON.stringify(data));
        this.props.switchTab(0)
    }

    render() {
        const {kpiPlanningDatastate} = this.props;
        const {loading, rowData, rowHeader} = kpiPlanningDatastate
        // const {tableHeaderData, tableRowData} = this.state;

        const changeKeysOfHeader = (data) => {
            if (data.length) {
                return data.map((item, index) => {
                    if(item.key == "action"){
                        const obj = {
                            Header : item.label,
                            id: item.key,
                            accessor: item.key,
                            Cell: (thisProps) => (
                                <div>
                                  <Tooltip title={"Edit"}>
                                     <EditIcon className="icon-table" onClick={() => {this.editPlan(thisProps.original)}}/>
                                     </Tooltip>  
                                </div>
                            )
                        }

                        return obj
                    }

                    return {
                        Header: item.label,
                        id: item.key,
                        accessor: item.key,
                    };
                });
            }
    
            return [];
        };
        let tableHeaderData = changeKeysOfHeader(rowHeader);
        let tableRowData = rowData;

        const body = (
            <React.Fragment>        
            <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={12} sm={12}>
                    <div className='top-header'>
                        <div>
                            <b>Saved Plans</b>
                        </div>
                        {/* {getAccessByMenuKey(props.menuData, 'planning-models', 'add') && (
                            <Button
                                onClick={handleNewButton}
                                variant='contained'
                                className='newButton btnBlue btnCreate uppercase'
                                color='primary'
                            >
                                Create New Report
                            </Button>
                        )} */}
                        <div className='text-right' style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            {/* <Button variant='contained' className='run-button mr-10 btnsearchcompare' onClick={handShowCompare}>Search and Compare</Button> */}
                            
                            {/* <Tooltip title={"Collaborate"}>
                                <Icon
                                    className="fs-20 primary mr-10"
                                    color={"inherit"}
                                    disabled
                                    onClick={() => {
                                        // handleOpen();
                                    }
                                    }
                                >
                                    chat_bubble_filled
                                </Icon>
                            </Tooltip> */}
                            <Button type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    className="btn-login mr-10"
                                    onClick={()=>{this.editPlan({ is_new : true })}}>
                                        Add New
                            </Button>
                            <Tooltip title={'Download to Excel'}>
                                <img
                                    src={DownloadExcel}
                                    className='pointer mr-10'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.props.getPlanModels({export : true});
                                    }} />
                            </Tooltip>
                            <Tooltip title={'Download to PDF'}>
                                <PictureAsPdfIcon fontSize="large"
                                    height='30'
                                    className="pointer mr-10"
                                    onClick={(event) => {
                                        // event.preventDefault();
                                        //   this.printDocument();
                                    }} />
                            </Tooltip>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className='planningModelsTable-container'>
                    <ReactTable
                        columns={tableHeaderData}
                        data={tableRowData}
                        loading={loading}
                        defaultPageSize={10}
                        pageSize={tableRowData.length > 5 ? tableRowData.length : 5}
                        showPagination={true}
                        className='-striped -highlight xpna-react-table'
                    />
                </Grid>
            </Grid>
              
            </React.Fragment>
          )

        return body;
    }
}

export default
    connect(
        state => {
            return {
                kpiPlanningDatastate: state.functionalPlanningState.kpiDataPlanningModels,
            }
        },
        dispatch => {
            return {
                // fetchDropdownPlanning() {
                //     dispatch({
                //         type: "FETCH_DROPDOWN_PLANNING"
                //     })
                // }
                // http://localhost:9091/api/v1/functionalplan/getkpiplanningsaved
                getPlanModels(data) {
                    dispatch({
                        type: "FETCH_KPI_PLANNING_MODELS",
                        payload: data,
                    })
                },
            };
        }
    )(PlanningModelsComponent);