import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import './index.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector, useDispatch } from "react-redux";
import TableComponent from './TableComponent'
import Tooltip from '@material-ui/core/Tooltip';


export default function CompareModal(props) {
    const [buValues, setBUValues] = useState([]);
    const [periodValues, setPeriodValues] = useState([]);
    const [countryValues, setCountryValues] = useState([]);
    const [plantValues, setPlantValues] = useState([]);
    const dispatch = useDispatch();


    const handleClose = () => {
        props.handleClose()
    }

    const handleExcelDownload = () => {
    }

    const body = (

        <div className='customModal'>
            <div className='header'>
                <h2>Drilldown Data</h2>
                <Icon onClick={() => { handleClose() }}>close</Icon>
            </div>
            <div className='modal-content bupl-Modal'>
                <TableComponent
                />
            </div>

        </div>
    );

    return (
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
    );
}