import React, {useState, useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import TableComponent from './TableComponent';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const rollingViewMap = {
  0: '',
  1: 'monthly',
  2: 'quarterly',
  3: 'yearly'
}

export default function(){
    const [qtrSelectorValue, setQtrSelectorValue] = useState('')
    const [yearSelectorValue, setYearSelectorValue] = useState('')
    const [radioSelectorValue, setRadioSelectorValue] = useState('0')
    
    const extendedPlanningState = useSelector(state => state.extendedPlanning);
    const {actualBUData} = extendedPlanningState
    const {filter} = actualBUData
    const {yearArr, qtrArr, monthArr, defaultDate} = filter
    const dispatch = useDispatch();
    const [monthSelectorValue, setMonthSelectorValue] = useState('')


    const handleMonthChange = (event) => {
      setYearSelectorValue('')
      setQtrSelectorValue('')
      setMonthSelectorValue(event.target.value)
    }
    const handleQtrChange = (event) => {
      setQtrSelectorValue(event.target.value)
      setYearSelectorValue('')
      setMonthSelectorValue('')
    }
    const handleYearChange = (event) => {
      setYearSelectorValue(event.target.value)
      setQtrSelectorValue('')
      setMonthSelectorValue('')
    }

    const handleRadioChange = (event) => {
      setRadioSelectorValue(event.target.value)
    }

    useEffect(() => {
      if (defaultDate) {
        setMonthSelectorValue(defaultDate)
      }
    },[defaultDate])
    useEffect(() => {
      handleFetchData()
    },[monthSelectorValue, qtrSelectorValue, yearSelectorValue])

    useEffect(() => {
      let thisMonth = monthSelectorValue
      if (radioSelectorValue == 1 || radioSelectorValue == 0) {
        setMonthSelectorValue(defaultDate)
        setQtrSelectorValue('')
        setYearSelectorValue('')
        if(thisMonth == defaultDate){
          handleFetchData()
        }
      }else if(radioSelectorValue == 2) {
        setQtrSelectorValue(qtrArr[0].value)
        setMonthSelectorValue('')
        setYearSelectorValue('')
      }else {
        setYearSelectorValue(yearArr[0].value)
        setMonthSelectorValue('')
        setQtrSelectorValue('')
      }
    },[radioSelectorValue])
    const handleFetchData = (isExport = false) => {
      const payload = {
        export: isExport,
        year: yearSelectorValue ? yearSelectorValue : undefined,
        quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
        yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
        view_type: 'view_bySummary',
        view_period: rollingViewMap[radioSelectorValue]
      }

      dispatch({
        type: "FETCH_ACTUAL_BU_TABLE_DATA",
        payload
      })
    }

    return (
        <div>
        <div className='flex jc-space-between'>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3} className='maxw-200'>
              <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Month</InputLabel>
                <Select
                  value={monthSelectorValue}
                  label="Month"
                  disabled={radioSelectorValue == 2 || radioSelectorValue == 3}
                  onChange={handleMonthChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {monthArr && monthArr.length && monthArr.map(item => {
                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className='maxw-200'>
              <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Quarter</InputLabel>
                <Select
                  value={qtrSelectorValue}
                  label="Quarter"
                  disabled={radioSelectorValue == 1 || radioSelectorValue == 3}
                  onChange={handleQtrChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {qtrArr && qtrArr.length && qtrArr.map(item => {
                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className='maxw-200'>
              <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                <InputLabel>Annual</InputLabel>
                <Select
                  value={yearSelectorValue}
                  label="Annual"
                  disabled={radioSelectorValue == 1 || radioSelectorValue == 2}
                  onChange={handleYearChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {yearArr && yearArr.length && yearArr.map(item => {
                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
						<Tooltip title={'Download to Excel'} className='pointer'>
							<img onClick={() => {handleFetchData(true)}} src={DownloadExcel} className='pointer' height='30' />
						</Tooltip>
					</Grid>
          </div>
          <div>
          <FormControl component="fieldset">
                <RadioGroup name="radio" value={radioSelectorValue} onChange={handleRadioChange} className='radioGroupHorizontal'>
                  <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Summary" />
                  <FormControlLabel value={'1'} control={<Radio color="primary" />} label="Monthly Rolling View" />
                  <FormControlLabel value={'2'} control={<Radio color="primary" />} label="Quarterly Rolling View" />
                  <FormControlLabel value={'3'} control={<Radio color="primary" />} label="Yearly Rolling View" />
                </RadioGroup>
              </FormControl>
          </div>
          <br/>
          <div>
            <TableComponent
            handleColumnDataClick={() => {}}
            />
          </div>
            
        </div>
    )
}