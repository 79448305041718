import React, { useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Popover } from "@material-ui/core";
import FilterIcon2 from './FilterIcon'
import DropdownComponent from './DropdownOptions'
import "./CustomTableColumnFilter.scss";


const CustomTableColumnFilter = ({ options, filterData, onFilterChange, optionLabel, defaultFilterValue }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isActive, setActiveStatus] = React.useState(false);

    useEffect(() => {
        if (defaultFilterValue?.length) {
            setActiveStatus(true);
        } else {
            setActiveStatus(false);
        }
    }, [defaultFilterValue]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // const { type: filterType } = columnData.filter;

    return (
        <>
            <IconButton color={isActive ? 'secondary': ''} className={isActive ? "filter-btn active" : "filter-btn"} aria-describedby={id} size="small" aria-label="filter" onClick={handleClick}>
                {/* <FilterAltSharpIcon className={`filter-icon ${isActive ? "active" : ""}`} /> */}
                <FilterIcon2 isActive={isActive}/>
            </IconButton>
            {open === true && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <div className="custom-table-filter-section">
                        <div className="custom-table-filter-header-section">
                            <div className="custom-table-filter-header-section-left">
                                <p className="custom-table-filter-header-section-left-title">Filter</p>
                            </div>
                            <div className="custom-table-filter-header-section-right">
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {/* {getFilterLayoutComponent({
                            key: `${filterType}_filter`,
                            component: filterType,
                            columnData,
                            filterData,
                            onFilterChange,
                            onClose: handleClose,
                        })} */}
                        <DropdownComponent 
                        options={options}
                        optionLabel={optionLabel}
                        onFilterChange={onFilterChange}
                        defaultFilterValue={defaultFilterValue}
                        />
                    </div>
                </Popover>
            )}
        </>
    );
};

export default CustomTableColumnFilter;
