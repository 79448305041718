import React, { useEffect } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from "react-redux-toastr";
import { getAccessByMenuKey } from "../../../../../utils/userManagement";



const DragDropContextContainer = styled.div`
  padding: 20px;
  border: 4px solid indianred;
  border-radius: 6px;
`;

const ListGrid = styled.div`
  display: flex;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

// fake data generator
const statusArr = ["blank", "late", "completed", "ahead"];
const getItems = (count, prefix) =>
  Array.from({ length: count }, (v, k) => k).map((k) => {
    const randomId = Math.floor(Math.random() * 1000);
    const randomStatus = Math.floor(Math.random() * statusArr.length);
    return {
      status: statusArr[randomStatus],
      id: `item-${randomId}`,
      prefix,
      content: `item ${randomId}`
    };
  });

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const lists = [
  {key : "" , label : "Backlog"}, 
  {key : "submit" , label : "Under Review"}, 
  {key : "reject" , label : "Return to Preparer"}, 
  {key : "approve" , label : "Approved"}, 
 ];

const generateLists = () =>
  lists.reduce(
    (acc, listKey) => ({ ...acc, [listKey]: getItems(10, listKey) }),
    {}
  );

function DragList(props) {
  const cadenceBoardSortedData = useSelector(state => state.functionalPlanningState.kpiDataPlanningCadence);
  const menuData = useSelector(state => state.authReducer.menuData);
  const { data, loading } = cadenceBoardSortedData
  // const [elements, setElements] = React.useState(cadenceBoardSortedData);

  console.log ('data',data)

  useEffect(() => {
    // setElements(generateLists())
  }, []);

  const onDragEnd = (result) => {

    if(!getAccessByMenuKey(menuData, "pns_func_pnl", "review") || result.source.droppableId == "approve"){
      toastr.error("Access Denied!")
      return
    }

    let thisSource = lists.find(x=>{ return x.label ==  result.source.droppableId })
    if(thisSource.key == "approve"){
      toastr.error("Can't Move Approved Plans")
      return
    }

    if (result.destination.droppableId != result.source.droppableId){
     let payload = {}
      let thisStatus = lists.find(x=>{ return x.label ==  result.destination.droppableId })
      payload._id = result.draggableId
      payload.status = thisStatus.key
 
      props.handleChangeBoard([payload])
    }

    console.log("--result",result)

  };

  return (
    <DragDropContextContainer className="dnd-cadence-board">
          {loading ? <div  className='text-center mt-10'><CircularProgress /></div> : 
        <DragDropContext onDragEnd={onDragEnd} >
          <ListGrid>
           { lists.map((listKey) => (
              <DraggableElement
                elements={data[listKey.key]}
                key={listKey.label}
                prefix={listKey.label}
                handleOpen={props.handleOpen}
                planTypeValue={props.planTypeValue}
                dateStatusSelected = {props.dateStatusSelected}
              />
            ))
           }
          </ListGrid>
        </DragDropContext>
          }
      </DragDropContextContainer>
  );
}

export default DragList;
