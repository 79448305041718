import React from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GapToTarget from './GapToTarget';
import ImprovementPlan from './ImprovementPlan';
import CadenceBoard from './CadenceBoard';
import ImprovementAnalysis from './ImprovementAnalysis';
import MonthlyPerformance from './PerformanceAnalysis/MonthlyPerformance';
import MonthlyChange from './PerformanceAnalysis/MonthlyChange';
import CumulativePerformance from './PerformanceAnalysis/CumulativePerformance';
import CompareAllBenchmarks from './PerformanceAnalysis/CompareAllBenchmarks';
import ReportComponent from './Reports';
import './index.scss';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Grid, Tooltip } from "@material-ui/core";
import DownloadExcel from "../../../src/images/download_excel.svg";
import { APP_ICONS } from '../../constant';
import { getList } from '../../utils/userManagement';
import { getAccessByMenuKey } from "../../utils/userManagement";
import AnalyticStudio from './AnalyticStudio';


export class NewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.iframeUrl = {
            0: 'https://data-browser.xpnademo.eaciitapp.com/app',
            // 0: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=raw_fcf_impact_analysis?screenSize=fullscreen',
            1: {
                0: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_plan_cadence_analysis?screenSize=fullscreen',
                1: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_plan_cost_and_resource?screenSize=fullscreen',
                2: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_plan_sales_output?screenSize=fullscreen'
            },
            2: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=variance_analysis?screenSize=fullscreen',
            3: {
                0: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_analysis_cumulative_performance_one?screenSize=fullscreen',
                1: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_analysis_cumulative_performance_two?screenSize=fullscreen',
                2: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_analysis_monthly_performance_one?screenSize=fullscreen',
                3: 'https://data-browser.xpnademo.eaciitapp.com/app/data-table/db-name=automated_reports&collection-name=improvement_analysis_monthly_performance_two?screenSize=fullscreen'
            },
            4: 'https://xpnademo.eaciitapp.com/dashboard/user-file-upload'
        }

        this.state = {
            performanceSelectorValue: '1',
            newRadioSelectorValue:  this.props.isDataBrowser == "true" ? '99' : '1',
            fcfImprovementSelectorValue: '0',
            dataBrowserSelectorValue: '0',
            fcfDimension : "lob",
            fcfPeriod : "fy", 
            fcfKPICategory : "",
            fcfKPIType : "",
            fcfKPI : "",
            yearLabel : "",
            quarter : "",
            yearMonth : "", 
            users: [],
            prefilledPlanValue: {},
            saveData: [],
        }
    }

  

    handleCollaborateClick = () => {
        this.setState({
            newRadioSelectorValue: '8',
        }, () => {this.fetchFCFData()})
    }

    handleImprovementPlanClick = (data, payload) => {
        this.setState({
            newRadioSelectorValue: data == 'view' ? '4' : '2',
            prefilledPlanValue: payload?.original
        })
    }

    handleFileUploadRedirect = (value) => {
         localStorage.setItem('show-back-fcf','true')
		window.location.assign('/dashboard/user-file-upload')
	}

    handleASRedirect = (value) => {
        localStorage.setItem('show-back-fcf','true')
       window.location.assign('/dashboard/analytics-studio')
   }

    handleNewRadioChange = (event) => {
        let thisVal = event.target.value
        
        if(thisVal == 11){
            this.handleFileUploadRedirect()
        }

        if(thisVal == 12){
            this.handleASRedirect()
        }

        this.setState({
            newRadioSelectorValue: thisVal,
        }, () => {
            if (this.props.isDataBrowser == "true" ){
                setTimeout(() => {
                    let iframeData = document.getElementsByTagName('iframe')
                    iframeData && iframeData[0].contentWindow.postMessage({
                            dataBrowserDatabaseName: '',
                            dataBrowserCollectionName: '',
                            dataBrowserScreenSize: 'fullscreen',
                            dataBrowserFilterVisibility: true,
                            dataBrowserFilterQuery: [],
                            dataBrowserEnableColumnFilters: false,
                            dataBrowserDevMode: false,
                            dataBrowserEnableDownload: false
                          }
                      )
                }, 3000)   
            }

            if (this.state.newRadioSelectorValue == 8 || this.state.newRadioSelectorValue == 1) {
                this.fetchFCFData()
            }
        })
    };

    fetchFCFData = () => {
        const {newRadioSelectorValue} = this.state
        const payload = {
            dimension: this.state.fcfDimension,
            period: this.state.fcfPeriod,
            isAutomated: newRadioSelectorValue == 8
          }
        this.props.fetchFCFImpactAnalysis(payload)
    }

    fetchMetaData = () => {
        this.props.fetchMetaDataAnalysis()
    }

    fetchCadenceBoard = () => {
        this.props.fetchCadenceBoardData()
    }

    getSaveDataAnalyticStudio = () => {
        // this.props.getSaveData()
    }

    fetchDataAnalyticsStudio = () => {
        const payload = {}
        this.props.fetchDataAnalysis(payload)
    }

    handleSaveDataAnalyticStudio = (data) => {
        this.props.saveData(data);
    }

    handleDeleteSavedDataAnalyticStudio =(data) => {
        this.props.deleteSavedData(data);
    }

    componentDidMount() {
        const openNewTabFlag = window.localStorage.getItem('openNewTabFlag')
        if (openNewTabFlag) {
            this.setState({
                newRadioSelectorValue: openNewTabFlag
            }, () => {
                this.fetchAllData();
                localStorage.removeItem('openNewTabFlag')
            })
        }else {
            this.fetchAllData()
        }

       
    }

    fetchAllData = () => {
        this.props.getAllUser({})
        this.fetchFCFData()
        this.getUserList()
        this.fetchMetaData()
        this.fetchDataAnalyticsStudio()
        this.getSaveDataAnalyticStudio()
        this.fetchCadenceBoard()
    }

    getUserList = async () => {
        this.setState({
            users : await getList()
        })
    }



    handlePerformanceChange = (event) => {
        this.setState({
            performanceSelectorValue: event.target.value,

        })
    };

    handleDataBrowserSelectorValue = (event) => {
        this.setState({
            dataBrowserSelectorValue: event.target.value,

        })
    };

    handleFCFImprovementChange = (event) => {
        this.setState({
            fcfImprovementSelectorValue: event.target.value,

        })
    };

    handleDownloadExcel = () => {
        const {newRadioSelectorValue} = this.state

        const payload = {
          dimension: this.state.fcfDimension ,
          period: this.state.fcfPeriod,
          kpi : this.state.fcfKPI,
          kpi_type : this.state.fcfKPIType,
          kpi_category : this.state.fcfKPICategory,
          yearmonth : this.state.yearMonth,
          quarter : this.state.quarter,
          year_label : this.state.yearLabel,
          isAutomated : newRadioSelectorValue == 8,
          export : true,
        }
        this.props.fetchFCFImpactAnalysis(payload)
    }

    setFCFParams = (payload) => {
        this.setState({
            fcfDimension: payload.dimension ,
            fcfPeriod: payload.period,
            fcfKPICategory: payload.kpi_category,
            fcfKPIType: payload.kpi_type,
            fcfKPI: payload.kpi,
            yearMonth : payload.yearMonth,
            quarter : payload.quarter,
            yearLabel : payload.yearLabel,

        })
    }

    render() {
        const { performanceSelectorValue, newRadioSelectorValue, fcfImprovementSelectorValue, prefilledPlanValue, dataBrowserSelectorValue, users } = this.state;
        const {fetchFCFImpactAnalysis, fetchMetaDataAnalysis, fetchDataAnalysis, getSaveData, fetchCadenceBoardData, isDataBrowser} = this.props;
        const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;
        const { data_browser_url } = window['runConfig'];

        const { authReducer } = this.props
        const menuData = authReducer.menuData

        return (
            <div className='buplComponent free-cash-flow'>
                <div className='flex jc-space-between'>
                </div>

                { isDataBrowser != "true" &&
                <>
                <div className="pointer-benchmark">
                    <div className="cnt">
                        <p>Benchmark</p>
                    </div>
                </div>
                <div className="pointer-ideation">
                    <div className="cnt">
                        <p>Ideation</p>
                    </div>
                </div>
                <div className="pointer-improvement">
                    <div className="cnt">
                        <p>Improvement</p>
                    </div>
                </div>
                <div className="pointer-self-service">
                    <div className="cnt">
                        <p>Self Service</p>
                    </div>
                </div>
                </>
                }

                { isDataBrowser != "true" &&
                    <React.Fragment>
                        <div className="flex ai-center jc-space-between mb-10 menu-options fcf-radio">
                            <FormControl component="fieldset">
                                <RadioGroup name="radio" value={newRadioSelectorValue} onChange={this.handleNewRadioChange} className='radioGroupHorizontal'>
                                    <div className="benchmark-group">
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                                <FormControlLabel value="11" control={<Radio color="primary" />} label="Data Management" />
                                        }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                            <FormControlLabel value="10" control={<Radio color="primary" />} label="FCF Reports" />
                                        }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Impact Analysis" />
                                    }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="8" control={<Radio color="primary" />} label="Auto Emails" />
                                }
                                    </div>
                                    <div className="ideation-group">
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Create Initiatives" />
                            }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Cadence Board" />
                        }
                                    </div>
                                    <div className="improvement-group">
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="View Initiatives" />
                    }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="5" control={<Radio color="primary" />} label="Variance Analysis" />
                }
                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="6" control={<Radio color="primary" />} label="FCF Analysis" />
            }

                                        {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                                <FormControlLabel value="12" control={<Radio color="primary" />} label="Analytics Studio" />
                                        }
                                        {/* {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="7" control={<Radio color="primary" />} label="Data Browser" />
        } */}
                                        {/* {getAccessByMenuKey(menuData, "analytics_studio", "view") &&
                                        <FormControlLabel value="9" control={<Radio color="primary" />} label="Analytics Studio" />
    } */}
                                        
                                    </div>
                                </RadioGroup>
                            </FormControl>
                            {
                                (newRadioSelectorValue != 2 && newRadioSelectorValue != 4 && newRadioSelectorValue != 5) ?
                                    <Grid className="export-button">
                                        {/* <Tooltip title={'Download to PDF'} className='pointer'>
                                            <img
                                                src={downloadPdfIcon}
                                                className='download-icon mr-5'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    // printDocument();
                                                }}
                                            />
                                        </Tooltip> */}
                                        {/* <Tooltip title={'Download to Excel'}>
                                            <img
                                                src={downloadExcelIcon}
                                                height='35'
                                                className='download-icon'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.handleDownloadExcel();
                                                }}
                                            />
                                        </Tooltip> */}
                                    </Grid>
                                    : ''
                            }
                        </div>
                        {isDataBrowser == "true" && <FormControl component="fieldset">
                            <RadioGroup name="radio" value={dataBrowserSelectorValue} onChange={this.handleDataBrowserSelectorValue} className='radioGroupHorizontal mb-20'>
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="XPNA" />
                                {/* <FormControlLabel value="1" control={<Radio color="primary" />} label="View Improvement Plan" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="Variance Analysis" />
                                <FormControlLabel value="3" control={<Radio color="primary" />} label="FCF Improvement Analysis" /> */}

                            </RadioGroup>
                        </FormControl>}
                        {newRadioSelectorValue == 5 && <FormControl component="fieldset" className='background-class'>
                            <RadioGroup name="radio" value={performanceSelectorValue} onChange={this.handlePerformanceChange} className='radioGroupHorizontal mb-20'>
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Monthly Change" />
                                <FormControlLabel value="3" control={<Radio color="primary" />} label="Compare All Benchmarks" />
                            </RadioGroup>
                        </FormControl>}
                        {newRadioSelectorValue == 6 && <FormControl component="fieldset" className='background-class'>
                            <RadioGroup name="radio" value={fcfImprovementSelectorValue} onChange={this.handleFCFImprovementChange} className='radioGroupHorizontal mb-20'>
                                <FormControlLabel value="0" control={<Radio color="primary" />} label="Monthly Performance" />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Cumulative Performance" />
                            </RadioGroup>
                        </FormControl>}
                    </React.Fragment>}

                    {newRadioSelectorValue == 10 &&
                        <ReportComponent />
                    }
                {(newRadioSelectorValue == 1 || newRadioSelectorValue == 8) && 
                <GapToTarget 
                fetchFCFImpactAnalysis={fetchFCFImpactAnalysis}
                setFCFParams={this.setFCFParams}
                menuData={menuData}
                usersList = {users}
                newRadioSelectorValue={newRadioSelectorValue}
                handleCollaborateClick={this.handleCollaborateClick}
                handleImprovementPlanClick={this.handleImprovementPlanClick}
                handleDownloadExcel={this.handleDownloadExcel}
                />}
                {newRadioSelectorValue == 2 && <ImprovementPlan menuData={menuData}  prefilledPlanValue={prefilledPlanValue}/>}
                {newRadioSelectorValue == 3 && <CadenceBoard menuData={menuData} fetchCadenceData={fetchCadenceBoardData}/>}
                {newRadioSelectorValue == 4 && <ImprovementAnalysis menuData={menuData} />}
                {newRadioSelectorValue == 9 && <AnalyticStudio menuData={menuData} shareChart={this.props.shareChart} fetchMetaDataAnalysis = {fetchMetaDataAnalysis} fetchDataAnalysis = {fetchDataAnalysis} users={users} saveData = {this.handleSaveDataAnalyticStudio} deleteSavedData={this.handleDeleteSavedDataAnalyticStudio}/>}
                {newRadioSelectorValue == 6 && fcfImprovementSelectorValue == 0 && <MonthlyPerformance menuData={menuData} />}
                {newRadioSelectorValue == 5 && performanceSelectorValue == 1 && <MonthlyChange menuData={menuData}/>}
                {newRadioSelectorValue == 6 && fcfImprovementSelectorValue == 1 && <CumulativePerformance menuData={menuData}/>}
                {newRadioSelectorValue == 5 && performanceSelectorValue == 3 && <CompareAllBenchmarks menuData={menuData}/>}
                {
                    isDataBrowser == "true" && (dataBrowserSelectorValue == 0 || dataBrowserSelectorValue == 2) && (
                        <iframe
                            style={{ display: 'block', width: '100%', minHeight: 500 }}
                            src={this.iframeUrl[dataBrowserSelectorValue]}
                            title="Data Browser - ECFM"
                        />
                    )
                }
                {
                    isDataBrowser == "true" && (dataBrowserSelectorValue == 1 || dataBrowserSelectorValue == 3)&&(
                        <div>
                            <div style={{margin: '20px 0'}}>
                                <iframe
                                    style={{ display: 'block', width: '100%', minHeight: 500 }}
                                    src={this.iframeUrl[dataBrowserSelectorValue][0]}
                                    title="Data Browser - ECFM"
                                />
                            </div>

                            <div style={{margin: '20px 0'}}>
                                <iframe
                                    style={{ display: 'block', width: '100%', minHeight: 500 }}
                                    src={this.iframeUrl[dataBrowserSelectorValue][1]}
                                    title="Data Browser - ECFM"
                                />
                            </div>

                            <div style={{margin: '20px 0'}}>
                                <iframe
                                    style={{ display: 'block', width: '100%', minHeight: 500 }}
                                    src={this.iframeUrl[dataBrowserSelectorValue][2]}
                                    title="Data Browser - ECFM"
                                />
                            </div>

                            {dataBrowserSelectorValue == 3 && <div style={{margin: '20px 0'}}>
                                <iframe
                                    style={{ display: 'block', width: '100%', minHeight: 500 }}
                                    src={this.iframeUrl[dataBrowserSelectorValue][3]}
                                    title="Data Browser - ECFM"
                                />
                            </div>
                            }


                        </div>


                    )
                }
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            authReducer: state.authReducer
        }
    },
    dispatch => {
        return {
            fetchFCFImpactAnalysis(data) {
                dispatch({
                    type: 'FETCH_FCF_IMPACT_ANALYSIS',
                    payload: data,
                });
            },
            fetchMetaDataAnalysis(data) {
                dispatch({
                    type: 'FETCH_META_DATA_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            fetchDataAnalysis(data) {
                dispatch({
                    type: 'FETCH_DATA_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            fetchCadenceBoardData(data){
                dispatch({
                    type: 'FETCH_CADENCE_BOARD_DATA',
                    payload: data,
                });
            },
            getAllUser(data) {
                dispatch({
                    type: "FETCH_ALL_USER",
                    payload: data,
                });
            },
            
            getSaveData(data) {
                dispatch({
                    type: 'GET_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            saveData(data) {
                dispatch({
                    type: 'SAVE_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            shareChart(data) {
                dispatch({
                    type: 'SHARE_CHART_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
            deleteSavedData(data) {
                dispatch({
                    type: 'DELETE_ANALYTIC_STUDIO',
                    payload: data,
                });
            },
        };
    }
)(NewComponent))
    ;
