import React from 'react';
import ReactTable from "react-table-6";
import withFixedColumns from 'react-table-hoc-fixed-columns';


const ReactTableFixedColumns = withFixedColumns(ReactTable);

 class ReactTableComponent extends React.Component {

    shouldComponentUpdate(nextProps, ){

        // console.log(this.props, 'pppppkkkkkkkk', nextProps)
        // if (nextProps.showLoader){
        //     return false
        // }

        if (nextProps.loading || this.props.loading){
            return true
        }

        return false
    }

    render() {

        const {pivotRowData, loading, pivotHeaderData, tableLen, subComponent, columnHeaderClick, rowClick, className  } = this.props;


        return (
            <ReactTableFixedColumns
            data={pivotRowData}
            className={className}
            loading={loading}
            columns={pivotHeaderData}
            defaultPageSize={6}
            pageSize={tableLen}
            SubComponent={subComponent}
            showPagination={false}
            sortable={false}
            getTheadThProps={columnHeaderClick}
            getTdProps={rowClick}
            // style={{
            //     height: "465px"
            // }}
    
        />
        )

    }
  }

export default ReactTableComponent;