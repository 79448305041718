const timePad = (t) => {
    return String(t).padStart(2).replace(" ", 0);
}

export const formatDate = (da) => {
    if (da) {
        var d = new Date(da);
        return timePad(d.getDate()) + "/" + timePad(d.getMonth() + 1) + "/" + d.getFullYear() +
            " " + timePad(d.getHours()) + ":" + timePad(d.getMinutes()) + ":" + timePad(d.getSeconds());
    }
    return "";
}

export const excelDateToJSDate = (serial) => {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}