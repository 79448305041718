import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleTableTransform, handleTableTransformV2, numberWithCommas } from '../../../../utils/numberUtils'
import { checkToOpenInvoiceDetail } from '../utils'
import InvoiceDetailModal from "../Shared/InvoiceDetail";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
const ReactTableFixedColumns = withFixedColumns(ReactTable);
export class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.sortList = {}
        this.headerColorArr = ['white', '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00', '#d00909', 'white', '#ff1e1e', 'white']
        this.state = {
            showActionLoading: false,
            seriesData: [],
            loading: true,
            showInvoiceDetailModal: false,
            revenueAnalysisData: [],
            costAnalysisData: [],
            drillDownDataTitle: '',
            xAxisLabel: '',
            yAxisLabel: '',
            invoicePayload: null,
            lastItem:{},
            radioValue : "Summary",
            headerTable : [{key: "business_unit", label: "Business Unit", operation: 0},{key: "liabillity", label: "Contract Liability", operation: 7}]
        }
    }

    componentDidMount() {
        this.setState({showActionLoading: true});
       
        // let payload = {
        //     report_date : this.props.selectedMonthFilter,
        // }
        // console.log(this.props.selectedMonthFilter, "cek123123")
        // this.props.getNewComponent(payload)
    }

    businessUnitClick = (data, type, rowData) => {
        // const { summaryState } = this.props;
        // var headerData = []
        // if (summaryState.internalData &&
        //     summaryState.internalData.row_header &&
        //     summaryState.internalData.row_header.length
        // ) {
        //     var _data = [...[], ...summaryState.internalData.row_header]
        //     headerData = _data.filter(item => {
        //         if (!(item.key == "business_unit" || item.key == "grand_total")) {
        //             return item
        //         }
        //     })
        // }
        // headerData = this.changeKeysOfHeader(headerData)
        // this.props.summaryBUClick(data.toLowerCase(), type, headerData, rowData)
        this.setState({
            radioValue: data,
          })
    }
    componentDidUpdate(prevProps) {
        const { summaryState, dashboardState} = this.props;
        if (prevProps.dashboardState.loading &&
            !dashboardState.loading) {
          this.setState({
            showActionLoading: false,
          });
        }
    }
   
    appendHyperLinkRowData = (item, type, props) => {
        if (props.original['business_unit'] != 'PAB') {
            return (
                <span>
                    {props.original['business_unit']}
                </span>
            )
        }else{
            return (
                <span
                    className='pointer primary-dark'
                    onClick={() => { this.businessUnitClick(props.original[item.key], type, props.original) }}>
                    <u>{props.original['business_unit']}</u>
                </span>)
        }
// console.log(props.original, "cekLabel")
       

    }
    modifyColumnData = (columnData, operations) => {
        let modifiedData;
        modifiedData = Math.round(columnData)
        var isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        modifiedData = modifiedData == '0' ? '-' : modifiedData

        if (operations == "percentage") {
            modifiedData += "%"
        }

        return modifiedData
    }
    handleAppendPercentage = (data) => {
        if (data && data['data_field']) {
            let dataField = data['data_field'];
            if (dataField.includes('%')) {
                return true;
            }
        }
        return false
    }
    changeKeysOfHeader2 = (data, isNested) => {
        if (data && data.length) {
            return data.map((item, i) => {
                console.log(item.key, isNested, "cekKeyss")
                item['operation'] = item.key == 'data_field' || item.key == 'data_group' || item.key == 'data_company_code' || item.key == 'data_ra' || item.key == 'so_item'? '' : 7;
                if (item.key == 'data_group') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: item.label,
                            id: item.key,
                        fixed: 'left',
                        className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_group' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Grand Total" ? 'totalLabel' : ''

                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName } >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                } else if (item.key == 'data_field') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            // Header: item.label,
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_field' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }                   
                        }]
                    }
                }  else if (item.key == 'so_item') {
                    return {
                        Header: <div style={{ textAlign: 'center' }}>{item.label}</div>,
                        id: item.key,
                        metaData: item.metadata,
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            textAlign:  "center"
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }else if (item.key == 'data_company_code') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_company_code' ? '' : 7;
                                let thisClassName = props.original[item.key] == "Total" ? 'totalLabel' : ''
                                return (
                                    <React.Fragment>
                                        {
                                            <span className={ thisClassName }  >{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }  else if (item.key == 'data_ra') {
                    return {
                        Header: item.label,
                        id: item.key,
                        fixed: 'left',
                        metaData: item.metadata,
                        columns: [{
                            Header: "",
                        fixed: 'left',
                        id: item.key,
                            className: isNested ? '' : 'noNesting',
                            Cell: (props) => {
                                item['operation'] = item.key == 'data_ra' ? '' : 7;

                                return (
                                    <React.Fragment>
                                        {
                                            <span>{props.original ? props.original[item.key] : ''}</span>
                                        }
                                    </React.Fragment>
                                )
                            }
                        }]
                    }
                }else if (item.key == 'company_code' ||item.key == 'country'||item.key == 'classification' || item.key == 'source'|| item.key == 'summary_acct' || item.key == 'summary_acct_desc'|| item.key == 'currency_code' ) {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                      
                        Cell: (props) => {
                            
                            return (
                                <div className='text-center'>
                                    {
                                        <span>{props.original[item.key]}</span>
                                    }
                                </div>
                            )
                        },
                    }
                } else if (item.key == 'grand_total') {
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#b1bace' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            let showPercentage = this.handleAppendPercentage(props.original);
                            let myClassName = props.original.data_field == "Total Receivables" ? 'percentageRowData' : ''
                            return (
                                <div className='text-center'>
                                    {
                                        <span className={myClassName}>{props.original ? this.modifyColumnData(props.original[item.key], props.original["operations"]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        headerStyle: {
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }
                    }
                }   else if (item.key == 'tran_amount' || item.key == 'base_amount' || item.key == 'base_amount_aop'   || item.key == 'tran_amount_aop') {
                    var isManualKey = true;
                    // console.log(item.sub_headers, item, "test123123")
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#b1bace' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                           
                            item['operation'] = 7;
                            if(props.original.summary_acct == "Total"){
                                isManualKey = false 
                            }
                            console.log(props.original.summary_acct, isManualKey, "chek123321")
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => { this.handleDGSAutomationClick(props.original, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:  isManualKey == true ? 'pointer' : ''
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }
                else if (!item.sub_headers) {
                    var isManualKey = false;
                   
                    return {
                        Header: item.label,
                        id: item.key,
                        metaData: item.metadata,
                        getProps: (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.original && rowInfo.original.budget_group === 'Sub Total') {
                                return { style: { background: '#b1bace' } }
                            }
                            return {}
                        },
                        Cell: (props) => {
                            if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 2 && props.original.disable_drill_down == false) {
                                console.log("masuksinigaes1234")
                                isManualKey= true
                            }
                            if (this.props.NewChart == true){
                                isManualKey= true
                            }
                          
                            return (
                                <div className={`${isManualKey == true  ? 'text-center pointer': 'text-center'}`}  onClick={(event) => {   this.handleInvoiceClick(props.original, item, item)}}>
                                    {
                                        <span className={`${isManualKey == true  ? 'enableDrillDown': ''}`}>{props.original ? this.modifyColumnData(props.original[item.key]) : '-'}</span>
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                            cursor:  isManualKey == true ? 'pointer' : ''
                            // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                        },
                    }
                }

                const { isDetailedView } = this.state;
                if (!isDetailedView) {
                return {
                    
                    Header:  <Tooltip title={this.handleHeaderTooltip()}>
                         <span onClick={() => {
                                if (this.props.radioValue == "region"){
                                    this.setState({isDetailedView : true})
                                    this.props.handleRegionHeaderClick(item)

                                }else if(this.props.radioValue == "hemisphere"){
                                    this.setState({lastItem : item})
                                    this.props.handleHemisphereHeaderClick(item)

                                }
                               

                          

                    }
                    }
                    
                    >
                       
                          {item.label}
                        </span></Tooltip>,
                    id: item.key,
                    columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
                    style: {
                        borderRight: '1px solid #423970',
                        cursor: this.showCursorPointerForHeader()
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight:'1px solid #423970'
                    }
                }
            }else{
                return {
                    Header: item.label,
                        id: item.key,
                        columns: this.handleSubColumnChangeHeader(item.sub_headers, item),
                        style: {
                            borderRight: '1px solid #423970',
                            cursor: ""
                        },
                        headerStyle: {
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            borderRight:'1px solid #423970'
                        }
                }
            }
            })
        }
        return []
    }

    handleBackClick = () => {
        this.setState({
            isDetailedView: false,
        }, () => {
            this.props.handleHemisphereHeaderClick(this.state.lastItem)
        })
    }
    showCursorPointerForHeader = () => {
        if (this.props.tabSelectorValue != 3 && (this.props.radioSelectorValue == 3 || this.props.radioSelectorValue == 5)){
            return 'pointer'

        }

        return ''
    }

    handleSubHeaderTooltip = (data) => {
        console.log( this.props.radioArrSelected , this.props.radioArrCountySelected, "CEKDefault")
              if(this.props.radioSelectorValue != 0 && this.props.radioSelectorValue != 2 && data.key != "sub_total" &&  this.props.radioArrSelected == "Default"  && this.props.radioArrCountrySelected == "Default"){
  
                  return 'Click here for detail'
  
              }
  
        
  
  
          return ''
      }
    handleSubColumnChangeHeader = (data, parentData) => {
        console.log(data, parentData, "testHere123")
        if (data && data.length) {
            return data.map((item, i) => {
                var isManualKey = false;
                return {
                    Header: <Tooltip title={this.handleSubHeaderTooltip(item)}>
                    <span onClick={() => {
                        if(item.key != "sub_total" && this.props.radioSelectorValue != 0 && this.props.radioSelectorValue != 2 && this.props.radioArrSelected == "Default"  && this.props.radioArrCountrySelected == "Default"){
                            // console.log(item, "checkSubHeaderClick2")
                               
                                this.setState({
                                    isExpandable : false,
                                }, () => {
                                    this.props.handleSubHeaderClick(item, parentData)

                                    })
                         
                       }
                    }
               }
               
               >
                  
                     {item.label}
                   </span></Tooltip>,
                    id: item.key,
                    Cell: (props) => {
                        let showPercentage = this.handleAppendPercentage(props.original);
                        console.log(props.original.data_field, item.key, parentData.key, "cekData")
                        let myClassName = props.original.data_field == "Total Receivables" ? 'percentageRowData' : ''
                        // let disableDrillDown = props.original["disable_drill_down"]
                        // myClassName += disableDrillDown || (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue != 7 ) || item.key == 'dass' ||  item.key == 'sub_total' || item.key == 'total' || item.key == 'grand_total' ? '' : ' enableDrillDown'
                        
                        // if (this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'bu_p&l' && props.original.data_field != "Gross Margin") {
                        //     isManualKey= true
                        //     // console.log(props.original, props.original.isManual, "masuk sini")
                        // }else if(this.props.tabSelectorValue == 3 && this.props.radioSelectorReconValue == 0 && item.key == 'new' && props.original.data_field != "Gross Margin"){
                        //     isManualKey= true
                        // }else if(this.props.tabSelectorValue == 5 ){
                        //     // console.log("testHere123")
                        //     isManualKey= true
                        // }else if(this.props.radioSelectorReconValue == 5 ){
                        //     // console.log("testHere123")
                        //     isManualKey= true
                        // }else if(this.props.radioSelectorReconValue == 6 ){
                        //     // console.log("testHere123")
                        //     isManualKey= true
                        // }
                        // else if(this.props.radioSelectorValuePlant == "MSM" && item.key == 'sub_total'){
                        //     console.log("testHere123")
                        //     myClassName += ' enableDrillDown'
                        //     isManualKey= true
                        // }
                        // else{
                        //     isManualKey = props.original && props.original.isManual;
                        // }
                                 
                        let classNames = ""
                        // if(isManualKey == true){
                        //     classNames = 'text-center pointer'
                        // }else{
                            classNames = 'text-center'
                        // }
                        isManualKey= false
                        
                        // console.log(props, classNames, "Testitem")
                        return (
                            <div className={classNames} >
                                {
                                    <span className={myClassName}>{props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? this.modifyColumnData(props.original.sub_column[parentData.key][item.key], props.original.sub_column[parentData.key]["operations"]) : '-'}</span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                        cursor: isManualKey && isManualKey == true ? 'pointer' : ''
                        // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                    }
                }
            })
        }
    }
    handleHeaderTooltip = () => {
     
                return 'Click here for the region view'

          


    }
    changeKeysOfHeader = (data) => {
        if (data && data.length) {
            let type = "liability"
            return data.map((item, i) => {
                console.log(item, "cekITEM")
                    return {
                        Header: item.label,
                        id: item.key,
                        Cell: (props) => {
                            return (
                                <div className='text-center'>
                                    { 
                                    
                                    // props.original[item.key] == "Grand Total" ?
                                    //     <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                    // <span>{handleTableTransformV2(props.original, item)}</span>
                                        // <span><b>{props.original[item.key]}</b></span> 
                                        item.key == 'business_unit' ?
                                        props.original[item.key] == "Grand Total" ?
                                            <span><b>{props.original[item.key]}</b></span> :
                                            <>{this.appendHyperLinkRowData(item, type, props)}</> :
                                        <span>{handleTableTransformV2(props.original, item)}</span>    
                                    }
                                </div>
                            )
                        },
                        style: {
                            fontSize: '12px',
                        },
                        headerStyle: {
                            whiteSpace: 'break-spaces',
                            overflow: 'visible',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            backgroundColor: '#b1bace',
                            flex:''
                            // backgroundColor: this.headerColorArr[i]
                        }
                    }
               
                
            })
        }
        return []
    }

    handleColumnClick = (state, colInfo, columnDetail, etc) => {
        console.log(colInfo,'>>>>',columnDetail)
        const { business_unit, parent_group } = colInfo.original;
        const payload = {
            aging_category: columnDetail.id,
            parent_group,
            bu: business_unit
        }
        // if (columnDetail.id != 'grand_total' && business_unit != "Grand Total") {
        //     if (checkToOpenInvoiceDetail(columnDetail.id)) {
        //         this.props.getInvoiceDetail(payload);
        //         this.setState({
        //             showInvoiceDetailModal: true,
        //             invoicePayload: payload

        //         })
        //     }
        // }


    }

    columnClickFunction = (state, colInfo, abc, etc) => {
        if (colInfo) {
            return {
                onClick: (e) => {
                    this.handleColumnClick(state, colInfo, abc, etc)
                }
            }
        } else {
            return {}
        }
    }

    invoiceModalCallBack = () => {
        this.setState({ showInvoiceDetailModal: false });
    }

    changeValuesOfData = (data, type) => {
        if (data && data.length) {
            return data.map(item => {
                item['parent_group'] = type;
                return item
            })
        }
        return []
    }


    handleInvoiceSorting = (columnId) => {
        const { invoicePayload } = this.state;
        this.sortList[columnId] = this.sortList[columnId] != undefined ? !this.sortList[columnId] : false

        let payload = {
            ...invoicePayload
        }
        payload['sort'] = {
            key: columnId,
            desc: this.sortList[columnId]
        }
        this.props.getInvoiceDetail(payload)
    }
    handleData = (data) => {
        let arr = []
        // console.log(data, "checkState123")
        data.map(item => {
            // console.log(item.category, "checkState123")
            if(item.category == "DSO"){
                arr.push(item)
            }
        })
        arr.sort((x, y) => {
            let a = new Date(x.date)
            let b = new Date(y.date)
            return b.getTime() - a.getTime()
          })
        return arr
    }
    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.business_unit == 'Grand Total' ? '#b1bace' : ''
            }
          }
        }
        return {};
    }
     handleApplyFilterFunc = (payload) => {
        const { radioValue } = this.state;
        payload['bu'] = radioValue;
        payload['radioValue'] = radioValue;
        // if (props.receivableTabSelectorValue == 1) {
        //   payload.bu = reverseNewBUnitIndex[externalTabValue]
    
        // }
        this.props.handleApplyFilter(payload)
    
      }
      handleRadioChange = (event) => {
        this.setState({
            radioValue: event.target.value,
          })
      }
      checkForNestedLevel = (data) => {
        if (data && data.length) {
            let isFlag = false;
            data.forEach(item => {
                if (item.sub_rows && item.sub_rows.length) {
                    isFlag = true
                }
            })
            return isFlag
        }
        return false
    }
    render() {
        let { summaryState, invoiceDetailState } = this.props;
        const { showInvoiceDetailModal, headerTable, radioValue, isDetailedView } = this.state;
        let pivotRowData = summaryState.externalData.row_data
        let nestedFlag = this.checkForNestedLevel(pivotRowData)
        let externalDataColumns = this.changeKeysOfHeader2(summaryState.externalData.row_header, nestedFlag)
        console.log(externalDataColumns, "checkState123")
        // let externalDataList = summaryState.externalData.data && summaryState.externalData.row_date ? this.handleData(summaryState.externalData.row_date) : []
        
        let externalDataList = pivotRowData
        // console.log(summaryState.externalData, externalDataColumns, "checkState123")
        return (
            <>
         
           
                { summaryState.loading ?
                    <div className='progressfullpage'><CircularProgress /></div> : 
                    <>{isDetailedView ?
                    
                    <div className='rowDetails flex ai-center mb-10'>
                        <Button onClick={this.handleBackClick} color="primary" startIcon={<ChevronLeft />}>back</Button>

                        <div>
                            <h3 className="country-view-info">Country View</h3>
                        </div>
                    </div> : ""}
                 
                    <Grid item xs={12}>
                         {/* <ReactTable
                            data={externalDataList}
                            columns={externalDataColumns}
                            defaultPageSize={externalDataList.length}
                            loading={summaryState.loading}
                            className="-striped -highlight"
                            sortable={false}
                            pageSizeOptions={[2, 4, 6]}
                            showPagination={false}
                            getTrProps={this.getTrProps}
                        /> */}
                        <ReactTableFixedColumns
                           data={externalDataList}
                            className={'newCustomTable -striped -highlight twoColumFixedTable'}
                            loading={summaryState.loading}
                            columns={externalDataColumns}
                            defaultPageSize={externalDataList.length}
                            SubComponent={null}
                            showPagination={false}
                            sortable={false}
                        />
                    {/* <ReactTable
                        data={externalDataList}
                        columns={externalDataColumns}
                        defaultPageSize={externalDataList.length}
                        className="-striped -highlight"
                        PaginationComponent={Pagination}
                        pageSizeOptions={[10, 20, 30]}
                        resizable={true}
                    /> */}
                </Grid>
               
                </>
                        // <ReactTable
                        //     // TheadComponent={props => null}
                        //     data={externalDataList}
                        //     defaultPageSize={6}
                        //     columns={externalDataColumns}
                        //     className="-striped -highlight"
                        //     pageSizeOptions={[10, 20, 30]}
                        //     style={{ height: '465px', }}
                        //     showPagination={false}
                        // />
                       
                      
                }
            
               
                {
                    <InvoiceDetailModal
                        showModal={showInvoiceDetailModal}
                        modalCallBack={this.invoiceModalCallBack}
                        invoiceDetailState={invoiceDetailState}
                        handleInvoiceSorting={this.handleInvoiceSorting}
                    />
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                summaryState: state.dashboardState.receivableData.ageingData.summaryMonthly,
                invoiceDetailState: state.invoiceDetailState,
                dashboardState: state.dashboardState,
            }
        },
        dispatch => {
            return {
                getContractLiability(data) {
                    dispatch({
                      type: 'FETCH_CONTRACT_LIABILITY',
                      payload: data
                    });
                  },
                  getNewComponent(data) {
                    dispatch({
                      type: 'FETCH_New_HEMISPHERE',
                      payload: data
                    });
                  },
                getInvoiceDetail(data) {
                    dispatch({
                        type: 'FETCH_INVOICE_DETAIL',
                        payload: data
                    });
                }
            };
        }
    )(Summary);
