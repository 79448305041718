import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from "../../../constant";

function HighChart(props) {
    const {improvementAnalysis:{ cadenceAnalysis: { b1Color, lob1Color, plant1Color, projectColor }}} = CHART_COLORS;
    return (
        <div className="mat-shadow-2">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                          },
                        xAxis: {
                            categories: ['IDEATION', 'UNDER EXECUTION', 'ACHIEVED', 'CLOSED']
                        },
                        yAxis: {
                            min: 0,
                            tickInterval: 5,
                            title: {
                                text: 'USD Millions'
                            }
                        },
                        legend: {
                            align: 'center',
                            verticalAlign: 'top',
                            shadow: false,
                        },
                        series: [ {
                            name: 'LoB1',
                            data: [5, 3, 11, 2],
                            color: '#E2E552'
                        }, {
                            name: 'Plant1',
                            data: [12, 6, 4, 7],
                            color: plant1Color
                        }, {
                            name: 'Project',
                            data: [4, 12, 8, 13],
                            color: projectColor
                        }]
                    }
                }
            />
        </div>
    )
}
export default HighChart;
