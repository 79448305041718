import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { convertNumber } from '../../../../../utils/numberUtils'

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
    chart: {
        style: {
            fontFamily: 'SCProsperSans'
        }
    }
    //   colors: ['#058DC7', '#DDDF00', '#50B432', '#ED561B', '#8E44AD', '#FF9655', '#FFF263', '#6AF9C4']
})

function ScatterChart(props) {
    // console.log(JSON.stringify(props.chartData));
    var subtitle = '<div class="summary"><span class="bu-keys title">Improvement in last 6 months : </span></div>'
    let subChartLen = props.subChart.length
    let classSummary = subChartLen > 1 ? "list-item" : "" 

    props.subChart.map((value, index) => {
        let modifiedData = Math.round(value.value)
        let isNegative = modifiedData < 0
        var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
        modifiedData = convertNumber(positiveTransformedValue)
        let className = isNegative ? 'text-green' : 'text-red'
        
        subtitle += '<div class="summary '+classSummary+'"><span class="bu-keys">'+value.key+'</span>  <span class='+className+'>'+modifiedData+'</span></div>'
    });

    return (
        <div id="column_chart_overdue" className="px-5">
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'column',
                            height: 400,
                            width: props.width,
                            spacingBottom: 0,
                            options3d: {
                                enabled: true,
                                alpha: 0,
                                beta: 0,
                                depth: 20,
                                viewDistance: 25
                            }
                        },
                        title: {
                            text: props.chartTitle,
                            useHTML: true,
                            align: "left",
                            margin: 25,
                            style: {
                                fontSize: "16px",   
                            }
                        },
                        subtitle: { 
                            useHTML: true,
                            text: '<div class="summary-content">'+subtitle+'</div>',
                        },
                        navigation: {
                            buttonOptions: {
                                verticalAlign: 'top',
                                y: -10
                            }
                        },
                        xAxis: {
                            type: 'category',
                        },
                        yAxis: {
                            title: { text: '$' },
                        },
                        tooltip: {
                            enabled: false,
                            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            pointFormat: '<span style="color:{point.color}" >{point.name}</span>: <b>{point.y:,.0f}</b><br/>',
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    formatter:function(){
                                        return convertNumber(this.y)
                                    }
                                },
                                opacity: 0.9,
                                borderWidth: 0,
                                pointPadding:0.1,
                            },
                        },
                        legend: {
                            squareSymbol: false,
                            symbolHeight: 6,
                            symbolWidth: 12,
                            symbolRadius: 0,
                        },
                        series:props.chartData,
                        credits: {
                            enabled: false
                        },
                    }
                }
            />
        </div>
    )
}
export default ScatterChart;