
const reducer = (state = {
   loading: false,
   tableRowData: [],
   chartRowData: [],
   filter: [],
   totalTableRowCount: 0,
   tableRowHeader: [],
   fcfImprovementChartData: {},
   createChannel: false,
   newRowItem: {},
   yearMonthArr: [],
   cadenceBoardData: {},
   cadenceBoardLoading: false,
   cadenceBoardHistoryData : [],
   cadenceBoardSortedData: {},
   agingCategoryArr: [],
   selectedEmailRowArr: [],
   createPlanFormData: [],
   createPlanFormIdData: '',
   actionOwnerArr: [],
   customerNameArr: [],
   dayFilterArr: [],
   fcfPayloadData:{},
   regionArr: [],
   metaData: [],
   drilldownData: [],
   defaultFilter : {},
   analyticStudio: {
      loading: false,
      loadingShare : false,
      tableRowData: [],
      chartRowData: [],
      defaultSelection: [],
      filter: [],
      totalTableRowCount: 0,
      tableRowHeader: [],
      saveData: [],
   }
}, action) => {
   let newState = JSON.parse(JSON.stringify(state));
   switch (action.type) {

      case 'FETCH_FCF_IMPACT_ANALYSIS_LOADER':
         return { ...state, loading: action.loading };

      case 'FETCH_FCF_IMPACT_DRILLDOWN_DATA_LOADER' :
         return { ...state, loading: action.loading };

      case 'FETCH_FCF_IMPACT_DRILLDOWN_DATA_SUCCESS' :
         newState.drilldownData = action.data
         return newState
      
      case 'FETCH_FCF_FILTER_SUCCESS' :
         // newState.filter = action.data.filter || [];
         newState.defaultFilter = action.data.default_selection || {};
         return newState

      case 'SET_SELECTED_ROW_EMAIL_AUTOMATED_REPORTING':
         newState.selectedEmailRowArr = action.payload
         return newState;

      case 'FETCH_FCF_IMPACT_ANALYSIS_SUCCESS':
         newState.loading = false;
         newState.tableRowData = action.data.row_data || [];
         newState.chartRowData = action.data.chart_data || [];
         newState.filter = action.data.filter || [];
         newState.tableRowHeader = action.data.row_header || [];
         newState.totalTableRowCount = action.data.total_count;

         return newState;

      case 'FETCH_FCF_IMPROVEMENT_SUCCESS':
         newState.loading = false;
         newState.fcfImprovementChartData = action.data.chart_data 

      return newState;
      
      case 'SHARE_CHART_LOADER' :
         newState.analyticStudio.loadingShare = action.loading;
      return newState
    
      case 'FETCH_COMMENT_HISTORY_AUTOMATED_REPORTING_SUCCESS':
         newState.tableRowData = action.data;
         return newState

      case 'FETCH_COMMENT_HISTORY_IMPROVEMENT_PLAN_SUCCESS':
         newState.cadenceBoardData.row_data = action.data;
         return newState

      case 'UPDATE_AUTOMATED_REPORTING_DATA_SET':

         newState.tableRowData = action.payload;
         return newState

      case 'UPDATE_CADENCE_BOARD_DATA_SET':

         newState.cadenceBoardData.row_data = action.payload;
         return newState

      case 'SAVE_PAYLOAD_FCF_IMPACT_ANALYSIS':

         newState.fcfPayloadData = action.data;
         return newState

      case 'FETCH_META_DATA_ANALYTIC_STUDIO_SUCCESS':
         newState.metaData = action.data;
         return newState

      case 'FETCH_CREATE_PLAN_DROPDOWN_SUCCESS':
         newState.createPlanFormData = action.data;
         return newState
      case 'FETCH_IMPROVEMENT_PLAN_HISTORY_LOADER' :
         newState.cadenceBoardHistoryData = [];
      return newState
      case 'FETCH_IMPROVEMENT_PLAN_HISTORY_SUCCESS' :
         newState.cadenceBoardHistoryData = action.data;
         return newState
      case 'FETCH_CREATE_PLAN_ID_SUCCESS':
         newState.createPlanFormIdData = action.data;
         return newState


      case 'FETCH_CADENCE_BOARD_DATA_SUCCESS':
         newState.cadenceBoardData = action.data;
         const lists = ["Ideation", "Feasible", "Under Execution", "Achieved", "Closed"];
         const items = action.data.row_data;
         const sortedData = {}
           lists.forEach(key => {
             sortedData[key] = []
             items.forEach(item => {
               if(item.status == key){
                 sortedData[key].push(item) 
               }
             })
           })
         newState.cadenceBoardSortedData = sortedData
         return newState
      
      case 'SAVE_CADENCE_BOARD_DATA_LOADER':
         newState.cadenceBoardLoading = action.loading
         return newState

      case 'FETCH_DATA_ANALYTIC_STUDIO_LOADER' :
         newState.analyticStudio.loading = action.loading;
         if(action.loading){
            newState.analyticStudio.tableRowData = [];
         }
         return newState

      case 'FETCH_DATA_ANALYTIC_STUDIO_SUCCESS':
         newState.analyticStudio.loading = false;
         newState.analyticStudio.tableRowData = action.data.row_data || [];
         newState.analyticStudio.chartRowData = action.data.chart_data || [];
         newState.analyticStudio.defaultSelection = action.data.default_selection || [];
         newState.analyticStudio.filter = action.data.filter || [];
         newState.analyticStudio.tableRowHeader = action.data.row_header || [];
         newState.analyticStudio.totalTableRowCount = action.data.total_count;
         return newState
      
      case 'GET_ANALYTIC_STUDIO_SUCCESS':
         newState.analyticStudio.saveData = action.data;
         return newState
      
      case 'SAVE_ANALYTIC_STUDIO_SUCCESS':
         // newState.analyticStudio.saveData = action.data;
         return newState
         
      case 'DELETE_ANALYTIC_STUDIO_SUCCESS':
         // newState.analyticStudio.saveData = action.data;
         return newState

      default:
         return newState;
   }
};
export default reducer;







