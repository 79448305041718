import React from 'react';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { AppBar, Box, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TabContainer from './tab';
import MenuContainer from './menu';
import SavedDataTable from './table';
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popper from '@material-ui/core/Popper';
import { toastr } from "react-redux-toastr";
import SimpleModalWrapped from './Modal/modalSave';
import SimpleModalShare from './Modal/modalShare';



// TAB PROPERTIES
function TabPanel(props) {

    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
// TAB PROPERTIES


export default function AnalyticStudio(props) {
    const users = props.users;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModalShareOpen, setIsModalShareOpen] = React.useState(false);
    const [isTableOpen, setIsTableOpen] = React.useState(true);
    const [isLoadingShare, setIsLoadingShare] = React.useState(false);
    const ecfmState = useSelector(state => state.ecfmState);
    const metaDataArray = ecfmState.metaData;
    const filterArray = ecfmState.analyticStudio.filter;
    const defaultSelection = ecfmState.analyticStudio.defaultSelection;
    const analyticStudioData = ecfmState.analyticStudio;
    const savedData = ecfmState.analyticStudio.saveData;
    // const deleteSavedData = ecfmState.analyticStudio.deleteSavedData;

    const [clearFilterValue, setClearFilterValue] = React.useState(0);
    const [unselectParamsArr, setUnselectParamsArr] = React.useState([]);
    const [dimensionArr, setDimensionArr] = React.useState([]);
    const [measurementArr, setMeasurementArr] = React.useState([]);
    const [fieldTitleArr, setFieldTitleArr] = React.useState([]);

    const [isClicked, setIsClicked] = React.useState(false);

    const [dimensionFilterOne, setDimensionFilterOne] = React.useState('');
    const [dimensionFilterTwo, setDimensionFilterTwo] = React.useState('');
    const [measurementFilterOne, setMeasurementFilterOne] = React.useState('');
    const [measurementFilterTwo, setMeasurementFilterTwo] = React.useState('');

    const [modalNameValue, setModalNameValue] = React.useState('');
    const [modalIDValue, setModalIDValue] = React.useState('');
    const [modalUserList, setModalUserList] = React.useState([]);
    const [currentTabData, setCurrentTabData] = React.useState([]);

    const [modalShareToValue, setModalShareToValue] = React.useState([]);
    const [modalShareCcValue, setModalShareCcValue] = React.useState([]);



    // TAB PROPERTIES
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(activeTabs[newValue])
        setValue(newValue);

    };
    let chartComponent = {}

    // ADDITIONAL
    let tabsContentArray = [
        {
            id: 1,
            label: "Tab 1",
            component: <MenuContainer />,
            closeable: true,
            isNew: true,
            dimensionOne: defaultSelection.dimensions,
            dimensionTwo: '',
            measurementOne: defaultSelection.measurements,
            measurementTwo: '',
            filterArray: filterArray,
            dataArray : analyticStudioData,
            selectedFilter: {
                monthByValue : '',
                quarterByValue :  '',
                yearByValue :  '',
                kpiCategoryByValue :  '',
                kpiTypeByValue :  '',
                kpiByValue :  ''
            },
            tabChartType: '',
        }
    ] 

    const [activeTabs, setActiveTabs] = React.useState(tabsContentArray);
    const [activeTab, setActiveTab] = React.useState(tabsContentArray[0]);

    const handleClose = React.useCallback(tabToDelete => {
        const tabToDeleteIndex = activeTabs.findIndex(
            tab => tab.id === tabToDelete.id
        );
        const updatedTabs = activeTabs.filter((tab, index) => {
            return index !== tabToDeleteIndex;
        });
        const previousTab = activeTabs[tabToDeleteIndex - 1] || activeTabs[tabToDeleteIndex + 1] ||  {};
            setActiveTabs(updatedTabs);
            setActiveTab(previousTab.id);
        },
        [activeTabs])
    
    const handleAddTab = React.useCallback(addTab => {
        const temp_activeTabs = activeTabs
        const index = temp_activeTabs[temp_activeTabs.length-1].id+1;
        const newTab = {
            id: index,
            label: "Tab " + index,
            component: <MenuContainer />,
            closeable: true,
            isNew: true,
            dimensionOne: defaultSelection.dimensions,
            dimensionTwo: '',
            measurementOne: defaultSelection.measurements,
            measurementTwo: '',
            filterArray: filterArray,
            dataArray : analyticStudioData,
            selectedFilter: {
                monthByValue : '',
                quarterByValue :  '',
                yearByValue :  '',
                kpiCategoryByValue :  '',
                kpiTypeByValue :  '',
                kpiByValue :  ''
            },
            tabChartType: '',
        }

        temp_activeTabs.push(newTab)
        setActiveTabs(temp_activeTabs)
        setActiveTab(newTab)
    })

    const handleSetFilterByTabId = (id, param) => {
        const temp_activeTabData = [...[], ...activeTabs];
        let objIndex = temp_activeTabData.findIndex((obj => obj.id == id));
        temp_activeTabData[objIndex].selectedFilter = {
            monthByValue :  param.monthByValue,
            quarterByValue :  param.quarterByValue,
            yearByValue :  param.yearByValue,
            kpiCategoryByValue :  param.kpiCategoryByValue,
            kpiTypeByValue :  param.kpiTypeByValue,
            kpiByValue :  param.kpiByValue
        }
        setActiveTabs(temp_activeTabData)
        setActiveTab(temp_activeTabData[objIndex])
        // console.log(activeTabs , param)
    }

    const handleCreateChart = (id, chartName, tabsData) => {
        if(tabsData == undefined || tabsData.length == 0){
            tabsData = activeTabs
        }
       
        let objIndex = tabsData.findIndex((obj => obj.id == id));
        tabsData[objIndex].isNew = false;
        tabsData[objIndex].tabChartType = chartName;

        let payload = createPayload(tabsData[objIndex])
      
        handleSetDataAnalyticArr(id,payload, tabsData)

    }

    const createPayload = (data) =>{
        const dimension = []
        const measurement = []
        dimension.push(data.dimensionOne)
        dimension.push(data.dimensionTwo)

        measurement.push(data.measurementOne)
        measurement.push(data.measurementTwo)

        // console.log( data, "-- gokil 2")

        const payload = {
            dimensions: dimension,
            measurements: measurement,
            period: "fy",
            kpi_category: data.selectedFilter.kpiCategoryByValue,
            kpi_type: data.selectedFilter.kpiTypeByValue,
            kpi: data.selectedFilter.kpiByValue,
            yearmonth : data.selectedFilter.monthByValue,
            quarter : data.selectedFilter.quarterByValue,
            year_label : data.selectedFilter.yearByValue,
          }

          return payload
    }

    if(currentTabData.length != ecfmState.analyticStudio.tableRowData.length){
        // console.log(currentTabData, ecfmState.analyticStudio.tableRowData.length, "-- gokil 1")
        setCurrentTabData(ecfmState.analyticStudio.tableRowData)
    }

    React.useEffect(() => {
        bindDataToCurrentTab();
    },[currentTabData]);

    const handleSetDataAnalyticArr = (tabId, payload, tabsData) =>{
        if(tabsData == undefined || tabsData.length == 0){
            tabsData = activeTabs
        }

        let objIndex = tabsData.findIndex((obj => obj.id == tabId));
        setActiveTabs(tabsData); 
        setActiveTab(tabsData[objIndex]);

        props.fetchDataAnalysis(payload)
      
    }

    const bindDataToCurrentTab = () =>{
        let objIndex = activeTabs.findIndex((obj => obj.id == activeTab.id));
        activeTabs[objIndex].dataArray = ecfmState.analyticStudio;

        setActiveTabs(activeTabs); 
        setActiveTab(activeTabs[objIndex]);
    }

    const getDefaultFilterTabData = (id) => {
         let filterDataById =  activeTabs.find((element) => {
            return element.id === id;
         });
         return filterDataById.filterArray         
    }

    const getSelectedFilterActiveTab = (id) =>{
        let filterDataById =  activeTabs.find((element) => {
            return element.id === id;
         });
        return filterDataById.selectedFilter; 
    }

    const getDataActiveTab = (id) =>{
        let dataActiveTab = activeTabs.find((element) => {
            return element.id === id;
         });
        return dataActiveTab.dataArray;
    }

    const getDimensionOne = (id) =>{
         let filterDataById = activeTabs.find((element) => {
            return element.id === id;
         });

        return filterDataById.dimensionOne
    }

    const getDimensionTwo = (id) =>{
         let filterDataById = activeTabs.find((element) => {
            return element.id === id;
         });

        return filterDataById.dimensionTwo
    }

    const getMeasurementOne= (id) =>{
         let filterDataById = activeTabs.find((element) => {
            return element.id === id;
         });

        return filterDataById.measurementOne
    }

    const getMeasurementTwo= (id) =>{
         let filterDataById = activeTabs.find((element) => {
            return element.id === id;
         });

        return filterDataById.measurementTwo
    }


    //  TAB PROPERTIES

    const clearFilter = () => {
        cleanFilter();
        setClearFilterValue(clearFilterValue + 1)
    }

    const cleanFilter = () => {
        fillArray()
    }

    const fetchMetaData = () => {
        cleanFilter()
    }

    const fetchImpactAnalysis = () => {
        cleanFilter()
    }

    React.useEffect(() => {
        fetchImpactAnalysis()
        fetchMetaData()
        userUpdate()
    }, [clearFilterValue])

    const fillArray = () => {
        metaDataArray.forEach( data => {
            const dataType = data["_id"].type
            const dataValue = data["details"]
            if (dataType == "dimension") {
                setDimensionArr(dataValue)
            } else if (dataType == "measurement") {
                setMeasurementArr(dataValue)

            }
        } )

        setDimensionFilterOne(defaultSelection.dimensions);
        setMeasurementFilterOne(defaultSelection.measurements);
    }

    const onDragEnd = (result) => {

        const temp_activeTabs = [...[], ...activeTabs];
        let objIndex = temp_activeTabs.findIndex((obj => obj.id == activeTab.id));
        
        // TODO: Reorder Our Column
        const { destination, source, draggableId } = result

        if (!destination) {
            return
        }
      
        if (destination.droppableId === source.droppableId) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        const draggableIdValue = draggableId.includes('-selected') ? draggableId.replace('-selected', '') : draggableId

        let isMoved = false;
        
        switch(destination.droppableId){
            case "dimension-filter-0":
                if( dimensionFilterTwo != draggableIdValue ){
                    setDimensionFilterOne(draggableId);
                    temp_activeTabs[objIndex].dimensionOne = draggableId;
                    isMoved = true;
                }
                break;
            case "dimension-filter-1":
                if( dimensionFilterOne != draggableIdValue ){
                    setDimensionFilterTwo(draggableId);
                    temp_activeTabs[objIndex].dimensionTwo = draggableId;
                    isMoved = true;
                }
                break;
            case "measurement-filter-0":
                if( measurementFilterTwo != draggableIdValue ){
                    setMeasurementFilterOne(draggableId);
                    temp_activeTabs[objIndex].measurementOne = draggableId;
                    isMoved = true;
                }
                break;
            case "measurement-filter-1":
                if( measurementFilterOne != draggableIdValue ){
                    setMeasurementFilterTwo(draggableId);
                    temp_activeTabs[objIndex].measurementTwo = draggableId;
                    isMoved = true;
                }
                break;
            case "dimension-option":
                isMoved = true;
                break;
            case "measurement-option":
                isMoved = true;
                break;
            default:
                return;
        }

        if(isMoved == true) {
            switch(source.droppableId){
                case "dimension-filter-0":
                    setDimensionFilterOne('');
                    temp_activeTabs[objIndex].dimensionOne = '';
                    break;
                case "dimension-filter-1":
                    setDimensionFilterTwo('');
                    temp_activeTabs[objIndex].dimensionTwo = '';
                    break;
                case "measurement-filter-0":
                    setMeasurementFilterOne('');
                    temp_activeTabs[objIndex].measurementOne = '';
                    break;
                case "measurement-filter-1":
                    setMeasurementFilterTwo('');
                     temp_activeTabs[objIndex].measurementTwo = '';
                    break;
                default:
                    return;
            }
        }

        setActiveTabs(temp_activeTabs); 
        setActiveTab(activeTabs[objIndex]);
    }

    const getDnDItemStyle = (isDragging, draggableStyle) => ({
        maxWidth: isDragging? "50%" : "100%",
        border: isDragging? "solid 1px grey" : "none",
        width: isDragging? "50%" : "100%",
        // width : "5vw",

        ...draggableStyle,
    });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleModalShare = () => {
        // setModalShareToValue([])
        // setModalShareCcValue([])
        // setIsLoadingShare(false);
        setIsModalShareOpen(!isModalShareOpen);
    };

    const toggleTable = () => {
        setIsTableOpen(!isTableOpen);
    };

    const handleBackButton = () => {
        setActiveTabs(tabsContentArray);
        setActiveTab(tabsContentArray[0])
        setValue(0)
        setModalNameValue("");
        setModalIDValue("");

        toggleTable();
    };

    const openModal = () => {
        if (activeTab.tabChartType != '') {
            toggleModal()
        }
        else {
            toastr.error('Please Select The Type Chart First');
        }
    }

    let defaultValueActionOwner = [];

    const userUpdate = () => {
    //     if(props.original[item.key] && props.original[item.key].length > 0 && props.original[item.key] !== ""){ 
    //         props.original[item.key].forEach(value => {
                users.forEach(x => {
                    // console.log(x.email , value)
                    if(x.email == value){
                        defaultValueActionOwner.push(x)
                    } 
                })
    //         })
    //     }
    };

    const handleSaveData = () => {
        const allTabs = [];
        
        activeTabs.forEach((val)=>{
            let dimensionVal = [];
            dimensionVal[0] = Array.isArray(val.dimensionOne) ?  val.dimensionOne.length > 0 ? val.dimensionOne[0] : "" : val.dimensionOne;
            dimensionVal[1] = val.dimensionTwo.toString() != ''? val.dimensionTwo.toString():null;

            let measurementVal = [];
            measurementVal[0] = Array.isArray(val.measurementOne) ? val.measurementOne.length > 0 ? val.measurementOne[0] : "" : val.measurementOne;
            measurementVal[1] = val.measurementTwo.toString() != ''? val.measurementTwo.toString():null ;

            // let userList = [];

            // modalUserList.forEach((data, idx) => {
            //     userList[idx] = data.email
            // })

            const actFilters = [];
            const activeFilters = val.selectedFilter;
            let idx = 0;
            for (var key of Object.keys(activeFilters)) {
                if(activeFilters[key]) {
                    const obj = {"key" : key, "value" : activeFilters[key]};
                    actFilters[idx] = obj;
                    idx += 1;
                }
            }

            const tab = {
                filters : actFilters,
                index : val.id,
                title : val.label,
                selected_menu : val.tabChartType,
                dimensions : dimensionVal,
                measurements : measurementVal,
            }

            allTabs.push(tab)

        })

        // console.log(modalIDValue, modalNameValue, "--- apa an nih guys")

        const payload = {
            _id : modalIDValue,
            name : modalNameValue,
            shared_to : [],// userList,
            tabs : allTabs
        }

        props.saveData(payload);
        toggleModal();
        handleBackButton();
    };

    const handleBack = () => {
        var array = [...activeTabs];
        var index = array.indexOf(activeTab);
        array[index].isNew = true;
        setActiveTabs(array);
    }

    const handleOpenTab = (obj) => {
        // console.log(tab,activeTabs, "-----TABS")
        const tab = obj.tabs
        const defaultTab = tab[0]
        const chartType = defaultTab.tabChartType;

        // console.log(obj, "--this is obj")

        setModalNameValue(obj.name);
        setModalIDValue(obj.id)

        // if(activeTabs.length == 1) {
        //     setActiveTabs([]);
        // }
     
        handleCreateChart(defaultTab.id, chartType, tab);
        toggleTable();

        // setActiveTabs(tab)
        // setActiveTab(defaultTab);

        // console.log(tab,activeTabs, "-----TABS 2")

    }

    const handleGetChartSVG = (stringSVG) =>{
        chartComponent = stringSVG
    }

    const handleShareChart = () =>{
       toggleModalShare()
    }

    const handleSendShareEmail = () => {
        var userOpt = chartComponent.current.chart.userOptions
        let payload = {
            userOptions : userOpt,
            emailTo : modalShareToValue.map(x=>{ return x.email }),
            emailCc : modalShareCcValue.map(x=>{ return x.email }),
        }
        props.shareChart(payload)
        setIsLoadingShare(true)
    }

    if(isLoadingShare && !analyticStudioData.loadingShare){
        toggleModalShare();
    }


    return null;
    return (
        <div>
            {isTableOpen ? (
                <SavedDataTable menuData={props.menuData } savedData = {savedData} toggleTable={toggleTable} openTab={handleOpenTab} deleteSavedData={props.deleteSavedData}/>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={1}>
                        <Grid item sm={1.5} variant="outlined" height = "100%" className="menu-container">
                                <Droppable key="dimension" droppableId="dimension-option" type='dimension'>
                                    {(provided, snapshot) => (
                                        <Accordion id="dimension-unselect-box" className="box-dnd" 
                                        innerRef={provided.innerRef}
                                        {...provided.droppableProps}
                                        isDraggingOver = {snapshot.isDraggingOver}
                                        >
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography class="bold-title">Dimension</Typography>
                                            </AccordionSummary>
                                            {dimensionArr && dimensionArr.length && dimensionArr.map((item, index) => {
                                                return (
                                                    <Draggable 
                                                    key={item.label}
                                                    draggableId={item.label} 
                                                    index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <AccordionDetails className='dnd-item'
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            innerRef={provided.innerRef}
                                                            isDragging = {snapshot.isDragging}
                                                            style = {getDnDItemStyle(snapshot.isDragging,
                                                                provided.draggableProps.style)}
                                                            >
                                                                {item.label}
                                                            </AccordionDetails>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </Accordion>
                                    )}
                                </Droppable>

                                <Droppable key="measurement" droppableId="measurement-option" type='measurement'>
                                    {(provided, snapshot) => (
                                        <Accordion id="measurement-unselect-box" className="box-dnd" 
                                        innerRef={provided.innerRef}
                                        {...provided.droppableProps}
                                        isDraggingOver = {snapshot.isDraggingOver}
                                        >
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography class="bold-title">Measurement</Typography>
                                            </AccordionSummary>
                                            {measurementArr && measurementArr.length && measurementArr.map((item, index) => {
                                                return (
                                                    <Draggable 
                                                    key={item.label}
                                                    draggableId={item.label} 
                                                    index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <AccordionDetails className='dnd-item'
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            innerRef={provided.innerRef}
                                                            isDragging = {snapshot.isDragging}
                                                            style = {getDnDItemStyle(snapshot.isDragging,
                                                                provided.draggableProps.style)}
                                                            >
                                                                {item.label}
                                                            </AccordionDetails>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </Accordion>
                                    )}
                                </Droppable>
                        </Grid>

                        <Grid item sm={10} className="mb-20 filter-options main-container">
                            <div className={classes.root}>
                                <AppBar position="static" color="default">
                                    

                                    {/* <Tab label="Item One" {...a11yProps(0)} />*/}

                                    
                                    <Grid container>
                                        <Grid xs={10} sm={10}>
                                            <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            >
                                                {activeTabs && activeTabs.length && activeTabs.map(tab => {
                                                    return <Tab label={
                                                        typeof tab.label === "string" ? (
                                                            <Grid container spacing={1} className='tab-header'>
                                                                <Grid item xs={10}>{tab.label}</Grid>
                                                                {activeTabs.length > 1 && (
                                                                    <Grid item xs={2}>         
                                                                        {tab.closeable && (
                                                                            <a
                                                                                className="closeTab"
                                                                                title={"Close tab"}
                                                                                onClick={() => handleClose(tab)}
                                                                            >
                                                                                <CloseIcon className="close-icon"/>
                                                                            </a>
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        ) : 
                                                        tab.id
                                                    } {...a11yProps(tab.id)} onClose/>
                                                    }
                                                )}
                                                <Tab label={<AddIcon/>} onClick={handleAddTab}/>
                                            </Tabs>

                                        </Grid>
                                        <Grid xs={2} sm={2}>
                                            <Grid container spacing={2}>
                                                <Grid xs={4} sm={4}>
                                                    <Button variant='contained' color="primary" className='top-button' onClick={openModal}>Save</Button>
                                                </Grid>
                                                <Grid xs={4} sm={4}>
                                                    <Button variant='contained' color="secondary" className='top-button edit-button' onClick={handleBackButton}>Back</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </AppBar>
                                {activeTabs && activeTabs.length && activeTabs.map((tab, index) => {
                                    return (
                                        <div
                                            role="tabpanel"
                                            hidden={value !== index}
                                            id={`scrollable-auto-tabpanel-${tab.id}`}
                                            aria-labelledby={`scrollable-auto-tab-${tab.id}`}
                                        >
                                        {value === index && (
                                            <Box p={3}>
                                            <Typography>
                                                {tab.isNew ? <MenuContainer handleCreateChart={handleCreateChart} tabId={tab.id}/> :  
                                                    <TabContainer 
                                                        tabId={tab.id}
                                                        chartType={tab.tabChartType}
                                                        dimensionOne={getDimensionOne(tab.id)}
                                                        dimensionTwo={getDimensionTwo(tab.id)}
                                                        measurementOne={getMeasurementOne(tab.id)}
                                                        measurementTwo={getMeasurementTwo(tab.id)}
                                                        fetchDataAnalysis={props.fetchDataAnalysis}
                                                        filterArray={getDefaultFilterTabData(tab.id)}
                                                        setFilterByTabId = {handleSetFilterByTabId}
                                                        selectedFilterActiveTab = {getSelectedFilterActiveTab(tab.id)}
                                                        analyticStudioDataActiveTab = {getDataActiveTab(tab.id)}
                                                        handleSetDataAnalyticArr = {handleSetDataAnalyticArr}
                                                        handleBack={handleBack}
                                                        getChartSVG={handleGetChartSVG}
                                                        shareChart={handleShareChart}
                                                    />
                                                }
                                            </Typography>
                                            </Box>
                                        )}
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>
                    </Grid>
                    <SimpleModalWrapped open={isModalOpen} toggleModal={toggleModal}  currentName={modalNameValue} setModalNameValue={setModalNameValue} handleSaveData={handleSaveData}/>
                    <SimpleModalShare isLoading={analyticStudioData.loadingShare} open={isModalShareOpen} userList={props.users} toggleModal={toggleModalShare}  currentTo={modalShareToValue} setModalShareToValue={setModalShareToValue}  currentCC={modalShareCcValue} setModalShareCcValue={setModalShareCcValue}  handleShareChart={handleSendShareEmail}/>
                </DragDropContext>
            )}
        </div>
    );
}
