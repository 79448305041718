import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { useHistory, Link as Linkroute } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import '../../Receivable/Receivable.scss';
import './Sidebar.scss';
import EBITDAUploadComponent from '../../UserManagement/MenuMaster/EBITDAUpload';
import EBITDA from '../../poc-shreecement/Ebitda'
import NotAvailableAlert from '../../common/components/Alerts/Alerts';
import ModalWithTable from '../../common/components/ModalWithTable';
import Planning from '../../poc-shreecement/Planning'

import { Switch, Route } from 'react-router-dom';
import ReceivableComponent from '../../Receivable';
import ImpactAnalysis from '../../Receivable/ImpactAnalysis';
import VarianceAnalysis from '../../Receivable/VarianceAnalysis';
import FCFImprovement from '../../Receivable/FCFImprovement';
import CadenceBoard from '../../Receivable/CadenceBoard';
import CreateInitiatives from '../../Receivable/CreateInitiatives';
import ViewInitiatives from '../../Receivable/ViewInitiatives';

import ECFM from '../../ECFM'
import Artificial from '../../AI'
import ActualBUData from '../../ExtendedPlanning/ActualBUData'
import SimulationLogic from '../../ExtendedPlanning/SimulationLogic'
import AddEditSimulationLogic from '../../ExtendedPlanning/SimulationLogic/AddEditSimulation'
import BUPlanData from '../../ExtendedPlanning/BuPlan'
import BUPlanvsActual from '../../ExtendedPlanning/BUPlanvsActual'
import AnalyticsModule from '../../ECFM/AnalyticsModule'
import RiskManagementModule from '../../ECFM/RiskManagement'
import OpportunityModule from '../../ECFM/OpportunityManagement'
import NewOriginationComponent from '../../IncomeStatement/NewOrigination'
import FunctionalPlanningComponent from '../../FunctionalPlanning/FunctionalPlanning/index.jsx'
import PlanningModelsComponent from '../../FunctionalPlanning/PlanningModels/index.jsx'
import ComparePlansComponent from '../../FunctionalPlanning/ComparePlans/index.jsx'
import ESFComponent from '../../ExtendedPlanning/ESFForecast/esfForecast.jsx';

import IncomeStatementModule from '../../IncomeStatement';
import DataBrowserModule from '../../DataBrowser';
import GeoLocationComponent from '../../GeoLocation';
import UserMaster from '../../UserManagement/UserMaster';
import UserAnalytics from '../../UserManagement/UserAnalytics';
import RoleMaster from '../../UserManagement/RoleMaster';
import ARUploadComponent from '../../UserManagement/MenuMaster/ARUpload';
import ISUploadComponent from '../../UserManagement/MenuMaster/ISUpload';
import PABUploadComponent from '../../UserManagement/MenuMaster/PABUpload';
import ImpactAnalysisUpload from '../../UserManagement/MenuMaster/ImpactAnalysisUpload';
import ForecastUploadComponent from '../../UserManagement/MenuMaster/ForecastUpload';
import NewUploadComponent from '../../UserManagement/MenuMaster/NewUpload';
import { getAccessByMenuKey } from '../../../utils/userManagement';
import AnalyticsStudioComponent from '../../FreeCashFlow';
import DataReportAnalyticsStudio from '../../FreeCashFlow/AnalyticsStudio-New/DataReport';
import AutomatedDataValidation from "../../UserManagement/AutomatedDataValidation";
import PLRawDataValidation from "../../UserManagement/P&LRawDataValidation";


//svg icons
//Home page
import UserFileUploadIcon from "../../../images/svgicons/user-file-upload.svg";
import CustomReportsIcon from "../../../images/svgicons/custom-reports.svg";
import DashboardsInsightsIcon from "../../../images/svgicons/dashboards&insights.svg";
import AnalyticsStudioIcon from "../../../images/svgicons/analytics-studio.svg";
import ImpactAnalysisIcon from "../../../images/svgicons/impact-analysis.svg";
import InitiativesIcon from "../../../images/svgicons/initiatives.svg";
import PlanningSimulationIcon from "../../../images/svgicons/planning&simulation.svg";
import FCFImprovementIcon from "../../../images/svgicons/viewFCFimprovement.svg";
import AISolutionsIcon from "../../../images/svgicons/ai-solutions.svg";
import PlanningIcon from '../../../images/svgicons/planning.svg';


//Sidebar
import FileUploadIcon from "../../../images/svgicons/file-upload.svg";
import AutomatedDataValidationIcon from "../../../images/svgicons/auto-data-validate.svg";
import DataBrowserIcon from "../../../images/svgicons/data-browser.svg";
import IncomeStatementIcon from "../../../images/svgicons/income-statement.svg";
import ReceivablesIcon from "../../../images/svgicons/receivables.svg";
import AutoDistributionIcon from "../../../images/svgicons/auto-distribution.svg";
import FCFReportsIcon from "../../../images/svgicons/fcf-reports.svg";
import VarianceAnalysisIcon from "../../../images/svgicons/var-analysis.svg";
import AnalyticsModuleIcon from "../../../images/svgicons/analytics-module.svg";
import GeoSpatialAnalysisIcon from "../../../images/svgicons/geospatial-analysis.svg";
import KeyFocusAreasIcon from "../../../images/svgicons/key-focus.svg";
import OverdueARIcon from "../../../images/svgicons/overdue-ar.svg";
import OverheadSpendIcon from "../../../images/svgicons/overhead-spend.svg";
import SONegativeMarginIcon from "../../../images/svgicons/so-negative-margin.svg";
import ForecaseFileUploadIcon from "../../../images/svgicons/forecast-file-upload.svg";
import PLFileUploadIcon from "../../../images/svgicons/pl-file-upload.svg";
import CadenceBoardIcon from "../../../images/svgicons/cadence-board.svg";
import ViewInitiativesIcon from "../../../images/svgicons/view-initiatives.svg";
import CreateInitiativesIcon from "../../../images/svgicons/create-initiatives.svg";
import KPIPlanningIcon from "../../../images/svgicons/kpi-planning.svg";
import SimulationPlanningIcon from "../../../images/svgicons/simulation-planning.svg";
import DetailedPlanningIcon from "../../../images/svgicons/detailed-planning.svg";
import ExtendedPlanningIcon from "../../../images/svgicons/extended-pna.svg";
import ARFileUploadIcon from "../../../images/svgicons/ar-upload.svg";
import ChartsDashboardsIcon from "../../../images/svgicons/charts-dashboards.svg";
import ReportsIcon from "../../../images/svgicons/reports-icon.svg";
import ImprovementPlansIcon from "../../../images/svgicons/improvement-plans.svg";
import PLReportsIcon from "../../../images/svgicons/pl-reports.svg";
import DataValidationToolIcon from "../../../images/svgicons/data-validation-tool.svg";
import BUTargetIcon from "../../../images/svgicons/bu-target.svg";
import BUSummaryLocationIcon from "../../../images/svgicons/bu-summary-location.svg";
import BUSummaryPMIcon from "../../../images/svgicons/bu-summary-PM.svg";
import RiskManagementIcon from "../../../images/svgicons/risk-mgmt.svg";
import OpportunityManagementIcon from "../../../images/svgicons/opportunity-mgmt.svg";
import BUPlanvsActualIcon from "../../../images/svgicons/plan-vs-actual.svg";
import ImpactAnalysisFileUploacIcon from "../../../images/svgicons/impact-analysis-file-upload.svg";
import BUPlanIcon from "../../../images/svgicons/bu-plan.svg";
import BUActualIcon from "../../../images/svgicons/bu-actual.svg";
import SimulationLogicIcon from "../../../images/svgicons/simulation-logic.svg";
import InvoiceReportsIcon from "../../../images/svgicons/invoice-reports.svg";
import OverdueAnalysisIcon from "../../../images/svgicons/overdue-ar.svg";
import HundredKdaysReportsIcon from "../../../images/svgicons/100k-120days-report.svg";
import PlanSummaryIcon from "../../../images/svgicons/plan-summary.svg";
import AgingReportIcon from "../../../images/svgicons/aging-report.svg";
import DSOAnalysisIcon from "../../../images/svgicons/dso-analysis.svg";
import OverdueAutomatedFollowupIcon from "../../../images/svgicons/overdue-auto-followup.svg";
import AutoEscalationIcon from "../../../images/svgicons/auto-escalation.svg";
import ColPlanvsActualIcon from "../../../images/svgicons/plan-vs-actual.svg";
import HFMDataValidationIcon from "../../../images/svgicons/data-validate-hfm.svg";
import SalesTechPLIcon from "../../../images/svgicons/sales-tech-pl.svg";
import FunctionalPLIcon from "../../../images/svgicons/functional-pl.svg";
import OriginationPLIcon from "../../../images/svgicons/origination-pl.svg";
import BUPLIcon from "../../../images/svgicons/bu-pl.svg";
import DestinationPLIcon from "../../../images/svgicons/destination-pl.svg";
import IntegratedPlanningIcon from "../../../images/svgicons/IntegratedPlanning.svg";
import FinancialPlanningIcon from "../../../images/svgicons/FinancialPlanning.svg";
import InventoryIcon from "../../../images/svgicons/Inventory.svg";
import ManufacturingIcon from "../../../images/svgicons/Manufacturing.svg";
import ReceivableIcon from "../../../images/svgicons/Receivable.svg";
import ResourcePlanningIcon from "../../../images/svgicons/ResourcePlanning.svg";
import SalesOperationIcon from "../../../images/svgicons/SalesOperation.svg";


//UserMgmt Icons
import RoleMasterIcon from '../../../images/svgicons/role-master.svg';
import UserAnalyticsIcon from "../../../images/svgicons/user-analytics.svg";
import UserMasterIcon from '../../../images/svgicons/user-master.svg';




import { connect, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: '50px !important',
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
}));

const UserManagementNavigation = [
    {
        text: "Role Master",
        link: "/usermanagement/role-master",
        selected: false,
        Icon: RoleMasterIcon,
        Submenu: [],
        routeId: 'role-master',
        id: 1,
        available: true,
        key: "role_master"
    },
    {
        text: "User Master",
        link: "/usermanagement/user-master",
        Icon: UserMasterIcon,
        selected: false,
        Submenu: [],
        routeId: 'user-master',
        id: 2,
        available: true,
        key: "user_master"
    },
    {
        text: "User Analytics",
        link: "/usermanagement/user-analytics",
        Icon: UserAnalyticsIcon,
        selected: false,
        Submenu: [],
        routeId: 'user-analytics',
        id: 2,
        available: true,
        key: "user_analytics"
    },
    // {
    //     text: "Upload Data Validation",
    //     link: "/usermanagement/p&l-raw-data-validation",
    //     Icon: PLValBlack,
    //     IconSelected: PLValWhite,
    //     IconMain: PLValMain,
    //     selected: false,
    //     Submenu: [],
    //     routeId: 'p&l-raw-data-validation',
    //     id: 2,
    //     available: true,
    //     key: "automated_data_validation"
    // },
]

const DashboardNavigation = [
    {
        text: "Data Management",
        link: "/dashboard/user-file-upload",
        Icon: UserFileUploadIcon,
        routeId: 'user-file-upload',
        Submenu: [
            {
                text: "EBITDA - File Upload",
                link: '/dashboard/user-file-upload/ebitdafileupload',
                toolTipText: 'EBITDA - File Upload',
                Icon: PLFileUploadIcon,
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'ebitdafileupload'
            },
            {
                text: "P&L - File Upload",
                link: '/dashboard/user-file-upload/p&lfileupload',
                toolTipText: 'P&L - File Upload',
                Icon: PLFileUploadIcon,
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'p&lfileupload'
            },
            {
                text: "PAB - Split Upload",
                link: '/dashboard/user-file-upload/pabfileupload',
                toolTipText: 'PAB - Split Upload',
                Icon: FileUploadIcon, 
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'pabfileupload'
            },
            {
                text: "Forecast - File Upload",
                link: '/dashboard/user-file-upload/forecastfileupload',
                toolTipText: 'Forecast - File Upload',
                Icon: ForecaseFileUploadIcon, 
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'forecastfileupload'
            },
            {
                text: "AR - File Upload",
                link: '/dashboard/user-file-upload/payment',
                toolTipText: 'AR - File Upload',
                Icon: ARFileUploadIcon,
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'payment'
            },
            {
                text: "Impact Analysis - File Upload",
                link: '/dashboard/user-file-upload/impact-analysis-upload',
                toolTipText: 'Impact Analysis File Upload',
                Icon: ImpactAnalysisFileUploacIcon, 
                open: false,
                key: "auto_payment",
                Submenu: [],
                routeId: 'impact-analysis-upload'
            },
            {
                text: "Automated Data Validation",
                link: "/dashboard/user-file-upload/automated-data-validation",
                Icon: AutomatedDataValidationIcon,
                selected: false,
                Submenu: [],
                routeId: 'automated-data-validation',
                id: 2,
                available: true,
                key: "automated_data_validation"
            },
            {
                id: 6,
                text: "Data Browser",
                link: "/dashboard/user-file-upload/main-data-browser",
                Icon: DataBrowserIcon,
                selected: false,
                Submenu: [],
                routeId: 'main-data-browser',
                key: "income_statement"
            },
            // {
            //     id: 6,
            //     text: "Data Time Comparison",
            //     link: "/dashboard/user-file-upload/data-browser",
            //     Icon: DataBrowserIcon,
            //     selected: false,
            //     Submenu: [],
            //     routeId: 'data-browser',
            //     key: "data_browser_modules"
            // }
        ],
        selected: false,
        open: false,
        id: 3,
        available: true,
        key: "menu_master"
    },
    {
        id: 0,
        text: "Custom Reports",
        link: "/dashboard/custom-reports",
        Icon: CustomReportsIcon,
        selected: false,
        routeId: 'custom-reports',
        key: "income_statement",
        Submenu: [
            {
                id: 1,
                text: "Income Statement",
                link: "/dashboard/income-statement",
                Icon: IncomeStatementIcon,
                selected: false,
                routeId: 'income-statement',
                key: "income_statement",
                Submenu: [
                    {
                        text: "P&L Reports",
                        link: '/dashboard/income-statement/p&lreports',
                        toolTipText: 'Income Statement - Operational and Functional Reports',
                        Icon: PLReportsIcon,
                        open: false,
                        routeId: 'p&lreports',
                        key: "pnl_reports",
                        Submenu: [
                            {
                                text: "New Origination P&L",
                                link: '/dashboard/income-statement/p&lreports/one-new-origination',
                                Icon: OriginationPLIcon,
                                selected: false,
                                routeId: 'one-new-origination',
                                key: "bu_pnl"
                            },
                            {
                                text: "New Destination P&L",
                                link: '/dashboard/income-statement/p&lreports/one-new',
                                Icon: DestinationPLIcon,
                                selected: false,
                                routeId: 'one-new',
                                key: "bu_pnl"
                            },
        
                            {
                                text: "Functional P&L",
                                link: '/dashboard/income-statement/p&lreports/functional-pl',
                                Icon: FunctionalPLIcon,
                                selected: false,
                                routeId: 'functional-pl',
                                key: "func_pnl"
                            },
                            // {
                            //     text: "MPR",
                            //     link: '/dashboard/income-statement/p&lreports/mpr',
                            //     Icon: MprBlack,
                            //     IconSelected: MprWhite,
                            //     IconMain: MprMain,
                            //     selected: false,
                            //     routeId: 'mpr',
                            //     key: "mpr"
                            // },
                            {
                                text: "Sales & Technology P&L",
                                link: '/dashboard/income-statement/p&lreports/sales-technology-pl',
                                Icon: SalesTechPLIcon,
                                selected: false,
                                routeId: 'sales-technology-pl',
                                key: "func_pnl"
                            },
                            {
                                text: "BU P&L",
                                link: '/dashboard/income-statement/p&lreports/bu-pl',
                                Icon: BUPLIcon,
                                selected: false,
                                routeId: 'bu-pl',
                                key: "bu_pnl"
                            },
                        ],
                    },
                    {
                        text: "Charts, Dashboard & Analytics", //Ad hoc Charts
                        link: "/dashboard/income-statement/ad-hoc",
                        Icon: ChartsDashboardsIcon,
                        toolTipText: 'Ad hoc Reports',
                        selected: false,
                        routeId: 'ad-hoc',
                        open: true,
                        key: "ad_hoc",
                        // Submenu: [
                        //     {
                        //         text: "T&E Analysis",
                        //         link: '/dashboard/income-statement/ad-hoc/tne',
                        //         Icon: DataValidationIcon,
                        //         IconSelected: DataValidationIcon,
                        //         IconMain: DataValidation,
                        //         selected: false,
                        //         routeId: 'tne',
                        //         key: "tne"
                        //     },
                        //     {
                        //         text: "Overtime Analysis",
                        //         link: '/dashboard/income-statement/ad-hoc/overtime',
                        //         Icon: HFMDataIcon,
                        //         IconSelected: HFMDataIcon,
                        //         IconMain: HFMData,
                        //         selected: false,
                        //         routeId: 'overtime',
                        //         key: "overtime"
                        //     },
                        //     {
                        //         text: "Ad hoc Charts",
                        //         link: '/dashboard/income-statement/ad-hoc/adhoc-chart',
                        //         Icon: Scatter,
                        //         IconSelected: Scatter,
                        //         IconMain: ScatterMain,
                        //         selected: false,
                        //         routeId: 'adhoc-chart',
                        //         key: "adhoc_chart"
                        //     },
                        // ]
                    },
                    
                    
                    {
                        text: "Data Validation Tool",
                        link: "/dashboard/income-statement/data-browser",
                        Icon: DataValidationToolIcon,
                        selected: false,
                        toolTipText: 'Data validation - HFM data reconcillation',
                        key: "databrowser",
                        routeId: 'data-browser',
                        Submenu: [
                            {
                                text: "Data Validation - HFM",
                                link: '/dashboard/income-statement/data-browser/validation',
                                Icon: HFMDataValidationIcon,
                                selected: false,
                                routeId: 'validation',
                                key: "data_validation"
                            },
                            // {
                            //     text: "Data Validation - SAP",
                            //     link: '/dashboard/income-statement/data-browser/hfm',
                            //     Icon: HFMDataIcon,
                            //     IconSelected: HFMDataIcon,
                            //     IconMain: HFMData,
                            //     selected: false,
                            //     routeId: 'hfm',
                            //     key: "hfm_data"
                            // },
                        ],
                    },
                ]
            },
            {
                id: 2,
                text: "Receivables",
                link: "/dashboard/receivables",
                selected: false,
                Icon: ReceivablesIcon,
                key: "receivables",
                routeId: 'receivables',
                Submenu: [
                    {
                        text: "Reports",
                        link: "/dashboard/receivables/reports",
                        toolTipText: 'Aging reports by Business Unit and Customer',
                        Icon: ReportsIcon,
                        routeId: 'reports',
                        selected: false,
                        key: "reports",
                        Submenu: [
                            {
                            text: "Aging Reports",
                            link: '/dashboard/receivables/reports/aging',
                            Icon: AgingReportIcon,
                            selected: false,
                            routeId: 'aging',
                            key: "aging_reports"
                        },
                        {
                            text: "100k-120 Day Reports",
                            link: '/dashboard/receivables/reports/day-reports',
                            Icon: HundredKdaysReportsIcon,
                            selected: false,
                            routeId: 'day-reports',
                            key: "100k-120_days_reports"
                        },
                        {
                            text: "Invoice List Report",
                            link: '/dashboard/receivables/reports/invoice_list',
                            Icon: InvoiceReportsIcon,
                            selected: false,
                            routeId: 'invoice_list',
                            key: "invoice_list"
                        },
        
                        ],
                    },
                    {
                        text: "Charts, Dashboard & Analytics",
                        link: "/dashboard/receivables/dashboard-analytics",
                        Icon: ChartsDashboardsIcon,
                        toolTipText: 'DSO, Overdue analysis and Account Owner Performance',
                        selected: false,
                        routeId: 'dashboard-analytics',
                        open: true,
                        key: "dashboard_analytics",
                        Submenu: [
                            {
                                text: "DSO Analysis",
                                selected: false,
                                Icon: DSOAnalysisIcon,
                                routeId: 'dso',
                                link: '/dashboard/receivables/dashboard-analytics/dso',
                                key: "dashboard_dso"
                            },
                            {
                                text: "Overdue Analysis",
                                link: '/dashboard/receivables/dashboard-analytics/overdue',
                                selected: false,
                                Icon: OverdueAnalysisIcon,
                                routeId: 'overdue',
                                key: "dashboard_overdue"
                            },
                            {
                                text: "Overdue Automated Followup",
                                link: '/dashboard/receivables/dashboard-analytics/scatter',
                                Icon: OverdueAutomatedFollowupIcon,
                                selected: false,
                                routeId: 'scatter',
                                key: "dashboard_scatter"
                            },
                            {
                                text: "Automated Escalations",
                                link: '/dashboard/receivables/dashboard-analytics/escalation',
                                Icon: AutoEscalationIcon,
                                selected: false,
                                routeId: 'escalation',
                                key: "dashboard_escalations"
                            },
                            {
                                text: "Collections Actual v/s Collections Forecast",
                                link: '/dashboard/receivables/dashboard-analytics/collections',
                                Icon: ColPlanvsActualIcon,
                                selected: false,
                                routeId: 'collections',
                                key: "dashboard_collections"
                            },
                        ],
                    },
                    
                    {
                        text: "Improvement Plans",
                        link: "/dashboard/receivables/improvement-plans",
                        toolTipText: 'A collaborative planning and execution tool',
                        Icon: ImprovementPlansIcon,
                        Submenu: [
                            {
                                text: "Plan Summary",
                                link: "/dashboard/receivables/improvement-plans/plan-summary",
                                toolTipText: 'A collaborative planning and execution tool',
                                Icon: PlanSummaryIcon,
                                Submenu: [],
                                routeId: 'plan-summary',
                                selected: false,
                                key: "plan_summary"
                            },
        
                        ],
                        routeId: 'improvement-plans',
                        selected: false,
                        key: "improvement_plan"
                    },
                    {
                        text: "Data Validation Tool",
                        link: "/dashboard/receivables/data-browser",
                        Icon: DataValidationToolIcon,
                        selected: false,
                        routeId: 'data-browser',
                        toolTipText: 'Data validation - HFM data reconcillation',
                        key: "databrowser",
                        Submenu: [
                            {
                                text: "Data Validation - HFM",
                                link: '/dashboard/receivables/data-browser/validation',
                                Icon: HFMDataValidationIcon,
                                selected: false,
                                routeId: 'validation',
                                key: "data_validation"
                            },
                            // {
                            //     text: "Data Validation - SAP",
                            //     link: '/dashboard/receivables/data-browser/hfm',
                            //     Icon: HFMDataIcon,
                            //     IconSelected: HFMDataIcon,
                            //     IconMain: HFMData,
                            //     routeId: 'hfm',
                            //     selected: false,
                            //     key: "hfm_data"
                            // },
                        ],
                    },
                ]
            },
            {
                text: "FCF Report",
                link: '/dashboard/receivables/dashboard-analytics/fcf-report',
                Icon: FCFReportsIcon,
                openInNewTab: false,
                selected: false,
                routeId: 'fcf-report',
                key: "data_validation"
            },
            {
                text: "Auto distribution",
                link: '/dashboard/receivables/dashboard-analytics/auto-distribution',
                Icon: AutoDistributionIcon,
                openInNewTab: false,
                selected: false,
                routeId: 'auto-distribution',
                key: "data_validation"
            },
        ]
    }, 
    {
        id: 4,
        text: "Dashboards & Insights",
        link: "/dashboard/dash-analysis",
        Icon: DashboardsInsightsIcon,
        selected: false,
        Submenu: [
            {
                text: "Dashboard",
                link: "/dashboard/dash-analysis/analytics-module",
                Icon: AnalyticsModuleIcon,
                toolTipText: 'Dashboard',
                Submenu: [],
                selected: false,
                routeId: 'analytics-module',
                open: true,
                key: "dashboard_analytics"
            },
            {
                text: "Variance Analysis",
                link: '/dashboard/dash-analysis/variance',
                Icon: VarianceAnalysisIcon,
                selected: false,
                routeId: 'variance',
                key: "data_validation"
            },
            {
                text: "Geospatial Analysis",
                link: '/dashboard/dash-analysis/geo-location',
                Icon: GeoSpatialAnalysisIcon,
                selected: false,
                routeId: 'geo-location',
                key: "data_validation"
            },
           
            {
                text: "SO’s with negative margins",
                link: '/dashboard/income-statement/p&lreports/one-new',
                Icon: SONegativeMarginIcon,
                openInNewTab: false,
                selected: false,
                routeId: 'validation',
                key: "data_validation"
            },
            {
                text: "Overhead spend vs Budget",
                link: '/dashboard/income-statement/p&lreports/functional-pl',
                Icon: OverheadSpendIcon,
                openInNewTab: false,
                selected: false,
                routeId: 'validation',
                key: "data_validation"
            },
            {
                text: "Key Focus Areas",
                link: "/dashboard/free-cashflow-analytics/key-focus",
                Icon: KeyFocusAreasIcon,
                toolTipText: 'Key Focus Areas',
                selected: false,
                routeId: 'key-focus',
                open: true,
                key: "dashboard_analytics",
                Submenu: [
                    {
                        text: "Risk Management",
                        link: '/dashboard/free-cashflow-analytics/risk-management',
                        Icon: RiskManagementIcon,
                        toolTipText: 'Risk Management',
                        selected: false,
                        routeId: 'risk-management',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "Opportunity Management",
                        link: '/dashboard/free-cashflow-analytics/opportunity-management',
                        Icon: OpportunityManagementIcon,
                        toolTipText: 'Opportunity Management',
                        selected: false,
                        routeId: 'opportunity-management',
                        key: "dashboard_analytics"
                    }

                ],
            },
            {
                text: "Overdue AR",
                link: '/dashboard/receivables/dashboard-analytics/scatter',
                Icon: OverdueARIcon,
                openInNewTab: false,
                selected: false,
                routeId: 'scatter',
                key: "data_validation"
            }
        ],
        routeId: 'dash-analysis',
        key: "income_statement"
    },
    {
        id: 5,
        text: "Analytics Studio",
        link: "/dashboard/analytics-studio",
        Icon: AnalyticsStudioIcon,
        selected: false,
        Submenu: [],
        routeId: 'analytics-studio',
        key: "income_statement"
    },
    {
        id: 5,
        text: "Impact Analysis",
        link: "/dashboard/impact-analysis",
        Icon: ImpactAnalysisIcon,
        selected: false,
        Submenu: [],
        routeId: 'impact-analysis',
        key: "income_statement"
    },
    {
        id: 5,
        text: "Initiatives",
        link: "/dashboard/initiatives",
        Icon: InitiativesIcon,
        selected: false,
        Submenu: [
            {
                text: "Create Initiatives",
                link: '/dashboard/initiatives/create',
                Icon: CreateInitiativesIcon,
                selected: false,
                routeId: 'create',
                key: "data_validation"
            },
            {
                text: "Cadence Board",
                link: '/dashboard/initiatives/cadence',
                Icon: CadenceBoardIcon,
                selected: false,
                routeId: 'cadence',
                key: "data_validation"
            },
            {
                text: "View Initiatives",
                link: "/dashboard/initiatives/view",
                Icon: ViewInitiativesIcon,
                toolTipText: 'View Initiatives',
                Submenu: [],
                selected: false,
                routeId: 'view',
                open: true,
                key: "dashboard_analytics"
            }
        ],
        routeId: 'initiatives',
        key: "income_statement"
    },
    {
        id: 0,
        text: "Planning & Simulation",
        link: "/dashboard/xpna",
        Icon: PlanningSimulationIcon,
        selected: false,
        routeId: 'xpna',
        key: "income_statement",
        Submenu: [
            {
                text: "Functional Planning",
                link: "/dashboard/xpna/extended-planning/functional-planning",
                Icon: PlanningIcon,
                Submenu: [
                    // {
                    //     text: "Functional Planning",
                    //     link: '/dashboard/xpna/extended-planning/func-planning',
                    //     Icon: KPIPlanningIcon,
                    //     toolTipText: 'Functional Planning',
                    //     selected: false,
                    //     routeId: 'func-planning',
                    //     key: "income_statement"
                    // }, 
                    // {
                    //     text: "Saved Plans",
                    //     link: '/dashboard/xpna/extended-planning/planning-models',
                    //     Icon: SimulationPlanningIcon,
                    //     toolTipText: 'Saved Plans',
                    //     selected: false,
                    //     routeId: 'planning-models',
                    //     key: "income_statement"
                    // }, {
                    //     text: "Compare Plans",
                    //     link: '/dashboard/xpna/extended-planning/compare-plans',
                    //     Icon: DetailedPlanningIcon,
                    //     toolTipText: 'Compare Plans',
                    //     selected: false,
                    //     routeId: 'compare-plans',
                    //     key: "income_statement"
                    // }
                ],
                toolTipText: 'Functional Planning',
                selected: false,
                routeId: 'functional-planning',
                open: true,
                key: "income_statement",
            },
            {
                text: "Simulation Planning",
                link: "/dashboard/xpna/extended-planning/simulation-based",
                Icon: SimulationPlanningIcon,
                Submenu: [
                    {
                        text: "BU Actual Data",
                        link: '/dashboard/xpna/extended-planning/actual-bu-data',
                        Icon: BUActualIcon,
                        toolTipText: 'BU Actual Data',
                        selected: false,
                        routeId: 'actual-bu-data',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "Simulation Models",
                        link: '/dashboard/xpna/extended-planning/simulation-logic',
                        Icon: SimulationLogicIcon,
                        toolTipText: 'Simulation Models',
                        selected: false,
                        routeId: 'simulation-logic',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan",
                        link: '/dashboard/xpna/extended-planning/bu-plan',
                        Icon: BUPlanIcon,
                        toolTipText: 'BU Plan',
                        selected: false,
                        routeId: 'bu-plan',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan vs Actual",
                        link: '/dashboard/xpna/extended-planning/actual-vs-BU-plan',
                        Icon: BUPlanvsActualIcon,
                        toolTipText: 'BU Plan vs Actual',
                        selected: false,
                        routeId: 'actual-vs-BU-plan',
                        key: "dashboard_analytics"
                    }
                ],
                toolTipText: 'Simulation Based Planning',
                selected: false,
                routeId: 'simulation-based',
                open: true,
                key: "income_statement",
            },
        

            {
                text: "KPI Based Planning",
                link: "/dashboard/xpna/extended-planning/kpi-based",
                Icon: KPIPlanningIcon,
                Submenu: [
                    {
                        text: "BU Actual Data",
                        link: '/dashboard/xpna/extended-planning/actual-bu-data',
                        Icon: BUActualIcon,
                        toolTipText: 'BU Actual Data',
                        selected: false,
                        routeId: 'actual-bu-data',
                        stepData: 'Step 1 : Take Actual Financial and Operational data',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan",
                        link: '/dashboard/xpna/extended-planning/bu-plan-kpi-detailed',
                        Icon: BUPlanIcon,
                        toolTipText: 'BU Plan',
                        selected: false,
                        routeId: 'bu-plan-kpi-detailed',
                        stepData: 'Step 2 : Generate plan based on KPIs',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan vs Actual",
                        link: '/dashboard/xpna/extended-planning/actual-vs-BU-plan-kpi-detailed',
                        Icon: BUPlanvsActualIcon,
                        toolTipText: 'BU Plan vs Actual',
                        selected: false,
                        stepData: 'Step 3 : Analyze Plan vs Actual ',
                        routeId: 'actual-vs-BU-plan-kpi-detailed',
                        key: "dashboard_analytics"
                    }
                ],
                toolTipText: 'KPI Based Planning',
                selected: false,
                routeId: 'kpi-based',
                open: true,
                key: "income_statement",
            },

            {
                text: "Detailed Planning",
                link: "/dashboard/xpna/extended-planning/detailed",
                Icon: DetailedPlanningIcon,
                Submenu: [
                    {
                        text: "BU Actual Data",
                        link: '/dashboard/xpna/extended-planning/actual-bu-data',
                        Icon: BUActualIcon,
                        toolTipText: 'BU Actual Data',
                        selected: false,
                        routeId: 'actual-bu-data',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan",
                        link: '/dashboard/xpna/extended-planning/bu-plan-kpi-detailed',
                        Icon: BUPlanIcon,
                        toolTipText: 'BU Plan',
                        selected: false,
                        routeId: 'bu-plan-kpi-detailed',
                        key: "dashboard_analytics"
                    },
                    {
                        text: "BU Plan vs Actual",
                        link: '/dashboard/xpna/extended-planning/actual-vs-BU-plan-kpi-detailed',
                        Icon: BUPlanvsActualIcon,
                        toolTipText: 'BU Plan vs Actual',
                        selected: false,
                        routeId: 'actual-vs-BU-plan-kpi-detailed',
                        key: "dashboard_analytics"
                    }
                ],
                toolTipText: 'Detailed Planning',
                selected: false,
                routeId: 'detailed',
                open: true,
                key: "income_statement",
            },
           
            {
                text: "Integrated Planning",
                link: "/dashboard/xpna/extended-planning",
                Icon: ExtendedPlanningIcon,
                Submenu: [
                    // {
                    //     text: "BU Target",
                    //     link: "/dashboard/xpna/extended-planning/bu-4-target",
                    //     Icon: BUTargetIcon,
                    //     toolTipText: 'BU Target hoc Reports',
                    //     selected: false,
                    //     routeId: 'bu-4-target',
                    //     open: true,
                    //     key: "income_statement",
                    // },
                    // {
                    //     text: "BU Summary by Location", 
                    //     link: "/dashboard/xpna/extended-planning/bu-4-location",
                    //     Icon: BUSummaryLocationIcon,
                    //     toolTipText: 'BU Summary by Location',
                    //     selected: false,
                    //     routeId: 'bu-4-location',
                    //     open: true,
                    //     key: "income_statement",
                    // },
                    // {
                    //     text: "BU Summary by PM", 
                    //     link: "/dashboard/xpna/extended-planning/bu-4-pm",
                    //     Icon: BUSummaryPMIcon,
                    //     toolTipText: 'BU Summary by PM',
                    //     selected: false,
                    //     routeId: 'bu-4-pm',
                    //     open: true,
                    //     key: "income_statement",
                    // },
                    {
                        text: "Financial Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: FinancialPlanningIcon,
                        toolTipText: 'Financial Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    },
                    {
                        text: "Sales & Operations Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: SalesOperationIcon,
                        toolTipText: 'Sales & Operations Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    },
                    {
                        text: "Workforce Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: ResourcePlanningIcon,
                        toolTipText: 'Workforce Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    },
                    {
                        text: "Project Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: IntegratedPlanningIcon,
                        toolTipText: 'Project Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    }, 
                    {
                        text: "Capex Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: ManufacturingIcon,
                        toolTipText: 'Production Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    },                                 
                    {
                        text: "Inventory Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: InventoryIcon,
                        toolTipText: 'Inventory Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    },
                    {
                        text: "Receivables Planning", 
                        link: "/dashboard/xpna/extended-planning/bu-4-pm",
                        Icon: ReceivableIcon,
                        toolTipText: 'Receivables Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    }                 
                ],
                toolTipText: 'Integrated Planning',
                selected: false,
                routeId: 'extended-planning',
                open: true,
                key: "income_statement",
            }
            , 
                    {
                        text: "ESF Forecast", 
                        link: "/dashboard/xpna/extended-planning/esf-forecast",
                        Icon: FinancialPlanningIcon,
                        toolTipText: 'Financial Planning',
                        selected: false,
                        routeId: 'bu-4-pm',
                        open: true,
                        key: "income_statement",
                    }
        ]
    },
    {
		text: 'AI Solutions',
        id: 9,
        link: "/dashboard/artificial-intelligence",
        Icon: AISolutionsIcon,
        selected: false,
        Submenu: [],
        routeId: 'artificial-intelligence',
        key: "income_statement"
	},
    {
        id: 5,
        text: "FCF Improvement",
        link: "/dashboard/fcf-improvement",
        Icon: FCFImprovementIcon,
        selected: false,
        Submenu: [],
        routeId: 'fcf-improvement',
        key: "income_statement"
    }

]

// const DashboardNewNavigation =  [
//     {
//         id: 0,
//         text: "xP&A",
//         link: "/dashboard/xpna",
//         Icon: UserAnalyticsBlack,
//         IconSelected: UserAnalyticsWhite,
//         selected: false,
//         routeId: 'xpna',
//         key: "income_statement",
//         Submenu: [
//             {
//                 text: "BU 4 Target",
//                 link: "/dashboard/xpna/bu-4-target",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Target hoc Reports',
//                 selected: false,
//                 routeId: 'bu-4-target',
//                 open: true,
//                 key: "income_statement",
//             },
//             {
//                 text: "BU 4 Summary by Location", 
//                 link: "/dashboard/xpna/bu-4-location",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Summary by Location',
//                 selected: false,
//                 routeId: 'bu-4-location',
//                 open: true,
//                 key: "income_statement",
//             },
//             {
//                 text: "BU 4 Summary by PM", 
//                 link: "/dashboard/xpna/bu-4-pm",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'BU 4 Summary by PM',
//                 selected: false,
//                 routeId: 'bu-4-pm',
//                 open: true,
//                 key: "income_statement",
//             }
//         ]
//     },
//     {
//         id: 3,
//         text: "Free Cashflow Analytics",
//         link: "/dashboard/free-cashflow-analytics",
//         Icon: FreeCashFlowBlack,
//         IconSelected: FreeCashFlowWhite,
//         Submenu: [
//             {
//                 text: "Improve KPIs and Free Cashflow",
//                 link: "/dashboard/free-cashflow-analytics/free-cashflow",
//                 IconMain: ImprovementMainIcon,
//                 toolTipText: 'A collaborative planning and execution tool',
//                 Icon: ImprovementPlansBlack,
//                 IconSelected: ImprovementPlansWhite,
//                 Submenu: [],
//                 routeId: 'free-cashflow',
//                 selected: false,
//                 key: "plan_summary"
//             },
//              {
//                 text: "Analytics Module",
//                 link: "/dashboard/dash-analysis/analytics-module",
//                 Icon: DashboardAnalyticsBlack,
//                 IconSelected: DashboardAnalyticsWhite,
//                 IconMain: DashboardMainIcon,
//                 toolTipText: 'Analytics Module',
//                 Submenu: [],
//                 selected: false,
//                 routeId: 'analytics-module',
//                 open: true,
//                 key: "dashboard_analytics"
//             },
//             {
//                 text: "Key Focus Areas",
//                 link: "/dashboard/free-cashflow-analytics/key-focus",
//                 Icon: OverdueAutomatedBlack,
//                 IconSelected: OverdueAutomatedWhite,
//                 IconMain: ReportMainIcon,
//                 toolTipText: 'Key Focus Areas',
//                 selected: false,
//                 routeId: 'key-focus',
//                 open: true,
//                 key: "dashboard_analytics",
//                 Submenu: [
//                     {
//                         text: "Risk Management",
//                         link: '/dashboard/free-cashflow-analytics/risk-management',
//                         Icon: RiskBlack,
//                         IconSelected: RiskWhite,
//                         IconMain: ImprovementMainIcon,
//                         toolTipText: 'Risk Management',
//                         selected: false,
//                         routeId: 'risk-management',
//                         key: "dashboard_analytics"
//                     },
//                     {
//                         text: "Opportunity Management",
//                         link: '/dashboard/free-cashflow-analytics/opportunity-management',
//                         Icon: RiskBlack,
//                         IconSelected: RiskWhite,
//                         IconMain: AgingReportMain,
//                         toolTipText: 'Opportunity Management',
//                         selected: false,
//                         routeId: 'opportunity-management',
//                         key: "dashboard_analytics"
//                     }

//                 ],
//             },
//             // {
//             //     text: "Risk Management",
//             //     link: "/dashboard/free-cashflow-analytics/risk-management",
//             //     Icon: FreeCashFlowBlack,
//             //     IconSelected: FreeCashFlowWhite,
//             //     IconMain: ImprovementMainIcon,
//             //     toolTipText: 'Risk Management',
//             //     Submenu: [],
//             //     selected: false,
//             //     routeId: 'risk-management',
//             //     open: true,
//             //     key: "dashboard_analytics"
//             // }
            

//         ],
//         routeId: 'free-cashflow-analytics',
//         selected: false,
//         available: true,
//         key: "improvement_modules"
//     },
//     {
//         id: 0,
//         text: "Custom Reports",
//         link: "/dashboard/custom-reports",
//         Icon: IncomeStatementBlack,
//         IconSelected: IncomeStatementWhite,
//         selected: false,
//         routeId: 'custom-reports',
//         key: "income_statement",
//         Submenu: [
//             {
//                 id: 1,
//                 text: "Income Statement",
//                 link: "/dashboard/income-statement",
//                 Icon: IncomeStatementBlack,
//                 IconSelected: IncomeStatementWhite,
//                 IconMain: ISMainNew,
//                 selected: false,
//                 routeId: 'income-statement',
//                 key: "income_statement",
//                 Submenu: [
//                     {
//                         text: "Charts, Dashboard & Analytics", //Ad hoc Charts
//                         link: "/dashboard/income-statement/ad-hoc",
//                         Icon: DashboardAnalyticsBlack,
//                         IconSelected: DashboardAnalyticsWhite,
//                         IconMain: DashboardMainIcon,
//                         toolTipText: 'Ad hoc Reports',
//                         selected: false,
//                         routeId: 'ad-hoc',
//                         open: true,
//                         key: "ad_hoc",
//                         // Submenu: [
//                         //     {
//                         //         text: "T&E Analysis",
//                         //         link: '/dashboard/income-statement/ad-hoc/tne',
//                         //         Icon: DataValidationIcon,
//                         //         IconSelected: DataValidationIcon,
//                         //         IconMain: DataValidation,
//                         //         selected: false,
//                         //         routeId: 'tne',
//                         //         key: "tne"
//                         //     },
//                         //     {
//                         //         text: "Overtime Analysis",
//                         //         link: '/dashboard/income-statement/ad-hoc/overtime',
//                         //         Icon: HFMDataIcon,
//                         //         IconSelected: HFMDataIcon,
//                         //         IconMain: HFMData,
//                         //         selected: false,
//                         //         routeId: 'overtime',
//                         //         key: "overtime"
//                         //     },
//                         //     {
//                         //         text: "Ad hoc Charts",
//                         //         link: '/dashboard/income-statement/ad-hoc/adhoc-chart',
//                         //         Icon: Scatter,
//                         //         IconSelected: Scatter,
//                         //         IconMain: ScatterMain,
//                         //         selected: false,
//                         //         routeId: 'adhoc-chart',
//                         //         key: "adhoc_chart"
//                         //     },
//                         // ]
//                     },
//                     {
//                         text: "P&L Reports",
//                         link: '/dashboard/income-statement/p&lreports',
//                         IconMain: PlMain,
//                         toolTipText: 'Income Statement - Operational and Functional Reports',
//                         Icon: PlBlack,
//                         IconSelected: PlWhite,
//                         open: false,
//                         routeId: 'p&lreports',
//                         key: "pnl_reports",
//                         Submenu: [
//                             {
//                                 text: "New Origination P&L",
//                                 link: '/dashboard/income-statement/p&lreports/one-new-origination',
//                                 Icon: NewBlack,
//                                 IconSelected: NewWhite,
//                                 IconMain: NewMain,
//                                 selected: false,
//                                 routeId: 'one-new-origination',
//                                 key: "bu_pnl"
//                             },
//                             {
//                                 text: "New Destination P&L",
//                                 link: '/dashboard/income-statement/p&lreports/one-new',
//                                 Icon: NewDestinationBlack,
//                                 IconSelected: NewDestinationWhite,
//                                 IconMain: NewDestinationMain,
//                                 selected: false,
//                                 routeId: 'one-new',
//                                 key: "bu_pnl"
//                             },
        
//                             {
//                                 text: "Functional P&L",
//                                 link: '/dashboard/income-statement/p&lreports/functional-pl',
//                                 Icon: PlfBlack,
//                                 IconSelected: PlfWhite,
//                                 IconMain: PlfMain,
//                                 selected: false,
//                                 routeId: 'functional-pl',
//                                 key: "func_pnl"
//                             },
//                             // {
//                             //     text: "MPR",
//                             //     link: '/dashboard/income-statement/p&lreports/mpr',
//                             //     Icon: MprBlack,
//                             //     IconSelected: MprWhite,
//                             //     IconMain: MprMain,
//                             //     selected: false,
//                             //     routeId: 'mpr',
//                             //     key: "mpr"
//                             // },
//                             {
//                                 text: "Sales & Technology P&L",
//                                 link: '/dashboard/income-statement/p&lreports/sales-technology-pl',
//                                 Icon: salesPlBlack,
//                                 IconSelected: salesPlWhite,
//                                 IconMain: salesPlMain,
//                                 selected: false,
//                                 routeId: 'sales-technology-pl',
//                                 key: "func_pnl"
//                             },
//                             {
//                                 text: "BU P&L",
//                                 link: '/dashboard/income-statement/p&lreports/bu-pl',
//                                 Icon: BuplBlack,
//                                 IconSelected: BuplWhite,
//                                 IconMain: BuplMain,
//                                 selected: false,
//                                 routeId: 'bu-pl',
//                                 key: "bu_pnl"
//                             },
//                         ],
//                     },
                    
//                     {
//                         text: "Data Validation Tool",
//                         link: "/dashboard/income-statement/data-browser",
//                         IconMain: DatabrowserMainIcon,
//                         Icon: DataBrowserBlack,
//                         selected: false,
//                         IconSelected: DataBrowserWhite,
//                         toolTipText: 'Data validation - HFM data reconcillation',
//                         key: "databrowser",
//                         routeId: 'data-browser',
//                         Submenu: [
//                             {
//                                 text: "Data Validation - HFM",
//                                 link: '/dashboard/income-statement/data-browser/validation',
//                                 Icon: AutomatedDataValidationBlack,
//                                 IconSelected: AutomatedDataValidationWhite,
//                                 IconMain: DataValidation,
//                                 selected: false,
//                                 routeId: 'validation',
//                                 key: "data_validation"
//                             },
//                             // {
//                             //     text: "Data Validation - SAP",
//                             //     link: '/dashboard/income-statement/data-browser/hfm',
//                             //     Icon: HFMDataIcon,
//                             //     IconSelected: HFMDataIcon,
//                             //     IconMain: HFMData,
//                             //     selected: false,
//                             //     routeId: 'hfm',
//                             //     key: "hfm_data"
//                             // },
//                         ],
//                     },
//                 ]
//             },
//             {
//                 id: 2,
//                 text: "Receivables",
//                 link: "/dashboard/receivables",
//                 selected: false,
//                 Icon: ReceivablesBlack,
//                 IconSelected: ReceivablesWhite,
//                         IconMain: RecMain,
//                         key: "receivables",
//                 routeId: 'receivables',
//                 Submenu: [
//                     {
//                         text: "Charts, Dashboard & Analytics",
//                         link: "/dashboard/receivables/dashboard-analytics",
//                         Icon: DashboardAnalyticsBlack,
//                         IconSelected: DashboardAnalyticsWhite,
//                         IconMain: DashboardMainIcon,
//                         toolTipText: 'DSO, Overdue analysis and Account Owner Performance',
//                         selected: false,
//                         routeId: 'dashboard-analytics',
//                         open: true,
//                         key: "dashboard_analytics",
//                         Submenu: [
//                             {
//                                 text: "DSO Analysis",
//                                 selected: false,
//                                 Icon: DSOAnalysisBlack,
//                                 routeId: 'dso',
//                                 IconSelected: DSOAnalysisWhite,
//                                 IconMain: DSOAnalysisMain,
//                                 link: '/dashboard/receivables/dashboard-analytics/dso',
//                                 key: "dashboard_dso"
//                             },
//                             {
//                                 text: "Overdue Analysis",
//                                 link: '/dashboard/receivables/dashboard-analytics/overdue',
//                                 selected: false,
//                                 Icon: OverdueAnalysisBlack,
//                                 IconSelected: OverdueAnalysisWhite,
//                                 IconMain: OverdueAnalysisMain,
//                                 routeId: 'overdue',
//                                 key: "dashboard_overdue"
//                             },
//                             {
//                                 text: "Overdue Automated Followup",
//                                 link: '/dashboard/receivables/dashboard-analytics/scatter',
//                                 Icon: OverdueFollowupBlack,
//                                 IconSelected: OverdueFollowupWhite,
//                                 IconMain: OverdueFollowupMain,
//                                 selected: false,
//                                 routeId: 'scatter',
//                                 key: "dashboard_scatter"
//                             },
//                             {
//                                 text: "Automated Escalations",
//                                 link: '/dashboard/receivables/dashboard-analytics/escalation',
//                                 Icon: AutomatedEscalationsBlack,
//                                 IconSelected: AutomatedEscalationsWhite,
//                                 IconMain: AutomatedEscalationsMain,
//                                 selected: false,
//                                 routeId: 'escalation',
//                                 key: "dashboard_escalations"
//                             },
//                             {
//                                 text: "Collections Actual v/s Collections Forecast",
//                                 link: '/dashboard/receivables/dashboard-analytics/collections',
//                                 Icon: CollectionsAvFBlack,
//                                 IconSelected: CollectionsAvFWhite,
//                                 IconMain: CollectionsAvFMain,
//                                 selected: false,
//                                 routeId: 'collections',
//                                 key: "dashboard_collections"
//                             },
//                         ],
//                     },
//                     {
//                         text: "Reports",
//                         link: "/dashboard/receivables/reports",
//                         IconMain: ReportMainIcon,
//                         toolTipText: 'Aging reports by Business Unit and Customer',
//                         Icon: ReportsBlack,
//                         routeId: 'reports',
//                         IconSelected: ReportsWhite,
//                         selected: false,
//                         key: "reports",
//                         Submenu: [
//                             {
//                             text: "Aging Reports",
//                             link: '/dashboard/receivables/reports/aging',
//                             Icon: AgingReportBlack,
//                             IconSelected: AgingReportWhite,
//                             IconMain: AgingReportMain,
//                             selected: false,
//                             routeId: 'aging',
//                             key: "aging_reports"
//                         },
//                         {
//                             text: "100k-120 Day Reports",
//                             link: '/dashboard/receivables/reports/day-reports',
//                             Icon: DayReportBlack,
//                             IconSelected: DayReportWhite,
//                             IconMain: DayReportMain,
//                             selected: false,
//                             routeId: 'day-reports',
//                             key: "100k-120_days_reports"
//                         },
//                         {
//                             text: "Invoice List Report",
//                             link: '/dashboard/receivables/reports/invoice_list',
//                             Icon: InvoiceListReportBlack,
//                             IconSelected: InvoiceListReportWhite,
//                             IconMain: InvoiceReportMain,
//                             selected: false,
//                             routeId: 'invoice_list',
//                             key: "invoice_list"
//                         },
//                         {
//                             text: "Monthly Status Report",
//                             link: '/dashboard/receivables/reports/month_status_report',
//                             Icon: InvoiceListReportBlack,
//                             IconSelected: InvoiceListReportWhite,
//                             IconMain: InvoiceReportMain,
//                             selected: false,
//                             routeId: 'month_status_report',
//                             key: "aging_reports"
//                         },
//                         ],
//                     },
//                     {
//                         text: "Improvement Plans",
//                         link: "/dashboard/receivables/improvement-plans",
//                         IconMain: ImprovementMainIcon,
//                         toolTipText: 'A collaborative planning and execution tool',
//                         Icon: ImprovementPlansBlack,
//                         IconSelected: ImprovementPlansWhite,
//                         Submenu: [
//                             {
//                                 text: "Plan Summary",
//                                 link: "/dashboard/receivables/improvement-plans/plan-summary",
//                                 IconMain: ImprovementMainIcon,
//                                 toolTipText: 'A collaborative planning and execution tool',
//                                 Icon: ImprovementPlansBlack,
//                                 IconSelected: ImprovementPlansWhite,
//                                 Submenu: [],
//                                 routeId: 'plan-summary',
//                                 selected: false,
//                                 key: "plan_summary"
//                             },
        
//                         ],
//                         routeId: 'improvement-plans',
//                         selected: false,
//                         key: "improvement_plan"
//                     },
//                     {
//                         text: "Data Validation Tool",
//                         link: "/dashboard/receivables/os/data-browser",
//                         IconMain: DatabrowserMainIcon,
//                         Icon: DataBrowserBlack,
//                         selected: false,
//                         routeId: 'data-browser',
//                         IconSelected: DataBrowserWhite,
//                         toolTipText: 'Data validation - HFM data reconcillation',
//                         key: "databrowser",
//                         Submenu: [
//                             // {
//                             //     text: "Data Validation - HFM",
//                             //     link: '/dashboard/receivables/data-browser/validation',
//                             //     Icon: AutomatedDataValidationBlack,
//                             //     IconSelected: AutomatedDataValidationWhite,
//                             //     IconMain: DataValidation,
//                             //     selected: false,
//                             //     routeId: 'validation',
//                             //     key: "data_validation"
//                             // },
//                             // {
//                             //     text: "Data Validation - SAP",
//                             //     link: '/dashboard/receivables/data-browser/hfm',
//                             //     Icon: HFMDataIcon,
//                             //     IconSelected: HFMDataIcon,
//                             //     IconMain: HFMData,
//                             //     routeId: 'hfm',
//                             //     selected: false,
//                             //     key: "hfm_data"
//                             // },
//                         ],
//                     },
//                 ]
//             },
//         ]
//     },
   
//     {
//         id: 4,
//         text: "Dashboard",
//         link: "/dashboard/dash-analysis",
//         Icon: GeoSpatialBlack,
//         IconSelected: GeoSpatialWhite,
//         selected: false,
//         Submenu: [
//             {
//                 text: "Geospatial Analysis",
//                 link: '/dashboard/dash-analysis/geo-location',
//                 Icon: AutomatedDataValidationBlack,
//                 IconSelected: AutomatedDataValidationWhite,
//                 IconMain: DataValidation,
//                 selected: false,
//                 routeId: 'geo-location',
//                 key: "data_validation"
//             },
//             {
//                 text: "Overdue AR",
//                 link: '/dashboard/receivables/dashboard-analytics/scatter',
//                 Icon: OverdueArBlack,
//                 IconSelected: OverdueArWhite,
//                 openInNewTab: true,
//                 IconMain: OverdueAr,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//             {
//                 text: "SO’s with negative margins",
//                 link: '/dashboard/income-statement/p&lreports/one-new',
//                 Icon: NegativeMarginBlack,
//                 IconSelected: NegativeMarginWhite,
//                 openInNewTab: true,
//                 IconMain: NegativeMargin,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//             {
//                 text: "Overhead spend vs Budget",
//                 link: '/dashboard/income-statement/p&lreports/functional-pl',
//                 Icon: OverheadSpendBlack,
//                 IconSelected: OverheadSpendWhite,
//                 openInNewTab: true,
//                 IconMain: OverheadSpend,
//                 selected: false,
//                 routeId: 'validation',
//                 key: "data_validation"
//             },
//         ],
//         routeId: 'dash-analysis',
//         key: "income_statement"
//     },
//     {
//         id: 5,
//         text: "Analytics Studio",
//         link: "/dashboard/analytics-studio",
//         Icon: AnalyticsStudioBlack,
//         IconSelected: AnalyticsStudioWhite,
//         selected: false,
//         Submenu: [],
//         routeId: 'analytics-studio',
//         key: "income_statement"
//     },
//     {
//         id: 6,
//         text: "Data Validation Tool",
//         link: "/dashboard/user-file-upload/main-data-browser",
//         Icon: AnalyticsStudioBlack,
//         IconSelected: AnalyticsStudioWhite,
//         selected: false,
//         Submenu: [],
//         routeId: 'main-data-browser',
//         key: "income_statement"
//     }

// ]

const NestedList = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { menuData } = props;
	const [onLoad, setOnLoad] = React.useState(true);
	const [breadcrumbsList, setBreadcrumbsList] = React.useState([]);
	const [plReportAlignment, setPlReportAlignment] = React.useState(false);
	const [kpiBasedAlignment, seKPIBasedAlignment] = React.useState(false);
	const [adHocAlignment, setAdHocAlignment] = React.useState(false);
	const [planningAndSimAlignment, setPlanningAndSimAlignment] = React.useState(false);
	const [navigationArray, setNavigationArray] = React.useState([]);
	const [navigationBoxArray, setNavigationBoxArray] = React.useState();
	const [navigationSelectedRouteId, setNavigationSelectedRouteId] =
		React.useState('');
	const [navigationBoxArrayIndex, setNavigationBoxArrayIndex] =
		React.useState(null);
	const [headerText, setHeaderText] = React.useState('Home');
	const [OpenNA, setOpenNA] = React.useState(false);
	const [showModalWithTableComponent, setShowModalWithTableComponent] =
		React.useState(false);

	// Drawer Collapsed/Expanded State
	const [levelOne, setLevelOne] = React.useState(true);
	const [levelTwo, setLevelTwo] = React.useState(true);
	const [levelThree, setLevelThree] = React.useState(true);
	const [levelFour, setLevelFour] = React.useState(true);

	// Drawer Visible/Hidden State
	const [showLevelOne, setShowLevelOne] = React.useState(true);
	const [showLevelTwo, setShowLevelTwo] = React.useState(false);
	const [showLevelThree, setShowLevelThree] = React.useState(false);
	const [showLevelFour, setShowLevelFour] = React.useState(false);

	// Top Level Active Index in navigation Array
	const [levelOneIndex, setLevelOneIndex] = React.useState(null);
	const [levelTwoIndex, setLevelTwoIndex] = React.useState(null);
	const [levelThreeIndex, setLevelThreeIndex] = React.useState(null);
	const [levelFourIndex, setLevelFourIndex] = React.useState(null);

	const [tabSelectorValue, setTabSelectorValue] = React.useState(0);
	const tabMapper = { 0: 'Legacy BU', 1: 'New BU' };
	const loginState = useSelector((state) => state);
	const { authReducer } = loginState;
    let thisUrl = window.location.pathname

    if(thisUrl.indexOf("menu-master") == -1 && thisUrl.indexOf("analytics-studio") == -1){
        localStorage.removeItem('show-back-fcf')
    }

	const handleRemoveDrawer = (id = null) => {
		switch (id) {
			case 1:
				setShowLevelOne(false);
				setShowLevelTwo(false);
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
			case 2:
				setShowLevelTwo(false);
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
			case 3:
				setShowLevelThree(false);
				setShowLevelFour(false);
				break;
            case 4:
                setShowLevelFour(false);
                break;
			default:
				break;
		}
	};

	const handleClickBreadcrums = (data, index) => {
	
		switch (index) {
			case 0:
				const shouldMoveForward = exitWithoutModalCondition(data.link);
				if (!shouldMoveForward) {
					return;
				}
				history.push(data.link);

				break;
			case 1:
				handleFirstLevelMenuClick(levelOneIndex);
				break;
			case 2:
				handleSecondLevelMenuClick(levelTwoIndex);
                break;
            case 3:
                handleThirdLevelMenuClick(levelThreeIndex);
				break;
            case 4:
                handleFourthLevelMenuClick(levelFourIndex);
			default:
				break;
		}
	};

	const handleMenuClick = (index, levelIndex) => {
		switch (levelIndex) {
			case 1:
				handleFirstLevelMenuClick(index);
				break;
			case 2:
				handleSecondLevelMenuClick(index);
				break;
			case 3:
				handleThirdLevelMenuClick(index);
				break;
            case 4:
                handleFourthLevelMenuClick(index);
                break;
			default:
				break;
		}
	};

	const setLevel = (index) => {
		switch (index) {
			case 1:
				setLevelOne(!levelOne);
				break;
			case 2:
				setLevelTwo(!levelTwo);
				break;
			case 3:
				setLevelThree(!levelThree);
				break;
            case 4:
                setLevelFour(!levelFour);
                break;
        default:
            break;
    }
	};

	const exitWithoutModalCondition = (link) => {
		if (props.exitWithoutSaveFlag) {
			props.showExitWithoutSaveModal({
				showModal: true,
				nextLocation: { pathname: link },
			});
			return false;
		} else {
			props.showExitWithoutSaveModal({
				showModal: false,
				nextLocation: '',
			});
		}
		return true;
	};

	const handleFirstLevelMenuClick = (index) => {
		const shouldMoveForward = exitWithoutModalCondition(
			navigationArray[index].link
		);
		if (!shouldMoveForward) {
			return;
		}
		if (navigationArray[index].available === false) {
			setOpenNA(true);
			return;
		}
		setLevelOneIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(false);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(navigationArray[index].Submenu);
		setNavigationSelectedRouteId(navigationArray[index].routeId);
		setNavigationBoxArrayIndex(1);
		setHeaderText(navigationArray[index].text);
		setBreadcrumbsList([breadcrumbsList[0], navigationArray[index]]);
		history.push(navigationArray[index].link);
	};

	const handleSecondLevelMenuClick = (index) => {
        let selectedItem = navigationArray[levelOneIndex]?.Submenu[index]
        if (selectedItem['openInNewTab']) {
            localStorage.setItem('openInNewTab', true)
        }
		const shouldMoveForward = exitWithoutModalCondition(
			navigationArray[levelOneIndex].Submenu[index].link
		);
		if (!shouldMoveForward) {
			return;
		}
		setLevelTwoIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[index].Submenu
		);
        let thisRoute = navigationArray[levelOneIndex].Submenu[index].routeId
		setNavigationSelectedRouteId(thisRoute)
		setNavigationBoxArrayIndex(2);
		setHeaderText(navigationArray[levelOneIndex].Submenu[index].text);
      
		if (
			thisRoute &&
			thisRoute == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				{ text: tabMapper[tabSelectorValue] },
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				navigationArray[levelOneIndex].Submenu[index],
			]);
		}
		history.push(navigationArray[levelOneIndex].Submenu[index].link);
	};

	const handleThirdLevelMenuClick = (index) => {
		setLevelThreeIndex(index);
        setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(false);
		setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(3);
		setHeaderText(navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].text);

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				breadcrumbsList[2],
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[index].link
		);
	};

    const handleFourthLevelMenuClick = (index) => {
		setLevelFourIndex(index);
        setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(true);
        setNavigationBoxArray(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(4);
		setHeaderText(navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].text);
	
			setBreadcrumbsList([
				breadcrumbsList[0],
				breadcrumbsList[1],
				breadcrumbsList[2],
				breadcrumbsList[3],
				navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index],
			]);
		history.push(
			navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu[index].link
		);
	};

	const handleSecondLevelMenuClickDefault = (index, levelOne) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(false);
		setShowLevelFour(false);
		setNavigationBoxArray(navigationArray[levelOne].Submenu[index].Submenu);
		// setNavigationSelectedRouteId(navigationArray[levelOne].Submenu[index].routeId)
		setNavigationBoxArrayIndex(2);
		setHeaderText(navigationArray[levelOne].Submenu[index].text);
		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[index],
			]);
		}
		history.push(navigationArray[levelOne].Submenu[index].link);
	};

	const handleThirdLevelMenuClickDefault = (index, levelOne, levelTwo) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(levelTwo);
		setLevelThreeIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(false);
		setNavigationBoxArray(null);
		// setNavigationSelectedRouteId('')
		setNavigationBoxArrayIndex(3);
		setNavigationBoxArray(navigationArray[levelOne].Submenu[levelTwo].Submenu[index].Submenu);
		setHeaderText(navigationArray[levelOne].Submenu[levelTwo].Submenu[index].text);

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOne].Submenu[levelTwo].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[levelTwo],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[index].link
		);
	};

    const handleFourthLevelMenuClickDefault = (index, levelOne, levelTwo, levelThree) => {
		setLevelOneIndex(levelOne);
		setLevelTwoIndex(levelTwo);
		setLevelThreeIndex(levelThree);
		setLevelFourIndex(index);
		setShowLevelOne(true);
		setShowLevelTwo(true);
		setShowLevelThree(true);
		setShowLevelFour(true);
		
        setNavigationBoxArray(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].Submenu
        );
		setNavigationBoxArrayIndex(4);
		setHeaderText(navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].text);
	

		if (
			navigationSelectedRouteId &&
			navigationSelectedRouteId == 'receivables'
		) {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				{ text: tabMapper[tabSelectorValue] },
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index],
			]);
		} else {
			setBreadcrumbsList([
				breadcrumbsList[0],
				navigationArray[levelOne],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree],
				navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index],
			]);
		}
		history.push(
			navigationArray[levelOne].Submenu[levelTwo].Submenu[levelThree].Submenu[index].link
		);
	};

	const handleBoxItemClick = (index, item) => {

        if (item['openInNewTab']) {
            localStorage.setItem('openInNewTab', true)
            window.open(item['link'])
            return;
        }


        if (navigationBoxArrayIndex === 2) {
			setShowLevelThree(true);
			handleThirdLevelMenuClick(index);
			return;
		}

        if (navigationBoxArrayIndex === 3) {
			setShowLevelFour(true);
			handleFourthLevelMenuClick(index);
			return;
		}
		setShowLevelTwo(true);
		handleSecondLevelMenuClick(index);
	};

	const handleSetOpenNA = () => {
		setOpenNA(false);
	};

	const autoClick = () => {
		const urlArray = window.location.pathname.split('/');
		if (urlArray[2]) {
			navigationArray.map((item, index) => {
				if (item.routeId == urlArray[2]) {
					if (urlArray.length > 3) {
						item.Submenu.map((sub_item, sub_index) => {
							if (sub_item.routeId == urlArray[3]) {
								if (urlArray.length > 4) {
									sub_item.Submenu.map(
										(third_level_item, third_level_index) => {
											if (third_level_item.routeId == urlArray[4]) {
                                                if (urlArray.length > 5) {
                                                    sub_item.Submenu.map(
                                                        (fourth_level_item, fourth_level_index) => {
                                                            if (fourth_level_item.routeId == urlArray[5]) {
                                                                handleFourthLevelMenuClickDefault(
                                                                    fourth_level_index,
                                                                    index,
                                                                    sub_index,
                                                                    third_level_index
                                                                );
                                                            }
                                                        }
                                                    );
                                                }else{
												handleThirdLevelMenuClickDefault(
													third_level_index,
													index,
													sub_index
												);
                                                }
											}
										}
									);
								} else {
									handleSecondLevelMenuClickDefault(sub_index, index);
								}
							}
						});
					} else {
						handleMenuClick(index, 1);
					}
				} else {
					item.selected = false;
				}
			});
		}
	};

	React.useEffect(() => {
		autoClick();
	}, [navigationArray, props.location.pathname]);

    React.useEffect(() => {
        let thisLevel = 0

        switch(navigationBoxArrayIndex){
            case 1 : 
            thisLevel = levelOneIndex
            break;
            case 2 : 
            thisLevel = levelTwoIndex
            break;
            case 3 : 
            thisLevel = levelThreeIndex
            break;
            case 4 : 
            thisLevel = levelFourIndex
            break;
        }

		handleMenuClick( thisLevel, navigationBoxArrayIndex)
	}, [navigationArray]);

	React.useEffect(() => {
		if (
			props.location.pathname.includes('income-statement/p&lreports') ||
			tabSelectorValue == 1
		) {
			setPlReportAlignment(true);
		} else {
			setPlReportAlignment(false);
		}

        if (
            props.location.pathname.includes('extended-planning/kpi-based')
       ) {
           seKPIBasedAlignment(true);
       } else {
        seKPIBasedAlignment(false);
       }
        //
        if (
			 props.location.pathname.includes('dashboard/xpna')
		) {
			setPlanningAndSimAlignment(true);
		} else {
			setPlanningAndSimAlignment(false);
		}

       

		// adHocAlignment
		if (
			props.location.pathname.includes('income-statement/ad-hoc') ||
			tabSelectorValue == 1 
		) {
			setAdHocAlignment(true);
		} else {
			setAdHocAlignment(false);
		}
	}, [props.location.pathname]);

	React.useEffect(() => {
		if (props.location.search.includes('process_id')) {
			setShowModalWithTableComponent(true);
			let queryData = props.location.search.split('?process_id=');
			let payload = {
				processId: queryData && queryData.length ? queryData[1] : '',
			};
			props.fetchPlFileUploadError(payload);
		}
	}, [props.location.search]);

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	const handleModalWithTableClose = () => {
		setShowModalWithTableComponent(false);
	};
	if (onLoad) {
		const urlArray = window.location.pathname.split('/');
		if (
			urlArray[1] == 'usermanagement' &&
			navigationArray != UserManagementNavigation
		) {
			setNavigationArray(UserManagementNavigation);
			setBreadcrumbsList([{ text: 'Settings', link: '/usermanagement/panel' }]);
		} else {
			setNavigationArray(DashboardNavigation);
			setBreadcrumbsList([{ text: 'Home', link: '/dashboard/panel' }]);
		}
		setOnLoad(false);
	}

	const NavigationDrawer = (props) => {
		const { array, level, headerText, levelIndex, levelItemsIndex } = props;
		return (
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: level,
					[classes.drawerClose]: !level,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: level,
						[classes.drawerClose]: !level,
					}),
				}}
			>
				<List
					component='nav'
					subheader={
						<ListSubheader
							component='div'
							className='flex ai-center jc-space-between px-0'
						>
							<div className='flex ai-center'>
								<IconButton
									onClick={() => setLevel(levelIndex)}
									style={{ padding: '12px 14px' }}
								>
									{level ? <ChevronLeftIcon /> : <ChevronRightIcon />}
								</IconButton>
								<span style={{ lineHeight: '1.2' }}>{headerText}</span>
							</div>
							<IconButton onClick={() => handleRemoveDrawer(levelIndex)}>
								<CloseIcon fontSize='small' />
							</IconButton>
						</ListSubheader>
					}
				>
					{array.map(
						(item, index) =>
							getAccessByMenuKey(menuData, item.key, 'view') && (
								<Tooltip title={item.text} key={index}>
									<ListItem
										button
										key={index}
										selected={item.selected || index == levelItemsIndex}
										onClick={() => {
											handleMenuClick(index, levelIndex);
										}}
									>
										<ListItemIcon>
											<img className={item.selected || index == levelItemsIndex? 'colored-icons sidebar-highlighted': 'colored-icons sidebar-normal'}
												src={item.Icon}
												height='18'
											/>
										</ListItemIcon>
										<ListItemText primary={item.text} />
									</ListItem>
								</Tooltip>
							)
					)}
				</List>
			</Drawer>
		);
	};

	const handleTabChange = (event, newValue) => {
		setTabSelectorValue(newValue);
		if (newValue == 1) {
			setNavigationArray(DashboardNavigation);
		} else {
			setNavigationArray(DashboardNavigation);
		}
	};

	return (
		<>
			<NotAvailableAlert
				open={OpenNA}
				setOpen={handleSetOpenNA}
			></NotAvailableAlert>
			{showModalWithTableComponent && (
				<ModalWithTable
					modalCallBack={handleModalWithTableClose}
					showModal={showModalWithTableComponent}
					generalTableState={props.generalTableState}
				/>
			)}
			{!authReducer.isEmbedIframe && (
				<Breadcrumbs aria-label='breadcrumb' className='app-breadcrumbs'>
					{breadcrumbsList && breadcrumbsList.length > 0
						? breadcrumbsList.map((item, index) => (
								<Link
									key={index}
									color='inherit'
									onClick={() => {
										handleClickBreadcrums(item, index);
									}}
									className={
										index + 1 == breadcrumbsList.length && index != 0 ? 'active' : ''
									}
								>
									{item && item.text}
								</Link>
						  ))
						: ''}
				</Breadcrumbs>
			)}

			<section className='app-sidebar flex'>
				{!authReducer.isEmbedIframe && showLevelOne ? (
					<NavigationDrawer
						array={navigationArray}
						level={levelOne}
						headerText={
							navigationArray == UserManagementNavigation ? 'Settings' : 'Home'
						}
						levelIndex={1}
						levelItemsIndex={levelOneIndex}
					/>
				) : (
					''
				)}

				{showLevelTwo ? (
					<NavigationDrawer
						array={navigationArray[levelOneIndex].Submenu}
						level={levelTwo}
						headerText={navigationArray[levelOneIndex].text}
						levelIndex={2}
						levelItemsIndex={levelTwoIndex}
					/>
				) : (
					''
				)}

				{showLevelThree ? (
					<NavigationDrawer
						array={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu
						}
						level={levelThree}
						headerText={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].text
						}
						levelIndex={3}
						levelItemsIndex={levelThreeIndex}
					/>
				) : (
					''
				)}

                {showLevelFour ? (
					<NavigationDrawer
						array={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].Submenu
						}
						level={levelFour}
						headerText={
							navigationArray[levelOneIndex].Submenu[levelTwoIndex].Submenu[levelThreeIndex].text
						}
						levelIndex={4}
						levelItemsIndex={levelFourIndex}
					/>
				) : (
					''
				)}

				{navigationBoxArray && navigationBoxArray.length ? (
					<div className='blockDesignSidebar'>
						<div className='header flex ai-center jc-space-between'>
							<span>{headerText}</span>
							<Linkroute to='/dashboard/panel'>
								<IconButton aria-label='delete'>
									<CloseIcon />
								</IconButton>
							</Linkroute>
						</div>
						{navigationSelectedRouteId &&
							navigationSelectedRouteId == 'receivables' && (
								<Tabs
									value={tabSelectorValue}
									onChange={handleTabChange}
									indicatorColor='primary'
									textColor='primary'
									className='mt-16 mb-16'
								>
									<Tab label='Legacy BU' {...a11yProps(0)} />
									<Tab label='New BU' {...a11yProps(1)} />
								</Tabs>
							)}
						<Box
							display='flex'
							className={
								plReportAlignment
									? 'plReportAlignment'
									: adHocAlignment
									? 'adHocAlignment'
                                    : kpiBasedAlignment
                                    ? 'kpiBasedAlignment'
									: planningAndSimAlignment 
                                    ? 'simPlanAlignment'  
                                    : ''
							}
							justifyContent='flex-start'
                            flexWrap='wrap'
							m={1}
							p={1}
						>
							{navigationBoxArray.map(
								(item, index) =>
									getAccessByMenuKey(menuData, item.key, 'view') && (
										<div>
                                            {kpiBasedAlignment && 
                                            <Typography variant='bold-title' style={{fontWeight:'900'}}>{item.stepData}</Typography>
                                            }
											<Tooltip
												key={index}
												title={item.toolTipText ? item.toolTipText : item.text}
											>
												<Box
													className='box'
													onClick={() => handleBoxItemClick(index, item)}
												>
													<img src={item.Icon} className='colored-icons main-icon' height='75'></img>
													<h5>{item.text}</h5>
												</Box>
											</Tooltip>
										</div>
									)
							)}
						</Box>
					</div>
				) : (
					<div className='app-content'>
						<Switch>
							{getAccessByMenuKey(menuData, 'data_browser_modules', 'view') && (
								<Route path='/dashboard/user-file-upload/data-browser'>
									<DataBrowserModule />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'income_statement', 'view') && (
								<Route path='/dashboard/income-statement'>
									<IncomeStatementModule />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'receivables', 'view') && (
								<Route path='/dashboard/receivables'>
									<ReceivableComponent tabSelectorValue={tabSelectorValue} />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'user_master', 'view') && (
								<Route path='/usermanagement/user-master'>
									<UserMaster />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'user_analytics', 'view') && (
								<Route path='/usermanagement/user-analytics'>
									<UserAnalytics />
								</Route>
							)}
							{getAccessByMenuKey(
								menuData,
								'automated_data_validation',
								'view'
							) && (
								<Route path='/dashboard/user-file-upload/automated-data-validation'>
									<AutomatedDataValidation />
								</Route>
							)}
							{getAccessByMenuKey(
								menuData,
								'automated_data_validation',
								'view'
							) && (
								<Route path='/usermanagement/p&l-raw-data-validation'>
									<PLRawDataValidation />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'role_master', 'view') && (
								<Route path='/usermanagement/role-master'>
									<RoleMaster />
								</Route>
							)}
							{getAccessByMenuKey(menuData, 'auto_payment', 'view') && (
								<Route path='/dashboard/user-file-upload/payment'>
									<ARUploadComponent />
								</Route>
							)}
                            <Route key="bu-4-target" path={`/dashboard/xpna/extended-planning/bu-4-target`} exact>
                                <NewOriginationComponent xpnaMenu="0" isXPNA="true" />
                            </Route>
                            <Route key="bu-4-location" path={`/dashboard/xpna/extended-planning/bu-4-location`} exact>
                                <NewOriginationComponent xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route key="actual-bu-data" path={`/dashboard/xpna/extended-planning/actual-bu-data`} exact>
                                <ActualBUData xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route  path={`/dashboard/xpna/extended-planning/functional-planning`} exact>
                                <FunctionalPlanningComponent  />
                            </Route>
                            {/* <Route  path={`/dashboard/xpna/extended-planning/planning-models`} exact>
                                <PlanningModelsComponent  />
                            </Route> */}
                            {/* <Route  path={`/dashboard/xpna/extended-planning/compare-plans`} exact>
                                <ComparePlansComponent  />
                            </Route> */}
                            
                            <Route key="simulation-logic" path={`/dashboard/xpna/extended-planning/simulation-logic`} exact>
                                <SimulationLogic xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route key="simulation-logic" path={`/dashboard/xpna/extended-planning/simulation-logic/add-edit`} exact>
                                <AddEditSimulationLogic xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route key="bu-plan" path={`/dashboard/xpna/extended-planning/bu-plan`} exact>
                                <BUPlanData xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route key="actual-vs-BU-plan" path={`/dashboard/xpna/extended-planning/actual-vs-BU-plan`} exact>
                                <BUPlanvsActual xpnaMenu="1" isXPNA="true" />
                            </Route>
                            
                            <Route key="bu-plan-kpi-detailed" path={`/dashboard/xpna/extended-planning/bu-plan-kpi-detailed`} exact>
                                <BUPlanData planType="actual" xpnaMenu="1" isXPNA="true" />
                            </Route>
                            <Route key="actual-vs-BU-plan-kpi-detailed" path={`/dashboard/xpna/extended-planning/actual-vs-BU-plan-kpi-detailed`} exact>
                                <BUPlanvsActual planType="actual" xpnaMenu="1" isXPNA="true" />
                            </Route>

                            <Route key="bu-4-pm" path={`/dashboard/xpna/extended-planning/bu-4-pm`} exact>
                                <NewOriginationComponent xpnaMenu="2" isXPNA="true" />
                            </Route>
                            <Route key="esf-forecast" path={`/dashboard/xpna/extended-planning/esf-forecast`} exact>
                                <ESFComponent />
                            </Route>
                            <Route key="ecfm" path={`/dashboard/free-cashflow-analytics/free-cashflow`} exact>
                                <ECFM />
                            </Route>
                            <Route key="ecfm-dara-browser"  path={`/dashboard/user-file-upload/main-data-browser`} exact>
                                <ECFM isDataBrowser="true" />
                            </Route>
                            
                            <Route path={`/dashboard/impact-analysis`} exact>
                            <ImpactAnalysis />
                            </Route>
                            <Route path={`/dashboard/fcf-improvement`} exact>
                            <FCFImprovement />
                            </Route>
                            <Route path={`/dashboard/initiatives/cadence`} exact>
                            <CadenceBoard />
                            </Route>
                            <Route path={`/dashboard/initiatives/create`} exact>
                            <CreateInitiatives />
                            </Route>
                            <Route path={`/dashboard/initiatives/view`} exact>
                            <ViewInitiatives />
                            </Route>
                            <Route path={`/dashboard/dash-analysis/variance`} exact>
                            <VarianceAnalysis />
                            </Route>
                            <Route path={`/dashboard/artificial-intelligence`} exact>
                            <Artificial />
                            </Route>

                           
                            <Route path='/dashboard/user-file-upload/ebitdafileupload'>
									<EBITDAUploadComponent />
								</Route>
	
                            
                            <Route path={`/dashboard/planning`} exact>
                            <Planning />
                            </Route>

                                 
                            
                            <Route path={`/dashboard/dash-analysis/analytics-module`} exact>
                                <AnalyticsModule />
                            </Route>
                            <Route path={`/dashboard/free-cashflow-analytics/risk-management`} exact>
                                <RiskManagementModule />
                            </Route>
                            <Route path={`/dashboard/free-cashflow-analytics/opportunity-management`} exact>
                                <OpportunityModule />
                            </Route>
							<Route path='/dashboard/user-file-upload/p&lfileupload'>
								<ISUploadComponent />
							</Route>
							<Route path='/dashboard/user-file-upload/pabfileupload'>
								<PABUploadComponent />
							</Route>
                            <Route path='/dashboard/user-file-upload/impact-analysis-upload'>
								<ImpactAnalysisUpload />
							</Route>
							<Route path='/dashboard/user-file-upload/forecastfileupload'>
								<ForecastUploadComponent />
							</Route>
							{getAccessByMenuKey(menuData, 'auto_new_comment', 'view') && (
								<Route path='/dashboard/user-file-upload/New'>
									<NewUploadComponent />
								</Route>
							)}
							<Route path='/dashboard/dash-analysis/geo-location'>
								<GeoLocationComponent />
							</Route>
							<Route path='/dashboard/analytics-studio/data-report'>
								<DataReportAnalyticsStudio />
							</Route>
							<Route path='/dashboard/analytics-studio' exact>
								<AnalyticsStudioComponent />
							</Route>
						</Switch>
					</div>
				)}
			</section>
		</>
	);
};

export default withRouter(
	connect(
		(state) => {
			return {
				exitWithoutSaveFlag: state.dashboardState.exitWithoutSaveFlag,
				generalTableState: state.dashboardState.generalTableState,
			};
		},
		(dispatch) => {
			return {
				showExitWithoutSaveModal(data) {
					dispatch({
						type: 'SHOW_EXIT_WITHOUT_SAVE_MODAL',
						payload: data,
					});
				},
				fetchPlFileUploadError(data) {
					dispatch({
						type: 'FETCH_PL_FILE_UPLOAD_ERROR',
						payload: data,
					});
				},
			};
		}
	)(NestedList)
);
