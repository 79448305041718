import React from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import { CONFIG } from '../../../../config'
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

const validPassword = RegExp(/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/)


export class UserMenu extends React.Component {

  constructor(props) {
    super(props);
    this.ITEM_HEIGHT = 48;
    this.state = {
      anchorEl: null,
      profileModal: false,
      isPasswordValue: "",
      isConfirmPasswordValue: "",
      showPassword: false,
      firstName: '',
      lastName: '',
      showNewPassword: false,
      showConfirmPassword: false,
      showPasswordFields: false,
      imagePreview: null,
      OpenNA: false,
      remover:false,
      uploader:false,
      imageUploaded:null,
      prevPreview:null,
      error:"",
    }
  }

  componentDidMount() {
    const { userData } = this.props.authReducer
    this.setState({
      firstName: userData.name,
      lastName: userData.last_name,
      imagePreview: CONFIG.api_base_url + 'v1/user/profile/download/image'
    })
  }

  comnponentDidUpdate(prevProps) {
    const { userData } = this.props.authReducer

    if (this.props.authReducer.userData && prevProps.authReducer.userData &&
      this.props.authReducer.userData.name != prevProps.authReducer.userData.name) {

      this.setState({
        firstName: userData.name,
        lastName: userData.last_name
      })
    }

  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfileClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfileOptionClick = (option) => {
    if (option.id === 1) {
      this.setState({ profileModal: true })
    }
    if (option.id == 2) {
      this.handleLogoutClick()
    }
  } 

  handleLogoutClick = () => {
    this.props.performLogout()
  }

  handleImageOptions = () => {
    this.setState({ imagePreview: null })
    document.getElementById('upload-profile-pic').value = '';
    this.setState({remover:true})
  }

  onImageInputHandler = (event) => {
    this.setState({ imagePreview: URL.createObjectURL(event.target.files[0]) })
    console.log('kkkkk', event.target.files[0])
    this.setState({prevPreview:this.state.imagePreview})
    this.setState({imageUploaded:event.target.files[0]})
    this.setState({uploader:true})
    console.log(this.state.prevPreview, this.state.imageUploaded,this.state.uploader)
  }

  handleClickShowPassword = (type) => {
    if (type === 'password') {
      this.setState({ showPassword: !this.state.showPassword })
    }
    if (type === 'newPassword') {
      this.setState({ showNewPassword: !this.state.showNewPassword })
    }
    if (type === 'confirmPassword') {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value
    })
  }

  handleLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value
    })
  }

  handleProfileSaveClick = () => {
    const { firstName, lastName, imagePreview } = this.state;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      imagePreview:imagePreview,
    }
    if(this.state.remover){
       this.props.removeProfilePic()
      this.setState({remover:false})
    }
    console.log("uploader", this.state.uploader)
    if(this.state.uploader){
      this.props.uploadProfilePic({ file: this.state.imageUploaded })
      this.setState({uploader:false})
    }
    this.props.updateProfileData(payload);
  }

  handleChangePassword = (event) => {
    const err = validPassword.test(event.target.value) ? "" : "Note: Password must be 8-15 characters long including one uppercase, one lowercase, one alphanumeric and one special character. Password should not be same as the default password" 
    this.setState({ isPasswordValue: event.target.value })
    this.setState({error:err});
  }

  handleResetPasswordSubmit = () => {
    const { isPasswordValue, isConfirmPasswordValue } = this.state;
    if (isPasswordValue == '' || isConfirmPasswordValue == '') {
      this.setState({
        showPasswordFields: false,
        isPasswordValue: '',
        isConfirmPasswordValue: ''
      })
      return;
    } else {
      let payload = {
        new_password: isPasswordValue,
        confirm_password: isConfirmPasswordValue
      }
      this.props.updatePasswordChange(payload);
    }
  }

  render() {
    const loginState = this.props.authReducer
    const profileOptions = [
      { id: 1, value: "Profile" },
      { id: 2, value: "Logout" }
    ];
    const isPreview= this.state.prevPreview
    const profileImage= (
      {isPreview } ?
          <Avatar alt={this.state.firstName} 
          src={this.state.imagePreview} /> :
          <Avatar alt={this.state.firstName} 
          src={this.state.prevPreview} />
    )
    return (
      <>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          aria-controls={'long-menu'}
          onClick={this.handleProfileMenuOpen}
          color="inherit"
        >
          {profileImage}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          className="userSettingsMenu"
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleProfileClose}
          PaperProps={{
            style: {
              maxHeight: this.ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <div className='userNameTitle'>
            <div>{'Welcome, '} {loginState.userData.name}</div>
            <h5 className='secondary text-center mt-5 mb-5' style={{ textTransform: 'capitalize' }}>({loginState.userData.role.split('_').join(' ')})</h5>
          </div>
          {profileOptions.map((option) => (
            <MenuItem key={option.id} onClick={() => { this.handleProfileOptionClick(option) }}>
              {option.value}
            </MenuItem>
          ))}
        </Menu>

        <Modal
          open={this.state.profileModal}
          onClose={() => this.setState({profileModal:false})}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.profileModal}>
            <div className='customModal profileModal'>
              <div className='header'>
                <h2>Profile</h2>
                <Icon onClick={() => this.setState({ profileModal: false })}>close</Icon>
              </div>
              <div className='modal-content'>
                <div className='flex ai-flex-start mb-30'>
                  <div className='profileLeftSide text-center'>
                    
                    {this.state.imagePreview ?
                    <div>
                      <input accept="image/*" style={{ display: 'none' }} id='upload-profile-pic' type="file" onChange={this.onImageInputHandler} />
                      <label className="profilePic" htmlFor='upload-profile-pic'>
                        <Tooltip title='Click to change Profile Image'>
                          <img src={this.state.imagePreview} height="175" width="175" />
                        </Tooltip>
                        <div>
                          <Button color="secondary" onClick={this.handleImageOptions}>Remove</Button>
                        </div>
                      </label>
                    </div> 
                      :
                     <div> 
                      <label htmlFor='upload-profile-pic' className='emptyProfilePic'>
                        <Tooltip title='Upload Your Profile Image'>
                          <IconButton color="primary" aria-label="upload picture" component="span" style={{ padding: '0', margin: '0' }}>
                            <Icon style={{ fontSize: 150 }}>account_circle</Icon>
                          </IconButton>
                        </Tooltip>
                        <input accept="image/*" style={{ display: 'none' }} id='upload-profile-pic' type="file" onChange={this.onImageInputHandler} />
                      </label>
                    </div>
                    }

                  </div>
                  <div className='profileRightSide'>
                    <form noValidate autoComplete="off">
                      <FormControl fullWidth className='mb-30'>
                        <TextField
                          fullWidth
                          label="First Name"
                          value={this.state.firstName}
                          onChange={this.handleFirstNameChange}
                        />
                      </FormControl>
                      <FormControl fullWidth className='mb-30'>
                        <TextField
                          fullWidth
                          label="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleLastNameChange}
                        />
                      </FormControl>
                      <div className='passwordSection flex ai-flex-start jc-space-between'>
                        <div>
                          {this.state.showPasswordFields ?
                            <React.Fragment>
                              <FormControl className='mb-30'>
                                <InputLabel htmlFor="password">New Password</InputLabel>
                                <Input
                                  required
                                  type={this.state.showNewPassword ? 'text' : 'password'}
                                  id="password"
                                  onChange={(event) => {this.handleChangePassword(event)}} 
                                  labelWidth={70}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleClickShowPassword('newPassword')}
                                        onMouseDown={this.handleMouseDownPassword}
                                      >
                                        {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <FormControl className='mb-30'>
                                <InputLabel htmlFor="password">Confirm New Password</InputLabel>
                                <Input
                                  required
                                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                                  id="cnfPassword"
                                  onChange={(event) => this.setState({ isConfirmPasswordValue: event.target.value })}
                                  labelWidth={170}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleClickShowPassword('confirmPassword')}
                                        onMouseDown={this.handleMouseDownPassword}
                                      >
                                        {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            
                            </React.Fragment>
                            : ''}
                        </div>

                        {this.state.showPasswordFields ?
                          <Button className='toggleButton'
                            disabled={this.state.isPasswordValue !== this.state.isConfirmPasswordValue || this.state.error.length >0 }
                            variant="contained" color="primary" onClick={() => this.handleResetPasswordSubmit()}>Submit Password</Button>
                          :
                          <Button className='toggleButton' variant="contained" color="primary" onClick={() => this.setState({ showPasswordFields: true })}>Change Password</Button>
                        }
                      </div>
                    </form>
                  </div>
                </div>
                 <Grid container justify="flex-end" >
                    {this.state.error.length > 0 &&  <div  style={{fontSize: '15px' ,color: '#d32f2f'}}>{this.state.error}</div> }
                 </Grid>
                <Grid container justify="flex-end">
                  <Button variant="contained" color="primary" className='mr-10' onClick={this.handleProfileSaveClick}>Save</Button>
                  <Button variant="contained" onClick={() => this.setState({profileModal:false})}>Close</Button>
                </Grid>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

export default connect(
  (state) => {
    return {
      authReducer: state.authReducer
    };
  },
  (dispatch) => {
    return {
      performLogout() {
        dispatch({
          type: 'PERFORM_LOGOUT'
        });
      },
      updateProfileData(data) {
        dispatch({
          type: 'PERFORM_PROFILE_UPDATE',
          payload: data
        });
      },
      updatePasswordChange(data) {
        dispatch({
          type: 'PERFORM_RESET_PASSWORD',
          payload: data
        });
      },
      uploadProfilePic(data) {
        dispatch({
          type: 'PERFORM_PROFILE_PIC_UPLOAD',
          payload: data
        });
      },
      removeProfilePic() {
        dispatch({
          type: 'PERFORM_PROFILE_PIC_REMOVE'
        });
      },
    }
  }
)(UserMenu);



