import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import FunctionalPivotTableComponent from './TableComponent'
import { formatDate } from '../../../../utils/dateFormat'
import 'jspdf-autotable'
import CircularProgress from '@material-ui/core/CircularProgress';
import { numberWithCommas } from '../../../../utils/numberUtils'
import {
    downloadExcel,
    pdfFileName,
    mapExcelData,
    mapExcelHeader,
    mapExcelHeaderComparative,
    mapExcelDataPLComparative,
    mapDataPDF,
    mapPDFDataPLComparative
} from '../../../../utils/exportExcel'
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


export class AnalysisTnEComponent extends React.Component {
    constructor(props) {
        super(props);
        this.excelData = {};
        this.excelDataComparison = {};
        this.state = {
            loading: true,
            yearSelectorValue: props.incomeStatementState.defaultYear,
            isShowTsaChecked: false,
            doExport: false,
            exportFileName: '',
            excelRefresh: false,
            isAllFunc: true,
            budgets: [],
            showFunctionalDrilldownModal: false,
            NewBUSelectorValue : 'New BU 1',
            buSelectorValue : 'SUMMARY',
            reportType : props.reportType,
        }

    }


    componentDidMount() {
         var arr = [];

        let payload = {
            year : this.state.yearSelectorValue,
            bu : this.state.buSelectorValue,
            New_bu : this.state.NewBUSelectorValue,
            report_type : this.state.reportType,
        }

        this.props.getLastMonthTAndEAnalysis(payload);

    }

    fetchLastMonth = () => {
        this.props.getBUPLDefaultMonthData();
    }

    fetchTAndEAnalysisData = () => {
        console.log(this.state.reportType, "--report type 2")
        let payload = {
            year : this.state.yearSelectorValue,
            bu : this.state.buSelectorValue,
            New_bu : this.state.NewBUSelectorValue,
            report_type : this.state.reportType,
        }
        this.props.getTAndEAnalysis(payload)
    }

    handleYearChange = (event) => {
        if (event.target) {
            this.setState({
                yearSelectorValue: event.target.value,
            }, () => {
                this.fetchTAndEAnalysisData()
            })
        }
    };

    handleShowTsaChange = (event) => {
        this.setState(
            { isShowTsaChecked: event.target.checked },
            () => {
                this.fetchFunctionalPivotData()
            });
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.incomeStatementState,"this.props.incomeStatementState")
        const { yearFilterArr, defaultYear } = this.props.incomeStatementState;
        if (prevProps.incomeStatementState.yearFilterArr.length != yearFilterArr.length) {
            this.setState({
                yearSelectorValue: defaultYear,
            })
        }

        if (prevProps.incomeStatementState.drilldownLoader && !this.props.incomeStatementState.drilldownLoader) {
            this.setState({
                showFunctionalDrilldownModal: true,
                renderExcel : false
            })
        }
    }

    handleExcludeFilterChange = (event) => {
        if (event.target.value) {
            this.setState(
                { excludeDataFilter: event.target.value }, () => {
                    this.fetchFunctionalPivotData()
                })
        }
    };

    handleResetExcludeFilter = () => {
        this.setState(
            { excludeDataFilter: [] }, () => {
                this.fetchFunctionalPivotData()
            })
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

    unwindSubRows = (data) => {
        let newData = []
        data.forEach(x => {
            newData.push(x)
            if(x.sub_rows){
                x.sub_rows = this.unwindHeaderKeyVal(x.sub_rows)
                x.sub_rows.forEach(y => {
                    y.data_field = "     "+y.data_field 
                    newData.push(y)
                    if(y.sub_rows){
                        y.sub_rows = this.unwindHeaderKeyVal(y.sub_rows)
                        y.sub_rows.forEach(z => {
                            z.data_field = "     "+"     "+z.data_field 
                            newData.push(z)
                        })
                    }
                })
            }
        })
        return newData
    }

    getFileName = () => {
        const { monthSelectorValue, qtrSelectorValue, yearSelectorValue } = this.state
        let sel =  yearSelectorValue;
        return sel;
    }

    handleFileName = () => {
        const name = "T&E Analysis - "
        return pdfFileName(name)
    }

    formatComments = (data) => {
        data.map(x => {
            let comm = "";
            if (x.comments && x.comments.length) {
                x.comments.forEach((y, idx) => {
                    comm += y.created_by + ', ' + formatDate(y.created_at) + '\n' + y.comment;
                    if (idx != x.comments.length - 1) {
                        comm += "\n"
                    }
                })
            }
            if (x.sub_rows && x.sub_rows.length) {
                x.sub_rows = this.formatComments(x.sub_rows)
            }
            x.comment = comm
            return x
        })

        return data
    }

    handleExcelDownload = () => {
        let data = []
        let header = {};
        let merge = [];

        let datas = this.excelData
        header = datas.header;
        datas.data = this.unwindHeaderKeyVal(datas.data);
        datas.data = this.unwindSubRows(datas.data)
        data = mapExcelData(datas.data, header, "id")
        header = mapExcelHeader(header, "headerTitle")
       
        let fileName = " Income Statement - T&E Analysis - " + this.getFileName()
        if (this.financialDuration) {
            fileName = fileName + " - " + this.financialDuration
        }
        console.log(data, fileName, header, false, merge)
        const { currentValue, previousValue } = this.props.incomeStatementState;
        let sheetName = 'Data';
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }

    handleExcelDataChange = (data, header) => {
        this.excelData.data = data;
        this.excelData.header = header;
    }

    handleExcelDataChangeComparison = (data, header) => {
        this.excelDataComparison.data = data;
        this.excelDataComparison.header = header;
    }

    handleComparativeRadioChange = (event) => {
        const { defaultMonth } = this.props.incomeStatementState;
        const { tabSelectorValue, yearSelectorValue } = this.state;
        console.log(defaultMonth, 'kkkkk>>>>', tabSelectorValue, event.target.value)
        console.log("budgets", this.state.budgets);
        this.setState({
            comparativeSelectorValue: event.target.value,
            yearSelectorValue: (tabSelectorValue == 0 && event.target.value == 1) ? '' : yearSelectorValue
        }, () => {
            this.fetchFunctionalPivotData()
        })
    }

    handleCommentSendFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendPivotCommentData(commentPayload)
    }

    unwindHeaderKeyVal = (data) => {
        data.forEach(x => {
            var obj = x.header_key_value
            if (obj != undefined) {
                for (var prop in obj) {
                    x[prop] = obj[prop]
                }
            }
        })
        return data
    }

   

    

    handleCommentSendFunctionalFn = (data) => {
        const { qtrSelectorValue, monthSelectorValue, isShowTsaChecked, yearSelectorValue, comparativeSelectorValue, radioFilterValue, excludeDataFilter, budgets } = this.state;
        let payload = {
            yearmonth: monthSelectorValue,
            year: yearSelectorValue,
            quarter: qtrSelectorValue,
            budgetGroup: radioFilterValue,
            budgetGroupUser: budgets,
            excludeData: excludeDataFilter.join(','),
            tsa: isShowTsaChecked,
            isComparative: comparativeSelectorValue == 2
        }
        let commentPayload = {
            commentData: data,
            tablePayload: payload
        }
        this.props.sendFunctionalCommentData(commentPayload)
    }

    handleNumberTransform = (number) => {
        const isNegative = number < 0
        const positiveTransformedValue = isNegative ? Math.abs(Math.round(number)) : Math.round(number)
        let modifiedData = numberWithCommas(positiveTransformedValue)
        modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
        return modifiedData
    }

    handleNestedColumnCallback = (payload) => {
        payload.bu = this.state.buSelectorValue
        payload.New_bu = this.state.NewBUSelectorValue
        payload.report_type = this.state.reportType

        this.props.getTAndEAnalysisDrillDownData(payload);
    }
    handleDownloadNestedColumnCallback = (payload) => {
        let drilldownPayload = {
            ...payload,
            report_date: this.state.monthSelectorValue
        }
        this.props.downloadFunctionalDrilldownData(drilldownPayload)
    }

    functionalDrilldownModalCallBack = () => {
        this.excelData = []
        this.setState({ showFunctionalDrilldownModal: false },   this.updateExcel);
    }

    handleDrilldownExcelDownload = () => {
        const {drillDownData} = this.state;

        let payload= {
           ...drillDownData,
           export: true
        }
    }

    handleNewBUChange = (event) => {
        this.setState({ 
            NewBUSelectorValue : event.target.value 
        },
        this.fetchTAndEAnalysisData
        )
    }

    handleBUChange = (event) => {
        this.setState({
            buSelectorValue : event.target.value,
        },
        this.fetchTAndEAnalysisData
        )
    }

    render() {
        const { monthFilterArr, yearFilterArr, drilldownRowHeader, drilldownRowData  } = this.props.incomeStatementState
        const {  NewBUSelectorValue, buSelectorValue, tabSelectorValue, yearSelectorValue, excelRefresh,  comparativeSelectorValue, radioFilterValue, showFunctionalDrilldownModal } = this.state;
       
        return (
            <>
                {!monthFilterArr.length ? <div className='text-center'><CircularProgress /></div> :
                    <>
                        <div className='flex jc-space-between'>
                            <div>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} className='maxw-200'>
                            <Tooltip title="Back">
                                <Button variant='contained' color="primary" className='back-button' onClick={this.props.handleBack}><ChevronLeftIcon /></Button>
                            </Tooltip>
                            </Grid>

                            {yearFilterArr && yearFilterArr.length ?
                                <Grid item xs={12} sm={11} className='maxw-200'>
                                    <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
                                        <InputLabel>Annual</InputLabel>
                                        <Select
                                            value={yearSelectorValue}
                                            label="Annual"
                                            onChange={this.handleYearChange}
                                        >
                                            {yearFilterArr && yearFilterArr.length && yearFilterArr.map(item => {
                                                return <MenuItem value={item} key={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid> :
                                <></>}
                            </Grid>
                            <FormControl component="fieldset" className='mt-10'>
                                <RadioGroup name="radio"  value={NewBUSelectorValue}  onChange={this.handleNewBUChange}   className='radioGroupHorizontal'>
                                    <FormControlLabel value="New BU 1" control={<Radio color="primary" />} label="New BU 1" />
                                    <FormControlLabel value="New BU 2" control={<Radio color="primary" />} label="New BU 2" />
                                    <FormControlLabel value="SUMMARY" control={<Radio color="primary" />} label="Total" />
                                </RadioGroup>
                            </FormControl>

                            <div className="flex ai-center jc-space-between">
                            
                                        <FormControl component="fieldset">
                                            <RadioGroup name="radio" value={buSelectorValue} onChange={this.handleBUChange}  aria-label='radio filter'
							                className='blockRadio'>
                                                 <FormControlLabel
                                                    value={'SUMMARY'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'Summary'}
                                                />
                                               <FormControlLabel
                                                    value={'BU 1'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'BU 1'}
                                                />
                                                <FormControlLabel
                                                    value={'BU 2'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'BU 2'}
                                                />
                                                <FormControlLabel
                                                    value={'BU 3'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'BU 3'}
                                                />
                                                <FormControlLabel
                                                    value={'BU 4'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'BU 4'}
                                                />
                                                <FormControlLabel
                                                    value={'OH'}
                                                    key={0}
                                                    control={<Radio />}
                                                    label={'OH'}
                                                />
                                                </RadioGroup>
                                            </FormControl>
                                            </div>
                            </div>
                            { <Tooltip title={'Download to Excel'} className='pointer'>
                                <img
                                    src={DownloadExcel}
                                    className='pointer'
                                    height='30'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.handleExcelDownload();
                                    }} />
                            </Tooltip>}
                        </div>
                       
                       
                        <FunctionalPivotTableComponent
                            {...this.props}
                            handleExcelDataChange={this.handleExcelDataChange}
                            handleCommentSendFn={this.handleCommentSendFn}
                            handleNestedColumnCallback={this.handleNestedColumnCallback}
                            handleDownloadNestedColumnCallback={this.handleDownloadNestedColumnCallback}
                            radioFilterValue={radioFilterValue}
                            tabSelectorValue={tabSelectorValue}
                            excelRefresh={excelRefresh}
                            comparativeSelectorValue={comparativeSelectorValue}
                        /> 
                    </>
                }
            </>
        );
    }
}

export default
    connect(
        state => {
            return {
                ageingData: state,
                incomeStatementState: state.incomeStatement,
                budgetGp: state.authReducer.userData.budgetGroup,
            }
        },
        dispatch => {
            return {
                getTAndEAnalysis(data) {
                    dispatch({
                        type: 'FETCH_T_AND_E_ANALYSIS_DATA',
                        payload: data
                    });
                },
                getBUPLDefaultMonthData() {
                    dispatch({
                      type: 'FETCH_BUPL_DEFAULT_MONTH'
                    });
                },
                getLastMonthTAndEAnalysis(data){
                    dispatch({
                        type: 'FETCH_T_AND_E_ANALYSIS_DATA_LAST_MONTH',
                        payload: data
                    });
                },
                getTAndEAnalysisDrillDownData(data){
                    dispatch({
                        type: 'FETCH_T_AND_E_ANALYSIS_DATA_DRILL_DOWN',
                        payload: data
                    });
                },
                downloadFunctionalDrilldownData(data) {
                    dispatch({
                        type: 'DOWNLOAD_FUNCTIONAL_DRILLDOWN_DATA',
                        payload: data
                    });
                },
            };
        }
    )(AnalysisTnEComponent);

