import { Draggable } from "react-beautiful-dnd";
import { LoremIpsum } from "lorem-ipsum";
import { generateFromString } from "generate-avatar";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import EditIcon from '@material-ui/icons/Edit';

const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border: 3px solid white;
  border-radius: 50%;
`;

const CardHeader = styled.div`
  font-weight: 500;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;
const CardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DragItem = styled.div`
  border-radius: 2px;
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
  filter: drop-shadow(2px 2px 4px #C4C4C4);
`;

const lorem = new LoremIpsum();

const ListItem = ({ item, index }) => {
  const randomHeader = useMemo(() => lorem.generateWords(5), []);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CardHeader className={`header-item flex jc-flex-end ai-center ${item.status}`}><EditIcon fontSize="small"/></CardHeader>
            <span className="plan-detail">
                Plan 1: xxxxxxx<br/>
                L0 Date : 11/03/2022<br/>
                Cost: $1200<br/>
                Responsible Party: <br/>
                Status:<br/>
            </span>
          </DragItem>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
