import React from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function Table() {
  return (
      <table className="ReactTable -striped -highlight xpna-fcf-table">
        <div className="rt-table">
          <thead className="rt-thead -header">
            <tr className="rt-tr">
              <th className="rt-th text-left minw-300 pl-30">Client Name</th>
              <th className="rt-th text-left w-60 pl-20">FY</th>
              <th className="rt-th">1</th>
              <th className="rt-th">2</th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
            </tr>
            <tr className="rt-tr">
              <th className="rt-th text-left minw-300 pl-30">Year ending 31 March 202x</th>
              <th className="rt-th text-left w-60 pl-20">QTR</th>
              <th className="rt-th">1</th>
              <th className="rt-th">2</th>
              <th className="rt-th">3</th>
              <th className="rt-th">4</th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
              <th className="rt-th"></th>
            </tr>
           <tr className="rt-tr">
              <th className="rt-th minw-300"></th>
              <th className="rt-th text-left w-60 pl-20">Mo</th>
              <th className="rt-th">1</th>
              <th className="rt-th">2</th>
              <th className="rt-th">3</th>
              <th className="rt-th">4</th>
              <th className="rt-th">5</th>
              <th className="rt-th">6</th>
              <th className="rt-th">7</th>
              <th className="rt-th">8</th>
              <th className="rt-th">9</th>
              <th className="rt-th">10</th>
              <th className="rt-th">11</th>
              <th className="rt-th">12</th>
            </tr>
            <tr className="rt-tr">
              <th className="rt-th minw-300"></th>
              <th className="rt-th w-60 pl-20"></th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Act</th>
              <th className="rt-th">Fcst</th>
              <th className="rt-th">Fcst</th>
              <th className="rt-th">Fcst</th>
              <th className="rt-th">Fcst</th>
              <th className="rt-th">Fcst</th>
              <th className="rt-th">Fcst</th>
            </tr>
          </thead>
          <tbody className="rt-tbody">
            <tr className="rt-tr">
              <td className="rt-td minw-300">Revenue</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Interco Revenue</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Other Income</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr total">
              <td className="rt-td minw-300">Total</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Direct Cost of Revenue</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Fixed Variable</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr total">
              <td className="rt-td minw-300">Total</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Margin</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Overheads</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Net Income</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Add : Depreciation</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Interest</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Tax</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Amortization</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr total">
              <td className="rt-td minw-300">Total</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">EBITDA</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Working Capital Change</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300 pl-100">Receivables</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300 pl-100">Inventory</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300 pl-100">Payables</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr total">
              <td className="rt-td minw-300">Total</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Purchase / Sale of Assets</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Loan and Interest Payments</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Tax Payments</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Funds from/to Shareholders</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
              <td className="rt-td"></td>
            </tr>
            <tr className="rt-tr total">
              <td className="rt-td minw-300">Total</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
              <td className="rt-td">X</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Net Cash Change</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
              <td className="rt-td">Y</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Closing Cash Balance</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
              <td className="rt-td">Z</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Actual Cash Balance</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
              <td className="rt-td">AA</td>
            </tr>
            <tr className="rt-tr">
              <td className="rt-td minw-300">Delta</td>
              <td className="rt-td w-60 pl-20"></td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
              <td className="rt-td">0</td>
            </tr>
          </tbody>
        </div>
      </table>
  )
}

export default Table;
