import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import history from '../../../../../history'
import {
    fetchMonthlyStatusReportDataService,
    saveActionOwnerMonthlyStatus,
    getEmailTemplateMonthlyStatusActionService,
    saveEmailTemplateMonthlyStatusService,
    sendEmailMonthlyStatusService
} from './service'
import { store } from '../../../../../index'

function* fetchMonthlyStatusData(data) {
    yield put({ type: "FETCH_MONTHLY_STATUS_DATA_LOADER", loading: true });

    const response = yield call(fetchMonthlyStatusReportDataService, data.payload);

    console.log(response, "fetchMonthlyStatusReportDataService")

    if (response && response.status == 200) {
        if(data.payload.export == "true"){
            yield put({ type: "FETCH_MONTHLY_STATUS_DATA_LOADER", loading: false });
        }else{
            yield put({ type: "FETCH_MONTHLY_STATUS_DATA_SUCCESS", loading: false, data: response.data });
        }
    }

}

function* saveActionOwnerMonthlyStatusAction(data){
    // yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const response = yield call(saveActionOwnerMonthlyStatus, data.payload);
    if (response && response.status == 200) {
        toastr.success('Action Owner 2 saved successfully')
        let payload = {
            "region": data.payload.region,
            "new_bu": data.payload.new_bu
        }
        yield put({ type: "FETCH_MONTHLY_STATUS_DATA", payload });
    }else if (response && response.status == 400) {
        toastr.error(response && response.data && response.data.error && response.data.error.debug_msg)
    }
}

function* getTemplateEmailMonthlyStatusAction(data) {
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(getEmailTemplateMonthlyStatusActionService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
    }
}

function* saveTemplateEmailMonthlyStatus(data){
    yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: true });
    const response = yield call(saveEmailTemplateMonthlyStatusService, data.payload);
    if (response && response.status == 200) {
        toastr.success('Email Template Saved')
        yield put({ type: "FETCH_AR_OVERDUE_VIEW_EMAIL_LOADER", loading: false });
    }else{
         toastr.error(response.error)
    }
}

function* sendMailMonthlyStatus(data){
    yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_LOADER", loading: true });
    const response = yield call(sendEmailMonthlyStatusService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_AR_OVERDUE_ANALYSIS_ACTION_DATA_SUCCESS", data: response.data });
        toastr.success('Email sent successfully')
    }
}

export default function* actionWatcher() {
     yield takeLatest('FETCH_MONTHLY_STATUS_DATA', fetchMonthlyStatusData)
     yield takeLatest('UPDATE_ACTION_OWNER_MONTHLY_STATUS_ACTION', saveActionOwnerMonthlyStatusAction)
     yield takeLatest('FETCH_EMAIL_TEMPLATE_MONTHLY_STATUS', getTemplateEmailMonthlyStatusAction)
     yield takeLatest('SAVE_EMAIL_TEMPLATE_MONTHLY_STATUS',saveTemplateEmailMonthlyStatus)
     yield takeLatest('SEND_MAIL_MONTHLY_STATUS', sendMailMonthlyStatus)


    
}