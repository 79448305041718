import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";
import Modal from "./ModalCadence";
import { useSelector} from "react-redux";

const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const DroppableStyles = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: #d4d4d4;
`;

function DraggableElement(props) {

  const { prefix, elements, handleOpen, planTypeValue, dateStatusSelected } = props;
  const [elementsArray, setElementsArray] = React.useState([]);
  const ecfmState = useSelector(state => state.ecfmState);
  React.useEffect(() => {
    fillElements()
  }, [planTypeValue, dateStatusSelected,ecfmState.filter])

  // console.log(dateStatusSelected, "--ini lo selected")

  const fillElements = () => {
    let newElements = []
    if(planTypeValue) {
      elements.map(el => {
        if(el.kpi === planTypeValue) {
          newElements.push(el)
        }
      })
    }
    else {
      newElements = elements
    }
  if(newElements?.length > 0){
      newElements = newElements.filter(x=>{
        return dateStatusSelected.indexOf(x.date_status) > -1
      })

      setElementsArray(newElements)
    }
  }

  return (
      <DroppableStyles className="dnd-cadence-board-child">
      <ColumnHeader className={`header-card title-${prefix}`}><span className="text-title">{prefix}</span></ColumnHeader>
      <ColumnHeader className="total-card">Total Plans ({elementsArray.length})</ColumnHeader>
      <Droppable droppableId={`${prefix}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="list-item-card">
            {/* {elements.length > 0 && 
              elements.map((item, index) => (
                <ListItem key={item._id} item={item} index={index} handleOpen={handleOpen}/>
              ))
            } */}
            {elementsArray.length > 0 && 
              elementsArray.map((item, index) => (
                <ListItem key={item._id} item={item} index={index} handleOpen={handleOpen}/>
              ))
            }
          </div>
        )}
      </Droppable>
    </DroppableStyles>
  )

};

export default DraggableElement;
