import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DropzoneArea } from 'material-ui-dropzone'
import DownloadExcel from "../../../../images/download_excel.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse, lastDayOfMonth } from 'date-fns'

export default function SimpleModal(props) {

    React.useEffect(() => {
        handleResetPopUp();
        if (props.showModal) {
            loadAddData();
        }
    }, [props.showModal]);


    const handleClose = () => {
        props.modalCallBack();
    }

    const handleResetPopUp = () => {
        setBU("BU 1,BU 2");
        setLoadForecast("false");
        setReportDate(parse(props.selectedDate, 'dd-MMM-yyyy', new Date()));
        setBtnDisabled(true)
    }

    const loadAddData = () => {
        setBU("BU 1,BU 2")
    }

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [bu, setBU] = React.useState('');
    const handleChangeBU = (event) => {
        setBU(event.target.value);
    };

    const [reportDate, setReportDate] = React.useState(new Date());
    const [loadForecast, setLoadForecast] = React.useState("false");
    const handleChangeLoadForecast = (event) => {
        // console.log(event.target.value,"--forecast")
        setLoadForecast(event.target.value);
    };

    const handleSaverole = () => {
        var data = {}
        data.bu = bu;
        data.file = files[0];
        // data.load_forecast = loadForecast;
        data.load_forecast = bu.includes('BU 1') ? "true" : "false";
        data.report_date = format(lastDayOfMonth(reportDate), "dd-MMM-yyyy");

        props.saveCallback(data);
    }

    const handleDeleterole = () => {
        var data = {}
        data.deleteData = props.editData;
        props.deleteCallback(data);
        handleCloseDialog();
    }
    const [files, setFiles] = useState(true)
    const handleChangeFile = (file) => {
        setFiles(file);
    }
    React.useEffect(() => {
        if (
            files.length > 0 &&
            reportDate != ""
        )
            setBtnDisabled(false)
        else
            setBtnDisabled(true)
    }, [files])

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        handleResetPopUp();
        if (props.showModal) {
            loadAddData();
        }
    }, [props.showModal]);

    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }
        return <img
            {...iconProps}
            src={DownloadExcel}
            className='pointer'
            height='30'
        />
    }

    const body = (
        <div className='customModal automationModal'>
            <div className='header'>
                <h2>Upload Collection Data</h2>
                {!props.saveLoading && <Icon onClick={() => { handleClose() }}>close</Icon>}
            </div>
            <div className='modal-content'>
                {props.saveLoading ? <div className='text-center'><CircularProgress /></div> : ""}
                <React.Fragment >
                    <Grid container spacing={1} style={{ pointerEvents: props.saveLoading ? 'none' : '' }} >
                        <Grid item xs={3} className={'report-date-picker'} >
                            <InputLabel>Report Date</InputLabel>
                        </Grid>
                        <Grid item xs={4} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    openTo="month"
                                    size="small"
                                    format="MMM-yyyy"
                                    className='pr-3'
                                    views={["year", "month"]}
                                    value={reportDate}
                                    onChange={setReportDate}
                                    minDate={props.reportDateArr[props.reportDateArr.length - 2]}
                                    maxDate={props.reportDateArr[props.reportDateArr.length - 1]}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} >
                            <InputLabel>Please select the BU's for which files are being uploaded</InputLabel>
                        </Grid>
                        <Grid item xs={12} >
                            <RadioGroup name="bu" value={bu} onChange={handleChangeBU}>
                                <FormControlLabel value="BU 1,BU 2" control={<Radio />} label="BU 1, BU 2 (Actual and Forecast)" />
                                {/* <FormControlLabel value="LCS,DGS" control={<Radio />} label="LCS, DGS" /> */}
                                <FormControlLabel value="BU 2,BU 3,BU 4" control={<Radio />} label="BU 2, BU 3, BU 4 (Actual Only)" />
                                <FormControlLabel value="BU 4" control={<Radio />} label="BU 4 (Forecast Only)" />
                            </RadioGroup>
                        </Grid>
                        {/* <Grid item xs={12} >
                            <InputLabel>Load forecast data</InputLabel>
                        </Grid>
                        <Grid item xs={2} >
                            <RadioGroup name="forecast" value={loadForecast} onChange={handleChangeLoadForecast}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} style={{ pointerEvents: props.saveLoading ? 'none' : '' }} >
                        <DropzoneArea
                            filesLimit={1}
                            getPreviewIcon={handlePreviewIcon}
                            onChange={handleChangeFile.bind(this)}
                            acceptedFiles={['.xls', '.xlsx', '.xlsm']}
                            showFileNames={true}
                            showAlerts={false}
                            maxFileSize={500000000000}
                            dropzoneClass={'payment-dropzone'}
                        />
                    </Grid>
                    {!props.saveLoading &&
                        <Grid container justify="flex-end" className='mt-30'>
                            <Button onClick={handleSaverole} disabled={btnDisabled} variant="contained" color="primary" className='mr-10'>Submit</Button>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        </Grid>
                    }
                </React.Fragment>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.showModal}
            modalOptions={{ dismissible: false }}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}

        >
            <Fade in={props.showModal}>{body}</Fade>
        </Modal>
    );
}