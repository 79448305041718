import React, { useState, useEffect } from "react";
import HighchartComponent from './highchart'
import { useSelector } from "react-redux";

let options = {
    chart : {
        type : 'line'
    },
    title : {
        text : ''
    },
    subtitle : {
        text : ''
    },
    yAxis : {
        title : {
            text : '',
            useHTML : true
        },
    },
    xAxis : {
        categories : [],
        title : {
            text : '',
            useHTML : true
        },
        crosshair: true
    },
    tooltip : {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    legend : {
        layout : 'vertical',
        align : 'right',
        verticalAlign : 'middle',
        useHTML : true
    },

    series : []
}

function LineChart (props) {
    const freeCashFlowState = useSelector(state => state.freeCashFlowState);
    const { loading, chartRowData } = freeCashFlowState.analyticStudio;
    const [optionState, setOptionState] = useState({})

    useEffect(() => {handleCreateChartData()}, [chartRowData])

    const handleCreateChartData = () => {

       const namesArr = getNames();
       const categoriesArr = getCategories();
       const final = chartRowData.length ? new Array(namesArr.length).fill(0).map(() => new Array(categoriesArr.length).fill(null)) : [];
        
        const { 
            categoriesData,
            yAxisTitle,
            xAxisTitle,
            seriesData 
        } = createSeries(namesArr, categoriesArr, final)


        if (seriesData) {
            options.xAxis.title.text = xAxisTitle
            options.xAxis.categories = categoriesData;
            options.yAxis.title.text = yAxisTitle
            options.series = seriesData;
        }

        setOptionState(options)
    };

   
    const getNames = () =>{
        let namesArr = [];
        let namesObj = {};
        if (chartRowData.length){
        chartRowData.forEach((chart_item) =>{
            const dimensionName = chart_item.dimension.reduce((acc, currItem) => {
                const currName = currItem.value;
                if (acc) {return acc + "-" + currName} else return currName;
              }, "");
            for (const item of chart_item.measurement) {
                const name = dimensionName + "-" + item.label + "-" + item.type_of_data;
                if (!namesObj.hasOwnProperty(name)) {
                    namesArr.push(name)
                    namesObj[name] = name
                }
            }
        })}
        return namesArr;
    }

    const getCategories = () => {
        let categoriesArr = [];
        let categoriesObj = {};
        if (chartRowData.length){
        chartRowData.forEach((chart_item) => {
            for (const measurement of chart_item.measurement) {
              if (!categoriesObj.hasOwnProperty(measurement.period)) {
                categoriesArr.push(measurement.period);
                categoriesObj[measurement.period] = measurement.period
              }
            }
          })}
        return categoriesArr;
    }
   
    //helper for creating series data for one chartrowdata item
    const createSeriesObj = (object, names, categories, final) => {
        const dimensionName = object.dimension.reduce((acc, currItem) => {
          const currName = currItem.value;
          if (acc) {return acc + "-" + currName} else return currName;
        }, "");
      
        for (const measure of object.measurement) {
          const seriesName = dimensionName + "-" + measure.label + "-" + measure.type_of_data;
          const seriesPosition = names.indexOf(seriesName);
          final[seriesPosition][categories.indexOf(measure.period)] =measure.value;
        }
    };
    
    
    //Series Data for all the series in array of objects format ready to be fed into component
    const createSeriesData = (namesArr, categoriesArr, final) => {
        const series = [];
        if (chartRowData.length){
            for (const object of chartRowData) {
                createSeriesObj(object, namesArr, categoriesArr, final);
            }
            
            for (const name of namesArr) {
                series.push({
                name: name,
                data: final[namesArr.indexOf(name)]
                });
            }
        }
        return series;
    };
    

    const createSeries = (namesArr, categoriesArr, final) => {

        let seriesData = [];
        let xAxisTitle = 'Time Period';
        let yAxisTitle = 'Amount';
        let categoriesData = [];

        if (chartRowData.length) {
            seriesData = createSeriesData(namesArr,categoriesArr, final);
            categoriesData = categoriesArr;
        }

        return {
            categoriesData,
            yAxisTitle,
            xAxisTitle,
            seriesData
        }

    };

    return (
        optionState.series && optionState.series.length ?
        <div className="analytic-studio-chart line-chart">
            <HighchartComponent
                options={optionState}
            />
        </div> 
        : ""
    )

}

export default LineChart;
