import React from 'react';
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import EbitdaGridAll from "./EbitdaGridAll";
import '../../userManagement.scss'
import '../../../Automation/Automation.scss'
export class EBITDAUploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            loading: true,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <EbitdaGridAll></EbitdaGridAll>
            </React.Fragment>
        );
    }
}

export default
    connect(
        state => {
            return {
                // userState: state.userManagementState.userState,
                // authReducer: state.authReducer,
            }
        },
        dispatch => {
            return {

            };
        }
    )(EBITDAUploadComponent);