import Highcharts           from 'highcharts';
import HighchartsDrilldown  from 'highcharts/modules/drilldown'
import HighchartsMore       from 'highcharts/highcharts-more'
import HighchartsNoData     from 'highcharts/modules/no-data-to-display'
import HighchartsFunnel     from 'highcharts/modules/funnel'
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'


// check if HighchartsDrilldown has already been loaded
if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
    HighchartsDrilldown(Highcharts);
}

// check if HighchartsMore has already been loaded
if (!Highcharts.seriesTypes['gauge']) {
    HighchartsMore(Highcharts);
}

// check if HighchartsNoData has already been loaded
if (!Highcharts.Chart.prototype.hideNoData) {
    HighchartsNoData(Highcharts);
}

// check if HighchartsFunnel has already been loaded
if (!Highcharts.seriesTypes['pyramid']) {
   HighchartsFunnel(Highcharts);
}

// check if HighchartsSolidGauge has already been loaded
if (!Highcharts.seriesTypes['solidgauge']) {
   HighchartsSolidGauge(Highcharts);
}

export default Highcharts;