import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CHART_COLORS } from '../../../constant';
// import HighchartOptions from "./highcharts.options";


export default function CustomerChangeAnalysis () {
    const {improvementAnalysis: { improveCostAndResources: { costInventoryReceivablesColor, improvementPlanOneColor, improvementPlanTwoColor, improvementPlanThreeColor, improvementPlanFourColor, targetPerformanceColor } }} = CHART_COLORS;
    const seriesData= [15,21,16,25,15,18,19,17,16,20,22,19];
    return(
        <div className="mat-shadow-2"> 
                <HighchartsReact
                    highcharts={Highcharts}
                    options = {
                        {
                            title: {
                                text: 'Improve Cost and Resources',
                            },
                            
                            credits: {
                                enabled: false
                            },
                            legend: {
                                enabled:false,
                            },
                            subtitle : {
                                text :' Target Performance ',
                                align: 'right',
                                x: -10,
                                y: 210,
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                }
                            },

                            plotOptions: {
                                series: {
                                groupPadding: 0,
                                pointPadding: 0,
                                borderWidth: 0
                                }
                            },
                            
                            xAxis: [{
                                title:{
                                    text:" ",
                                    offset: 30,
                                },
                                visible:true,
                                categories: ['<b> Cost </b> <br> <b>Inventory</b> <br> <b>Receivables</b>', '<b>Improvement Plan 1</b>', '<b>Improvement Plan 2</b>', '<b>Improvement Plan 3</b>', '<b>Improvement Plan 4</b>',],
                                zoomEnabled: false,
                                
                            },{
                                gridLineWidth: 0,
                                lineWidth: 0,
                                opposite: true,
                                categories: ['<b>$m</b>', '<b>$m</b>','<b>$m</b>', '<b>$m</b>', '<b>$m</b>']
                            }],
                            yAxis:[            
                                {
                                title: {
                                    text: '$M',                              
                                },
                                max:100,
                                gridLineWidth: 0,
                                // minorGridLineWidth: 0,
                                visible:false,
                                
                                zoomEnabled:false,
                                tickInterval:10,
                            }],

                            dataLabels: {
                                enabled: true,
                                rotation: -90,
                                color: '#FFFFFF',
                                align: 'right',
                                formatter: function() {
                                    console.log(" this", this);
                                    return  this.low ? this.low : ""
                                },
                                y: 10, // 10 pixels down from the top
                                style: {
                                    fontSize: '13px',
                                    fontFamily: 'Verdana, sans-serif'
                                }
                            },
                            tooltip: {
                            enabled:false,
                            },
        
                            series: [{
                                type: 'columnrange',
                                showInLegend: false,  
                                data: [{
                                    low: 0,
                                    high: 100,
                                    l: "CURRENT PERFORMANCE",
                                    color: costInventoryReceivablesColor
                                },{
                                    low: 85,
                                    high: 100,
                                    color: improvementPlanOneColor
                                },{
                                    low: 65,
                                    high: 85,
                                    color: improvementPlanTwoColor
                                },{
                                    low: 65,
                                    high: 55,
                                    color: improvementPlanThreeColor
                                },{
                                    low: 50,
                                    high: 55,
                                    color: improvementPlanFourColor
                                }],
                                
                            },{
                                // type:'line',
                                // name:"Target Performance",
                                // lineWidth:2.5,
                                // marker:{enabled:false},
                                // data: [50,50,50,50,50]
                            },{
                                type:'line',
                                name:"Target Performance",
                                xAxis: 1,
                                lineWidth:2.0,
                                marker:{enabled:false},
                                data: [50,50,50,50,50],
                                color: targetPerformanceColor,

                            } ]
                        }
                    }
                />

            </div>
    )
}