import React, { useState, useEffect } from "react";
import HighchartComponent from './highchart'
import { useSelector } from "react-redux";

let options = {
    chart: {
        type: 'bar'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: [],
        title: {
            useHTML: true,
            text: ''
        },
        crosshair: true
    },
    yAxis: {
        title: {
            useHTML: true,
            text: ''
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
   
    series: []
}



function BarChart(props) {
    const freeCashFlowState = useSelector(state => state.freeCashFlowState);
    const { loading, chartRowData } = freeCashFlowState.analyticStudio;
    const [optionState, setOptionState] = useState({})

    useEffect(() => {
        handleCreateChartData()
    }, [])

    useEffect(() => {
        handleCreateChartData()
    }, [chartRowData])


    const handleCreateChartData = () => {

        const { 
            categoriesData,
            yAxisTitle,
            xAxisTitle,
            seriesData 
        } = createSeries()


        // if (seriesData.name) {
            options.xAxis.title.text = xAxisTitle
            options.xAxis.categories = categoriesData
            options.yAxis.title.text = yAxisTitle
            options.series = seriesData
        // }

        setOptionState(options)

      
    }

    const createSeries = () => {

        let seriesObj = {
            name: '',
            data: []
        }

        let tempSeriesObj = {
            
        }

        let categoriesData = []
        let xAxisTitle = ''
        let yAxisTitle = ''

        if (chartRowData.length) {
            let obj = {
                name: '',
                data: []
            }
            chartRowData.forEach((chart_item, chart_index) => {

                chart_item.measurement.forEach((msm_item, msm_index) => {
                    seriesObj.name = msm_item.label + msm_item.period
                    seriesObj.type_of_data = msm_item.type_of_data
                    seriesObj.data.push(msm_item.value)
                    yAxisTitle = msm_item.label
                    let key = msm_item.label + msm_index
                    if(tempSeriesObj[key]){
                        tempSeriesObj[key]['data'].push(msm_item.value)
                        tempSeriesObj[key]['type_of_data'] = msm_item.type_of_data
                        tempSeriesObj[key]['name'] = msm_item.label + msm_item.period

                    }else{
                        tempSeriesObj[key] = {
                            name: msm_item.label + msm_item.period,
                            data: [msm_item.value],
                            type_of_data: msm_item.type_of_data
                        }
                    }
                })

                chart_item.dimension.forEach((dim_item, msm_index) => {
                    categoriesData.push(dim_item.value)
                    xAxisTitle = dim_item.label
                })
            })
        }
        let seriesData = structureTheSeries(tempSeriesObj)


        return {
            categoriesData,
            yAxisTitle,
            xAxisTitle,
            seriesData
        }
    }


    const structureTheSeries = (obj) => {
        let seriesArr = []

        for(let item in obj){
            seriesArr.push(obj[item])
        }

        return seriesArr
    }

    return (
        optionState.series && optionState.series.length ?
        <div className="analytic-studio-chart column-chart">
            <HighchartComponent
                options={optionState}
            />
        </div> 
        : ""
    )
    
}

export default BarChart;
