import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableComponent from './TableComponent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadExcel from "../../../../src/images/download_excel.svg";
import FileUploadIcon from "../../../../src/images/isMain.png";

import Button from '@material-ui/core/Button';
import './index.scss'
import FileUploadPopUp from '../Shared/FIleUploadPopUp'

let savePayload = {}

const rollingViewMap = {
  0: '',
  1: 'monthly',
  2: 'quarterly',
  3: 'yearly'
}

export default function (props) {
  const [qtrSelectorValue, setQtrSelectorValue] = useState('')
  const [yearSelectorValue, setYearSelectorValue] = useState('')
  const [simulationSelectorValue, setSimulationSelectorValue] = useState('')
  const [isEditData, setIsEditData] = useState(false);
  const extendedPlanningState = useSelector(state => state.extendedPlanning);
  const { actualBUData, savePlanData , planBUData } = extendedPlanningState
  const { filter, simulation } = actualBUData
  const {  headerText, detailText, updatedOn } = simulation
  const { simulationArr ,yearArr, qtrArr, monthArr, defaultDate } = filter
  const dispatch = useDispatch();
  const [monthSelectorValue, setMonthSelectorValue] = useState('')

  const [radioSelectorValue, setRadioSelectorValue] = useState('0')
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleMonthChange = (event) => {
    setYearSelectorValue('')
    setQtrSelectorValue('')
    setMonthSelectorValue(event.target.value)
  }
  const handleQtrChange = (event) => {
    setQtrSelectorValue(event.target.value)
    setYearSelectorValue('')
    setMonthSelectorValue('')
  }
  const handleYearChange = (event) => {
    setYearSelectorValue(event.target.value)
    setQtrSelectorValue('')
    setMonthSelectorValue('')
  }

  const handleSimulationChange = (event) => {
    setSimulationSelectorValue(event.target.value)
  }

  useEffect(() => {
    if (defaultDate) {
      setMonthSelectorValue(defaultDate)
    }

    if(simulationArr){
      simulationArr.forEach(x=>{
        if(x.is_default){
          setSimulationSelectorValue(x._id);
        }
      })
    }
  }, [defaultDate])

  useEffect(() => {
    handleFetchData()
  }, [monthSelectorValue, qtrSelectorValue, yearSelectorValue, simulationSelectorValue])

  useEffect(() => {
    savePlanData && handleFetchData()
  }, [savePlanData])

  useEffect(() => {
    let thisMonth = monthSelectorValue
    if (radioSelectorValue == 1 || radioSelectorValue == 0) {
      setMonthSelectorValue(defaultDate)
      setQtrSelectorValue('')
      setYearSelectorValue('')
      if(thisMonth == defaultDate){
        handleFetchData()
      }
    }else if(radioSelectorValue == 2) {
      setQtrSelectorValue(qtrArr[0].value)
      setMonthSelectorValue('')
      setYearSelectorValue('')
    }else {
      setYearSelectorValue(yearArr[0].value)
      setMonthSelectorValue('')
      setQtrSelectorValue('')
    }
  },[radioSelectorValue])

  const handleFetchData = (isExport = false) => {
    const payload = {
      export: isExport,
      year: yearSelectorValue ? yearSelectorValue : undefined,
      simulation_id: simulationSelectorValue ? simulationSelectorValue : undefined,
      quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
      yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
      view_type: 'view_bySummary',
      view_period: rollingViewMap[radioSelectorValue],
      plan_type: props.planType
    }

    dispatch({
      type: "FETCH_BU_PLAN_TABLE_DATA",
      payload
    })
  }

  const handleEditData = () => {

    setIsEditData(!isEditData)
    dispatch({
      type: "EDIT_BU_PLAN_DATA"
    })
  }

  const handleRadioChange = (event) => {
    setRadioSelectorValue(event.target.value)
  }

  const createSavePayload = () => {
    const payload = []
    Object.keys(savePayload).forEach(item => {
      payload.push(savePayload[item])
    })

    return payload
  }

  const handleSavePlanData = () => {

    const payload = createSavePayload()
    dispatch({
      type: "SAVE_BU_PLAN_DATA",
      payload
    })
  }

  useEffect(() => {
    savePayload = {}
  }, [isEditData])


  const handleSaveChange = (_value, props, item, parentData) => {
    const uniqKey = props.channel + '_' + [parentData.key] + '_' + [item.key]
    if (savePayload[uniqKey]) {
      savePayload[uniqKey] = {
        ...savePayload[uniqKey],
        [item.key]: Number(_value)
      }
    } else {
      savePayload[uniqKey] = {
        ...props.sub_column['info'],
        year: yearSelectorValue ? yearSelectorValue : undefined,
        quarter: qtrSelectorValue ? qtrSelectorValue : undefined,
        yearmonth: monthSelectorValue ? monthSelectorValue : undefined,
        [item.key]: Number(_value)
      }
    }
  }

  const handleFileUploadSave = (data) => {
    const payload = {
        ...data,
        view_period: rollingViewMap[radioSelectorValue],
        period: monthSelectorValue ? monthSelectorValue : yearSelectorValue ? yearSelectorValue : qtrSelectorValue ? qtrSelectorValue : ''
    }
    dispatch({
      type: "UPLOAD_BU_PLAN_EXCEL",
      payload
    })
  }

    const handleUploadExcel = () => {
        setShowUploadModal(true)
    }

    const modalCallBack = () => {
      setShowUploadModal(false)
    };

    useEffect(() => {
      if(!planBUData.loading){
        modalCallBack();
        handleFetchData();
      }
    }, [planBUData.loading])

  return (
    <div>
      <div className='flex jc-space-between'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Month</InputLabel>
              <Select
                value={monthSelectorValue}
                label="Month"
                disabled={radioSelectorValue == 2 || radioSelectorValue == 3}
                onChange={handleMonthChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {monthArr && monthArr.length && monthArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Quarter</InputLabel>
              <Select
                value={qtrSelectorValue}
                label="Quarter"
                disabled={radioSelectorValue == 1 || radioSelectorValue == 3}
                onChange={handleQtrChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {qtrArr && qtrArr.length && qtrArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Annual</InputLabel>
              <Select
                value={yearSelectorValue}
                label="Annual"
                disabled={radioSelectorValue == 1 || radioSelectorValue == 2}
                onChange={handleYearChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {yearArr && yearArr.length && yearArr.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          { props.planType != 'actual'  ? 
          <Grid item xs={12} sm={3} className='maxw-200'>
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <InputLabel>Simulation</InputLabel>
              <Select
                value={simulationSelectorValue}
                label="Simulation"
                onChange={handleSimulationChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {simulationArr && simulationArr.length && simulationArr.map(item => {
                  return <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          : '' }
        </Grid>
        <Grid container justifyContent='flex-end'>
        <div className='flex jc-flex-end mt-10' style={{alignItems:'center'}}>
          <Button variant='outlined' color="primary" className='newButtonOutlined btnBlue btnEdit uppercase' onClick={() => { handleEditData() }}>Edit</Button>
          <Button variant='contained' className='ml-10 mr-10 newButton btnBlue btnSave uppercase' onClick={() => handleSavePlanData()}>Save</Button>
          <Tooltip title={'Download to Excel'} className='pointer mr-10'>
            <img onClick={() => { handleFetchData(true) }} src={DownloadExcel} className='pointer' height='30' />
          </Tooltip>
           { rollingViewMap[radioSelectorValue] == 'monthly' ?
              <Tooltip title={'Upload Excel'} className='pointer'>
                  <img
                      src={FileUploadIcon}
                      className='pointer'
                      height='30'
                      onClick={(event) => {
                          event.preventDefault();
                          handleUploadExcel();
                      }} />
            </Tooltip> : 
            <Tooltip title={'Upload Excel'} className='pointer'>
            <img
            src={FileUploadIcon}
            className='pointer'
            height='30'
            onClick={(event) => {
                event.preventDefault();
                handleUploadExcel();
            }} />
          </Tooltip>
            }
        </div>
        </Grid>
      </div>
      <div>
        <FormControl component="fieldset">
          <RadioGroup name="radio" value={radioSelectorValue} onChange={handleRadioChange} className='radioGroupHorizontal'>
            <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Summary" />
            <FormControlLabel value={'1'} control={<Radio color="primary" />} label="Monthly Rolling View" />
            <FormControlLabel value={'2'} control={<Radio color="primary" />} label="Quarterly Rolling View" />
            <FormControlLabel value={'3'} control={<Radio color="primary" />} label="Yearly Rolling View" />
          </RadioGroup>
        </FormControl>
      </div>
      <br />
      <div>
        <TableComponent
          isEditData={isEditData}
          handleSaveChange={handleSaveChange}
        />
      </div>
      { headerText != '' ? 
          <div className='mt-10 simulation-text '>
              <div>{ headerText + ' - ' + updatedOn}</div>
              <div className='simulation-detail' dangerouslySetInnerHTML={{ __html: detailText}}> 
              </div>
          </div>
      : '' }
      {/* <div className='flex jc-flex-end mt-10'>
        <Button variant='outlined' color="primary" className='newButtonOutlined btnBlue btnEdit uppercase' onClick={() => { handleEditData() }}>Edit</Button>
        <Button variant='contained' className='ml-10 newButton btnBlue btnSave uppercase' onClick={() => handleSavePlanData()}>Save</Button>
      </div> */}

      <FileUploadPopUp
            saveLoading={planBUData.loading}
            showModal={showUploadModal}
            modalCallBack={modalCallBack}           
            saveCallback={handleFileUploadSave}
          />

    </div>
  )
}