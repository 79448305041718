import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Map from './map.jsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SmsIcon from '@material-ui/icons/Sms';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { APP_ICONS } from '../../../constant';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// import FailureMechanism from './failure.jsx';
// import PreventiveMeasure from './preventive.jsx';
// import RCATable from './table.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const { downloadExcelIcon, downloadPdfIcon } = APP_ICONS;

function createData(name, t360, t90, t30, t1, tPlus1, tPlus30, detail) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30, detail};
}

function createDetail(name, t360, t90, t30, t1, tPlus1, tPlus30) {
  return { name, t360, t90, t30, t1, tPlus1, tPlus30};
}

const rows = [
  createData(
    'First Class', "", "", "", "", "", "",
    [
     createDetail("Number of seats","", "", "", "", "", ""),
     createDetail("Number of passengers","", "", "", "", "", ""),
     createDetail("Occupancy","", "", "", "", "", "")
    ]
  ),
  createData(
    'Business Class',"", "", "", "", "", "",
    []
  ),
  createData(
    'Premium Economy',"", "", "", "", "", "",
    []
  ),
  createData(
    'Economy',"", "", "", "", "", "",
    []
  ),
  createData(
    'Average Flight Occupancy',"", "", "", "", "", "",
    []
  )
];

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        expandComponent.map(detail => (
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell width="200">{detail.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
         </TableRow>
        ))
      )}
    </>
  );
}

export default function RouteOperational() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
        <Grid item xs={12} className="ml-10 mt-10">
            <AppBar position="static" color="default" className="custom-tabs-box">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Load Factor" {...a11yProps(0)} />
                <Tab label="Fuel Consumption" {...a11yProps(1)} />
                <Tab label="Crew Details" {...a11yProps(2)} />
                <Tab label="On time performance" {...a11yProps(3)} />
                <Tab label="Flight Details" {...a11yProps(4)} />
                <Tab label="Events" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
        </Grid>
        
        <TabPanel value={value} index={0} className="tab-panel">
           <div className="flex ai-center mt-20">
             <Grid item xs={9} className="form-group flex">
              <FormControl variant="outlined" size="small" fullWidth className="filter">
                <InputLabel htmlFor="outlined-age-native-simple">Filter 1</InputLabel>
                <Select
                  native
                  label="Age"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth className="filter">
                <InputLabel htmlFor="outlined-age-native-simple">Filter 2</InputLabel>
                <Select
                  native
                  label="Age"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth className="filter">
                <InputLabel htmlFor="outlined-age-native-simple">Filter 3</InputLabel>
                <Select
                  native
                  label="Age"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={3} className="btn-action flex jc-flex-end ai-center ml-30">
                  <Tooltip title={'Download to Excel'}>
                    <img
                      src={downloadExcelIcon}
                      height='35'
                      className='download-icon mr-10'
                    />
                  </Tooltip>
                  <Tooltip title={'Download to Pdf'}>
                    <img
                      src={downloadPdfIcon}
                      height='35'
                      className='download-icon'
                    />
                  </Tooltip>
              </Grid>
            </div>
            <Grid item xs={12} className="mt-20">
              <Table className="custom-table" aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell rowspan={2} padding="checkbox"></TableCell>
                      <TableCell rowspan={2} width="200"></TableCell>
                      <TableCell colspan={4}>Q1 FY 21</TableCell>
                      <TableCell colspan={4}>Q2 FY 21</TableCell>
                      <TableCell colspan={4}>Q3 FY 21</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <ExpandableTableRow
                        key={row.name}
                        expandComponent={row.detail}
                      >
                        <TableCell component="th" scope="row">{row.name}</TableCell>
                        <TableCell width="200"></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </ExpandableTableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
        </TabPanel>
    </Grid>
  );
}
