import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import BarChart from './BarChart';
import PieChart from './PieChart';
import MapsChart from './MapsChart';
import ColumnChart from './ColumnChart';
import LineChart from './LineChart';
import StackedBarChart from './StackedBarChart';
import './index.css';

export class ReportChartComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			loading: false,
			isTranspose: false,
			showMore: {
				uiTypes: ['table'],
				chartType: 'none',
			},
		};
	}

	fetchReport = (isExport = false) => {
		const { isTranspose } = this.state;
		const { columnArr, rowArr } = this.props;
		let payload = {
			isTranspose,
			export: isExport,
			columnArr,
			rowArr,
		};
		this.props.fetchReportTableData(payload);
	};

	handleGenerateReport = () => {
		this.fetchReport();
	};

	handleTransposeData = () => {
		this.setState({ isTranspose: !this.state.isTranspose }, () => {
			this.fetchReport();
		});
	};

	render() {
		const {
			chartType,
			freeCashFlowState: {
				analyticStudio: { loading },
			},
		} = this.props;

		const getChart = () => {
			switch (chartType) {
				case 'bar':
					return <BarChart />;
				case 'column':
					return <ColumnChart />;
				case 'pie':
					return <PieChart />;
				case 'line':
					return <LineChart />;
				case 'stacked':
					return <StackedBarChart />;
				case 'map' :
					return <MapsChart />;
				default:
					return (
						<p className='text-center'>
							<i>Work in Progress</i>
						</p>
					);
			}
		};

		return (
			<div className='report-chart-analyticStudio'>
				<div className='report-chart-analyticStudio-header'>
					<p>Report Chart</p>
				</div>
				{loading ? (
					<div className='text-center mt-10'>
						<CircularProgress />
					</div>
				) : (
					getChart()
				)}
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			freeCashFlowState: state.freeCashFlowState,
		};
	},
	(dispatch) => {
		return {
			fetchReportTableData(data) {
				dispatch({
					type: 'FETCH_NEW_DATA_ANALYTIC_STUDIO',
					payload: data,
				});
			},
		};
	}
)(ReportChartComponent);
