
import React from 'react';
import ReactTable from "react-table-6";
import Radio from '@material-ui/core/Radio';
import "react-table-6/react-table.css";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DownloadExcel from "../../../../../src/images/download_excel.svg";
import { numberWithCommas } from '../../../../utils/numberUtils'
import NewPagination from "../../../common/components/CustomPagination";
import FormControl from '@material-ui/core/FormControl';
import { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Grid, InputLabel, MenuItem,  Select, Button } from '@material-ui/core';

export default function SimpleModal(props) {

    const handleClose = () => {
        props.modalCallBack(false);
    };

    const [showRadioTrade, setShowRadioTrade] = useState(false)
    const [radioValue, setRadioValue] = useState('trade')
    const [regionFilter, setRegionFilter] = useState([])
    const [regionFilterSecond, setRegionFilterSecond] = useState([])
    const [plantFilter, setPlantFilter] = useState([])
    const [dealerFilter, setDealerFilter] = useState([])
    const [customerFilter, setCustomerFilter] = useState([])
    const [costCenterFilter, setCostCenterFilter] = useState([])
    const [regionValue, setRegionValue] = useState([])
    const [regionValueSecond, setRegionValueSecond] = useState([])
    const [plantValue, setPlantValue] = useState([])
    const [dealerValue, setDealerValue] = useState([])
    const [customerValue, setCustomerValue] = useState([])
    const [costCenterValue, setCostCenterValue] = useState([])

    const [rowData, setRowData] = useState([])
    const [rowDataSecond, setRowDataSecond] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [headerDataSecond, setHeaderDataSecond] = useState([])

    
    const modifyNewColumnData = (columnData, operations) => {

		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
            if (stringVersion.includes('.0000')){
			    modifiedData = columnData.toFixed(6);
            }else if (stringVersion.includes('.00')){
			    modifiedData = columnData.toFixed(4);
            }else{
			    modifiedData = columnData.toFixed(2);
            } 
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};

const handleSubColumnChangeHeader = (data, parentData) => {
    let selfProps = props
    if (data && data.length) {
        return data.map((item, i) => {
            var isManualKey = false;
            return {
                Header: item.label,
                id: item.key,
                Cell: (props) => {
                    
                  
                    item['operation'] =  '';
                    let ori = selfProps.isExpandable ? props.row.original : selfProps.isInvoiceDetailModal ? props.row.original : props.original

                    return (
                        <div >
                            {
                                <span className=''>{props.original.sub_column && props.original.sub_column[parentData.key] && props.original.sub_column[parentData.key][item.key] ? modifyNewColumnData(props.original.sub_column[parentData.key][item.key], props.original.sub_column[parentData.key]["operations"], item.key) : '-'}</span>
                            }
                        </div>
                    )
                },
                style: {
                    fontSize: '12px',
                    borderRight: i == data.length - 1 ? '1px solid #423970' : '',
                    cursor: isManualKey && isManualKey == true ? 'pointer' : ''
                    
                    // cursor: this.props.tabSelectorValue == 3 || item.key == 'sub_total' || item.key == 'grand_total' ? '' : 'pointer'
                },
                headerStyle: {
                    overflow: 'visible',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    borderRight: i == data.length - 1 ? '1px solid #423970' : ''
                }
            }
        })
    }
}

const handleDynamicWidth = (item) => {
    let widthSize = 100;
    switch (item.key) {
      case 'region':
        widthSize = 150;
        break;
      default:
        break;
    }
    return widthSize;
  };

  const changeKeysOfHeader = (data, type) => {
    let selfProps = props
    let tableDataHeader = []
    if (data && data.length) {
        data = data.filter(x => { return x.key != "collaborate" })
        tableDataHeader = data.map((item, i) => {
            if(item.sub_headers){
                return {
                    Header:  item.label,
                    id: item.key,
                    columns: handleSubColumnChangeHeader(item.sub_headers, item),
                    style: {
                        borderRight: '1px solid #423970',
                    },
                    headerStyle: {
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        borderRight:'1px solid #423970'
                    }
                }
            }else{
                let myClassNameHeader =""
                return {
                    
                    Header: <div className={'flex ai-center jc-center ' + myClassNameHeader}>
                        <span style={{ lineHeight: 1.5 }}>{item.label}</span>
                        {item.isSorted && item.isSorted === 'asc' && <ArrowDropDownIcon fontSize="small" />}
                        {item.isSorted && item.isSorted === 'desc' && <ArrowDropUpIcon fontSize="small" />}
                    </div>,
                    HeaderLabel: item.label,
                  
                    id: item.key,
                    accessor:item.key,

                   
                    Cell: (props) => {
                       

                        let ori = selfProps.isExpandable ? props.row.original : selfProps.isInvoiceDetailModal ? props.row.original : props.original
                        if (!ori) {
                            ori = props.original
                        }
                      

                       
                        return (
                            <div className={'text-center ' }>
                                {
                                    modifyNewColumnData(
                                        ori[item.key],
                                        ori['operations']
                                  )

                                    }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: "break-spaces",
                        overflow: "visible",
                        wordBreak: 'break-word',

                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        // wordBreak: 'break-word',
                        width: "50px !important",
                    }
                }
            }
        })
    }
    return tableDataHeader
}


function onlyUnique(value, index, array) {
    if(value == ""){
        return false
    }

    return array.indexOf(value) === index;
  }

React.useEffect(() => {
    let region = rowData.map(x=>{ return x.region })
    let plant = rowData.map(x=>{ return x.plant })
    let dealer = rowData.map(x=>{ return x.dealer })
    let costCenter = rowData.map(x=>{ return x.cost_center })
    
    if(regionValue.length == 0 )
    setRegionFilter(region.filter(onlyUnique))
    if(plantValue.length == 0)
    setPlantFilter(plant.filter(onlyUnique))
    if(dealerValue.length == 0)
    setDealerFilter(dealer.filter(onlyUnique))
    if(costCenterValue.length == 0)
    setCostCenterFilter(costCenter.filter(onlyUnique))

}, [rowData]);

React.useEffect(() => {
    let region = rowDataSecond.map(x=>{ return x.region })
    let customer = rowDataSecond.map(x=>{ return x.customer })
    
    if(regionValueSecond.length == 0 )
    setRegionFilterSecond(region.filter(onlyUnique))
    if(customerValue.length == 0)
    setCustomerFilter(customer.filter(onlyUnique))

}, [rowDataSecond]);

React.useEffect(() => {
    setShowRadioTrade(false)


    if(props.data.rowDataSecond && props.data.rowDataSecond.length > 0){
        setHeaderData(changeKeysOfHeader(props.data.rowHeader))
        setHeaderDataSecond(changeKeysOfHeader(props.data.rowHeaderSecond))
        setRowData(props.data.rowData)
        setRowDataSecond(props.data.rowDataSecond)
        setShowRadioTrade(true)
    }else if(props.data.rowData && props.data.rowData.length > 0){
        setHeaderData(changeKeysOfHeader(props.data.rowHeader))
        setRowData(props.data.rowData)
        if(radioValue == 'non-trade'){
            setRadioValue('trade')
        }
    }

    setRegionFilterSecond([])
    setRegionFilter([])
    setPlantFilter([])
    setCustomerFilter([])
    setDealerFilter([])
    setCostCenterFilter([])

    setRegionValueSecond([])
    setRegionValue([])
    setPlantValue([])
    setCustomerValue([])
    setDealerValue([])
    setCostCenterValue([])

    console.log(props,headerData,rowData, "--yw props")
}, [props.data.rowData]);

const handleReset = () => {
    setRegionValueSecond([])
    setRegionValue([])
    setPlantValue([])
    setCustomerValue([])
    setDealerValue([])
    setCostCenterValue([])

    setRowData(props.data.rowData)
    setRowDataSecond(props.data.rowDataSecond)
}

const handleExcelDownload = () => {
    props.handleExcelDownload()
}

const handleRadioChange = (event) => {
    setRadioValue(event.target.value) 
}

const handleRegionChange = (event) => {
    setRegionValue(event.target.value) 
    let data = props.data.rowData.filter(x=>{ return event.target.value.length == 0 ? true : event.target.value.indexOf(x.region) > -1 })
    data = data.filter(x=>{ return dealerValue.length == 0 ? true : dealerValue.indexOf(x.dealer) > -1 })
    data = data.filter(x=>{ return customerValue.length == 0 ? true : customerValue.indexOf(x.customer) > -1 })
    setRowData(data.filter(x=>{ return plantValue.length == 0 ? true : plantValue.indexOf(x.plant) > -1 }))
}

const handleRegionChangeSecond = (event) => {
    setRegionValueSecond(event.target.value) 
    let data = props.data.rowDataSecond.filter(x=>{ return event.target.value.length == 0 ? true : event.target.value.indexOf(x.region) > -1 })
    data = data.filter(x=>{ return customerValue.length == 0 ? true : customerValue.indexOf(x.customer) > -1 })
    setRowDataSecond(data)
}

const handlePlantChange = (event) => {
    setPlantValue(event.target.value) 
    let data = props.data.rowData.filter(x=>{ return regionValue.length == 0 ? true : regionValue.indexOf(x.region) > -1 })
    setRowData(data.filter(x=>{ return event.target.value.length == 0 ? true :event.target.value.indexOf(x.plant) > -1 }))
}

const handleCustomerChange = (event) => {
    setCustomerValue(event.target.value) 
    let data = props.data.rowDataSecond.filter(x=>{ return regionValueSecond.length == 0 ? true : regionValueSecond.indexOf(x.region) > -1 })
    setRowDataSecond(data.filter(x=>{ return event.target.value.length == 0 ? true :event.target.value.indexOf(x.customer) > -1 }))
}

const handleDealerChange = (event) => {
    setDealerValue(event.target.value) 
    let data = props.data.rowData.filter(x=>{ return regionValue.length == 0 ? true : regionValue.indexOf(x.region) > -1 })
    setRowData(data.filter(x=>{ return event.target.value.length == 0 ? true :event.target.value.indexOf(x.dealer) > -1 }))
}

const handleCostCenterChange = (event) => {
    setCostCenterValue(event.target.value) 
    let data = props.data.rowData
    setRowData(data.filter(x=>{ return event.target.value.length == 0 ? true :event.target.value.indexOf(x.cost_center) > -1 }))
}

const body = (
    <div className='customModal'>
        <div className='header'>
        <h2 className='text-center'>{props.header}</h2>
        <Icon onClick={() => { handleClose() }}>close</Icon>
        </div>
        <div className='modal-content pt-5'>
    <Grid container spacing={3}>
        {   showRadioTrade ?
   
      <Grid item xs={2} sm={2} >
               <FormControl component="fieldset" >
               <RadioGroup name="radio"  value={radioValue}  onChange={handleRadioChange}   className='radioGroupHorizontal mt-10'>
                   <FormControlLabel value="trade" control={<Radio color="primary" />} label="Trade" />
                   <FormControlLabel value="non-trade" control={<Radio color="primary" />} label="Non Trade" />
               </RadioGroup>
           </FormControl>
          
        </Grid>
          :''
        }
      
            <Grid item xs={2} sm={2} className='mb-10'>
            {
            regionFilter.length > 0 && radioValue == 'trade' ? 
                     <FormControl fullWidth>
                          <InputLabel>Region</InputLabel>
                          <Select
                              value={regionFilter.length > 0 ? regionValue : ''}
                              label="Region"
                              onChange={handleRegionChange}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {regionFilter && regionFilter.length && regionFilter.map(item => {
                                  return <MenuItem value={item} key={item} >{item}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>
                         : ''

                        }
                            {
            regionFilterSecond.length > 0 && radioValue != 'trade' ? 
                     <FormControl fullWidth>
                          <InputLabel>Region</InputLabel>
                          <Select
                              value={regionFilterSecond.length > 0 ? regionValueSecond : ''}
                              label="Region"
                              onChange={handleRegionChangeSecond}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {regionFilterSecond && regionFilterSecond.length && regionFilterSecond.map(item => {
                                  return <MenuItem value={item} key={item} >{item}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>
                         : ''

                        }

{
            costCenterFilter.length > 0 ? 
                     <FormControl fullWidth>
                          <InputLabel>Cost Center</InputLabel>
                          <Select
                              value={costCenterFilter.length > 0 ? costCenterValue : ''}
                              label="Cost Center"
                              onChange={handleCostCenterChange}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {costCenterFilter && costCenterFilter.length && costCenterFilter.map(item => {
                                  return <MenuItem value={item} key={item} >{item}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>
                         : ''

                        }
            </Grid>
            
         

       
            <Grid item xs={2} sm={2} className='mb-10'>

            {
            plantFilter.length > 0 ? 
                     <FormControl fullWidth>
                          <InputLabel>Plant</InputLabel>
                          <Select
                              value={plantFilter.length > 0 ? plantValue : ''}
                              label="Plant"
                              onChange={handlePlantChange}
                              multiple={true}
                          >
                              <MenuItem value="" disabled>
                                  <em>Select</em>
                              </MenuItem>
                              {plantFilter && plantFilter.length && plantFilter.map(item => {
                                  return <MenuItem value={item} key={item} >{item}</MenuItem>;
                              })}
                          </Select>
                      </FormControl>

                        : ''

                        }

{
            dealerFilter.length > 0 && radioValue == 'trade' ? 

            <FormControl fullWidth>
            <InputLabel>Dealer</InputLabel>
            <Select
                value={dealerFilter.length > 0 ? dealerValue : ''}
                label="Dealer"
                onChange={handleDealerChange}
                multiple={true}
            >
                <MenuItem value="" disabled>
                    <em>Select</em>
                </MenuItem>
                {dealerFilter && dealerFilter.length && dealerFilter.map(item => {
                    return <MenuItem value={item} key={item} >{item}</MenuItem>;
                })}
            </Select>
        </FormControl>
        : ''
            }

{
            customerFilter.length > 0 && radioValue != 'trade' ? 
            <FormControl fullWidth>
            <InputLabel>Customer</InputLabel>
            <Select
                value={customerFilter.length > 0 ? customerValue : ''}
                label="Customer"
                onChange={handleCustomerChange}
                multiple={true}
            >
                <MenuItem value="" disabled>
                    <em>Select</em>
                </MenuItem>
                {customerFilter && customerFilter.length && customerFilter.map(item => {
                    return <MenuItem value={item} key={item} >{item}</MenuItem>;
                })}
            </Select>
        </FormControl>
            : ''

        }
            </Grid>

            <Grid item xs={1} sm={1} className='mt-10'>
                <Button variant='outlined' className='run-button newButtonOutlined btnBlue btnReset mr-10' onClick={() => handleReset()}>Reset</Button>
            </Grid>

        <Grid item xs={ showRadioTrade ? 5 : 7 } sm={ showRadioTrade ? 5 : 7 } >
            <div className='text-right mb-10 mt-10'>
                <Tooltip title={'Download to Excel'}>
                    <img
                        src={DownloadExcel}
                        className='pointer'
                        height='30'
                        onClick={(event) => {
                            event.preventDefault();
                            handleExcelDownload();
                        }} />
                </Tooltip>
                
            </div>
        </Grid>
    </Grid>
    <Grid item xs={12} className= "modalData">
                            <ReactTable
                                columns={ radioValue == 'trade' ? headerData : headerDataSecond}
                                data={radioValue == 'trade'  ? rowData : rowDataSecond}
                                // defaultPageSize={props.header ? 7 : 50}
                                pageSize={ radioValue == 'trade' ? rowData.length : rowDataSecond.length}
                                loading={props.data.loading}
                                className="-striped -highlight"
                                PaginationComponent={headerData ? NewPagination : null}
                                sortable={true}
                                // getTheadThProps={columnHeaderClick}

                            />
                        </Grid>
    </div>
    </div>
)

return (
    <div>
        <Modal
            open={props.showModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.showModal}>
                {body}
            </Fade>
        </Modal>
        </div>
    );
}