import { put, takeLatest, call } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import {
    fetchValidationSummaryService,
    getValidationCommentService,
    postValidationCommentService,
    fetchIncomeValidationSummaryService,
    getIncomeValidationCommentService,
    postIncomeValidationCommentService,
    fetchISEmailTemplate,
    saveISEmailTemplate,
    sendEmailHFMData
} from './service'
import { store } from '../../index'

function* fetchDataBrowserValidationData(data) {
    yield put({ type: "FETCH_DATABROWSER_DATA_LOADER", loading: true });
    const state = store.getState();
    const payload = {
        selectedDate: state.dashboardState.selectedDate,
        validationType: data.payload,
    }
    const response = yield call(fetchValidationSummaryService, payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_VALIDATION_SUCCESS", reportDate: payload.selectedDate, loading: false, data: response.data, queryType: data.payload });
    }
}

function* fetchDataBrowserValidationComment(data) {
    const state = store.getState();
    const { dataBrowser } = state;
    const payload = {
        selectedDate: state.dashboardState.selectedDate,
        validationType: data.payload,
        isIncomeStatement: dataBrowser.isIncomeStatement
    }
    const response = yield call(getValidationCommentService, payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_COMMENT_SUCCESS", data: response.data });
    }
}

function* sendDataBrowserValidationComment(data) {
    const state = store.getState();
    const payload = {
        report_date: state.dashboardState.selectedDate,
        view: data.payload.view,
        comment: data.payload.comment,
    }
    const response = yield call(postValidationCommentService, payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_VALIDATION_COMMENT", payload: data.payload.view });
    }
}

function* fetchEmailTemplate(data) {
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchISEmailTemplate, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS", loading: false, data: response.data});
    }
}


function* saveEmailTemplate(data) {
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(saveISEmailTemplate, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS", loading: false, data: response.data});
        toastr.success('Edit successfully')
    }
}

function* fetchDataBrowserIncomeValidationData(data) {
    yield put({ type: "FETCH_DATABROWSER_DATA_LOADER", loading: true });
    const state = store.getState();
    const response = yield call(fetchIncomeValidationSummaryService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_VALIDATION_INCOME_SUCCESS", reportDate: '', loading: false, data: response.data, queryType: data.payload.validationType });
    }
}

function* fetchDataBrowserIncomeValidationComment(data) {
    const response = yield call(getIncomeValidationCommentService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_COMMENT_INCOME_SUCCESS", data: response.data });
    }
}

function* sendDataBrowserIncomeValidationComment(data) {
    const response = yield call(postIncomeValidationCommentService, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_INCOME_VALIDATION_COMMENT", payload: data.payload });
    }
}

function* sendMailHFM(data){
    yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: true });
    const response = yield call(sendEmailHFMData, data.payload);
    if (response && response.status == 200) {
        yield put({ type: "FETCH_DATABROWSER_EMAIL_LOADER", loading: false });
        // yield put({ type: "FETCH_DATABROWSER_EMAIL_TEMPLATE_DATA_SUCCESS", data: response.data });
        toastr.success('Email sent successfully')
    }
}

export default function* actionWatcher() {
    yield takeLatest('FETCH_DATABROWSER_VALIDATION_DATA', fetchDataBrowserValidationData)
    yield takeLatest('FETCH_DATABROWSER_INCOME_VALIDATION_DATA', fetchDataBrowserIncomeValidationData)
    yield takeLatest('FETCH_EMAIL_TEMPLATE_IS_VALIDATION', fetchEmailTemplate)
    yield takeLatest('SAVE_EMAIL_TEMPLATE_HFM', saveEmailTemplate)
    yield takeLatest('FETCH_DATABROWSER_VALIDATION_COMMENT', fetchDataBrowserValidationComment)
    yield takeLatest('FETCH_DATABROWSER_INCOME_VALIDATION_COMMENT', fetchDataBrowserIncomeValidationComment)
    yield takeLatest('SEND_DATABROWSER_VALIDATION_COMMENT', sendDataBrowserValidationComment)
    yield takeLatest('SEND_DATABROWSER_INCOME_VALIDATION_COMMENT', sendDataBrowserIncomeValidationComment)
    yield takeLatest('SEND_MAIL_HFM', sendMailHFM)
}
