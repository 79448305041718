import React from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Divider from '@material-ui/core/Divider';
import { numberWithCommas,handleTableTransform } from '../../../../../utils/numberUtils'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { formatDate } from '../../../../../utils/dateFormat'
import CircularProgress from '@material-ui/core/CircularProgress';
import '../ScatterChart.scss';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DownloadExcel from "../../../../../../src/images/download_excel.svg";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAccessByMenuKey, getActionOwneruser } from "../../../../../utils/userManagement";
import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import {
    downloadExcel, pdfFileName, mapDataPDF, mapPDFDataComparative, mapPDFHeaderComparative,
    mapExcelData, mapExcelHeader, mapExcelHeaderComparative, mapExcelDataComparative, mapExcelDataVariance
  } from '../../../../../utils/exportExcel'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EmailContainer from './email'
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ActionOwnerPopper = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);


export class AnalysisActionComponent extends React.Component {

    constructor(props) {
        super(props);
        this.headerColorArr = ['#f7f7f7', '#685aa9', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5', '#8306ce', '#be7de5']
        const datelen = props.dashboardState.reportDateArr && props.dashboardState.reportDateArr.length;
        this.state = {
            openDialogTest: false,
            openDialog: false,
            loading: true,
            seriesData: [],
            commentValue: '',
            commentModal: false,
            filterView: "tp_gt_10000",
            dataSourceFiltered : [],
            viewEmail: false,
            emailTemplateContent: "",
            editorMsgBody: null,
            month: props.dashboardState.reportDateArr && props.dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
        }
    }


    refreshTable = () => {
        this.getFilteredDataSource(this.state.filterView)
    }


    handleActionOwnerChangeFn = (dataJson, data, item) => {
        const {month, filterView} = this.state
        this.setState({loading: true});
        let action_owners = []
        let action_owner_type = 'ops'
        if (item.key == 'action_party_finance') {
            action_owner_type = 'finance'
        }


        if(dataJson != null){
            dataJson.forEach((value, key)=>{
                action_owners.push({
                    action_owner_name: value.full_name,
                    action_owner_email: value.email
                })

            })
        }

        this.props.updateActionOwnerFn({
          mgt_group: data.mgtGroup,
          company_code: data.company_code,
          customer: data.customer_id,
          country: data.soldToCountryName,
          customer_name: data.customer_name,
          region: data.region,
          bu: data.business_unit,
          action_owners : action_owners,
          action_owner_type
        })

        this.props.getAnalysisAction(
            {"report_date": month,
            "layout_type" : filterView,
            receivableTabSelectorValue: this.props.receivableTabSelectorValue
        }
            )
        // console.log(payload)
    }

    // handleActionOwnerChange = (data, props) => {
        
        
        

    //     // this.props.updateActionOwnerFn(payload)
    //     this.props.getAnalysisAction({"report_date": month,"layout_type" : filterView})
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tableViewState.loading && !this.props.tableViewState.loading)
          this.refreshTable()
    }

    getDataAnalysis = () =>{
        let {filterView, month} = this.state
        let payload = {
            "report_date": month,
            "layout_type": filterView,
            receivableTabSelectorValue: this.props.receivableTabSelectorValue

        }
        this.props.getAnalysisAction(payload)
    }

    componentDidMount() {
        this.getDataAnalysis() 
    }



    handleMonthChange = (event) => {
        const {filterView} = this.state
        if (event.target && this.state.month != event.target.value) {
          this.setState({ month: event.target.value }, () => {
            let payload = {
                "report_date": event.target.value,
                "layout_type": filterView,
                receivableTabSelectorValue: this.props.receivableTabSelectorValue

            }
            this.props.getAnalysisAction(payload)
          });
        }
    }

    handleFilterValue = (event) => {
        // console.log(event.target.value)
        if (event.target && this.state.filterView != event.target.value) {
            this.setState({ filterView: event.target.value }, () => {
                this.getDataAnalysis()
            });    
        }
        
    }

    handleSummaryColumn = (dataItem) => {
        const { buRowData } = this.props.summaryState;
        let returnString = '';
        returnString = dataItem.label;
        if (buRowData[dataItem.key]) {
            //   returnString = returnString + ' ' + this.modifyColumnData(buRowData[dataItem.key])
            returnString = returnString + ' ' + buRowData[dataItem.key]
        }
        if(dataItem.key == "customer_name") {
            let total = 0;
            this.state.dataSourceFiltered.forEach((v, i) => {
                if(v[dataItem.key]) {
                    total++;
                }
            })
            returnString += "\n"+total+" Accounts";
        }
        if(dataItem.key == "worsened" || dataItem.key == "improved" || dataItem.key == "total") {
            let total = 0;
            this.state.dataSourceFiltered.forEach((v, i) => {
                total += v[dataItem.key];
            })
            let modifiedData = Math.round(Math.round(total))
            var isNegative = modifiedData < 0
            var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
            modifiedData = numberWithCommas(positiveTransformedValue)
            modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
            modifiedData = modifiedData == '0' ? '' : modifiedData
            returnString += "\n" + modifiedData;
        }
        return returnString;
    }
    changeKeysOfHeader = (data, type) => {
        let buUser = this.props.externalState.allUser

        if (data && data.length) {
         
            return data.map((item, i) => {
                if(item.key == "customer_id" || item.key == "customer_name" ){
                  return {
                    Header: this.handleSummaryColumn(item),
                    id: item.key,
                    fixed: "left",
                    width: 150,
                    accessor: (_data) => {
                        if (item.operation) {
                            let modifiedData;
                            let columnData = _data[item.key]
                            switch (item.operation) {
                                case 0:
                                    modifiedData = item.key;
                                    break;
                                case 1:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 2:
                                    modifiedData = numberWithCommas(columnData)
                                    break;
                                case 4:
                                    modifiedData = modifiedData < 0 ? '-(' + Math.abs(columnData) + ')' : columnData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 3:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 5:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = modifiedData < 0 ? '-(' + Math.abs(modifiedData) + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 6:
                                    var isNegative = columnData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '-(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 7:
                                    modifiedData = Math.round(columnData)
                                    var isNegative = modifiedData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '-(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                default:
                                    break;
                            }

                            return modifiedData;


                        } else {
                            return _data[item.key];
                        }
                    },
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {

                                    props.original[item.key] == "Grand Total" ?
                                        <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                        <span
                                            onClick={() => {
                                                // this.businessUnitClick(props.original[item.key], type, props.original) 
                                            }}>
                                            <>{handleTableTransform(props.original, item)}</>
                                        </span>
                                }
                            </div>
                        )
                    },
                    style: {
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        color: 'white',
                        width: "200px !important",
                        backgroundColor: this.headerColorArr[i]
                    }
                  }
                }else if(item.key === "action_party_finance" || item.key == 'action_party_ops'){
                    return {
                        Header: this.handleSummaryColumn(item),
                        id: item.key,
                        fixed: "left",
                        width: 200,
                        sortable: false,
                        accessor: item.key,
                        headerStyle: {
                            whiteSpace: 'break-spaces',
                            overflow: 'visible',
                            color: 'white',
                            width: "200px !important",
                            backgroundColor: this.headerColorArr[i]
                        },
                        Cell: (props) => {

                            // let defaultValueActionOwner = buUser.find(x=>{ 
                            //     console.log(props.original.action_party_finance)

                            //      
                            // })
                            let defaultValueActionOwner = []
                            // let label = ""
                            if(props.original[item.key] !== null && props.original[item.key].length > 0 && props.original[item.key] !== ""){ 
                                props.original[item.key].forEach(value => {
                                    buUser.forEach(x => {
                                        // console.log(x.email , value)
                                        if(x.email == value){
                                            defaultValueActionOwner.push(x)
                                        } 
                                    })
                                })
                            }

                            
                            return (
                                <Autocomplete
                                    // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                                    multiple
                                    className='actionOwnerTabelSelect'
                                    options={buUser}
                                    size='small'
                                    disabled={this.state.filterView == 'slb' || this.state.filterView == 'pemex'}
                                    PopperComponent={ActionOwnerPopper}
                                    getOptionLabel={(option) => option.full_name}
                                    value={defaultValueActionOwner}
                                    onChange={(event, data) => {this.handleActionOwnerChangeFn(data, props.original, item)}}
                                    renderInput={(params) => (
                                        // <Tooltip title={label}>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        // </Tooltip>
                                    )}
                                    renderOption={(option) => <Typography
                                        style={{ fontSize: '12px', width: '250px' }}
                                        className='flex jc-space-between minw-200 ai-center'
                                        noWrap>
                                        <span className='pr-10'>{option.full_name}</span>
                                        <span style={{ opacity: '0.5', float: 'right' }}>{option.role}</span>
                                    </Typography>}

                                />
                            )
                        }
                    }
                }else{
                  return {
                    Header: this.handleSummaryColumn(item),
                    id: item.key,
                    accessor: (_data) => {
                        if (item.operation) {
                            let modifiedData;
                            let columnData = _data[item.key]
                            switch (item.operation) {
                                case 0:
                                    modifiedData = item.key;
                                    break;
                                case 1:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 2:
                                    modifiedData = numberWithCommas(columnData)
                                    break;
                                case 4:
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(columnData) + ')' : columnData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 3:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = numberWithCommas(modifiedData)
                                    break;
                                case 5:
                                    modifiedData = Math.round(columnData)
                                    modifiedData = modifiedData < 0 ? '(' + Math.abs(modifiedData) + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 6:
                                    var isNegative = columnData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(columnData) : columnData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                case 7:
                                    modifiedData = Math.round(columnData)
                                    var isNegative = modifiedData < 0
                                    var positiveTransformedValue = isNegative ? Math.abs(modifiedData) : modifiedData
                                    modifiedData = numberWithCommas(positiveTransformedValue)
                                    modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData
                                    modifiedData = modifiedData == '0' ? '' : modifiedData
                                    break;
                                default:
                                    break;
                            }

                            return modifiedData;


                        } else {

                            return _data[item.key];
                        }
                    },
                    Cell: (props) => {
                        return (
                            <div className='text-center'>
                                {

                                    props.original[item.key] == "Grand Total" ?
                                        <span style={{ fontWeight: 'bold' }}>{props.original[item.key]}</span> :
                                        <span
                                            onClick={() => {
                                                // this.businessUnitClick(props.original[item.key], type, props.original) 
                                            }}>
                                            <>{handleTableTransform(props.original, item)}</>
                                        </span>
                                }
                            </div>
                        )
                    },
                    sortMethod: (a, b) => {
                        let valNeg = a.indexOf("(") > -1 ? -1 : 1
                        let valNegB = b.indexOf("(") > -1 ? -1 : 1
                        let val = a.split(",").join("").replace("(", "").replace(")", "")
                        let valB = b.split(",").join("").replace("(", "").replace(")", "")
                        let valInt = parseInt(val)
                        let valIntB = parseInt(valB)
                        valInt = isNaN(valInt) ? 0 : valInt * valNeg
                        valIntB = isNaN(valIntB) ? 0 : valIntB * valNegB
                        if (item.operation)



                            return valInt - valIntB
                        else {
                            if (a > b) {
                                return -1;
                            } else if (a < b) {
                                return 1;
                            }
                            return 0;
                        }

                    },
                    style: {
                        fontSize: '12px'
                    },
                    headerStyle: {
                        whiteSpace: 'break-spaces',
                        overflow: 'visible',
                        color: 'white',
                        width: "200px !important",
                        backgroundColor: this.headerColorArr[i]
                    }
                  }
                }
                
            })
        }
        return []
    }

    changeValuesOfData = (data) => {
        if (data && data.length) {
            return data.map(item => {
                return item
            })
        }
        return []
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.business_unit == 'Grand Total' ? '#b1bace' : ''
            }
          }
        }
        return {};
    }

    handleOpenDialog = () =>{
        this.setState({
            openDialog : true
        })

    }

    handleSendMail = () =>{
        const { month } = this.state
        this.props.sendEmail({"report_date": month, "test_email": false})
        this.handleCloseDialog()
    }

    handleCloseDialog = () => {
        this.setState({
            openDialog : false
        })
    }

    handleOpenDialogTest = () =>{
        this.setState({
            openDialogTest : true
        })

    }

    handleSendMailTest = () =>{
        const { month } = this.state
        this.props.sendEmail({"report_date": month, "test_email": true})
        this.handleCloseDialogTest()
    }

    handleCloseDialogTest = () => {
        this.setState({
            openDialogTest : false
        })
    }
    

    getFilteredDataSource = (filterView) => {

        let { tableViewState} = this.props
        let rowData = tableViewState.data.row_data
        let dataSource = rowData

        this.setState({
          dataSourceFiltered: dataSource
        });
        
    }

    openViewEmail = () => {
        this.setState({viewEmail : true})
        this.props.getEmailTemplate({})
    }

    closeViewEmail = () => {
        this.setState({viewEmail : false})
    }

    saveTemplateEmail = () => {
       
    }

    handleSubmitTemplate = (event) => {
        const {editorMsgBody} = this.state

        event.preventDefault();

        let payload = {
          // cc_emails: [event.currentTarget.cc_emails.value],
          email_title: event.currentTarget.email_title.value,
          // email_salutation: event.currentTarget.email_salutation.value,
          email_msg: editorMsgBody.getData(),
          // email_signature: event.currentTarget.email_signature.value
        }

        this.props.saveEmailTemplate(payload)
        this.setState({viewEmail : false})
        
      }

    onChangeTemplateEmail = (data) => {
        this.setState({emailTemplateContent: data})
    }
    handleDownloadExcel = () => {
        const { dataSourceFiltered } = this.state

        let { tableViewState, dashboardState} = this.props;
        let externalDataColumns = this.changeKeysOfHeader(tableViewState.data.row_header, 'analysisAction')
        let data = []
        let header = externalDataColumns
        let merge = [];
        // let header = JSON.parse(JSON.stringify(dayReportState.tableRowHeader))
       
        data = mapExcelData(dataSourceFiltered, header, "id")
        header = mapExcelHeader(header, "Header")
        console.log(header, "checkDatass")
        console.log(data, "checkDatass")

        console.log(data, "--EXCELDATAdata3")
        let fileName = " Receivables - Chart, Dashboard & Analytics - Overdue Automated Followup - Analytics and Actions"
      
        console.log(data, fileName, header, false, merge)
        let sheetName = 'Data';
    
     
        downloadExcel(data, fileName, header, false, merge, undefined, sheetName)
    }
    render() {
        const { month, openDialog ,openDialogTest, filterView, dataSourceFiltered } = this.state

        let { tableViewState, dashboardState} = this.props;
        let externalDataColumns = this.changeKeysOfHeader(tableViewState.data.row_header, 'analysisAction')
        console.log("column data is -> ", externalDataColumns);

        if(filterView=="pemex" || filterView=="slb"){
            externalDataColumns = externalDataColumns.filter(x => x.Header != "Region")
        }

        const emailData = tableViewState.emailTemplate.data;
        const datelen = dashboardState.reportDateArr && dashboardState.reportDateArr.length;
        const updatedMonth = dashboardState.reportDateArr[datelen > 0 ? datelen - 1 : 0]
        const externalDataLen = tableViewState && tableViewState.data && tableViewState.data.row_data ? dataSourceFiltered.length : 0;

        const arrayDate = dashboardState.reportDateArr
        const newArray = []
        const idx = arrayDate.length
        let Newidx = 0
        for (var keys in arrayDate) {
          Newidx++ 
          
          newArray.push(arrayDate[(idx-Newidx)])
        }

        return (
            <>
                <div className="analysis-action">
                    {dashboardState && dashboardState.reportDateArr && dashboardState.reportDateArr.length ?
                      <>
                        <div className="flex ai-center jc-space-between mb-10">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                              <FormControl className='w-200'>
                                <Select
                                  value={month || updatedMonth}
                                  onChange={this.handleMonthChange}
                                  MenuProps={{ onExited: () => document.activeElement.blur() }}
                                >
                                  {newArray.map((item, index) => {
                                    return <MenuItem value={item} key={index}>{item}</MenuItem>
                                  })
                                  }
                                </Select>
                              </FormControl>
                              {filterView == "tp_gt_10000" ? 
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className = "ml-20 newButton btnBlue btnEmail uppercase"
                                endIcon={<Icon>visibility</Icon>}
                                onClick={this.openViewEmail}
                              >
                                View Email
                              </Button>
                                : ""}
                              <Modal
                                    open={this.state.viewEmail}
                                    onClose={this.closeViewEmail}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={this.state.viewEmail}>
                                        {tableViewState.emailTemplate.loading ? <div className='text-center'><CircularProgress /></div> : 
                                        <div className='customModal viewEmailModal'>
                                            <div className='header'>
                                                <h2>View Email</h2>
                                            </div>
                                            <div className='modal-content email-template'>
                                                <form onSubmit={this.handleSubmitTemplate}>
                                                    <TextField
                                                      id="email_title"
                                                      label="Title of Email"
                                                      style={{ margin: 8 }}
                                                      placeholder=""
                                                      helperText=""
                                                      fullWidth
                                                      defaultValue= {emailData.email_title}
                                                      margin="normal"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <TextField
                                                      id="email_salutation"
                                                      label="Salutation"
                                                      style={{ margin: 8 }}
                                                      defaultValue= "Dear username,"
                                                      margin="normal"
                                                      disabled 
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <FormControl style={{ marginTop: 8 ,  marginLeft: 10 }}>
                                                        <InputLabel htmlFor="editor-msg-body" className="MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Message Body</InputLabel>
                                                        <CKEditor
                                                            config={{
                                                                removePlugins: ['Link','EasyImage','CKFinder','Indent','Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                                            }}
                                                            id="editor-msg-body"
                                                            name="emailView"
                                                            editor={ ClassicEditor }
                                                            data= {emailData.email_msg}
                                                            onReady={editor => {
                                                              this.setState({editorMsgBody: editor})
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <p className="ml-10">{emailData.app_link}</p>
                                                    
                                                    <TextField
                                                      id="email_signature"
                                                      label="Signature"
                                                      style={{ margin: 10 }}
                                                      defaultValue= {`Thanks - xP&A Support `}
                                                      margin="normal"
                                                      disabled 
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />
                                                    <div className="flex jc-flex-end mt-30">
                                                        <Button type="submit" variant="contained" className='newButton btnBlue btnSubmit uppercase' color="primary">Submit</Button>
                                                        <Button variant="contained" className='ml-16 newButton btnRed btnCancel uppercase' onClick={this.closeViewEmail}>Cancel</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        }
                                        
                                    </Fade>
                              </Modal>

                              {filterView == "tp_gt_10000" ? 
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className = "ml-20 newButton btnBlue btnEmail uppercase"
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialogTest}
                              >
                                Send Test Email
                              </Button> : ""}
                              <Dialog
                                open={openDialogTest}
                                onClose={this.handleCloseDialogTest}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send the test email?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus variant='contained' className='newButton btnRed btnCancel uppercase' onClick={this.handleCloseDialogTest} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMailTest} variant="contained" className='newButton btnBlue btnEmail uppercase' size="small" color="primary">
                                    Send Test Email
                                  </Button>
                                </DialogActions>
                              </Dialog>


                              {filterView == "tp_gt_10000" ? 
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className = "ml-20 newButton btnBlue btnEmail uppercase"
                                endIcon={<Icon>send</Icon>}
                                onClick={this.handleOpenDialog}
                              >
                                Send Email
                              </Button> : ""}
                              <Dialog
                                open={openDialog}
                                onClose={this.handleCloseDialog}
                              >
                                <DialogTitle>
                                  Confirmation
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to send it?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button autoFocus variant='contained' className='newButton btnRed btnCancel uppercase' onClick={this.handleCloseDialog} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={this.handleSendMail} variant="contained" className='newButton btnBlue btnEmail uppercase' size="small" color="primary">
                                    Send Email
                                  </Button>
                                </DialogActions>
                              </Dialog>
                             
                              <Tooltip title={'Download to Excel'}>
                                <img
                                    src={DownloadExcel}
                                    height='30'
                                    className='pointer download-excel-custom'
                                    onClick={(event) => {
                                    event.preventDefault();
                                    this.handleDownloadExcel();
                                    }} />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={12} className="filter-section">
                                <FormControl component="fieldset">
                                  <RadioGroup name="radio" onChange={this.handleFilterValue} value={filterView} className='radioGroupHorizontal'>
                                    <FormControlLabel value="tp_gt_10000" control={<Radio color="primary" />} label="Third Party > $ 10,000" />
                                    <FormControlLabel value="tp_lt_10000" control={<Radio color="primary" />} label="Third Party < $ 10,000" />
                                    <FormControlLabel value="slb" control={<Radio color="primary" />} label="Interco" />
                                    <FormControlLabel value="pemex" control={<Radio color="primary" />} label="Key Customer 1" />
                                  </RadioGroup>
                                </FormControl>  
                            </Grid>
                          </Grid>
                        </div>
                        {
                            <div className={'hfmDataTable'}>
                                {
                                    tableViewState.loading ? <div className='progressfullpage'><CircularProgress /></div> : 
                                    <ReactTableFixedColumns
                                        data={dataSourceFiltered}
                                        className={'spna-reactFixed-table -striped -highlight no-fixed-line'}
                                        loading={tableViewState.loading}
                                        columns={externalDataColumns}
                                        pageSize={externalDataLen}
                                        showPagination={false}
                                        sortable={true}
                                    />
                                }
                            </div>
                        }
                      </> : null
                    }
                  </div>
            </>
        );
        return(
            <>
                {
                    <div className={'hfmDataTable'}></div>
                }
            </>
        )
    }
}

export default
    connect(
        state => {
            return {
                dashboardState: state.dashboardState,
                tableViewState: state.dashboardState.dashboardAnalytic.AROverdueActionAnalysis,
                summaryState: state.dashboardState.receivableData.ageingData.summary,
                externalState: state.dashboardState.receivableData.ageingData.external,
            }
        },
        dispatch => {
            return {
                getAnalysisAction(data) {
                    dispatch({
                        type: 'FETCH_AR_OVERDUE_ANALYSIS_ACTION',
                        payload: data
                    });
                },
                saveEmailTemplate(data){
                    dispatch({
                      type: "SAVE_EMAIL_TEMPLATE_AR",
                      payload : data
                    })
                },
                getEmailTemplate(data){
                    dispatch({
                        type: 'FETCH_EMAIL_TEMPLATE_AR',
                        payload: data
                    });
                },
                sendEmail(data){
                  dispatch({
                    type : "SEND_MAIL_ANALYSIS_ACTION",
                    payload : data
                  });
                },
                updateActionOwnerFn(data) {
                    dispatch({
                        type: 'UPDATE_ACTION_OWNER_ANALYSIS_ACTION',
                        payload: data
                    });
                },
            };
        }
    )(AnalysisActionComponent);