import React from "react";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { formatDate } from "../../../../../utils/dateFormat";
import { CONFIG } from "../../../../../config";
import { numberWithCommas } from "../../../../../utils/numberUtils";
import { getAccessByMenuKey, getActionOwneruser } from "../../../../../utils/userManagement";
import Popper from "@material-ui/core/Popper";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const MenuProps = {
  PaperProps: {
    style: {
     
      width: 250,
    },
  },
};

const ActionOwnerPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: "fit-content" }}
      placement="bottom-start"
    />
  );
};
const headerColorObj = {
  current: "#64DD17",
  "1_30_days": "#AEEA00",
  "31_60_days": "#FFD600",
  "61_90_days": "#FFAB00",
  "91_180_days": "#FF6D00",
  "180_plus_days": "#d00909",
};

const agingCategoryArr = [
  "1_30_days",
  "31_60_days",
  "61_90_days",
  "91_180_days",
  "180_plus_days",
  "grand_total",
  "overdue_amount",
  "current",
];

const handleDynamicWidth = (item) => {
  let widthSize;
  switch (item.key) {
    case "percentage":
      widthSize = 90;
      break;
    case "grand_total":
      widthSize = 80;
      break;
    case "customer_name":
      widthSize = 200;
      break;
    case "project":
      widthSize = 80;
      break;
    case "document_number":
      widthSize = 80;
      break;
    case "assignment":
      widthSize = 80;
      break;
    case "overdue_amount":
      widthSize = 120;
      break;
    case "improvement_plan":
      widthSize = 90;
      break;
    case "collection_forecast":
      widthSize = 90;
      break;
    case "collection_actual":
      widthSize = 90;
      break;
    case "action_owner":
      widthSize = 150;
      break;
    case "text":
      widthSize = 90;
      break;
    case "new_bu" :
      widthSize = 130;
      break;
    case "sold_to_country" :
      widthSize = 120;
      break;
    default:
      break;
  }
  return widthSize;
};

const modifyColumnData = (columnData) => {
  let modifiedData;
  modifiedData = Math.round(columnData);
  var isNegative = modifiedData < 0;
  var positiveTransformedValue = isNegative
    ? Math.abs(modifiedData)
    : modifiedData;
  modifiedData = numberWithCommas(positiveTransformedValue);
  modifiedData = isNegative ? "(" + modifiedData + ")" : modifiedData;
  modifiedData = modifiedData == "0" ? "" : modifiedData;
  return modifiedData;
};

const handleSummaryColumn = (dataItem, buRowData) => {
  let returnString = "";
  returnString = dataItem.label;
  if (buRowData[dataItem.key]) {
    returnString =
      returnString + " " + modifyColumnData(buRowData[dataItem.key]);
  }
  return returnString;
};



const handleFixedColumn = (item) => {
  let fixed = "";
  switch (item.key) {
    case "customer_name":
      fixed = "left";
      break;
    case "customer":
      fixed = "left";
      break;
    case "company_code":
      fixed = "left";
      break;
    default:
      break;
  }
  return fixed;
};

export const changeKeysOfHeader = (
  data,
  summaryState,
  authState,
  externalState,
  buValue,
  handleChatChangeFn,
  onImageInputHandler,
  handleSendChatFunction,
  handleImageOptions,
  uploadImageObj,
  getPipID,
  showCommentModal,
  showForecastModal,
  showModalListPip,
  showAddImporvementPlanModal,
  handleActionOwnerChange,
  handleCustomerIdClick,
  chatDataCollection,
  dropdownInvoiceList,
  handleCountryChanges,
  handleClassificationChanges
) => {
  const handleCountryChange = (event, rowData, idx) => {
    if (event.target && event.target.value) {
        rowData["sold_to_country"] = event.target.value;
        let payload = {
          dataChanges: rowData,
            index: idx
        }
        // console.log()
        handleCountryChanges(payload)
    }
  }

  const handleMappingChange = (event, rowData, idx) => {
    if (event.target && event.target.value) {
       
        rowData["New_business_unit"] = event.target.value;
        let payload = {
          dataChanges: rowData,
            index: idx
        }
        handleClassificationChanges(payload)

    }
}

  const handleActionOwnerChangeFn = (dataJson, data) => {
    // console.log(dataJson.full_name, 'lllll');
    let loginID = "";
    if (dataJson != null) {
      loginID = dataJson.login_id;
    }
    handleActionOwnerChange(loginID, data);
  };

  const convertAmountData = (data) => {
    var modifiedData = data;
    var isNegative = modifiedData < 0;
    var positiveTransformedValue = isNegative
      ? Math.abs(modifiedData)
      : modifiedData;
    modifiedData = numberWithCommas(positiveTransformedValue);
    return modifiedData;
  };

  const menus = authState.menuData;
  let buUser = externalState.allUser //getActionOwneruser(externalState.allUser, buValue);
//   const userData = {
//     login_id: "ra.corporate@eaciit.com",
//     name: 'RA Corporate', 
//     email: 'ra.corporate@eaciit.com', 
//     enable: true, 
//     role: 'Corporate User',
//     full_name: "RA Corporate",
//   }

//   buUser.push(userData)
//  buUser = buUser.sort(
//       function (a, b) {
//               return a.full_name > b.full_name ? 1 : -1;
//       });
  // buUser = buUser.map(x => {
  //   return x.l;
  // }),
 // {login_id: 'asif@test.com', name: 'ASIF', email: 'asif@test.com', enable: true, role: 'Corporate User', …}
  // externalState.allUser.filter(item => {
  //     if (item && item.bu && buValue) {
  //         return item.bu.toLowerCase() == buValue.toLowerCase()
  //     }
  // });

  // if (buUser.length == 0) {
  //     buUser = externalState.allUser.filter(x => {
  //         let res = false;
  //         let roles = ["admin", "corporate", "functional"];
  //         roles.forEach(y => {
  //             if (!res)
  //                 res = x.role.toLowerCase().indexOf(y.toLowerCase()) > -1
  //         })
  //         return res
  //     })
  // }

  // buUser = buUser.sort(
  //     function (a, b) {
  //         if (a.role === b.role) {
  //             // order by role and name
  //             return a.full_name > b.full_name ? 1 : -1;
  //         }
  //         return a.role > b.role ? 1 : -1;
  //     });
  
  if (data && data.length) {
    return data.map((item) => {
      if (item.key == "comments") {
        return {
          Header: item.label,
          id: item.key,
          headerStyle: {
            fontSize: "12px",
            fontWeight: "bold",
            minWidth: "400px",
          },
          style: {
            width: "65px",
            whiteSpace: "break-spaces",
            overflow: "visible",
            minWidth: "400px",
          },
          accessor: item.key,
          Cell: (props) => (
            <div className="commentContainerTable">
              <div className="commentInputSection flex ai-center jc-space-between">
                <TextField
                  id={`outlined-basic-${props.original.channel}`}
                  label="Type your Comment"
                  name="comment"
                  // value={chatDataCollection[props.original.channel]}
                  placeholder="Comments"
                  multiline
                  variant="outlined"
                  size="small"
                  onChange={(event) => {
                    handleChatChangeFn(event, props.original);
                  }}
                />
                <input
                  style={{ display: "none" }}
                  id={`upload-icon${props.original.channel}`}
                  type="file"
                  onChange={(event) =>
                    onImageInputHandler(event, props.original)
                  }
                />
                {getAccessByMenuKey(menus, "aging_reports", "upload") && (
                  <Tooltip title="Attach File">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      className="px-0 mx-0"
                    >
                      <label htmlFor={`upload-icon${props.original.channel}`}>
                        <Icon fontSize="small">attach_file</Icon>
                      </label>
                    </IconButton>
                  </Tooltip>
                )}
                {getAccessByMenuKey(menus, "aging_reports", "add") && (
                  <Tooltip title="Send">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      className="px-0 mx-0"
                      onClick={() => {
                        handleSendChatFunction(props.original);
                      }}
                    >
                      <Icon>send</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {uploadImageObj[props.original.channel] && (
                <div className="uploadedFilePreview">
                  <img
                    src={uploadImageObj[props.original.channel].imagePreview}
                    height="50"
                    width="50"
                  />
                  <span>
                    {uploadImageObj[props.original.channel].imageName}
                  </span>
                  <IconButton
                    aria-label="approve upload"
                    onClick={() =>
                      handleImageOptions("approve", props.original)
                    }
                  >
                    <Icon fontSize="small">check</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="cancel upload"
                    onClick={() => handleImageOptions("cancel", props.original)}
                  >
                    <Icon fontSize="small">clear</Icon>
                  </IconButton>
                </div>
              )}
              <div className="commentArea">
                {props.original &&
                props.original.commentArr &&
                props.original.commentArr.length
                  ? props.original.commentArr.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="date">
                            {formatDate(item.timestamp)}
                          </div>
                          {item.type == "image" ? (
                            <>
                              {item.name} :{" "}
                              <img
                                width="100"
                                src={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel}/${item.body}`}
                              />{" "}
                            </>
                          ) : item.type == "doc" ? (
                            <>
                              {item.name} :{" "}
                              <div>
                                <a
                                  href={`${CONFIG.api_base_url}v1/chat/channel/file/${props.original.channel}/${item.body}`}
                                >
                                  {item.file_name}
                                </a>
                              </div>
                            </>
                          ) : (
                            <div>
                              {item.name} :{" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.body.replace(
                                    /@\S+/gi,
                                    '<strong class="primary-dark">$&</strong>'
                                  ),
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="commentModalIcon text-center">
                <Tooltip title="More">
                  <Icon
                    color="primary"
                    onClick={() => {
                      showCommentModal(props.original);
                    }}
                  >
                    more_horiz
                  </Icon>
                </Tooltip>
              </div>
            </div>
          ),
        };
      } else if (item.key == "collection_forecast") {
        return {
          Header: item.label,
          id: item.key,
          width: handleDynamicWidth(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: "center",
            cursor: "pointer",
          },
          accessor: item.key,
          Cell: (props) => (
            <div className="mt-10">
              <Tooltip title="Add/Edit Collections Forecast">
                <span
                  onClick={() => {
                    //   this.setState({
                    //     showForecastModal: true,
                    //     commentRow: props.original,
                    //   });
                    showForecastModal(props.original);
                    getPipID(props.original);
                  }}
                >
                  {convertAmountData(props.original.collection_forecast) != 0
                    ? convertAmountData(props.original.collection_forecast)
                    : "Not Available"}
                </span>
              </Tooltip>
            </div>
          ),
        };
      } else if (item.key == "comment") {
        // console.log(item, "checkItem")
        return {
            Header: item.label,
            id: item.key,
            width: handleDynamicWidth(item),
            fixed: handleFixedColumn(item),
            headerStyle: {
                whiteSpace: "break-spaces",
                //width: '50px !important',
               // overflow: "visible",
                fontSize: '11px',
                fontWeight: 'bold',
                minWidth: "400px",
                //backgroundColor: headerColorObj[item.key] ? headerColorObj[item.key] : 'white'
            },
            style: {
                whiteSpace: "break-spaces",
                width: '50px',
                overflow: "visible",
                fontSize: '11px',
                textAlign: 'left',
                cursor: '',
                minWidth: "400px",
            },

            
            accessor: item.key,
            Cell: (props) => {
              // console.log(item, props.original.comment_originator, props.original.comment, "checkItem")
              return (
                <> {props.original.comment_originator ?
                  <p>{props.original.comment_originator}, {formatDate(props.original.comment_date)} <br></br>
           {props.original.comment}</p> :
           <p>{props.original.comment_originator} {formatDate(props.original.comment_date)} <br></br>
           {props.original.comment}</p> }
           </>
             )

             
          },
        };

    }else if (item.key == "improvement_plan") {
        return {
          Header: item.label,
          id: item.key,
          width: handleDynamicWidth(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: "center",
          },
          accessor: item.key,
          Cell: (props) => (
            <div className="mt-10 flex ai-center jc-space-evenly">
              <Tooltip title="View Improvement Plan">
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={props.original.pip_count}
                  color="primary"
                >
                  <Icon
                    color={
                      props.original["pip_count"] > 0 ? "inherit" : "disabled"
                    }
                    onClick={() => {
                      if (props.original["pip_count"] > 0) {
                        showModalListPip(props.original);
                      }
                    }}
                  >
                    remove_red_eye
                  </Icon>
                </Badge>
              </Tooltip>
              {getAccessByMenuKey(menus, "100k-120_days_reports", "add") && (
                <Tooltip title={"Add Improvement Plan"}>
                  <Icon
                    color={"inherit"}
                    disabled
                    onClick={() => {
                      showAddImporvementPlanModal(props.original);
                      getPipID();
                    }}
                  >
                    add_circle_outline
                  </Icon>
                </Tooltip>
              )}
            </div>
          ),
        };
      } else if (item.key == "action_owner") {
        return {
          Header: item.label,
          id: item.key,
          width: handleDynamicWidth(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: "center",
          },
          accessor: item.key,
          Cell: (props) => {
            let label = buUser.find((x) => {
              return x.login_id == props.original.action_owner;
            });
            label = label == undefined || label == null ? "" : label.full_name;

            return (
              <Autocomplete
                // getOptionSelected={(option, value) => option.full_name == props.original.action_owner}
                className="actionOwnerTabelSelect"
                options={buUser}
                size="small"
                PopperComponent={ActionOwnerPopper}
                getOptionLabel={(option) => option.full_name}
                value={buUser.find((x) => {
                  return x.login_id == props.original.action_owner;
                })}
                onChange={(event, data) => {
                  handleActionOwnerChangeFn(data, props.original);
                }}
                renderInput={(params) => (
                  <Tooltip title={label}>
                    <TextField {...params} variant="outlined" />
                  </Tooltip>
                )}
                renderOption={(option) => (
                  <Typography
                    style={{ fontSize: "12px", width: "250px" }}
                    className="flex jc-space-between minw-200 ai-center"
                    noWrap
                  >
                    <span className="pr-10">{option.full_name}</span>
                    <span style={{ opacity: "0.5", float: "right" }}>
                      {option.role}
                    </span>
                  </Typography>
                )}
              />
            );
          },
        };
      } else if (item.key == "customer") {
        return {
          Header: handleSummaryColumn(item, summaryState.buRowData),
          id: item.key,
          width: handleDynamicWidth(item),
          fixed: handleFixedColumn(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
            backgroundColor: headerColorObj[item.key]
              ? headerColorObj[item.key]
              : "white",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: item.key != "customer_name" ? "center" : "",
          },
          accessor: item.key,
          Cell: (props) => (
            <>
              <div>{props.original.customer}</div>
            </>
          ),
        };
      } else if (item.key == "customer_name") {
        return {
          Header: handleSummaryColumn(item, summaryState.buRowData),
          id: item.key,
          width: handleDynamicWidth(item),
          fixed: handleFixedColumn(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
            backgroundColor: headerColorObj[item.key]
              ? headerColorObj[item.key]
              : "white",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: item.key != "customer_name" ? "center" : "",
          },
          getProps : (state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              let ml = rowInfo.row._original.match_logic
              // console.log(ml, "-------ML")
              return {
                style: {
                  color:
                  ml == "first" ?  "rgb(76 187 3)" : ml == "second" ? "rgb(255 118 0)" : ""  
                }
              };
            } else {
              return {};
            }
          },
          accessor: item.key,
          Cell: (props) => (
            <>
              <div  >{props.original.customer_name}</div>
            </>
          ),
        };
      }else if (item.key == 'sold_to_country'  ) {
        return {
          Header: handleSummaryColumn(item, summaryState.buRowData),
          id: item.key,
          width: handleDynamicWidth(item),
          fixed: handleFixedColumn(item),
          headerStyle: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            fontWeight: "bold",
            backgroundColor: headerColorObj[item.key]
              ? headerColorObj[item.key]
              : "white",
          },
          style: {
            whiteSpace: "break-spaces",
            width: "50px !important",
            overflow: "visible",
            fontSize: "11px",
            textAlign: item.key != "customer_name" ? "center" : "",
          },
          accessor: item.key,
          Cell: (props) => {
            let countryArr = dropdownInvoiceList.countries
            let countries = props.original.sold_to_country
            let ml = props.original.match_logic
            return ( 
              <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
              <Select
                    MenuProps={MenuProps}
                    disabled={ml == "first" ? true : false}
                  // value={dataz[props.index]['sold_to_country']}
                  value={countries}
                  label="Country"
                  onChange={(event) => { handleCountryChange(event, props.original, props.index) }}
              >
                  <MenuItem value="">
                      <em>{''}</em>
                  </MenuItem>
                  {countryArr && countryArr.length && countryArr.map(item => {
                      return <MenuItem value={item.key} key={item.key}>{item.key}</MenuItem>
                  })}
              </Select>
              
          </FormControl> 
      
        
          )
            
          },
        };

       
    }else if (item.key == 'new_bu'  ) {
      return {
        Header: handleSummaryColumn(item, summaryState.buRowData),
        id: item.key,
        width: handleDynamicWidth(item),
        fixed: handleFixedColumn(item),
        headerStyle: {
          whiteSpace: "break-spaces",
          width: "50px !important",
          overflow: "visible",
          fontSize: "11px",
          fontWeight: "bold",
          backgroundColor: headerColorObj[item.key]
            ? headerColorObj[item.key]
            : "white",
        },
        style: {
          whiteSpace: "break-spaces",
          width: "50px !important",
          overflow: "visible",
          fontSize: "11px",
          textAlign: item.key != "customer_name" ? "center" : "",
        },
        accessor: item.key,
        Cell: (props) => {
          // console.log(props.original, "cekDataClass1")
          let countryArr = dropdownInvoiceList.classification_receivables
          let countries = props.original.new_bu
          let ml = props.original.match_logic
          return ( 
            <FormControl variant="outlined" className='selectOutlineSmall' fullWidth size='small'>
            <Select
                  MenuProps={MenuProps}
                  disabled={ml == "first" ? true : false}
                // value={dataz[props.index]['country_name_new']}
                value={countries}
                label="New BU"
                onChange={(event) => { handleMappingChange(event, props.original, props.index) }}
            >
                <MenuItem value="">
                    <em>{''}</em>
                </MenuItem>
                {countryArr && countryArr.length && countryArr.map(item => {
                    return <MenuItem value={item.label} key={item.label}>{item.label}</MenuItem>
                })}
            </Select>
            
        </FormControl> 
    
      
        )
          
        },
      };

     
  }

      return {
        Header: handleSummaryColumn(item, summaryState.buRowData),
        id: item.key,
        width: handleDynamicWidth(item),
        fixed: handleFixedColumn(item),
        accessor: (_data) => {
          if (item.operation) {
            let modifiedData;
            let columnData = _data[item.key];
            switch (item.operation) {
              case 0:
                modifiedData = item.key;
                break;
              case 1:
                modifiedData = Math.round(columnData);
                break;
              case 2:
                modifiedData = numberWithCommas(columnData);
                break;
              case 4:
                modifiedData =
                  modifiedData < 0
                    ? "(" + Math.abs(columnData) + ")"
                    : columnData;
                modifiedData = modifiedData == "0" ? "" : modifiedData;
                break;
              case 3:
                modifiedData = Math.round(columnData);
                modifiedData = numberWithCommas(modifiedData);
                break;
              case 5:
                modifiedData = Math.round(columnData);
                modifiedData =
                  modifiedData < 0
                    ? "(" + Math.abs(modifiedData) + ")"
                    : modifiedData;
                modifiedData = modifiedData == "0" ? "" : modifiedData;
                break;
              case 6:
                var isNegative = columnData < 0;
                var positiveTransformedValue = isNegative
                  ? Math.abs(columnData)
                  : columnData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative
                  ? "(" + modifiedData + ")"
                  : modifiedData;
                modifiedData = modifiedData == "0" ? "" : modifiedData;
                break;
              case 7:
                modifiedData = Math.round(columnData);
                var isNegative = modifiedData < 0;
                var positiveTransformedValue = isNegative
                  ? Math.abs(modifiedData)
                  : modifiedData;
                modifiedData = numberWithCommas(positiveTransformedValue);
                modifiedData = isNegative
                  ? "(" + modifiedData + ")"
                  : modifiedData;
                modifiedData = modifiedData == "0" ? "" : modifiedData;
                break;
              default:
                break;
            }
            return modifiedData;
          } else {
            return _data[item.key];
          }
        },
        sortMethod: (a, b) => {
          if (typeof a != "string") {
            
            return Number(a) - Number(b);
          } else {
            if (item.key == "collection_actual" || item.key == "amount") {
              let val = a.split(",").join("");
              let valb = b.split(",").join("");
              let valInt = parseInt(val);
              let valIntB = parseInt(valb);
              valInt = isNaN(valInt) ? 0 : valInt;
              valIntB = isNaN(valIntB) ? 0 : valIntB;

              return valInt - valIntB;
            } else {
              if (a > b) {
                return -1;
              } else if (a < b) {
                return 1;
              }
              return 0;
            }
          }
        },
        headerStyle: {
          whiteSpace: "break-spaces",
          width: "50px !important",
          overflow: "visible",
          fontSize: "11px",
          fontWeight: "bold",
          backgroundColor: headerColorObj[item.key]
            ? headerColorObj[item.key]
            : "white",
        },
        style: {
          whiteSpace: "break-spaces",
          width: "50px !important",
          overflow: "visible",
          fontSize: "11px",
          textAlign: item.key != "customer_name" ? "center" : "",
          cursor: agingCategoryArr.indexOf(item.key) > -1 ? "pointer" : "",
        },
      };
    });
  }
  return [];
};
