import React from "react";
import TableComponent from './NewReactTable'
import Icon from '@material-ui/core/Icon';
import {
	handleTableTransform,
	numberWithCommas,
	checkNan,
} from '../../../../utils/numberUtils';

let globalIndex = 0;

export default function SummaryTableComponent(props) {


    const modifyNewColumnData = (columnData, operations) => {
		// console.log(columnData,operations, "--ini props")

		let modifiedData;
		let stringVersion = String(columnData);
		if (stringVersion.includes('.') && columnData && columnData.toFixed) {
			modifiedData = columnData.toFixed(2);
		} else {
			modifiedData = columnData;
		}
		var isNegative = modifiedData < 0;
		var positiveTransformedValue = isNegative
			? Math.abs(modifiedData)
			: modifiedData;
		modifiedData = numberWithCommas(positiveTransformedValue);
		modifiedData = isNegative ? '(' + modifiedData + ')' : modifiedData;
		modifiedData = modifiedData == '0' ? '-' : modifiedData;

		if (operations == 'percentage') {
			modifiedData += '%';
		}

		modifiedData = modifiedData ? modifiedData : '-'

		return modifiedData;
	};

    const handleAppendPercentage = (data) => {
		if (data && data['data_field']) {
			let dataField = data['data_field'];
			if (dataField.includes('%')) {
				return true;
			}
		}
		return false;
	};


    const newChangeHeaderKeysLevel3 = (data, parentData, subParentData) => {
		if (data && data.length > 0) {
			return data.map((item, i) => {
				const tempItemData = {
					Header: item.label,
					id:
						item.key +
						'_' +
						parentData.key +
						'_' +
						subParentData.key +
						Math.random('92129911') +
						new Date().getTime(),
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				tempItemData.Cell = (props) => {
					const { row } = props;
					let showPercentage = handleAppendPercentage(row.original);
					let myClassName = showPercentage ? 'percentageRowData' : '';
					let showBorder = false
					let showDrillDown = false
					if ( subParentData?.key == 'total' && item.key == 'total'){
						showBorder = true
					}

					// if (item.key === "data_field") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
					//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
					// } else {
					// return <span>{'lol'}</span>
					if (!row.original.disable_drill_down) {
						showDrillDown = true;
					}
					// console.log('row.originalrow.original', row.original, parentData, subParentData, item)
					return (
						<div 
						className={`text-center ${showDrillDown ? 'enableDrillDown pointer' : ''} ${showBorder ? '' : ''}`} style={{borderRight: showBorder && '1px solid #423970', lineHeight: '40px'}}
						onClick={()=> {
							if (showDrillDown){
								// this.handleThreeLevelDrilldownClick(row.original, parentData, subParentData, item)
							}
						}}
						>
							{
								<span className={myClassName}>
									{row.original &&
									row.original.sub_column &&
									row.original.sub_column[parentData.key] &&
									row.original.sub_column[parentData.key]['sub_column'] &&
									row.original.sub_column[parentData.key]['sub_column'][
										subParentData.key
									]
										? modifyNewColumnData(
												row.original.sub_column[parentData.key]['sub_column'][
													subParentData.key
												][item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							}
						</div>
					);
					// }
				};

				// tempItemData.Cell = ({row}) =>  <span>{row.original ? row.original.subColumn[parentData.key][subParentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

    const newChangeHeaderKeysLevel2 = (data, parentData, level1Index) => {
		if (data && data.length > 0) {
			return data.map((item, level2Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + '_' + parentData.key,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel3(
						item.sub_headers,
						parentData,
						item
					);
				} else {
					// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

					tempItemData.Cell = (props) => {
						const { row } = props;
						// if (item.key === "data_field") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else if (item.key === "Q4_comment" || item.key === "Sep_comment") {
						//     return <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						// } else {
						// return <span>{ 'pp'}</span>
						if (parentData.key.includes('comment')) {
							const commentData = row.original && 
							row.original.sub_column && 
							row.original.sub_column[parentData.key] && 
							row.original.sub_column[parentData.key]['sub_column'] && 
							row.original.sub_column[parentData.key]['sub_column'][item.key]
								return (
									<div 
									style={{'color': '#c0b6ed' }}
									onClick={() => {
                                        // this.handleCommentShowClick(row.original, parentData, item, level1Index, level2Index)
                                	}}>
									<Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
								</div>
									
								);
							
						}

						return (
							<span>
								{row.original && row.original.sub_column && row.original.sub_column[parentData.key] && row.original.sub_column[parentData.key]['sub_column']
									? 
									row.original.sub_column[parentData.key]['sub_column'][item.key]
									: ''}
							</span>
						);
						// }
					};
				}

				// tempItemData.Cell = ({row}) => <span>{row.original ? row.original.subColumn[parentData.key][item.key] : ''}</span>

				return tempItemData;
			});
		} else return [];
	};

    const newChangeKeysOfHeader = (data) => {
		if (data && data.length > 0) {
			return data.map((item, level1Index) => {
				const tempItemData = {
					Header: item.label,
					id: item.key + globalIndex,
					metaData: item.metadata,
					style: {
						// borderRight: '1px solid #423970',
						// cursor: this.showCursorPointerForHeader()
					},
					headerStyle: {
						overflow: 'visible',
						fontSize: '13px',
						fontWeight: 'bold',
						// borderRight: '1px solid #423970'
					},
				};

				globalIndex += 1;

				const isNested = item.hasOwnProperty('sub_headers');

				if (isNested) {
					tempItemData.columns = newChangeHeaderKeysLevel2(
						item.sub_headers,
						item,
						level1Index
					);
				} else {
					if (item.key === 'hemisphere' || item.key === 'sub_region' || item.key === 'sub_bu' || item.key == 'new_bu') {
						tempItemData.sticky = 'left';
					}
					tempItemData.Cell = (props) => {
						const { row } = props;
						if (item.key === 'hemisphere' || item.key === 'sub_region' || item.key === 'sub_bu' || item.key == 'new_bu') {
							return row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									<span
										className={
											row.isExpanded
												? 'rt-expanded-class'
												: 'rt-collapsed-class'
										}
									></span>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							) : (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</span>
							);

							// <span>{row.original && row.original[item.key] ? row.original[item.key] : ''}</span>
						} else if (
							item.key.includes('comment')) {
							return (
								<div 
                                style={{'color': '#c0b6ed' }}
                                onClick={() => {
                                        // this.handleCommentShowClick(row.original, item)
                                }}>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}
                                    >
                                        remove_red_eye
                                    </Icon>
                                    <Icon
                                        color={'#c0b6ed'}
                                        style={{'color': '#c0b6ed !important' }}

                                    >
                                        add_circle_outline
                                    </Icon>
									{row.original && row.original[item.key]
										? row.original[item.key]
										: ''}
								</div>
							);
						} else {
							return (
								<span>
									{row && row.original && row.original[item.key]
										? modifyNewColumnData(
												row.original[item.key],
												row.original['operations']
										  )
										: '-'}
								</span>
							);
						}
					};
				}

				return tempItemData;
			});
		} else return [];
	};
  

    const {rowData, rowHeader, loading} = props.tableData
    const newPivotHeaderData = newChangeKeysOfHeader(rowHeader);
    

    return (
        <div>
            <TableComponent 
                 data={rowData}
                 className={'newCustomTable fixedFirstColumTableSummaryView expandableRows'}
                 loading={loading}
                 columns={newPivotHeaderData}
				 addClassName={props.addClassName}
				 paginationEnable={props.paginationEnable}
            />
        </div>
    )
}